import React from 'react';
import { pastDuePath } from 'config/paths';
import { fullTrainerConvPathFor, paywallPathFor } from 'redux/helpers';
import { withRouter } from 'react-router-dom';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { TPAYWALL } from 'config/settings';

class TrainerPaywall extends React.Component {

    constructor(props) {
        super(props);
        const { trainer, type, history, allowUninit=true } = props;
        if(trainer && trainer.showPastDueScreen()) {
            history.replace(pastDuePath)
        }else if(!trainer || !trainer.hasTrainerAccess(type,allowUninit)) {
            history.replace(fullTrainerConvPathFor(TPAYWALL))
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}

const mapStateToTrainerProps = state => ({
    trainer: trainerRecordSelector(state)
})

TrainerPaywall = connect(mapStateToTrainerProps)(withRouter(TrainerPaywall));

export { TrainerPaywall }

class PaywallWrapper extends React.Component {

    constructor(props) {
        super(props);
        const { user, blockTypes, history, context } = props;
        if(!user || !user.hasProAccess(blockTypes)) {
            history.replace(paywallPathFor(context || 'paywall',user))
        }
    }

    render() {
        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    user: userRecordSelector(state)
})

PaywallWrapper = connect(mapStateToProps)(withRouter(PaywallWrapper));

export default PaywallWrapper;