import * as _ from 'lib/utilities';

export default class PlateCalculator {

    static KG_PLATES = [1.25,2.5,5,10,15,20]
    static LB_PLATES = [2.5,5,10,25,35,45]
    static KG_BARS = [5,10,20]
    static LB_BARS = [10,25,45]

    constructor(spec,t) {
        this.spec = spec;
        this.t = t;
        this.suffix = this.isMetric() ? t('kgs') : t('lbs');
        this.map = this.labelWeightMap();
        this.weights = Object.values(this.map);
        this.unitMincrement = this.spec.user().unitMincrement(this.spec.exercise);
        if(this.isMetric()) {
            this.plates = PlateCalculator.KG_PLATES;
            this.bars = [...PlateCalculator.KG_BARS];
        } else {
            this.plates = PlateCalculator.LB_PLATES;
            this.bars = [...PlateCalculator.LB_BARS];
        }
        const minPlate = this.unitMincrement/2;
        this.plates = _.filter(this.plates,plate => (plate >= minPlate));
        if(!this.plates.includes(minPlate)) {
            this.plates.unshift(minPlate);
        }

        this.plates.reverse();
        this.bars.reverse();
    }

    user() {
        return this.spec.user();
    }

    isMetric() {
        return this.spec.isMetric();
    }

    labelWeightMap() {
        let map = {};
        this.spec.exerciseSets.forEach(set => {
            const baseKey = set.warmup ? this.t('Warmup Set') : this.t('Set');
            const unitWeight = set.unitWeight();
            const weightKey = unitWeight ? `${unitWeight} ${this.suffix}` : this.t('no weight')
            map[`${baseKey} ${set.setIndex()} (${weightKey})`] = set.unitWeight(); 
        })
        return map;
    }

    results() {
        let results = {};

        Object.entries(this.map).forEach(([label,totalWeight]) => {
            if(_.isBlank(totalWeight) || totalWeight === 0) {
                results[label] = null;
                return;
            }

            let barWeight = null;
            for(let weight of this.bars) {
                if(totalWeight >= weight) {
                    barWeight = weight;
                    break;
                }
            }

            barWeight = barWeight || this.bars[this.bars.length-1];

            let result = { bar: `${barWeight} ${this.suffix}`};
            let remainingWeight = totalWeight - barWeight;

            if(remainingWeight <= 0) {
                result.noPlates = true;
                results[label] = this.finish(result,remainingWeight);
                return;
            }

            const canComplete = remainingWeight % (this.plates[this.plates.length-1]*2) === 0;

            for(let plate of this.plates) {
                let num = Math.floor(remainingWeight/(2*plate));
                remainingWeight -= num*plate*2;
                if(canComplete) {
                    while(remainingWeight % (this.plates[this.plates.length-1]*2) !== 0 && num > 0) {
                        num -= 1;
                        remainingWeight += plate*2;
                    }
                }

                if(num > 0) {
                    result[`${plate} ${this.suffix}`] = num;
                }

                if(remainingWeight <= 0) {
                    break;
                }
            }

            if(Object.keys(result).length === 1) {
                result.noPlates = true;
            }

            results[label] = this.finish(result,remainingWeight);
        })
        return results;
    }

    finish(result,remaining) {
        if(remaining !== 0) {
            result['residual'] = `${remaining} ${this.suffix}`;
        }
        return result;
    }


}