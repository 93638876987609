import React from 'react';
import { progressChartsSelector, userRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { updateProgressChartsLocal, updateMacroAnalytics } from 'redux/actions';
import Charts from 'partials/MacroAnalytics';
import { workoutSetupMatch, pickExercisePath, emailPaywallMatches, allMainMatches, macroAnalyticsPath, clientDetailsMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import PaywallWrapper from 'partials/PaywallWrapper';
import ClientSwitcher from 'partials/ClientSwitcher';

const transitionMap = {
    rules: [
        [[ ...allMainMatches, clientDetailsMatch],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath], transitions.slideOver]
    ]
};

export const ClientMacroAnalyticsShortcut = (props) => {
    const { t } = useTranslation();

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('Macro Analytics')} afterPath={macroAnalyticsPath} />
    )
}

const AnalyticsPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, ...rest } = props;

    return (
        <PaywallWrapper blockTypes={['hard','soft']} context="macro_analytics">
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t('Macro Analytics')}
            >
                <Charts 
                    {...rest}
                    t={t}
                />
            </SimpleNavPage>
        </PaywallWrapper>
    )
}

const mapStateToProps = (state) => ({
    user: userRecordSelector(state),
    progressChartFormData: progressChartsSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    updateMacroAnalytics: (lookbackDays) => dispatch(updateMacroAnalytics(lookbackDays)),
    updateProgressChartsLocal: (data) => dispatch(updateProgressChartsLocal(data)),
})

export default connect(mapStateToProps,mapDispatchToProps)(AnalyticsPage)