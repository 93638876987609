import React from 'react';
import Page from'components/Page';
import { matchPath} from 'react-router';
import { Route } from "react-router-dom";
import { trainerMainMatches, rootPath, startPath, startFlow, startEnd, assignClientRoutineMatch, editRoutineStandaloneMatch, editRoutinePath, trainersPath, mainMatches, routinesBaseMatch, editRoutineShortcutMatch, switchCliAccountMatch, assignRoutineToClientsMatch, trainerHomeMatch, trainerSignupMatch, trainerConversionMatch, trainerRecipesMatch, routinesBase, trainerHabitsMatch, assignClientHabitsMatch, assignHabitToClientsMatch, trainerMessagingMatch, onboardMatch } from 'config/paths';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { clearCachedWorkouts, logoutUser } from 'redux/actions';
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import { TrainerMainNav, TrainerMainSidenav, trainerSlideOverPaths } from 'partials/MainLayout';
import * as transitions from 'assets/transitions';
import TrainerHome, { MetricTabs } from './TrainerHome';
import TrainerMgmt from './TrainerMgmt';
import WorkoutRoutines from './WorkoutRoutines';
import RoutineSyncChecker from 'partials/RoutineSyncChecker';
import MediaQuery from 'components/MediaQuery';
import { MainRecipeBrowser, TrainerRecipeTabs } from './RecipeBrowser';
import Habits from './Habits';
import { RecordTabs } from 'partials/IndexPage';
import TrainerMessaging from './TrainerMessaging';
import NotificationRequestorWrapper from 'partials/NotificationRequestorWrapper';

export const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.none],
        [[rootPath,startPath,startFlow,startEnd,trainerSignupMatch,onboardMatch],transitions.slideOut],
        [[
            assignClientHabitsMatch,
            assignHabitToClientsMatch,
            assignClientRoutineMatch,
            assignRoutineToClientsMatch,
            editRoutineStandaloneMatch,
            editRoutinePath,
            editRoutineShortcutMatch,
            switchCliAccountMatch,
            trainerConversionMatch
        ],transitions.slideOver],
        [trainerSlideOverPaths,transitions.slideOver],
        [mainMatches,transitions.slideOver]
    ]
};

class TrainerMainCore extends React.Component {

    constructor(props) {
        super(props)
        props.setupTransitions(transitionMap);
        this.state = { routineImports: 0, recipeImports: 0, habitImports: 0 };
    }

    render() {
        const { trainer, scrollRef, location, history, logoutUser, isLarge } = this.props;
        const { routineImports, recipeImports, habitImports } = this.state;
        const classNames = classnames({"nav-container": true, "activity-tabs-container": this.hasActivityTabs(), 'activity-tabs-container-extra': this.hasMealPlanHeader(), 'messaging-cont': this.noScrollPadding() });
        const showTabs = this.showClientMetricTabs();

        return (
                <Page ref={scrollRef} noScrollPadding={this.noScrollPadding()}>
                    <RoutineSyncChecker location={location} history={history} basePaths={trainerMainMatches}>
                        <div className={classNames}>
                            <RouteTransitionMap location={location} overrideScrollRef={scrollRef} canUpdate noTransitions>
                                <TrainerHome path={trainerHomeMatch} isLarge={isLarge} showTabs={showTabs} />
                                <MainRecipeBrowser path={trainerRecipesMatch} addRecipeImport={this.addRecipeImport} canUpdate />     
                                <TrainerMgmt path={trainersPath} isLarge={isLarge} />
                                <WorkoutRoutines isLarge={isLarge} path={routinesBaseMatch} addRoutineImport={this.addRoutineImport} clearRoutineImports={this.clearRoutineImports} routineImports={routineImports} />
                                <Habits isLarge={isLarge} path={trainerHabitsMatch} addHabitImport={this.addHabitImport} clearHabitImports={this.clearHabitImports} habitImports={habitImports} />
                                <TrainerMessaging path={trainerMessagingMatch} />
                            </RouteTransitionMap>
                            <TrainerMainNav location={location} user={trainer} className={classnames({ 'with-mp-header': this.hasMealPlanHeader()})}>
                                {!isLarge && <Route path={routinesBaseMatch} render={(routeProps) => (<RecordTabs {...routeProps} importCount={routineImports} basePath={routinesBase} />)}/>}
                                {!isLarge && showTabs && <Route path={trainerHomeMatch} render={(routeProps) => (<MetricTabs {...routeProps} trainer={trainer} />)}/>}
                                {
                                //!isLarge && <Route path={trainerHabitsMatch} render={(routeProps) => (<RecordTabs {...routeProps} importCount={habitImports} basePath={habitsBase} />)}/>
                                }
                                <Route path={trainerRecipesMatch} render={(routeProps) => (<TrainerRecipeTabs {...routeProps} recipeImports={recipeImports} clearRecipeImports={this.clearRecipeImports} />)}/>
                            </TrainerMainNav>
                        </div>
                        <TrainerMainSidenav user={trainer} trainer={trainer} logoutUser={logoutUser} basePath={this.menuBasePath()} />
                    </RoutineSyncChecker>
                    {trainer && trainer.shouldRequestNotification() && <NotificationRequestorWrapper />}
                </Page>
        )
    }

    showClientMetricTabs() {
        const { trainer } = this.props;
        return trainer.showClientMetricTabs();
    }

    hasClientMetricTabs() {
        const { trainer, location: { pathname } } = this.props;
        return this.showClientMetricTabs() && matchPath(pathname, { path: trainerHomeMatch }) && !trainer.needsFirstMpButton();
    }

    showRecipeTabs() {
        const { location: { pathname } } = this.props;
        return matchPath(pathname, { path: trainerRecipesMatch });
    }

    hasActivityTabs() {
        const { location: { pathname }, isLarge } = this.props;
        if(this.showRecipeTabs()) {
            return true;
        }
        return !isLarge && (matchPath(pathname, { path: [routinesBaseMatch] }) || this.hasClientMetricTabs());
    }

    noScrollPadding() {
        const { location: { pathname } } = this.props;
        return matchPath(pathname,trainerMessagingMatch);
    }

    hasMealPlanHeader() {
        return false;
    }

    addRoutineImport = () => {
        this.setState({ routineImports: this.state.routineImports+1 });
    }

    addHabitImport = () => {
        this.setState({ habitImports: this.state.habitImports+1 });
    }

    addRecipeImport = () => {
        this.setState({ recipeImports: this.state.recipeImports+1 });
    }

    clearRoutineImports = () => {
        this.setState({ routineImports: 0 });
    }

    clearRecipeImports = () => {
        this.setState({ recipeImports: 0 });
    }

    clearHabitImports = () => {
        this.setState({ habitImports: 0 });
    }

    menuBasePath = () => {
        const { location: { pathname } } = this.props;
        return pathname.replace('/menu','')
    }
}

const TrainerMain = (props) => (<MediaQuery query={'(min-width: 993px)'} render={({ queryMatches }) => (<TrainerMainCore {...props} isLarge={queryMatches} />)} />)

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
});

const mapDispatchToProps = dispatch => ({
    clearCachedWorkouts: (workouts) => dispatch(clearCachedWorkouts(workouts)),
    logoutUser: () => dispatch(logoutUser())
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(TrainerMain));