import React from 'react';
import { modalPathFor, trainerPaymentPathFor } from 'config/paths';
import { billCycleLengths,  StripeModal } from 'views/Conversion';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Trans, withTranslation } from 'react-i18next';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import * as _ from 'lib/utilities';
import { FULL, NUTRITION, TPAYWALL, TRAINING } from 'config/settings';
import { User } from 'lib/classes';
import { initStripeTrainerSub, updateTrainerSub } from 'redux/actions';
import { SlashedPrice } from './Utilities';

const signupFirstSuffix = '/signup_required';
export const signupFirstPathFor = pathname => modalPathFor(signupFirstSuffix,pathname);

export const baseClients = 5;

const prices = { 
    nutrition: { 
        quarterly: 20, 
        monthly: 20, 
        name: "Nutrition Only",
        shortName: "Nutrition",
        clientPrices: {
            quarterly: 4,
            monthly: 4
        } 
    },
    full: { 
        quarterly: 25, 
        monthly: 25, 
        name: "Full Package",
        shortName: "Full",
        clientPrices: {
            quarterly: 5,
            monthly: 5
        } 
    },
    training: { 
        quarterly: 10, 
        monthly: 10, 
        name: "Training Only",
        shortName: "Training",
        clientPrices: {
            quarterly: 2,
            monthly: 2
        } 
    }
};

const getSummaryFeatures = (planType,cliDols,t) => {
    const arr = [];
    if(planType === NUTRITION) {
        arr.push(t("customizable meal planner"));
    } else if(planType === TRAINING) {
        arr.push(t("custom workout routines"));
    } else {
        arr.push(t("all nutrition features"));
        arr.push(t("all training features"));
    }
    if(planType !== FULL) {
        let str = `${t("iOS/Android/web app access for clients")}`;
        if(planType === NUTRITION) {
            str = `${str} (${t('optional')})`
        }
        arr.push(str);
    }
    arr.push(<Trans i18nKey="x clients y dollars" >{`${baseClients}`}{cliDols}</Trans>);

    return arr;
}

const prodIsCurrent = ({ planType, frequency, user }) => {
    if(user.hasActiveTrainerSub() && !user.trainerSubIsOldPrice()) {
        return `${planType}-${frequency}` === `${user.activeTrainerPlanType()}-${user.trainerSubFreq()}`;
    }
    return false;
}

const prodIsSelectable = ({ planType, frequency, user, context }) => {
    if(context === TPAYWALL) {
        return (planType !== user.activeTrainerPlanType());
    } else {
        return !prodIsCurrent({ planType, frequency, user });
    }
}

const getProducts = (t,user,frequency,context,successHandler) => {
    const curPlan = user.activeTrainerPlanType();
    const trainerType = user.getTrainerType();    
    const isUpgrade = user.hasActiveTrainerSub() && user.canManageOwnAccount();
    const isTrialing = user.isTrainerTrialing();
    let resolvedPrices = prices;
    if(curPlan && context === TPAYWALL && trainerType !== User.TRAINER_TYPES.pdf) {
        resolvedPrices = _.pick(prices,[curPlan,FULL]);
    }
    const prods = Object.entries(resolvedPrices).map(([planType,{ name, clientPrices, shortName, ...freqs }]) => {
        const isCurrent = prodIsCurrent({ planType, frequency, user });
        const months = billCycleLengths[frequency];
        const originalMonthlyAmount = freqs[frequency];
        let monthlyAmount = originalMonthlyAmount;
        let cliDols = clientPrices[frequency];
        const bulkMult = user.tBulkMultiplier();
        let dAmount = originalMonthlyAmount*0.7;
        let dCliDols = cliDols*0.7;

        if(bulkMult) {
            monthlyAmount = originalMonthlyAmount*bulkMult
            cliDols = clientPrices[frequency]*bulkMult;
        }

        let cliDolsStr = `$${cliDols.toFixed(2)}`
        if(user.isFromDiscountedAffiliate()) {
            cliDolsStr = <SlashedPrice oldPrice={cliDols} discountPrice={dCliDols} dark />;
        }

        return {
            name: t(name),
            amount: monthlyAmount*months,
            monthlyAmount,
            months,
            dAmount: user.isFromDiscountedAffiliate() ? dAmount : null,
            dLength: user.isFromDiscountedAffiliate() ? 12 : null,
            topPriceStr: user.isFromDiscountedAffiliate() ? <React.Fragment><SlashedPrice oldPrice={monthlyAmount} discountPrice={dAmount} dark />{`/${t('month abbr')}`}</React.Fragment> : `$${monthlyAmount}/${t('month abbr')}`,
            bottomPriceStr: t("amount USD every X months", { amount: `$${monthlyAmount*months}`, months } ),
            moneyBackString: (user && user.hadTrainerTrial()) ? '' : t("14-day free trial"),
            primary: trainerType === User.TRAINER_TYPES.pdf ? planType === NUTRITION : planType === FULL,
            selectable: prodIsSelectable({ planType, frequency, user, context }),
            isUpgrade: isUpgrade,
            isTrialing, 
            planType,
            frequency,
            forTrainers: true,
            discount: user.isFromDiscountedAffiliate() ? 30 : null,
            featureSummary: getSummaryFeatures(planType,cliDolsStr,t),
            getPlanParams: () => ({ planType, frequency }),
            initiateCheckout: () => {},
            hasTrial: !(user && user.hadTrainerTrial()),
            shortName,
            extraClientPrice: cliDolsStr,
            onSuccess: successHandler,
            isCurrent,
            originalMonthlyAmount: isCurrent ? originalMonthlyAmount : null,
            originalAmount: isCurrent ? originalMonthlyAmount*months : null
        }
    })
    return prods;
}

export const withStore = (initialSelect,coupon=null,allFreqs=false) => (Component) => {

    const mapStateToProps = state => ({
        user: trainerRecordSelector(state) || userRecordSelector(state) || User.defaultUser()
    })

    const mapDispatchToProps = dispatch => ({
        initStripeAction: data => dispatch(initStripeTrainerSub(data)),
        updatePlan: data => dispatch(updateTrainerSub(data))
    })

    class Store extends React.Component {

        //products have: initiateCheckout(), topPriceStr, bottomPriceStr, moneyBackString, primary
        constructor(props) {
            super(props);

            this.paymentModalOpen = false;
            this.buildProducts = this.buildProducts.bind(this);
            this.handlePaymentModalClose = this.handlePaymentModalClose.bind(this);
            this.initStripePayment = this.initStripePayment.bind(this);
            this.getTrainerType = this.getTrainerType.bind(this);
            this.storeContext = props.match.params && props.match.params.context;
            const initFreq = 'monthly';
        
            //build products
            const products = this.buildProducts(initFreq);
            
            this.state = { frequency: initFreq, products, selectedProduct: (initialSelect ? (products[1] || products[0]) : null) };
            this.mounted = true;
        }

        getTrainerType() {
            const { user } = this.props;
            return user.getTrainerType();
        }

        buildProducts(frequency) {
            const { t, user, updatePlan, history, location: { pathname } } = this.props;
            let products;
            if(allFreqs) {
                products = _.flatMap(['quarterly','monthly'],freq => getProducts(t,user,freq,this.storeContext,this.successHandler));
            } else {
                products = getProducts(t,user,frequency,this.storeContext,this.successHandler);
            }

            products.forEach( product => { 
                const planParams = { planType: product.planType, frequency: product.frequency };
                if(coupon) {
                    planParams.coupon = coupon;
                }
                product.getPlanParams = () => planParams;

                product.initiateCheckout = () => { 
                    if(_.isBlank(user.email) || !user.isTrainer()) {
                        history.push(signupFirstPathFor(pathname))
                    }  else {
                        if(product.isUpgrade) {
                            return updatePlan({ ...product.getPlanParams() });
                        } else {
                            this.initStripePayment(product);
                        }
                    }
                }
            });

            return products;
        }

        initStripePayment(product) {
            if(!this.paymentModalOpen) {
                this.paymentModalOpen = true;
                this.selectProduct(product);
                this.props.history.push(trainerPaymentPathFor(this.props.match.params.context));
            }
        }

        selectProduct = (product) =>  this.setState({ selectedProduct: product });

        setFrequency = frequency => {
            this.setState({ frequency })
        }

        handlePaymentModalClose() {
            this.paymentModalOpen = false;
        }

        componentDidUpdate(prevProps,prevState) {
            if(prevProps.t !== this.props.t || this.state.frequency !== prevState.frequency) {
                this.setState({ products: this.buildProducts(this.state.frequency)});
            }
        }

        successHandler = () => {
            const { successPath, history } = this.props;
            history.replace(successPath);
        }

        render() {
            const modal = (this.state.selectedProduct && 
                (<StripeModal 
                    closeCallback={this.handlePaymentModalClose} 
                    product={this.state.selectedProduct} 
                    t={this.props.t}
                    i18n={this.props.i18n}
                    submitAction={this.props.initStripeAction}
                    initProSuccess={this.state.selectedProduct.onSuccess}
                    user={this.props.user}
                    disallowNonOwner={true}
                    path={trainerPaymentPathFor(this.props.match.params.context)}
                />)
            )

            return (
                <Component 
                    {...this.props} 
                    selectProduct={this.selectProduct}
                    initProSuccess={this.successHandler}
                    frequency={this.state.frequency}
                    setFrequency={this.setFrequency} 
                    products={this.state.products} 
                    selectedProduct={this.state.selectedProduct}
                    modal={modal} 
                />
            )
        }
    }

    return connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(Store)));
}