import React from 'react';
import { allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut]
    ]
};

class HelpPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, user, trainer, t } = this.props;
        const usr = trainer || user;
        let link = "https://www.strongrfastr.com/help";

        if(usr) {
            if(usr.isTrainer()) {
                link = `${link}/professionals`
            } else if(usr.isClient()) {
                link = `${link}/clients`
            } else {
                link = `${link}/users`
            }
        }

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("Help")}
                containerClass={'for-iframe'}
            >
                <div className="fp-iframe-cont">
                    <iframe src={link} title="Help" width="100%" height="100%" frameborder="0"></iframe>
                </div>
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

export default withTranslation()(connect(mapStateToProps)(HelpPage));