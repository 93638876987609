import React from 'react';

export default class AnimTo extends React.Component {

    constructor(props) {
        super(props);
        const { top, left } = this.props;
        this.state = { top, left };
    }

    componentDidMount() {
        setTimeout(() => this.setState({ top: 0, left: 0 }),1);
    }

    render() {
        const { tag: Tag, children, top: inTop, left: inLeft, style, ms=300, ...rest } = this.props;
        const { top, left } = this.state;

        return (
            <Tag style={{ ...(style || {}), top, left, position: 'relative', transition: `top ${ms}ms ease-out, left ${ms}ms ease-out`}} {...rest}>{children}</Tag>
        )
    }
}