import { logoUrl } from 'config/settings';
import React from 'react';

export default function AppSplash({ children, className }) {
    return (
        <div className={`app ${className ? className : ''}`}>
            <img className="logo-img" src={logoUrl()} alt="logo" />
            <div id="deviceready">
               {children}
            </div>
        </div>
    )
}