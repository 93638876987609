import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, progressionPickMatch, workoutPreviewMatch, workoutDoMatch, progressionShowMatch, editWorkoutTemplateMatch, editExerciseGroupMatch, allMainMatches } from 'config/paths';
import { connect } from 'react-redux';
import { mapStateToStandaloneProps, mapDispatchToProps, RoutineUserReconciler } from 'views/EditRoutine';
import { Redirect } from "components/Routing";
import { Loader } from 'components/LoadingHOC';
import { SimpleActivityTabsFull, NonLinkActivityTab } from 'components/BottomNav';
import AddExerciseTabs from 'partials/AddExerciseTabs';
import { ExitRoutineButton } from './EditWorkoutTemplate';
import { resolvedHomePath } from 'redux/helpers';

class AddExercisesSuccess extends React.Component {

    render() {
        const { workoutRoutine, workoutTemplateId, activeTab } = this.props;
        const workoutTemplate = workoutRoutine && workoutRoutine.findWorkoutTemplate(workoutTemplateId);


        if(workoutTemplate) {
            return (
                <div className="row">
                    <div className="col s12 m12 l6 offset-l3">
                        <div className="m-center-column">
                            <AddExerciseTabs 
                                workoutTemplate={workoutTemplate} 
                                activeTab={activeTab} 
                                basePath={workoutTemplate.addExercisesPath()}
                                standalone
                            />
                        </div>
                    </div>
                </div>
            )
        } else {
            return (<Redirect to={resolvedHomePath()} />)
        }
    }
}

const transitionMap = {
    rules: [
        [[ ...allMainMatches, workoutLogMatch, workoutPreviewMatch, workoutDoMatch, editWorkoutTemplateMatch ], transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch, editExerciseGroupMatch], transitions.slideOver]
    ]
};

const egTransitionMap = {
    rules: [
        [[ ...allMainMatches, workoutLogMatch, workoutPreviewMatch, workoutDoMatch, editWorkoutTemplateMatch, editExerciseGroupMatch ], transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch], transitions.slideOver]
    ]
};


class AddExercises extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(this.isGroup() ? egTransitionMap : transitionMap);
        this.state = { activeTab: props.activeTab || 2 }
    }

    render() {
        const { workoutRoutine, 
            loadRoutine, 
            warmupsNeedLoading, 
            match: { params: { id, workoutTemplateId }}, 
            t, 
            scrollRef,
            trainer,
            user
        } = this.props;

        const { activeTab } = this.state;
        let tabLabels = [t("My Exercises"),t('Search')];
        if(!this.isGroup()) {
            tabLabels.push(t('Exercise Groups'));
        }
        const showTabs = workoutRoutine && workoutRoutine.isLoaded() && tabLabels.length > 1;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow={!showTabs}>
                    <SimpleNavTitle>{t("Add Exercises")}</SimpleNavTitle>
                    <ExitRoutineButton />
                </SimpleNav>
                {showTabs && (<SimpleActivityTabsFull 
                    Tab={NonLinkActivityTab}
                    tabLabels={tabLabels}
                    setActiveTab={this.setActiveTab}
                    activeTab={activeTab}
                />)}
                <SimpleNavContainer className={ showTabs ? "with-tabs" : ''}>
                    <RoutineUserReconciler user={user} workoutRoutine={workoutRoutine} trainer={trainer}>
                        <Loader
                            load={loadRoutine.bind(null,id,warmupsNeedLoading)}
                            preloaded={() => (workoutRoutine && workoutRoutine.isLoaded())}
                            successComponent={AddExercisesSuccess}
                            type='page'
                            workoutRoutine={workoutRoutine}
                            workoutTemplateId={workoutTemplateId}
                            activeTab={activeTab}
                        />
                    </RoutineUserReconciler>
                </SimpleNavContainer>
            </Page>
        )
    }

    setActiveTab = (activeTab) => {
        const { setActiveTab } = this.props;
        this.setState({activeTab});
        setActiveTab(activeTab);
    }

    isGroup = () => {
        const { location } = this.props;
        return location.pathname.includes('exercise_group');
    }
}



export default connect(mapStateToStandaloneProps,mapDispatchToProps)(withTranslation()(AddExercises));