import React from 'react';
import classnames from 'classnames';
import { useLocation } from 'react-router-dom';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from './Nav';
import { Redirect } from './Routing';
import { SimpleActivityTabsFull, NonLinkActivityTab } from './BottomNav';

const Page = React.forwardRef(({ className, children, white, darkMode, noScrollPadding, ...rest}, ref) => {
        const classNames = classnames({'page-container':true, 'white-color': white,'off-white': !(white || darkMode), 'dark': darkMode, [className]: className})
        const scrollClassNames = classnames("app-scroll-container",{ 'no-scroll-padding': noScrollPadding })
        const location = useLocation();

        //safari is special needs and seems to like to scroll the window in certain situations (e.g. keyboard opening & closeing) such that
        //it can't be unscrolled. Since the app doesn't actually scroll the window for any reason, it's safe to scroll it all the way back to the top
        const scrollHack = e => {
            const target = e.target;

            if(target && target.scrollTop === 0) {
                window.scrollBy(0,-9000); 
            }
        }

        //padding on scroll container is for weird scroll issue with Chrome URL bar behavior (scrolling stops before bottom of page and you have to swipe again to scroll fully to bottom, which also hides the URL bar)
        return (
            <div className={classNames} style={{minHeight: `100vh`}} {...rest} >
                <div id={`app-scroll-container-${location.key}`} className={scrollClassNames} ref={ref} onScroll={scrollHack}>
                    {children}
                </div>
            </div>
        );

})

export class PageWithTransitions extends React.Component {
    constructor(props) {
        super(props);
        props.setupTransitions(props.transitionMap);
    }

    render() {
        const { scrollRef, children, setupTransitions, transitionMap, ...rest } = this.props;


        return (
            <Page ref={scrollRef} {...rest}>
                {children}
            </Page>
        )
    }
}

export class SimpleNavPage extends React.Component {
    constructor(props) {
        super(props);
        props.setupTransitions(props.transitionMap);
    }

    render() {
        const { scrollRef, title, containerClass, children, className, tabs, darkMode, whiteMode, navChildren, redirectCheck, backBtn, noScrollPadding, white } = this.props;
        const redirectPath = redirectCheck && redirectCheck();

        if(redirectPath) {
            return (
                <div>
                    <Redirect to={redirectPath} />
                </div>
            )
        } else {
            return (
                <Page ref={scrollRef} darkMode={darkMode} noScrollPadding={noScrollPadding} white={white}>
                    <SimpleNav primary={!darkMode && !whiteMode} shadow={!tabs} className={className} darkMode={darkMode} backBtn={backBtn}>
                        <SimpleNavTitle>{title}</SimpleNavTitle>
                        {navChildren}
                    </SimpleNav>
                    {tabs}
                    <SimpleNavContainer className={containerClass}>
                        {children}
                    </SimpleNavContainer>
                </Page>
            )
        }
    }
}

export class SimpleNavPageWithTabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { activeTab: (props.activeTab || props.defaultActiveTab || 1)}
    }

    render() {
        const { activeTab } = this.state;
        const { tabLabels, containerClass, children, tabs, ...rest } = this.props;

        return (
            <SimpleNavPage
                {...rest}
                containerClass={classnames('with-tabs',{ [containerClass]: containerClass })}
                tabs={tabs || (<SimpleActivityTabsFull 
                    Tab={NonLinkActivityTab}
                    tabLabels={tabLabels}
                    setActiveTab={this.setActiveTab}
                    activeTab={activeTab}
                />)}
            >
                {React.Children.map(children,(child,index) => {
                    return child ? React.cloneElement(child, { ...child.props, activeTab, setActiveTab: this.setActiveTab }) : null;
                })}
            </SimpleNavPage>
        )
    }

    setActiveTab = (activeTab) => {
        const { setActiveTab } = this.props;
        this.setState({activeTab});
        setActiveTab(activeTab);
    }
}

export default Page;
