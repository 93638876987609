import React from 'react';
import classnames from 'classnames';

const Progress = ({ percent, fat, gradient, classNames }) => {
    const style = gradient ? {width: `${percent}%`, backgroundSize: `${100/percent*100}% ${fat ? '20px' : '4px'}`} : {width: `${percent}%`}
    return (
        <div className={classnames('progress', { gradient, [classNames]: classNames })} style={ fat && { height: '20px' }}>
            <div className={"determinate"} style={style} />
        </div>
    )
}

export default Progress;