import Component from './component';
import $ from './cash';
import M from './global';
import anim from './anime';


  let _defaults = {
    exitDelay: 1,
    enterDelay: 0,
    html: null,
    margin: 5,
    inDuration: 250,
    outDuration: 200,
    position: 'bottom',
    transitionMovement: 10
  };

  /**
   * @class
   *
   */
  class Tooltip extends Component {
    /**
     * Construct Tooltip instance
     * @constructor
     * @param {Element} el
     * @param {Object} options
     */
    constructor(el, options) {
      super(Tooltip, el, options);

      this.el.M_Tooltip = this;
      this.options = $.extend({}, Tooltip.defaults, options);

      this.isOpen = false;
      this.isHovered = false;
      this.isFocused = false;
      this._appendTooltipEl();
      this._setupEventHandlers();
    }

    static get defaults() {
      return _defaults;
    }

    static init(els, options) {
      return super.init(this, els, options);
    }

    /**
     * Get Instance
     */
    static getInstance(el) {
      let domElem = !!el.jquery ? el[0] : el;
      return domElem.M_Tooltip;
    }

    /**
     * Teardown component
     */
    destroy() {
      $(this.tooltipEl).remove();
      this._removeEventHandlers();
      this.el.M_Tooltip = undefined;
    }

    _appendTooltipEl() {
      let tooltipEl = document.createElement('div');
      tooltipEl.classList.add('material-tooltip');
      if(this.options.autoOpen) {
        tooltipEl.classList.add('auto-open');
      }
      if(this.options.classes) {
        tooltipEl.classList.add(...this.options.classes);
      }
      this.tooltipEl = tooltipEl;

      let tooltipContentEl = document.createElement('div');
      tooltipContentEl.classList.add('tooltip-content');

      let html = this.options.html;
      if(typeof html === 'string') {
        tooltipContentEl.innerHTML = html;
      } else {
        tooltipContentEl.appendChild(html);
      }
      tooltipEl.appendChild(tooltipContentEl);

      let tooltipArrowEl = document.createElement('div');
      tooltipArrowEl.classList.add('arrow');
      tooltipEl.appendChild(tooltipArrowEl);
      this.arrowEl = tooltipArrowEl;
      tooltipEl.style.pointerEvents = 'none';

      document.body.appendChild(tooltipEl);
    }

    _updateTooltipContent() {
      let tooltipContentEl = this.tooltipEl.querySelector('.tooltip-content');
      let html = this.options.html;
      if(typeof html === 'string') {
        tooltipContentEl.innerHTML = html;
      } else {
        tooltipContentEl.appendChild(html);
      }
    }

    _setupEventHandlers() {
      if(!this.options.autoOpen) {
        this._handleMouseEnterBound = this._handleMouseEnter.bind(this);
        this._handleMouseLeaveBound = this._handleMouseLeave.bind(this);
        this._handleMouseClickBound = this._handleMouseClick.bind(this);
        this._handleFocusBound = this._handleFocus.bind(this);
        this._handleBlurBound = this._handleBlur.bind(this);
        this.el.addEventListener('mouseenter', this._handleMouseEnterBound);
        this.el.addEventListener('click', this._handleMouseClickBound);
        this.el.addEventListener('mouseleave', this._handleMouseLeaveBound);
        this.el.addEventListener('focus', this._handleFocusBound, true);
        this.el.addEventListener('blur', this._handleBlurBound, true);
      }
    }

    _removeEventHandlers() {
      this.el.removeEventListener('mouseenter', this._handleMouseEnterBound);
      this.el.removeEventListener('click', this._handleMouseClickBound);
      this.el.removeEventListener('mouseleave', this._handleMouseLeaveBound);
      this.el.removeEventListener('focus', this._handleFocusBound, true);
      this.el.removeEventListener('blur', this._handleBlurBound, true);
    }

    open(isManual) {
      if (this.isOpen) {
        return;
      }
      isManual = isManual === undefined ? true : isManual; // Default value true
      this.isOpen = true;
      // Update tooltip content with HTML attribute options
      this.options = $.extend({}, this.options, this._getAttributeOptions());
      this.movementInterval = setInterval(this._handleScreenMovement.bind(this),500);
      this._updateTooltipContent();
      this._setEnterDelayTimeout(isManual);
    }

    close(immediate) {
      if (!this.isOpen) {
        return;
      }

      this.isHovered = false;
      this.isFocused = false;
      this.isOpen = false;
      clearInterval(this.movementInterval);
      this._setExitDelayTimeout(immediate);
    }

    /**
     * Create timeout which delays when the tooltip closes
     */
    _setExitDelayTimeout(immediate) {
      clearTimeout(this._exitDelayTimeout);

      this._exitDelayTimeout = setTimeout(() => {
        if (this.isHovered || this.isFocused) {
          return;
        }

        this._animateOut();
      }, immediate ? 0 : this.options.exitDelay);
    }

    /**
     * Create timeout which delays when the toast closes
     */
    _setEnterDelayTimeout(isManual) {
      clearTimeout(this._enterDelayTimeout);

      this._enterDelayTimeout = setTimeout(() => {
        if (!this.isHovered && !this.isFocused && !isManual) {
          return;
        }

        this._animateIn();
      }, isManual ? 0 : this.options.enterDelay);
    }

    _getTargCoords() {
      let origin = this.el,
      tooltip = this.tooltipEl,
      originHeight = origin.offsetHeight,
      originWidth = origin.offsetWidth,
      tooltipHeight = tooltip.offsetHeight,
      tooltipWidth = tooltip.offsetWidth,
      margin = this.options.margin,
      targetTop,
      targetLeft;

      targetTop = origin.getBoundingClientRect().top + M.getDocumentScrollTop();
      targetLeft = origin.getBoundingClientRect().left + M.getDocumentScrollLeft();

      if (this.options.position === 'top') {
        targetTop += -tooltipHeight - margin;
        targetLeft += originWidth / 2 - tooltipWidth / 2;
      } else if (this.options.position === 'right') {
        targetTop += originHeight / 2 - tooltipHeight / 2;
        targetLeft += originWidth + margin;
      } else if (this.options.position === 'left') {
        targetTop += originHeight / 2 - tooltipHeight / 2;
        targetLeft += -tooltipWidth - margin;
      } else {
        targetTop += originHeight + margin;
        targetLeft += originWidth / 2 - tooltipWidth / 2;
      }

      return { ...this._repositionWithinScreen(
        targetLeft,
        targetTop,
        tooltipWidth,
        tooltipHeight
      ), targetTop, targetLeft };
    }

    _positionTooltip() {
      let tooltip = this.tooltipEl,
        arrow = this.arrowEl,
        tooltipHeight = tooltip.offsetHeight,
        tooltipWidth = tooltip.offsetWidth,
        newCoordinates,
        arrowOffset;

        arrow.classList.remove('point-up','point-down','point-left','point-right');
      this.xMovement = 0;
      this.yMovement = 0;

      if (this.options.position === 'top') {
        this.yMovement = -this.options.transitionMovement;
        arrow.classList.add('point-down');
      } else if (this.options.position === 'right') {
        this.xMovement = this.options.transitionMovement;
        arrow.classList.add('point-left');
      } else if (this.options.position === 'left') {
        this.xMovement = -this.options.transitionMovement;
        arrow.classList.add('point-right');
      } else {
        this.yMovement = this.options.transitionMovement;
        arrow.classList.add('point-up');
      }

      newCoordinates = this._getTargCoords();
      $(tooltip).css({
        top: newCoordinates.y + 'px',
        left: newCoordinates.x + 'px'
      });

      if (this.options.position === 'right' || this.options.position === 'left') {
        arrowOffset = newCoordinates.targetTop - newCoordinates.y;
        let newTop = Math.max(Math.min(tooltipHeight / 2 + arrowOffset, tooltipHeight),0);
        $(arrow).css({ top: newTop + 'px'});
      } else {
        arrowOffset = newCoordinates.targetLeft - newCoordinates.x;
        let newLeft = Math.max(Math.min(tooltipWidth / 2 + arrowOffset,tooltipWidth),0);
        $(arrow).css({ left: newLeft + 'px'});
      }
    }

    _repositionWithinScreen(x, y, width, height) {
      let scrollLeft = M.getDocumentScrollLeft();
      let scrollTop = M.getDocumentScrollTop();
      let newX = x - scrollLeft;
      let newY = y - scrollTop;

      let bounding = {
        left: newX,
        top: newY,
        width: width,
        height: height
      };

      let offset = this.options.margin + this.options.transitionMovement;
      let edges = M.checkWithinContainer(document.body, bounding, offset);

      if (edges.left) {
        newX = offset;
      } else if (edges.right) {
        newX -= newX + width - window.innerWidth;
      }

      if (edges.top) {
        newY = offset;
      } else if (edges.bottom) {
        newY -= newY + height - window.innerHeight;
      }

      return {
        x: newX + scrollLeft,
        y: newY + scrollTop
      };
    }

    _animateIn() {
      this._positionTooltip();
      this.tooltipEl.style.visibility = 'visible';
      this.tooltipEl.style.removeProperty('pointer-events');
      anim.remove(this.tooltipEl);
      anim({
        targets: this.tooltipEl,
        opacity: 1,
        translateX: this.xMovement,
        translateY: this.yMovement,
        duration: this.options.inDuration,
        easing: 'easeOutCubic'
      });
    }

    _animateOut() {
      anim.remove(this.tooltipEl);
      this.tooltipEl.style.pointerEvents = 'none';
      anim({
        targets: this.tooltipEl,
        opacity: 0,
        translateX: 0,
        translateY: 0,
        duration: this.options.outDuration,
        easing: 'easeOutCubic'
      });
    }

    _handleMouseEnter() {
      this.isHovered = true;
      this.isFocused = false; // Allows close of tooltip when opened by focus.
      this.open(false);
    }

    _handleMouseClick() {
      this.wasClicked = true;
      this.open(true);
    }

    _handleMouseLeave() {
      this.isHovered = false;
      this.isFocused = false; // Allows close of tooltip when opened by focus.
      const immediate = this.wasClicked;
      this.wasClicked = false;
      this.close(immediate);
    }

    _handleFocus() {
      if (M.tabPressed) {
        this.isFocused = true;
        this.open(false);
      }
    }

    _handleBlur() {
      this.isFocused = false;
      this.close();
    }

    _handleScreenMovement() {
      const { x: targLeft, y: targTop } = this._getTargCoords();
      const curLeft = Number(this.tooltipEl.style.left.replace('px',''));
      const curTop = Number(this.tooltipEl.style.top.replace('px',''));
      if(Math.abs(curLeft - targLeft) >= 15 || Math.abs(curTop - targTop) >= 15) {
        this.close(true);
      }
    }

    _getAttributeOptions() {
      let attributeOptions = {};
      let tooltipTextOption = this.el.getAttribute('data-tooltip');
      let positionOption = this.el.getAttribute('data-position');

      if (tooltipTextOption) {
        attributeOptions.html = tooltipTextOption;
      }

      if (positionOption) {
        attributeOptions.position = positionOption;
      }
      return attributeOptions;
    }
  }

export default Tooltip;
