import React from 'react';
import { useTranslation } from 'react-i18next';
import { allMainMatches, clientDetailsMatch, viewLogsPath, viewMealPathMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPageWithTabs } from 'components/Page';
import ClientSwitcher from 'partials/ClientSwitcher';
import { getWodLogSelector, getWodSelector, loggedMacrosForDateSel, trainerRecordSelector, userRecordSelector, viewLogsDateSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { SimpleActivityTabs } from 'components/BottomNav';
import { loadLogs, setViewLogsDate } from 'redux/actions';
import { dateFormat } from 'config/settings';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'components/LoadingHOC';
import ActivityContainer from 'components/ActivityContainer';
import { ConnectedOverviewCard } from './ClientDetails';
import { MainHeading, SlightEmphasisIconNote } from 'components/Typography';
import { ConnectedFoodLogTable, ConnectedWeightLog, HabitsSection } from './Today';
import { MacroPieChartCard } from 'partials/MacroPieChart';
import { WorkoutLogCore } from './WorkoutLog';
import * as _ from 'lib/utilities';
import moment from 'moment';
import DatePicker from 'components/DatePicker';
import { useHistory, useLocation } from 'react-router-dom';
import Tooltipped from 'components/Tooltipped';
import ActivityLogList from 'partials/ActivityLogList';

const pickDatePath = '/pick_date';

const transitionMap = {
    rules: [
        [[ ...allMainMatches, clientDetailsMatch ],transitions.slideOut],
        [[viewMealPathMatch], transitions.slideOver]
    ]
};

export const RatingButton = ({ rating }) => {
    const { t } = useTranslation();
    const msg = t("difficulty rating desc", { rating: rating+1 });

    return (
        <Tooltipped 
            key={msg}
            options={{ 
                html: msg,
                classes: ['square']
            }}
            component='div'
            className={`wo-wt-test-btns color-${rating+1} non-interactive`}
        >
        </Tooltipped>
    )
}

let WorkoutLogs = ({ workouts, scheduledWorkout, date }) => {
    const { t } = useTranslation();
    const finalWorkouts = _.filter(workouts,workout => workout.isAnyLogged());
    const showScheduledMessages = scheduledWorkout && !finalWorkouts.map(w => w.id).includes(scheduledWorkout.id);
    const loggedOnOtherDate = scheduledWorkout && scheduledWorkout.logDate && scheduledWorkout.isAnyLogged();

    return (
        <div className="col s12 m12 l6">
            <MainHeading>
                {t('Workout Log')}
            </MainHeading>
            {finalWorkouts.length === 0 && !scheduledWorkout && (
                <div className="mb20" id="rest-day-msg"><SlightEmphasisIconNote text={t("no logs rest day")} /></div>
            )}
            {showScheduledMessages && loggedOnOtherDate && (
                <div className="mb20" id="logged-on-other-day-msg">
                    <SlightEmphasisIconNote text={t("scheduled workout logged date",{ name: scheduledWorkout.displayName(t), date: moment(scheduledWorkout.logDate).format('ddd MMM Do')})} variant="success" />
                </div>
            )}
            {showScheduledMessages && !loggedOnOtherDate && (
                <div className="mb20" id="not-logged-msg">
                    <SlightEmphasisIconNote text={t("scheduled workout not logged",{ name: scheduledWorkout.displayName(t) })} variant="error" />
                </div>
            )}
            {finalWorkouts.map(workout => {

                return (
                    <div className="mb25" key={workout.id}>
                        <div className="wod-title for-log-view">
                            {workout.displayName(t)}
                            {!_.isBlank(workout.difficultyRating) && (<RatingButton rating={workout.difficultyRating} />)}
                        </div>
                        <WorkoutLogCore workout={workout} t={t} readOnly tableOnly basePath={viewLogsPath} />
                    </div>
                )
            })}
            <MainHeading>
                {t('Activity Log')}
            </MainHeading>
            <ActivityLogList 
                date={date}
                readOnly
                showEmptyMsg
            />
        </div>
    )
}

const mapStateToWorkoutLogProps = (state,props) => {
    const wodLogsSelector = getWodLogSelector(props.date.format(dateFormat));
    const wodSelector = getWodSelector(props.date.format(dateFormat));

    return {
        workouts: wodLogsSelector(state,props),
        scheduledWorkout: wodSelector(state,props)
    }
}

WorkoutLogs = connect(mapStateToWorkoutLogProps)(WorkoutLogs);

let MacroChartCard = ({ user,date,macroHash }) => {

    if(macroHash) {
        return <MacroPieChartCard macroHash={macroHash} />
    }

    return '';
}

const mapStateToMacroCardProps = (state,props) => ({
    macroHash: loggedMacrosForDateSel(state,props)
})

MacroChartCard = connect(mapStateToMacroCardProps)(MacroChartCard);

const FoodLogs = ({ user, date, targets }) => {
    const { t } = useTranslation();
    const habitLogs = user.habitLogsByDate(date);
    const hasHabits = (user.hasAnyAssignedHabits() || habitLogs.length > 0);

    return (
        <div className="col s12 m12 l6">
            <div className="mb25">
                <MainHeading>
                    {t('Food Log')}
                </MainHeading>
                <ConnectedOverviewCard noWorkout date={date} square user={user} targets={targets} />
                <ConnectedFoodLogTable user={user} date={date} readOnly targets={targets} />
                <MacroChartCard user={user} date={date} />
            </div>
            <div className="mb25">
                <ConnectedWeightLog date={date} user={user} t={t} />
            </div>
            {hasHabits && (<div className="mb25">
                <HabitsSection habitLogs={habitLogs} date={date} readOnly />
            </div>)}
        </div>
    )
}

const ViewLogsCore = ({ user, date, responseData: { targets }}) => {

    return (
        <ActivityContainer>
            <FoodLogs user={user} date={date} targets={targets} />
            <WorkoutLogs user={user} date={date} />
        </ActivityContainer>
    )
}

const DatePickerHeader = ({ date, setDate }) => {
    const history = useHistory();
    const location = useLocation();
    const pickerPath = `${location.pathname.replace(pickDatePath,'')}${pickDatePath}`

    return (
        <SimpleActivityTabs>
            <div className="tab-header">
                <div className="pr15 clickable inline-block" id="toggle-yesterday" onClick={() => setDate(date.clone().subtract(1,'day').format(dateFormat))}>
                    <FontAwesomeIcon icon="chevron-left" />
                </div>
                <div style={{ width: '150px', display: 'inline-block', cursor: 'pointer', userSelect: 'none' }} onClick={() => history.push(pickerPath)} id="date-pick-btn">
                    <FontAwesomeIcon icon="calendar-alt" /> {date.format('ddd MMM Do')}
                </div>
                <div className="pl15 clickable inline-block" id="toggle-tomorrow" onClick={() => setDate(date.clone().add(1,'day').format(dateFormat))}>
                    <FontAwesomeIcon icon="chevron-right" />
                </div>
            </div>
        </SimpleActivityTabs>
    )
}

let ViewLogsShortcut = (props) => {
    const { date, setDate } = props;
    const { t } = useTranslation();

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('View Logs')} tabs={<DatePickerHeader date={date} setDate={setDate} />} afterPath={viewLogsPath} />
    )
}

const ViewLogsPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, setActiveTab, activeTab, user, trainer, date, setDate, loadLogs } = props;
    const isViewingClient = trainer && trainer.id !== user.id;
    const dateStr = date.format(dateFormat);

    return (
        <SimpleNavPageWithTabs
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={isViewingClient ? t('names Logs', { name: user.shortName() }) : t('View Logs')}
            tabs={<DatePickerHeader date={date} setDate={setDate} />}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        >
            <Loader 
                key={dateStr}
                successComponent={ViewLogsCore}
                load={() => loadLogs(dateStr)}
                preloaded={() => false}
                alwaysLoad
                type="page"
                {...props}
            />
            <DatePicker 
                suffix={pickDatePath}
                name="date"
                setFieldValue={(name,val) => setDate(val)}
                options={ { defaultDate: date.toDate() } }
            />
        </SimpleNavPageWithTabs>
    )
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state),
    user: userRecordSelector(state),
    date: viewLogsDateSelector(state)
})

const mapDispatchToProps = dispatch => ({
    setDate: date => dispatch(setViewLogsDate(date)),
    loadLogs: date => dispatch(loadLogs(date))
})

ViewLogsShortcut = connect(mapStateToProps,mapDispatchToProps)(ViewLogsShortcut)

export { ViewLogsShortcut }

export default connect(mapStateToProps,mapDispatchToProps)(ViewLogsPage)