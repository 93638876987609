import { RecordBase, registerInflection } from 'lib/record-base';
import { User } from 'lib/user';
import * as _ from 'lib/utilities';

export class ExerciseSetting extends RecordBase {
    static NAME = 'ExerciseSetting';
    static ASSOCS = {
        user: { type: 'belongsTo' },
        exercise: { type: 'belongsTo' }
    }

    static unitsForForm(exercise,t) {
        const strs = exercise.needsDumbellInfo() ? { lbs: t("units per dumbbell",{units: t('lbs')}), kgs: t("units per dumbbell",{units: t('kgs')}) } : { lbs: t('lbs'), kgs: t('kgs')}
        return [{ text: strs.lbs, value: User.IMPERIAL},{ text: strs.kgs, value: User.METRIC }] 
    }

    static overrideTypeForForm(exercise,t) {
        return [{ text: t("for all equipment exercises", { equipment: (exercise.equipmentName || '').toLowerCase() }), value: ''},{ text: "only for this exercise", value: 1}]
    }

    isOverride() {
        return this.mincrementType === 1;
    }

    isMetric() {
        return this.unit === User.METRIC;
    }

    unitMincrement() {
        if(_.isBlank(this.mincrement)) {
            return null;
        } else if(this.isMetric()) {
            return this.mincrements/2.2;
        } else {
            return this.mincrement;
        }
    }

}

registerInflection('exerciseSetting','exerciseSettings',ExerciseSetting);