import React from 'react';
import Card from 'components/Card';
import { DismissCardButton, CardImageWithPlaceholder } from 'components/Card';
import workoutSignupM from 'assets/img/WorkoutSignupM.png';
import workoutSignupF from 'assets/img/WorkoutSignupF.png';
import LinkButton from 'components/LinkButton';
import { workoutSetupPath, mealPlanSetupPath, setupWeeklyPlanPath, trainerBrandingPathFor, viewAssignedFormPathFor, trainerFormsPath, modalPathFor } from 'config/paths';
import { useTranslation } from 'react-i18next';
import mealPlanSignup from 'assets/img/MealPlanSignup.png';
import { hasSingleDayMealsSel, trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { NUTRITION, TRAINING } from 'config/settings';
import { APPLE_HEALTH_SYNC_TIP, SETUP_BRANDING_TIP, SETUP_FORMS_TIP } from 'config/tooltips';
import { dismissTooltip } from 'redux/actions';
import { LinkButtonTPB } from './ProRequiredButton';
import brandingPhone from 'assets/img/brandingPhone.webp';
import formsPhone from 'assets/img/formsPhone.webp';
import applehealth from 'assets/img/applehealth.png';
import * as _ from 'lib/utilities';
import Button from 'components/Button';
import { healthkitRequestAuth } from 'redux/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import { MainHeading } from 'components/Typography';
import { notificationSettingsSuffix } from './NotificationSettings';
import { useLocation } from 'react-router-dom';

const ClientFormListingCard = ({ form, t }) => {
    const cardIcon = form.cardIcon(true);

    return (
        <ListCard className="mb5 cli-form-card" id={`cli-form-${form.id}`}>
            <ListCardAction className="inactive">
                <FontAwesomeIcon icon={cardIcon} className={cardIcon === 'check' ? 'success-color' : ''} />
            </ListCardAction>
            <ListCardTitle withTags linkTo={viewAssignedFormPathFor(form.id)}>
                {form.resolvedName(t)}
            </ListCardTitle>
        </ListCard>
    )
}

export const ClientFormCards = ({ forms }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {forms.map(f => (<ClientFormListingCard key={f.id} form={f} t={t} />))}
        </React.Fragment>
    )
}

export const ClientFormsTodoSection = ({ forms }) => {
    const { t } = useTranslation();

    return (
        <div id="client-forms-section">
            <MainHeading>{t('TODO Items')}</MainHeading>
            <ClientFormCards forms={forms} />
        </div>
    )
}

export const WorkoutPlanSetupCard = ({ user, dismissSetupCard, ...rest }) => {
    const { t } = useTranslation();
    
    return (
        <Card {...rest} id="workout-plan-setup-card">
            {user.mealPlanInitialized() && dismissSetupCard && <DismissCardButton onClick={() => dismissSetupCard('setup_workout_card')} />}
            <CardImageWithPlaceholder src={user.female() ? workoutSignupF : workoutSignupM} noContent width={500} height={334} />
            <LinkButton rounded color="workout-setup" className="today-page-setup" to={workoutSetupPath}>
                {t("Set Up Workout Plan")}
            </LinkButton>
        </Card>
    )
}


let MealPlanSetupCard = ({ user, dismissSetupCard, hasSingleDayMeals, dispatch, ...rest }) => {
    const { t } = useTranslation();
    return (
        <Card {...rest} id="meal-plan-setup-card">
            {user.workoutPlanInitialized() && dismissSetupCard && <DismissCardButton onClick={() => dismissSetupCard('setup_meal_plan_card')} />}
            <CardImageWithPlaceholder src={mealPlanSignup} noContent width={500} height={334} />
            <LinkButton rounded color="meal-plan-setup" className="today-page-setup" to={hasSingleDayMeals ? setupWeeklyPlanPath : mealPlanSetupPath} id="meal-plan-setup-btn">
                {hasSingleDayMeals ? t("Set Up Weekly Plan") : t("Set Up Meal Plan")}
            </LinkButton>
        </Card>
    )
}

const StandaloneSetupSection = ({ user, type }) => {
    const { t } = useTranslation();
    const forms = user.getClientDashFormsOn(moment());

    return (
        <CenteredActivityContainer>
                <h3 className="conversion-heading ml0 mb0 mt100">{forms.length > 0 ? t('TODO Items') : t("Let's Get Started")}</h3>
                {forms.length > 0 && (<ClientFormCards forms={forms} />)}
                {type === TRAINING && <WorkoutPlanSetupCard user={user}/>}
                {type === NUTRITION && <MealPlanSetupCard user={user} />}
        </CenteredActivityContainer>
    )
}

let BrandingSetupCard = ({ dismissTip }) => {
    const { t } = useTranslation();
    return (
        <Card style={{ overflow: 'hidden'}}>
            <DismissCardButton onClick={() => dismissTip(SETUP_BRANDING_TIP)} />
            <CardImageWithPlaceholder src={''} noContent width={500} height={200} />
            <img src={brandingPhone} className="branding-phone" alt=""/>
            <div className="bcard-logo-cont">
                <LinkButtonTPB type={null} rounded noShadow color="meal-plan-setup" id="setup-branding-btn" to={trainerBrandingPathFor(1)} allowUninit={false}>
                    {t('Set Up Your Branding')}
                </LinkButtonTPB>
            </div>

        </Card>
    )
}

let FormsSetupCard = ({ dismissTip }) => {
    const { t } = useTranslation();
    return (
        <Card style={{ overflow: 'hidden'}}>
            <DismissCardButton onClick={() => dismissTip(SETUP_FORMS_TIP)} />
            <CardImageWithPlaceholder src={''} noContent width={500} height={200} />
            <img src={formsPhone} className="branding-phone" alt=""/>
            <div className="bcard-logo-cont">
                <LinkButtonTPB type={null} rounded noShadow color="workout-plan-setup" id="setup-forms-btn" to={trainerFormsPath} allowUninit={false}>
                    {t("Set Up Forms, Check-ins & Assessments")}
                </LinkButtonTPB>
            </div>

        </Card>
    )
}

let NotificationsSetupCard = ({ user }) => {
    const { t } = useTranslation();
    const { pathname }= useLocation();

    return (
        <Card>
            <div className="center info-color pt15 pb15">
                <div style={{ fontSize: '100px', lineHeight: 1}}>
                    <FontAwesomeIcon icon="info-circle" />
                </div>
            </div>
            <div className="text-center limit-50-h pa20">
                <MainHeading>{t('Notification Setup')}</MainHeading>
                <div>{t('notification tip')}</div>
                <div className="pa20">
                    <LinkButton 
                        color="primary" 
                        noShadow 
                        rounded 
                        to={modalPathFor(notificationSettingsSuffix,pathname)}
                    >
                        <FontAwesomeIcon icon="check" />
                        {t("Finish Setup")}
                    </LinkButton>
                </div>
            </div>
        </Card>
    )
}

let AppleHealthSyncCardCore = ({ user, trainer, dismissTip }) => {
    const { t } = useTranslation();

    if(_.signedInAsClient(trainer,user)) {
        return '';
    }

    return (
        <Card className="app-icon-card" style={{ overflow: 'hidden'}}>
            <DismissCardButton onClick={() => dismissTip(APPLE_HEALTH_SYNC_TIP)} />
            <div className="cont">
                <div className="icon-cont">
                    <img src={applehealth} className="app-icon" alt="Apple Health icon"/>
                </div>
                <div className="btn-cont text-center">
                    <Button noShadow rounded color="primary" outlined id="connect-healthkit-btn" onClick={() => healthkitRequestAuth(() => dismissTip(APPLE_HEALTH_SYNC_TIP),() => alert("Couldn't sync with Apple Health"))} allowUninit={false}>
                        <span className="small-screen-disappear"><FontAwesomeIcon icon="link" /></span>
                        {t('Connect Apple Health')}
                    </Button>
                </div>
                <div className="x-holder"></div>
            </div>
        </Card>
    )
}

class AppleHealthSyncCard extends React.Component {
     constructor(props) {
        super(props);

        this.state = { show: false };

        if(_.hasAppleHealth()) {
            navigator.health.isAvailable(this.showCard);
        }
     }

     showCard = result => this.setState({ show: result });

     render() {

        if(this.state.show) {
            return (<AppleHealthSyncCardCore {...this.props} />);
        }
        
        return '';
     }
}

export const WorkoutPlanStandaloneSetupSection = ({ user }) => (<StandaloneSetupSection user={user} type={TRAINING} />)
export const MealPlanStandaloneSetupSection = ({ user }) => (<StandaloneSetupSection user={user} type={NUTRITION} />)

const mapDispatchToBrandProps = dispatch => ({
    dismissTip: tipName => dispatch(dismissTooltip(tipName))
})

const mapStateTpAppleProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

BrandingSetupCard = connect(null,mapDispatchToBrandProps)(BrandingSetupCard);
FormsSetupCard = connect(null,mapDispatchToBrandProps)(FormsSetupCard);
AppleHealthSyncCard = connect(mapStateTpAppleProps,mapDispatchToBrandProps)(AppleHealthSyncCard);

const mapStateToMpSetupProps = state => ({
    hasSingleDayMeals: hasSingleDayMealsSel(state)
})

MealPlanSetupCard = connect(mapStateToMpSetupProps)(MealPlanSetupCard)

export { MealPlanSetupCard, BrandingSetupCard, AppleHealthSyncCard, FormsSetupCard, NotificationsSetupCard }