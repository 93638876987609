import React from 'react';
import { connect } from 'react-redux';
import { autoplaySelector, muxUploadProgs, trainerSelector, userSelector, videoUploadsSel } from 'redux/selectors';
import videoPoster from 'assets/img/video_poster.png';
import NoImage from 'assets/img/NoImageExercise.png';
import PlaceholderImage from 'components/PlaceholderImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';
import Button, { AsyncSpinnerButton } from 'components/Button';
import { useTranslation, withTranslation } from 'react-i18next';
import * as Hls from 'hls.js';
import classnames from 'classnames';
import { cancelMuxUpload, loadMuxRecord, restartMuxUpload } from 'redux/actions';
import { FileGrabber } from 'components/Form';

const VideoError = ({ retry }) => {
    const { t } = useTranslation();

    return (
        <div style={{ 
            width: '100%', 
            height: '100%', 
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center', 
            position: 'absolute', 
            top: '0', 
            left: '0', 
            color: 'white',
            lineHeight: '1'
        }}>
            <div className="text-center">
                <div className="mb10"><FontAwesomeIcon icon="times" className="error-color" />{t('Error loading video. Check your internet connection.')}</div>
                {retry && (<div>
                    <Button color="primary" outlined rounded noShadow onClick={retry}>{t('Try Again')}</Button>
                </div>)}
            </div>
        </div>
    )
}

const ResumeUploadBtn = ({ resumeVideoUpload, uploadInfo, t }) => {
    const { recordId, recordClass, url } = uploadInfo;

    return (
        <FileGrabber 
            accept={'video/mp4,video/x-m4v,video/*'}
            name="resume"
            fileHandler={(file) => resumeVideoUpload({ recordId, recordClass, file, url })}
            label={<React.Fragment><FontAwesomeIcon icon="cloud-upload-alt" /> {t('Retry')}</React.Fragment>}
            idOverride={`resume-${recordId}-btn`}
            flat
            btnClasses="white-text"
            className={'inline-block ml5'}
        />
    )
}

const CancelUploadBtn = ({ exercise, cancelMuxUpload, label }) => {

    return (
        <AsyncSpinnerButton
            buttonProps={{
                noShadow: true,
                variant: 'flat',
                color: 'white',
                label,
                id: `cancel-${exercise.id}-upload-btn`,
            }}
            icon={'times'}
            action={() => cancelMuxUpload({ id: exercise.id, type: exercise.constructor.NAME })}
        />
    )
}

class ExerciseMuxInProcess extends React.Component {

    componentDidMount() {
        const { exercise, loadRecord } = this.props;
        
        this.loadIntv = setInterval(() => {
            loadRecord(exercise);
        },5000)
    }

    render() {
        const { exercise, uploadProgs, videoUploads, user, trainer, cancelMuxUpload, resumeVideoUpload, t } = this.props;
        const personalUser = trainer || user;
        const isOwned = personalUser && exercise.videoUploadableBy(personalUser);
        const pct = uploadProgs && uploadProgs[`${exercise.constructor.NAME}|${exercise.id}`];
        const uploadInfo = videoUploads[exercise.muxAssetId];
        const isFailed = exercise.muxPlaybackId === 'failed';
        const isInterrupted = (_.isBlank(pct) || isFailed) && !_.isBlank(uploadInfo);
        const isProcessing = exercise.muxPlaybackId === 'processing';

        let msg = `${t('Video is uploading')} ${pct ? pct : ''}${pct ? '%' : ''}`;
        if(isInterrupted) {
            msg = t('Video upload interrupted');
        } else if(isProcessing) {
            msg = t('Video is processing');
        } else if(isFailed) {
            msg = t('Video failed to upload');
        }

        return (
            <div className="ex-vid-parent">
                <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
                <div className="vid-upload-txt">
                    <div className={classnames({"mb20": (isOwned || isInterrupted)})}>
                        {(isInterrupted || isFailed) && <FontAwesomeIcon icon="times" color="red" />} {msg}
                    </div>
                    <div className="text-center">
                        {isOwned && <CancelUploadBtn exercise={exercise} label={isFailed ? t('Ok') : t('Cancel')} cancelMuxUpload={cancelMuxUpload} />}
                        {isInterrupted && (<ResumeUploadBtn resumeVideoUpload={resumeVideoUpload} uploadInfo={uploadInfo} t={t} />)}
                    </div>
                </div>
            </div>
        )
    }

    componentWillUnmount() {
        clearInterval(this.loadIntv);
    }
}

const mapStateToInProcessProps = (state) => ({
    uploadProgs: muxUploadProgs(state),
    videoUploads: videoUploadsSel(state),
    user: userSelector(state),
    trainer: trainerSelector(state)
})

const mapDispatchToInProcessProps = dispatch => ({
    cancelMuxUpload: params => dispatch(cancelMuxUpload(params)),
    resumeVideoUpload: params => dispatch(restartMuxUpload(params)),
    loadRecord: record => dispatch(loadMuxRecord(record))
})

const ExerciseMuxInProcessCon = connect(mapStateToInProcessProps,mapDispatchToInProcessProps)(withTranslation()(ExerciseMuxInProcess));
class ExerciseMux extends React.Component {

    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.state = { videoLoaded: false, showLoadingMsg: false, showFailedMsg: false };
        this.loadingMsgTimeout = setTimeout(() => this.setState({ showLoadingMsg: true }), 1500);
    }

    componentDidMount() {
        const vid = this.videoRef.current;

        if(vid && Hls.isSupported()) {
            const hls = new Hls();
            hls.loadSource(vid.src);
            hls.attachMedia(vid);
        }
    }

    render() {
        const { exercise, fixedAspect } = this.props;
        const { videoLoaded, showLoadingMsg, showFailedMsg } = this.state;
        const showPlaceholder = (fixedAspect || !videoLoaded);

        if(exercise.muxPlaybackId === 'uploading' || exercise.muxPlaybackId === 'failed' || exercise.muxPlaybackId === 'processing') {
            return (<ExerciseMuxInProcessCon exercise={exercise} />)
        }

        if(showFailedMsg) {
            return (
                <div className="ex-vid-parent">
                    <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
                    <div className="vid-upload-txt">
                        <FontAwesomeIcon icon="times" color="red" />
                        Video failed to load
                    </div>
                </div>
            )
        }
        
        return (
            <div className={classnames("ex-vid-parent", { 'limit-height': fixedAspect })}>
                {showPlaceholder && (<PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />)}
                {!videoLoaded && showLoadingMsg && (<div className="vid-upload-txt">Loading video...</div>)}
                <video 
                    src={exercise.muxVideoUrl()} 
                    poster={exercise.muxPosterUrl()} 
                    ref={this.videoRef} 
                    controls 
                    className={classnames("mux-vid",{"no-placeholder": !showPlaceholder, 'hidden': !videoLoaded})} 
                    onLoadedMetadata={this.markVideoLoaded} 
                    onError={this.showFailedMsg} 
                />
            </div>
        )
    }

    markVideoLoaded = () => this.setState({ videoLoaded: true });

    showFailedMsg = () => this.setState({ showFailedMsg: true });

    componentWillUnmount() {
        clearTimeout(this.loadingMsgTimeout);
    }
}

class ExerciseMP4 extends React.Component {

    constructor(props) {
        super(props);
        const { autoplay, exercise } = props;
        this.state = { exercise: exercise, isPlaying: autoplay, hasError: false };
        this.autoPlayProps = autoplay ? { autoPlay: true, playsInline: true } : {};
        this.needsOverlay = !autoplay;
        this.videoRef = React.createRef();
    }

    componentDidMount() {
        this.videoRef.current.setAttribute('muted', 'true')
        this.videoRef.current.muted = true;
    }

    render() {
        const { exercise, isPlaying, hasError } = this.state;
        
        return (
            <div className="ex-vid-parent clickable" onClick={() => !hasError && this.togglePlayState()}>
                <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
                {!hasError && (<video src={exercise.image.url} {...this.autoPlayProps} loop muted poster={videoPoster} ref={this.videoRef} onError={() => this.setState({hasError: true})}  />)}
                {this.needsOverlay && !hasError && (<img src={exercise.image.hqThumb.url} alt="" />)}
                {!isPlaying && !hasError && (<div className="video-play-btn"><FontAwesomeIcon icon="play-circle" /></div>)}
                {hasError && (<VideoError retry={() => this.setState({hasError: false})} />)}
            </div>
        )
    }

    togglePlayState() {
        if(this.videoRef.current.paused) {
            this.videoRef.current.play();
            this.needsOverlay = false;
            this.setState({isPlaying: true});
        } else {
            this.videoRef.current.pause();
            this.setState({isPlaying: false});
        }
    }
}


export class ExerciseFullVideo extends React.Component {

    constructor(props) {
        super(props);
        this.state = { onLine: navigator.onLine };
        this.onLineHandler = this.onLineHandler.bind(this);
    }

    componentDidMount() {
        window.addEventListener('online',this.onLineHandler);
    }

    render() {
        const { exercise } = this.props;
        const { onLine } = this.state;

        return (
            <div className="ex-vid-parent">
                <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
                {onLine && (<iframe type="text/html" title="Full Exercise Video"
                          src={`https://www.youtube.com/embed/${exercise.youtubeId()}?start=${exercise.youtubeVideoStart()}`}
                          frameBorder="0" 
                          allowFullScreen
                ></iframe>)}
                {!onLine && (<VideoError />)}
            </div>
        )
    }

    onLineHandler() {
        this.setState({onLine: true});
    }
}

const ExerciseImage = ({ exercise, blankMessage, videoOnly }) => {
    //this won't work well except for models (like Exercise) where the image is normalized to the correct dimensions
    //in other cases the placeholder will sit there looking weird

    return (
        <div className="ex-vid-parent">
            <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
            {!(videoOnly || blankMessage) && (<img src={(!exercise.image || _.isBlank(exercise.image.url)) ? NoImage : exercise.image.url}  alt="" />)}
            {blankMessage && (
                <div className="vid-upload-txt">
                    {blankMessage}
                </div>
            )}
        </div>
    )
}

let ExerciseVideo = ({ exercise, autoplay, supportsAutoplay, fallbackToYT, fixedAspect, blankMessage, videoOnly }) => {

    const autoplayProp = (autoplay && supportsAutoplay);

    if(exercise.hasMuxVideo()) {
        return (<ExerciseMux exercise={exercise} key={exercise.muxPlaybackId} fixedAspect={fixedAspect} />)
    } else if(exercise.hasMp4()) {
        return (<ExerciseMP4 exercise={exercise} autoplay={autoplayProp} />)
    } else if(fallbackToYT && exercise.hasYouTubeVideo()) {
        return (<ExerciseFullVideo exercise={exercise} />)
    } else {
        return (<ExerciseImage exercise={exercise} blankMessage={blankMessage} videoOnly={videoOnly} />)
    }
}

const mapStateToProps = state => ({
    supportsAutoplay: autoplaySelector(state)
})

ExerciseVideo = connect(mapStateToProps)(ExerciseVideo);

export { ExerciseVideo };