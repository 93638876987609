import React from 'react';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { SlightEmphasisIconNote } from 'components/Typography';
import { ListCard, ListCardTitle, ListCardActionLink, AsyncCardAction } from 'components/List';
import { BottomButtons } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clientBasicProfileMatch, clientDetailsMatch, generateNewWeekMatch, modalPathFor, standaloneMealPlanMatch, standaloneMealPlanPathFor, switchForGenerateMatch, trainerMainMatches } from 'config/paths';
import { trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { loadClient, updateMpInfoStub, publishMealPlan } from 'redux/actions';
import { SimpleNavPage } from 'components/Page';
import { HideOnExit } from 'components/RouteTransitionMap';
import { Loader } from 'components/LoadingHOC';
import { MealPlanQuotaHitModal, mealPlanQuotaModalSuffix } from 'partials/RecipePickFlowPage';
import LinkButton from 'components/LinkButton';
import { useLocation } from 'react-router-dom';
import { dateFormat } from 'config/settings';
import * as transitions from 'assets/transitions';
import { withTranslation } from 'react-i18next';
import withTrainer from 'partials/TrainerLoader';

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [[clientDetailsMatch,clientBasicProfileMatch], transitions.slideOut],
        [[generateNewWeekMatch,switchForGenerateMatch,standaloneMealPlanMatch],transitions.slideOver]
    ]
};

const MealPlansListing = ({ client, name, startDate, publish, t }) => {
    const isPublished = client.isMealPlanPublished(startDate);
    const cliCreated = client.isMealPlanClientCreated(startDate);

    return (
        <ListCard>
            <ListCardTitle linkTo={standaloneMealPlanPathFor(client.id,name)}>{t('Week of date',{ date: startDate.format('ddd MMM Do') })}</ListCardTitle>
            {!isPublished && (<AsyncCardAction load={() => publish({ clientId: client.id, startDate: startDate.format(dateFormat) })} icon={'paper-plane'} label={t('Publish')} />)}
            {isPublished && (<ListCardActionLink to={standaloneMealPlanPathFor(client.id,name)}>
                <FontAwesomeIcon icon="check" className="success-color" />
                <div className="action-label">{t(cliCreated ? 'Client created' : 'Published')}</div>
            </ListCardActionLink>)}
        </ListCard>
    )
}

const NewMealPlanButton = ({ client, t }) => {
    const  { pathname } = useLocation()

    return (
        <LinkButton 
            rounded 
            className="shadow"
            id="new-mp-btn"
            to={client.createNewMealPlanPathApp() || modalPathFor(mealPlanQuotaModalSuffix,pathname)}
        >
                <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t('New')}
        </LinkButton>
    )
}

const ClientMealPlansCore = ({ client, publish, t }) => {
    const plans = client.currentMealPlansDateList();

    return (
        <CenteredActivityContainer>
            <div className="col s12">
                {plans.length === 0 && (<SlightEmphasisIconNote text={t("no current meal plans for client")} />)}
                {plans.map(({ name, startDate }) => {
                    return (
                        <MealPlansListing 
                            key={name}
                            client={client} 
                            name={name} 
                            startDate={startDate} 
                            publish={publish} 
                            t={t} 
                        />
                    )
                })}
                <BottomButtons>
                    <NewMealPlanButton 
                        client={client} 
                        t={t} 
                    />
                </BottomButtons>
            </div>
            <MealPlanQuotaHitModal />
        </CenteredActivityContainer>
    )
}

class ClientMealPlansPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, trainer, loadClient, updateMpInfo, publish, match: { params: { clientId } } } = this.props;
        const client = trainer.clientById(clientId);

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={client ? t("name's Current Meal Plans",{ name: client.firstName }) : 'Current Meal Plans'}
            >
                <HideOnExit>
                    <Loader 
                        successComponent={ClientMealPlansCore}
                        load={() => loadClient(clientId)}
                        preloaded={() => (client && client.isFullyLoaded())}
                        alwaysLoad
                        type="page"
                        client={client}
                        clientId={clientId}
                        trainer={trainer}
                        updateMpInfo={updateMpInfo}
                        publish={publish}
                        t={t}
                    />
                </HideOnExit>
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    loadClient: (id) => dispatch(loadClient(id)),
    updateMpInfo: data => dispatch(updateMpInfoStub(data)),
    publish: data => dispatch(publishMealPlan(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(withTrainer(false,'page')(ClientMealPlansPage)));