import { FullFormModal } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { DefaultSelect, FileUpload } from 'components/Form';
import { connect } from 'react-redux';
import { createProgressPhoto } from 'redux/actions';
import { useLocation } from 'react-router-dom';
import { FormField } from 'lib/classes';
import { dateFormat } from 'config/settings';
import moment from 'moment';
import progPhotoPlaceholder from 'assets/img/progPhotoPlaceholder.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ProgressPhotoModal = ({ path, submit, initialVals, title, icon }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth
            path={path}
            scrollable
            exact
            icon={icon}
            title={title}
            formProps={({ modalProps }) => {
                const vals = initialVals(modalProps);
                return {
                    initialValues: vals,
                    submit,
                    valuesFilter: (values) => ({ ...values, image: _.dataURItoBlob(values.image) }),
                    initialErrors: { image: t('Required') },
                    validate: ({ image }) => (_.isBlank(image) ? { image: t('Required') } : {})
                }
            }}
            render={({ modalProps, ...rest }) => {

                return (
                    <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="valign-wrapper mb10">
                            <DefaultSelect
                                className="flex-grow"
                                name="angle" 
                                label={t('Photo Angle')}
                                collection={FormField.photoAngles(t,'')} 
                                {...rest} 
                            />
                        </div>
                        <FileUpload
                            name={`image`}
                            setFieldValue={rest.setFieldValue}
                            defaultImageUrl={progPhotoPlaceholder}
                            btnLabel={<div className="btn-content"><FontAwesomeIcon icon={['far','camera']} /> <span>{t("Take/upload Photo")}</span></div>}
                            className="block-btn"
                            imgStyle={{ maxWidth: '100%', maxHeight: '350px' }}
                            outlined={!_.isBlank(rest.values.image)}
                            containerClass="text-center"
                        />
                    </div>
                )

            }}
        />
    )
}

export const newProgressPhotoModalSuffix = '/new';

let NewProgressPhotoModal = ({ submit }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <ProgressPhotoModal
            path={modalPathFor(newProgressPhotoModalSuffix,pathname)}
            icon="plus"
            title={t('New Progress Photo')}
            initialVals={() => ({ date: moment().format(dateFormat), image: '', angle: '' })}
            submit={submit}
            assessment={null}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(createProgressPhoto(data))
})

NewProgressPhotoModal = connect(null,mapDispatchToProps)(NewProgressPhotoModal);

export { NewProgressPhotoModal }