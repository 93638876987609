export const brandName = () => window.appBrandName;
export const logoUrl = () => window.appLogoUrl;
export const supportEmail = 'mary@strongrfastr.com';
export const dateFormat = 'YYYY-MM-DD';
export const dateFormatRegex = /^\d{3,5}-\d{2}-\d{2}$/;
export const iosRatingUrl = `itms-apps://itunes.apple.com/app/viewContentsUserReviews/id${window.iosAppId}?action=write-review`;
export const androidRatingUrl = `https://play.google.com/store/apps/details?id=${window.androidPackageName}`;
export const macroColors = {
    protein: '#3fa054',
    fat: '#FF9900',
    carbs: '#3366CC',
    fiber: '#979dac',
    alcohol: '#990099'
}
export const calRoundFactor = 50.0;
export const NUTRITION = 'nutrition';
export const TRAINING = 'training';
export const FULL = 'full';
export const CLEARED_FILTERS = { trainerId: 'me', status: 'active', setup: null, tag: null, goal: null, routineId: null, gender: null, query: ''};
export const TPAYWALL = 'trainer_paywall';
export const TSIGNUP = 'trainer_signup';
export const TSIGNUP_CONTEXTS = [TPAYWALL,TSIGNUP];
export const citations = { 
    calories: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC3880593', 
    rt: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC7068252/', 
    protein: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5470183/', 
    consistency: 'https://ajcn.nutrition.org/article/S0002-9165(23)29536-2/fulltext',
    twiceEffectMuscle: 'https://jissn.biomedcentral.com/articles/10.1186/s12970-017-0177-8',
    twiceEffectLose: 'https://pubmed.ncbi.nlm.nih.gov/28871849/',
    stokesMetabolism: 'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2931407/#!po=8.33333',
    mortalityRisk: 'https://journals.lww.com/acsm-msse/Fulltext/2018/03000/Associations_of_Muscle_Mass_and_Strength_with.8.aspx',
    intermittentFasting: 'https://pubmed.ncbi.nlm.nih.gov/35276989/',
    mentalHealth: 'https://www.bmj.com/content/384/bmj-2023-075847'
 }