import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';

export class HabitLog extends RecordBase {
    static NAME = 'HabitLog';
    static ASSOCS = {
        habit: { type: 'belongsTo' },
        user: { type: 'belongsTo' }
    }

    initialFormValues() {
        return _.pick(this,['id','logged','loggedQuantity']);
    }

    isLogged() {
        if(this.isQuantityType()) {
            if(this.loggedQuantity && this.goalQuantity && this.loggedQuantity >= this.goalQuantity) {
                return true;
            }
            return false;
        }
        return this.logged;
    }

    percentComplete() {
        return Math.min(((this.loggedQuantity || 0)/(this.goalQuantity || 1))*100,100);
    }

    isQuantityType() {
        return this.habit.isQuantityType();
    }

    isActivityGoal() {
        return this.habit.isActivityGoal();
    }

    isStepGoal() {
        return this.habit.isStepGoal();
    }

    goalTitle(t) {
        if(this.isActivityGoal()) {
            return t('Active minutes goal');
        } else if (this.isStepGoal()) {
            return t('Step goal');
        }
    }

    goalSuffix(t) {
        if(this.isActivityGoal()) {
            return t('mins');
        } else if (this.isStepGoal()) {
            return t('steps');
        }
    }

    goalIcon() {
        if(this.isActivityGoal()) {
            return 'running';
        } else if (this.isStepGoal()) {
            return 'walking';
        }
    }

    showQuantityCard(readOnly) {
        return _.isIOS() || _.isAndroid() || readOnly || this.isActivityGoal() || (!_.isBlank(this.loggedQuantity) && this.loggedQuantity > 0);
    }

}

registerInflection('habitLog','habitLogs',HabitLog);
