import React from 'react';
import * as Utils from 'lib/utilities';
import RecordPanel, { ActionBox, ContentBox } from 'components/RecordPanel';
import { TinyText, LowOpacityText } from 'components/Typography';
import Collapse from 'components/Collapse';
import { dateFormat } from 'config/settings';
import * as _ from 'lib/utilities';
import Tooltipped from 'components/Tooltipped';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const FoodHighlightCheck = () => {
    const { t } = useTranslation();
    const msg = t('food highlight tip');

    return (
        <Tooltipped
            key={msg}
            options={{ 
                html: msg,
                classes: ['square']
            }}
            component='span'
        >
            <FontAwesomeIcon icon={'check-circle'} className="success-color" />
        </Tooltipped>
    )
}
class DBLogForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { status: 'DEFAULT', expanded: false, loaded: false };
        this.setStatus = this.setStatus.bind(this);
    }

    render() {
        const { record, log, t, date, topLevel, render, subtitle, triggerRequestRating, extraActionRender, valuesFilter, recipeCount, ...rest } = this.props;
        const { expanded, status, loaded } = this.state;
        const highlight = recipeCount && recipeCount > 0;

        if(date) {
            record.date = date.format(dateFormat);
        }
        const formProps = {
            t,
            formFields: record,
            onSubmit: Utils.formikHandlerCreator(this,this.setStatus,log,values => _.pick(valuesFilter ? valuesFilter(values) : values,['id','seq','servings','foodWeightId','date'])),
            status,
            ...rest
        }

        return (
            <React.Fragment>
                <RecordPanel {...topLevel} >
                    <ActionBox className="expand-db-log-btn" icon={ expanded ? "angle-down" : "angle-right"} onClick={() => this.setState({expanded: !expanded, loaded: true})} />
                    {extraActionRender && extraActionRender({ record })}
                    <ContentBox>
                        <TinyText>
                            <div className={classNames({ 'em-food-res': highlight })}>{record.name} {highlight ? (<FoodHighlightCheck />) : ''}</div>
                            {subtitle && <LowOpacityText>{subtitle}</LowOpacityText>}
                        </TinyText>
                    </ContentBox>
                </RecordPanel>
                <Collapse isOpen={expanded}>
                    {loaded && render(formProps)}
                </Collapse>
            </React.Fragment>
        )
    }

    setStatus(status) {
        if(status === 'SUCCESS') {
            const { triggerRequestRating } = this.props;
            triggerRequestRating && triggerRequestRating();
        }
        this.setState({ status });
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }

}

export const RecipeLogForm = (props) => {
    
}

export default DBLogForm;