import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { DefaultAsyncActionButton } from 'components/Button';
import LinkButton from 'components/LinkButton';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { mealPlanNextWeekPath, mealPlanPath, generateNewWeekPathFor, regenerateWeekPathFor, mealPlanPathFor,swapMealModalPathFor, offPlanMealsSetupPathFor, addPeopleToPlanModalPathFor, freeMealPlanPath, allMainMatches, rootPath, standaloneMealPlanPathFor, standaloneGlistPathFor, mealPlanSettingsPath, clientDetailsMatch, modalPathFor } from 'config/paths';
import * as _ from 'lib/utilities';
import { ToggleButton2 } from 'components/Form';
import { userWithMealPlanSelector, getMealInfoMatrixSelector, mealPlanViewTypeSel, hasSingleDayMealsSel, userRecordSelector, trainerRecordSelector, loadedMpDatesSelector, preMpEditorBack } from 'redux/selectors';
import { connect } from 'react-redux';
import ActivityContainer, { CenteredActivityContainer } from 'components/ActivityContainer';
import { MealPlanStandaloneSetupSection } from 'partials/SetupCards';
import Card from 'components/Card';
import { SlightEmphasisIconNote } from 'components/Typography';
import { markWeekClean, ignoreWarnings, setMpViewType, regenSingleDay, switchClient, copyClientMealPlan, publishMealPlan } from 'redux/actions';
import { MealView, NutritionModalButton } from 'partials/MealPlanViews';
import FloatingActionButton, { FabChild } from 'components/FAB';
import { AddPeopleModal } from 'partials/AddPeopeModal';
import classnames from 'classnames';
import { InitialTippedButton, InitialTip, InitialTipPopup, ExitMealPlanButton } from 'partials/Utilities';
import { EDIT_CLIENT_MEAL_PLAN, MEAL_PLAN_START1, VIEW_TOGGLE, VIEW_TOGGLE_OA } from 'config/tooltips';
import { SelectDaysForCopyModal, SelectDaysModal } from 'partials/PickDaysModal';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import SingleDayMeals from 'partials/SingleDayMeals';
import { PlanNotReady } from 'partials/PlanNotReady';
import { dateFormat, NUTRITION } from 'config/settings';
import { SimpleNavPage } from 'components/Page';
import * as transitions from 'assets/transitions';
import { mealPlanSlideOverPaths } from 'partials/MainLayout';
import { Loader } from 'components/LoadingHOC';
import { SimpleNavBack, SimpleNavRightButton } from 'components/Nav';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import { MealPlanStandaloneContext } from './MealPlanSetupFlow';
import { DropdownItem, DropdownLink, MultiLevelDropdown } from 'components/Dropdown';
import { Redirect } from 'components/Routing';
import { HideOnExit } from 'components/RouteTransitionMap';
import { ExportCurrentPDFModal, exportCurrentPDFSuffix } from 'partials/ExportPDFModal';
import ProRequiredButton, { DropdownLinkTPB } from 'partials/ProRequiredButton';
import { RequestMealPlanModal, requestPlanModalSuffix } from 'partials/RequestMealPlanModal';
import ViewSharedMealsSelectModal, { viewSharedMealsPickSuffix } from 'partials/ViewSharedMealsSelectModal';

const getNextWeekPath = (user,standalone) => {
    if(standalone) {
        return standaloneMealPlanPathFor(user.id,'next');
    } else {
        return (user.isPro() || user.hasSofterPaywall()) ? mealPlanNextWeekPath : freeMealPlanPath;
    }
}

const PlaceHolderToggle = ({ user, week }) => {

    if(_.isNumeric(week) && user) {
        return (
            <NumericWeekToggle user={user} week={week} />
        )
    }

    return (
        <h3 className="meal-plan-heading">
        </h3>
    )
}

const NumericWeekToggle = ({ user, week }) => {
    const history = useHistory();
    week = Number(week);
    const prevWeek = user.nextMealPlanWeek(week,-1);
    const nextWeek = user.nextMealPlanWeek(week,1);

    return (
        <h3 className="meal-plan-heading">
            {!_.isBlank(prevWeek) && (
                <div className="caret-cont left-caret" id="prev-week-caret" onClick={() => history.push(standaloneMealPlanPathFor(user.id,prevWeek))}>
                    <FontAwesomeIcon icon="chevron-circle-left" className="mp-week-toggle"  />
                </div>
            )}
            {user.mealPlanWeekName(week)}
            {!_.isBlank(nextWeek) && (<div className="caret-cont right-caret" id="next-week-caret" onClick={() => history.push(standaloneMealPlanPathFor(user.id,nextWeek))}>
                <FontAwesomeIcon icon="chevron-circle-right" className="mp-week-toggle"  />
            </div>)}
        </h3>
    )
}

const AppWeekToggle = ({ user, week }) => {
    const history = useHistory();
    const weeks = ['current','next','third','fourth'];
    const weekInd = weeks.indexOf(week);
    const prevWeek = weeks[weekInd-1];
    const nextWeek = weeks[weekInd+1];

    return (
        <h3 className="meal-plan-heading">
            {!_.isBlank(prevWeek) && (
                <div className="caret-cont left-caret" id="prev-week-caret" onClick={() => history.push(standaloneMealPlanPathFor(user.id,prevWeek))}>
                    <FontAwesomeIcon icon="chevron-circle-left" className="mp-week-toggle"  />
                </div>
            )}
            {user.activeWeekStart(week).format('ddd MMM Do')}
            {!_.isBlank(nextWeek) && (<div className="caret-cont right-caret" id="next-week-caret" onClick={() => history.push(standaloneMealPlanPathFor(user.id,nextWeek))}>
                <FontAwesomeIcon icon="chevron-circle-right" className="mp-week-toggle"  />
            </div>)}
        </h3>
    )
}

const ThisWeekToggle = ({ user, firstWeek, hasSingleDayMeals, clientId }) => {
    const { t } = useTranslation();
    const history = useHistory();
    let title =  t('This Week');
    if(hasSingleDayMeals && !(user.isPro() || user.hasSofterPaywall()) && !user.mealPlanInitialized()) {
        title = t('Weekly Plan')
    } else if(firstWeek) {
        title = t('First Week');
    }

    return (
        <h3 className="meal-plan-heading">
            {title}
            <div className="caret-cont right-caret" id="next-week-caret" onClick={() => history.push(getNextWeekPath(user,clientId))}>
                
                <FontAwesomeIcon icon="chevron-circle-right" className="mp-week-toggle"  />
            </div>
        </h3>
    )
}

const NextWeekToggle = ({ clientId }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <h3 className="meal-plan-heading">
            <div className="caret-cont left-caret" id="this-week-caret" onClick={() => history.push(mealPlanPathFor('current',clientId))}>
                <FontAwesomeIcon icon="chevron-circle-left" className="mp-week-toggle" />
            </div>
            {t('Next Week')}
        </h3>
    )
}

const SingleDayToggle = () => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <h3 className="meal-plan-heading">
            <div className="caret-cont left-caret" id="this-week-caret" onClick={() => history.push(mealPlanPath)}>
                <FontAwesomeIcon icon="chevron-circle-left" className="mp-week-toggle" />
            </div>
            {t('Single-day Plan')}
        </h3>
    )
}

const RSDCore = ({ children, id, onClick, className }) => {
    const { t } = useTranslation();

    return (<Button
            rounded 
            color="primary" 
            className={className} 
            onClick={onClick}
            id={id}
        >
            {children} {t("Regenerate")}
        </Button>
    )
}

let RegenSingleDayButton = ({ regeneratePlan, meals, context }) => {
    const classNames = classnames({ "semitransparent btn-no-shadow": context === 'header' });

    return (
        <ResponsiveContainer render={({ belowBreakpoint }) => {
            if((belowBreakpoint && context === 'bottom') || (!belowBreakpoint && context === 'header')) {
                return (
                    <DefaultAsyncActionButton 
                        className={classNames}
                        Comp={RSDCore}
                        LoaderWrapper={RSDCore}
                        loaderType="icon"
                        rounded 
                        color="primary" 
                        id={`regenerate-plan-btn`}
                        action={regeneratePlan.bind(null,meals)}
                        preloaded={() => (meals.length > 0)}
                    >
                            <FontAwesomeIcon icon={'random'}></FontAwesomeIcon>
                    </DefaultAsyncActionButton>
                )
            } else {
                return '';
            }
        }} />
    )
}

const mapDispatchToRSDProps = dispatch => ({
    regeneratePlan: (userMeals) => dispatch(regenSingleDay(userMeals))
})

RegenSingleDayButton = connect(null,mapDispatchToRSDProps)(RegenSingleDayButton)

const RegenButton = ({ week, ...rest }) => {
    const { t } = useTranslation();

    return (
        <LinkButton to={regenerateWeekPathFor(week)} {...rest}>
            <FontAwesomeIcon icon="random" />    
            {t('Pin & Regenerate')}
        </LinkButton>
    )
}

const SwapButton = ({ mealInfoMatrix, weeklyMeal, userMeal, className, clientId }) => {
    const history = useHistory();
    weeklyMeal = weeklyMeal || mealInfoMatrix.weeklyMealForUserMeal(userMeal);
    const id = weeklyMeal.initMeal().id; 

    return (
        <div id={`meal-${userMeal ? userMeal.id : id}-swap-btn`} className={classnames("meal-swap-btn",{ [className]: className})} onClick={() => history.push(swapMealModalPathFor(mealPlanPathFor(mealInfoMatrix.week,clientId),id))}>
            <FontAwesomeIcon icon="exchange-alt" />
        </div>
    )
}

const BlankAction = () => '';

export const ViewToggleButton = ({ viewType, setViewType, className }) => {
    const { t } = useTranslation();

    return (
        <InitialTip 
            tipName={VIEW_TOGGLE}
            delay={5000}
            text={t("view toggle tip")}
            onlyAfter={VIEW_TOGGLE_OA}
            passThroughProps={{ options: [{ value: 'day', icon: 'list'},{ value: 'type', icon: 'th-large'}] }}
            component={ToggleButton2}
            value={viewType} 
            setValue={setViewType} 
            className={className}
            id={'view-toggle-btn'}
        />
    )
}

const MealPlanHeaderCont = ({ className, children, title }) => {
    const classNames = classnames("meal-plan-header", { [className]: className });

    return (
        <div className={classNames}>
            <div className="topper-container position-relative">
                <div className="align-bottom display-flex">
                    <div style={{height: '36px', marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
                        {title}
                    </div>
                </div>
                {children}
            </div>
        </div>
    )
}

let MealPlanHeader = ({ user, mealInfoMatrix, viewType, setViewType, hasSingleDayMeals, className, match: { params: { clientId, week } } }) => {
    const { t } = useTranslation();
    week = _.isBlank(week) ? 'current' : week;
    const isTrainerEditing = !_.isBlank(clientId);
    const showEditFeatures = isTrainerEditing || user.allowedToEditOwnMealPlan();
    const isPublished = mealInfoMatrix.isPublished();
    const showHeaderStuff = mealInfoMatrix.showFullMealPlanView(isTrainerEditing) && (isPublished || isTrainerEditing);

    return (
        <MealPlanHeaderCont
            className={className}
            title={(
                <React.Fragment>
                    {_.isNumeric(week) && (
                        <NumericWeekToggle 
                            user={user}
                            week={week}
                        />
                    )}
                    {!isTrainerEditing && week === 'current' && (<ThisWeekToggle 
                        user={user} 
                        firstWeek={mealInfoMatrix.isFirstWeek()} 
                        hasSingleDayMeals={hasSingleDayMeals} 
                        clientId={clientId}
                    />)}
                    {!isTrainerEditing && week === 'next' && (<NextWeekToggle 
                        clientId={clientId} 
                    />)}
                    {isTrainerEditing && !_.isNumeric(week) && <AppWeekToggle user={user} week={week} />}
                    {week === 'single' && <SingleDayToggle />}
                </React.Fragment>
            )}
        >
            {showHeaderStuff && (<div className="align-bottom display-flex ab-right">
                <NutritionModalButton mealInfoMatrix={mealInfoMatrix} basePath={mealPlanPathFor(mealInfoMatrix.week,clientId)} hasWhiteBcg={isTrainerEditing} isTrainerEditing={isTrainerEditing} />
                {!mealInfoMatrix.isSingle() && showEditFeatures && (<InitialTip
                    tipName={MEAL_PLAN_START1}
                    delay={1500}
                    text={t("meal plan start tip1")}
                    onlyShowVisible
                    className="inline-block hide-on-med-and-down" 
                >
                    <ProRequiredButton  
                        user={user}
                        proPath={addPeopleToPlanModalPathFor(mealPlanPathFor(week,clientId))}
                        blockTypes={['soft']}
                        context="add_people"
                        render={({ onClick }) => (
                            <Button className="semitransparent hide-on-med-and-down" noShadow color="primary" outlined onClick={onClick}>
                                <FontAwesomeIcon icon="user-plus" />
                                {t('Add People')}
                            </Button>
                        )}

                    />
                    <ProRequiredButton  
                        user={user}
                        proPath={offPlanMealsSetupPathFor(week)}
                        blockTypes={['soft']}
                        context="off_plan"
                        render={({ onClick }) => (
                            <Button
                                className="semitransparent hide-on-med-and-down" 
                                noShadow 
                                color="primary" 
                                outlined 
                                onClick={onClick}
                            >
                                    <FontAwesomeIcon icon="burger-soda" />
                                    {t('Off-Plan')}
                            </Button>
                        )}

                    />
                    <RegenButton className="semitransparent hide-on-med-and-down" noShadow color="primary" outlined week={week} />
                </InitialTip>)}
                {mealInfoMatrix.isSingle() && showEditFeatures && (
                    <RegenSingleDayButton meals={mealInfoMatrix.allUserMeals()} context="header" />
                )}
                {!mealInfoMatrix.isSingle() && (<div className="mr5 mb10">
                    <div className="text-center">
                        <label className="ml5">{t('View')}</label> 
                    </div>
                    <ViewToggleButton viewType={viewType} setViewType={setViewType} />
                </div>)}
            </div>)}
        </MealPlanHeaderCont>
    )
}

const DirtyScreen = ({ user, isTrainerEditing, mealPlanState, week, markWeekClean, startDate }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    let [infoMsg,subtitle] = [t('This week was generated before you changed your settings'),t('Would you like to regenerate it to match your new settings?')];
    const showClientOptions = user.isClient() && !isTrainerEditing;
    if(mealPlanState === 'ungenerated') {
        if(showClientOptions) {
            infoMsg = t("This week's meal plan hasn't been created yet");
            subtitle = t("client gen mp tip");
        } else {
            infoMsg = t("This week's meals haven't been generated yet");
            subtitle = t("Generate your plan for the week to continue");
        }
    } else if(mealPlanState === 'partial') {
        infoMsg = t("This week hasn't been fully generated yet");
        subtitle = t("Generate your plan for the week to continue");
    }

    return (
        <CenteredActivityContainer>
            <Card className="pa20 text-center">
                <SlightEmphasisIconNote text={`${infoMsg}. ${subtitle}`} />
                <div className="mt20">
                    {mealPlanState === 'ungenerated' && !showClientOptions && (
                        <MealPlanStandaloneContext.Consumer>
                            {clientId => (
                                <LinkButton rounded id="generate-week-btn" noShadow color="primary" to={generateNewWeekPathFor(week,clientId)}>
                                    <FontAwesomeIcon icon="arrow-right" />    
                                    {t('Next')}
                                </LinkButton>
                            )}
                        </MealPlanStandaloneContext.Consumer>
                    )}
                    {mealPlanState === 'ungenerated' && showClientOptions && (
                        <MealPlanStandaloneContext.Consumer>
                            {clientId => (
                                <div className="text-center">
                                    <LinkButton rounded outlined id="generate-week-btn" noShadow color="primary" to={generateNewWeekPathFor(week,clientId)}>
                                        <FontAwesomeIcon icon="arrow-right" />    
                                        {t('Create My Own')}
                                    </LinkButton>
                                    <LinkButton rounded outlined id="request-plan-btn" noShadow color="primary" className="ml5" to={modalPathFor(requestPlanModalSuffix,pathname)}>
                                        <FontAwesomeIcon icon="paper-plane" />    
                                        {t('Request a Plan')}
                                    </LinkButton>
                                </div>
                            )}
                        </MealPlanStandaloneContext.Consumer>
                    )}
                    {mealPlanState === 'partial' && (<RegenButton rounded noShadow color="primary" week={week} id="partial-regen-btn" />)}
                    {mealPlanState === 'dirty' && (
                        <div className="row mb0" id="meal-plan-dirty-msg">
                            <div className="col s12 m6 l6">
                                <div className="text-center mt10 mb10">
                                    <div className="inline-block" style={{ width: '280px', maxWidth: '100%' }}>
                                        <RegenButton rounded outlined noShadow color="primary" week={week} block id="regen-plan-btn" />
                                    </div>
                                </div>
                            </div>
                            <div className="col s12 m6 l6">
                                <div className="text-center mt10 mb10">
                                    <div className="inline-block" style={{ width: '280px', maxWidth: '100%' }}>    
                                    <DefaultAsyncActionButton 
                                        id="mark-week-clean-btn"
                                        rounded
                                        outlined
                                        className="mr5"
                                        color="primary"
                                        loaderType="loaderOnly"
                                        block
                                        action={markWeekClean.bind(null,user.mealPlanStartDateFor(week))}
                                    >
                                        <FontAwesomeIcon icon="arrow-right" />
                                        {t('Go to Plan')}
                                    </DefaultAsyncActionButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Card>
            <RequestMealPlanModal startDate={startDate} />
        </CenteredActivityContainer>
    );
}

const RequestPlanScreen = ({ startDate }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const infoMsg = t("This week's meal plan hasn't been created yet");

    return (
        <CenteredActivityContainer>
            <Card className="pa20 text-center">
                <SlightEmphasisIconNote text={infoMsg} />
                <LinkButton rounded id="request-plan-btn" noShadow color="primary" className="mt10" to={modalPathFor(requestPlanModalSuffix,pathname)}>
                    <FontAwesomeIcon icon="paper-plane" />
                    {t('Request a Plan')}
                </LinkButton>
            </Card>
            <RequestMealPlanModal startDate={startDate} />
        </CenteredActivityContainer>
    );
}

class MealPlan extends React.Component {
    static contextType = MealPlanStandaloneContext;

    render() {
        let { markWeekClean, user, week, mealInfoMatrix, ignoreWarnings, viewType, t, history, showPlanLivePopup } = this.props;
        
        const clientId = this.context;
        const isTrainerEditing = !_.isBlank(clientId);
        const showEditFeatures = isTrainerEditing || user.allowedToEditOwnMealPlan();
        
        if(mealInfoMatrix.isSingle()) {
            return (
                <React.Fragment>
                    <MealView 
                        week={week} 
                        mealInfoMatrix={mealInfoMatrix} 
                        basePath={mealPlanPathFor(week,clientId)}
                    >
                        <SingleDayMeals mealInfoMatrix={mealInfoMatrix} />
                    </MealView>
                </React.Fragment>
            )
        } else if(user.mealPlanSetupAndVisible()) {
            const mealPlanState = user.mealPlanGenerateState(week);
            if(mealInfoMatrix.showRequestPlanScreen(isTrainerEditing,mealPlanState)) {
                return (<RequestPlanScreen startDate={mealInfoMatrix.stubDate()} />)
            } else if(mealInfoMatrix.showFullMealPlanView(isTrainerEditing)) {
                return (
                    <React.Fragment>
                        <MealView 
                            week={week} 
                            mealInfoMatrix={mealInfoMatrix} 
                            ignoreWarnings={week => ignoreWarnings(week,user.ignoreWarningsFor)} 
                            viewType={viewType} 
                            actionType={'swap'} 
                            basePath={mealPlanPathFor(week,clientId)}
                            ActionComp={showEditFeatures ? (<SwapButton clientId={clientId} />) : (<BlankAction />)}
                        />
                        {showEditFeatures && (<InitialTippedButton
                            tipName={MEAL_PLAN_START1}
                            delay={1500}
                            text={t("meal plan start tip1")}
                            component={FloatingActionButton}
                            options={{ position: 'top' }}
                            onlyShowVisible
                            id={"edit-meal-plan-btn"} 
                            containerClass="large-screen-disappear" 
                            btnClass="btn-primary" 
                            icon={"pencil"}
                        >
                            <ProRequiredButton  
                                user={user}
                                proPath={offPlanMealsSetupPathFor(week)}
                                blockTypes={['soft']}
                                context="off_plan"
                                render={({ onClick }) => (
                                    <FabChild id="fab-off-plan-btn" label={t('Off-Plan')} icon="burger-soda" className="carb-color" onClick={onClick} />
                                )}

                            />
                            <FabChild id="fab-pin-regen-btn" label={t('Pin & Regenerate')} icon="random" className="red" onClick={() => history.push(regenerateWeekPathFor(week))} />
                            <ProRequiredButton  
                                user={user}
                                proPath={addPeopleToPlanModalPathFor(mealPlanPathFor(week,clientId))}
                                blockTypes={['soft']}
                                context="add_people"
                                render={({ onClick }) => (
                                    <FabChild id="fab-add-people-btn" label={t('Add People')} icon="user-plus" className="fat-color" onClick={onClick} />
                                )}

                            />
                        </InitialTippedButton>)}
                        <AddPeopleModal
                            mealInfoMatrix={mealInfoMatrix} 
                            basePath={mealPlanPathFor(week,clientId)} 
                        />
                        <SelectDaysModal 
                            basePath={mealPlanPathFor(week,clientId)} 
                            mealInfoMatrix={mealInfoMatrix} 
                            selectType="swap" 
                        />
                        <ViewSharedMealsSelectModal week={week} miniProfiles={mealInfoMatrix.allSharedProfiles()} />
                        <SelectDaysForCopyModal mealInfoMatrix={mealInfoMatrix} />
                        {showPlanLivePopup && (<InitialTipPopup tipName={EDIT_CLIENT_MEAL_PLAN} id="edit-client-mp-tip" >
                            <div>
                                <p className="pl20 pr20">
                                    {t("edit client meal plan tip")}
                                </p>
                            </div>
                        </InitialTipPopup>)}
                    </React.Fragment>
                )
            } else {
                return (
                    <div className="row limit-50-h">
                        <DirtyScreen 
                            user={user} 
                            mealPlanState={mealPlanState} 
                            week={week} 
                            markWeekClean={markWeekClean} 
                            isTrainerEditing={isTrainerEditing}
                            startDate={mealInfoMatrix.stubDate()}
                        />
                    </div>
                )
            }
        } else if(user.setupOwnMealPlanPrefs()) {
            return (
                <MealPlanStandaloneSetupSection user={user} />
            )
        } else {
            return (
                <PlanNotReady type={NUTRITION} />
            )
        }
    }

}

export const mapStateToMpProps = (state,props) => {
    let { match: { params: { week }}} = props;
    week = _.isBlank(week) ? 'current' : week;
    const mealInfoMatrixSelector = getMealInfoMatrixSelector(week);

    return {
        user: userWithMealPlanSelector(state),
        mealInfoMatrix: mealInfoMatrixSelector(state),
        hasSingleDayMeals: hasSingleDayMealsSel(state),
        viewType: mealPlanViewTypeSel(state),
        week
    }
}

export const mapDispatchToMpProps = dispatch => ({
    markWeekClean: startDate => dispatch(markWeekClean(startDate)),
    ignoreWarnings: (week,currentIgnores) => dispatch(ignoreWarnings(week,currentIgnores)),
    setViewType: type => dispatch(setMpViewType(type))
})

const mapDispatchToHeaderProps = dispatch => ({
    setViewType: type => dispatch(setMpViewType(type))
})

MealPlanHeader = connect(mapStateToMpProps,mapDispatchToHeaderProps)(MealPlanHeader);

export { MealPlanHeader, RegenSingleDayButton };

MealPlan = connect(mapStateToMpProps,mapDispatchToMpProps)(withTranslation()(MealPlan));

export const MealPlanMain = props => (
    <MealPlanStandaloneContext.Provider value={null}>
        <MealPlan {...props} />
    </MealPlanStandaloneContext.Provider>
)

const PreloadMealPlanHeader = ({ user, week }) => {
    
    return (
        <MealPlanHeaderCont
            className={'for-simple-nav'}
            title={<PlaceHolderToggle user={user} week={week} />}
        >
        </MealPlanHeaderCont>
    )
}

const standaloneTmap = {
    rules: [
        [[...allMainMatches,clientDetailsMatch,rootPath],transitions.slideOut],
        [mealPlanSlideOverPaths,transitions.slideOver]
    ]
};

let AfterCopyRedirect = ({ user, responseData: { clientMpQuotaHit } }) => {
    const { t } = useTranslation();

    if(clientMpQuotaHit) { 
        return (
            <ActivityContainer>
                <div className="mt50">
                    <SlightEmphasisIconNote text={t("meal plan quota hit")} />
                </div>
            </ActivityContainer>
        )
    }

    return (
        <Redirect to={standaloneMealPlanPathFor(user.id,user.lastMealPlanWeek())} />
    )
}

let StandaloneMealPlanPage = ({ trainer, user, loadedDates, scrollRef, setupTransitions, switchClient, copyPlan, publish, preMpBack, history, location: { pathname }, match, match: { params: { clientId, week, copy } } }) => {
    const { t } = useTranslation();
    const client = (user.id === Number(clientId)) ? user : trainer.clientById(clientId);
    const startDate = (client && client.switchMpFocusDate(week));
    const startDateStr = startDate && startDate.format(dateFormat);
    const stubDate = (client && client.activeWeekStart(week));
    const key = `${clientId}-${startDateStr}-${copy}`;
    const isCopy = copy === 'copy';
    const load = isCopy ? (() => copyPlan(clientId,startDateStr)) : (() => switchClient(clientId,startDateStr));
    const planIsPublished = client && client.isMealPlanPublished(stubDate);
    const showPublishBtn = client && stubDate && trainer && trainer.isAppTrainer() && !client.isMealPlanPublished(stubDate);
    const showPlanLivePopup = trainer.isAppTrainer() && client && !client.sampleClient && planIsPublished; 
    
    const preloaded = isCopy ? (() => false) : (() => {
        if(user && user.id === Number(clientId)) {
            if(!startDateStr) {
                return true;
            }
            return (loadedDates && loadedDates.includes(startDateStr));
        }
        return false;
    })
    const isLoaded = preloaded();

    return (
        <SimpleNavPage 
            scrollRef={scrollRef} 
            setupTransitions={setupTransitions} 
            transitionMap={standaloneTmap}
            tabs={isLoaded ? <MealPlanHeader match={match} className="for-simple-nav" /> : <PreloadMealPlanHeader user={client} week={isCopy ? ((client && client.numMealPlanWeeks()) || week) : week} />}
            title={t("name's meal plan", { name: (client && client.shortName()) })}
            backBtn={<SimpleNavBack backNum={preMpBack} />}
            navChildren={(
                <MultiLevelDropdown
                    options={ { coverTrigger: false, alignment: 'right', closeOnClick: true, closeOnChildClick: true } }
                    triggerRender={({ ref, target }) => {
                        return (
                            <SimpleNavRightButton 
                                ref={ref} 
                                data-target={target} 
                                id="mp-menu-btn" 
                                icon="ellipsis-v" 
                                className="pr20" 
                            />
                        )
                    }}
                >
                    <DropdownLinkTPB id="grocery-list-btn" label={t("Grocery List")} icon={'shopping-basket'} to={standaloneGlistPathFor(clientId,week)} allowUninit={false} />
                    {!showPublishBtn && (<DropdownLinkTPB id="pdf-mp-btn" label={t("Export PDF")} icon={['far','file-export']} to={modalPathFor(exportCurrentPDFSuffix,pathname)} allowUninit={false} />)}
                    {showPublishBtn && (<DropdownItem id="publish-mp-btn" label={t("Publish to Client")} icon={'paper-plane'} onClick={() => publish({ startDate: stubDate.format(dateFormat) })} />)}
                    <DropdownLink label={t("Settings")} icon="cog" to={mealPlanSettingsPath} />
                    <DropdownLink label={t("View Shared Meals")} icon="user-friends" id="view-shared-meals-btn" to={modalPathFor(viewSharedMealsPickSuffix,pathname)} />
                    {trainer && trainer.isPdfTrainer() && (<DropdownLink id="copy-plan-btn" label={t("Copy to New Week")} icon={['far','copy']} to={standaloneMealPlanPathFor(clientId,week,true)} />)}
                    <ExitMealPlanButton forMenu alwaysShow />
                </MultiLevelDropdown>
            )}
        >
            <TrainerPaywall type={NUTRITION}>
                <MealPlanStandaloneContext.Provider value={clientId}>
                    <div className="mp-container">
                        <HideOnExit>
                            <Loader
                                key={key}
                                load={load}
                                preloaded={preloaded}
                                successComponent={isCopy ? AfterCopyRedirect : MealPlan}
                                user={isCopy ? client : null}
                                match={match}
                                history={history}
                                type='page'
                                showPlanLivePopup={showPlanLivePopup}
                            />
                        </HideOnExit>
                        {client && (<ExportCurrentPDFModal client={client} week={week} />)}
                    </div>
                </MealPlanStandaloneContext.Provider>
            </TrainerPaywall>
        </SimpleNavPage>
    )
}

const mapStateToStandaloneProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state),
    loadedDates: loadedMpDatesSelector(state),
    preMpBack: preMpEditorBack(state)
})

const mapDispatchToStandaloneProps = dispatch => ({
    switchClient: (clientId,focusDate) => dispatch(switchClient(clientId,focusDate)),
    copyPlan: (clientId,focusDate) => dispatch(copyClientMealPlan(clientId,focusDate)),
    publish: data => dispatch(publishMealPlan(data))
})

StandaloneMealPlanPage = connect(mapStateToStandaloneProps,mapDispatchToStandaloneProps)(StandaloneMealPlanPage);

export { StandaloneMealPlanPage }