import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ChildDropdown, DropdownItem, DropdownLink, DropdownOption, DropdownTip, MultiLevelDropdown } from 'components/Dropdown';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ConfirmActionButton, IconButton } from 'components/Button';
import { User } from 'lib/classes';
import * as _ from 'lib/utilities';
import { assignClientHabitsPathFor, assignClientRoutinePathFor, assignFormsToClientsPath, clientDetailsPath, clientMealPlanSettingsPath, clientMealPlansPath, editRoutineShortcutPathFor, modalPathFor, switchCliAccountPathFor, tagClientsModalPathFor } from 'config/paths';
import { SlightEmphasisIconNote } from 'components/Typography';
import { assignTrainer, updateClients } from 'redux/actions';
import { connect } from 'react-redux';
import { DropdownLinkTPB } from './ProRequiredButton';
import { CLEARED_FILTERS, NUTRITION, TRAINING } from 'config/settings';
import classnames from 'classnames';
import { reassignSuffix, trainerSelectSuffix } from './TrainerSelectModal';
import { useLocation } from 'react-router-dom';
import { UserIcon } from './Utilities';
import { exportClientRoutinePDFSuffix } from './ExportPDFModal';
import { viewAllFormsModalSuffix } from './TrainerFormModals';

export const ClientFilterButton = ({ trainer, filters, setFilters, className, omit=[], isModal, hidden }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    filters = _.omit(filters,omit);
    const routines = [ { text: 'No Routine', value: 'none' } , ...trainer.filterRoutines().map(wrt => ({ text: wrt.resolvedName(t), value: wrt.id }))];
    const isFlagged = _.mapValues(_.omit(filters,['lookback','sort','selected','hasBasicProfile','showAllOnTextSearch']),(val,key) => (key === 'status' ? val !== 'active' : !_.isBlank(val)));
    const filterCount = _.filter(Object.values(isFlagged),flagged => flagged).length;
    const tags = trainer.tagFilterList(filters.tag);
    const showTrainerBtn = (!omit.includes('trainerId') && trainer.isTrainerAdmin() && trainer.hasOtherTrainers());
    const selTrainer = _.isBlank(filters.trainerId) ? null : trainer.trainerById(filters.trainerId);

    return (
        <MultiLevelDropdown
            fixed={isModal}
            options={ { coverTrigger: true, alignment: 'right', closeOnClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <div className={classnames("position-relative inline-block", { hidden })}>
                        {filterCount > 0 && (<span className="notification-icon blue for-btn">{filterCount}</span>)}
                        <Button noShadow outlined color="primary" variant="icon-only" id="client-filters-btn" className={className} ref={ref} data-target={target}>
                            <FontAwesomeIcon icon={['far','filter']}></FontAwesomeIcon>
                        </Button>
                    </div>
                )
            }}
        >
            <DropdownItem noClose label={t("Reset Filters")} id="reset-filters-btn" onClick={() => setFilters({ ...filters, ...CLEARED_FILTERS })} />
            {showTrainerBtn && (
                <DropdownLink 
                    id="by-trainer-btn"
                    className="position-relative always-force-close"
                    label={(
                        <React.Fragment>
                            <span style={{ zIndex: '9', position: 'relative' }}>{t('By Trainer')}</span>
                            {selTrainer && (<UserIcon user={selTrainer} className="usr-filter ddown xs" />)}
                        </React.Fragment>
                     )}
                    to={modalPathFor(trainerSelectSuffix,pathname)}
                />
            )}
            {!omit.includes('setup') && (<ChildDropdown label={t('By Setup Step')} triggerId="by-setup-btn" flagged={isFlagged.setup}>
                {trainer.setupStepsCol(t).map(({ text, value }) => (
                    <DropdownOption 
                        key={value}
                        label={text} 
                        value={value} 
                        name='setup' 
                        values={filters} 
                        setValues={setFilters} 
                        id={`setup-${value}-filter-btn`}
                    />
                ))}
            </ChildDropdown>)}
            {!omit.includes('tag') && (<ChildDropdown label={t('By Tag')} triggerId="by-tag-btn" flagged={isFlagged.tag}>
                {tags.length === 0 && (<li className="pa10">{t('no client tags')}</li>)}
                {tags.map(tag => (
                    <DropdownOption 
                        key={tag}
                        label={tag} 
                        name='tag'
                        value={tag} 
                        values={filters} 
                        setValues={setFilters} 
                        id={`${tag}-filter-btn`}
                    />
                ))}
            </ChildDropdown>)}
            {!omit.includes('goal') && (<ChildDropdown label={t('By Goal')} triggerId="by-goal-btn" flagged={isFlagged.goal}>
                {User.weightGoalCol(t).map(({ text, value }) => (
                    <DropdownOption 
                        key={value}
                        label={text} 
                        value={value} 
                        name='goal' 
                        values={filters} 
                        setValues={setFilters} 
                        id={`goal-${value}-filter-btn`}
                    />
                ))}
            </ChildDropdown>)}
            {!omit.includes('routineId') && (<ChildDropdown label={t('By Routine')} triggerId="by-routine-btn" flagged={isFlagged.routineId}>
                {routines.map(({ text, value}) => (
                    <DropdownOption 
                        key={value}
                        label={text} 
                        value={value} 
                        name='routineId' 
                        values={filters} 
                        setValues={setFilters} 
                        id={`routine-${value}-filter-btn`}
                    />))}
            </ChildDropdown>)}
            {!omit.includes('gender') && (<ChildDropdown label={t('By Gender')} triggerId="by-gender-btn" flagged={isFlagged.gender}>
                {User.genderCol(t).map(({ text, value }) => (
                    <DropdownOption 
                        key={value} 
                        label={text} 
                        value={value} 
                        name='gender' 
                        values={filters} 
                        setValues={setFilters}
                        id={`gender-${value}-filter-btn`}
                    />
                ))}
            </ChildDropdown>)}
            {!omit.includes('status') && (<ChildDropdown label={t('By Status')} triggerId="by-status-btn" flagged={isFlagged.status}>
                {[{ text: t('Active'), value: 'active' }, { text: t('Deactivated'), value: 'inactive'}, { text: t('Billable'), value: 'billable' } ].map(({ text, value }) => (
                    <DropdownOption 
                        key={value}
                        label={text} 
                        value={value} 
                        name='status' 
                        values={filters} 
                        setValues={setFilters} 
                        nullVal='active'
                        id={`${value}-filter-btn`}
                    />
                ))}
            </ChildDropdown>)}
        </MultiLevelDropdown>                      
    )
}

let ReassignOptionDrop = ({ selClients, label, trainerIds }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    let ids,type;
    trainerIds = trainerIds || _.uniq(selClients.map(client => client.trainerId));
    if(selClients) {
        type = 'client';
        ids = selClients.map(cli => cli.id)
    } else {
        type = 'trainer';
        ids = trainerIds;
    }

    return (
        <DropdownLink 
            id="reassign-clients-drop"
            label={label || t('Reassign to Trainer')}
            icon="users"
            to={modalPathFor(reassignSuffix,pathname,{ type, ids: ids.join('_') })}
        />
    )
}

const mapDispatchToReassignProps = dispatch => ({
    assignTrainer: params => dispatch(assignTrainer(params))
})

ReassignOptionDrop = connect(null,mapDispatchToReassignProps)(ReassignOptionDrop)

export { ReassignOptionDrop }

let ClientActions = ({ trainer, selected, reactivateClients, deactivateClients, t, basePath, type }) => {
    const selClients = trainer.clientsByIds(selected);
    const inactive = _.some(selClients, client => client.isInactive());
    const isSetup = _.every(selClients, client => client.hasBasicProfile);
    const { pathname } = useLocation();

    if(inactive) {
        return (
            <DropdownItem id="reactivate-btn" icon="undo" label={t('Reactivate')} onClick={() => reactivateClients(selected)} />
        )
    }

    const reassign = trainer.hasOtherTrainers() && (<ReassignOptionDrop selClients={selClients} />);

    const assignPath = assignClientRoutinePathFor(selected);
    const assignNewRoutine = isSetup && (<DropdownLinkTPB type={TRAINING} id="assign-routine-btn" label={t("Assign New Routine")} icon="exchange-alt" to={assignPath} />);

    const assignHabits = (<DropdownLinkTPB type={null} id={`assign-client-habits-btn`} label={t("Assign/Modify Habits")} icon="calendar-check" to={assignClientHabitsPathFor(selClients.map(c => c.id))} />)
    const assignForms = (
        <ChildDropdown label={t('Forms, Check-ins, etc')} triggerId="forms-drop">
            {type === 'single' && (
                <DropdownLink 
                    id="view-all-forms-btn" 
                    label={t('View')} 
                    icon="eye" 
                    to={modalPathFor(viewAllFormsModalSuffix,pathname, { clientId: selClients[0].id })} 
                />
            )}
            <DropdownLinkTPB 
                type={null} 
                id={`assign-client-forms-btn`} 
                label={t("Settings")} 
                icon={'cog'} 
                to={assignFormsToClientsPath(selClients.map(c => c.id))} 
            />
        </ChildDropdown>
    )


    const deactivate = (<ConfirmActionButton
        onClick={() => deactivateClients(selected)}
        render={({ onClick, ready, clickRef }) => (
            <li onClick={onClick} ref={clickRef} className={ready ? null : 'dont-close-dropdown'} id="deactivate-btn">
                <span className={ready ? 'red-text' : null}>
                    <FontAwesomeIcon icon="times" /> {t("Deactivate")}
                </span>
            </li>
        )}
    />)

    if(type === 'single') {
        const client = selClients[0];
        
        return (
            <React.Fragment>
                <DropdownLinkTPB type={null} id={`view-client-btn`} label={t("View Client")} icon="eye" to={clientDetailsPath(client.id)} />
                {!client.trainerDidInitialMpSetup() && <DropdownLinkTPB type={NUTRITION} label={t("Set Up Meal Plan")} icon={["fal","utensils-alt"]} to={clientMealPlanSettingsPath(client.id,'setup')} />}
                {!client.trainerDidInitialWorkoutSetup() && <DropdownLinkTPB type={TRAINING} 
                    id="setup-client-wrt-btn" 
                    label={t("Set Up Workouts")} 
                    icon={['fal',"dumbbell"]} 
                    to={assignPath} 
                />}
                {trainer.isAppTrainer() && client.mealPlanInitialized() && (<DropdownLinkTPB type={NUTRITION} id="view-mp-btn" label={`${t('Meal Plans')}`} icon={["fal","utensils-alt"]} to={clientMealPlansPath(client.id)} />)}
                {assignHabits}
                <DropdownLink id="tag-clients-btn" label={t("Tag")} icon={["far","tags"]} to={tagClientsModalPathFor(basePath,[client.id])} />
                {trainer.isAppTrainer() && client.canSeeMainLayout() && (<DropdownLinkTPB type={null} label={t("Sign Into Account")} icon="external-link-alt" to={switchCliAccountPathFor(selected[0])} linkTarget={window.isCordova ? null : '_blank'} />)}
                {reassign}
                {deactivate}
                {trainer.isPdfTrainer() && client.mealPlanInitialized() && (<ChildDropdown triggerId={'client-mp-menu'} label={t('Meal Plans')}>
                    <DropdownLinkTPB type={NUTRITION} id="view-mp-btn" label={`${t('View')}/${t("Edit")}`} icon={["far","edit"]} to={client.editViewMpsPath(basePath)} />
                    <DropdownLinkTPB type={NUTRITION} id="new-mp-btn" label={t("New")} icon="plus" to={client.createNewMealPlanPath()} />
                </ChildDropdown>)}
                {!client.mealPlanInitialized() && (
                    <ChildDropdown label={t('Meal Plan')}>
                        <li className="pa10">
                            <SlightEmphasisIconNote text={t("client hasn't setup mp")} />
                        </li>
                    </ChildDropdown>
                )}
                {client.trainerDidInitialWorkoutSetup() && (<ChildDropdown label={t('Workout Plan')} triggerId="workout-plan-drop">
                    {client.workoutPlanInitialized() && (
                        <DropdownLinkTPB type={TRAINING} 
                            id="edit-client-wrt-btn"
                            label={t("Edit Routine")} 
                            icon={["far","edit"]} 
                            to={editRoutineShortcutPathFor(selected[0])}
                        />
                    )}
                    {!client.workoutPlanInitialized() && (
                        <li className="pa10">
                            <SlightEmphasisIconNote text={t("client hasn't picked routine")} />
                        </li>
                    )}
                    {assignNewRoutine}
                    {client.workoutPlanInitialized() && (
                        <DropdownLinkTPB 
                            type={TRAINING} id="pdf-workout-btn" 
                            label={t("Export PDF")} 
                            icon={['far','file-export']} 
                            to={modalPathFor(exportClientRoutinePDFSuffix,pathname,{ clientId: client.id })} 
                            allowUninit={false} 
                        />
                    )}
                </ChildDropdown>)}
                {trainer.isAppTrainer() && assignForms}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            <DropdownLink id="tag-clients-btn" label={t("Tag")} icon={["far","tags"]} to={tagClientsModalPathFor(basePath,selected)} />
            {assignNewRoutine}
            {!isSetup && <ChildDropdown triggerId="assign-setup-tip" label={t("Assign New Routine")}>
                <li className="pa10">{t('setup all clients first')}</li>
            </ChildDropdown>}
            {trainer.isAppTrainer() && assignForms}
            {assignHabits}
            {deactivate}
            {reassign}
        </React.Fragment>
    )
}

const mapDispatchToClientActionsProps = dispatch => ({
    assignTrainer: params => dispatch(assignTrainer(params)),
    deactivateClients: (clientIds) => dispatch(updateClients({ clientIds, accountStatus: User.INACTIVE })),
    reactivateClients: (clientIds) => dispatch(updateClients({ clientIds, accountStatus: '' }))
})

ClientActions = connect(null,mapDispatchToClientActionsProps)(ClientActions)

export const ClientActionsButton = ({ trainer, clients, selected: inSel, basePath, className }) => {
    const { t } = useTranslation();
    const selected = _.intersection(clients.map(client => client.id),inSel);
    
    return (
        <MultiLevelDropdown
            options={ { coverTrigger: true, closeOnClick: true, closeOnChildClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <Button 
                        noShadow 
                        outlined 
                        olor="primary" 
                        className={className} 
                        variant="icon-only" 
                        ref={ref} 
                        data-target={target}
                        id="clients-actions-menu-btn"
                    >
                        <FontAwesomeIcon icon={'ellipsis-v'}></FontAwesomeIcon>
                    </Button>
                )
            }}
        >
            {selected.length === 0 && (
                <DropdownTip text={t("select clients tip", { type: t('client')})} id="select-clients-first-tip" />
            )}
            {selected.length > 0 && (
                <ClientActions 
                    type="mass"
                    key={selected.join('_')}
                    trainer={trainer}
                    selected={selected}
                    t={t}
                    basePath={basePath}
                />
            )}
        </MultiLevelDropdown>                      
    )
}

export const ClientDropdown = ({ trainer, client, basePath }) => {
    const { t } = useTranslation();

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: true, closeOnClick: true, closeOnChildClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <div className="dropdown-container clickable" id={`client-${client.id}-drop-btn`} ref={ref} data-target={target}>
                            <IconButton icon="ellipsis-v" size="2x" />
                    </div>
                )
            }}
        >
            <ClientActions 
                type="single"
                basePath={basePath}
                trainer={trainer}
                selected={[client.id]}
                t={t}
            />
        </MultiLevelDropdown>
    )
}