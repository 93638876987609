import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { ExerciseSpecification } from './exercise-specification';
import { ExerciseSet } from './exercise-set';
import { Exercise } from './exercisable';
import { UserMeal } from './user-meal';

export class ActivityLog extends RecordBase {
    static NAME = 'ActivityLog'

    static ASSOCS = {
        user: { type: 'belongsTo' },
        exerciseSpecification: { type: 'belongsTo' }
    }

    static createFor(exercise,date,user) {
        const log = new this({ date, user });
        const spec = new ExerciseSpecification({ activityLog: log, exercise, exerciseId: exercise.id, setType: exercise.isGeneralActivity() ? 105 : 101 });
        const set = new ExerciseSet({ distance: null, time: null, exerciseSpecification: spec });
        log.exerciseSpecification = spec;
        spec.exerciseSets = [ set ];
        return log;
    }

    static createFromRedux = activity => {
        const log = new this({ ...activity });
        const spec = log.exerciseSpecification = new ExerciseSpecification({ ...activity.exerciseSpecification, activityLog: log });
        spec.exercise = new Exercise({ ...spec.exercise });
        spec.exerciseSets = [ new ExerciseSet({ ...spec.exerciseSets[0], exerciseSpecification: spec }) ];
        return log;
    }

    exercise() {
        return this.spec().exercise;
    }

    spec() {
        return this.exerciseSpecification;
    }

    exerciseSet() {
        return this.exerciseSpecification.exerciseSets[0];
    }

    initialFormValues() {
        let vals = this.user.isMetric() ? { kmDistance: '' } : { mileDistance: '' };
        vals.minSecTime = _.stopwatchFormat(this.secondsDuration());
        return _.parseObjForForm(vals);
    }

    formValues(time) {
        return { id: this.id || '', date: this.date, exerciseSpecification: { id: this.spec().id || '', exerciseId: this.exercise().id, setType: this.spec().setType, exerciseSets: [{ id: this.exerciseSet().id || '', logType: UserMeal.LOGGED, distance: this.exerciseSet().distance, time: (time || Math.max(this.exerciseSet().time || 0,1)) }] }}
    }

    isDeleted() {
        return this.deleted;
    }

    fromHealthkit() {
        return !_.isBlank(this.uuid);
    }

    name() {
        if(this.fromHealthkit()) {
            return this.activityName;
        }
        return this.exerciseSpecification.exerciseName();
    }

    secondsDuration() {
        if(this.fromHealthkit()) {
            return this.time;
        }
        return this.exerciseSpecification.exerciseSets[0].time;
    }

    kmDistance() {
        return _.roundToF(this.distance/1000.0,0.1);
    }

    mileDistance() {
        return _.roundToF(this.distance/1600.0,0.1);
    }

    unitDistance() {
        const attr = this.user.isMetric() ? 'kmDistance' : 'mileDistance';
        if(this.fromHealthkit()) {
            return this[attr]();
        }
        return this.exerciseSpecification.exerciseSets[0][attr]();
    }

    distSuffix(t) {
        return this.user.isMetric() ? t('km') : t('mi');
    }

    toRedux() {
        return {
            startedAt: this.startedAt,
            finishedAt: this.finishedAt,
            date: this.date,
            exerciseSpecification: { ..._.pick(this.exerciseSpecification,['setType','exerciseId']), exercise: this.exerciseSpecification.exercise.persistAttrs(), exerciseSets: [this.exerciseSpecification.exerciseSets[0].persistAttrs()] }
        }
    }

}

registerInflection('activityLog','activityLogs',ActivityLog);