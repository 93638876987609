import React from 'react';
import BodyMeasurements from 'partials/BodyMeasurements';
import { workoutSetupMatch, pickExercisePath, emailPaywallMatches, allMainMatches, bodyMeasurementsPath, clientDetailsMatch, viewClientAssignedFormMatch, viewAssignedFormMatch, progressMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import PaywallWrapper from 'partials/PaywallWrapper';
import ClientSwitcher from 'partials/ClientSwitcher';

const transitionMap = {
    rules: [
        [[...allMainMatches,progressMatch,clientDetailsMatch, viewClientAssignedFormMatch, viewAssignedFormMatch],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath], transitions.slideOver]
    ]
};

export const ClientBmShortcut = (props) => {
    const { t } = useTranslation();

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('Body Stats')} afterPath={bodyMeasurementsPath} />
    )
}

const BodyMeasurementsPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions } = props;

    return (
        <PaywallWrapper blockTypes={['hard','soft']} context="body_measurements">
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t('Body Stats')}
            >
                <BodyMeasurements />
            </SimpleNavPage>
        </PaywallWrapper>
    )
}

export default BodyMeasurementsPage