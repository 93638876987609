import { BasicModalListingBox } from 'components/Form';
import { ScrollableFormModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { modalPathFor, viewSharedMealsPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';

class PickPersonContent extends React.Component {

    render() {
        const { miniProfiles, week, renderHeader, renderScrollable, renderFooter, closeAndGo, t } = this.props;

        return (
            <React.Fragment>
                {renderHeader()}
                {renderScrollable({ children: (
                    <div className="text-center pt20 pb20 pr10 pl10">
                        {miniProfiles.length > 0 && miniProfiles.map(miniProfile => {
                            
                            return (
                                <BasicModalListingBox 
                                    key={miniProfile.id}
                                    title={miniProfile.name} 
                                    onClick={() => closeAndGo(viewSharedMealsPathFor(week,miniProfile.id))} 
                                />
                            )
                        })}
                        {miniProfiles.length === 0 && (
                            <SlightEmphasisIconNote text={t("no shared meals")} />
                        )}
                    </div>
                )})}
                {miniProfiles.length === 0 && renderFooter({ children: (
                    <Button color="primary" rounded className="modal-close">
                        {t('Ok')}
                    </Button>
                )})}
            </React.Fragment>
        )
    }
}

export const viewSharedMealsPickSuffix = '/pick_shared';

const ViewSharedMealsSelectModal = ({ week, miniProfiles }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={modalPathFor(viewSharedMealsPickSuffix,pathname)} 
            exact 
            icon={'hand-pointer'}
            title={t("Select a Person")}
            modalRedirectCheck={() => (miniProfiles.length === 1 ? viewSharedMealsPathFor(week,miniProfiles[0].id) : null)}
            render={({ renderScrollable, renderHeader, renderFooter, match, closeAndGo, close }) => (
                <PickPersonContent
                    week={week}
                    miniProfiles={miniProfiles}
                    match={match}
                    renderScrollable={renderScrollable}
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                    closeAndGo={closeAndGo}
                    close={close}
                    t={t}
                />
            )} 
        />
    )
}

export default ViewSharedMealsSelectModal;

