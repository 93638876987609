import { BasicModalListingBox } from 'components/Form';
import { ScrollableFormModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { mealPlanPickMatchFor, standaloneMealPlanPathFor } from 'config/paths';
import * as _ from 'lib/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import Button from 'components/Button';

const PickPlanContent = ({ trainer, match: { params: { clientId } }, renderHeader, renderScrollable, renderFooter, closeAndGo, close }) => {
    const client = trainer.clientById(clientId);
    const { t } = useTranslation();
    if(!client) {
        close();
        return '';
    }
    const weeks = client.mealPlanWeeksList();

    return (
        <React.Fragment>
            {renderHeader()}
            {renderScrollable({ children: (
                <div className="text-center pt20 pb20 pr10 pl10">
                    {weeks.length > 0 && weeks.map(({ name, week }) => {
                        
                        return (
                            <BasicModalListingBox 
                                id={`view-week-${week}-btn`}
                                key={week}
                                title={_.fallbackText(name,_.weekTitle(week,client.resolvedMpNameScheme()))} 
                                onClick={() => closeAndGo(standaloneMealPlanPathFor(client.id,week))} 
                            />
                        )
                    })}
                    {weeks.length === 0 && (
                        <SlightEmphasisIconNote text={t("no meal plans for client")} />
                    )}
                </div>
            )})}
            {weeks.length === 0 && renderFooter({ children: (
                                <Button color="primary" rounded className="modal-close">
                                    {t('Ok')}
                                </Button>
                    ) })}
        </React.Fragment>
    )
}

const PickMealPlanPopup = ({ trainer, basePath }) => {
    const { t } = useTranslation();

    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={mealPlanPickMatchFor(basePath)} 
            exact 
            icon={'hand-pointer'}
            title={t("Select Meal Plan")}
            render={({ renderScrollable, renderHeader, renderFooter, match, closeAndGo, close }) => (
                <PickPlanContent
                    trainer={trainer}
                    basePath={basePath}
                    match={match}
                    renderScrollable={renderScrollable}
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                    closeAndGo={closeAndGo}
                    close={close}
                    t={t}
                />
            )} 
        />
    )
}

const mapStateToPickModalProps = state => ({
    trainer: trainerRecordSelector(state)
})

export default connect(mapStateToPickModalProps)(PickMealPlanPopup)

