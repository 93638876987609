import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, progressionPickMatch, workoutPreviewMatch, workoutDoMatch, progressionShowMatch, editWorkoutTemplateMatch, editExerciseGroupMatch, pickExercisePath, allMainMatches, progressMatch } from 'config/paths';
import { updateProgressChartsLocal } from 'redux/actions';
import { connect } from 'react-redux';
import { StandaloneExerciseSearch } from 'partials/ExerciseSearchForm';
import { ListCardAction } from 'components/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const actionComp = (updateProgressChartsLocal,afterAction) => ({ exercisable, setBlockModals }) => {

    return (
        <ListCardAction id={`pick-exercise-${exercisable.id}-btn`} onClick={() => {
            updateProgressChartsLocal({ exerciseId: exercisable.id, exerciseName: exercisable.fullName() });
            afterAction();
        }}>
            <FontAwesomeIcon icon="check" />
        </ListCardAction>
    )
}

class PickLoggedExerciseContent extends React.Component {

    render() {
        const { updateProgressChartsLocal } = this.props;

        return (
            <StandaloneExerciseSearch 
                context={{id: '', type: ''}} 
                initialValues={{ searchTerm: '', equipmentTypes: [''], simpleTypes: [''], contextId: '', contextType: '' }} 
                basePath={pickExercisePath}
                actionComp={actionComp(updateProgressChartsLocal,this.finish)}
            />
        )
    }

    finish = () => {
        const { history } = this.props;

        if(!this.finished) {
            history.go(-1);
            this.finished = true;
        }
    }

    componentWillUnmount() {
        this.finished = true;
    }
}

const transitionMap = {
    rules: [
        [[ ...allMainMatches, progressMatch, workoutLogMatch, workoutPreviewMatch, workoutDoMatch, editWorkoutTemplateMatch, editExerciseGroupMatch ] ,transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch], transitions.slideOver]
    ]
};

class PickLoggedExercise extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { updateProgressChartsLocal, history, t, scrollRef } = this.props;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t("Pick Exercise")}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <PickLoggedExerciseContent 
                        updateProgressChartsLocal={updateProgressChartsLocal}
                        history={history}
                    />
                </SimpleNavContainer>
            </Page>
        )
    }
}

export const mapDispatchToProps = dispatch => ({
    updateProgressChartsLocal: (data) => dispatch(updateProgressChartsLocal(data))
})

export default connect(null,mapDispatchToProps)(withTranslation()(PickLoggedExercise));