import { ExerciseImplementation, registerInflection, RecordBase } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { Exercise } from 'lib/classes';

export class ProgressionScheme extends RecordBase {
    static NAME = 'ProgressionScheme'
    static ASSOCS = {
        exerciseTemplates: { type: 'hasMany' }
    }

    static METRIC_WT_INCR = [0.0,1.25,2.5,5.0,7.5,10.0,12.5,15.0];
    static IMPERIAL_WT_INCR = [0.0,2.5,5.0,10.0,15.0,20.0,25.0,30.0];

    static FREQUENCY_COL = [{ text: 'every workout', value: 0 },{ text: 'every week',value: 1 }, {text: 'every two weeks', value: 2 },{ text: 'every four weeks', value: 4 }]
    static FRERUQNCY_COL_MULTI = [['every workout cycle',-1],['at start of cycle',0],['every week',1],['every two weeks',2],['every four weeks',4]].map(arr => ({ text: arr[0], value: arr[1]}))
    static FREQUENCY_COL_SINGLE = [['every cycle',0],['every week',1],['every two weeks',2],['every four weeks',4]].map(arr => ({ text: arr[0], value: arr[1]}))
    static CROSS_CHECK_COL = [{ text: 'will not', value: 0}, {text: 'will', value: 1}];


    constructor(obj) {
        super(obj);
        this.allowedWeightTypes = _.filter(this.allowedWeightTypes,wt => !_.isBlank(wt)).map(st => Number(st));
        this.allowedSetTypes = _.filter(this.allowedSetTypes,setType => !_.isBlank(setType)).map(st => Number(st))
        this.params = (this.params || []).map(param => _.camelize(param));
    }

    defaults() {
        return this.defaultAttrs = this.defaultAttrs || { frequency: 0, weightIncrease: 0, deloadType: 0, crossCheck: 0, trainingMaxExercise: this.trainingMaxOptions[0].value }
    }

    isAllowedFor(template) {
        return (this.matchesExercisable(template.exercisable) && this.matchesSetType(template.setType) && this.matchesWeightType(template.weightType) && this.matchesOther(template))
    }

    matchesExercisable(exercisable) {
        if(exercisable.isWeighted()) {
            if(exercisable.maxType === 2) {
                return this.allowedWeightTypes.includes(1)
            } else {
                return this.allowedWeightTypes.includes(0)
            }
        } else if(exercisable.isProgression()) {
            return this.allowedWeightTypes.includes(3)
        } else {
            return this.allowedWeightTypes.includes(2)
        }
    }

    matchesSetType(setType) {
        return this.allowedSetTypes.includes(setType);
    }

    matchesWeightType(weightType) {
        if(weightType === 'referential') {
            return false;
        } else if(weightType === 'percent') {
            return true;
        } else {
            return !this.isLinkedToOther();
        }
    }

    matchesOther(template) {
        if(template.isInGroup()) {
            if(this.isScheduledLinear() || this.isTrainingMaxBased()) {
                return false;
            }
        }
        return true;
    }

    isLinkedToOther() {
        return this.methodName === 'linked_progression';
    }

    defaultedDesc() {
        return this.description || '';
    }

    defaultedParams() {
        if(this.isAutoregulated()) {
            return [ ...this.params, 'amrapSourceId', 'repThreshold1', 'repThreshold2', 'repThreshold3', 'weightIncrease1', 'weightIncrease2', 'weightIncrease3']
        }
        return this.params;
    }

    defaultedParamDefaults() {
        return this.paramDefaults || [];
    }

    parsedParams() {
        const params =  this.defaultedDesc().match(/{\w+}/g);
        return params.map(param => _.camelize(param.slice(1,param.length-1)))
    }

    descriptionMap() {
        return { 
            params: this.parsedParams(),
            strings: this.defaultedDesc().split(/{\w+}/)
        }
    }

    paramDefault(param) {
        const index = this.defaultedParams().indexOf(param);
        let val = null;
        if(index !== -1) {
            val = this.defaultedParamDefaults()[index];
        }
        return _.isBlank(val) ? this.defaults()[param] : val;
    }

    paramDefaultFor(param,template) {
        const { progressionParams: { [param]: templateVal }={} } = template;
        let val = (_.isBlank(templateVal) && templateVal !== '') ? this.paramDefault(param) : templateVal;
        if(_.isNumeric(val)) {
            val = Number(val);
        }
        return val;
    }

    formValuesFor(template) {
        const obj = _.arrToObj(this.defaultedParams(),param => this.paramDefaultFor(param,template));
        return _.parseObjForForm(obj);
    }

    isSimpleLinear() {
        return this.methodName === 'simple_linear';
    }

    isAutoperiodized() {
        return this.methodName === 'autoperiodized';
    }

    isScheduledLinear() {
        return this.methodName === 'scheduled_linear';
    }

    isTrainingMaxBased() {
        return this.methodName === 'training_max_based';
    }

    isAutoregulated() {
        return this.isScheduledLinear() || this.isTrainingMaxBased();
    }

    deloadTypeCol() {
        const col = [['no deload',0],['a 10% deload',1]].map(arr => ({ text: arr[0], value: arr[1] }))
        if(this.isSimpleLinear() || this.isScheduledLinear()) {
            col.push({text: 'a 1 week deload', value: 2});
            col.push({text: 'a 2 week deload', value: 3});
        }
        return col;
    }

    getColAndTip(record,param,curVal,t) {
        const scheme = this;
        let col, tooltip = null;
        switch(param) {
            case 'frequency': {
                col = ProgressionScheme.FREQUENCY_COL_SINGLE;
                if(curVal === 0) {
                    tooltip = 'A cycle is every time a full loop through every workout in the routine is completed. E.g. a routine made up of workouts A, B and C would increase weight every time workout C was completed';
                }
                if(scheme.isAutoperiodized() || scheme.isSimpleLinear()) {
                    col = ProgressionScheme.FREQUENCY_COL;
                    if(curVal === 0) {
                        tooltip = 'Every workout means every time this specific workout (or exercise group) is performed, so if there are multiple workouts in this routine that use this exercise those other workouts will be unaffected.';
                    }
                } else if(record.workoutRoutine().isMulticycle()) {
                    col = ProgressionScheme.FRERUQNCY_COL_MULTI;
                    if(curVal === -1) {
                        tooltip = `A workout cycle is every time a full loop through every workout in this cycle (${record.routineCycleName(t)}) is completed. E.g. a cycle made up of workouts A, B and C would increase weight every time workout C was completed`;
                    } else if(curVal === 0) {
                        tooltip = `That is, each time you repeat the routine, weight will be increased at the beginning of this cycle (${record.routineCycleName(t)}).`
                    }
                }
                break;
            }
            case 'weightIncrease': {
                col = record.weightIncreaseCol(t);
                break;
            }
            case 'deloadType': {
                col = scheme.deloadTypeCol();
                break;
            }
            case 'crossCheck': {
                col = ProgressionScheme.CROSS_CHECK_COL;
                break;
            }
            case 'trainingMaxExercise': {
                col = scheme.trainingMaxOptions;
                break
            }
            default:
                break;
        }
        return [col,tooltip];
    }

}

registerInflection('progressionScheme','progressionSchemes',ProgressionScheme);

export class ExerciseProgressionRequirement extends RecordBase {
    static NAME = 'ExerciseProgressionRequirement';
    static ASSOCS = { 
        exerciseTemplate: { type: 'belongsTo' },
        exerciseProgression: { type: 'belongsTo' }
    
    }

    formValues() {
        return _.parseObjForForm({ id: this.id, exerciseProgressionId: this.exerciseProgressionId, requirement: this.requirement })
    }

}

registerInflection('exerciseProgressionRequirement','exerciseProgressionRequirements',ExerciseProgressionRequirement);

export class ExerciseTemplate extends ExerciseImplementation {
    static NAME = 'ExerciseTemplate'
    static ASSOCS = { 
        exerciseSpecifications: { type: 'hasMany' },
        setTemplates: { type: 'hasMany', sortAttr: 'setOrder' },
        workoutTemplateChild: { type: 'hasOne', inverse: 'child' },
        exercisable: { type: 'belongsTo', poly: true },
        progressionScheme: { type: 'belongsTo' },
        exerciseProgressionRequirements: { type: 'hasMany' }
    }

    static FORM_DEPENDENCIES = ['setType', 'weightType', 'progressionSchemeId', 'distanceType','warmup','progressionParams']

    static cardioRpeCol = (t) => [
        { text: t('Easy'), value: 'slow'}, 
        { text: t('Moderate'), value: 'easy'}, 
        { text: t('Challenging'), value: 'moderate'},
        { text: t('High effort'), value: 'hard' },
        { text: t('Max effort'), value: 'fast' }
    ]

    static targetRpeCol = (t) => [
        { text: t('Light'), value: 'light' },
        { text: t('Moderate'), value: 'moderate' },
        { text: t('Heavy'), value: 'heavy' },
        { text: t('Max'), value: 'max' }
    ]

    static expLevelCol = [{text: 'Beg', value: 0},{text: 'Int', value: 1 },{ text: 'Adv', value: 2}]

    static SUPERSET_COL = ['Never','Always','Allow'].map(type => ({ text: type, value: type.toLowerCase() }))

    static GENDER_COL = [{ text: 'No', value: 0}, {text: 'Male', value: 1}, {text: 'Female', value: 2}]

    static referenceTypeCol = (t) => [{ text: _.upperFirst(t('percent')), value: 0 }, { text: t('Offset'), value: 1 }]

    constructor(obj) {
        super(obj);
        if(this.setTemplateParams) {
            this.setTemplateParams = this.setTemplateParams.map(param => _.camelize(param));
        }
    }

    exerciseSearchValues() {
        return { searchTerm: '', equipmentTypes: [''], simpleTypes: this.exercisable.simpleTypes(), contextId: this.id, contextType: 'ExerciseTemplate' }
    }

    getActionParams() {
        return { workoutRoutineId: this.workoutRoutine().id, workoutTemplateId: this.workoutTemplate().id, exerciseTemplateId: this.id }
    }

    routineCycleName(t) {
        return this.workoutTemplate().routineCycleName(t);
    }

    setTypeTooltip(t) {
        return t(`set type ${this.setType} tip`);
    }

    weightTypeTooltip(t) {
        return t(`${this.weightType} weight type tip`);
    }

    allowWeightType() {
        return this.isWeighted() && this.allowedWeightTypeArr().length > 0;
    }

    allowDistanceType() {
        return this.allowedDistanceTypeArr().length > 0;
    }

    allowEprs() {
        return this.currentUser && this.currentUser.isAdmin() && !this.isWeighted();
    }

    allowProgressionScheme() {
        return this.allowedProgressionSchemeArr().length > 0;
    }

    formSupersetType() {
        if(this.isEdge() || this.isIrregularType()) {
            return null;
        } else {
            if(this.currentUser && this.currentUser.isAdmin()) {
                return 'select';
            } else {
                return 'check';
            }
        }
    }

    isWarmupable() {
        return this.repsDefined();
    }

    isInGroup() {
        return this.workoutTemplate().isGroup();
    }

    allowedSetTypeArr() {
        return this.exercisable.allowedSetTypes;
    }

    allowedWeightTypeArr() {
        if(this.isMaxTest()) {
            return [];
        }
        return this.exercisable.allowedTemplateWeightTypes;
    }

    allowedDistanceTypeArr() {
        return (this.exercisable.canHaveDistance() && this.isDistanceBased()) ? [0,1,2] : [];
    }

    allowedProgressionSchemeArr() {
        return _.filter(this.progressionSchemes,scheme => scheme.isAllowedFor(this))
    }

    allowedProgressionSchemeIdArr() {
        return this.allowedProgressionSchemeArr(this.progressionSchemes).map(scheme => scheme.id).unshift(null);
    }

    allowedRepMaxRepsArr() {
        return [1,2,3,4,5,6,7,8,9,10];
    }
    
    allowedWarmupArr() {
        return [false,true];
    }

    allowedGenderSpecificArr() {
        return this.workoutRoutine().isGenerator() ? [0,1,2] : [];
    }

    allowedSupersetArr() {
        return this.constructor.SUPERSET_COL.map(opt => opt.value);
    }

    selectCol(t,type) {
        const exerciseAttrName = type === 'weightType' ? 'templateWeightType' : type;
        return this[`allowed${_.upperFirst(type)}Arr`]().map(value => ({ text: Exercise.textFor(exerciseAttrName,value,t), value: value }))
    }

    repMaxRepsCol(t) {
        return this.allowedRepMaxRepsArr().map(rmr => ({ text: `${rmr} ${t('rep max')}`, value: rmr }));
    }

    progressionSchemeCol() {
        const arr = this.allowedProgressionSchemeArr();
        arr.unshift(null);
        return _.mapSelectOptions(arr,scheme => ({ text: scheme.name, value: scheme.id }))
    }

    weightIncreaseCol(t) {
        if(this.currentUser && this.currentUser.isMetric()) {
            return ProgressionScheme.METRIC_WT_INCR.map((wt,i) => {
                const femWt = wt/2;
                return { text: `${wt} ${t('kgs')} for men/${femWt} ${t('kgs')} for women`, value: i };
            })
        } else {
            return ProgressionScheme.IMPERIAL_WT_INCR.map((wt,i) => {
                const femWt = wt/2;
                return { text: `${wt} ${t('lbs')} for men/${femWt} ${t('lbs')} for women`, value: i };
            })
        }
    }

    selectedProgressionScheme() {
        return _.find(this.allowedProgressionSchemeArr(),scheme => scheme.id === this.progressionSchemeId);
    }

    user() {
        return this.workoutTemplate().user();
    }

    workoutRoutine() {
        return this.workoutTemplate().workoutRoutine;
    }

    isMetric() {
        if(!this.user()) {
            return false;
        }

        return this.user().isMetric(this.exercisable);
    }

    formValues() {
        let attrs = [
            'id',
            'setType',
            'repMaxReps',
            'supersetType',
            'weightType',
            'referenceType',
            'distanceType',
            'comments',
            'progressionSchemeId',
            'warmup'
        ];
        if(this.currentUser && this.currentUser.isAdmin()) {
            attrs.push('genderSpecific');
        }

        const vals = _.parseObjForForm({ 
            ...this.resolveFormValues(_.pick(this,attrs)), 
            workoutRoutineId: this.workoutRoutine().id, 
            workoutTemplateId: this.workoutTemplate().id,
            progressionParams: this.progressionParamFormValues()
        })
        if(this.currentUser && this.currentUser.isAdmin() && this.allowEprs()) {
            return { ...vals, exerciseProgressionRequirements: this.eprFormValues() }
        }
        return vals;
    }

    eprFormValues() {
        return this.bannableProgs.map(prog => {
            const epr = _.find(this.eprs,epr => epr.exerciseProgressionId === prog.id);
            if(epr) {
                return epr.formValues();
            }
            return _.parseObjForForm({ id: null, exerciseProgressionId: prog.id, requirement: null });
        })
    }

    progressionParamFormValues() {
        const scheme = this.selectedProgressionScheme();
        if(scheme) {
            return scheme.formValuesFor(this);
        }
        return {};
    }

    overriddenMappedParams() {
        return _.flatMap((this.overriddenParams || []),(param) => {
            switch(param) {
                case 'weight':
                    return ['weight','kgWeight'];
                case 'targetRpe': 
                    return [param,'targetPace'];
                case 'restTime': 
                    return [param,'minSecRestTime'];
                case 'reps':
                    return [param,'isoTime'];
                case 'time':
                    return [param,'minSecTime'];
                case 'distance':
                    return [param,'kmDistance','mileDistance'];
                default: 
                    return param;
            }
        })
    }

    showGeneratorParams(user) {
        return (user && user.isAdmin() && this.workoutRoutine().isGenerator());
    }

    getSetTemplateParams(user) {
        return this.showGeneratorParams(user) ? [ ...this.setTemplateParams, 'optional', 'experienceLevel' ] : this.setTemplateParams; 
    }

    mappedTemplateParams(t,user) {
        const map = {};
        const setTemplateParams = this.getSetTemplateParams(user);
        setTemplateParams.forEach(param => {
            switch(param) {
                case 'weight':
                    if(this.isMetric()) {
                        map.kgWeight = `${t('Wt(units)',{units: t('kgs')})}`;
                    } else {
                        map.weight = `${t('Wt(units)',{units: t('lbs')})}`;
                    }
                    break;
                case 'targetRpe': {
                    if(this.hasTargetPace()) {
                        map.targetPace = t('Pace');
                    } else {
                        map.targetRpe = t('Weight');
                    }
                    break;
                }
                case 'referenceTemplateId': {
                    map.referenceTemplateId = t('Weight');
                    break;
                }
                case 'repMaxPercent': {
                    map.repMaxPercent = `% ${t('XRM',{ reps: (this.repMaxReps || 1)})}`;
                    break;
                }
                case 'arbitraryMeasure': {
                    map.arbitraryMeasure = this.exercisable.arbitraryMeasure;
                    break;
                }
                case 'count': {
                    map.count = t('Sets')
                    break;
                }
                case 'restTime': {
                    map.minSecRestTime = t('Rest');
                    break;
                }
                case 'reps': {
                    if(this.isIsometric()) {
                        map.isoTime = t('Time');
                    } else {
                        map.reps = t('Reps');
                    }
                    break;
                }
                case 'amrap': {
                    map.amrap = `${t('AMRAP')}?`;
                    break;
                }
                case 'time': {
                    map.minSecTime = t('Time');
                    break;
                }
                case 'distance': {
                    if(this.useKm()) {
                        map.kmDistance = t("Dist(units)",{ units: t('km')});
                    } else if (this.useMiles()) {
                        map.mileDistance = t("Dist(units)",{ units: t('mi')});
                    } else {
                        map.distance = t("Dist(units)",{ units: t('m')});
                    }
                    break;
                }
                case 'optional': {
                    map.optional = 'Opt?';
                    break;
                }
                case 'experienceLevel': {
                    map.experienceLevel = 'Exp?'
                    break;
                }
                default:
                    map[param] = param;
                    break;
            }
        })
        return _.mapValues(map,(title,key) => (this.overriddenMappedParams().includes(key) ? `${title}*` : title ));
    }

    workoutTemplate() {
        return this.workoutTemplateChild.workoutTemplate;
    }

    hasProgressionOverrides() {
        return this.overriddenParams.length > 0;
    }

    isExerciseTemplate() {
        return true;
    }

    isExerciseGroup() {
        return false;
    }

    isIrregularType() {
        return this.isMaxTest() || this.isVideoOnly() || this.isGeneralActivity();
    }

    canHaveMultipleSets() {
        return !this.isIrregularType();
    }

    isEdge() {
        if(this.workoutTemplate().isAlternator()) {
            return true;
        }

        const next = this.workoutTemplate().nextChild(this.workoutTemplateChild);
        const hasNext = next && next.child.isExerciseTemplate() && next.child.canHaveMultipleSets();

        return !hasNext;
    }

    isSuperset() {
        return this.supersetType === 'always' && !this.isEdge();
    }

    isSupersetChild() {
        const prev = this.workoutTemplate().prevChild(this.workoutTemplateChild);
        return prev && prev.child.isExerciseTemplate() && prev.child.isSuperset();
    }

    supersetLabel(t) {
        const superset = this.workoutTemplate().supersetFor(this);
        if(!superset) {
            return t('Superset');
        }
        return superset.length > 2 ? t('Circuit') : t('Superset');
    }

    resolvedExerciseId() {
        if(this.isLinkedToOther()) {
            return Number(this.progressionParams.trainingMaxExercise);
        } else {
            return null;
        }
    }

    isLinkedToOther() {
        return (!_.isBlank(this.progressionSchemeId) && this.progressionParams && !_.isBlank(this.progressionParams.trainingMaxExercise));
    }

    needsToPickWeight() {
        return (this.isWeighted() && !(this.isMaxTest() || this.isWeightSet() || this.canTestRepMax()));
    }

    exercisableName(t) {
        return this.exercisable.fullName(t);
    }

    isCardio() {
        return this.exercisable.cardio;
    }

    useMeters() {
        return this.distanceType === 0;
    }

    useKm() {
        return this.distanceType === 1;
    }

    useMiles() {
        return this.distanceType === 2;
    }

    hasTargetPace() {
        return this.isCardio() && ![2,3,4,102,103].includes(this.setType);
    }

    isWeighted() {
        return this.exercisable.isWeighted();
    }

    hasBaseWeight() {
        return this.exercisable.hasBaseWeight();
    }

    isMaxTest() {
        return this.setType === 4;
    }

    isVideoOnly() {
        return this.exercisable.isVideoOnly();
    }

    isRegularReps() {
        return this.setType === 0;
    }

    isPercentMax() {
        return this.weightType === 'percent';
    }

    isRpe() {
        return this.weightType === 'rpe';
    }

    isReferential() {
        return this.weightType === 'referential';
    }

    isSpecificWeight() {
        return this.weightType === 'specific';
    }

    firstSetNeedsWeight() {
        return this.setTemplates[0] && !this.setTemplates[0].isWeightSet();
    }

    isWeightSet() {
        return (this.isSpecificWeight() && !this.firstSetNeedsWeight());
    }

    canTestRepMax() {
        return this.shouldTestRepMax;
    }

    isIsometric() {
        return this.exercisable.isIsometric();
    }

    isGeneralActivity() {
        return this.exercisable.isGeneralActivity();
    }

    countSuffix(t) {
        return this.isIsometric() ? t('Seconds') : t('Reps');
    }

    isProgression() {
        return (this.exercisable.isProgression());
    }

    minRepsForProgStr(t) {
        const reps = this.minRepsForProg();

        return `${reps} ${this.countSuffix(t).toLowerCase()}`;
    }

    minRepsForProg() {
        if(this.isTimed()) {
            if(this.isIsometric()) {
                return Math.max( ...this.setTemplates.map(set => set.time));
            } else {
                return 20;
            }
        } else {
            return Math.max( ...this.setTemplates.map(set => set.minReps));
        }
    }
}

registerInflection('exerciseTemplate','exerciseTemplates',ExerciseTemplate);