import React, { Component, useState } from 'react';
import { AsyncSpinnerButton, ConfirmActionButton, FixedButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { modalPathFor } from 'config/paths';
import LinkButton from 'components/LinkButton';
import { useTranslation } from 'react-i18next';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { ActivityTab, ActivityTabs } from 'components/BottomNav';
import { AsyncCardAction, ListCard, ListCardAction, ListCardTitle, ListCardActionLink } from 'components/List';
import { Loader } from 'components/LoadingHOC';
import * as _ from 'lib/utilities';
import { Link } from 'components/Routing';
import requestStateHandler from 'components/RequestStateHandler';
import Collapse from 'components/Collapse';
import { MainHeading, SlightEmphasisIconNote } from 'components/Typography';
import classnames from 'classnames';
import { TagButtons } from 'components/Form';
import AnimTo from 'components/AnimTo';
import Dropdown from 'components/Dropdown';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import { TrainerSelectModal, trainerSelectSuffix } from './TrainerSelectModal';
import { UserIcon } from './Utilities';
import { DropdownLinkTPB } from './ProRequiredButton';
import { TRAINING } from 'config/settings';
import { exportRoutinePDFSuffix } from './ExportPDFModal';
import { previewRoutinePdfUrlFor } from 'lib/api';

export const RecordTabs = ({ importCount, notTop, basePath }) => {
    const { t } = useTranslation();
    const paths = [`${basePath}/personal`,`${basePath}/db`,`${basePath}/team`];
    const labels = [<React.Fragment>{t("Mine")}{importCount > 0 && (<AnimTo top={'150px'} left={'150px'} tag='b' className="primary-text"> +{importCount}</AnimTo>)}</React.Fragment>,t("Database"),t("Team")];

    return (
        <ActivityTabs className={classnames({ 'not-top': notTop, 'off-white': notTop, 'bottom-border': notTop })}>
            {paths.map((path,index) => <ActivityTab id={"routines-tab-link-"+index} path={path} label={labels[index]} key={path} />)}
        </ActivityTabs>
    )
}

const MyRecordOptions = ({ record, importRecord, newDestroy, t }) => {
    const { pathname } = useLocation();

    return (
        <React.Fragment>
            <li>
                <Link to={record.editPath(pathname)} id={`wrt-edit-btn-${record.id}`}>
                    <FontAwesomeIcon icon={['far','edit']} /> {t("Edit")}
                </Link>
            </li>
            <li>
               <Link to={record.assignToClientsPath()} id={`wrt-assign-btn-${record.id}`}>
                   <FontAwesomeIcon icon='users' /> {t("Assign to Client(s)")}
               </Link>
            </li>
            {record.isRoutine() && (
                <DropdownLinkTPB type={TRAINING} id="pdf-routine-btn" label={t("Export PDF")} icon={['far','file-export']} to={modalPathFor(exportRoutinePDFSuffix,pathname,{ routineId: record.id })} allowUninit={false} />
            )}
            <li onClick={() => importRecord(record.id,true)} id={`wrt-copy-btn-${record.id}`}>
                <span>
                    <FontAwesomeIcon icon={['far','copy']} /> {t("Copy")}
                </span>
            </li>
            <ConfirmActionButton 
                onClick={() => newDestroy()}
                render={({ onClick, ready, clickRef }) => (
                    <li onClick={onClick} ref={clickRef} className={ready ? null : 'dont-close-dropdown'} id={`wrt-delete-btn-${record.id}`}>
                        <span className={ready ? 'red-text' : null}>
                            <FontAwesomeIcon icon="times" /> {t("Delete")}
                        </span>
                    </li>
                )}
            />
        </React.Fragment>
    )
}

const MyRecordDropdown = ({ record, importRecord, newDestroy, t }) => {

    return (
        <Dropdown 
                contentComp='ul'
                options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                triggerRender={({ ref, target }) => {
                    return (
                            <ListCardAction ref={ref} data-target={target} id={`wrt-dropdown-btn-${record.id}`}>
                                <FontAwesomeIcon icon="ellipsis-h" />
                            </ListCardAction>
                    )
                }}
                contentRender={({ recalcDims }) => {
                    return (
                        <MyRecordOptions record={record} importRecord={importRecord} newDestroy={newDestroy} t={t} />
                    )
                }}
            />
    )
}

let RecordListing = ({ record, tabType, importRecord, t, destroy, PersonalMenu }) => {

    const [expanded,setExpanded] = useState(false);
    const { pathname } = useLocation();
    const unowned = (tabType === 'team' || tabType === 'db');

    const newDestroy = () => {
        const newRecord = new record.constructor({ ...record, inactive: true });
        destroy(newRecord);
    }

    if(!record.isActive()) {
        return '';
    }

    return (
        <ListCard className="wrt-list-card" collapse={unowned ? (<Collapse isOpen={expanded}>
            <div className="ma5"><SlightEmphasisIconNote text={record.resolvedDescription(t)} /></div>
        </Collapse>) : null}>
            <ListCardTitle linkTo={record.editPath(pathname)}>{record.resolvedName(t)}</ListCardTitle>
            {record.isRoutine() && (<ListCardActionLink to={previewRoutinePdfUrlFor({ id: record.id},true,false)} target="_blank">
                <FontAwesomeIcon icon={"info-circle"} />
            </ListCardActionLink>)}
            {tabType === 'personal' && <PersonalMenu record={record} t={t} newDestroy={newDestroy} importRecord={importRecord} />}
            {unowned && <AsyncCardAction id={`import-team-wrt-btn-${record.id}`} icon={['far','file-import']} load={() => importRecord(record.id)} label={t('Import')} />}
            {!record.isRoutine() && unowned && (<ListCardAction onClick={() => setExpanded(!expanded )}>
                <FontAwesomeIcon icon={"info-circle"} />
            </ListCardAction>)}
        </ListCard>
    )
}

RecordListing = requestStateHandler({ destroy: 'record' })(RecordListing)



export const TrainerSelectButton = ({ trainer, selTrainerId }) => {
    const selTrainer = trainer.trainerById(selTrainerId);
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <LinkButton rounded outlined color="primary" className="show-overflow" to={modalPathFor(trainerSelectSuffix,pathname)}>
            <FontAwesomeIcon icon={['far','filter']} />
            {t('Filter')}
            {selTrainer && (<UserIcon user={selTrainer} className="xs usr-filter" />)}
        </LinkButton> 
    )
}

const TrainerRecordButtons = ({ trainer, selTrainerId, selectTrainer }) => {
    const { t } = useTranslation();
    const trainers = trainer.allOtherTrainers();
    const usePopup = trainer.usePopupForTrainerSelect();
    const col = [{ text: t('All'), value: '' }, ...trainers.map(trainer => ({ text: trainer.initialsName(), value: trainer.id }))]
    const classNames = classnames("added-people-wrapper wrap fake-pad")
    const sharedProps = { setFieldTouched: () => {}, setFieldValue: (name,value) => selectTrainer(value), name: 'trainerId', values: { trainerId: selTrainerId } }

    if(trainer.teamSize <= 1) {
        return '';
    }

    return (
        <div>
            <div className="font-grey no-wrap">{t('Team member')}:</div>
            <div className={classNames}>
                {!usePopup && (<TagButtons
                    {...sharedProps}
                    col={col}
                    btnId={(val) => `toggle-trainer-${val}`}
                    single
                    buttonProps={{ className: 'btn-no-shadow ma2' }}
                />)}
                {usePopup && (
                    <TrainerSelectButton 
                        trainer={trainer}
                        selTrainerId={selTrainerId}
                    />
                )}
            </div>
        </div>
    )

}

const RecordsListCore = ({ records, tabType, importRecord, destroy, PersonalMenu }) => {
    const { t } = useTranslation();


    return (<React.Fragment>
            {_.sortBy(records,wrt => wrt.resolvedName(t)).map(rec => (
                <RecordListing 
                    key={rec.id} 
                    record={rec} 
                    tabType={tabType} 
                    t={t} 
                    importRecord={importRecord} 
                    destroy={destroy} 
                    PersonalMenu={PersonalMenu} 
                />
            ))}
    </React.Fragment>)
}

const RecordsList = ({ records, recordType: type, importRecord, destroy, trainer, selTrainerId, selectTrainer, isLarge, recordNameP, recordNameS, NewModal, EditModal, ExportModal, PersonalMenu, loadRecords }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const finalRecords = _.filter(records, rec => !rec.inactive);
    const selTrainer = trainer.trainerById(selTrainerId);
    const newPath = modalPathFor('/new',pathname);
    const listProps = { records: finalRecords, tabType: type, importRecord, destroy, PersonalMenu };
    const incrementalLoading = type === 'team' && trainer.hasLargeTeam();

    let emptyMessage = type === 'personal' ? t('empty records tip',{ record_name: recordNameP }) : (_.isBlank(selTrainerId) ? t('empty team records', { record_name: recordNameP }) : t('empty trainer records', { name: selTrainer.initialsName(), record_name: recordNameP }))
    
    if(type === 'team' && trainer.hasLargeTeam() && _.isBlank(selTrainer)) {
        emptyMessage = t("select to view routines");
    }
    let emtpyBtn = type === 'personal' ? (
        <AsyncSpinnerButton 
            buttonProps={{
                noShadow: true,
                color: 'primary',
                label: t('Import Sample Record',{ record_name: recordNameS }),
                id: "import-sample-btn"
            }}
            icon={['far','file-import']}
            action={() => importRecord()}
        />
    ) : null;

    return (
        <div className="mt15 mb150">
            {type === 'team' && (
                <React.Fragment>
                    <TrainerRecordButtons trainer={trainer} selTrainerId={selTrainerId} selectTrainer={selectTrainer} />
                    <TrainerSelectModal trainer={trainer} initialTrainerId={selTrainerId} submit={({ trainerId }) => selectTrainer(trainerId)} excludeSelf={true} noAll />
                </React.Fragment>
            )}
            {type === 'personal' && (
                <LinkButton
                    to={newPath}
                    rounded
                    color="primary"
                    outlined={finalRecords.length === 0}
                    className="hide-on-med-and-down"
                >
                    <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span>{t("New")}</span>
                </LinkButton>
            )}
            {finalRecords.length === 0 && (
                <div className="mb10 mt10">
                    <SlightEmphasisIconNote text = {emptyMessage} btn={emtpyBtn} />
                </div>
            )}
            {!incrementalLoading && (
                <RecordsListCore {...listProps} />
            )}
            {incrementalLoading && (
                <Loader
                    key={selTrainerId}
                    successComponent={RecordsListCore}
                    type="padded"
                    load={() => loadRecords({ type, trainerId: selTrainerId })}
                    preloaded={() => (finalRecords.length !== 0)}
                    alwaysLoad
                    {...listProps}
                />
            )}
            {type === 'personal' && (
                <React.Fragment>
                    <NewModal />
                    {EditModal && <EditModal />}
                    {ExportModal && <ExportModal />}
                    {!isLarge && <FixedButton id="new-routine-btn" icon="plus" onClick={() => history.push(newPath)} />}
                </React.Fragment>
            )}
        </div>
    )
}

class IndexPage extends Component {

    componentDidUpdate() {
        const { match: { params: { type }}, importCount, clearImports } = this.props;

        if(importCount > 0 && type === 'personal') {
            clearImports();
        }
    }

    render() {
        const { 
            Wrapper=CenteredActivityContainer, 
            trainer, 
            title, 
            dbRecords, 
            load, 
            history, 
            trainerId, 
            match: { params: { type }}, 
            recordName, 
            recordNameS,
            recordNameP,
            importRecord, 
            destroy, 
            selectTrainer, 
            addImport, 
            isLarge, 
            importCount,
            basePath,
            successComponent=RecordsList,
            NewModal,
            EditModal,
            PersonalMenu=MyRecordDropdown,
            ExportModal,
            hideTabs
        } = this.props;

        let records = trainer[`active${recordName}`]();
        const selTrainerId = trainer.resolvedTrainerForRecords(trainerId);
        if(type === 'db') {
            records = dbRecords;
        } else if(type === 'team') {
            records = trainer[`team${recordName}`](selTrainerId);
        }

        return (
            <div>
                {isLarge && (<CenteredActivityContainer className="mb0">
                    <MainHeading>{title}</MainHeading>
                    {!hideTabs && (<RecordTabs importCount={importCount} notTop basePath={basePath} />)}
                </CenteredActivityContainer>)}
                <Wrapper>
                    <Loader
                        key={type}
                        successComponent={successComponent}
                        type="page"
                        recordType={type}
                        isLarge={isLarge}
                        load={() => load({ type })}
                        loadRecords={load}
                        preloaded={() => (type === 'personal' || records.length !== 0)}
                        alwaysLoad
                        trainer={trainer}
                        selTrainerId={selTrainerId}
                        records={records}
                        importRecord={(id,popup) => {
                            return importRecord(id,popup).then(resp => {
                                if(!popup)
                                {
                                    addImport();
                                }
                                return Promise.resolve(resp);
                            })
                        }}
                        selectTrainer={selectTrainer}
                        destroy={destroy}
                        history={history}
                        NewModal={NewModal}
                        EditModal={EditModal}
                        ExportModal={ExportModal}
                        PersonalMenu={PersonalMenu}
                        recordNameS={recordNameS}
                        recordNameP={recordNameP}
                    />
                </Wrapper>
            </div>
        );
    }
}

export default IndexPage;
