
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ResponsiveTabs from 'components/ResponsiveTabs';
import FormikWrapper from 'components/FormikWrapper';
import { CardForm, DefaultSelect, FullInput, LoadingSubmitButton, ToggleButton } from 'components/Form';
import { User, Exercise, macroCals, allMacros } from 'lib/classes';
import ChartWrapper from 'components/ChartWrapper';
import { Loader } from 'components/LoadingHOC';
import Card, { BasicCardHeader } from 'components/Card';
import { SlightEmphasisIconNote, TitleWithTip } from 'components/Typography';
import * as _ from 'lib/utilities';
import LinkButton from 'components/LinkButton';
import { bodyMeasurementsPath, modalPathFor, pickExercisePath, progressPath, progressPathFor, weightCalcModalPostfix } from 'config/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Progress from 'components/Progress';
import { WeightCalculatorModal } from './ExerciseModals';
import { BottomButtons } from 'components/Button';
import Tooltipped from 'components/Tooltipped';
import { connect } from 'react-redux';
import { userRecordSelector, progressChartsSelector, trainerRecordSelector } from 'redux/selectors';
import { updateProgressChartsLocal, updateProgressCharts, updateSettings, loadBmHistory } from 'redux/actions';
import { validateWeight } from './UserFields';
import { SmallerNumberInput } from 'components/TextInput';
import { brandName, calRoundFactor, dateFormat, macroColors } from 'config/settings';
import classnames from 'classnames';
import moment from 'moment';
import { ActivityTabs, SectionTab } from 'components/BottomNav';
import { metricProgressColors, percentileTipFor } from './BodyMeasurements';
import MediaQuery from 'components/MediaQuery';
import { SlightEmphasisTip } from './Utilities';
import { useLocation } from 'react-router-dom';

const percentiles = [0,25,50,75,100];
const levels = ['Untrained','Beginner','Intermediate','Advanced','Elite'];

const percentileColor = (percentile,redGreen=false) => {
    const rgb0 = redGreen ? [244,67,54] : [100,255,218]
    const rgb50 = redGreen ? [255,235,59] : [3,169,244]
    const rgb100 = redGreen ? [139, 195, 74] : [156,39,176]
    let newRgb = []
    if(percentile < 50) {
        const weight = percentile/50.0
        for(let i=0; i < 3; i++) {
            newRgb.push(Math.round((1-weight)*rgb0[i]+weight*rgb50[i]))
        }
    } else {
        const weight = (percentile-50)/50.0;
        for(let i=0; i < 3; i++) {
            newRgb.push(Math.round((1-weight)*rgb50[i]+weight*rgb100[i]))
        }
    }
    return newRgb
}

const percentileColorStr = (percentile,redGreen) => `rgba(${percentileColor(percentile,redGreen).join(', ')}, 1)`;

const percentileChartConfig = (percentile,label,redGreen) => {
    const colors = percentileColorStr(percentile,redGreen);
    return {
        data: {
            datasets: [{
              data: [percentile, 100-percentile],
              backgroundColor: [
                colors,
                'rgba(0, 0, 0, 0)'
              ],
              label: label && label.titleize
            }],
            labels: [label, '']
        },
        options: {responsive: true,
          legend: {
              display: false,
          },
          title: {
              display: false
          },
          animation: {
              animateScale: false,
              animateRotate: false
          }, 
          tooltips: {enabled: false},
            plugins: {
                labels: {
                    render: () => ''
                }
            }
        }
    }
}

function parseGchartToStackedMacrosJs(gchartData,showWarnings,t=(val) => (val)) {
    if(!gchartData) {
        return  { dayLabels: [], datasets: [] };
    }
    const orderedMacros = [ ...allMacros ];
    let calValMap = {};
    let backgroundColors = {};
    let warnings = {}
    orderedMacros.forEach(macro => {
        calValMap[macro] = []
        backgroundColors[macro] = []
        warnings[macro] = []
    });
    const dataRows = gchartData.slice(1,gchartData.length);
    const dFormat = dataRows.length <= 7 ? 'ddd' : 'M/D';
    let datasets = [];
    let labels = [];
    let checkLabels = [];
    let exLabels = [];

    dataRows.forEach(row => {
        const rowWarnings = showWarnings ? showWarnings({ row, t, date: row[0] }) : {};
        labels.push(moment(row[0]).format(dFormat));
        for(let i=1; i < row.length; i++) {
            const macro = orderedMacros[i-1];
            calValMap[macro].push(row[i]);
            warnings[macro].push(rowWarnings[macro]);
            if(rowWarnings[macro]) {
                backgroundColors[macro].push('#e51c23');
            } else {
                backgroundColors[macro].push(macroColors[macro]);
            }
        }
    })

    orderedMacros.reverse().forEach(macro => {
        datasets.push({
            data: calValMap[macro],
            backgroundColor: backgroundColors[macro],
            label: _.capitalize(macro === 'carbs' ? t('net carbs') : t(macro)),
            xAxisId: 'x1',
            warnings: warnings[macro],
            macro
        })
    })


    return { datasets, dayLabels: labels, checkLabels, exLabels }    
}

function parseGchartToDailyComplianceJs(gchartData,type) {
    if(!gchartData) {
        return  { labels: [], datasets: [] };
    }

    const isWeekly = gchartData[0][0] === 'Week of';
    const dataSetLabels = gchartData[0].slice(1,gchartData[0].length);
    const dataRows = gchartData.slice(1,gchartData.length);
    const dFormat = 'M/D';
    let dataHsh = {};
    let bcgHsh = {};
    let datasets = [];
    let labels = [];

    dataRows.forEach(row => {
        labels.push(moment(row[0]).format(dFormat))
        dataSetLabels.forEach((dLabel,i) => {
            const isRest = ['Rest','No Plan','No habits assigned'].includes(dLabel)
            dataHsh[dLabel] = dataHsh[dLabel] || [];
            dataHsh[dLabel].push(row[i+1]);
            bcgHsh[dLabel] = bcgHsh[dLabel] || [];
            if(isRest) {
                bcgHsh[dLabel].push('rgba(155,155,155,0.3');
            } else {
                bcgHsh[dLabel].push(percentileColorStr(row[i+1],true));
            }
        })
    })

    let labelsToUse;
    if(type === 'exercise') {
        labelsToUse = ['Exercise','Rest'];
    } else if(type === 'nutrition') {
        labelsToUse = ['Nutrition','No Plan'];
    } else if(type === 'habits') {
        labelsToUse = ['Habits','No habits assigned'];
    } else {
        labelsToUse = ['Total'];
    }

    labelsToUse.forEach(dLabel => {
        const isRest = ['Rest','No Plan','No habits assigned'].includes(dLabel)
        datasets.push({
            data: dataHsh[dLabel],
            backgroundColor: bcgHsh[dLabel],
            hoverBackgroundColor: bcgHsh[dLabel],
            label: dLabel,
            isRest
        })
    })



    return { datasets, labels, isWeekly }    
}

export const SmallNoDataMessage = ({ noDataMsg, t, id }) => (
    <div className={classnames("inline-block pa5",{ "slight-emphasis-bcg": (noDataMsg !== '-' && noDataMsg !== 'none')})} id={id ? id : ''}>
        {noDataMsg !== 'none' && (noDataMsg || t("Not enough data"))}
    </div>
)

const WrappedNoDataMessage = ({ noDataMsg, height, t }) => (<div style={{ height: `${height}px`, display: 'flex', alignItems: 'center'}}><SmallNoDataMessage noDataMsg={noDataMsg} t={t} /></div>)

const getLevelAndProgress = (currentMax,strengthLevels) => {
    if(_.isBlank(currentMax) || _.isBlank(strengthLevels)) {
        return null;
    }
    
    let level = null;
    let nextLevelProgress = 0;
    let overallPercent = 0;
    let last = null;
    let lastPercentile = null;

    for(let index=0; index < strengthLevels.length; index++) {
        const minWeight = strengthLevels[index];
        if(currentMax < minWeight) {
            level = levels[Math.max(index-1,0)];
            if(!_.isBlank(last)) {
                nextLevelProgress = Math.round(100*(currentMax-last)/(minWeight - last));
                overallPercent = Math.round(lastPercentile + nextLevelProgress/100*(percentiles[index] - lastPercentile))
            }
            break;
        }
        last = minWeight;
        lastPercentile = percentiles[index];
    }
    
    if(!level) {
        level = levels[levels.length-1];
        nextLevelProgress = 100;
        overallPercent = 100;
    }

    return { level, nextLevelProgress, overallPercent }
}

const bodyMeasurementLabel = (metric, t) => {
    switch(metric) {
        case 'bmi': {
            return t('BMI');
        }
        case 'shoulderToWaist': {
            return t('Shoulder/waist');
        }
        case 'waistToHip': {
            return t('Waist/hip');
        }
        case 'bodyFat': {
            return t('Body Fat')
        }
        default: {
            return _.capitalize(t(metric));
        }
    }
}

const unitsStrFor = (metric,unitsStr) => {
    switch(metric) {
        case 'bmi': {
            return '';
        }
        case 'shoulderToWaist': {
            return '';
        }
        case 'waistToHip': {
            return '';
        }
        case 'bodyFat': {
            return '%';
        }
        default: {
            return unitsStr;
        }
    }
}

const parseBodyMeasurementHistory = (historyData,metricType,t=(val) => (val),unitsStr) => {
    const datasets = {};
    const entries = Object.entries(historyData);
    entries.forEach(([date,values]) => {
        Object.entries(values).forEach(([metric,vals]) => {
            if(metricType === 'bodyFat' && metric !== 'bodyFat') {
                return;
            }

            if(metric === 'weight') {
                return;
            }

            if(metricType === 'value' && ['bmi','bodyFat','waistToHip','shoulderToWaist'].includes(metric)) {
                return;
            }
            datasets[metric] = datasets[metric] || { label: bodyMeasurementLabel(metric,t), pointStyle: 'circle', lineTension: 0, fill: false, borderColor: metricProgressColors[metric], metric, data: [] };

            datasets[metric].data.push({ t: moment(date).format(dateFormat), y: metricType === 'bodyFat' ? vals.value : vals[metricType], raw: `${vals.value}${unitsStrFor(metric,unitsStr)}` });
        })
    })
    return { datasets: Object.values(datasets) }    
}

const BodyMeasurementsChartCore = ({ responseData: { historyData }, metricType, unitsStr, t, user }) => {
    const data = parseBodyMeasurementHistory(historyData,metricType,t,unitsStr);
    const dataIsEmpty = data.datasets.length === 0 || _.every(data.datasets,dset => dset.data.length < 2);

    return (
        <MediaQuery query={'(min-width: 993px)'} render={({ queryMatches: isLarge }) => {
            return (
                <React.Fragment>
                    <Chart 
                        data={dataIsEmpty ? null : data} 
                        useRaw
                        chartOpts={ { legend: { position: 'bottom', labels: { boxWidth: 15 } }, tooltips: {
                            callbacks: {
                                label: (tooltipObj, data) => {
                                    const dset = data.datasets[tooltipObj.datasetIndex];
                                    const metric = dset.metric;
                                    let value = dset.data[tooltipObj.index].y;
                                    let rawVal = dset.data[tooltipObj.index].raw;

                                    if(metricType.includes('percentile')) {
                                        return percentileTipFor(metric,value,user.gender,t,rawVal);
                                    } else if(metricType === 'bodyFat') {
                                        return `${t('Body Fat')}: ${value}%`
                                    } else {
                                        return `${_.capitalize(t(metric))}: ${value}${unitsStr}`
                                    }
                                },
                                title: ([tooltipObj], data) => {
                                    const date = tooltipObj.label;
                                    return moment(date).format('MMMM Do, YYYY');
                                }
                            }
                        } }}
                        chartProps={{ width: 300, height: isLarge ? 150 : 200 }}
                        id={`body-measurements-chart`}
                    />
                    {!dataIsEmpty && (
                        <SlightEmphasisTip tipName={'chart_legend_tap_tip'} text={t("tap legend to add/remove")} />
                    )}
                </React.Fragment>
            )
        }} />
    )
}

let BodyMeasurementsProgressCard = ({ title, loadBmHistory, user, trainer }) => {
    const { t } = useTranslation();
    const [lookback,setLookback] = useState(365);
    const [metricType,setMetricType] = useState('value');
    const curUser = (trainer || user);
    const unitsStr = (curUser && curUser.isMetric()) ? t('cm') : t('in');

    return (
        <Card className="pa10" id="bm-prog-card">
            <BasicCardHeader>{title}</BasicCardHeader>
            <div className="valign-wrapper mb5">
                <DefaultSelect
                    className="flex-grow mr5"
                    name={`metricType`}
                    values={{ metricType }}
                    collection={[{ text: `${t('Measurements')} (${(unitsStr)})`, value: 'value' }, { text: t("Percentile vs military"), value: 'percentileM' }, { text: t("Percentile vs civilians"), value: 'percentileC' }, { text: t('Estimated body fat %'), value: 'bodyFat' }]}
                    handleChange={(e) => setMetricType(e.target.value)}
                />
                <DefaultSelect
                    name={'lookback'}
                    collection={User.progressPeriodsCol(t)}
                    values={{ lookback }}
                    numeric
                    setFieldValue={(name,val) => setLookback(val)}
                />
            </div>
           <Loader 
                key={lookback}
                type="padded"
                successComponent={BodyMeasurementsChartCore}
                load={() => loadBmHistory({ lookback })}
                preloaded={() => false}
                metricType={metricType}
                unitsStr={unitsStr}
                user={user}
                t={t}
           />
        </Card>
    )
}

const mapStateToBmProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToBmProps = dispatch => ({
    loadBmHistory: data => dispatch(loadBmHistory(data))
})

BodyMeasurementsProgressCard = connect(mapStateToBmProps,mapDispatchToBmProps)(BodyMeasurementsProgressCard);

export { BodyMeasurementsProgressCard }

const VolumeAndMaxCharts = ({ exercise, impliedMax, volume }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Card className="pa10">
                <BasicCardHeader>{exercise.isWeighted() ? t('Estimated Max Progress') : t('Highest Rep Set')}</BasicCardHeader>
                <Chart 
                    data={impliedMax} 
                    datasetOpts={[{ borderColor: '#8BC34A', backgroundColor: '#8BC34A66' }]} 
                    id={`implied-max-${exercise.id}-chart`}
                />
            </Card>
            <Card className="pa10">
                <BasicCardHeader>
                    {t('Volume')}
                    <div className="subtitle">{exercise.isWeighted() ? t('Total reps x weight') : t('Total reps')}</div>
                </BasicCardHeader>
                <Chart 
                    data={volume} 
                    datasetOpts={[{ fill: true, borderColor: '#5cb8b8', backgroundColor: '#5cb8b866' }]} 
                    id={`volume-${exercise.id}-chart`}
                />
            </Card>
        </React.Fragment>
    )
}

export const PercentileDonutChart = ({ percentile, label, topTitle, noDataMsg, tooltip, wrapperId, id, width=120, height=80, centerLabel=true, redGreen=false }) => {
    const { t } = useTranslation();
    const Component = tooltip ? Tooltipped : 'div';
    let props = { className: 'inline-block', id: (wrapperId ? wrapperId : '') }
    if(tooltip) {
        props = { ...props, style: { cursor: 'pointer' }, options: { 
                html: tooltip,
                classes: ['square']
            }
        }
    }

    return (
        <Component {...props} >
            {topTitle && (<div className="faint-color" style={{fontSize: '16px'}}>{topTitle}</div>)}
            {!_.isBlank(percentile) && (<div className="position-relative" style={{ width: `${width}px`, maxWidth: '100%', height: `${height}px`}}>
                <ChartWrapper 
                    width={width}
                    height={height}
                    chartType="donut"
                    {...percentileChartConfig(percentile, label, redGreen)}
                />
                {centerLabel && (<div className="metric-center-title" id={id ? id : ''}>{percentile}</div>)}
            </div>)}
            {_.isBlank(percentile) && <WrappedNoDataMessage noDataMsg={noDataMsg} t={t} height={height} />}
            {label && (<div className="faint-color" style={{fontSize: '12px'}}>{label}</div>)}
        </Component>
    )
}

const WeightedExerciseCharts = ({ impliedMax, volume, exercise, currentMax, strengthLevels, percentileRank, basePath, isMetric }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const wtSuffix = isMetric ? t('kgs') : t('lbs');
    const levelInfo = getLevelAndProgress(currentMax,strengthLevels);
    const { level, nextLevelProgress, overallPercent } = levelInfo || {};
    const newMaxButton = (<LinkButton to={modalPathFor(weightCalcModalPostfix,pathname,{ id: exercise.id })} rounded outlined color="primary" className="no-upcase shadow report-new-max-btn">
        <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("New max")}
    </LinkButton>)

    return (
        <React.Fragment>
            {_.isBlank(currentMax) && <SlightEmphasisIconNote text={t("no data for exercise tip")} id="record-max-prompt" />}
            {!_.isBlank(currentMax) && (
                <React.Fragment>
                    <Card className="pa10">
                        <div className="valign-wrapper text-center mt10">
                            <div className="flex-grow">
                                <div className="font-grey lh1 max-estimate"><b>{`${currentMax} ${wtSuffix}`}</b></div>
                                <div className="faint-color">{t('Estimated XRM', { reps: '1'})}</div>
                            </div>
                            <div className="flex-grow">
                                <div className="font-grey lh1 strength-level">{_.isBlank(level) ? (<SmallNoDataMessage t={t} />) : <b>{t(level)}</b>}</div>
                                <div className="faint-color">{t('Level of Strength')}</div>
                            </div>
                        </div>
                        {!_.isBlank(overallPercent) && (<div>
                            <div className="position-relative mt20 mb25">
                                <Progress percent={overallPercent} fat gradient />
                                {strengthLevels.map((weight,index) => (
                                    <span key={weight} className="progress-bar-rule" style={{ left: `${percentiles[index]}%` }}>
                                        <div className="rule-label">
                                            <div>{t(levels[index])}</div>
                                            <div>{weight}{wtSuffix}</div>
                                        </div>
                                    </span>
                                ))}
                            </div>
                        </div>)}
                    </Card>
                    <Card className="pa10">
                        <div className="valign-wrapper text-center">
                            <div className="flex-grow">
                                <PercentileDonutChart 
                                    percentile={nextLevelProgress} 
                                    topTitle={t('Next Level')} 
                                    label={`${!_.isBlank(nextLevelProgress) ? nextLevelProgress : ''}% ${t('Progress')}`}
                                    id="next-level-chart"
                                />
                            </div>
                            <div className="flex-grow">
                                <PercentileDonutChart 
                                    percentile={percentileRank} 
                                    topTitle={t('Rank')} 
                                    label={`${t('Percentile')}${percentileRank ? `: ${percentileRank}` : ''}`}
                                    tooltip={t("Rank among brand users",{ brandName: brandName() })}
                                    id="percentile-rank-chart"
                                />
                            </div>
                        </div>
                    </Card>
                    <VolumeAndMaxCharts exercise={exercise} impliedMax={impliedMax} volume={volume} />
                </React.Fragment>
            )}
            <div className="mt20 text-center hide-on-med-and-down">
                {newMaxButton}
            </div>
            <BottomButtons className="hide-on-large-only has-bot-nav">
                {newMaxButton}
            </BottomButtons>
        </React.Fragment>
    )
}

const ExerciseCharts = ({ basePath, responseData: { exercise: exData, impliedMax, volume, currentMax, strengthLevels, percentileRank, metric: isMetric }}) => {
    const exercise = new Exercise(exData);
    const { t } = useTranslation();
    let content = null;

    if(exercise.hasRepMax()) {
        content = (<WeightedExerciseCharts 
                exercise={exercise} 
                currentMax={currentMax}
                impliedMax={impliedMax} 
                volume={volume} 
                strengthLevels={strengthLevels} 
                percentileRank={percentileRank} 
                basePath={basePath}
                isMetric={isMetric}
            />)
    } else if(exercise.hasProgressCharts()) {
        content = <VolumeAndMaxCharts exercise={exercise} impliedMax={impliedMax} volume={volume} />
    } else {
        content = <SlightEmphasisIconNote text={t('No progress charts for this type of exercise yet')} id="no-charts-msg" />
    }

    return (
        <div id="exercise-charts">
            {content}
        </div>
    )
}

export const NoDataMessage = ({ id }) => {
    const { t } = useTranslation()

    return (
        <SlightEmphasisIconNote text={t('no data progress tip')} id={id} />
    )
}

export const Chart = ({ data, datasetOpts, yAxesOpts={}, yAxes, chartOpts={}, id, useRaw, chartProps}) => {
    const { t } = useTranslation();
    const scales = {
        xAxes: [{
            type: 'time',
            time: {
                displayFormats: {
                    day: 'MMM D',
                    week: 'MMM D',
                    month: 'MMM D',
                    quarter: 'MMM D'
                },
                minUnit: 'day'
            },
            ticks: { autoSkip: true, maxTicksLimit: 5 }
        }],
        yAxes: yAxes || [{
            ticks: { autoSkip: true, maxTicksLimit: 4 },
            ...yAxesOpts
        }]
    }
    
    if(data) {
        return (
            <ChartWrapper 
                chartType="line"
                data={useRaw ? data : _.parseGchartToChartJs(data,datasetOpts,t)}
                options={{ scales, ...chartOpts }}
                {...chartProps}
            />
        )
    } else {
        return <NoDataMessage id={id ? `${id}-no-data` : ''} />
    }
}

const weightDataOpts = [{ lineTension: 0.2, fill: false, borderColor: '#3366CC' },{ fill: false, borderColor: '#8BC34A' }];

export const Sparkline = ({ data, datasetOpts, width, height, chartOpts={}, noDataMsg, units, id, beginAtZero=true }) => {
    const { t } = useTranslation();

    const scales = {
        xAxes: [{
            display: false,
            type: 'time',
            time: {
                minUnit: 'day'
            },
            ticks: { autoSkip: true, maxTicksLimit: 5 }
        }],
        yAxes: [{
            gridLines: { 
                display: true, 
                drawBorder: false,
                borderDash: [2,2],
                color: 'rgba(0, 0, 0, 0.4)',
                zeroLineWidth: 0
            },
            ticks: { 
                autoSkip: true,
                maxTicksLimit: 1, 
                mirror: true, 
                labelOffset: -5, 
                beginAtZero,
                callback: function(value, index, values) {
                    return (value > 0) ? `${value} ${units}` : null;
                }

             },
            display: true
        }]
    }

    const options = {
        scales,
        legend: { display: false }, 
        layout: { padding: { top: 10 } }, 
        tooltips: {
            callbacks: {
                label: (tooltipObj, data) => {
                    const value = data.datasets[tooltipObj.datasetIndex].data[tooltipObj.index].y;
                    return `${value} ${units}`
                },
                title: ([tooltipObj], data) => {
                    const date = tooltipObj.label;
                    const mom = moment(date);
                    return t('Week ending date', { date: mom.format('M/D')});
                }
            }
        },
        animation: {
            duration: 0
        },
        responsiveAnimationDuration: 0,
        ...chartOpts
    }
    return (
        <div className="inline-block" id={id}>
            {data && (
                <ChartWrapper 
                    chartType="line"
                    width={width}
                    height={height}
                    data={_.parseGchartToChartJs(data,datasetOpts,t)}
                    options={options}
                />
            )}
            {!data && (<WrappedNoDataMessage t={t} height={height} noDataMsg={noDataMsg || '-'} />)}
        </div>
    )
}

export const BareMacroBarChart = ({ user, data, width, height, showWarnings, chartOpts={}, noDataMsg, id }) => {
    const { t } = useTranslation();
    showWarnings = data && data.length <= 8 ? showWarnings : null;
    const { dayLabels, checkLabels, exLabels, ...finalData } = parseGchartToStackedMacrosJs(data,showWarnings,t);
    const targCals = user.baseTargetCalsReadable();

    const xAxes = [{
        id: 'x1',
        stacked: true,
        labels: dayLabels,
        gridLines: {
          display: false,
        },
        ticks: { 
            fontSize: 8, 
            lineHeight: 0.5, 
            marginTop: -10, 
            backdropPadding: 0, 
            padding: -7,
            maxTicksLimit: 7,
            maxRotation: 0
        },
        labelOffset: 0,
        offset: true,
        type: 'category'
    }]

    if(showWarnings) {
        xAxes.push({ 
            id: 'x2',
            stacked: true,
            labels: exLabels,
            offset: true,
            gridLines: {
              display: false,
            },
            ticks: { 
                fontSize: 8, 
                lineHeight: 0.5, 
                backdropPadding: 0, 
                padding: -5,
                fontColor: '#e51c23'
            },
            labelOffset: 0,
            type: 'category'
        })
        xAxes.push({ 
            id: 'x3',
            stacked: true,
            labels: checkLabels,
            offset: true,
            gridLines: {
              display: false,
            },
            ticks: { 
                fontSize: 8, 
                lineHeight: 0.5, 
                backdropPadding: 0, 
                padding: -16,
                fontColor: '#4CAF50'
            },
            labelOffset: 0,
            type: 'category'
        })
    }

    const scales = {
        xAxes,
        yAxes: [{
            id: 'y1',
            stacked: true,
            major: { enabled: false },
            gridLines: { 
                display: true, 
                drawBorder: false,
                borderDash: [2,2],
                color: 'rgba(0, 0, 0, 0.4)'
            },
            ticks: {
                beginAtZero: true,
                suggestedMax: targCals*1.15,
                stepSize: calRoundFactor,
                maxTicksLimit: 100,
                callback: function(value, index, values) {
                    return (value === targCals) ? value : null;
                }
            },
            type: 'linear'
        }]
    }

    const options = {
        scales,
        tooltips: {
            callbacks: {
                label: (tooltipObj, data) => {
                    const label = data.datasets[tooltipObj.datasetIndex].macro || '';
                    const value = data.datasets[tooltipObj.datasetIndex].data[tooltipObj.index];
                    const warning = data.datasets[tooltipObj.datasetIndex].warnings[tooltipObj.index];
                    const totalCals = allMacros.reduce((agg,macro) => {
                        const dset = _.find(data.datasets,dataset => (dataset.macro === macro));
                        const macroCals = dset ? dset.data[tooltipObj.index] || 0 : 0;
                        return agg + macroCals;
                    },0)
                    let arr = [`${label === 'carbs' ? t('net carbs') : label}: ${Math.round(value/macroCals[label.toLowerCase()])}${t('g')} (${value}${t('cals')}/${Math.round(value/totalCals*100)}%)`]
                    if(warning) {
                        arr.push(warning);
                    }
                    return arr;
                },
                title: ([tooltipObj], data) => {
                    const date = tooltipObj.label;
                    const totalCals = allMacros.reduce((agg,macro) => {
                        const dset = _.find(data.datasets,dataset => (dataset.macro === macro));
                        const macroCals = dset ? dset.data[tooltipObj.index] || 0 : 0;
                        return agg + macroCals;
                    },0)
                    return `${date} - ${Math.round(totalCals)} cals`;
                }
            }
        },
        animation: {
            duration: 0
        },
        responsiveAnimationDuration: 0
    }
    return (
        <div className="inline-block" id={id}>
            {data && (
                <ChartWrapper 
                    chartType="bar"
                    width={width}
                    height={height}
                    data={finalData}
                    options={{ ...options, cornerRadius: 4, legend: { display: false }, layout: { padding: { bottom: 5, top: 5 } }, plugins: { labels: { render: () => '' } }, ...chartOpts }}
                />
            )}
            {!data && (<WrappedNoDataMessage t={t} height={height} noDataMsg={noDataMsg || '-'} />)}
        </div>
    )
}

export const DailyComplianceBarChart = ({ data, width, height, label, chartOpts={}, noDataMsg, id, showLabels, type, chartOnly, displayAxis }) => {
    const { t } = useTranslation();
    const { labels, isWeekly, ...finalData } = parseGchartToDailyComplianceJs(data,type);

    const xAxes = [{
        id: 'x1',
        labels,
        stacked: true,
        gridLines: {
          display: false,
        },
        ticks: { 
            fontSize: 8, 
            lineHeight: 0.5, 
            marginTop: -10, 
            backdropPadding: 0, 
            padding: -7,
            maxTicksLimit: 7,
            maxRotation: 0
        },
        offset: true,
        type: 'category'
    }]

    const scales = {
        xAxes,
        yAxes: [{
            id: 'y1',
            stacked: true,
            major: { enabled: false },
            gridLines: { 
                display: true, 
                drawBorder: false,
                borderDash: [2,2],
                color: 'rgba(0, 0, 0, 0.4)'
            },
            ticks: {
                display: displayAxis,
                beginAtZero: true,
                suggestedMax: 100,
                stepSize: 10,
                maxTicksLimit: 100,
                callback: function(value, index, values) {
                    return ((value === 100 || value === 50) && displayAxis) ? `${value}%` : null;
                 },
                type: 'linear'
            }
        }]
    }

    const legend = !showLabels ? { display: false } : {
        position: 'bottom',
        labels: {
            boxWidth: 0,
            filter: function(item, chart) {
                // Logic to remove a particular legend item goes here
                return !item.text.includes('Rest') && !item.text.includes('No Plan') && !item.text.includes('No habits');
            } 
        }
    }

    const dLabels = { render: () => '' }

    const options = {
        scales,
        tooltips: {
            callbacks: {
                label: (tooltipObj, data) => {
                    const dset = data.datasets[tooltipObj.datasetIndex];
                    const isRest = dset.isRest;
                    let label = dset.label;
                    let compliance = dset.data[tooltipObj.index];
                    compliance = compliance <= 3 ? 0 : compliance;
                    if(isRest) {
                        return label === 'Rest' ? t('Rest Day') : t(label);
                    } else {
                        if(label === 'Exercise') {
                            return t('x% of workout logged',{ pct: compliance});
                        } else if(label === 'Nutrition') {
                            return t('x% nutrition compliance',{ pct: compliance});
                        } else if(label === 'Habits') {
                            return t('x% habit compliance',{ pct: compliance});
                        } else {
                            return t('x% total compliance',{ pct: compliance});
                        }
                    }
                },
                title: ([tooltipObj], data) => {
                    const date = tooltipObj.label;
                    return isWeekly ? `Week starting ${date}` : date;
                }
            }
        },
        animation: {
            duration: 0
        },
        responsiveAnimationDuration: 0,
        legend
    }
    const chart = data && (
        <ChartWrapper 
            chartType="bar"
            width={width}
            height={height}
            data={finalData}
            options={{ ...options, cornerRadius: 4, stackedRounded: false, layout: { padding: { bottom: 5, top: 10 } }, plugins: { labels: dLabels }, ...chartOpts }}
        />
    );

    if(chartOnly) {
        return chart || '';
    }

    return (
        <div className="inline-block" id={id}>
            {chart}
            {!data && (<WrappedNoDataMessage t={t} height={height} noDataMsg={noDataMsg || '-'} />)}
        </div>
    )
}

export const ComplianceChartCard = ({ data, trainer }) => {
    const { t } = useTranslation();
    const showTabs = true;
    let initState = 'total';
    if(trainer.showNutritionOnly()) {
        initState = 'nutrition';
    } else if(trainer.showTrainingOnly()) {
        initState = 'exercise';
    }
    const [type,setType] = useState(initState);

    return (
        <Card className="pa10">
            <BasicCardHeader className={classnames({"pb0": (data && showTabs)})}>{t('Compliance')}</BasicCardHeader>
            {data && showTabs && (<ActivityTabs className="not-top no-border mb5">
                <SectionTab onClick={() => setType('total')} active={type === 'total'}>{t('Total')}</SectionTab>
                {!trainer.showTrainingOnly() && (<SectionTab onClick={() => setType('nutrition')} active={type === 'nutrition'}>{t('Nutrition')}</SectionTab>)}
                {!trainer.showNutritionOnly() && (<SectionTab onClick={() => setType('exercise')} active={type === 'exercise'}>{t('Exercise')}</SectionTab>)}
                <SectionTab onClick={() => setType('habits')} active={type === 'habits'}>{t('Habits')}</SectionTab>
            </ActivityTabs>)}
            <DailyComplianceBarChart data={data} chartOnly type={type} displayAxis />
            {!data && (<NoDataMessage id="compliance-charts-no-data" />)}
        </Card>
    )
}

export const WeightChart = ({ weight }) => {

    return (
        <Chart 
            id="weight-progress-chart"
            data={weight} 
            chartOpts={ {
                legend: {
                    labels: {
                        boxWidth: 10
                    }
                }
            }}
            datasetOpts={weightDataOpts} 
        />
    )
}

export const ActivityChart = ({ activity }) => {
    return (
        <Chart 
            id="activity-progress-chart"
            data={activity} 
            chartOpts={ {
                legend: {
                    labels: {
                        boxWidth: 10
                    }
                }
            }}
            yAxes={[{ id: 'steps-axis', ticks: { autoSkip: true, maxTicksLimit: 4 }, position: 'left' }, { id: 'active-minutes-axis', ticks: { autoSkip: true, maxTicksLimit: 4 }, position: 'right' }]}
            datasetOpts={[
                { lineTension: 0, fill: false, borderColor: '#3366CC', yAxisID: 'steps-axis' },
                { lineTension: 0, fill: false, borderColor: '#8BC34A', yAxisID: 'active-minutes-axis' }
            ]} 
        />
    )
}

export const WeightChartCard = ({ weight }) => {
    const { t } = useTranslation();

    return (
        <Card className="pa10">
            <BasicCardHeader>{t('Weight Progress')}</BasicCardHeader>
            <WeightChart weight={weight} />
        </Card>
    )
}

export const WeightAndActivityChartCard = ({ weight, activity}) => {
    const { t } = useTranslation();
    const [type,setType] = useState('weight');

    return (
        <Card className="pa10">
            <BasicCardHeader className="pb0">{t("Weight & Activity History")}</BasicCardHeader>
            <ActivityTabs className="not-top no-border mb5">
                <SectionTab onClick={() => setType('weight')} active={type === 'weight'}>{t('Weight')}</SectionTab>
                <SectionTab onClick={() => setType('activity')} active={type === 'activity'}>{t('Activity')}</SectionTab>
            </ActivityTabs>
            {type === 'weight' && (<WeightChart weight={weight} />)}
            {type === 'activity' && (<ActivityChart activity={activity} />)}
        </Card>
    )
}

export const WeightSparkline = ({ weight, height, width, noDataMsg, color, id, units, beginAtZero }) => {
    color = color || '#3366CC';
    let data = weight && weight.map(row => row.slice(0,2));
    if(data) {
        data[0][1] = '';
    }
    return (
        <Sparkline 
            id={id}
            data={data} 
            datasetOpts={[{ lineTension: 0, backgroundColor: `${color}66`, borderColor: color, pointStyle: 'circle' }]} 
            height={height} 
            width={width} 
            noDataMsg={noDataMsg} 
            units={units}
            beginAtZero={beginAtZero}
        />
    )
}

export const TotalVolumeCard = ({ volume }) => {
    const { t } = useTranslation();

    return (
        <Card className="pa10">
            <BasicCardHeader><TitleWithTip title={t('Lifting Volume')} tip={t('lifting volume tip')} /></BasicCardHeader>
            <Chart 
                id="overall-volume-chart"
                data={volume} 
                datasetOpts={[{ fill: true, borderColor: '#5cb8b8', backgroundColor: '#5cb8b866' }]} 
            />
        </Card>
    )
}

const OverallCharts = ({ user, responseData: { weight, bodyweightVolume, volume }}) => {
    const { t } = useTranslation();

    const bwCard = (
        <Card className="pa10">
            <BasicCardHeader><TitleWithTip title={t('Bodyweight Volume')} tip={t('bodyweight volume tip')} /></BasicCardHeader>
            <Chart 
                id="overall-bw-volume-chart"
                data={bodyweightVolume} 
                datasetOpts={[{ fill: true, borderColor: '#8BC34A', backgroundColor: '#8BC34A66' }]} 
            />
        </Card>
    )

    const volCard = (
        <TotalVolumeCard volume={volume} />
    )

    return (
        <div id="overall-charts">
            <WeightChartCard weight={weight} />
            {user.gymTypeString() === 'gym' && (
                <React.Fragment>
                    {volCard}
                    {bwCard}
                </React.Fragment>
            )}
            {user.gymTypeString() !== 'gym' && (
                <React.Fragment>
                    {bwCard}
                    {volCard}
                </React.Fragment>
            )}
        </div>
    )
}

const ChartsTab = ({ user, progressChartFormData, updateProgressCharts, updateProgressChartsLocal, type, basePath, isSmall }) => {
    const { t } = useTranslation();
    const formData = type === 'overall' ? { overallLookback: progressChartFormData.overallLookback } : { exerciseLookback: progressChartFormData.exerciseLookback };
    const showCharts = type === 'overall' || !_.isBlank(progressChartFormData.exerciseId);
    const submitData = type === 'overall' ? { lookbackDays: progressChartFormData.overallLookback } : { lookbackDays: progressChartFormData.exerciseLookback, exerciseId: progressChartFormData.exerciseId }
    const [renderKey,setRenderKey] = useState(1);
    const key = type === 'overall' ? progressChartFormData.overallLookback : `${progressChartFormData.exerciseLookback}-${progressChartFormData.exerciseId}-${renderKey}`;
    const showExName = !_.isBlank(progressChartFormData.exerciseName);

    return (
        <React.Fragment>
            <FormikWrapper 
                initialValues={ formData }
                submit={updateProgressChartsLocal}
                initialErrors={{}}
                autoSubmit
                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                    return (
                        <CardForm onSubmit={handleSubmit} className="text-center" id={`${type}-level-form`}>
                            <div className="valign-wrapper">
                                <DefaultSelect
                                    className="inline-block"
                                    label={t('Time period')}
                                    name={type === 'overall' ? 'overallLookback' : 'exerciseLookback'}
                                    collection={User.progressPeriodsCol(t)}
                                    {...rest}
                                    handleChange={handleAutoSubmitChange}
                                />
                                {type === 'exercise' && (
                                    <div className="ml10">
                                        <div className="font-grey">{t('Exercise')}</div>
                                        {!showExName && (
                                            <LinkButton to={pickExercisePath} rounded outlined color="primary" id="pick-exercise-btn">
                                                <FontAwesomeIcon icon="hand-pointer"></FontAwesomeIcon> {t("Pick Exercise")}
                                            </LinkButton>
                                        )}
                                        {showExName && (
                                            <div className="valign-wrapper">
                                                <div className="lh1"><b>{progressChartFormData.exerciseName}</b></div>
                                                <LinkButton to={pickExercisePath} rounded outlined color="primary" className="btn-round-icon ml10" id="pick-exercise-btn">
                                                    <FontAwesomeIcon icon="exchange-alt"></FontAwesomeIcon>
                                                </LinkButton>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </CardForm>
                    )
                }}
            />
            {showCharts && (<Loader 
                successComponent={type === 'overall' ? OverallCharts : ExerciseCharts}
                basePath={basePath}
                user={user}
                type="padded"
                load={updateProgressCharts.bind(null,submitData)}
                preloaded={() => false}
                key={key}
            />)}
            {!showCharts && (
                <SlightEmphasisIconNote text={t('Pick an exercise to view your stats/progress')} id={'pick-exercise-prompt'} />
            )}
            {type === 'exercise' && (<WeightCalculatorModal 
                showVideo 
                callback={(status,formikBag,data,setCalcStatus) => { 
                    setCalcStatus('success');
                    setRenderKey(renderKey+1);
                }} 
            />)}
        </React.Fragment>
    )
}

const PreStrengthForm = ({ user, submit }) => {
    const { t } = useTranslation();

    return (
        <FormikWrapper
            initialValues={ user.basicProfileValues() }
            validate={(values) => validateWeight(values)}
            valuesFilter={({gender, unitPreference, metricWeight, currentWeight}) => {
                if(User.metric(unitPreference)) {
                    return { gender, metricWeight, unitPreference };
                }
                return { gender, currentWeight, unitPreference };
            }}
            submit={submit}
            initialErrors={{}}
            render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                const { values: { unitPreference }, setFieldValue } = rest;
                const isMetric = User.metric(unitPreference);
                const weightField = isMetric ? 'metricWeight' : 'currentWeight';
                const unitLabel = isMetric ? t('kgs') : t('lbs');
                    return (
                        <CardForm className="pa20" align="center" onSubmit={handleSubmit}>
                            <div className="inline-block text-left">
                                <DefaultSelect
                                    className="inline-block"
                                    label={t('Gender')}
                                    name='gender'
                                    collection={User.genderCol(t)}
                                    {...rest}
                                />
                                <FullInput
                                    name={weightField}
                                    className="compact inline"
                                    label={`${t("Weight")} (${unitLabel})`}
                                    component={SmallerNumberInput}
                                    formikProps={rest}
                                />
                                <ToggleButton
                                    className="no-upcase btn-no-shadow ml5"
                                    options={{
                                        [t('lbs')]: 0, 
                                        [t('kgs')]: 1}} 
                                        value={unitPreference} 
                                        setValue={pref => setFieldValue('unitPreference',pref)
                                    } 
                                />
                            </div>
                            <div className="mt20">
                                <LoadingSubmitButton icon="arrow-right" label={t('Next')} loadState={submitState} noShadow />
                            </div>
                        </CardForm>
                    )
            }}
        />
    )
}

const mapStateToProps = (state) => ({
    user: userRecordSelector(state),
    progressChartFormData: progressChartsSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    submitBasic: (data) => dispatch(updateSettings(data)),
    updateProgressCharts: (data) => dispatch(updateProgressCharts(data)),
    updateProgressChartsLocal: (data) => dispatch(updateProgressChartsLocal(data)),
})

const NewMeasuresBtn = ({ t }) => (
            <LinkButton to={bodyMeasurementsPath} rounded outlined color="primary" className="no-upcase shadow">
                <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("New Measurements")}
            </LinkButton>
)

export const ExerciseChartsTab = connect(mapStateToProps,mapDispatchToProps)(({ basePath, progressChartFormData, updateProgressCharts, updateProgressChartsLocal, user, submitBasic, isTab }) => {
    const { t } = useTranslation();

    if(user && !_.isBlank(user.currentWeight) && !_.isBlank(user.gender)) {
        
        return (
            <React.Fragment>
                {!isTab && (
                    <React.Fragment>
                        <BodyMeasurementsProgressCard user={user} title={t('Progress')} />
                        <div className="text-center hide-on-med-and-down">
                            <NewMeasuresBtn t={t} />
                        </div>
                    </React.Fragment>
                )}
                {!isTab && (<h4 className="conversion-heading ml0 hide-on-med-and-down">{t('Exercises')}</h4>)}
                <ChartsTab 
                    type='exercise'
                    user={user}
                    progressChartFormData={progressChartFormData}
                    updateProgressCharts={updateProgressCharts} 
                    updateProgressChartsLocal={updateProgressChartsLocal}
                    basePath={basePath}
                />
            </React.Fragment>
            
        )
    } else {
        return (
            <PreStrengthForm 
                user={user || User.newWithAssocs({},{})}
                submit={submitBasic}
            />
        )
    }
 
})

const OverallChartsTab = connect(mapStateToProps,mapDispatchToProps)(({ basePath, progressChartFormData, updateProgressCharts, updateProgressChartsLocal, user, isTab }) => {

    return (
        <ChartsTab 
            type='overall'
            user={user}
            progressChartFormData={progressChartFormData} 
            updateProgressCharts={updateProgressCharts} 
            updateProgressChartsLocal={updateProgressChartsLocal}
            basePath={basePath}
            isTab={isTab}
        />
    )
})

let BodyMeasurementsTab = ({ user, isTab }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <BodyMeasurementsProgressCard user={user} title={t('Progress')} />
            <BottomButtons className="hide-on-large-only has-bot-nav">
                <NewMeasuresBtn t={t} />
            </BottomButtons>
        </React.Fragment>
    )
}

const mapStateToBmTabProps = state => ({
    user: userRecordSelector(state)
})

BodyMeasurementsTab = connect(mapStateToBmTabProps)(BodyMeasurementsTab)
class ProgressCharts extends React.Component {

    render() {
        const { activeTab, setActiveTab, t, isMain, exerciseId } = this.props;

        const overallKey = t('Overall');
        const exercisesKey = `${t('Exercises')}`;
        const basePath = _.isBlank(exerciseId) ? progressPath : progressPathFor(exerciseId);

        const tabs = [ 
            [overallKey,({ hideInactive: isSmall }) => <OverallChartsTab basePath={basePath} isTab={isSmall} />],
            [t('Body Measurements'), ({ hideInactive: isTab }) => <BodyMeasurementsTab isTab={isTab} />, false],
            [exercisesKey,({ hideInactive: isSmall }) => <ExerciseChartsTab basePath={basePath} isTab={isSmall} />,<React.Fragment>{t('Body Measurements')}</React.Fragment>],
        ]

        return (
            <React.Fragment>
                <ResponsiveTabs 
                    tabs={tabs}
                    maxL={5}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    noNav={isMain}
                />
            </React.Fragment>
        )
    }
}

export default withTranslation()(ProgressCharts);