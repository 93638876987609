import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PlaceholderImage from 'components/PlaceholderImage';
import React from 'react';
import classnames from 'classnames';
import { Link } from 'components/Routing';
import loadingContainer, { Loader, LoadingContainerParent } from 'components/LoadingHOC';
import { nutritionDataInfoPath } from 'config/paths';
import { AutoAdvanceButton, FixedSizeQuestion, FlowQuestion, FullWidthNextButton, NextButton, autoAdvanceSetValue } from 'components/FlowForm';
import { InputWithErrors, SmallButtonSelect, formikInputProps } from 'components/Form';
import { UserMacroFields } from './CustomMacroFields';
import Button from 'components/Button';
import { PsuedoPctLoadingBlock } from 'components/CircularProgress';
import { NumberInput } from 'components/TextInput';
import { MealType } from 'lib/classes';
import * as _ from 'lib/utilities';
import { EstimatedProgressChart, getDatasetInfoFor, getEndDateFor } from './EstimatedProgressChart';
import { Trans } from 'react-i18next';
import { connect } from 'react-redux';
import { loadPossibleRoutines, loadUnpossibleRoutines, loadUserWorkoutTimes } from 'redux/actions';
import { possibleRoutinesSelector, unpossibleRoutinesParams, unpossibleRoutinesSelector } from 'redux/selectors';
import ResponsiveSections from 'components/ResponsiveSections';
import { CardAction, CardContent, CardImageWithPlaceholder, CardReveal, CardTitle, RespPsuedoImageCard, StaticAspectImageCard } from 'components/Card';
import varietyOfFoods from 'assets/img/variety_of_foods.webp';
import groceryBreeze from 'assets/img/grocery_breeze.webp';
import superhero from 'assets/img/superhero.webp';
import yesCardio from 'assets/img/yes_cardio.webp';
import noCardio from 'assets/img/no_cardio.webp';
import homeF from 'assets/img/bedroom_workout_f.webp';
import homeM from 'assets/img/bedroom_workout_m.webp';
import aestheticsF from 'assets/img/AestheticsF.png';
import strengthF from 'assets/img/StrengthF.png';
import aestheticsM from 'assets/img/AestheticsM.png';
import strengthM from 'assets/img/StrengthM.png';
import laurie from 'assets/img/Laurie.webp';
import jeff from 'assets/img/Jeff.webp';
import josh from 'assets/img/Josh.webp';
import twiceAsEffective from 'assets/img/twice_as_effective.png';

const onboardingImages = [
    varietyOfFoods,
    groceryBreeze,
    superhero,
    yesCardio,
    noCardio,
    homeF,
    homeM,
    aestheticsF,
    strengthF,
    aestheticsM,
    strengthM,
    laurie,
    jeff,
    josh,
    twiceAsEffective
];

export const preloadOnboardingImages = () => {
    const images = [];

    onboardingImages.forEach(src => {
        const img = new Image();
        img.src = src;
        images.push(img);
    });

    ['male','female'].forEach(gender => {
        ['before','after'].forEach(timeType => {
            let values;
            if(gender === 'female') {
                values = [15,20,25,30,35];
            } else {
                if(timeType === 'before') {
                    values = [10,15,20,25,30];
                } else {
                    values = [5,10,15,20,25,30];
                }
            }
            values.forEach(val => {
                const img = new Image();
                img.src = `${_.publicUrl()}img/body_fat_images/${gender}/${timeType}/${val}.png`;
                images.push(img);
            })
        })
    })


    return images;
} 

export const mixpanelProperties = [
    'mainMotivation',
    'sciKeysMore',
    'lastTimeIdealBody',
    'healthRisks',
    'badHabits',
    'mealEnergyLevels',
    'tryIfAnswer',
    'flexibleWeekends',
    'familyTypes',
    'dyrQuestion0',
    'dyrQuestion1',
    'dyrQuestion2',
    'dyrQuestion3',
    'anyStrengthTraining',
    'trainingHabits',
    'sleepAmount',
    'focusAreas',
    'selfRewards',
    'successFeels',
    'enableSmartReminders'
];

export const firstEstimateProperties = ['gender','weightGoal','age','curBodyFat','goalBodyFat','simpleWeight','simpleGoalWeight','unitPreference','specialEvent','specialEventDate','simpleHeight','planPace'];

const excludeProperties = ['similarUsers1','similarUsers2','similarUsers3','highDays','sharedMealTypes','childrenCount','roommatesCount','trainingType'];

export const validateTrainingDays = (values,t) => {
    const tDays = values.trainingDays || [];
    let err = false;
    if(tDays.length < 2) {
        err = true;
    } else if(tDays.length === 2) {
        if(Math.abs(tDays[0] - tDays[1]) <= 1 || Math.abs(tDays[0] - tDays[1]) === 6) {
            err = true;
        }
    }
    
    if(err) {
        return { trainingDays: t("Pick at least 2 non-consecutive days") }
    }

    return {};
}

export const filterSubmitValues = (values,addAllMealTypes=false) => {
    let newVals = _.omit(values,[ ...mixpanelProperties, ...excludeProperties, 'dailyNutritionProfiles' ]);
    if(addAllMealTypes) {
        newVals = { ...newVals, selectedMealTypes: [ ...MealType.ON_PLAN_CATS ] };
    }

    return newVals;
}

export const CalculatingScreen = ({ duration=10, ...rest }) => {
    
    return (
        <div>
            <PsuedoPctLoadingBlock duration={duration} {...rest} />
        </div>
    )
}

export class ReachGoalByScreen extends React.Component {

    constructor(props) {
        super(props);
        const { flowProps, t, pace} = this.props;
        const { meta, ...data } = getDatasetInfoFor({ ...flowProps.values, t, pace });
        let prevEndDate = null;
        if(pace === 'moderate') {
            prevEndDate = getEndDateFor({ ...flowProps.values, t, pace: 'slow' });
        } else if(pace === 'fast') {
            prevEndDate = getEndDateFor({ ...flowProps.values, t, pace: 'moderate' });
        }

        this.state = { meta, data, displayEndDate: prevEndDate || meta.endDate };
    }

    componentDidMount() {
        this.decrementInterval = setInterval(this.decrementEndDate,200);
    }

    render() {
        const { flowProps, t, title, addlPoints, subtextRender } = this.props;
        const { values: { specialEventDate, specialEvent } } = flowProps;
        const { meta, data, displayEndDate } = this.state;
        const { endWeight, endDate, type, diffByEvent, minimalDiffByEvent, goalIndex, eventIndex, valByEvent } = meta;

        return (
            <React.Fragment>
                <FixedSizeQuestion text={title} className="mb30 mt0 pt0" />
                <div>
                    <div className="onb-ol">
                        {addlPoints}
                        <PointListItem icon={['far','bullseye-arrow']} cntr>
                            <Trans i18nKey={'ysrygby'} values={{ weight: endWeight, date: displayEndDate.format('MMM Do') }}>
                                <label></label>
                                <label></label>
                            </Trans>
                        </PointListItem>
                        {specialEventDate && !_.isBlank(diffByEvent) && !minimalDiffByEvent && (
                            <PointListItem icon={'calendar-check'} cntr>
                                <Trans i18nKey={`ys${type}byevent`} values={{ weight: diffByEvent, event: _.lowerFirst(specialEvent) }} />
                            </PointListItem>
                        )}
                        {specialEventDate && !_.isBlank(diffByEvent) && minimalDiffByEvent && (
                            <PointListItem icon={'calendar-check'} cntr>
                                <Trans i18nKey={"ywsmprogbyevent"} values={{ event: _.lowerFirst(specialEvent) }} />
                            </PointListItem>
                        )}
                        {specialEventDate && _.isBlank(diffByEvent) && (
                            <PointListItem icon={'calendar-check'} cntr>
                                <Trans i18nKey={"ywrygbyevent"} values={{ event: _.lowerFirst(specialEvent) }} />
                            </PointListItem>
                        )}
                    </div>
                </div>
                <EstimatedProgressChart 
                    data={data} 
                    goalIndex={goalIndex} 
                    eventIndex={eventIndex} 
                    t={t} 
                    valByEvent={valByEvent} 
                    endWeight={endWeight} 
                    specialEvent={specialEvent} 
                />
                {subtextRender && subtextRender({ endDate })}
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t('Next')}
                />
            </React.Fragment>
        );
    }

    decrementEndDate = () => {
        const { displayEndDate } = this.state;
        const { meta: { endDate } } = this.state;
        if(endDate.isBefore(displayEndDate,'day')) {
            const newEndDate = displayEndDate.clone();
            newEndDate.subtract(1,'day');
            this.setState({ displayEndDate: newEndDate });
        } else {
            clearInterval(this.decrementInterval);
        }
    }

    componentWillUnmount() {
        if(this.decrementInterval) {
            clearInterval(this.decrementInterval);
        }
    }
}

export const Illustration = ({ src, imgClass, children, width=480, height=270, subtitle }) => {

    return (
        <div className="illustration-cont">
            <div className="vid-parent illustration">
                <PlaceholderImage width={width} height={height} color="white" className="placeholder-svg" />
                {src && (<img src={src} alt="bleh" className={imgClass} />)}
                {children}
            </div>
            {subtitle && (
                <div className="faint-color text-left">{subtitle}</div>
            )}
        </div>
    )
}

export const PointListItem = ({ icon, children, cntr }) => {

    return (
        <div className={classnames("onb-ol-li", { cntr })}>
            <div className="icon">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="title-cont">
                {children}
            </div>
        </div>
    )
}

export const getSubmitValues = (user,values) => (user.canEditOwnMacros() ? values : _.omit(values,['dailyNutritionProfiles']));

export const CustomMacrosQuestion = ({ flowProps, recommendedCalories, user, t, submitBtn }) => {
    const { values: { dailyNutritionProfiles }, advance, validateForm, values, setTouched } = flowProps;
    const { nutritionParameters } = dailyNutritionProfiles[0];
    const fieldProps = _.pick(flowProps,formikInputProps);
    
    return (
        <React.Fragment>
            <div className="limit-50-h">
                <FlowQuestion text={t("Input your daily macros")} />
            </div>
            <div className="left-align-block limit-25-h ml10">
                <div>
                    <h3 className="inline-block green-title">{_.capitalize(t('calories'))}:</h3>
                    <InputWithErrors {...fieldProps} name="dailyNutritionProfiles.0.calorieOverride" component={NumberInput} className="inline" inProps={{ placeholder: recommendedCalories }} />
                </div>
                <div>
                    <h3 className="green-title mb0">{_.capitalize(t('macros'))}:</h3>
                    <UserMacroFields nutritionParameters={nutritionParameters} t={t} flowProps={flowProps} user={user} noRecCheck />
                </div>
                <div>
                    <h3 className="inline-block green-title">{_.capitalize(t('fiber g'))}:</h3>
                    <InputWithErrors {...fieldProps} inProps={{ placeholder: t('optional')}} name="dailyNutritionProfiles.0.fiberGoal" component={NumberInput} className="inline" />
                </div>
            </div>
            {submitBtn || (<NextButton
                className="btn-wide mt65"
                label={t('Confirm')} 
                advance={advance} 
                validateForm={validateForm} 
                values={values} 
                isValid={true}
                setTouched={setTouched} 
                id='next-btn'
            />)}
        </React.Fragment>
    )
}

const skipMacroInputCreator = ({ advance, values, setValues }) => () => {
    const { dailyNutritionProfiles } = values;
    const { nutritionParameters } = dailyNutritionProfiles[0];
    const newParams = nutritionParameters.map(param => ({ ...param, _destroy: true}));
    const newValues = { ...values, dailyNutritionProfiles: [{ ...dailyNutritionProfiles[0], overrideCalories: false, calorieOverride: '', nutritionParameters: newParams }] };
    setValues(newValues);
    advance(newValues);
}

const MacroRecommendations = ({ recMacroParams, recommendedCalories, flowProps, t, user }) => {
    const { advance, values, setValues } = flowProps;

    return (
        <React.Fragment>
            <div className="primary-bar white-font mt40">
                <div className="centering">
                    <h4 className="mb0">
                        {recommendedCalories} {t('calories')}
                    </h4>
                    <div className="thin-text">
                        {recMacroParams.summary(t,true).map((msg,i) => (
                            <div key={msg}>{i === 0 ? (<b>{msg}</b>) : msg}</div>
                        ))}
                    </div>
                </div>
            </div>
            {user.canEditOwnMacros() && (<div className="choose-goal-heading mt40 underline">
                <AutoAdvanceButton {...flowProps} attr="dailyNutritionProfiles.0.overrideCalories" value={true} render={({ onClick }) => (
                    <span className="buttonize-no-color" onClick={onClick} id="custom-macros-btn">{t('I want to put in my own calories/macros')}</span>
                )} />
            </div>)}
            <div className="mt95">
                <Button color="primary" rounded onClick={skipMacroInputCreator({ advance, values, setValues})} className="btn-wide" id="next-btn"><span>{t("Next")}</span></Button>
            </div>
        </React.Fragment>
    )
}

const MacroRecsLC = loadingContainer({
    'SUCCESS': MacroRecommendations
})

export const MacroRecommendationsPage = ({ user, flowProps, initMacros, recMacroParams, recommendedCalories, t }) => {
    let questionText;
    if(user.isClient()) {
        questionText = t("coach macro rec msg");
    } else {
        questionText = <React.Fragment>
            {t("macro recommendation message")}
            <Link to={nutritionDataInfoPath}><sup>1</sup></Link>
        </React.Fragment>
    }
    const { values, setValues } = flowProps;
    
    return (
        <div className="text-center limit-50-h ">
            <div className="ml5 mr5">
                <FlowQuestion text={questionText} />
            </div>
            
            <LoadingContainerParent 
                load={initMacros.bind(null,getSubmitValues(user,flowProps.values))}
                preloaded={() => false}
                component={MacroRecsLC}
                recMacroParams={recMacroParams}
                recommendedCalories={recommendedCalories}
                flowProps={flowProps}
                user={user}
                t={t}
                successCallback={() => setValues({ ...values, dailyNutritionProfiles: user.mnpFormValArr() })}
            />
        </div>
    )
}

export const workoutSubmitAttrs = ['liftingExperience','gymType','allowedExerciseProgressionIds','needsCardio','noRoutine','dailyStepGoal','routineFocus','trainingDays','workoutTime','routineStart']

const WorkoutTimesSelect = ({ responseData: { workoutTimes }, flowProps, t }) => {
    const mins = t('mins');
    const collection = workoutTimes.map((secTime,index) => {
        const minTime = Math.round(secTime/60);
        const text = minTime < 30 && index === 0 ? `< 30 ${mins}` : `${minTime === 35 ? 30 : minTime} ${mins}`;
        return { text: text, value: secTime };
    })
    const { values, advance, setFieldTouched, setFieldValue } = flowProps;

    return (
        <React.Fragment>
            <SmallButtonSelect  
                name="workoutTime"
                values={values}
                options={collection}
                setFieldValue={autoAdvanceSetValue({ setFieldValue, values, advance })}
                setFieldTouched={setFieldTouched}
                space
                idBase={'workout-time'}
            />
        </React.Fragment>
    )
}

const mapDispatchToWtProps = dispatch => ({
    loadWorkoutTimes: (data) => dispatch(loadUserWorkoutTimes(data))
})

export const WorkoutTimes = connect(null,mapDispatchToWtProps)(({ flowProps, loadWorkoutTimes, t }) => {

    return (
        <Loader 
            load={() => loadWorkoutTimes(_.pick(flowProps.values,workoutSubmitAttrs))}
            preloaded={() => false}
            type="padded"
            successComponent={WorkoutTimesSelect}
            flowProps={flowProps}
            t={t}
        />
    )
})

const RoutineCardActions = ({ flowProps, routine, t }) => {

    return (
        <React.Fragment>
            <AutoAdvanceButton {...flowProps} attr="newRoutine" value={routine.id} render={({ onClick }) => (
                <Button className="workout-routine-card" noShadow color="primary" rounded onClick={onClick}><span>{t('Select')}</span></Button>
            )} />
            <Button noShadow outlined color="primary" rounded className="activator ml5">{t('Info')}</Button>
        </React.Fragment>
    )
}

export const WorkoutRoutineCard = ({ routine, t, actions }) => {

    return (
        <StaticAspectImageCard action >
            <CardImageWithPlaceholder src={_.publicUrl() + `img/${routine.imageName}`} className="waves-effect waves-block waves-light activator" width={500} height={333} setDims>
                <div className="text-overlay-signup">
                    {routine.frequencyStr}x
                    <div className="subtitle">{t('week')}</div>
                </div>
                <div className="text-overlay-signup right">
                    {routine.estTimeStr}
                    <div className="subtitle">{t('mins')}</div>
                </div>
                <span className="routine-gray-overlay"></span>
            </CardImageWithPlaceholder>
            <CardContent className="routine-cards-padding buttonize activator">
                <CardTitle title={routine.name} className="grey-text text-darken-4 activator" />
            </CardContent>
            <CardAction className="pt10 pb10 center">
                {actions}
            </CardAction>
            <CardReveal>
                <CardTitle className="float-right icon-low-opacity pl15 pr10 pt5" icon="times"/>
                <p className="routine-text">
                    {routine.description}
                </p>
                {!_.isBlank(routine.sourceUrl) && (<p className="routine-text">
                    <Link to={routine.sourceUrl} target="_new">{t('Source')}</Link>
                </p>)}
            </CardReveal>
        </StaticAspectImageCard>
    )
}

const DefaultUnpossible = ({ load, t }) => (
    <RespPsuedoImageCard clickable double onClick={load} id="show-more-routines-btn">
        <CardTitle icon='eye' title={t('View all routines')}  className="card-title-gray" />
        <div className="pl5 pr5 lh1">{t("Additional routines don't match all your criteria")}</div>
    </RespPsuedoImageCard>
)

const UnpossibleLC = loadingContainer({
    'SUCCESS': () => '',
    'DEFAULT': DefaultUnpossible
})

const PossibleRoutines = ({ flowProps, t, workoutRoutines, unpossibleRoutines, unpossibleParams, loadUnpossible }) => {
    return (
        <ResponsiveSections centerLastRow>
            <AutoAdvanceButton {...flowProps} attr="newRoutine" value={''} render={({ onClick }) => (
                <RespPsuedoImageCard clickable onClick={onClick} id="pick-one-for-me-btn">
                    <CardTitle icon='arrow-right' title={t('Just pick the best one for me')} className="card-title-gray" />
                </RespPsuedoImageCard>
            )} />
            {workoutRoutines.map((routine) => (<WorkoutRoutineCard key={routine.id} routine={routine} flowProps={flowProps} t={t} actions={<RoutineCardActions flowProps={flowProps} routine={routine} t={t} />} />))}
            {unpossibleRoutines && unpossibleRoutines.map((routine) => (<WorkoutRoutineCard key={routine.id} routine={routine} flowProps={flowProps} t={t} actions={<RoutineCardActions flowProps={flowProps} routine={routine} t={t} />} />))}
            {!unpossibleRoutines && (<LoadingContainerParent 
                skipAutoLoad
                load={loadUnpossible.bind(null,unpossibleParams)}
                preloaded={() => !!unpossibleRoutines}
                component={UnpossibleLC}
                flowProps={flowProps}
                workoutRoutines={unpossibleRoutines}
                t={t}
            />)}
        </ResponsiveSections>
    )
}

const RoutinesLC = loadingContainer({
    'SUCCESS': PossibleRoutines
})

const PossibleRoutinesContainer = ({ flowProps, t, loadRoutines, loadUnpossible, unpossibleParams, workoutRoutines, unpossibleRoutines }) => {
    return (
        <LoadingContainerParent 
            load={loadRoutines.bind(null,flowProps.values)}
            preloaded={() => false}
            component={RoutinesLC}
            flowProps={flowProps}
            workoutRoutines={workoutRoutines}
            unpossibleRoutines={unpossibleRoutines}
            loadUnpossible={loadUnpossible}
            unpossibleParams={unpossibleParams}
            t={t}
        />
    )
}

const mapStateToWrt = state => ({
    workoutRoutines: possibleRoutinesSelector(state),
    unpossibleRoutines: unpossibleRoutinesSelector(state),
    unpossibleParams: unpossibleRoutinesParams(state)
})

const mapDispatchToLoadRoutines = dispatch => ({
    loadRoutines: data => dispatch(loadPossibleRoutines(data)),
    loadUnpossible: data => dispatch(loadUnpossibleRoutines(data))
})

const ConPossibleRoutines = connect(mapStateToWrt,mapDispatchToLoadRoutines)(PossibleRoutinesContainer);

export const RoutineSelectQuestion = ({ flowProps, t }) => {
    return (
        <div className="container">
            <div className="row text-center limit-50-h">
                <div className="col s12">
                    <FlowQuestion text={t("Here are routines that match your profile")} className="mb0" />
                    <div className="flow-question-subtitle">{t("Select a routine")}</div>
                </div>
                <div className="col s12">
                    <ConPossibleRoutines flowProps={flowProps} t={t} />
                </div>
            </div>
        </div>
    )
}

export const UserReview = ({ review }) => {

    return (
        <React.Fragment>
            <div className="thin left-align typography-conversion ratings-title mt20">
                {review.title}
            </div>
            <div className="left-align display-flex">
                {[0,1,2,3,4].map(num => (
                    <FontAwesomeIcon key={num} icon="star" className="stars" />
                ))}
                <div className="typography-conversion ratings-title">
                    - {review.reviewer}
                </div>
            </div>
            <div className="left-align typography-conversion ratings-text">
                {review.text}
            </div>
        </React.Fragment>
    )
}

export const AppReviewCard = ({ imgSrc, weightLoss, review, t }) => {

    return (
        <StaticAspectImageCard action >
            <CardImageWithPlaceholder src={imgSrc} width={452} height={250} setDims>
            </CardImageWithPlaceholder>
            <CardContent className="routine-cards-padding position-relative">
                <div className="rating-wl-tag">
                    {t('Lost X', { weight: `${weightLoss}${t('lbs')}` })}
                </div>
                <UserReview review={review} />
            </CardContent>
        </StaticAspectImageCard>
    )
}