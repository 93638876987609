import React from 'react';
import MTabs from 'assets/js/tabs';

class Tabs extends React.Component {

    constructor(props) {
        super(props);
        this.tabsRef = React.createRef();
        this.uniqIdBase = "tabs" + Math.floor(Math.random() * 999999);
        this.onShowTab = this.onShowTab.bind(this);
        this.getTabId = this.getTabId.bind(this);
        this.initFn = () => { this.domTabs = MTabs.init(this.tabsRef.current,{ onShow: this.onShowTab, swipeable: false, responsiveThreshold: 0 }) };
        this.state = { activeIndex: props.defaultIndex || 0 };
    }

    componentDidMount() {
        const { addContentInit } = this.props;
        
        if(addContentInit) {
            addContentInit(() => this.initFn());
        } else {
            this.initFn();
        }
        
    }

    render() {
        const { tabs, render } = this.props;
        const { activeIndex } = this.state;
        const passTabs = [];
        const contents = [];
        const { title, content, tabRender, ...otherProps } = tabs[activeIndex || 0];

        tabs.forEach((tab,index) => {
            const tabId = this.getTabId(index);
            const tabKey = "#" + tabId;
            passTabs.push((<li className="tab" key={tabKey}><a href={tabKey} className={activeIndex === index ? 'active' : ''}>{tab.title}</a></li>))
            contents.push((<div key={tabId} id={tabId}>{ activeIndex === index ? tab.content : '' }</div>));
        });

        return (
            render({
                tabs: (<ul className="tabs tabs-fixed-width" ref={this.tabsRef}>{passTabs}</ul>),
                tabContents: contents,
                tabRender,
                activeTab: activeIndex,
                activeTabId: this.getTabId(activeIndex),
                otherProps
            })
        )
    }

    getTabId(index) {
        return this.uniqIdBase + "-" + index;
    }

    onShowTab(arg) {
        this.setState({ activeIndex: this.domTabs.index })
    }

    componentWillUnmount() {
        if(this.domTabs) {
            this.domTabs.destroy();
        } else {
            this.initFn = () => {};
        }
    }
}

export default Tabs;