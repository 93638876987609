import React, { useState } from 'react';
import { recentUserMealsSelector, recUmsPageSelector, recUmsMoreSelector, 
    recipeSearchFullSelector, userRecordSelector, trainerRecordSelector } from 'redux/selectors';
import { loadRecentUserMeals, logOffPlan, logFoodFromDB, offPlanRecipeSearch, loadRecipes, startEditingRecipe } from 'redux/actions';
import RouteModal, { ModalHeader } from 'components/Modal';
import Tabs from 'components/Tabs';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { foodLogModalMatchFor } from 'config/paths';
import RecentFoodsTab from 'views/FoodLogRecentTab';
import DatabaseTab from 'views/FoodLogDatabaseTab';
import RecipesTab from 'views/FoodLogRecipesTab';
import FoodForm from 'partials/FoodForm';

const mapNewDispatchToProps = (dispatch, { parentId }) => ({
    submit: (data) => dispatch(logFoodFromDB(parentId ? parentId : '',data,'new'))
})

const ConnectedFoodForm = connect(null,mapNewDispatchToProps)(FoodForm);

const mapRecipeStateToProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state),
    recipeSearch: recipeSearchFullSelector(state)
});

const mapRecipeDispatchToProps = (dispatch, { parentId }) => ({
    load: (params,page) => dispatch(offPlanRecipeSearch(params,page)),
    log: (data) => dispatch(logFoodFromDB(parentId ? parentId : '',data,'recipe')),
    loadFullRecipe: (recipeId) => dispatch(loadRecipes([recipeId])),
    startEditingRecipe: (user) => dispatch(startEditingRecipe(user))
});

const ConnectedRecipesTab = connect(mapRecipeStateToProps,mapRecipeDispatchToProps)(withTranslation()(RecipesTab));

const mapDBTDispatchToProps = (dispatch, { parentId }) => ({
    log: (data) => dispatch(logFoodFromDB(parentId ? parentId : '',data,'food'))
});

const ConnectedDatabaseTab = connect(null,mapDBTDispatchToProps)(DatabaseTab);

const mapRFTStateToProps = state => ({
    userMeals: recentUserMealsSelector(state),
    more: recUmsMoreSelector(state),
    page: recUmsPageSelector(state)
});

const mapRFTDispatchToProps = (dispatch,{ parentId }) => ({
    load: (page) => dispatch(loadRecentUserMeals(page)),
    log: (userMealId,userMeal) => dispatch(logOffPlan(parentId ? parentId : '',userMeal,userMealId))
});

const ConnectedRecentFoodsTab = connect(mapRFTStateToProps,mapRFTDispatchToProps)(withTranslation()(RecentFoodsTab));

class FoodLogModalContent extends React.Component {

    constructor(props) {
        super(props);
        const { setTriggerRequestRating } = props;
        setTriggerRequestRating(false);
        this.triggerRequestRating = this.triggerRequestRating.bind(this);
    }

    render() {
        const { addContentInit, footerRef, headerRef, calcNonScrollDim, date, t, match: { params: { parentId }}, closeAndGo } = this.props;
        return (
            <React.Fragment>
                <Tabs addContentInit={addContentInit} 
                    tabs={[
                        {title: t("Database"), content: (<ConnectedDatabaseTab 
                            calcNonScrollDim={calcNonScrollDim} 
                            footerRef={footerRef} 
                            date={date} 
                            parentId={parentId}
                            triggerRequestRating={this.triggerRequestRating}
                        />)},
                            {title: t("Recent"), content: (<ConnectedRecentFoodsTab 
                                calcNonScrollDim={calcNonScrollDim} 
                                footerRef={footerRef} 
                                date={date} 
                                parentId={parentId}
                                triggerRequestRating={this.triggerRequestRating}
                            />)},
                            {title: t("New"), content: (<ConnectedFoodForm 
                                calcNonScrollDim={calcNonScrollDim} 
                                footerRef={footerRef} 
                                date={date} 
                                servingsEaten={1} 
                                actionTitle={t('Save and Log')}
                                parentId={parentId}
                                triggerRequestRating={this.triggerRequestRating}
                            />)},
                            
                            {title: t("Recipes"), content: (<ConnectedRecipesTab 
                                calcNonScrollDim={calcNonScrollDim} 
                                footerRef={footerRef} 
                                date={date} 
                                parentId={parentId}
                                closeAndGo={closeAndGo}
                                triggerRequestRating={this.triggerRequestRating}
                            />)}
                        ]} 
                    render={({tabs,tabContents}) => {
                    return (
                        <div id="food-log-modal-content">
                            <ModalHeader scrollable ref={headerRef} exitButton>
                                <div></div>
                                <h5>{t("What did you eat?")}</h5>
                                {tabs}
                            </ModalHeader>
                            {tabContents}
                        </div>
                    )
                }} />
            </React.Fragment>
        )
    }

    triggerRequestRating() {
        const { setTriggerRequestRating } = this.props;
        setTriggerRequestRating(true);
    }
}

FoodLogModalContent = withTranslation()(FoodLogModalContent);

const FoodLogModal = ({ date, requestRating }) => {
    const [triggerRequestRating,setTriggerRequestRating] = useState(false);
    const closeCallback = () => {
        if(triggerRequestRating) {
            requestRating();
        }
    }

    return (
        <RouteModal fullWidth path={foodLogModalMatchFor(date)} exact scrollable closeCallback={closeCallback} render={(modalProps) => (
            <FoodLogModalContent {...modalProps} date={date} setTriggerRequestRating={setTriggerRequestRating} />
        )} />
    )
}

export default FoodLogModal;