import { RecordBase, registerInflection } from  'lib/record-base';
import * as _ from 'lib/utilities';

export class WorkoutRoutineDay extends RecordBase {
    static NAME = 'WorkoutRoutineDay'
    static ASSOCS = {
        routineCycle: { type: 'belongsTo' },
        workoutTemplate: { type: 'belongsTo' }
    }

    name() {
        return this.workoutTemplate.name;
    }

    defaultedName(t) {
        return this.workoutTemplate.defaultedName(t);
    }

    workoutRoutine() {
        return this.routineCycle.workoutRoutine;
    }

}

export class RoutineCycle extends RecordBase {
    static NAME = 'RoutineCycle'
    static ASSOCS = {
        workoutRoutine: { type: 'belongsTo' },
        workoutRoutineDays: { type: 'hasMany', sortAttr: 'dayOrder' }
    }
    static CARDIO = 1;
    static WORKOUT = 0;

    defaultedName(t) {
        return _.isBlank(this.name) ? t('Untitled cycle') : this.name;
    }

    isCardio() {
        return this.subType === RoutineCycle.CARDIO;
    }

    isWorkout() {
        return this.subType === RoutineCycle.WORKOUT;
    }

    daysTitle(t) {
        return this.isCardio() ? t('Cardio days') : t('Workout days');
    }

    user() {
        return this.workoutRoutine.user;
    }

    startWeekday() {
        if(this.user()) {
            return this.user().routineStartDate().day();
        }

        return 0;
    }

    showSettings() {
        return this.workoutRoutine.isMulticycle();
    }

    reorderDays(startIndex,endIndex) {
        const [removed] = this.workoutRoutineDays.splice(startIndex, 1);
        this.workoutRoutineDays.splice(endIndex, 0, removed);
    }

    dayOrderParams() {
        return { 
            id: this.id, 
            workoutRoutineId: this.workoutRoutineId, 
            workoutRoutineDays: this.workoutRoutineDays.map((wrtd,index) => ({ id: wrtd.id, dayOrderPosition: index }))
        }
    }

    formValues() {
        return _.parseObjForForm({ name: this.name, length: this.length, workoutRoutineId: this.workoutRoutineId, id: this.id });
    }

    workoutRoutineDayIds() {
        return this.workoutRoutineDays.map(wrtd => wrtd.id);
    }

    showCycleWeeks() {
        return this.workoutRoutine.showCycleWeeks(this.subType);
    }
}

registerInflection('workoutRoutineDay','workoutRoutineDays',WorkoutRoutineDay);
registerInflection('routineCycle','routineCycles',RoutineCycle);