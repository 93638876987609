import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ChildDropdown, DropdownItem, DropdownOption, MultiLevelDropdown } from 'components/Dropdown';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';
import { DatePickField, DefaultSelect, FullInput, ModalSelectListing, WeekdayButtonSelect } from 'components/Form';
import { SchedulableSetting } from 'lib/classes';
import { SmallerNumberInput } from 'components/TextInput';
import moment from 'moment';
import { setFilterBtnFilts } from 'redux/actions';
import { filterBtnFiltsSel } from 'redux/selectors';
import { connect } from 'react-redux';

const tagColors = { default: 'gt', mine: '', team: 'bt', intake: 'ot', assessment: 'rt', check_in: 'pt', not_submitted: 'ot', submitted: 'rt', completed: '', upcoming: 'gt' }
const tagLabels = { default: 'Default', mine: 'Mine', team: 'Team Level', intake: 'Intake', assessment: 'Assessment', check_in: 'Check-in', not_submitted: 'Not submitted', submitted: 'Needs review', completed: 'Completed', upcoming: 'Upcoming' }

export const ownershipCol = t => ([{ text: t('Mine'), value: 'mine' }, { text: t('Team Level'), value: 'team' }])

const RecordTag = ({ type, t }) => {
    const tagColor = tagColors[type];

    return (
        <div 
            className={classnames("last-active-tag ml5", {[tagColor]: tagColor })}
        >
                {t(tagLabels[type])}
        </div>  
    )
}

export const RecordTags = ({ record, viewer, listCard, tagList }) => {
    const { t } = useTranslation();
    const tags = tagList || record.getTagList(viewer);

    return (
        <div className={classnames("cli-tag-cont", { lc: listCard })}>
            {tags.map(type => (<RecordTag type={type} t={t} key={type} />))}
        </div>
    )
}

let FilterButtonWrapper = ({ allFilters, setFilters, filterKey, initialFilters, className, options, fixed, render }) => {
    const { t } = useTranslation();
    const filters = allFilters[filterKey] || initialFilters;
    const filterCount = _.some(Object.entries(filters),([key,val]) => !_.isBlank(val));
    const singleLayer = Object.entries(options).length === 1;

    const btn = (
        <MultiLevelDropdown
            options={ { coverTrigger: true, closeOnClick: true, alignment: 'right' } }
            fixed={fixed}
            triggerRender={({ ref, target }) => {
                return (
                    <div className={classnames("position-relative inline-block")}>
                        {filterCount > 0 && (<span className="notification-icon blue for-btn">{filterCount}</span>)}
                        <Button noShadow outlined color="primary" variant="icon-only" id="trainer-filters-btn" className={className} ref={ref} data-target={target}>
                            <FontAwesomeIcon icon={['far','filter']}></FontAwesomeIcon>
                        </Button>
                    </div>
                )
            }}
        >
            <DropdownItem id="reset-filters-btn" noClose label={t("Reset Filters")} onClick={() => setFilters({ filterKey, filters: _.mapValues(filters,val => null)})} />
            {Object.entries(options).map(([key,{ label, options }]) => {

                if(singleLayer) {

                    return (
                        <React.Fragment key={key}>
                            {options.map(({ text, value }) => {
                                return (
                                    <DropdownOption 
                                        key={value}
                                        label={text} 
                                        value={value} 
                                        name={key} 
                                        values={filters} 
                                        setValues={values => setFilters({ filterKey, filters: values })} 
                                        id={`drop-opt-${value}`}
                                    />
                                )
                            })}
                        </React.Fragment> 
                    )
                }

                return (
                    <ChildDropdown key={key} label={label} flagged={!_.isBlank(filters[key])} triggerId={`${key}-child-drop`}>
                        {options.map(({ text, value }) => {
                            return (
                                <DropdownOption 
                                    key={value}
                                    label={text} 
                                    value={value} 
                                    name={key} 
                                    values={filters} 
                                    setValues={values => setFilters({ filterKey, filters: values })} 
                                    id={`drop-opt-${value}`}
                                />
                            )
                        })}
                    </ChildDropdown> 
                )

            })}
        </MultiLevelDropdown>                      
    )

    return render({ filterBtn: btn, filters, setFilters: filters => setFilters({ filterKey, filters }) })
}

const mapDispatchToFiltProps = dispatch => ({
    setFilters: data => dispatch(setFilterBtnFilts(data))
})

const mapStateToFiltProps = state => ({
    allFilters: filterBtnFiltsSel(state)
})

FilterButtonWrapper = connect(mapStateToFiltProps,mapDispatchToFiltProps)(FilterButtonWrapper)

const handleWeirdDates = t => val => {

    if(val === 'various') {
        return t('Various');
    }

    return t('None selected');
}

export const SharedSchedulingFields = ({ prefix, record, formikProps, isForDefaults, initDatePick }) => {
    const { t } = useTranslation();
    const freqType = _.get(formikProps.values,`${prefix}freqType`);
    const frequency = _.get(formikProps.values,`${prefix}frequency`);
    const { values, setFieldValue, setFieldTouched, touched, errors } = formikProps;
    const weekdaysTouched = _.get(touched,`${prefix}weekdays`);
    const weekdaysErr = _.get(errors,`${prefix}weekdays`);
    const startDateStr = _.get(formikProps.values,`${prefix}startDate`);
    const endDateStr = _.get(formikProps.values,`${prefix}endDate`);
    let minEndDate = moment().add(1,'days');
    let defaultStartDate = moment();
    let defaultEndDate = minEndDate.clone();
    if(!_.isBlank(startDateStr) && startDateStr !== 'various') {
        const startDate = moment(startDateStr);
        if(startDate.isAfter(minEndDate)) {
            minEndDate = startDate.clone().add(1,'days');
        }

        if(startDate.isAfter(defaultStartDate)) {
            defaultStartDate = startDate.clone();
        }
    }

    if(!_.isBlank(endDateStr)) {
        const endDate = moment(endDateStr);
        if(endDate.isAfter(minEndDate)) {
            defaultEndDate = endDate.clone();
        }
    }

    const startDateField = (
        <DatePickField
            name={`${prefix}startDate`}
            label={freqType === SchedulableSetting.ONCE ? t('Assigned date') : t('Start date')}
            fallbackText={handleWeirdDates(t)}
            initDatePick={initDatePick}
            defaultDate={defaultStartDate}
            options={{ minDate: moment().toDate() }}
            id={`start-date-select-${record.id}`}
            {...formikProps}
        />
    )

    return (
        <React.Fragment>
            <div className="valign-wrapper flex-end">
                <DefaultSelect
                    numeric
                    className="mb20 flex-grow"
                    label={t('Schedule type')}
                    name={`${prefix}freqType`}
                    collection={SchedulableSetting.typeOptions(t,record.constructor.NAME === 'Habit',isForDefaults,freqType,frequency)}
                    {...formikProps}
                />
                {(freqType === SchedulableSetting.MODULO || freqType === SchedulableSetting.WEEKLY) && (<FullInput
                    label={freqType === SchedulableSetting.MODULO ? t('X day(s)',{ days: '' }) : t("X week(s)", { weeks: '' })}
                    className="inline ml10"
                    name={`${prefix}frequency`}
                    component={SmallerNumberInput}
                    limitPattern={/[0-9]+/}
                    onlyErrorText
                    formikProps={formikProps}
                />)}
                {freqType === SchedulableSetting.ONCE && !isForDefaults && (
                    <div className="mb20 ml10">{startDateField}</div>
                )}
            </div>
            {(freqType === SchedulableSetting.WEEKDAYS || freqType === SchedulableSetting.WEEKLY) && (<div>
                <div className="font-grey">{freqType === SchedulableSetting.WEEKDAYS ? t('Select Days') : t('On which day of the week?')}</div>
                <WeekdayButtonSelect 
                    startDay={0} 
                    name={`${prefix}weekdays`}
                    values={values} 
                    setFieldValue={setFieldValue}
                    setFieldTouched={setFieldTouched}
                    dayNames={moment.weekdaysShort()} 
                    psuedoSingle={freqType === SchedulableSetting.WEEKLY}
                    id={`weekday-select-${record.id}`}
                />
                {weekdaysTouched && weekdaysErr && (<div className="red-text"><FontAwesomeIcon icon="times" /> {weekdaysErr}</div>)}
            </div>)}
            {record.constructor.NAME === 'Form' && record.isAssessment() && (<div className="mt20">
                <div className="font-grey">{t('how assess admin')}</div>
                <ModalSelectListing 
                    name={`${prefix}selfAdmin`}
                    single={true}
                    col={[{ text: t('client record assess'), subtitle: t('crassess tip'), value: true }, { text: t('coach in person assess'), subtitle: t('coach assess tip'), value: false }]}
                    boxClass="valign-wrapper pa10"
                    {...formikProps}
                />
            </div>)}
            {!isForDefaults && freqType !== SchedulableSetting.ONCE && (
                <div className="valign-wrapper mt15 off-black-text spbt">
                    {startDateField}
                    <DatePickField 
                        className="ml10"
                        name={`${prefix}endDate`}
                        label={`${t('End date')} (${t('optional')})`}
                        fallbackText={handleWeirdDates(t)}
                        initDatePick={initDatePick}
                        defaultDate={defaultEndDate}
                        options={{ minDate: minEndDate.toDate() }}
                        id={`end-date-select-${record.id}`}
                        showClear
                        {...formikProps}
                    />
                </div>
            )}
        </React.Fragment>
    )
}

export { FilterButtonWrapper }