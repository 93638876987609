import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { Route, Switch } from "react-router-dom";
import { matchPath } from 'react-router';
import { isBlank } from 'lib/utilities';

const none = { enteringClassNames: '', leavingClassNames: '', timeout: 1 };

const parseRule = (rule,props) => {
    if(typeof rule === 'string' && props[rule]) {
        return props[rule]();
    } else {
        return rule;
    }
}

const getRuleFromMap = (path,map,props) => {
    if(!document.body.classList.contains('disable-transitions')) {
        for(let rule of map.rules) {
            if(matchPath(path, {path: rule[0], exact: true })) {
                return parseRule(rule[1],props);
            }
        }
    
        for(let rule of map.rules) {
            if(matchPath(path, {path: rule[0] })) {
                return parseRule(rule[1],props);
            }
        }
    }

    return none;
};

export const createDefaultMap = () => {
    return {
        key: null,
        rules: [
            ['*', none]
        ]
    }
}

export class SingleRenderTransitionGroup extends Component {

    constructor(props) {
        super(props);
        this.mounting = true;
    }
}

export const RedirectContext = React.createContext(false);

export const HideOnExit = ({ children }) => {
    
    return (
        <RedirectContext.Consumer>
            {switchDisabled => {
                if(switchDisabled) {
                    return '';
                }

                return (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                )
            }}
        </RedirectContext.Consumer>
    )
}

const TransitionWrapper = (props) => {
    const { onExited, timeout, children, classNames, in: inProp, disableRedirectsOnExit } = props;

    const newChildren = disableRedirectsOnExit ? (<RedirectContext.Provider value={!inProp}>
        {children}
    </RedirectContext.Provider>) : children;

    return (
        <CSSTransition 
            timeout={timeout} 
            in={inProp} 
            classNames={classNames} 
            onExited={onExited}
        >
            {newChildren}
        </CSSTransition>
    )
}

export class RouteTransitionMap extends Component {

    constructor(props) {
        super(props);
        this.childFactory = this.childFactory.bind(this)
        this.transitionMap = createDefaultMap()
        this.endTransition = this.endTransition.bind(this)
        this.state = { transitioning: false };
    }

    render() {
        const { trackScrolling, overrideScrollRef, noTransitions } = this.props;
        const tmapKey = this.transitionKey();
        const core = (
            <Switch location={this.props.location}>
                {React.Children.map(this.props.children,(child) => (
                    <Route key={child.props.path} 
                        {...this.childPathProps(child)} 
                        render={(routeProps) => {
                            return (
                                <RouteTransition 
                                pathname={this.props.location.pathname} 
                                startTransition={this.startTransition}
                                endTransition={this.endTransition} 
                                trackScrolling={trackScrolling}
                                oldRules={this.transitionMap} 
                                overrideScrollRef={overrideScrollRef}
                                canUpdate={this.props.canUpdate || child.props.canUpdate}
                                {...routeProps}>
                                {child}
                                </RouteTransition>
                            )
                        }
                    }/>
                ))}
            </Switch>
        )

        if(noTransitions) {
            return core;
        }

        return (
            <TransitionGroup childFactory={this.childFactory}>
                <TransitionWrapper key={tmapKey} tmapKey={tmapKey} timeout={1} classNames="" onExited={this.clearOverflow}>
                    {core}
                </TransitionWrapper>
            </TransitionGroup>
        )
    }

    transitionKey() {
        for(let pathProps of this.childrenPathProps()) {
            if (matchPath(this.props.location.pathname,pathProps)) {
                return pathProps.path;
            }
        }
    }

    childrenPathProps() {
        return React.Children.map(this.props.children,(child) => this.childPathProps(child))
    }

    childPathProps(child) {
        return {exact: child.props.exact, path: child.props.path}
    }

    childFactory(child) {
        return React.cloneElement(
            child,
            this.getTransitionInfo(this.transitionMap.key === child.props.tmapKey)
          )
    }

    getTransitionInfo(leaving) {
        const { disableRedirectsOnExit } = this.props;
        const rules = getRuleFromMap(this.props.location.pathname,this.transitionMap,this.props);
        if(leaving) {
            return { classNames: rules.leavingClassNames, timeout: Math.max((rules.timeout-30),1), disableRedirectsOnExit  };
        } else {
            return { classNames: rules.enteringClassNames, timeout: rules.timeout, disableRedirectsOnExit  };
        }
    }

    startTransition = () => {
        const { noOverflow } = this.props;
        if(noOverflow) {
            document.body.style.overflow = 'hidden';
        }
    }

    clearOverflow = () => {
        setTimeout(() => document.body.style.overflow = '',30);
    }

    endTransition(transitionMap,pathProps) {
        const curPathname = this.props.location.pathname;
        if (matchPath(curPathname,pathProps)) {
            this.transitionMap = {key: this.transitionKey(), ...transitionMap};
        }
    }
    
    componentWillUnmount() {
        document.body.style.overflow = '';
    }

}

class RouteTransition extends Component {
    constructor(props) {
        super(props);
        this.setupTransitions = this.setupTransitions.bind(this);
        this.scrollRef = React.createRef();
        const { location } = props;
        this.activeTab = location.state && location.state.activeTab;
    }

    componentDidMount() {
        const { location, history, trackScrolling } = this.props;
        const scrollElem = this.getScrollRef().current;
        if(scrollElem && typeof scrollElem.scrollTo === 'function') {
            if(location.state && location.state.scrollPos) {
                scrollElem.scrollTo(0,location.state.scrollPos);
            } else {
                scrollElem.scrollTo(0,0);
            }
        }

        if(trackScrolling) {
            this.unblock = history.block((location,action) => {
                if(action === 'PUSH') {
                    const { location: curLoc } = history;
                    const scrollElem = this.getScrollRef().current;
                    let newState = curLoc.state ? { ...curLoc.state } : {};
                    if(scrollElem) {
                        newState.scrollPos = scrollElem.scrollTop;
                        history.replace(curLoc.pathname, newState);
                    }
                }
                return null;
            })
        }

        //this.unlisten = history.listen(({ action, location }) => {

        //    if(action === 'POP') {
        //        this.activeTab = location.state && location.state.activeTab;
        //    }
        //})
    }

    render() {
        return React.Children.map(this.props.children,(child) => {

            return React.cloneElement(child,{ 
                setupTransitions: this.setupTransitions, 
                match: this.props.match, 
                location: this.props.location, 
                history: this.props.history, 
                scrollRef: this.scrollRef, 
                setActiveTab: this.setActiveTab, 
                activeTab: this.activeTab
            })
        })
    }

    shouldComponentUpdate(nextProps) {
        if(this.props.canUpdate) {
            return true;
        }
        return false;
    }

    setupTransitions(map) {
        const { startTransition } = this.props;
        //const timeout = getRuleFromMap(this.props.pathname,this.props.oldRules,this.props).timeout
        const timeoutFnCreator = (pathProps) => () => this.props.endTransition(map,pathProps)

        startTransition()
        this.transitionTimer = setTimeout(timeoutFnCreator({path: this.props.match.path, exact: this.props.match.isExact}),1);
    }

    getScrollRef() {
        return this.props.overrideScrollRef || this.scrollRef;
    }

    setActiveTab = (tabId) => {
        const { history } = this.props;
        const { location: curLoc } = history;
        let newState = curLoc.state ? { ...curLoc.state } : {};
        this.activeTab = tabId;

        const stateTab = (curLoc.state && !isBlank(curLoc.state.activeTab)) ? curLoc.state.activeTab : null;
        if(this.activeTab !== stateTab) {
            newState.activeTab = this.activeTab;
            history.replace(curLoc.pathname, newState);
        }
    }

    componentWillUnmount() {
        if(this.transitionTimer) {
            clearTimeout(this.transitionTimer);
        }
        if(this.unblock) {
            this.unblock();
        }
        if(this.unlisten) {
            this.unlisten();
        }
    }
}