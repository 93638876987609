import React, { Component } from 'react';
import Page from'components/Page';
import { adminSwitchUser, switchClient } from 'redux/actions';
import { connect } from 'react-redux';
import { homePath, trainerHomePath, trainerMainMatches } from 'config/paths';
import AppSplash from 'components/AppSplash';
import { userSelector } from 'redux/selectors';
import { Redirect } from 'components/Routing';
import * as transitions from 'assets/transitions';
import * as _ from 'lib/utilities';

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut]
    ]
};

class SwitchAccountLoader extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
        const { switchClient, adminSwitch, match: { params: { id }} } = props;
        const adminId = localStorage.getItem('adminId');
        const switchFinal = _.isBlank(adminId) ? switchClient : adminSwitch;
        setTimeout(() => switchFinal(id),1);
    }


    render() {
        const { user, scrollRef, match: { params: { id }} } = this.props;

        return (
            <Page ref={scrollRef} white>
                <AppSplash>
                        <div id="success" className="current-state">
                            <div>
                            </div>
                        </div>
                </AppSplash>
                {user && user.id === Number(id) && <Redirect to={user.type === 'Trainer' ? trainerHomePath : homePath()} />}
            </Page>
        );
    }
}

const mapStateToProps = (state) => ({
    user: userSelector(state)  
})

const mapDispatchToProps = dispatch => ({
    switchClient: (id) => dispatch(switchClient(id)),
    adminSwitch: id => dispatch(adminSwitchUser(id))
});

export default connect(mapStateToProps,mapDispatchToProps)(SwitchAccountLoader)
