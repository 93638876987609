import { RecordBase, registerInflection } from 'lib/record-base';


export class PdfExportSetting extends RecordBase {
    static NAME = 'PdfExportSetting';
    static ASSOCS = {
        user: { type: 'belongsTo' },
    }

    static DEFAULTS = { delivery: 'email', subject: '', body: '', includeMealPlan: true, includeRecipes: true, includeGlist: true, includeHabits: true, includePics: true, includeLinks: true };
    static ROUTINE_DEFAULTS = { delivery: 'email', subject: '', body: '' };
}

registerInflection('pdfExportSetting','pdfExportSettings',PdfExportSetting);
