import { RecordBase, registerInflection } from 'lib/record-base';

export class ProgressionExercise extends RecordBase {
    static NAME = 'ProgressionExercise'
    static ASSOCS = {
        exerciseProgression: { type: 'belongsTo' },
        exercise: { type: 'belongsTo' }
    }
}

registerInflection('progressionExercise','progressionExercises',ProgressionExercise);