import React from 'react';
import { connect } from 'react-redux';
import { workoutSetupMatch, pickExercisePath, webViewUserPath, settingsMatch, emailPaywallMatches, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { loadErrorLog } from 'redux/actions';
import { Loader } from 'components/LoadingHOC';
import ActivityContainer from 'components/ActivityContainer';
import Card from 'components/Card';
import { ErrorLog } from 'lib/error-log';
import LinkButton from 'components/LinkButton';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,settingsMatch], transitions.slideOver]
    ]
};

const ErrorLogSuccess = ({ responseData: { errorLog }}) => {
    const record = new ErrorLog(errorLog);
    console.log(errorLog.backtrace)

    return (
        <ActivityContainer>
                <div className="col s12">
                    <h4 className="conversion-heading ml0 mb0">Info</h4>
                    <Card className="pa20">
                        <div className="mb20">
                            <label>Summary</label>
                            <div>{record.getTitle()}</div>
                        </div>
                        <div className="mb20">
                            <label>URL/Action</label>
                            <div>{record.urlOrAction()}</div>
                        </div>
                        <div className="mb20">
                            <label>Message</label>
                            <div>{record.message}</div>
                        </div>
                        <div className="mb20">
                            <label>Backtrace</label>
                            {record.backtraceArr().map((line,i) => (<div key={i}>{line}</div>))}
                        </div>
                        <div className="mb20">
                            <label>Client Data</label>
                            <div style={{ whiteSpace: 'pre' }}>{record.getClientData() || 'None'}</div>
                        </div>
                        <div>
                            <label>User</label>
                            <div>{record.userId && (
                                <LinkButton color="primary" outlined rounded to={webViewUserPath(record.userId)} openInApp >
                                    View User
                                </LinkButton>
                            )}</div>
                            <div>{record.userAgent}</div>
                        </div>
                    </Card>
                </div>
        </ActivityContainer>
    )
}

class ErrorLogPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, load, match: { params: { id }} } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title='Error Log'
            >
                <Loader
                    successComponent={ErrorLogSuccess}
                    type="page"
                    load={load.bind(null,id)}
                    preloaded={() => false}
                />
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = () => ({

})

export const mapDispatchToProps = dispatch => ({
    load: id => dispatch(loadErrorLog(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ErrorLogPage))