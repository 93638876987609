import React from 'react';
import { SimpleNavPage } from'components/Page';
import * as transitions from 'assets/transitions';
import { 
        clientDetailsMatch, allMainMatches, modalPathFor } from 'config/paths';
import { useHistory, useLocation } from 'react-router-dom';
import { NewActivityTypeModal, newActivityTypeModalSuffix } from 'partials/ExerciseModals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from 'react-i18next';
import { DropdownItem, MultiLevelDropdown } from 'components/Dropdown';
import LinkButton from 'components/LinkButton';
import Button, { AsyncSpinnerButton, BottomButtons, ConfirmActionButton } from 'components/Button';
import { StandaloneExerciseSearch } from 'partials/ExerciseSearchForm';
import { ActivityLog, Exercise } from 'lib/classes';
import LogActivityModal, { ActivityFormCore, activityLogSubmit, logActivitySuffix } from 'partials/LogActivityModal';
import { curActivitySel, userWithActivityLogsSel } from 'redux/selectors';
import { connect } from 'react-redux';
import { createActivityLog, discardCurrentActivity, startActivity, updateActivityLog } from 'redux/actions';
import { CountupTimer } from 'components/Timers';
import { FixedWorkoutNum } from 'partials/WorkoutInputs';
import { CountdownTimer } from 'partials/ConnectedTimers';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import FormikWrapper from 'components/FormikWrapper';
import { FullInput, LoadingSubmitButton } from 'components/Form';
import { FullTimeField } from 'components/TextInput';

const transitionMap = {
    rules: [
        [[...allMainMatches,clientDetailsMatch],transitions.slideOut],
        [[], transitions.slideOver]
    ]
};

const DoActivityScreen = ({ activity, startActivity, discardActivity, update, finish, t, history, user }) => {
    const [savedId, setSavedId] = React.useState(null);
    const [isSettingTimer, setIsSettingTimer] = React.useState(false);
    const exerciseSet = activity.exerciseSet();
    const countdown = exerciseSet.isCountdownActivity(t);
    const savedActivity = !_.isBlank(savedId) && user.activityLogById(savedId);
    let seconds = null;
    let autoStart = !!activity.startedAt;
    let Comp = CountupTimer;

    if(countdown) {
        seconds = exerciseSet.time;
        autoStart = true;
        Comp = CountdownTimer;
    }

    return (
        <div className="text-center">
            <div className="set-container" style={ {width: '480px'} }>
                <div className="wo-wt-ex-title">{!_.isBlank(savedId) ? t('Activity logged') : exerciseSet.exercise().name}</div>
                {!isSettingTimer && _.isBlank(savedId) && (
                    <Comp seconds={seconds} autoStart={autoStart} startedAt={activity.startedAt} render={({ secondsElapsed, secondsRemaining, display, start, pause, status }) => {

                        const startAll = () => {
                            start();
                            startActivity(activity);
                        }

                        const finishAll = () => {
                            pause();
                            return finish(activity.formValues(Math.round(secondsElapsed)));
                        }

                        return (
                            <div>
                                <div className="pt100 valign-wrapper cntr pb100">
                                    <FixedWorkoutNum 
                                        title={countdown ? t('Timer') : ''}
                                        content={display}
                                        subtitle={''}
                                    />
                                </div>
                                {status !== 'unstarted' && (
                                    <div>
                                        <AsyncSpinnerButton
                                            buttonProps={{
                                                color: 'primary',
                                                id: 'finish-btn',
                                                label: t(`Finish & Log`),
                                            }}
                                            successCallback={({ activityLogs }) => { setSavedId(Object.values(activityLogs)[0].id) }}
                                            icon={'check'}
                                            action={finishAll}
                                        />
                                    </div>
                                )}
                                {status === 'unstarted' && (<div>
                                    <Button color="primary" id="start-now-btn" onClick={() => startAll()}>
                                        <FontAwesomeIcon icon="play-circle" />
                                        {t('Start Now')}
                                    </Button>
                                    <Button color="primary" id="set-timer-btn" outlined className="ml5 shadow" onClick={() => setIsSettingTimer(true)}>
                                        <FontAwesomeIcon icon={["far","clock"]} />
                                        {t('Set Timer')}
                                    </Button>
                                </div>)}
                            </div>
                            )
                        }}
                    />
                )}
                {savedActivity && (
                    <React.Fragment>
                        <svg width="0" height="0">
                            <linearGradient id="medal-gradient">
                                <stop stopColor="#FA932D" offset="0%" />
                                <stop stopColor="#FFC22E" offset="100%" />
                            </linearGradient>
                        </svg>
                        <div><FontAwesomeIcon icon="medal" className="medal-gradient"/></div>
                        <div className="complete-container">
                            <FormikWrapper 
                                initialValues={savedActivity.initialFormValues()}
                                submit={activityLogSubmit(savedActivity,update)}
                                successCallback={() => history.go(-1)}
                                render={(formikProps) => {

                                    return (
                                        <form onSubmit={formikProps.handleSubmit}>
                                            <ActivityFormCore exercise={savedActivity.exercise()} {...formikProps} />
                                            <div>
                                                <LoadingSubmitButton icon="check" id="done-btn" label={t("Done")} loadState={formikProps.submitState} />
                                            </div>
                                        </form>
                                    )
                                }}
                            />
                        </div>
                    </React.Fragment>
                )}
                {isSettingTimer && (
                    <FormikWrapper 
                        initialValues={{ minSecTime: '0:00' }}
                        submit={({ minSecTime }) => {
                            activity.exerciseSet().time = _.parseStopwatchFormat(minSecTime);
                            activity.spec().setType = activity.exercise().isGeneralActivity() ? 104 : 100;
                            startActivity(activity);
                            setIsSettingTimer(false);
                            return Promise.resolve({ status: 'SUCCESS' });
                        }}
                        render={(formikProps) => {

                            return (
                                <div>
                                <div className="pt100 pb100 valign-wrapper cntr">
                                    <FullInput
                                        label={t("Set Timer")}
                                        className=""
                                        name="minSecTime"
                                        component={FullTimeField}
                                        formikProps={formikProps}
                                        inProps={{ className: 'center'}}
                                    />
                                </div>
                                <Button color="primary" id="start-timer-btn" className="ml5" onClick={() => formikProps.submitForm()}>
                                    <FontAwesomeIcon icon="play-circle" />
                                    {t('Start')}
                                </Button>
                                </div>
                            )
                        }}
                    />
                )}
                <div className="mt30">
                    {_.isBlank(savedId) && (<ConfirmActionButton 
                        onClick={() => discardActivity()}
                        render={({ onClick, ready }) => {
                            return (
                                <span id="discard-btn" className={classnames("underline clickable",{'font-grey': !ready,'red-text': ready })} onClick={onClick}>
                                    <FontAwesomeIcon icon={['fal','trash']} />
                                    {t('Discard activity')}
                                </span>
                            )
                        }}
                    />)}
                </div>
            </div>
        </div>
    )
}

export const actionRender = (t,pickActivity) => ({ exercisable }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: true, alignment: 'left', closeOnClick: true, closeOnChildClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <div className="action-container no-border extra-padding" id={`log-activity-${exercisable.id}-drop`} ref={ref} data-target={target} >
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </div>
                )
            }}
        >
            <DropdownItem label={t("Do Now")} id="do-now-btn" icon={"play-circle"} onClick={() => pickActivity(exercisable)} />
            <DropdownItem label={t("Log Now")} id="log-now-btn" icon={"check"} onClick={() => history.push(modalPathFor(logActivitySuffix,pathname, { exerciseId: exercisable.id }))}/>
        </MultiLevelDropdown>
    )
}

const SelectActivity = ({ date, location: { pathname }, t, pickActivity }) => {

    return (
        <div style={{ marginTop: '-20px' }}>
            <StandaloneExerciseSearch 
                context={{id: 'log_activity', type: date}} 
                initialValues={Exercise.activitySearchValues()} 
                basePath={pathname}
                hideForm
                hideGeneric
                noInfo
                actionComp={actionRender(t,pickActivity)}
                header={(<div className="label-3 text-center ml10 mr10 mb15 mt0">
                    <FontAwesomeIcon icon="info-circle" className="primary-text" /> {t("Select an activity")}
                </div>)}
            />
            <LogActivityModal date={date} />
            <NewActivityTypeModal />
            <BottomButtons>
                <LinkButton outlined color="primary" className="shadow" id="new-activity-btn" to={modalPathFor(newActivityTypeModalSuffix,pathname)}><FontAwesomeIcon icon="plus" /> {t('Activity Type')}</LinkButton>
            </BottomButtons>
        </div>
    )
}

class LogActivityPage extends React.Component {

    constructor(props) {
        super(props);
        const { currentActivity } = this.props;
        this.state = { currentActivity };
        props.setupTransitions(transitionMap);
    }

    render() {
        const { match: { params: { date }}, user, t, scrollRef, location, setupTransitions, start, discard, finish, update, history } = this.props;
        const { currentActivity } = this.state;

        const pickActivity = (exercise) => {
            this.setState({ currentActivity: ActivityLog.createFor(exercise,date,user) });
        }

        const startActivity = activity => {
            this.setState({ currentActivity: activity });
            start(activity.toRedux());
        }

        const discardActivity = () => {
            this.setState({ currentActivity: null });
            discard();
        }

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("Log Activity")}
            >
                {!currentActivity && (<SelectActivity date={date} location={location} t={t} pickActivity={pickActivity} />)}
                {currentActivity && (<DoActivityScreen 
                    activity={currentActivity} 
                    startActivity={startActivity} 
                    discardActivity={discardActivity} 
                    finish={finish} 
                    update={update} 
                    history={history}
                    t={t} 
                    user={user}
                />)}
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = (state) => ({
    currentActivity: curActivitySel(state),
    user: userWithActivityLogsSel(state)
})

const mapDispatchToProps = (dispatch) => ({
    start: data => dispatch(startActivity(data)),
    discard: () => dispatch(discardCurrentActivity()),
    finish: data => dispatch(createActivityLog(data,true)),
    update: data => dispatch(updateActivityLog(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(LogActivityPage));
