import React from 'react';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';

const requestStateHandler = (dispatchToPropKeysMap,mapStateToProps,mapDispatchToProps) => (Component) => {

    const getPropForAction = (actionKey) => dispatchToPropKeysMap[actionKey];

    class RequestStateHandler extends React.Component {

        constructor(props) {
            super(props);
            this.state = {};
            this.actions = {};
            this.currentCall = {};
            this.queuedCalls = {};
            this.cancelablePromise = {};
            Object.keys(dispatchToPropKeysMap).forEach((action) => {
                this.actions[action] = this.callAction.bind(this,action);
                this.queuedCalls[action] = [];
                this.currentCall[action] = null;
            })
        }

        render() {
            const passthroughProps = _.omit(this.props,[ ...Object.keys(dispatchToPropKeysMap), ...Object.values(dispatchToPropKeysMap)])

            return (
                <Component {...passthroughProps} {...this.actions} {...this.collectProps()} />
            )
        }

        callAction(key,data) {
            this.setState({ [getPropForAction(key)]: data });
            if(this.currentCall[key]) {
                this.queuedCalls[key].push(data);
            } else {
                this.sendAction(key,data);
            }

        }

        sendAction(key,data) {
            const action = this.props[key];
            this.currentCall[key] = true;
            this.cancelablePromise[key] = _.makeCancelable(action(data));
            const respHandler = response => {
                if(!response.isCanceled) {
                    const next = this.queuedCalls[key].shift();
                    if(next) {
                        this.sendAction(key,next);
                    } else {
                        this.currentCall[key] = false;
                        this.setState({ [getPropForAction(key)]: null });
                    }
                }

            }
            this.cancelablePromise[key].promise.then(respHandler).catch(respHandler);
        }

        getPropValue(key) {
            if(_.isBlank(this.state[key])) {
                return this.props[key];
            }
            return this.state[key];
        }

        collectProps() {
            return _.mapValues(_.keyBy(Object.values(dispatchToPropKeysMap),key => key),key => this.getPropValue(key));
        }

        componentWillUnmount() {
            Object.values(this.cancelablePromise).forEach(promise => promise.cancel());
        }

    }

    if(mapStateToProps || mapDispatchToProps) {
        return connect(mapStateToProps,mapDispatchToProps)(RequestStateHandler);
    } else {
        return RequestStateHandler;
    }
}

export default requestStateHandler;