import * as _ from 'lib/utilities';

class ProgressionTestSet {

    constructor(obj) {
        Object.assign(this,obj);
    }

    exercise() {
        return this.progressionTest.progression.exercises()[this.level];
    }

    result(rpe,setIndex) {
        this.rpe = rpe;
        return this.progressionTest.result(this,setIndex);
    }

    restTime() {
        if(!_.isBlank(this.rpe)) {
            if(this.rpe >= 3) {
                return 90;
            }
        }
        return 0;
    }

    reps() {
        return this.progressionTest.reps;
    }
}

export class ProgressionTest {

    constructor(obj) {
        Object.assign(this,obj);
        this.sets = this.sets.map(set => (new ProgressionTestSet({ ...set, progressionTest: this })));
    }

    submitSet(set,index) {
        this.sets = [ ...this.sets.slice(0,index), set ];
    }

    result(set,index) {
        this.submitSet(set,index);
        let lastSet = this.sets[this.sets.length-2];
        let level = null;
        if(set.rpe === 3 || (set.level === this.progression.maxLevel() && set.rpe <= 2) || (set.level === 0 && set.rpe >= 4)) {
            level = set.level;
        } else if(lastSet) {
            if(lastSet.level < set.level && set.rpe >= 4) {
                if(set.rpe >= 5 || lastSet.rpe >= 2) {
                    level = lastSet.level;
                } else {
                    level = set.level;
                }
            } else if(lastSet.level > set.level && set.rpe <= 2) {
                if(lastSet.rpe === 5 || set.rpe === 2) {
                    level = set.level;
                } else {
                    level = lastSet.level;
                }
            }
        }

        if(level || level === 0) {
            const exercise = this.progression.exercises()[level];
            return { exerciseProgressionId: this.progression.id, exerciseId: exercise.id, reps: this.reps, arbitraryMeasure: exercise.arbitraryMeasureVal }
        }

        return null;
    }

    getNextSet(index) {
        const set = this.sets[index];
        if(set) {
            return set;
        }

        let lastSet = this.sets[this.sets.length-1];
        let level = this.progression.defaultLevel();
        if(lastSet) {
            if(lastSet.rpe < 4) {
                level = Math.min(lastSet.level + 1,this.progression.maxLevel());
            } else {
                level = Math.max(lastSet.level - 1,0);
            }
        }

        return new ProgressionTestSet({ level, progressionTest: this })
    }
}