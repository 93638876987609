import React from 'react';
import { addExercisesToFormPath, allMainMatches, editTrainerFormMatch, trainerFormsPath } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPageWithTabs } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { trainerWithFormsSelector } from 'redux/selectors';
import { loadTrainerForm } from 'redux/actions';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import { Loader } from 'components/LoadingHOC';
import { AddExerciseToFormTabs } from 'partials/AddExerciseTabs';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { InitialTipPopup } from 'partials/Utilities';
import { STRTSTS_NOT_NEEDED } from 'config/tooltips';

const transitionMap = {
  rules: [
      [[...allMainMatches,editTrainerFormMatch,trainerFormsPath],transitions.slideOut]
  ]
};

class AddExercisesToForm extends React.Component {

    render() {
        const { trainer, loadForm, activeTab, setActiveTab, scrollRef, setupTransitions, t, match: { params: { formId, fieldType } } } = this.props;
        const isFormCheck = fieldType === 'form_check';
        const form = trainer.getFormById(formId);

        return (
          <SimpleNavPageWithTabs
            scrollRef={scrollRef}
            transitionMap={transitionMap}
            setupTransitions={setupTransitions}
            title={isFormCheck ? t('Add Form Checks') : t('Add Strength Tests')}
            tabLabels={ [t("Mine"),t("Database")]}
            activeTab={activeTab || 2}
            setActiveTab={setActiveTab}
          >
            <CenteredActivityContainer>
                <Loader
                    load={() => loadForm(formId)}
                    preloaded={() => !_.isBlank(form)}
                    successComponent={AddExerciseToFormTabs}
                    type='page'
                    trainer={trainer}
                    form={form}
                    activeTab={activeTab}
                    fieldType={fieldType}
                    basePath={addExercisesToFormPath(formId,fieldType)}
                />
                {!isFormCheck && (<InitialTipPopup tipName={STRTSTS_NOT_NEEDED} id="strtsts-not-needed-tip" >
                  <div>
                      <p className="pl20 pr20">
                          {t('strtsts not needed')}
                      </p>
                  </div>
              </InitialTipPopup>)}
            </CenteredActivityContainer>
          </SimpleNavPageWithTabs>
        )
    }
}

const mapStateToProps = state => ({
    trainer: trainerWithFormsSelector(state)
})

const mapDispatchToProps = dispatch => ({
    loadForm: id => dispatch(loadTrainerForm({ id }))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AddExercisesToForm));