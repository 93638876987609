import React from 'react';
import { connect } from 'react-redux';
import { workoutSetupMatch, pickExercisePath, notificationsPath, settingsMatch, emailPaywallMatches, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { isClientError } from 'components/LoadingHOC';
import { notificationRecordsSelector, userRecordSelector } from 'redux/selectors';
import { markNotificationsRead } from 'redux/actions';
import Card from 'components/Card';
import classnames from 'classnames';
import { PickNewRoutineModal } from 'partials/EditRoutineModals';
import { DeloadModal, UndeloadModal } from 'partials/DeloadModals';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,settingsMatch], transitions.slideOver]
    ]
};

class NotificationsPage extends React.Component {

    constructor(props) {
        super(props);
        const { markNotificationsRead, notifications } = props;
        const unchecked = _.filter(notifications,notification => !notification.checked)
        if(unchecked.length > 0) {
            markNotificationsRead(unchecked.map(n => n.id)).catch(e => {
                if(isClientError(e)) {
                    throw e.data;
                }
            })
        }
        this.state = { notifications }
    }

    render() {
        const { scrollRef, setupTransitions, t, history, user } = this.props;
        const { notifications } = this.state;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("Notifications")}
            >
                <div className="row">
                    {notifications.map(n => {
                        const path = n.linkPath(user);
                        return (
                            <div className="col s12 m6 l6">
                                <Card className={classnames("notification",{ "checked": n.checked, "buttonize": path })} onClick={() => history.push(path)}>
                                    {n.message}
                                </Card>
                            </div>
                        )
                    })}
                </div>
                <PickNewRoutineModal basePath={notificationsPath} />
                <DeloadModal basePath={notificationsPath} />
                <UndeloadModal basePath={notificationsPath} />
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = (state) => ({
    notifications: notificationRecordsSelector(state),
    user: userRecordSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    markNotificationsRead: (ids) => dispatch(markNotificationsRead(ids))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(NotificationsPage))