import * as _ from 'lib/utilities';

export const trainerModelFns = {

    teamTrainerAssessments() {
        if(this.isMaster()) {
            return _.filter(this.ownedAssessments,assessment => assessment.isTeam());
        }

        return this.getMasterAccount().teamTrainerAssessments();
    },

    myTrainerAssessments() {
        return _.filter(this.ownedAssessments,assessment => !assessment.isTeam())
    },

    allTrainerAssessments() {
        return [ ...this.myTrainerAssessments(), ...this.teamTrainerAssessments()];
    },

    activeTrainerAssessmentById(id) {
        return _.find(this.allTrainerAssessments(), assessment => assessment.id === Number(id));
    },

    getRecordsForFilters(recordType,filters,t) {
        const recs = this[`${filters.type}Trainer${recordType}`]();

        return _.sortBy(recs,rec => rec.resolvedName(t));
    },

    getTrainerRecordForModal(modalProps,recType) {
        const { match: { params: { id }} } = modalProps;
        if(id) {
            return this[`activeTrainer${recType}ById`](id);
        }

        return null;
    }
}