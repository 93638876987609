import React, {Component} from 'react';
import CircularProgress from 'components/CircularProgress';
import * as utils from 'lib/utilities';
import { useTranslation } from 'react-i18next';
import { brandName } from 'config/settings';
import { RetryLoadButton } from './Button';
import Card, { CardImageWithPlaceholder } from 'components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NativeError = window.Error;

export const validResponses = ['DEFAULT','SUCCESS','NETERR','MAINTENANCE','SERVERERR','REQUEST'];
export const isClientError = error => (!error.status || !validResponses.includes(error.status));

export const errorMsgFor = (status,t) => {
    const map = errorMessageMap(t)
    let msg = map[status];
    msg = msg || map["SERVERERR"];

    return msg;
}

export const errorMessageMap = (t) => ({ 
    'NETERR': t("can't connect", {brand_name: brandName()}), 
    'SERVERERR': t("something went wrong"), 
    'MAINTENANCE': t("servers unavailable", {brand_name: brandName()})
})

const DefaultOnly = ({ defaultComponent: DefaultComponent, ...rest }) => (<DefaultComponent {...rest} />)

const ErrorCore = ({defaultComponent, message, ...rest}) => {
    const DefaultComponent = defaultComponent
    return (
        <div className="text-center">
            <DefaultComponent {...rest} />
            <div className="red-text mb10">
                {message}            
            </div>
        </div>
    )
}

const StandloneLoader = ({ color }) => (<CircularProgress color={color} />)

const IconLoader = ({ LoaderWrapper=null, className, buttonProps }) => {
    
    if(!LoaderWrapper) {
        return (
            <React.Fragment>
                <FontAwesomeIcon icon='spinner' spin />
            </React.Fragment>
        )
    }
    
    return (<LoaderWrapper 
        className={className} 
        {...buttonProps}>
            <FontAwesomeIcon icon='spinner' spin />
    </LoaderWrapper>)
}

const Loading = ({ color, loadingContainerId }) => (<div id={loadingContainerId} className="text-center"><CircularProgress color={color} /></div>)

const Error = (props) => {
    const { t } = useTranslation();
    return (<ErrorCore {...props} message={t("something went wrong")} /> );
}

const Connectivity = (props) => {
    const { t } = useTranslation();
    return (<ErrorCore {...props} message={t("can't connect", {brand_name: brandName()})} /> );
}

const Maintenance = (props) => {
    const { t } = useTranslation();
    return (<ErrorCore {...props} message={t("servers unavailable", {brand_name: brandName()})} /> );
}

const FullPageContainer = (props) => (
    <div style={{minHeight: '75vh', width: '100%',display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
        <div>{props.children}</div>
    </div>
)

const FPLoading = (props) => (<FullPageContainer><Loading {...props} /></FullPageContainer>);
const FPError = (props) => (<FullPageContainer><Error {...props} /></FullPageContainer>);
const FPConnectivity = (props) => (<FullPageContainer><Connectivity {...props} /></FullPageContainer>);
const FPMaintenance = (props) => (<FullPageContainer><Maintenance {...props} /></FullPageContainer>);


const PaddingContainer = ({ children }) => (<div className="pt50 pb50 pl10 pr10">{children}</div>)
export const PDLoading = (props) => (<PaddingContainer><Loading {...props} /></PaddingContainer>);
const PDError = (props) => (<PaddingContainer><Error {...props} /></PaddingContainer>);
const PDConnectivity = (props) => (<PaddingContainer><Connectivity {...props} /></PaddingContainer>);
const PDMaintenance = (props) => (<PaddingContainer><Maintenance {...props} /></PaddingContainer>);


const CardContainer = ({ children }) => (
    <Card>
        <CardImageWithPlaceholder noContent width={500} height={334} color="primary" />
        <div className="centered-box" style={{position: 'absolute', top: 0, left: 0}}>
            {children}
        </div>
    </Card>
)
const CardLoading = (props) => (<CardContainer><Loading {...props} color="yellow" /></CardContainer>);
const CardError = (props) => (<CardContainer><Error {...props} /></CardContainer>);
const CardConnectivity = (props) => (<CardContainer><Connectivity {...props} /></CardContainer>);
const CardMaintenance = (props) => (<CardContainer><Maintenance {...props} /></CardContainer>);


const defaultMap =  {
    'REQUEST': Loading, 
    'NETERR': Connectivity, 
    'SERVERERR': Error, 
    'MAINTENANCE': Maintenance,
}

export const fpDefaultMap = {
    'REQUEST': FPLoading, 
    'NETERR': FPConnectivity, 
    'SERVERERR': FPError, 
    'MAINTENANCE': FPMaintenance
}

export const pdDefaultMap = {
    'REQUEST': PDLoading, 
    'NETERR': PDConnectivity, 
    'SERVERERR': PDError, 
    'MAINTENANCE': PDMaintenance
}

export const cardDefaultMap = {
    'REQUEST': CardLoading, 
    'NETERR': CardConnectivity, 
    'SERVERERR': CardError, 
    'MAINTENANCE': CardMaintenance
}

export const blankDefaultMap = {
    'REQUEST': () => '', 
    'NETERR': () => '', 
    'SERVERERR': () => '', 
    'MAINTENANCE': () => ''
}

export const loaderOnlyMap = {
    'REQUEST': StandloneLoader, 
    'NETERR': DefaultOnly, 
    'SERVERERR': DefaultOnly, 
    'MAINTENANCE': DefaultOnly
}

export const iconOnlyMap = {
    'REQUEST': IconLoader, 
    'NETERR': DefaultOnly, 
    'SERVERERR': DefaultOnly, 
    'MAINTENANCE': DefaultOnly
}

export default function loadingContainer(componentMap,params={ }) {

    const { type } = params;
    let map = defaultMap;

    if(type === "page") {
        map = fpDefaultMap;
    } else if (type === "padded") {
        map = pdDefaultMap;
    } else if (type === "card") {
        map = cardDefaultMap;
    } else if(type === 'loaderOnly') {
        map = loaderOnlyMap;
    } else if(type === 'icon') {
        map = iconOnlyMap;
    } else if(type === 'blank') {
        map = blankDefaultMap;
    }

    componentMap = { ...map, ...componentMap }

    class LoadingContainer extends Component {

        render() {
            let { loadState, loadingContainerId, ...other } = this.props;
            let { defaultComponent } = params;
            if(!defaultComponent)
                defaultComponent = componentMap['DEFAULT'];
            if(!defaultComponent)
                defaultComponent = RetryLoadButton;
            const WrappedComponent = componentMap[loadState];
            let wrappedProps = { ...other, defaultComponent };
            if(loadState === 'REQUEST') {
                wrappedProps.loadingContainerId = loadingContainerId;
            }
            return (<WrappedComponent {...wrappedProps} />);
        }
    }

    return LoadingContainer
}

export class LoadingContainerParent extends Component {

    //load, preloaded, skipAutoLoad, alwaysLoad
    constructor(props) {
        super(props);
        this.firstTry = true;
        this.makeApiRequest = this.makeApiRequest.bind(this);
        this.handleApiResponse = this.handleApiResponse.bind(this);
        if(props.skipAutoLoad) {
            this.state = {loadState: 'DEFAULT'};
        } else if(!this.props.preloaded()) {
            this.state = {loadState: 'REQUEST'};
            this.makeApiRequest(false);
        } else {
            this.state = { loadState: 'SUCCESS' };
            if(props.alwaysLoad) {
                this.makeApiRequest(false);
            }
        }
    }

    render() {
        const { component, preloaded, load, alwaysLoad, ...rest } = this.props;
        const Component = component;
        return (
            <Component firstTry={this.firstTry} load={this.makeApiRequest.bind(this,true)} loadState={this.state.loadState} responseData={this.state.responseData} {...rest} />
        )
    }

    componentWillUnmount() {
        const { dontCancelPromise } = this.props;

        this.unmounted = true;
        if(this.loadPromise && !dontCancelPromise) {
            this.loadPromise.cancel();
        }
    }

    makeApiRequest(setLoadState=true) {
        const { load } = this.props;
        this.loadPromise = utils.makeCancelable(load());
        this.loadPromise.promise.then(this.handleApiResponse).catch(this.handleApiResponse)
        if(setLoadState) {
            this.setState( { loadState: 'REQUEST' } );
        }
    }

    handleApiResponse(response) {
        if(response instanceof NativeError) {
            throw response;
        }

        if(response.isCanceled) {
            return;
        }

        const validReponse = validResponses.includes(response.status);
        if(!validReponse) {
            throw(response.data);
        }

        if(!this.props.preloaded() || this.state.loadState !== 'SUCCESS') {
            this.firstTry = false;
            this.loadPromise = null;
            if(this.props.successCallback && response.status === 'SUCCESS') {
                this.props.successCallback(response.data);
            } else if(this.props.failCallback && response.status !== 'SUCCESS' && response.status !== 'REQUEST') {
                this.props.failCallback(response.status);
            }
            if(validReponse && !this.unmounted) {
                this.setState({loadState: response.status, responseData: response.data });
            }
        } else if(validReponse && !this.unmounted) {
            this.setState({responseData: response.data });
        }
    }
}

export const LoadingContainerChild = ({ successComponent, type, overrideMap, defaultComponent, loadState, loadingContainerId, ...other }) => {
    let map = defaultMap;

    if(type === "page") {
        map = fpDefaultMap;
    } else if (type === "padded") {
        map = pdDefaultMap;
    } else if (type === "card") {
        map= cardDefaultMap;
    } else if(type === 'loaderOnly') {
        map= loaderOnlyMap;
    } else if(type === 'icon') {
        map = iconOnlyMap;
    } else if(type === 'blank') {
        map = blankDefaultMap;
    }

    map = { ...map, 'SUCCESS': successComponent, ...(overrideMap || {}) };

    if(!defaultComponent)
        defaultComponent = map['DEFAULT'];
    if(!defaultComponent)
        defaultComponent = RetryLoadButton;
    const WrappedComponent = map[loadState];
    let wrappedProps = { ...other, defaultComponent };
    if(loadState === 'REQUEST') {
        wrappedProps.loadingContainerId = loadingContainerId;
    }
    return (<WrappedComponent {...wrappedProps} wrapperLoadState={loadState} />);
}

export class Loader extends React.Component {

    constructor(props) {
        super(props);
        let { successComponent, type, overrideMap, defaultComponent } = props;
        if(defaultComponent) {
            overrideMap = overrideMap || {};
            overrideMap['DEFAULT'] = defaultComponent;
        }
        this.childComponent = (props) => (<LoadingContainerChild successComponent={successComponent} type={type} overrideMap={overrideMap} {...props} />);
    }

    render() {
        const { successComponent, type, overrideMap, defaultComponent, ...rest } = this.props;

        return (
            <LoadingContainerParent 
                component={this.childComponent}
                {...rest}
            />
        )
    }

}