import React from 'react';
import { regenerateWeekPathFor, editWeeklyMealMatch, userMealMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import { MealView, NutritionModalButton } from 'partials/MealPlanViews';
import { ViewToggleButton } from './MealPlan'
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import { setMpViewType } from 'redux/actions';
import { getMealInfoMatrixSelector, mealPlanViewTypeSel, userWithMealPlanSelector } from 'redux/selectors';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { resolvedHomePath } from 'redux/helpers';
import { ExitMealPlanButton } from 'partials/Utilities';

const transitionMap = {
    rules: [
        [mealPlanSlideOutPaths,transitions.slideOut],
        [[editWeeklyMealMatch,userMealMatch], transitions.slideOver]
    ]
};

const NoopButton = () => ''

let ViewSharedMealsCore = ({ miniProfile, week, mealInfoMatrix, viewType }) => {
    
    return (
        <MealView 
            actionType="viewShared" 
            miniProfile={miniProfile}
            week={week} 
            mealInfoMatrix={mealInfoMatrix} 
            viewType={viewType} 
            basePath={regenerateWeekPathFor(week)}
            ActionComp={<NoopButton />}
        />
            
    )
}

const redirectCheckCreator = (user,week) => () => {
    const genState = user.mealPlanGenerateState(week);
    if(!user.mealPlanInitialized() || genState === 'ungenerated') {
        return resolvedHomePath();
    }

    return null;
}


const mapStateToProps = (state,props) => {
    let { match: { params: { week, miniProfileId }}} = props;
    const user = userWithMealPlanSelector(state);
    const miniProfile = user.miniProfileById(miniProfileId);
    week = _.isBlank(week) ? 'current' : week;
    const mealInfoMatrixSelector = getMealInfoMatrixSelector(week);

    return {
        user,
        miniProfile,
        mealInfoMatrix: mealInfoMatrixSelector(state),
        viewType: mealPlanViewTypeSel(state),
        week
    }
}

const mapDispatchToProps = dispatch => ({
    setViewType: type => dispatch(setMpViewType(type))
})

const ViewSharedMealsPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, week, mealInfoMatrix, miniProfile, viewType, setViewType } = props;

    return (
        <SimpleNavPage
            darkMode
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            containerClass="with-drop-btn"
            title={t("name's Meals", { name: miniProfile.name })}
            redirectCheck={redirectCheckCreator(mealInfoMatrix.user,mealInfoMatrix.week)}
            navChildren={(<div className="mr5 mb5 align-middle display-flex ab-right">
                <NutritionModalButton mealInfoMatrix={mealInfoMatrix} basePath={regenerateWeekPathFor(week)} className="mb1" isTrainerEditing={true} />
                <ViewToggleButton className="dark-mode simple-nav-resp" viewType={viewType} setViewType={setViewType} />
                <ExitMealPlanButton className="hide-on-small-and-down with-flex" />
            </div>)}
        >
            <ViewSharedMealsCore 
                week={week}
                mealInfoMatrix={mealInfoMatrix}
                viewType={viewType}
                miniProfile={miniProfile}
            />
        </SimpleNavPage>
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(ViewSharedMealsPage);