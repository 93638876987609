import React from 'react';
import { Link } from './Routing';
import { HashLink } from 'react-router-hash-link';
import { useHistory } from "react-router-dom";
import Button from "components/Button";
import { goWrapper } from 'redux/helpers';

export const BackButton = ({ render, backNum=-1 }) => {
    const history = useHistory();
    return (
        render({ back: () => goWrapper(history,backNum) })
    )
}

const LinkButton = React.forwardRef((props,ref) => {

    const { to, withHash, ...others } = props;
  
      return (
          
            <Button tag={withHash ? HashLink : Link} to={to} {...others} ref={ref}>
              {props.children}
            </Button>
          
      );
})

export default LinkButton;
  