import React from 'react';
import MToast from 'assets/js/toasts';
import ReactDOM from 'react-dom';

export default class Toast extends React.Component {

    constructor(props) {
        super(props);
        this.toggleToastContent = this.toggleToastContent.bind(this);
        this.dismiss = this.dismiss.bind(this);
        const { message, collapseContent, completeCallback, okCallback, moreCallback, dismissText, moreText, id, ...rest } = props;
        const html = document.createElement('div');
        let msg = document.createElement(`b`);
        if(typeof message === 'string') {
            msg.innerHTML = message;
        } else {
            ReactDOM.render(message,msg);
        }
        
        let titleParent = document.createElement('div');
        titleParent.style.display = 'flex';
        titleParent.style.alignItems = 'center';
        titleParent.id = id;
        let remove = document.createElement('button');
        remove.classList.add('btn-flat','toast-action', 'ml10','mr0');
        remove.innerHTML = dismissText || 'OK';
        remove.onclick = () => { if(okCallback) { okCallback() }; this.dismiss() };
        msg.style.flexGrow = 1;

        if(collapseContent) {
            let btn = document.createElement('button');
            btn.classList.add('btn-flat','toast-action', 'ml10','mr0');
            btn.innerHTML = moreText || 'More';
            btn.onclick = () => { if(moreCallback) { moreCallback() }; this.toggleToastContent() };
            let content = document.createElement(`div`);
            content.classList.add('p10','collapse-content');
            content.style.height = 0;
            content.style.transition = 'height .35s ease-in-out';
            content.style.overflow = 'hidden';
            content.style.maxWidth = '400px';
            content.innerHTML = collapseContent;
            msg.style.flexGrow = 1;

            titleParent.appendChild(msg);
            titleParent.appendChild(btn);
            titleParent.appendChild(remove);
            html.appendChild(titleParent);
            html.appendChild(content);
        } else {
            titleParent.appendChild(msg);
            titleParent.appendChild(remove);
            html.appendChild(titleParent);
        }
        
        this.completeCallback = this.completeCallback.bind(this);
        this.toast = new MToast({ html, completeCallback: this.completeCallback, ...rest });
    }

    render() {
        return '';
    }

    completeCallback() {
        if(this.toast) {
            if(this.props.completeCallback) {
                this.props.completeCallback();
            }
            this.toast = null;
        }
    }

    componentWillUnmount() {
        if(this.toast) {
            this.toast.dismiss();
            this.toast = null;
        }
    }

    toggleToastContent(e) {
        const el = this.toast.el;
        const colContent = el.querySelector('.collapse-content');
        if(colContent.dataset.isOpen === 'true') {
            colContent.style.height = 0;
            colContent.dataset.isOpen = false;
        } else {
            colContent.style.height = colContent.scrollHeight + 'px';
            colContent.dataset.isOpen = true;
        }
    }

    dismiss() {
        if(this.toast) {
            this.toast.dismiss();
        }
    }

}