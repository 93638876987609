import React from 'react';
import { useTranslation } from 'react-i18next';
import { userWithMealPlanSelector, mealSearchFullSelector, recipeSearchFullSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import SearchPage from 'partials/MealAndRecipeSearch';
import { startBrowseMeals, startBrowseRecipes, updateRecipeSearch, updateMealSearch } from 'redux/actions';
import { recipeBrowserPath, trainerDbRecipesPath, trainerMyRecipesPath, trainerRecipesPathFor, trainerTeamRecipesPath, viewMealPathFor } from 'config/paths';
import { ActivityTab, ActivityTabs } from 'components/BottomNav';
import { SlightEmphasisIconNote } from 'components/Typography';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TooltippedText } from 'components/Tooltipped';
import AnimTo from 'components/AnimTo';
import withTrainer from 'partials/TrainerLoader';

export const TrainerRecipeTabs = ({ recipeImports, clearRecipeImports }) => {
    const { t } = useTranslation();

    return (
        <ActivityTabs>
            <ActivityTab id="fav-recs-tab" label={<React.Fragment>{t("My Recipes")}{recipeImports > 0 && (<AnimTo top={'150px'} left={'150px'} tag='b' className="primary-text"> +{recipeImports}</AnimTo>)}</React.Fragment>} icon="heart" path={trainerMyRecipesPath} onClick={() => (recipeImports > 0 && clearRecipeImports())} />
            <ActivityTab id="db-recs-tab" label={t('Database')} icon="list-alt" path={trainerDbRecipesPath} />
            <ActivityTab id="team-recs-tab" label={t('Team')} icon="users" path={trainerTeamRecipesPath} />
        </ActivityTabs>
    )
}

const mapStateToRecipeProps = state => ({
    title: 'Recipe Browser',
    user: userWithMealPlanSelector(state),
    mealSearch: recipeSearchFullSelector(state)
})

const mapDispatchToRecipeProps = dispatch => ({
    initAction: () => dispatch(startBrowseRecipes()),
    searchAction: (data,page) => dispatch(updateRecipeSearch(data,page))
})

const mapStateToMealProps = state => ({
    title: 'Meal Browser',
    user: userWithMealPlanSelector(state),
    mealSearch: mealSearchFullSelector(state)
})

const mapDispatchToMealProps = dispatch => ({
    initAction: () => dispatch(startBrowseMeals()),
    searchAction: (data,page) => dispatch(updateMealSearch(data,page))
})

const BrowserPage = ({ user, trainer, mealSearch, title, basePath, ...props }) => {
    const { isMain } = props;
    const { t } = useTranslation();
    let searchAlreadyLoaded = () => mealSearch.browserAlreadyLoaded();
    let tTitle = t(title);
    let showDbTab = isMain || !user || user.allowedToEditOwnMealPlan();
    

    return (
        <SearchPage
            mealSearch={mealSearch}
            searchAlreadyLoaded={searchAlreadyLoaded}
            redirect={null}
            title={tTitle}
            user={user}
            basePath={basePath || recipeBrowserPath}
            getClickCardProps={(tabType,meal) => {
                return { linkTo: viewMealPathFor(meal.urlStub(),10) }
            }}
            noDbTab={!showDbTab}
            {...props}
        />
    )
}

const RecipeBrowserPage = connect(mapStateToRecipeProps,mapDispatchToRecipeProps)(BrowserPage)
const MealBrowserPage = connect(mapStateToMealProps,mapDispatchToMealProps)(BrowserPage)

let MainRecipeBrowser = ({ match: { params: { type } }, addRecipeImport, ...rest }) => {
    const { t } = useTranslation();
    let activeTab = 1;
    if(type === 'favorites') {
        activeTab = 2;
    } else if(type === 'team') {
        activeTab = 4;
    }
    const bottomTip = (
        <div className="mt25 center">
            <TooltippedText text={t("How Do Coach's Favorites Work")} tip={t("trainer faves explanation")} />
        </div>
    )
    const myRecipesEmptyMsg = (
        <div>
            <SlightEmphasisIconNote text={<React.Fragment>
                {t('trainer no faves')}
                <div className="mt25 center">
                    <LinkButton noShadow color="primary" to={trainerDbRecipesPath} outlined>
                        <FontAwesomeIcon icon={['far',"list-alt"]} /> {t('Browse Database')}
                    </LinkButton>
                </div>
            </React.Fragment>} />
            {bottomTip}
        </div>
    )

    return (
        <div className="mt10">
            <RecipeBrowserPage 
                {...rest}
                bottomTip={bottomTip}
                myRecipesEmptyMsg={myRecipesEmptyMsg}
                activeTab={activeTab}
                isMain
                addRecipeImport={addRecipeImport}
                basePath={trainerRecipesPathFor(type)}
            />
        </div>
    )
}

MainRecipeBrowser = withTrainer(false)(MainRecipeBrowser)

export { RecipeBrowserPage, MealBrowserPage, MainRecipeBrowser }