import React from 'react';
import { InputWithErrors } from 'components/Form';
import { SmallerNumberInput, SmallerTextInput } from 'components/TextInput';
import { useTranslation } from 'react-i18next';
import { IconTip } from 'components/Tooltipped';
import * as _ from 'lib/utilities';
import PlusMinusToggle from 'components/PlusMinusToggle';
import classnames from 'classnames';
import Button from 'components/Button';
import loadingContainer, { blankDefaultMap, LoadingContainerParent } from 'components/LoadingHOC';
import { getLastExerciseMax } from 'redux/actions';
import { connect } from 'react-redux';

const LastMaxNote = ({ exerciseSet, responseData }) => {
    const { t } = useTranslation();
    let max = responseData.lastMax;
    if(max) {
        if(exerciseSet.isMetric()) {
            max = _.roundToF(max/2.2,0.5);
        } else {
            max = _.roundToF(max,0.5);
        }
    
        return <span id="last-max-note">{` ${t('last max tip', { max: `${max} ${exerciseSet.weightSuffix(t)}`, reps: exerciseSet.minReps })}`}</span>;
    } else {
        return '';
    }
}

const LastMaxComp = loadingContainer({
    'SUCCESS': LastMaxNote,
    ...blankDefaultMap
})

let InstructionBox = ({ title, instructions, exerciseSet, className, getLastExerciseMax, id, preInstructions, postInstructions }) => {
    const { t } = useTranslation();
    const classNames = classnames('wo-wt-notes', { [className]: className })

    return (
        <div className={classNames} id={id ? id : ''}>
            <div className="left-align-block">
                {preInstructions && (
                    <React.Fragment>
                        <span className="regular">{t('Note')} </span>
                        {preInstructions}
                        <br/>
                    </React.Fragment>
                )}
                <span className="regular">{title} </span>{instructions}
                {exerciseSet && exerciseSet.isMaxTest() && (
                    <LoadingContainerParent 
                        load={getLastExerciseMax.bind(null,exerciseSet.exercise(),exerciseSet.minReps)}
                        preloaded={() => false}
                        component={LastMaxComp}
                        exerciseSet={exerciseSet}
                    />
                )}
                {postInstructions && !_.isBlank(postInstructions[1]) && (
                    <React.Fragment>
                        <br/><span className="regular">{postInstructions[0]}: </span>
                        {postInstructions[1]}
                    </React.Fragment>
                )}
                {exerciseSet && exerciseSet.comments() && (
                    <React.Fragment>
                        <br/><span id="other-comments-sect" className="regular">{t('Other comments')}: </span>
                        {exerciseSet.comments()}
                    </React.Fragment>
                )}
            </div>
        </div>
    )
}

const mapDispatchToGetMax = dispatch => ({
    getLastExerciseMax: (exercise,reps) => dispatch(getLastExerciseMax(exercise,reps))
})

InstructionBox = connect(null,mapDispatchToGetMax)(InstructionBox);
export { InstructionBox };

export const UnbrokenQuestion = ({ yesHandler, noHandler, className, id }) => {
    const { t } = useTranslation();
    const classNames = classnames("wo-wt-input-container",{ [className]: className} );

    return (
        <div className={classNames} id={id ? id : ''}>
            <div className="wo-wt-ex-subtitle">
                {t("Did you finish without resting?")}
            </div>
            <div className="mt10">
                <Button rounded color='primary' className="ml5" id="set-unbroken-btn" outlined onClick={yesHandler}>
                    {t("Yes")}
                </Button>
                <Button rounded color='red' className="ml5" id="set-broken-btn" outlined onClick={noHandler}>
                    {t("No")}
                </Button>
            </div>
        </div>
    )}

export const FixedWorkoutNum = ({ title, content, subtitle, id }) => {

    return (
        <div className="wo-wt-input-container" id={id ? id : ''}>
            <div className="wo-wt-ex-subtitle">
                {title}
            </div>
            <div className="wo-wt-ex-num">
                {content}
            </div>
            <div className="wo-wt-ex-subtitle">
                {subtitle}
            </div>
        </div>
    )
}

export const WorkoutInput = ({ exercise, field, setFieldValue, setFieldTouched, inProps, fieldPrefix, ...formikProps }) => {

    const { t } = useTranslation();
    let Component = SmallerNumberInput;
    let inputClass = 'medium-text-input';
    let helperText = '';
    let label = ''
    let tooltip = '';
    if(field === 'arbitraryMeasure') {
        Component = SmallerTextInput;
        inputClass = 'small-text-input';
        helperText = _.lowerFirst(t('Optional'));
        label = exercise.arbitraryMeasure;
        tooltip = exercise.arbitraryTip;
    } else if(field === 'kgWeight') {
        helperText = t('kgs');
    } else if (field === 'weight') {
        helperText = t('lbs');
    } else if (field === 'reps') {
        label = t('Reps Completed');
        if(exercise.unilateral) {
            helperText = t('per side');
        }
    } else if(field === 'trainingMaxPercent') {
        label = t('Training max');
        helperText = t('percent');
        tooltip = t('training max tip');
    } else if(field === 'distance') {
        label = t('Distance Covered');
        helperText = t('meters');
    } else if(field === 'kmDistance') {
        label = t('Distance Covered');
        helperText = t('kilometers');
    } else if (field === 'mileDistance') {
        label = t('Distance Covered');
        helperText = t('miles');
    }

    if(field === 'kgWeight' || field === 'weight') {
        if(exercise.needsDumbellInfo()) {
            helperText = t("units per dumbbell",{ units: helperText })
        }
        label = t('Weight')
    }

    let placeholder = '';
    if(field === 'distance' || field === 'kmDistance' || field === 'mileDistance') {
        placeholder = t('optional')
    }

    let input = (<InputWithErrors
        className="inline ma0"
        align="center"
        helperText={helperText}
        inProps={ { className: inputClass, placeholder: placeholder, ...inProps } }
        name={fieldPrefix ? `${fieldPrefix}${field}` : field}
        component={Component}
        {...formikProps}
    />)

    if(field === 'reps') {
        const { values: { reps }, errors: { reps: error } } = formikProps;
        const oldInput = input;
        input = (<PlusMinusToggle 
            tighter
            inline
            large
            value={reps} 
            setValue={(val) => { setFieldTouched('reps'); setFieldValue('reps',val) }}  
            error={error} 
            render={({ value, error, onChange}) => {
                return (
                    oldInput
                )
        }} />)
    }

    return (
        <div className='wo-wt-input-container text-center'>
            <div className="wo-wt-ex-subtitle"><div className="inline-tooltip-container">{label}{!_.isBlank(tooltip) && (<IconTip size='1x' icon="info-circle" containerClassName='inline-tooltip' className="font-grey" msg={tooltip} id={`${field}-tooltip`}/>)}</div></div>
            {input}
        </div>
    )
}