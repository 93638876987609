import React from 'react';
import AppSplash from 'components/AppSplash';
import Page from 'components/Page';

class Connector extends React.Component {
    constructor(props) {
        super(props);
        const { history, setupTransitions, afterPath, beforeAction } = props;
        setupTransitions({ rules: [] });
        const res = beforeAction && beforeAction();
        if(res && typeof res.then === 'function') {
            res.then(() => history.replace(afterPath))
        } else {
            history.replace(afterPath);
        }
    }

    render() {
        return <Page><AppSplash /></Page>;
    }
}

export default Connector;