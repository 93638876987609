import { RecordBase } from 'lib/record-base';
import * as _ from 'lib/utilities';
import moment from 'moment';

export class ErrorLog extends RecordBase {
    static ASSOCS = {
        user: { type: 'belongsTo' }
    }

    getTitle() {
        return `${this.userId || 'anon'} --- ${this.name} --- ${this.platform} --- ${this.createdAtFormatted()}`;
    }

    createdAtMoment() {
        return moment(this.createdAt);
    }

    createdAtFormatted() {
        return this.createdAtMoment().format('MMM Do YYYY hh:mm:ss');
    }

    backtraceArr() {
        return this.backtrace.split(/\r?\n/)
    }

    urlOrAction() {
        return _.isBlank(this.url) ? this.action : this.url;
    }

    getClientData() {
        return _.isBlank(this.clientState) ? null : JSON.stringify(JSON.parse(this.clientState),null,6);
    }
}