import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import Card from 'components/Card';
import { ContactForm } from 'partials/ContactForm';
import { SlightEmphasisIconNote } from 'components/Typography';
import { allMainMatches, emailPaywallMatches, onboardMatch, pickExercisePath, startPath, trainerBrandingMatch, trainerConversionMatch, workoutSetupMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';

const transitionMap = {
    rules: [
        [[...allMainMatches,trainerConversionMatch,trainerBrandingMatch],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,startPath,onboardMatch], transitions.slideOver]
    ]
}

const ContactPage = (props) => {
    const { t } = useTranslation();
    const [submitted,setSubmitted] = useState(false);
    const successCallback = () => {
        setSubmitted(true);
    }

    return (
        <SimpleNavPage {...props} transitionMap={transitionMap} title={t('Contact')}>
            <CenteredActivityContainer>
                <div className="mt15">
                    {submitted && <SlightEmphasisIconNote text={t("message submitted")} />}
                    {!submitted && (<Card className="pa15">
                        <ContactForm successCallback={successCallback} showType validate />
                    </Card>)}
                </div>
            </CenteredActivityContainer>
        </SimpleNavPage>
    )
}

export default ContactPage;
