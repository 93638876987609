import { FullInput } from 'components/Form';
import { FullFormModal } from 'components/Modal';
import { FullTimeField, NumberInput } from 'components/TextInput';
import { modalPathFor } from 'config/paths';
import { ActivityLog } from 'lib/classes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { createActivityLog } from 'redux/actions';
import { getExerciseSelector, userRecordSelector } from 'redux/selectors';

export const ActivityFormCore = ({ exercise, modalProps, ...formikProps }) => { 
    const { t } = useTranslation();
    const { values } = formikProps;
    const distLabel = values.kmDistance !== undefined ? t('km') : t('mi')

    return (
        <div className="pa20">
            <div className="valign-wrapper cntr">
                <FullInput
                    label={t("Time")}
                    className=""
                    name="minSecTime"
                    component={FullTimeField}
                    formikProps={formikProps}
                    inProps={{ className: 'center'}}
                />
                {exercise.canHaveDistance() && (<FullInput
                    label={`${t("Distance")} (${distLabel})`}
                    id="dist-field"
                    className="ml5"
                    name={values.kmDistance ? "kmDistance" : "mileDistance"}
                    component={NumberInput}
                    formikProps={formikProps}
                    inProps={{ className: 'center'}}
                />)}
            </div>
        </div>                
    )
}

const mapStateToCoreProps = (state,props) => {
    const { modalProps: { match: { params: { exerciseId } } } } = props;
    const exerciseSelector = getExerciseSelector(Number(exerciseId));
    return {
        exercise: exerciseSelector(state)
    }
}

export const activityLogSubmit = (log,submit) => values => {
    const set = log.exerciseSet();
    set.update(values);
    return submit(log.formValues())
}

export const logActivitySuffix = '/log_now/:exerciseId'

const LogActivityModal = ({ user, date, submit }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(logActivitySuffix,pathname);

    return (
        <FullFormModal 
            path={path} 
            scrollable 
            fullWidth={false}
            stateSelector={mapStateToCoreProps}
            exact 
            title={({ stateProps: { exercise } }) => exercise.name}
            formProps={ ({ stateProps: { exercise }, modalProps: { match: { params: { exerciseId } } }}) => {       
                let initialValues = { minSecTime: '0:00' };
                if(exercise.canHaveDistance()) {
                    if(user.isMetric()) {
                        initialValues.kmDistance = '';
                    } else {
                        initialValues.mileDistance = '';
                    }
                }
                return ({
                    initialValues,
                    submit: activityLogSubmit(ActivityLog.createFor(exercise,date,user),submit,null)
                })
            }}
            footerProps={{ saveLabel: t('Log'), saveIcon: 'check', noCancel: true }}
            render={({ stateProps: { exercise }, ...props }) => {
                return (<ActivityFormCore exercise={exercise} {...props} />)
            }}
        />
    )
}

const mapDispatchToProps = (dispatch) => ({
    submit: data => dispatch(createActivityLog(data))
})

const mapStateToProps = state => ({
    user: userRecordSelector(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(LogActivityModal);