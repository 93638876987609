import { assignHabitToClientsPathFor, modalPathFor } from 'config/paths';
import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';

const editSuffix = '/edit/:id';

export class Habit extends RecordBase {
    static NAME = 'Habit';
    static ASSOCS = {
        owner: { type: 'belongsTo', tableName: 'users', inverse: 'ownedHabits' },
        habitLogs: { type: 'hasMany' },
        schedulableSettings: { type: 'hasMany', inverse: 'source' }
    }

    static typeCol(t) {
        return [
            { text: t('General'), value: '' },
            { text: t('Active minutes'), value: 'activity' },
            { text: t('Steps'), value: 'steps'}
        ]
    }

    isRoutine() { 
        return false;
    }

    isActive() {
        return !this.inactive;
    }

    resolvedName(t) {
        if(this.isActivityGoal()) {
            return t('X active minutes goal',{ mins: this.goalQuantity });
        } else if(this.isStepGoal()) {
            return t('X steps goal', { steps: this.goalQuantity });
        }
        return _.fallbackText(this.name,t('Untitled'))
    }

    resolvedDescription(t) {
        if(_.isBlank(this.description)) {
            return t("This record doesn't have a description", { record_name: t('habit')})
        }
        return this.description;
    }

    editPath(pathname) {
        return modalPathFor(editSuffix,pathname, { id: this.id });
    }

    assignToClientsPath() {
        return assignHabitToClientsPathFor(this.id);
    }

    initialFormValues() {
        return _.parseObjForForm(_.pick(this,'id','name','description','type','goalQuantity'));
    }

    isQuantityType() {
        return this.type === 'activity' || this.type === 'steps';
    }

    isActivityGoal() {
        return this.type === 'activity';
    }

    isStepGoal() {
        return this.type === 'steps';
    }
}

registerInflection('habit','habits',Habit);
