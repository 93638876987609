import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, mealSearchModalPathFor, viewMealPathMatch, viewTempMealMatch, viewRecipeReplacementMatch, viewTempRecipeReplacementMatch, editRecipePathFor, editRecipeMatch, emailPaywallMatches, modalPathFor } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPageWithTabs } from 'components/Page';
import { allergyTagsSelector, msCategoryRecordSelector, myRecipesFiltersSelector, recentRecipesSelector, trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { Link, Redirect } from 'components/Routing';
import Button, { BottomButtons, DefaultAsyncActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import { Loader } from 'components/LoadingHOC';
import { MealCardContainer, MealCardInnerContainer, MealCard, SideDishImages, MealCardImage, MealCardTitle, MealCardSubtitle, SidebarContainer, SideDishes } from './MealPlanViews';
import { MacroSummaryText } from './MacroSummary';
import InfiniteScroll, { InfiniteScrollForm } from 'components/InfiniteScroll';
import { IconNote, PaddedIconNote } from 'components/Typography';
import FormikWrapper from 'components/FormikWrapper';
import Card from 'components/Card';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { CollapsibleFormSection, IconOnlySubmitLC, TagButtons, ToggleButton3 } from 'components/Form';
import { MealSearch, MealSearchCategory } from 'lib/meal-search';
import { User, Meal } from 'lib/classes';
import { loadMealSearchCategories, loadRecentRecipes, myRecipesLoad, startEditingRecipe, updateMyRecipesFilters } from 'redux/actions';
import { AutoSubmitTextInput } from 'components/TextInput';
import { CustomMacroFields } from './CustomMacroFields';
import Tooltipped from 'components/Tooltipped';
import LinkButton from 'components/LinkButton';
import { ScrollableFormModal } from 'components/Modal';
import * as Yup from 'yup';
import PlusMinusToggle, { PlusMinusField } from 'components/PlusMinusToggle';
import { ExitMealPlanButton, InitialTip, LikeRecipeButton, TrainerBadgeWithContext } from 'partials/Utilities'; 
import ProRequiredButton from './ProRequiredButton';
import { mealPlanSlideOutPaths } from './MainLayout';
import { LikeRecipeForClientsPopup } from './PickClientPopups';
import TeamRecipesTab from './TeamRecipesTab';
import { ExclusionTags } from './UserFields';
import DislikedRecipesModal, { dislikedRecipeModalSuffix } from './DislikedRecipesModal';
import { paywallPathFor } from 'redux/helpers';


const defTransitionMap = {
    rules: [
        [[...mealPlanSlideOutPaths, offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch],transitions.slideOut],
        [[viewMealPathMatch,viewTempMealMatch,viewRecipeReplacementMatch,viewTempRecipeReplacementMatch,editRecipeMatch,...emailPaywallMatches], transitions.slideOver]
    ]
};

export const TypeButton = ({children, className, ...rest }) => (<div className={classnames("type-button-area", { [className]: className })} {...rest}>{children}</div>)

const servingChangeCreator = (setServings,recipeMeal) => (values,setValues) => newVal => {
    setServings(recipeMeal.actualServings(newVal));
    setValues({ ...values, servings: newVal });
}

export const RecipeMealServingToggle = ({ recipeMeal, values, setValues, handleBlur, errors, setServings }) => {
    const {t} = useTranslation();

    return (
        <PlusMinusToggle 
            tighter
            inline
            value={values.servings} 
            setValue={servingChangeCreator(setServings,recipeMeal)(values,setValues)}  
            error={errors.servings} 
            render={({ value, error, onChange}) => {
                return (
                    <PlusMinusField 
                        label={recipeMeal.servingName(t)}
                        onChange={onChange} 
                        onBlur={handleBlur} 
                        value={value} 
                        error={error} 
                        id={`recipe-${recipeMeal.recipeId}-servings-field`}
                        name="servings"
                    />
                )
            }} 
        />
    )
}

export const SelectWithServingsButton = ({ mealSearch, action, meal, user, linksEnabled, setLinksEnabled, setServingsForRecipe }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [selfEnabled,setSelfEnabled] = useState(null);
    const enabled = selfEnabled || linksEnabled;
    const fullAction = ({ servings }) => {
            setSelfEnabled(true);
            setLinksEnabled(false);
            return action({ mealSearch, meal: { recipeMeals: [{ ...meal.recipeMeals[0], servings: meal.recipeMeals[0].actualServings(servings) }] } });
    }
    const statusCallback = (status,formikBag,data,values) => {
        if(status === 'SUCCESS' && data) {
            history.go(-1);
        } else {
            setSelfEnabled(null);
            setLinksEnabled(true);
        }
    }

    return (
        <FormikWrapper 
            initialValues={ { servings: meal.recipeMeals[0].readableServings() }}
            validationSchema={Yup.object().shape({
                servings: Yup.number().moreThan(0).required()
            })}
            submit={fullAction}
            statusCallback={statusCallback}
            render={({ submitState, submitForm, values, setValues, errors, handleBlur }) => {
                let onClick = (enabled && submitState !== 'REQUESTING') ? submitForm : null;
                if(!user.hasProAccess(['soft'])) {
                    onClick = () => history.push(paywallPathFor('meal_swap',user))
                }
                return (
                    <React.Fragment>
                        <div className="meal-card-servings-cont">
                            <RecipeMealServingToggle 
                                recipeMeal={meal.recipeMeals[0]}
                                values={values}
                                setValues={setValues}
                                errors={errors}
                                handleBlur={handleBlur}
                                setServings={setServingsForRecipe.bind(null,meal.recipeMeals[0].recipeId)}
                            />
                        </div>
                        <TypeButton onClick={onClick} id={`select-recipe-${meal.reactKey()}-btn`}>
                            <IconOnlySubmitLC loadState={submitState}>
                                <FontAwesomeIcon icon={'check'} className="mr5" />  {t("Select")}
                            </IconOnlySubmitLC>
                        </TypeButton>
                    </React.Fragment>
                )
            }}
        />
    )
}

export const SelectMealButton = ({ mealSearch, action, meal, user, linksEnabled, setLinksEnabled }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const [selfEnabled,setSelfEnabled] = useState(null);
    const enabled = selfEnabled || linksEnabled;
    const fullAction = () => {
            setSelfEnabled(true);
            setLinksEnabled(false);
            return action({ mealSearch, meal });
    }


    if(user.hasProAccess(['soft'])) {
        return (
            <DefaultAsyncActionButton 
                Comp={TypeButton}
                loaderType="icon"
                LoaderWrapper={TypeButton}
                id={`select-recipe-${meal.reactKey()}-btn`}
                action={fullAction}
                disableAction={!enabled}
                successCallback={() => history.go(-1)}
                failCallback={() => {
                    setSelfEnabled(null);
                    setLinksEnabled(true);
                }}
            >
                <FontAwesomeIcon icon={'check'} className="mr5" />  {t("Select")}
            </DefaultAsyncActionButton>
        )
    }

    return (
        <TypeButton id={`select-recipe-${meal.reactKey()}-btn`} onClick={() => history.push(paywallPathFor('meal_swap',user))}>
            <FontAwesomeIcon icon={'check'} className="mr5" /> {t("Select")}
        </TypeButton>
    )

}

const MacroWarningIcon = ({ innerIcon, transform, color='orange' }) => {

    return (
        <div className="macro-warning-icon">
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={'circle'} color="white" size='2x' />
                <FontAwesomeIcon icon={innerIcon || 'exclamation-circle'} color={color} size='2x' transform={transform || "grow-1"} className="shadowed-circle-icon"  />
            </span>
        </div>
    )
}

export const MacroWarning = ({ msg, left, color='orange' }) => {
    return (
        <Tooltipped
            key={msg}
            options={{ 
                html: msg,
                classes: ['square']
            }}
            component='div'
            className={classnames('warning-icon',{ left })}
        >
            <MacroWarningIcon color={color} />
        </Tooltipped>
    )
}

const MealListCard = ({ user, meal, style, ActionComp, linkTo, showMacros, showHeart, prevFoodList, foodListWithout, showExtraInfo, macroWarning, onClick }) => {    
    let groceryCaption, groceryAmount;
    if(showExtraInfo) {
        groceryAmount = meal.ingredientLoad(prevFoodList,foodListWithout);
        if(groceryAmount >= 0 && ((prevFoodList && prevFoodList.length !== 0) || (foodListWithout && foodListWithout.length !== 0))) {
            groceryCaption = `+${groceryAmount}`;
        } else {
            groceryCaption = `${groceryAmount}`;
        }
    }

    return (
        <MealCard
            style={style}
            actionComp={ActionComp && React.cloneElement(ActionComp, { meal })}
            warningIcon={macroWarning}
            trainerBadge={macroWarning ? null : <TrainerBadgeWithContext recipe={meal.mainRecipe()} />}
            heart={showHeart && <LikeRecipeButton recipe={meal.mainRecipe()} className={'shorter'} />}
            linkTo={linkTo}
            onClick={onClick}
            id={`meal-list-card-${meal.mainRecipeId()}`}
        >
            <SideDishImages sideDishes={meal.sideDishes()} />
            <MealCardImage imageUrl={meal.mainImageUrl('mediumThumb')} />
            <MealCardTitle>{meal.mainName()}</MealCardTitle>
            <MealCardSubtitle>
                {showMacros && (<MacroSummaryText macroHash={meal.macroHash()} />)}
                {!showMacros && <SideDishes sideDishes={meal.sideDishes()} />}
                {showExtraInfo && (<div className="mt3">
                    <div className="mr10 inline-block">
                        <FontAwesomeIcon icon={['far',"clock"]} /> {meal.totalActiveTime()} 
                    </div>
                    <div className="mr10 inline-block">
                        {_.xArray(meal.dollarSigns()).map((ds,i) => (<FontAwesomeIcon key={i} icon={['far','dollar-sign']} />))}
                    </div>
                    <div className="inline-block">
                        <FontAwesomeIcon icon={['far',"shopping-basket"]} /> {groceryCaption} 
                    </div>
                </div>)}
            </MealCardSubtitle>
        </MealCard>
    )
}

const MealListing = ({ 
    user,
    meals, 
    noSidebar, 
    ActionComp, 
    getClickCardProps, 
    getErrFor, 
    prevFoodList, 
    foodListWithout, 
    scrollTriggerComp, 
    linksEnabled, 
    topNote,
    showMacros, 
    showHeart,
    showExtraInfo,
    extraSpace=0,
    sidesCap=4
}) => {
    const minMeals = meals.length;
    const maxSides = meals.reduce((curMaxSides,meal) => (curMaxSides > meal.sideDishes().length ? curMaxSides : meal.sideDishes().length),0);
    const { t } = useTranslation();

    return (
        <MealCardContainer
            noSidebar={noSidebar}
            minMeals={minMeals}
            maxMeals={3}
            maxSides={maxSides+extraSpace}
            sidesCap={sidesCap}
            alwaysWrap
            render={({ containerStyle, cardStyle }) => {

                return (
                    <React.Fragment>
                        {topNote}
                        <MealCardInnerContainer style={containerStyle}>
                                {meals.map((meal,mealIndex) => {
                                    const err = getErrFor(t,meal);
                                    return (
                                        <MealListCard 
                                            key={meal.reactKey()}
                                            meal={meal}
                                            style={cardStyle}
                                            ActionComp={ActionComp}
                                            showMacros={showMacros}
                                            showHeart={showHeart}
                                            prevFoodList={prevFoodList}
                                            foodListWithout={foodListWithout}
                                            showExtraInfo={showExtraInfo}
                                            macroWarning={err && (<MacroWarning msg={err} />)}
                                            user={user}
                                            { ...(linksEnabled ? getClickCardProps(meal) : {}) }
                                        />
                                    )
                                })}
                        </MealCardInnerContainer>
                        {scrollTriggerComp}
                    </React.Fragment>
                )
            }}
        />
    )
} 

const TopNote = ({ text, variant, children }) => {

    return (
        <div className="inline-text-block mb10 lh1">
            <IconNote variant={variant} text={text} /> {children}
        </div>
    )
}

const DatabaseTabListing = ({ user, meals, ActionComp, mealSearch, scrollTriggerComp, linksEnabled, getClickCardProps }) => {
    const { t } = useTranslation();
    let topNote = null;
    if(mealSearch.hasContext()) {
        if(mealSearch.customSearch) {
            topNote = meals.length > 0 ? { text: t('custom meal search warning'), variant: 'warning' } : null;
        } else {
            if(mealSearch.showLimitedResultsWarning()) {
                topNote = { text: t('limited results message'), variant: 'warning' }
            } else {
                topNote = meals.length > 0 ? { text: t(t('macros met meal search info')), variant: 'info' } : null;
            }
        }
    }
    topNote = topNote && <TopNote {...topNote} />

    return (
        <MealListing 
            user={user}
            meals={meals}
            noSidebar={false}
            getErrFor={(t,meal) => mealSearch.macroWarningFor(t,meal)}
            scrollTriggerComp={scrollTriggerComp}
            showMacros={mealSearch.isRecipeSearch()}
            showExtraInfo
            showHeart={mealSearch.isRecipeSearch()}
            topNote={topNote}
            foodListWithout={mealSearch.foodListWithout || []}
            prevFoodList={mealSearch.foodList || []}
            ActionComp={ActionComp}
            linksEnabled={linksEnabled}
            getClickCardProps={getClickCardProps.bind(null,'database')}
            extraSpace={mealSearch.isRecipeSearch() ? 2 : 1}
        />
    )
} 

const TabContainer = React.forwardRef(({ children },ref) => (<div ref={ref} className="limit-50-h display-flex">{children}</div>))

const mapStateToRecentsProps = state => ({
    recents: recentRecipesSelector(state)
})

const mapDispatchToRecentsProps = dispatch => ({
    loadRecents: page => dispatch(loadRecentRecipes(page))
})

const getErrForFn = (mealSearch) => {
    if((mealSearch.isRecipeSearch() && mealSearch.hasContext())) {
        return (t,meal) => mealSearch.macroWarningFor(t,meal);
    }
    return () => null;
}

export const RecipeListing = ({ user, mealSearch, meals, ActionComp, getClickCardProps, linksEnabled, ...rest }) => {
    const [overrideServings,setOverrideServings] = useState({});
    const  { t } = useTranslation();
    const setServingsForRecipe = (recipeId,servings) => {
        if(_.isNumeric(servings) && Number(servings) > 0) {
            setOverrideServings({ ...overrideServings, [recipeId]: Number(servings) });
        } else {
            setOverrideServings(_.omit(overrideServings,recipeId));
        }
    }
    meals.forEach(meal => {
        meal.mainDish().servings = overrideServings[meal.mainRecipeId()] || meal.mainRecipe().servingsForCals(mealSearch.originalCalorieTarg());
    })
    let extraProps = {};
    if(mealSearch.isRecipeSearch()) {
        if(mealSearch.hasContext()) {
            extraProps = { extraSpace: 6, sidesCap: 8 }
        } else {
            extraProps = { extraSpace: 2 }
        }
    }
    const topNote = (mealSearch.isRecipeSearch() && mealSearch.hasContext() && meals.length > 0) ? (<div className="mb10 lh1"><IconNote variant="info" text={t("recipe servings default message")} /></div>) : null;

    return (
        <MealListing 
            user={user}
            meals={meals}
            noSidebar
            getErrFor={getErrForFn(mealSearch)}
            mealSearch={mealSearch}
            showMacros={mealSearch.isRecipeSearch()}
            showHeart={true}
            showExtraInfo={mealSearch.isRecipeSearch()}
            prevFoodList={mealSearch.foodList || []}
            foodListWithout={mealSearch.foodListWithout || []}
            ActionComp={ActionComp && React.cloneElement(ActionComp, { setServingsForRecipe })}
            getClickCardProps={getClickCardProps.bind(null,'recipes')}
            linksEnabled={linksEnabled}
            topNote={topNote}
            {...extraProps}
            {...rest}
        />
    )
}

let RecentsTab = ({ user, mealSearch, recents: { more, page, results }, loadRecents, ActionComp, linksEnabled, getClickCardProps }) => {
    const { t } = useTranslation();
    let finalMeals = mealSearch.isRecipeSearch() ? results : _.filter(results,results => results.mainRecipe().canBeMainDish());

    return (
        
        <InfiniteScroll
            load={loadRecents} 
            initialPage={page}
            loadedPage={page} 
            fullyLoaded={!more} 
            records={results}
            component={TabContainer}
            dontRenderTrigger
            noResultsComp={<PaddedIconNote variant="info" text={t("You don't have any recent recipes")} />}
            id="recent-recipes-scroll"
            render={({ maxIndex, scrollTriggerComp }) => {
                return (
                    <RecipeListing 
                        user={user}
                        meals={finalMeals}
                        mealSearch={mealSearch}
                        ActionComp={ActionComp}
                        linksEnabled={linksEnabled}
                        getClickCardProps={getClickCardProps}
                        scrollTriggerComp={scrollTriggerComp}
                    />
                )
            }}
        />
    )
}

RecentsTab = connect(mapStateToRecentsProps,mapDispatchToRecentsProps)(RecentsTab)

const MyRecipesFiltersCard = ({ trainer, user, filters, updateFilters }) => {
    const { t } = useTranslation();
    const formProps = { setFieldTouched: () => {}, setFieldValue: (name,value) => updateFilters({ type: value }), name: 'type', values: filters }
    let col = [{ text: t('All'), value: 'all' }, { text: (trainer && trainer.id !== user.id) ? t('Client Favorites') : t('My Favorites'), value: 'liked'}]
    if(user.isClient()) {
        col.push({text: t("Coach's Favorites"), value: 'trainer'});
    }

    return (
        <div className="pa10 text-center">
            <div className="no-wrap" style={{ overflowX: 'auto'}}>
                <TagButtons
                    col={col}
                    {...formProps}
                    single
                    btnId={val => `recipes-for-${val}-btn`}
                />
            </div>
        </div>
    )
}

const MyRecipesTabSuccess = ({ user, trainer, mealSearch, ActionComp, linksEnabled, getClickCardProps, filters, filters: { type }, updateFilters, myRecipesEmptyMsg, bottomTip }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();
    const { owned, liked, trainer: trainerRecipes } = user.myRecipesFor(mealSearch);
    const all = [ ...liked, ...trainerRecipes, ...owned ];
    const showFilters = (user.isClient() && trainerRecipes.length > 0) || _.difference(owned,liked).length > 0;
    let recipes;
    if(type === 'all' || !showFilters) {
        recipes = [ ...liked, ...trainerRecipes, ...owned ];
    } else if(type === 'liked') {
        recipes = liked;
    } else if(type === 'trainer' && user.isClient()) {
        recipes = trainerRecipes;
    } else {
        recipes = all;
    }
    const dislikedRecipesLink = user.hasDislikedRecipes() ? (<div className="center mt50">
        <Link className="underline" to={modalPathFor(dislikedRecipeModalSuffix,pathname)}>
            <FontAwesomeIcon icon="ban" /> {t('Disliked Recipes')}
        </Link>
    </div>) : null;

    if(trainer && myRecipesEmptyMsg && all.length === 0) {
        return (
            <div className="row limit-50-h">
                <div className="col s12 m8 offset-m2 l6 offset-l3">
                    {myRecipesEmptyMsg}
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            {showFilters && (<div className="row limit-50-h">
                <div className="col s12 m12 l8 offset-l2">
                    <MyRecipesFiltersCard 
                        user={user}
                        trainer={trainer} 
                        filters={filters} 
                        updateFilters={updateFilters} 
                    />
                </div>
            </div>)}
            <div className="limit-50-h display-flex">
                <RecipeListing 
                    user={user}
                    meals={Meal.mealsForRecipes(_.uniqBy(recipes,rec => rec.staticId()))}
                    mealSearch={mealSearch}
                    ActionComp={ActionComp}
                    linksEnabled={linksEnabled}
                    getClickCardProps={getClickCardProps}
                />
            </div>
            <DislikedRecipesModal />
            {dislikedRecipesLink || bottomTip}
        </React.Fragment>
    )
}

export const NewRecipeButton = ({ user, trainer, closeAndGo, startEditingRecipe, ...rest }) => {
    const history = useHistory();
    const { t } = useTranslation();
    const clickHandler = () => {
        startEditingRecipe(trainer || user);
        if(closeAndGo) {
            closeAndGo(editRecipePathFor(1));
        } else {
            history.push(editRecipePathFor(1));
        }
    }
    
    return (
        <ProRequiredButton  
            user={user}
            closeAndGo={closeAndGo}
            context="recipe_editor"
            proClickHandler={clickHandler}
            blockTypes={['old','hard','soft']}
            render={({ onClick }) => (
                <Button 
                    rounded 
                    color="primary" 
                    className="no-upcase shadow"
                    {...rest}
                    id={`new-recipe-btn`}
                    onClick={trainer ? clickHandler : onClick}
                >
                    <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span className="no-wrap">{t('New Recipe')}</span>
                </Button>
            )}

        />
    )
}

let MyRecipesTab = ({ user, mealSearch, loadRecipes, startEditingRecipe, ActionComp, basePath, linksEnabled, getClickCardProps, filters, updateFilters, trainer, isMain, myRecipesEmptyMsg, bottomTip }) => {
    const needLoading = user.myRecipesNeedLoading();

    return (
        <React.Fragment>
            <Loader 
                load={loadRecipes}
                preloaded={() => !needLoading}
                alwaysLoad={user.isClientOrTrainer()}
                type='page'
                mealSearch={mealSearch}
                user={user}
                ActionComp={ActionComp}
                basePath={basePath}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
                successComponent={MyRecipesTabSuccess}
                trainer={trainer}
                filters={filters}
                updateFilters={updateFilters}
                myRecipesEmptyMsg={myRecipesEmptyMsg}
                bottomTip={bottomTip}
            />
            <BottomButtons className={classnames({ "has-bot-nav": isMain })}>
                <NewRecipeButton user={user} startEditingRecipe={startEditingRecipe} trainer={trainer} />
            </BottomButtons>
        </React.Fragment>
    )
}

const mapStateToRecipeTabProps = state => ({
    filters: myRecipesFiltersSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToRecipeTabProps = dispatch => ({
    startEditingRecipe: (user) => dispatch(startEditingRecipe(user)),
    loadRecipes: () => dispatch(myRecipesLoad()),
    updateFilters: filters => dispatch(updateMyRecipesFilters(filters))
})

MyRecipesTab = connect(mapStateToRecipeTabProps,mapDispatchToRecipeTabProps)(MyRecipesTab);

const BrowseFullDatabaseTip = ({ children }) => {
    const { t } = useTranslation();
    
    return (
        <InitialTip 
            tipName={'browse_full_database_tip'} 
            text={t("Leave these fields black to brows the full database")} 
            delay={0}
        >
            {children}
        </InitialTip>
    )
}

const NutritionToggle = ({ mealSearch, formikProps }) => {
    const { values: { customSearch, targetCalories, macroFields } } = formikProps;
    const autoSubmit = !!formikProps.handleAutoSubmitBlur;
    const showFields = customSearch || !mealSearch.hasContext();
    const { t } = useTranslation();
    const ContainerComp = mealSearch.hasContext() ? BrowseFullDatabaseTip : 'div';

    return (
        <React.Fragment>
            <div className="basic-heading mt10">{t('Nutrition')}</div>
            {mealSearch.hasContext() && (
                <ToggleButton3 
                    value={customSearch || false}
                    id={'toggle-custom-search-btn'}
                    setValue={val => formikProps.setFieldValue('customSearch',val)}
                    options={[{ text: t('Try to fit my plan'), value: false }, {text: t('Custom search'), value: true }]}
                    className="mt10 mb10"
                />
            )}
            {showFields && (
                <div className="text-center">
                    {mealSearch.hasContext() && (<div className="ml10 mr10 lh1 tiny-text">
                        <IconNote text={`${t("To fit plan")} ${mealSearch.fitPlanMacroSummary(t)}`} />
                    </div>)}
                    <div className="custom-macro-container">
                        <ContainerComp>
                            <AutoSubmitTextInput 
                                label={t("Calories")}
                                noHelperText
                                className="search-field mb10"
                                value={formikProps.values.targetCalories}
                                submitForm={formikProps.submitForm}
                                timeout={autoSubmit ? 500 : 9999999}
                                inputProps={ {
                                    className: 'text-center',
                                    name: "targetCalories",
                                    onBlur: formikProps.handleBlur,
                                    onChange: formikProps.handleChange,
                                    id: 'calories-field'
                                } }
                            />
                        </ContainerComp>
                    </div>
                    <CustomMacroFields 
                        nutritionParameters={macroFields}
                        flowProps={{...formikProps, handleBlur: autoSubmit ? formikProps.handleAutoSubmitBlur : formikProps.handleBlur }}
                        colName='macroFields'
                        t={t}
                        calTarget={targetCalories}
                        noChecks
                    />
                </div>
            )}
            
        </React.Fragment>
    )


}

const CategoryTags = ({ categories, formikProps }) => {
    const { t } = useTranslation();
    const tags = MealSearchCategory.tagsCol(categories, t);

    return (
        <React.Fragment>
            <div className="basic-heading mt10">{t('Category')}</div>
            <TagButtons
                col={tags}
                {...formikProps}
                name={'mealSearchCategoryId'}
                single
                btnId={val => `category-tag-${val}`}
                buttonProps={ { className: 'ma2 btn-no-shadow' } }
            />
        </React.Fragment>
    )

}

const SearchForm = ({ mealSearch, categories, allergyTags, loadCategories, formikProps }) => {
    const { t } = useTranslation();
    const [suitableCats,setSuitableCats] = useState(MealSearchCategory.getSuitableCats(categories,formikProps.values));
    const autoSubmit = !!formikProps.handleAutoSubmitBlur;

    const setFieldValue = (fieldName,value) => {
        const { values: curVals, setValues } = formikProps;
        let values = { ...curVals, [fieldName]: value };
        const newCats = MealSearchCategory.getSuitableCats(categories,values);
        const newCatIds = newCats.map(cat => cat.id);
        if(!newCatIds.includes(values.mealSearchCategoryId)) {
            values.mealSearchCategoryId = '';
        }
        setValues(values);
        setSuitableCats(newCats);
    }

    const foodTypeCol = MealSearch.mealTypesForForm(t);
    const dietTypesCol = User.dietTypesForSearchCol(t);
    const exclusionTags = mealSearch.fullExclusionTags(allergyTags);

    const movableSection = (
        <React.Fragment>
            <div className="basic-heading mt10">{t('Meal type')}</div>
            <TagButtons
                btnId={(val) => `food-type-${val.replace(/,/g,'')}`}
                col={foodTypeCol}
                {...formikProps}
                setFieldValue={setFieldValue}
                name={'acceptableFoodTypes'}
                single
                buttonProps={ { className: 'ma2 btn-no-shadow' } }
            />
            {mealSearch.isRecipeSearch() && (<React.Fragment>
                <div className="basic-heading mt10">{t('Dish type')}</div>
                <TagButtons
                    btnId={(val) => `dish-type-${val}`}
                    col={MealSearch.dishTypesCol(t)}
                    {...formikProps}
                    setFieldValue={setFieldValue}
                    name={'dishType'}
                    single
                    buttonProps={ { className: 'ma2 btn-no-shadow' } }
                />
            </React.Fragment>)}
        </React.Fragment>
    )

    return (
        <div className="pa10">
            <div>
            <AutoSubmitTextInput 
                label={t("Search")}
                noHelperText
                className="search-field mb10"
                value={formikProps.values.keywords}
                submitForm={formikProps.submitForm}
                timeout={autoSubmit ? 500 : 9999999}
                inputProps={ {
                    name: "keywords",
                    onBlur: formikProps.handleBlur,
                    onChange: formikProps.handleChange,
                    id: 'keywords-field'
                } }
            />
            </div>
            {mealSearch.hasContext() && (<NutritionToggle formikProps={formikProps} mealSearch={mealSearch} />)}
            {!mealSearch.hasContext() && movableSection}
            <CategoryTags categories={suitableCats} formikProps={formikProps} />
            <CollapsibleFormSection header={t('More')}>
                {!mealSearch.hasContext() && (<NutritionToggle formikProps={formikProps} mealSearch={mealSearch} />)}
                {mealSearch.hasContext() && movableSection}
                <div className="basic-heading mt10">{t('Diet type')}</div>
                <TagButtons
                    col={dietTypesCol}
                    {...formikProps}
                    setFieldValue={setFieldValue}
                    name={'dietType'}
                    single
                    btnId={val => `diet-type-${val}`}
                    buttonProps={ { className: 'ma2 btn-no-shadow' } }
                />
                <div className="basic-heading mt10">{t('Exclusions')}</div>
                {exclusionTags.length > 0 && (<ExclusionTags allergyTags={exclusionTags} buttonProps={ { className: 'ma2 btn-no-shadow' } } {...formikProps} />)}
                {exclusionTags.length === 0 && (<div>{t('None')}</div>)}
            </CollapsibleFormSection>
        </div>
    )
}

let SearchFormLoader = ({ formikProps, categories, allergyTags, loadCategories, mealSearch }) => {

    return (
        <Loader 
            load={loadCategories}
            preloaded={() => false}
            type='padded'
            allergyTags={allergyTags}
            successComponent={SearchForm}
            formikProps={formikProps}
            categories={categories}
            mealSearch={mealSearch}
        />
    )
}

const mapStateToFormProps = state => ({
    categories: msCategoryRecordSelector(state),
    allergyTags: allergyTagsSelector(state)
})

const mapDispatchToFormProps = dispatch => ({
    loadCategories: () => dispatch(loadMealSearchCategories())
})

SearchFormLoader = connect(mapStateToFormProps,mapDispatchToFormProps)(SearchFormLoader)

class SearchModalContents extends React.Component {

    componentDidMount() {
        const { setCloseModal, close } = this.props;
        setCloseModal({fn: close});
    }

    render() {
        const { renderHeader, renderFooter, renderScrollable, formikProps, mealSearch, t } = this.props;
        return (
            <React.Fragment>
                {renderHeader()}
                {renderScrollable({ children: (
                    <SearchFormLoader 
                        formikProps={formikProps} 
                        autoSubmit={false} 
                        mealSearch={mealSearch} 
                    />
                )})}
                {renderFooter({ dontCloseOnDone: true, loadState: formikProps.submitState, id: 'save-search-btn', saveChildren: (
                    <React.Fragment>
                        <FontAwesomeIcon icon={'search'} /> {t('Search')}
                    </React.Fragment>
                )})}
            </React.Fragment>
        )
    }

    componentWillUnmount() {
        const { setCloseModal } = this.props;
        setCloseModal({});
    }
}

const SearchModal = ({ basePath, formikProps, mealSearch, setCloseModal }) => {
    const { t } = useTranslation();

    return (
        <ScrollableFormModal
            fullWidth 
            noOverflow 
            limitWidth 
            path={mealSearchModalPathFor(basePath)} 
            exact 
            title={t('Search Settings')}
            icon="cog"
            render={(modalProps) => {

                return (
                    <SearchModalContents 
                        formikProps={formikProps}
                        setCloseModal={setCloseModal}
                        mealSearch={mealSearch}
                        t={t}
                        {...modalProps}
                    />
                )
            }} 
        />
    )
}

const DatabaseTabContents = ({ user, mealSearch, results, formikProps, scrollTriggerComp, belowBreakpoint, ActionComp, basePath, setCloseModal,linksEnabled, getClickCardProps, isMain }) => {
    const { t } = useTranslation();
    const ButtonComp = linksEnabled ? LinkButton : Button;
    const buttonProps = linksEnabled ? { to: mealSearchModalPathFor(basePath) } : {};

    return (
        <div className="limit-50-h display-flex">
            {!belowBreakpoint && (<SidebarContainer>
                <Card>
                    <SearchFormLoader formikProps={formikProps} autoSubmit mealSearch={mealSearch} />
                </Card>
            </SidebarContainer>)}
            {belowBreakpoint && (
                <React.Fragment>
                    <BottomButtons className={classnames("hide-on-large-only", { "has-bot-nav": isMain })}>
                        <ButtonComp 
                            rounded 
                            color="primary" 
                            className="no-upcase shadow" 
                            id={`search-modal-btn`}
                            {...buttonProps}
                        >
                            <FontAwesomeIcon icon={'search'}></FontAwesomeIcon> {t("Edit Search")}
                        </ButtonComp>
                    </BottomButtons>
                    <SearchModal basePath={basePath} mealSearch={mealSearch} formikProps={formikProps} setCloseModal={setCloseModal} />
                </React.Fragment>
            )}
            <DatabaseTabListing
                user={user}
                meals={results}
                scrollTriggerComp={scrollTriggerComp}
                mealSearch={mealSearch}
                ActionComp={ActionComp}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
            />
        </div>
    )
}

const DatabaseTab = ({ user, mealSearch, searchAction, ActionComp, basePath, linksEnabled, getClickCardProps, isMain }) => {
    const { t } = useTranslation();
    const [closeModal,setCloseModal] = useState({});

    return (
        <InfiniteScrollForm
            initialPage={mealSearch.resultIndex || 1}
            initialMsg={null}
            noResultsComp={<PaddedIconNote variant="info" text={t(`No ${mealSearch.isRecipeSearch() ? 'recipes' : 'meals'} match your search`)} />}
            initialValues={mealSearch.formValues()}
            records={mealSearch.results}
            loadedPage={mealSearch.resultIndex}
            load={searchAction}
            more={mealSearch.hasMore()}
            dontRenderTrigger
            id="meal-search-scroll-wrapper"
            render={({ maxIndex, startValues, submitLoad, retryLoad, status, scrollTriggerComp }) => {

                return (
                    <ResponsiveContainer 
                        render={({ belowBreakpoint }) => (
                            <FormikWrapper 
                                initialValues={ startValues }
                                submit={submitLoad}
                                validate={(values) => ({})}
                                valuesFilter={values => ({ ...values, acceptableFoodTypes: values.acceptableFoodTypes.split(',')})}
                                initialErrors={{}} 
                                autoSubmit={!belowBreakpoint}
                                successCallback={() => (closeModal.fn && closeModal.fn())}
                                render={(formikProps) => {

                                    return (
                                        <form onSubmit={formikProps.handleSubmit}>
                                            <DatabaseTabContents 
                                                user={user}
                                                results={_.filter(mealSearch.results,(result,index) => (index < maxIndex))}
                                                formikProps={formikProps}
                                                scrollTriggerComp={scrollTriggerComp}
                                                belowBreakpoint={belowBreakpoint}
                                                mealSearch={mealSearch}
                                                ActionComp={ActionComp}
                                                basePath={basePath}
                                                setCloseModal={setCloseModal}
                                                linksEnabled={linksEnabled}
                                                getClickCardProps={getClickCardProps}
                                                isMain={isMain}
                                            />
                                        </form>
                                    )
                                }}
                            />
                        )}
                    />
                )
            }}
        >
        </InfiniteScrollForm>
    )
}

const MealSearchContents = ({ activeTab, mealSearch, user, searchAction, ActionComp, RecipeActionComp, basePath, linksEnabled, getClickCardProps, isMain, myRecipesEmptyMsg, bottomTip, addRecipeImport, noDbTab }) => {

    const effectiveTab = noDbTab ? activeTab+1 : activeTab;
    let contents;

    if(effectiveTab === 1) {
        contents = (
            <DatabaseTab 
                user={user}
                mealSearch={mealSearch}
                searchAction={searchAction}
                ActionComp={ActionComp}
                basePath={basePath}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
                isMain={isMain}
            />
        )
    } else if(effectiveTab === 2) {
        contents = (
            <MyRecipesTab 
                user={user}
                mealSearch={mealSearch}
                ActionComp={RecipeActionComp}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
                isMain={isMain}
                myRecipesEmptyMsg={myRecipesEmptyMsg}
                bottomTip={bottomTip}
            />
        )
    } else if(effectiveTab === 4) {
        contents = (
            <TeamRecipesTab 
                user={user}
                mealSearch={mealSearch}
                ActionComp={RecipeActionComp}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
                addRecipeImport={addRecipeImport}
            />
        )
    } else {
        contents = (
            <RecentsTab 
                user={user}
                mealSearch={mealSearch}
                ActionComp={RecipeActionComp}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
            />
        )
    }

    return (
        <div className="mb100">
            {contents}
            <LikeRecipeForClientsPopup />
        </div>
    )
}

const SearchPage = (props) => {
    const { t } = useTranslation();
    const [linksEnabled,setLinksEnabled] = useState(true);
    const { 
        scrollRef, 
        setupTransitions, 
        user, 
        title, 
        mealSearch, 
        redirect, 
        searchAlreadyLoaded, 
        initAction, 
        searchAction, 
        transitionMap, 
        setActiveTab, 
        ActionComp,
        RecipeActionComp, 
        basePath,
        activeTab,
        getClickCardProps,
        myRecipesEmptyMsg,
        bottomTip,
        addRecipeImport,
        isMain, 
        noDbTab
     } = props;
     const extraProps = isMain ? { activeTab, isMain } : {};

     const core = redirect ? (<Redirect to={redirect} />) : (<Loader 
        load={initAction}
        preloaded={searchAlreadyLoaded}
        type='page'
        mealSearch={mealSearch}
        searchAction={searchAction}
        user={user}
        ActionComp={ActionComp && React.cloneElement(ActionComp,{ user, mealSearch, linksEnabled, setLinksEnabled })}
        RecipeActionComp={RecipeActionComp && React.cloneElement(RecipeActionComp,{ user, mealSearch, linksEnabled, setLinksEnabled })}
        basePath={basePath}
        linksEnabled={linksEnabled}
        getClickCardProps={getClickCardProps}
        successComponent={MealSearchContents}
        myRecipesEmptyMsg={myRecipesEmptyMsg}
        bottomTip={bottomTip}
        noDbTab={noDbTab}
        addRecipeImport={addRecipeImport}
        {...extraProps}
    />)

    if(isMain) {
        return core;
    }

    let tabLabels = [
        (<React.Fragment><FontAwesomeIcon icon={["far","heart"]} /> {t('My Recipes')}</React.Fragment>),
        (<React.Fragment><FontAwesomeIcon icon={["far","history"]} /> {t('Recent')}</React.Fragment>)
    ]

    if(!noDbTab) {
        tabLabels.unshift((<React.Fragment><FontAwesomeIcon icon={["far","list-alt"]} /> {t('Database')}</React.Fragment>))
    }

    return (
        <SimpleNavPageWithTabs
            scrollRef={scrollRef} 
            transitionMap={transitionMap || defTransitionMap} 
            setupTransitions={setupTransitions} 
            title={title}
            tabLabels={tabLabels}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            navChildren={<ExitMealPlanButton />}
        >
            {core}
        </SimpleNavPageWithTabs>
    )
}

export default SearchPage;