import React from 'react';
import * as _ from 'lib/utilities';
import MCarousel from 'assets/js/carousel';
import classnames from 'classnames';

export class Carousel extends React.PureComponent {

    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        const { initialActive, items } = this.props;
        this.state = { activeItem: (_.isBlank(initialActive) ? items[0].id : initialActive) };
    }

    componentDidMount() {
        this.carousel = MCarousel.init(this.carouselRef.current, {...this.props.options, onCycleTo: this.onCycleTo });
        const { activeItem } = this.state;
        const { items } = this.props;
        this.carousel.set(_.findIndex(items, item => item.id === activeItem));
    }

    render() {
        const { items, options: { fullWidth } } = this.props;

        return (
            <div className={classnames("carousel", { 'carousel-slider': fullWidth })} ref={this.carouselRef}>
                {items.map(item => (
                    <div key={item.id} className="carousel-item" data-carousel-id={item.id}>{!_.isBlank(item.src) && (<img src={item.src} alt="" />)}{item.children}</div>
                ))}
            </div>
        )
    }

    componentWillUnmount() {
        this.carousel.destroy();
    }

    onCycleTo = (el) => {
        const { onCycleTo } = this.props;
        onCycleTo && onCycleTo(Number(el.dataset.carouselId))
    }

}

export class ControlledCarousel extends React.PureComponent {

    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.state = { initialItem: this.props.activeItem };
        this.curItem = React.createRef();
    }

    componentDidMount() {
        this.carousel = MCarousel.init(this.carouselRef.current, {...this.props.options });
        this.curItem.current = this.orderedItems()[0].id;
        this.carousel.options.onCycleTo = this.onCycleTo;
    }

    componentDidUpdate(prevProps,prevState) {
        if(this.curItem.current !== this.props.activeItem) {
            this.setCarouselItem();
        }
    }

    render() {
        const { options: { fullWidth }, className, setActiveItem } = this.props;

        return (
            <div className={classnames("carousel", { 'carousel-slider': fullWidth, [className]: className })} ref={this.carouselRef}>
                {this.orderedItems().map(item => (
                    <div 
                        key={item.id} 
                        className="carousel-item" 
                        data-carousel-id={item.id}
                        onClick={() => setActiveItem(item.id)}
                    >
                            <img src={item.src} alt="" />{item.children}
                    </div>
                ))}
            </div>
        )
    }

    componentWillUnmount() {
        this.carousel.destroy();
    }

    orderedItems = () => {
        const { initialItem } = this.state;
        const { items } = this.props;
        const initialIndex = _.findIndex(items,i => (i.id === initialItem));

        if(initialIndex === -1) {
            return [ ...items ];
        }

        return [ ...items.slice(initialIndex), ...items.slice(0,initialIndex) ];
    }

    onCycleTo = (el) => {
        const { setActiveItem } = this.props;
        const newItemId = Number(el.dataset.carouselId);
        if(this.curItem.current !== newItemId) {
            console.log(`cycling to ${newItemId}`);
            this.curItem.current = newItemId;
            setActiveItem(Number(el.dataset.carouselId));
        }
    }

    setCarouselItem = () => {
        const { activeItem } = this.props;
        this.curItem.current = activeItem;
        console.log(`setting carousel item to ${activeItem}`);
        this.carousel.set(_.findIndex(this.orderedItems(), item => item.id === activeItem));
    }

}

export const FixedTopCarousel = ({ initialActive, items, onCycleTo, children }) => {

    return (
        <div className="image-center-crop large pinned">
            <Carousel 
                items={items} 
                initialActive={initialActive} 
                onCycleTo={onCycleTo}
                key={_.sortBy(items,item => item.id).join('|')}
                options={{ fullWidth: true, indicators: true }} 
            />
            {children}
        </div>
    )
}

export default Carousel;