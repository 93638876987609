import React from 'react';
import { useTranslation } from 'react-i18next';
import MacroPieChart from './MacroPieChart';
import MacroBarChart from './MacroBarChart';
import classnames from 'classnames';
import { coreMacros } from 'lib/classes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollableFormModal } from 'components/Modal';
import { mealPlanNutritionModalPathFor, nutritionOverridesPath, regenerateWeekPathFor } from 'config/paths';
import Button from 'components/Button';
import { InitialTip } from './Utilities';
import Tooltipped from 'components/Tooltipped';
import { MACRO_ERR_ONLY_AFTER, MACRO_ERROR } from 'config/tooltips';

export const MacroErrorTip = ({ onClick, warnings }) => {
    const { t } = useTranslation();
    const msg = `${warnings ? warnings : ''} ${t("missed macro tip")}`
    return (
        <div>
            <div className="text-left mb10">{msg}</div>
            {onClick && (
                <Button color="primary" className ="semitransparent" rounded outlined onClick={onClick}>
                    <FontAwesomeIcon icon="random" />
                    {t('Regenerate')}
                </Button>
            )}
        </div>
    )
}

const MacroInfoBlockCore = ({ macro, macroHash, t }) => {
    const val = macroHash[macro];

    return (
        <div className={`${macro}-text`}>
            <div className={classnames("macros-text",{"v2": macro !== 'calories'})}>
                {val}{macro !== 'calories' && t('g')}
            </div>
            <div>{macro === 'carbs' ? t('net carbs') : t(macro)}</div>
        </div>
    )
}

const NoGoalMacroInfoBlock = ({ macro, macroHash }) => {
    const { t } = useTranslation();

    return (
        <div className="inline-block pl5 pr5">
            <MacroInfoBlockCore macro={macro} macroHash={macroHash} t={t} />
        </div>
    )
}

const GoalTag = ({ text, hasError }) => {

    return (<div className={classnames("goal-text", { error: hasError })}>
        {text}
        {!hasError && <FontAwesomeIcon icon="check" className="ml5 success-color"/>}
        {hasError && <FontAwesomeIcon icon="exclamation-circle" className="ml5"/>}
    </div>)
}

const GenericGoalBlock = ({ missedTarget, t }) => {
    return (
        <GoalTag text={missedTarget ? t('Goals missed') : t('Goals hit')} hasError={missedTarget} />
    )
}

const GoalBlock = ({ targets, macro, t }) => {
    const target = targets[macro];
    const { targetSym, missedTarget } = target || { targetSym: '', missedTarget: false };

    if(target) {
        return (
            <GoalTag text={`${t("Goal")}: ${`${targetSym}${target.displayAmount ? target.displayAmount : Math.round(target.amount)}${macro !== 'calories' ? t('g') : ''}`}`} hasError={missedTarget} />
        )
    }

    return (
        <div className="goal-text">
            {t('Flexible')}
        </div>
    )
}

const TippedMacroBlock = ({ missedTarget, showTip, macroError, children, warnings }) => {
    const { t } = useTranslation();
    const className = "inline-block pl5 pr5";
    let TopComp = 'div';
    let topProps = { className }
    if(missedTarget) {
        TopComp = Tooltipped;
        topProps = { ...topProps, 
            key: warnings,
            component: 'div',
            options: { classes: ['square','has-actions'], html: macroError, exitDelay: 2500 } 
        }
    }
    const content = (
        <TopComp {...topProps}>
            {children}
        </TopComp>
    )

    if(showTip) {
        return (
            <InitialTip 
                tipName={MACRO_ERROR} 
                text={t("Tap/hover for more info about nutrition errors")} 
                className="inline-block"
                delay={3000}
                onlyAfter={showTip}
            >
                {content}
            </InitialTip>
        )
    }

    return content;
}

const MacroInfoBlock = ({ macro, macroHash, targets, showTip, macroError, warnings, t }) => {
    const target = targets[macro];
    const { missedTarget } = target || { missedTarget: false };
   
    return (
        <TippedMacroBlock missedTarget={missedTarget} showTip={showTip} macroError={macroError} warnings={warnings}>
            <MacroInfoBlockCore macro={macro} macroHash={macroHash} t={t} />
            <GoalBlock macro={macro} targets={targets} t={t} />
        </TippedMacroBlock>
    )
}

const GeneralMacroInfoBlock = ({ macro, macroHash, warnings, showTip, macroError, t }) => {
   
    return (
        <TippedMacroBlock missedTarget={!!warnings} showTip={showTip} macroError={macroError} warnings={warnings}>
            <MacroInfoBlockCore macro={macro} macroHash={macroHash} t={t} />
            <GenericGoalBlock missedTarget={!!warnings} t={t} />
        </TippedMacroBlock>
    )
}

export const NutritionInfoWeeklyChart = ({ macroHash, targets, hasWarnings, ignoreWarnings, macroError, isModal, isSingle, warnings }) => {
    const { t } = useTranslation();
    let errorTipShown = !targets || targets.calories.missedTarget;
    const onlyAfter = isModal ? [] : MACRO_ERR_ONLY_AFTER

    return (
        <div id="nutrition-weekly-chart">
            <div className="valign-wrapper mb15">
                <div className="small-thin-header flex-grow">{isSingle ? '' : t('Avg Daily Macros')}</div>
                {hasWarnings && ignoreWarnings && (
                <Button
                    id="mark-nutrition-errors-btn"
                    variant="flat"
                    outlined
                    color="grey"
                    className="underline no-upcase"
                    onClick={ignoreWarnings}
                >
                    {t('Mark error(s) read')}
                </Button>)}
            </div>
            <div className="text-center">
                {targets && (<MacroInfoBlock 
                    macroHash={macroHash} 
                    macro="calories" 
                    targets={targets} 
                    showTip={errorTipShown ? onlyAfter : null} 
                    macroError={macroError} 
                    warnings={warnings}
                    t={t}
                />)}
                {!targets && (<GeneralMacroInfoBlock 
                    macroHash={macroHash} 
                    macro="calories" 
                    warnings={warnings} 
                    showTip={onlyAfter} 
                    macroError={macroError}
                    t={t} 
                />)}
            </div>
            <div className="mt10 mb10">
                <MacroPieChart 
                    macros={macroHash} 
                    titleFontSize={12} 
                    hideLegend 
                    hideTitle 
                    maxWidth={120} 
                />
            </div>
            <div className="text-center">
                {coreMacros.map(macro => {
                    if(targets) {
                        const showTip = !errorTipShown && targets[macro] && targets[macro].missedTarget;
                        errorTipShown = errorTipShown || showTip;
                        return (
                            <MacroInfoBlock 
                                macroHash={macroHash} 
                                macro={macro} 
                                targets={targets} 
                                key={macro} 
                                showTip={showTip ? onlyAfter : null} 
                                macroError={macroError} 
                                t={t}
                            />
                        )
                    }

                    return (
                        <NoGoalMacroInfoBlock 
                            key={macro}
                            macro={macro}
                            macroHash={macroHash}
                        />
                    )
                })}
            </div>
        </div>
    )
}

export const NutritionInfoDailyChart = ({ macroHashes }) => {
    const { t } = useTranslation();

    return (
        <div id="nutrition-daily-chart">
            <div className="small-thin-header mb15">{t('Daily Chart')}</div>
            <div className="mt10 mb10">
                <MacroBarChart macroHashes={macroHashes} hideLegend hideTitle />
            </div>
        </div>
    )
}

export const NutritionInfoModal = ({ basePath, mealInfoMatrix, ignoreWarnings, linkToRegenForErrors, isTrainerEditing, miniProfile }) => {
    const { t } = useTranslation();
    const showWarnings = mealInfoMatrix.shouldShowMacroWarnings(isTrainerEditing,miniProfile);

    return (
        <ScrollableFormModal
            fullWidth 
            noOverflow 
            limitWidth 
            path={mealPlanNutritionModalPathFor(basePath)} 
            exact 
            title={t('Nutrition')}
            icon="chart-pie"
            render={({ close, closeAndGo, renderHeader, renderFooter, renderScrollable }) => {
                const macroWarnings = showWarnings ? mealInfoMatrix.macroWarningFor(t) : null;
                const macroErrorProps = linkToRegenForErrors ? { onClick: () => closeAndGo(regenerateWeekPathFor(mealInfoMatrix.week)), warnings: macroWarnings } : { warnings: macroWarnings };
                const macroError = <MacroErrorTip {...macroErrorProps} />

                return (
                    <React.Fragment>
                        {renderHeader()}
                        {renderScrollable({ children: (
                            <div className="pa10">
                                <div className="mb20">
                                    <NutritionInfoWeeklyChart 
                                        macroHash={miniProfile ? mealInfoMatrix.avgMacroHashForProfile(miniProfile) : mealInfoMatrix.avgMacroHash()} 
                                        targets={mealInfoMatrix.weeklyGramMacroTargets(miniProfile)} 
                                        hasWarnings={showWarnings && mealInfoMatrix.hasWarningsWithTrainer(isTrainerEditing)}
                                        isSingle={mealInfoMatrix.isSingle()}
                                        ignoreWarnings={ignoreWarnings && ignoreWarnings.bind(null, mealInfoMatrix.week)}
                                        warnings={macroWarnings}
                                        macroError={macroError}
                                        isModal={true}
                                    />
                                </div>
                                {!mealInfoMatrix.isSingle() && (<div className="mb10">
                                    <NutritionInfoDailyChart macroHashes={mealInfoMatrix.macroHashesForDailyChart(miniProfile)} />
                                </div>)}
                            </div>
                        )})}
                        {renderFooter({ children: (
                            <React.Fragment>
                                {mealInfoMatrix.user.canEditOwnMacros() && (<Button rounded color="primary" outlined className="mr5" onClick={() => closeAndGo(nutritionOverridesPath)} >
                                    <FontAwesomeIcon icon={["far","edit"]} />
                                    {t('Edit Goals')}
                                </Button>)}
                                <Button rounded color="primary" onClick={close} id="close-nutrition-modal-btn" >
                                    <FontAwesomeIcon icon="check" />
                                    {t('Ok')}
                                </Button> 
                            </React.Fragment>
                        )})}
                    </React.Fragment>
                )
            }} 
        />
    )
}