import { delay, decamelize, isBlank } from 'lib/utilities';
import camelcaseKeys from 'camelcase-keys';
import moment from 'moment';
import { dateFormat } from 'config/settings';
import * as _ from 'lib/utilities';
import * as download from 'downloadjs';

const apiVersion = 230;
const apiUrl = window.reactApiUrl;
const apiPath = '/api/v1';
const allowedLocales = ['en','de','fr','es']
let apiBase = apiUrl + apiPath;
let apiLocaleChangeHandlers = [];

export const getApiBase = () => apiBase;
export const subscribeToLocaleChange = (fn) => apiLocaleChangeHandlers.push(fn);

const getPdfFilename = function(response,defName='meal_plan.pdf') {
    const disp = response.headers.get("Content-Disposition");
    let fname = 'meal_plan.pdf';
    if(_.isBlank(disp) || disp.indexOf('attachment') === -1) {
        return fname;
    }
    const regResult = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/.exec(disp);
    if(regResult && regResult[1]) {
        fname = regResult[1].replace(/['"]/g, "");
    }

    return fname;
}

function parseNestedAttributes(obj,attrMap) {
    if(!attrMap) {
        return obj;
    } else {
        let newObj;
        if(Array.isArray(obj)) {
            newObj = [];
            for(let i=0; i < obj.length; i++) {
                newObj.push(parseNestedAttributes(obj[i],attrMap));
            }
        } else {
            newObj = { ...obj };
            for(let attr of Object.keys(attrMap)) {
                if(newObj[attr]) {
                    newObj[attr + 'Attributes'] = parseNestedAttributes(newObj[attr],attrMap[attr]);
                    delete newObj[attr];
                }
            }
        }
        return newObj;
    }
}

function objectToFormData(obj, form, namespace) {    
    var fd = form || new FormData();
    var formKey;
    var index = 0;
    
    for(var property in obj) {
      if(obj.hasOwnProperty(property)) {
        index += 1;
        let isObj = (typeof obj[property] === 'object' && !(obj[property] instanceof File || obj[property] instanceof Blob));
        
        if(namespace) {
            if(Array.isArray(obj)) {
                if(isObj) {
                    formKey = namespace + '[' + index + ']';
                } else {
                    formKey = namespace + '[]';
                }
            } else {
                formKey = namespace + '[' + decamelize(property) + ']';
            }
        } else {
          formKey = decamelize(property);
        }
       
        // if the property is an object, but not a File,
        // use recursivity.
        if(isObj) {
          
          objectToFormData(obj[property], fd, formKey);
          
        } else {
          
          // if it's a string or a File object
          fd.append(formKey, obj[property]);
        }
        
      }
    }
    
    return fd;
}

function urlencodeFormData(fd){
    var s = '';
    function encode(s){ return encodeURIComponent(s).replace(/%20/g,'+'); }
    for(var pair of fd.entries()){
        if(typeof pair[1]=='string'){
            s += (s?'&':'') + encode(pair[0])+'='+encode(pair[1]);
        }
    }
    return s;
}

function getQueryString(params) {
    return Object
    .keys(params)
    .map(k => {
        const finalK = decamelize(k)
        if (Array.isArray(params[k])) {
            return params[k]
                .map(val => `${encodeURIComponent(finalK)}[]=${encodeURIComponent(val)}`)
                .join('&')
        }

        return `${encodeURIComponent(finalK)}=${encodeURIComponent(params[k])}`
    })
    .join('&')
}

const responseHandler = (response) => {
    if(response.ok) {
        const contType = response.headers.get("content-type");
        const isPdf = !_.isBlank(contType) && contType.includes('pdf');
        const isCsv = !_.isBlank(contType) && contType.includes('csv');
        if(isPdf || isCsv) {
            const fname = getPdfFilename(response, isPdf ? 'meal_plan.pdf' : 'productivity.csv');
            return response.blob().then((blob) => {
                download(blob,fname,`application/${isPdf ? 'pdf' : 'csv'}`);
                return Promise.resolve({});
            })
        } else {
            return response.json().then((json) => (Promise.resolve(camelcaseKeys(json,{deep: true}))));
        }
    } else if(response.status === 503) {
        return Promise.reject({message: 'MAINTENANCE'});
    } else {
        return Promise.reject({message: 'SERVERERR'});
    }
}

const networkHandlerCreator = (retries,retry) => (typeErr) => {
    if(retry && retries > 0) {
        return delay(1000,retries-1).then(retry);
    } else {
        return Promise.reject({message: 'NETERR'});
    }
}

const nativeFetchWrapper = (url,params) => {
    //probably should turn this back on if we decide to switch to localhost hosting (because of WKWebView third-party cookie bug)
    if(false && window.cordova && window.cordova.plugin.http) {
        const { body, ...newParams } = params || {};
        return new Promise((resolve,reject) => {
            if(body) {
                newParams.data = urlencodeFormData(body);
                if(newParams.method.match(/post|put|patch|delete/i)) {
                    newParams.headers = {'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'}
                }
            }
            
            window.cordova.plugin.http.sendRequest(url,newParams,(response) => {
                response.ok = true;
                response.json = () => (Promise.resolve(JSON.parse(response.data)));
                resolve(response);
            }, (response) => {
                if(response.status && response.status !== 0 && response.status !== '' && response.status !== -1) {
                    response.ok = false;
                    resolve(response);
                } else {
                    reject(response);
                }
            })
            
        })
    } else {
        const {data, ...rest } = params || {};
        const noNetwork = window.localStorage.getItem('__SIMULATE_NO_NETWORK');
        if(noNetwork && process.env.REACT_APP_TEST === 'true') {
            return Promise.reject({ message: 'error'});
        } else {
            return fetch(url,rest);
        }
    }
}

const fetchWrapper = (method,url,params,init=true,retries=0) => {
    if(method === 'GET' && params && params.queryParams) {
        url += (url.indexOf('?') === -1 ? '?' : '&') + getQueryString(params.queryParams);
        delete params.queryParams;
    }
    const retry = fetchWrapper.bind(null,method,url,params,false);
    params = { method: method, credentials: 'include', ...params };
    url = url.match(/^http/) ? url : apiBase + url;
    const promise = nativeFetchWrapper(url, params).catch(networkHandlerCreator(retries,retry));
    if(init) {
        return promise.then(responseHandler);
    } else {
        return promise;
    }
}

let lastUserUpdates = {};
let trainerRefreshAt = Number(window.localStorage.getItem('trainerRefreshAt'));

let activeFetch = null;
let fetchQueue = [];

const handleFetchQueue = (response,breakingKey) => {
    if(response.lastBreakingUpdate || response.lastBreakingUpdate === 0) {
        let newKey = breakingKey;
        if(!newKey) {
            const { user: { id: userId }={} } = response; 
            if(userId) {
                newKey = `lastUserUpdate${userId}`;
            }
        }
        if(newKey) {
            lastUserUpdates[newKey] = response.lastBreakingUpdate;
            delete response.lastBreakingUpdate;
            window.localStorage.setItem(newKey, lastUserUpdates[newKey]);
        }
    }
    if(response.trainerRefreshAt || response.trainerRefreshAt === 0) {
        trainerRefreshAt = response.trainerRefreshAt;
        window.localStorage.setItem('trainerRefreshAt', trainerRefreshAt);
    }
    let next = fetchQueue.shift();
    if (next) {
        next();
    } else {
        activeFetch = null;
        if(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_TEST === 'true') {
            document.body.classList.remove(['active-api-request'])
        }
    }
}

const intermediateFetch = (method,url,params,init=true,retries=0,userId,breakingKey,skipBreakCheck) => {
    let lastUserUpdate = null;
    if(breakingKey) {
        lastUserUpdate = lastUserUpdates[breakingKey] = _.isBlank(lastUserUpdates[breakingKey]) ? Number(window.localStorage.getItem(breakingKey)) : lastUserUpdates[breakingKey]; 
    }
    params = params || {};
    if (method === 'GET' || method === 'HEAD') {
        let queryParams = params.queryParams || {};
        queryParams.lastUserUpdate = lastUserUpdate;
        queryParams.apiVersion = apiVersion;
        if(userId) {
            queryParams.trainerClientId = userId;
        }
        if(skipBreakCheck) {
            queryParams.skipBreakCheck = true;
        }
        const focusDate = sessionStorage.getItem('currentFocusDate');
        if(!_.isBlank(focusDate)) {
            queryParams.focusDate = focusDate;
        }
        params.queryParams = queryParams;
    } else {
        let data = params.data || {};
        data.lastUserUpdate = lastUserUpdate;
        data.apiVersion = apiVersion;
        if(userId) {
            data.trainerClientId = userId;
        }
        if(skipBreakCheck) {
            data.skipBreakCheck = true;
        }
        const focusDate = sessionStorage.getItem('currentFocusDate');
        if(!_.isBlank(focusDate)) {
            data.focusDate = focusDate;
        }
        params.body = objectToFormData(data);
    }
    activeFetch = fetchWrapper(method,url,params,init,retries)
    .then((response) => {
        handleFetchQueue(response,breakingKey);
        return Promise.resolve(response);
    }).catch((error) => {
        handleFetchQueue({},breakingKey);
        return Promise.reject(error);
    });
    if(process.env.NODE_ENV !== 'production' || process.env.REACT_APP_TEST === 'true') {
        document.body.classList.add(['active-api-request'])
    }
    return activeFetch;
}

const fullFetch = (method,url,params,init=true,retries=0,userId,skipBreakCheck) => {
    const breakingKey = userId ? `lastUserUpdate${userId}` : null;
    if(activeFetch) {
        let boundFetch = intermediateFetch.bind(this,method,url,params,init,retries,userId,breakingKey,skipBreakCheck);
        return new Promise((resolve,reject) => {
            fetchQueue.push(() => {
                resolve(boundFetch());
            })
        });
    } else {
        return intermediateFetch(method,url,params,init,retries,userId,breakingKey,skipBreakCheck);
    }
}

const api = {
    get: (url,params,retries=0) => (userId,skipBreakCheck) =>  fullFetch('GET',url,params,true,retries,userId,skipBreakCheck),
    post: (url,params,retries=0) => (userId,skipBreakCheck) => fullFetch('POST',url,params,true,retries,userId,skipBreakCheck),
    patch: (url,params) => (userId,skipBreakCheck) => fullFetch('PATCH',url,params,true,0,userId,skipBreakCheck),
    put: (url,params) => (userId,skipBreakCheck) => fullFetch('PUT',url,params,true,0,userId,skipBreakCheck),
    delete: (url,params) => (userId,skipBreakCheck) => fullFetch('DELETE',url,params,true,0,userId,skipBreakCheck)
}

export function setLocale(locale) {
    locale = locale ? locale.split('-')[0] : locale;
    if(allowedLocales.includes(locale)) {
        apiBase = apiUrl + '/' + locale + apiPath;
    } else {
        apiBase = apiUrl + apiPath;
    }
    for(let fn of apiLocaleChangeHandlers) {
        fn(apiBase);
    }
}

export function loadUser({ curDate=moment() } = {}) {
    return api.get('/user.json',{queryParams: {curDate: curDate.format(dateFormat)}});
}

export function loginUser({ data }) {
    const { isSwitcher, ...rest } = data;
    return api.post('/user/login', { data: { isSwitcher, user: rest } });
}

export function emailSignup({ data }) {
    return api.post('/user/email_signup', { data: { user: data } });
}

export function verifyOauth({ data }) {
    const { type, ...rest } = data;
    return api.post('/user/verify_' + type + '_login',{ data: { ...rest } });
}

export function loadRecentUserMeals(page) {
    return api.get('/user_meals/recents',{ queryParams: { page } });
}

export function touchUser() {
    return api.post('/user/touch');
}

export function registerDevice(data) {
    fetchWrapper('POST',"/register_device", { body: objectToFormData(data) }).catch(() => {});
}

export function setAsaAttribution(token) {
    fetchWrapper('POST',"/set_asa_attribution", { body: objectToFormData({ token }) }).catch(() => {});
}

export function registerMixpanelDeviceId(mixpanelDeviceId) {
    fetchWrapper('POST',"/register_mixpanel_id", { body: objectToFormData({ mixpanelDeviceId }) }).catch(() => {});
}

export function registerTimezone() {
    const params = { timezone_offset: (new Date()).getTimezoneOffset() };
    try {
        params.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (error) {
        console.log(error);
    }
    
    return fetchWrapper('POST',"/register_timezone", { body: objectToFormData(params) });
}

export function logOffPlan(parentId,data) {
    return api.post('/user_meals/log_off_plan.json', { data: { userMeal: data, userMealId: parentId } });
}

export function foodDBSearchResults(query,page) {
    return api.get('/foods/user_search',{ queryParams: { page, query } });
}

export function populateFatsecret(fatsecretId) {
    return api.post('/foods/populate_fatsecret.json', { data: { fatsecretId } });
}

export function logFood(parentId,data) {
    return api.post('/user_meals/log_food.json', { data: { ...data, userMealId: parentId } });
}

export function offPlanRecipeSearch(queryParams,page) {
    return api.post('/recipe_searches/off_plan_search', { data: { recipeSearch: queryParams, page } });
}

export function logRecipe(parentId,data) {
    return api.post('/user_meals/log_recipe.json', { data: { ...data, userMealId: parentId } });
}

export function loadRecipes(ids) {
    return api.get('/recipes/full_load.json', { queryParams: { ids } });
}

export function myRecipesLoad() {
    return api.get('/recipes/my_recipes_load.json');
}

export function loadDislikedRecipes() {
    return api.get('/recipes/load_disliked_recipes.json');
}

export function teamRecipesLoad(trainerId) {
    return api.get('/recipes/team_recipes_load.json', { queryParams: { trainerId } });
}

export function loadAllRecipeVersions(ids) {
    return api.get('/recipes/load_all_versions.json', { queryParams: { ids } });
}

export function loadCategorizeRecipes(ids) {
    return api.get('/recipes/categorize_load.json', { queryParams: { ids } });
}

export function dismissTooltip(tipName) {
    return api.post('/user/destroy_tooltip', { data: {tooltip: tipName}})();
}

export function createAndLogOffPlan(parentId,data) {
    let { date, servingsEaten, ...food } = data;
    food = parseNestedAttributes(food,{foodWeights: {}});
    return api.post('/foods/create_and_log', { data: { food, servingsEaten, date, userMealId: parentId }});
}

export function loadBannableProgs() {
    return api.get('/exercise_progressions/bannable');
}

export function possibleWorkoutTimes(data) {
    return api.post('/user/workout_times', { data: { user: data } });
}

export function possibleRoutines(data) {
    return api.post('/workout_routines/possible', { data: { user: data } });
}

export function unpossibleRoutines(data) {
    return api.get('/workout_routines/unpossible', { queryParams: { ...data } });
}

export function assignNewRoutine(data) {
    return api.post('/workout_routines/assign', { data: { user: data }});
}

export function initMacros(data) {
    return api.post('/user/init_macros', { data: { user: data }});
}

export function initialRecipeOptions(data) {
    if(data) {
        const user = parseNestedAttributes(data,{dailyNutritionProfiles: { nutritionParameters: {} }});
        return api.post('/user/initial_recipe_options', { data: { user: user }});
    } else {
        return api.post('/user/initial_recipe_options');
    }
}

export function getRecipeOptionsCatPage(data) {
    return api.post('/user/recipe_options_category', { data });
}

export function startMealPlan(data) {
    return api.post('/user/start_meal_plan', { data });
}

export function initStripeSub(data) {
    return api.post('/subscriptions/init_stripe', { data });
}

export function initStripeCheckout(data) {
    return api.post('/subscriptions/init_checkout', { data });
}

export function updateStripeCard(data) {
    return api.post('/subscriptions/update_stripe_card', { data });
}

export function updateTStripeCard(data) {
    return api.post('/trainer_subscriptions/update_stripe_card', { data });
}

export function verifyIAP(product) {
    return api.post('/subscriptions/verify', { data: product })();
}

export function registerOriginalSource(data) {
    return api.post('/user/register_original_source', { data })();
}

export function unlogMeal(userMealId) {
    return api.post(`/user_meals/${userMealId}/unlog`);
}

export function getFoodFromBarcode(data) {
    return api.post('/foods/get_from_barcode',{ data });
}

export function likeRecipe({recipeId, type, clientId}) {
    return api.post(`/recipes/${recipeId}/like`, { data: { type }});
}

export function multilikeRecipe({recipeId, ...data }) {
    return api.post(`/recipes/${recipeId}/multilike`, { data });
}

export function dislikeRecipe({recipeId, type}) {
    return api.post(`/recipes/${recipeId}/dislike`, { data: { type }});
}

export function deactivateRecipe({recipeId, type}) {
    return api.post(`/recipes/${recipeId}/deactivate`, { data: { type }});
}

export function logMeal({ id, ...data }) {
    return api.post(`/user_meals/${id}/log`,{ data: { userMeal: parseNestedAttributes(data,{ recipeMeals: {}}) } });
}

export function skipMeal({ id, type }) {
    return api.post(`/user_meals/${id}/skip`, { data: { type }});
}

export function logDailyWeight(data) {
    return api.post('/user/log_daily_weight', { data });
}

export function logClientWeight(data) {
    return api.post('/trainers/log_client_weight', { data });
}

export function cleanupRoutine(date,loadWarmups) {
    return api.post('/workout_routines/cleanup', {data: { date: date.format(dateFormat), loadWarmups }});
}

export function loadWorkout(id,loadWarmups) {
    return api.get(`/workouts/${id}/load`, { queryParams: { loadWarmups } });
}

export function logWorkout({ id, workout }) {
    workout = parseNestedAttributes(workout, { exerciseSpecifications: { exerciseSets: {}}});
    return api.post('/workouts/log', { data: { id, workout } })
}

export function muteTimers(mute) {
    return api.post('/workouts/mute_timers',{ data: { mute }})();
}

export function loadStrengthTest(params) {
    return api.get('/strength_tests/load', { queryParams: { ...params } });
}

export function submitStrengthTest(params) {
    return api.post('/strength_tests/submit', { data: { strengthTest: params } } );
}

export function updateExerciseSettings({ date, exerciseId, ...settings }) {
    return api.patch(`/exercise_settings/${exerciseId}`, { data: { exerciseSetting: settings, date: date }});
}

export function loadExerciseProgression(id) {
    return api.get(`/exercise_progressions/${id}/load`);
}

export function loadExercise({ id }) {
    return api.get(`/exercises/${id}/load`);
}

export function submitProgressionTest({ exerciseProgressionId, date, ...params }) {
    return api.post('/progression_tests/submit', { data: { exerciseProgressionId, date, progressionTest: params } } );
}

export function restartWorkout(date) {
    return api.post('/workouts/reset', { data: { date }});
}

export function executeExerciseSearch(data,page) {
    const { contextId, contextType, ...rest } = data;
    let allData = { exerciseSearch: rest, page };
    if(!isBlank(contextId)) {
        allData.contextId = contextId;
        allData.contextType = contextType;
    }
    return api.post('/exercise_searches/execute', { data: allData});
}

export function swapExerciseSpec(data) {
    const { id, ...rest } = data;
    return api.post(`/exercise_specifications/${id}/swap_exercise`,{ data: rest });
}

export function lastExerciseMax({ id, reps }) {
    return api.get(`/exercises/${id}/last_max`, { queryParams: { reps }});
}

export function workoutsLoggedThisWeek({ date }) {
    return api.get(`/workouts/logged_this_week`, { queryParams: { date }});
}

export function submitContactForm(data) {
    return api.post('/contact',{ data });
}

export function ratingResponse(data) {
    return api.post('/user/rating_response', { data },3)();
}

export function deload(type) {
    return api.post('/workout_routines/deload',{ data: { type }});
}

export function undeload() {
    return api.post('/workout_routines/undeload');
}

export function loadWorkoutRoutine(id,loadWarmups,loadExerciseGroups) {
    return api.get(`/workout_routines/${id}/load`, { queryParams: { loadWarmups, loadExerciseGroups }});
}

export function updateWorkoutRoutine(data) {
    return api.patch(`/workout_routines/${data.id}`, { data: { workoutRoutine: data }});
}

export function destroyWorkoutRoutine(id) {
    return api.post(`/workout_routines/${id}/deactivate`);
}

export function restoreRoutineDefaults() {
    return api.post('/workout_routines/restore_defaults');
}

export function restartRoutine({ date }) {
    return api.post('/workout_routines/restart_routine', { data: { date }});
}

export function updateRoutineCycle(data) {
    const { workoutRoutineId, id, ...params } = data;
    return api.patch(`/workout_routines/${workoutRoutineId}/routine_cycles/${id}`, { data: { routineCycle: params } });
}

export function createRoutineCycle({ workoutRoutineId, subType }) {
    return api.post(`/workout_routines/${workoutRoutineId}/routine_cycles`, { data: { subType } });
}

export function destroyRoutineCycle({ workoutRoutineId, id }) {
    return api.delete(`/workout_routines/${workoutRoutineId}/routine_cycles/${id}`);
}

export function destroyWrtd(workoutRoutineDay) {
    return api.delete(`/routine_cycles/${workoutRoutineDay.routineCycleId}/workout_routine_days/${workoutRoutineDay.id}`);
}

export function createWrtd({ routineCycleId, ...params }) {
    return api.post(`/routine_cycles/${routineCycleId}/workout_routine_days`, { data: params });
}

export function updateCycleDayOrder({ id, workoutRoutineId, ...params }) {
    return api.post(`/workout_routines/${workoutRoutineId}/routine_cycles/${id}/update_day_order`, { data: { routineCycle: parseNestedAttributes(params,{ workoutRoutineDays: {} }) } });
}

export function updateWorkoutTemplate({ id, workoutRoutineId, ...params }) {
    return api.patch(`/workout_routines/${workoutRoutineId}/workout_templates/${id}`,{ data: { workoutTemplate: params }});
}

export function updateWtChildOrder({ id, workoutRoutineId, ...params }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${id}/update_child_order`, { data: { workoutTemplate: parseNestedAttributes(params,{ children: {} }) } });
}

export function updateExerciseTemplate({ workoutRoutineId, workoutTemplateId, id, ...params }) {
    return api.patch(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${id}`, { data: { exerciseTemplate: parseNestedAttributes(params, { exerciseProgressionRequirements: {} }) }});
}

export function updateSetTemplate({ workoutRoutineId, workoutTemplateId, exerciseTemplateId, id, ...params }) {
    return api.patch(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/update_set_template`, { data: { setTemplateId: id, setTemplate: params }});
}

export function loadProgressionRequirements({ workoutRoutineId, workoutTemplateId, exerciseTemplateId }) {
    return api.get(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/load_progression_requirements`);
}

export function loadAutoregTemplates({ workoutRoutineId, workoutTemplateId, exerciseTemplateId }) {
    return api.get(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/load_autoreg_templates`);
}

export function addSetTemplate({ workoutRoutineId, workoutTemplateId, exerciseTemplateId }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/add_set_template`);
}

export function swapExerciseTemplate({ workoutRoutineId, workoutTemplateId, exerciseTemplateId, substituteId, substituteType }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/swap_exercise`,{ data: { substituteId, substituteType }});
}

export function destroySetTemplate({ workoutRoutineId, workoutTemplateId, exerciseTemplateId, id }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/destroy_set_template`,{ data: { setTemplateId: id }});
}

export function loadStReferences({ workoutRoutineId, workoutTemplateId, exerciseTemplateId, id }) {
    return api.get(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates/${exerciseTemplateId}/load_possible_references`,{ queryParams: { setTemplateId: id }});
}

export function addWtChildGroup({ workoutRoutineId, workoutTemplateId, childId }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/add_child_group`, { data: { childId }});
}

export function addExerciseTemplate({ workoutRoutineId, workoutTemplateId, exercisableType, exercisableId }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/exercise_templates`, { data: { exercisableId, exercisableType }});
}

export function destroyWtc({ workoutRoutineId, workoutTemplateId, childId }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/destroy_child`, { data: { childId }});
}

export function destroyWorkoutTemplate({ workoutRoutineId, workoutTemplateId }) {
    return api.delete(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}`);
}

export function createExerciseGroup({ workoutRoutineId, workoutTemplateId }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/create_exercise_group`);
}

export function createExercise({ workoutRoutineId, workoutTemplateId, owned, ...params }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/create_exercise`, { data: { exercise: params }});
}

export function createCustomExercise(data) {
    return api.post(`/exercises/create_custom`, { data: { exercise: data, owned: true }});
}

export function updateExercise({ id, owned, ...params }) {
    return api.patch(`/exercises/${id}`,{ data: { exercise: params }})
}

export function destroyCustomExercise(id) {
    return api.delete(`/exercises/${id}/destroy_custom`)
}

export function updateSettings(data) {
    return api.patch('/user',{ data: { user: data }})
}

export function updateMealPlanSettings({ isFinish, ...data }) {
    data = parseNestedAttributes({ ...data }, {dailyNutritionProfiles: { nutritionParameters: {} }})
    data = isFinish ? { finishSetup: true, user: data } : { user: data }
    return api.patch('/user/update_meal_plan',{ data })
}

export function logoutUser() {
    return api.delete('/user/logout')
}

export function updateProgressCharts(queryParams) {
    return api.get(`/user/progress_chart_data`, { queryParams });
}

export function updateMacroAnalytics(lookbackDays) {
    return api.get(`/user/macro_analytics`, { queryParams: { lookbackDays } });
}

export function loadBodyMeasurements({ userId }) {
    const queryParams = isBlank(userId) ? {} : { userId };
    return api.get(`/body_measurements/load`, { queryParams })
}

export function createBodyMeasurements(data) {
    return api.post(`/body_measurements`,{ data: { user: data }})
}

export function loadSubscription() {
    return api.get('/subscriptions/load');
}

export function destroyUser() {
    return api.post('/user/delete');
}

export function cancelSubscription() {
    return api.post('/subscriptions/cancel_stripe');
}

export function uncancelSubscription() {
    return api.post('/subscriptions/uncancel_stripe');
}

export function cancelTSubscription(call) {
    return api.post('/trainer_subscriptions/cancel_stripe', { data: { call } });
}

export function uncancelTSubscription() {
    return api.post('/trainer_subscriptions/uncancel_stripe');
}

export function addBrandedApp() {
    return api.post('/trainer_subscriptions/add_branded_app');
}

export function loadBrandedAppSetupInfo() {
    return api.get('/branded_apps/load_setup_info');
}

export function createBrandedApp(data) {
    return api.post('/branded_apps', { data: { brandedApp: data }});
}

export function markNotificationsRead(ids) {
    return api.post('/notifications/mark_read', { data: { ids }});
}

export function logError(data) {
    return api.post('/error_logs',{ data: { errorLog: data } })();
}

export function loadErrorLogs({ firstId,page }) {
    const queryParams = !_.isBlank(firstId) ? { firstId, page } : { page };
    return api.get('/error_logs',{ queryParams });
}

export function destroyErrorLog(id) {
    return api.delete(`/error_logs/${id}`);
}

export function clearErrorLogs() {
    return api.post('/error_logs/clear');
}

export function loadErrorLog(id) {
    return api.get(`/error_logs/${id}`);
}

export function markWeekClean(startDate) {
    return api.post('/user/mark_week_clean',{ data: { startDate }});
}

export function ignoreWarnings(week) {
    return api.post('/user/ignore_warnings',{ data: { week }})
}

export function regenerateMealPlan(data) {
    return api.post('/user/regenerate_meal_plan', { data });
}

export function copyMealPlanDay(data) {
    return api.post('/user/copy_meal_plan_day', { data });
}

export function setOffPlanMeals(data) {
    return api.post('/user/set_off_plan_meals',{ data })
}

export function createMiniProfile(data) {
    return api.post('/mini_profiles', { data: { miniProfile: data } })
}

export function updateMiniProfile({ id, ...data }) {
    const opts = { data: { miniProfile: data } };
    if(isBlank(id)) {
        return api.post('/mini_profiles', opts);
    } else {
        return api.patch(`/mini_profiles/${id}`, opts);
    }
}

export function destroyMiniProfile(id) {
    return api.delete(`/mini_profiles/${id}`)
}

export function confirmSharedMeals(data) {
    return api.post('/user_meals/share_meals',{ data: { userMealMap: data }})
}

export function getLockedDays(userMealId) {
    return api.get(`/user_meals/${userMealId}/locked_days`)
}

export function pickMealDays({ mealTypeId, ...data }) {
    return api.post(`/meal_types/${mealTypeId}/pin_meal`,{ data })
}

export function startSwapMeals({ mealTypeId, ...data }) {
    return api.post(`/meal_types/${mealTypeId}/start_replace_meals`, { data });
}

export function startBrowseMeals() {
    return api.post(`/meal_searches/start_browse`);
}

export function loadMealSearchCategories() {
    return api.get(`/meal_search_categories/load_all`);
}

export function updateMealSearch(data,page) {
    const mealSearch = parseNestedAttributes(data,{macroFields: {}});
    return api.post(`/meal_searches/updates`, { data: { page, mealSearch } });
}

export function startBrowseRecipes() {
    return api.post(`/recipe_searches/start_browse`);
}
export function updateRecipeSearch(data,page) {
    const recipeSearch = parseNestedAttributes(data,{macroFields: {}});
    return api.post(`/recipe_searches/updates`, { data: { page, recipeSearch } });
}

export function replaceMeals({ contextId, recipeId, ...meal }) {
    let data;
    if(!isBlank(recipeId)) {
        data = { recipeId };
    } else {
        data = { meal: parseNestedAttributes(meal,{sideDishes: {}}) };
    }
    return api.post(`/meal_types/${contextId}/replace_meals`, { data })
}

export function loadRecentRecipes(page) {
    return api.get(`/recipes/recents`, { queryParams: { page } });
}

export function getRandomMeal({ mealTypeId, ...data }) {
    return api.get(`/meal_types/${mealTypeId}/random_meal`, { queryParams: data });
}

export function startReplaceTempRecipe({ mealTypeId, meal, ...data }) {
    meal = parseNestedAttributes(meal,{ sideDishes: {} });
    return api.post(`/meal_types/${mealTypeId}/start_replace_dish`, { data: { meal, ...data } })
}

export function startReplaceRecipe({ userMealId, ...data }) {
    return api.post(`/user_meals/${userMealId}/start_replace_dish`, { data })
}

export function replaceRecipe({ userMealId, ...data }) {
    return api.post(`/user_meals/${userMealId}/replace_dish`, { data })
}

export function randomizeSides({ userMealId, ...data }) {
    return api.post(`/user_meals/${userMealId}/randomize_sides`, { data })
}

export function setRecipeMealServings({ userMealId, ...data }) {
    return api.post(`/user_meals/${userMealId}/set_servings`, { data })
}

export function loadWimageCats() {
    return api.get(`/workout_image_categories`)
}

export function destroyWimageCat(id) {
    return api.delete(`/workout_image_categories/${id}`)
}

export function updateWimageCat({ id, ...data }) {
    return api.patch(`/workout_image_categories/${id}`, { data: { workoutImageCategory: data }})
}

export function createWimageCat(data) {
    return api.post(`/workout_image_categories`, { data: { workoutImageCategory: data }})
}

export function loadGroceryList(data) {
    return api.get('/user/load_grocery_list', { queryParams: data })
}

export function createPantryItem(data) {
    return api.post('/pantry_items/create_or_add',{ data })
}

export function destroyPantryItem(data) {
    return api.post('/pantry_items/remove_or_destroy',{ data })
}

export function regenSingleDay() {
    return api.post('/user_meals/randomize_day')
}

export function regenUserMeal(userMealId,excludedIds) {
    return api.post(`/user_meals/${userMealId}/randomize`, { data: { excludedIds }})
}

export function createFoodWeight({id, ...foodWeight }) {
    return api.post(`/foods/${id}/create_food_weight`, { data: { foodWeight }});
}

export function loadIngredientFoods(foodWeightIds) {
    return api.get(`/foods/load_ingredient_foods`, { queryParams: { foodWeightIds }})
}

export function loadMyFoods() {
    return api.get('/foods/load_my_foods');
}

export function destroyFood(foodId) {
    return api.delete(`/foods/${foodId}`)
}

export function updateFood({ id, ...rest }) {
    const food = parseNestedAttributes(rest,{foodWeights: {}});
    if(id) {
        return api.patch(`/foods/${id}`, { data: { food }});
    }
    return api.post(`/foods`, { data: { food }});
}

export function createRecipe({ showMasterEditWarning, saveToFavorites, teamShared, ...data }) {
    return api.post('/recipes', { data: { saveToFavorites, teamShared, recipe: parseNestedAttributes(data,{ingredients: {}}) }});
}

export function copyRecipe(recipeId) {
    return api.post(`/recipes/${recipeId}/copy`);
}

export function updateRecipe({ showMasterEditWarning, saveToFavorites, teamShared, id, ...data }) {
    return api.patch(`/recipes/${id}`, { data: { recipe: parseNestedAttributes(data,{ingredients: {}}) }});
}

export function createRecipeOverride({ forTrainer, showMasterEditWarning, saveToFavorites, teamShared, ...data }) {
    return api.post('/recipes/create_override', { data: { forTrainer, recipe: parseNestedAttributes(data,{ingredients: {}}) }});
}

export function restoreRecipeDefaults(id) {
    return api.post(`/recipes/${id}/restore_defaults`);
}

export function createUserLead({ id, ...userLead }) {
    const data = id ? { id, userLead } : { userLead }
    return api.post(`/user_leads`, { data })
}

export function restoreSubscription() {
    return api.post('/subscriptions/restore');
}

export function switchClient(userId,focusDate) {
    let queryParams = { userId };
    if(focusDate) {
        queryParams.date = focusDate;
    }
    return api.get('/user/switch_to_user', { queryParams });
}

export function copyClientMealPlan(startDate) {
    return api.post('/user/copy_meal_plan', { data: { startDate } });
}

export function createChildTrainer({ type, ...data }) {
    return api.post('/trainers', { data: { type, trainer: { ...data }}});
}

export function checkTrainerRefresh() {
    return api.get('/trainers/check_refresh', { queryParams: { trainerRefreshAt }});
}

export function createWorkoutRoutine(params, loadWarmups, needToLoadGroups) {
    return api.post(`/workout_routines`, { data: { workoutRoutine: params, loadWarmups, loadExerciseGroups: needToLoadGroups }});
}

export function loadExerciseGroups() {
    return api.get('/workout_templates/load_exercise_groups');
}

export function importExerciseGroups({ workoutRoutineId, workoutTemplateId, templateIds }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/import_exercise_groups`, { data: { templateIds }});
}

export function markExerciseGroupsOwned({ workoutRoutineId, workoutTemplateId, templateIds }) {
    return api.post(`/workout_routines/${workoutRoutineId}/workout_templates/${workoutTemplateId}/mark_groups_owned`, { data: { templateIds }});
}

export function loadWorkoutTemplates() {
    return api.get('/workout_templates/load_workout_templates');
}

export function importWorkoutTemplates({ workoutRoutineId, routineCycleId, templateIds }) {
    return api.post(`/workout_routines/${workoutRoutineId}/routine_cycles/${routineCycleId}/import_workouts`, { data: { templateIds }});
}

export function assignClientRoutines({ clientIds, ...data }) {
    return api.post('/workout_routines/assign_clients', { data: { clientIds, user: data }});
}

export function syncClientRoutines(data) {
    return api.post('/workout_routines/sync_client_routines', { data });
}

export function loadClient(id) {
    return api.get('/trainers/load_client',{ queryParams: { clientId: id }});
}

export function loadUnsyncedClients() {
    return api.get('/trainers/load_unsynced_clients');
}

export function loadTrainerRoutines(params) {
    return api.get('/trainers/load_routines', { queryParams: params });
}

export function importRoutine(id) {
    return _.isBlank(id) ? api.post(`/workout_routines/import_sample`) : api.post(`/workout_routines/${id}/import`);
}

export function loadClientsForDash(params) {
    return api.get('/trainers/load_clients_for_dash', { queryParams: params });
}

export function loadAllTrainers(filters) {
    return api.get('/trainers/load_all_trainers', { queryParams: filters });
}

export function tagClients(data) {
    return api.post('/trainers/tag_clients', { data });
}

export function updateClients({ clientIds, ...data }) {
    return api.post('/trainers/update_clients', { data: { clientIds, user: data } });
}

export function updateClient({ clientId, ...data }) {
    return api.post('/trainers/update_client', { data: { clientId, user: data } });
}

export function updateClientProfiles({ clientIds, passToDefaults, ...data }) {
    const params = { clientIds, user: data };
    if(passToDefaults) params.passToDefaults = passToDefaults;
    return api.post('/trainers/update_client_profiles', { data: params });
}

export function assignTrainer(data) {
    return api.post('/trainers/assign_trainer', { data });
}

export function createClient({ trainerId, type, ...data }) {
    return api.post('/trainers/create_client', { data: { trainerId, type, user: data } });
}

export function sendInvite({ id }) {
    return api.post('/trainers/send_invite', { data: { inviteeId: id } });
}

export function updateClientMealPlan({ clientId, ...data }) {
    data = parseNestedAttributes({ ...data }, {dailyNutritionProfiles: { nutritionParameters: {} }})
    return api.post('/trainers/update_client_meal_plan', { data: { clientId, user: data }})
}

export function createTrainer({ trainer, token, planType, frequency }) {
    const data = _.omitBy({ trainer, token, planType, frequency },val => val === undefined)
    return api.post('/trainers/make_trainer', { data });
}

export function initStripeTrainerSub(data) {
    return api.post('/trainer_subscriptions/init_stripe', { data });
}

export function updateTrainerSub(data) {
    return api.post('/trainer_subscriptions/update_plan', { data });
}

export function checkForBreakingUpdate() {
    return api.get('/user/check_for_breaking_update');
}

export function loadRequiredTrainingDays(id) {
    return api.get(`/workout_routines/${id}/required_training_days`);
}

export function createTrainerNote({ clientId, ...data }) {
    return api.post(`/clients/${clientId}/trainer_notes`, { data: { trainerNote: data} });
}

export function updateTrainerNote({ clientId, id, ...data }) {
    return api.patch(`/clients/${clientId}/trainer_notes/${id}`, { data: { trainerNote: data } });
}

export function deleteTrainerNote({ clientId, id }) {
    return api.delete(`/clients/${clientId}/trainer_notes/${id}`);
}

export function loadClientStats(params) {
    return api.get(`/trainers/load_client_stats`, { queryParams: params });
}

export function loadLogs(date) {
    return api.get(`/user/load_logs`, { queryParams: { date } });
}

export function deactivateTrainers(data) {
    return api.post('/trainers/deactivate_trainers',{ data })
}

export function reactivateTrainers(data) {
    return api.post('/trainers/reactivate_trainers',{ data })
}

export function updateTrainer({ id, defaultImage, isOwner, ...data }) {
    return api.patch(`/trainers/${id}`, { data: { trainer: data } })
}

export function createHabit(data) {
    return api.post('/habits', { data: { habit: data } });
}

export function updateHabit({ id, ...data }) {
    return api.patch(`/habits/${id}`, { data: { habit: data} });
}

export function deleteHabit(id) {
    return api.delete(`/habits/${id}`);
}

export function loadHabits(params) {
    return api.get('/habits/load', { queryParams: params });
}

export function importSampleHabit() {
    return api.post('/habits/import_sample');
}

export function importHabit(id) {
    return _.isBlank(id) ? api.post('/habits/import_sample') : api.post(`/habits/${id}/import`);
}

export function loadSchedulableSettings(data) {
    return api.get('/schedulable_settings/load_settings',{ queryParams: data })
}

export function assignSchedulables(data) {
    return api.post('/schedulable_settings/assign_schedulables', { data });
}

export function logHabit({ id, ...data }) {
    return api.patch(`/habit_logs/${id}`, { data: { habitLog: data }});
}

export function loadBmHistory({ lookback }) {
    return api.get('/body_measurements/load_history', { queryParams: { lookback }});
}

export function sendPlanPdfEmail(data) {
    return api.post('/trainers/send_pdf', { data });
}

export function downloadPlanPdf(data) {
    return api.get('/trainers/generate_pdf.pdf', { queryParams: data })
}

export function downloadGroceryList(data) {
    return api.get('/user/download_glist.pdf', { queryParams: data })
}

export function sendRoutinePdfEmail({ id, ...data}) {
    return api.post(`/workout_routines/${id}/send_pdf`, { data });
}

export function downloadRoutinePdf({ id, ...data}) {
    return api.get(`/workout_routines/${id}/generate_pdf.pdf`, { queryParams: data })
}

export function downloadTrainerProductivity({ lookback }) {
    return api.get('/trainers/download_trainer_productivity.csv', { queryParams: { lookback } })
}

export function jumpToBrowser(path) {
    return api.post('/jump_to_browser', { data: { afterPath: path } });
}

export function getAppLinks() {
    return api.get('/get_app_links');
}

export function uploadTrainerLogo(data) {
    return api.post('/trainers/upload_logo', { data });
}

export function updateMpInfoStub({ startDate, userId, mode, mpNameScheme, ...rest }) {
    return api.post('/mp_info_stubs/update_client_stub', { data: { startDate, userId, mode, mpNameScheme, mpInfoStub: rest } } );
}

export function switchTrainerType(data) {
    return api.post('/trainers/switch_trainer_type', { data });
}

export function updateTBasicProfile(data) {
    return api.post('/trainers/update_basic_profile', { data: { user: data } });
}

export function getCustomDomain() {
    return api.get('/trainers/get_custom_domain');
}

export function setCustomDomain(data) {
    return api.post('/trainers/set_custom_domain', { data });
}

export function getJwt() {
    return api.get('/chats/get_jwt');
}

export function sendChatMessage({ chatId, image, ...message }) {
    if(image) {
        message.image = _.dataURItoBlob(image);
    }
    return api.post('/chat_messages', { data: { chatId, message } });
}

export function forceSendChatMessage({ clientId, ...message }) {
    return api.post('/chat_messages/force_send', { data: { clientId, message } });
}

export function loadChats(queryParams) {
    return api.get(`/chats/load_chats`,{ queryParams });
}

export function createChat({ clientIds, ...chat }) {
    return api.post('/chats', { data: { chat, clientIds } } );
}

export function updateChat({ chatId, clientIds, name }) {
    return api.patch(`/chats/${chatId}`, { data: { name, clientIds } } );
}

export function loadChatMessages({ chatId, ...queryParams }) {
    return api.get(`/chats/${chatId}/load_messages`,{ queryParams })
}

export function markMessagesRead(data) {
    return api.post('/chat_messages/mark_read',{ data });
}

export function toggleArchiveChat(chatId) {
    return api.post(`/chats/${chatId}/archive`);
}

export function muteChat(chatId) {
    return api.post(`/chats/${chatId}/mute`);
}

export function activateMessaging(data) {
    return api.post(`/chats/activate`, { data: { user: data } });
}

export function getChatCount() {
    return api.get('/chats/get_chat_count');
}

export function loadApiKey() {
    return api.get('/trainers/load_api_key')
}

export function billableClientCount() {
    return api.get('/trainers/billable_client_count')
}

export function checkExportLimits({ clientId }) {
    return api.get('/trainers/check_export_limits', { queryParams: { clientId } })
}

export function tSubscribeImmediately() {
    return api.post('/trainer_subscriptions/subscribe_immediately')
}

export function getInvoices() {
    return api.get('/trainer_subscriptions/invoices')
}

export function loadDnps() {
    return api.get('/daily_nutrition_profiles/load_all')
}

export function createDnp({ saveToTemplate, userId, ...rest}) {
    rest = parseNestedAttributes({ ...rest }, { nutritionParameters: {} })
    return api.post(`/daily_nutrition_profiles`, { data: { userId, saveToTemplate, dailyNutritionProfile: rest } });
}

export function updateDnp({ id, userId, ...rest }) {
    rest = parseNestedAttributes({ ...rest }, { nutritionParameters: {} })
    return api.patch(`/daily_nutrition_profiles/${id}`, { data: { userId, dailyNutritionProfile: rest }})
}

export function destroyDnp(id) {
    return api.delete(`/daily_nutrition_profiles/${id}`)
}

export function startMuxUpload({ id, type }) {
    return api.post(`/mux_uploads/${id}/start_mux_upload`, { data: { type }});
}

export function cancelMuxUpload({ id, type }) {
    return api.post(`/mux_uploads/${id}/cancel_mux_upload`, { data: { type }});
}

export function finishMuxUpload({ id, type }) {
    return api.post(`/mux_uploads/${id}/finish_mux_upload`, { data: { type }});
}

export function failMuxUpload({ id, type }) {
    return api.post(`/mux_uploads/${id}/fail_mux_upload`, { data: { type } });
}

export function restartMuxUpload({ recordId: id, recordClass: type }) {
    return api.post(`/mux_uploads/${id}/restart_mux_upload`, { data: { type }});
}

export function loadMuxRecord({ id, type }) {
    return api.get(`/mux_uploads/${id}/load_mux_record`, { queryParams: { type }});
}

export function loadBrandedApps() {
    return api.get('/branded_apps');
}

export function markBrandedAppInitialized(id) {
    return api.post('/branded_apps/mark_initialized', { data: { id } });
}

export function deactivateBrandedApp(id) {
    return api.post('/branded_apps/deactivate', { data: { id } });
}

export function updateBrandedApp({ id, ...data}) {
    return api.patch(`/branded_apps/${id}`, { data: { brandedApp: data } });
}

export function createActivityLog(data) {
    const activityLog = parseNestedAttributes(data, { exerciseSpecification: { exerciseSets: {} }});
    return api.post(`/activity_logs`, { data: { activityLog }})
}

export function updateActivityLog({ id, ...data }) {
    const activityLog = parseNestedAttributes(data, { exerciseSpecification: { exerciseSets: {} }});
    return api.patch(`/activity_logs/${id}`, { data: { activityLog }})
}

export function destroyActivityLog(id) {
    return api.delete(`/activity_logs/${id}`);
}

export function getOwnedHealthkitData(date) {
    return api.get('/activity_logs/data_for_healthkit', { queryParams: { date } });
}

export function publishMealPlan(data) {
    const { clientId } = data;
    const path = _.isBlank(clientId) ? '/user/publish_meal_plan' : '/trainers/publish_meal_plan'
    return api.post(path,{ data });
}

export function requestMealPlan(data) {
    return api.post('/user/request_meal_plan', { data });
}

export function loadTrainerForms(params) {
    return api.get('/forms/load', { queryParams: { ...params }});
}

export function loadTrainerForm({ id }) {
    return api.get(`/forms/${id}/load_one`);
}

export function createTrainerForm(data) {
    return api.post(`/forms`, { data: { form: data } });
}

export function importTrainerForm({ id, ...data }) {
    return api.post(`/forms/${id}/import`, { data });
}

export function deleteTrainerForm({ id, clientId }) {
    let url = `/forms/${id}`;
    if(!_.isBlank(clientId)) {
        url = `${url}?client_id=${clientId}`;
    }
    return api.delete(url);
}

export function updateTrainerForm({ id,...form}) {
    return api.patch(`/forms/${id}`, { data: { form } })
}

export function fillTrainerForm({ id,...form}) {
    return api.post(`/forms/${id}/fill`, { data: { form: parseNestedAttributes(form,{ formFields: {} }) } });
}

export function reviewTrainerForm({ id, clientId, ...form}) {
    return api.post(`/forms/${id}/review`, { data: { clientId, form: parseNestedAttributes(form,{ formFields: {} }) } });
}

export function updateFormFieldOrder({ id, ...params }) {
    return api.post(`/forms/${id}/update_field_order`, { data: { form: parseNestedAttributes(params,{ formFields: {} }) } });
}

export function deleteFormField({ id, formId }) {
    return api.delete(`/forms/${formId}/form_fields/${id}`);
}

export function createFormField({ formId, ...data }) {
    return api.post(`/forms/${formId}/form_fields`, { data: { formField: data } });
}

export function updateFormField({ formId, id, ...formField }) {
    return api.patch(`/forms/${formId}/form_fields/${id}`, { data: { formField } })
}

export function setDefaultFormScheduling({ formId, ...rest }) {
    return api.post(`/forms/${formId}/set_default_schedule`, { data: { schedulableSetting: rest } });
}

export function removeFormFromDefaults({ formId }) {
    return api.delete(`/forms/${formId}/remove_from_defaults`);
}

export function androidStepsUpdate(data) {
    return api.post('/activity_logs/android_steps_update', { data: { steps: data } });
}

export function healthkitUpdateUrl() {
    return apiBase + '/activity_logs/healthkit_update';
}

export function androidStepsUpdateUrl() {
    return apiBase + '/activity_logs/android_steps_update';
}

export const initCheckoutPathFor = saleId => `${getApiBase()}/subscriptions/init_checkout/${saleId}`;
export const previewGlistUrlFor = (data,user) => `${getApiBase()}/user/download_glist.pdf?${getQueryString({ ...data, trainerClientId: (user && user.id), inline: true })}`;
export const previewMpPdfUrlFor = (params,preview=true) => `${getApiBase()}/trainers/generate_pdf.pdf?${getQueryString({ ...params, preview })}`;
export const previewRoutinePdfUrlFor = ({ id, ...params },preview=true,pdf=true) => `${getApiBase()}/workout_routines/${id}/generate_pdf.${pdf ? 'pdf' : 'html'}?${getQueryString({ ...params, preview })}`;
export const submitTCancelFeedack = feedback => {
    let fd = new FormData();
    const { cancelReasons, otherCancelReason, email, elaboration, marketing } = feedback;

    cancelReasons.forEach(reason => {
        fd.append('entry.1745167778', reason);
    })

    if(!_.isBlank(otherCancelReason)) {
        fd.append('entry.1745167778.other_option_response',otherCancelReason);
    }

    fd.append('entry.1586032724',elaboration);
    fd.append('entry.1728139077',marketing);
    fd.append('entry.2067264204',email);

    fetch('https://docs.google.com/forms/u/0/d/e/1FAIpQLSfoEjOBJFda9fm51sJLLQQrJsYk8dAm9tGPgRbGYd-efcsamg/formResponse',{ method: 'POST', body: fd });
}

export function createTrainerAssessment(assessment) {
    return api.post(`/assessments`, { data: { assessment } });
}

export function loadTrainerAssessments(params) {
    return api.get('/assessments/load', { queryParams: { ...params }});
}

export function loadTrainerAssessment({ id }) {
    return api.get(`/assessments/${id}/load_one`);
}

export function importTrainerAssessment({ id, ...data }) {
    return api.post(`/assessments/${id}/import`, { data });
}

export function deleteTrainerAssessment(id) {
    return api.delete(`/assessments/${id}`);
}

export function updateTrainerAssessment({ id,...assessment}) {
    return api.patch(`/assessments/${id}`, { data: { assessment } })
}

export function initFormFieldDataEntry({ formId, id }) {
    return api.post(`/forms/${formId}/form_fields/${id}/init_data_entry`);
}

export function loadFormFieldHistory({ formId, id }) {
    return api.get(`/forms/${formId}/form_fields/${id}/load_history`);
}

export function loadProgressPhotos() {
    return api.get('/user/load_progress_photos');
}

export function createProgressPhoto(data) {
    return api.post('/progress_photos', { data: { progressPhoto: data }});
}

export function destroyProgressPhoto({ id }) {
    return api.delete(`/progress_photos/${id}`);
}

export function setCalorieSchedule({ highDays, ...user }) {
    return api.post('/user/set_calorie_schedule',{ data: { highDays, user }});
}

export function initMiniProfiles(data) {
    return api.post('/mini_profiles/init',{ data });
}