import { useState } from 'react';
import * as _ from 'lib/utilities';

export const DestroyableCollection = ({ collection, destroy, render, idAttr='id' }) => {
    const [pendingDestructions,setPendingDestructions] = useState([]);
    const allowDestroy = (collection.length - pendingDestructions.length) > 1;

    const newDestroy = (record) => {
        setPendingDestructions(_.uniq([...pendingDestructions, record[idAttr]]));
        const respHandler = () => setPendingDestructions(_.filter(pendingDestructions,id => id !== record[idAttr]));
        return destroy(record).then(res => {
            respHandler();
            return Promise.resolve(res);
        }).catch(err => {
            respHandler();
            return Promise.reject(err);
        })
    }

    return render({ destroy: newDestroy, allowDestroy, pendingDestructions });
}