import React from 'react';

export default class MediaQuery extends React.Component {

    constructor(props) {
        super(props);
        const { query } = this.props;
        this.mediaQuery = window.matchMedia(query);
        this.state = { queryMatches: this.mediaQuery.matches };
        this.mediaQuery.addListener(this.screenChangeListener);
    }

    render() {
        const { render, query, ...rest } = this.props;
        const { queryMatches } = this.state;

        return render({ ...rest, queryMatches });
    }

    screenChangeListener = e => this.setState({ queryMatches: e.matches });

    componentWillUnmount() {
        this.mediaQuery.removeListener(this.screenChangeListener);
    }
}