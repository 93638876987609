import React from 'react';
import FormikWrapper from 'components/FormikWrapper';
import * as _ from 'lib/utilities';

export class DependencyForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { record: props.record };
    }

    render() {
        const { render, t, context, record: persisted, ...rest } = this.props;
        const { record } = this.state;
        if(context) {
            Object.assign(record,context);
        }

        return (
            <FormikWrapper 
                initialValues={ record.formValues() }
                validate={(values) => record.update(values,false,t)}
                {...rest}
                render={({ handleChange: oldHandleChange, setValues, setFieldValue, values, ...formikProps }) => {
                    const handleChange = (e) => {
                        const val = _.isNumeric(e.target.value) ? Number(e.target.value) : e.target.value;
                        const changes = record.updateForForm(e.target.name,val);
                        if(Object.keys(changes).length > 1) {
                            setValues({ ...values, ...changes });
                        } else {
                            setFieldValue(e.target.name,val);
                        }
                    }

                    return render({ ...formikProps, setValues, setFieldValue, values, handleChange, record });
                }}
            />
        )
    }
}