import React, { useState } from 'react';
import RouteModal, { ModalHeader, ModalFooter, ScrollableFormModal, FullFormModal } from 'components/Modal';
import { routineSettingsPathFor, restoreRoutineDefaultsPathFor, restartRoutinePathFor, copyWorkoutModalMatchFor, editWorkoutTemplatePathFor, editRoutinePath, cycleSettingsModalMatchFor, workoutSetupPickPath, pickNewRoutineModalPathFor, workoutSetupPath, importExerciseGroupsPathFor, importWorkoutTemplatesMatchFor, editRoutineStandlonePathFor, modalPathFor } from 'config/paths';
import loadingContainer, { LoadingContainerParent, PDLoading } from 'components/LoadingHOC';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconOptionCard } from 'components/Card';
import { IconNote, SlightEmphasisIconNote } from 'components/Typography';
import { updateWorkoutRoutine, restoreRoutineDefaults, restartRoutine, createWrtd, updateRoutineCycle, loadExerciseGroups, importExerciseGroups, loadWorkoutTemplates, importWorkoutTemplates, createWorkoutRoutine } from 'redux/actions';
import { connect } from 'react-redux';
import FormikWrapper from 'components/FormikWrapper';
import * as _ from 'lib/utilities';
import { userRecordSelector } from 'redux/selectors';
import * as Yup from 'yup';
import { ModalForm, ModalSubmitLC, InnerFormContainer, CollapsibleFormSection, InputWithErrors, formikInputProps, Checkbox, ModalSelectListing } from 'components/Form';
import TextInput, { NumberInput, TextArea, SelectInput } from 'components/TextInput';
import { WorkoutRoutine } from 'lib/classes';
import { dateFormat } from 'config/settings';
import moment from 'moment';
import { Redirect } from "components/Routing";
import { useLocation } from 'react-router-dom';

const SettingsContent = ({ submit, workoutRoutine, user, renderFooter, renderScrollable, renderHeader, closeAndGo }) => {
    const { t } = useTranslation();
    const isNew = _.isBlank(workoutRoutine.id);

    return (
        <React.Fragment>
            {renderHeader()}
            <FormikWrapper
                initialValues={ workoutRoutine.formValues(user.isAdmin()) }
                submit={submit}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required()
                })}
                successCallback={isNew ? ({ status,formikBag,data }) => {
                        const id = Object.values(data.workoutRoutines)[0].id;
                        closeAndGo(editRoutineStandlonePathFor(id));
                } : null}
                initialErrors={{}}
                render={({ handleSubmit, submitState, ...rest }) => {
                    const inputProps = _.pick(rest,formikInputProps);

                    return (
                        <ModalForm centered onSubmit={handleSubmit}>
                            {renderScrollable({ children: (
                                <InnerFormContainer className="pa10" style={{width: '100%'}}>
                                    <div className="valign-wrapper">
                                        <InputWithErrors 
                                            label={t("Name")}
                                            className="inline flex-grow"
                                            name="name"
                                            id="nameField"
                                            component={TextInput}
                                            {...inputProps}
                                        />
                                        {user.isAdmin() && (<Checkbox 
                                            inputProps={{ 
                                                name: 'published', 
                                                onChange: rest.handleChange, 
                                                onBlur: rest.handleBlur, 
                                                value: true,
                                                checked: rest.values.published
                                            } } 
                                            label={t('Published?')} 
                                            filled 
                                            className="ml10"
                                        />)}

                                    </div>
                                    <div className="text-left ml5">
                                        <div className="font-grey">{t("Description")}</div>
                                        <TextArea 
                                            placeholder={`${t('Optional')}${user.isTrainer() ? `. ${t('routine desc tip')}` : ''}`}
                                            name="description" 
                                            value={rest.values.description} 
                                            onChange={rest.handleChange} 
                                            onBlur={rest.handleBlur} 
                                            stopModalDrag
                                        />
                                    </div>
                                    {isNew && (
                                        <div className="ml5 mr5 mt15">
                                            <div className="font-grey">
                                                {t('Routine type')}
                                            </div>
                                            <ModalSelectListing 
                                                name='repeating'
                                                single={true}
                                                col={[{ text: t('Repeating'), subtitle: t('repeat routine tip'), value: true }, { text: t('Non-repeating'), subtitle: t('non-repeat routine tip'), value: false }]}
                                                boxClass="valign-wrapper pa10"
                                                {...rest}
                                            />
                                        </div>
                                    )}
                                    {user.isAdmin() && (<div className="text-left ml5">
                                        <div className="font-grey">{t("Source URL")}</div>
                                        <TextArea 
                                            name="sourceUrl" 
                                            value={rest.values.sourceUrl} 
                                            onChange={rest.handleChange} 
                                            onBlur={rest.handleBlur} 
                                            stopModalDrag
                                        />
                                    </div>)}
                                    {!isNew && (<CollapsibleFormSection header={t("Advanced")}>
                                        <div className="valign-wrapper">
                                            <Checkbox 
                                                inputProps={{ 
                                                    name: 'multicycle', 
                                                    onChange: rest.handleChange, 
                                                    onBlur: rest.handleBlur, 
                                                    value: true,
                                                    checked: rest.values.multicycle
                                                } } 
                                                label={t('Multi-cycle?')} 
                                                filled 
                                                className="no-wrap"
                                                tooltip={t('multicycle tip')}
                                            />
                                            <Checkbox 
                                                inputProps={{ 
                                                    name: 'repeating', 
                                                    onChange: rest.handleChange, 
                                                    onBlur: rest.handleBlur, 
                                                    value: true,
                                                    checked: rest.values.repeating
                                                } } 
                                                label={t('Repeating?')} 
                                                filled 
                                                className="ml5 no-wrap"
                                                tooltip={t('repeating tip')}
                                            />
                                        </div>
                                        {user.isAdmin() && (
                                            <React.Fragment>
                                                <div className="valign-wrapper flex-start mt20">
                                                    <div className="flex-grow">
                                                        <div className="font-grey no-wrap">{t('Experience levels')}</div>
                                                        <SelectInput 
                                                            className="auto-height"
                                                            multiple
                                                            name="experienceLevels" 
                                                            collection={WorkoutRoutine.EXPERIENCE_LEVELS.map((text) => ({ text, value: text }))} 
                                                            browserDefault 
                                                            value={rest.values.experienceLevels} 
                                                            onChange={rest.handleArrayChange} 
                                                            onBlur={rest.handleBlur} 
                                                        />
                                                    </div>
                                                    <div className="flex-grow">
                                                        <div className="font-grey no-wrap">{t('Gender')}</div>
                                                        <SelectInput 
                                                            name="genders" 
                                                            collection={WorkoutRoutine.GENDERS.map((text) => ({ text, value: text.toLowerCase() }))} 
                                                            browserDefault 
                                                            value={rest.values.genders} 
                                                            onChange={rest.handleChange} 
                                                            onBlur={rest.handleBlur} 
                                                        />
                                                    </div>
                                                </div>
                                                <div className="valign-wrapper mt20">
                                                    <div>
                                                        <div className="font-grey">{t('Equipment types')}</div>
                                                            <SelectInput 
                                                                name="equipmentType" 
                                                                collection={WorkoutRoutine.equipmentTypesCol(t)} 
                                                                browserDefault 
                                                                value={rest.values.equipmentType} 
                                                                onChange={rest.handleChange} 
                                                                onBlur={rest.handleBlur} 
                                                            />
                                                    </div>
                                                    <div className="ml15">
                                                        <div className="font-grey">{t('Focus')}</div>
                                                            <SelectInput 
                                                                name="focus" 
                                                                collection={[{ text: t('None'), value: ''}, { text: t('Aesthetics'), value: 0}, { text: t('Strength'), value: 1}]} 
                                                                browserDefault 
                                                                value={rest.values.focus} 
                                                                onChange={rest.handleChange} 
                                                                onBlur={rest.handleBlur} 
                                                            />
                                                    </div>
                                                </div>
                                                <div className="valign-wrapper mt20">
                                                    <Checkbox 
                                                        inputProps={{ 
                                                            name: 'includesCardio', 
                                                            onChange: rest.handleChange, 
                                                            onBlur: rest.handleBlur, 
                                                            value: true,
                                                            checked: rest.values.includeCardio
                                                        } } 
                                                        label={t('Includes cardio?')} 
                                                        filled 
                                                    />
                                                    <Checkbox 
                                                        inputProps={{ 
                                                            name: 'addCardio', 
                                                            onChange: rest.handleChange, 
                                                            onBlur: rest.handleBlur, 
                                                            value: true,
                                                            checked: rest.values.addCardio
                                                        } } 
                                                        label={t('Add cardio?')} 
                                                        filled 
                                                        className="ml10"
                                                    />
                                                    <Checkbox 
                                                        inputProps={{ 
                                                            name: 'generator', 
                                                            onChange: rest.handleChange, 
                                                            onBlur: rest.handleBlur, 
                                                            value: true,
                                                            checked: rest.values.generator
                                                        } } 
                                                        label={t('Generator?')} 
                                                        filled 
                                                        className="ml10"
                                                    />
                                                </div>
                                                    
                                            </React.Fragment>
                                        )}
                                    </CollapsibleFormSection>)}
                                </InnerFormContainer>
                            )})}
                            {renderFooter({ loadState: submitState, saveLabel: isNew ? t('Create') : t('Save'), saveIcon: 'check' })}
                        </ModalForm>
                    )
                }}
            />
        </React.Fragment>
    )
}

const mapStateToSettingsProps = state => ({
    user: userRecordSelector(state)
})

const mapDispatchToSettingsProps = dispatch => ({
    submit: (data) => dispatch(updateWorkoutRoutine(data))
})

const mapDispatchToNewRoutineProps = dispatch => ({
    submit: (data) => dispatch(createWorkoutRoutine(data))
})

const ConnectedSettingsContent = connect(mapStateToSettingsProps,mapDispatchToSettingsProps)(SettingsContent);
const ConnectedNewRoutineContent = connect(mapStateToSettingsProps,mapDispatchToNewRoutineProps)(SettingsContent);


export const SettingsModal = ({ basePath, workoutRoutine }) => {
    const { t } = useTranslation();

    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={routineSettingsPathFor(basePath)} 
            exact 
            icon="cog"
            title={t("Routine Settings")}
            render={({ renderFooter, renderScrollable, renderHeader }) => (
                <ConnectedSettingsContent 
                    renderFooter={renderFooter} 
                    renderScrollable={renderScrollable} 
                    renderHeader={renderHeader} 
                    workoutRoutine={workoutRoutine} 
                />
            )} 
        />
    )
}

export const NewRoutineModal = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor('/new',pathname);
    const workoutRoutine = new WorkoutRoutine({ name: '', description: '', multicycle: true, repeating: true })

    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={path} 
            exact 
            icon="plus"
            title={t("New Routine")}
            render={({ renderFooter, renderScrollable, renderHeader, closeAndGo }) => (
                <ConnectedNewRoutineContent 
                    renderFooter={renderFooter} 
                    renderScrollable={renderScrollable} 
                    renderHeader={renderHeader} 
                    closeAndGo={closeAndGo}
                    workoutRoutine={workoutRoutine} 
                />
            )} 
        />
    )
}

const RestoreDefaultsButtons = ({ load, t }) => (
    <React.Fragment>
                <Button rounded outlined color="primary" className="modal-close">{t('Cancel')}</Button>
                <Button rounded outlined color="red" className="ml5" onClick={() => load()}><FontAwesomeIcon icon="check" /> {t('Restore')}</Button>
    </React.Fragment>
)

const RestoreDefaultsLC = loadingContainer({
    "DEFAULT": RestoreDefaultsButtons,
    "SUCCESS": ({ close }) => { close(); return ''; }
})

const RestoreContent = ({ restoreDefaults, close }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b><FontAwesomeIcon icon="cloud-download-alt" /> {t("Restore Defaults")}</b>
            </ModalHeader>
            <div className="pa20 text-center">
                <div className="left-align-block">
                    <IconNote text={t("restore routine tip")} variant="warning" />
                </div>
            </div>
            <ModalFooter defaults>
                <LoadingContainerParent 
                    load={restoreDefaults}
                    preloaded={() => false}
                    skipAutoLoad
                    component={RestoreDefaultsLC}
                    close={close}
                    t={t}
                />
            </ModalFooter>
 
        </React.Fragment>
    )
}

const mapDispatchToRestoreProps = dispatch => ({
    restoreDefaults: () => dispatch(restoreRoutineDefaults())
})

const ConnectedRestoreContent = connect(null,mapDispatchToRestoreProps)(RestoreContent);


export const RestoreDefaultsModal = ({ basePath, workoutRoutine }) => {
    return (
        <RouteModal fullWidth limitWidth path={restoreRoutineDefaultsPathFor(basePath)} exact render={({ close }) => (
            <ConnectedRestoreContent close={close} workoutRoutine={workoutRoutine} />
        )} />
    )
}

const RestartContent = ({ restartRoutine, close }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b><FontAwesomeIcon icon="undo" /> {t("Restart Routine")}</b>
            </ModalHeader>
            <FormikWrapper
                initialValues={ { date: moment().format(dateFormat) } }
                submit={restartRoutine}
                initialErrors={{}}
                render={({ handleSubmit, submitState, handleChange, handleBlur, values, ...rest }) => {
                    return (
                        <ModalForm onSubmit={handleSubmit}>
                            <div className="pa15">
                                <div className="left-align-block">
                                    <div className="font-grey no-wrap">{t('Restart on')}</div>
                                    <SelectInput 
                                        name="date" 
                                        collection={_.nextWeekCollection(dateFormat)} 
                                        browserDefault 
                                        value={values.date} 
                                        onChange={handleChange} 
                                        onBlur={handleBlur} 
                                    />
                                </div>
                            
                                <div className="left-align-block mt10">
                                    <IconNote text={t("restart routine tip")} variant="info" />
                                </div>
                            </div>
                            <ModalFooter defaults noShadow>
                                <ModalSubmitLC close={close} loadState={submitState} saveText={t('Restart')} id="restart-routine-btn" />
                            </ModalFooter>
                        </ModalForm>
                    )
                }}
            />
        </React.Fragment>
    )
}

const mapDispatchToRestartProps = dispatch => ({
    restartRoutine: (data) => dispatch(restartRoutine(data))
})

const ConnectedRestartContent = connect(null,mapDispatchToRestartProps)(RestartContent);


export const RestartRoutineModal = ({ basePath, workoutRoutine }) => {
    return (
        <RouteModal fullWidth limitWidth path={restartRoutinePathFor(basePath)} exact render={({ close }) => (
            <ConnectedRestartContent close={close} workoutRoutine={workoutRoutine} />
        )} />
    )
}

class CopySuccess extends React.Component {

    render() {
        return <PDLoading />;
    }

}

const DefaultCopyContent = ({ setCopyType }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <IconOptionCard 
                className="no-shadow"
                title={t("Repeat")} 
                subtitle={t("Repeat same workout on another day")} 
                icon={'redo'} 
                id='repeat-workout-btn' 
                onClick={() => setCopyType('repeat')} 
            />
            <IconOptionCard 
                className="no-shadow"
                title={t("Copy")} 
                subtitle={t("Copy to another day and edit")} 
                icon={['far','copy']} 
                id='copy-workout-btn' 
                onClick={() => setCopyType('copy')} 
            />
        </React.Fragment>

    )
}

const CopyLC = loadingContainer({
    "SUCCESS": CopySuccess
},  { type: 'padded' });

const CopyContent = ({ copyWorkout, close, closeAndGo, match, workoutRoutine }) => {
    const [copyType,setCopyType] = useState(null);
    const { t } = useTranslation();
    const { params: { routineCycleId, workoutTemplateId } } = match;

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b>{t("What do you want to do?")}</b>
            </ModalHeader>
            <div className="pa10 text-center">
                {!copyType && (
                    <DefaultCopyContent setCopyType={setCopyType} />
                )}
                {copyType && (
                    <LoadingContainerParent 
                        component={CopyLC}
                        preloaded={() => false}
                        load={copyWorkout.bind(null,{ type: copyType, routineCycleId, workoutTemplateId })}
                        successCallback={(data) => {
                            if(copyType === 'copy') {
                                closeAndGo(editWorkoutTemplatePathFor(workoutRoutine.id,data.workoutTemplateId));
                            } else {
                                close();
                            }
                        }}
                    />
                )}
            </div>
            <ModalFooter defaults>
                <Button rounded noShadow outlined color="primary" className="modal-close">
                    <FontAwesomeIcon icon="ban" />
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </React.Fragment>
    )
}

const mapDispatchToCopyProps = dispatch => ({
    copyWorkout: (data) => dispatch(createWrtd(data))
})

const ConnectedCopyWorkoutContent = connect(null,mapDispatchToCopyProps)(CopyContent);


export const CopyWorkoutModal = ({ basePath, workoutRoutine }) => {
    return (
        <RouteModal fullWidth limitWidth path={copyWorkoutModalMatchFor(basePath)} exact render={({ close, closeAndGo, match }) => (
            <ConnectedCopyWorkoutContent workoutRoutine={workoutRoutine} close={close} match={match} closeAndGo={closeAndGo} />
        )} />
    )
}


const CycleSettingsContent = ({ updateRoutineCycle, workoutRoutine, match, close }) => {
    const { t } = useTranslation();
    const { params: { routineCycleId } } = match;
    const cycle = _.find((workoutRoutine || {}).routineCycles, cycle => cycle.id === Number(routineCycleId));

    if(cycle) {
        return (
            <React.Fragment>
                <ModalHeader defaults exitButton>
                    <b><FontAwesomeIcon icon="cog" /> {t("Cycle Settings")}</b>
                </ModalHeader>
                <FormikWrapper
                    initialValues={ cycle.formValues() }
                    submit={updateRoutineCycle}
                    validationSchema={Yup.object().shape({
                        length: Yup.number().integer().min(1).required()
                    })}
                    initialErrors={{}}
                    render={({ handleSubmit, submitState, ...rest }) => {
                        const inputProps = _.pick(rest,formikInputProps);
                        return (
                            <ModalForm centered onSubmit={handleSubmit}>
                                <InnerFormContainer className="pa10" style={{width: '100%'}}>
                                    <div className="valign-wrapper">
                                        <InputWithErrors 
                                            label={t("Name")}
                                            className="inline flex-grow"
                                            name="name"
                                            id="nameField"
                                            component={TextInput}
                                            {...inputProps}
                                        />
                                        {cycle.showCycleWeeks() && (<InputWithErrors 
                                            label={t("Weeks")}
                                            className="inline"
                                            name="length"
                                            id="lengthField"
                                            component={NumberInput}
                                            {...inputProps}
                                        />)}
                                    </div>
                                </InnerFormContainer>
                                <ModalFooter defaults noShadow>
                                    <ModalSubmitLC close={close} loadState={submitState} id="submit-cycle-setting-btn" />
                                </ModalFooter>
                            </ModalForm>
                        )
                    }}
                />
            </React.Fragment>
        )
    } else {
        return <Redirect to={editRoutinePath} />
    }
 
}

const mapDispatchToCycleSettingsProps = dispatch => ({
    updateRoutineCycle: (data) => dispatch(updateRoutineCycle(data))
})

const ConnectedCycleSettingsContent = connect(null,mapDispatchToCycleSettingsProps)(CycleSettingsContent);


export const CycleSettingsModal = ({ basePath, workoutRoutine }) => {
    return (
        <RouteModal fullWidth noOverflow limitWidth path={cycleSettingsModalMatchFor(basePath)} exact render={({ close, match }) => (
            <ConnectedCycleSettingsContent close={close} workoutRoutine={workoutRoutine} match={match} />
        )} />
    )
}

const PickNewRoutineContent = ({ closeAndGo }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <IconOptionCard 
                className="no-shadow"
                title={t("Pick from Recommended Routines")} 
                subtitle={t("Go straight to your list of matching routines and pick a new one")} 
                icon={'arrow-right'} 
                id='pick-workout-btn' 
                onClick={() => closeAndGo(workoutSetupPickPath)} 
            />
            <IconOptionCard 
                className="no-shadow"
                title={t("Update Preferences First")} 
                subtitle={t("Change your workout preferences and pick from an updated list of routines")} 
                icon={'arrow-right'} 
                id='update-workout-prefs-btn' 
                onClick={() => closeAndGo(workoutSetupPath)} 
            />
        </React.Fragment>

    )
}

const PickNewRoutineModalContent = ({ closeAndGo }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b>{t("What do you want to do?")}</b>
            </ModalHeader>
            <div className="pa10 text-center">
                <PickNewRoutineContent closeAndGo={closeAndGo} />
            </div>
            <ModalFooter defaults>
                <Button rounded noShadow outlined color="primary" className="modal-close">
                    <FontAwesomeIcon icon="ban" />
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </React.Fragment>
    )
}

export const PickNewRoutineModal = ({ basePath }) => {
    return (
        <RouteModal fullWidth limitWidth path={pickNewRoutineModalPathFor(basePath)} exact render={({ closeAndGo }) => (
            <PickNewRoutineModalContent closeAndGo={closeAndGo} />
        )} />
    )
}

let ImportExerciseGroupsModal = ({ loadExerciseGroups, submit, baseMatch, workoutTemplate, type }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal 
            fullWidth 
            path={importExerciseGroupsPathFor(baseMatch)} 
            scrollable 
            exact 
            loadForm={loadExerciseGroups}
            icon={['far','file-import']}
            title={t("Import Exercise Groups")}
            footerProps={{ saveIcon: type === 'mark' ? ['far','file-import'] : ['far','copy'], saveLabel: type === 'mark' ? t('Import & Add') : t('Copy & Add') }}
            formProps={ {
                initialValues: { templateIds: [''] },
                submit: values => submit({ ...workoutTemplate.actionParams(), type, ...values }),
                validationSchema: Yup.object().shape({
                    templateIds: Yup.array().compact().required().min(1)
                }),
                initialErrors: { templateIds: 'empty' }
            }}
            render={({ modalProps, loaderProps: { responseData }, ...formikProps }) => {
                if(!responseData.workoutTemplates) {
                    return '';
                }

                const workoutTemplates = type === 'mark' ? _.filter(responseData.workoutTemplates,wt => _.isBlank(wt.ownerId)) : responseData.workoutTemplates;

                const col = workoutTemplates.map(wt => ({ text: wt.name, value: wt.id, subtitle: _.isBlank(wt.ownerId) ? wt.routineName : '' }))

                return (
                    <div className="pa10" id={`${type}-groups-modal`}>
                        {col.length > 0 && (<ModalSelectListing 
                            col={col}
                            name={'templateIds'}
                            {...formikProps}
                        />)}
                        {col.length === 0 && <SlightEmphasisIconNote text={type === 'mark' ? t("no importable exercise groups trainer") : t("no importable exercise groups")} />}
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToImportEgProps = dispatch => ({
    loadExerciseGroups: () => dispatch(loadExerciseGroups()),
    submit: data => dispatch(importExerciseGroups(data))
});

const ImportWorkoutsContent = ({ workoutRoutine, workoutTemplates, formikProps }) => {
    const { t } = useTranslation();
    const groupedTemplates = _.groupBy(workoutTemplates,wt => wt.workoutRoutineId);
    const { [workoutRoutine.id]: thisRoutine, ...rest } = groupedTemplates;
    const orderedRoutines = _.compact([ thisRoutine, ..._.sortBy(Object.values(rest),arr => arr[0].routineName) ]);

    return (
        <React.Fragment>
            {orderedRoutines.map(wtArr => {
                const isThis = wtArr[0].workoutRoutineId === workoutRoutine.id;
                const col = _.sortBy(wtArr, wt => `${wt.cycleType}-${wt.cycleOrder}-${wt.dayOrder}`).map(wt => ({ text: _.fallbackText(wt.name, 'Untitled'), value: wt.id, subtitle: _.fallbackText(wt.cycleName,t('Untitled cycle')) }))
                return (
                    <CollapsibleFormSection 
                        key={wtArr[0].workoutRoutineId}
                        dontRenderUntilShow
                        alwaysShowDivider 
                        startOpen={isThis}
                        header={isThis ? t('This Routine') : _.fallbackText(wtArr[0].routineName, 'Untitled')}
                        headerClasses='modal-listing'
                    >
                        <ModalSelectListing 
                            col={col}
                            name={'templateIds'}
                            {...formikProps}
                        />
                    </CollapsibleFormSection>
                )
            })}
        </React.Fragment>
    )
}

ImportExerciseGroupsModal = connect(null,mapDispatchToImportEgProps)(ImportExerciseGroupsModal);

let ImportWorkoutTemplatesModal = ({ loadWorkoutTemplates, submit, basePath, workoutRoutine }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal 
            fullWidth 
            path={importWorkoutTemplatesMatchFor(basePath)} 
            scrollable 
            exact 
            hasCollapse
            loadForm={loadWorkoutTemplates}
            icon={['far','file-import']}
            title={t("Import Workouts")}
            footerProps={{ saveIcon: ['far','copy'], saveLabel: t('Copy & Add') }}
            formProps={ ({ modalProps: { match: { params: { routineCycleId }}} }) => ({
                initialValues: { templateIds: [''] },
                submit: values => submit({ workoutRoutineId: workoutRoutine.id, routineCycleId, ...values }),
                validationSchema: Yup.object().shape({
                    templateIds: Yup.array().compact().required().min(1)
                }),
                initialErrors: { templateIds: 'empty' }
            })}
            render={({ modalProps, loaderProps: { responseData }, ...formikProps }) => {
                if(!responseData.workoutTemplates) {
                    return '';
                }

                const { workoutTemplates } = responseData;

                return (
                    <div className="pa10" id="import-workouts-modal">
                        {workoutTemplates.length > 0 && (<ImportWorkoutsContent workoutRoutine={workoutRoutine} formikProps={formikProps} workoutTemplates={workoutTemplates} />)}
                        {workoutTemplates.length === 0 && <SlightEmphasisIconNote text={t("no importable workouts")} />}
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToImportWtProps = dispatch => ({
    loadWorkoutTemplates: () => dispatch(loadWorkoutTemplates()),
    submit: data => dispatch(importWorkoutTemplates(data))
});


ImportWorkoutTemplatesModal = connect(null,mapDispatchToImportWtProps)(ImportWorkoutTemplatesModal);

export { ImportExerciseGroupsModal, ImportWorkoutTemplatesModal }



