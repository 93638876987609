import React from 'react';
import moment from 'moment';
import * as _ from 'lib/utilities';
import beepSound from 'assets/sound/single_beep.mp3';
import timerFinished from 'assets/sound/timer_finished.mp3';

export class CountdownTimer extends React.Component {

    constructor(props) {
        super(props);
        const { autoStart, seconds, startedAt } = props;
        this.state = { seconds, display: _.stopwatchFormat(seconds), status: autoStart ? 'running' : 'unstarted' };
        this.start = this.start.bind(this);
        this.pause = this.pause.bind(this);
        this.resume = this.resume.bind(this);
        this.intervalHandler = this.intervalHandler.bind(this);
        this.reset = this.reset.bind(this);
        this.restart = this.restart.bind(this);

        this.beepAudio = new Audio(beepSound);
        this.finishedAudio = new Audio(timerFinished);

        this.startedAt = startedAt;

        if(autoStart) {
            this.start(false);
        }
    }

    render() {
        const { render, seconds } = this.props;
        const { display, status } = this.state;
        const remaining = this.secondsRemaining();
        return render({ display, status, start: this.start, pause: this.pause, reset: this.reset, restart: this.restart, secondsRemaining: remaining, secondsElapsed: seconds - remaining })
    }

    intervalHandler() {
        const secondsRemaining = this.secondsRemaining();
        const display = _.stopwatchFormat(secondsRemaining);

        if(secondsRemaining <= 0.2) {
            this.finish();
        } else if(secondsRemaining < 5 && !this.beeper) {
            this.startBeeper();
        } 

        this.setState({ display })
    }

    start(setStatus=true) {
        if(this.timeInterval) {
            return;
        }

        if(this.pausedAt) {
            this.resume();
        } else {
            this.startTime = this.startedAt ? moment(this.startedAt) : moment();
            this.timeInterval = setInterval(this.intervalHandler,300);
            if(setStatus) {
                this.setState({ status: 'running' });
            }
        }
    }

    pause() {
        this.clearTimeInterval();

        this.pausedAt = moment();

        this.setState({ status: 'paused' });
    }

    resume() {
        const curTime = moment();
        const pausedFor = curTime.diff(this.pausedAt);
        this.startTime.add(pausedFor,'milliseconds');
        this.pausedAt = null;
        this.timeInterval = setInterval(this.intervalHandler,300);
        this.setState({ status: 'running' });
    }

    reset() {
        this.clearTimeInterval();
        this.startTime = null;
        this.pausedAt = null;
        this.setState({ display: _.stopwatchFormat(this.state.seconds), status: 'unstarted' });
    }

    restart() {
        this.reset();
        this.start();
    }

    clearTimeInterval() {
        if(this.timeInterval) {
            clearInterval(this.timeInterval)
            this.timeInterval = null;
        }

        if(this.beeper) {
            clearInterval(this.beeper);
            this.beeper = null;
        }
    }

    startBeeper() {

        this.beeper = setInterval(() => {
            const { soundOn } = this.props;
            const secondsRemaining = this.secondsRemaining();
            if(secondsRemaining <= 1) {
                if(this.beeper) {
                    clearInterval(this.beeper);
                    this.beeper = null;
                }
            } else if(soundOn) {
                if(_.hasNativeBeep()) {
                    window.timerBeep.playSingle();
                } else {
                    this.beepAudio.play();
                }
            }
        },1000)
    }

    finish() {
        const { soundOn, onFinish } = this.props;
        this.clearTimeInterval()

        this.setState({ status: 'finished' });

        if(soundOn) {
            if(_.hasNativeBeep()) {
                window.timerBeep.playFinished();
            } else {
                this.finishedAudio.play();
            }
        }

        if(onFinish) {
            onFinish();
        }
    }

    secondsRemaining() {
        const curTime = moment();
        const elapsedMillis = curTime.diff(this.startTime);
        return Math.max(this.state.seconds - elapsedMillis/1000,0);
    }

    componentWillUnmount() {
        this.clearTimeInterval();
    }
    
} 

export class CountupTimer extends React.Component {

    constructor(props) {
        super(props);
        const { autoStart, startedAt } = this.props;
        this.state = { secondsElapsed: 0, display: _.stopwatchFormat(0), status: autoStart ? 'running' : 'unstarted' };
        this.start = this.start.bind(this);
        this.pause = this.pause.bind(this);
        this.resume = this.resume.bind(this);
        this.intervalHandler = this.intervalHandler.bind(this);
        this.reset = this.reset.bind(this);
        this.restart = this.restart.bind(this);
        this.startedAt = startedAt;

        if(autoStart) {
            this.start(false);
        }

    }

    render() {
        const { render } = this.props;
        const { display, status } = this.state;
        return render({ display, status, start: this.start, pause: this.pause, reset: this.reset, restart: this.restart, secondsElapsed: this.secondsElapsed() })
    }

    intervalHandler() {
        const secondsElapsed = this.secondsElapsed();
        const display = _.stopwatchFormat(secondsElapsed);

        this.setState({ display })
    }

    start(setStatus=true) {
        if(this.timeInterval) {
            return;
        }

        if(this.pausedAt) {
            this.resume();
        } else {
            this.startTime = this.startedAt ? moment(this.startedAt) : moment();
            this.timeInterval = setInterval(this.intervalHandler,300);
            if(setStatus) {
                this.setState({ status: 'running' });
            }
        }
    }

    pause() {
        this.clearTimeInterval();

        this.pausedAt = moment();

        this.setState({ status: 'paused' });
    }

    resume() {
        const curTime = moment();
        const pausedFor = curTime.diff(this.pausedAt);
        this.startTime.add(pausedFor,'milliseconds');
        this.pausedAt = null;
        this.timeInterval = setInterval(this.intervalHandler,300);
        this.setState({ status: 'running' });
    }

    reset() {
        this.clearTimeInterval();
        this.startTime = null;
        this.pausedAt = null;
        this.setState({ display: _.stopwatchFormat(0), status: 'unstarted' });
    }

    restart() {
        this.reset();
        this.start();
    }

    clearTimeInterval() {
        if(this.timeInterval) {
            clearInterval(this.timeInterval)
            this.timeInterval = null;
        }
    }

    secondsElapsed() {
        const curTime = moment();
        const elapsedMillis = curTime.diff(this.startTime);
        return Math.max(elapsedMillis/1000,0);
    }

    componentWillUnmount() {
        this.clearTimeInterval();
    }
    
} 