import React from 'react';
import loadingContainer, { LoadingContainerParent, validResponses } from 'components/LoadingHOC';
import { makeCancelable, isBlank } from 'lib/utilities';
import { PaddedIconNote } from 'components/Typography';

export class InfiniteScrollForm extends React.Component {

    constructor(props) {
        super(props);
        this.triggerReload = this.triggerReload.bind(this);
        this.retryLoad= this.retryLoad.bind(this);
        this.handleQueryResponse = this.handleQueryResponse.bind(this);
        this.state = { 
            loader: this.props.load.bind(null,this.props.initialValues), 
            status: 'SUCCESS',
            values: this.props.initialValues
        }
    }

    triggerReload(values) {
        return this.doReload(values,false);
    }

    retryLoad(values) {
        this.doReload(values,true);
    }

    handleQueryResponse(response){
        if(response.isCanceled) {
            return;
        }

        const { responseCallback } = this.props; 

        if(validResponses.includes(response.status)) {
            this.setState({status: response.status});
            responseCallback && responseCallback(response);
        } else {
            if(response.data)
                throw response.data;
            else
                throw response;
        }
    }

    doReload(values,setRequesting) {
        const { load } = this.props;
        const loader = load.bind(null,values);
        let newState = { loader, values };
        if(setRequesting) {
            newState.status = 'REQUEST';
        }
        this.loadPromise = makeCancelable(loader(1));
        this.loadPromise.promise.then(this.handleQueryResponse).catch(this.handleQueryResponse);
        this.setState(newState);
        return this.loadPromise.promise;
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }

    render() {
        const { initialMsg, render, initialValues, load, more, initialPage, responseCallback, ...scrollProps } = this.props; 
        //scrollProps should include loadedPage

        const { status, values } = this.state;

        return (
            <InfiniteScroll 
                load={this.state.loader} 
                initialPage={initialPage || 1}
                initialComp={status === 'SUCCESS' && !isBlank(initialMsg) && <PaddedIconNote variant="info" text={initialMsg} />}
                fullyLoaded={(!more && more !== undefined) || status !=='SUCCESS'}
                {...scrollProps}
                render={({ maxIndex, scrollTriggerComp }) => {
                    return render({ 
                        maxIndex, 
                        startValues: values, 
                        submitLoad: this.triggerReload, 
                        retryLoad: () => this.retryLoad(values),
                        status,
                        scrollTriggerComp
                    });
                }}
            >
            </InfiniteScroll>
        )
    }
}

const InfiniteScrollTrigger = ({ triggerRef }) => {
    return (
        <div ref={triggerRef}></div>
    )
}

const LoadingContainer = loadingContainer({
    'SUCCESS': InfiniteScrollTrigger
});

const ScrollTrigger = ({ load, triggerKey, triggerRef, skipLoad }) => {

    if(skipLoad) {
        return (
            <InfiniteScrollTrigger 
                triggerRef={triggerRef}
            />
        )
    }

    return (
        <LoadingContainerParent 
            key={triggerKey}
            component={LoadingContainer}
            load={load}
            preloaded={() => false}
            triggerRef={triggerRef}
        />
    )
}

export class TwoWayInfiniteScroll extends React.Component {
    constructor(props) {
        super(props);
        const { scrollRef } = this.props;
        this.topTriggerRef = React.createRef();
        this.bottomTriggerRef = React.createRef();
        this.containerRef = scrollRef || React.createRef();
        this.checkTrigger = this.checkTrigger.bind(this);
        this.state = { topKey: 0, bottomKey: 0 };
    }

    componentDidMount() {
        setTimeout(() => this.intervalCheckTrigger(),10);
        this.intervalCheck = setInterval(this.intervalCheckTrigger,1000);
    }

    render() {
        const { render, load, component, topDone, bottomDone, scrollRef, scrollCallback, ...rest } = this.props;
        const { topKey, bottomKey } = this.state;
        const Component = component || 'div';

        return (
            <Component {...rest} onScroll={this.checkTrigger} ref={this.containerRef}>
                <ScrollTrigger 
                    load={() => load('top')}
                    triggerKey={`top-${topKey}`}
                    triggerRef={this.topTriggerRef}
                    skipLoad={topDone || topKey === 0}
                />
                {render()}
                <ScrollTrigger 
                    load={() => load('bottom')}
                    triggerKey={`bottom-${bottomKey}`}
                    triggerRef={this.bottomTriggerRef}
                    skipLoad={bottomDone || bottomKey === 0}
                />
            </Component>
        )
    }

    componentWillUnmount() {
        clearInterval(this.intervalCheck);
    }

    intervalCheckTrigger = () => this.checkTrigger(null,'interval');

    checkTrigger(e,type='scroll') {
        const { topDone, bottomDone, scrollCallback } = this.props;
        let triggerPos, topEdge, bottomEdge;
        if(this.containerRef.current) {
            topEdge = Math.max(this.containerRef.current.getBoundingClientRect().top - 50,-75);
            bottomEdge = this.containerRef.current.getBoundingClientRect().bottom + 50;
            scrollCallback && scrollCallback({ topEdge, bottomEdge, type });
            bottomEdge = Math.min(bottomEdge,window.innerHeight+75);
        }

        if(!topDone && this.topTriggerRef.current) {
            triggerPos = this.topTriggerRef.current.getBoundingClientRect().bottom;
            if(triggerPos >= topEdge) {
                this.setState({ topKey: this.state.topKey+1 });
                return;
            }
        }

        if(!bottomDone && this.bottomTriggerRef.current) {
            triggerPos = this.bottomTriggerRef.current.getBoundingClientRect().top;
            if(triggerPos <= bottomEdge) {
                this.setState({ bottomKey: this.state.bottomKey+1 });
                return;
            }
        }
    }
}

export default class InfiniteScroll extends React.Component {

    constructor(props) {
        super(props);
        this.triggerRef = React.createRef();
        this.containerRef = React.createRef();
        this.perPage = props.perPage || 20;
        this.state = { currentPage: Math.min((props.initialPage || 1),this.loadedPage()+1), initial: true, forceLoad: props.alwaysReloadOnMount };
        this.checkTrigger = this.checkTrigger.bind(this);
    }

    componentDidMount() {
        this.intervalCheck = setInterval(this.checkTrigger,1000);
    }

    static getDerivedStateFromProps(props,state) {
        if(state.currentPage > (props.loadedPage || 0)+1) {
            return { ...state, currentPage: (props.loadedPage || 0) }
        }
        return null;
    }

    render() {
        const { render, load, fullyLoaded, component, initialPage, successCallback, perPage, loadedPage,
             records, noResultsComp, initialComp, horizontal, upward, dontRenderTrigger, alwaysReloadOnMount, ...rest } = this.props;
        const { currentPage, initial, forceLoad } = this.state;
        const Component = component || 'div';
        const recordsEmpty = (!records || records.length === 0) && !forceLoad;
        const endOfResults = this.endOfResults();
        const willLoad = (!endOfResults && this.loadedPage() < currentPage) || forceLoad;

        const scrollTrigger = (
                <React.Fragment>
                    {initial && !willLoad && recordsEmpty && initialComp}
                    {(!endOfResults || forceLoad) && <LoadingContainerParent 
                        key={'inf-trigger-' + currentPage}
                        component={LoadingContainer}
                        load={load.bind(null,currentPage)}
                        preloaded={() => !willLoad}
                        triggerRef={this.triggerRef}
                        successCallback={this.successHandler}
                        dontCancelPromise={true}
                    />}
                    {(!initial || !initialComp) && endOfResults && recordsEmpty && noResultsComp}
                </React.Fragment>
        )

        return (
            <Component {...rest} onScroll={this.checkTrigger} ref={this.containerRef}>
                {upward && !dontRenderTrigger && scrollTrigger}
                {render({ maxIndex: this.perPage*currentPage, scrollTriggerComp: scrollTrigger })}
                {!upward && !dontRenderTrigger && scrollTrigger}
            </Component>
        )
    }

    successHandler = (data) => {
        const { successCallback } = this.props;
        const forceLoad = this.state;
        successCallback && successCallback(data);
        if(forceLoad) this.setState({ forceLoad: false });
    }

    componentWillUnmount() {
        clearInterval(this.intervalCheck);
    }

    loadedPage = () => (this.props.loadedPage || 0)

    endOfResults() {
        const { currentPage } = this.state;
        const { fullyLoaded, loadedPage } = this.props;
        return (currentPage >= loadedPage && fullyLoaded);
    }

    checkTrigger(e) {
        const { horizontal, upward } = this.props;
        if(this.triggerRef.current && (this.containerRef.current || !horizontal) && !this.endOfResults()) {
            let triggerPos, containerEdge;
            let triggered = false;

            if(upward) {
                triggerPos = this.triggerRef.current.getBoundingClientRect().bottom;
                containerEdge = Math.min(this.containerRef.current.getBoundingClientRect().top - 50,75);
                if(triggerPos >= containerEdge) {
                    triggered = true;
                }
            } else {
                if(horizontal) {
                    triggerPos = this.triggerRef.current.getBoundingClientRect().left;
                    containerEdge = this.containerRef.current.getBoundingClientRect().right + 50;
                } else {
                    triggerPos = this.triggerRef.current.getBoundingClientRect().top;
                    containerEdge = Math.min(this.containerRef.current.getBoundingClientRect().bottom + 50,window.innerHeight+50);
                }
                if(triggerPos <= containerEdge) {
                    triggered = true;
                }
            }
            
            if(triggered) {
                this.setState({ currentPage: this.state.currentPage+1, initial: false })
            }

        }
    }
}