import React, { Suspense } from 'react';
import ChartErrorBoundary, { ChartPlaceholder } from 'components/ChartErrorBoundary';
import { useTranslation } from 'react-i18next';
const Chart = React.lazy(() => import('components/Chart'));

const ChartWrapper = (props) => {
    const { t } = useTranslation();

    return (
        <ChartErrorBoundary errorMsg={t("unable to load chart")}>
            <Suspense fallback={(<ChartPlaceholder message={t("Loading...")} />)}>
                <Chart {...props} />
            </Suspense>
        </ChartErrorBoundary>
    )
}

export default ChartWrapper;