import { FullFormModal } from 'components/Modal';
import { likeRecipeForClientsMatchFor } from 'config/paths';
import * as _ from 'lib/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { multilikeRecipe } from 'redux/actions';
import { ClientSelectListing } from './Utilities';

let LikeRecipeForClientsPopup = ({ trainer, multilikeRecipe }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    if(_.isBlank(trainer)) {
        return '';
    }

    return (
        <FullFormModal 
            fullWidth 
            path={likeRecipeForClientsMatchFor(pathname)} 
            scrollable 
            exact 
            icon={['far','heart']}
            title={t("Like for Specific Client(s)")}
            formProps={ ({ modalProps: { match: { params: { recipeId } } } }) => ({
                initialValues: { clientIds: [''], unlikeIds: [''], recipeId },
                submit: multilikeRecipe
            })}
            footerProps={{ id: 'save-likes-btn' }}
            render={({ modalProps: { match: { params: { recipeId } } }, ...formikProps }) => {
                const { values: { unlikeIds, clientIds }} = formikProps;
                const preselected = trainer.recipeLikedForClientIds(recipeId);
                return (
                    <div className="text-center pt20 pb20 pr10 pl10">
                        <ClientSelectListing 
                            isModal
                            containerClass={"modal-list-header"}
                            trainer={trainer}
                            formikProps={formikProps}
                            selectSort
                            initialSelected={preselected}
                            selectedNum={_.compact(_.union(_.difference(preselected,unlikeIds),clientIds)).length}
                            listingProps={{ 
                                names: ['clientIds','unlikeIds'],
                                getName: clientId => preselected.includes(clientId) ? 'unlikeIds' : 'clientIds',
                                getInverted: clientId => preselected.includes(clientId)
                            }}
                            excludeFilters={['trainerId','status']}
                        />
                    </div>
                )
            }}
        />
    )
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    multilikeRecipe: data => dispatch(multilikeRecipe(data))
})

LikeRecipeForClientsPopup = connect(mapStateToProps,mapDispatchToProps)(LikeRecipeForClientsPopup);

export { LikeRecipeForClientsPopup }

