import React from 'react';
import { editWeeklyMealMatch, mealPlanMatch, standaloneMealPlanMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { MealDayListing } from 'partials/MealPlanViews';
import { mapStateToMpProps, mapDispatchToMpProps } from './MealPlan'
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomButtons, DefaultAsyncActionButton } from 'components/Button';
import { setOffPlanMeals, cacheOffPlanMeals, setUnsavedChanges } from 'redux/actions';
import { offPlanMealMapSelector } from 'redux/selectors';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { redirectCheckCreator } from './AddPeopleToMeals';
import { ExitMealPlanButton, InitialTippedButton, SIAC } from 'partials/Utilities';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';

const transitionMap = {
    rules: [
        [mealPlanSlideOutPaths,transitions.slideOut],
        [[editWeeklyMealMatch], transitions.slideOver]
    ]
};

const getTempState = (offPlanMealMap,userMeal) => {
    return offPlanMealMap[userMeal.mealType.id][userMeal.date] || 0;
}

const OffPlanButton = ({ userMeal, mealInfoMatrix, className, setOffPlanState, offPlanMealMap }) => {
    const { t } = useTranslation();
    const curState = getTempState(offPlanMealMap,userMeal);
    const allowLocking = !mealInfoMatrix.user.isPdfClient();
    const locked = curState === 2;
    const active = curState > 0;
    const mod = allowLocking ? 3 : 2;
    const onClick = () => setOffPlanState(userMeal,(curState+1)%mod)
    let icon = 'check';
    let msg = null;
    if(locked) {
        icon = 'lock';
        msg = t('Every Week');
    } else if(active) {
        icon = 'lock-open';
        msg = t('This Week');
    }

    let TopComp = 'div';
    let topProps = { onClick, className: classnames(`lh1 set-off-plan-btn state${curState} set-off-plan-${userMeal.mealType.category}-btn`,{[className]: className, active }) };
    if(active && !locked && allowLocking) {
        TopComp = InitialTippedButton;
        topProps = { ...topProps, delay: 0, tipName: 'lock_off_plan_meals_tip', text: t("Tap again to make off-plan every week") }
    }

    return (<TopComp {...topProps} >
            <span>
                {icon && (<FontAwesomeIcon icon={icon} />)} {icon && <br/>}
                {msg}
            </span>
    </TopComp>)

}

const Header = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center mb15">
            <h4 className="font-white center mb5">{t('Pick the meals to make off-plan')}</h4>
            <div className="ml10 mr10 thin-subtitle font-white lh1">{t("off-plan prompt info")}</div>
        </div>
    )
}

class OffPlanMealsCore extends React.Component {

    render() {
        const { t, history, mealInfoMatrix, setOffPlanMeals, cacheOffPlanMeals, offPlanMealMap: inMap, setUnsavedChanges } = this.props;
        const offPlanMealMap = inMap || {};
        const fullOffPlanMealMap = { ...mealInfoMatrix.offPlanMealMap(), ...offPlanMealMap };
        const submitData = { offPlanMeals: offPlanMealMap, startDate: mealInfoMatrix.startDate };
        const setOffPlanState = (userMeal,state) => {
            const oldCatMap = offPlanMealMap[userMeal.mealType.id] || fullOffPlanMealMap[userMeal.mealType.id];
            let newMap = { ...offPlanMealMap, [userMeal.mealType.id]: { ...oldCatMap, [userMeal.date]: state } };
            setUnsavedChanges({ type: 'offPlan', match: [mealPlanMatch,standaloneMealPlanMatch], submitData: { ...submitData, offPlanMeals: newMap } });
            cacheOffPlanMeals(newMap,mealInfoMatrix.startDate);
        }
        
        const lockCheck = (userMeal) => {
            const tempState = getTempState(fullOffPlanMealMap,userMeal);
            if(tempState === 0 && !userMeal.isOffPlan()) {
                return userMeal.isLocked()
            } else {
                return tempState === 2;
            }
        }
        
        return (
            <React.Fragment>
                <Header />
                <SIAC render={({ signedInAsClient }) => (
                    <MealDayListing 
                        signedInAsClient={signedInAsClient}
                        mealInfoMatrix={mealInfoMatrix}
                        lockCheck={lockCheck}
                        getTempState={userMeal => { 
                            const tempState = getTempState(fullOffPlanMealMap,userMeal);
                            if(userMeal.isOffPlan() && tempState === 0) {
                                return 'filler';
                            } else if(tempState !== 0) {
                                return 'offPlan';
                            }
        
                            return null;
                        }}
                        ActionComp={(<OffPlanButton
                            offPlanMealMap={fullOffPlanMealMap}
                            setOffPlanState={setOffPlanState}
                        />)}
                    />
                )} />
                <BottomButtons>
                    <DefaultAsyncActionButton 
                        loaderType={"loaderOnly"}
                        rounded 
                        color="primary" 
                        id={`confirm-off-plan-btn`}
                        action={setOffPlanMeals.bind(null,submitData)}
                        successCallback={(data) => (!this.dontGoBack && history.goBack())}
                    >
                            <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Confirm")}
                    </DefaultAsyncActionButton>
                </BottomButtons>
            </React.Fragment>
        )
    }

    componentWillUnmount() {
        this.dontGoBack = true;
    }
}

const mapDispatchToProps = dispatch => ({
    setOffPlanMeals: (data) => dispatch(setOffPlanMeals(data)),
    setUnsavedChanges: (data) => dispatch(setUnsavedChanges(data)),
    cacheOffPlanMeals: (offPlanMealMap,startDate) => dispatch(cacheOffPlanMeals(offPlanMealMap,startDate))
})

const mapStateToProps = (state,props) => ({
    offPlanMealMap: offPlanMealMapSelector(state,props)
})

OffPlanMealsCore = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OffPlanMealsCore));

const OffPlanMealsPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, mealInfoMatrix, history } = props;

    return (
        <SimpleNavPage
            darkMode
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t('Off-Plan Meals')}
            redirectCheck={redirectCheckCreator(mealInfoMatrix.user,mealInfoMatrix.week)}
            navChildren={<ExitMealPlanButton hideIfUnsavedChanges />}
        >
            <CenteredActivityContainer>
                <OffPlanMealsCore 
                    mealInfoMatrix={mealInfoMatrix}
                    history={history}
                />
            </CenteredActivityContainer>
        </SimpleNavPage>
    )
}

export default connect(mapStateToMpProps,mapDispatchToMpProps)(OffPlanMealsPage);