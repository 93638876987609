import React from 'react';
import classnames from 'classnames';

export const FadeIn = ({ children, delay }) => {
    const [show,setShow] = React.useState(false);

    React.useEffect(() => {
        const timeoutId = setTimeout(() => setShow(true),delay);

        return () => clearTimeout(timeoutId);
    },[delay]);

    return (
        <React.Fragment>
            {React.Children.map(children,child => {
                const classNames = classnames('fade-in', { in: show, [child.props.className]: child.props.className })
                return React.cloneElement(child, { ...child.props, className: classNames });
            })}
        </React.Fragment>
    )
}