import React from 'react';
import classnames from 'classnames';
import { BackButton } from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';

export const SimpleNav = ({ className, shadow, primary, darkMode, responsiveTabs, children, backBtn }) => {
    const classNames = classnames('flow-nav',{ "shadow": shadow, "primary": primary, 'dark': darkMode, [className]: className });

    return (
        <div className={classNames}>
            {backBtn || <SimpleNavBack />}
            {children}
        </div>
    )
}

export const SimpleNavTitle = ({children}) => {
    return (
        <div className="topper-title">
            {children}
        </div>
    )
}

export const SimpleNavBack = ({ backNum=-1 }) => {
    return (
        <BackButton backNum={backNum} render={({ back }) => (
            <div className="back-arrow" onClick={back}>
                <FontAwesomeIcon icon='chevron-left' />
            </div>
        )} />
    )
}

export const SimpleNavBackLink = ({ path }) => {
    const history = useHistory();

    return (
        <div className="back-arrow" onClick={() => history.push(path)}>
            <FontAwesomeIcon icon='chevron-left' />
        </div>
    )
}

export const SimpleNavRightButton = React.forwardRef(({ onClick, className, icon, id, ...rest },ref) => {
    const classNames = classnames("right-btn",{ [className]: className })
    return (
        <div className={classNames} onClick={onClick} id={id} ref={ref} {...rest}>
            <FontAwesomeIcon icon={icon} />
        </div>
    )
})

export const SimpleNavContainer = ({ children, className }) => {
    const classNames = classnames("simple-nav-container", {[className]: className})
    return (
        <div className={classNames}>
            {children}
        </div>
    )
}