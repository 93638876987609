import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { forceSendChatMessage } from 'redux/actions';
import { trainerRecordSelector } from 'redux/selectors';
import { DefaultTextArea } from 'components/Form';
import * as Yup from 'yup';
import { FullFormModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { modalPathFor } from 'config/paths';
import { useLocation } from 'react-router-dom';


const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(forceSendChatMessage(data))
})

export const sendMsgModalSuffix = '/message/:clientId'

export const SendMessageModal = connect(mapStateToProps,mapDispatchToProps)(({ submit, trainer, record  }) => {

    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(sendMsgModalSuffix,pathname);

    return (
        <FullFormModal 
            fullWidth 
            path={path} 
            scrollable 
            exact 
            preProcessor={({ modalProps: { match: { params: { clientId }}} }) => ({ client: trainer.clientById(clientId) })}
            icon={"comments-alt"}
            title={({ client }) => t("Send name a Message", { name: client.shortName() })}
            formProps={({ client }) => {            
                return ({
                    initialValues: { clientId: client.id, body: '', ...(record ? record.sendMessageVals() : {}) },
                    submit: trainer.hasActiveMessaging() ? submit : (() => Promise.resolve({ status: 'SUCCESS'})),
                    validationSchema: trainer.hasActiveMessaging() ? (Yup.object().shape({
                        body: Yup.string().required()
                    })) : null
                })
            }}
            footerProps={trainer.hasActiveMessaging() ? { saveLabel: t('Send'), saveIcon: 'paper-plane' } : { saveLabel: t('Ok'), noCancel: true }}
            render={({ modalProps, ...formikProps }) => {
                
                if(record) {
                    return (
                        <div className="pa20">
                            {!trainer.hasActiveMessaging() && (<SlightEmphasisIconNote text={t("activate msg msg")} id={'activate-msg'}/>)}
                            {trainer.hasActiveMessaging() && (
                                <DefaultTextArea
                                    label={t('Message about object', { object: record.sendMessageLabel(t) })}
                                    className="mt10 mb10"
                                    name="body"
                                    inProps={{
                                        maxLength: 4000,
                                        placeholder: t('Required'),
                                        height: 150
                                    }}
                                    {...formikProps}
                                />
                            )}
                        </div>                
                    )
                }

                modalProps.close();

                return '';

            }}
        />
    )
})