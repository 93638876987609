import React from 'react';
import FormikWrapper from 'components/FormikWrapper';
import { connect } from 'react-redux';
import { startMuxUpload } from 'redux/actions';
import * as _ from 'lib/utilities';

const MuxUploadForm = ({ successCallback, startMuxUpload, recordType, afterUploadStart, ...props }) => {
    const videoFileRef = React.useRef(null);

    return (<FormikWrapper 
        videoFileRef={videoFileRef}
        successCallback={({ formikBag, data, values }) => {
            if(videoFileRef && videoFileRef.current && videoFileRef.current.files && videoFileRef.current.files.length > 0) {
                const picker = videoFileRef.current;
                const file = picker.files[0];
                const id = Object.values(data[`${_.lowerFirst(recordType)}s`])[0].id;
                const prom = startMuxUpload({ id, type: recordType },file);
                if(afterUploadStart) {
                    prom.then(({ status, data }) => {
                        if(status === 'SUCCESS') {
                            afterUploadStart(data);
                        }
                    })
                }
            }
            successCallback && successCallback({ formikBag, data, values });
        }}
        {...props}
    />)
}

const mapDispatchToProps = dispatch => ({
    startMuxUpload: (data,file) => dispatch(startMuxUpload(data,file))
})

export default connect(null,mapDispatchToProps)(MuxUploadForm);