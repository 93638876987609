export const NOOP = "NOOP";

export const SET_TRANSITIONS = "SET_TRANSITIONS";

export const SET_ACTIVITY_EXIT_URL = "SET_ACTIVITY_EXIT_URL";

export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOGIN_USER_SUCCESS = "LOGIN_USER_SUCCESS";
export const EMAIL_SIGNUP_SUCCESS = "EMAIL_SIGNUP_SUCCESS";
export const VERIFY_OAUTH_SUCCESS = "VERIFY_OAUTH_SUCCESS";
export const TSAC_SIGNUP_SUCCESS = "TSAC_SIGNUP_SUCCESS";
export const CREATE_TRAINER = "CREATE_TRAINER";

export const LOGIN_ACTIONS = [LOGIN_USER_SUCCESS,VERIFY_OAUTH_SUCCESS];

export const SWITCH_CLIENT_SUCCESS = "SWITCH_CLIENT_SUCCESS";
export const SWITCH_TO_TRAINER = "SWITCH_TO_TRAINER";

export const ADMIN_SWITCH_USER_SUCCESS = "ADMIN_SWITCH_USER_SUCCESS";

export const REFRESH_CACHE = "REFRESH_CACHE";
export const REFRESH_CACHE_NO_POPUP = "REFRESH_CACHE_NO_POPUP";
export const DISMISS_REFRESH_CACHE = "DISMISS_REFRESH_CACHE";

export const LOGIN_REQUIRED = "LOGIN_REQUIRED";
export const TRAINER_LOGIN_REQUIRED = "TRAINER_LOGIN_REQUIRED";
export const TRAINERS_ONLY = "TRAINERS_ONLY";
export const LOGOUT_USER = "LOGOUT_USER";
export const CLEAR_CACHE = "CLEAR_CACHE";
export const SET_TRAINER_REFRESH = "SET_TRAINER_REFRESH";

export const ONBOARDING_REQUIRED = "ONBOARDING_REQUIRED";

export const PRO_REQUIRED = "PRO_REQUIRED";

export const REQUEST_FAILED = "REQUEST_FAILED";
export const REQUEST_SUCCEEDED = "REQUEST_SUCCEEDED";
export const SHOW_SAVE_FLASH = "SHOW_SAVE_FLASH";
export const HIDE_SAVE_FLASH = "HIDE_SAVE_FLASH";

export const TOUCH_USER_SUCCESS = "TOUCH_USER_SUCCESS";

export const FULL_RELOAD_ACTIONS = [LOGIN_USER_SUCCESS,SWITCH_CLIENT_SUCCESS,SWITCH_TO_TRAINER,EMAIL_SIGNUP_SUCCESS,VERIFY_OAUTH_SUCCESS,REFRESH_CACHE,CREATE_TRAINER,ADMIN_SWITCH_USER_SUCCESS];
export const CLEAR_USER_ACTIONS = [TRAINER_LOGIN_REQUIRED,LOGIN_REQUIRED,LOGOUT_USER];
export const CLEAR_WIP_ACTIONS = [SWITCH_CLIENT_SUCCESS,SWITCH_TO_TRAINER,ADMIN_SWITCH_USER_SUCCESS];
export const CLEAR_TRAINER_ACTIONS = [TRAINER_LOGIN_REQUIRED,LOGOUT_USER,TRAINERS_ONLY];

export const SET_VIDEO_AUTOPLAY = "SET_VIDEO_AUTOPLAY";
export const DEVICE_READY = "DEVICE_READY";

export const LOG_MEAL_SUCCESS = "LOG_MEAL_SUCCESS";
export const SKIP_MEAL_SUCCESS = "SKIP_MEAL_SUCCESS";

export const RECENT_USER_MEALS_SUCCESS = "RECENT_USER_MEALS_SUCCESS";
export const LOG_OFF_PLAN_SUCCESS = "LOG_OFF_PLAN_SUCCESS";

export const FOOD_DB_SEARCH_SUCCESS = "FOOD_DB_SEARCH_SUCCESS";

export const POPULATE_FATSECRET_SUCCESS = "POPULATE_FATSECRET_SUCCESS";

export const LOG_FOOD_FROM_DB_SUCCESS = "LOG_FOOD_FROM_DB_SUCCESS";

export const LOAD_RECIPES_SUCCESS = "LOAD_RECIPES_SUCCESS";
export const LOAD_CATEGORIZE_RECIPES = "LOAD_CATEGORIZE_RECIPES";

export const DISMISS_TOOLTIP = "DISMISS_TOOLTIP";

export const CREATE_AND_LOG_FOOD_SUCCESS = "CREATE_AND_LOG_FOOD_SUCCESS";

export const ADVANCE_SIGNUP_FLOW= "ADVANCE_SIGNUP_FLOW";
export const ADVANCE_TRAINER_SIGNUP = "ADVANCE_TRAINER_SIGNUP";
export const ADVANCE_WORKOUT_SETUP = "ADVANCE_WORKOUT_SETUP";
export const ADVANCE_MEAL_PLAN_SETUP = "ADVANCE_MEAL_PLAN_SETUP";
export const LOAD_BANNABLE_PROGS_SUCCESS = "LOAD_BANNABLE_PROGS_SUCCESS";

export const ADVANCE_ONBOARDING_FLOW = "ADVANCE_ONBOARDING_FLOW";

export const POSSIBLE_ROUTINES_SUCCESS = "POSSIBLE_ROUTINES_SUCCESS";
export const UNPOSSIBLE_ROUTINES_SUCCESS = "UNPOSSIBLE_ROUTINES_SUCCESS";
export const ASSIGN_NEW_ROUTINE_SUCCESS = "ASSIGN_NEW_ROUTINE_SUCCESS";

export const UPDATE_MEAL_PLAN_SUCCESS = "UPDATE_MEAL_PLAN_SUCCESS";
export const INITIAL_RECIPE_OPTIONS = "INITIAL_RECIPE_OPTIONS";
export const RESET_INITIAL_RECIPE_OPTIONS = "RESET_INITIAL_RECIPE_OPTIONS";
export const RECIPE_OPTIONS_CATEGORY_SUCCESS = "RECIPE_OPTIONS_CATEGORY_SUCCESS";

export const FULL_MEAL_PLAN_UPDATE = "FULL_MEAL_PLAN_UPDATE";

export const INIT_PRO_SUCCESS = "INIT_PRO_SUCCESS";

export const SET_ANALYTICS_DATA = "SET_ANALYTICS_DATA";

export const UNLOG_MEAL_SUCCESS = "UNLOG_MEAL_SUCCESS";

export const GET_FOOD_FROM_BARCODE_SUCCESS = "GET_FOOD_FROM_BARCODE_SUCCESS";
export const CLEAR_SCANNED_FOOD = "CLEAR_SCANNED_FOOD";

export const LIKE_RECIPE_SUCCESS = "LIKE_RECIPE_SUCCESS";
export const DISLIKE_RECIPE_SUCCESS = "DISLIKE_RECIPE_SUCCESS";
export const DEACTIVATE_RECIPE_SUCCESS = "DEACTIVATE_RECIPE_SUCCESS";

export const LOG_WEIGHT_SUCCESS = "LOG_WEIGHT_SUCCESS";

export const CLEANUP_ROUTINE_SUCCESS = "CLEANUP_ROUTINE_SUCCESS";

export const LOAD_WORKOUT_SUCCESS = "LOAD_WORKOUT_SUCCESS";

export const POPULATE_PARTIAL_LOG = "POPULATE_PARTIAL_LOG";

export const START_MUX_UPLOAD_SUCCESS = "START_MUX_UPLOAD_SUCCESS";

export const START_WORKOUT = "START_WORKOUT";
export const LOG_WORKOUT_REQUEST = "LOG_WORKOUT_REQUEST";
export const LOG_WORKOUT_SUCCESS = "LOG_WORKOUT_SUCCESS";
export const LOG_WORKOUT_FAIL = "LOG_WORKOUT_FAIL";
export const UPDATE_EXERCISE_SETS = "UPDATE_EXERCISE_SETS";
export const FLAG_WORKOUT_UNSYNCED = "FLAG_WORKOUT_UNSYNCED";
export const CLEAR_CACHED_WORKOUTS = "CLEAR_CACHED_WORKOUTS";
export const CLEAR_WORKOUT_REQUESTING_FLAGS = "CLEAR_WORKOUT_REQUESTING_FLAGS";
export const MUTE_WORKOUT_TIMERS = "MUTE_WORKOUT_TIMERS";
export const WORKOUT_ACTION_SUCCESS = "WORKOUT_ACTION_SUCCESS";
export const LOAD_EX_PROGRESSION = "LOAD_EX_PROGRESSION";
export const HANDLE_PROG_PICK_FAIL = "HANDLE_PROG_PICK_FAIL";
export const RESTART_WORKOUT_SUCCESS = "RESTART_WORKOUT_SUCCESS";
export const UPDATE_WORKOUT = "UPDATE_WORKOUT";
export const UPDATE_EXERCISE_SPECIFICATION = "UPDATE_EXERCISE_SPECIFICATION";
export const SWAP_EXERCISE_SPEC = "SWAP_EXERCISE_SPEC";
export const LOAD_STRENGTH_TEST_SUCCESS = "LOAD_STRENGTH_TEST_SUCCESS";
export const SUBMIT_STRENGTH_TEST_SET = "SUBMIT_STRENGTH_TEST_SET";
export const SUBMIT_PROGRESSION_TEST_SET = "SUBMIT_PROGRESSION_TEST_SET";

export const EXERCISE_SETTING_UPDATE_SUCCESS = "EXERCISE_SETTING_UPDATE_SUCCESS";
export const LOAD_WARMUPS_SUCCESS = "LOAD_WARMUPS_SUCCESS";
export const RESET_EXERCISE_SEARCH = "RESET_EXERCISE_SEARCH";
export const EXECUTE_EXERCISE_SEARCH = "EXECUTE_EXERCISE_SEARCH";
export const CACHE_EXERCISE_SEARCH = "CACHE_EXERCISE_SEARCH";
export const LOAD_PROGRESSION_STANDALONE = "LOAD_PROGRESSION_STANDALONE";

export const SET_PAYWALL_REDIRECT = "SET_PAYWALL_REDIRECT";
export const SET_NEXT_RATING_REQUEST = "SET_NEXT_RATING_REQUEST";

export const SET_VIEWPORT_HEIGHT = "SET_VIEWPORT_HEIGHT";

export const DELOAD_SUCCESS = "DELOAD_SUCCESS";

export const HISTORY_PUSH = "HISTORY_PUSH";
export const HISTORY_REPLACE = "HISTORY_REPLACE";
export const PRE_HISTORY_POP = "PRE_HISTORY_POP";
export const HISTORY_POP = "HISTORY_POP";
export const HISTORY_CLEAR = "HISTORY_CLEAR";
export const HISTORY_CLEAR_FORWARD = "HISTORY_CLEAR_FORWARD";

export const LOAD_ROUTINE_SUCCESS = "LOAD_ROUTINE_SUCCESS";
export const UPDATE_ROUTINE_SUCCESS = "UPDATE_ROUTINE_SUCCESS";
export const RESTORE_ROUTINE_DEFAULTS_SUCCESS = "RESTORE_ROUTINE_DEFAULTS_SUCCESS";
export const RESTART_ROUTINE_SUCCESS = "RESTART_ROUTINE_SUCCESS";
export const UPDATE_ROUTINE_CYCLE_SUCCESS = "UPDATE_ROUTINE_CYCLE_SUCCESS";
export const DESTROY_ROUTINE_CYCLE_SUCCESS = "DESTROY_ROUTINE_CYCLE_SUCCESS";
export const UPDATE_CYCLE_DAY_ORDER_SUCCESS = "UPDATE_CYCLE_DAY_ORDER_SUCCESS";
export const DESTROY_WRTD_SUCCESS = "DESTROY_WRTD_SUCCESS";
export const CREATE_WRTD_SUCCESS = "CREATE_WRTD_SUCCESS";

export const UPDATE_WORKOUT_TEMPLATE_SUCCESS = "UPDATE_WORKOUT_TEMPLATE_SUCCESS";
export const CREATE_EXERCISE_GROUP = "CREATE_EXERCISE_GROUP";
export const UPDATE_WT_CHILD_ORDER = "UPDATE_WT_CHILD_ORDER";
export const UPDATE_SET_TEMPLATE = "UPDATE_SET_TEMPLATE";
export const UPDATE_EXERCISE_TEMPLATE = "UPDATE_EXERCISE_TEMPLATE";
export const LOAD_PROGRESSION_REQUIREMENTS = "LOAD_PROGRESSION_REQUIREMENTS";
export const ADD_WT_CHILD_GROUP = "ADD_WT_CHILD_GROUP";
export const ADD_EXERCISE_TEMPLATE = "ADD_EXERCISE_TEMPLATE";
export const DESTROY_SET_TEMPLATE = "DESTROY_SET_TEMPLATE";
export const DESTROY_WTC = "DESTROY_WTC";
export const DESTROY_WORKOUT_TEMPLATE = "DESTROY_WORKOUT_TEMPLATE";
export const SWAP_EXERCISE_TEMPLATE = "SWAP_EXERCISE_TEMPLATE";
export const UPDATE_EXERCISE = "UPDATE_EXERCISE";
export const CREATE_EXERCISE = "CREATE_EXERCISE";

export const UPDATE_ROUTINE_META_ACTIONS = [
    UPDATE_ROUTINE_SUCCESS,
    RESTORE_ROUTINE_DEFAULTS_SUCCESS,
    RESTART_ROUTINE_SUCCESS,
    UPDATE_ROUTINE_CYCLE_SUCCESS,
    DESTROY_ROUTINE_CYCLE_SUCCESS,
    UPDATE_CYCLE_DAY_ORDER_SUCCESS,
    DESTROY_WRTD_SUCCESS,
    CREATE_WRTD_SUCCESS,
    UPDATE_WT_CHILD_ORDER,
    UPDATE_WORKOUT_TEMPLATE_SUCCESS,
    UPDATE_WT_CHILD_ORDER,
    UPDATE_SET_TEMPLATE,
    UPDATE_EXERCISE_TEMPLATE,
    ADD_WT_CHILD_GROUP,
    ADD_EXERCISE_TEMPLATE,
    DESTROY_SET_TEMPLATE,
    DESTROY_WTC,
    DESTROY_WORKOUT_TEMPLATE,
    SWAP_EXERCISE_TEMPLATE
]


export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';

export const UPDATE_PROGRESS_CHARTS = "UPDATE_PROGRESS_CHARTS";
export const LOAD_BODY_MEASUREMENTS = "LOAD_BODY_MEASUREMENTS";
export const CREATE_BODY_MEASUREMENTS = "CREATE_BODY_MEASUREMENTS";

export const CLEAR_INITIAL_SIGNUP = "CLEAR_INITIAL_SIGNUP";
export const CLEAR_MEAL_PLAN_SETUP = "CLEAR_MEAL_PLAN_SETUP";

export const NOTIFICATIONS_READ = "NOTIFICATIONS_READ";

export const ERROR_LOGS_LOAD = "ERROR_LOGS_LOAD";
export const DESTROY_ERROR_LOG = "DESTROY_ERROR_LOG";
export const CLEAR_ERROR_LOGS = "CLEAR_ERROR_LOGS";

export const SET_MP_VIEW_TYPE = "SET_MP_VIEW_TYPE";
export const MARK_WEEK_CLEAN = "MARK_WEEK_CLEAN";

export const REFRESH_ERROR_LOGS = "REFRESH_ERROR_LOGS";

export const IGNORE_WARNINGS = "IGNORE_WARNINGS";

export const SET_PINNED_MEALS = "SET_PINNED_MEALS";

export const CACHE_OFF_PLAN_MEALS = "CACHE_OFF_PLAN_MEALS";

export const ADD_PEOPLE_SELECTION = "ADD_PEOPLE_SELECTION";

export const UPDATE_MINI_PROFILE = "UPDATE_MINI_PROFILE";
export const DESTROY_MINI_PROFILE = "DESTROY_MINI_PROFILE";

export const HANDLE_SHARE_MEAL_SIDE_EFFECTS = "HANDLE_SHARE_MEAL_SIDE_EFFECTS";
export const HANDLE_MEAL_UPDATE = "HANDLE_MEAL_UPDATE";

export const SET_UNSAVED_CHANGES = "SET_UNSAVED_CHANGES";
export const DISCARD_UNSAVED_CHANGES = "DISCARD_UNSAVED_CHANGES";
export const CLEAR_UNSAVED_CHANGES = "CLEAR_UNSAVED_CHANGES";

export const SETUP_MEAL_SWAP = "SETUP_MEAL_SWAP";
export const LOAD_MEAL_SEARCH_RESULTS = "LOAD_MEAL_SEARCH_RESULTS";
export const LOAD_RECIPE_SEARCH_RESULTS = "LOAD_RECIPE_SEARCH_RESULTS";

export const LOAD_MEAL_SEARCH_CATEGORIES = "LOAD_MEAL_SEARCH_CATEGORIES";

export const LOAD_RECENT_RECIPES = "LOAD_RECENT_RECIPES";

export const SETUP_REPLACE_RECIPE = "SETUP_REPLACE_RECIPE";

export const START_EDIT_TEMP_MEAL = "START_EDIT_TEMP_MEAL";

export const GET_RANDOM_MEAL = "GET_RANDOM_MEAL";
export const REPLACE_TEMP_RECIPE = "REPLACE_TEMP_RECIPE";

export const SET_RECIPE_MEAL_SERVINGS = "SET_RECIPE_MEAL_SERVINGS";
export const SET_TEMP_RECIPE_MEAL_SERVINGS = "SET_TEMP_RECIPE_MEAL_SERVINGS";

export const LOAD_WIMAGE_CATS = "LOAD_WIMAGE_CATS";
export const DESTROY_WIMAGE_CAT = "DESTROY_WIMAGE_CAT";
export const UPDATE_WIMAGE_CAT = "UPDATE_WIMAGE_CAT";

export const LOAD_GLIST_SUCCESS = "LOAD_GLIST_SUCCESS";
export const CREATE_PANTRY_ITEM = "CREATE_PANTRY_ITEM";
export const DESTROY_PANTRY_ITEM = "DESTROY_PANTRY_ITEM";

export const START_EDITING_RECIPE = "START_EDITING_RECIPE";
export const CACHE_RECIPE_CHANGES = "CACHE_RECIPE_CHANGES";

export const ADD_INGREDIENT = "ADD_INGREDIENT";
export const UPDATE_FOOD = "UPDATE_FOOD";
export const FOOD_LOAD = "FOOD_LOAD";
export const LOAD_MY_FOODS = "LOAD_MY_FOODS";
export const DESTROY_FOOD = "DESTROY_FOOD";

export const CREATE_RECIPE = "CREATE_RECIPE";
export const UPDATE_RECIPE = "UPDATE_RECIPE";
export const CREATE_RECIPE_OVERRIDE = "CREATE_RECIPE_OVERRIDE";
export const RESTORE_RECIPE_DEFAULTS = "RESTORE_RECIPE_DEFAULTS";
export const CLEAR_RECIPE_DRAFT = "CLEAR_RECIPE_DRAFT";

export const CREATE_CHILD_TRAINER = "CREATE_CHILD_TRAINER";

export const IMPORT_EXERCISE_GROUPS = "IMPORT_EXERCISE_GROUPS";
export const IMPORT_WORKOUT_TEMPLATES = "IMPORT_WORKOUT_TEMPLATES";

export const ASSIGN_CLIENT_ROUTINES = "ASSIGN_CLIENT_ROUTINES";
export const SYNC_CLIENT_ROUTINES = "SYNC_CLIENT_ROUTINES";
export const LOAD_CLIENTS = "LOAD_CLIENTS";
export const LOAD_ALL_TRAINERS = "LOAD_ALL_TRAINERS";
export const LOAD_CLIENT = "LOAD_CLIENT";
export const LOAD_TRAINER_ROUTINES = "LOAD_TRAINER_ROUTINES";
export const IMPORT_ROUTINE = "IMPORT_ROUTINE";
export const SET_TRAINER_FOR_ROUTINES = "SET_TRAINER_FOR_ROUTINES";
export const TEMP_DISMISS_TIP = "TEMP_DISMISS_TIP";
export const SET_CLIENT_FILTERS = "SET_CLIENT_FILTERS";
export const SET_TRAINER_FILTERS = "SET_TRAINER_FILTERS";
export const TAG_CLIENTS = "TAG_CLIENTS";
export const UPDATE_CLIENTS = "UPDATE_CLIENTS";
export const CREATE_CLIENT = "CREATE_CLIENT";
export const ASSIGN_TRAINER = "ASSIGN_TRAINER";
export const UPDATE_CLIENT_MEAL_PLAN = "UPDATE_CLIENT_MEAL_PLAN";
export const TRAINER_SUB_UPDATED = "TRAINER_SUB_UPDATED";
export const LOAD_SUBSCRIPTION = "LOAD_SUBSCRIPTION";
export const UPDATE_MY_RECIPES_FILTERS = "UPDATE_MY_RECIPES_FILTERS";
export const LOAD_TEAM_RECIPES = "LOAD_TEAM_RECIPES";
export const UPDATE_TEAM_RECIPES_FILTERS = "UPDATE_TEAM_RECIPES_FILTERS";
export const SEND_INVITE = "SEND_INVITE";
export const CRUPDATE_TRAINER_NOTE = "CRUPDATE_TRAINER_NOTE";
export const DESTROY_TRAINER_NOTE = "DESTROY_TRAINER_NOTE";
export const SET_VIEW_LOGS_DATE = "SET_VIEW_LOGS_DATE";
export const LOAD_LOGS_SUCCESS = "LOAD_LOGS_SUCCESS";
export const DEREACTIVATE_TRAINERS = "DEREACTIVATE_TRAINERS";
export const UPDATE_TRAINER = "UPDATE_TRAINER";
export const UPDATE_HABIT = "UPDATE_HABIT";
export const SET_TRAINER_FOR_HABITS = "SET_TRAINER_FOR_HABITS";
export const LOAD_SCHEDULABLE_SETTINGS = "LOAD_SCHEDULABLE_SETTINGS";
export const LOG_HABIT_SUCCESS = "LOG_HABIT_SUCCESS";

export const UPDATE_PDF_EXPORT_SETTINGS = 'UPDATE_PDF_EXPORT_SETTINGS';
export const UPLOAD_TRAINER_LOGO = 'UPLOAD_TRAINER_LOGO';

export const UPDATE_MP_INFO_STUB = 'UPDATE_MP_INFO_STUB';

export const SWITCH_TRAINER_TYPE = 'SWITCH_TRAINER_TYPE';
export const SWITCH_TTYPE_SUCCESS = 'SWITCH_TTYPE_SUCCESS';

export const CLEAR_TRAINER_SIGNUP = 'CLEAR_TRAINER_SIGNUP';
export const UPDATE_TBASIC_PROFILE = 'UPDATE_TBASIC_PROFILE';

export const ACTIVATE_MESSAGING = "ACTIVATE_MESSAGING";
export const GET_JWT = "GET_JWT";
export const CHAT_DATA_RECEIVED = "CHAT_DATA_RECEIVED";
export const CREATE_CHAT = "CREATE_CHAT";
export const LOAD_CHATS = "LOAD_CHATS";
export const LOAD_CHAT_MESSAGES = "LOAD_CHAT_MESSAGES";

export const CACHE_CHAT_MESSAGE = "CACHE_CHAT_MESSAGE";
export const MESSAGE_SEND_FAILED = "MESSAGE_SEND_FAILED";
export const DISCARD_MESSAGES = "DISCARD_MESSAGES";
export const UPDATE_UNREAD_MSGS = "UPDATE_UNREAD_MSGS";

export const DNP_UPDATE_SUCCESS = "DNP_UPDATE_SUCCESS";
export const DNP_LOAD_SUCCESS = "DNP_LOAD_SUCCESS";

export const CLIENT_MP_QUOTA_HIT = "CLIENT_MP_QUOTA_HIT";
export const FREE_TRIAL_EXPORT_QUOTA_HIT = "FREE_TRIAL_EXPORT_QUOTA_HIT";
export const MSC_EXPORT_QUOTA_HIT = "MSC_EXPORT_QUOTA_HIT";

export const MUX_VIDEO_UPLOAD_PROG = "MUX_VIDEO_UPLOAD_PROG";
export const CANCEL_MUX_UPLOAD_SUCCESS = "CANCEL_MUX_UPLOAD_SUCCESS";
export const RESTART_MUX_UPLOAD = "RESTART_MUX_UPLOAD";

export const CACHED_WORKOUTS_ACTIONS = [
    START_WORKOUT,
    LOG_WORKOUT_FAIL,
    LOG_WORKOUT_REQUEST,
    LOG_WORKOUT_SUCCESS,
    UPDATE_EXERCISE_SETS,
    FLAG_WORKOUT_UNSYNCED,
    WORKOUT_ACTION_SUCCESS,
    LOAD_EX_PROGRESSION,
    HANDLE_PROG_PICK_FAIL,
    RESTART_WORKOUT_SUCCESS,
    UPDATE_WORKOUT,
    UPDATE_EXERCISE_SPECIFICATION,
    SWAP_EXERCISE_SPEC,
    LOAD_STRENGTH_TEST_SUCCESS,
    SUBMIT_STRENGTH_TEST_SET,
    SUBMIT_PROGRESSION_TEST_SET,
    START_MUX_UPLOAD_SUCCESS,
    CANCEL_MUX_UPLOAD_SUCCESS,
    RESTART_MUX_UPLOAD,
    UPDATE_EXERCISE
];

export const SET_INITIAL_RECIPE_FILTER = "SET_INITIAL_RECIPE_FILTER";

export const CREATE_ACTIVITY_LOG = "CREATE_ACTIVITY_LOG";
export const UPDATE_ACTIVITY_LOG = "UPDATE_ACTIVITY_LOG";
export const DESTROY_ACTIVITY_LOG = "DESTROY_ACTIVITY_LOG";
export const CREATE_CUSTOM_EXERCISE = "CREATE_CUSTOM_EXERCISE";
export const INSERT_NEW_EXERCISE_TO_TOP = "INSERT_NEW_EXERCISE_TO_TOP";
export const START_ACTIVITY = "START_ACTIVITY";
export const DISCARD_CURRENT_ACTIVITY = "DISCARD_CURRENT_ACTIVITY";

export const HEALTHKIT_DATA_RECEIVED = "HEALTHKIT_DATA_RECEIVED";

export const PUBLISH_MEAL_PLAN = "PUBLISH_MEAL_PLAN";

export const SHOW_CONVERSION_POPUP = "SHOW_CONVERSION_POPUP";

export const LOAD_FORMS_SUCCESS = "LOAD_FORMS_SUCCESS";
export const UPDATE_FORM_SUCCESS = "UPDATE_FORM_SUCCESS";
export const UPDATE_FORM_SECTION_SUCCESS = "UPDATE_FORM_SECTION_SUCCESS";
export const UPDATE_FORM_FIELD_SUCCESS = "UPDATE_FORM_FIELD_SUCCESS";
export const REMOVE_FORM_AS_DEF = "REMOVE_FORM_AS_DEF";
export const SET_FORM_DEFAULT_SCHED = "SET_FORM_DEFAULT_SCHED";

export const UPDATE_ASSESSMENT_SUCCESS = 'UPDATE_ASSESSMENT_SUCCESS';
export const LOAD_MUX_RECORD = 'LOAD_MUX_RECORD';

export const LOAD_PROGRESS_PHOTOS = 'LOAD_PROGRESS_PHOTOS';
export const CREATE_PROGRESS_PHOTO = 'CREATE_PROGRESS_PHOTO';
export const DESTROY_PROGRESS_PHOTO = 'DESTROY_PROGRESS_PHOTO';

export const SET_FILTER_BTN_FILTS = 'SET_FILTER_BTN_FILTS';

export const INIT_MINI_PROFILES = 'INIT_MINI_PROFILES'; 

export const SET_SCANNER_DEVICE_ID = "SET_SCANNER_DEVICE_ID";