import * as _ from 'lib/utilities';
import { DailyNutritionProfile } from './daily-nutrition-profile';
import { MacroParams } from './macro-param';
import moment from 'moment';


export const dnpConcern = {
    mainNutritionProfile() {
        return _.find(this.dailyNutritionProfiles,dnp => dnp.main) || DailyNutritionProfile.defaultMain({ user: this });
    },

    specificNutritionProfiles() {
        return _.filter(this.dailyNutritionProfiles,dnp => !dnp.main);
    },

    mnpFormValArr() {
        return DailyNutritionProfile.buildForForm([this.mainNutritionProfile()]);
    },

    baseCalsForForm(formValues,t) {
        const { dailyNutritionProfiles: { 0: { calorieOverride, overrideCalories, nutritionParameters } } } = formValues;
        const baseCals = this.mainNutritionProfile().targetCalories(overrideCalories ? calorieOverride : null);
        const recParams = this.mainNutritionProfile().recMacroParams();
        const fallbackParams = MacroParams.macroParamsFromForm(nutritionParameters,baseCals,t,recParams);
        return [baseCals,fallbackParams];
    },

    dnpById(id) {
        return _.find(this.dailyNutritionProfiles,dnp => (dnp.id === Number(id)));
    },

    dnpOnWday(wday) {
        return _.find(this.activeDnpProfiles(),dnp => dnp.weekdaysArr().includes(wday)) || this.mainNutritionProfile();
    },

    highAndLowCalDayGoals() {
        const high = _.find(this.specificNutritionProfiles(), dnp => (dnp.weekdaysArr().length > 0 && dnp.percentOfBase > 100));
        const low = _.find(this.specificNutritionProfiles(), dnp => (dnp.weekdaysArr().length > 0 && dnp.percentOfBase < 100));
        const baseCals = this.baseTargetCalories();
        const result = {};
        [['high',high],['low',low]].forEach(([name,dnp]) => {
            result[name] = dnp ? dnp.targetCalories() : baseCals;
        })
        return result;
    },

    lowCalDayGoal() {

    },

    dnpOn(date) {
        if(_.isBlank(date)) {
            return this.mainNutritionProfile();
        }

        const wday = _.wday(moment(date));
        const dnp = this.dnpOnWday(wday);

        return dnp;
    },

    activeSpecificDnps() {
        return _.filter(this.specificNutritionProfiles(),dnp => dnp.isActive());
    },

    activeDnpTemplates(excludeIds=[]) {
        return _.filter(this.activeSpecificDnps(),dnp => (!excludeIds.includes(dnp.id) && dnp.isTemplate()));
    },

    activeDnpProfiles(alwaysInclude=[]) {
        const arr = _.filter(this.activeSpecificDnps(),dnp => !dnp.isTemplate());
        if(alwaysInclude && alwaysInclude.length > 0) {
            const always = _.compact(_.filter(alwaysInclude,id => !arr.map(dnp => dnp.id).includes(id)).map(id => this.dnpById(id)));
            return [ ...always, ...arr ];
        }
        return arr;
    },

    formDnpListOrder(client=null) {
        const dnps = this.formDnpListFor(client);
        const ids = dnps.map(dnp => dnp.id);
        return _.flatMap(dnps, dnp => _.compact([dnp.id,(ids.includes(dnp.templateId) ? null : dnp.templateId)]));
    },

    formDnpListFor(client=null,orderList=[]) {
        let arr;
        if(client && this.isTrainer() && client.id !== this.id) {
            arr = client.activeDnpProfiles(orderList);
            arr = [...arr, ...this.activeDnpTemplates(_.compact(arr.map(dnp => dnp.templateId)))]
        } else {
            arr = this.activeDnpProfiles(orderList)
        }

        return _.sortBy(arr,dnp => {
            const ind = orderList.indexOf(dnp.id);
            if(ind === -1) {
                return orderList.indexOf(dnp.templateId)
            }
            return ind;
        });
    },

    hasSpecificDnps() {
        return this.overriddenDnpDays().length > 0;
    },

    overriddenDnpDays() {
        return _.uniq(_.flatMap(this.activeDnpProfiles(),dnp => dnp.weekdaysArr())).sort();
    },

    relevantDnpProfiles() {
        return _.filter([this.mainNutritionProfile(), ...this.activeDnpProfiles()],dnp => dnp.weekdaysArr().length > 0)
    }
}