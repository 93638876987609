import React from 'react';
import { SlightEmphasisIconNote } from './Typography';


export const BlurOverlay = ({ msg }) => {

    return (
        <div className="blur-overlay">
            {msg && (
                <div className="overlay-msg">
                    <SlightEmphasisIconNote text={msg} containerClassName="elevation-2-dp pa20" />
                </div>
            )}
        </div>
    )
}