import React, { useState } from 'react';
import { connect } from 'react-redux';
import { trainerMainMatches, clientDetailsMatch, generateNewWeekMatch, switchForGenerateMatch, clientBasicProfileMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import withTrainer from 'partials/TrainerLoader';
import { dismissTooltip, loadClient, updateClientMealPlan } from 'redux/actions';
import { LoaderOnlySubmitButton } from 'components/Form';
import { MealPlanSettings } from './Settings';
import { Loader } from 'components/LoadingHOC';
import { useHistory } from 'react-router-dom';
import { allergyTagsSelector } from 'redux/selectors';
import { BottomButtons } from 'components/Button';
import { HideOnExit } from 'components/RouteTransitionMap';

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [[clientDetailsMatch,clientBasicProfileMatch], transitions.slideOut],
        [[generateNewWeekMatch,switchForGenerateMatch],transitions.slideOver]
    ]
};

const ClientMealPlanSettingsCore = ({ client, update, dismissTooltip, allergyTags, trainer, context }) => {
    const { t } = useTranslation();
    const [afterPath,setAfterPath] = useState(null);
    const history = useHistory();
    const isFirst = context === 'first';
    const isSetup = context === 'setup';

    const submitRender = ({ errors, submitState, setupOwnMpPrefs }) => {
        return (
            <BottomButtons>
                <LoaderOnlySubmitButton 
                    id="submit-mp-btn"
                    icon={isFirst ? "arrow-right" : "check"} 
                    label={isFirst ? t("Next") : t('Save')} 
                    className="btn-wide" 
                    loadState={submitState} 
                    onClick={() => setAfterPath(null)}
                    disabled={!_.isEmpty(errors)} 
                />
                {isSetup && submitState !== 'REQUEST' && !setupOwnMpPrefs && (
                    <LoaderOnlySubmitButton 
                        id="submit-gen-mp-btn"
                        icon="random"
                        label={t("Generate")} 
                        className="btn-wide ml5 shadow" 
                        outlined
                        loadState={submitState} 
                        onClick={() => setAfterPath(client.createNewMealPlanPath())}
                        disabled={!_.isEmpty(errors)} 
                    />
                )}
            </BottomButtons>
        )
    }


    return (
        <MealPlanSettings 
            t={t}
            user={client}
            trainer={trainer}
            forTrainer
            allergyTags={allergyTags}
            submitMealPlan={(values) => update({ ...values, clientId: client.id},false)}
            submitRender={submitRender}
            successCallback={(() => {
                if(afterPath) {
                    history.replace(afterPath);
                } else if(isFirst) {
                    history.replace(client.createFirstMealPlanPath());
                } else {
                    history.go(-1)
                }
            })}
        />
    )
}

class ClientMealPlanSettings extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, trainer, loadClient, update, dismissTooltip, allergyTags, match: { params: { id, context }} } = this.props;
        const client = trainer.clientById(id);

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={`${(client ? client.shortName() : '')}'s ${t("Meal Plan Settings")}`}
            >
                <HideOnExit>
                    <Loader 
                        successComponent={ClientMealPlanSettingsCore}
                        load={loadClient.bind(null,id)}
                        preloaded={() => false}
                        type="page"
                        client={client}
                        trainer={trainer}
                        update={update}
                        dismissTooltip={dismissTooltip}
                        context={context}
                        allergyTags={allergyTags}
                    />
                </HideOnExit>
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = state => ({
    allergyTags: allergyTagsSelector(state)
})

const mapDispatchToProps = dispatch => ({
    loadClient: id => dispatch(loadClient(id)),
    update: (data,autoSubmit) => dispatch(updateClientMealPlan(data,autoSubmit)),
    dismissTooltip: tip => dispatch(dismissTooltip(tip))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(withTrainer(false)(ClientMealPlanSettings)))