import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';

export class GroceryItem extends RecordBase {
    static NAME = 'GroceryItem'
    static ASSOCS = {
        foodWeight: { type: 'belongsTo' },
        recipeMeals: { type: 'hasMany', hasAssocKey: 'recipeMealIds', tableName: 'recipeMeals' }
    }

    static submitValues(items) {
        return { foodIds: _.flatMap(items,item => item.foodId), foodMap: _.mapValues(_.keyBy(items, item => item.foodId), item => item.recipeMealIds) }
    }

    name() {
        return this.foodWeight.food.name;
    }

    humanReadableQty() {
        return this.foodWeight.humanReadableQty(this.amount,false,true);
    }

    food() {
        return this.foodWeight.food;
    }

    grams() {
        return this.amount*this.foodWeight.grams;
    }

    recipesForPopup(recipesTable) {
        const recipes = {};
        let map = {};
        this.recipeMeals.forEach(rm => {
            map[rm.recipeId] = map[rm.recipeId] || 0;
            map[rm.recipeId] = map[rm.recipeId] + rm.servings;
            recipes[rm.recipeId] = recipesTable[rm.recipeId];
        });

        return Object.values(_.mapValues(map,(servings,recipeId) => ({ recipe: recipes[recipeId], servings })))
    }

    rawValues() {
        return _.pick(this,['foodId','foodWeightId','recipeMealIds','amount','groupName'])
    }

    submitValues() {
        return _.pick(this,['foodId','recipeMealIds'])
    }

    mergedAmount(other) {
        if(other.foodWeightId === this.foodWeightId) {
            return other.amount + this.amount;
        }
        const foodWeight = _.find(this.food().foodWeights,foodWeight => foodWeight.id === other.foodWeightId);
        if(foodWeight) {
            return (this.grams() + foodWeight.grams*other.amount)/this.foodWeight.grams;
        }
        return this.amount;
    }

}

registerInflection('groceryItem','groceryItems',GroceryItem);