import React, {Component} from 'react';
import Page from'components/Page';
import { rootPath, workoutSetupBase, emailPaywallMatches, homePath } from 'config/paths';
import * as transitions from 'assets/transitions';
import FlowForm, { FlowPageContent, FlowQuestionContainer, FlowQuestion, AutoAdvanceButton, NextButton, SmallImageCardOption } from 'components/FlowForm';
import { formikHandlerCreator } from 'lib/utilities';
import Button from 'components/Button';
import { RCButtonWithErrors, Checkbox, CheckboxGroup, SingleOptionSlider, WeekdayButtonSelect, ToggleButton } from 'components/Form';
import { workoutFlowSelector, bannableProgSelector, userRecordSelector } from 'redux/selectors';
import { advanceWorkoutSetup, loadBannableProgs, assignNewRoutine } from 'redux/actions';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { Redirect } from "components/Routing";
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import moment from 'moment';
import * as _ from 'lib/utilities';
import aestheticsF from 'assets/img/AestheticsF.png';
import strengthF from 'assets/img/StrengthF.png';
import aestheticsM from 'assets/img/AestheticsM.png';
import strengthM from 'assets/img/StrengthM.png';
import classnames from 'classnames';
import { SelectInput } from 'components/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { dateFormat } from 'config/settings';
import { WorkoutRoutine, User } from 'lib/classes';
import { workoutPlanSlideOutPaths } from 'partials/MainLayout';
import { RoutineSelectQuestion, WorkoutTimes, validateTrainingDays } from 'partials/OnboardingComponents';

const validateForm = (t,page,values) => {
    if(page === 1) {
        if(_.isBlank(values.gymType)) {
            return { gymType: t('Required') }
        }
    } else if (page === 2) {
        if(_.isBlank(values.needsCardio)) {
            return { needsCardio: t('Required') }
        }
    } else if (page === 3) {
        if(_.isBlank(values.routineFocus) && values.gymType === 2) {
            return { routineFocus: t('Required') }
        }
    } else if (page === 4) {
        if(_.isBlank(values.liftingExperience)) {
            return { liftingExperience: t('Required') }
        }
    } else if(page === 5) {
        return validateTrainingDays(values,t);
    } else if (page === 6) {
        if(_.isBlank(values.workoutTime)) {
            return { workoutTime: t('Required') }
        }
    } else if (page === 8) {
        if(_.isBlank(values.routineStart)) {
            return { routineStart: t('Required') }
        }
    }
    return {}
}

const getNextPage = (page,values) => {
    let newPage = page+1;
    if(newPage === 3 && values.gymType !== 2) {
        newPage += 1;
    }
    return newPage;
}

const valueFilter = values => _.pick(values,'newRoutine','routineStart','workoutRemind','workoutRemindPm')

const SubmissionLC = loadingContainer({
    'DEFAULT': ({ t }) => (<Button color="primary" rounded tag="button" type="submit" id="workout-setup-submit-btn">
            <FontAwesomeIcon icon='arrow-right' /><span>{t('Start Routine')}</span>
        </Button>),
    'SUCCESS': ({ user }) => (<Redirect to={user.afterWorkoutPlanSetupPath()} push />)
})

class StartDateQuestion extends React.Component {

    render() {
        const { flowProps, t, submitState, user } = this.props;
        const { setFieldValue, handleChange, handleBlur, values: { routineStart, workoutRemind, workoutRemindPm }} = flowProps;

        return (
            <React.Fragment>
                <FlowQuestion text={t("When do you want to start your routine?")} />
                <SelectInput 
                    name="routineStart" 
                    collection={_.nextWeekCollection(dateFormat)} 
                    browserDefault 
                    className="select-signup" 
                    value={routineStart} 
                    onChange={handleChange} 
                    onBlur={handleBlur} 
                />
                <FlowQuestion text={`${t("Workout reminder")}?`} />
                <div className="valign-wrapper cntr">
                    <SelectInput 
                        name="workoutRemind" 
                        collection={User.workoutRemindCol(t,true)} 
                        browserDefault 
                        className="select-signup mr5 ml0" 
                        value={workoutRemind} 
                        onChange={handleChange} 
                        onBlur={handleBlur} 
                    />
                    <ToggleButton
                        className="btn-no-shadow"
                        options={{
                            [`${t('AM')}`]: false, 
                            [`${t('PM')}`]: true}} 
                            value={workoutRemindPm} 
                            setValue={pref => setFieldValue('workoutRemindPm',pref)
                        } 
                    />
                </div>
                <div className="mt35">
                    <SubmissionLC t={t} loadState={submitState} user={user} />
                </div>
            </React.Fragment>
        )
    }
}

const WorkoutTimesQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FlowQuestion text={t("How much time do you have to workout?")} />
            <div className="text-center">
                <WorkoutTimes flowProps={flowProps} t={t} />
            </div>
        </React.Fragment>
    )
}
    

const TrainingDaysQuestion = ({ flowProps, t }) => {
    const { setFieldValue, advance, values, isValid, setTouched, setFieldTouched, validateForm, errors: { trainingDays: error }, touched: { trainingDays: touched } } = flowProps;
    const subtitleCnames = classnames("flow-question-subtitle",{"red-text": error && touched});
    return (
        <React.Fragment>
            <FlowQuestion text={t("Which days do you want to workout?")} />
            <div className={subtitleCnames}>{t("Pick at least 2 non-consecutive days")}</div>
            <WeekdayButtonSelect 
                startDay={0} 
                name="trainingDays"
                values={values} 
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                dayNames={moment.weekdaysShort()} 
            />
            <NextButton 
                className="btn-wide mt65"
                label={t('Next')} 
                advance={advance} 
                validateForm={validateForm} 
                values={values} 
                isValid={isValid}
                setTouched={setTouched} 
                id='next-btn'
            />
        </React.Fragment>
    )
}

class ExperienceQuestion extends React.Component {

    render() {
        const {flowProps: { advance, values, validateForm, setTouched, setFieldValue, values: { liftingExperience } }, t, user } = this.props;
        const tKeyAdv = `experience level weights advanced ${user.female() ? 'female' : 'male'}`;
        const tKeyInt = `experience level weights intermediate ${user.female() ? 'female' : 'male'}`;
        const strstds = user.strengthStandards(t);

        const options = {
            [t('Beginner')]: { value: 0, subtitle: `${t('beginner tip')} ${t()}` }, 
            [t('Intermediate')]: { value: 1, subtitle: `${t('intermediate tip')} ${user.gymType === 2 ? t(tKeyInt,strstds.intermediate) : ''}` }, 
            [t('Advanced')]: { value: 2, subtitle: `${t('advanced tip')} ${user.gymType === 2 ? t(tKeyAdv,strstds.advanced) : ''}` }
        };

        return (
            <React.Fragment>
                <FlowQuestion text={t("What is your level of training experience?")} className="mt0 pt0 mb20" />
                <SingleOptionSlider className="shorter" options={options} value={liftingExperience} setValue={(val) => setFieldValue('liftingExperience',val) } />
                <NextButton 
                    className="btn-wide mt95"
                    label={t('Next')} 
                    advance={advance} 
                    validateForm={validateForm} 
                    values={values} 
                    isValid={true}
                    setTouched={setTouched} 
                    id='next-btn'
                />
            </React.Fragment>
        )
    }
}

const FocusQuestion = ({ flowProps, t, user } ) => {
    return (
        <React.Fragment>
            <FlowQuestion text={t("What is your primary focus?")} tooltip={t("routine focus tip")} />
            <div className="no-wrap">
                <AutoAdvanceButton {...flowProps} attr="routineFocus" value="0" render={({ onClick }) => (
                    <SmallImageCardOption id="aesthetics-btn" src={user.female() ? aestheticsF : aestheticsM} alt="" onClick={onClick} title={t("Aesthetics")}  />
                )} />
                <AutoAdvanceButton {...flowProps} attr="routineFocus" value="1" render={({ onClick }) => (
                    <SmallImageCardOption id="strength-btn" src={user.female() ? strengthF : strengthM} alt="" onClick={onClick} title={t("Strength")}  />
                )} />
            </div>
        </React.Fragment>
    )
}

const CardioQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FlowQuestion text={t("Do you want to include cardio in your routine?")} />
            <div className="no-wrap">
                <AutoAdvanceButton {...flowProps} attr="needsCardio" value="1" render={({ onClick }) => (
                    <Button color="primary" rounded onClick={onClick} className="btn-wide" id="yes-cardio-btn"><span>{t("Yes")}</span></Button>
                )} />
                <AutoAdvanceButton {...flowProps} attr="needsCardio" value="0" render={({ onClick }) => (
                    <Button color="primary" rounded onClick={onClick} className="btn-wide ml5" id="no-cardio-btn"><span>{t("No")}</span></Button>
                )} />
            </div>
        </React.Fragment>
    )
}

const BannableProgChecks = ({ bannableProgs, flowProps, t }) => (
    <React.Fragment>
        <p>
            {t("Do you have a place to do?")}
        </p>
        <CheckboxGroup 
            values={flowProps.values} 
            errors={flowProps.errors} 
            touched={flowProps.touched} 
            onChange={flowProps.setFieldValue} 
            onBlur={flowProps.setFieldTouched} 
            name="allowedExerciseProgressionIds" 
        >
            {bannableProgs.map((prog) => (
                <Checkbox className="bannable-prog-check" key={prog.id} inputProps={{ value: prog.id} } label={_.capitalize(prog.pluralName)} filled tooltip={prog.equipmentDescription} />
            ))}
        </CheckboxGroup>
    </React.Fragment>
)

const BannableProgsLC = loadingContainer({
    'SUCCESS': BannableProgChecks
})

const AllowedProgsUnconnected = ({ flowProps, loadBannableProgs, bannableProgs, t }) => {
    return (
        <LoadingContainerParent 
            load={loadBannableProgs}
            preloaded={() => !!bannableProgs }
            component={BannableProgsLC}
            bannableProgs={bannableProgs}
            flowProps={flowProps}
            t={t}
        />
    )
}

const mapStateToBannableProgs = state => ({
    bannableProgs: bannableProgSelector(state)
})

const AllowedProgs = connect(mapStateToBannableProgs)(AllowedProgsUnconnected);

class EquipmentQuestion extends React.Component {

    render() {
        const { flowProps, loadBannableProgs, t} = this.props;
        const { advance, values, setTouched, validateForm, isValid } = flowProps;
        const allProps = _.pick(flowProps,[ 'values', 'touched', 'handleChange','setFieldValue', 'handleBlur']);
    
        return (
            <React.Fragment>
                <FlowQuestion text={t("What kind of workouts would you prefer?")} />
                <div className="radio-btn-container">
                    <RCButtonWithErrors id="weighted-radio" name="gymType" label={t("Weighted - barbell focused")} inProps={{ type: 'radio', value: WorkoutRoutine.GYM }} {...allProps} />
                    <RCButtonWithErrors id="no-equipment-radio" name="gymType" label={t("Bodyweight - no equipment")} inProps={{ type: 'radio', value: WorkoutRoutine.BW }} {...allProps} />
                    <RCButtonWithErrors id="bodyweight-radio" name="gymType" label={t("Bodyweight - minimal equipment")} inProps={{ type: 'radio', value: WorkoutRoutine.GYM_BW }} {...allProps} errors={flowProps.errors} />
                    {values.gymType === 1 && (
                        <div className="sub-fields">
                            <AllowedProgs loadBannableProgs={loadBannableProgs} flowProps={flowProps} t={t} />
                        </div>
                    )}
                </div>
                <div>
                    <NextButton 
                        className="btn-wide mt65"
                        label={t('Next')} 
                        advance={advance} 
                        validateForm={validateForm} 
                        values={values} 
                        isValid={isValid}
                        setTouched={setTouched} 
                        id='next-btn'
                    />
                </div>
            </React.Fragment>
        )
    }
}

const flowTransitionMap = {
    rules: [
        [[workoutPlanSlideOutPaths,rootPath],transitions.slideOut],
        [[...emailPaywallMatches],transitions.slideOver]
    ]
};

class WorkoutSetupFlowPage extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(flowTransitionMap);
        this.props.loadBannableProgs().catch(() => console.log("Failed to load bannable progressions."));
        this.exitPath = homePath();
        this.state = { submitState: 'DEFAULT' };
    }

    setSubmitState = (status) => {
        this.setState({ submitState: status });
    }

    render() {
        const { initialValues, user, advanceAction, loadBannableProgs, submit, t, scrollRef } = this.props;

        return (
        <Page ref={scrollRef} white>
            <FlowForm 
                initialValues={initialValues}
                validate={validateForm.bind(this,t)}
                validateOnMount={true}
                advanceCallback={advanceAction}
                onSubmit={formikHandlerCreator(this,this.setSubmitState,submit,valueFilter)}
                basePath={workoutSetupBase}
                exitPath={this.exitPath}
                progress
                nextPage={getNextPage}
            >
                    <FlowPageContent key='equipment' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <EquipmentQuestion flowProps={flowProps} t={t} loadBannableProgs={loadBannableProgs} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='cardio' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <CardioQuestion flowProps={flowProps} t={t} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='focus' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <FocusQuestion flowProps={flowProps} t={t} user={user} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='experience' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer style={{width: '100%'}}>
                                    <ExperienceQuestion flowProps={flowProps} t={t} user={user} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='trainingDays' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer style={{width: '100%'}}>
                                    <TrainingDaysQuestion flowProps={flowProps} t={t} user={user} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='workoutTime' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer style={{width: '100%'}}>
                                    <WorkoutTimesQuestion 
                                        flowProps={flowProps} 
                                        t={t} 
                                        user={user} 
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='routineSelect' block render={(flowProps) => {
                            return (
                                <RoutineSelectQuestion flowProps={flowProps} t={t} />
                            )
                    }} />
                    <FlowPageContent key='startDate' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer style={{width: '100%'}}>
                                    <StartDateQuestion flowProps={flowProps} t={t} user={user} submitState={this.state.submitState} />
                                </FlowQuestionContainer>
                            )
                    }} />
            </FlowForm>
        </Page>
        );
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }
}

const mapStateToProps = (state) => ({
    initialValues: workoutFlowSelector(state),
    user: userRecordSelector(state)
})

const mapDispatchToProps = dispatch =>({
    advanceAction: (data) => dispatch(advanceWorkoutSetup(data)),
    loadBannableProgs: () => dispatch(loadBannableProgs()),
    submit: data => dispatch(assignNewRoutine(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(WorkoutSetupFlowPage));
