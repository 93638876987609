import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card';
import React from 'react';
import * as _ from 'lib/utilities';
import classnames from 'classnames';
import { User } from 'lib/classes';
import { getDatasetInfoFor } from './EstimatedProgressChart';

const deem = (highlight,type) => (!_.isBlank(highlight) && highlight !== type);

const PlanStep = ({ icon, title, subtitle, deemphasize }) => {

    return (
        <div className={classnames("plan-step", { deemphasize: deemphasize })}>
            <div className="icon">
                <FontAwesomeIcon icon={icon} />
            </div>
            <div className="title-cont">
                <div className="title">
                    {title}
                </div>
                {!_.isBlank(subtitle) && (
                    <div className="subtitle">{subtitle}</div>
                )}
            </div>
        </div>
    )
}

const WorkoutPlanPersonalization = ({ flowProps, t, user, highlight }) => {
    const { values: { focusAreas } } = flowProps;
    const ffAreas = (_.isBlank(focusAreas) || _.noBlanks(focusAreas).length === 0) ? ['Full Body'] : _.noBlanks(focusAreas);
    let title, subtitle;

    if(user && user.assignedRoutine) {
        title = user.assignedRoutine.name;
        subtitle = `${_.listToSentence(ffAreas)} ${t('Focus')}, ${user.assignedRoutine.frequencyStr}x/${t('week')}, ${user.assignedRoutine.estTimeStr} ${t('mins')}`;
    } else {
        title = t('Workout Plan Personalization');
        subtitle = null;
    }


    return (
        <PlanStep icon={['far','dumbbell']} title={title} subtitle={subtitle} deemphasize={deem(highlight,'workoutPlan')}/>
    )
}

const TrainingStrategy = ({ flowProps, t, highlight }) => {
    const { values: { liftingExperience, anyStrengthTraining, trainingHabits, noRoutine, needsCardio, dailyStepGoal } } = flowProps;
    let title, subtitle;

    if(noRoutine) {
        if(!_.isBlank(dailyStepGoal)) {
            title = `${t('Profile')}: ${t('Step Counter')}`;
            subtitle = t("Walk X steps per day", { steps: dailyStepGoal })
        } else {
            title = `${t('Profile')}: ${t('Nutrition Focused')}`;
            subtitle = t('No workout plan');
        }
    } else if(!_.isBlank(liftingExperience)) {
        let numericExp = 0;

        if(anyStrengthTraining) {
            if(liftingExperience === 2) {
                numericExp = 3;
            } else if(liftingExperience === 1) {
                numericExp = 2;
            } else if(trainingHabits === 2) {
                numericExp = 1;
            }
        }
        title = `${t('Profile')}: ${t(`tprofile${numericExp}`)}`;
        subtitle = needsCardio ? t('yescardio') : t('nocardio');
    } else {
        title = t('Training Profile');
        subtitle = null;
    }

    return (
        <PlanStep icon={['far','id-card']} title={title} subtitle={subtitle} deemphasize={deem(highlight,'training')}/>
    )
}

const MealPlanPersonalization = ({ user, flowProps, t, highlight }) => {
    const mpGenerated = user && user.firstMealPlanIsGenerated();
    let title, subtitle;

    if(mpGenerated) {
        title = t('ympir');
        subtitle = t('ympirsub',{ date: user.mealPlanStartMom().format('ddd MMM Do')});
    } else {
        title = t('Meal Plan Personalization');
        subtitle = null;
    }

    return (
        <PlanStep icon={['far','utensils-alt']} title={title} subtitle={subtitle} deemphasize={deem(highlight,'mealPlan')}/>
    )
}

const NutritionStrategy = ({ user, flowProps, t, highlight }) => {
    const initialized = user && user.macrosInitialized();
    let title, subtitle;

    if(initialized) {
        const macroParams = user.baseActiveMacroParams();
        const targetCals = user.baseTargetCalsReadable();
        const macroSummaryArr = macroParams.summary(t);
        const topLevelMacros = macroSummaryArr.slice(0,Math.max(macroSummaryArr.length-1,1));

        title = `${t('Targets')}: ${targetCals}${t('cals')}, ${topLevelMacros.join(', ')}`;
        subtitle = macroSummaryArr.length > 1 ? macroSummaryArr[macroSummaryArr.length-1] : null;
    } else {
        title = t('Macronutrient Targets');
        subtitle = null;
    }

    return (
        <PlanStep icon={['far','chart-pie']} title={title} subtitle={subtitle} deemphasize={deem(highlight,'nutrition')}/>
    )
}

const BodyAnalysis = ({ user, flowProps, t, highlight, reachGoalPace }) => {
    const { unitPreference, weightGoal, simpleWeight, simpleGoalWeight, curBodyFat, goalBodyFat, planPace } = flowProps.values;
    let title, subtitle;

    if(_.isBlank(user) || _.some([unitPreference, weightGoal, simpleWeight, simpleGoalWeight, curBodyFat, goalBodyFat, planPace, reachGoalPace ], val => _.isBlank(val))) {
        title = ('Metabolic Analysis');
        subtitle = null;
    } else {
        const { meta } = getDatasetInfoFor({ ...flowProps.values, t, pace: reachGoalPace || 'slow' });
        const { endWeight, endDate } = meta;
        title = t('reachgoalby',{ goal: endWeight, date: endDate.format('MMM Do')});
        subtitle = t('metanaltdeesub', { calories: Math.round(user.dailyTdee) });
    }

    return (
        <PlanStep icon={['far','universal-access']} title={title} subtitle={subtitle} deemphasize={deem(highlight,'body')}/>
    )
}

const Goals = ({ flowProps, t, highlight, forceBlank }) => {
    const { values: { gender, weightGoal, simpleGoalWeight, simpleWeight, unitPreference } } = flowProps;
    let title, subtitle;


    if(_.some([gender, weightGoal, simpleGoalWeight, simpleWeight, unitPreference],val => _.isBlank(val)) || forceBlank === 'goals') {
        title = t('Goals & Focus');
        subtitle = null;
    } else {
        const units = User.weightSuffix(unitPreference);
        const weightDiff = simpleGoalWeight - simpleWeight;
        const wtStr = `${Math.abs(weightDiff)} ${units}`;
        subtitle = t('Weigh weight',{ weight: `${simpleGoalWeight} ${units}` });
    
        if(weightGoal === 0) {
            title = t('Get Toned');
        } else if(weightGoal === 1) {
            if(weightDiff >= 5) {
                if(gender) {
                    title = t('Gain X', { weight: wtStr })
                } else {
                    title = t('Build X of muscle', { weight: wtStr })
                }
            } else {
                title = t('Build Muscle');
            }
        } else {
            title = t('Lose X of fat', { weight: wtStr })
        }
    }

    return (
        <PlanStep icon={['far','bullseye-arrow']} title={title} subtitle={subtitle} deemphasize={deem(highlight,'goals')}/>
    )
}

const SummaryCard = ({ flowProps, user, t, highlight, reachGoalPace, forceBlank }) => {
    const { values: { noRoutine } } = flowProps;

    return (
        <Card className="flow-sum-card mt20">
            <div className="sum-card-head">
                {t('Your Plan')}
            </div>
            <div className="sum-card-bod">
                <Goals flowProps={flowProps} t={t} highlight={highlight} forceBlank={forceBlank} />
                <BodyAnalysis flowProps={flowProps} t={t} highlight={highlight} reachGoalPace={reachGoalPace} user={user} />
                <NutritionStrategy flowProps={flowProps} t={t} highlight={highlight} user={user} />
                <MealPlanPersonalization flowProps={flowProps} t={t} highlight={highlight} user={user} />
                <TrainingStrategy flowProps={flowProps} t={t} highlight={highlight}/>
                {!noRoutine && (<WorkoutPlanPersonalization flowProps={flowProps} t={t} highlight={highlight} user={user} />)}
            </div>
        </Card>
    )

}

export default SummaryCard;