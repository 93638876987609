import React from 'react';
import { connect } from 'react-redux';
import { userWithRoutineSelector, lastLoadedWorkoutDaySel, getCachedWodSelector, getWodSelector, warmupsNeedLoadingSel } from 'redux/selectors';
import { cleanupRoutine, loadUser, loadWorkout, logAllWorkouts } from 'redux/actions';
import moment from 'moment';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import { dateFormat } from 'config/settings';

function workoutNeedsFullLoad(workout) {
    if(!workout) {
        return false;
    } else {
        return !workout.isFullyLoaded();
    }
}

//showBefore = all, regen, fullLoad, dirty, null
const withWorkout = (showBefore,type="padded",ignoreDirty,attemptSync) => (Component) => {

    const WorkoutLOC = loadingContainer({ 
        "SUCCESS": Component
    }, { type })

    class WorkoutState extends React.Component {

        constructor(props) {
            super(props);
            this.preloaded = this.preloaded.bind(this);
            this.load = this.load.bind(this);
        }

        render() {
            const { loadWorkout, load, firstTry, responseData, workout, skipWorkoutLoad, warmupsNeedLoading, ...rest } = this.props;

            if(skipWorkoutLoad) {
                return (
                    <Component {...rest} workout={workout} />
                )
            } else {
                return (
                    <LoadingContainerParent
                        {...rest} 
                        component={WorkoutLOC}
                        load={this.load}
                        preloaded={this.preloaded}
                        alwaysLoad={this.shouldLoad()}
                        workout={workout}
                        loadingContainerId={`workout-full-loader-${workout && workout.id}`}
                        key={workout && `${workout.id}-${this.shouldLoad()}`}
                    />
                )
            }
        }

        shouldLoad() {
            return this.workoutNeedsFullLoad();
        }

        preloaded() {
            return !this.needsWorkoutLoadingSpinner();
        }

        load() {
            const { workout, loadWorkout, warmupsNeedLoading } = this.props;
            if(workout) {
                return loadWorkout(workout.id,warmupsNeedLoading);
            }
        }

        needsWorkoutLoadingSpinner() {
            const { showBefore } = this.props;
            return (this.workoutNeedsFullLoad() && !['fullLoad','regen'].includes(showBefore))
        }

        workoutNeedsFullLoad() {
            return workoutNeedsFullLoad(this.props.workout);
        }
    }

    const RoutineLOC = loadingContainer({
        "SUCCESS": WorkoutState
    }, { type });

    class RoutineState extends React.Component {

        constructor(props) {
            super(props);
            this.preloaded = this.preloaded.bind(this);
            if(this.needsStandaloneSync()) {
                const { logAllWorkouts } = props;
                logAllWorkouts();
            }
        }

        render() {
            const { cleanupRoutine, loadUser, date, showBefore, user, warmupsNeedLoading, ...rest } = this.props;

            return (
                <LoadingContainerParent 
                    {...rest}
                    user={user}
                    component={RoutineLOC}
                    load={cleanupRoutine.bind(this,date,warmupsNeedLoading)}
                    preloaded={this.preloaded}
                    loadingContainerId="routine-cleanup-loader"
                    date={date}
                    alwaysLoad={this.alwaysLoad()}
                    showBefore={showBefore}
                    skipWorkoutLoad={this.alwaysLoad()}
                    warmupsNeedLoading={warmupsNeedLoading}
                    key={`${date.format(dateFormat)}-${this.alwaysLoad()}`}
                />
            )
        }

        preloaded() {
            return !this.needsLoadingSpinner();
        }

        alwaysLoad() {
            return (this.shouldDoRegen() || this.shouldCleanupDirty());
        }

        needsStandaloneSync() {
            return attemptSync && !this.alwaysLoad();
        }

        needsLoadingSpinner() {
            
            if(this.needsRegenSpinner() || this.needsDirtySpinner() || this.needsWorkoutLoadingSpinner()) {
                return true;
            }
            return false;
        }

        needsRegenSpinner() {
            const { showBefore } = this.props;
            return (this.shouldDoRegen() && showBefore !== 'regen');
        }

        needsDirtySpinner() {
            const { showBefore } = this.props;
            return (this.shouldCleanupDirty() && !showBefore);
        }

        shouldDoRegen() {
            const { user } = this.props;
            return (user.routineNeedsRegen && !this.ignoreThisDate())
        }

        shouldCleanupDirty() {
            const { user, date } = this.props;
            if(user.routineDirtyDate && !(ignoreDirty || this.ignoreThisDate())) {
                const dirtyDate = moment(user.routineDirtyDate);
                return date.isAfter(dirtyDate,'day');
            } else {
                return false;
            }
        }

        needsWorkoutLoadingSpinner() {
            const { showBefore } = this.props;
            const skipWorkoutLoad = this.alwaysLoad();
            if(skipWorkoutLoad) {
                return workoutNeedsFullLoad(this.props.workout) && !['fullLoad','regen'].includes(showBefore);
            } else {
                return false;
            }
        }

        ignoreThisDate() {
            const { user: { ignoreRoutineCleanupDate }, date } = this.props;
            return date.format(dateFormat) === ignoreRoutineCleanupDate;
        }
    }

    const UserLOC = loadingContainer({
        "SUCCESS": RoutineState
    }, { type });

    class UserState extends React.Component {
        constructor(props) {
            super(props);
            this.preloaded = this.preloaded.bind(this);
        }

        render() {
            const { loadUser, date, lastLoadedWorkoutDay, cachedWorkout, workout, ...rest } = this.props;
            const useCached = this.cachedWorkoutAvailable();

            return (
                <LoadingContainerParent 
                    component={UserLOC}
                    load={loadUser.bind(this,date)}
                    preloaded={this.preloaded}
                    alwaysLoad={this.dateNeedsLoading()}
                    date={moment(date.format(dateFormat))}
                    workout={useCached ? cachedWorkout : workout}
                    showBefore={useCached ? 'regen' : showBefore}
                    loadingContainerId="user-loader"
                    key={date.format(dateFormat)}
                    {...rest}
                />
            )
        }

        preloaded() {
            return !this.dateNeedsLoading() || this.cachedWorkoutAvailable();
        }

        dateNeedsLoading() {
            const { lastLoadedWorkoutDay, date } = this.props;
            const lastLoadedDate = moment(lastLoadedWorkoutDay);

            if(!lastLoadedWorkoutDay || lastLoadedDate.isBefore(date)) {
                return true;
            }
        }

        cachedWorkoutAvailable() {
            const { cachedWorkout } = this.props;
            return !!cachedWorkout;
        }
    }

    const mapStateToProps = (state,props) => {
        const wodSelector = getWodSelector(props.date.format(dateFormat));
        const cachedWodSelector = getCachedWodSelector(props.date.format(dateFormat));

        return {
            user: userWithRoutineSelector(state),
            lastLoadedWorkoutDay: lastLoadedWorkoutDaySel(state),
            workout: wodSelector(state),
            cachedWorkout: cachedWodSelector(state),
            warmupsNeedLoading: warmupsNeedLoadingSel(state)
        }
    }

    const mapDispatchToProps = dispatch => ({
        logAllWorkouts: () => dispatch(logAllWorkouts()),
        cleanupRoutine: (date,type,loadWarmup) => dispatch(cleanupRoutine(date,type,loadWarmup)),
        loadUser: (curDate) => dispatch(loadUser({ curDate })),
        loadWorkout: (id,loadWarmup) => dispatch(loadWorkout(id,loadWarmup))
    });

    return connect(mapStateToProps,mapDispatchToProps)(UserState)
}

export default withWorkout;