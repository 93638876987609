
import { RecordBase, registerInflection} from 'lib/record-base';
import * as _ from 'lib/utilities';

export class SideDish extends RecordBase {
    
    readableServings() {
        return this.recipe.readableServings(this.servings);
    }

    servingName(t) {
        return this.recipe.defaultedServingName(t);
    }

    showServings() {
        return !_.isBlank(this.recipe.servingName);
    }

    actualServings(readable) {
        if(_.isNumeric(readable)) {
            return Number(readable)/this.countPerServing();
        }
        return null;
    }

    macroHash() {
        return this.recipe.macroHash(this.servings);
    }

    loggedMacroHash() {
        return this.macroHash();
    }

    activeTime() {
        return Math.round(this.recipe.activeTime || 0);
    }

    countPerServing() {
        return this.recipe.countPerServing || 1;
    }

    dishOrder() {
        return this.isMainDish() ? [-1,-1] : (this.canBeMainDish() ? [0,this.recipeId] : [1,this.recipeId]);
    }

    isMainDish() {
        return this.mainDish;
    }

    key() {
        return this.recipeId;
    }

    cost() {
        return _.roundToF(this.recipe.costPerServing()*this.servings,0.01);
    }

    foodsArr() {
        return this.recipe.foodsArr || [];
    }

    rawObject() {
        return { recipeId: this.recipeId, servings: this.servings, mainDish: this.mainDish }
    }

    canBeMainDish() {
        return this.recipe.canBeMainDish();
    }

    servingsCopy(servings) {
        return new this.constructor({ ...this, servings })
    }

    fullIngredientList() {
        return this.recipe.ingredients.map(ingredient => ({ id: ingredient.id, qtyText: ingredient.humanReadableQty(this.recipe.ingredientMult(this.servings),false,true), nameText: ingredient.fullFoodName() }))
    }

    staticRecipeId() {
        return this.recipe ? this.recipe.staticId() : this.recipeId;
    }

    urlStub() {
        return this.recipe.urlStub(this.servings);
    }
}

export class RecipeMeal extends SideDish {
    static NAME = 'RecipeMeal'

    static ASSOCS = {
        recipe: { type: 'belongsTo' },
        userMeal: { type: 'belongsTo' }
    }

    key() {
        return this.id;
    }

    logTitle(mealType,t) {
        return this.recipe.name;
    }

    logKey() {
        return `rm${this.id}`;
    }

}

registerInflection('recipeMeal','recipeMeals',RecipeMeal);