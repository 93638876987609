import React from 'react';
import * as _ from 'lib/utilities';
import InfiniteScroll from 'components/InfiniteScroll';
import { trainersScrollMetaSel } from 'redux/selectors';
import { loadAllTrainers } from 'redux/actions';
import { connect } from 'react-redux';
import { SlightEmphasisIconNote } from 'components/Typography';
import { useTranslation } from 'react-i18next';

export const getClientScrollInfo = ({ trainer, trainersScrollMeta, context, filters, preSelectedIds, excludeIds }) => {

    const key = [ ..._.flatten(Object.entries(_.pickBy(filters,(val) => !_.isBlank(val))))].join('_');
    const fullContext = `${key}_${context}`;
    const resolvedMeta = (trainersScrollMeta && fullContext === trainersScrollMeta.context) ? trainersScrollMeta : null;
    const loadedPage = resolvedMeta ? resolvedMeta.page : 0;
    let ids = preSelectedIds ? [ ...preSelectedIds ] : [];

    if(resolvedMeta) {
        ids = _.uniq([ ...ids, ...resolvedMeta.ids ]);
    }

    if(excludeIds) {
        ids = _.difference(ids,excludeIds);
    }

    const trainers = trainer.trainersForFilters(filters,ids);

    return { trainers, key, resolvedMeta, fullContext, loadedPage };
}

const TrainerScroller = ({ trainer, filters, clientCounts, setClientCounts, load, trainersScrollMeta, context, preSelectedIds, excludeIds, render }) => {
    const { t } = useTranslation();
    const { trainers, key, resolvedMeta, fullContext, loadedPage } = getClientScrollInfo({ trainer, trainersScrollMeta, context, filters, preSelectedIds, excludeIds });

    return (
        <InfiniteScroll 
            key={key}
            load={page => load({ ...filters, page, context: fullContext })} 
            initialPage={1}
            loadedPage={loadedPage} 
            fullyLoaded={resolvedMeta && resolvedMeta.done} 
            records={trainers}
            alwaysReloadOnMount={true}
            perPage={35}
            successCallback={({ clientCounts: newClientCounts }) => {
                setClientCounts && setClientCounts({ ...clientCounts, ...newClientCounts });
            }}
            noResultsComp={<div className="mt10"><SlightEmphasisIconNote id="no-clients-note" text={t("no clients for filters", { type: t('team member')})} /></div>}
            render={({ maxIndex }) => render({ clientCounts, trainers: trainers.slice(0,maxIndex) })}
        />
    )
}

const mapStateToTeamPageProps = state => ({
    trainersScrollMeta: trainersScrollMetaSel('teamPage')(state)
})

const mapDispatchToTeamPageProps = dispatch => ({
    load: params => dispatch(loadAllTrainers('teamPage')(params)),
})

const mapStateToSelectProps = state => ({
    trainersScrollMeta: trainersScrollMetaSel('trainerSelect')(state)
})

const mapDispatchToSelectProps = dispatch => ({
    load: params => dispatch(loadAllTrainers('trainerSelect')(params)),
})

const TeamPageScroller = connect(mapStateToTeamPageProps,mapDispatchToTeamPageProps)(TrainerScroller);
const TrainerSelectScroller = connect(mapStateToSelectProps,mapDispatchToSelectProps)(TrainerScroller);

export { TeamPageScroller, TrainerSelectScroller }