import React, {Component, useState} from 'react';
import Page, { PageWithTransitions } from'components/Page';
import { rootPath, mealPlanSetupBase, startPath, mealPlanSignupBase, 
     generateNewWeekBaseFor, emailPaywallMatches, setupWeeklyPlanPath, homePath, generateNewWeekPathFor, generateNewWeekMatch, switchForGenerateMatch, clientMealPlanSettingsMatch, fullModalPathFor, modalPathFor, mealPlanPath, pdfSetupDonePath, nutritionDataInfoPath } from 'config/paths';
import FlowForm, { FlowNav, FlowChooseGoal, FlowPageContent, FlowQuestionContainer, FlowQuestion, NextButton, autoAdvanceSetValue } from 'components/FlowForm';
import { formikHandlerCreator } from 'lib/utilities';
import { User } from 'lib/classes';
import Button, { BottomButtons } from 'components/Button';
import { RCButtonWithErrors, CheckButtons, SelectButton, SmallButtonSelect, WeekdayButtonSelect } from 'components/Form';
import { userRecordSelector, mealPlanFlowSelector, allergyTagsSelector, recommendedCalsSelector, trainerRecordSelector, showMpExitButton } from 'redux/selectors';
import { initMacros, advanceMealPlanSetup, startMealPlan, clearInitialSignup, switchClient, finishMealPlanSetup } from 'redux/actions';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { Redirect } from "components/Routing";
import { Loader } from 'components/LoadingHOC';
import moment from 'moment';
import * as _ from 'lib/utilities';
import classnames from 'classnames';
import { ExclusionTags, ExtraExclusions, validateSelectedMeals, validateCustomMacros } from 'partials/UserFields';
import Connector from 'partials/WebsiteConnector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as transitions from 'assets/transitions';
import { SwitchFinished } from 'partials/ClientSwitcher';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { HideOnExit } from 'components/RouteTransitionMap';
import { resolvedHomePath, resolvedStartPath } from 'redux/helpers';
import { withRouter } from 'react-router-dom';
import { exportCurrentPDFSuffix } from 'partials/ExportPDFModal';
import { CustomMacrosQuestion, MacroRecommendationsPage, getSubmitValues } from 'partials/OnboardingComponents';
import InitialRecipePicker, { RecipePickWrapper, mealPlanQuotaModalSuffix } from 'partials/RecipePickFlowPage';

const mealPlanSetupTransitionMap = {
    rules: [
        [[generateNewWeekMatch,switchForGenerateMatch],transitions.none],
        [[...mealPlanSlideOutPaths,clientMealPlanSettingsMatch,rootPath,startPath],transitions.slideOut],
        [[...emailPaywallMatches,pdfSetupDonePath,nutritionDataInfoPath],transitions.slideOver]
    ]
};

const validateForm = (user,t,page,values) => {
    if(page === 1) {
        if(_.isBlank(values.dietType)) {
            return { dietType: t('Required') }
        }
    } else if(page === 2) {
        let errs = {};
        if(_.isBlank(values.budget)) {
            errs.budget = t('Required');
        }
        if(_.isBlank(values.complexityPreference)) {
            errs.complexityPreference = t('Required');
        }
        if(_.isBlank(values.weeklyVariety)) {
            errs.weeklyVariety = t('Required');
        }
        return errs;
    } else if (page === 4) {
        return validateSelectedMeals(t)(values);
    } else if (page === 5) {
        if(!user.macrosInitialized()) {
            return { macros: 'macros must be initialized'}
        }
    } else if (page === 6) {
        return validateCustomMacros(t,user)(values);
    } else if (page === 7) {
        if(_.isBlank(values.mealPlanWeekday)) {
            return { mealPlanWeekday: t('Required') };
        }
    }
    return {}
}

const getNextPage = user => (page,values) => {
    let newPage = page+1;
    if(newPage === 6 && !_.get(values,'dailyNutritionProfiles.0.overrideCalories')) {
        newPage += 1;
    } else if(newPage === 8 && !user.showRecipePickStep()) {
        newPage += 1;
    }
    return newPage;
}

const valueFilter = values => values;

const FinishSetupRedirector = ({ user }) => (<Redirect to={user.isPdfClient() ? pdfSetupDonePath : mealPlanPath} />)

const FinishSetupLoader = ({ finishSetup, user, flowProps }) => {
    const { values } = flowProps;
    const submitValues = getSubmitValues(user,values);

    return (
        <Loader 
            type="padded"
            load={finishSetup.bind(null,submitValues)}
            preloaded={() => false}
            successComponent={FinishSetupRedirector}
            user={user}
        />
    )
}

class StartMealPlanSuccess extends React.Component {

    componentDidMount() {
        const { toExport, history, user, forNewWeek, clientId, location: { pathname }, responseData: { clientMpQuotaHit } } = this.props;
        if(clientMpQuotaHit) {
            const path = modalPathFor(mealPlanQuotaModalSuffix,pathname);
            setTimeout(() => history.push(path),30);
        } else {
            const basePath = user.afterMealPlanSetupPath(forNewWeek,clientId);
            if(toExport) {
                setTimeout(() => history.push(fullModalPathFor(exportCurrentPDFSuffix,basePath)),30);
            }
        }
    }

    render() {
        const { user, forNewWeek, clientId, responseData: { clientMpQuotaHit } } = this.props;

        if(clientMpQuotaHit) { 
            return '';
        }

        return (
            <Redirect to={user.afterMealPlanSetupPath(forNewWeek,clientId)} />
        )
    }
}

StartMealPlanSuccess = withRouter(StartMealPlanSuccess)

const StartMealPlanButtons = ({ user, load, setToExport, forNewWeek, clientId, t }) => {
    const pdfTrainerFlow = user.isPdfClient() && !_.isBlank(clientId);
    const pdfClientSetup = user.isPdfClient() && _.isBlank(clientId);

    const finalLoad = (toExport) => {
        setToExport(toExport);
        return load();
    }

    return (<React.Fragment>
        <Button color="primary" rounded onClick={() => finalLoad(false)} className="btn-wide" id="next-btn">
            <FontAwesomeIcon icon={pdfClientSetup ? "check" : "random"} />
            <span>{pdfClientSetup ? t('Done') : (forNewWeek ? t('Generate') : t("Start Plan"))}</span>
        </Button>
        {pdfTrainerFlow && (<Button color="primary" rounded outlined onClick={() => finalLoad(true)} className="btn-wide shadow ml5" id="export-btn">
            <FontAwesomeIcon icon={['far','file-export']} />
            <span>{t('Export PDF')}</span>
        </Button>)}
    </React.Fragment>)

}

const StartMealPlanButtonsWrapper = ({ user, t, forNewWeek, startMealPlan, initialRecipeIds }) => {
    const [toExport,setToExport] = useState(false);

    return (
        <MealPlanStandaloneContext.Consumer>
            {clientId => (
                <Loader 
                    defaultComponent={StartMealPlanButtons}
                    successComponent={StartMealPlanSuccess}
                    clientId={clientId}
                    toExport={toExport}
                    setToExport={setToExport}
                    type="loaderOnly"
                    skipAutoLoad
                    preloaded={() => false}
                    user={user}
                    forNewWeek={forNewWeek}
                    t={t}
                    load={startMealPlan.bind(null,initialRecipeIds,forNewWeek ? user.mealPlanStartDateFor(forNewWeek) : null,forNewWeek)}

                />
            )}
        </MealPlanStandaloneContext.Consumer>
        
    )
}

const RecipePickerPage = ({ startMealPlan, ...props }) => {

    return (
        <InitialRecipePicker 
            {...props}
            continueBtnRender={({ user, t, initialRecipeIds, forNewWeek }) => {

                return  (
                    <BottomButtons>
                        <StartMealPlanButtonsWrapper 
                            startMealPlan={startMealPlan}
                            user={user}
                            t={t}
                            initialRecipeIds={initialRecipeIds}
                            forNewWeek={forNewWeek}
                        />
                    </BottomButtons>
                )
            }}
        />
    )
}

class StartDateQuestion extends React.Component {
    constructor(props) {
        super(props);
        this.advanceTimeout = null;
    }

    advanceToRecipes = (values) => {
        clearTimeout(this.advanceTimeout);
        this.advanceTimeout = setTimeout(() => this.props.flowProps.advance(values), 1);
    }

    render() {
        const { flowProps, t } = this.props;
        const { values, setFieldTouched, setFieldValue } = flowProps;
        const newAdvance = this.advanceToRecipes;

        return (
            <React.Fragment>
                <FlowQuestion text={t("Start meal plan each week on")} />
                <WeekdayButtonSelect 
                    startDay={0} 
                    name="mealPlanWeekday"
                    values={values} 
                    setFieldValue={autoAdvanceSetValue({ setFieldValue, values, advance: newAdvance })}
                    setFieldTouched={setFieldTouched}
                    dayNames={moment.weekdaysShort()}
                    single
                />
            </React.Fragment>
        );
    }
}

const MealTypesQuestion = ({ flowProps, t }) => {
    const { isValid, values, advance, validateForm, setTouched, errors: { selectedMealTypes: error }, touched: { selectedMealTypes: touched } } = flowProps;
    const subtitleCnames = classnames("flow-question-subtitle",{"red-text": error && touched});

    return (
        <React.Fragment>
            <FlowQuestion text={t("Which meals do you want to include in your plan?")} />
            <div className={subtitleCnames}>{t("Pick at least",{count: 2})}</div>
            <div>
                <CheckButtons {...flowProps} name="selectedMealTypes" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {User.MEAL_TYPES.map(([mt,label]) => (
                                <SelectButton id={`select-${mt}-btn`} key={mt} toggleHandler={toggleHandler} value={mt} values={values}><span>{t(label)}</span></SelectButton>
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <NextButton 
                className="btn-wide mt65"
                label={t('Next')} 
                advance={advance} 
                validateForm={validateForm} 
                values={values} 
                isValid={isValid}
                setTouched={setTouched} 
                id='next-btn'
            />
        </React.Fragment>
    )
}

const ExclusionsQuestion = ({ flowProps,allergyTags, t }) => {
    const { values, advance, validateForm, setTouched } = flowProps;

    return (
        <React.Fragment>
            <FlowQuestion text={t('Do you have any food allergies?')} />
            <ExclusionTags allergyTags={allergyTags} {...flowProps} />
            <FlowQuestion text={t('Any keywords to exclude?')} tooltip={t("keyword exclusion tooltip")} />
            <ExtraExclusions t={t} values={flowProps.values} setFieldValue={flowProps.setFieldValue} />
            <NextButton 
                className="btn-wide mt25"
                label={t('Next')} 
                advance={advance} 
                validateForm={validateForm} 
                values={values} 
                isValid={true}
                setTouched={setTouched} 
                id='next-btn'
            />
        </React.Fragment>
    )
}

class BudgetVarietyComplexityQuestion extends React.Component {

    render() {
        const { flowProps,t } = this.props;
        const { setFieldValue, setFieldTouched, advance, validateForm, values, isValid, setTouched } = flowProps;
        const labelMap = [ 
            [t("What's your grocery budget?"), 'budget'],
            [t("What kind of weekly meal variety do you want?"), 'weeklyVariety',t('More repetitive'),t('More cooking')],
            [`${t("Max recipe complexity")}?`, 'complexityPreference',t('Basics only'),t('Complex')]
        ]
    
        return (
            <React.Fragment>
                {labelMap.map(([label, name, firstLabel, lastLabel]) => (
                    <React.Fragment key={name}>
                        <FlowQuestion text={label} />
                        <SmallButtonSelect  
                            name={name}
                            values={values}
                            options={User[`${name}Col`](t,false,false,true)}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            noWrap
                            minWidth
                            firstLabel={firstLabel}
                            lastLabel={lastLabel}
                            idBase={name}
                        />
                    </React.Fragment>
                ))}
                <NextButton 
                    className="btn-wide mt65"
                    label={t('Next')} 
                    advance={advance} 
                    validateForm={validateForm} 
                    values={values} 
                    isValid={isValid}
                    setTouched={setTouched} 
                    id='next-btn'
                />
            </React.Fragment>
        )
    }
}

const DietTypeQuestion = ({ flowProps, t}) => {

    const { advance, values, setTouched, validateForm, isValid } = flowProps;
    const allProps = _.pick(flowProps,[ 'values', 'touched', 'handleChange','setFieldValue', 'handleBlur']);

    return (
        <React.Fragment>
            <FlowQuestion text={t("What's your diet type?")} />
            <div className="radio-btn-container">
                {User.DIET_TYPES_FOR_FORM.map(([name,dietType]) => (
                    <RCButtonWithErrors key={dietType} id={`diet-type-${dietType}-radio`} name="dietType" label={t(name)} inProps={{ type: 'radio', value: dietType }} {...allProps} />
                ))}
            </div>
            <div>
                <NextButton 
                    className="btn-wide mt65"
                    label={t('Next')} 
                    advance={advance} 
                    validateForm={validateForm} 
                    values={values} 
                    isValid={isValid}
                    setTouched={setTouched} 
                    id='next-btn'
                />
            </div>
        </React.Fragment>
    )
}

class MealPlanSetupFlowPage extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(mealPlanSetupTransitionMap);
        this.state = { submitState: 'DEFAULT' }
    }

    setSubmitState = (status) => {
        this.setState({ submitState: status });
    }

    render() {
        const { 
            initialValues, 
            user, 
            allergyTags, 
            advanceAction, 
            submit, 
            initMacros, 
            finishSetup,
            resetInitialRecipeOptions, 
            startMealPlan,
            t,
            scrollRef,
            exitPath=homePath(),
            basePath=mealPlanSetupBase,
            progress=true,
            forNewWeek=false
        } = this.props;

        return (
        <Page ref={scrollRef} white>
            <FlowForm 
                initialValues={initialValues}
                validate={validateForm.bind(this,user,t)}
                validateOnMount={true}
                advanceCallback={advanceAction}
                onSubmit={formikHandlerCreator(this,this.setSubmitState,submit,valueFilter)}
                basePath={basePath}
                exitPath={exitPath}
                progress={progress}
                nextPage={getNextPage(user)}
            >
                    <FlowPageContent key='dietType' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <DietTypeQuestion flowProps={flowProps} t={t} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='budgetVarietyComplexity' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <BudgetVarietyComplexityQuestion flowProps={flowProps} t={t} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='exclusions' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <ExclusionsQuestion flowProps={flowProps} t={t} allergyTags={allergyTags} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='mealTypes' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <MealTypesQuestion flowProps={flowProps} t={t} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='recParams' render={(flowProps) => {
                            return (

                                    <MacroRecommendationsPage 
                                        flowProps={flowProps} 
                                        t={t} 
                                        recMacroParams={user.onbRecMacroParams()} 
                                        recommendedCalories={user.onbRecCalsReadable()} 
                                        initMacros={initMacros}
                                        user={user}
                                    />

                            )
                    }} />
                    <FlowPageContent key='macros' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer noLimitH>
                                    <CustomMacrosQuestion flowProps={flowProps} t={t} recommendedCalories={user.recommendedCalsReadable()} user={user} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='startDate' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <StartDateQuestion 
                                        flowProps={flowProps} 
                                        t={t} 
                                        resetInitialRecipeOptions={resetInitialRecipeOptions} 
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='pickRecipes' block render={(flowProps) => {
                            return (
                                <RecipePickerPage 
                                    flowProps={flowProps} 
                                    startMealPlan={startMealPlan}
                                    user={user}
                                    forNewWeek={forNewWeek}
                                />
                            )
                    }} />
                    <FlowPageContent key='finishSetup' block render={(flowProps) => {
                            return (
                                <FinishSetupLoader 
                                    flowProps={flowProps} 
                                    user={user}
                                    finishSetup={finishSetup}
                                />
                            )
                    }} />
            </FlowForm>
        </Page>
        );
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }
}

const mapDispatchToMealPlanInitProps = dispatch => ({
    beforeAction: () => dispatch(clearInitialSignup()),
    afterPath: setupWeeklyPlanPath
})

export const MealPlanInit = connect(null,mapDispatchToMealPlanInitProps)(Connector)

let MealPlanSignup = ({ user, ...props }) => {
    if(user && user.hasBasicProfileFields()) {
        return (
            <MealPlanSetupFlowPage
                exitPath={null}
                user={user}
                basePath={mealPlanSignupBase}
                {...props}
            />
        )
    } else {
        return (<div><Redirect to={resolvedStartPath()} /></div>)
    }
    
}

export const MealPlanStandaloneContext = React.createContext(null)

let RecipePickClientSwitcher = (props) => {
    const { t } = useTranslation();
    const { trainer, scrollRef, setupTransitions, match: { params: { clientId, week } }, switchClient }  = props;
    const client = trainer.clientById(clientId);

    return (
        <PageWithTransitions
            ref={scrollRef} 
            white
            transitionMap={mealPlanSetupTransitionMap} 
            setupTransitions={setupTransitions} 
        >
            <FlowNav page={0} totalPages={1} progress={false} exitPath={null} />
            <FlowChooseGoal className="display-block">
                <RecipePickWrapper t={t}>
                    <HideOnExit>
                        <Loader
                            load={() => switchClient(clientId,client.switchMpFocusDate(week))}
                            preloaded={() => false}
                            successComponent={SwitchFinished}
                            afterPath={generateNewWeekPathFor(week,clientId)}
                            type='padded'
                        />
                    </HideOnExit>
                </RecipePickWrapper>
            </FlowChooseGoal>
        </PageWithTransitions>
    )
}

const mapStateToRPSwitchProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToRPSwitchProps = dispatch => ({
    switchClient: (clientId,focusDate) => dispatch(switchClient(clientId,focusDate))
})

RecipePickClientSwitcher = connect(mapStateToRPSwitchProps,mapDispatchToRPSwitchProps)(RecipePickClientSwitcher);

let GenerateNewWeek = (props) => {
    let { showExitButton, match: { params: { week, clientId }}} = props;
    clientId = clientId !== 'main' ? clientId : null;
    return (
        <MealPlanStandaloneContext.Provider value={clientId}>
            <MealPlanSetupFlowPage
                exitPath={showExitButton ? resolvedHomePath() : null}
                basePath={generateNewWeekBaseFor(week,clientId)}
                forNewWeek={week}
                progress={false}
                {...props}
            />
        </MealPlanStandaloneContext.Provider>
    )
}

const mapStateToProps = (state) => ({
    initialValues: mealPlanFlowSelector(state),
    user: userRecordSelector(state),
    allergyTags: allergyTagsSelector(state),
    recommendedCalories: recommendedCalsSelector(state),
    showExitButton: showMpExitButton(state)
})

const mapDispatchToProps = dispatch =>({
    advanceAction: (data) => dispatch(advanceMealPlanSetup(data)),
    initMacros: data => dispatch(initMacros(data)),
    finishSetup: data => dispatch(finishMealPlanSetup(data)),
    submit: data => data,
    startMealPlan: (selectedRecipes,startDate) => dispatch(startMealPlan(selectedRecipes,startDate)),
})

MealPlanSignup = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(MealPlanSignup))
GenerateNewWeek = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(GenerateNewWeek))

export { MealPlanSignup, RecipePickClientSwitcher, GenerateNewWeek }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(MealPlanSetupFlowPage));
