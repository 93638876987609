import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FullFormModal, ScrollableFormModal } from 'components/Modal';
import { pickMealDaysModalPathFor, editWeeklyMealPathFor, swapMealPathFor, swapMealModalMatchFor, modalPathFor } from 'config/paths';
import FormikWrapper from 'components/FormikWrapper';
import { MultivalueCheckbox, CheckboxCore, CheckButtons, ModalSelectListing } from 'components/Form';
import { copyMealPlanDay, getLockedDays, pickMealDays, setupMealSwap } from 'redux/actions';
import * as _ from 'lib/utilities';
import { connect } from 'react-redux';
import { Loader } from 'components/LoadingHOC';
import { dateFormat } from 'config/settings';
import skippedMeal from 'assets/img/skipped_meal.jpg';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router-dom';
import { Redirect } from 'components/Routing';
import Toast from 'components/Toast';

const userMealInfoFor = (weeklyMeal,date,t) => {
    const userMeal = weeklyMeal.user().findUserMeal(weeklyMeal.category(),date.format(dateFormat));
    if(userMeal) {
        return { name: userMeal.mainName(t), image: <img src={userMeal.mainImageUrl('thumb')} alt=""/> };
    } else {
        return { name: t('No meal'), image: <img src={skippedMeal} alt="" /> }
    }
}

const getInfoFor = (type,weeklyMeal,active,date,generic,t) => {
    if(generic && !active) {
        return { name: t('Weekday not selected'), image: <img src={skippedMeal} alt="" /> }
    } else if(type === 'pick') {
        if(active) {
            return { name: weeklyMeal.mainName(t), image: <img src={weeklyMeal.mainImageUrl('thumb')} alt="" /> };
        } else {
            if(weeklyMeal.rawDates().includes(date.format(dateFormat))) { //will be replaced
                if(weeklyMeal.mealTypeActive()) {
                    return { name: t('New meal'), image: <img src={weeklyMeal.mainImageUrl('thumb','filler')} alt="" /> }
                } else {
                    return { name: t('No meal'), image: <img src={skippedMeal} alt="" /> }
                }
            } else {
                return userMealInfoFor(weeklyMeal,date,t);
            }
        }
    } else {
        if(active) {
            return { name: t('Replacement meal'), image: <FontAwesomeIcon icon="exchange-alt" className="img-placeholder" /> };
        } else {
            return userMealInfoFor(weeklyMeal,date,t);
        }
    }
}

const SelectButton = ({ toggleHandler, weeklyMeal, generic, lockMeals, date, values, type, selectable, setShowDnpMismatch }) => {
    const { t } = useTranslation();
    const value = generic ? _.wday(date) : date.format(dateFormat);
    const active = values.includes(value);
    const classNames = classnames('btn swap-btn no-upcase', { active });
    const everyWeek = lockMeals && (active || generic);
    const { name, image } = getInfoFor(type,weeklyMeal,active,date,generic,t);
    const clickHandler = selectable ? (() => toggleHandler(value)) : () => setShowDnpMismatch(true)

    return (
        <div className={classNames} onClick={clickHandler}>
            <div className="left">
                <div className="swap-date main">{date.format('ddd')}</div>
                <div className="swap-date">{everyWeek ? t('Every Week') : date.format('MMM D')}</div>
            </div>
            <div className="circle-img cntr">
                {image}
            </div>
            <div className="swap-date-title">{name}</div>
            <div className="swap-check-cont">
                {selectable && (<CheckboxCore 
                    inputProps={{ checked: active, readOnly: true }}
                    filled
                    className="middle-align less-padding"
                    onClick={clickHandler}
                />)}
            </div>
        </div>
    )
}

const pickValuesFilter = (weeklyMeal,history) => values => {
    if(!values.lockMeals) {
        values = _.omit(values,'lockMeals');
    }
    const oldUrl = weeklyMeal.editPath();

    if(values.dates.includes(weeklyMeal.firstDate())) {
        return { ...values, history, oldUrl };
    }

    let newDate = values.dates[0];

    if(_.isBlank(newDate)) {
        newDate = _.weekStart(moment(),values.weekdays[0]).add(7,'days').format(dateFormat);
    }

    return { ...values, history, newUrl: editWeeklyMealPathFor({ category: () => weeklyMeal.category(), date: newDate }), oldUrl }
}

const validateSelection = t => values => {
    const { lockMeals } = values;
    const weekdays = _.filter(values.weekdays,wday => !_.isBlank(wday))
    const dates = _.filter(values.dates,date => !_.isBlank(date));
    
    const selectionCount = lockMeals ? (weekdays.length + dates.length) : dates.length;

    if(selectionCount <= 0) {
        return { dates: t("Pick at least",{ count: 1 })}
    }

    return {};
}

const swapValuesFilter = values => values.lockMeals ? values : _.omit(values,'lockMeals')

const SelectDaysForm = ({ renderScrollable, renderFooter, weeklyMeal, selectType: type, lockedDays, responseData, submit }) => {
    lockedDays = (responseData && responseData.lockedDays) ? responseData.lockedDays : lockedDays;
    const [showDnpMismatchMsg,setShowDnpMismatch] = useState(false);
    const appropriateDates = weeklyMeal.appropriateDates();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <FormikWrapper
            initialValues={ weeklyMeal.initialPickDayValues(lockedDays) }
            valuesFilter={type === 'pick' ? pickValuesFilter(weeklyMeal,history) : swapValuesFilter}
            validate={validateSelection(t)}
            submit={submit}
            initialErrors={{}}
            render={({ handleSubmit, submitState, ...rest }) => {
                const { values, setFieldValue, setFieldTouched, errors: { dates: error }, values: { lockMeals } } = rest;
                return (
                    <form onSubmit={handleSubmit}>
                        {showDnpMismatchMsg && (
                            <Toast 
                                message={(<span className="lh1"><FontAwesomeIcon icon="info-circle" className="info-color" /> {t("can't pick dnp day")}</span>)} 
                                completeCallback={() => setShowDnpMismatch(false)} 
                                dismissText={t('Ok')}
                                displayLength={5000}
                            />
                        )}
                        {renderScrollable({ children: (
                            <div className="swap-day-margins">
                                <div className="swap-container">
                                    {['weekdays','dates'].map(field => {
                                        const show = field === 'dates' || lockMeals;
                                        if(show) {
                                            return (
                                                <CheckButtons 
                                                    key={field}
                                                    name={field}
                                                    values={values}
                                                    setFieldValue={setFieldValue}
                                                    setFieldTouched={setFieldTouched}
                                                    render={({ toggleHandler, values }) => {
                                                        const dates = field === 'weekdays' ? weeklyMeal.genericDates() : weeklyMeal.activeDates();
                                                        return (
                                                            <React.Fragment>
                                                                {dates.map(dt => {
                                                                    return (
                                                                        <SelectButton 
                                                                            key={dt.format(dateFormat)}
                                                                            toggleHandler={toggleHandler}
                                                                            weeklyMeal={weeklyMeal}
                                                                            generic={field==='weekdays'}
                                                                            lockMeals={lockMeals}
                                                                            date={dt}
                                                                            values={values}
                                                                            selectable={appropriateDates.includes(dt.format(dateFormat))}
                                                                            setShowDnpMismatch={setShowDnpMismatch}
                                                                            type={type}
                                                                        />
                                                                    )
                                                                })}
                                                            </React.Fragment>
                                                        )
                                                    }}
                                                />
                                            )
                                        }
                                        return '';
                                    })}
                                    {error && (<div className="red-text ma10 text-center"><FontAwesomeIcon icon="times" /> {error}</div>)}
                                    {!weeklyMeal.user().isPdfClient() && (<div className="ma10 text-center">
                                        <MultivalueCheckbox
                                            name='lockMeals'
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            values={values}
                                            setFieldValue={setFieldValue}
                                            label={<React.Fragment><FontAwesomeIcon icon="lock" /> {t(`Lock ${type === 'pick' ? 'meal' : 'replacement'} to these days every week`)}</React.Fragment>}
                                            className="middle-align"
                                            wrapLabel
                                            id={`lock-meal-check`}
                                        />
                                    </div>)}
                                </div>
                            </div>
                        )})}
                        {renderFooter({ loadState: submitState, disabled: !!error, id: "confirm-days-btn", saveChildren: type === 'pick' ? null : ((<React.Fragment><FontAwesomeIcon icon="arrow-right" /> {t('Find Replacement')}</React.Fragment>)) })}
                    </form>
                )
            }}
        />
    )
}

const ModalContent = ({ weeklyMeal, 
    mealInfoMatrix, 
    closeAndGo, 
    renderHeader, 
    renderFooter, 
    renderScrollable, 
    match, 
    getLockedDays, 
    action, 
    selectType, 
    basePath }) => {

    if(selectType === 'swap') {
        const { params: { id } } = match;
        weeklyMeal = mealInfoMatrix.weeklyMealForUserMealId(id);
        const reduxAction = action;
        action = values => {
            const { mealTypeId } = values;
            reduxAction(values);
            closeAndGo(swapMealPathFor(mealTypeId,mealInfoMatrix.week));
            return Promise.resolve({status: 'DEFAULT'});
        }
    }

    if(weeklyMeal) {
        const lockedDays = weeklyMeal.lockedDays();
        return (
            <React.Fragment>
                {renderHeader()}
                <Loader 
                    load={getLockedDays.bind(null,weeklyMeal.id())}
                    preloaded={() => !!lockedDays}
                    type='padded'
                    successComponent={SelectDaysForm}
                    weeklyMeal={weeklyMeal}
                    selectType={selectType}
                    renderFooter={renderFooter}
                    submit={action}
                    renderScrollable={renderScrollable}
                />
            </React.Fragment>
        )
    }

    return <Redirect to={basePath} />
}

let SelectDaysModal = ({ basePath, weeklyMeal, mealInfoMatrix, selectType, getLockedDays, pickMealDays, setupMealSwap }) => {
    const { t } = useTranslation();

    return (
        <ScrollableFormModal
            fullWidth 
            noOverflow 
            limitWidth 
            path={selectType === 'pick' ? pickMealDaysModalPathFor(basePath) : swapMealModalMatchFor(basePath)} 
            exact 
            title={selectType === 'pick' ? t('Select Days') : t('Select Meals to Swap')}
            icon={selectType === 'pick' ? "calendar-alt" : 'exchange-alt'}
            render={({ closeAndGo, renderHeader, renderFooter, renderScrollable, match }) => {
                
                return (<ModalContent 
                    weeklyMeal={weeklyMeal}
                    mealInfoMatrix={mealInfoMatrix}
                    basePath={basePath}
                    selectType={selectType}
                    action={selectType === 'pick' ? pickMealDays : setupMealSwap}
                    getLockedDays={getLockedDays}
                    closeAndGo={closeAndGo} 
                    renderHeader={renderHeader}
                    renderFooter={renderFooter} 
                    renderScrollable={renderScrollable}
                    match={match}
                />)
            }} 
        />
    )
}

const mapDispatchToProps = dispatch => ({
    getLockedDays: userMealId => dispatch(getLockedDays(userMealId)),
    pickMealDays: (data) => dispatch(pickMealDays(data)),
    setupMealSwap: data => dispatch(setupMealSwap(data))
})

SelectDaysModal = connect(null,mapDispatchToProps)(SelectDaysModal)

export const selectDaysForCopySuffix = '/copy_day/:date';

let SelectDaysForCopyModal = ({ submit, mealInfoMatrix }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <FullFormModal 
            fullWidth 
            path={modalPathFor(selectDaysForCopySuffix,pathname)} 
            scrollable 
            exact 
            icon={['far','copy']}
            title={({ modalProps: { match: { params: { date } } } }) => (t('Copy Weekday To',{ weekday: moment(date).format('dddd') }))}
            formProps={ ({ modalProps: { match: { params: { date } } } }) => ({
                initialValues: { selectedDates: [''], copyDate: date, startDate: mealInfoMatrix.startDate },
                submit
            })}
            footerProps={{ id: 'copy-date-btn', saveIcon: ['far','copy'], saveLabel: t('Copy') }}
            render={({ modalProps, ...formikProps }) => {
                const { values: { copyDate }} = formikProps;
                const copyMom = moment(copyDate);
                const dateCol = _.filter(mealInfoMatrix.weekDates(),dt => !dt.isSame(copyMom,'day')).map(dt => ({ value: dt.format(dateFormat), text: dt.format('dddd'), subtitle: dt.format('MMMM Do')}))

                return (
                    <div className="text-center pt20 pb20 pr10 pl10">
                        <ModalSelectListing 
                            name='selectedDates'
                            col={dateCol}
                            {...formikProps}
                        />
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToCopyProps = dispatch => ({
    submit: data => dispatch(copyMealPlanDay(data))
})

SelectDaysForCopyModal = connect(null,mapDispatchToCopyProps)(SelectDaysForCopyModal)

export { SelectDaysModal, SelectDaysForCopyModal }