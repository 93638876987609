import React, { useState } from 'react';
import RouteModal from 'components/Modal';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ratingPromptPathFor, ratingPromptPostfix } from 'config/paths';
import { useTranslation } from 'react-i18next';
import { brandName, iosRatingUrl, androidRatingUrl } from 'config/settings';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PDLoading } from 'components/LoadingHOC';
import { deviceReadySelector, userRecordSelector } from 'redux/selectors';
import { respondToRatingPrompt } from 'redux/actions';
import * as _ from 'lib/utilities';
import { ContactForm } from './ContactForm';

const requestIosRating = () => {
    if(window.cordova && window.LaunchReview && window.LaunchReview.isRatingSupported()) {
        window.LaunchReview.rating();
        return true;
    }

    return false;
}

const DismissPrompt = ({ closePrompt, respondToRatingPrompt }) => {
    const { t } = useTranslation();

    const dismissHandler = (type) => () => {
        respondToRatingPrompt(type);
        closePrompt();
    }

    return (
        <div className="display-flex faint-color">
            <div className="flex-grow clickable text-left pa10" id="rate-later-btn" onClick={dismissHandler('later')}>
                {t('Maybe later')}
            </div>
            <div className="flex-grow clickable text-right pa10" id="rate-never-btn" onClick={dismissHandler('never')}>
                {t("Don't ask again")}
            </div>
        </div>
    )
}

const FeedbackPrompt = ({ closePrompt, respondToRatingPrompt }) => {
    const { t } = useTranslation();
    const successCallback = () => {
            respondToRatingPrompt('never');
            closePrompt();
    }

    return (
        <div>
            <div className="pt50 pb20 pl10 pr10 text-center">
                <div className="basic-heading">{t("How can we improve?")}</div>
                <div className="pt10 pb10">
                    <ContactForm successCallback={successCallback} subject={'In-app Response'} />
                </div>
            </div>
            <DismissPrompt closePrompt={closePrompt} respondToRatingPrompt={respondToRatingPrompt} />
        </div>

    )
}

const RequestPrompt = ({ user, closePrompt, respondToRatingPrompt  }) => {
    const { t } = useTranslation();
    const title = _.isBlank(user.firstName) ? 
    t("In less than a minute you can make my day and help us grow enough to add even more cool features") : 
    t("Name in less than a minute you can make my day and help us grow enough to add even more cool features", { name: user.firstName });

    const rateHandler = () => {
        const url = (window.isCordova && window.cordova.platformId === 'ios') ? iosRatingUrl : androidRatingUrl;
        respondToRatingPrompt('never');
        closePrompt();
        if(window.isCordova) {
            window.cordova.InAppBrowser.open(url, '_system', '')
        } else {
            window.location.href = url;
        }
    }

    return (
        <div>
            <div className="pt50 pb20 pl10 pr10 text-center">
                <div className="basic-heading" style={{ maxWidth: '400px', lineHeight: '1.1', display: 'inline-block'}}>{title}</div>
                <div className="basic-heading-sub">{t("I read and respond to every bit of feedback")}</div>
                <div className="pt20 pb10">
                    <Button rounded color="primary" onClick={rateHandler} id="rate-the-app-btn">
                        <FontAwesomeIcon icon="star" className="gold-color"></FontAwesomeIcon> {t("Rate the app")}
                    </Button>
                </div>
            </div>
            <DismissPrompt closePrompt={closePrompt} respondToRatingPrompt={respondToRatingPrompt} />
        </div>

    )
}

const InitialPrompt = ({ context, setStatus, closePrompt, respondToRatingPrompt }) => {
    const { t } = useTranslation();
    let title = t('Like brand name?', { brand_name: brandName() });
    if(context === 'logMeal' || context === 'logFood') {
        title = `${t('Meal logged')}. ${title}`;
    } else if(context === 'workoutComplete') {
        title = `${t('Workout complete')}! ${title}`;
    } else if(context === 'logWeight') {
        title = `${t('Weight logged')}. ${title}`;
    }

    const yesHandler = () => {
        if(requestIosRating()) {
            setStatus('loading');
            setTimeout(() => {
                closePrompt();
            },1500);
            respondToRatingPrompt('later_ios');
        } else {
            setStatus('request');
            respondToRatingPrompt('later');
        }
    }

    const noHandler = () => {
        setStatus('feedback');
        respondToRatingPrompt('later');
    }

    return (
        <div>
            <div className="pt50 pb20 pl10 pr10 text-center">
                <div className="basic-heading">{title}</div>
                <div className="pt10 pb10">
                    <Button rounded color="primary" outlined noShadow onClick={yesHandler} id="yes-rate-btn">
                        <FontAwesomeIcon icon={['far','thumbs-up']}></FontAwesomeIcon> {t("Yes")}
                    </Button>
                    <Button rounded color="primary" className="ml10" outlined noShadow onClick={noHandler} id="no-rate-btn">
                        <FontAwesomeIcon icon={['far','thumbs-down']}></FontAwesomeIcon> {t("No")}
                    </Button>
                </div>
            </div>
            <DismissPrompt closePrompt={closePrompt} respondToRatingPrompt={respondToRatingPrompt} />
        </div>

    )
}

const RatingPromptContent = ({ user, context, close, respondToRatingPrompt }) => {
    const [status,setStatus] = useState('initial');
    const closePrompt = () => {
        if(close) {
            close();
        } else {
            setStatus('done');
        }
    }

    if(status === 'initial') {
        return (<InitialPrompt 
            context={context} 
            setStatus={setStatus} 
            closePrompt={closePrompt} 
            respondToRatingPrompt={respondToRatingPrompt} 
        />)
    } else if(status === 'feedback') {
        return (
            <FeedbackPrompt 
                closePrompt={closePrompt}
                respondToRatingPrompt={respondToRatingPrompt}
            />
        )
    } else if(status === 'request') {
        return (
            <RequestPrompt 
                user={user}
                closePrompt={closePrompt}
                respondToRatingPrompt={respondToRatingPrompt}
            />
        )
    } else if(status === 'loading') {
        return (<PDLoading />)
    } else {
        return '';
    }
}

const RatingPromptModal = ({ path, context, respondToRatingPrompt, closeCallback, user }) => {

    return (
        <RouteModal fullWidth path={path} exact closeCallback={closeCallback} render={({ close }) => {
            return (
                <RatingPromptContent context={context} close={close} respondToRatingPrompt={respondToRatingPrompt} user={user} />
            )
        }} />
    )
}

class RatingPromptWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.requestRating = this.requestRating.bind(this);
        this.respondToPrompt = this.respondToPrompt.bind(this);
        this.closeCallback = this.closeCallback.bind(this);
        this.responded = false;
    }

    render() {
        const { basePath, context, render, user } = this.props;
        const modalPath = basePath && ratingPromptPathFor(basePath,context);

        const ratingPrompt = () => {
            
            if(user.onlyIosPrompt || !user.shouldRequestRating()) {
                return '';
            } else {
                return (<RatingPromptContent 
                    context={context} 
                    respondToRatingPrompt={this.respondToPrompt}  
                    user={user}
                />)
            }
        }

        return (
            <React.Fragment>
                {render({ ratingPrompt, requestRating: this.requestRating })}
                {basePath && (<RatingPromptModal 
                    path={modalPath} 
                    context={context} 
                    respondToRatingPrompt={this.respondToPrompt} 
                    user={user}
                    closeCallback={this.closeCallback} />
                )}
            </React.Fragment>
        )
    }

    requestRating() {
        const { user, location, history, deviceReady, basePath, context } = this.props;
        const modalPath = ratingPromptPathFor(basePath,context);

        if(user.shouldRequestRating() && deviceReady && !location.pathname.includes(ratingPromptPostfix)) {
            if(requestIosRating()) {
                this.respondToPrompt('later_ios');
            } else {
                history.push(modalPath);
            }
        }
    }

    respondToPrompt(response) {
        const { respondToRatingPrompt } = this.props;
        respondToRatingPrompt(response);
        this.responded = true;
    }

    closeCallback() {
        if(!this.responded) {
            const { respondToRatingPrompt } = this.props;
            respondToRatingPrompt('later');
        }
    }
}

const mapStateToProps = state => ({
    deviceReady: deviceReadySelector(state),
    user: userRecordSelector(state)
});

const mapDispatchToProps = dispatch => ({
    respondToRatingPrompt: (response) => dispatch(respondToRatingPrompt(response))
})

export default connect(mapStateToProps,mapDispatchToProps)(withRouter(RatingPromptWrapper));
