import React from 'react';
import { metricToImperial, imperialToMetric } from 'lib/utilities';
import { SmallerNumberInput, NumberInput } from 'components/TextInput';
import { ToggleButton, formikInputProps, InputWithErrors, formikAutoSubmitTextProps, CheckButtons, TagButton } from 'components/Form';
import * as _ from 'lib/utilities';
import * as Yup from 'yup';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { WeightRecord, MacroParams } from 'lib/classes';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { useTranslation } from 'react-i18next';
import { DailyNutritionProfile } from 'lib/daily-nutrition-profile';

export const validateBudgetVarietyComplexityDiet = t => values => {
    let errs = {};
    ['budget','weeklyVariety','complexityPreference','dietType'].forEach(field => {
        if(_.isBlank(values[field])) {
            errs[field] = t("Required");
        }
    })
    return errs;
}

export const validateSelectedMeals = (t) => (values) => {
    if(!values.selectedMealTypes || values.selectedMealTypes.length < 2) {
        return { selectedMealTypes: t("Pick at least",{ count: 2 })}
    }
    return {};
}

const validateMacroParams = (errs,values,calTarg,t) => {
    const paramErrs = MacroParams.validateParams(values.nutritionParameters,calTarg,t);
    if(!_.isEmpty(paramErrs)) {
        errs.nutritionParameters = paramErrs;
    }
}

const validateMacrosCore = (t,dailyNutritionProfile,values) => {
    let errs = {}
    const { fiberGoal, calorieOverride } = values;

    if(!_.isBlank(fiberGoal)) {
        if(fiberGoal >= 40) {
            errs.fiberGoal = t("must be less than", {count: 40})
        } else if (fiberGoal <= 0) {
            errs.fiberGoal = t("must be greater than", {count: 0})
        }
    }

    if(!_.isBlank(calorieOverride)) {
        if(calorieOverride >= 5500) {
            errs.calorieOverride = t("must be less than", {count: 5500})
        } else if (calorieOverride <= 600) {
            errs.calorieOverride = t("must be greater than", {count:600})
        }
    }

    validateMacroParams(errs,values,dailyNutritionProfile.targetCalories(values.calorieOverride),t);

    return errs;
}

export const validateDnp = (t) => (values) => {
    const errs = {};
    const { percentOfBase, name, weekdays } = values;

    if(_.isBlank(name)) {
        errs.name = t('required')
    }

    if(_.noBlanks(weekdays).length <= 0) {
        errs.weekdays = t('Pick at least', { count: 1 })
    }

    if(!_.isBlank(percentOfBase)) {
        if(percentOfBase > 200) {
            errs.percentOfBase = t("must be no more than", {count: 200})
        } else if (percentOfBase < 50) {
            errs.percentOfBase = t("must be at least", {count: 50})
        }
    }
    //cal target doesn't matter since only %s allowed
    validateMacroParams(errs,values,2500,t);

    return errs;
}

export const validateCustomMacros = (t,user) => (values) => {
    if(!values.dailyNutritionProfiles) {
        const dnp = !_.isBlank(values.id) ? user.dnpById(values.id) : DailyNutritionProfile.defaultNew({ ...values, user })
        return validateMacrosCore(t,dnp,values);
    }

    const errs = validateMacrosCore(t,user.mainNutritionProfile(),values.dailyNutritionProfiles[0]);
    if(_.isEmpty(errs)) {
        return errs;
    }

    return { dailyNutritionProfiles: [errs] };
}

export const validateAgeHeightWeight = (values,addl={}) => {

    if(values.setupOwnProfile) {
        return {};
    }

    let objShape = {
        unitPreference: Yup.number().oneOf([0,1]),
        age: Yup.number().required().integer().moreThan(12).lessThan(99),
        ...addl
    }
    if(values.unitPreference === 1) {
        objShape.metricHeight = Yup.number().required().moreThan(80).lessThan(240);
        objShape.metricWeight = WeightRecord.weightValidationObj(values.unitPreference);
    } else {
        objShape.footHeight = Yup.number().required().min(3).lessThan(8);
        objShape.inchHeight = Yup.number().lessThan(36);
        objShape.currentWeight = WeightRecord.weightValidationObj(values.unitPreference);
    }
    const schema = Yup.object().shape(objShape);
    try {
        validateYupSchema(values, schema,true);
        return {};
    } catch(validationError) {
        return yupToFormErrors(validationError);
    }
}

export const validateWeight = (values) => {
    let objShape = {
        unitPreference: Yup.number().oneOf([0,1])
    }
    if(values.unitPreference === 1) {
        objShape.metricWeight = WeightRecord.weightValidationObj(values.unitPreference);
    } else {
        objShape.currentWeight = WeightRecord.weightValidationObj(values.unitPreference);
    }
    const schema = Yup.object().shape(objShape);
    try {
        validateYupSchema(values, schema,true);
        return {};
    } catch(validationError) {
        return yupToFormErrors(validationError);
    }
}

export const validateWeightAttr = attr => values => {
    const objShape = {};

    objShape[attr] = WeightRecord.weightValidationObj(values.unitPreference);
    const schema = Yup.object().shape(objShape);
    try {
        validateYupSchema(values, schema,true);
        return {};
    } catch(validationError) {
        return yupToFormErrors(validationError);
    }
}

export const HeightWeightFields = ({ 
    formikProps, 
    autoSubmit, 
    heightLabel, 
    heightToggle, 
    weightLabel, 
    weightToggle, 
    allToggle, 
    className, 
    heightClassName, 
    weightClassName }) => {

    const { t } = useTranslation();
    const { values: { unitPreference } } = formikProps;
    const fieldProps = _.pick(formikProps,autoSubmit ? formikAutoSubmitTextProps : formikInputProps)

    const metric = unitPreference === 1;
    let heightFields = [];
    let weightFields = [];
    const sharedProps = {className: 'mb0 ml0 inline', component: SmallerNumberInput }
    if(metric) {
        heightFields.push(<InputWithErrors {...fieldProps} key="mheight" name="metricHeight" label={t("cm")} {...sharedProps} />);
        weightFields.push(<InputWithErrors {...fieldProps} key="mweight" name="metricWeight" label={t("kgs")} {...sharedProps} />);
    } else {
        heightFields.push(<InputWithErrors {...fieldProps} key="fheight" name="footHeight" label={t("ft")} {...sharedProps} />);
        heightFields.push(<InputWithErrors {...fieldProps} key="inheight" name="inchHeight" label={t("in")} {...sharedProps} className='mb0 ml10 inline' />);
        weightFields.push(<InputWithErrors {...fieldProps} key="cweight" name="currentWeight" label={t("lbs")} {...sharedProps} />);
    }

    return (
        <div className={className}>
            <div className={heightClassName}>
                {heightLabel}
                {heightFields}
                {heightToggle}
            </div>
            <div className={weightClassName}>
                {weightLabel}
                {weightFields}
                {weightToggle}
            </div>
            {allToggle}
        </div>
    )
}

export const AgeHeightWeightFields = ({ flowProps, t, ageLabel, heightLabel, weightLabel, className, autoSubmit, singleButton }) => {
    const { values, values: { unitPreference, metricHeight, metricWeight, footHeight, inchHeight, currentWeight }, setValues } = flowProps;
    const fieldProps = _.pick(flowProps,autoSubmit ? formikAutoSubmitTextProps : formikInputProps)
    const switchUnits = (newPref) => {
        let valMap = {inches: 'inchHeight', feet: 'footHeight', lbs: 'currentWeight', cm: 'metricHeight', kgs: 'metricWeight'}
        let newValues;
        if(newPref === 1) {
            newValues = imperialToMetric({footHeight, inchHeight, currentWeight }, valMap);
        } else {
            newValues = metricToImperial({ metricHeight, metricWeight }, valMap);
        }
        newValues.unitPreference = newPref;
        setValues({ ..._.omit(values,['metricHeight', 'metricWeight', 'footHeight', 'inchHeight', 'currentWeight']), ...newValues });
    }

    const ageField = <InputWithErrors {...fieldProps} name="age" component={NumberInput} className="inline" />;
    
    const heightToggle = !singleButton && (<div className="text-center">
        <ToggleButton options={{[t('ft')]: 0, [t('cm')]: 1}} value={unitPreference} setValue={switchUnits} className="no-capital" />
    </div>)

    const weightToggle = !singleButton && (<div className="text-center">
        <ToggleButton options={{[t('lbs')]: 0, [t('kgs')]: 1}} value={unitPreference} setValue={switchUnits} className="no-capital" />
    </div>)

    const allToggle = singleButton && (
        <div className="text-center">
            <ToggleButton className="btn-no-shadow no-capital" options={{[`${t('lbs')}/${t('ft')}`]: 0, [`${t('kgs')}/${t('cm')}`]: 1}} value={unitPreference} setValue={switchUnits} />
        </div>
    )
    return (
        <div className={className}>
            {ageLabel}
            {ageField}
            <HeightWeightFields
                className="no-wrap"
                heightClassName="inline-block"
                weightClassName="inline-block ml30"
                autoSubmit={autoSubmit}
                heightLabel={heightLabel}
                weightLabel={weightLabel}
                heightToggle={heightToggle}
                weightToggle={weightToggle}
                allToggle={allToggle}
                formikProps={flowProps}
            />
        </div>
    )
}

export const ExclusionTags = ({ allergyTags, buttonProps, ...formikProps }) => {

    return (
        <div>
            <CheckButtons {...formikProps} name="excludedKeywords" render={({ toggleHandler, values }) => {
                return (
                    <React.Fragment>
                        {allergyTags.map(([label,value]) => (
                            <TagButton {...buttonProps} key={value} id={`${value}-allergy-btn`} toggleHandler={toggleHandler} value={value} values={values}><span>{label}</span></TagButton>
                        ))}
                    </React.Fragment>
                )
            }}/>
        </div>
    )
}

export const ExtraExclusions = ({ t, values: { extraKeywords }, setFieldValue }) => {

    return (
        <TagsInput 
            addOnBlur
            value={_.without(extraKeywords,'').slice(0,20)} 
            onChange={(tags) => setFieldValue('extraKeywords',_.arrayForForm(tags))}
            inputProps={{
                className: 'react-tagsinput-input',
                placeholder: t('Add keyword'),
                name: 'extraKeywords'
            }}
        />
    )
}