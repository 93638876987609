import React, { Component } from 'react';
import classnames from 'classnames';
import styles from './Collapse.module.scss';

export default class Collapse extends Component {
    
    constructor(props) {
        super(props)
        this.containerRef = React.createRef()
        this.updating = false;
        this.wasOpen = this.props.isOpen;
    }

    componentDidMount() {
        this.containerRef.current.style.transition = 'height .35s ease-in-out';
    }

    componentDidUpdate() {
        if(this.wasOpen !== this.props.isOpen) {
            this.wasOpen = this.props.isOpen;
            this.updating = true;
            this.containerRef.current.style.height = (this.props.isOpen ? 0 : this.containerRef.current.scrollHeight) + 'px'
            this.outerTimeout = setTimeout(() => {
                this.containerRef.current.style.height = (this.props.isOpen ? this.containerRef.current.scrollHeight : 0) + 'px'
                this.innerTimeout = setTimeout(() => {
                    if(this.props.isOpen) {
                        this.containerRef.current.style.height = null;
                    }
                },350)
            },1)
        }
    }

    componentWillUnmount() {
        if(this.outerTimeout) {
            clearTimeout(this.outerTimeout);
        }

        if(this.innerTimeout) {
            clearTimeout(this.innerTimeout);
        }
    }

    render() {
        const {isOpen, children, className, ...props} = this.props;
        const classNameFinal = classnames({ [styles.collapse]: true, [className]: className})
        let style = {}
        if(!this.props.isOpen && !this.updating) {
            style = {height: 0}
        }
        return (
            <div className={classNameFinal} ref={this.containerRef} style={style} {...props}>
                {children}
            </div>
        )
    }
}