import { coreMacros, macroCals, MacroParams } from 'lib/classes';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';

const omitMacros = (value,macro) => (macro === 'calories' || (!coreMacros.includes(macro) && value <= 0))

export const MacroCircleSvg = ({ macros }) => {
    const { t } = useTranslation();
    const calories = MacroParams.caloriesFor(macros);
    let totalPctDone = 0;

    return (
        <div className="position-relative">
            <svg viewBox="0 0 36 36" className="macro-circle-chart" style={{ width: '68px', height: '68px' }}>
                {Object.entries(_.omitBy(macros,omitMacros)).map(([macro,grams]) => {
                    const pctDone = Math.max(100 - totalPctDone,0);
                    totalPctDone = totalPctDone + ((macroCals[macro]*grams)/calories)*100;
                    return (
                        <path key={macro} className={`${macro}-circle`} strokeDasharray={`${pctDone}, 100`} d="M18 2.0845
                        a 15.9155 15.9155 0 0 1 0 31.831
                        a 15.9155 15.9155 0 0 1 0 -31.831"></path>
                    )
                })}
            </svg>
            <div className="num-sub-container pl10 mr10">
                <div className="number-circle">{Math.round(calories)}</div>
                <div className={`subtitle-circle`}>{t('calories')}</div>
            </div>
        </div>
    )
}

export const FullSvgMacroChart = ({ macros }) => {
    const { t } = useTranslation();

    return (
        <div className="macros-box valign-wrapper">
            <MacroCircleSvg macros={macros} />
            {Object.entries(_.omitBy(macros,omitMacros)).map(([macro,grams]) => {

                return (
                    <div className="num-sub-container" key={macro}>
                        <div className="number-circle">{grams}{t('g')}</div>
                        <div className={`subtitle-circle ${macro}-text`}>{macro === 'carbs' ? t('net carbs') : t(macro)}</div>
                    </div>
                )
            })}
        </div>
    )
}