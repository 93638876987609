import React from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { modalPathFor } from 'config/paths';
import Button, {  AsyncSpinnerButton, ConfirmActionButton } from 'components/Button';
import { validateDnp } from './UserFields';
import { FullInput, WeekdayButtonSelect, MultivalueCheckbox } from 'components/Form';
import TextInput, { NumberInput } from 'components/TextInput';
import { updateDnp, createDnp, destroyDnp } from 'redux/actions';
import * as _ from 'lib/utilities';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { FullFormModal } from 'components/Modal';
import { DailyNutritionProfile } from 'lib/daily-nutrition-profile';
import { UserMacroFields } from './CustomMacroFields';
import LinkButton from 'components/LinkButton';
import { trainerRecordSelector } from 'redux/selectors';
import moment from 'moment';
import { SlightEmphasisTip } from './Utilities';
import { DNP_EDIT_TIP, DNT_EDIT_TIP } from 'config/tooltips';
import { IconNote } from 'components/Typography';

const DestroyButton = ({ destroy, id }) => {
    const { t } = useTranslation();
    const btnProps = {
        noShadow: true,
        outlined: true,
        id: 'delete-dnp-btn'
    }
    const icon = ['fal','trash'];
    const finalDestroy = () => {
        return destroy(id);
    }

    return (
        <ConfirmActionButton onClick={finalDestroy} render={({ ready, onClick }) => {
            
            if(ready) {
                return (
                    <AsyncSpinnerButton
                        buttonProps={{ ...btnProps, color: 'red', label: t('Delete') }}
                        icon={icon}
                        action={() => onClick()}
                    />
                )
            }

            return (
                <Button 
                    {...btnProps}
                    onClick={onClick}
                    color="grey"
                ><FontAwesomeIcon icon={icon} /> {t('Delete')}</Button>
            )
        }} />
    )
}

const DnpFormCore = ({ formikProps, user, trainer, dnp, destroy, close, nutritionSettingsVals }) => {
    const { t } = useTranslation();
    const [baseCals,fallbackParams] = user.baseCalsForForm(nutritionSettingsVals,t);
    const { values } = formikProps;
    const { nutritionParameters } = values;
    const sac = _.signedInAsClient(trainer,user);
    if(dnp.isInactive()) {
        close();
    }

    return (
        <React.Fragment>
            {sac && dnp.isProfile() && dnp.isFromTemplate() && (
                <div className="mt10 mb10"><SlightEmphasisTip tipName={DNP_EDIT_TIP} text={t('dnp edit tip')} /></div>
            )}
            {sac && dnp.isTemplate() && (
                <div className="mt10 mb10"><SlightEmphasisTip tipName={DNT_EDIT_TIP} text={t('dnt edit tip')} /></div>
            )}
            {values.saveToTemplate !== undefined && (<div className="mt10">
                <MultivalueCheckbox 
                    name="saveToTemplate"
                    checkedVal={true}
                    uncheckedVal={false}
                    label={`${t('save dnp template')}`} 
                    {...formikProps}
                />
            </div>)}

            <div className="valign-wrapper">
                <FullInput
                    label={t("Name")}
                    className="inline flex-grow with-placeholder"
                    name="name"
                    component={TextInput}
                    formikProps={formikProps}
                    inProps={{
                        placeholder: 'E.g. Low carb day(s)'
                    }}
                    
                />
                <FullInput
                    label={t("% of normal calories")}
                    className="inline ml10 flex-grow with-placeholder"
                    name="percentOfBase"
                    component={NumberInput}
                    formikProps={formikProps}
                    inProps={{
                        placeholder: '100'
                    }}
                />
            </div>
            <div className="left-align-block">
                <div className="mb10 font-grey text-center">{_.capitalize(t('macros'))}</div>
                <UserMacroFields 
                    nutritionParameters={nutritionParameters} 
                    t={t} 
                    flowProps={formikProps} 
                    user={user} 
                    noRecCheck
                    colName="nutritionParameters"
                    percentOnly
                />
            </div>
            <div className="text-center">
                <IconNote text={<React.Fragment>{t('Calculated values')}: <span id="dnp-calcd">{DailyNutritionProfile.shortSummaryForForm(values,baseCals,t,fallbackParams)}</span></React.Fragment>} />
            </div>
            <div className="mt20">
                <div className="font-grey mb10">{t('Apply to')}</div>
                <WeekdayButtonSelect 
                    startDay={_.signedInAsClient(trainer,user) ? 0 : (user.mealPlanWeekday || 0)} 
                    name={'weekdays'}
                    values={values} 
                    touched={formikProps.touched}
                    errors={formikProps.errors}
                    setFieldValue={formikProps.setFieldValue}
                    setFieldTouched={formikProps.setFieldTouched}
                    dayNames={moment.weekdaysShort()} 
                    id={`dnp-wday-select`}
                />
            </div>
            {dnp.isTemplate() && (<div className="mt20 text-center">
                <DestroyButton id={dnp.id} destroy={destroy} />
            </div>)}
        </React.Fragment>
    )
}

export const dnpModalSuffix = '/dnp/:id'

export const NewDnpButton = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    
    return (
        <LinkButton
            rounded 
            color="primary"
            noShadow 
            outlined 
            id={'new-dnp-btn'}
            to={modalPathFor(dnpModalSuffix,pathname,{ id: 'new' })}
        >
            <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("New")}
        </LinkButton>
    )
}

const dnpFrom = ({ modalProps: { match: { params: { id } } }, trainer, user }) => {
    const isNew = id === 'new';
    const dnp = isNew ? DailyNutritionProfile.defaultNew() : DailyNutritionProfile.byId([trainer,user],id);

    return [dnp, isNew];
}

let DnpModal = ({ trainer, user, update, create, destroy, nutritionSettingsVals }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <FullFormModal 
            fullWidth 
            path={modalPathFor(dnpModalSuffix,pathname)} 
            scrollable 
            exact 
            icon={'calendar-alt'}
            title={({ modalProps }) => {
                const [dnp] = dnpFrom({ modalProps, trainer, user });
                if(dnp.isTemplate()) {
                    return t('Edit Template')
                }

                return t('dnp title');
            }}
            formProps={ ({ modalProps }) => { 
                const [dnp,isNew] = dnpFrom({ modalProps, trainer, user });

                return ({
                    initialValues: { ...dnp.parsedFormValues(trainer || user,true), userId: user.id },
                    submit: isNew ? create : update,
                    validate: validateDnp(t)
                })
            }}
            footerProps={{ id: 'save-dnp-btn' }}
            render={({ modalProps, ...formikProps }) => {
                const [dnp] = dnpFrom({ modalProps, trainer, user });

                return (
                    <div className="text-center pa10">
                        <DnpFormCore 
                            formikProps={formikProps}
                            user={user} 
                            trainer={trainer} 
                            dnp={dnp} 
                            destroy={destroy} 
                            close={modalProps.close} 
                            nutritionSettingsVals={nutritionSettingsVals}
                        />
                    </div>
                )
            }}
        />
    )
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    update: data => dispatch(updateDnp(data)),
    create: data =>dispatch(createDnp(data)),
    destroy: id => dispatch(destroyDnp(id))
})

DnpModal = connect(mapStateToProps,mapDispatchToProps)(DnpModal)

export { DnpModal }


