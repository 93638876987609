import { RecordBase, registerInflection } from  'lib/record-base';
import { User } from 'lib/user';
import * as Yup from 'yup';

export class WeightRecord extends RecordBase {
    static NAME = 'WeightRecord'

    static weightValidationObj(unitPreference) {
        if(unitPreference === User.METRIC) {
            return Yup.number().required().moreThan(30).lessThan(360);
        } else {
            return Yup.number().required().moreThan(60).lessThan(800);
        }
    }

    unitWeight(unitPreference) {
        if(unitPreference === User.METRIC) {
            return this.weight/2.2;
        } else {
            return this.weight;
        }
    }
}

registerInflection('weightRecord','weightRecords',WeightRecord);