import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { IconTip } from './Tooltipped';

export const LowOpacityText = ({ children, component, className, ...rest }) => {
    const Component = component || 'div';
    const classNames = classnames("low-emphasis",{ [className]: className });

    return (
        <Component className={classNames} {...rest}>{children}</Component>
    )
}

export const TinyText = ({ children }) => {
    return (
        <div className="tiny-text">{children}</div>
    )
}

function Typography({title, variant, children, ...props}) {
    return React.createElement(variant || 'p', props, children)
}

export const IconNote = ({variant, text, btn, id, hasBcg}) => {
    const Comp = 'span';
    let icon = 'info-circle';
    let colorClass = 'info-color';
    if(variant === 'warning') {
        icon = 'exclamation-triangle';
        colorClass = 'warning-color';
    } else if(variant === 'error') {
        icon = 'exclamation-circle';
        colorClass = 'red-text';
    } else if(variant === 'success') {
        icon = 'check';
        colorClass = 'success-color';
    }

    return (
        <React.Fragment>
            {variant !== 'none' && (<FontAwesomeIcon icon={['fas',icon]} className={colorClass + ' mr10'}></FontAwesomeIcon>)}
            <Comp className={hasBcg ? "" : "low-emphasis"} id={id ? id : ''}>
                {text}
                {btn && (
                    <div className="text-center mt25">
                        {btn}
                    </div>
                )}
            </Comp>
        </React.Fragment>
    )
}

export const PaddedIconNote = (props) => {
    return (
        <div className="pa20 text-center">
            <IconNote {...props} />
        </div>
    )
}

export const SlightEmphasisIconNote = ({ dismiss, containerClassName, ...props }) => {
    containerClassName = containerClassName || 'pa20';

    return (
        <div className={classnames("slight-emphasis-bcg text-center position-relative", { [containerClassName]: containerClassName })}>
            {dismiss && (
                <div className="upper-right-btn low-opacity-icon" onClick={() => dismiss()}>
                    <FontAwesomeIcon icon={'times'} /> 
                </div>
            )}
            <div className="left-align-block">
                <IconNote hasBcg {...props} />
            </div>
        </div>
    )
}

export const IconMessage = ({ message, icon, size }) => {
    return (
        <span className="faint-color">
            <FontAwesomeIcon icon={icon} size={size || "2x"} className="success-color vert-align-mid mr10"></FontAwesomeIcon>
            {message}
        </span>
    )
}

export const TitleWithTip = ({ title, tip}) => {

    return (
        <div className="position-relative">
            {title}
            <IconTip 
                containerClassName="offside-tip" 
                icon="question-circle" 
                inline 
                msg={tip} 
                size='1x' 
            />
        </div>
    )
}

export const MainHeading = ({ children, className }) => (<h4 className={classnames("conversion-heading ml0",{ [className]: className })}>{children}</h4>)

export default Typography;