import React from 'react';
import { connect } from 'react-redux';
import { getFullRecipeSelector } from 'redux/selectors';
import loadingContainer from 'components/LoadingHOC';
import MacroSummary from './MacroSummary';
import { withTranslation } from 'react-i18next';

class IngredientInfo extends React.Component {

    componentDidMount() {
        if(this.props.onSuccess) {
            this.props.onSuccess();
        }
    }

    render() {
        const { recipe, recipe: { ingredients }, servings, includeMacros, t } = this.props;
        return (
            <div className="recipe-ingredient-info text-left">
                <div><b>Per {recipe.readableServings(servings)} {recipe.defaultedServingName(t)}:</b></div>
                {includeMacros && (<MacroSummary macroHash={recipe.macroHash(servings)} />)}
                {ingredients.map((ingredient) => (<div className="ingredient-info-line" key={ingredient.id}>{ingredient.humanReadableQty(recipe.ingredientMult(servings),true,true)}</div>))}
            </div>
        )
    }
}

IngredientInfo = withTranslation()(IngredientInfo);

export const IngredientLoadingContainer = loadingContainer({
    "SUCCESS": IngredientInfo
})

const mapStateToProps = (state,props) => {
    const recipeSelector = getFullRecipeSelector(props.recipeId);
    return {
        recipe: recipeSelector(state)
    }
}

const ConnectedIngredientInfo = connect(mapStateToProps)(IngredientInfo);

export default ConnectedIngredientInfo;