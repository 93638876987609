import { RecordBase, coreMacros, macroCals, allMacros, registerInflection, zeroedMacros } from 'lib/record-base';
import * as _ from 'lib/utilities';

export class NutritionParameter extends RecordBase {
    static NAME = 'NutritionParameter'

    static ASSOCS = {
        dailyNutritionProfile: { type: 'belongsTo' }
    }
}

registerInflection('nutritionParameter','nutritionParameters',NutritionParameter);

export class MacroParam extends RecordBase {

    static sampleParam(user) {
        return new this({ 
            id: 1, 
            userId: user.id, 
            nutritentId: MacroParams.MACRO_IDS.protein, 
            amount: 150, 
            compareType: 1, 
            userOverride: false, 
            unitType: 0,
            nutrientStr: 'protein',
            comparatorStr: '>'
        });
    }
}

const dirStr = param => param.dir < 0 ? '≤' : '≥';
const dirStrLong = param => param.dir < 0 ? 'at most' : 'at least';

export class MacroParams {

    static MACRO_IDS = { protein: 203, fat: 204, carbs: 205 }
    static ID_MACROS = { 203: 'protein', 204: 'fat', 205: 'carbs' }
    static FIBER_ID = 291;

    static caloriesFor(macroHash) {
        let cals = 0;
        Object.entries(_.omit(macroHash,'calories')).forEach(([macro,value]) => {
            cals = cals + macroCals[macro]*value;
        })
        return cals;
    }

    static gramsFor(macroCalHash) {
        let macroGrams = { ...macroCalHash };
        Object.entries(_.omit(macroGrams,'calories')).forEach(([macro,value]) => {
            macroGrams[macro] = (value || 0)/macroCals[macro];
        })
        return macroGrams;
    }

    static userOverrideParams(params) {
        return _.filter(Object.values(params),param => param.userOverride)
    }

    static unfilteredBuildForForm(nutritionParams) {
        return this.buildForForm(this.userOverrideParams(nutritionParams));
    }

    static macroParamsFromColCore(paramArr) {
        let params = {};
        for(let nutrParam of paramArr) {
            const key = MacroParams.ID_MACROS[nutrParam.nutrientId];
            params[key] = {
                dir: nutrParam.compareType > 0 ? 1 : -1,
                unit: nutrParam.unitType === 1 ? '%' : 'g',
                amount: Number(nutrParam.amount)
            }
        }
        return new MacroParams(params);
    }

    static macroParamsFromCol(nutrParams) {
        return this.macroParamsFromColCore(Object.values(nutrParams));
    }

    static macroParamsFromForm(nutritionParameters, calTarget, t, fallbackParams=null) {
        const active = this.activeParams(nutritionParameters,true);
        if(active.length === 0 && fallbackParams) {
            return fallbackParams;
        }

        const implied = this.impliedParam(active,calTarget,t);
        if(implied) {
            active.push(implied);
        }

        return this.macroParamsFromColCore(active);
    }

    static newFromDailyTargets(targets) {
        let params = {};
        coreMacros.forEach(macro => {
            if(!_.isBlank(targets[macro])) {
                params[macro] = {
                    dir: targets[`${macro}Dir`],
                    unit: 'g',
                    amount: targets[macro]
                }
            }
        });
        return new MacroParams(params);
    }

    static sampleMacroParams(user) {
        return this.macroParamsFromCol({ 1: MacroParam.sampleParam(user) });
    }

    static sampleRemainingMacros(user) {
        const macroParams = this.sampleMacroParams(user);
        const usedMacros = zeroedMacros;
        const targetCals = 2000;
        let remaining = macroParams.remaining(usedMacros,targetCals);
        remaining.calories = targetCals - usedMacros.calories;
        remaining.flexibleMacros = macroParams.coreFlexibleMacros();
        remaining.targetCalories = targetCals;
        return remaining;
    }

    static buildForForm(nutritionParams,forSearch=false,percent=false) {
        let start = _.filter((nutritionParams || []),param => param.nutrientId !== this.FIBER_ID);
        let fiberParam = _.find((nutritionParams || []),param => param.nutrientId === this.FIBER_ID);
        let neededParams = 2 - start.length;
        let retArr = start.map(param => ({ ...param, _destroy: false}));
        let unitType = percent ? 1 : 0;
        while(neededParams > 0) {
            let newMacro = _.difference(coreMacros,retArr.map(param => this.ID_MACROS[param.nutrientId]))[0];
            retArr.push({ _destroy: !forSearch, compareType: 1, amount: '', unitType, nutrientId: this.MACRO_IDS[newMacro], nutrientStr: newMacro, userOverride: true });
            neededParams -= 1;
        }
        if(forSearch) {
            fiberParam = fiberParam || { _destroy: !forSearch, compareType: 1, amount: '', unitType: 0, nutrientId: this.FIBER_ID, nutrientStr: 'fiber', userOverride: true };
            retArr.push(fiberParam);
        }
        return retArr;
    }

    static compareTypesForForm(t,implied) {
        let opts = [{ text: t('at least'), value: 1},{ text: t('at most'), value: 0}]
        if(implied) {
            opts.push({ text: t('implied'), value: 2})
        }
        return opts;
    }

    static unitTypesForForm(t) {
        return [{ text: t('g'), value: 0},{ text: '%', value: 1}]
    }

    static nutrientIdsForForm(t,exclude) {
        return _.compact(Object.entries(this.MACRO_IDS).map(([str,id]) => {
            if(exclude.includes(str)) {
                return null;
            } else {
                return {text: t(str), value: id };   
            }
        }))
    }

    static fiberForForm(t) {
        return [{text: t('fiber'), value: this.FIBER_ID}]
    }

    static activeParams(nutritionParams,excludeBlanks=false) {
        return _.filter(nutritionParams,param => (!param._destroy && param.nutrientId !== this.FIBER_ID && (!excludeBlanks || !_.isBlank(param.amount))));
    }

    static impliedParam(activeParams,calTarget,t) {
        if(activeParams.length < 2 || _.isBlank(calTarget)) {
            return null;
        } else {
            let maxCals = calTarget;
            let calsNeeded = calTarget;
            let setMinCals = true;
            let unitType = activeParams[0].unitType;
            const impliedMacroName = _.difference(coreMacros,activeParams.map(param => this.ID_MACROS[param.nutrientId]))[0];
            activeParams.forEach(param => {
                const macroAmt = _.isNumeric(param.amount) ? Math.max(Number(param.amount),0) : 0;
                const calsPerG = macroCals[this.ID_MACROS[param.nutrientId]];
                let thisCals = null;
                if(param.unitType === 0) {
                    thisCals = macroAmt*calsPerG;
                } else {
                   thisCals = (macroAmt/100.0)*calTarget;
                }

                if(param.compareType === 1) {
                    maxCals -= thisCals;
                    setMinCals = false;
                } else {
                    calsNeeded -= thisCals;
                }
            });

            let minCals = setMinCals ? Math.max(calsNeeded,0) : 0;
            maxCals = Math.min(maxCals,calTarget);
            let calsPerG = macroCals[impliedMacroName];

            let impliedMacro = { nutrientId: this.MACRO_IDS[impliedMacroName], index: 2, unitType, nutrientStr: impliedMacroName }
            if(unitType === 0) {
                impliedMacro.rangeStr = `${minCals > 0 ? `${Math.round(minCals/calsPerG)}-` : ''}${Math.round(maxCals/calsPerG)}${t('g')}`
            } else {
                impliedMacro.rangeStr = `${minCals > 0 ? `${Math.round(minCals/calTarget*100)}-` : ''}${Math.round(maxCals/calTarget*100)}%`
            }

            impliedMacro.amount = unitType === 0 ? Math.round(maxCals/calsPerG) : Math.round(maxCals/calTarget*100);

            if(minCals <= 0) {
                impliedMacro.compareType = 0;
            } else {
                impliedMacro.compareType = 2;
            }
            return impliedMacro;
        }
    }

    static validateParams(nutritionParams,calTarget,t) {
        const withIndices = nutritionParams.map((param,index) => ({ ...param, index }))
        const activeParams = _.filter(withIndices,param => !param._destroy);

        if(activeParams.length <= 0 ) {
            return {};
        } else {
            const errs = {};

            activeParams.forEach(param => {
                if(_.isBlank(param.amount)) {
                    errs[param.index] = { amount: t('uncheck or set an amount')};
                } else if (!_.isNumeric(param.amount)) {
                    errs[param.index] = { amount: t('must be a number')};
                }
            })

            activeParams.push(this.impliedParam(activeParams,calTarget,t));

            const allParams = _.compact(activeParams);

            allParams.forEach(param => {
                if(param.compareType === 0) {
                    if(param.unitType === 0) {
                        if(param.amount < 15) {
                            errs[param.index] = { amount: t('macro too low', {macro: t(this.ID_MACROS[param.nutrientId]), count: `15${t('g')}`})};
                        }
                    } else {
                        if(param.amount < 5) {
                            errs[param.index] = { amount: t('macro too low', {macro: t(this.ID_MACROS[param.nutrientId]), count: `5%`})};
                        }
                    }
                } else if (param.compareType === 1) {
                    if(param.amount <= 0 && !errs[param.index]) {
                        errs[param.index] = { amount: t("must be greater than", { count: `0` })};
                    } else if(param.unitType === 0) {
                        if(param.amount > 500) {
                            errs[param.index] = { amount: t('macro too high', {macro: t(this.ID_MACROS[param.nutrientId]), count: `500${t('g')}`})};
                        }
                    } else {
                        if(param.nutrientId === 203 && param.amount > 50) {
                            errs[param.index] = { amount: t('macro too high', {macro: t(this.ID_MACROS[param.nutrientId]), count: `50%`})};
                        } else if(param.amount > 80) {
                            errs[param.index] = { amount: t('macro too high', {macro: t(this.ID_MACROS[param.nutrientId]), count: `80%`})};
                        }
                    }
                } else if (param.compareType === 2) {
                    if(param.amount <= 0) {
                        errs[param.index] = { amount: t('macro too low', {macro: t(this.ID_MACROS[param.nutrientId]),count: 0})};
                    }
                }
            })

            return errs;
        }
    }

    static gramsFromMacroPct(macro,pct,targetCalories) {
        return (pct/100.0)*targetCalories/macroCals[macro]
    }

    static remainingMacroTargets = (targs,macroHash) => {
        const retParams = {};
        Object.entries(targs).forEach(([macro,targParams]) => {
            if(_.isBlank(targParams)) {
                return;
            }
            const { [macro]: mealVal=0 } = macroHash;
            let diff = 0;
            if(macro === 'calories') {
                diff = targParams - mealVal;
                retParams[macro] = diff;
            } else {
                const isPct = !!targParams[3];
                const amount = isPct ? ((targParams[0]/100)*macroHash.calories)/macroCals[macro] : targParams[0]
                diff = amount - mealVal;
                retParams[macro] = [diff,targParams[1],diff,targParams[3]]
            }
        })
        return retParams;
    }

    static recommendedMacros = (targs,usedMacros) => {
        const remaining = this.remainingMacroTargets(targs,usedMacros);
        Object.entries(remaining).forEach(([macro,targParams]) => {
            if(macro === 'calories') {
                remaining[macro] = Math.max(targParams,0);
            } else {
                remaining[macro][0] = Math.max(remaining[macro][0],0);
            }
        })
        return remaining;
    }

    static macroWarningsHash = (t,targs,macroHash,calLeeway=60) => {
        const errs = {};
        const remaining = this.remainingMacroTargets(targs,macroHash);
        
        Object.entries(remaining).forEach(([macro,remainingParams]) => {
            if(_.isBlank(remainingParams)) {
                return;
            }
            const { [macro]: mealVal=0 } = macroHash;
            let diff = 0;
            let isErr = false;
            let amountText = '';
            if(macro === 'calories') {
                diff = -remainingParams;
                isErr = Math.abs(diff) > calLeeway;
                amountText = `${Math.round(Math.abs(diff))} ${t('cals')}`;
            } else {
                diff = -remainingParams[0];
                const dir = remainingParams[1];
                const finalDiff = dir > 0 ? diff : Math.min(mealVal,diff);
                isErr = dir > 0 ? (finalDiff <= -2) : (finalDiff >= 2);
                const absDiff = Math.abs(finalDiff);
                amountText = `${Math.round(absDiff)}${t('g')}`;
                let pctAmt = null;
                const calAmt = Math.round(macroCals[macro]*absDiff);
                if(macroHash.calories && macroHash.calories > 10) {
                    pctAmt = calAmt/macroHash.calories*100;
                }
                amountText = pctAmt ? `${amountText} (${Math.round(pctAmt)}%)` : amountText
            }
            if(isErr) {
                const errKey = diff < 0 ?  'Falls under target by' : 'Exceeds target by';
                errs[macro] = t(errKey,{ target: t(`${macro} goal`), amount: amountText });
            }
        })
        return errs;
    }

    static macroWarnings = (t,targs,macroHash,calLeeway=60) => {
        const errs = this.macroWarningsHash(t,targs,macroHash,calLeeway);
        if(_.isEmpty(errs)) {
            return null;
        }
        return Object.values(errs).join(' ');
    }

    constructor(obj) {
        Object.assign(this,obj);
    }

    isEmpty() {
        return Object.values(this).length === 0;
    }

    rawMacroParams(targetCalories) {
        let macroParams = { calories: targetCalories };
        Object.entries(this).forEach(([macro,param]) => {
            const gramAmount = param.unit === '%' ? MacroParams.gramsFromMacroPct(macro,param.amount,targetCalories) : param.amount;
            macroParams[macro] = [gramAmount, param.dir]
        });
        return macroParams;
    }

    setOverrideRequiredMacros(override) {
        this.overrideRequiredMacros = override;
    }

    requiredMacros() {
        return this.overrideRequiredMacros || Object.keys(this);
    }

    requirementStr(macro,t) {
        if(this[macro]) {
            const param = this[macro];
            return `${dirStr(param)}${Math.round(param.amount)}${t('g')}`;
        }

        return '-';
    }

    overshootStr(macro,amount,t) {
        if(this[macro]) {
            const param = this[macro];
            const diff = Math.round(amount - param.amount);
            const color = diff*param.dir < 0 ? 'red-text' : `${macro}-text`;
            const str = `${diff < 0 ? '' : '+'}${diff}${t('g')}`
            return [str,color];
        }

        return ['-',`${macro}-text`];
    }

    coreRequiredParams() {
        return _.pick(this,coreMacros)
    }

    coreFlexibleMacros() {
        return _.difference(coreMacros,this.requiredMacros());
    }

    optionalMacros() {
        return _.difference(allMacros,this.requiredMacros());
    }

    remaining(usedMacros={},targetCalories) {
        let remaining = {};
        Object.entries(this).forEach(([macro,param]) => {
            const usedUp = usedMacros[macro] || 0;
            const gramAmount = param.unit === '%' ? MacroParams.gramsFromMacroPct(macro,param.amount,targetCalories) : param.amount;
            remaining[macro] = { amount: gramAmount - usedUp, dir: param.dir };
        })
        return remaining;
    }

    summary(t,long=false) {
        let base = Object.entries(this.coreRequiredParams()).map(([macro,params]) => {
            return `${long ? `${dirStrLong(params)} ` : `${dirStr(params)}`}${params.unit === '%' ? `${Math.round(params.amount)}% ${t(macro)}` : t(`grams ${macro}`,{ grams: Math.round(params.amount)})}`;
        });

        const flexible = this.coreFlexibleMacros();
        if (flexible.length === 1) {
            base.push(t("Flexible macro",{ macro: t(flexible[0])}))
        } else if( flexible.length > 1) {
            base.push(t("Flexible macros",{ macro1: t(flexible[0]), macro2: t(flexible[1])}))
        }

        return base;
    }

    markValidations(validations) {
        for(let macro of this.requiredMacros()) {
            if(validations[macro]) {
                validations[macro] = validations[macro].required();
            }
        }
    }
}