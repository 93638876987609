import * as _ from 'lib/utilities';

export const chatConcern = {

    paginatedChats(chatsMeta) {
        const { cutoff, archiveType } = chatsMeta;
        const archiveCheck = chat => (archiveType === 'archived' ? chat.isArchived() : !chat.isArchived())
        if(cutoff) {
            return _.filter(this.chats(),c => (c.lastActiveTstamp() >= cutoff && archiveCheck(c)));
        }

        return [];
    },

    chats() {
        return _.sortBy(_.uniqBy(_.filter(this.chatMemberships,cm => cm.active()).map(cm => cm.chat), c => c.id),c => -c.lastActiveTstamp());
    },

    unarchivedChats() {
        return _.filter(this.chats(),c => !c.isArchived());
    },

   chatById(id) {
        return _.find(this.chats(),c => (c.id === Number(id))); 
   },

   umMap() {
    return (this.unreadMessagesMap || { unmuted: {}, muted: {}});
   },

   unreadMessageListFor(chatId,type) {
        return this.umMap()[type][chatId] || [];
   },

   unreadMessageCountFor(chatId) {
        let msgIds = this.unreadMessageListFor(chatId,'unmuted');
        if(msgIds.length === 0) {
            msgIds = this.unreadMessageListFor(chatId,'muted');
        }
        return msgIds.length;
   },

   calcdUnreadMessages() {
        const hsh = { unmuted: {}, muted: {} };
        if(!this.hasActiveMessaging()) {
            return hsh;
        }
        this.unarchivedChats().forEach(chat => {
            const mem = chat.membershipFor(this);
            const unreadCheck = msgId => (_.isBlank(mem.lastReadId) || msgId > mem.lastReadId);
            const unread = _.filter(this.unreadMessageListFor(chat.id,(chat.isMuted(this) ? 'muted' : 'unmuted')),unreadCheck);
            const unreadLoaded = _.filter(chat.messages.map(m => m.id),unreadCheck);
            const unreadFinal = _.union(unread,unreadLoaded);
            if(unreadFinal.length > 0) {
                if(chat.isMuted(this)) {
                    hsh.muted[chat.id] = unreadFinal;
                } else {
                    hsh.unmuted[chat.id] = unreadFinal;
                }
            }
        });
        return hsh;
   },

   needsUnreadMsgUpdate() {
        const calcd = this.calcdUnreadMessages();

        for(const type of ['unmuted','muted']) {
            if(!_.arraysEqual(Object.keys(this.umMap()[type]),Object.keys(calcd[type]))) {
                return calcd;
            }

            for(const entry of Object.entries(calcd[type])) {
                const [chatId,unreadIds] = entry;
                if(!_.arraysEqual(unreadIds,this.unreadMessageListFor(chatId,type))) {
                    return calcd;
                }
            }
        }

        return false;
   },

   unreadMessageCount(trainer) {
        if(_.signedInAsClient(trainer,this) || !this.hasActiveMessaging()) {
            return 0;
        }

        return _.reduce(Object.values(this.umMap()['unmuted']),(count,unreadChatMessageIds) => (count + unreadChatMessageIds.length),0);
   },

   totalUncheckedCount(trainer) {
        return this.uncheckedNotifications().length + this.unreadMessageCount(trainer);
   },

   hasMultipleChats() {
        return this.chats().length > 1;
   }
}