import React from 'react';
import * as transitions from 'assets/transitions';
import { editRecipeMatch, editTempMealPath, editWeeklyMealMatch, emailPaywallMatches, mealBrowserPath, recipeBrowserPath, swapMealMatch, swapRecipeMatch, swapTempRecipeMatch, viewLogsPath, viewTempMealMatch } from 'config/paths';
import { getMealSelector, userSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { TempRecipeReplacementWrapper } from './FindTempRecipeReplacement';
import { ViewMealParent } from 'partials/ViewMealCore';
import { BottomButtons, DefaultAsyncActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { RecipeReplacementWrapper } from './FindRecipeReplacement';
import { TempMealWrapper } from './EditTempMeal';
import PaywallWrapper from 'partials/PaywallWrapper';
import { isNumeric } from 'lib/utilities';
import * as _ from 'lib/utilities';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { goWrapper } from 'redux/helpers';

const transitionMap = {
    rules: [
        [[...mealPlanSlideOutPaths, 
            recipeBrowserPath, 
            mealBrowserPath, 
            swapMealMatch, 
            swapRecipeMatch, 
            swapTempRecipeMatch, 
            editTempMealPath, 
            editWeeklyMealMatch, 
            viewTempMealMatch,
            viewLogsPath
        ],transitions.slideOut],
        [[editRecipeMatch,...emailPaywallMatches],transitions.slideOver]
    ]
};

const ConfirmButton = ({ action, successCallback }) => {
    const { t } = useTranslation();

    return (
        <BottomButtons>
            <DefaultAsyncActionButton 
                rounded 
                color="primary" 
                className="no-upcase" 
                id={`confirm-replacement-btn`}
                action={() => action()}
                successCallback={successCallback}
            >
                <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Confirm")}
            </DefaultAsyncActionButton>
        </BottomButtons>
    )
}

const ViewReplacementShared = ({ TopComp, meal: urlMeal, history, goPages=-2, ...outerProps}) => {

    return (
            <TopComp 
                {...outerProps}
                render={({ selectAction, tempMeal }) => {
                    const meal = urlMeal || tempMeal;

                    return (<ViewMealParent 
                        meal={meal}
                        transitionMap={transitionMap}
                        {...outerProps}
                        confirmComp={(<ConfirmButton 
                            action={selectAction.bind(null,{ meal })} 
                            successCallback={() => goWrapper(history,goPages)} 
                        />)}
                    />)
                }}
            />
    )
}

const ViewWithPaywall = (props) => (<PaywallWrapper blockTypes={['old','hard']} context="view_recipe"><ViewReplacementShared {...props} /></PaywallWrapper>)

let ViewTempRecipeReplacement = (props) => (<ViewWithPaywall TopComp={TempRecipeReplacementWrapper} {...props} />)

let ViewRecipeReplacement = (props) => (<ViewWithPaywall TopComp={RecipeReplacementWrapper} {...props} />)

let ViewTempMeal = (props) => (<ViewWithPaywall TopComp={TempMealWrapper} {...props} goPages={-3} />)

let ViewBrowserRecipe = ({ meal, ...props }) => {

    return (<ViewMealParent 
        meal={meal}
        transitionMap={transitionMap}
        {...props}
    />)
}

const replaceOverridden = (sideDishStubs,overrides) => {
    if(overrides) {
        return _.serializeDishStubs(_.parseDishStubs(sideDishStubs).map(([ recipeId, servings ]) => {
            const overrideId = _.isBlank(overrides[recipeId]) ? recipeId : overrides[recipeId];
            return [  overrideId, servings ];
        }))
    }
    return sideDishStubs;
}

const mapStateToProps = (state,{ match: { params: { sideDishStubs, mealCount, focus }}}) => {
    const user = userSelector(state);
    const overrides = user && { ...(user.recipeOverrideMap || {}) };
    const finalStubs = sideDishStubs && replaceOverridden(sideDishStubs,overrides);
    const mealSelector = finalStubs ? getMealSelector(finalStubs) : () => null;
    return {
        meal: mealSelector(state),
        mealCount: Number(mealCount),
        focusId: isNumeric(focus) ? Number(focus) : null
    }
}

ViewTempRecipeReplacement = connect(mapStateToProps)(ViewTempRecipeReplacement);
ViewRecipeReplacement = connect(mapStateToProps)(ViewRecipeReplacement);
ViewTempMeal = connect(mapStateToProps)(ViewTempMeal);
ViewBrowserRecipe = connect(mapStateToProps)(ViewBrowserRecipe);


export { ViewRecipeReplacement, ViewTempRecipeReplacement, ViewTempMeal, ViewBrowserRecipe }