import React from 'react';
import { useTranslation } from 'react-i18next';
import MacroSummary from 'partials/MacroSummary';
import RecipeLikeButtons from 'partials/RecipeLikeButtons';
import classnames from 'classnames';
import { IngredientLoadingContainer } from 'partials/IngredientInfo';
import Dropdown from 'components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { LoadingContainerParent } from 'components/LoadingHOC';
import { useHistory } from 'react-router-dom';
import { foodLogModalPathFor, userMealPathFor, viewMealPathFor } from 'config/paths';
import { paywallPathFor } from 'redux/helpers';
import skippedMeal from 'assets/img/skipped_meal.jpg';
import * as _ from 'lib/utilities';
import moment from 'moment';

const IngredientInfoIcon = ({ load, recipe, servings }) => {

    const preloaded = () => recipe.isFullyLoaded();

    return (
        <Dropdown 
            options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
            triggerRender={({ ref, target }) => {
                return (
                        <div className="icon-btn-container display-inline vert-align-mid" ref={ref} data-target={target} id={`ingredient-info-btn-${recipe.id}`}>
                            <FontAwesomeIcon icon="info-circle" />
                        </div>
                )
            }}
            contentRender={({ recalcDims }) => {
                return (
                    <LoadingContainerParent 
                        load={load}
                        preloaded={preloaded}
                        recipe={recipe}
                        onSuccess={recalcDims}
                        servings={servings}
                        component={IngredientLoadingContainer}
                    />
                )
            }}
        />
    )
}

export const SkippedMealRow = ({ userMeal }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="display-flex">
                <div className="image-center-crop">
                    <img src={skippedMeal} alt="" />
                </div>
                <div className="today-recipe-details">
                    <div className="today-recipe-title">
                        {t("Skipped Meal")}
                    </div>
                    <div style={{fontSize: '12px'}}><MacroSummary macroHash={userMeal.unloggedMacroHash()} /></div>
                </div>
            </div>
        </div>
    )
}

export const OffPlanRecipeMealRow = ({ userMeal }) => {
    const { t } = useTranslation();
    const history = useHistory();
    
    return (
        <div className="recipe-meal-row position-relative">
            <div className="display-flex clickable-panel" onClick={() => history.push(foodLogModalPathFor(moment(userMeal.date),userMeal.id))}>
                <div className="image-center-crop">
                    <img src={userMeal.mainImageUrl('mediumThumb')} alt="" />
                </div>
                <div className="today-recipe-details" style={ { width: '100%'} }>
                    <div className="today-recipe-title">
                        {t('Off-plan meal')}
                    </div>
                    <div style={{fontSize: '12px'}}>{t('Recommended')}: <MacroSummary macroHash={userMeal.unloggedMacroHash()} /></div>
                </div>
            </div>
        </div>
    )
}

const RecipeMealRow = ({ user, recipeMeal, index, type, contentRender, load }) => {

    const { recipe } = recipeMeal;

    const history = useHistory();

    let clickHandler = null;
    const hasLinks = (type === 'today' || type === 'single')

    if(type === 'today') {
        clickHandler = () => {
            const path = user.hasProAccess(['old','hard']) ? userMealPathFor(recipeMeal.userMealId,recipeMeal.recipeId,recipeMeal.userMeal.date) : paywallPathFor('view_recipe',user);
            history.push(path);
        }
    } else if (type === 'single') {
        clickHandler = () => {
            history.push(viewMealPathFor(recipeMeal.userMeal.urlStub(),1,recipeMeal.recipeId));
        }
    }
    const infoIcon = (<IngredientInfoIcon recipe={recipe} load={load} servings={recipeMeal.servings} />);

    return (
        <div className={classnames("recipe-meal-row",{ "position-relative": hasLinks})}>
            {index > 0 && (<div className="recipe-divider" />)}
            <div className={classnames("display-flex",{"clickable-panel": hasLinks, [`view-recipe-link-${recipe.id}`]: hasLinks})} onClick={clickHandler}>
                <div className="image-center-crop">
                    <img src={recipe.imageUrl('mediumThumb')} alt="" />
                </div>
                <div className="today-recipe-details" style={ (type === 'log' || type === 'portions') ? { width: '100%'} : null }>
                    <div className="today-recipe-title">
                        {_.abbrText(recipe.name,50)} {type === 'log' && infoIcon}
                    </div>
                    <div style={{fontSize: '12px'}}><MacroSummary macroHash={recipeMeal.macroHash()} id={type !== 'today' ? `rm-macros-${recipeMeal.id}` : ''}/></div>
                    {contentRender && contentRender({ infoIcon })}
                </div>
                {type === 'today' && (<div className="recipe-like-icons-placeholder"></div>)}
            </div>
            {type === 'today' && (<RecipeLikeButtons user={user} recipe={recipe} />)}
        </div>
    )
}

export default RecipeMealRow;