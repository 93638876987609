import React, { useState } from 'react';
import * as _ from 'lib/utilities';
import InfiniteScroll from 'components/InfiniteScroll';
import { clientsScrollMetaSel } from 'redux/selectors';
import { loadClientsForDash } from 'redux/actions';
import { connect } from 'react-redux';
import { SlightEmphasisIconNote } from 'components/Typography';
import { Trans, useTranslation } from 'react-i18next';
import { CLEARED_FILTERS } from 'config/settings';
import { Link } from 'components/Routing';
import { trainerHomePath } from 'config/paths';

export const getClientScrollInfo = ({ trainer, clientsScrollMeta, context, filters, metricType }) => {

    const key = [ metricType, ..._.flatten(Object.entries(_.pickBy(filters,(val) => !_.isBlank(val))))].join('_');
    const fullContext = `${key}_${context}`;
    const resolvedMeta = (clientsScrollMeta && fullContext === clientsScrollMeta.context) ? clientsScrollMeta : null;
    const loadedPage = resolvedMeta ? resolvedMeta.page : 0;
    const clients = trainer.clientsFor(filters,resolvedMeta ? resolvedMeta.ids : []);

    return { clients, key, resolvedMeta, fullContext, loadedPage };
}

const filtersClear = (filters,exclude=[]) => _.every(Object.entries(CLEARED_FILTERS),([k,v]) => (exclude.includes(k) || filters[k] === v || (_.isBlank(filters[k]) && _.isBlank(v))))

const ClientScroller = ({ trainer, filters, load, metricType, clientsScrollMeta, context, render, uneditableFilters }) => {
    const { t } = useTranslation();
    const noFilters = filtersClear(filters,uneditableFilters);
    const [metrics,setMetrics] = useState({});
    const { clients, key, resolvedMeta, fullContext, loadedPage } = getClientScrollInfo({ trainer, clientsScrollMeta, context, filters, metricType });

    return (
        <InfiniteScroll 
            key={key}
            load={page => load({ ...filters, type: metricType, page, context: fullContext })} 
            initialPage={1}
            loadedPage={loadedPage} 
            fullyLoaded={resolvedMeta && resolvedMeta.done} 
            records={clients}
            alwaysReloadOnMount={true}
            perPage={35}
            successCallback={(data) => {
                if(data && data.metrics) {
                    setMetrics({ ...metrics, ...data.metrics })}
                }
            }
            noResultsComp={<div className="mt10"><SlightEmphasisIconNote id="no-clients-note" text={(noFilters && context !== 'dash') ? (<Trans i18nKey={'no clients created tip'}><Link to={trainerHomePath}></Link></Trans>) : t("no clients for filters", { type: t('client')})} /></div>}
            render={({ maxIndex }) => render({ metrics, clients: clients.slice(0,maxIndex) })}
        />
    )
}

const mapStateToProps = state => ({
    clientsScrollMeta: clientsScrollMetaSel(state)
})

//want to skip client removal when loading in modal because it can swipe out clients being used elsewhere in the UI. Any ones that should get cleared will the next time user visits dashboard
const mapDispatchToProps = dispatch => ({
    load: params => dispatch(loadClientsForDash(params,true))
})

export default connect(mapStateToProps,mapDispatchToProps)(ClientScroller)