import React from 'react';
import * as _ from 'lib/utilities';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ids = ['very-easy-btn','easy-btn','medium-btn','difficult-btn','very-difficult-btn'];

export const RatingButtons = ({ easyLabel, hardLabel, handlerCreator, labelPos, compact, activeRating, setIds }) => {
    const hasLabels = !_.isBlank(easyLabel) || !_.isBlank(hardLabel);
    const labels = hasLabels ? (<div className="valign-wrapper wo-wt-test-subtitle">
        <div className="">{easyLabel}</div>
        <div className="wo-wt-test-subtitle-2">{hardLabel}</div>
    </div>) : null;

    return (
        <div className={classnames({ 'compact-rating-btns': compact })}>
            {labelPos === 'top' && labels}
            <div className={classnames("valign-wrapper cntr",{ mt10: labelPos !== 'top' })}>
                {[0,1,2,3,4].map(rating => {
                    return (
                        <div key={rating} className={classnames(`wo-wt-test-btns buttonize`,{ [`color-${rating+1}`]: (activeRating !== rating), active: (activeRating === rating)})} id={setIds ? ids[rating] : ''} onClick={handlerCreator(rating)}>
                            {activeRating === rating && <FontAwesomeIcon icon="check" className="success-color" />}
                        </div>
                    )
                })}
            </div>
            {labelPos !== 'top' && labels}
        </div>
    )
}