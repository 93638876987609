import React, { Component } from 'react';
import { Link, Redirect } from "components/Routing";
import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as Utils from 'lib/utilities';
import { Checkbox, InputWithErrors, SubmitButton as CoreButton } from 'components/Form';
import { loginUser, newEmailUser } from 'redux/actions';
import loadingContainer from './LoadingHOC';
import { Trans, useTranslation } from 'react-i18next';
import { signupFlowDataSelector, trainerInviteDataSelector } from 'redux/selectors';
import { webPrivacyPath, webTosPath } from 'config/paths';

const Success = ({ errorMsg, successPath, label }) => {

    if(errorMsg) {
        return (
            <React.Fragment>
                <div id="invalid-email-msg" className="red-text mb20">{errorMsg}</div>
                <SubmitButton label={label} />
            </React.Fragment>
        )
    } else {
        return (
            <Redirect to={successPath} />
        )
    }
}

const SubmitButton = ({ label }) => {
    return (
        <CoreButton id="submit-email-btn" icon="sign-in-alt" label={label} />
    )
}

const LoadingContainer = loadingContainer(
    {
        'SUCCESS': Success, 
        'DEFAULT': SubmitButton
    }
)

const CoreEmailForm = (props) => {
    const {
        values,
        touched,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        loadState, 
        errorMsg,
        label,
        successPath,
        includeName,
        includePassword,
        includeTos,
        excludeEmail
    } = props;

    const { t } = useTranslation();
    const tosOnly = includeTos && !includeName && excludeEmail && !includePassword;

      return (
        <form onSubmit={handleSubmit} style={{marginBottom: '10px', marginTop: '10px', backgroundColor: 'white'}}>
            {includeName && 
                <div style={{padding: '10px'}}>
                    <InputWithErrors
                        className="mb0 mt0"
                        label={`${t('First Name')} (${t("optional")})`}
                        name="firstName"
                        values={values}
                        errors={errors}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        inProps={{id: 'first-name-field'}}
                        
                    />
                </div>
            }
            {!excludeEmail && (<div style={{padding: '10px'}}>
                <InputWithErrors
                    className="mb0 mt0"
                    label={t('Email')}
                    name="email"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    inProps={{id: 'email-field'}}
                    
                />
            </div>)}

            {includePassword && <div style={{padding: '10px'}}>
                <InputWithErrors
                    className="mb0 mt0"
                    label={t('Password')}
                    name="password"
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    inProps={ {id: 'password-field', type: 'password'} }
                />
            </div>}

            {includeTos && (
                <div className={ tosOnly ? "pa10 mt20 text-center" : " pl10 pr10 pb10 text-center" }>
                    <Checkbox
                        inputProps={{ 
                            name: 'acceptedTos', 
                            onChange: handleChange, 
                            onBlur: handleBlur, 
                            value: true,
                            checked: !!values.acceptedTos
                        } } 
                        label={<Trans i18nKey="accept tos"><Link target='_blank' to={webTosPath()}></Link><Link target='_blank' to={webPrivacyPath()}></Link></Trans>} 
                        filled
                        error={(touched.acceptedTos && errors.acceptedTos) ? t('Please accept the terms') : null}
                        className="no-wrap valign-wrapper cntr"
                        id="accept-tos-check"
                    />
                </div>
            )}

            <LoadingContainer loadState={loadState} successPath={successPath} errorMsg={errorMsg} label={label} />
        </form>
      )
}

class EmailForm extends Component {

    constructor(props) {
        super(props)

        const { isSwitcher } = this.props;

        if(this.props.oauthData) {
            const { curStatus} = this.props;
            this.isOauth = true;
            this.initialValues = { ...this.props.oauthData, acceptedTos: '' }
            let schema = {
                acceptedTos: Yup.boolean().required()
            }
            if(curStatus === 'emailMissing') {
                this.initialValues.email = '';
                schema.email = Yup.string().email().required();
            } else {
                this.excludeEmail = true;
            }
            this.validationSchema = Yup.object().shape(schema)
        } else if(this.props.signupData) {
            this.signup = true;
            let extraValues = {};
            if(this.props.signupData.hasOwnProperty('isTrainer')) {
                this.excludeName = true;
            } else {
                this.excludeName = false;
                extraValues = this.props.signupData;
            }
            this.initialValues = { email: '', password: '', ...extraValues, acceptedTos: '' };

            this.validationSchema = Yup.object().shape({
                email: Yup.string().email().required(),
                password: Yup.string().min(5).max(128).required(),
                acceptedTos: Yup.boolean().required()
            })
        } else {
            this.initialValues = { email: '', password: ''}
            this.validationSchema = Yup.object().shape({
                email: Yup.string().email().required(),
                password: Yup.string().required()
            })
        }
        this.initialValues.isSwitcher = isSwitcher;
        this.state = { status: 'DEFAULT', errorMsg: null }
    }

    render() {
        const { successPath, label, setError } = this.props;
        
        return (
            <Formik
                initialValues={this.initialValues}
                validationSchema={this.validationSchema}
                onSubmit={(values, {setSubmitting, isSubmitting }) => {
                    if(!isSubmitting) {
                        this.setState({status: 'REQUEST'})
                        const handleApiResponse = (response) => {
                            if(!response.isCanceled) {
                                if(response.data && response.data.error && setError) {
                                    setError(response.data.error);
                                } 
                                this.setState({status: response.status, errorMsg: (response.data && response.data.error) });
                                setSubmitting(false)
                            }
                        }
                        this.loginPromise = Utils.makeCancelable(this.props.loginUser(values))
                        this.loginPromise.promise.then(handleApiResponse).catch(handleApiResponse)
                    }
                }}
                >
                {props => {
                    let errorMsg = this.state.errorMsg;

                    return (<CoreEmailForm 
                                includeName={this.signup && !this.excludeName}
                                includePassword={!this.isOauth}
                                includeTos={this.isOauth || this.signup}
                                excludeEmail={this.excludeEmail}
                                successPath={successPath} 
                                label={label} 
                                errorMsg={errorMsg}
                                loadState={this.state.status}
                                {...props}
                            />
                    )
                }}
            </Formik>
        )
    }

    componentWillUnmount() {
        if(this.loginPromise){
            this.loginPromise.cancel();
        }
    }

}


const mapDispatchToProps = (dispatch) => ({
    loginUser: (data) => dispatch(loginUser(data))
});

const mapStateToSignupProps = state => ({
    signupData: signupFlowDataSelector(state)
})

const mapStateToInviteProps = state => ({
    signupData: trainerInviteDataSelector(state)
})

const mapStateToTrainerSignupProps = state => ({
    signupData: { isTrainer: true }
})

const mapDispatchToSignupProps = dispatch => ({
    loginUser: (data) => dispatch(newEmailUser(data))
})

const EmailSignupForm = connect(mapStateToSignupProps, mapDispatchToSignupProps)(EmailForm)
const EmailMissingForm = EmailForm;
const TCEmailSignupForm = connect(mapStateToInviteProps, mapDispatchToSignupProps)(EmailForm)
const TrainerEmailSignupForm = connect(mapStateToTrainerSignupProps, mapDispatchToSignupProps)(EmailForm)

export { EmailSignupForm, EmailMissingForm, TCEmailSignupForm, TrainerEmailSignupForm };

const EmailLoginForm = connect(null, mapDispatchToProps)(EmailForm);
export default EmailLoginForm;