import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, progressionPickMatch, workoutPreviewMatch, workoutDoMatch, progressionShowMatch, editWorkoutTemplateMatch, editExerciseGroupMatch, swapExerciseTemplatePathFor, allMainMatches } from 'config/paths';
import { swapExerciseTemplate } from 'redux/actions';
import { connect } from 'react-redux';
import { mapStateToStandaloneProps, mapDispatchToProps } from 'views/EditRoutine';
import { StandaloneExerciseSearch } from 'partials/ExerciseSearchForm';
import { Redirect } from "components/Routing";
import { PickButtonLC } from 'views/ProgressionPick';
import { LoadingContainerParent, Loader } from 'components/LoadingHOC';
import { resolvedHomePath } from 'redux/helpers';

const actionComp = (swapExerciseTemplate,exerciseTemplate,afterAction) => ({ exercisable, setBlockModals }) => {

    return (<LoadingContainerParent 
        load={swapExerciseTemplate.bind(null,{ ...exerciseTemplate.getActionParams(), ...exercisable.swapParams() })}
        preloaded={() => false}
        component={PickButtonLC}
        setBlockModals={setBlockModals}
        successCallback={(data) => afterAction(exercisable,data)}
        failCallback={() => setBlockModals(false)}
        className={`ex-swap-btn-${exerciseTemplate.id}`}
        skipAutoLoad
    />)
}

class SwapTemplateSuccess extends React.Component {

    render() {
        const { workoutRoutine, workoutTemplateId, exerciseTemplateId, swapExerciseTemplate } = this.props;
        const workoutTemplate = workoutRoutine && workoutRoutine.findWorkoutTemplate(workoutTemplateId);
        const exerciseTemplate = workoutTemplate && workoutTemplate.findExerciseTemplate(exerciseTemplateId);


        if(exerciseTemplate) {
            return (
                <StandaloneExerciseSearch 
                    context={{id: exerciseTemplate.id, type: 'ExerciseTemplate'}} 
                    initialValues={exerciseTemplate.exerciseSearchValues()} 
                    basePath={swapExerciseTemplatePathFor(exerciseTemplate.getActionParams())}
                    actionComp={actionComp(swapExerciseTemplate,exerciseTemplate,this.finish)}
                />
            )
        } else {
            return (<Redirect to={resolvedHomePath()} />)
        }
    }

    finish = () => {
        const { history } = this.props;

        if(!this.finished) {
            history.go(-1);
            this.finished = true;
        }
    }

    componentWillUnmount() {
        this.finished = true;
    }
}

const mapDispatchToSwapProps = dispatch => ({
    swapExerciseTemplate: (data) => dispatch(swapExerciseTemplate(data))
})

SwapTemplateSuccess = connect(null,mapDispatchToSwapProps)(SwapTemplateSuccess)

const transitionMap = {
    rules: [
        [[ ...allMainMatches, workoutLogMatch, workoutPreviewMatch, workoutDoMatch, editWorkoutTemplateMatch, editExerciseGroupMatch ] ,transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch], transitions.slideOver]
    ]
};

class SwapExerciseTemplate extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { workoutRoutine, loadRoutine, warmupsNeedLoading, match: { params: { id, workoutTemplateId, exerciseTemplateId }}, history, t, scrollRef } = this.props;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t("Swap Exercise")}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <Loader
                        load={loadRoutine.bind(null,id,warmupsNeedLoading)}
                        preloaded={() => (workoutRoutine && workoutRoutine.isLoaded())}
                        successComponent={SwapTemplateSuccess}
                        type='page'
                        workoutRoutine={workoutRoutine}
                        workoutTemplateId={workoutTemplateId}
                        exerciseTemplateId={exerciseTemplateId}
                        history={history}
                    />
                </SimpleNavContainer>
            </Page>
        )
    }
}



export default connect(mapStateToStandaloneProps,mapDispatchToProps)(withTranslation()(SwapExerciseTemplate));