import { calRoundFactor } from 'config/settings';
import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { MacroParam, MacroParams } from './macro-param';

export class DailyNutritionProfile extends RecordBase {
    static NAME = 'DailyNutritionProfile'

    static ASSOCS = {
        user: { type: 'belongsTo' },
        nutritionParameters: { type: 'hasMany' }
    }

    static FORM_FIELDS = ['id','name','overrideCalories', 'calorieOverride','fiberGoal','main','percentOfBase'];

    static defaultNew(attrs) {
        return new this({ id: null, main: false, name: null, overrideCalories: false, percentOfBase: null, calorieOverride: null, fiberGoal: null, weekdays: [], nutritionParameters: [], ...attrs });
    }

    static defaultMain(attrs) {
        return this.defaultNew({ main: true, ...attrs });
    }

    static buildForForm(dnpArr) {
        const arr = _.compact(dnpArr);
        if(arr.length <= 0) {
            return [_.parseObjForForm({ id: null, name: null, overrideCalories: false, calorieOverride: null, fiberGoal: null, nutritionParameters: MacroParams.buildForForm([]) })]
        }

        return arr.map(dnp => dnp.formValues());
    }

    static pickForForm(dnp) {
        return _.pick(dnp,this.FORM_FIELDS);
    }

    static byId(users,id) {
        return _.compact(_.compact(users).map(user => user.dnpById(id)))[0];
    }

    static targetCalsFor(baseCals,percentOfBase) {
        let resolvedPob = _.isNumeric(percentOfBase) ? Number(percentOfBase) : null;
        resolvedPob = (percentOfBase <= 0 || percentOfBase > 300) ? null : percentOfBase;
        if(this.main || _.isBlank(resolvedPob)) {
            return baseCals;
        }

        return baseCals*(resolvedPob/100.0)
    }

    static targetCalsReadableFor(baseCals,percentOfBase) {
        return _.roundToF(this.targetCalsFor(baseCals,percentOfBase),calRoundFactor)
    }

    static shortSummaryForForm({ nutritionParameters, percentOfBase },baseCals,t,fallbackParams) {
        const calTarget = this.targetCalsReadableFor(baseCals,percentOfBase);
        const macroParams = MacroParams.macroParamsFromForm(nutritionParameters,calTarget,t,fallbackParams);
        const macroSummary = _.macroTargetsSummary(t,macroParams.rawMacroParams(calTarget),', ');

        return macroSummary;
    }

    isInactive() {
        return !!this.inactive;
    }

    isActive() {
        return !this.isInactive();
    }

    isTemplate() {
        return this.type === 'DailyNutritionTemplate';
    }

    isProfile() {
        return!this.isTemplate();
    }

    formValues(signedInUser=null,percent=false) {
        const ret = _.pick(this,this.constructor.FORM_FIELDS);
        ret.nutritionParameters = MacroParams.buildForForm(this.overrideMacroParams(),false,percent);
        if(!this.main && !this.id && signedInUser && signedInUser.isTrainer()) {
            ret.saveToTemplate = true;
        }
        if(!this.main) {
            ret.weekdays = this.weekdaysArr()
        }
        return ret;
    }

    parsedFormValues(signedInUser=null,percent=false) {
        return _.parseObjForForm(this.formValues(signedInUser,percent));
    }

    targetCalories(override=null) {
        if(override && _.isNumeric(override)) {
            return override;
        }

        if(this.main) {
            override = this.overrideCalories ? this.calorieOverride : null;
            if(override) {
                if(_.isNumeric(override)) {
                    return override;
                } else {
                    return this.user.recommendedCalories;
                }
            } else {
                return this.user.recommendedCalories;
            }
        }

        return this.constructor.targetCalsReadableFor(this.user.baseTargetCalories(),this.percentOfBase)

    }

    targetCalsReadable() {
        return _.roundToF(this.targetCalories(),calRoundFactor)
    }

    macrosInitialized() {
        return (!_.isBlank(this.nutritionParameters) && !_.isEmpty(this.strippedNutritionParams()))
    }

    strippedNutritionParams() {
        return this.strippedNparams = this.strippedNparams || _.keyBy(this.nutritionParameters.map(param => _.omit(param,'dailyNutritionProfile')),'id');
    }

    activeNutritionParamIds() {
        if(this.anpis) {
            return this.anpis;
        }
        const overrides = _.filter(this.nutritionParameters,param => param.userOverride);
        if(overrides.length > 0) {
            this.anpis = overrides.map(param => param.id);
        } else {
            this.anpis = _.filter(this.nutritionParameters,param => !param.userOverride).map(param => param.id);
        }
        return this.anpis;
    }

    overrideMacroParams() {
        return MacroParams.userOverrideParams(this.strippedNutritionParams());
    }

    recMacroParams() {
        return MacroParams.macroParamsFromCol(_.recordsFromObjects(_.filter(Object.values(this.strippedNutritionParams() || {}),param => !param.userOverride),MacroParam));
    }

    activeMacroParams() {
        const ownParams = this.activeOwnMacroParams();
        if(this.main || !ownParams.isEmpty()) {
            return ownParams;
        }
        return this.user.baseActiveMacroParams()
    }

    activeOwnMacroParams() {
        return MacroParams.macroParamsFromCol(_.recordsFromIds(this.strippedNutritionParams(),this.activeNutritionParamIds(),MacroParam))
    }

    rawActiveMacroParams(targCals=null) {
        return this.activeMacroParams().rawMacroParams(targCals || this.targetCalsReadable());
    }

    macroWarningsFor(macroHash,calLeeway=60,t=(val) => val) {
        const rawParams = this.activeMacroParams().rawMacroParams(this.targetCalories());
        return MacroParams.macroWarningsHash(t,rawParams,macroHash,calLeeway);
    }

    weekdaysArr() {
        if(this.main) {
            return _.difference([0,1,2,3,4,5,6],this.user.overriddenDnpDays());
        } else {
            return this.weekdays || [];
        }
    }

    displayName(t) {
        if(this.main && _.isBlank(this.name)) {
            return t('Normal days')
        }

        const name = _.isBlank(this.name) ? t('Untitled') : this.name;

        if(this.isTemplate()) {
            return `${name} (${t('Template')})`;
        }

        return name;
    }

    daysSummary(t) {
        return _.shortWdaysDesc(this.weekdaysArr(),t);
    }

    shortSummary(t,baseCals,fallbackParams) {
        const params = this.activeOwnMacroParams().isEmpty() ? fallbackParams : this.activeOwnMacroParams();
        const targCals = this.constructor.targetCalsReadableFor(baseCals,this.percentOfBase);
        const macros = _.macroTargetsSummary(t,params.rawMacroParams(targCals),'|');
        const days = this.daysSummary(t);

        return `${days} ${macros}`;
    }

    isFromTemplate() {
        return !_.isBlank(this.templateId);
    }

    
}

registerInflection('dailyNutritionProfile','dailyNutritionProfiles',DailyNutritionProfile);
