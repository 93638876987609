import React from 'react';
import { offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, editWeeklyMealMatch, viewMealPathMatch, editTempMealPath, swapTempRecipePathFor, addTempRecipePathFor, viewTempRecipeReplacementPathFor, viewTempRecipeReplacementMatch, editRecipeMatch, emailPaywallMatches, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { userWithMealPlanSelector, recipeSearchFullSelector, tempMealSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { updateRecipeSearch, replaceTempRecipe, startReplaceTempRecipe } from 'redux/actions';
import RecipeReplacementPage, { selectActionCreator } from 'partials/RecipeReplacement';
import { getProPathCreator } from './FindRecipeReplacement';
import { resolvedHomePath } from 'redux/helpers';

const transitionMap = {
    rules: [
        [[...allMainMatches, offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, editWeeklyMealMatch, editTempMealPath],transitions.slideOut],
        [[viewMealPathMatch,viewTempRecipeReplacementMatch,...emailPaywallMatches,editRecipeMatch], transitions.slideOver]
    ]
};

let FindTempRecipeReplacement = (outerProps) => {

    return (
        <TempRecipeReplacementWrapper 
            {...outerProps}
            render={({ tempMeal, startSwapRecipe, recipeId, id, mealSearch, user, ...props }) => {
                const getPath = getProPathCreator(user,viewTempRecipeReplacementPathFor.bind(null,id,recipeId, mealSearch.mealCount()))

                return (
                    <RecipeReplacementPage
                        basePath={recipeId ? swapTempRecipePathFor(id,recipeId) : addTempRecipePathFor(id)}
                        getPath={getPath}
                        title={recipeId ? 'Replace Recipe' : 'Add Recipe'}
                        startSwapRecipe={startSwapRecipe.bind(null,tempMeal)}
                        transitionMap={transitionMap}
                        mealSearch={mealSearch}
                        user={user}
                        {...props}
                    />
                )
            }}
        />
    )
}

let TempRecipeReplacementWrapper = ({ tempMeal, render, mealSearch, selectAction, match: { params: { id, recipeId }}, ...rest }) => {
    let redirect = resolvedHomePath();
    if(tempMeal && mealSearch.matchesContext({ type: 'MealType', id: Number(id) })) {
        redirect = null;
    }

    return render({ tempMeal, recipeId, id: Number(id), redirect, mealSearch, selectAction: selectActionCreator(selectAction.bind(null,tempMeal),recipeId), ...rest })
}

const mapStateToProps = state => ({
    user: userWithMealPlanSelector(state),
    mealSearch: recipeSearchFullSelector(state),
    tempMeal: tempMealSelector(state)
})

const mapDispatchToProps = dispatch => ({
    selectAction: (meal,data) => dispatch(replaceTempRecipe(meal,data))
})

const mapDispatchToSearchPageProps = dispatch => ({
    startSwapRecipe: (meal,data) => dispatch(startReplaceTempRecipe(meal.rawMealObject(),data)),
    searchAction: (data,page) => dispatch(updateRecipeSearch(data,page))
})

TempRecipeReplacementWrapper = connect(mapStateToProps,mapDispatchToProps)(TempRecipeReplacementWrapper)

export { TempRecipeReplacementWrapper }

export default connect(null,mapDispatchToSearchPageProps)(FindTempRecipeReplacement);




