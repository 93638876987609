import React from 'react';
import classnames from 'classnames';

export const SplitButtonRow = ({ leftChildren, rightChildren, className, rightClasses, leftClasses, shrinkRight }) => {

    return (
        <div className={classnames("valign-wrapper",{ [className]: className })}>
            <div className={classnames("flex-grow text-left",{ [leftClasses]: leftClasses })}>
                {leftChildren}
            </div>
            <div className={classnames("text-right",{ [rightClasses]: rightClasses, 'flex-grow': !shrinkRight, 'flex-shrink': shrinkRight })}>
                {rightChildren}
            </div>
        </div>
    )
}