import React from 'react';
import { connect } from 'react-redux';
import { trainerHabitsPath } from 'config/paths';
import { withTranslation } from 'react-i18next';
import withTrainer from 'partials/TrainerLoader';
import { assignSchedulables, loadSchedulableSettings } from 'redux/actions';
import { habitRecordsByIdsSel } from 'redux/selectors';
import { AssignRecord, AssignRecords } from './AssignRecords';

class AssignHabit extends React.Component {

    render() {
        const { t } = this.props;

        return (
            <AssignRecord 
                {...this.props}

                title={t('Assign Habit')}
                formProps={{
                    recordName: 'habit', 
                    createRecordsPath: trainerHabitsPath, 
                    createRecordsTip: t('create habits tip'), 
                    createRecordsBtnLabel: t("Go to Habits Activity"),
                    recordsByIdsSel: habitRecordsByIdsSel
                }}
            />
        )
    }
}
class AssignHabits extends React.Component {

    render() {
        const { t } = this.props;

         return (
            <AssignRecords 
                {...this.props}
                title={t('Assign/Modify Habits')}
                formProps={{
                    recordName: 'habit', 
                    createRecordsPath: trainerHabitsPath, 
                    createRecordsTip: t('create habits tip'), 
                    createRecordsBtnLabel: t("Go to Habits Activity"),
                    recordsByIdsSel: habitRecordsByIdsSel
                }}
            />
        )
    }
}

export const mapDispatchToProps = dispatch => ({
    loadSettings: (data) => dispatch(loadSchedulableSettings({ recordType: 'Habit', ...data })),
    assignSettings: data => dispatch(assignSchedulables({ recordType: 'Habit', ...data }))
})

AssignHabit = connect(null,mapDispatchToProps)(withTranslation()(withTrainer(false)(AssignHabit)));

export { AssignHabit }

export default connect(null,mapDispatchToProps)(withTranslation()(withTrainer(false)(AssignHabits)))