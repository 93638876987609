
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikWrapper from 'components/FormikWrapper';
import { CardForm, DefaultSelect, FullInput, ToggleButton, LoadingSubmitButton } from 'components/Form';
import { User} from 'lib/classes';
import { Loader } from 'components/LoadingHOC';
import Card, { BasicCardHeader } from 'components/Card';
import * as _ from 'lib/utilities';
import { BodyMeasurementsProgressCard, PercentileDonutChart } from './ProgressCharts';
import ActivityContainer from 'components/ActivityContainer';
import { SmallerNumberInput, ExtraSmallNumberInput } from 'components/TextInput';
import { MaleGraphic, FemaleGraphic } from './BodySvgs';
import { FlexIconTip } from 'components/Tooltipped';
import { validateAgeHeightWeight } from './UserFields';
import * as Yup from 'yup';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { InitialTip, SlightEmphasisTip } from './Utilities';
import { bodyMeasurementsSelector, bmUserSelector } from 'redux/selectors';
import { loadBodyMeasurements, createBodyMeasurements } from 'redux/actions';
import { connect } from 'react-redux';
import { Link } from 'components/Routing';
import MediaQuery from 'components/MediaQuery';
import { reqMeasures, mRecMeasures, fRecMeasures, mOptMeasures, fOptMeasures, allMeasures } from 'lib/body-measurements';

const derived = ['bodyFat','bmi','shoulderToWaist','waistToHip','height']
const metrics = [ ...derived, ...(_.difference(allMeasures,['neck'])) ]
const chartRows = gender => _.chunk(_.difference(metrics,User.male(gender) ? ['waistToHip'] : ['shoulderToWaist']),2)

export const metricProgressColors = {
    waist: '#ff0029',
    arms: '#377eb8',
    shoulders: '#66a61e',
    hips: '#984ea3',
    chest: '#00d2d5',
    thighs: '#ff7f00',
    neck: '#af8d00',
    forearms: '#7f80cd',
    calves: '#b3e900',
    bodyFat: '#c42e60',
    bmi: '#a65628',
    shoulderToWaist: '#f781bf',
    waistToHip: '#8dd3c7'
}

const titleForMetric = (metric,t) => {
    switch(metric) {
        case 'bodyFat':
            return t('Body Fat')
        case 'bmi':
            return t('BMI')
        case 'shoulderToWaist':
            return t('Shoulder/waist')
        case 'waistToHip':
            return t('Waist/hip')
        default:
            return _.upperFirst(t(metric))
    }
}

export const percentileTipFor = (metric,percent,gender,t,addendum) => {
    const isDerived = derived.includes(metric);
    const genderKey = User.male(gender) ? 'men' : 'women';
    const derivedKey = `greater than percent of ${genderKey}`;
    const measureKey = `larger than percent of ${genderKey}`;
    const topTitle = titleForMetric(metric,t)

    return _.isBlank(percent) ? null : `${topTitle}${addendum ? ` (${addendum})` : ''}: ${isDerived ? t(derivedKey, {percent}) : t(measureKey,{percent})}`;
}

const titleAndSubtitleFor = (metric,info,t,units,gender) => {
    const val = info && info.value;
    const percent = info && info.percentile;
    const topTitle = titleForMetric(metric,t);
    const tooltip = percentileTipFor(metric,percent,gender,t); 

    switch(metric) {
        case 'bodyFat':
            return { topTitle, label: _.handleBlankStr(val,`${val}%`), tooltip }
        case 'bmi':
            return { topTitle, label: _.handleBlankStr(val,`${val}`), tooltip }
        case 'shoulderToWaist':
            return { topTitle, label: _.handleBlankStr(val,`${t('Ratio')}: ${val}`), tooltip }
        case 'waistToHip':
            return { topTitle, label: _.handleBlankStr(val,`${t('Ratio')}: ${val}`), tooltip }
        default:
            return { topTitle, label: _.handleBlankStr(val,`${val} ${units}`), tooltip }
    }
}

const validateBodyMeasurements = (values) => {
    const ageHeightWeightErrs = validateAgeHeightWeight(values);

    let objShape = {
        waistSize: Yup.number().required()
    }
    const schema = Yup.object().shape(objShape);
    try {
        validateYupSchema(values, schema,true);
        return ageHeightWeightErrs;
    } catch(validationError) {
        return { ...yupToFormErrors(validationError), ...ageHeightWeightErrs };
    }
}

const BodyMeasurementCharts = ({ gender, isMetric, metrics, historyData }) => {
    const { t } = useTranslation();
    const units = isMetric ? t('cm') : t('in');
    const rows = chartRows(gender);
    let tooltipPlaced = false;

    return (
        <div >
            {rows.map((metricPair, rowIndex) => {

                return (
                    <div className="valign-wrapper cntr" key={metricPair[0]}>
                        {metricPair.map((metric,index) => {
                            const metricData = metrics[metric];
                            const percentile = metricData && metricData.percentile;
                            let innerEl = (
                                <Card className="pa10 text-center">
                                    <PercentileDonutChart 
                                        percentile={percentile}
                                        id={`${metric}-chart`}
                                        {...titleAndSubtitleFor(metric,metricData,t,units,gender)}
                                    />
                                </Card>
                            )
                            if(!tooltipPlaced && percentile) {
                                tooltipPlaced = true;
                                innerEl = (
                                    <InitialTip tipName={'bod_measure_info'} text={t('Tap/hover for more info')} delay={1000}>
                                        {innerEl}
                                    </InitialTip>
                                )
                            }

                            return (
                                <div className={`flex-grow ${index === 0 ? 'mr5' : 'ml5'}`} key={metric}>
                                    {innerEl}
                                </div>
                            );
                        })}
                    </div>
                )
            })}
        </div>
    )
}

const ScoreLegend = () => {
    const { t } = useTranslation();
    
    return (
        <div className="score-legend-container">
        <div className="score-legend"></div>
        <div className="top-title">100</div>
        <div className="bottom-title">0</div>
        <div className="title-container">
          <div className="legend-title">{t('Percentile')}</div>
        </div>
      </div>
    )
}

const HeightField = ({ isMetric, ...formikProps }) => {
    const { t } = useTranslation();

    if(isMetric) {
        return (
            <FullInput
                name="metricHeight"
                className="compact"
                label={`${t("Height")} (${t('cm')})`}
                component={SmallerNumberInput}
                formikProps={formikProps}
            />
        )
    } else {
        return (
            <div>
                <FullInput
                    name="footHeight"
                    className="inline compact"
                    label={`${t("Height")} ${t('ft')}`}
                    component={ExtraSmallNumberInput}
                    formikProps={formikProps}
                />
                <FullInput
                    name="inchHeight"
                    className="inline right-label compact"
                    label={`${t('in')}`}
                    component={ExtraSmallNumberInput}
                    formikProps={formikProps}
                />
            </div>
        )
    }
}

const Fields = ({ fields, unitLabel, formikProps }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {fields.map(field => (
                <div className="valign-wrapper flex-end" key={field}>
                    <FullInput
                        name={`${field}Size`}
                        className="compact"
                        label={`${t(field)} (${unitLabel})`}
                        component={SmallerNumberInput}
                        formikProps={formikProps}
                    />
                    <FlexIconTip icon="question-circle" msg={t(`${field} measure tip`)} size="1x" />
                </div>
            ))}
        </React.Fragment>
    )
}

const BodyMeasurementFields = ({showAge, ...formikProps}) => {
    const { t } = useTranslation();
    const { values: { unitPreference, gender } } = formikProps;
    const isMetric = User.metric(unitPreference);
    const isMale = User.male(gender);
    const weightField = isMetric ? 'metricWeight' : 'currentWeight';
    const unitLabel = isMetric ? t('kgs') : t('lbs');
    const measLabel = isMetric ? t('cm') : t('in');

    return (
        <React.Fragment>
            <div className="font-grey"><b>{t('Required')}</b></div>
            {showAge && (
                <FullInput
                    name={'age'}
                    className="compact"
                    label={t('Age')}
                    component={SmallerNumberInput}
                    formikProps={formikProps}
                />
            )}
            <HeightField isMetric={isMetric} {...formikProps} />
            <FullInput
                name={weightField}
                className="compact"
                label={`${t("Weight")} (${unitLabel})`}
                component={SmallerNumberInput}
                formikProps={formikProps}
            />
            <Fields fields={reqMeasures} unitLabel={measLabel} formikProps={formikProps} />
            <div className="font-grey mt15"><b>{t('Recommended')}</b></div>
            <Fields fields={isMale ? mRecMeasures : fRecMeasures} unitLabel={measLabel} formikProps={formikProps} />
            <div className="font-grey mt15"><b>{t('Optional')}</b></div>
            <Fields fields={isMale ? mOptMeasures : fOptMeasures} unitLabel={measLabel} formikProps={formikProps} />
        </React.Fragment>
    )
}

const BodyMeasurementsSuccess = ({ user, bodyMeasurements, createBodyMeasurements }) => {
    const { t } = useTranslation();
    const { measures, metrics, compareType, historyData } = bodyMeasurements;

    return (
        <ActivityContainer>
                <div className="col s12 m12 l8">
                    <div className="mt10">
                        <SlightEmphasisTip text={t('body measurements tip')} tipName='body_measurements_init_tip' />
                    </div>
                    <MediaQuery query={'(min-width: 993px)'} render={({ queryMatches: isLarge }) => {

                        return (
                            <FormikWrapper 
                                initialValues={ { ...user.bodyMeasurementValues(), ..._.parseObjForForm(measures), compareType: _.isBlank(compareType) ? 'civilians' : 'military' }}
                                submit={values => createBodyMeasurements(values,!isLarge)}
                                validate={validateBodyMeasurements}
                                initialErrors={{}}
                                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                    const { setFieldValue, values: { gender, unitPreference } } = rest;
                                    const toggleButton = (
                                        <ToggleButton 
                                            className="no-upcase btn-no-shadow"
                                            options={{
                                                [`${t('lbs')}/${t('in')}`]: 0, 
                                                [`${t('kgs')}/${t('cm')}`]: 1}} 
                                                value={unitPreference} 
                                                setValue={pref => setFieldValue('unitPreference',pref)
                                            } 
                                        />
                                    )
                                    const submitButton = (
                                        <div className="mb10">
                                            <LoadingSubmitButton 
                                                id="rank-me-btn"
                                                loadState={submitState}
                                                icon={'check'}
                                                label={t("Save")}
                                                noShadow
                                                outlined={!_.isEmpty(metrics)}
                                            />
                                        </div>
                                    )

                                    return (
                                        <CardForm onSubmit={handleSubmit} className="text-center pa0 mt10">
                                            <div className="row">
                                                <div className="col s7 m6 l6">
                                                    <div className="hide-on-med-and-up text-center">
                                                        {toggleButton}
                                                    </div>
                                                    {User.male(gender) && <MaleGraphic metrics={metrics} />}
                                                    {User.female(gender) && <FemaleGraphic metrics={metrics} />}
                                                    <div className="hide-on-med-and-up text-center">
                                                        {submitButton}
                                                    </div>
                                                </div>
                                                <div className="col s5 m4 l4">
                                                    <div className="no-wrap">
                                                        <div className="inline-block">
                                                            {!user.hasBasicProfile && (<DefaultSelect 
                                                                className="mb10"
                                                                name="gender" 
                                                                selectProps={ {style: { maxWidth: '100px' } }}
                                                                collection={User.genderCol(t)} 
                                                                {...rest} 
                                                            />)}
                                                            <BodyMeasurementFields {...rest} showAge={!user.hasBasicProfile} />
                                                            <DefaultSelect 
                                                                className="mt20"
                                                                name="compareType" 
                                                                labelHtml={<div className="font-grey"><b>{t('Rank Against')}</b></div>}
                                                                collection={[{ text: t('Civilians'), value: 'civilians' }, { text: t('Military'), value: 'military' }]} 
                                                                {...rest} 
                                                            />
                                                        </div>
                                                        <div className="inline-block text-center hide-on-small-and-down" style={{ verticalAlign: 'top'}}>
                                                            {submitButton}
                                                            {toggleButton}
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <ScoreLegend />
                                        </CardForm>
                                    )
                                }}
                            />
                        )
                    }} />
                    {user && !_.isBlank(user.id) && (<div className="mt10">
                        <BodyMeasurementsProgressCard title={t('Progress')} user={user} />
                    </div>)}
                </div>
                <div className="col s12 m12 l4">
                    <BodyMeasurementCharts metrics={metrics} historyData={historyData} gender={user.gender} isMetric={user.isMetric()} />
                </div>
                <div className="col s12">
                    <Card>
                        <BasicCardHeader className="pt10">{t('About the Data')}</BasicCardHeader>
                        <div className="pl20 pr20 pb20 text-left">
                            <p className="mt0">{t("body measurements data1")}</p>
                            <p>{t("body measurements data2")}</p>
                            <p>{t("body measurements data3")}</p>
                            <b>{t('Sources')}</b>
                            <div className="overflow-hidden">
                                <p><Link to="https://www.dst.defence.gov.au/sites/default/files/publications/documents/DST-Group-GD-0968.pdf">https://www.dst.defence.gov.au/sites/default/files/publications/documents/DST-Group-GD-0968.pdf</Link></p>
                                <p><Link to="https://dined.io.tudelft.nl/en/database/tool">https://dined.io.tudelft.nl/en/database/tool</Link></p>
                                <p><Link to="https://www.bwc.ohio.gov/downloads/blankpdf/ErgoAnthropometricData.pdf">https://www.bwc.ohio.gov/downloads/blankpdf/ErgoAnthropometricData.pdf</Link></p>
                                <p><Link to="https://www.cdc.gov/nchs/data/nhsr/nhsr010.pdf">https://www.cdc.gov/nchs/data/nhsr/nhsr010.pdf</Link></p>
                                <p><Link to="https://www.cdc.gov/nchs/data/series/sr_03/sr03_039.pdf">https://www.cdc.gov/nchs/data/series/sr_03/sr03_039.pdf</Link></p>
                                <p><Link to="http://www.armyg1.army.mil/hr/Weight/docs/FriedlKarl%202002%20Mil%20Med.pdf">http://www.armyg1.army.mil/hr/Weight/docs/FriedlKarl%202002%20Mil%20Med.pdf</Link></p>
                                <p><Link to="https://wwwn.cdc.gov/nchs/nhanes/ContinuousNhanes/Default.aspx?BeginYear=2003">https://wwwn.cdc.gov/nchs/nhanes/ContinuousNhanes/Default.aspx?BeginYear=2003</Link></p>
                                <p><Link to="https://wwwn.cdc.gov/nchs/nhanes/ContinuousNhanes/Default.aspx?BeginYear=2003">http://www.dtic.mil/docs/citations/ADA611869</Link></p>
                            </div>
                        </div>
                    </Card>
                </div>
        </ActivityContainer>
    )
}

const mapStateToProps = (state) => ({
    user: bmUserSelector(state),
    bodyMeasurements: bodyMeasurementsSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    loadBodyMeasurements: () => dispatch(loadBodyMeasurements()),
    createBodyMeasurements: (data, flash) => dispatch(createBodyMeasurements(data,flash))
})

const BodyMeasurements = ({ user, loadBodyMeasurements, bodyMeasurements, createBodyMeasurements }) => {

    return (
        <Loader 
            successComponent={BodyMeasurementsSuccess}
            user={user}
            type="page"
            load={loadBodyMeasurements}
            preloaded={() => false}
            bodyMeasurements={bodyMeasurements}
            createBodyMeasurements={createBodyMeasurements}
        />
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(BodyMeasurements);