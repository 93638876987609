import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MiniProfile, User } from 'lib/classes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollableFormModal, ModalActionButtons } from 'components/Modal';
import { addPeopleToPlanModalPathFor, addPeopleToMealsPathFor, addPeopleToMealModalPathFor } from 'config/paths';
import Button, {  ConfirmActionButton, LoaderActionButton } from 'components/Button';
import { validateAgeHeightWeight, HeightWeightFields } from './UserFields';
import * as Yup from 'yup';
import FormikWrapper from 'components/FormikWrapper';
import { FullInput, DefaultSelect, LoaderOnlySubmitButton } from 'components/Form';
import TextInput, { SmallerNumberInput } from 'components/TextInput';
import { setAddPeopleSelection, updateMiniProfile, destroyMiniProfile, confirmSingleSharedMeal } from 'redux/actions';
import * as _ from 'lib/utilities';
import { connect } from 'react-redux';
import { Multiform } from 'components/ExpandableMultiform';
import { validateYupSchema, yupToFormErrors } from 'formik';

const valFilter = values => {
    let { currentWeight, unitPreference, calorieMethod, ...rest } = values;
    if(_.isBlank(calorieMethod)) {
        if(currentWeight) {
            rest.weight = currentWeight;
        }
    
        return rest;
    } else {
        return _.pick(values,['id','calorieMethod','name','calorieGoal','alwaysAddMeals']);
    }

}

const validateMiniProfile = values =>  {
    const addl = { name: Yup.string().min(1).max(128).required() };
    if(_.isBlank(values.calorieMethod)) {
        return validateAgeHeightWeight(values,addl);
    } else {
        let objShape = (Number(values.calorieMethod) === 1) ? { calorieGoal: Yup.number().min(800).max(4500).required() } : { calorieGoal: Yup.number().min(35).max(300).required() }
        const schema = Yup.object().shape(objShape);
        try {
            validateYupSchema(values, schema,true);
            return {};
        } catch(validationError) {
            return yupToFormErrors(validationError);
        }
    }
}

const DestroyButton = ({ load, id }) => {
    const { t } = useTranslation();

    return (
        <ConfirmActionButton onClick={load} render={({ ready, onClick }) => (
            <Button id={id} className="mr5" color={ready ? "red" : "grey"} rounded outlined onClick={onClick}>
                <FontAwesomeIcon icon={['fal','trash']} size='1x'/><span>{t('Delete')}</span>
            </Button>
        )} />
    )
}

const MiniProfileForm = ({ user, miniProfile, submit, destroy, showCancel, toggleSelect, toggleExpand }) => {
    const { t } = useTranslation();
    const [formKey,setFormKey] = useState(`${Math.random()}`);
    const newForm = !miniProfile;
    const id = newForm ? 'new' : miniProfile.id;
    const initVals = miniProfile ? miniProfile.formValues() : MiniProfile.defaultValues(user);
    const successCallback = ({ formikBag,data,values }) => {
        if(newForm) {
            toggleSelect(data.miniProfileId,true);
            setFormKey(`${Math.random()}`);
        }

        toggleExpand(id,false);
    }

    return (
        <FormikWrapper
            key={formKey}
            initialValues={ initVals }
            submit={submit}
            valuesFilter={valFilter}
            validate={validateMiniProfile}
            successCallback={successCallback}
            initialErrors={{}}
            render={({ submitState, handleSubmit, ...formikProps }) => {
                const { values: { calorieMethod } } = formikProps;
                const showExtraFields = _.isBlank(calorieMethod);

                return (
                    <form onSubmit={handleSubmit} className="ml10 mr10 text-left" >
                        <div className="valign-wrapper mb10">
                            <DefaultSelect
                                className="flex-grow"
                                numeric
                                label={t('Calorie method')}
                                name='calorieMethod'
                                collection={MiniProfile.calorieMethods(t)}
                                {...formikProps}
                            />
                        </div>
                        <div className="valign-wrapper">
                            <FullInput
                                label={t("Name")}
                                className="inline flex-grow"
                                name="name"
                                component={TextInput}
                                formikProps={formikProps}
                            />
                            {showExtraFields && (<FullInput
                                label={t("Age")}
                                className="inline ml10"
                                name="age"
                                component={SmallerNumberInput}
                                formikProps={formikProps}
                            />)}
                        </div>
                        {!showExtraFields && (
                            <div className="valign-wrapper">
                                <FullInput
                                    label={calorieMethod === 1 ? `${_.capitalize(t("calories"))}/${t('day')}` : t('pct of my calories') }
                                    className="flex-grow"
                                    name="calorieGoal"
                                    component={SmallerNumberInput}
                                    formikProps={formikProps}
                                />
                            </div>
                        )}
                        {showExtraFields && (<div className="valign-wrapper">
                            <DefaultSelect
                                className="mb20 flex-grow"
                                label={t('Gender')}
                                name='gender'
                                selectProps={{id: (newForm ? `new-form-gender` : `person-${miniProfile.id}-gender-select`)}}
                                collection={User.genderCol(t)}
                                {...formikProps}
                            />
                            <DefaultSelect
                                className="ml5 mb20 flex-grow"
                                label={t('Weight goal')}
                                name='weightGoal'
                                collection={User.weightGoalCol(t)}
                                {...formikProps}
                            />
                        </div>)}
                        {showExtraFields && (<div className="inline-block">
                            <HeightWeightFields 
                                className="no-wrap"
                                heightLabel={<div className="font-grey">{t('Height')}</div>}
                                weightLabel={<div className="font-grey">{t('Weight')}</div>}
                                heightClassName="inline-block"
                                weightClassName="inline-block ml30 mr15"
                                formikProps={formikProps}
                            />
                        </div>)}
                        <div className="valign-wrapper">
                            <DefaultSelect
                                className="mb20 flex-grow"
                                label={`${t('Always add to')}:`}
                                name='alwaysAddMeals'
                                selectProps={{ multiple: true, className: 'auto-height'}}
                                collection={User.mealTypesCol(t)}
                                {...formikProps}
                            />
                        </div>
                        <div className="mt10 mb10 text-center">
                            {destroy && (
                                <div className="inline-block">
                                    <LoaderActionButton 
                                        id={`destroy-person-${miniProfile.id}-btn`}
                                        component={DestroyButton}
                                        action={destroy.bind(null,miniProfile.id)}
                                    />
                                </div>
                            )}
                            {newForm && showCancel && (
                                <div className="inline-block">
                                    <Button className="mr5" color="primary" rounded outlined onClick={() => toggleExpand(id,false)}>
                                        <FontAwesomeIcon icon={'ban'} size='1x'/><span>{t('Cancel')}</span>
                                    </Button>
                                </div>
                            )}
                            <div className="inline-block">
                                <LoaderOnlySubmitButton 
                                    id={newForm ? `save-new-person-btn` : `update-person-${miniProfile.id}-btn`}
                                    icon="check"
                                    label={newForm ? t('Save and Add') : t('Save')}
                                    loadState={submitState}
                                    noShadow
                                />
                            </div>
                        </div>
                    </form>
                )
            }}
        />
    )
}

const ModalContent = ({ 
    selection, 
    mealInfoMatrix, 
    close, 
    closeAndGo, 
    renderHeader, 
    renderFooter, 
    renderScrollable, 
    type='multiple', 
    confirm,
    weeklyMeal,
    setSelection,
    submit, 
    destroy }) => {

    const { t } = useTranslation();
    const user = mealInfoMatrix.user;
    const noProfiles = mealInfoMatrix.user.miniProfiles.length === 0;

    return (
        <Multiform
            col={[ ...user.miniProfiles.map(p => ({ value: p.id, text: p.name, titleBlockClasses: 'inline-block', miniProfile: p, center: true, expandToggleId: `person-${p.id}-toggle` })), { value: 'new', text: `+ ${t('New Person')}`, titleBlockClasses: 'inline-block pl40', noCheck: true, center: true, expandToggleId: 'new-form-toggle' } ]}
            initialExpanded={noProfiles ? { new: true } : {}}
            initialSelected={selection ? _.mapValues(_.keyBy(selection,id => id),id => true) : {}}
            selectedVal={true}
            unselectedVal={false}
            entryFormRender={({ miniProfile, toggleExpand, toggleSelect, checkState }) => {
                const isNew = !miniProfile;
                const isSelected = checkState === 'checked';

                return (
                    <MiniProfileForm 
                        user={user}
                        miniProfile={miniProfile}
                        submit={submit}
                        destroy={!isSelected && !isNew && destroy}
                        toggleExpand={toggleExpand}
                        toggleSelect={toggleSelect}
                        showCancel={isNew && user.miniProfiles.length > 0}
                    />
                )
            }}
            render={({ entries, selection, expandedMap, isTouched })=> {
                selection = Object.keys(_.pickBy(selection,selected => selected)).map(id => Number(id));
                const disabled = noProfiles || (type === 'multiple' && selection.length === 0) || expandedMap.new;

                return (
                    <React.Fragment>
                        {renderHeader()}
                        {renderScrollable({ children: (<div className="pa10">{entries}</div>)})}
                        {renderFooter({
                            children: type === 'multiple' ? (
                                <React.Fragment>
                                    <Button id="cancel-add-people-btn" rounded outlined color="primary" className="mr5" onClick={close} >
                                        <FontAwesomeIcon icon="ban" />
                                        {t('Cancel')}
                                    </Button> 
                                    <Button 
                                        id="pick-shared-meals-btn" 
                                        rounded 
                                        noShadow 
                                        color={ "primary"} 
                                        disabled={disabled} 
                                        onClick={() => {
                                            if(!disabled) {
                                                setSelection(mealInfoMatrix.startDate,{ miniProfileIds: selection });
                                                closeAndGo(addPeopleToMealsPathFor(mealInfoMatrix.week));
                                            }
                                        }} 
                                    >
                                        <FontAwesomeIcon icon="arrow-right" />
                                        {`${t('Next')}: ${t('Pick Meals')}`}
                                    </Button>
                                </React.Fragment>
                            ) : (
                                <ModalActionButtons 
                                    icon="check"
                                    label={t('Confirm')}
                                    cancelLabel={t('Cancel')}
                                    action={confirm.bind(null,{ miniProfileIds: selection, weeklyMeal })}
                                    close={close}
                                    id="confirm-shared-profiles-btn"
                                    disabled={disabled || !isTouched}
                                />
                            )
                        })}
                    </React.Fragment>
                )
            }}
        />
    )
}

let AddPeopleModal = ({ basePath, mealInfoMatrix, ...rest }) => {
    const { t } = useTranslation();

    return (
        <ScrollableFormModal
            fullWidth 
            noOverflow 
            limitWidth 
            path={addPeopleToPlanModalPathFor(basePath)} 
            exact 
            title={t('People to Add/Remove')}
            icon="user-plus"
            render={({ close, closeAndGo, renderHeader, renderFooter, renderScrollable }) => {

                return (
                    <ModalContent
                        close={close}
                        closeAndGo={closeAndGo}
                        renderHeader={renderHeader}
                        renderFooter={renderFooter}
                        renderScrollable={renderScrollable}
                        type={'multiple'}
                        selection={mealInfoMatrix.user.miniProfiles.map(mp => mp.id)}
                        mealInfoMatrix={mealInfoMatrix}
                        {...rest}
                    />
                )
            }} 
        />
    )
}

const ContentWrapper = ({ weeklyMeal, ...rest }) => {

    return (
        <ModalContent
            weeklyMeal={weeklyMeal}
            type='single'
            selection={weeklyMeal.sharedProfileIds()}
            {...rest}
        />
    )
}

let AddPeopleToMealModal = ({ basePath, ...rest }) => {
    const { t } = useTranslation();

    return (
        <ScrollableFormModal
            fullWidth 
            noOverflow 
            limitWidth 
            path={addPeopleToMealModalPathFor(basePath)} 
            exact 
            title={t('Add People to Meal')}
            icon="user-plus"
            render={({ close, closeAndGo, renderHeader, renderFooter, renderScrollable }) => {
                return (
                    <ContentWrapper 
                        close={close}
                        closeAndGo={closeAndGo}
                        renderHeader={renderHeader}
                        renderFooter={renderFooter}
                        renderScrollable={renderScrollable}
                        {...rest}
                    />
                )
            }} 
        />
    )
}

const mapDispatchToProps = dispatch => ({
    setSelection: (startDate,selection) => dispatch(setAddPeopleSelection(selection,startDate)),
    submit: data => dispatch(updateMiniProfile(data)),
    destroy: id => dispatch(destroyMiniProfile(id))
})

const mapDispatchToSingleProps = dispatch => ({
    submit: data => dispatch(updateMiniProfile(data)),
    destroy: id => dispatch(destroyMiniProfile(id)),
    confirm: data => dispatch(confirmSingleSharedMeal(data))
})

AddPeopleToMealModal = connect(null,mapDispatchToSingleProps)(AddPeopleToMealModal)

AddPeopleModal = connect(null,mapDispatchToProps)(AddPeopleModal)

export { AddPeopleModal, AddPeopleToMealModal }