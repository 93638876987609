import { RecordBase, registerInflection } from 'lib/record-base';

export class RecipePreference extends RecordBase {
    static NAME = 'RecipePreference'

    static ASSOCS = {
        user: { type: 'belongsTo' }
    }

    isLike() {
        return this.rating === 1;
    }

    isDislike() {
        return this.rating === 0;
    }
}

registerInflection('recipePreference','recipePreferences',RecipePreference);