import { ModalSelectListing } from 'components/Form';
import { FullFormModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { syncRoutineSuffix } from 'config/paths';
import * as _ from 'lib/utilities';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath } from 'react-router';
import { trainerRecordSelector } from 'redux/selectors';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { loadUnsyncedClients, syncClientRoutines } from 'redux/actions';
import { IconTip } from 'components/Tooltipped';

let SyncClientRoutinesModal = ({ trainer, submit, basePaths, dismiss, loadClients }) => {
    const { t } = useTranslation();
    const [loadedData,setLoadedData] = useState({});
    const summary = loadedData.summary;
    const isSummary = !!loadedData.summary;
    const clients = trainer.unsyncedClients();
    const clids = _.filter(clients,client => !client.routineHasCustomizations()).map(client => client.id);
    const customizationsTip = <IconTip icon="exclamation-triangle" className="warning-color-i" msg={t("routine customizations tip")} size="1x" inline />;

    return (
        <FullFormModal 
            fullWidth 
            path={basePaths.map(path => `${path}${syncRoutineSuffix}`)} 
            scrollable 
            exact 
            hasCollapse
            exitButton={false}
            loadSuccess={data => setLoadedData(data)}
            loadForm={loadClients}
            icon={'cloud-download-alt'}
            title={t("Sync Client Routines?")}
            footerProps={{ cancelIcon: 'ban', cancelLabel: t("Don't Sync"), saveIcon: 'cloud-download-alt', saveLabel: t('Sync') }}
            formProps={ (props) => ({
                initialValues: isSummary ? { syncType: (summary.notCustomized > 0 ? 'no_customized' : 'all') } : { clientIds: _.arrayForForm(clids) },
                submit,
                validationSchema: isSummary ? Yup.object().shape({
                    syncType: Yup.string().required()
                }) : Yup.object().shape({
                    clientIds: Yup.array().compact().required().min(1)
                }),
                initialErrors: isSummary ? {} : (clids.length === 0 ? { clientIds: 'required' } : {})
            })}
            closeCallback={() => dismiss()}
            render={({ modalProps, ...formikProps }) => {

                if(isSummary) {
                    const num = (summary.customized + summary.notCustomized);
                    if(num === 0) {
                        modalProps.close();
                    }

                    return (
                        <div className="pa10" id="sync-routines-modal">
                            <div className="mb10">
                                <SlightEmphasisIconNote text={`${t("sync client summary msg", { num })} ${t('sync clients q')} ${summary.customized > 0 ? t('sync clients overwrite') : ''}`} />
                            </div>
                            {(summary.customized > 0 && summary.notCustomized > 0) && (<ModalSelectListing 
                                name='syncType'
                                single
                                col={[{ text: t('sync only nc clients', { num: summary.notCustomized }), value: 'no_customized' },{ text: t('sync all clients', { num }), value: 'all' }]}
                                {...formikProps}
                            />)}
                        </div>
                    )
                } else {
                    if(clients.length === 0) {
                        modalProps.close();
                    }
    
                    return (
                        <div className="pa10" id="sync-routines-modal">
                            <div className="mb10">
                                <SlightEmphasisIconNote text={`${t("sync client routines msg")} ${t('sync clients q')} ${clids.length < clients.length ? t('sync clients overwrite') : ''}`} />
                            </div>
                            <ModalSelectListing 
                                name='clientIds'
                                col={clients.map(client => ({ text: <React.Fragment>{client.fullName()}{client.routineHasCustomizations() ? customizationsTip : ''}</React.Fragment>, value: client.id, subtitle: client.assignedRoutine.resolvedName(t) }))}
                                {...formikProps}
                            />
                        </div>
                    )
                }
            }}
        />
    )
}

const mapStateToSyncModalProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToSyncModalProps = dispatch => ({
    submit: data => dispatch(syncClientRoutines(data)),
    dismiss: () => dispatch(syncClientRoutines({ clientIds: [''] })),
    loadClients: () => dispatch(loadUnsyncedClients())
});

class RoutineSyncChecker extends React.Component {

    componentDidMount() {
        this.doCheck();
        this.start = new Date().getTime();
    }

    componentDidUpdate() {
        const now = new Date().getTime();
        if(now - this.start <= 5000) {
            this.doCheck();
        }
    }

    render() {
        const { children, ...rest } = this.props;

        return (
            <React.Fragment>
                {children}
                <SyncClientRoutinesModal {...rest} />
            </React.Fragment>
        )
    }

    modalIsOpen = () => {
        const { location, basePaths } = this.props;

        return matchPath(location.pathname, { path: basePaths.map(path => `${path}${syncRoutineSuffix}`)})
    }

    doCheck = () => {
        const { trainer, history, location: { pathname } } = this.props;

        const routines = trainer.unsyncedRoutines();
        if(routines.length > 0 && !this.openedModal && !this.modalIsOpen()) {
            history.push(`${pathname}${syncRoutineSuffix}`);
            this.openedModal = true;
        }
    }
}

export default connect(mapStateToSyncModalProps,mapDispatchToSyncModalProps)(RoutineSyncChecker);
