import { FullFormModal, SubmitButtonFooter } from 'components/Modal';
import { editNoteModalMatchFor, newNoteModalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { FullInput } from 'components/Form';
import TextInput, { TextArea } from 'components/TextInput';
import { connect } from 'react-redux';
import { createTrainerNote, updateTrainerNote } from 'redux/actions';
import Button from 'components/Button';
import { SlightEmphasisIconNote } from 'components/Typography';

const TrainerNoteModal = ({ path, submit, initialVals, title, icon, ownsClient }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={icon}
            title={title}
            formProps={({ modalProps }) => {
                const vals = initialVals(modalProps);
                return {
                    initialValues: vals,
                    submit
                }
            }}
            footerProps={ownsClient ? null : { render: () => (<SubmitButtonFooter><Button color="primary" className="modal-close" rounded>{t('Ok')}</Button></SubmitButtonFooter>)}}
            render={({ modalProps, ...rest }) => {
                const { values, handleBlur, handleChange } = rest;
                const isView = !_.isBlank(values.id);

                if(isView || ownsClient) {
                    return (
                        <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                            <div className="valign-wrapper">
                                <FullInput
                                    className="flex-grow"
                                    name="subject"
                                    label={t('Subject')}
                                    component={TextInput}
                                    formikProps={rest}
                                    inProps={ownsClient ? {} : { id: "note-subject-field", disabled: true }}
                                />
                            </div>
                            <div className="text-center">
                                <TextArea 
                                    maxLength={25000} 
                                    rows={50} 
                                    style={{ width: '100%', height: '300px' }} 
                                    id="note-body-field"
                                    name='body' 
                                    value={values.body} 
                                    onChange={handleChange} 
                                    onBlur={handleBlur} 
                                    disabled={!ownsClient}
                                />
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div className="pa10" id="cant-create-note">
                            <SlightEmphasisIconNote text={t("no notes for other trainers clients")} />
                        </div>
                    )
                }
            }} 
        />
    )
}

let NewTrainerNoteModal = ({ baseMatch, submit, client, trainer }) => {
    const { t } = useTranslation();

    return (
        <TrainerNoteModal 
            path={newNoteModalPathFor(baseMatch)}
            icon="plus"
            title={t('New Note')}
            ownsClient={client.trainerId === trainer.id}
            initialVals={() => ({ subject: '', body: '',  clientId: client.id })}
            submit={submit}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(createTrainerNote(data))
})

NewTrainerNoteModal = connect(null,mapDispatchToProps)(NewTrainerNoteModal);

let EditTrainerNoteModal = ({ baseMatch, submit, client, trainer }) => {
    const { t } = useTranslation();
    const ownsClient = client.trainerId === trainer.id;

    return (
        <TrainerNoteModal 
            path={editNoteModalMatchFor(baseMatch)}
            icon={ownsClient ? ['far','edit'] : 'eye'}
            title={ownsClient ? t('Edit Note') : t('View Note')}
            ownsClient={ownsClient}
            initialVals={({ match: { params: { noteId }}}) => {
                const note = client.activeTrainerNoteById(noteId);
                return ({ subject: note.subject, body: note.body, id: noteId, clientId: client.id })
            }}
            submit={submit}
        />
    )
}

const mapDispatchToEditProps = dispatch => ({
    submit: data => dispatch(updateTrainerNote(data))
})

EditTrainerNoteModal = connect(null,mapDispatchToEditProps)(EditTrainerNoteModal);

export { NewTrainerNoteModal, EditTrainerNoteModal }