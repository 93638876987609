import React, { Component } from 'react';
import Page from'components/Page';
import LinkButton from 'components/LinkButton';
import AppSplash from 'components/AppSplash';
import * as transitions from 'assets/transitions';
import { useTranslation } from 'react-i18next';
import { loginPath, startMatch, loginMatch, onboardMatch} from 'config/paths';
import * as _ from 'lib/utilities';
import { resolvedStartPath } from 'redux/helpers';

const transitionMap = {
    rules: [
        [[startMatch,loginMatch,onboardMatch],transitions.slideOver]
    ]
};

function StartButton() {
    const { t } = useTranslation();
    return (
      <div style={ {marginBottom: "10px"} }><LinkButton color="primary" to={resolvedStartPath()} id="get-started-btn" rounded>{t('Get Started')}</LinkButton></div>
    );
  }
  
  function ExistingAccountButton() {
    const { t } = useTranslation();
    return (
      <LinkButton color="primary" to={loginPath} id="sign-in-btn" rounded variant={_.allowDirectSignup() ? "flat" : null}>{t('Sign In')}</LinkButton>
    );
  }

class StartScreen extends Component {

    constructor(props) {
        super(props)
        props.setupTransitions(transitionMap)
    }

    render() {
        const { scrollRef } = this.props;

        return (
            <Page ref={scrollRef} white>
                <AppSplash>
                        <div id="success" className="current-state">
                            <div>
                                {_.allowDirectSignup() && (<StartButton />)}
                                <ExistingAccountButton />
                            </div>
                        </div>
                </AppSplash>
            </Page>
        );
    }
}

export default StartScreen;
