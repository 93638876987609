import React from 'react';
import { Formik } from 'formik';
import { formikHandlerCreator } from 'lib/utilities';
import * as _ from 'lib/utilities';

export default class FormikWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.updateStatus = this.updateStatus.bind(this);
        this.waitingToSubmit = false;
        this.state = { submitState: 'DEFAULT' };
    }

    render() {
         const { validationSchema, validate, initialValues, initialErrors, submit, statusCallback, valuesFilter, render, autoSubmit, autoSubmitByDefault, autoSubmitTimeout, ...rest } = this.props

         return (
            <Formik
                initialValues={initialValues}
                initialErrors={initialErrors}
                validationSchema={validationSchema}
                validate={validate}
                validateOnChange
                onSubmit={formikHandlerCreator(this,this.updateStatus,submit,valuesFilter)}
            >
                {(formikBag) => {
                    let newFormikBag = formikBag;
                    const submitHandler = () => this.scheduleSubmission(formikBag,0);
                    if(autoSubmit) {
                        const handleAutoSubmitBlur = (e) => {
                            formikBag.handleBlur(e);
                            this.scheduleSubmission(formikBag, 100) //if timeout is zero this would interrupt change event's triggered by clicking to another field for some reason
                        }

                        const handleAutoSubmitChange = (e) => {
                            formikBag.handleChange(e);
                            this.scheduleSubmission(formikBag,autoSubmitTimeout || 0)
                        }

                        const setFieldValue = (field,value) => {
                            formikBag.setFieldValue(field,value);
                            this.scheduleSubmission(formikBag, 0)
                        }

                        const setValues = (values) => {
                            formikBag.setValues(values);
                            this.scheduleSubmission(formikBag, 0)
                        }
                        newFormikBag = autoSubmitByDefault ? { 
                            ...formikBag, 
                            setFieldValueNoSubmit: formikBag.setFieldValue, 
                            handleBlur: handleAutoSubmitBlur, 
                            setFieldValue, 
                            setValues, 
                            handleChange: handleAutoSubmitChange,
                            handleBlurNoSubmit: formikBag.handleBlur,
                            handleChangeNoSubmit: formikBag.handleChange
                        } : {
                            ...formikBag, 
                            setFieldValueNoSubmit: formikBag.setFieldValue, 
                            handleAutoSubmitBlur, 
                            setFieldValue, 
                            setValues, 
                            handleAutoSubmitChange 
                        };
                    }
                    const handleArrayChange = _.arrayChangeHandlerCreator(formikBag.setFieldValue,formikBag.setFieldTouched,autoSubmit ? submitHandler : null)
                    return render({ ...newFormikBag, handleArrayChange, ...rest, submitState: this.state.submitState })
                }}
            </Formik>
         )
    }

    updateStatus(status,formikBag,data,values) {
        const { statusCallback, successCallback } = this.props;
        statusCallback && statusCallback(status,formikBag,data,values);
        if(status === 'SUCCESS' && data && successCallback) {
            successCallback({ formikBag, data, values });
        }
        if(!this.unmounted) {
            this.setState({ submitState: status });
            if(this.waitingToSubmit && !this.nextSubmitTimeout) {
                this.waitingToSubmit = false;
                setTimeout(() => formikBag.submitForm(),0);
            }
        }
    }

    attemptSubmit = (formikBag) => () => {
        const { submitState } = this.state;
        this.nextSubmitTimeout = null;
        if(submitState === 'REQUESTING') {
            this.waitingToSubmit = true;
        } else {
            this.waitingToSubmit = false;
            formikBag.submitForm();
        }
    }

    scheduleSubmission = (formikBag,timeout) => {
        if(this.nextSubmitTimeout) {
            clearTimeout(this.nextSubmitTimeout);
        }
        this.nextSubmitTimeout = setTimeout(this.attemptSubmit(formikBag),timeout);
    }

    componentWillUnmount() {
        this.unmounted = true;
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }
}