import React from 'react';
import Page from'components/Page';
import { matchPath} from 'react-router';
import { Route } from "react-router-dom";
import { homePathProps, workoutPlanPath, errorLogsPath, mealPlanMatch, groceryListMatch, mainProgressPath } from 'config/paths';
import Today, { DayTabs } from 'views/Today';
import { MealPlanHeader, MealPlanMain } from 'views/MealPlan';
import WorkoutPlan from 'views/WorkoutPlan';
import GroceryList, { GroceryListTabs } from 'views/GroceryList';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { userRecordSelector, cachedWorkoutRecordsSelector, hasSingleDayMealsSel, trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { clearCachedWorkouts, logoutUser } from 'redux/actions';
import * as _ from 'lib/utilities';
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import { MainSidenav, MainNav } from 'partials/MainLayout';
import { mainTransitionMap as transitionMap } from 'partials/MainLayout';
import ErrorLogsPage from './ErrorLogs';
import { MainProgressPage } from './Progress';
import PDFSetupDone from './PdfSetupDone';
import NotificationRequestorWrapper from 'partials/NotificationRequestorWrapper';

class Main extends React.Component {

    constructor(props) {
        super(props)
        props.setupTransitions(transitionMap)
        const { cachedWorkouts, clearCachedWorkouts } = props;
        const needsClearedWorkouts = _.filter(cachedWorkouts,workout => (!workout || (workout.syncStatus === 'synced' && moment().subtract(3,'days').isAfter(moment(workout.date))) || !workout.shouldBeSynced()));

        clearCachedWorkouts(needsClearedWorkouts);
    }

    render() {
        const { user, trainer, scrollRef, location, logoutUser, hasSingleDayMeals } = this.props;
        const requestNotify = user && !_.signedInAsClient(trainer,user) && user.shouldRequestNotification();
        const now = moment();
        const classNames = classnames({"nav-container": true, "activity-tabs-container": this.hasActivityTabs(), 'activity-tabs-container-extra': this.hasMealPlanHeader(), "with-signed-in-as": (trainer && user && trainer.id !== user.id) });

        if(user && user.isPdfClient() && !_.signedInAsClient(trainer,user)) {
            return (
                <PDFSetupDone {...this.props} />
            )
        }

        return (
            <Page ref={scrollRef} >
                <div className={classNames}>
                    <RouteTransitionMap location={location} overrideScrollRef={scrollRef} canUpdate noTransitions>
                        <Today {...homePathProps} now={now} />
                        <MealPlanMain path={mealPlanMatch} />
                        <WorkoutPlan path={workoutPlanPath} />
                        <GroceryList path={groceryListMatch} />
                        <MainProgressPage path={mainProgressPath} />
                        <ErrorLogsPage path={errorLogsPath} />
                    </RouteTransitionMap>
                    <MainNav location={location} user={user} trainer={trainer} className={classnames({ 'with-mp-header': this.hasMealPlanHeader()})}>
                        {user.showDayTabs() && (<Route {...homePathProps} render={(routeProps) => (<DayTabs {...routeProps} now={now}/>)}/>)}
                        {user.mealPlanInitialized() && (<Route path={groceryListMatch} render={(routeProps) => (<GroceryListTabs {...routeProps} />)}/>)}
                        {(user.mealPlanInitialized() || hasSingleDayMeals) && (<Route path={mealPlanMatch} render={routeProps => <MealPlanHeader {...routeProps} />} />)}
                    </MainNav>
                </div>
                <MainSidenav user={user} trainer={trainer} logoutUser={logoutUser} basePath={this.trainerLeadBasePath()} />
                {requestNotify && <NotificationRequestorWrapper />}
            </Page>
        )
    }

    hasActivityTabs() {
        const { user, location: { pathname } } = this.props;
        return (user && user.showDayTabs() && matchPath(pathname,homePathProps)) || matchPath(pathname,mainProgressPath) || matchPath(pathname,groceryListMatch);
    }

    hasMealPlanHeader() {
        const { user, hasSingleDayMeals } = this.props;
        return (user.mealPlanInitialized() || hasSingleDayMeals) && matchPath(this.props.location.pathname,mealPlanMatch);
    }

    trainerLeadBasePath = () => {
        const { location: { pathname } } = this.props;
        return pathname.replace('/trainer','').replace('/menu','')
    }
}

const mapStateToProps = state => ({
    user: userRecordSelector(state),
    hasSingleDayMeals: hasSingleDayMealsSel(state),
    cachedWorkouts: cachedWorkoutRecordsSelector(state),
    trainer: trainerRecordSelector(state)
});

const mapDispatchToProps = dispatch => ({
    clearCachedWorkouts: (workouts) => dispatch(clearCachedWorkouts(workouts)),
    logoutUser: () => dispatch(logoutUser())
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Main));