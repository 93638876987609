import { RecordBase, registerInflection } from 'lib/record-base';

export class UserDataEntry extends RecordBase {
    static NAME = 'UserDataEntry'
    static ASSOCS = {
        user: { type: 'belongsTo' },
        formField: { type: 'hasOne', inverse: 'dataEntry' }
    }


}

registerInflection('userDataEntry','userDataEntries',UserDataEntry);