import React, { useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from './Button';
import PlaceholderImage from 'components/PlaceholderImage';
import { Link } from './Routing';

export const DismissCardButton = ({ onClick }) => {

    return (
        <IconButton size="2x" icon="times" onClick={onClick} className="upper-right-icon" style={{zIndex: 2, color: '#999'}} />
    )
}

export const CardAction = (props) => {
    const { className } = props;
    const classNames = classnames({ "card-action": true, [className]: className})
    return (
        <div className={classNames}>
            {props.children}
        </div>
    )
}

export const CardReveal = ({ children }) => {
    return (
        <div className="card-reveal">
            {children}
        </div>
    )
}

export const CardContent = (props) => {
    const { className } = props;
    const classNames = classnames({ "card-content": true, [className]: className})
    return (
        <div className={classNames}>
            {props.children}
        </div>
    )
}

export const CardImage = (props) => {
    const { src, className, noContent, children } = props;
    const classNames = classnames({ "card-image": true, [className]: className})
    return (
        <div className={classNames}>
            <img className={noContent ? 'no-content' : ''} src={src} alt='' />
            {children}
        </div>
    )
}

export const BasicCardHeader = ({ children, className }) => {
    return (
        <div className={classnames("default-card-header",{ [className]: className})}>
            {children}
        </div>
    )
}

export const CardImageWithPlaceholder = (props) => {
    const { src, className, noContent, width, height, children, color, setDims } = props;
    const classNames = classnames({ "card-image": true, [className]: className});
    const placeholderClassNames = classnames({'no-content': noContent});
    const [stateHeight,setHeight] = useState(height);

    const onImageLoad = (e) => {
        if(setDims) {
            const targ = e.target;
            if(targ) {
                const aspectRatio = (targ.width/Math.max(targ.height,1));
                setHeight(width/aspectRatio-2);
            }
        }
    }

    return (
        <div className={classNames}>
            <PlaceholderImage color={color} width={width} height={stateHeight} style={{width: '100%', display: 'block'}} className={placeholderClassNames} />
            {src && (<img onLoad={onImageLoad} className={noContent ? 'no-content' : ''} src={src} alt='' style={{position: 'absolute', top: 0, left: 0}}  />)}
            {children}
        </div>
    )
}

export const CardTitle = ({ icon, iconSize='1x', title, className }) => {
    const classNames = classnames("card-title", { [className]: className })
    return (
        <span className={classNames}>
            {title} {icon && (<FontAwesomeIcon icon={icon} size={iconSize} />)}
        </span>
    )
}

export const RespPsuedoImageCard = ({ double, clickable, children, className, ...rest }) => {
    const classNames = classnames("card static-aspect max-size-medium psuedo-image", { "buttonize": clickable, [className]: className});
    const contentCNames = classnames("card-content-psudo-routine",{"double": double});

    return (
        <div className={classNames} {...rest}>
            <div className="card-image" />
            <div className={contentCNames}>
                <div className="centering">
                    {children}
                </div>
            </div>
        </div>
    )
}

export const StaticAspectImageCard = ({ action, children, className }) => {
    const classNames = classnames("card static-aspect max-size-medium", { "sticky-action": action, [className]: className });

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}

export const ItemSelectCard = ({ className, active, children, ...rest}) => {
    const classNames = classnames({ "card signup-recipe-card buttonize": true, [className]: className, "active": active});

    return (
        <div className={classNames} {...rest}>
            {children}
        </div>
    )
}

export const IconOptionCard = ({ icon, title, subtitle, className, ...rest }) => {
    const classNames = classnames("init buttonize text-left",{ [className]: className})
    
    return (
        <Card className={classNames} {...rest} >
            <div className="display-flex" style={{alignItems: 'center'}}>
                <div className="pa10 font-grey">
                    <FontAwesomeIcon icon={icon} size='2x' />
                </div>
                <div className="flex-grow">
                    <div className="init-text-1">{title}</div> 
                    <div className="init-text-2">{subtitle}</div>
                </div>
            </div>
        </Card>
    )
}

export const InfoCard = ({ children }) => {
    return (
        <Card className="meal-editor-card me-nutrition valign-wrapper cntr">
            {children}
        </Card>
    )
}

const Card = React.forwardRef(({image, content, className, children, linkTo, ...rest },ref) => {
    let contentTag = null
    if(content) {
        let contents = typeof content === 'function' ? content() : content
        contentTag = <CardContent>{contents}</CardContent>
    }
    const imageTag = image ? <CardImage src={image} noContent={!contentTag} /> : ''
    const classNames = classnames({ "card": true, [className]: className})
    const Comp = linkTo ? Link : 'div'
    const addlProps = linkTo ? { to: linkTo } : {}

    return (
        <Comp className={classNames} {...rest} {...addlProps} ref={ref}>
            {children}
            {!children && imageTag}
            {!children && contentTag}
        </Comp>
    )
})

export default Card;