import React from 'react';
import { connect } from 'react-redux';
import { trainerMainMatches, myRoutinesPath, trainerHomePath, clientDetailsMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { Trans, withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import FormikWrapper from 'components/FormikWrapper';
import withTrainer from 'partials/TrainerLoader';
import { assignClientRoutines, loadRequiredTrainingDays } from 'redux/actions';
import { FlowQuestion } from 'components/FlowForm';
import { Checkbox, DateButtonSelect, LoaderOnlySubmitButton, ModalSelectListing, WeekdayButtonSelect } from 'components/Form';
import { dateFormat, TRAINING } from 'config/settings';
import { SlightEmphasisIconNote } from 'components/Typography';
import { Link } from 'components/Routing';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import moment from 'moment';
import classnames from 'classnames';
import { Loader } from 'components/LoadingHOC';
import { ClientSelectListing, SlightEmphasisTip } from 'partials/Utilities';
import { TCREATE_ROUTINE_TIP } from 'config/tooltips';
import { BottomButtons } from 'components/Button';

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [clientDetailsMatch,transitions.slideOut],
        [[], transitions.slideOver]
    ]
};

const validateCreator = (t,requiredTrainingDays) => ({ trainingDays, routineStart, newRoutine, clientIds }) => {
    const errors = {};

    if(_.isBlank(routineStart) && newRoutine !== 'pick') {
        errors.routineStart = t('Required');
    }

    if(_.isBlank(newRoutine)) {
        errors.newRoutine = t('Required');
    }

    if(_.isBlank(clientIds) || _.noBlanks(clientIds).length === 0) {
        errors.clientIds = t('Required');
    }

    if(requiredTrainingDays && _.isNumeric(newRoutine)) {
        if(_.noBlanks(trainingDays) < requiredTrainingDays) {
            errors.trainingDays = t('Pick at least', { count: requiredTrainingDays });
        }
    }

    return errors;
}

class PickDaysTip extends React.Component {

    constructor(props) {
        super(props);
        const { responseData: { requiredTrainingDays }, setFieldValue, setRequiredDays, touched } = this.props;
        setRequiredDays(requiredTrainingDays.length);
        if(!touched) {
            setFieldValue('trainingDays',requiredTrainingDays);
        }
    }

    render() {
        const { responseData: { requiredTrainingDays }, t } = this.props;

        return (
            <div>
                {t('Pick at least', { count: requiredTrainingDays.length })}
            </div>
        )
    }
}

const WeekdaySelect = ({ loadRequiredDays, t, setFieldValue, setFieldTouched, values, errors: { trainingDays: error }, touched: { trainingDays: touched }, setRequiredDays }) => {
    const subtitleCnames = classnames("flow-question-subtitle",{"red-text": error && touched});
    const { newRoutine } = values;

    if(!_.isNumeric(newRoutine)) {
        return '';
    }

    return (
        <div id="training-days-section">
            <FlowQuestion text={t("Schedule workouts on")} className="mb0" tooltip={t("trainer training days tip")} />
            <div className={subtitleCnames}>
                <Loader
                    key={newRoutine}
                    successComponent={PickDaysTip}
                    type="icon"
                    t={t}
                    load={() => loadRequiredDays(newRoutine)}
                    setFieldValue={setFieldValue}
                    setRequiredDays={setRequiredDays}
                    touched={touched}
                    preloaded={() => false}
                />
            </div>
            <WeekdayButtonSelect 
                startDay={0} 
                name="trainingDays"
                values={values} 
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                dayNames={moment.weekdaysShort()} 
            />
        </div>
    )
}
class AssignRoutine extends React.Component {

    constructor(props) {
        super(props);
        this.state = { requiredTrainingDays: null };
    }

    render() {
        const { 
            scrollRef, 
            setupTransitions, 
            t, title, initialValues, 
            history, 
            assignClientRoutines, 
            selectRender, hasRecords, 
            emptyMsg, 
            routineAlreadySelected,
            loadRequiredDays,
            showPickWarning,
            initialTip,
            trainer
         } = this.props;
        const { requiredTrainingDays } = this.state;

        return (
            <TrainerPaywall type={TRAINING}>
                <SimpleNavPage
                    scrollRef={scrollRef} 
                    transitionMap={transitionMap} 
                    setupTransitions={setupTransitions} 
                    title={title}
                >
                    <CenteredActivityContainer>
                        {!hasRecords && emptyMsg}
                        {hasRecords && (<FormikWrapper 
                            initialValues={ initialValues }
                            initialErrors={{ routineStart: 'required' }}
                            validate={validateCreator(t,requiredTrainingDays)}
                            submit={assignClientRoutines}
                            successCallback={() => {
                                history.go(-1);
                            }}
                            render={({ submitState, errors, handleSubmit, ...formikProps }) => {
                                const isPick = formikProps.values.newRoutine === 'pick';
                                const pickWarningShowing = isPick && showPickWarning;

                                const weekdaySelect = (
                                    <WeekdaySelect 
                                        loadRequiredDays={loadRequiredDays}
                                        t={t}
                                        setRequiredDays={this.setRequiredDays}
                                        errors={errors}
                                        {...formikProps}
                                    />
                                )

                                const allowEditingCheck = (
                                    <div className="pa15 mt20 text-center">
                                        <Checkbox 
                                            inputProps={{ 
                                                name: 'canEditOwnRoutine', 
                                                onChange: formikProps.handleChange, 
                                                onBlur: formikProps.handleBlur, 
                                                value: true,
                                                checked: formikProps.values.canEditOwnRoutine
                                            } } 
                                            label={t("allow edit own routine")} 
                                            filled
                                            className="no-wrap valign-wrapper cntr"
                                            tooltip={t("allow edit own routine tip")}
                                            tipOpts={{ position: 'top'}}
                                        />
                                    </div>
                                )

                                return (
                                    <form onSubmit={handleSubmit} id="assign-routine-form" className="mb100">
                                        {!isPick && trainer.isAppTrainer() && (
                                            <React.Fragment>
                                                <FlowQuestion text={t("Start new routine on")} />
                                                <DateButtonSelect 
                                                    dates={_.nextWeekCollection(dateFormat,false,'ddd')} 
                                                    name="routineStart"
                                                    single
                                                    stretch
                                                    {...formikProps}
                                                />
                                            </React.Fragment>
                                        )}
                                        {routineAlreadySelected && weekdaySelect}
                                        {selectRender(formikProps)}
                                        {!pickWarningShowing && initialTip}
                                        {!routineAlreadySelected && weekdaySelect}
                                        {!isPick && trainer.isAppTrainer() && allowEditingCheck}
                                        {pickWarningShowing && (
                                            <SlightEmphasisIconNote text={t("pick routine is immediate tip")} />
                                        )}
                                        <BottomButtons>
                                            <LoaderOnlySubmitButton 
                                                icon="check" 
                                                label={t("Assign")} 
                                                className="btn-wide" 
                                                loadState={submitState} 
                                                disabled={!_.isEmpty(errors)} 
                                            />
                                        </BottomButtons>
                                    </form>
                                )
                            }}
                        />)}
                    </CenteredActivityContainer>
                </SimpleNavPage>
            </TrainerPaywall>
        )
    }

    setRequiredDays = (days) => {
        this.setState({ requiredTrainingDays: days });
    }
}

const AssignClientRoutine = (props) => {
    const {  match: { params: { ids } }, trainer, t } = props;
    const idArr = ids.split('_');
    const clients = trainer.clientsByIds(idArr);
    const showPickWarning = _.some(clients,client => client.workoutPlanInitialized());
    const routines = trainer.activeWorkoutRoutines();
    const title = t('Assign New Routine');
    const initialValues = { clientIds: idArr, routineStart: moment().format(dateFormat), newRoutine: '', trainingDays: [''], canEditOwnRoutine: _.every(clients,client => client.canEditOwnRoutine) };
    let initialCol = [ { text: t('No Routine'), value: 'none' } ];
    const sharedTip = (<Trans i18nKey={'create routines tip'}><Link to={myRoutinesPath}></Link></Trans>);
    const tip = (
        <React.Fragment>
            {t('no records created tip', { record_name: t('routines')})} {sharedTip}
        </React.Fragment>
    );
    if(trainer.isAppTrainer()) {
        initialCol.push({ text: t("have client pick routine"), value: 'pick' });
    }

    const selectRender = (formikProps) => (
        <React.Fragment>
            <FlowQuestion text={t("Select a routine")} />
            <ModalSelectListing 
                col={[ ...initialCol, ...routines.map(wrt => ({ text: wrt.resolvedName(t), value: wrt.id }))]}
                name={'newRoutine'}
                single
                allowBlank
                boxClass="non-modal"
                hideUnselected
                {...formikProps}
            />
        </React.Fragment>
    )

    return (
        <AssignRoutine 
            selectRender={selectRender}
            title={title}
            initialValues={initialValues}
            hasRecords={routines.length > 0}
            routineAlreadySelected={false}
            emptyMsg={<SlightEmphasisIconNote text={tip} />}
            initialTip={<SlightEmphasisTip text={sharedTip} tipName={TCREATE_ROUTINE_TIP} />}
            showPickWarning={showPickWarning}
            {...props}
        />
    )
}

let AssignRoutineToClients = (props) => {
    const {  match: { params: { id } }, trainer, t } = props;
    const routine = trainer.routineById(id);
    const title = t('Assign name',{name: routine.resolvedName(t)});
    const initialValues = { clientIds: [''], routineStart: moment().format(dateFormat), newRoutine: routine.id, trainingDays: [''], canEditOwnRoutine: false };
    const tip = (<Trans i18nKey={'no clients created tip'}><Link to={trainerHomePath}></Link></Trans>);
    const getSelectProps = client => ({ subtitle: (client.assignedRoutine && client.assignedRoutine.resolvedName(t)) });

    const selectRender = (formikProps) => (
        <React.Fragment>
            <FlowQuestion text={t("Select clients to assign")} />
            <ClientSelectListing 
                trainer={trainer}
                initialFilters={{hasBasicProfile: true}}
                getSelectProps={getSelectProps}
                formikProps={formikProps}
                numName={'clientIds'}
                listingProps={ { boxClass: "non-modal", name: 'clientIds' } }
                excludeFilters={['trainerId','status']}
            />
        </React.Fragment>
    )

    return (
        <AssignRoutine 
            selectRender={selectRender}
            title={title}
            initialValues={initialValues}
            hasRecords={true}
            routineAlreadySelected={true}
            emptyMsg={<SlightEmphasisIconNote text={tip} />}
            {...props}
        />
    )
}

export const mapDispatchToProps = dispatch => ({
    assignClientRoutines: (data) => dispatch(assignClientRoutines(data)),
    loadRequiredDays: id => dispatch(loadRequiredTrainingDays(id))
})

AssignRoutineToClients = connect(null,mapDispatchToProps)(withTranslation()(withTrainer(false)(AssignRoutineToClients)))

export { AssignRoutineToClients }

export default connect(null,mapDispatchToProps)(withTranslation()(withTrainer(false)(AssignClientRoutine)))