import React, { useState } from 'react';
import loadingContainer, {LoadingContainerParent} from 'components/LoadingHOC';
import { Formik } from 'formik';
import { InfiniteScrollForm } from 'components/InfiniteScroll';
import { AutoSubmitTextInput } from 'components/TextInput';
import { ScrollableModalContent } from 'components/Modal';
import { ServingsToggleForm } from 'partials/ServingsToggleForm';
import { handleServingChgCreator, handleSeqChangeCreator, FatsecretWatermark } from 'partials/Utilities';
import DBLogForm from 'partials/DBLogForm';
import { useTranslation, withTranslation } from 'react-i18next';
import { LowOpacityText, PaddedIconNote, TinyText } from 'components/Typography';
import * as _ from 'lib/utilities';
import { foodDBSearchSelector } from 'redux/selectors';
import { foodDBSearch, populateFatsecret } from 'redux/actions';
import { connect } from 'react-redux';
import Button, { BottomButtons, IconButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RecordPanel, { ActionBox, ContentBox } from 'components/RecordPanel';
import { useHistory } from 'react-router-dom';
import { foodModalPathFor } from 'config/paths';
import { EditFoodModal } from './RecipeEditor';

const LoadingContainer = loadingContainer({
    'SUCCESS': ServingsToggleForm
});

const NewFoodPrompt = ({ topLevel, basePath, query }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <RecordPanel {...topLevel} >
            <ActionBox icon={"plus"} className="new-food-btn" label={t('New')} onClick={() => history.push(foodModalPathFor(basePath,query))}/>
            <ContentBox className="text-center">
                <TinyText>
                    <FontAwesomeIcon icon="info-circle" className="info-color"/> <LowOpacityText component={'span'}>{t('new food prompt')}</LowOpacityText>
                </TinyText>
            </ContentBox>
        </RecordPanel>
    )
}

const DBFoodForm = (props) => {
    let { food, load, allowNewMeasure, ...rest } = props;

    const { t } = useTranslation();
    const preloaded = () => !food.fatsecretResult;
    load = preloaded() ? null : load.bind(null,food.fatsecretId);
    const id = food.effectiveId();
    const weights = food.weightsForForm(null,allowNewMeasure,t);
    const [servings,setServings] = useState(food.defaultServings());
    const [seq,setSeq] = useState(food.defaultSeq());
    const foodWeight = food.foodWeightFor(seq);

    return (
        <DBLogForm 
            {...rest} 
            record={{ ...food, servings, seq, foodWeightId: (foodWeight && foodWeight.id) } } 
            valuesFilter={values => {
                if(!_.isBlank(values.seq)) {
                    const foodWeight = food.foodWeightFor(values.seq);
                    return { ...values, foodWeightId: (foodWeight && foodWeight.id) }
                }
                return { ...values, foodWeightId: '' };
            }}
            subtitle={food.macroSummary(_.isBlank(seq) ? food.defaultSeq() : seq,_.isBlank(seq) ? food.defaultServings() : servings,t)} 
            load={load} 
            preloaded={preloaded} 
            id={id} 
            weights={weights} 
            render={(formProps) => {
                return (
                        <LoadingContainerParent 
                            component={LoadingContainer}
                            borderBottom
                            {...formProps}
                            defaultIcon={props.defaultIcon}
                            setValueCreator={handleServingChgCreator(setServings)}
                            setSeqCreator={handleSeqChangeCreator(setSeq)}
                        />
                );
            }} 
        />
    )
}

export const FoodsList = ({ foods, maxIndex, log, loadFatsecret, t, date, triggerRequestRating, logIcon, allowNewMeasure, extraActionRender, showNewFoodPrompt, minFoodPromptIndex, basePath, query, recipeCounts }) => {
    const shared = { variant: "narrow", borderBottom: true };
    
    return (
        foods.map((food,index) => {
            const showPrompt = showNewFoodPrompt && index >= minFoodPromptIndex && index%15 === 0;
            const recipeCount = (recipeCounts && recipeCounts[food.id]) || 0;

            if(index < maxIndex) {
                return (
                    <React.Fragment key={food.effectiveId()}>
                        {showPrompt && (
                            <NewFoodPrompt topLevel={{ ...shared, borderTop: index === 0 }} basePath={basePath} query={query} />
                        )}
                        <DBFoodForm 
                            food={food} 
                            recipeCount={recipeCount}
                            topLevel={{
                                ...shared, 
                                borderTop: (index === 0 && !showPrompt)
                            }}
                            log={log}
                            load={loadFatsecret}
                            date={date} 
                            defaultIcon={logIcon}
                            allowNewMeasure={allowNewMeasure}
                            t={t}
                            triggerRequestRating={triggerRequestRating}
                            extraActionRender={extraActionRender}
                        />
                    </React.Fragment>
                )
            } else {
                return '';
            }
        })
    )
}

const ClearQueryButton = ({ setFieldValue }) => {

    return (
        <IconButton className="clear-text-btn" icon="times" onClick={() => setFieldValue('query','')} />
    )
}

const FoodsLoadingContainer = loadingContainer({
    'SUCCESS': FoodsList
})

const ScanButton = ({ className, onClick }) => {
    const { t } = useTranslation();

    return (
        <Button rounded color="primary" outlined className={className} onClick={onClick}>
            <FontAwesomeIcon icon="barcode"></FontAwesomeIcon> {t("Scan")}
        </Button>
    )
}

const DatabaseTab = (props) => {
    const { 
        log, 
        loadFatsecret, 
        t, 
        calcNonScrollDim, 
        date,
        foodDBSearch: {
            results: foods,
            more,
            page: loadedPage,
            query: initialQuery,
            hasGoodMatches,
            recipeCounts
        },
        load,
        triggerRequestRating,
        logIcon,
        allowNewMeasure,
        scanLaunchHandler,
        showNewFoodPrompt,
        basePath
     } = props;
     
     const [minFoodPromptIndex,setMinFoodPromptIndex] = useState(hasGoodMatches ? 10 : 0);
     const foodListProps = { foods, log, loadFatsecret, t, date, triggerRequestRating, logIcon, allowNewMeasure, showNewFoodPrompt, minFoodPromptIndex, basePath };
     let scrollProps = { component: 'div' };
     if(calcNonScrollDim) {
         scrollProps = { component: ScrollableModalContent, calcNonScrollDim };
     }

     return (
        <InfiniteScrollForm
            initialPage={1}
            initialMsg={t("Enter a search term to find foods in the database.")}
            noResultsComp={<PaddedIconNote variant="info" text={t("No foods match your search.")} />}
            initialValues={initialQuery}
            {...scrollProps}
            records={foods}
            loadedPage={loadedPage}
            load={load}
            more={more}
            responseCallback={({ status, data }) => {
                if(status === 'SUCCESS') {
                    const newFoodPromptIndex = data.search.hasGoodMatches ? 10 : 0;
                    if(newFoodPromptIndex !== minFoodPromptIndex) {
                        setMinFoodPromptIndex(newFoodPromptIndex);
                    }
                }
            }}
            id="database-foods-tab-scroll"
            render={({ maxIndex, startValues, submitLoad, retryLoad, status }) => {
                return (
                    <React.Fragment>
                        <Formik initialValues={ { query: startValues } }
                            onSubmit={(values) => {
                                submitLoad(values.query);
                            }}
                            validate={(values) => ({}) }
                            initialErrors={{}} >
                            {({ handleBlur, handleChange, handleSubmit, setFieldValue, values }) => {
                                return (
                                    <React.Fragment>
                                        <div className="position-relative">
                                            <FatsecretWatermark />
                                            <form className="pr10 pl10 modal-search-bar valign-wrapper" onSubmit={handleSubmit}>
                                                <div className="flex-grow position-relative">
                                                    <AutoSubmitTextInput 
                                                        label={t("Search")}
                                                        value={values.query}
                                                        submitForm={() => submitLoad(values.query)}
                                                        timeout={500}
                                                        inputProps={ {
                                                            name: "query",
                                                            onBlur: handleBlur,
                                                            onChange: handleChange,
                                                            id: 'db-foods-query-field'
                                                        } }
                                                    />
                                                    {!_.isBlank(values.query) && <ClearQueryButton setFieldValue={setFieldValue} />}
                                                </div>
                                                {scanLaunchHandler && (
                                                    <ScanButton onClick={scanLaunchHandler} className="ml5 hide-on-med-and-down" />
                                                )}
                                                {scanLaunchHandler && (
                                                    <BottomButtons className="large-screen-disappear">
                                                        <ScanButton onClick={scanLaunchHandler} className="shadow" />
                                                    </BottomButtons>
                                                )}
                                            </form>
                                        </div>
                                        <FoodsLoadingContainer 
                                            maxIndex={maxIndex} 
                                            load={retryLoad} 
                                            loadState={status} 
                                            query={values.query}
                                            recipeCounts={recipeCounts} 
                                            {...foodListProps} 
                                        />
                                        {showNewFoodPrompt && (
                                            <EditFoodModal basePath={basePath} submitLoad={query => {
                                                setFieldValue('query',query);
                                                submitLoad(query);
                                            }} />
                                        )}
                                    </React.Fragment>
                                )
                            }}
                        </Formik>
                    </React.Fragment>
                )
            }}
        >
        </InfiniteScrollForm>
    )
}

const mapDBTStateToProps = state => ({
    foodDBSearch: foodDBSearchSelector(state)
});

const mapDBTDispatchToProps = (dispatch, { parentId }) => ({
    load: (query,page) => dispatch(foodDBSearch(page,query)),
    loadFatsecret: (fatsecretId) => dispatch(populateFatsecret(fatsecretId))
});

export default connect(mapDBTStateToProps,mapDBTDispatchToProps)(withTranslation()(DatabaseTab));