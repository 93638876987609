import { RecordBase, registerInflection } from 'lib/record-base';
import NoImage from 'assets/img/NoImageExercise.png';
import * as _ from 'lib/utilities';
import { videoImgConcern } from './video-img-concern';

export class Exercisable extends RecordBase {

    static PUSH = ['horizontal push','vertical push']
    static PUSH_MUSCLES = ['chest','shoulders','triceps']
    static PULL = ['horizontal pull','vertical pull','shoulder health']
    static PULL_MUSCLES = ['biceps','forearms','upper back']
    static UPPER_BODY = [ ...this.PUSH, ...this.PULL ]
    static UPPER_BODY_MUSCLES = [...this.PUSH_MUSCLES, ...this.PULL_MUSCLES ]
    static LOWER_BODY = ['squat','hip hinge','hip hyperextension']
    static SQUAT_MUSCLES = ['quads','glutes']
    static HIP_HINGE_MUSCLES = ['glutes','hamstrings']
    static HYPEREXTENSION_MUSCLES = ['glutes','hamstrings','quads']
    static LOWER_BODY_MUSCLES = ['glutes','hamstrings','quads','calves']
    static CORE = ['plank','l-sit']
    static CORE_MUSCLES = ['lower back','abs','obliques']
    static EXERCISE_GROUPS = [ ...this.UPPER_BODY, ...this.LOWER_BODY, ...this.CORE, 'isolation', 'mobility']
    static MUSCLE_GROUPS = [ ...this.UPPER_BODY_MUSCLES, ...this.LOWER_BODY_MUSCLES, ...this.CORE_MUSCLES ]
    static MUSCLE_MAP = {
        'chest': ['general chest/pectoralis major (sternal)'],
        'shoulders': ['anterior deltoid','lateral deltoid'],
        'rear delts': ["posterior deltoid","supraspinatus"],
        'triceps': ['triceps brachii'],
        'biceps': ['biceps brachii','brachialis'],
        'forearms': ["brachioradialis","wrist flexors","wrist extensors"],
        'upper back': ["general back","latissimus dorsi and teres major"],
        'glutes': ['gluteus maximus'],
        'hamstrings': ['hamstrings'],
        'quads': ['quadriceps'],
        'calves': ["general calf/ gastrocnemius ","soleus"],
        'lower back': ["erector spinae"],
        'abs': ["rectus abdominis"],
        'obliques': ['obliques'],
        "neck": ["sternocleidomastoid","splenius"]
    }   
    static SIMPLE_TYPES = ['cardio', ...Object.keys(this.MUSCLE_MAP)]
    static EQUIPMENT_TYPES = [
        ['Dumbbell',3],
        ['Bodyweight',5],
        ['Barbell',8],
        ['Cable',10],
        ['Bodyweight + weight',12],
        ['Other weighted',101],
        ['Other unweighted',102]
    ]
    static REP_TYPES = ['Reps', 'Timed hold/stretch', 'Distance or time', 'Distance, reps, or time','General activity (time only)']

    static repTypesCol = t => this.REP_TYPES.map((repType,index) => ({ text: t(repType), value: index }))
    static simpleTypesCol = t => this.SIMPLE_TYPES.map((value) => ({ text: _.upperFirst(t(value)), value }))
    static equipmentTypesCol = t => this.EQUIPMENT_TYPES.map(([text,value]) => ({ text: t(text), value }))

    static setTypeMap = {0: 'Regular', 1: 'Timed AMRAP', 2: 'Reps for time', 3: 'Warmup reps', 4: 'Max test', 100: 'Time', 101: 'Distance', 102: 'Timed warmup', 103: 'Distance warmup', 104: 'Specific time goal', 105: 'Open ended activity'}
    static templateWeightTypeMap = { percent: 'Percent max', rpe: 'Difficulty', specific: 'Specific weight', referential: 'Referential' }
    static distanceTypeMap = { 0: 'meters', 1: 'kilometers', 2: 'miles'}

    static activitySearchValues() {
        return { searchTerm: '', equipmentTypes: [''], simpleTypes: ['cardio','activity'], contextId: 'log_activity' }
    }

    static textFor = (attr,value,t) => {
        return t(this[`${attr}Map`][value]);
    }

    simpleTypes() {
        if(this.cardio) {
            return ['cardio'];
        } else if(this.isGeneralActivity()) {
            return ['activity'];
        } else {
            const simpleMuscleGroups = this.simpleMuscleGroups();
            if(simpleMuscleGroups.length > 0) {
                return simpleMuscleGroups;
            } else {
                return [''];
            }
        }
    }

    simpleMuscleGroups() {
        const all = Object.keys(Exercisable.MUSCLE_MAP);
        const selfMuscles = this.muscleGroupsArr() || [];
        return _.filter(all,simpleMuscle => (_.intersection(Exercisable.MUSCLE_MAP[simpleMuscle],selfMuscles).length > 0))
    }

    muscleGroupsArr() {
        return this.muscleGroups ? this.muscleGroups.split(',') : [];
    }

    translatedMuscleGroups(t) {
        return this.simpleMuscleGroups().map(muscle => t(muscle));
    }

    exercisableType() {
        return this.constructor.NAME;
    }

    swapParams() {
        return { substituteType: this.exercisableType(), substituteId: this.id }
    }

    exerciseTemplateParams() {
        return { exercisableType: this.exercisableType(), exercisableId: this.id }
    }

}

export class ExerciseProgression extends Exercisable {
    static NAME = 'ExerciseProgression'
    static ASSOCS = {
        exerciseTemplates: { type: 'hasMany', inverse: 'exercisable' },
        progressionExercises: { type: 'hasMany', sortAttr: 'progressionLevel'},
        exerciseProgressionRequirements: { type: 'hasMany' }
    }

    isVideoOnly() {
        return false;
    }

    isGeneric() {
        return false;
    }

    canHaveDistance() {
        return false;
    }

    isProgression() {
        return true;
    }

    fullName(t) {
        return t('name progression', { name: this.name });
    }

    isIsometric() {
        return this.isometric;
    }

    isGeneralActivity() {
        return false;
    }

    isWeighted() {
        return false;
    }

    exercises() {
        return this.progressionExercises.map(progex => {
            progex.exercise.arbitraryMeasureVal = progex.arbitraryMeasure;
            return progex.exercise;
        });
    }

    defaultLevel() {
        const index = _.findIndex(this.progressionExercises,progex => progex.default);
        if(index === -1) {
            return 0;
        }

        return index;
    }

    defaultExercise() {
        return this.exercises()[this.defaultLevel()];
    }

    maxLevel() {
        return this.exercises().length-1;
    }

    resolvedThumbUrl() {
        if(this.image && this.image.thumb && this.image.thumb.url && !this.image.thumb.url.includes('NoImageExercise.jpg')) {
            return this.image.thumb.url;
        } else {
            return NoImage;
        }
    }
}

registerInflection('exerciseProgression','exerciseProgressions',ExerciseProgression);

export class Exercise extends Exercisable {
    static NAME = 'Exercise'
    static ASSOCS = {
        exerciseSpecifications: { type: 'hasMany' },
        exerciseTemplates: { type: 'hasMany', inverse: 'exercisable' },
        progressionExercises: { type: 'hasMany' },
        exerciseSettings: { type: 'hasMany' },
        owner: { type: 'belongsTo', tableName: 'users', inverse: 'customExercises' },
        formFields: { type: 'hasMany' },
        assessmentResults: { type: 'hasMany', inverse: 'source' }
    }

    isGeneric() {
        return this.generic;
    }

    hasProgressCharts() {
        return this.repType !== 2;
    }

    formValues() {
        const attrs = ['id', 'name', 'repType', 'equipmentType', 'instructions', 'videoUrl','videoOnly'];

        return _.parseObjForForm({ ..._.pickAll(this,attrs), simpleTypes: this.simpleTypes() });
    }

    isVideoOnly() {
        return this.videoOnly;
    }

    isProgression() {
        return false;
    }

    fullName() {
        return this.name;
    }

    instructionEntries() {
        return this.instructions.split(/\r\n/);
    }

    muscleGroupsArr() {
        if(_.isBlank(this.muscleGroups)) {
            return [];
        } else {
            return this.muscleGroups.split(',');
        }
    }

    isWeighted() {
        if(this.isVideoOnly()) {
            return false;
        }

        return this.weightType !== 0;
    }

    hasRepMax() {
        return (this.isWeighted() && this.maxType !== 2)
    }

    hasArbMeasure() {
        return (!this.isWeighted() && !_.isBlank(this.arbitraryMeasure));
    }

    lowerBody() {
        if(_.intersection(this.muscleGroupsArr(),Exercise.LOWER_BODY_MUSCLES).length > 0) {
            return true;
        } else {
            return false;
        }
    }

    isDumbell() {
        return (this.equipmentType === 3);
    }

    needsDumbellInfo() {
        return (this.isDumbell() && (!this.isUnilateral() || this.lowerBody()));
    }

    isWeightedBw() {
        if(this.isVideoOnly()) {
            return false;
        }

        return this.weightType === 2;
    }

    isAssistedBw() {
        return this.weightType === 3;
    }

    hasBaseWeight() {
        return (this.isWeightedBw() || this.isAssistedBw());
    }

    shouldHalveWeight() {
        return this.isUnilateral() || this.isDumbell() || this.hasBaseWeight();
    }

    isBarbell() {
        return this.isWeighted() && this.equipmentType === 8;
    }

    canHaveDistance() {
        return this.isDistOrTime() || this.isDistTimeOrReps();
    }

    isDistOrTime() {
        return this.repType === 2;
    }

    isDistTimeOrReps() {
        return this.repType === 3;
    }

    isRepsOnly() {
        return this.repType === 0;
    }

    isIsometric() {
        return this.repType === 1;
    }

    isGeneralActivity() {
        return this.repType === 4;
    }

    isAlternating() {
        return this.laterality === 2 && !this.cardio;
    }

    isUnilateral() {
        return this.laterality === 1 && !this.cardio;
    }

    listName() {
        if(this.hasArbMeasure() && this.arbitraryMeasureVal) {
            return `${this.name} (${_.abbrText(this.arbitraryMeasure,15)}: ${this.arbitraryMeasureVal})`;
        } else {
            return this.name;
        }
    }

    timePerRep() {
        if(this.isUnilateral()) {
            return 8;
        } else {
            return 4;
        }
    }

    strengthTestReps() {
        if(this.maxType === 1) {
            return 10;
        }

        return 5;
    }
}

Object.assign(Exercise.prototype,videoImgConcern);

registerInflection('exercise','exercises',Exercise);