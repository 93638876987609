import React from 'react';
import { withRouter } from 'react-router-dom';
import { Link as RRLink } from 'react-router-dom';
import classnames from 'classnames';
import { RedirectContext } from './RouteTransitionMap';

export const isExternal = path => path.match(/^http/);

class Redirect extends React.Component {

    componentDidMount() {
        const disableRedirects = this.context;
        const { history, to, push, delay } = this.props;

        if(!disableRedirects) {
            if(isExternal(to)) {
                setTimeout(() => {
                    if(push) {
                        window.location.href = to;
                    } else {
                        window.location.replace(to);
                    }
                },delay || 0)
            } else {
                if(push) {
                    if(delay) {
                        setTimeout(() => history.push(to),delay)
                    } else {
                        history.push(to);
                    }
                } else {
                    if(delay) {
                        setTimeout(() => history.replace(to),delay)
                    } else {
                        history.replace(to);
                    }
                }
            }
        }
    }

    render() {
        return '';
    }
}

Redirect.contextType = RedirectContext;
Redirect = withRouter(Redirect)

export { Redirect }

export const Link = React.forwardRef(({ to, children, openInApp, alwaysAtag, alwaysExternal, ...rest },ref) => {
    if(alwaysAtag) {
        return <a href={to} children={children} {...rest} ref={ref} />;
    }
    
    if(isExternal(to) || alwaysExternal) {
        if(window.isCordova && !openInApp) {
            const onClick = (e) => {
                e.preventDefault();
                window.cordova.InAppBrowser.open(to, '_system', '')
            }
            return <a href={to} children={children} {...rest} onClick={onClick} ref={ref} />;
        } else {
            return <a href={to} children={children} {...rest} ref={ref} />;
        }
    } else {
        return <RRLink to={to} children={children} {...rest} ref={ref} />
    }
})

export const NavLink = ({ active, className, ...rest }) => {

    const classes = classnames({ active, [className]: className });

    return (
        <Link {...rest} className={classes} />
    )
}

export const UnderlineLink = ({ className, ...rest }) => {

    return (
        <Link  className={classnames('underline clickable font-grey')} {...rest}/>
    )
}


