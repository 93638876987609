import React from 'react';
import * as _ from 'lib/utilities';
import MDropdown from 'assets/js/dropdown';
import classnames from 'classnames';
import { Link } from './Routing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlightEmphasisIconNote } from './Typography';
import ReactDOM from 'react-dom';
import { ConfirmActionButton } from './Button';

export class Dropdown extends React.PureComponent {

    constructor(props) {
        super(props);
        this.dropdownRef = React.createRef();
        this.onOpenStart = this.onOpenStart.bind(this);
        this.onCloseEnd = this.onCloseEnd.bind(this);
        this.recalcDims = this.recalcDims.bind(this);
        this.state = { open: false };
        this.targetId = "dropdown" + _.random(999999);
    }

    componentDidMount() {
        const { contentRender, recalcDims, fixed } = this.props;
        if(fixed) {
            this.domEl = document.createElement('ul');
            this.domEl.id = this.targetId;
            this.domEl.classList.add('dropdown-content');
            document.body.appendChild(this.domEl);
            ReactDOM.render(contentRender({ recalcDims }),this.domEl);
        }
        this.dropdown = MDropdown.init(this.dropdownRef.current, { container: (fixed ? document.body : null), fixed: fixed, ...this.props.options, hover: false, onOpenStart: this.onOpenStart, onCloseEnd: this.onCloseEnd });
    }

    componentDidUpdate() {
        const { contentRender, recalcDims, fixed } = this.props;
        if(fixed) {
            ReactDOM.render(contentRender({ recalcDims }),this.domEl);
        }
    }

    render() {
        const { contentComp: ContentComp='div', fixed } = this.props;

        return (
            <React.Fragment>
                {this.props.triggerRender({ ref: this.dropdownRef, target: this.targetId })}
                {!fixed && (<ContentComp className="dropdown-content" id={this.targetId}>
                    {this.state.open && this.props.contentRender({ recalcDims: this.recalcDims })}
                </ContentComp>)}
            </React.Fragment>
        )
    }

    componentWillUnmount() {
        if(this.domEl) {
            this.domEl.remove();
        }
        this.unmounted = true;
        this.dropdown.destroy();
    }

    onOpenStart() {
        if(!this.unmounted) {
            this.setState( {open: true} );
        }
    }

    onCloseEnd() {
        if(!this.unmounted) {
            this.setState( {open: false} );
        }
    }

    recalcDims() {
        this.dropdown.recalculateDimensions();
    }

}

export const DropdownTip = ({ text, ...rest }) => {

    return (
        <li className="pa10 no-hover" style={{ maxWidth: '200px' }} {...rest}>
            <SlightEmphasisIconNote text={text} />
        </li>
    )
}

const DropdownItem = React.forwardRef(({ icon, label, isDropdown, noClose, className, link, flagged, to, target, linkTarget, download, linkProps, compClassName, ...rest }, ref) => {
    const Comp = link ? Link : 'span';
    const compProps = link ? { to, target: linkTarget, ...linkProps } : { className: compClassName };

    return (
        <li ref={ref} data-target={target} className={classnames("no-wrap",{"position-relative": flagged,"dont-close-dropdown": (isDropdown || noClose), [className]: className})} {...rest}>
           {flagged && (<div className="notification-icon blue"></div>)}
           <Comp {...compProps}>
                {icon && <FontAwesomeIcon icon={icon} />} {label} {isDropdown && <FontAwesomeIcon icon='angle-right' />}
            </Comp>
        </li>
    )
})

const DropdownLink = React.forwardRef((props,ref) => (<DropdownItem link {...props} ref={ref} />))

const DropdownOption = React.forwardRef(({ name, value, setValues, values, icon, nullVal=null, ...rest},ref) => {
    const selected = values[name] === value;

    return (
        <DropdownItem icon={selected ? 'check' : icon} onClick={() => setValues({ ...values, [name]: (selected ? nullVal : value) })} {...rest} ref={ref} />
    )
})

const DropdownDelete = ({ action, label, icon, id }) => {

    return (
        <ConfirmActionButton
            onClick={action}
            render={({ onClick, ready }) => (
                <DropdownItem label={label} icon={icon} onClick={onClick} className={ready ? null : 'dont-close-dropdown'} compClassName={ready ? 'red-text' : null} id={id} />
            )}
        />
    )
}

const MultiLevelDropdown = ({ contentComp, options, children, ...rest}) => {

    return (
        <Dropdown 
            contentComp={contentComp || 'ul'} 
            options={{ 
                constrainWidth: false, 
                alignment: 'left', 
                coverTrigger: false, 
                hover: true, 
                multiLevel: true, 
                closeOnClick: false, 
                closeOnChildClick: false, 
                ...options 
            }} 
            contentRender={({ recalcDims }) => {
                return (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                )
            }}
            {...rest}
        />
    )
}

const ChildDropdown = ({ contentComp, options, triggerRender, icon, label, children, flagged, triggerId, ...rest}) => {

    return (
        <Dropdown 
            contentComp={contentComp || 'ul'} 
            options={{ 
                constrainWidth: false, 
                alignment: 'left', 
                coverTrigger: true, 
                hover: true, 
                offToSide: true, 
                closeOnClick: false,
                ...options 
            }} 
            triggerRender={triggerRender || (({ ref, target }) => {
                return (
                    <DropdownItem icon={icon} isDropdown label={label} ref={ref} target={target} flagged={flagged} id={triggerId} />
                )
            })}
            contentRender={({ recalcDims }) => {
                return (
                    <React.Fragment>
                        {children}
                    </React.Fragment>
                )
            }}
            {...rest}
        />
    )
}

export { DropdownItem, DropdownLink, DropdownOption, DropdownDelete, MultiLevelDropdown, ChildDropdown }

export default Dropdown;