import React from 'react';
import { mealPlanMatch, editWeeklyMealMatch, standaloneMealPlanMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { ProteinInfoButton, MealTypeListing } from 'partials/MealPlanViews';
import { mapStateToMpProps, mapDispatchToMpProps } from './MealPlan'
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BottomButtons, DefaultAsyncActionButton } from 'components/Button';
import { setAddPeopleSelection, confirmSharedMeals, setUnsavedChanges } from 'redux/actions';
import { addPeopleToMealsSelector } from 'redux/selectors';
import * as _ from 'lib/utilities';
import { Redirect } from 'components/Routing';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { resolvedHomePath } from 'redux/helpers';
import { ExitMealPlanButton } from 'partials/Utilities';

const transitionMap = {
    rules: [
        [mealPlanSlideOutPaths,transitions.slideOut],
        [[editWeeklyMealMatch], transitions.slideOver]
    ]
};

const getTempState = (weeklyMeal,removedIds,addedIds,miniProfiles) => {
    if(addedIds && addedIds.includes(weeklyMeal.initMeal().id)) {
        return true;
    } else if(removedIds && removedIds.includes(weeklyMeal.initMeal().id)) {
        return false;
    } else {
        return weeklyMeal.isSharedFor(miniProfiles);
    }
}

const ShareMealButton = ({ weeklyMeal, mealInfoMatrix, className, toggleShareState, removedIds, addedIds, miniProfiles }) => {
    const { t } = useTranslation();
    const curState = getTempState(weeklyMeal,removedIds,addedIds,miniProfiles);

    if(weeklyMeal.isOffPlan()) {
        return (<ProteinInfoButton className={className} msg={t("Off-plan meals can't be shared")} addendClass="oof-plan-add-people-btn" /> )
    } else {
        return (<div id={`share-meal-${weeklyMeal.id()}-btn`} className={classnames('add-people-btn lh1',{[className]: className, active: curState })} onClick={() => toggleShareState(weeklyMeal)}>
            <FontAwesomeIcon icon={curState ? 'minus' : 'plus'} className="mr5" /> 
            <span>
                {curState ? t('Remove') : t('Add')}
            </span>
        </div>)
    }
}

const Header = ({ miniProfiles }) => {
    const { t } = useTranslation();

    return (
        <div className="text-center mb15">
            <h4 className="font-white center mb5">{t("Add X to the meals you'll share", { names: miniProfiles.map(mp => mp.name).join('/') })}</h4>
        </div>
    )
}

class AddPeopleToMealsCore extends React.Component {

    render() {
        const { t, history, mealInfoMatrix, setSelection, selection, confirmSharedMeals, setUnsavedChanges } = this.props;
        const { miniProfileIds=mealInfoMatrix.user.miniProfiles.map(mp => mp.id), removedIds=[], addedIds=[] } = selection || {};
        const miniProfiles = _.filter(mealInfoMatrix.user.miniProfiles,miniProfile => miniProfileIds.includes(miniProfile.id))
        const saveChanges = ({removed, added, }) => {
            setUnsavedChanges({ type: 'shared', match: [mealPlanMatch,standaloneMealPlanMatch], submitData: { 
                miniProfileIds: miniProfiles.map(mp => mp.id), 
                removed: mealInfoMatrix.weeklyMealsForIds(removed), 
                added: mealInfoMatrix.weeklyMealsForIds(added) 
            }})
            setSelection(mealInfoMatrix.startDate,{ miniProfileIds: miniProfiles.map(mp => mp.id), removedIds: removed, addedIds: added })
        }

        const shareAll = () => {
            let added = [];
            const removed = [];
            mealInfoMatrix.allWeeklyMeals().forEach(weeklyMeal => {
                const persistedState = weeklyMeal.isSharedFor(miniProfiles);
                if(weeklyMeal.isShareable() && !persistedState) {
                    added.push(weeklyMeal.id());
                }
            })
            saveChanges({removed, added})
        }

        const removeAll = () => {
            const added = [];
            let removed = [];
            mealInfoMatrix.allWeeklyMeals().forEach(weeklyMeal => {
                const persistedState = weeklyMeal.isSharedFor(miniProfiles);
                if(weeklyMeal.isShareable() && persistedState) {
                    removed.push(weeklyMeal.id());
                }
            })
            saveChanges({removed, added})
        }

        const allAdded = _.every(mealInfoMatrix.allWeeklyMeals(),weeklyMeal => (getTempState(weeklyMeal,removedIds,addedIds,miniProfiles) || !weeklyMeal.isShareable()))

        const toggleShareState = (weeklyMeal) => {
            const persistedState = weeklyMeal.isSharedFor(miniProfiles);
            const newState = !getTempState(weeklyMeal,removedIds,addedIds,miniProfiles);
            let removed = _.without(removedIds,weeklyMeal.id());
            let added = _.without(addedIds,weeklyMeal.id());
            if(newState !== persistedState) {
                if(newState) {
                    added.push(weeklyMeal.id());
                } else {
                    removed.push(weeklyMeal.id());
                }
            }
            saveChanges({ removed, added });
        }
        
        const shareCheck = (weeklyMeal) => {
            if(removedIds.includes(weeklyMeal.id())) {
                return weeklyMeal.showShareIcon(miniProfiles);
            } else if(addedIds.includes(weeklyMeal.id())) {
                return true;
            } else {
                return weeklyMeal.isShared();
            }
        }

        if(miniProfiles.length > 0) {
            return (
                <React.Fragment>
                    <Header miniProfiles={miniProfiles} />
                    <MealTypeListing
                        noSidebar
                        mealInfoMatrix={mealInfoMatrix}
                        shareCheck={shareCheck}
                        ActionComp={(<ShareMealButton
                            toggleShareState={toggleShareState}
                            addedIds={addedIds}
                            removedIds={removedIds}
                            miniProfiles={miniProfiles}
                        />)}
                    />
                    <BottomButtons>
                        {!allAdded && (
                            <Button id="add-all-btn" rounded outlined color="primary" className="mr5 shadow no-wrap" onClick={shareAll}>
                                <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("Add All")}
                            </Button>
                        )}
                        {allAdded && (
                            <Button id="remove-all-btn" rounded outlined color="primary" className="mr5 shadow no-wrap" onClick={removeAll}>
                                <FontAwesomeIcon icon={'minus'}></FontAwesomeIcon> {t("Remove All")}
                            </Button>
                        )}
                        <DefaultAsyncActionButton 
                            loaderType={"loaderOnly"}
                            rounded 
                            color="primary" 
                            id={`confirm-shared-meals-btn`}
                            action={confirmSharedMeals.bind(null,{ 
                                miniProfileIds: miniProfiles.map(mp => mp.id), 
                                removed: mealInfoMatrix.weeklyMealsForIds(removedIds), 
                                added: mealInfoMatrix.weeklyMealsForIds(addedIds) 
                            })}
                            successCallback={(data) => (!this.dontGoBack && history.goBack())}
                        >
                                <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Confirm")}
                        </DefaultAsyncActionButton>
                    </BottomButtons>
                </React.Fragment>
            )
        } else {
            return (
                <Redirect to={resolvedHomePath()} />
            )
        }
    }

    componentWillUnmount() {
        this.dontGoBack = true;
    }
}

const mapDispatchToProps = dispatch => ({
    setSelection: (startDate,selection) => dispatch(setAddPeopleSelection(selection,startDate)),
    confirmSharedMeals: (data) => dispatch(confirmSharedMeals(data)),
    setUnsavedChanges: data => dispatch(setUnsavedChanges(data))
})

const mapStateToProps = (state,props) => ({
    selection: addPeopleToMealsSelector(state,props)
})

export const redirectCheckCreator = (user,week) => () => {
    if(!user.mealPlanInitialized() || user.mealPlanGenerateState(week) !== 'generated') {
        return resolvedHomePath();
    }
    return null;
}

AddPeopleToMealsCore = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AddPeopleToMealsCore));

const AddPeopleToMealsPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, mealInfoMatrix, history } = props;

    return (
        <SimpleNavPage
            darkMode
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t('Add People')}
            navChildren={<ExitMealPlanButton hideIfUnsavedChanges />}
            redirectCheck={redirectCheckCreator(mealInfoMatrix.user,mealInfoMatrix.week)}
        >
            <div className="limit-50-h">
                <AddPeopleToMealsCore 
                    mealInfoMatrix={mealInfoMatrix}
                    history={history}
                />
            </div>
        </SimpleNavPage>
    )
}

export default connect(mapStateToMpProps,mapDispatchToMpProps)(AddPeopleToMealsPage);