import { homePath, onboardPath, startPath, trainerBasicProfilePathFor, trainerConversionPathFor, trainerHomePath } from 'config/paths';
import { store } from './initializer';
import { trainerRecordSelector, trainerSelector, userRecordSelector} from './selectors';
import * as actionTypes from './actionTypes';
import * as _ from 'lib/utilities';
import * as api from 'lib/api';
import { emailSignupPathFor, conversionPathBase } from 'config/paths';
import { APPLE_HEALTH_SYNC_TIP } from 'config/tooltips';

export const resolvedStartPath = () => {
    const user = userRecordSelector(store.getState());
    if(user && user.isClient()) {
        return startPath;
    }
    return onboardPath;
}

export const resolvedHomePath = () => {
    const trainer = trainerSelector(store.getState());
    return (trainer ? trainerHomePath : homePath())
}

export const resolvedFoodsLabel = () => {
    const trainer = trainerRecordSelector(store.getState());
    return ((trainer && trainer.hasOtherTrainers()) ? 'Team Foods' : 'My Foods')
}

export const goWrapper = (history,go) => {
    store.dispatch({ type: actionTypes.PRE_HISTORY_POP, data: { goNum: go }})
    return history.go(go);
}

const resolveTrainerPath = (context,trainer) => {
    if(_.isBlank(trainer) || _.isBlank(trainer.businessName)) {
        return trainerBasicProfilePathFor(context);
    }

    if(_.isBlank(trainer.email)) {
        return emailSignupPathFor(context);
    }

    return trainerConversionPathFor(context);
}

export const conversionPathFor = (context,user,suffix) => {
    const trainer = trainerRecordSelector(store.getState());
    const convPath = `${conversionPathBase}/${context}${suffix ? `/${suffix}` : ''}`;

    if(trainer && (!user || user.id !== trainer.id)) {
        return resolveTrainerPath(context,trainer);
    }

    if(user === undefined || (user && !_.isBlank(user.email))) {
        return convPath;
    }
    return emailSignupPathFor(context);
}

export const fullTrainerConvPathFor = (context) => {
    const trainer = trainerRecordSelector(store.getState());
    return resolveTrainerPath(context,trainer);
}


export const paywallPathFor = (context,user) => {
    return conversionPathFor(context,user,'paywall');
}
export const paymentModalPathFor = context => `${conversionPathFor(context)}/payment`;

export const healthkitCallWrapper = (callback,fallback,fallbackUnlessAuthd=true) => {
    const user = userRecordSelector(store.getState());
    const trainer = trainerRecordSelector(store.getState());
    const alwaysFallback = !_.hasAppleHealth() || _.signedInAsClient(trainer,user) || (fallbackUnlessAuthd && (!user || !user.sawTooltip(APPLE_HEALTH_SYNC_TIP)));

    if(alwaysFallback) {
        fallback();
    } else {
        navigator.health.isAvailable(success => {
            if(success) {
                navigator.health.requestAuthorization(
                    ['calories',
                    'distance',
                    'activity',
                    'steps',
                    'weight',
                    'nutrition.calories',
                    'nutrition.fat.total',
                    'nutrition.carbs.total',
                    'nutrition.dietary_fiber',
                    'nutrition.protein', { read: ['appleExerciseTime'] }], 
                    () => {
                            if(!window.healthkitIsListening) {
                                navigator.health.observeChanges(api.healthkitUpdateUrl(),['activity',
                                'steps',
                                'weight',
                                'nutrition',
                                'appleExerciseTime'], () => {
                                    window.healthkitIsListening = true;
                                }, err => console.log(err));
                            }
                            callback();
                    }, 
                    err => fallback())
            } else {
                fallback();
            }
        }, err => fallback());
    }
}

export const healthkitRequestAuth = (callback,fallback) => {
    healthkitCallWrapper(callback,fallback,false);
}