import React from 'react';
import FormikWrapper from 'components/FormikWrapper';
import { validateAgeHeightWeight, AgeHeightWeightFields, ExclusionTags, ExtraExclusions, validateSelectedMeals, validateCustomMacros, validateBudgetVarietyComplexityDiet } from 'partials/UserFields';
import { User } from 'lib/classes';
import { CardForm, DefaultSelect, PercentagesSlider, formikInputProps, InputWithErrors, MultivalueCheckbox, ModalSelectListing, ListingRightBtn } from 'components/Form';
import * as _ from 'lib/utilities';
import { userRecordSelector, allergyTagsSelector, trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { updateSettings, updateMealPlan, loadDnps } from 'redux/actions';
import { FlexIconTip } from 'components/Tooltipped';
import { NumberInput } from 'components/TextInput';
import { UserMacroFields } from 'partials/CustomMacroFields';
import Card, { DismissCardButton } from 'components/Card';
import ProRequiredButton, { AsyncSpinnerTextTPB } from 'partials/ProRequiredButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/Button';
import { editRoutinePath, pickNewRoutineModalPathFor, settingsPath, workoutSetupMatch, notificationsPath, emailPaywallMatches, modalPathFor } from 'config/paths';
import LinkButton from 'components/LinkButton';
import { PickNewRoutineModal } from 'partials/EditRoutineModals';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { SignupModal } from 'partials/SignupModal';
import { IconNote, SlightEmphasisIconNote } from 'components/Typography';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { NotificationSettingsDescription, NotificationSettingsModal } from 'partials/NotificationSettings';
import classnames from 'classnames';
import { ClientSetupChecks } from 'partials/EditTrainerModal';
import { DnpModal, dnpModalSuffix, NewDnpButton } from 'partials/DnpModal';
import { useHistory, useLocation } from 'react-router-dom';
import { Loader } from 'components/LoadingHOC';

export const BasicProfileForm = ({ user, submit, autoSubmit, submitRender, successCallback, sendInvite, isClientSetup }) => {
    const { t } = useTranslation();
    const initVals = user ? user.basicProfileValues(true) : {};
    const initErrs = validateAgeHeightWeight(initVals);
    const showNotificationSettings = user && !isClientSetup;

    return (
        <React.Fragment>
            <FormikWrapper
                initialValues={ initVals }
                validate={(values) => validateAgeHeightWeight(values)}
                valuesFilter={User.filterUnitValues}
                submit={submit}
                initialErrors={initErrs}
                autoSubmit={autoSubmit}
                successCallback={successCallback}
                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                    const ageLabel = <div className="font-grey">{t('Age')}</div>;
                    const heightLabel = <div className="font-grey">{t('Height')}</div>;
                    const weightLabel = <div className="font-grey">{t('Weight')}</div>;
                    const selectProps = autoSubmit ? { ...rest, handleChange: handleAutoSubmitChange } : { ...rest };
                    const { errors } = rest;

                        return (
                            <React.Fragment>
                                <CardForm className="pa20" onSubmit={handleSubmit}>
                                    {rest.values.setupOwnProfile !== undefined && (
                                        <div className={classnames("row", { mb0: rest.values.setupOwnProfile })}>
                                            <div className="col s12">
                                                <div className="text-center pa15">
                                                    <div className="left-align-block">
                                                        <ClientSetupChecks formikProps={rest} checkClassName="pa10" user={user} isPdf={user.isPdfClient()} />
                                                    </div>
                                                </div>
                                                <div className="text-center">
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    {!rest.values.setupOwnProfile && (<div className="row">
                                        <div className="col s12 m12 l6">
                                            <DefaultSelect
                                                className="mb20"
                                                label={t('Gender')}
                                                name='gender'
                                                collection={User.genderCol(t)}
                                                {...selectProps}
                                            />
                                            <AgeHeightWeightFields 
                                                autoSubmit={autoSubmit}
                                                className="mb20 inline-block"
                                                ageLabel={ageLabel}
                                                heightLabel={heightLabel}
                                                weightLabel={weightLabel}
                                                t={t}
                                                flowProps={rest}
                                                singleButton
                                            />
                                        </div>
                                        <div className="col s12 m12 l6">
                                            <DefaultSelect
                                                className="mb20"
                                                label={t('Primary goal')}
                                                name='weightGoal'
                                                collection={User.weightGoalCol(t)}
                                                {...selectProps}
                                            />
                                            <DefaultSelect
                                                className="mb20"
                                                label={t('Activity level')}
                                                name='activityLevel'
                                                collection={User.activityLevelCol(t)}
                                                {...selectProps}
                                            />
                                            {showNotificationSettings && (<div>
                                                <NotificationSettingsDescription user={user} />
                                            </div>)}
                                        </div>
                                    </div>)}
                                    {submitRender && submitRender({ values: rest.values, errors, submitState })}
                                </CardForm>
                                {/* want to check the form value of SOP and user value to make sure value hasn't changed and avvoid confusion where user changes to SOP, clicks resend, and then doesn't click save */}
                                {sendInvite && (rest.values.setupOwnProfile && user.setupOwnProfile) && user.inviteSent() && !_.isBlank(user.email) && (<div className="text-center">
                                    <AsyncSpinnerTextTPB
                                        buttonProps={{
                                            label: user.inviteSent() ? t('Resend Invitation Email') : t("Send Invitation Email"),
                                            id: "send-invite-btn"
                                        }}
                                        action={() => sendInvite(user.id)}
                                        type={null}
                                        allowUninit={false}
                                    />
                                </div>
                                )}
                            </React.Fragment>
                        )
                }}
            />
            {showNotificationSettings && <NotificationSettingsModal user={user} />}
        </React.Fragment>
    )
}

class BasicProfileSettings extends React.Component {

    render() {
        const { user, submitBasic: submit, t } = this.props;
     
        return (
            <div className="row limit-50-h" id="basic-profile-form">
                <div className="col s12 m12 l10 offset-l1">
                    <h4 className="conversion-heading ml0">{t('Basic Profile')}</h4>
                    <BasicProfileForm user={user} submit={submit} autoSubmit />
                </div>
            </div>
        )      
    }
}

const DnpButton = ({ dnp }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <ListingRightBtn icon="pencil" id={`edit-dnp-${dnp.id}-btn`} onClick={() => history.push(modalPathFor(dnpModalSuffix,pathname,{ id: dnp.id }))} />
    )
}

const dnpListFor = (trainer,user,initialOrdering) => (trainer || user).formDnpListFor(user,initialOrdering)
const dnpListOrder = (trainer,user) => (trainer || user).formDnpListOrder(user)
const alwaysShowDnpSect = (trainer,user) => (dnpListFor(trainer,user).length > 0)

const DnpSectionCore = ({ trainer, user, formikProps }) => {
    const { t } = useTranslation(); 
    const [initialOrdering] = React.useState(dnpListOrder(trainer,user));
    const dnpList = dnpListFor(trainer,user,initialOrdering);
    const preselected = _.filter(dnpList, dnp => (dnp.isProfile() && dnp.isActive())).map(dnp => dnp.id);
    const [baseCals,fallbackParams] = user.baseCalsForForm(formikProps.values,t);

    return (
        <div className="">
            {dnpList.length === 0 && (
                <SlightEmphasisIconNote text={t('no dnps yet')} btn={<NewDnpButton />} />
            )}
            {dnpList.length > 0 && (
                <React.Fragment>
                    <ModalSelectListing 
                        boxClass="non-modal"
                        col={dnpList.map(dnp => ({ text: dnp.displayName(t), subtitle: dnp.shortSummary(t,baseCals,fallbackParams), value: dnp.id, rightBtns: (<DnpButton dnp={dnp} />) }))}
                        names={['activateDnpIds','deactivateDnpIds']}
                        getName={dnpId => (preselected.includes(dnpId) ? 'deactivateDnpIds' : 'activateDnpIds')}
                        getInverted={dnpId => preselected.includes(dnpId)}
                        {...formikProps}
                    />
                    <div className="text-center mt25">
                        <NewDnpButton />
                    </div>
                </React.Fragment>
            )}
        </div>
    )
}

const DnpSectionWrapper = ({ hideDnpSection, setHideDnpSection, alwaysShowDnps, trainer, user, formikProps }) => {
    const { t } = useTranslation();

    if(hideDnpSection) {
        return '';
    }

    return (
        (<Card className="pa20">
            {!alwaysShowDnps && (<DismissCardButton onClick={() => setHideDnpSection(true)} />)}
            <div className="row">
                <div className="col s12">
                    <div>{_.capitalize(t('dnp title'))} ({t('optional')})</div>
                    <DnpSectionCore trainer={trainer} user={user} formikProps={formikProps} />
                </div>
            </div>
        </Card>)
    )
}

let DnpSetupLoader = ({ trainer, user, formikProps, loadDnps, hideDnpSection, setHideDnpSection, alwaysShowDnps }) => {

    return (
        <Loader 
            load={loadDnps}
            preloaded={() => false}
            type="blank"
            alwaysLoad
            successComponent={DnpSectionWrapper}
            trainer={trainer}
            user={user}
            formikProps={formikProps}
            hideDnpSection={hideDnpSection}
            setHideDnpSection={setHideDnpSection}
            alwaysShowDnps={alwaysShowDnps}
        />
    )
}

const mapDispatchToDnpProps = dispatch => ({
    loadDnps: () => dispatch(loadDnps())
})


DnpSetupLoader = connect(null,mapDispatchToDnpProps)(DnpSetupLoader)

const validateMealPlan = (t,user,showMpPrefsCheck) => (values) => {
    if(values.setupOwnMpPrefs && showMpPrefsCheck) {
        return validateCustomMacros(t, user)(values);
    }

    const validateMeals = validateSelectedMeals(t);
    const validateOther = validateBudgetVarietyComplexityDiet(t);
    const validateMacros = validateCustomMacros(t, user);

    return { ...validateMeals(values), ...validateMacros(values), ...validateOther(values) }
}

export class MealPlanSettings extends React.Component {

    constructor(props) {
        super(props);

        this.state = { hideDnpSection: true }
    }

    render() {
        const { user, overridesOnly, forTrainer, trainer, submitMealPlan: submit, allergyTags, t, autoSubmit, submitRender, successCallback } = this.props;
        let { hideDnpSection } = this.state;
        const alwaysShowDnps = alwaysShowDnpSect(trainer,user);
        hideDnpSection = hideDnpSection && !alwaysShowDnps;
        const recommendedCalories = user.recommendedCalsReadable();
        const rawParams = user.recMacroParams().rawMacroParams(recommendedCalories);
        const showMpPrefsCheck = (forTrainer && trainer && trainer.isAppTrainer() && !user.mealPlanInitialized());
        const showCanEditOwn = (forTrainer && trainer && trainer.isAppTrainer());
        const showMacroFields = (forTrainer || trainer || user.canEditOwnMacros());
        const labelMap = [
            [t('Grocery budget'),'budget'],
            [t('Weekly variety'),'weeklyVariety'],
            [t('Max recipe complexity'),'complexityPreference']
        ]
     
        return (
            <FormikWrapper
                initialValues={ overridesOnly ? user.customMacrosValues() : { ...user.mealPlanValues(allergyTags,false,'settings',forTrainer), ...user.customMacrosValues() } }
                validate={overridesOnly ? validateCustomMacros(t,user) : validateMealPlan(t,user,showMpPrefsCheck)}
                submit={submit}
                valuesFilter={values => {
                    let retVals = values;
                    const macroFields = ['dailyNutritionProfiles'];
                    if(values.setupOwnMpPrefs && showMpPrefsCheck) {
                        retVals = _.pick(retVals,['setupOwnMpPrefs','canEditOwnMealPlan', ...macroFields ]);
                    }

                    if(!showMacroFields) {
                        retVals = _.omit(retVals,macroFields);
                    }

                    return retVals;
                }}
                initialErrors={{}}
                autoSubmit={autoSubmit}
                successCallback={({ status,formikBag,data,values }) => {
                    if(showMacroFields) {
                        const { setFieldValue } = formikBag;
                        const { dailyNutritionProfiles } = values;
                        const { nutritionParameters } = dailyNutritionProfiles[0];

                        Object.entries(_.pick(values,['activateDnpIds','deactivateDnpIds'])).forEach(([name,value]) => {
                            if(_.compact(value).length > 0) {
                                setFieldValue(name,_.parseObjForForm([]));
                            }
                        })

                        const responseDnp = _.find(Object.values(data.dailyNutritionProfiles),(dnp) => (dnp.main && dnp.userId === user.id));
                        setFieldValue('dailyNutritionProfiles.0.id',responseDnp.id)
                        const responseParams = _.filter(Object.values(data.nutritionParameters),(param) => param.userOverride);
                        nutritionParameters.forEach((param,index) => {
                            const match = _.find(responseParams,respParam => (respParam.nutrientId === param.nutrientId && respParam.dailyNutritionProfileId === responseDnp.id));
                            if(match && match.id !== param.id) {
                                setFieldValue(`dailyNutritionProfiles.0.nutritionParameters.${index}.id`,match.id);
                            } else if(!match && !_.isBlank(param.id)) {
                                setFieldValue(`dailyNutritionProfiles.0.nutritionParameters.${index}.id`,'');
                            }
                        })
                    }
                    successCallback && successCallback({ data, values, formikBag });
                }}
                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                    const { errors } = rest;
                    const selectProps = autoSubmit ? { ...rest, handleChange: handleAutoSubmitChange } : { ...rest };
                    const handleBlur = autoSubmit ? rest.handleAutoSubmitBlur : rest.handleBlur;
                    const { values: { dailyNutritionProfiles, selectedMealTypes, setupOwnMpPrefs } } = rest;
                    const { nutritionParameters, overrideCalories } = dailyNutritionProfiles[0];
                    const showMinSnacks = selectedMealTypes && selectedMealTypes.includes('snack');
                    const fieldProps = _.pick(rest,formikInputProps);
                        return (
                            <React.Fragment>
                                <DnpModal user={user} nutritionSettingsVals={rest.values} />
                                <form onSubmit={handleSubmit}>
                                    {!overridesOnly && (<div className="row limit-50-h">
                                        <div className="col s12 m12 l10 offset-l1">
                                            <h4 className="conversion-heading ml0">{forTrainer ? t('Meal Plan Settings') : t('Meal Plan')}</h4>
                                            <Card className="pa20">
                                                <div className="row">
                                                    {(showMpPrefsCheck || showCanEditOwn) && (<div className="col s12">
                                                        <div className="pt10 pb10 text-center">
                                                            <div className="left-align-block">
                                                                {showMpPrefsCheck && (<div className="inline-block mr15">
                                                                    <MultivalueCheckbox 
                                                                        name="setupOwnMpPrefs"
                                                                        id="client-setup-check"
                                                                        checkedVal={true}
                                                                        uncheckedVal={false}
                                                                        label={t('have client fill in')} 
                                                                        filled 
                                                                        className="no-wrap"
                                                                        values={rest.values}
                                                                        setFieldValue={rest.setFieldValue}
                                                                    />
                                                                </div>)}
                                                                {showCanEditOwn && (<div className="inline-block"><MultivalueCheckbox 
                                                                    name="canEditOwnMealPlan"
                                                                    id="client-edit-own-check"
                                                                    checkedVal={true}
                                                                    uncheckedVal={false}
                                                                    label={t('allow edit own mp')} 
                                                                    filled 
                                                                    className="no-wrap"
                                                                    values={rest.values}
                                                                    setFieldValue={rest.setFieldValue}
                                                                /></div>)}
                                                            </div>
                                                        </div>
                                                    </div>)}
                                                    {!(showMpPrefsCheck && rest.values.setupOwnMpPrefs) && (
                                                        <React.Fragment>
                                                            <div className="col s12 m12 l6">
                                                                <DefaultSelect
                                                                    className="mb20"
                                                                    label={t('Diet type')}
                                                                    name='dietType'
                                                                    collection={User.dietTypesCol(t,true)}
                                                                    {...selectProps}
                                                                />
                                                                {labelMap.map(([label,name]) => (
                                                                    <DefaultSelect
                                                                        className="mb20"
                                                                        key={name}
                                                                        label={label}
                                                                        name={name}
                                                                        collection={User[`${name}Col`](t,true,false,true)}
                                                                        {...selectProps}
                                                                    />
                                                                ))}
                                                                <div className="mb20">
                                                                    <div className="font-grey">{t('Food allergies')}</div>
                                                                    <ExclusionTags allergyTags={allergyTags} {...rest} />
                                                                </div>
                                                            </div>
                                                            <div className="col s12 m12 l6">
                                                                <div className="mb20">
                                                                    <div className="valign-wrapper font-grey">{t('Keywords to exclude')}<FlexIconTip icon='question-circle' msg={t("keyword exclusion tooltip")} /></div>
                                                                    <ExtraExclusions t={t} values={rest.values} setFieldValue={rest.setFieldValue} />
                                                                </div>
                                                                <DefaultSelect
                                                                    className="mb20"
                                                                    selectProps={{ multiple: true, className: 'auto-height'}}
                                                                    label={t('Included meals')}
                                                                    name='selectedMealTypes'
                                                                    collection={User.mealTypesCol(t)}
                                                                    {...rest}
                                                                    handleChange={(e) => {
                                                                        const { setValues, values } = rest;
                                                                        const opts = e.target.options;
                                                                        let sel = _.filter(opts,opt => opt.selected).map(opt => opt.value);
                                                                        if(sel.length === 0) {
                                                                            sel = [''];
                                                                        }
                                                                        setValues({ ...values, selectedMealTypes: sel, mealTypeDailyPercentages: user.mealTypeDailyPercentages(sel,values.minSnacks) })
                                                                    }}
                                                                />
                                                                {showMinSnacks && (
                                                                    <DefaultSelect
                                                                        className="mb20"
                                                                        label={t("Minimum snacks per day")}
                                                                        name='minSnacks'
                                                                        collection={[{text: '1', value: 1}, {text: '2', value: 2}, { text: '3', value: 3 }]}
                                                                        {...rest}
                                                                        handleChange={(e) => {
                                                                            const { setValues, values, values: { selectedMealTypes } } = rest;
                                                                            const minSnacks = Number(e.target.value);
                                                                            setValues({ ...values, minSnacks, mealTypeDailyPercentages: user.mealTypeDailyPercentages(selectedMealTypes,minSnacks) })
                                                                        }}
                                                                    />
                                                                )}
                                                                <DefaultSelect
                                                                    className="mb20"
                                                                    label={t('Start weekday')}
                                                                    name='mealPlanWeekday'
                                                                    collection={_.weekdaysCollection()}
                                                                    {...selectProps}
                                                                />
                                                                <div className="font-grey">{t('Daily calories per meal')}</div>
                                                                <PercentagesSlider 
                                                                    name='mealTypeDailyPercentages' 
                                                                    values={rest.values} 
                                                                    setFieldValue={autoSubmit ? rest.setFieldValueNoSubmit : rest.setFieldValue}
                                                                    submitForm={autoSubmit ? rest.submitForm : null}    
                                                                />
                                                            </div>
                                                        </React.Fragment>
                                                    )}
                                                </div>
                                            </Card>
                                        </div>
                                    </div>)}
                                    {showMacroFields && (<div className="row limit-50-h">
                                        <div className="col s12 m12 l10 offset-l1">
                                            <h4 className="conversion-heading ml0 mb0">{forTrainer ? t('Nutrition Settings') : t('Nutrition')}</h4>
                                            <div className="mb10"><IconNote id="macro-rec-span" text={`${t('Recommended')}: ${_.macroTargetsSummary(t,rawParams)}`} /></div>
                                            <Card className="pa20">
                                                <div className="row">
                                                    <div className="col s12 m3 l4">
                                                        <div className="mb30">
                                                            <div className="mb20">{t('Calories')}</div>
                                                            <div>
                                                                <MultivalueCheckbox
                                                                    name="dailyNutritionProfiles.0.overrideCalories"
                                                                    checkedVal={false}
                                                                    uncheckedVal={true}
                                                                    className="ml10 mb10"
                                                                    label={t("Use Recommendation")}
                                                                    {...rest}
                                                                />
                                                            </div>
                                                            <div className="valign-wrapper">
                                                                <MultivalueCheckbox
                                                                    name="dailyNutritionProfiles.0.overrideCalories"
                                                                    checkedVal={true}
                                                                    uncheckedVal={false}
                                                                    className="ml10 mb10 override-cals-check"
                                                                    label={overrideCalories ? null : t("Manually Input")}
                                                                    {...rest}
                                                                />
                                                                {overrideCalories && (<InputWithErrors 
                                                                    {...fieldProps}
                                                                    handleBlur={handleBlur}
                                                                    name="dailyNutritionProfiles.0.calorieOverride" 
                                                                    component={NumberInput} 
                                                                    className="mt0" 
                                                                    inProps={{ disabled: !overrideCalories, placeholder: user.recommendedCalories ? user.recommendedCalsReadable() : '' }} 
                                                                />)}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m6 l5">
                                                        <div className="mb30">
                                                            <div className="mb20">{_.capitalize(t('macros'))}</div>
                                                            <div className="mb20">
                                                                <UserMacroFields 
                                                                    nutritionParameters={nutritionParameters} 
                                                                    t={t} 
                                                                    flowProps={{ ...rest, handleBlur }} 
                                                                    user={user} 
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col s12 m3 l3">
                                                        <div className="">{_.capitalize(t('fiber g'))}</div>
                                                        <InputWithErrors 
                                                            {...fieldProps}
                                                            handleBlur={handleBlur}
                                                            inProps={{ placeholder: t('optional')}} 
                                                            name="dailyNutritionProfiles.0.fiberGoal" 
                                                            component={NumberInput} 
                                                            className="inline mt0" 
                                                        />
                                                    </div>
                                                </div>
                                                {hideDnpSection && (
                                                    <div className="row">
                                                        <div className="col s12">
                                                            <div className="text-center">
                                                                <div className="underline clickable font-grey" id="setup-dnps-btn" onClick={() => this.setState({ hideDnpSection: false })}>
                                                                    {t('Set up weekday-specific nutriton')}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Card>
                                            <DnpSetupLoader 
                                                hideDnpSection={hideDnpSection}
                                                alwaysShowDnps={alwaysShowDnps}
                                                setHideDnpSection={(val) => this.setState({ hideDnpSection: val })}
                                                trainer={trainer} 
                                                user={user} 
                                                formikProps={rest}
                                            />
                                        </div>
                                    </div>)}
                                    {submitRender && submitRender({ errors, submitState, setupOwnMpPrefs })}
                                </form>
                            </React.Fragment>
                        )
                }}
            />
        )      
    }
}

const WorkoutPlanSettings = ({ user, t }) => {

    return (
        <div className="row limit-50-h">
            <div className="col s12 m12 l10 offset-l1">
                <h4 className="conversion-heading ml0">{t('Workout Plan')}</h4>
                <Card className="pa20">
                    <div className="row mb0">
                        <div className="col s12 m6 l6">
                            <div className="text-center mt10 mb10">
                                <div className="inline-block" style={{ width: '280px' }}>
                                    <ProRequiredButton  
                                        user={user}
                                        context="routine_editor"
                                        proPath={editRoutinePath}
                                        blockTypes={['hard','soft']}
                                        render={({ onClick }) => (
                                            <Button key="edit-routine-btn" id="edit-routine-btn" rounded color="primary" block outlined onClick={onClick}>
                                                <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon> <span>{t("Edit Current Plan")}</span>
                                            </Button>
                                        )}

                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col s12 m6 l6">
                            <div className="text-center mt10 mb10">
                                <div className="inline-block" style={{ width: '280px' }}>    
                                    <LinkButton key="edit-routine-btn" id="pick-new-plan-btn" rounded color="primary" block outlined to={pickNewRoutineModalPathFor(settingsPath)}>
                                        <FontAwesomeIcon icon="exchange-alt"></FontAwesomeIcon> <span>{t("Pick New Plan")}</span>
                                    </LinkButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </Card>
            </div>
            <SignupModal basePath={settingsPath} />
        </div>
    )
}

const SettingsCore = ({section, ...props}) => {
    const { user, trainer } = props;
    const overridesOnly = section === 'nutrition';
    const excludeWorkout = section === 'meal_plan' || overridesOnly;

    return (
        <React.Fragment>
            {!overridesOnly && <BasicProfileSettings {...props} />}
            {user.workoutPlanSetupAndVisible() && user.canPickOwnRoutine() && !excludeWorkout && <WorkoutPlanSettings {...props} />}
            {user.workoutPlanSetupAndVisible() && !overridesOnly && <PickNewRoutineModal basePath={settingsPath} {...props} />}
            {user.mealPlanSetupAndVisible() && (user.allowedToEditOwnMealPlan() || trainer) && <MealPlanSettings {...props} overridesOnly={overridesOnly} autoSubmit />}
        </React.Fragment>
    )
}

const transitionMap = {
    rules: [
        [[...mealPlanSlideOutPaths,notificationsPath],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,editRoutinePath], transitions.slideOver]
    ]
};

const SettingsPage = (props) => {
    const { t, scrollRef, setupTransitions, match: { params: { section } }, ...rest } = props;

    return (
        <SimpleNavPage 
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t('Settings')}
        >
            <SettingsCore {...rest} t={t} section={section} />
        </SimpleNavPage>
    )
}

const mapStateToProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state),
    allergyTags: allergyTagsSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submitBasic: (data) => dispatch(updateSettings(data)),
    submitMealPlan: (data) => dispatch(updateMealPlan(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SettingsPage))