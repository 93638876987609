import React from 'react';
import {
    getChildMapping,
    mergeChildMappings
} from 'lib/utilities';

class KeepChildren extends React.Component {

    constructor(props) {
        super(props);
        this.lastChildren = getChildMapping(props.children);
        this.firstRender = true;
    }

    componentDidMount() {
        this.firstRender = false;
    }

    render() {
        if(!this.firstRender) {
            const { children } = this.props;
            const nextMapping = getChildMapping(children);
            this.lastChildren = mergeChildMappings(this.lastChildren,nextMapping);
        }
        const curChildren = Object.values(this.lastChildren);

        return (
            <React.Fragment>
                {curChildren}
            </React.Fragment>
        )
    }
}

export default KeepChildren;