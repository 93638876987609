import React from 'react';
import { offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, swapRecipePathFor, addRecipePathFor, editWeeklyMealMatch, viewMealPathMatch, viewRecipeReplacementPathFor, viewTempMealMatch, viewRecipeReplacementMatch, viewTempRecipeReplacementMatch, editRecipeMatch, emailPaywallMatches, allMainMatches } from 'config/paths';
import { paywallPathFor } from 'redux/helpers';
import * as transitions from 'assets/transitions';
import { userWithMealPlanSelector, getMealInfoMatrixSelector, recipeSearchFullSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { updateRecipeSearch, startReplaceRecipe, replaceRecipe } from 'redux/actions';
import RecipeReplacementPage, { selectActionCreator } from 'partials/RecipeReplacement';
import { resolvedHomePath } from 'redux/helpers';

const transitionMap = {
    rules: [
        [[...allMainMatches, offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, editWeeklyMealMatch],transitions.slideOut],
        [[viewMealPathMatch,viewTempMealMatch,viewRecipeReplacementMatch,viewTempRecipeReplacementMatch,...emailPaywallMatches,editRecipeMatch], transitions.slideOver]
    ]
};

export const getProPathCreator = (user,fn) => (args) => {
    if(user && user.hasProAccess(['old','hard'])) {
        return fn(args);
    } else {
        return paywallPathFor('view_recipe',user);
    }
}

const FindRecipeReplacementPage = (outerProps) => {
    
    return (
        <RecipeReplacementWrapper 
            {...outerProps}
            render={({ userMeal, recipeId, mealSearch, user, ...props }) => {
                const id = userMeal ? userMeal.id : '';
                const getPath = getProPathCreator(user,viewRecipeReplacementPathFor.bind(null,id,recipeId,mealSearch.mealCount()))
                
                return (
                    <RecipeReplacementPage
                        basePath={recipeId ? swapRecipePathFor(id,recipeId) : addRecipePathFor(id)}
                        getPath={getPath}
                        title={recipeId ? 'Replace Recipe' : 'Add Recipe'}
                        transitionMap={transitionMap}
                        user={user}
                        mealSearch={mealSearch}
                        {...props}
                    />
                )
            }}
        />
    )
}

let RecipeReplacementWrapper = ({ userMeal, mealInfoMatrix, mealSearch, render, selectAction, match: { params: { recipeId, id }}, ...rest }) => {
    let redirect = resolvedHomePath();
    let weeklyMeal = null;
    if(userMeal) {
        weeklyMeal = mealInfoMatrix.matchingMealForUserMeal(userMeal);
        if(weeklyMeal && !weeklyMeal.isProteinSupp() && mealSearch.matchesContext({ type: 'UserMeal', id: Number(id) })) {
            redirect = null;
        }
    }

    return render({ userMeal, recipeId, redirect, selectAction: selectActionCreator(selectAction.bind(null,weeklyMeal),recipeId), mealSearch, ...rest })
}

export const mapUserMealPathAndStateToProps = (includeSearch) => (state,props) => {
    let { match: { params: { id }}} = props;
    const user = userWithMealPlanSelector(state);
    const userMeal = user.findUserMealById(id);
    const week = user.weekForUserMeal(userMeal);
    const mealInfoMatrixSelector = getMealInfoMatrixSelector(week);
    const map = {
        user,
        mealInfoMatrix: mealInfoMatrixSelector(state),
        userMeal
    }
    if(includeSearch) {
        map.mealSearch = recipeSearchFullSelector(state)
    }
    return map;
}

const mapDispatchToInnerProps = dispatch => ({
    selectAction: (weeklyMeal,data) => dispatch(replaceRecipe(weeklyMeal,data))
})

RecipeReplacementWrapper = connect(mapUserMealPathAndStateToProps(true),mapDispatchToInnerProps)(RecipeReplacementWrapper)

export { RecipeReplacementWrapper }

const mapDispatchToSearchPageProps = dispatch => ({
    startSwapRecipe: data => dispatch(startReplaceRecipe(data)),
    searchAction: (data,page) => dispatch(updateRecipeSearch(data,page))
})

export default connect(null,mapDispatchToSearchPageProps)(FindRecipeReplacementPage);


