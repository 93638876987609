import * as _ from 'lib/utilities';

class StrengthTestSet {

    constructor(obj) {
        Object.assign(this,obj);
    }

    isMetric() {
        return this.strengthTest.isMetric();
    }

    unitWeight() {
        if(this.isMetric()) {
            return _.roundToF(this.weight/2.2,this.strengthTest.mincrement()/2.2);
        } else {
            return this.weight;
        }
    }

    weightLabel(t) {
        let units = t('lbs');
        if(this.isMetric()) {
            units = t('kgs');
        }

        if(this.strengthTest.needsDumbellInfo()) {
            return t("units per dumbbell",{ units })
        }

        return units;
    }

    setWeight(weight) {
        this.weight = _.roundToF(weight,this.strengthTest.mincrement());
    }

    result(rpe,index) {
        this.rpe = rpe;
        return this.strengthTest.result(this,index);
    }

    restTime() {
        if(!_.isBlank(this.rpe)) {
            if(this.rpe >= 3) {
                return 90;
            }
        }
        return 0;
    }
}


export class StrengthTest {
    static EASY_PLATE_WEIGHTS = [[45,65,95,115,135,155,185,205,225,245,275,295,315,335,365,385,405,425,455,475,495,515,545,565,585],
                                [20,30,40,50,60,70,80,90,100,110,120,130,140,150,160,170,180,190,200,210,220,230,240,250,260,270]]

    static EASY_HALF_WEIGHTS = [[25,35,45,55,65,75,90,100,110,120,135,150,160,170,185,195,205,215,230,240,250,260,275,285,295],
                                [10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100,105,110,115,120,125,130,135]]

    static progressAmount(rpe,units) {
        if(rpe <= 2) {
            return [90,40*2.2][units];
        } else {
            return [50,20*2.2][units];
        }
    }

    constructor(obj) {
        Object.assign(this,obj);
        this.sets = this.sets.map(set => (new StrengthTestSet({ ...set, strengthTest: this })));
    }

    result(set,index) {
        this.submitSet(set,index);
        if(set.rpe === 4) {
            return { weight: set.weight, reps: set.reps };
        } else {
            const ceiling = this.ceiling();
            let floor = this.floor();
            const mincrement = this.mincrement();
            const minDiff = mincrement > 10 ? mincrement : mincrement*2;
            const base = this.exercise.hasBaseWeight() ? -this.user.currentWeight : 0;
            if(!floor && set.weight <= base) {
                return { weight: base, reps: set.reps }
            }
            if(ceiling && floor) {
                const curDiff = ceiling - floor;
                const pctDiff = curDiff/Math.max(Math.abs(floor-base),Math.abs(base+mincrement));
                if(curDiff <= minDiff || pctDiff <= 0.15) {
                    return { weight: _.roundToF((ceiling+floor)/2,mincrement), reps: set.reps };
                }
            }
        }
        return null;
    }

    submitSet(set,index) {
        this.sets = [ ...this.sets.slice(0,index), set ];
    }

    getNextSet(index) {
        const set = this.sets[index];
        if(set) {
            return set;
        }

        let lastSet = this.sets[this.sets.length-1];
        if(lastSet) {
            let nextWeight = null;
            const previousUnitWeights = this.sets.map(set => set.unitWeight());
            const jumps = { 0: 4, 1: 2, 2: 2, 3: (lastSet.weight > 325 ? 2 : 1) };
            const drops = { 5: 2 };
            let weightLadder = this.exercise.shouldHalveWeight() ? StrengthTest.EASY_HALF_WEIGHTS[this.units()] : StrengthTest.EASY_PLATE_WEIGHTS[this.units()];
            if(this.exercise.isAssistedBw()) {
                const oldLadder = [ ...weightLadder ];
                weightLadder = [ ...weightLadder ];
                weightLadder = weightLadder.reverse().map(wt => -wt).concat(oldLadder);
            }
            weightLadder = weightLadder.map(wt => _.roundToF(wt,this.isMetric() ? this.mincrement()/2.2 : this.mincrement()))
            const previousIndices = previousUnitWeights.map(wt => weightLadder.indexOf(wt));
            let arbitraryWeight = _.some(previousIndices,index => (index === -1))

            const ceiling = this.ceiling();
            const floor = this.floor();

            let ceilingUnitWt = null;
            let floorUnitWt = null;
            let floordex = null;
            let ceildex = null;
            let newdex = null;

            if(!arbitraryWeight) {
                if(ceiling) {
                    ceilingUnitWt = this.isMetric() ? _.roundToF(ceiling/2.2,this.mincrement()/2.2) : ceiling;
                }

                if(floor) {
                    floorUnitWt = this.isMetric() ? _.roundToF(floor/2.2,this.mincrement()/2.2) : floor;
                }

                floordex = floorUnitWt && weightLadder.indexOf(floorUnitWt);
                ceildex = ceilingUnitWt && weightLadder.indexOf(ceilingUnitWt);
                
                if(floordex === -1) {
                    floordex = null;
                }

                if(ceildex === -1) {
                    ceildex = null;
                }

                if(!_.isBlank(floordex) && !_.isBlank(ceildex)) {
                    if(ceildex - floordex > 1) {
                        newdex = Math.floor((ceildex + floordex)/2);
                    }
                } else if(!_.isBlank(floordex)) {
                    const maxdex = weightLadder.length-1;
                    if(floordex < maxdex) {
                        newdex = Math.min(floordex + jumps[lastSet.rpe],maxdex);
                    }
                } else if (!_.isBlank(ceildex)) {
                    if(ceildex > 0) {
                        newdex = Math.max(ceildex - drops[lastSet.rpe],0);
                    }
                }

                arbitraryWeight = _.isBlank(newdex);
            }

            if(arbitraryWeight) {
                if(!_.isBlank(floor) && !_.isBlank(ceiling)) {
                    nextWeight = (floor+ceiling)/2;
                } else if (!_.isBlank(floor)) {
                    nextWeight = floor + StrengthTest.progressAmount(lastSet.rpe,this.units());
                } else if (!_.isBlank(ceiling)) {
                    nextWeight = ceiling/2;
                }

                let newSet = new StrengthTestSet({ reps: this.setReps(), strengthTest: this });
                newSet.setWeight(nextWeight);
                return newSet;
            } else {
                nextWeight = weightLadder[newdex];
                let newSet = new StrengthTestSet({ reps: this.setReps(), strengthTest: this });
                newSet.setWeight(this.isMetric() ? nextWeight*2.2 : nextWeight);
                return newSet;
            }

        } else {
            return new StrengthTestSet({ weight: this.startWeight(), reps: this.setReps(), strengthTest: this });
        }
    }

    ceiling() {
        let ceiling = null;
        for(let set of this.sets) {
            if(set.rpe >= 5 && (_.isBlank(ceiling) || set.weight < ceiling)) {
                ceiling = set.weight;
            }
        }
        return ceiling
    }

    floor() {
        let floor = null;
        for(let set of this.sets) {
            if(set.rpe <= 3 && (_.isBlank(floor) || set.weight > floor)) {
                floor = set.weight;
            }
        }
        return floor;
    }

    isMetric() {
        return this.user.isMetric(this.exercise);
    }

    units() {
        return this.isMetric() ? 1 : 0;
    }

    mincrement() {
        return this.user.mincrement(this.exercise);
    }

    needsDumbellInfo() {
        return this.exercise.needsDumbellInfo();
    }

    setReps() {
        return this.exercise.strengthTestReps();
    }

    startWeight() {
        let val = this.exercise.shouldHalveWeight() ? StrengthTest.EASY_HALF_WEIGHTS[this.units()][0] : StrengthTest.EASY_PLATE_WEIGHTS[this.units()][0];
        if(this.isMetric()) {
            val = val*2.2;
        }

        if(this.exercise.isAssistedBw()) {
            val = -val;
        }

        return _.roundToF(val,this.mincrement());
    }
}