import React from 'react';
import Slider from 'rc-slider';
import { ControlledCarousel } from 'components/Carousel';
import * as _ from 'lib/utilities';
import * as classnames from 'classnames';

const EstBodyFatNote = ({ gender, value, t, timeType, diff }) => {

    if(_.isBlank(value)) {
        return '';
    }

    let bfStr,cname,desc,aftnote;
    if(gender === 'male') {
        if(value <= 5) {
            bfStr = '6 - 10%';
            cname = 'gd';
            desc = 'Ideal to low';
        } else if(value <= 10) {
            bfStr = '10 - 15%';
            cname = 'gd';
            desc = 'Ideal';
        } else if(value <= 15) {
            bfStr = '15 - 20%';
            cname = 'gd';
            desc = 'Good';
        } else if(value <= 20) {
            bfStr = '20 - 25%';
            cname = 'wn';
            desc = 'Ok';
        } else if(value <= 25) {
            bfStr = '25 - 30%';
            cname = 'bd';
            desc = 'High';
        } else {
            bfStr = '31 - 50%';
            cname = 'bd';
            desc = 'Very High';
        }
    } else {
        if(value <= 15) {
            bfStr = '15 - 20%';
            cname = 'gd';
            desc = 'Ideal to low';
        } else if(value <= 20) {
            bfStr = '20 - 25%';
            cname = 'gd';
            desc = 'Ideal';
        } else if(value <= 25) {
            bfStr = '25 - 30%';
            cname = 'gd';
            desc = 'Good';
        } else if(value <= 30) {
            bfStr = '30 - 40%';
            cname = 'wn';
            desc = 'Ok';
        } else {
            bfStr = '40 - 60%';
            cname = 'bd';
            desc = 'High';
        }
    }

    if(timeType === 'after') {
        if(diff >= 5) {
            cname = 'wn';
            desc = null;
            aftnote = null;
        } else if(diff >= 0) {
            cname = 'gd';
            desc = 'Current';
            aftnote = 'maintainbfnote';
        } else if(diff >= -10) {
            cname = 'gd';
            desc = 'Realistic';
            aftnote = 'realisticbfnote';
        } else {
            cname = 'wn';
            desc = 'Challenging';
            aftnote = 'challengingbfnote';
        }
    }

    return (
        <div className="bf-note">
            <label>{timeType === 'before' ? t('Estimated Body Fat') : t('Body Fat Goal')}</label>
            <div className={classnames("bf-text", { [cname]: cname })}>{bfStr} {desc && `(${t(desc)})`}</div>
            <div className="font-grey">{timeType === 'before' ? t(`${gender}${value}bfnote`) : t(aftnote)}</div>
        </div>
    )
}

const BodyFatSelector = ({ attr, flowProps, t }) => {
    const { setFieldValue, setValues, values: inValues } = flowProps;
    const { values: { [attr]: attrVal } } = flowProps;
    const { values: { curBodyFat } } = flowProps;

    const marks = {};
    const gender = inValues.gender === false ? 'male' : 'female';
    const timeType = attr === 'curBodyFat' ? 'before' : 'after';
    let values;

    if(gender === 'female') {
        values = [15,20,25,30,35];
    } else {
        if(timeType === 'before') {
            values = [10,15,20,25,30];
        } else {
            values = [5,10,15,20,25,30];
        }
    }

    const options = values.map(val => ({ value: val, src: `${_.publicUrl()}img/body_fat_images/${gender}/${timeType}/${val}.png`}))
    const carouselItems = [];

    options.forEach((opt,i) => {
        let label = null;
        let style = {};
        if(i === 0) {
            label = t('Low body fat');
            style = { whiteSpace: 'nowrap', color: '#666', transform: 'none' };
        } else if(i === (options.length-1)) {
            label = t('High body fat');
            style = { whiteSpace: 'nowrap', color: '#666', transform: 'translateX(-100%)' };
        }
        
        marks[`${opt.value}`] = { style, label };
        carouselItems.push({ id: opt.value, src: opt.src });
    })

    const defaultInd = 0;
    const defaultOpt = options[defaultInd];
    let selVal = _.isBlank(attrVal) ? defaultOpt.value : attrVal;
    const selOption = _.find(options,opt => (opt.value === selVal)) || defaultOpt;
    const diff = timeType === 'after' ? (selOption.value - curBodyFat) : 0;

    const setValFn = val => {
        if(attr === 'curBodyFat') {
            setValues({ ...inValues, curBodyFat: val, goalBodyFat: Math.max(values[0],val-5) });
        } else {
            setFieldValue(attr,val);
        }
    }

    return (
        <div>
            <div className="mt20 mb20">
                <ControlledCarousel
                    items={carouselItems} 
                    activeItem={selOption.value}
                    setActiveItem={setValFn}
                    options={{ indicators: false }} 
                    className="ht-300"
                />
            </div>
            <Slider
                defaultValue={selOption.value}
                marks={marks}
                step={null}
                min={options[0].value}
                max={options[options.length-1].value}
                value={selOption.value}
                onChange={setValFn}
            />
            <div className="mt40 mb20">
                <EstBodyFatNote gender={gender} value={attrVal} t={t} timeType={timeType} diff={diff} />
            </div>
        </div>
    )
}

export default BodyFatSelector;