import { ScrollableFormModal } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { connect } from 'react-redux';
import { dislikeRecipe, loadDislikedRecipes } from 'redux/actions';
import { recipesByIdsSelector, trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlightEmphasisIconNote } from 'components/Typography';
import Button from 'components/Button';
import { Loader } from 'components/LoadingHOC';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import { Recipe } from 'lib/classes';
import requestStateHandler from 'components/RequestStateHandler';
import Tabs from 'components/Tabs';

export const dislikedRecipeModalSuffix = '/disliked_recipes';

let RecipeListing = ({ recipe, undislike }) => {

    const newDestroy = () => {
        const newRecord = new Recipe({ ...recipe, exclude: true });
        undislike(newRecord);
    }

    if(recipe.exclude) {
        return '';
    }

    return (
        <ListCard className="no-shadow">
            <ListCardTitle>{recipe.name}</ListCardTitle>
            <ListCardAction onClick={newDestroy} className="no-border">
                <FontAwesomeIcon icon={"undo"} />
            </ListCardAction>
        </ListCard>
    )
}

RecipeListing = requestStateHandler({ undislike: 'recipe' })(RecipeListing)

let RecipeList = ({ recipes, undislike, type }) => {

    if(recipes.length === 0) {
        return (
            <div className="pa10">
                <SlightEmphasisIconNote text="No disliked recipes to show" />
            </div>
        )
    }

    return (
        <div className="pa10">
            {!undislike && <SlightEmphasisIconNote text="undislike only for own clients" />}
            {recipes.map(recipe => <RecipeListing key={recipe.id} recipe={recipe} undislike={undislike ? (recipe => undislike(recipe,type)) : null} />)}
        </div>
    )
}

const mapStateToRecipes = (state,props) => ({
    recipes: recipesByIdsSelector(state,props)
})

RecipeList = connect(mapStateToRecipes)(RecipeList);

const ModalContent = ({ trainer, user, undislike, activeTab, renderScrollable }) => {

    if(_.signedInAsClient(trainer,user)) {
        const recipeIds = activeTab === 0 ? trainer.dislikedRecipeIds : user.clientDislikedRecipeIds;
        const isOwned = user.trainerId === trainer.id;

        return renderScrollable({ children: (<RecipeList recipeIds={recipeIds} undislike={isOwned ? undislike : null} type={activeTab === 0 ? "undo_trainer" : "undo"} />) });
    }

    return renderScrollable({ children: (<RecipeList recipeIds={user.dislikedRecipeIds} undislike={undislike} type="undo" />) });
}

const DislikedRecipesModal = ({ trainer, user, load, undislike }) => {
    const { pathname } = useLocation();
    const path = modalPathFor(dislikedRecipeModalSuffix,pathname);
    const { t } = useTranslation();
    const siac = _.signedInAsClient(trainer,user);
    
    return (
        <ScrollableFormModal
            path={path}
            fullWidth
            scrollable
            icon="ban"
            title={t('Disliked Recipes')}
            headerContProps={siac ? { defaults: false, noShadow: false, defaultFont: true } : null}
            footerContProps={siac ? { noShadow: false } : null}
            render={({ renderFooter, renderScrollable, renderHeader, ...modalProps }) => {
                const sharedProps = {
                    successComponent: ModalContent,
                    trainer,
                    user,
                    undislike,
                    preloaded: () => false,
                    load,
                    type: "padded",
                    renderScrollable
                }

                if(siac) {

                    const tabs = [{
                        title: t('For All Clients')
                    },{
                        title: t('For This Client')
                    }]
                    return (
                        <Tabs addContentInit={modalProps.addContentInit} 
                            tabs={tabs} 
                            render={({ tabs, tabContents, tabRender, otherProps, activeTab }) => {
    
                                return (
                                    <React.Fragment>
                                        {renderHeader({ children: (
                                            <React.Fragment>
                                                <b>{t('Disliked Recipes')}</b>
                                                {tabs}
                                            </React.Fragment>
                                        )})}
                                        <Loader {...sharedProps} activeTab={activeTab} />
                                        {renderFooter({ children: (<Button rounded className="modal-close">{t('Ok')}</Button>) })}
                                    </React.Fragment>
                                )
                        }} />
                    )
                }

                    return (
                        <React.Fragment>
                            {renderHeader()}
                            <Loader {...sharedProps} />
                            {renderFooter({ children: (<Button rounded className="modal-close">{t('Ok')}</Button>) })}
                        </React.Fragment>
                    )
        }} />
    )

}

const mapStateToProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadDislikedRecipes()),
    undislike: (recipe,type) => dispatch(dislikeRecipe({ recipeId: recipe.id, type }))
})

export default connect(mapStateToProps,mapDispatchToProps)(DislikedRecipesModal);