import React from 'react';
import { ListCard, ListCardTitle, ListCardAction } from 'components/List';
import { BottomButtons, ConfirmActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { allMainMatches, wimageCatMatch, wimageCatNewPath, wimageCatPathFor } from 'config/paths';
import requestStateHandler from 'components/RequestStateHandler';
import { workoutImageCatSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { loadWimageCats, destroyWimageCat } from 'redux/actions';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { Loader } from 'components/LoadingHOC';
import LinkButton from 'components/LinkButton';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut],
        [[wimageCatMatch], transitions.slideOver]
    ]
};

let CategoryListing = ({ record, destroy }) => {
    const history = useHistory();
    const newDestroy = () => {
        const newRecord = { ...record, destroyed: true };
        destroy(newRecord);
    }

    if(record.destroyed) {
        return ''
    } else {
        return (
        <ListCard >
            <ListCardTitle>{record.baseName}</ListCardTitle>
            <ListCardAction onClick={() => history.push(wimageCatPathFor(record.id))}>
                <FontAwesomeIcon icon={"eye"} />
            </ListCardAction>
            <ConfirmActionButton onClick={newDestroy} render={({ ready, onClick }) => (
                <ListCardAction onClick={onClick}>
                    <FontAwesomeIcon color={ready ? 'red' : null} icon="times" />
                </ListCardAction>
            )} />
        </ListCard>
        )
    }
}

CategoryListing = requestStateHandler({ destroy: 'record' })(CategoryListing);

class WorkoutImageCatsSuccess extends React.Component {

    render() {
        const { categories, destroy } = this.props;

        return (
            <div className="row">
                <div className="col s12">
                    {categories.map(record => (
                        <CategoryListing 
                            key={record.id}
                            record={record}
                            destroy={(rec) => destroy(rec.id)}
                        />
                    ))}
                    <BottomButtons>
                        <LinkButton 
                            rounded 
                            color="primary" 
                            className="no-upcase shadow" 
                            to={wimageCatNewPath}
                        >
                            <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> New
                        </LinkButton>
                    </BottomButtons>
                </div>
            </div>
        )
    }
}

const WorkoutImageCatsPage = ({ load, scrollRef, setupTransitions, ...props }) => {

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title="Workout Image Categories"
        >
            <Loader
                load={load}
                preloaded={() => false}
                successComponent={WorkoutImageCatsSuccess}
                type='page'
                {...props}
            />
        </SimpleNavPage>
    )
}

const mapStateToProps = state => ({
    categories: workoutImageCatSelector(state)
})

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadWimageCats()),
    destroy: id => dispatch(destroyWimageCat(id))
})

export default connect(mapStateToProps,mapDispatchToProps)(WorkoutImageCatsPage);