import { CenteredActivityContainer } from 'components/ActivityContainer';
import { ActivityTab, ActivityTabs } from 'components/BottomNav';
import FormikWrapper from 'components/FormikWrapper';
import { Loader } from 'components/LoadingHOC';
import { dateFormat, NUTRITION } from 'config/settings';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { createPantryItem, destroyPantryItem, downloadGroceryList, loadGroceryList, switchClient } from 'redux/actions';
import { getFullRecipeSelector, glistPreferencesSelector, groceryListSelector, trainerRecordSelector, userRecordSelector, userWithMealPlanSelector } from 'redux/selectors';
import moment from 'moment';
import { CardForm, CheckboxCore, DefaultSelect, MultivalueCheckbox, TagButtons } from 'components/Form';
import { allMainMatches, foodInfoModalMatchFor, foodInfoModalPathFor, groceryListPathFor, rootPath, standaloneGlistPathFor, standaloneMealPlanMatch } from 'config/paths';
import { nextWeekCollection } from 'lib/utilities';
import Card from 'components/Card';
import { SlightEmphasisIconNote } from 'components/Typography';
import { ScrollableFormModal } from 'components/Modal';
import * as _ from 'lib/utilities';
import { ServingsToggleFormCore } from 'partials/ServingsToggleForm';
import { handleServingChgCreator, handleSeqChangeCreator, InitialTip, CordovaDownloadButton, ExitMealPlanButton } from 'partials/Utilities';
import * as Yup from 'yup';
import Button, { AsyncFixedSpinnerButton, FixedButton } from 'components/Button';
import { Redirect } from 'components/Routing';
import { useHistory } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import classnames from 'classnames';
import PaywallWrapper from 'partials/PaywallWrapper';
import RatingPrompt from 'partials/RatingPrompt';
import { PlanNotReady } from 'partials/PlanNotReady';
import { MealPlanStandaloneSetupSection } from 'partials/SetupCards';
import { SimpleNavPage } from 'components/Page';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import * as transitions from 'assets/transitions';
import { previewGlistUrlFor } from 'lib/api';


const getKeyFromPrefs = ({ week, startDate, offset, batch }) => `${week}-${startDate}-${offset}-${batch}`

const getDatesFromGlistPrefs = (user) => ({ week, startDate, offset, batch, all }) => {
    if(week === 'custom') {
        const start = moment().add(startDate,'days');
        const startDateStr = start.format(dateFormat);
        const endDate = start.add(offset,'days').format(dateFormat);
        return { startDate: startDateStr, endDate, batch, all }
    } else {
        const startDate = user.mealPlanStartDateFor(week);
        const endDate = user.mealPlanEndDateFor(week).format(dateFormat);
        return { startDate, endDate, all }
    }

}

class GroceryItemInfoContent extends React.Component {

    constructor(props) {
        super(props);
        const { setTitle, item } = props;
        if(item) {
            setTitle(item.food().name);
            this.state = { seq: item.food().defaultSeq(), amount: 1 }
        } else {
            this.state = {};
        }
    }

    render() {
        const { renderHeader, renderScrollable, renderFooter, recipes, item, t, basePath } = this.props;
        const { seq, amount } = this.state;
        const macroHash = item && item.food().macroHash(seq,amount);
    
        if(item) {
            return (
                <React.Fragment>
                    {renderHeader()}
                    <FormikWrapper
                        initialValues={ { servings: amount, seq: seq } }
                        submit={(values) => Promise.resolve({ status: 'SUCCESS' })}
                        validationSchema={Yup.object().shape({
                            servings: Yup.number().moreThan(0).required()
                        })}
                        initialErrors={{}}
                        render={({ handleSubmit, submitState, values, setValues, errors, handleBlur, handleChange }) => {
                            return (
                                <form onSubmit={handleSubmit}>
                                    {renderScrollable({ children: (
                                        <div className="text-center">
                                            <div className="pt20 pb20 pl10 pr10">
                                                <div className="basic-heading">{item.humanReadableQty()}</div>
                                                <div className="slight-emphasis-bcg mt10 mb10 inline-block pa10">
                                                    <div className="text-left mb10 font-grey">{t('Find brands with similar macros')}:</div>
                                                    <div><b>{_.macroHashSummary(t,macroHash)}</b></div>
                                                    <div>{t('per')}</div>
                                                    <ServingsToggleFormCore 
                                                        weights={item.food().weightsForForm()}
                                                        values={values}
                                                        setValueCreator={handleServingChgCreator(this.setAmount)}
                                                        errors={errors}
                                                        setValues={setValues}
                                                        handleBlur={handleBlur}
                                                        setSeqCreator={handleSeqChangeCreator(this.setSeq)}
                                                        handleChange={handleChange}
                                                    />  
                                                </div>
                                            </div>
                                            <div className="center faint-text">{t('Used in')}:</div>
                                            <div className="pt10 pb10 left-align-block">
                                                {item.recipesForPopup(recipes).map(({ recipe, servings },index) => {
                                                    return (
                                                        <React.Fragment key={recipe.id}>
                                                            {index !== 0 && (<div className="divider-line rp mt10"></div>)}
                                                            <div className="recipe-ingredient ml5 lh1" >
                                                                <div><b>{recipe.name}</b></div>
                                                                <div className="basic-heading-sub">{recipe.ingredientFor(item.food()).humanReadableQty(recipe.ingredientMult(servings),false,true)}</div>
                                                            </div>
                                                        </React.Fragment>

                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )})}
                                </form>
                            )
                        }}
                    />
                    {renderFooter({ children: (
                                <Button color="primary" rounded className="modal-close">
                                    {t('Ok')}
                                </Button>
                    ) })}
                </React.Fragment>
            )
        } else {
            return <Redirect to={basePath} />
        }
     
    }


    item = () => {
        const { groceryItems, match: { params: { id } } } = this.props;
        const item = _.find(groceryItems,item => item.food().id === Number(id));
        return item;
    }

    setSeq = (seq) => this.setState({ seq })
    setAmount = amount => this.setState( { amount } )
}

const mapStateToModalProps = (state,props) => {
    const { groceryItems, match: { params: { id } } } = props;
    const item = _.find(groceryItems,item => item.food().id === Number(id));
    if(item) {
        const recipes = {};
        item.recipeMeals.forEach(recipeMeal => {
            if(!recipes[recipeMeal.recipeId]) {
                const selector = getFullRecipeSelector(recipeMeal.recipeId)
                recipes[recipeMeal.recipeId] = selector(state);
            }
        })
        return {
            item,
            recipes
        }
    }

    return {
        item: null,
        recipes: null
    }

}

GroceryItemInfoContent = connect(mapStateToModalProps)(GroceryItemInfoContent)

const GroceryItemInfoModal = ({ basePath, groceryItems }) => {
    const { t } = useTranslation();
    const [title, setTitle] = useState('')
    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={foodInfoModalMatchFor(basePath)} 
            exact 
            icon={'info-circle'}
            title={title}
            render={({ renderFooter, renderScrollable, renderHeader, match }) => (
                <GroceryItemInfoContent 
                    groceryItems={groceryItems}
                    basePath={basePath}
                    match={match}
                    renderFooter={renderFooter}
                    renderScrollable={renderScrollable}
                    renderHeader={renderHeader}
                    setTitle={setTitle}
                    t={t}
                />
            )} 
        />
    )
}

let GroceryListTabs = ({ groceryList }) => {
    const { t } = useTranslation();
    let neededItems = 0, boughtItems = 0;
    if(groceryList) {
        neededItems = _.flatMap(groceryList.needed,group => group.groceryItems).length;
        boughtItems = _.flatMap(groceryList.bought,group => group.groceryItems).length;
    }

    return (
        <ActivityTabs>
            <ActivityTab id={"needed-tab"} path={groceryListPathFor('needed')} label={`${t('Shopping list')} (${neededItems})`} key={'needed'} />
            <ActivityTab id={"done-tab"} path={groceryListPathFor('done')} label={`${t('Already done')} (${boughtItems})`} key={'done'} />
        </ActivityTabs>
    )
}

const mapStateToTabProps = state => ({
    groceryList: groceryListSelector(state)
})

GroceryListTabs = connect(mapStateToTabProps)(GroceryListTabs)

export { GroceryListTabs }

const GroceryCheck = ({ item, listType, onCheckOff }) => {
    const [checked,setChecked] = useState(listType === 'done')

    return (
        <div className="grocery-check">
            <CheckboxCore 
                filled
                inputProps={{
                    checked: checked,
                    onChange: () => {
                        setChecked(!checked)
                        onCheckOff(item)
                    }
                }}
            />
        </div>
    )
}

const GroceryItem = React.forwardRef(({ item, history, onCheckOff, listType, showDivider, basePath, onClick, isStandalone },ref) => {

    return (
        <div className="grocery-item" onClick={onClick} ref={ref}>
            <div className="recipe-ingredient grocery-item ml5 mr30 clickable" onClick={() => history.push(foodInfoModalPathFor(basePath,item.food().id))}>
                <div className="basic-heading">{item.name()}</div>
                <div className="basic-heading-sub">{item.humanReadableQty()}</div>
            </div>
            {!isStandalone && (<GroceryCheck item={item} listType={listType} onCheckOff={onCheckOff} />)}
            <div className="mt10"></div>
            {showDivider && (<div className="divider-line rp"></div>)}
        </div>
    )
})

const GroceryGroup = ({ name, groceryItems, showDivider, onCheckOff, listType, basePath, showTip, isStandalone }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
            <div className="grocery-group">
                <div className="recipe-ingredient header">
                    <div className="basic-heading">{name}</div>
                </div>
                <TransitionGroup>
                    {groceryItems.map((item,index) => {
                        const shouldShow = (index === 0 && showTip);
                        const props = { isStandalone, item, history, onCheckOff, listType, basePath, showDivider: (index < groceryItems.length-1) };
                        let comp;
                        if(shouldShow) {
                            comp = (<InitialTip
                                tipName={'grocery_info_modal'}
                                delay={10000}
                                text={t("grocery tap info")}
                                passThroughProps={props}
                                component={GroceryItem}
                            />)
                        } else {
                            comp = <GroceryItem {...props} />
                        }
                        return (
                            <CSSTransition key={item.foodWeightId} timeout={200} classNames="list-slide">
                                {comp}
                            </CSSTransition>
                        )
                    })}
                </TransitionGroup>
                <div className="mt10"></div>
                {showDivider && (<div className="divider-line rp"></div>)}
            </div>
    )
}

class GroceryTypeList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { checkedOff: [] }
        const list = this.getList();
        this.state = { ...this.state, promptAllowed: list.length > 0 };
        this.queuedChecks = [];
    }

    render() {
        const { showMessage, basePath, listType, isStandalone, t } = this.props;
        const { promptAllowed } = this.state;
        const list = this.getList();
        const isIn = showMessage || list.length > 0;

        return (
            <RatingPrompt render={({ ratingPrompt }) => {
                const promptContent = promptAllowed ? ratingPrompt() : '';
                const show = isIn || !_.isBlank(promptContent);
                const total = 0;
                const insertIndex = _.findIndex(list,group => (total+group.groceryItems.length >= 4));
                let transitionChildren = list.map(({ name, groceryItems },index) => {
                    groceryItems = _.filter(groceryItems,this.checkIfItemActive)
                    return (
                        <CSSTransition 
                            key={name}
                            timeout={200}
                            classNames="list-slide"
                        >
                            <GroceryGroup 
                                key={name}
                                name={name}
                                isStandalone={isStandalone}
                                groceryItems={groceryItems}
                                showDivider={index < list.length-1}
                                showTip={index === 0}
                                onCheckOff={this.checkOff}
                                listType={listType}
                                basePath={basePath}
                            />
                        </CSSTransition>
                    )
                    
                })
    
                if(!_.isBlank(promptContent)) {
                    transitionChildren.splice(insertIndex === -1 ? transitionChildren.length : insertIndex+1,0,(
                        <CSSTransition 
                            key={'prompt'}
                            timeout={200}
                            classNames="list-slide"
                        >
                            <div>{promptContent}</div>
                        </CSSTransition>
                    ))
                }
    
                return (
                    <TransitionGroup>
                        {show && (<CSSTransition timeout={300} classNames="fade">
                            <Card className={classnames("pa10",{ "slide-left": listType === 'done'})} key={listType} style={{overflow: 'hidden'}}>
                                {showMessage && (<SlightEmphasisIconNote text={t("You don't have any groceries for the selected dates")} />)}
                                <TransitionGroup>
                                    {transitionChildren}
                                </TransitionGroup>
                    
                            </Card>
                        </CSSTransition>)}
                    </TransitionGroup>
                )
            }} />
        )
    }

    checkIfItemActive = gi => (!this.state.checkedOff.includes(gi.foodId))

    getList = () => _.filter(this.props.list,group => _.filter(group.groceryItems,this.checkIfItemActive).length > 0);

    setCheckedOff = checkedOff => this.setState({ checkedOff })

    checkOff = item => {
        this.setCheckedOff([ ...this.state.checkedOff, item.foodId ]);
        if(this.checkOffTimeout) {
            clearTimeout(this.checkOffTimeout);
        }
        this.queuedChecks.push(item);
        this.checkOffTimeout = setTimeout(this.finishCheckOff,1000);
    }

    finishCheckOff = () => {
        const { onCheckOff } = this.props;
        onCheckOff(this.queuedChecks);
        this.queuedChecks = [];
        this.checkOffTimeout = null;
    }
}

const GroceryListSuccess = ({ listType, ...props }) => {
    const { t } = useTranslation();
    
    return (
        <GroceryTypeList key={listType} listType={listType} t={t} {...props} />
    )
}

const GroceryListContent = ({ user, basePath, weekList, groceryList, loadGroceryList, downloadGroceryList, glistPreferences, createPantryItem, destroyPantryItem, match: { params: { type } }, isStandalone, ...rest }) => {
    const { t } = useTranslation();
    const getLoadData = getDatesFromGlistPrefs(user);
    const [newPrefs,setNewPrefs] = useState(null);
    const prefs = newPrefs || glistPreferences;
    const offsets = [ ...Array(14) ].map((nil,ind) => ({ text: ind+1, value: ind }));
    let list = [];
    let showMessage = false;
    let groceryItems = [];
    const key = getKeyFromPrefs(prefs);
    basePath = basePath || groceryListPathFor(type);


    if(user.mealPlanInitialized()) {
        if(groceryList) {
            list = type === 'done' ? groceryList.bought : groceryList.needed
            showMessage = type === 'needed' && (groceryList.bought.length === 0 && groceryList.needed.length === 0);
            groceryItems = _.flatMap(list,group => group.groceryItems);
        }
    
        return (
            <PaywallWrapper blockTypes={['hard']} context="grocery_list">
                <CenteredActivityContainer>
                    <FormikWrapper
                        autoSubmit
                        initialValues={ glistPreferences }
                        submit={(values) => {
                            setNewPrefs(values);
                            return Promise.resolve({ status: 'SUCCESS' })
                        }}
                        initialErrors={{}}
                        render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                            return (
                                <CardForm className="pl10 pt10 pb10" onSubmit={handleSubmit}>
                                    {weekList && (
                                        <DefaultSelect 
                                            className="flex-grow"
                                            name="week"
                                            label={t('List for')}
                                            collection={weekList}
                                            {...rest}
                                            handleChange={handleAutoSubmitChange}
                                        />
                                    )}
                                    {!weekList && (
                                        <React.Fragment>
                                            <div className="basic-heading mt10">{t('List for')}:</div>
                                            <div className="no-wrap" style={{ overflowX: 'auto'}}>
                                                <TagButtons
                                                    col={[{ text: t('This Week'), value: 'current' }, { text: t('Next Week'), value: 'next'}, {text: t('Custom'), value: 'custom'}]}
                                                    {...rest}
                                                    name={'week'}
                                                    single
                                                    btnId={val => `list-for-${val}-btn`}
                                                    buttonProps={ { className: ' ma2 btn-no-shadow' } }
                                                />
                                            </div>
                                            {rest.values.week === 'custom' && (<div className="valign-wrapper flex-end mt10">
                                                <DefaultSelect 
                                                    className="flex-grow"
                                                    name="startDate"
                                                    label={t('First day')}
                                                    collection={nextWeekCollection(dateFormat,true)}
                                                    {...rest}
                                                    handleChange={handleAutoSubmitChange}
                                                />
                                                <DefaultSelect 
                                                    className="ml5"
                                                    name="offset"
                                                    label={t('# of days')}
                                                    collection={offsets}
                                                    {...rest}
                                                    handleChange={handleAutoSubmitChange}
                                                />
                                                <MultivalueCheckbox 
                                                    name="batch"
                                                    checkedVal={true}
                                                    uncheckedVal={false}
                                                    values={rest.values}
                                                    setFieldValue={rest.setFieldValue}
                                                    label={t('Batching?')} 
                                                    filled 
                                                    className="no-wrap ml5 mb5"
                                                    tooltip={t('batching tip')}
                                                />
                                            </div>)}
                                        </React.Fragment>
                                    )}
                                    {!window.isCordova && (<AsyncFixedSpinnerButton 
                                        buttonProps={{
                                            color: 'primary',
                                            id: 'download-glist-btn'
                                        }}
                                        icon={['far','download']}
                                        action={() => downloadGroceryList(getLoadData(prefs))}
                                    />)}
                                    {window.isCordova && window.cordova.InAppBrowser && (
                                        <CordovaDownloadButton 
                                            url={previewGlistUrlFor(getLoadData(prefs),user)}
                                            render={({ onClick, downloading }) => {
                                                return (
                                                    <FixedButton 
                                                        icon={downloading ? 'spinner' : ['far','download']}
                                                        spin={downloading}
                                                        color="primary"
                                                        id="download-glist-btn"
                                                        onClick={onClick}
                                                    />
                                                )
                                            }}
                                        />
                                    )}
                                </CardForm>
                            )
                        }}
                    />
                    <Loader
                        key={key}
                        successComponent={GroceryListSuccess}
                        type="page"
                        load={loadGroceryList.bind(null,prefs,getLoadData(prefs))}
                        preloaded={() => {
                            if(isStandalone) {
                                return false;
                            }
                            return !!groceryList && !newPrefs;
                        }}
                        list={list}
                        isStandalone={isStandalone}
                        showMessage={showMessage}
                        basePath={basePath}
                        onCheckOff={type === 'needed' ? createPantryItem : destroyPantryItem}
                        listType={type}
                    />
                    <GroceryItemInfoModal groceryItems={groceryItems} basePath={basePath} />
                </CenteredActivityContainer>
            </PaywallWrapper>
        )
    } else if(user.setupOwnMealPlanPrefs()) {
        return (
            <MealPlanStandaloneSetupSection user={user} />
        )
    } else {
        return (
            <PlanNotReady type={NUTRITION} />
        )
    }

}

const mapStateToProps = state => ({
    user: userWithMealPlanSelector(state),
    glistPreferences: glistPreferencesSelector(state),
    groceryList: groceryListSelector(state)
})

const mapStateToStandaloneProps = state => ({
    groceryList: groceryListSelector(state)
})

const mapDispatchToProps = dispatch => ({
    loadGroceryList: (preferences,data) => dispatch(loadGroceryList(preferences,data)),
    downloadGroceryList: data => dispatch(downloadGroceryList(data)),
    createPantryItem: item => dispatch(createPantryItem(item)),
    destroyPantryItem: item => dispatch(destroyPantryItem(item))
})

const GroceryList = connect(mapStateToProps,mapDispatchToProps)(GroceryListContent)
const StandaloneGroceryList = connect(mapStateToStandaloneProps,mapDispatchToProps)(GroceryListContent)

const standaloneTmap = {
    rules: [
        [[...allMainMatches,standaloneMealPlanMatch,rootPath],transitions.slideOut]
    ]
};

let StandaloneGroceryListPage = ({ trainer, user, scrollRef, setupTransitions, switchClient, match, match: { params: { clientId, week } } }) => {
    const { t } = useTranslation();
    const client = (user.id === Number(clientId)) ? user : trainer.clientById(clientId);

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            setupTransitions={setupTransitions} 
            transitionMap={standaloneTmap}
            title={t("name's glist", { name: client.shortName() })}
            navChildren={<ExitMealPlanButton />}
        >
            <TrainerPaywall type={NUTRITION} allowUninit={false}>
                <Loader
                    key={client.id}
                    load={() => switchClient(clientId)}
                    preloaded={() => (user && user.id === Number(clientId))}
                    successComponent={StandaloneGroceryList}
                    user={client}
                    glistPreferences={{ week, startDate: 0, offset: 7, batch: false, all: true }}
                    match={match}
                    weekList={_.isNumeric(week) && client.mealPlanWeeksCol(t)}
                    isStandalone={true}
                    type='page'
                    basePath={standaloneGlistPathFor(clientId,week)}
                />
            </TrainerPaywall>
        </SimpleNavPage>
    )
}

const mapStateToStandalonePageProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToStandalonePageProps = dispatch => ({
    switchClient: (clientId,focusDate) => dispatch(switchClient(clientId,focusDate))
})

StandaloneGroceryListPage = connect(mapStateToStandalonePageProps,mapDispatchToStandalonePageProps)(StandaloneGroceryListPage);

export { StandaloneGroceryListPage }

export default GroceryList;
