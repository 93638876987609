import React from 'react';
import { allMainMatches, mealPlanSetupMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import Card from 'components/Card';
import { Link } from 'components/Routing';

const transitionMap = {
    rules: [
        [[...allMainMatches,mealPlanSetupMatch],transitions.slideOut]
    ]
};

class NutritionInfoPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("Nutrition Data & Methodology")}
            >
                <CenteredActivityContainer>
                    <Card>
                        <div className="pa20">
                            <div>
                                <div><b>Where do you get your nutrition facts from?</b></div>
                                <p>
                                    For our recipes, most of our ingredients are sourced from the <Link to={'https://fdc.nal.usda.gov/api-guide.html'} target="_blank">USDA's FoodData Central database</Link>. For barcode scanning and some off-plan foods we use <Link to={'https://platform.fatsecret.com/platform-api'} target="_blank">FatSecret's Food and Nutrition API</Link> which sources and verifies nutrition information.
                                </p>

                                <div><b>How are calorie and macro recommendations calculated?</b></div>
                                <p>
                                    Our calorie recommendations use the Mifflin St. Jeor equation which is used for estimating the Basal Metabolic Rate (BMR). See <Link to={'https://en.wikipedia.org/wiki/Basal_metabolic_rate'} target="_blank">here</Link> for details.
                                </p>
                                <p>
                                    The macro recommendations use the recommendations outlined by the The International Society of Sports Nutrition (ISSN). See <Link to={'https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5470183/'} target="_blank">here</Link> for details.
                                </p>
                            </div>
                        </div>
                    </Card>
                </CenteredActivityContainer>
            </SimpleNavPage>
        )
    }
}

export default withTranslation()(NutritionInfoPage);