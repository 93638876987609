import React from 'react';
import { LayoutSwitcher } from 'partials/MainLayout';
import { withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import BodyMeasurements from 'partials/BodyMeasurements';
import { ExerciseChartsTab } from 'partials/ProgressCharts';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { strengthBodyStatsPath } from 'config/paths';

const Core = ({ activeTab }) => {
    const tabIndex = _.isBlank(activeTab) ? 1 : activeTab;

    return (
        <React.Fragment>
                {tabIndex === 1 && <BodyMeasurements />}
                {tabIndex === 2 && (
                <CenteredActivityContainer>
                    <ExerciseChartsTab basePath={strengthBodyStatsPath} isTab />
                </CenteredActivityContainer>
                )}
        </React.Fragment>
    )
}

class StrengthAndBodyStatsPage extends React.Component {


    render() {
        const { t } = this.props;
        const tabLabels = [t('Body'),t('Strength')];

        return (
            <LayoutSwitcher {...this.props} title={`${t('Strength')}/${t('Body Stats')}`} tabLabels={tabLabels}>
                <Core />
            </LayoutSwitcher>
        )
    }
}

export default withTranslation()(StrengthAndBodyStatsPage);
