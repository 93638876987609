import React from 'react';
import Page from'components/Page';
import * as transitions from 'assets/transitions';
import { progressionPickPathFor, workoutPreviewMatch, workoutLogPathFor,exerciseInfoModalPathFor, 
    workoutLogMatch, exerciseSettingsModalPathFor, 
    plateCalculatorModalPathFor, progressionPickMatch, swapExerciseSpecPathFor, progressionShowMatch,
    swapExerciseSpecMatch, 
    progressionTestPathFor,
    strengthTestPathFor,
    strengthTestMatch,
    progressionTestMatch,
    workoutDoMatch,
    workoutCompleteModalPathFor,
    emailPaywallMatches,
    allMainMatches,
    modalPathFor,
    weightCalcModalPostfix} from 'config/paths';
import { SimpleNavRightButton, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation, useTranslation } from 'react-i18next';
import withWorkout from 'partials/RoutineStateHOC';
import moment from 'moment';
import Card from 'components/Card';
import * as _ from 'lib/utilities';
import Dropdown from 'components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExerciseInfoModal, WeightCalculatorModal, ExerciseSettingsModal, PlateCalculatorModal } from 'partials/ExerciseModals';
import { Link, useLocation } from 'react-router-dom';
import { CheckboxCore } from 'components/Form';
import { logExerciseSets, updateWorkout, workoutsLoggedThisWeek } from 'redux/actions';
import { connect } from 'react-redux';
import { NumberCell, TextCell, TimeField, TelCell } from 'components/TextInput';
import classnames from 'classnames';
import { CountdownTimer, MuteTimerButton } from 'partials/ConnectedTimers';
import PlusMinusToggle from 'components/PlusMinusToggle';
import { Redirect } from "components/Routing";
import LinkButton from 'components/LinkButton';
import { IconTip } from 'components/Tooltipped';
import { SimpleNav } from 'components/Nav';
import ProRequiredButton from 'partials/ProRequiredButton';
import { SignupModal } from 'partials/SignupModal';
import RouteModal, { ScrollableFormModal } from 'components/Modal';
import RatingPrompt from 'partials/RatingPrompt';
import { BackToHomeButton, WorkoutDone } from './WorkoutDo';
import { resolvedHomePath } from 'redux/helpers';
import { RatingButton } from './ViewLogs';
import { ExerciseVideo } from 'partials/ExerciseImage';

const transitionMap = {
    rules: [
        [[...allMainMatches,workoutPreviewMatch],transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch,swapExerciseSpecMatch, ...emailPaywallMatches,strengthTestMatch, progressionTestMatch, workoutDoMatch], transitions.slideOver]
    ]
};

const CompleteModal = ({ basePath, ...rest }) => {
    const { t } = useTranslation();
    const { workout } = rest;

    if(workout.user.isClient()) {
        return (
            <ScrollableFormModal
                fullWidth 
                noOverflow 
                limitWidth 
                path={workoutCompleteModalPathFor(basePath)} 
                exact 
                title={`${t('Workout complete')}!`}
                icon="trophy"
                render={({ close, closeAndGo, renderHeader, renderFooter, renderScrollable }) => {
    
                    return (
                        <React.Fragment>
                            {renderHeader()}
                            {renderScrollable({
                                children: [
                                    <div className="text-center mb10 mt10">
                                        <WorkoutDone {...rest} isModal closeAndGo={closeAndGo} />
                                    </div>
                                ]
                            })}
                            {renderFooter({
                                children: [
                                    <BackToHomeButton t={t} closeAndGo={closeAndGo} />
                                ]
                            })}
                        </React.Fragment>
                    )
                }} 
            />
        )
    }


    return (
        <RouteModal fullWidth path={workoutCompleteModalPathFor(basePath)} exact render={(modalProps) => {
            return (
                <div className="text-center">
                    <WorkoutDone {...rest} closeAndGo={modalProps.closeAndGo} />
                </div>
            )
        }} />
    )
}

export const ExerciseActionMenu = ({ spec, basePath, allowEditing, isInitial, infoOnly }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    if(spec.isVideoOnly()) {
        return '';
    }

    return (
        <Dropdown 
                contentComp='ul'
                options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                triggerRender={({ ref, target }) => {
                    return (
                            <div className="wol-right-icon" ref={ref} data-target={target} id={`spec-dropdown-btn-${spec.id}`}>
                                <FontAwesomeIcon icon="ellipsis-h" />
                            </div>
                    )
                }}
                contentRender={({ recalcDims }) => {
                    return (
                        <React.Fragment>
                            <li>
                                <Link to={exerciseInfoModalPathFor(basePath,spec.exerciseId)} id={`spec-exercise-info-btn-${spec.id}`}>
                                    <FontAwesomeIcon icon="info-circle" /> {t("Exercise Info")}
                                </Link>
                            </li>
                            {!infoOnly && (
                                <React.Fragment>
                                    {allowEditing && spec.canTestRepMax() && !isInitial && (<li>
                                        <Link to={modalPathFor(weightCalcModalPostfix,pathname,{ id: spec.resolvedExerciseId() })} id={`spec-calc-weight-btn-${spec.id}`}>
                                            <FontAwesomeIcon icon="calculator" /> {t("Calculate Weight")}
                                        </Link>
                                    </li>)}
                                    {allowEditing && spec.canTestRepMax() && !isInitial && (<li>
                                        <Link to={strengthTestPathFor(spec.id,spec.date(),0)} id={`spec-strength-test-btn-${spec.id}`}>
                                            <FontAwesomeIcon icon="tachometer-alt" /> {t('Take a Strength Test')}
                                        </Link>
                                    </li>)}
                                    {spec.isBarbell() &&  !isInitial && (<li>
                                        <Link to={plateCalculatorModalPathFor(basePath,spec.id)} id={`spec-plate-calc-btn-${spec.id}`}>
                                            <FontAwesomeIcon icon="dot-circle" /> {t("Plate Calculator")}
                                        </Link>
                                    </li>)}
                                    {allowEditing && spec.isProgression() && !isInitial && (<li>
                                        <Link to={progressionPickPathFor(spec.id,spec.workout.date,'log')} id={`spec-prog-pick-btn-${spec.id}`}>
                                            <FontAwesomeIcon icon="list" /> {t("Use an easier/harder exercise")}
                                        </Link>
                                    </li>)}
                                    {allowEditing && spec.isProgression() && !isInitial && (<li>
                                        <Link to={progressionTestPathFor(spec.id,spec.date(),0)} id={`spec-prog-test-btn-${spec.id}`}>
                                            <FontAwesomeIcon icon="tachometer-alt" /> {t('Take a Strength Test')}
                                        </Link>
                                    </li>)}
                                    <li>
                                        <Link to={exerciseSettingsModalPathFor(basePath,spec.exerciseId)} id={`spec-exercise-settings-btn-${spec.id}`}>
                                            <FontAwesomeIcon icon="cog" /> {t("Exercise Settings")}
                                        </Link>
                                    </li>
                                    {!spec.anyLogged() && allowEditing && !isInitial && spec.user().allowedToEditOwnRoutine() && (<li>
                                            <ProRequiredButton 
                                                user={spec.user()} 
                                                proPath={swapExerciseSpecPathFor(spec.workout.date,spec.id)} 
                                                blockTypes={['hard','soft']}
                                                context="swap_exercise"
                                                render={({ onClick }) => (
                                                    <Link to="" id={`spec-swap-btn-${spec.id}`} onClick={onClick}>
                                                        <FontAwesomeIcon icon="exchange-alt" /> {t("Swap Out")}
                                                    </Link>
                                                )} 
                                            />
                                        </li>
                                    )}
                                </React.Fragment>
                            )}
                        </React.Fragment>
                    )
                }}
            />
    )
}

const ExerciseLogHeader = ({ exerciseSpecification: spec, readOnly, basePath }) => {

    return (
        <div className="display-flex wol-exercise-container" id={`spec-card-header-${spec.id}`}>
            <div className="wol-exercise-title abbr-text">
                <span>{spec.exerciseName()}</span>
                {readOnly && !_.isBlank(spec.difficultyRating) && (<RatingButton rating={spec.difficultyRating} />)}
            </div>
            {spec.comments() && (<div className="wol-exercise-title ml0 mr0"><IconTip size='1x' icon="info-circle" className="font-grey" msg={spec.comments()}/></div>)}
            <ExerciseActionMenu spec={spec} basePath={basePath || workoutLogPathFor(spec.workout.date)} allowEditing infoOnly={readOnly} />
        </div>
    )
}

const SetCheckbox = ({ checked, onChange, disabled, id }) => {

    return (
        <CheckboxCore filled id={id} inputProps={{ value: 1, checked, onChange: (disabled ? () => {} : onChange) }} middle className="less-padding" labelClasses="set-check" />
    )
}

const SpreadsheetHeaderRow = ({ spec, logParams, logAll, fullyLogged, readOnly }) => {

    return (
        <div className="spreadsheet-head">
            {Object.entries(logParams).map(([param,title]) => {

                if(param === 'logType') {
                    return (
                        <div key={param} className={`spreadsheet-head-cell wol-checkbox head-cell-for-${spec.id}`} id={`spec-head-cell-${param}-${spec.id}`}>
                            <SetCheckbox checked={fullyLogged} onChange={logAll} disabled={readOnly} />
                        </div>
                    )
                } else {
                    return (<div key={param} className={`spreadsheet-head-cell head-cell-for-${spec.id}`} id={`spec-head-cell-${param}-${spec.id}`}>{title}</div>)
                }
            })}
        </div>
    )
}

const ExerciseSetField = ({ set, param, changeHandler, blurHandler, weightBlurHandler, errors, values, setValues, touched, t, activeTimer, logSet, readOnly }) => {

    const textOnly = values.logType === 1 || readOnly;
    const textField = (val) => (<span className="pl5 pr5">{val}</span>)

    if(param === 'goal') {
        return textField(set.goal(t));
    } else if(param === 'restTime') {
        if(activeTimer && set.restTime > 0) {
            return (<CountdownTimer seconds={set.restTime} autoStart soundOn render={({ display }) => (<span className="workout-log-rest-timer" id={`rest-timer-${set.id}`}>{display}</span>)} />);
        } else {
            return textField(set.restStr(t));
        }
    } else if(param === 'weight' || param === 'kgWeight') {
        if(textOnly) {
            return textField(values[param]);
        } else {
            const weightRegex = set.hasBaseWeight()  ? /[0-9-.]+/ : /[0-9.]+/;
            return (
                <NumberCell 
                    name={param} 
                    error={errors[param] && touched[param]} 
                    onChange={changeHandler}
                    limitPattern={weightRegex}
                    onBlur={weightBlurHandler} 
                    value={values[param]} 
                    className="input-text"
                />
            )
        }
    } else if (param === 'logType') {
        return (
            <SetCheckbox checked={values.logType === 1} onChange={logSet} id={`set-log-check-${set.id}`} disabled={readOnly} />
        )
    } else if(param === 'reps') {
        if(textOnly) {
            return textField(values[param]);
        } else {
            return (<PlusMinusToggle value={values[param]} setValue={(val) => setValues({ reps: Math.max(val,0) })} render={({ value }) => {
                return (<span className="input-bold">{value}{set.amrap && set.minReps === value && (<span style={{ fontWeight: 100}}>+</span>)}</span>)
            }} />)
        }
    } else if(param === 'unbroken') {
        if(textOnly) {
            if(values.unbroken) {
                return textField(t("Yes"));
            } else {
                return textField(t("No"));
            }
        } else {
            return (
                <SetCheckbox checked={values.unbroken} onChange={(e) => setValues({ unbroken: !values.unbroken })} />
            )
        }
    } else if(param === 'minSecTime' || param === 'isoTime') {
        if(textOnly) {
            return values[param];
        } else {
            return (
                <TimeField 
                    component={TelCell}
                    name={param} 
                    error={errors[param] && touched[param]} 
                    onChange={changeHandler} 
                    onBlur={blurHandler}
                    value={values[param]}
                    className={'input-text'}
                />
            )
        }
    } else if(param === 'distance' || param === 'kmDistance' || param === 'mileDistance') {
        if(textOnly) {
            return textField(values[param]);
        } else {
            const regex = param === 'distance'  ? /[0-9]+/ : /[0-9.]+/;
            return (
                <NumberCell 
                    name={param} 
                    error={errors[param] && touched[param]} 
                    onChange={changeHandler} 
                    limitPattern={regex}
                    onBlur={blurHandler} 
                    value={values[param]} 
                    className="input-text"
                />
            )
        }
    } else if(param === 'arbitraryMeasure') {
        if(textOnly) {
            return textField(values[param]);
        } else {
            return (
                <TextCell 
                    name={param} 
                    error={errors[param] && touched[param]} 
                    onChange={changeHandler} 
                    onBlur={weightBlurHandler} 
                    value={values[param]} 
                    className="input-text"
                />
            )

        }
    }
}

const ExerciseSetRow = ({ set, values, logParams, changeHandler, weightBlurHandler, blurHandler, setValues, touched, errors, activeTimer, logSet, readOnly, t }) => {

    const isLogged = values.logType === 1;
    const classNames = classnames("spreadsheet-row",{ "logged": isLogged });
    

    return (
        <div className={classNames} id={`exercise-set-row-${set.id}`}>
            {Object.keys(logParams).map(param => {
                const classNames = classnames("spreadsheet-cell", { "wol-checkbox": (param === 'logType')});
                return (
                    <div key={param} className={classNames} id={`set-field-cell-${param}-${set.id}`}>
                        <ExerciseSetField 
                            readOnly={readOnly}
                            set={set}
                            param={param} 
                            changeHandler={changeHandler} 
                            blurHandler={blurHandler}
                            weightBlurHandler={weightBlurHandler}
                            errors={errors} 
                            values={values} 
                            setValues={setValues} 
                            touched={touched} 
                            logSet={() => logSet(set.id,isLogged ? 0 : 1)}
                            activeTimer={activeTimer}
                            t={t}
                        />
                    </div>
                )
            })}
        </div>
    )

}

const ExerciseSpreadsheetForm = ({ spec, logAll, logSet, currentValues, changeHandler, blurHandler, weightBlurHandler, setSetValues, errors, touched, fullyLogged, activeTimerId, readOnly }) => {
    const { t } = useTranslation();
    const logParams = spec.logParams(t);

    return (
        <div className="spreadsheet-form">
            <SpreadsheetHeaderRow 
                readOnly={readOnly}
                spec={spec}
                logParams={logParams} 
                fullyLogged={fullyLogged}
                logAll={() => logAll(spec,fullyLogged ? 0 : 1)} 
            />
            {spec.workSets().map(set => (
                <ExerciseSetRow 
                    readOnly={readOnly}
                    key={set.id} 
                    set={set} 
                    logParams={logParams} 
                    values={currentValues[set.id]} 
                    errors={errors[set.id] || {}}
                    touched={touched[set.id] || {}}
                    changeHandler={(e) => changeHandler(set.id,e)}
                    logSet={logSet}
                    blurHandler={(e) => blurHandler(set.id,e)}
                    weightBlurHandler={(e) => weightBlurHandler(set.id,e)}
                    setValues={(values) => setSetValues(set.id,values)}
                    activeTimer={set.id === activeTimerId}
                    t={t}
                />
            ))}
        </div>
    )
}

const VideoOnlyForm = ({ spec, logSet, currentValues, readOnly }) => {
    const set = spec.workSets()[0];
    const values = currentValues[set.id];
    const isLogged = values.logType === 1;
    const { t } = useTranslation();

    return (
        <div className="text-center">
            <ExerciseVideo exercise={spec.exercise} autoplay={false} fallbackToYT />
            <div className="pt20 pb20">
                <CheckboxCore 
                    filled 
                    inputProps={{ value: values.logType, checked: isLogged, onChange: (readOnly ? (() => {}) : () => logSet(set.id,isLogged ? 0 : 1)) }} 
                    label={t("Logged")}
                    disabled={readOnly}
                    labelClasses="set-check"
                    id={`set-log-check-${set.id}`}
                 />
            </div>
        </div>
    )
}

const ExerciseLogCard = ({ exerciseSpecification: spec, currentValues, readOnly, basePath, ...rest }) => {
    const fullyLogged = _.every(Object.values(_.pick(currentValues,spec.workSetIds())),vals => (vals.logType === 1));
    const classNames = classnames("workout-log",{ "mt20": !spec.isSupersetChild(), logged: fullyLogged })
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Card className={classNames} id={`exercise-spec-card-${spec.id}`}>
                <ExerciseLogHeader exerciseSpecification={spec} readOnly={readOnly} basePath={basePath} />
                {!spec.isVideoOnly() && (<ExerciseSpreadsheetForm spec={spec} currentValues={currentValues} fullyLogged={fullyLogged} readOnly={readOnly} {...rest} />)}
                {spec.isVideoOnly() && (<VideoOnlyForm spec={spec} currentValues={currentValues} readOnly={readOnly} {...rest} />)}
            </Card>
            {spec.isSuperset() && (<div className="superset-label" id={`superset-label-for-${spec.id}`}><FontAwesomeIcon icon="arrow-down"/> {spec.supersetLabel(t)} <FontAwesomeIcon icon="arrow-up"/></div>)}
        </React.Fragment>
    )
}

export class WorkoutLogCore extends React.Component {

    constructor(props) {
        super(props);
        this.state = { setDrafts: {}, setErrors: {}, setTouched: {}, activeTimerId: null };
        this.setSetValues = this.setSetValues.bind(this);
        this.changeHandler = this.changeHandler.bind(this);
        this.blurHandler = this.blurHandler.bind(this);
        this.weightBlurHandler = this.weightBlurHandler.bind(this);
        this.logAll = this.logAll.bind(this);
        this.logSet = this.logSet.bind(this);
    }

    render() {
        const { workout, t, updateWorkout, getWorkoutsLoggedThisWeek, tableOnly, readOnly, basePath } = this.props;

        if(workout) {
            const path = workoutLogPathFor(workout.date);
            const table = (<React.Fragment>
                {workout.exerciseSpecifications.map(spec => (
                    <ExerciseLogCard 
                        key={spec.id} 
                        readOnly={readOnly}
                        basePath={basePath}
                        exerciseSpecification={spec}
                        setSetValues={this.setSetValues}
                        changeHandler={this.changeHandler}
                        blurHandler={this.blurHandler}
                        weightBlurHandler={this.weightBlurHandler}
                        logAll={this.logAll}
                        logSet={this.logSet}
                        currentValues={this.currentSetValues()}
                        errors={this.state.setErrors}
                        touched={this.state.setTouched}
                        activeTimerId={this.state.activeTimerId}
                    />
                ))}
                <ExerciseInfoModal basePath={basePath || path} baseMatch={basePath || workoutLogMatch} />
            </React.Fragment>);

            if(tableOnly) {
                return table;
            }

            return (
                <div id={`workout-log-${workout.id}`}>
                    <div className="row">
                        <div className="col s12 m12 l6 offset-l3">
                            <div className="text-center mb10 mt20">
                            <LinkButton rounded color="primary" outlined noShadow to={workout.startPath()}>
                                <FontAwesomeIcon icon="play"></FontAwesomeIcon> {t("Do this workout")}
                            </LinkButton>
                            </div>
                            {table}
                        </div>
                    </div>
                    <WeightCalculatorModal showVideo basePath={path} baseMatch={workoutLogMatch} callback={(status,formikBag,data,setCalcStatus) => this.workoutActionSuccess(['weight','kgWeight'],data,status,setCalcStatus)} date={workout.date} />
                    <ExerciseSettingsModal basePath={path} baseMatch={workoutLogMatch} />
                    <PlateCalculatorModal basePath={path} baseMatch={workoutLogMatch} />
                    <SignupModal basePath={workoutLogMatch} />
                    <RatingPrompt path={path} basePath={path} context="workoutComplete" render={({ requestRating }) => (
                        <CompleteModal
                            basePath={path}
                            t={t} 
                            workout={workout} 
                            updateWorkout={updateWorkout}
                            getWorkoutsLoggedThisWeek={getWorkoutsLoggedThisWeek}
                            requestRating={requestRating}
                        />
                    )} />
                </div>
            )
        } else {
            return (<Redirect to={resolvedHomePath()} />)
        }
    }

    logAll(spec,logType) {
        const sets = spec.workSets();
        this.touchAllMulti(sets);
        const updated = this.validateAndPersistMulti(sets.map(set => set.id),_.mapValues(_.keyBy(sets,set => set.id),set => ({ logType })),true);
        if(logType === 1 && updated.length > 0) {
            this.setState({activeTimerId: updated[updated.length-1].id});
            this.afterLog();
        } else if(logType === 0 && updated.map(set => set.id).includes(this.state.activeTimerId)) {
            this.setState({activeTimerId: null});
        }
    }

    logSet(id,logType) {
        const updated = this.setSetValues(id, { logType }, true, true)[0];
        if(updated) {
            if(logType === 1) {
                this.setState({activeTimerId: id});
                this.afterLog();
            } else if(this.state.activeTimerId === id) {
                this.setState({activeTimerId: null});
            }
        }
    }

    afterLog = () => {
        const allVals = Object.values(this.currentSetValues());
        if(_.every(allVals,vals => vals.logType === 1)) {
            const { workout, history } = this.props;
            history.push(workoutCompleteModalPathFor(workoutLogPathFor(workout.date)));
        }
    }

    setSetValues(id,values,touchAll=false,rejectInvalid=false) {
        if(touchAll) {
            this.touchAll(id);
        } else {
            this.touchFields(id,values);
        }
        return this.validateAndPersist(id,values,rejectInvalid);
    }

    changeHandler(id,e) {
        const num = e.target.type === 'number';
        const val = (num && _.isNumeric(e.target.value)) ? Number(e.target.value) : e.target.value;
        const name = e.target.name;
        const { setDrafts, setErrors, setDrafts: { [id]: drafts={} }, setErrors: { [id]: errors={} } } = this.state;
        this.setState({ setDrafts: { ...setDrafts, [id]: { ...drafts, [name]: val }}, setErrors: { ...setErrors, [id]: _.omit(errors,name)}});
    }

    blurHandler(id,e) {
        const name = e.target.name
        this.validateAndPersist(id);
        this.touchFields(id,{ [name]: true });
    }

    weightBlurHandler(id,e) {
        const name = e.target.name;
        const updatedSet = this.validateAndPersist(id,{},false,true)[0];
        if(updatedSet) {
            let linkedIds = updatedSet.linkedSets().map(set => set.id);
            linkedIds.push(updatedSet.id);
            this.clearDraftFields(linkedIds,['kgWeight','weight','arbitraryMeasure']);
        }
        this.touchFields(id,{ [name]: true });
    }

    validateAndPersist(id,changes={},rejectInvalid=false,copyWeights=false) {
        return this.validateAndPersistMulti([id],{ [id]: changes },rejectInvalid,copyWeights);
    }

    validateAndPersistMulti(ids,setChanges={},rejectInvalid=false,copyWeights=false) {
        const curSetVals = this.currentSetValues();
        ids = ids instanceof Array ? ids : [ids];
        const finalUpdateSets = [];
        let { setDrafts: newSetDrafts, setErrors: newSetErrors } = this.state;
        const { workout, updateSets, t } = this.props;
        ids.forEach(id => {
            const changes = setChanges[id] || {};
            const set = workout.getSet(id);
            const { [id]: draft={} } = newSetDrafts;
            const fullChanges = { ...curSetVals[id], ...draft, ...changes };
            const errors = set.update(fullChanges,true,t);
            
            if(_.isEmpty(errors)) {
                finalUpdateSets.push(set);
                newSetDrafts = { ...newSetDrafts, [id]: {}};
                newSetErrors = { ...newSetErrors, [id]: {}};
            } else {
                const newDraft = rejectInvalid ? draft : fullChanges;
                newSetDrafts = { ...newSetDrafts, [id]: newDraft };
                newSetErrors = { ...newSetErrors, [id]: errors };
            }
        })
        if(finalUpdateSets.length > 0) {
            updateSets(workout,finalUpdateSets,copyWeights);
        }
        this.setState({ setDrafts: newSetDrafts, setErrors: newSetErrors });
        return finalUpdateSets;
    }

    currentSetValues() {
        const { workout } = this.props;
        const { setDrafts } = this.state;
        return _.mapValues(_.keyBy(workout.workSets(), set => set.id),set => ({ ...set.formValues(true), ...(setDrafts[set.id] || {}) }));
    }

    touchAllMulti(sets) {
        const { setTouched } = this.state;
        const newTouched = _.mapValues(_.keyBy(sets,set => set.id),set => _.mapValues(set.formValues(),val => true));
        this.setState({ setTouched: { ...setTouched, ...newTouched }});
    }

    touchAll(id) {
        const { workout } = this.props;
        const set = workout.getSet(id);
        this.touchFields(id,set.formValues());
    }

    touchFields(id,fields) {
        this.setState({ setTouched: { ...this.state.setTouched, [id]: { ...(this.state.setTouched[id] || {}), ..._.mapValues(fields,set => true) } }})
    }

    clearDraftFields(ids,fields) {
        let { setDrafts: newSetDrafts, setErrors: newSetErrors } = this.state;
        ids.forEach(id => {
            const { [id]: drafts={} } = newSetDrafts;
            const { [id]: errors={} } = newSetErrors;

            newSetDrafts = { ...newSetDrafts, [id]: _.omit(drafts,fields) };
            newSetErrors = { ...newSetErrors, [id]: _.omit(errors,fields) };
        })
        this.setState({ setDrafts: newSetDrafts, setErrors: newSetErrors });
    }

    workoutActionSuccess(fields,data,status,setCalcStatus) {
        const { workout } = this.props;
        const ids = Object.keys(data.exerciseSets).map(id => Number(id));
        this.clearDraftFields(ids,fields);
        if(workout.hasAllSets(ids) && ids.length !== 0) {
            setCalcStatus('success');
        } else {
            setCalcStatus('failed');
        }
    }
}

const mapDispatchToProps = dispatch => ({
    updateSets: (workout,sets,updateWeights) => dispatch(logExerciseSets(workout,sets,updateWeights)),
    updateWorkout: (workout,updates) => dispatch(updateWorkout(workout,updates)),
    getWorkoutsLoggedThisWeek: (date) => dispatch(workoutsLoggedThisWeek(date))
})

const WithWorkout = withWorkout('dirty','page',true)(connect(null,mapDispatchToProps)(withTranslation()(WorkoutLogCore)))

class WorkoutLogPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { match: { params: { date }}, t, scrollRef, history } = this.props;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t("Workout Log")}</SimpleNavTitle>
                    <MuteTimerButton render={({ soundOn, muteTimers }) => {
                        if(soundOn) {
                            return (<SimpleNavRightButton id="mute-timers-btn" onClick={() => muteTimers(1)} icon="volume-up" />)
                        } else {
                            return (<SimpleNavRightButton id="unmute-timers-btn" onClick={() => muteTimers(0)} icon="volume-mute" />)
                        }
                    }} />
                </SimpleNav>
                <SimpleNavContainer>
                    <WithWorkout date={moment(date)} history={history} />
                </SimpleNavContainer>
            </Page>
        )
    }
}

export default withTranslation()(WorkoutLogPage);
