import { RecordBase, registerInflection } from 'lib/record-base';
import moment from 'moment';
import progPhotoPlaceholder from 'assets/img/progPhotoPlaceholder.png';

export class ProgressPhoto extends RecordBase {
    static NAME = 'ProgressPhoto'

    static ASSOCS = {
        user: { type: 'belongsTo' },
        formFields: { type: 'hasMany', inverse: 'dataEntry' }
    }

    sortAttr() {
        return moment(this.date).valueOf();
    }
    
    hasImage() {
        return (this.image && this.image.url && !this.image.url.includes('NoImage'));
    }

    resolvedThumbUrl() {
        if(this.hasImage()) {
            return this.image.thumb.url;
        }

        return progPhotoPlaceholder;
    }

    dtLabel() {
        return moment(this.date).format('MMM Do YYYY');
    }

}

registerInflection('progressPhoto','progressPhotos',ProgressPhoto);