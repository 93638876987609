import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CardImageWithPlaceholder } from 'components/Card';
import Card from '../components/Card';
import { workoutPreviewPathFor, syncWorkoutsModalPathFor } from '../config/paths';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { WhiteBackgroundIcon } from 'components/Icon';
import { Workout } from 'lib/classes';
import classnames from 'classnames';

export const WorkoutNotSyncedIcon = ({ workout, basePath }) => {
    const history = useHistory();

    if(workout && workout.syncStatus === 'failed') {
        return (<div className="upper-right-card-cont clickable workout-not-synced-icon" onClick={() => history.push(syncWorkoutsModalPathFor(basePath))}><WhiteBackgroundIcon icon="exclamation-circle" className="orange-text" size="3x"/></div>)
    } else {
        return null;
    }
}

export const WorkoutTimeEstimate = ({ workout, t }) => ((workout && workout.isFullyLoaded()) ? (<div className="today-workout-subtitle"><FontAwesomeIcon icon={["far","clock"]} /> {workout.estTimeStr(t)}</div>) : '')

const WorkoutCard = ({ workout, user, basePath, getWorkoutPath, playIcon="play-circle", className }) => {

    const { t } = useTranslation();
    const history = useHistory();
    let imagePath = workout ? workout.imagePath() : Workout.restDayImage(user);

    return (
        <div className="position-relative">
            {basePath && (<WorkoutNotSyncedIcon workout={workout} basePath={basePath} />)}
            <Card className={classnames({ [className]: className, 'buttonize': workout })} id={workout ? `workout-card-${workout.id}` : 'rest-day-card'} onClick={() => (workout && history.push(getWorkoutPath ? getWorkoutPath(workout) : workoutPreviewPathFor(workout.date)))}>
                <CardImageWithPlaceholder src={imagePath} noContent width={500} height={334} color="primary" setDims>
                </CardImageWithPlaceholder>
                <div className="today-time">

                </div>
                <div className="today-workout">
                    <div className="today-workout-title-card">{workout ? _.abbrText(workout.displayName(t),30) : t("Rest Day")}</div>
                    {workout && <WorkoutTimeEstimate workout={workout} t={t} />}
                </div>
                {workout && (<div className="wt-play-btn">
                    {!workout.logged && (<FontAwesomeIcon icon={playIcon} className="play-workout-btn-tst" />)}
                    {workout.logged && (<FontAwesomeIcon icon="medal" className="medal-color" />)}
                </div>)}
            </Card>
        </div>
    )
}

export default WorkoutCard;