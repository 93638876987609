import React, { useState } from 'react';
import { connect } from 'react-redux';
import { workoutSetupMatch, pickExercisePath, updatePaymentModalPathFor, emailPaywallMatches, allMainMatches, switchAccountMatch, trainerConversionPathFor, updateTPaymentModalPathFor, pastDuePath, accountPath, trainerBrandingPathFor, trainerBrandingMatch, cancelTsubPath, viewInvoicesPath, brandedAppSetupPath } from 'config/paths';
import { paywallPathFor } from 'redux/helpers';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import { userRecordSelector } from 'redux/selectors';
import { updateSettings, loadSubscription, destroyUser, updateStripeCard, cancelSubscription, uncancelSubscription, loadApiKey, addBrandedApp, downloadTrainerProductivity } from 'redux/actions';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import { CardForm, DefaultSelect, FileUpload, FullInput, LoadingSubmitButton, MultivalueCheckbox, ToggleButton } from 'components/Form';
import TextInput, { TextArea } from 'components/TextInput';
import ActivityContainer from 'components/ActivityContainer';
import { Loader } from 'components/LoadingHOC';
import Card, { BasicCardHeader } from 'components/Card';
import Button, { ConfirmActionButton, LoaderActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';
import { SlightEmphasisIconNote } from 'components/Typography';
import moment from 'moment';
import { StripeModal } from './Conversion';
import LinkButton from 'components/LinkButton';
import SyncSubscription from 'partials/SyncSubscription';
import classnames from 'classnames';
import { User } from 'lib/classes';
import { LinkButtonTPB } from 'partials/ProRequiredButton';
import { Link } from 'components/Routing';
import { AsyncSpinnerButton } from 'components/Button';
import { useHistory } from 'react-router-dom';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,switchAccountMatch,trainerBrandingMatch,cancelTsubPath,viewInvoicesPath,brandedAppSetupPath], transitions.slideOver]
    ]
};

const UncancelButtons = ({ load }) => {
    const { t } = useTranslation()

    return (
        <Button color="primary" rounded outlined onClick={load} className="no-upcase">
            <FontAwesomeIcon icon={'undo'} size='1x'/><span>{t("Turn On Auto-renewal")}</span>
        </Button>
    )
}

const SubscriptionEditButtons = ({ isTrainerSub, load, basePath }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {!isTrainerSub && (<Button color={"red"} rounded outlined className="block-small no-upcase" onClick={load}>
                <FontAwesomeIcon icon={'times'} size='1x'/><span>{t("Cancel")}</span>
            </Button>)}
            {isTrainerSub && (<LinkButton color={"red"} rounded outlined className="block-small no-upcase" to={cancelTsubPath}>
                <FontAwesomeIcon icon={'times'} size='1x'/><span>{t("Cancel")}</span>
            </LinkButton>)}
            <LinkButton color="primary" rounded outlined className="block-small no-upcase with-margins ml5" to={isTrainerSub ? updateTPaymentModalPathFor(basePath) : updatePaymentModalPathFor(basePath)}>
                <FontAwesomeIcon icon={'credit-card'} size='1x'/><span>{t("Update")}</span>
            </LinkButton>
        </React.Fragment>
    )
}

const SubscriptionManagementBtns = ({ isTrainerSub, status, cancelAtPeriodEnd, cancelSubscription, uncancelSubscription, user, basePath }) => {
    const { t } = useTranslation();

    if(_.isBlank(status) || status === 'canceled') {
        return (
            <LinkButton color="primary" rounded outlined to={isTrainerSub ? trainerConversionPathFor('switch') : paywallPathFor('upgrade',user)}>
                <FontAwesomeIcon icon={'award'} size='1x'/><span>{t("Upgrade")}</span>
            </LinkButton>
        )
    } else if(cancelAtPeriodEnd) {
        return (
            <Loader
                key="uncancel"
                successComponent={UncancelButtons}
                defaultComponent={UncancelButtons}
                type="loaderOnly"
                load={uncancelSubscription}
                preloaded={() => false}
                skipAutoLoad
            />
        )
    } else {
        return (
            <Loader
                key="cancel"
                successComponent={SubscriptionEditButtons}
                defaultComponent={SubscriptionEditButtons}
                type="loaderOnly"
                isTrainerSub={isTrainerSub}
                load={cancelSubscription}
                preloaded={() => false}
                basePath={basePath}
                skipAutoLoad
            />
        )
    }
}

const SubscriptionStatus = ({ periodEnd, status, cancelAtPeriodEnd, contactToCancel, t }) => {
    const date = moment.unix(periodEnd);

    return (
        <React.Fragment>
            {(status === 'past_due' || status === 'unpaid' || status === 'incomplete') && <SlightEmphasisIconNote text={t("subscription lapse tip")} variant="warning" />}
            {(status === 'canceled' || cancelAtPeriodEnd) && <SlightEmphasisIconNote text={t("subscription canceled date",{ date: date.format("ddd MMM Do YYYY") })} variant="error" />}
            {!['past_due','canceled','unpaid','incomplete'].includes(status) && !cancelAtPeriodEnd && (
                <SlightEmphasisIconNote text={`${t("subscription renew date",{ date: date.format("ddd MMM Do YYYY") })} ${contactToCancel ? 'Please contact us if you want to cancel this subscription.' : ''}`} variant="success" />
            )}
        </React.Fragment>
    )
}

const SubscriptionInfoCard = ({ t, i18n, user, subscription, updateCard, cancelSubscription, uncancelSubscription, basePath }) => {
    const { stripeSubStatus: status, periodEnd, cancelAtPeriodEnd, source } = subscription;
    const type = subscription.actionType();

    if(source === 'app_store' || source === 'play_store') {
        const [name,icon] = source === 'app_store' ? ['App Store',['fab','app-store']] : ['Play Store',['fab','google-play']]
        return (
            <Card id={`${source}-info-card`}>
                <BasicCardHeader className="pt20">
                        <FontAwesomeIcon icon={icon} />
                        {name} {t('Subscription')}
                </BasicCardHeader>
                <div className="pl20 pr20 pb20">
                    {t("subscription manage tip",{ store_name: name })}.
                </div>
            </Card>
        )
    } else {
        return (
            <React.Fragment>
                <Card id={subscription.isTrainerSub() ? 'trainer-sub-card' : 'subscription-info-card'}>
                    <BasicCardHeader className="pt20">
                            {subscription.isTrainerSub() ? 'Strongr Fastr for Professionals' : 'Strongr Fastr Pro'}
                    </BasicCardHeader>
                    <div className="pa10">
                        <SubscriptionStatus periodEnd={periodEnd} status={status} cancelAtPeriodEnd={cancelAtPeriodEnd} t={t} />
                        {(user.isMaster() || !subscription.isTrainerSub()) && (<div className="mt20 text-center">
                            <SubscriptionManagementBtns 
                                basePath={basePath}
                                status={status} 
                                cancelAtPeriodEnd={cancelAtPeriodEnd} 
                                cancelSubscription={() => cancelSubscription(type)} 
                                uncancelSubscription={() => uncancelSubscription(type)}
                                user={user}
                                isTrainerSub={subscription.isTrainerSub()}
                            />
                        </div>)}
                        {subscription.isTrainerSub() && (
                            <div className="mt25 text-center">
                                <Link to={viewInvoicesPath}>{t('View Invoices')}</Link>
                            </div>
                        )}
                    </div>
                </Card>
                <StripeModal
                    t={t} 
                    i18n={i18n}
                    submitAction={data => updateCard(type,data)}
                    initProSuccess={data => {}}
                    path={subscription.isTrainerSub() ? updateTPaymentModalPathFor(basePath) : updatePaymentModalPathFor(basePath)}
                />
            </React.Fragment>
        )
    }
}

const AddBrandedAppCard = ({ brandedAppPrice, brandedAppSubInfo, addBrandedApp, t }) => {
    const price = brandedAppPrice === 'high' ? 3000 : 1500;
    const history = useHistory();
    const [info] = React.useState(brandedAppSubInfo)
    const [err,setErr] = React.useState(null);
    const hasInfo = info || brandedAppSubInfo;

    return (
        <Card id="branded-app-card">
            <BasicCardHeader className="pt20">
                <FontAwesomeIcon icon="mobile-alt" /> Branded iOS & Android App
            </BasicCardHeader>
            {!hasInfo && (<div className="pa10">
                <SlightEmphasisIconNote text={`Confirm your Branded App subscription below. Please note that you'll be charged the full $${price} USD for your first quarter (3 months) immediately. You'll be automatically billed $${price} every quarter thereafter until you cancel. If you want to cancel your order instead, please email us or simply ignore this confirmation card.`} variant="info" />
                <div className="mt20 text-center">
                    <AsyncSpinnerButton
                        buttonProps={{
                            noShadow: true,
                            color: 'primary',
                            label: `Upgrade for $${price}/quarter`,
                            className: 'no-capital'
                        }}
                        icon={'check'}
                        action={addBrandedApp}
                        successCallback={({ result: { success, error }}) => {
                            if(success) {
                                history.push(brandedAppSetupPath);
                            }
                            setErr(error);
                        }}
                    />
                    {err && (<div className="red-text"><FontAwesomeIcon icon="times" /> {err} Try updating your payment method above.</div>)}
                </div>
            </div>)}
            {hasInfo && (<div className="pa10">
                <SubscriptionStatus {...brandedAppSubInfo} contactToCancel t={t} />
                {!brandedAppSubInfo.appInitialized && (<div className="text-center">
                    <LinkButton className="mt10" color="primary" rounded outlined to={brandedAppSetupPath}>
                        <FontAwesomeIcon icon={'arrow-right'} size='1x'/><span>Set Up App</span>
                    </LinkButton>
                </div>)}
            </div>)}
        </Card>
    )
}

const SubscriptionInfo = ({ 
        setShowDelete, 
        updateCard,
        cancelSubscription,
        uncancelSubscription,
        addBrandedApp,
        user,
        showPersonal,
        basePath
     }) => {

    const { t, i18n } = useTranslation();
    const subs = user.subsForAccountPage(showPersonal);
    const brandedSub = _.find(subs, sub => (!_.isBlank(sub.brandedAppPrice) || !_.isBlank(sub.brandedAppSubInfo)));
    const brandedAppSubInfo = brandedSub && brandedSub.brandedAppSubInfo;
    const brandedAppPrice = brandedSub && brandedSub.brandedAppPrice;

    if(subs.length > 0) {
        return (
            <React.Fragment>
                <h4 className="conversion-heading ml0">{t(subs.length > 1 ? 'Subscriptions' : 'Subscription')}</h4>
                {subs.map(subscription => (
                    <SubscriptionInfoCard 
                        key={subscription.id}
                        basePath={basePath}
                        t={t}
                        i18n={i18n}
                        user={user}
                        subscription={subscription}
                        updateCard={updateCard}
                        cancelSubscription={cancelSubscription}
                        uncancelSubscription={uncancelSubscription}
                    />
                ))}
                {(brandedAppSubInfo || brandedAppPrice) && (
                    <AddBrandedAppCard brandedAppPrice={brandedAppPrice} brandedAppSubInfo={brandedAppSubInfo} addBrandedApp={addBrandedApp} t={t} />
                )}
            </React.Fragment>
        )
 
    } else {
        setTimeout(() => (setShowDelete && setShowDelete(true)),0);
        return '';
    }
}

const SubscriptionInfoLoader = ({ user, loadSubscription, setShowDelete, updateCard, cancelSubscription, uncancelSubscription, addBrandedApp, showPersonal, basePath }) => {

    return (
        <Loader
            successComponent={SubscriptionInfo}
            user={user}
            type="padded"
            load={loadSubscription}
            setShowDelete={setShowDelete}
            preloaded={() => false}
            updateCard={updateCard}
            showPersonal={showPersonal}
            basePath={basePath}
            cancelSubscription={cancelSubscription}
            uncancelSubscription={uncancelSubscription}
            addBrandedApp={addBrandedApp}
        />
    )
}

const ApiAccessInfo = ({ responseData: { apiKey } }) => {

    if(_.isBlank(apiKey)){
        return '';
    }

    return (
        <React.Fragment>
            <h4 className="conversion-heading ml0">API Access</h4>
            <Card>
                <BasicCardHeader className="pt20">Your API Key</BasicCardHeader>
                <div className="pl20 pr20 pb20 text-center">
                    {apiKey}
                </div>
            </Card>
        </React.Fragment>
    )
}

const ApiAccessLoader = ({ loadApiKey }) => {
    return (
        <Loader
            successComponent={ApiAccessInfo}
            type="blank"
            load={loadApiKey}
            preloaded={() => false}
        />
    )
}

const DestroyButton = ({ load, id }) => {
    const { t } = useTranslation();

    return (
        <ConfirmActionButton onClick={load} render={({ ready, onClick }) => (
            <Button id={id} color={ready ? "red" : "primary"} rounded outlined onClick={onClick}>
                <FontAwesomeIcon icon={['fal','trash']} size='1x'/><span>{t('Delete Account')}</span>
            </Button>
        )} />
    )
}

const AccountPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, user, submit, loadSubscription, deleteAccount, updateCard, cancelSubscription, uncancelSubscription, addBrandedApp, loadApiKey, downloadProductivity } = props;
    const [showDelete,setShowDelete] = useState(false);
    const [emailErr,setEmailErr] = useState(null);
    const initVals = user.initialValsForAccount();

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t("Account")}
        >
            <ActivityContainer>
                    {(user.isClientOrTrainer() || user.isContributor()) && (<div className="col s12">
                        <FormikWrapper
                                initialValues={ initVals }
                                validationSchema={ user.yupForNames() }
                                submit={submit}
                                valuesFilter={_.imageFormFilter()}
                                initialErrors={{}}
                                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {

                                    return (
                                        <CardForm className="pa20" onSubmit={handleSubmit}>
                                            <div className="mb15">
                                                <FileUpload 
                                                    name="image"
                                                    setFieldValue={rest.setFieldValue}
                                                    defaultImageUrl={_.isBlank(rest.values.image) ? rest.values.defaultImage : rest.values.image}
                                                />
                                            </div>
                                            <div className="valign-wrapper">
                                                <FullInput
                                                    className="flex-grow"
                                                    name="firstName"
                                                    label={t('First Name')}
                                                    component={TextInput}
                                                    formikProps={rest}
                                                />
                                                <FullInput
                                                    className="ml5 flex-grow"
                                                    name="lastName"
                                                    label={t('Last Name')}
                                                    component={TextInput}
                                                    formikProps={rest}
                                                />
                                                {user.isContributor() && (
                                                    <FullInput
                                                        className="ml5 flex-grow"
                                                        name="businessName"
                                                        label={'Credentials'}
                                                        component={TextInput}
                                                        formikProps={rest}
                                                    />
                                                )}
                                                {!(user.isTrainerAdmin() || user.isContributor()) && (<div className="ml15">
                                                    <LoadingSubmitButton noShadow icon="check" label={t("Save")} loadState={submitState} />
                                                </div>)}
                                            </div>
                                            {user.isContributor() && (
                                                <React.Fragment>
                                                    <div>
                                                        <div>Bio</div>
                                                        <div className="text-center">
                                                            <TextArea 
                                                                maxLength={2500} 
                                                                rows={10} 
                                                                style={{ width: '100%', height: '300px' }} 
                                                                name='bio' 
                                                                value={rest.values.bio} 
                                                                onChange={rest.handleChange} 
                                                                onBlur={rest.handleBlur} 
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="text-center">
                                                        <LoadingSubmitButton noShadow icon="check" label={t("Save")} loadState={submitState} />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                            {user.isTrainerAdmin() && (
                                                <React.Fragment>
                                                    {user.isMaster() && (
                                                        <React.Fragment>
                                                            <div className="valign-wrapper flex-start">
                                                                <FullInput
                                                                    className="ml5 flex-grow"
                                                                    name="businessName"
                                                                    label={t('Business Name')}
                                                                    component={TextInput}
                                                                    formikProps={rest}
                                                                />
                                                                <DefaultSelect
                                                                    name="trainerType" 
                                                                    className="ml5"
                                                                    label={t('Account Type')}
                                                                    numeric
                                                                    collection={[{ text: t('App/Web'), value: User.TRAINER_TYPES.app }, {text: t('PDF/email/paper'), value: User.TRAINER_TYPES.pdf}]} 
                                                                    {...rest} 
                                                                />
                                                            </div>
                                                            {`${initVals.trainerType}` !== `${rest.values.trainerType}` && (
                                                                <div className="pa10">
                                                                    <SlightEmphasisIconNote variant="warning" text={`${t("change account type warning")} ${initVals.trainerType === User.TRAINER_TYPES.pdf ? t("pdf to app warning") : t("app to pdf warning")}`} />
                                                                </div>
                                                            )}
                                                            {!_.isBlank(rest.values.autoDeactivateClients) && (<div className="pt10 pb20 text-center">
                                                                <MultivalueCheckbox 
                                                                    name="autoDeactivateClients"
                                                                    checkedVal={30}
                                                                    uncheckedVal={''}
                                                                    values={rest.values}
                                                                    setFieldValue={rest.setFieldValue}
                                                                    label={'Auto-deactivate clients after 30 days of inactivity'} 
                                                                    filled 
                                                                    className="no-wrap"
                                                                    tooltip={'This applies to clients of all trainers in your organization'}
                                                                />
                                                            </div>)}
                                                        </React.Fragment>
                                                    )}
                                                    {rest.values.unitPreference !== undefined && (
                                                        <div className="text-center mb30">
                                                            <div className="font-grey">{t("Unit Preference")}</div>
                                                            <ToggleButton 
                                                                id='toggle-units-btn'
                                                                className="btn-no-shadow no-capital" 
                                                                options={{[`${t('lbs')}/${t('ft')}`]: 0, [`${t('kgs')}/${t('cm')}`]: 1}} 
                                                                value={rest.values.unitPreference} 
                                                                setValue={value => rest.setFieldValue('unitPreference',value)} 
                                                            />
                                                        </div>)}
                                                    <div className="text-center">
                                                        <LoadingSubmitButton noShadow icon="check" label={t("Save")} loadState={submitState} />
                                                    </div>
                                                </React.Fragment>
                                            )}
                                        </CardForm>
                                    )
                                }}
                            />
                    </div>)}
                    {user.isMaster() && (
                        <BrandingSettings t={t} />
                    )}
                    <div className="col s12 l6">
                        <h4 className="conversion-heading ml0">{t('Email')}</h4>
                            <FormikWrapper
                                initialValues={ { email: user.email || ''} }
                                validationSchema={Yup.object().shape({ email: Yup.string().email().required()})}
                                submit={submit}
                                initialErrors={{}}
                                successCallback={({ status,formikBag,data }) => {
                                    if(data.error) {
                                        setEmailErr(data.error);
                                    } else if(emailErr) {
                                        setEmailErr(null);
                                    }
                                }}
                                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                        return (
                                            <CardForm className="pa20" onSubmit={handleSubmit}>
                                                <div className="valign-wrapper">
                                                    <FullInput
                                                        className="flex-grow"
                                                        name="email"
                                                        label={t('Email')}
                                                        component={TextInput}
                                                        formikProps={rest}
                                                    />
                                                    <div className="ml15">
                                                        <LoadingSubmitButton id="save-email-btn" noShadow icon="check" label={t("Save")} loadState={submitState} />
                                                    </div>
                                                </div>
                                                {emailErr && (<div className="red-text">{emailErr}</div>)}
                                            </CardForm>
                                        )
                                }}
                            />
                    </div>
                    <div className="col s12 l6">
                        <h4 className="conversion-heading ml0">{t('Password')}</h4>
                        <FormikWrapper
                            initialValues={ { password: '', passwordConfirmation: '' } }
                            validationSchema={Yup.object().shape({ 
                                password: Yup.string().min(5).max(128).required(), 
                                passwordConfirmation: Yup.string().required().oneOf([Yup.ref('password')], t("doesn't match"))})}
                            submit={submit}
                            initialErrors={{}}
                            render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                return (
                                    <CardForm className="pa20" onSubmit={handleSubmit}>
                                        <div className="valign-wrapper">
                                            <FullInput
                                                className=""
                                                name="password"
                                                label={t('New Password')}
                                                component={TextInput}
                                                inProps={{ type: 'password' }}
                                                formikProps={rest}
                                            />
                                            <FullInput
                                                className="ml5"
                                                name="passwordConfirmation"
                                                label={t('Confirm')}
                                                component={TextInput}
                                                inProps={{ type: 'password' }}
                                                formikProps={rest}
                                            />
                                            <div className="ml15">
                                                <LoadingSubmitButton id="save-pass-btn" noShadow icon="check" label={t("Save")} loadState={submitState} />
                                            </div>
                                        </div>
                                    </CardForm>
                                )
                            }}
                        />
                    </div>
                    {!showDelete && (<div className="col s12 l6 offset-l3">
                        <SubscriptionInfoLoader 
                            user={user} 
                            loadSubscription={loadSubscription} 
                            setShowDelete={setShowDelete}
                            showPersonal
                            basePath={accountPath}
                            updateCard={updateCard}
                            cancelSubscription={cancelSubscription}
                            uncancelSubscription={uncancelSubscription}
                            addBrandedApp={addBrandedApp}
                        />
                    </div>)}
                    {showDelete && !user.isClientOrTrainer() && (<div className={classnames("col s12 l6 offset-l3")}>
                        <h4 className="conversion-heading ml0">{t("Subscription not showing up?")}</h4>
                        <Card className="pa20 text-center">
                            <SyncSubscription setShowDelete={setShowDelete} />
                        </Card>
                    </div>)}
                    {user.isTrainerAdmin() && user.showVshredFeatures() && (
                        <DownloadProductivityCard downloadProductivity={downloadProductivity} />
                    )}
                    {user.isMaster() && (<div className="col s12 l6 offset-l3">
                        <ApiAccessLoader loadApiKey={loadApiKey} />
                    </div>)}
                    {showDelete && user.canManageOwnAccount() && (<div className="col s12 l6 offset-l3">
                        <h4 className="conversion-heading ml0">{t('Delete')}</h4>
                        <SlightEmphasisIconNote text={t("This cannot be undone")} variant="warning" />
                        <Card className="pa20 text-center">
                            <LoaderActionButton 
                                id="delete-user-btn"
                                component={DestroyButton}
                                action={deleteAccount}
                            />
                        </Card>
                    </div>)}
            </ActivityContainer>
 
        </SimpleNavPage>
    )
}

const DownloadProductivityCard = ({ downloadProductivity }) => {
    const { t } = useTranslation();

    return (
        <div className="col s12 l6 offset-l3">
            <h4 className="conversion-heading ml0">{t('Trainer Productivity Data')}</h4>
                <FormikWrapper
                    initialValues={ { lookback: 90 } }
                    submit={downloadProductivity}
                    initialErrors={{}}
                    render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                            return (
                                <CardForm className="pa20" onSubmit={handleSubmit}>
                                    <div className="valign-wrapper">
                                        <DefaultSelect
                                            name="lookback" 
                                            className="ml5"
                                            label={t('Time Period')}
                                            numeric
                                            collection={[{ text: t('Last X days', { days: '30' }), value: 30 }, { text: t('Last X days', { days: '90' }), value: 90 }, { text: t('Last X days', { days: '180' }), value: 180 }, { text: t('All time'), value: 9999 }]} 
                                            {...rest} 
                                        />
                                        <div className="ml15">
                                            <LoadingSubmitButton noShadow icon={['far','download']} label={t("Download")} loadState={submitState} />
                                        </div>
                                    </div>
                                </CardForm>
                            )
                    }}
                />
        </div>
    )
}

const BrandingSettings = ({ t }) => {

    return (
        <div className="col s12">
            <h4 className="conversion-heading ml0">{t('Branding')}</h4>
            <Card className="pa20 text-center">
                <LinkButtonTPB type={null} rounded color="primary" outlined to={trainerBrandingPathFor(1)} id="edit-branding-btn" allowUninit={false}>
                    <FontAwesomeIcon icon="pencil-alt" /> {t('Edit Your Branding')}
                </LinkButtonTPB>
            </Card>
        </div>
    )
}

let PastDuePage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, user, loadSubscription, updateCard, cancelSubscription, uncancelSubscription, addBrandedApp } = props;

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t("Account Past Due")}
        >
            <ActivityContainer>
                <div className="col s12 l6 offset-l3">
                    {!user.canManageOwnAccount() && (<SlightEmphasisIconNote variant="warning" text={t("past due non owner tip", { business_name: user.getMasterAccount().businessName, owner_name: user.getMasterAccount().fullName() })} />)}
                    {user.canManageOwnAccount() && (<SubscriptionInfoLoader 
                        user={user} 
                        loadSubscription={loadSubscription} 
                        updateCard={updateCard}
                        showPersonal={false}
                        basePath={pastDuePath}
                        cancelSubscription={cancelSubscription}
                        uncancelSubscription={uncancelSubscription}
                        addBrandedApp={addBrandedApp}
                    />)}
                </div>
            </ActivityContainer>
 
        </SimpleNavPage>
    )
}

const mapStateToProps = (state) => ({
    user: userRecordSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(updateSettings(data)),
    loadSubscription: () => dispatch(loadSubscription()),
    deleteAccount: () => dispatch(destroyUser()),
    updateCard: (type,data) => dispatch(updateStripeCard(type,data)),
    cancelSubscription: (type) => dispatch(cancelSubscription(type)),
    uncancelSubscription: (type) => dispatch(uncancelSubscription(type)),
    addBrandedApp: () => dispatch(addBrandedApp()),
    loadApiKey: () => dispatch(loadApiKey()),
    downloadProductivity: (data) => dispatch(downloadTrainerProductivity(data))
})

PastDuePage = connect(mapStateToProps,mapDispatchToProps)(PastDuePage)

export { PastDuePage }

export default connect(mapStateToProps,mapDispatchToProps)(AccountPage)