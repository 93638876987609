import React from 'react';
import classnames from 'classnames';
import { isFalsy } from 'lib/utilities';

export const OverallHeader = ({ isHeader, children }) => {

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export default ({ dividers, sectionWidth=4, centerLastRow, children }) => {
    let sections = 0;
    React.Children.forEach(children, child => { if(!isFalsy(child) && !child.props.isHeader) { sections += 1 } });
    const mWidth = Math.round(sectionWidth*1.5)
    const mChildPerRow = Math.floor(12/mWidth)
    const applyOffsetsToAll = mChildPerRow <= 1 ;

    const baseClasses = "col s12"
    let lastRowClasses = {}
    let lastOffsetClasses = {}
    let lastRowIndex = {}
    let headerClasses = {}

    for(let type of ['m','l']) {
        const width = type === 'l' ? sectionWidth : mWidth
        const childPerRow = Math.floor(12/width)
        const lastRowChildren = childPerRow === 1 ? 1 : sections % childPerRow
        let curWidth = null
        if(centerLastRow) {
            for(let i=12; i > 0; i--) {
                if(i % lastRowChildren === 0 && (12-i) % 2  === 0) {
                    let thisWidth = i / lastRowChildren
                    if (!curWidth || Math.abs(width - thisWidth) < Math.abs(width - curWidth)) {
                        curWidth = thisWidth
                    }
                }
            }
        }

        curWidth = curWidth || width;
        const lastOffset = Math.floor((12 - curWidth*lastRowChildren)/2)
        const regOffset = Math.floor((12 - curWidth*childPerRow)/2)
        lastRowClasses[type] = type + curWidth
        lastOffsetClasses[type] = centerLastRow ? "offset-" + type + lastOffset : "offset-" + type + regOffset;
        lastRowIndex[type] = sections - lastRowChildren
        if(type === 'm' && applyOffsetsToAll) {
            headerClasses[type] = `offset-${type}${lastOffset} ${type + (12 - lastOffset)}`
        } else {
            headerClasses[type] = `${type}12`
        }
    }
    
    let index = -1;
    return (
        <div className="row">
            { React.Children.map(children,(child) => {
                if(isFalsy(child)) {
                    return '';
                } else if(child.props.isHeader) {
                    let cnamesObj = {[baseClasses]: true};
                    for(let type of ['m','l']) {
                        cnamesObj[headerClasses[type]] = true;
                    }
                    return (
                        <div className={classnames(cnamesObj)}>
                            {child}
                        </div>
                    )
                } else {
                    index += 1;
                    let cnamesObj = {[baseClasses]: true}
                    for(let type of ['m','l']) {
                        let width = type === 'm' ? mWidth : sectionWidth
                        if(index >= lastRowIndex[type] || (type === 'm' && applyOffsetsToAll)) {
                            cnamesObj[lastRowClasses[type]] = true
                            if(index === lastRowIndex[type]) {
                                cnamesObj[lastOffsetClasses[type]] = true
                            }
                        } else {
                            const childPerRow = Math.floor(12/width)
                            cnamesObj[type + width] = true;
                            if(index%childPerRow === 0) {
                                const offset = Math.floor((12 - width*childPerRow)/2)
                                if(offset > 0) {
                                    cnamesObj["offset-" + type + offset] = true;
                                }
                            }
                        }
                    }
                    
                    let classNames = classnames(cnamesObj)
                    return (
                        <React.Fragment>
                            <div className={classNames}>
                                <div className="section">
                                    {child}
                                </div>
                                {dividers && index < (sections-1) && (<div className="divider hide-on-med-and-up"></div>)}
                            </div>
                            {index < lastRowIndex['m'] && (index+1)%mChildPerRow === 0 && (<div className={`${dividers ? 'divider' : ''} clearfix hide-on-small-only hide-on-large-only`}></div>)}
                            {index < lastRowIndex['l'] && (index+1)%(Math.floor(12/sectionWidth)) === 0 && (<div className={`${dividers ? 'divider' : ''} clearfix hide-on-med-and-down`}></div>)}
                        </React.Fragment>
                    )
                }
            })}
        </div>
    )
}

export const TwoColumns = ({ children }) => {
    let sections = 0;
    React.Children.forEach(children, child => { if(!isFalsy(child) && !child.props.isHeader) { sections += 1 } });
    const splitIndex = Math.ceil(sections/2);
    let leftSection = 0;
    let rightStart = 0;

    return (
        <div className="row">
            <div className="col s12 m10 offset-m1 l6">
                { React.Children.map(children,(child, index) => {
                    if(isFalsy(child) || leftSection >= splitIndex) {
                        return '';
                    } else {
                        leftSection += 1;
                        rightStart = index + 1;
                        return <div className="section">{child}</div>
                    }
                })}
            </div>
            <div className="col s12 m10 offset-m1 l6">
                { React.Children.map(children,(child, index) => {
                    if(isFalsy(child) || index < rightStart) {
                        return '';
                    } else {
                        return <div className="section">{child}</div>
                    }
                })}
            </div>
        </div>
    )
}