import React, { useState } from 'react';
import * as _ from 'lib/utilities';
import { StandaloneExerciseSearch } from 'partials/ExerciseSearchForm';
import { addExerciseTemplate, addWtChildGroup, destroyWorkoutTemplate, createExerciseGroup, destroyCustomExercise, createFormField } from 'redux/actions';
import { connect } from 'react-redux';
import { ExerciseGroupCard } from 'views/EditWorkoutTemplate';
import { ListCardAction, ListCard, ListCardTitle, AsyncListCardAction } from 'components/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { MuxUpoadMsg, SlightEmphasisTip } from 'partials/Utilities';
import requestStateHandler from 'components/RequestStateHandler';
import { WorkoutTemplate, Exercise } from 'lib/classes';
import { DefaultAsyncActionButton, BottomButtons, ConfirmActionButton, FixedButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { editExerciseGroupPathFor, editExerciseModalPathFor, importExerciseGroupsPathFor } from 'config/paths';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { EditExerciseModal } from 'partials/ExerciseModals';
import LinkButton from 'components/LinkButton';
import { ImportExerciseGroupsModal } from './EditRoutineModals';
import { ExerciseListSmallThumb } from './ExerciseListing';
import MediaQuery from 'components/MediaQuery';

const addExerciseTemplateActionComp = addRecord => ({ exercisable }) => {

    return (
        <AsyncListCardAction
            icon={'plus'}
            className="add-et-btn"
            action={() => addRecord(exercisable)}
        />
    )
}

let GroupCard = ({ destroyGroup, exerciseGroup, addGroup, workoutTemplate }) => {
    const destroy = () => {
        destroyGroup(new WorkoutTemplate({ ...exerciseGroup, destroyed: true }));
    }

    const primaryAction = (<AsyncListCardAction icon={'plus'} className="add-et-btn" action={addGroup.bind(null,{ ...workoutTemplate.actionParams(), childId: exerciseGroup.id })} />)

    if(exerciseGroup.destroyed) {
        return '';
    } else {
        return (
            <ExerciseGroupCard 
                exerciseGroup={exerciseGroup}
                destroy={destroy}
                primaryAction={primaryAction}
            />
        )
    }
}

GroupCard = requestStateHandler({ destroyGroup: 'exerciseGroup' })(GroupCard)

const GroupTab = ({ workoutTemplate, trainer, createGroup, addGroup, destroyGroup, standalone, basePath }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const groups = workoutTemplate.workoutRoutine.exerciseGroups();
    const showImport = !!trainer;
    const createButton = (
        <DefaultAsyncActionButton 
            loaderType='loaderOnly'
            rounded 
            color="primary" 
            className="no-upcase shadow" 
            outlined 
            id="create-group-btn"
            action={createGroup.bind(null,workoutTemplate.actionParams())}
            successCallback={(data) => history.push(editExerciseGroupPathFor(workoutTemplate.workoutRoutineId,Object.keys(data.workoutTemplates)[0]))}
        >
            <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("New Group")}
        </DefaultAsyncActionButton>
    )
    const importButton = showImport ? (
        <LinkButton
            rounded 
            color="primary" 
            className="no-upcase shadow mr5" 
            outlined 
            id={'import-groups-btn'}
            to={importExerciseGroupsPathFor(basePath)}
        >
            <FontAwesomeIcon icon={['far','file-import']}></FontAwesomeIcon> {t("Import")}
        </LinkButton>
    ) : null;

    return (
        <React.Fragment>
            <SlightEmphasisTip text={t('exercise groups tip')} tipName='exercise_groups_tip' />
            {groups.map(exerciseGroup => (
                <GroupCard 
                    key={exerciseGroup.id}
                    exerciseGroup={exerciseGroup}
                    destroyGroup={destroyGroup}
                    addGroup={addGroup}
                    workoutTemplate={workoutTemplate}
                />
            ))}
            {standalone && (<BottomButtons>
                {importButton}
                {createButton}
            </BottomButtons>)}
            {!standalone && (<div className="mt50 text-center">
                {importButton}
                {createButton}
            </div>)}
        </React.Fragment>
    )
}

let CustomExerciseCard = ({ exercise, addRecord, basePath, destroyCustomExercise, canBeAddedCheck }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const destroy = () => {
        destroyCustomExercise(new Exercise({...exercise, inactive: true }))
    }

    if(exercise.inactive) {
        return ''
    } else {

        return (
            <ListCard>
                <ExerciseListSmallThumb exercise={exercise} />
                <ListCardTitle className="pl10">
                    {exercise.fullName(t)}
                    <MuxUpoadMsg record={exercise} />
                </ListCardTitle>
                <ConfirmActionButton onClick={destroy} render={({ ready, onClick }) => (
                    <ListCardAction className="destroy-ex-btn" onClick={onClick}>
                        <FontAwesomeIcon color={ready ? 'red' : null} icon="times" />
                    </ListCardAction>
                )} />
                <ListCardAction id={`edit-ex-btn-${exercise.id}`} onClick={() => history.push(editExerciseModalPathFor(basePath,exercise.id))}>
                    <FontAwesomeIcon icon={["far","edit"]} />
                </ListCardAction>
                {addRecord && (!canBeAddedCheck || canBeAddedCheck(exercise)) && (
                    <AsyncListCardAction
                        icon={'plus'}
                        action={() => addRecord(exercise)}
                    />
                )}
            </ListCard>
        )
    }
}

CustomExerciseCard = requestStateHandler({ destroyCustomExercise: 'exercise' })(CustomExerciseCard)

const MyExercisesTab = ({ user, trainer, addRecord, basePath, standalone, destroyCustomExercise, canBeAddedCheck }) => {
    const [createdExerciseIds,setCreatedExerciseIds] = useState([]);
    const { t } = useTranslation(); 
    const history = useHistory();
    const exercises = _.signedInAsClient(trainer,user) ? trainer.orderedCustomExercises(createdExerciseIds) : user.orderedCustomExercises(createdExerciseIds);
    const newPath = editExerciseModalPathFor(basePath);

    const createButton = (
        <LinkButton
            rounded 
            color="primary" 
            className="no-upcase shadow" 
            outlined 
            id={'create-exercise-btn'}
            to={newPath}
        >
            <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("New Exercise")}
        </LinkButton>
    )
    
    return (
        <MediaQuery query={'(min-width: 993px)'} render={({ queryMatches: isLarge }) => (
            <React.Fragment>
                {standalone && isLarge && createButton}
                {exercises.map(exercise => (
                    <CustomExerciseCard 
                        key={exercise.id}
                        exercise={exercise} 
                        addRecord={addRecord}
                        basePath={basePath} 
                        destroyCustomExercise={destroyCustomExercise} 
                        canBeAddedCheck={canBeAddedCheck}
                    />
                ))}
                {standalone && !isLarge && (<FixedButton id="create-exercise-btn" icon="plus" onClick={() => history.push(newPath)} />)}
                {!standalone && (<div className="mt50 text-center">
                    {createButton}
                </div>)}
                <EditExerciseModal basePath={basePath} createSuccessCallback={({ data }) => { setCreatedExerciseIds([ Object.values(data.exercises)[0].id, ...createdExerciseIds ])}} />
            </React.Fragment>
          )} />

    )
}

const DatabaseTab = ({ addRecord, context, initialSearchValues, basePath }) => {
    return (
        <StandaloneExerciseSearch
            noContainers
            context={{ ...context }} 
            initialValues={initialSearchValues} 
            basePath={basePath}
            actionComp={addExerciseTemplateActionComp(addRecord)}
        />
    )
}

let AddExerciseToFormTabs = ({ trainer, form, activeTab, destroyCustomExercise, basePath, addRecord: addRecordBase, fieldType }) => {
    const tabIndex = _.isBlank(activeTab) ? 2 : activeTab;
    const tabs = [MyExercisesTab,DatabaseTab];
    const Tab = tabs[tabIndex-1] || (() => '');
    const addRecord = exercise => addRecordBase({ formId: form.id, fieldType, exerciseId: exercise.id });

    return (
        <Tab 
            user={trainer}
            trainer={trainer}
            context={{ id: 'add_strength_test'}} 
            initialSearchValues={form.exerciseSearchValues(fieldType)}
            addRecord={addRecord}
            basePath={basePath} 
            destroyCustomExercise={destroyCustomExercise}
            standalone={true}
            canBeAddedCheck={fieldType === 'strength_test' ? (ex => ex.hasRepMax()) : null}
        />
    )
}

const AddExerciseTabs = ({ user, trainer, workoutTemplate, activeTab, addExerciseTemplate, addGroup, destroyGroup, createGroup, destroyCustomExercise, basePath, standalone }) => {
    const tabIndex = _.isBlank(activeTab) ? 2 : activeTab;
    const tabs = workoutTemplate.isGroup() ? [MyExercisesTab,DatabaseTab] : [MyExercisesTab,DatabaseTab,GroupTab];
    const type = workoutTemplate.workoutRoutine.showUserExerciseGroups() ? 'mark' : 'import';
    const Tab = tabs[tabIndex-1] || (() => '');
    const addRecord = exercise => addExerciseTemplate({ ...workoutTemplate.actionParams(), ...exercise.exerciseTemplateParams() });

    return (
        <React.Fragment>
            <Tab 
                user={user}
                trainer={trainer}
                context={{ id: workoutTemplate.id, type: workoutTemplate.constructor.NAME }}
                workoutTemplate={workoutTemplate}
                initialSearchValues={workoutTemplate.exerciseSearchValues()}
                basePath={basePath} 
                addRecord={addRecord}
                createGroup={createGroup}
                addGroup={addGroup}
                destroyGroup={destroyGroup}
                destroyCustomExercise={destroyCustomExercise}
                standalone={standalone}
            />
            <ImportExerciseGroupsModal baseMatch={basePath} workoutTemplate={workoutTemplate} type={type} />
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    addExerciseTemplate: data => dispatch(addExerciseTemplate(data)),
    addGroup: data => dispatch(addWtChildGroup(data)),
    createGroup: data => dispatch(createExerciseGroup(data)),
    destroyGroup: workoutTemplate => dispatch(destroyWorkoutTemplate(workoutTemplate)),
    destroyCustomExercise: exercise => dispatch(destroyCustomExercise(exercise))
})

const mapDispatchToFormAddProps = dispatch => ({
    addRecord: data => dispatch(createFormField(data,'Added')),
    destroyCustomExercise: exercise => dispatch(destroyCustomExercise(exercise))
})

AddExerciseToFormTabs = connect(mapStateToProps,mapDispatchToFormAddProps)(AddExerciseToFormTabs);

export { AddExerciseToFormTabs }

export default connect(mapStateToProps,mapDispatchToProps)(AddExerciseTabs)