import React from 'react';
import withTrainer from 'partials/TrainerLoader';
import { withTranslation } from 'react-i18next';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import { dismissTooltip, sendChatMessage } from 'redux/actions';
import { connect } from 'react-redux';
import ChatList from 'partials/ChatList';
import NewChatModal, { ActivateChatModal, TurnOffChatModal, UpdateChatSettingsModal } from 'partials/NewChatModal';
import Chat, { ChatHeader } from 'partials/Chat';
import * as _ from 'lib/utilities';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { chatUserSelector } from 'redux/selectors';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { clientDesktopViewChatPath, desktopViewChatPath, viewChatPath } from 'config/paths';
import classnames from 'classnames';
import { MESSAGING_LIVE } from 'config/tooltips';

class MobileMessaging extends React.Component {

    render() {
        const { user, standalone }= this.props;

        return (
            <CenteredActivityContainer>
                <ChatList selectChat={this.selectChat} standalone={standalone} />
                <NewChatModal trainer={user} selectChat={this.selectChat} />
                <ActivateChatModal />
                <UpdateChatSettingsModal />
                <TurnOffChatModal />
            </CenteredActivityContainer>
        );
    }

    selectChat = (chat) => {
        const { history } = this.props;
        history.push(viewChatPath(chat.id));
    }

}

class DesktopMessaging extends React.Component {

    render() {
        const { user, standalone, selectedChatId }= this.props;
        const activeChat = this.getSelectedChat();

        return (
            <div>
                {activeChat && <ChatHeader chat={activeChat} user={user} standalone={standalone} />}
                <div className={classnames("chat-sidebar", { standalone })}>
                    <ChatList selectChat={this.selectChat} isSidebar selectedChat={_.isNumeric(selectedChatId) && Number(selectedChatId)} />
                </div>
                <div className="main-chat-area">
                    {!_.isBlank(selectedChatId) && user.hasActiveMessaging() && (<Chat chatId={selectedChatId} user={user} key={selectedChatId} desktop standalone={standalone} />)}
                </div>
                <NewChatModal trainer={user} selectChat={this.selectChat} />
                <ActivateChatModal />
                <UpdateChatSettingsModal />
                <TurnOffChatModal />
            </div>
        );
    }

    selectChat = (chat) => {
        const { history, user } = this.props;
        history.push(user.isClient() ? clientDesktopViewChatPath(chat.id) : desktopViewChatPath(chat.id));
    }

    getSelectedChat = () => {
        const { user, selectedChatId } = this.props;

        if(_.isBlank(selectedChatId)) {
            return null;
        }

        return user.chatById(selectedChatId);
    }
}

class ChatActivity extends React.Component {

    constructor(props) {
        super(props);
        const { user, dismissTooltip } = this.props;
        if(user.isTrainer() && !user.sawTooltip(MESSAGING_LIVE)) {
            dismissTooltip(MESSAGING_LIVE);
        }
    }

    render() {
        const { user, history, match: { params: { chatId } }, standalone }= this.props;

        return (
            <ResponsiveContainer 
                render={({ belowBreakpoint }) => {
                    if(belowBreakpoint) {
                        return <MobileMessaging user={user} history={history} standalone={standalone} />
                    }
        
                    return <DesktopMessaging user={user} selectedChatId={chatId} history={history} standalone={standalone} />
                }}
            />
        );
    }
}

const mapStateToProps = state => ({
    user: chatUserSelector(state)
})

const mapDispatchToActivityProps = dispatch => ({
    dismissTooltip: tipName => dispatch(dismissTooltip(tipName))
})

ChatActivity = connect(mapStateToProps, mapDispatchToActivityProps)(ChatActivity);

class TrainerMessaging extends React.Component {

    render() {
        return (
            <TrainerPaywall type={null} >
                <ChatActivity {...this.props} />
            </TrainerPaywall>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    sendMessage: data => dispatch(sendChatMessage(data))
})

export { ChatActivity }

export default withTrainer(false)(withTranslation()(connect(null,mapDispatchToProps)(TrainerMessaging)));
