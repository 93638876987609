import React from 'react';
import { connect } from 'react-redux';
import { jwtSelector } from 'redux/selectors';
import { chatDataReceived, getJwt, healthkitDataReceived } from 'redux/actions';
import * as _ from 'lib/utilities';

const AuthExt = (token) => ({
    outgoing: (msg,callback) => {
        msg = { ...msg, ext: { authToken: token }};
        callback(msg);
    }
})
class AppMessagingWrapper extends React.Component {

    constructor(props) {
        super(props);
        const { jwt, getJwt } = props;
        if(_.isBlank(jwt)) {
            getJwt();
        } else {
            this.subscribeCheck();
        }

        if(!window.Faye) {
            this.fayePoll = setInterval(this.pollFayeLoad,100);
        }
    }

    componentDidUpdate(prevProps) {
        this.subscribeCheck();
    }

    render() {

        return '';
    }

    componentWillUnmount() {
        this.clearSubscriptions();
    }

    pollFayeLoad = () => {
        this.subscribeCheck();
        if(this.subscribed) {
            clearInterval(this.fayePoll);
        }
    }

    subscribeCheck() {
        const { jwt } = this.props;
        if(jwt && window.Faye && !this.subscribed) {
            this.initSubscriptions();
        }
    }

    initSubscriptions() {
        const { user, jwt } = this.props;
        const { Faye } = window;
        this.subscribed = true;
        this.fayeClient = new Faye.Client(process.env.REACT_APP_CHAT_URL);
        this.fayeClient.addExtension(AuthExt(jwt));
        if(user.needsChatSubscription()) {
            this.chatMessageSub = this.fayeClient.subscribe(`/chat_messages/${user.id}`, this.chatDataHandler);
        }
        if(user.needsHealthkitSubscription()) {
            this.healthDataSub = this.fayeClient.subscribe(`/healthkit_data/${user.id}`, this.healthDataHandler);
        }
    }

    clearSubscriptions() {
        if(this.subscribed) {
            if(this.chatMessageSub) {
                this.chatMessageSub.cancel();
            }
            if(this.healthDataSub) {
                this.healthDataSub.cancel();
            }
            this.fayeClient.disconnect();
            this.subscribed = false;
        }
    }

    chatDataHandler = (data) => {
        if(this.subscribed) {
            const { chatDataReceived } = this.props;
            chatDataReceived(data);
        }
    }

    healthDataHandler = data => {
        if(this.subscribed) {
            const { healthDataReceived } = this.props;
            healthDataReceived(data);
        }
    }
}

const mapStateToProps = state => ({
    jwt: jwtSelector(state)
})

const mapDispatchToProps = dispatch => ({
    getJwt: () => dispatch(getJwt()),
    chatDataReceived: data => dispatch(chatDataReceived(data)),
    healthDataReceived: data => dispatch(healthkitDataReceived(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(AppMessagingWrapper)