import { RecordBase, registerInflection } from 'lib/record-base';

export class TrainerNote extends RecordBase {
    static NAME = 'TrainerNote'

    static ASSOCS = {
        client: { type: 'belongsTo', tableName: 'users', inverse: 'cTrainerNotes' },
        trainer: { type: 'belongsTo', tableName: 'users', inverse: 'tTrainerNotes' }
    }
}

registerInflection('trainerNote','trainerNotes',TrainerNote);