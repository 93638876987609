import React from 'react';
import { useTranslation } from 'react-i18next';
import { macroCals } from 'lib/classes';
import * as _ from 'lib/utilities';
import ChartWrapper from 'components/ChartWrapper'
import { macroColors } from 'config/settings';
import { MacroWarning } from './MealAndRecipeSearch';
import { InfoCard } from 'components/Card';

export const MacroPieChartCard = ({ warning, macroHash }) => {
    const { t } = useTranslation();

    return (
        <InfoCard>
            {warning && (<MacroWarning msg={warning} left color="red" />)}
            <div className="mt10 mb10 flex-grow">
                <MacroPieChart 
                    macros={macroHash} 
                    titleFontSize={12} 
                    hideLegend 
                    hideTitle 
                    maxWidth={120} 
                />
            </div>
            <div className="me-nut-container mr20">
                <div className="me-cals display-inline valign-wrapper">
                    {macroHash.calories} <div className="cals display-inline">{t('calories')}</div>
                </div>
                {Object.entries(_.omit(macroHash,'alcohol','calories')).map(([macro,value]) => {
                    const tKey = macro === 'carbs' ? 'net carbs' : macro;
                    return (
                        <div key={macro} className={`display-flex ${macro}-text`}>
                            <div className="me-macro-num">{value}{t('g')}</div>
                            <div className="me-macro">{t(tKey)}</div>
                        </div>
                    )
                })}
            </div>
        </InfoCard>
    )
}

const MacroPieChart = ({ macros, titleTemplate, titleFontSize=18, maxWidth=200, aspectRatio=1, hideTitle, hideLegend, percentFontSize=15 }) => {

    const { t } = useTranslation();
    let calories = macros.calories;
    let calVals = {};
    Object.entries(macros).forEach(([macro,grams]) => {
        if(macro !== 'calories') {
            calVals[macro] = Math.round(grams*macroCals[macro]);
        }
    });
    calories = calories || _.reduce(calVals,(sum,cals) => sum+cals,0)
    calVals = _.pickBy(calVals,(cals,macro) => (cals/calories >= 0.01))

    const data = { 
        datasets: [
            {
                data: Object.values(calVals),
                backgroundColor: Object.values(_.pick(macroColors,Object.keys(calVals))),
            }
        ], 
        labels: Object.keys(calVals).map(label => _.capitalize(t(label))),
        macros: Object.keys(calVals).map(label => _.capitalize(label))
    }

    const calStr = `${Math.round(calories)} ${t('calories')}`;
    const title = titleTemplate ? titleTemplate.replace('{calories}',calStr) : calStr;

    const options = { 
        legend: {
            display: !hideLegend,
            position: 'bottom',
            labels: {
                boxWidth: 10
            }
        },
        title: {
            display: !hideTitle,
            text: title,
            fontSize: titleFontSize
        },
        tooltips: {
            callbacks: {
                label: (tooltipObj, data) => {
                    const label = data.macros[tooltipObj.index] || '';
                    const value = data.datasets[tooltipObj.datasetIndex].data[tooltipObj.index];
                    return `${label}: ${Math.round(value/macroCals[label.toLowerCase()])}${t('g')} (${value}${t('cals')})`
                }
            }
        },
        animation: {
            duration: 0
        },
        plugins: {
            labels: {
                render: 'percentage',
                fontSize: percentFontSize,
                fontColor: '#fff',
                position: 'border'
            }
        },
        aspectRatio
    }

    const config = { data, options };

    return (
        <ChartWrapper chartType="pie" {...config} width={null} height={null} maxWidth={maxWidth} />
    )

}

export default MacroPieChart;