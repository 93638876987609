import React, {useState,Fragment} from 'react';
import InfiniteScroll from 'components/InfiniteScroll';
import { 
     modalPathFor } from 'config/paths';
import { FlowQuestion } from 'components/FlowForm';
import { ItemSelectCard } from 'components/Card';
import { MacroParams, MealType } from 'lib/classes';
import Button from 'components/Button';
import { arrayToggleCreator, TagButtons } from 'components/Form';
import { userRecordSelector, allergyTagsSelector, initialRecipeOptionsSelector, msCategoryRecordSelector } from 'redux/selectors';
import { resetInitialRecipeOptions, loadInitialRecipeOptions, getRecipeOptionsCatPage, setInitialRecipesFilter } from 'redux/actions';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import * as _ from 'lib/utilities';
import classnames from 'classnames';
import { WhiteBackgroundIcon } from 'components/Icon';
import { IconTip } from 'components/Tooltipped';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toast from 'components/Toast';
import { RedirectContext } from 'components/RouteTransitionMap';
import { paywallPathFor } from 'redux/helpers';
import { DailyNutritionProfile } from 'lib/daily-nutrition-profile';
import { SectionTab, SectionTabs } from 'components/BottomNav';
import { useHistory, useLocation } from 'react-router-dom';
import { SimpleContentModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { MacroWarning } from 'partials/MealAndRecipeSearch';
import { MealSearchCategory } from 'lib/meal-search';
import { getSubmitValues } from 'partials/OnboardingComponents';

const recipeClickHandlerCreator = (category,dnpId,recipeId,initialRecipeIds,setInitialRecipeIds,setShowLimitFlash) => () => {
    const curDnp = initialRecipeIds[dnpId] || {};
    const currentIds = curDnp[category] || [];
    let newIds;
    arrayToggleCreator(currentIds,newVals => (newIds = newVals))(recipeId);
    setInitialRecipeIds({ ...initialRecipeIds, [dnpId]: { ...curDnp, [category]: newIds } });
    setShowLimitFlash(false);
}

const getMaxRecs = recipeOptions => {
    if(_.isBlank(recipeOptions)) {
        return 3;
    }

    const dnpVals = Object.values(recipeOptions);
    let maxRecs;
    if(dnpVals.length <= 1) {
        maxRecs = 3;
    } else if(dnpVals.length === 2) {
        maxRecs = 2;
    } else {
        maxRecs = 1;
    }

    return maxRecs;
}

let AddlFilters = ({ categories, allergyTags, user, mealTypeCategory, curFilter, setCurFilter, t }) => {
    const filteredCats = user.filteredMealSearchCats(categories,allergyTags,mealTypeCategory);
    const tags = MealSearchCategory.tagsCol(filteredCats, t, false);

    return (
        <div className="responsive-scrolling-wrapper">
            <TagButtons 
                col={[ { text: <Fragment><FontAwesomeIcon icon="heart" />{t('Favorites')}</Fragment>, value: 'favorites' }, { text: <Fragment><FontAwesomeIcon icon={['far','history']} />{t('Recent')}</Fragment>, value: 'recent' }, ...tags ]}
                name="mealSearchCategoryId"
                setFieldTouched={() => {}}
                setFieldValue={(name,value) => setCurFilter(value)}
                values={{ mealSearchCategoryId: curFilter }}
                buttonProps={ { className: 'ma2 btn-no-shadow' } }
                btnId={val => `filter-${mealTypeCategory}-${val}-btn`}
                single
                allowBlank
            />
        </div>
    )
}

const mapStateToFilterProps = (state) => ({
    categories: msCategoryRecordSelector(state),
    allergyTags: allergyTagsSelector(state),
    user: userRecordSelector(state)
})

AddlFilters = connect(mapStateToFilterProps)(AddlFilters)

const PossibleRecipesCat = ({ getRecipeOptionsCatPage, category, vals, initialRecipeIds, setInitialRecipeIds, setInitialRecipesFilter, dnpId, setShowLimitFlash, maxRecs, t }) => {
    const [showAddlFilters,setShowAddlFilters] = useState(!_.isBlank(vals.mealSearchCategoryId));
    const finalSetCurFilter = val => {
        setInitialRecipesFilter({ dnpId, category, filter: val });
        getRecipeOptionsCatPage(_.parseObjForForm({ category, dnpId, initialExclusions: [], seed: vals.seed, mealSearchCategoryId: val, pageExcluded: [] }),1);
    }

    const hideAddlFilters = () => {
        setShowAddlFilters(false);
        finalSetCurFilter(null);
    }

    const selIds = initialRecipeIds[dnpId] && initialRecipeIds[dnpId][category] && _.compact(initialRecipeIds[dnpId][category]);


    return (
        <div key={category} className="text-left" >
            <div className="signup-subheading-container ml10">
                <h4 className="thin-font-weight mb0">
                    <div className="valign-wrapper">
                        <div>{t(_.capitalize(category))}</div>
                        {selIds && selIds.length > 0 && (
                            <div className="inline-notificon ml10 primary bigger">
                                {selIds.length}
                            </div>
                        )}
                        <Button id={`filter-${category}-btn`} noShadow outlined color={showAddlFilters ? "grey" : "primary"} variant={"icon-only"} className={classnames("ml10", { 'no-border': showAddlFilters })} onClick={showAddlFilters ? hideAddlFilters : (() => setShowAddlFilters(true))}>
                            <FontAwesomeIcon icon={showAddlFilters ? 'times' : ['far','filter']}></FontAwesomeIcon>
                        </Button>
                    </div>
                </h4>
                {showAddlFilters && (<AddlFilters mealTypeCategory={category} curFilter={vals.mealSearchCategoryId} setCurFilter={finalSetCurFilter} t={t} />)}
            </div>
            <InfiniteScroll 
                key={vals.mealSearchCategoryId || 'default'}
                load={getRecipeOptionsCatPage.bind(null,_.parseObjForForm({ category, dnpId, initialExclusions: vals.initialExclusions, pageExcluded: vals.pageExcluded, seed: vals.seed, mealSearchCategoryId: vals.mealSearchCategoryId }))} 
                initialPage={1}
                loadedPage={Math.ceil(vals.recipes.length/10)} 
                fullyLoaded={vals.finished} 
                className={classnames("fatter-hz-scroll",{"horizontal-scrolling-wrapper": vals.recipes.length > 0 })}
                id={`${category}-recipe-options`}
                horizontal
                render={({ maxIndex }) => {
                    if(vals.recipes.length === 0) {
                        return(
                            <div className="text-center mt20 ml10 mr10">
                                <SlightEmphasisIconNote text={t("no rec options for filter")} />
                            </div>
                        )
                    }

                    return (
                        vals.recipes.map((recipe,index) => {
                            const irids = initialRecipeIds[dnpId];
                            const isActive = irids && irids[category] && irids[category].includes(recipe.id);
                            const numIrids = (!irids || !irids[category]) ? 0 : _.compact(irids[category]).length;
                            const allowAction = isActive || numIrids < maxRecs;
                            const meetsMacros = vals.meetsMacros[index];

                            if(index < maxIndex) {
                                return (
                                    <ItemSelectCard 
                                        key={recipe.id}
                                        onClick={allowAction ? recipeClickHandlerCreator(category,dnpId,recipe.id,initialRecipeIds,setInitialRecipeIds,setShowLimitFlash) : () => setShowLimitFlash(true)} 
                                        active={isActive}
                                    >
                                        {isActive && <WhiteBackgroundIcon containerClassName="upper-right-icon primary-text" icon="check-circle" size="2x" />}
                                        <img src={recipe.imageUrl('thumb')} className="signup-recipe-card-img" alt="" />
                                        <div className="signup-recipe-card-title">{recipe.name}</div>
                                        {!meetsMacros && (<MacroWarning msg={t("initial rec macro warn")} />)}
                                    </ItemSelectCard>
                                )
                            } else {
                                return '';
                            }

                        })
                    )
                }}
            />
        </div>
    )

}

const PossibleRecipes = ({ recipeOptions, t, getRecipeOptionsCatPage, setInitialRecipesFilter, user, forNewWeek, continueBtnRender }) => {
    const [initialRecipeIds,setInitialRecipeIds] = useState({});
    const [showLimitFlash,setShowLimitFlash] = useState(false);
    const history = useHistory();


    if(!recipeOptions) {
        return '';
    }

    const useProFlash = !user.allowInitialRecipePick();
    const dnpVals = Object.values(recipeOptions);
    const maxRecs = useProFlash ? 0 : getMaxRecs(recipeOptions);

    const limitFlash = (<Toast 
        message={(<span className="lh1"><FontAwesomeIcon icon="info-circle" className="info-color" /> {maxRecs > 1 ? t("Please pick X or fewer recipes per meal type", { count: maxRecs }) : t('pick 1 recipe or less')}</span>)} 
        collapseContent={t("recipe pick limit tip")} 
        completeCallback={() => setShowLimitFlash(false)} 
        moreText={t('More')}
        dismissText={t('Ok')}
        displayLength={10000}
    />);

    const proFlash = (<Toast 
        message={(<span className="lh1"><FontAwesomeIcon icon="info-circle" className="info-color" /> {t('Upgrade to pro to select specific recipes')}</span>)} 
        completeCallback={() => setShowLimitFlash(false)} 
        dismissText={t('Upgrade')}
        okCallback={() => history.push(paywallPathFor('upgrade',user))}
        displayLength={10000}
    />)

    return (
        <div className="pb100 possible-recs-cont">
            {showLimitFlash && !useProFlash && limitFlash}
            {showLimitFlash && useProFlash && proFlash}
            <SectionTabs activeTab={0} hideIfOne white noBorder render={({ activeTab }) => {
                const { id: dnpId, name, ...options } = dnpVals[activeTab];
                return (
                    <React.Fragment>
                        {MealType.sortedCats(options).map(([category,vals]) => {
                            
                            return (
                                <PossibleRecipesCat
                                    getRecipeOptionsCatPage={getRecipeOptionsCatPage}
                                    key={`${dnpId}-${category}`}
                                    category={category}
                                    vals={vals}
                                    initialRecipeIds={initialRecipeIds}
                                    setInitialRecipeIds={setInitialRecipeIds}
                                    setInitialRecipesFilter={setInitialRecipesFilter}
                                    dnpId={dnpId}
                                    setShowLimitFlash={setShowLimitFlash}
                                    maxRecs={maxRecs}
                                    t={t}
                                />
                            )
                        })}
                    </React.Fragment>
                )
            }}>
                {dnpVals.map((dnp,index) => (<SectionTab key={dnp.id}>{dnp.name}</SectionTab>))}
            </SectionTabs>
            {continueBtnRender({ user, t, initialRecipeIds, forNewWeek })}
        </div>
    )
}

const PossibleRecipesLC = loadingContainer({
    'SUCCESS': PossibleRecipes
},{ type: 'padded' })

export const RecipePickWrapper = ({ t, children, recipeOptions }) => {
    const maxRecs = getMaxRecs(recipeOptions);

    return (
        <div className="text-center limit-50-h">
            <div className="ml5 mr5">
                <FlowQuestion className="mb0" text={maxRecs > 1 ? t("Pick 1-2 recipes for each meal") : t("pick recs to start")} />
                <div className="flow-question-subtitle">
                    {t("Your meal plan will be built around your choices")}
                    <IconTip icon='question-circle' msg={t("recipe pick tooltip")} size='1x' />
                </div>
            </div>
            
            {children}
        </div>
    )
}

const initRecPickHandler = (setValues,values) => ({ dailyNutritionProfiles, nutritionParameters }) => 
{
    const mnp = DailyNutritionProfile.pickForForm(_.find(Object.values(dailyNutritionProfiles),dnp => dnp.main));
    mnp.nutritionParameters = MacroParams.unfilteredBuildForForm(_.selectWithFKs(nutritionParameters,'dailyNutritionProfileId',[mnp.id]));
    setValues({ ...values, dailyNutritionProfiles: [{ ...mnp }] });
}

export const mealPlanQuotaModalSuffix = '/quota_hit';

export const MealPlanQuotaHitModal = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(mealPlanQuotaModalSuffix,pathname);

    return (
        <SimpleContentModal 
            path={path}
            title={t("mp rate limit")}
            btnLabel={t("Ok")}
            render={() => {

                return (
                    <div className="pa20 text-center">
                        <SlightEmphasisIconNote text={t("meal plan quota hit")} />
                    </div>
                )
            }}
        />
    )
}

const InitialRecipePicker = ({ flowProps, loadInitialRecipeOptions, recipeOptions, getRecipeOptionsCatPage, setInitialRecipesFilter, user, forNewWeek, continueBtnRender }) => {
    const { t } = useTranslation();
    
    const { setValues, values } = flowProps;
    const submitValues = getSubmitValues(user,values);

    return (
        <RecipePickWrapper t={t} recipeOptions={recipeOptions}>
            <RedirectContext.Consumer>
                {disableRedirects => {
                    if(disableRedirects) {
                        return '';
                    }

                    return (
                        <LoadingContainerParent 
                            load={loadInitialRecipeOptions.bind(null,forNewWeek ? null : submitValues, initRecPickHandler(setValues,values))}
                            preloaded={() => false}
                            component={PossibleRecipesLC}
                            recipeOptions={recipeOptions}
                            getRecipeOptionsCatPage={getRecipeOptionsCatPage}
                            setInitialRecipesFilter={setInitialRecipesFilter}
                            flowProps={flowProps}
                            user={user}
                            t={t}
                            forNewWeek={forNewWeek}
                            continueBtnRender={continueBtnRender}
                        />
                    )
                }}
            </RedirectContext.Consumer>
            <MealPlanQuotaHitModal />
        </RecipePickWrapper>
    )
}

const mapStateToProps = (state) => ({
    recipeOptions: initialRecipeOptionsSelector(state)
})

const mapDispatchToProps = dispatch =>({
    resetInitialRecipeOptions: data => dispatch(resetInitialRecipeOptions(data)),
    loadInitialRecipeOptions: (data,success) => dispatch(loadInitialRecipeOptions(data,success)),
    getRecipeOptionsCatPage: (params,page) => dispatch(getRecipeOptionsCatPage({ page, ...params })),
    setInitialRecipesFilter: params => dispatch(setInitialRecipesFilter(params))
})

export default connect(mapStateToProps,mapDispatchToProps)(InitialRecipePicker)