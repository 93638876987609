import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const WhiteBackgroundIcon = ({ containerClassName, ...rest }) => {
    const classNames = classnames("white-icon-bcg", {[containerClassName]: containerClassName});

    return (
        <div className={classNames}>
            <FontAwesomeIcon {...rest} />
        </div>
    )
}