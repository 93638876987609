import React, {} from 'react';
import TextInput, { NumberInput, PhoneInput } from 'components/TextInput';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import Slider from "rc-slider";
import { Checkbox, CheckboxGroup, DefaultSelect, FullInput, FullTextArea, PlaceholderFileUpload, SingleSelectCheckboxGroup } from './Form';
import  { ReactComponent as Mood1 } from 'assets/img/mood1.svg';
import  { ReactComponent as Mood2 } from 'assets/img/mood2.svg';
import  { ReactComponent as Mood3 } from 'assets/img/mood3.svg';
import  { ReactComponent as Mood4 } from 'assets/img/mood4.svg';
import  { ReactComponent as Mood5 } from 'assets/img/mood5.svg';
import SignaturePad from './SignaturePad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexIconTip } from './Tooltipped';

export const FieldError = ({ name, formikProps, alwaysTouched }) => {
    const err = _.get(formikProps.errors,name);
    const touched = alwaysTouched || _.get(formikProps.touched,name);

    if(_.isBlank(err) || _.isBlank(touched)) {
        return '';
    }

    return (
        <div className="red-text"><FontAwesomeIcon icon="times" /> {err}</div>
    )
}

export const LongTextField = ({ name, label, optional, multiple, t, formikProps, labelClassName, readOnly, viewType, ...rest }) => {

    return (
        <FullTextArea
            name={name} 
            label={label}
            labelClassName={_.isBlank(labelClassName) ? 'noop' : labelClassName}
            formikProps={formikProps}
            stopModalDrag
            disabled={readOnly}
            placeholder={`${optional ? t('Optional') : ''}`}
            {...rest}
        />
    )
}

const FullInputField = ({ name, label, formikProps, readOnly, comp, labelClassName, optional, multiple, t, viewType, ...rest }) => {

    return (
        <div className="text-left">
            <div className={labelClassName}>{label}</div>
                <FullInput
                    className="mt0"
                    name={name}
                    label={null}
                    onlyErrorText
                    component={comp}
                    inProps={{ disabled: readOnly }}
                    formikProps={formikProps}
                    {...rest}
                />
        </div>
    )
}

export const ShortTextField = (props) => {

    return (
        <FullInputField {...props} comp={TextInput} />
    )
}

export const SelectField = ({ name, label, options, formikProps, multiple, labelClassName, readOnly, isEditable }) => {

    return (
        <div className="valign-wrapper mb10">
            <DefaultSelect
                className={"flex-grow"}
                labelHtml={<div className={labelClassName}>{label}</div>}
                name={name}
                collection={_.collectorize(multiple ? options : ['', ...options ])}
                selectProps={{ skipReconcile: true, multiple, className: (multiple ? 'auto-height' : ''), disabled: (readOnly && !isEditable) }}
                {...formikProps}
            />
        </div>
    )
}

export const NumberField = (props) => {
    return (
        <FullInputField {...props} comp={NumberInput} />
    )
}

export const PhoneField = (props) => {
    return (
        <FullInputField {...props} comp={PhoneInput} />
    )
}

export const NumericSlider = ({ name, label, min, max, formikProps, readOnly, labelClassName }) => {
    let curVal = _.get(formikProps.values,name);
    curVal = _.isBlank(curVal) ? min : curVal;

    return (
        <div className="text-left">
            <div className={classnames(labelClassName,{ mb10: true })}>{label}</div>
            <div className="position-relative">
                <Slider
                    defaultValue={curVal}
                    marks={{ [min]: { label: min }, [max]: { label: max}}}
                    min={min || 0}
                    max={max}
                    disabled={readOnly}
                    onChange={(value) => formikProps.setFieldValue(name,value)}
                />
                {curVal !== min && curVal !== max && (<div className="font-grey" style={{ position: 'absolute', left: `${((curVal - min)/(max-min))*100}%`, transform: 'translateX(-50%)' }}>
                    {curVal}
                </div>)}
            </div>
            <FieldError name={name} formikProps={formikProps} />
        </div>
    )
}

export const CheckboxField = ({ name, label, options, multiple, formikProps, readOnly, labelClassName }) => {
    const Comp = multiple ? CheckboxGroup : SingleSelectCheckboxGroup;

    return (
        <div>
            <div className={classnames(labelClassName,{ mb10: true })}>{label}</div>
            <Comp 
                values={formikProps.values} 
                errors={formikProps.errors} 
                touched={formikProps.touched} 
                onChange={formikProps.setFieldValue} 
                onBlur={formikProps.setFieldTouched} 
                name={name} 
            >
                {options.map((opt) => (
                    <Checkbox key={opt} inputProps={{ value: opt, disabled: readOnly} } label={opt} filled id={`${name.replaceAll('.','')}-${opt.replaceAll(/\s/g,'')}-check`} />
                ))}
            </Comp>
        </div>
    )
}

export const MoodField = ({ name, label, labels, formikProps, readOnly, labelClassName, t }) => {
    const opts = [Mood1,Mood2,Mood3,Mood4,Mood5];
    const value = _.get(formikProps.values,name);

    return (
        <div>
            <div className={classnames(labelClassName,{ mb10: true })}>{label}</div>
            <div className="mood-field-cont">
                <div className={classnames("mood-field", { 'read-only': readOnly })} id={`${name.replaceAll('.','')}-field`}>
                    {opts.map((Comp,i) => (
                        <Comp 
                            key={i} 
                            className={classnames(`mood-${i+1}`,{ sel: value === i })} 
                            onClick={readOnly ? null : (() => formikProps.setFieldValue(name,i))} 
                        />
                    ))}
                </div>
                <div className="mood-labels">
                    <div className="left-label">{labels[0]}</div>
                    <div className="right-label">{labels[1]}</div>
                </div>
            </div>
            <FieldError name={name} formikProps={formikProps} />
        </div>
    )
}

export const SignatureField = ({ name, label, formikProps, readOnly, signatureDate, signerName, labelClassName }) => {
    const initialSignature = _.get(formikProps.values,`${name}defaultImage`);
    const uploadName = `${name}uploadImage`;

    return (
        <div>
            <div className={labelClassName}>{label}</div>
            <SignaturePad 
                name={signerName}
                readOnly={readOnly}
                initialSignature={initialSignature}
                onEnd={image => formikProps.setFieldValue(uploadName,image)}
            />
            <div>{signerName} - {signatureDate}</div>
            <FieldError name={`${name}uploadImage`} formikProps={formikProps} />
        </div>
    )
}

export const ProgressPhotoField = ({ name, label, labelClassName, formikProps, readOnly, t, fullViewPath, formField }) => {
    const defaultImage = _.get(formikProps.values,`${name}defaultImage`);

    return (
        <div>
            <div className={labelClassName}>{label}</div>
            <PlaceholderFileUpload
                name={`${name}uploadImage`}
                setFieldValue={formikProps.setFieldValue}
                defaultImageUrl={defaultImage}
                fullViewPath={fullViewPath}
                idOverride={`file-upload-${formField.id}`}
                outlined
                disabled={readOnly}
                btnLabel={<div className="btn-content"><FontAwesomeIcon icon={['far','camera']} /> <span>{t("Take/upload Photo")}</span></div>}
                className="block-btn"
                imgClass="mb5"
                containerClass="vid-width-cont lh0 text-center"
            />
            <FieldError name={`${name}uploadImage`} formikProps={formikProps} alwaysTouched />
        </div>
    )
}

export const BodyMeasurementField = ({ t, name, label, labelClassName, formikProps, readOnly, modifier: bodypart, isMetric }) => {

    return (
        <div>
            <div className={labelClassName}>{label}</div>
            <div className="valign-wrapper flex-end">
                <FullInput
                    name={name}
                    label={`${t(bodypart)} (${isMetric ? t('cm') : t('in')})`}
                    component={NumberInput}
                    className="compact"
                    formikProps={formikProps}
                    inProps={{ disabled: readOnly }}
                />
                <FlexIconTip icon="question-circle" msg={t(`${bodypart} measure tip`)} size="1x" />
            </div>
        </div>
    )
}