import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { TransitionGroup, Transition } from 'react-transition-group';
import { SubmitButton, ModalSubmitLC, ModalSubmitNoClose } from 'components/Form';
import MModal from 'assets/js/modal';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { viewportHeightSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import { isExternal } from './Routing';
import Button from './Button';
import { Loader } from './LoadingHOC';
import FormikWrapper from './FormikWrapper';
import Tabs from './Tabs';

const ActionButtons = ({ icon, label, cancelLabel, defaultComponent, load, close, disabled, successCallback, skipAutoLoad, responseData, firstTry,  wrapperLoadState, ...rest }) => {

    return(
        <React.Fragment>
            <Button rounded outlined color="primary" className="mr5" onClick={close} >
                <FontAwesomeIcon icon="ban" />
                {cancelLabel}
            </Button> 
            <Button color="primary" rounded disabled={disabled} {...rest} onClick={() => (!disabled && load())}>
                <FontAwesomeIcon icon={icon} size='1x' /><span>{label}</span>
            </Button>
        </React.Fragment>
    )
}

export const ModalActionButtons = ({ action, close, ...rest }) => {
    return (
        <Loader
            successComponent={() => ''}
            defaultComponent={ActionButtons}
            type="loaderOnly"
            load={action}
            preloaded={() => false}
            skipAutoLoad
            successCallback={() => close()}
            close={close}
            {...rest}
        />
    )
}

export const ModalHeader = React.forwardRef(({ scrollable, className, children, defaults, defaultFont, exitButton, exitButtonClick, noShadow, ...rest }, ref) => {
    const classNames = classnames({
        [className]: className,
        "default-modal-header-font": defaultFont,
        "default-modal-header": defaults,
        "scroll-modal-header": scrollable,
        "no-shadow": noShadow
    })
    return (
        <div className={classNames} ref={ref} {...rest}>
            {children}
            {exitButton && (<div className="modal-close low-opacity-icon modal-close-btn" onClick={exitButtonClick}><FontAwesomeIcon icon="times" /></div>)}
        </div>
    )
})

export const ModalFooter = React.forwardRef(({ scrollable, className, children, defaults, noShadow }, ref) => {
    const classNames = classnames({
        [className]: className,
        "default-modal-footer": defaults,
        "scroll-modal-footer": scrollable,
        "no-shadow": noShadow
    })
    return (
        <div className={classNames} ref={ref}>
            {children}
        </div>
    )
})

export const SubmitButtonFooter = (props) => {
    return (
        <div className="text-center pa10">
            {props.children || (<SubmitButton {...props} />)}
        </div>
    )
}

class ScrollableContentCore extends React.Component {

    constructor(props) {
        super(props);
        this.state = { nonScrollDim: 0 };
        this.scrollRef = React.createRef();
        this.stopModalDrag = this.stopModalDrag.bind(this);
    }

    componentDidMount() {
        setTimeout(() => {
            const newDim = this.props.calcNonScrollDim();
            if(newDim !== this.state.nonScrollDim) {
                this.setState({ nonScrollDim: newDim });
            }
        },0)

        const scrollRef = this.getScrollRef();
        scrollRef.current.addEventListener('touchstart',this.stopModalDrag);
        scrollRef.current.addEventListener('mousedown',this.stopModalDrag);
    }

    componentDidUpdate() {
        const newDim = this.props.calcNonScrollDim();
        const oldDim = this.state.nonScrollDim;
        if(newDim !== oldDim) {
            if(_.isBlank(oldDim) || Math.abs(newDim - oldDim) > 2)  {
                this.setState({ nonScrollDim: newDim });
            }
        }
    }

    render() {
        const { calcNonScrollDim, children, onScroll, viewportHeight, dispatch, scrollRef, ...rest } = this.props;
        const { nonScrollDim } = this.state;
        let notchHeight = getComputedStyle(document.documentElement).getPropertyValue("--sab");
        if(_.isBlank(notchHeight)) {
            notchHeight = 0;
        } else {
            notchHeight = Number((notchHeight.match(/\d+/) || [])[0]);
        }
        const maxScrollHeight = Math.max(viewportHeight - notchHeight - nonScrollDim,150);

        return (
            <div ref={this.getScrollRef()} onScroll={onScroll} style={{overflowY: 'auto', maxHeight: `${maxScrollHeight}px`}} {...rest}>
                {children}
            </div>
        )
    }

    getScrollRef() {
        const { scrollRef } = this.props;
        return scrollRef || this.scrollRef;
    }

    stopModalDrag(e) {
        const scrollRef = this.getScrollRef();
        if(scrollRef.current.scrollHeight > scrollRef.current.clientHeight) {
            e.stopPropagation();
        }
    }

    componentWillUnmount() {
        const scrollRef = this.getScrollRef();
        scrollRef.current.removeEventListener('touchstart',this.stopModalDrag);
        scrollRef.current.removeEventListener('mousedown',this.stopModalDrag);
    }
}

const mapStateToProps = state => ({
    viewportHeight: viewportHeightSelector(state)
})

ScrollableContentCore = connect(mapStateToProps)(ScrollableContentCore);

export const ScrollableModalContent = React.forwardRef((props,ref) => {
    return (
        <ScrollableContentCore {...props} scrollRef={ref} />
    )
})

export class BasicModal extends React.PureComponent {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.onClick = this.onClick.bind(this);
        this.onOpenStart = this.onOpenStart.bind(this);
        this.onCloseEnd = this.onCloseEnd.bind(this);
        this.state = { open: false };
    }

    componentDidMount() {
        this.modal = MModal.init(this.modalRef.current, {...this.props.options, onOpenStart: this.onOpenStart, onCloseEnd: this.onCloseEnd });
        if(this.props.startOpen) {
            this.modal.open();
        }
    }

    render() {
        const { fullWidth, className } = this.props;
        const classNames = classnames({"modal": true, "full-width": fullWidth, [className]: className });
        return (
            <React.Fragment>
                {this.props.triggerRender({ onClick: this.onClick })}
                <div className={classNames} ref={this.modalRef}>
                    {this.state.open && this.props.contentRender()}
                </div>
            </React.Fragment>
        )
    }

    componentWillUnmount() {
        this.unmounted = true;
        this.modal.close();
    }

    onOpenStart() {
        if(!this.unmounted)
            this.setState( {open: true} );
    }

    onCloseEnd() {
        if(this.unmounted)
            this.modal.destroy();
        else
            this.setState( {open: false} );
    }

    onClick() {
        if(!this.state.open) {
            this.modal.open();
        }
    }

}

class CoreRouteModal extends React.Component {

    constructor(props) {
        super(props);
        this.modalRef = React.createRef();
        this.headerRef = React.createRef();
        this.footerRef = React.createRef();
        this.handleManualClose = this.handleManualClose.bind(this);
        this.closeAndGo = this.closeAndGo.bind(this);
        this.closeAndDo = this.closeAndDo.bind(this);
        this.close = this.close.bind(this);
        this.popped = false;
        this.initializers = [];
        this.initialized = false;
        this.addContentInit = this.addContentInit.bind(this);
        this.calcNonScrollDim = this.calcNonScrollDim.bind(this);
        this.onOpenEnd = this.onOpenEnd.bind(this);
    }

    render() {
        const { fullWidth, scrollable, bottomSheet, limitWidth, style, noOverflow, fixed, hasCollapse, status: modalStatus } = this.props;
        const classNames = classnames({"modal": true, "full-width": fullWidth, "scrollable-content": scrollable, "bottom-sheet": bottomSheet, 'limit-width': limitWidth, 'no-overflow': noOverflow, 'fixed-modal': fixed, 'has-collapse': hasCollapse });

        return (
            <div className={classNames} style={style} ref={this.modalRef}>
                {this.props.render && this.props.render({ close: this.close, closeAndGo: this.closeAndGo, closeAndDo: this.closeAndDo, addContentInit: this.addContentInit, 
                    headerRef: this.headerRef, footerRef: this.footerRef, calcNonScrollDim: this.calcNonScrollDim, match: this.props.match, modalStatus })}
                {!this.props.render && this.props.children}
            </div>
        )
    }

    componentDidMount() {
        const { modalRedirectCheck, history } = this.props;
        if(modalRedirectCheck && modalRedirectCheck()) {
            history.replace(modalRedirectCheck());
            this.skipModalClose = true;
        } else {
            this.modal = MModal.init(this.modalRef.current, {onCloseStart: this.handleManualClose, onOpenEnd: this.onOpenEnd });
            this.modal.open();
        }
    }

    componentDidUpdate() {
        if (!this.popped && this.props.status === 'exiting' && !this.skipModalClose) {
            this.popped = true;
            this.modal.close();
        }
    }

    componentWillUnmount() {
        if(!this.skipModalClose){
            this.modal.close();
            this.modal.destroy();
        }

        this.initializers = null;
        if(this.props.closeCallback) {
            this.props.closeCallback()
        }
        if(this.afterCloseFn) {
            this.afterCloseFn();
        }
        if (this.afterClosePath) {
            if(isExternal(this.afterClosePath)) {
                if(window.isCordova) {
                    window.cordova.InAppBrowser.open(this.afterClosePath, '_system', '')
                } else {
                    window.location.href = this.afterClosePath;
                }
            } else {
                this.props.history.push(this.afterClosePath);
            }
        }
    }

    handleManualClose() {
        if(!this.popped) {
            this.popped = true;
            this.props.history.goBack();
        }
    }

    close() {
        if(this.modal) {
            this.modal.close();
        }
    }

    closeAndGo(to) {
        this.afterClosePath = to;
        this.modal.close();
    }

    closeAndDo(fn) {
        this.afterCloseFn = fn;
        this.modal.close();
    }

    calcNonScrollDim() {
        const headerHeight = this.headerRef.current ? this.headerRef.current.clientHeight : 0;
        const footerHeight = this.footerRef.current ? this.footerRef.current.clientHeight : 0;
        const topPosition = this.modalRef.current ? this.modalRef.current.offsetTop : 0;
        return (topPosition + headerHeight + footerHeight);
    }

    addContentInit(fn) {
        if(this.initialized) {
            fn();
        } else {
            this.initializers.push(fn);
        }
    }

    onOpenEnd() {
        if(!this.initialized && this.initializers) {
            this.initialized = true;
            for(let initializer of this.initializers) {
                initializer();
            }
        }
    }
}

let SFMCore = ({ icon, title, render, basic, exitButton=true, headerContProps={}, footerContProps={}, stateProps, modalProps, headerRef, footerRef, calcNonScrollDim, preProcessor }) => {
    preProcessor = preProcessor || (vals => vals);

    const renderHeader = ({ children }={}) => (
        <ModalHeader defaults exitButton={exitButton} scrollable noShadow {...headerContProps} ref={headerRef}>
            {children || <React.Fragment>{icon && (<FontAwesomeIcon icon={icon} />)} <b>{typeof(title) === 'function' ? title(preProcessor({ modalProps, stateProps })) : title}</b></React.Fragment>}
        </ModalHeader>
    )

    const renderFooter = ({ children, cancelChildren, saveChildren, loadState, id, disabled, dontCloseOnDone, saveIcon, saveLabel, cancelIcon, cancelLabel, noCancel, saveColor, render }={}) => {
        const Comp = dontCloseOnDone ? ModalSubmitNoClose : ModalSubmitLC;
        saveChildren = saveChildren || ((saveIcon || saveLabel) ? <React.Fragment><FontAwesomeIcon icon={saveIcon} /> {saveLabel}</React.Fragment> : null);
        cancelChildren = cancelChildren || (noCancel ? <React.Fragment></React.Fragment> : ((cancelIcon || cancelLabel) ? <React.Fragment><FontAwesomeIcon icon={cancelIcon} /> {cancelLabel}</React.Fragment> : null));
        return (
            <ModalFooter defaults scrollable noShadow {...footerContProps} ref={footerRef}>
                {render && render({ disabled, loadState, close: modalProps.close })}
                {!render && (children || <Comp 
                    disabled={disabled}
                    close={modalProps.close} 
                    loadState={loadState} 
                    saveChildren={saveChildren} 
                    cancelChildren={cancelChildren} 
                    saveColor={saveColor}
                    id={id}
                    noCancel={noCancel}
                />)}
            </ModalFooter>
        )
    }

    const renderScrollable = ({ children }={}) => (
        <ScrollableModalContent calcNonScrollDim={calcNonScrollDim}>
            {children}
        </ScrollableModalContent>
    )

    return (
        render({ renderFooter, renderScrollable, renderHeader, stateProps, ...modalProps })
    )
}

const mapStateToMappedProps = (state,props) => {
    if(props.stateSelector && typeof(props.stateSelector) === 'function') {
        return { stateProps: props.stateSelector(state,props) };
    }

    return {};
}

SFMCore = connect(mapStateToMappedProps)(SFMCore)

export const ScrollableFormModal = ({ icon, title, render, basic, exitButton=true, headerContProps={}, footerContProps={}, stateSelector, preProcessor, ...props }) => {

    return (
        <RouteModal 
            noOverflow
            {...props}
            render={({ headerRef, footerRef, calcNonScrollDim, ...modalProps}) => {
                return (
                    <SFMCore 
                        icon={icon}
                        title={title}
                        render={render}
                        basic={basic}
                        exitButton={exitButton}
                        headerContProps={headerContProps}
                        footerContProps={footerContProps}
                        stateSelector={stateSelector}
                        modalProps={modalProps}
                        headerRef={headerRef}
                        footerRef={footerRef}
                        calcNonScrollDim={calcNonScrollDim}
                        preProcessor={preProcessor}
                    />
                )
            }}
        />
    )
}

const FormModalCore = ({ formProps, footerProps, render, renderScrollable, renderFooter, modalProps, disableInvalid, noFooter, stateProps, FormikComp, preProcessor, ...loaderProps }) => {
    preProcessor = preProcessor || (vals => vals);
    const processedVals = preProcessor({ modalProps, stateProps });
    const resolvedFormProps = typeof(formProps) === 'function' ? formProps(processedVals) : formProps;
    const Comp = FormikComp || FormikWrapper;

    return (
        <Comp
            {...resolvedFormProps}
            render={(props) => {
                const { submitState, handleSubmit, isValid, ...rest } = props;
                const resolvedFooterProps = typeof(footerProps) === 'function' ? footerProps({ formikProps: props, stateProps }) : footerProps;
                return (
                    <form onSubmit={handleSubmit}>
                        {renderScrollable({ children: render({ submitState, handleSubmit, isValid, loaderProps, modalProps, stateProps, ...processedVals, ...rest }) })}
                        {!noFooter && renderFooter({ loadState: submitState, disabled: (disableInvalid && !isValid), ...resolvedFooterProps })}
                    </form>
                )
                
            }}
        />
    )
}

export const FullFormModal =  ({ render, formProps, footerProps={}, loadForm, loaderType='padded', disableInvalid=true, loadSuccess, noFooter, FormikComp, preProcessor, ...rest }) => {

    return (
        <ScrollableFormModal
            {...rest}
            preProcessor={preProcessor}
            render={({ renderFooter, renderScrollable, renderHeader, stateProps, ...modalProps }) => {
                const props = {
                    renderFooter,
                    renderScrollable,
                    modalProps,
                    formProps,
                    footerProps,
                    disableInvalid,
                    noFooter,
                    stateProps,
                    render,
                    preProcessor
                }

                if(loadForm) {
                    const processedVals = preProcessor ? preProcessor({ modalProps, stateProps }) : null;

                    return (
                        <React.Fragment>
                            {renderHeader()}
                            <Loader
                                successComponent={FormModalCore}
                                type={loaderType}
                                load={processedVals ? (() => loadForm(processedVals)) : loadForm}
                                successCallback={loadSuccess}
                                FormikComp={FormikComp}
                                preloaded={() => false}
                                {...props}
                            />
                        </React.Fragment>
                    )
                } else {
                    return (
                        <React.Fragment>
                            {renderHeader()}
                            <FormModalCore {...props} FormikComp={FormikComp} />
                        </React.Fragment>
                    )
                }
        }} />
    )
}

export const TabbedFormModal = ({ title, tabs, ...rest }) => {

    return (
        <ScrollableFormModal
            headerContProps={{ defaults: false, noShadow: false, defaultFont: true }}
            footerContProps={{ noShadow: false }}
            {...rest}
            render={({ renderFooter, renderScrollable, renderHeader, ...modalProps }) => {
            
                    return (
                        <Tabs addContentInit={modalProps.addContentInit} 
                            tabs={tabs} 
                            render={({ tabs, tabContents, tabRender, otherProps, activeTabId }) => {
                                const props = { renderFooter, renderScrollable, render: tabRender, ...otherProps }

                                return (
                                    <React.Fragment>
                                        {renderHeader({ children: (
                                            <React.Fragment>
                                                <b>{title}</b>
                                                {tabs}
                                            </React.Fragment>
                                        )})}
                                        <div key={activeTabId} id={activeTabId}>
                                            <FormModalCore {...props} />
                                        </div>
                                    </React.Fragment>
                                )
                        }} />
                    )
        }} />
    )

}

const SimpleContentModalCore = ({ render, title, icon, btnLabel, btnIcon, buttonRender, ...rest }) => {

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b>{icon && <FontAwesomeIcon icon={icon} />} {title}</b>
            </ModalHeader>
            {render({ ...rest })}
            <ModalFooter defaults>
                {buttonRender && buttonRender(rest)}
                {!buttonRender && (<Button color="primary" rounded className="modal-close">
                    {btnIcon && <FontAwesomeIcon icon={btnIcon} />}
                    {btnLabel}
                </Button>)}
            </ModalFooter>
 
        </React.Fragment>
    )
}

export const SimpleContentModal = ({ path, ...rest }) => {
    return (
        <RouteModal fullWidth limitWidth path={path} exact render={(routeProps) => (
            <SimpleContentModalCore {...routeProps} {...rest} />
        )} />
    )
}


let RouteModal = (props) => {
    
    const { path, exact, location, match, history, ...otherProps } = props;
    const { options: { inDuration } = {} } = props;

    return (
        <TransitionGroup>
            <Transition key={location.pathname} timeout={inDuration || 300}>
                { state => (
                    <Route location={location} path={path} exact={exact} render={(routeProps) => (
                        <CoreRouteModal {...routeProps} {...otherProps} status={state} />
                    )} />
                )}
            </Transition>
        </TransitionGroup>
    )

}

RouteModal = withRouter(RouteModal);

export default RouteModal;