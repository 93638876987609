import React, { useState } from 'react';
import { addAssessmentsToFormMatch, addExercisesToFormMatch, allMainMatches, editTrainerFormMatch, modalPathFor } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPageWithTabs } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { dbFormsSelector, filterBtnFiltsSel, trainerWithFormsSelector } from 'redux/selectors';
import { deleteTrainerForm, dismissTooltip, importTrainerForm, loadTrainerForms, removeFormFromDefaults, setFilterBtnFilts } from 'redux/actions';
import { connect } from 'react-redux';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { SlightEmphasisIconNote } from 'components/Typography';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { FixedButton } from 'components/Button';
import { useHistory, useLocation } from 'react-router-dom';
import * as _ from 'lib/utilities';
import { DropdownItem, DropdownLink } from 'components/Dropdown';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import Collapse from 'components/Collapse';
import requestStateHandler from 'components/RequestStateHandler';
import { Loader } from 'components/LoadingHOC';
import MediaQuery from 'components/MediaQuery';
import AnimTo from 'components/AnimTo';
import { MyRecordDropdown } from 'partials/Utilities';
import { FilterButtonWrapper, RecordTags, ownershipCol } from 'partials/RecordUtilities';
import { Form } from 'lib/classes';
import { DefaultFormScheduleModal, NewFormModal, defaultFormScheduleSuffix, newModalSuffix } from 'partials/TrainerFormModals';
import { TagButtons } from 'components/Form';
import { SETUP_FORMS_TIP } from 'config/tooltips';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut],
        [[addAssessmentsToFormMatch,editTrainerFormMatch,addExercisesToFormMatch],transitions.slideOver]
    ]
};


let RecordListing = ({ record, tabType, importRecord, t, destroy, setImportCount, trainer, pathname, removeFromDefaults }) => {

    const [expanded,setExpanded] = useState(false);

    const newDestroy = () => {
        const newRecord = new record.constructor({ ...record, status: 'deleted' });
        destroy(newRecord);
    }

    if(record.status === 'deleted') {
        return '';
    }

    const extraOpts = trainer.isTrainerAdmin() ? (
        <React.Fragment>
            <DropdownLink 
                id={`defaults-btn-${record.id}`}
                icon={record.isDefault() ? ['far','edit'] : 'check'} 
                to={modalPathFor(defaultFormScheduleSuffix,pathname,{ id: record.id })}
                label={record.isDefault() ? t('Edit Default Settings') : t('Set as Default')}
            />
            {record.isDefault() && (<DropdownItem id={`remove-def-btn-${record.id}`} icon="ban" label={t("Remove from Defaults")} onClick={() => removeFromDefaults({ formId: record.id })} />)}
        </React.Fragment>
    ) : null;

    return (
        <ListCard className="wrt-list-card" id={`rec-card-${record.id}`} collapse={<Collapse isOpen={expanded}>
            <div className="ma5"><SlightEmphasisIconNote text={record.resolvedDescription(t)} /></div>
        </Collapse>}>
            <ListCardAction className="inactive">
                <FontAwesomeIcon icon={record.cardIcon()} />
            </ListCardAction>
            <ListCardTitle withTags linkTo={record.editPath()}>
                <RecordTags record={record} viewer={trainer} listCard />
                {record.resolvedName(t)}
            </ListCardTitle>
            <ListCardAction onClick={() => setExpanded(!expanded )}>
                <FontAwesomeIcon icon={"info-circle"} />
            </ListCardAction>
            <MyRecordDropdown
                record={record} 
                t={t} 
                newDestroy={newDestroy} 
                importRecord={importRecord} 
                isDb={tabType === 'db'} 
                setImportCount={setImportCount}
                trainer={trainer} 
                extraOpts={(tabType !== 'db' && trainer.isTrainerAdmin()) ? (extraOpts) : null}
            />
        </ListCard>
    )
}

RecordListing = requestStateHandler({ destroy: 'record' })(RecordListing)

const RecordsListCore = ({ records, tabType, importRecord, setImportCount, destroy, trainer, pathname, removeFromDefaults }) => {
    const { t } = useTranslation();


    return (<React.Fragment>
            {_.sortBy(records,form => form.resolvedName(t)).map(rec => (
                <RecordListing 
                    key={rec.id} 
                    record={rec} 
                    tabType={tabType} 
                    t={t} 
                    importRecord={importRecord} 
                    setImportCount={setImportCount}
                    destroy={destroy} 
                    trainer={trainer}
                    pathname={pathname}
                    removeFromDefaults={removeFromDefaults}
                />
            ))}
    </React.Fragment>)
}

const RecordsList = ({ trainer, records, recordType: type, importRecord, destroy, isLarge, setImportCount, removeFromDefaults, setActiveTab, filters, setFilters, filterBtn }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const finalRecords = _.filter(records, rec => rec.isVisible(trainer,filters));
    const newPath = modalPathFor(newModalSuffix,pathname);
    const listProps = { records: finalRecords, tabType: type, importRecord, destroy, trainer, removeFromDefaults };

    const emptyMessage = `${t('no forms')} ${t('import from db tip')}`;

    return (
        <div className="mt15 mb150">
            {type === 'db' && (<div className="text-center">
              <TagButtons
                single
                col={[{ text: t('All'), value: null }, ...Form.formTypes(t) ]}
                setFieldTouched={() => {}}
                setFieldValue={(name,value) => setFilters({ formType: value })}
                name='formType'
                values={filters}
                buttonProps={ { className: 'ma2' } }
                btnId={value => `${value || 'all'}-filt-btn`}
              />
          </div>)}
            {type === 'personal' && (
                <div>
                    <LinkButton
                        to={newPath}
                        rounded
                        color="primary"
                        outlined={finalRecords.length !== 0}
                        className="hide-on-med-and-down"
                    >
                        <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span>{t("New")}</span>
                    </LinkButton>
                    {filterBtn}
                </div>
            )}
            {finalRecords.length === 0 && (
                <div className="mb10 mt10">
                    <SlightEmphasisIconNote text = {emptyMessage} btn={<Button rounded id="to-db-btn" outlined onClick={() => setActiveTab(2)}><FontAwesomeIcon icon="arrow-right" /> {t("Go to Database Tab")}</Button>} />
                </div>
            )}
            {finalRecords.length > 0 && (<RecordsListCore {...listProps} setImportCount={setImportCount} pathname={pathname} />)}
            {type === 'personal' && (
                <React.Fragment>
                    <DefaultFormScheduleModal trainer={trainer} />
                    <NewFormModal trainer={trainer} />
                    {!isLarge && <FixedButton id="new-form-btn" icon="plus" onClick={() => history.push(newPath)} />}
                </React.Fragment>
            )}
        </div>
    )
}

class RecordsListWrap extends React.Component {

    constructor(props) {
        super(props);
        const { setImportCount, recordType } = this.props;

        if(recordType === 'personal') {
            setImportCount(0);
        }
    }

    render() {
        const { t, recordType } = this.props;

        return (
            <FilterButtonWrapper 
                filterKey={recordType === 'db' ? 'dbFormsIndex' : 'personalFormsIndex'} 
                initialFilters={{ formType: null, ownershipType: null, isDefault: null }} 
                className="ml5"
                options={{
                    formType: { label: t('Form Type'), options: Form.formTypes(t) },
                    ownershipType: { label: t('Ownership'), options: ownershipCol(t) },
                    isDefault: { label: t('Is Default?'), options: [{ text: t('Default'), value: true }, { text: t('Non-Default'), value: false }]}
                }}
                render={(filtProps) => (<RecordsList {...this.props} {...filtProps} />)} 
            />
        )
    }
}

class FormsIndexPage extends React.Component {

    constructor(props) {
        super(props);
        const { dismissTip, trainer } = this.props;
        if(trainer.showFormsSetupCard()) {
            dismissTip(SETUP_FORMS_TIP);
        }

        this.state = {
            importCount: 0
        }
    }

    render() {
        const { trainer, dbForms, loadForms, importForm, destroy, activeTab, setActiveTab, scrollRef, setupTransitions, removeFromDefaults, t, allFilters, setFilters } = this.props;
        const { importCount } = this.state;


        return (
            <SimpleNavPageWithTabs
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t('fcas')}
                tabLabels={ [<React.Fragment>{t("Mine")}{importCount > 0 && (<AnimTo top={'150px'} left={'150px'} tag='b' className="primary-text"> +{importCount}</AnimTo>)}</React.Fragment>,t("Database")]}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
            >
                <MediaQuery query={'(min-width: 993px)'} render={({ queryMatches, activeTab, setActiveTab }) => {
                    const recordType = activeTab === 2 ? 'db' : 'personal';
                    let records = recordType === 'db' ? dbForms : trainer.activeTrainerPersonalForms();

                    return (
                        <CenteredActivityContainer>
                            <Loader
                                key={recordType}
                                type="page"
                                alwaysLoad
                                successComponent={RecordsListWrap}
                                preloaded={() => records.length > 0}
                                load={() => loadForms({ type: recordType })}
                                records={records}
                                recordType={recordType}
                                importRecord={importForm}
                                destroy={destroy}
                                trainer={trainer}
                                isLarge={queryMatches}
                                loadRecords={loadForms}
                                setImportCount={this.setImportCount}
                                removeFromDefaults={removeFromDefaults}
                                setActiveTab={setActiveTab}
                                allFilters={allFilters}
                                setFilters={setFilters}
                                t={t}
                            />
                        </CenteredActivityContainer>
                    )
                }} />
            </SimpleNavPageWithTabs>
        )
    }

    setImportCount = (count) => {
        const { importCount } = this.state;

        this.setState({ importCount: _.isBlank(count) ? (importCount+1) : count });
    }
}

const mapStateToProps = state => ({
    dbForms: dbFormsSelector(state),
    trainer: trainerWithFormsSelector(state),
    allFilters: filterBtnFiltsSel(state)
})

const mapDispatchToProps = dispatch => ({
    loadForms: params => dispatch(loadTrainerForms(params)),
    importForm: (params,popup) => dispatch(importTrainerForm(params,popup)),
    destroy: (form) => dispatch(deleteTrainerForm({ id: form.id })),
    removeFromDefaults: data => dispatch(removeFormFromDefaults(data)),
    dismissTip: tipName => dispatch(dismissTooltip(tipName)),
    setFilters: data => dispatch(setFilterBtnFilts(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(FormsIndexPage));