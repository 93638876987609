import React, { useState } from 'react';
import * as _ from 'lib/utilities';
import { InfiniteScrollForm } from 'components/InfiniteScroll';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { AutoSubmitTextInput } from 'components/TextInput';
import { exerciseSearchResultsSel, exerciseSearchPageSel, exerciseSearchMoreSel, exerciseSearchCtxSel, exerciseSearchFormValsSel } from 'redux/selectors';
import { resetExerciseSearch, executeExerciseSearch, cacheExerciseSearch } from 'redux/actions';
import { connect } from 'react-redux';
import Card from 'components/Card';
import { ExerciseSearchListing } from 'partials/ExerciseListing';
import { Exercisable } from 'lib/exercisable';
import { SelectInput } from 'components/TextInput';
import { PaddedIconNote } from 'components/Typography';
import { progressionShowPathFor, exerciseInfoModalPathFor } from 'config/paths';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import { ExerciseInfoModal } from 'partials/ExerciseModals';
import { ListCardAction } from 'components/List';

export const ExerciseSearchForm = ({ values, handleBlur, handleChange, submitForm, setFieldValue, setFieldTouched }) => {

    const { t } = useTranslation();

    const changeHandler = _.arrayChangeHandlerCreator(setFieldValue,setFieldTouched,submitForm);

    return (
        <div className="pa10 text-left">
            <div className="">
                <AutoSubmitTextInput 
                    label="Search"
                    value={values.searchTerm}
                    submitForm={submitForm}
                    timeout={500}
                    className="search-field mb0"
                    inputProps={ {
                        name: "searchTerm",
                        onBlur: handleBlur,
                        onChange: handleChange,
                        id: 'db-recipes-query-field'
                    } }
                />
            </div>
            <div className="display-flex">
                <div style={{ flexGrow: '1'}}>
                    <div className="font-grey">{t('Equipment types')}</div>
                    <SelectInput 
                        className="auto-height"
                        multiple
                        name="equipmentTypes" 
                        collection={Exercisable.equipmentTypesCol(t)} 
                        browserDefault 
                        value={values.equipmentTypes} 
                        onChange={changeHandler} 
                        onBlur={handleBlur} 
                    />
                </div>
                <div className="ml5" style={{ flexGrow: '1'}}>
                    <div className="font-grey">{t('Exercise types')}</div>
                        <SelectInput 
                            className="auto-height"
                            multiple
                            name="simpleTypes" 
                            collection={Exercisable.simpleTypesCol(t)} 
                            browserDefault 
                            value={values.simpleTypes} 
                            onChange={changeHandler} 
                            onBlur={handleBlur} 
                        />
                    </div>
                </div>
            </div>
    )
}

let ExerciseSearchInfiniteScroll = ({ 
    exercisables,   
    initialValues,
    loadedPage,
    more,
    load,
    cacheSearch,
    render
 }) => {

    const { t } = useTranslation();

     return (
        <InfiniteScrollForm
            initialPage={loadedPage || 1}
            initialMsg={null}
            noResultsComp={<PaddedIconNote variant="info" text={t("No exercises match your search")} />}
            initialValues={initialValues || { searchTerm: '', equipmentTypes: [''], simpleTypes: [''] }}
            records={exercisables}
            loadedPage={loadedPage || 0}
            load={load}
            more={more}
            dontRenderTrigger
            id="exercises-scroll-wrapper"
            render={({ maxIndex, startValues, submitLoad, retryLoad, status, scrollTriggerComp }) => {
                const submitHandler = (values) => {
                    cacheSearch(values);
                    submitLoad(values);
                }

                return (
                    <React.Fragment>
                        <Formik 
                            initialValues={ startValues }
                            onSubmit={submitHandler}
                            validate={(values) => ({})}
                            initialErrors={{}} >
                            {(formikProps) => {
                                return render({ maxIndex, startValues, submitLoad, retryLoad, status, formikProps, exercisables, scrollTriggerComp })
                            }}
                        </Formik>
                    </React.Fragment>
                )
            }}
        >
        </InfiniteScrollForm>
    )
}

const mapStateToProps = state => ({
    exercisables: exerciseSearchResultsSel(state),
    loadedPage: exerciseSearchPageSel(state),
    more: exerciseSearchMoreSel(state),
    initialValues: exerciseSearchFormValsSel(state)
})

const mapDispatchToProps = dispatch => ({
    load: (values,page) => dispatch(executeExerciseSearch(values,page)),
    cacheSearch: (values) => dispatch(cacheExerciseSearch(values))
})

ExerciseSearchInfiniteScroll = connect(mapStateToProps,mapDispatchToProps)(ExerciseSearchInfiniteScroll);

const contextsMatch = (context1,context2) => {
    context1 = context1 || {};
    context2 = context2 || {};
    return context1.id === context2.id && context1.type === context2.type;
}

class ExerciseSearchWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.handleMismatchedContext();
    }

    handleMismatchedContext() {
        const { context, reduxContext, resetExerciseSearch, initialValues } = this.props;
        if(!contextsMatch(context,reduxContext)) {
            resetExerciseSearch({ formValues: initialValues, context: context })
        }
    }

    render() {
        const { context, reduxContext, children } = this.props;

        if(contextsMatch(context,reduxContext)) {
            return (
                <React.Fragment>
                    {children}
                </React.Fragment>
            )
        } else {
            return <div></div>;
        }
    }

}

const mapStateToWrapperProps = state => ({
    reduxContext: exerciseSearchCtxSel(state)
})

const mapDispatchToWrapperProps = dispatch => ({
    resetExerciseSearch: (data) => dispatch(resetExerciseSearch(data))
})

ExerciseSearchWrapper = connect(mapStateToWrapperProps,mapDispatchToWrapperProps)(ExerciseSearchWrapper);

export const StandaloneExerciseSearch = ({ context, initialValues, basePath, actionComp, hideForm, header, noContainers, hideGeneric, noInfo }) => {
    const [blockModals,setBlockModals] = useState(false);
    const history = useHistory();

    const actionRender = ({ exercisable }) => {
        const infoPath = exercisable.isProgression() ? progressionShowPathFor(exercisable.id) : exerciseInfoModalPathFor(basePath,exercisable.id);

        return (
            <React.Fragment>
                {!noInfo && (<ListCardAction 
                    id={`${exercisable.isProgression() ? 'prog' : 'exercise'}-info-btn-${exercisable.id}`}
                    onClick={() => (!blockModals && history.push(infoPath))} 
                    className={!actionComp && 'no-border extra-padding'}
                >
                    <FontAwesomeIcon icon="info-circle" />
                </ListCardAction>)}
                {actionComp && actionComp({ exercisable, setBlockModals })}
            </React.Fragment>
        )
    }

    return (
        <ExerciseSearchWrapper context={context} initialValues={initialValues} >
            <ExerciseSearchInfiniteScroll 
                render={({ maxIndex, startValues, submitLoad, retryLoad, status, formikProps, exercisables, scrollTriggerComp }) => {
                    if(noContainers) {
                        return (
                            <React.Fragment>
                                {!hideForm && (<Card>
                                    <ExerciseSearchForm {...formikProps} />
                                </Card>)}
                                <ExerciseSearchListing exercisables={exercisables} maxIndex={maxIndex} actionRender={actionRender} hideGeneric={hideGeneric} />
                                {scrollTriggerComp}
                            </React.Fragment>
                        )
                    } else {
                        return (
                            <div className="container mt20">
                                <div className="row">
                                    {header && (<div className="col s12 text-center">{header}</div>)}
                                    {!hideForm && (<div className="col s12 m12 l6">
                                        <Card>
                                            <ExerciseSearchForm {...formikProps} />
                                        </Card>
                                    </div>)}
                                    <div className={hideForm ? "col s12 m12 l6 offset-l3" : "col s12 m12 l6"}>
                                        <ExerciseSearchListing exercisables={exercisables} maxIndex={maxIndex} actionRender={actionRender} hideGeneric={hideGeneric} />
                                        {scrollTriggerComp}
                                    </div>
                                </div>
                            </div>
                        )   
                    }
                }}
            />
            <ExerciseInfoModal basePath={basePath} baseMatch={basePath}/>

        </ExerciseSearchWrapper>
    )
}

export { ExerciseSearchInfiniteScroll, ExerciseSearchWrapper }