import classnames from 'classnames';
import { FixedButton } from 'components/Button';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { chatsMetaSelector, chatUserSelector } from 'redux/selectors';
import { activateChatModalSuffix, newChatModalSuffix, turnOffChatModalSuffix, updateChatSettingsModalSuffix } from './NewChatModal';
import * as _ from 'lib/utilities';
import { UserGroupImage } from './Utilities';
import { DropdownItem, DropdownLink, MultiLevelDropdown } from 'components/Dropdown';
import { ChatMenuTrigger } from './Chat';
import { TwoWayInfiniteScroll } from 'components/InfiniteScroll';
import { loadChats } from 'redux/actions';
import { Loader } from 'components/LoadingHOC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlightEmphasisIconNote } from 'components/Typography';
import LinkButton from 'components/LinkButton';

const ChatsMenu = ({ user, chatType, setChatType, triggerRender }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const viewingArchived = (chatType === 'archived');
    const toggleChatType = () => {
        setChatType(viewingArchived ? 'active' : 'archived');
    }

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: false, alignment: 'right', closeOnClick: true, closeOnChildClick: true } }
            triggerRender={triggerRender}
        >
            <DropdownItem 
                onClick={toggleChatType} 
                label={t(`${viewingArchived ? 'Active' : 'Archived'} Chats`)} 
                icon={viewingArchived ? ['far','users'] : ['far','folder-download']} 
                id="toggle-chat-type"
            />
            {user.isTrainer() && (<DropdownLink to={modalPathFor(updateChatSettingsModalSuffix,pathname)} label={t('Settings')} icon="cog" />)}
            {user.isTrainer() && (<DropdownLink to={modalPathFor(turnOffChatModalSuffix,pathname)} label={t('Turn Off Messaging')} icon="ban" id="turn-off-btn" />)}
        </MultiLevelDropdown>
    )
}

const NewChatButton = ({ isSidebar }) => {
    const history = useHistory();
    const { pathname } = useLocation();

    return (
        <FixedButton 
            icon="plus"
            id="new-chat-btn"
            containerClass={classnames({ sidebar: isSidebar })}
            onClick={() => history.push(modalPathFor(newChatModalSuffix,pathname))}
        />
    )
}

const ActivateChatButton = () => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <LinkButton color="primary" noShadow rounded to={modalPathFor(activateChatModalSuffix,pathname)} id='activate-chat-btn'>
            <FontAwesomeIcon icon="comments-alt" /> {t('Activate Messaging')}
        </LinkButton>
    )
}

const ChatListEntry = ({ user, chat, selectChat, selectedChat }) => {
    const { t } = useTranslation();
    const last = chat.lastMessage();
    const isMuted = chat.isMuted(user);
    let others = chat.otherMembers(user);
    const unreadCount = user.unreadMessageCountFor(chat.id);
    if(others.length === 0) {
        others = [user];
    }

    return (
        <div className={classnames("chat-list-entry", { active: selectedChat === chat.id }) } id={`chat-entry-${chat.id}`} onClick={() => selectChat(chat)}>
            <div className="cicon-wrap">
                <UserGroupImage users={others} />
            </div>
            <div className="cinfo-wrap">
                <div className="title-time">
                    <div className="ctitle">{chat.displayName(user,t)}</div>
                    <div className="ctime">{chat.lastActiveStr(t)}</div>
                </div>
                <div className="title-time">
                    <div className="cdetail">
                        {last && (
                            <div className="abbr-text">
                                {chat.isGroup() && (<span className="sender-name">{last.sender.initialsName()}: </span>)}
                                {last.summary(t)}
                            </div>
                        )}
                        {!last && (
                            <div className="abbr-text">
                                {t("No messages yet")}
                            </div>
                        )}
                    </div>
                    {(unreadCount > 0 || isMuted) && (<div className="position-relative">
                    {isMuted && (<FontAwesomeIcon icon={['far','bell-slash']} />)} {unreadCount > 0 && (<span className={classnames("inline-notificon",{ grey: isMuted})}>{unreadCount}</span>)}
                    </div>)}
                </div>

            </div>
        </div>
    )
}

const ChatListScroller = ({ chatType, loadChats, chatsMeta, user, selectChat, selectedChat }) => {
    const { t } = useTranslation();

    if(chatsMeta && chatsMeta.done && user.paginatedChats(chatsMeta).length === 0) {
        return (
            <div className="pa10">
                <SlightEmphasisIconNote text={chatType === 'archived' ? t('no archived chats') : `${t('no msgs tip')} ${user.isTrainer() ? t('msg click plus') : ''}`} />
            </div>
        )
    }

    return (
        <TwoWayInfiniteScroll 
            load={dir => loadChats({ ...chatsMeta }) }
            topDone={true}
            bottomDone={chatsMeta && chatsMeta.done}
            id="chat-list-scroll"
            render={() => {
                return (
                    <React.Fragment>
                        {user.paginatedChats(chatsMeta).map(chat => {
                            return (
                                <ChatListEntry 
                                    user={user} 
                                    chat={chat} 
                                    selectChat={selectChat} 
                                    key={chat.id} 
                                    selectedChat={selectedChat} 
                                />
                            )
                        })}
                    </React.Fragment>
                )
            }}
        />
    )
}

class ChatList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { incrementer: 0, chatType: 'active', hadChatsMeta: !!props.chatsMeta, keySlop: '' }
    }

    static getDerivedStateFromProps(props,state) {
        if(state.hadChatsMeta && !props.chatsMeta) {
            return { hadChatsMeta: false, keySlop: `${Math.round(Math.random()*99999)}`}
        } else if(!state.hadChatsMeta && !!props.chatsMeta) {
            return { hadChatsMeta: true }
        }
        return null;
    }

    componentDidMount() {
        //need this to trigger render so time passed on chats updates
        this.refresher = setInterval(() => {
            this.setState({ incrementer: this.state.incrementer+1 })
        },60000)
    }

    render() {
        const { loadChats, user, isSidebar, selectChat, selectedChat, t, standalone } = this.props;
        const { chatType, keySlop } = this.state;
        const chatsMeta = this.getChatMetaData();

        return (
            <div className="chat-list">
                <div className={classnames("message-head", { standalone })}>
                    {chatType === 'archived' ? t('Archived Chats') : t('Messages')}
                    {user.hasActiveMessaging() && (<ChatsMenu 
                        user={user}
                        chatType={chatType}
                        setChatType={this.setChatType}
                        triggerRender={({ ref, target }) => (<ChatMenuTrigger ref={ref} target={target} />)}
                    />)}
                </div>
                {user.hasActiveMessaging() && (<Loader
                    type="padded"
                    key={`${chatType}-${keySlop}`}
                    load={() => loadChats({ archiveType: chatType })}
                    preloaded={() => !_.isBlank(chatsMeta)}
                    alwaysLoad
                    successComponent={ChatListScroller}
                    loadChats={loadChats}
                    chatType={chatType}
                    user={user}
                    selectChat={selectChat}
                    selectedChat={selectedChat}
                    chatsMeta={chatsMeta}
                />)}
                {user.isTrainer() && !user.hasActiveMessaging() && (
                    <div className="pa10">
                        <SlightEmphasisIconNote 
                            text={(<React.Fragment>
                                {t("activate msg msg")}
                                <div className="center mt25">
                                    <ActivateChatButton />
                                </div>
                            </React.Fragment>)} 
                        />
                    </div>
                )}
                {!user.isClient() && user.hasActiveMessaging() && (<NewChatButton isSidebar={isSidebar} />)}
            </div>
        );
    }

    setChatType = (chatType) => {
        this.setState({ chatType })
    }

    getChatMetaData = () => {
        const { chatsMeta } = this.props;
        const { chatType } = this.state;

        if(chatsMeta && chatsMeta.archiveType === chatType) {
            return chatsMeta;
        }

        return null;
    }

    componentWillUnmount() {
        clearInterval(this.refresher)
    }
}

const mapStateToProps = state => ({
    user: chatUserSelector(state),
    chatsMeta: chatsMetaSelector(state)
})

const mapDispatchToProps = dispatch => ({
    loadChats: data => dispatch(loadChats(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ChatList))