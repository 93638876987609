import React from 'react';
import { userMealMealTypeSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import { unlogMeal } from 'redux/actions';
import { SimpleConfirmActionButton } from 'components/Button';
import { hashSum } from 'lib/utilities';
import { zeroedMacros } from 'lib/record-base';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import { viewMealPathFor } from 'config/paths';
import { Link } from 'components/Routing';
import { PlainTippedText } from 'components/Tooltipped';

const GoalsRows = ({ totals: { calories, ...macros }, targets: { targetCals, macroParams } }) => {
    const { t } = useTranslation();
    const netCals = calories - targetCals;

    return (
        <React.Fragment>
            <tr className="totals normal-height">
                <td className="logged-title-text">
                    <b>{t("Goal")}</b>
                </td>
                <td className="font-grey pl10">
                    {targetCals} {t('cals')}
                </td>
                {['protein','carbs','fat'].map(macro => {
                    return (
                        <td className={`${macro}-text`} key={macro}>
                            {macroParams.requirementStr(macro,t)}
                        </td>
                    )
                })}
                <td></td>
            </tr>
            <tr className="totals normal-height">
                <td className="logged-title-text">
                    <b>{t("Net")}</b>
                </td>
                <td className={classnames("pl10", { "font-grey": Math.abs(netCals) < 100, "red-text": Math.abs(netCals) >= 100 })}>
                    {netCals < 0 ? '' : '+'}{netCals} {t('cals')}
                </td>
                {['protein','carbs','fat'].map(macro => {
                    const [str, color] = macroParams.overshootStr(macro,macros[macro] || 0,t)
                    return (
                        <td className={color} key={macro}>
                            {str}
                        </td>
                    )
                })}
                <td></td>
            </tr>
        </React.Fragment>
    )
}

const TotalsRow = ({ totals }) => {
    const { t } = useTranslation();

    return (
        <tr className="totals">
            <td className="logged-title-text">
                <b>{t("Total")}</b>
            </td>
            <td className="font-grey pl10">
                {Math.round(totals.calories)} {t('cals')}
            </td>
            <td className="protein-text">
                {Math.round(totals.protein)}{t('protein_abbr')}
            </td>
            <td className="carbs-text">
                {Math.round(totals.carbs)}{t('carb_abbr')}
            </td>
            <td className="fat-text">
                {Math.round(totals.fat)}{t('fat_abbr')}
            </td>
            <td></td>
        </tr>
    )
}

const LogEntryDefault = ({ record, mealType, load, error, readOnly }) => {
    const { t } = useTranslation();
    const macros = record.loggedMacroHash();
    let recipePath = null;
    let recipe = null;
    if(record.constructor.NAME === 'RecipeMeal') {
        recipe = record.recipe;
        recipePath = viewMealPathFor(record.urlStub(),1);
    } else if(record.source && record.source.constructor.NAME === 'Recipe' && !_.isBlank(record.servings)) {
        recipe = record.source;
        recipePath = viewMealPathFor(record.source.urlStub(record.servings,true),1);
    }
    const title = record.logTitle(mealType,t);
    const titleBlock = recipePath ? <Link to={recipePath} className={`underline link-to-recipe-${recipe.id}`}>{title}</Link> : title;

    return (
        <tr>
            <PlainTippedText comp="td" className="logged-title-text" tip={<span>{title}</span>}>
                {titleBlock}
            </PlainTippedText>
            <td className="font-grey pl10">
                {Math.round(macros.calories)} {t('cals')}
            </td>
            <td className="protein-text">
                {Math.round(macros.protein)}{t('protein_abbr')}
            </td>
            <td className="carbs-text">
                {Math.round(macros.carbs)}{t('carb_abbr')}
            </td>
            <td className="fat-text">
                {Math.round(macros.fat)}{t('fat_abbr')}
            </td>
            {!readOnly && (<SimpleConfirmActionButton component="td" className="x-padding clickable unlog-um-btn" id={`unlog-um-${record.id}-btn`} onClick={load}>
                <FontAwesomeIcon icon="times" />
                {error && (<div className="red-text">{t("failed")}</div>)}
            </SimpleConfirmActionButton>)}
        </tr>
    )
}

const LogEntryRequest = () => null;
const LogEntryErr = (props) => (<LogEntryDefault {...props} error />)

const LogEntryLC = loadingContainer({
    "DEFAULT": LogEntryDefault,
    "REQUEST": LogEntryRequest,
    "NETERR": LogEntryErr,
    "SERVERERR": LogEntryErr,
    "MAINTENANCE": LogEntryErr
})

class LogEntry extends React.Component {

    render() {
        const { record, mealType, unlogMeal, readOnly } = this.props;

        return (
            <LoadingContainerParent 
                skipAutoLoad
                component={LogEntryLC}
                load={unlogMeal.bind(null,record.id)}
                preloaded={() => false}
                record={record}
                mealType={mealType}
                readOnly={readOnly}
            />
        )
    }
}

const mapStateToProps = (state,props) => ({
    mealType: userMealMealTypeSelector(state,props)
})

const mapDispatchToProps = (dispatch) => ({
    unlogMeal: (userMealId) => dispatch(unlogMeal(userMealId))
})

const ConnectedLogEntry = connect(mapStateToProps,mapDispatchToProps)(LogEntry);

const LoggedMealsTable = ({ userMeals, readOnly, targets }) => {
    const totals = userMeals.length > 0 ? hashSum(userMeals.map(userMeal => userMeal.loggedMacroHash())) : { ...zeroedMacros };
    const showTotals = !!targets || (userMeals && userMeals.length > 1);
    const recordCol = readOnly ? _.flatMap(userMeals,um => ((um.isOffPlan() || _.isBlank(um.recipeMeals) || um.recipeMeals.length === 0) ? um : um.recipeMeals)) : userMeals;

    return (
        <table className="food-log-table">
            <tbody>
                {recordCol.map(record => (<ConnectedLogEntry key={record.logKey()} record={record} readOnly={readOnly} />))}
                {showTotals && (<TotalsRow totals={totals} />)}
                {showTotals && targets && <GoalsRows totals={totals} targets={targets} />}
            </tbody>
        </table>
    )
}

export default LoggedMealsTable;