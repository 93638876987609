import React, {  } from 'react';
import { createHabit, deleteHabit, importHabit, loadHabits, setTrainerForHabits, updateHabit } from 'redux/actions';
import { connect } from 'react-redux';
import { habitsBase, modalPathFor } from 'config/paths';
import withTrainer from 'partials/TrainerLoader';
import { dbHabitsSelector, trainerForHabitsSel, trainerRecordSelector } from 'redux/selectors';
import { useTranslation, withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import IndexPage from 'partials/IndexPage';
import { FullFormModal } from 'components/Modal';
import { DefaultSelect, FullInput } from 'components/Form';
import TextInput, { NumberInput, TextArea } from 'components/TextInput';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { InitialTipPopup, SlightEmphasisTip } from 'partials/Utilities';
import { INITIAL_HABITS_TIP } from 'config/tooltips';
import { TooltippedLabel } from 'components/Tooltipped';
import { HabitLogPreview } from 'partials/HabitLogCard';
import { Habit } from 'lib/classes';
import { validateYupSchema, yupToFormErrors } from 'formik';

const habitValidator = values => {
    const { type } = values;

    let objShape = {
        name: Yup.string().required()
    }

    if(type === 'activity' || type === 'steps') {
        objShape = {
            goalQuantity: Yup.number().min(type === 'activity' ? 1 : 100).max(type === 'activity' ? 1000 : 50000).required()
        }
    }
    
    const schema = Yup.object().shape(objShape);
    try {
        validateYupSchema(values, schema,true);
        return {};
    } catch(validationError) {
        return yupToFormErrors(validationError);
    }
}

const HabitModalCore = ({ path, submit, initialVals, title, icon }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={icon}
            title={title}
            formProps={({ modalProps }) => {
                const vals = initialVals(modalProps);

                return ({
                    initialValues: vals,
                    submit,
                    validate: habitValidator,
                    initialErrors: habitValidator(vals)
                })
            }}
            footerProps={null}
            render={({ modalProps, ...rest }) => {
                const { values, handleBlur, handleChange } = rest;
                const { type } = values;
                const isNew = _.isBlank(values.id);

                return (
                    <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="valign-wrapper flex-start">
                            {isNew && (<DefaultSelect
                                className="mtn5"
                                label={t('Type')}
                                name='type'
                                collection={Habit.typeCol(t)}
                                {...rest}
                            />)}
                            {_.isBlank(type) && (<FullInput
                                className="ml5 flex-grow with-placeholder"
                                name="name"
                                label={`${t('Name')}/${t('Prompt')}`}
                                component={TextInput}
                                formikProps={rest}
                                inProps={{
                                    placeholder: 'E.g. take a multivitamin'
                                }}
                            />)}
                            {(type === 'activity' || type === 'steps') && (
                                <FullInput
                                    className="ml5 flex-grow"
                                    name="goalQuantity"
                                    label={type === 'activity' ? t('Active minutes goal') : t('Step goal')}
                                    component={NumberInput}
                                    formikProps={rest}
                                />
                            )}
                        </div>
                        {_.isBlank(type) && (<div className="text-center">
                            <div className="text-left font-grey">{`${t('Description')}/${t('Instructions')} (${t('optional')})`}</div>
                            <TextArea 
                                maxLength={25000} 
                                rows={20} 
                                style={{ width: '100%', height: '150px' }} 
                                id="habit-desc-field"
                                name='description' 
                                value={values.description} 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                            />
                        </div>)}
                        <SlightEmphasisTip text={t('habit scheduling tip')} containerClassName={_.isBlank(type) ? "mt10 pa20" : ""} />
                        <div className="mt10">
                            <TooltippedLabel text={t('Habit preview')} tip={t('habit preview tip')} />
                            <HabitLogPreview {...values} />
                        </div>
                    </div>
                )
                
            }} 
        />
    )
}

let NewHabitModal = ({ createHabit }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor('/new',pathname);

    return (
        <HabitModalCore 
            path={path}
            submit={values => createHabit(values)}
            initialVals={() => ({ name: '', description: '', type: '', goalQuantity: '' })}
            title={t('New Habit')}
            icon="plus"
        />
    )
}

const mapDispatchToNewProps = dispatch => ({
    createHabit: data => dispatch(createHabit(data))
})

NewHabitModal = connect(null,mapDispatchToNewProps)(NewHabitModal);

let EditHabitModal = ({ trainer, update }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor('/edit/:id',pathname);

    return (
        <HabitModalCore 
            path={path}
            submit={values => update(values)}
            initialVals={({ match: { params: { id }}}) => {
                const habit = trainer.habitById(id);
                return habit.initialFormValues();
            }}
            title={t('Edit Habit')}
            icon={['far','edit']}
        />
    )
}

const mapStateToModalProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToEditProps = dispatch => ({
    update: data => dispatch(updateHabit(data))
})

EditHabitModal = connect(mapStateToModalProps,mapDispatchToEditProps)(EditHabitModal);

class Habits extends React.Component {

    render() {
        const { habitImports, clearHabitImports, dbHabits, importHabit, loadHabits, addHabitImport, t, ...rest } = this.props;

        return (
            <TrainerPaywall type={null}>
                <IndexPage 
                    {...rest}
                    importCount={habitImports}
                    clearImports={clearHabitImports}
                    recordName={'TrainerHabits'}
                    recordNameP={t('habits')}
                    recordNameS={_.capitalize(t('habit'))}
                    dbRecords={dbHabits}
                    importRecord={importHabit}
                    basePath={habitsBase}
                    load={loadHabits}
                    addImport={addHabitImport}
                    title={t("Habits")}
                    NewModal={NewHabitModal}
                    EditModal={EditHabitModal}
                    hideTabs
                />
                <InitialTipPopup tipName={INITIAL_HABITS_TIP} id="initial-habits-tip" title={t('Habits')} alwaysDismissForever >
                    <div>
                        <p className="pl20 pr20">
                            {t("initial habits tip")}
                        </p>
                    </div>
                </InitialTipPopup>
            </TrainerPaywall>
        )
    }
}

const mapStateToProps = state => ({
    dbHabits: dbHabitsSelector(state),
    trainerId: trainerForHabitsSel(state)
})

const mapDispatchToProps = dispatch => ({
    loadHabits: params => dispatch(loadHabits(params)),
    importHabit: (id,popup) => dispatch(importHabit(id,popup)),
    destroy: (habit) => dispatch(deleteHabit(habit.id)),
    selectTrainer: trainerId => dispatch(setTrainerForHabits(trainerId))
})

export default withTrainer(false)(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(Habits)));
