import React, {Component} from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import FlowForm, { FlowPageContent, FlowQuestionContainer, FlowQuestion, AutoAdvanceButton, NextButton, SmallCardOption, FlowChooseGoal, FlowNav, getHighestValidPage } from 'components/FlowForm';
import Button from 'components/Button';
import { startBase, homePath, trainerHomePath, trainerConversionPathFor, clientInvitePath, mealPlanSetupPath, pdfSetupDonePath } from 'config/paths';
import { conversionPathFor } from 'redux/helpers';
import { Redirect } from "components/Routing";
import { formikHandlerCreator, isBlank } from 'lib/utilities';
import { SingleOptionSlider } from 'components/Form';
import { newEmailUser, advanceSignupFlow } from 'redux/actions';
import { signupFlowDataSelector, trainerRecordSelector, userRecordSelector } from 'redux/selectors'
import { connect } from 'react-redux';
import { InviteForm, SignupForm } from 'components/LoginForm';
import spinach1 from 'assets/img/spinach1.png';
import spinach2 from 'assets/img/spinach2.png';
import spinach3 from 'assets/img/spinach3.png';
import barbell from 'assets/img/barbell.png';
import { AgeHeightWeightFields, validateAgeHeightWeight } from 'partials/UserFields';
import { User } from 'lib/classes';
import loadingContainer from 'components/LoadingHOC';
import { TPAYWALL, TSIGNUP_CONTEXTS } from 'config/settings';
import classnames from 'classnames';
import * as _ from 'lib/utilities';

const validateForm = (t,page,values) => {
    switch(page) {
        case 1: {
            if(isBlank(values.weightGoal)) {
                return { weightGoal: 'required' }
            }
            return {};
        }
        case 2: {
            if(isBlank(values.gender)) {
                return { gender: 'required' }
            }
            return {};
        }
        case 3: {
            return validateAgeHeightWeight(values);
        }
        case 4: {
            if(isBlank(values.activityLevel)) {
                return { activityLevel: 'required' }
            }
            return {}
        }
        default: {
            return {};
        }
    }
}

const selectGoalCreator = (value,action,history,initialValues) => (e) => {
    action({ ...initialValues, weightGoal: value });
    history.push(`${startBase}/2`);
}

const GoalQuestion = ({ action, history, initialValues, user }) => {
    const { t } = useTranslation();
    const showGreen = (isBlank(user) || !user.isClient()) && _.isPrimaryDomain();
    return (
        <div className={classnames({ "green-gradient-bcg": showGreen })}>
            <FlowChooseGoal fullScreen>
                <FlowQuestionContainer>
                    <FlowQuestion text={t("What's your primary goal?")} color={classnames({ "white": showGreen })} className="mb0" />
                    <div className="inline-block">
                        <SmallCardOption 
                            onClick={selectGoalCreator(-1,action,history,initialValues)} 
                            title={t("Lose Fat")} 
                            subtitle={t("Get lean & lose weight")}
                            id='lose-fat-btn'
                            primary={!showGreen}
                        />
                        <SmallCardOption 
                            onClick={selectGoalCreator(0,action,history,initialValues)} 
                            title={t("Get Toned")} 
                            subtitle={t("Maintain your weight & get fit")}
                            id='tone-up-btn' 
                            primary={!showGreen}
                        />
                        <SmallCardOption 
                            onClick={selectGoalCreator(1,action,history,initialValues)} 
                            title={t("Build Muscle")} 
                            subtitle={t("Gain mass & strength")}
                            id='build-muscle-btn'
                            primary={!showGreen}
                        />
                    </div>
                </FlowQuestionContainer>
            </FlowChooseGoal>
        </div>
    )
}

const GenderQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FlowQuestion text={t("What is your gender?")} />
            <div className="no-wrap">
                <AutoAdvanceButton {...flowProps} attr="gender" value={false} render={({ onClick }) => (
                    <Button color="primary" rounded onClick={onClick} className="btn-wide" id="male-gender-btn"><span>{t("Male")}</span></Button>
                )} />

                <AutoAdvanceButton {...flowProps} attr="gender" value={true} render={({ onClick }) => (
                    <Button color="primary" rounded onClick={onClick} className="btn-wide ml5" id="female-gender-btn"><span>{t("Female")}</span></Button>
                )} />
            </div>
        </React.Fragment>
    )
}

const AgeHeightWeightQuestion = ({ flowProps, t }) => {
    const ageLabel = <FlowQuestion text={t("Age")} className="mb0" />;
    const heightLabel = <FlowQuestion text={t("Height")} className="mt20" />;
    const weightLabel = <FlowQuestion text={t("Weight")} className="mt20" />;

 
    return (
        <React.Fragment>
            
            <AgeHeightWeightFields 
                ageLabel={ageLabel} 
                heightLabel={heightLabel} 
                weightLabel={weightLabel}
                className="mb20"
                t={t}
                flowProps={flowProps}
            />
            
            <div className="text-center">
                <NextButton 
                    className="btn-wide mt20"
                    label={t('Next')} 
                    advance={flowProps.advance} 
                    validateForm={flowProps.validateForm} 
                    values={flowProps.values} 
                    isValid={flowProps.isValid}
                    setTouched={flowProps.setTouched}
                    id='next-btn'
                />
            </div>
            
        </React.Fragment>
    )
}

const FinalButtonLC = loadingContainer({
    "DEFAULT" : NextButton,
    "SUCCESS": NextButton
}, { type: 'padded' })

class ActivityLevelQuestion extends React.Component {

    render() {
        const { user, flowProps: { advance, values, validateForm, setTouched, setFieldValue, submitForm, values: { activityLevel } }, t, loadState } = this.props;
        const options = User.activityLevelCol(t,'slider');
        let finalAdvance = submitForm;
        if(user && user.isAppClient()) {
            finalAdvance = values => advance(values,false,clientInvitePath);
        } else if(process.env.REACT_APP_TEST === 'true') {
            finalAdvance = advance;
        }

        return (
            <React.Fragment>
                <FlowQuestion text={t("How active are you?")} className="mt0 pt0 mb20" />
                <SingleOptionSlider options={options} value={activityLevel} setValue={(val) => setFieldValue('activityLevel',val) } />
                <FinalButtonLC
                    loadState={loadState} 
                    className="btn-wide mt65"
                    label={t('Next')} 
                    advance={finalAdvance} 
                    validateForm={validateForm} 
                    values={values} 
                    isValid={true}
                    setTouched={setTouched} 
                    id='next-btn'
                />
            </React.Fragment>
        )
    }
}

const EmailQuestion = ({ initialValues, context, user, trainer }) => {

    const { t } = useTranslation();
    const redirectPage = getHighestValidPage((page,values) => validateForm(t,page,values),initialValues,5);
    const isInvite = (context === 'client' || context === 'trainer');
    const signedInAsClient = (trainer && !isInvite && (!user || user.id !== trainer.id));
    const isTrainerSignup = TSIGNUP_CONTEXTS.includes(context) || signedInAsClient;
    if(!isInvite && !isTrainerSignup && redirectPage < 5) {
        return (<Redirect to={`${startBase}/${redirectPage}`} />)
    } else {
        let successPath = homePath();
    
        if(isTrainerSignup) {
            context = signedInAsClient ? TPAYWALL : context;
            successPath = trainerConversionPathFor(context);
        } else if(context === 'trainer') {
            successPath = trainerHomePath;
        } else if(context === 'client') {
            successPath = user.afterClientInvitePath();
        } else {
            if(!isBlank(context)) {
                successPath = conversionPathFor(context,user);
            }
        }

        return (
            <div>
                <FlowNav />
                <FlowChooseGoal fullScreen>
                    <img src={spinach1} className="spinach-1 extra-small-screen-disappear" alt="" />
                    <img src={spinach3} className="spinach-3 extra-small-screen-disappear" alt="" />
                    <img src={spinach2} className="spinach-2 extra-small-screen-disappear" alt="" />
                    <img src={barbell} className="barbell extra-small-screen-disappear" alt="" />
                    <FlowQuestionContainer fixedBackground>
                        {isInvite && <InviteForm successPath={successPath} context={context} />}
                        {!isInvite && <SignupForm successPath={successPath} context={context} />} 
                    </FlowQuestionContainer>
                </FlowChooseGoal>
            </div>
        )
    }
}

class BasicProfileFlow extends Component {

    constructor(props) {
        super(props);
        this.state = { loadState: 'DEFAULT', errorMsg: null };
        this.setSubmitState = this.setSubmitState.bind(this);
    }

    setSubmitState(status,formikBag,data) {
        const { user, history } = this.props;
        if(status === 'SUCCESS') {
            if(user && user.isPdfClient() && _.isBlank(data.error)) {
                if(user.setupOwnMealPlanPrefs()) {
                    history.push(mealPlanSetupPath);
                } else {
                    history.push(pdfSetupDonePath);
                }
            } else {
                this.setState({ loadState: 'SUCCESS', errorMsg: data.error })
            }
        } else {
            this.setState({ loadState: status, errorMsg: null })
        }
    }

    render() {
        let { t, submit, advanceAction, initialValues, basePath, exitPath, startIndex, user } = this.props;

        return (
            <FlowForm 
                initialValues={initialValues}
                validate={validateForm.bind(this,t)}
                validateOnMount={true}
                advanceCallback={advanceAction}
                onSubmit={formikHandlerCreator(this,this.setSubmitState,submit)}
                basePath={basePath} 
                nextPage={(page,values) => (page+1)}
                exitPath={exitPath}
                startIndex={startIndex}>
                    <FlowPageContent key='gender' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <GenderQuestion flowProps={flowProps} t={t} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='ageHeightWeight' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer style={{textAlign: 'center'}}>
                                    <AgeHeightWeightQuestion flowProps={flowProps} t={t} />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent key='activityLevel' render={(flowProps) => {
                            return (
                                <FlowQuestionContainer style={{width: '100%'}}>
                                    <ActivityLevelQuestion 
                                        flowProps={flowProps} 
                                        t={t} 
                                        loadState={this.state.loadState} 
                                        user={user}
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
            </FlowForm>
        );
    }

    componentWillUnmount() {
        if(this.loadPromise){
            this.loadPromise.cancel();
        }
    }
}

const mapStateToProps = state => ({
    initialValues: signupFlowDataSelector(state),
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submit: (data) => dispatch(newEmailUser(data)),
    advanceAction: (data) => dispatch(advanceSignupFlow(data))
})

const goalQDispatchToProps = dispatch => ({
    action: (data) => dispatch(advanceSignupFlow(data))
})

const ConnectedGoalQuestion = connect(mapStateToProps,goalQDispatchToProps)(GoalQuestion)
const ConnectedEmailQuestion = connect(mapStateToProps)(EmailQuestion)

export { ConnectedGoalQuestion as GoalQuestion, ConnectedEmailQuestion as EmailQuestion }
export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(BasicProfileFlow));
