import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { modalPathFor, newTrainerModalPathFor, trainerHomePath, trainersPath } from 'config/paths';
import withTrainer from 'partials/TrainerLoader';
import { connect } from 'react-redux';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { MainHeading } from 'components/Typography';
import { useTranslation, withTranslation } from 'react-i18next';
import { AllCheck, GenericDashCard, GenericTitleRow } from 'partials/ClientCards';
import { reactivateTrainers, sendInvite, setClientFilters, setTrainertFilters } from 'redux/actions';
import { CheckButtons } from 'components/Form';
import { SplitButtonRow } from 'components/SplitButtonRow';
import * as _ from 'lib/utilities';
import { clientFiltersSelector, trainerFiltersSelector } from 'redux/selectors';
import { ReassignOptionDrop } from 'partials/ClientDropdowns';
import { EditTrainerModal, editTrainerSuffix, NewTrainerModal } from 'partials/EditTrainerModal';
import Button, { IconButton } from 'components/Button';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import classnames from 'classnames';
import { DropdownLinkTPB, FixedButtonTPB, LinkButtonTPB } from 'partials/ProRequiredButton';
import { DropdownItem, DropdownOption, DropdownTip, MultiLevelDropdown } from 'components/Dropdown';
import { CLEARED_FILTERS } from 'config/settings';
import { DeactivateModal, deactivateSuffix, ReassignModal } from 'partials/TrainerSelectModal';
import { TeamPageScroller } from 'partials/TrainerScroller';
import { ClientSearchButton } from 'partials/Utilities';

const metrics = ['Role','Assigned','Last Active'];
const getMetrics = isLarge => (isLarge ? metrics : _.filter(metrics,m => (m !== 'Last Active')));

const TrainerDropdown = ({ trainer, selTrainer, basePath, clientCounts }) => {

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: true, closeOnClick: true, closeOnChildClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <div className="dropdown-container clickable" id={`trainer-${selTrainer.id}-drop-btn`} ref={ref} data-target={target}>
                        <IconButton icon="ellipsis-v" size="2x" />
                    </div>
                )
            }}
        >
            <TrainerActions trainer={trainer} selectedTrainers={[selTrainer]} clientCounts={clientCounts} />
        </MultiLevelDropdown>
    )
}

let AssignedClientsLink = ({ currentTrainer, trainer, filters, setFilters, t, clientCount }) => {
    const history = useHistory();

    const onClick = () => {
        setFilters({ ...filters, ...CLEARED_FILTERS, trainerId: (trainer.id === currentTrainer.id) ? 'me' : trainer.id });
        history.push(trainerHomePath);
    }

    return (
        <div id={`view-clients-${trainer.id}-btn`} className={classnames({ "underline clickable": !trainer.isInactive() })} onClick={trainer.isInactive() ? null : onClick}>
            {trainer.assignedClientsStr(clientCount,t)}
        </div>
    )
}

const mapStateToCliLinkProps = state => ({
    filters: clientFiltersSelector(state)
})

const mapDispatchToCliLinkProps = dispatch => ({
    setFilters: filters => dispatch(setClientFilters(filters))
})

AssignedClientsLink = connect(mapStateToCliLinkProps,mapDispatchToCliLinkProps)(AssignedClientsLink);

const TrainerDashMetrics = ({ isLarge, clickLink, currentTrainer, trainer, t, clientCount }) => {
    const history = useHistory();
    const [daysAgo,fullStr] = trainer.lastActiveAtStr(t);

    return (
        <React.Fragment>
            {getMetrics(isLarge).map(metric => {
                return (
                    <div key={metric} className={classnames("metric", { "clickable": metric !== 'Assigned', "three": isLarge, "two": !isLarge })} onClick={metric === 'Assigned' ? null : (() => history.push(clickLink))}>
                        {metric === 'Role' && trainer.trainerRoleStr(t)}
                        {metric === 'Assigned' && (<AssignedClientsLink currentTrainer={currentTrainer} trainer={trainer} t={t} clientCount={clientCount} />)}
                        {metric === 'Last Active' && <span title={fullStr}>{daysAgo}</span>}
                    </div>
                )})}
        </React.Fragment>
    )
} 

const TrainerDashCard = ({ currentTrainer, trainer, selected, toggleHandler, t, basePath, clientCounts }) => {
    const { pathname } = useLocation();
    const clickLink = modalPathFor(editTrainerSuffix,pathname,{ trainerId: trainer.id });
    const cliIdsLoaded = Object.keys(clientCounts).length > 0;


    return (
        <GenericDashCard user={trainer} selected={selected} toggleHandler={toggleHandler} clickLink={clickLink}>
            <TrainerDashMetrics t={t} currentTrainer={currentTrainer} trainer={trainer} clickLink={clickLink} clientCount={cliIdsLoaded ? clientCounts[trainer.id] : null} />
            <TrainerDropdown trainer={currentTrainer} selTrainer={trainer} basePath={basePath} clientCounts={clientCounts} />
        </GenericDashCard>
    )
}

const RowTitles = ({ isLarge }) => {
    const  { t } = useTranslation();

    return (
        <React.Fragment>
            {getMetrics(isLarge).map(title => {
                return (
                    <div key={title} className={classnames("metric",{ three: isLarge, two: !isLarge})}>
                        <div className="metric-title">
                            {t(title)}
                        </div>
                    </div>
                )
            })}
        </React.Fragment>
    )
}

const TitleRow = ({ check }) => {

    return (
        <GenericTitleRow check={check}>
            <RowTitles />
        </GenericTitleRow>
    )
}

class TrainerCardList extends React.Component {

    render() {
        const { selected, setSelected, trainers, t, trainer: inTrainer, basePath, clientCounts } = this.props;

        const check = trainers.length === 0 ? null : (
            <AllCheck clients={trainers} selected={selected} setSelected={setSelected} />
        )
    
        return (
            <React.Fragment>
                <TitleRow check={check} />
                <CheckButtons 
                    values={{ selected }}
                    name='selected'
                    setFieldTouched={() => {}}
                    setFieldValue={(name,newVal) => setSelected(newVal)}
                    render={({ toggleHandler, values }) => {
                        return (
                            <React.Fragment>
                                {trainers.map(trainer => (
                                    <TrainerDashCard 
                                        basePath={basePath}
                                        trainer={trainer}
                                        clientCounts={clientCounts}
                                        key={trainer.id}
                                        currentTrainer={inTrainer}
                                        selected={values} 
                                        toggleHandler={toggleHandler} 
                                        t={t}
                                    />
                                ))}
                            </React.Fragment>
                        )
                    }}
                />
            </React.Fragment>
        )
    }
}

TrainerCardList = withTranslation()(TrainerCardList);

const TrainerListLoader = ({ trainer, selected, setSelected, comp, type, basePath, filters, clientCounts, setClientCounts }) => {

    return (
        <TeamPageScroller 
            trainer={trainer}
            filters={filters}
            clientCounts={clientCounts}
            setClientCounts={setClientCounts}
            context="teamPage"
            render={({ clientCounts, trainers }) => {

                return (
                    <TrainerCardList 
                        setSelected={setSelected}
                        selected={selected}
                        trainers={trainers}
                        trainer={trainer}
                        metricType={type}
                        basePath={basePath}
                        clientCounts={clientCounts}
                    />
                )
            }}
        />
    )
}

const TrainerFilterButton = ({ filters, setFilters, className, hidden }) => {
    const { t } = useTranslation();
    const isFlagged = filters.status !== 'active';
    const filterCount = isFlagged ? 1 : 0;

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: true, closeOnClick: true, alignment: 'right' } }
            triggerRender={({ ref, target }) => {
                return (
                    <div className={classnames("position-relative inline-block", { hidden })}>
                        {filterCount > 0 && (<span className="notification-icon blue for-btn">{filterCount}</span>)}
                        <Button noShadow outlined color="primary" variant="icon-only" id="trainer-filters-btn" className={className} ref={ref} data-target={target}>
                            <FontAwesomeIcon icon={['far','filter']}></FontAwesomeIcon>
                        </Button>
                    </div>
                )
            }}
        >
            {[{ text: t('Active'), value: 'active' }, { text: t('Deactivated'), value: 'inactive'} ].map(({ text, value }) => (
                    <DropdownOption 
                        key={value}
                        label={text} 
                        value={value} 
                        name='status' 
                        values={filters} 
                        setValues={setFilters} 
                        nullVal='active'
                        id={`${value}-filter-btn`}
                    />
            ))}
        </MultiLevelDropdown>                      
    )
}

let TrainerActions = ({ trainer, selectedTrainers, reactivate, sendInvite, isMass, clientCounts }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const selClientCount = _.reduce(selectedTrainers.map(t => clientCounts[t.id]),(sum,n) => (sum+n),0);
    const selTrainer = selectedTrainers[0];
    const deactivated = _.some(selectedTrainers,t => t.isInactive());

    if(deactivated) {
        return (
            <DropdownItem id="reactivate-btn" icon="undo" label={t('Reactivate')} onClick={() => reactivate({ trainerIds: selectedTrainers.map(t => t.id)})} />
        )
    }

    return (
        <React.Fragment>
            {!isMass && (<DropdownLinkTPB 
                type={null} 
                id="edit-btn" 
                label={t("Edit")} 
                icon={["far","edit"]} 
                to={modalPathFor(editTrainerSuffix,pathname, { trainerId: selTrainer.id })} 
            />)}
            {selClientCount > 0 && (<ReassignOptionDrop 
                trainerIds={selectedTrainers.map(t => t.id)}
                label={t('Reassign All Clients')} 
            />)}
            {!isMass && !selTrainer.inviteClaimed() && !selTrainer.isMaster() && (
                <DropdownItem 
                    id="send-invite-btn" 
                    icon="at" 
                    label={selTrainer.inviteSent() ? t('Resend Invitation Email') : t("Send Invitation Email")} 
                    onClick={() => sendInvite(selTrainer.id)} 
                />
            )}
            {((isMass && selectedTrainers.length > 0) || !selTrainer.isMaster()) && (<DropdownLinkTPB 
                type={null} 
                id="deactivate-btn" 
                label={t("Deactivate")} 
                icon="times" 
                to={modalPathFor(deactivateSuffix,pathname, { ids: selectedTrainers.map(t => t.id).join('_')})} 
            />)}
        </React.Fragment>
    )
}

const mapDispatchToActionProps = dispatch => ({
    reactivate: data => dispatch(reactivateTrainers(data)),
    sendInvite: (id) => dispatch(sendInvite({ id })),
})

TrainerActions = connect(null,mapDispatchToActionProps)(TrainerActions);

const TrainerActionsButton = ({ trainer, selected: inSel, className, clientCounts }) => {
    const { t } = useTranslation();
    const selected = trainer.trainersByIds(inSel);

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: true, closeOnClick: true, closeOnChildClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <Button noShadow outlined color="primary" variant="icon-only" id="trainer-actions-btn" className={className} ref={ref} data-target={target}>
                        <FontAwesomeIcon icon={'ellipsis-v'}></FontAwesomeIcon>
                    </Button>
                )
            }}
        >
            {selected.length === 0 && (
                <DropdownTip text={t("select clients tip", { type: t('team member')})} id="select-tm-first-tip" />
            )}
            {selected.length > 0 && (<TrainerActions selectedTrainers={selected} trainer={trainer} isMass clientCounts={clientCounts} />)}
        </MultiLevelDropdown>                      
    )
}

let TrainerControls = ({ trainer, filters, setFilters, selected, basePath, clientCounts }) => {
    const { t } = useTranslation();
    const finalFilters = filters;
    const [searchIsFocused,setSearchIsFocused] = useState(!_.isBlank(filters.query));
    const hideNonSearch = searchIsFocused || !_.isBlank(filters.query);

    return (
        <SplitButtonRow 
            className={"mb10"}
            leftClasses="valign-wrapper"
            leftChildren={[
                <LinkButtonTPB
                    type={null}
                    key="new-client-btn"
                    to={newTrainerModalPathFor(basePath)}
                    rounded
                    color="primary"
                    className="hide-on-med-and-down"
                    noShadow
                    allowUninit={false}
                >
                    <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span>{t("New")}</span>
                </LinkButtonTPB>,
                <TrainerFilterButton 
                    key="trainer-filter-btn"
                    trainer={trainer} 
                    filters={finalFilters} 
                    setFilters={setFilters} 
                    hidden={hideNonSearch}
                    className={"ml5"} 
                />,
                <ClientSearchButton 
                    key="search-btn"
                    toggleFocus={val => setSearchIsFocused(val)}
                    topQuery={finalFilters.query}
                    setTopQuery={query => setFilters({...filters, query })}
                    status={finalFilters.status}
                    showAll
                    overrideLabel={'Search all team members'}
                />,
                <TrainerActionsButton 
                    key="trainer-actions-btn"
                    trainer={trainer} 
                    selected={selected} 
                    className={classnames("ml5", { "hidden": hideNonSearch })} 
                    basePath={basePath}
                    clientCounts={clientCounts}
                />
            ]}
            rightChildren={[]}
        />
    )
}

const TeamControlsAndList = ({ trainer, filters, setFilters, load, comp, type, basePath, clientCounts, setClientCounts }) => {
    const history = useHistory();
    const [selected,setSelected] = useState([]);
    const props = { trainer, filters, load, selected, setSelected, comp, basePath, type, clientCounts, setClientCounts };


    return (
        <div className="page-height-cont">
            <TrainerControls 
                trainer={trainer} 
                selected={selected} 
                filters={filters} 
                setFilters={setFilters} 
                basePath={basePath}
                clientCounts={clientCounts}
            />
            <TrainerListLoader {...props} />
            <FixedButtonTPB id="new-trainer-btn" icon="plus" className="hide-on-large-only" onClick={() => history.push(newTrainerModalPathFor(basePath))} allowUninit={false} />
        </div>
    )
}

class TrainerMgmt extends Component {

    constructor(props) {
        super(props);

        this.state = { clientCounts: {} }
    }

    render() {
        const { trainer, filters, setFilters, t, isLarge, showTabs, location } = this.props;
        const { clientCounts } = this.state;
        const basePath = trainersPath;

        return (
            <CenteredActivityContainer wide>
                <MainHeading>{t('Team Members')}</MainHeading>
                <TeamControlsAndList 
                    trainer={trainer}
                    filters={{ ...filters, showAllOnTextSearch: true }}
                    setFilters={setFilters}
                    comp={TrainerCardList}
                    isLarge={isLarge}
                    showTabs={showTabs}
                    basePath={basePath}
                    clientCounts={clientCounts}
                    setClientCounts={this.setClientCounts}
                />
                <NewTrainerModal trainer={trainer} baseMatch={basePath} />
                <EditTrainerModal trainer={trainer} />
                <DeactivateModal trainer={trainer} location={location} />
                <ReassignModal trainer={trainer} />
                {!trainer.isTrainerAdmin() && (<Redirect to={trainerHomePath} />)}
            </CenteredActivityContainer>
        );
    }

    setClientCounts = (clientCounts) => this.setState({ clientCounts });
}

const mapStateToProps = state => ({
    filters: trainerFiltersSelector(state)
})

const mapDispatchToProps = dispatch => ({
    setFilters: filters => dispatch(setTrainertFilters(filters))
})


export default connect(mapStateToProps,mapDispatchToProps)(withTrainer(false)(withTranslation()(TrainerMgmt)));
