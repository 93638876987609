import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import moment from 'moment';


export class Chat extends RecordBase {
    static NAME = 'Chat';
    static ASSOCS = {
        memberships: { type: 'hasMany', tableName: 'chatMemberships' },
        messages: { type: 'hasMany', tableName: 'chatMessages', sortAttr: 'sentAtTimestamp' },
        events: { type: 'hasMany', tableName: 'chatEvents', sortAttr: 'sentAtTimestamp' }
    }

    owner() {
        const m = _.find(this.memberships, m => m.role === 'owner');
        return m && m.user;
    }

    members() {
        return _.filter(this.memberships,m => m.active()).map(membership => membership.user);
    }

    isArchived() {
        return this.archived;
    }

    lastMessage() {
        return this.messages[this.messages.length-1];
    }

    messagesAndEvents() {
        return _.sortBy([ ...this.messages, ...this.events ], em => em.sentAtTimestamp());
    }

    lastActiveMom() {
        return moment(this.lastSentAt);
    }

    lastActiveTstamp() {
        return this.lastActiveMom().valueOf();
    }

    lastActiveStr(t) {
        const mom = this.lastActiveMom();
        if(mom) {
            return _.formatTimePassed(mom,t);
        }

        return '';
    }

    otherMembers(curUser) {
        return _.compact(_.filter(this.memberships, m => (m.active() && m.userId !== curUser.id)).map(m => m.user));
    }

    displayName(user,t) {
        if(!_.isBlank(this.name)) {
            return this.name;
        }

        const otherMembers = this.otherMembers(user);
        if(otherMembers.length === 1) {
            return otherMembers[0].fullName();
        }

        return t('Untitled')
    }

    bubbleGroups(firstUnreadMsg) {
        const senderGroups = [];
        const finalGroups = [];
        let curGroup = null;
        let hitMsg = false;
        this.messagesAndEvents().forEach(msgOrEvt => {
            const isFirstUnread = msgOrEvt.isMessage() && firstUnreadMsg && firstUnreadMsg.id === msgOrEvt.id;
            if(!curGroup || (curGroup.messages && msgOrEvt.isEvent()) || curGroup.sender.id !== msgOrEvt.sender.id || isFirstUnread) {
                if(msgOrEvt.isEvent()) {
                    curGroup = null;
                    senderGroups.push({ event: msgOrEvt });
                } else {
                    if(isFirstUnread && (hitMsg || !this.topDone)) {
                        curGroup = { unreadMarker: true };
                        senderGroups.push(curGroup);
                    }
                    hitMsg = true;
                    curGroup = { sender: msgOrEvt.sender, messages: [msgOrEvt] };
                    senderGroups.push(curGroup);
                }
            } else {
                curGroup.messages.push(msgOrEvt);
            }
        })

        let lastDateBreak = null;

        senderGroups.forEach(group => {
            if(group.event || group.unreadMarker) {
                finalGroups.push(group);
            } else {
                let curChunk = null;
                let lastSentAt = null;
                group.messages.forEach(msg => {
                    if(!lastSentAt || lastSentAt.isSame(msg.sentAt(),'day')) {
                        const paddingCutoff = lastSentAt && lastSentAt.clone().add(10,'minutes')
                        let msgInfo;
                        if(lastSentAt && msg.sentAt().isAfter(paddingCutoff)) {
                            msgInfo = { msg, extraTopPadding: true }
                        } else {
                            msgInfo = { msg };
                        }
                        lastSentAt = msg.sentAt();
                        if(!curChunk) {
                            if(!lastDateBreak || !lastDateBreak.isSame(msg.sentAt(),'day')) {
                                lastDateBreak = msg.sentAt();
                                finalGroups.push({ dateBreak: msg.sentAt() });
                            }
                            curChunk = { sender: group.sender, messageInfos: [{ msg }] }
                            finalGroups.push(curChunk);
                        } else {
                            curChunk.messageInfos.push(msgInfo);
                        }
                    } else {
                        if(!lastDateBreak || !lastDateBreak.isSame(msg.sentAt(),'day')) {
                            lastDateBreak = msg.sentAt();
                            finalGroups.push({ dateBreak: msg.sentAt() });
                        }
                        lastSentAt = msg.sentAt();
                        curChunk = { sender: group.sender, messageInfos: [{ msg }] };
                        finalGroups.push(curChunk);
                    }
                })
            }
        })

        return finalGroups;
    }

    loadParams(dir) {
        const cutoffId = dir === 'bottom' ? this.bottomCutoff : this.topCutoff;
        return { chatId: this.id, cutoffId };
    }

    failedMessages() {
        return _.filter(this.messages,m => m.failedToSend());
    }

    isGroup() {
        return this.chatType === 'group';
    }

    isSingle() {
        return this.chatType === 'single';
    }

    membershipFor(user) {
        return _.find(this.memberships, m => m.userId === user.id)
    }

    isMuted(user) {
        return this.membershipFor(user).muted;
    }

    receivedMessages(user) {
        return _.filter(this.messages,m => (m.senderId !== user.id));
    }

    unreadMessages(user) {
        const mem = this.membershipFor(user);
        const received = this.receivedMessages(user);
        if(mem.lastReadId) {
            return _.filter(received,m => (m.id > mem.lastReadId));
        }

        return received;
    }

    scrolleventMessages(user) {
        const mem = this.membershipFor(user);
        if(mem.lastReadId) {
            return _.filter(this.messages,m => (m.id >= mem.lastReadId));
        }

        return this.messages;
    }

    messageById(id) {
        const fn = _.isNumeric(id) ? (m => (m.id === Number(id))) : (m => (m.tempKey === id));
        return _.find(this.messages,fn);
    }

    imgSrcFor(msgId) {
        const msg = this.messageById(msgId);
        return msg.getImageSrc();
    }

}

registerInflection('chat','chats',Chat);

export class ChatMembership extends RecordBase {
    static NAME = 'ChatMembership';
    static ASSOCS = {
        chat: { type: 'belongsTo', inverse: 'memberships' },
        user: { type: 'belongsTo' }
    }

    active() {
        return !this.inactive;
    }
}

registerInflection('chatMembership','chatMemberships',ChatMembership);

export class ChatMessage extends RecordBase {
    static NAME = 'ChatMessage';
    static ASSOCS = {
        chat: { type: 'belongsTo', inverse: 'messages' },
        sender: { type: 'belongsTo', tableName: 'users' },
        responseTo: { type: 'belongsTo', poly: true }
    }
    static LINK_REGEX = /((?:(?:https?:\/\/)|(?:www\.))[^\s]+)/g

    sentAt() {
        if(this.sentAtMom) {
            return this.sentAtMom;
        }
        return (this.sentAtMom = moment(this.createdAt));
    }

    sentAtTimestamp() {
        return this.sentAt().valueOf();
    }

    lines() {
        const raw = (this.body || '').split(/\r\n|\n|\r/);
        const withLinks = raw.map(line => line.split(ChatMessage.LINK_REGEX))
        return withLinks;
    }

    getImageSrc() {
        if(this.isTemp()) {
            return this.image || null;
        } else {
            return (this.image && this.image.url);
        }
    }

    isTemp() {
        return _.isBlank(this.id);
    }

    failedToSend() {
        if(this.isTemp()) {
            return (this.failed || moment.unix(this.attemptedSendAt).isBefore(moment().subtract(15,'seconds')))
        }
        return false;
    }

    isEvent() {
        return false;
    }

    isMessage() {
        return true;
    }

    resolvedId() {
        if(this.isTemp()) {
            return this.tempKey;
        }

        return this.id;
    }

    hasImage() {
        return !_.isBlank(this.getImageSrc());
    }

    summary(t) {
        if(this.hasImage()) {
            return t('Sent an image');
        }

        return this.body;
    }

    //TODO
    chatMsgReplyImg() {
        return null;
    }

    chatMsgReplyIcon() {
        return this.cardIcon(false);
    }

    chatMsgReplyTitle(t) {
        return this.resolvedName(t);
    }

    chatMsgReplySubtitle(t) {
        return this.assignedDateStr();
    }

    chatMsgClickLink(viewer) {

    }
}

registerInflection('chatMessage','chatMessages',ChatMessage);


export class ChatEvent extends RecordBase {
    static NAME = 'ChatEvent';
    static ASSOCS = {
        chat: { type: 'belongsTo', inverse: 'events' }
    }

    sentAt() {
        if(this.sentAtMom) {
            return this.sentAtMom;
        }
        return (this.sentAtMom = moment(this.createdAt));
    }

    sentAtTimestamp() {
        return this.sentAt().valueOf();
    }

    message(t) {
        const strs = [];
        if(this.removed) {
            strs.push(t('removed from chat',{ names: _.listToSentence(this.removed)}))
        }

        if(this.added) {
            strs.push(t('added to chat',{ names: _.listToSentence(this.added)}))
        }

        if(this.archived) {
            strs.push(t('chat archived'))
        }

        if(this.unarchived) {
            strs.push(t('chat unarchived'))
        }

        strs.push(`- ${this.sentAt().format("dddd, MMMM Do YYYY, h:mm a")}`)

        return strs.join(' ')
    }

    isEvent() {
        return true;
    }

    isMessage() {
        return false;
    }

}

registerInflection('chatEvent','chatEvents',ChatEvent);
