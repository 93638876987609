import React from 'react';
import { allMainMatches, clientDetailsMatch, viewAssignedFormMatch, viewClientAssignedFormMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { chatUserSelector } from 'redux/selectors';
import Chat, { ChatMenu } from 'partials/Chat';
import { connect } from 'react-redux';
import { SimpleNavRightButton } from 'components/Nav';

const transitionMap = {
    rules: [
        [[...allMainMatches,clientDetailsMatch],transitions.slideOut],
        [[viewClientAssignedFormMatch,viewAssignedFormMatch],transitions.slideOver]
    ]
};
class ChatPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, user, match: { params: { chatId } } } = this.props;
        const chat = user.chatById(chatId);

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={chat ? chat.displayName(user,t) : t('Messaging')}
                noScrollPadding
                containerClass={'min-padding'}
                white
                navChildren={chat && (
                    <ChatMenu 
                        user={user}
                        chat={chat}
                        triggerRender={({ ref, target }) => {
                            return (
                                <SimpleNavRightButton 
                                    ref={ref} 
                                    data-target={target} 
                                    icon="ellipsis-v" 
                                    className="pr20" 
                                />
                            )
                        }}
                    />
                )}
            >
                <CenteredActivityContainer className="ma0">
                    <Chat chatId={chatId} user={user} />
                </CenteredActivityContainer>
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = (state) => ({
    user: chatUserSelector(state)
})


export default withTranslation()(connect(mapStateToProps)(ChatPage));