import { MultiToggleButton } from 'components/Form';
import { FullFormModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { tagClientsModalMatchFor } from 'config/paths';
import * as _ from 'lib/utilities';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { tagClients } from 'redux/actions';
import TagsInput from 'react-tagsinput';
import 'react-tagsinput/react-tagsinput.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


let TagModal = ({ trainer, basePath, tagClients }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal 
            fullWidth 
            path={tagClientsModalMatchFor(basePath)} 
            scrollable 
            exact 
            icon={['far','tags']}
            title={t("Tag Clients")}
            formProps={ ({ modalProps: { match: { params: { ids } } } }) => ({
                initialValues: { clientIds: ids.split('_'), tags: [''], removeTags: [''] },
                submit: (values) => tagClients(values),
                validationSchema: Yup.object().shape({
                    clientIds: Yup.array().compact().required().min(1)
                }),
                initialErrors: { tags: 'required' }
            })}
            footerProps={{ id: 'save-tags-btn' }}
            render={({ modalProps: { match: { params: { ids: idsStr } }}, setValues, setFieldValue, values, values: { tags, removeTags }, ...formikProps }) => {
                const ids = idsStr.split('_');
                const formTags = tags;
                const clients = trainer.clientsByIds(ids);
                const allTags = trainer.allTags();
                const tagsMatchAll = trainer.clientTagsForAll(clients);
                const tagsMatchAny = trainer.clientTagsForAny(clients);
                const tagsMatchSome = _.difference(tagsMatchAny,tagsMatchAll);

                const clickHandler = tag => {
                    if(tagsMatchSome.includes(tag)) {
                        if(tags.includes(tag)) {
                            setValues({ ...values, removeTags: _.without(removeTags,tag), tags: _.without(tags,tag) })
                        } else if(removeTags.includes(tag)) {
                            setValues({ ...values, removeTags: _.without(removeTags,tag), tags: [ ...tags, tag ] })
                        } else {
                            setValues({ ...values, removeTags: [ ...removeTags, tag ]});
                        }
                    } else if(tagsMatchAll.includes(tag)) {
                        if(removeTags.includes(tag)) {
                            setValues({ ...values, removeTags: _.without(removeTags,tag) })
                        } else {
                            setValues({ ...values, removeTags: [ ...removeTags, tag ] })
                        }
                    } else {
                        if(tags.includes(tag)) {
                            setValues({ ...values, tags: _.without(tags,tag) })
                        } else {
                            setValues({ ...values, tags: [ ...tags, tag ] })
                        }
                    }
                }

                const getActiveType = tag => {
                    if(tags.includes(tag)) {
                        return 1;
                    } else if(removeTags.includes(tag)) {
                        return 0;
                    } else if(tagsMatchSome.includes(tag)) {
                        return 2;
                    } else if(tagsMatchAll.includes(tag)) {
                        return 1
                    }
                    return 0;
                }

                return (
                    <div className="pa10" id="tag-clients-modal">
                        <div className="mb10">
                            <SlightEmphasisIconNote text={t("tags modal tip", { client_list: clients.map(client => client.shortName()).join(', ') })} />
                        </div>
                        <div className="mb20">
                            {allTags.length > 0 && (<div className="mb15">
                                <div className="font-grey">{t('Tags')}</div>
                                {allTags.map(tag => {
                                    const activeType = getActiveType(tag);
                                    let icon = 'plus';
                                    if(activeType === 1) {
                                        icon = 'times';
                                    } else if(activeType === 2) {
                                        icon = 'minus';
                                    }
                                    return (
                                        <MultiToggleButton 
                                            key={tag}
                                            toggleHandler={clickHandler} 
                                            activeType={activeType} 
                                            value={tag} 
                                            className='ma2 btn-no-shadow no-capital'
                                            id={`${tag}-toggle-btn`}
                                        >
                                            <FontAwesomeIcon icon={icon} />
                                            {tag}
                                        </MultiToggleButton>
                                    )
                                })}
                            </div>)}

                            <div className="font-grey">{t('Add Tags')}</div>
                            <TagsInput
                                addOnBlur
                                value={_.without(tags,'',...allTags).slice(0,25)} 
                                onChange={(tags) => setFieldValue('tags',_.arrayForForm(_.uniqBy([...tags, ..._.intersectionBy(formTags,allTags,ttag => ttag.toLowerCase())],tag => tag.toLowerCase())))}
                                inputProps={{
                                    className: 'react-tagsinput-input',
                                    placeholder: t('tags input tip'),
                                    name: 'tags'
                                }}
                            />
                        </div>
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToTagProps = dispatch => ({
    tagClients: (params) => dispatch(tagClients(params))
})

TagModal = connect(null,mapDispatchToTagProps)(TagModal);

export { TagModal }