import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer, SimpleNavRightButton } from 'components/Nav';
import { withTranslation, useTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, progressionPickMatch, workoutPreviewMatch, editRoutinePath, editRoutineStandaloneMatch, exerciseInfoModalPathFor, progressionShowPathFor, editWorkoutTemplatePathFor, editExerciseGroupMatch, editWorkoutTemplateMatch, editExerciseGroupPathFor, etSettingsModalPathFor, progressionShowMatch, stReferenceWeightModalPathFor, swapExerciseTemplatePathFor, swapExerciseTemplateMatch, addExercisesMatch, addExercisesToGroupMatch, allMainMatches } from 'config/paths';
import { mapStateToStandaloneProps, mapDispatchToProps, RoutineUserReconciler } from 'views/EditRoutine';
import { connect } from 'react-redux';
import { Loader } from 'components/LoadingHOC';
import { Link, useHistory } from 'react-router-dom';
import { Redirect } from "components/Routing";
import FormikWrapper from 'components/FormikWrapper';
import { CardForm, InputWithErrors, Checkbox, formikAutoSubmitTextProps, CheckboxCore } from 'components/Form';
import { updateWorkoutTemplate, updateWtChildOrder, updateSetTemplate, addSetTemplate, destroySetTemplate, destroyWtc } from 'redux/actions';
import * as _ from 'lib/utilities';
import TextInput, { SelectInput, NumberCell, SelectCell, TextCell, TimeField, TelCell } from 'components/TextInput';
import { warmupOptionsSelector, userRecordSelector, workoutImageCatSelector, preRoutineEditorPath } from 'redux/selectors';
import { WorkoutTemplate, WorkoutTemplateChild, ExerciseTemplate, SetTemplate } from 'lib/classes';
import { IconTip } from 'components/Tooltipped';
import Dropdown from 'components/Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card';
import classnames from 'classnames';
import { ExerciseInfoModal } from 'partials/ExerciseModals';
import requestStateHandler from 'components/RequestStateHandler';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import { ConfirmActionButton, DefaultAsyncActionButton, BottomButtons } from 'components/Button';
import { ExerciseTemplateSettingsModal, ReferenceWeightModal } from 'partials/ExerciseTemplateModal';
import { DestroyableCollection } from 'components/DestroyableCollection';
import LinkButton from 'components/LinkButton';
import AddExerciseTabs from 'partials/AddExerciseTabs';
import { SectionTabs, SectionTab } from 'components/BottomNav';
import { resolvedHomePath } from 'redux/helpers';
import { ExerciseVideo } from 'partials/ExerciseImage';

const SetTemplateField = ({ setTemplate, param, values, errors, touched, handleChange, handleBlur, handleAutoSubmitChange, handleAutoSubmitBlur }) => {
    const { t } = useTranslation();
    const freeTextProps = { name: param, error: (errors[param] && touched[param]), onChange: handleChange, onBlur: handleAutoSubmitBlur, value: values[param] }
    const selectProps = { name: param, browserDefault: true, value: values[param], onChange: handleAutoSubmitChange, onBlur: handleBlur };

    switch(param) {
        case 'reps': {
            const regex = /[0-9-]+/;
            return (
                <TelCell
                    {...freeTextProps}
                    limitPattern={regex}
                />
            )
        }
        case 'kgWeight':
        case 'weight': {
            const regex = setTemplate.hasBaseWeight() ? /[0-9-.]+/ : /[0-9.]+/;
            return (
                <NumberCell
                    {...freeTextProps}
                    limitPattern={regex} 
                />
            )
        }
        case 'targetPace': {
            return (
                <SelectCell
                    {...selectProps} 
                    collection={ExerciseTemplate.cardioRpeCol(t)} 
                />
            )
        }
        case 'targetRpe': {
            return (
                <SelectCell
                    {...selectProps} 
                    collection={ExerciseTemplate.targetRpeCol(t)} 
                />
            )
        }
        case 'repMaxPercent':
        case 'kmDistance': 
        case 'mileDistance': {
            const regex = /[0-9.]+/;
            return (
                <NumberCell
                    {...freeTextProps}
                    limitPattern={regex}
                />
            )
        }
        case 'arbitraryMeasure': {
            return (
                <TextCell {...freeTextProps} />
            )
        }
        case 'count':
        case 'distance': {
            const regex = /[0-9]+/;
            return (
                <NumberCell
                    {...freeTextProps}
                    limitPattern={regex}
                />
            )
        }
        case 'minSecRestTime':
        case 'minSecTime':
        case 'isoTime': {
            return (
                <TimeField 
                    component={TelCell}
                    allowRange={param === 'isoTime'}
                    {...freeTextProps}
                />
            )
        }
        case 'optional':
        case 'amrap': {
            return (
                <CheckboxCore 
                    filled 
                    inputProps={{ value: true, checked: values[param], onChange: handleAutoSubmitChange, name: param }} 
                    middle 
                    className="less-padding" 
                />
            )
        }
        case 'experienceLevel': {
            return (
                <SelectCell
                    {...selectProps} 
                    collection={ExerciseTemplate.expLevelCol} 
                />
            )
        }
        default: {
            return ''
        }
    }
}

let SetTemplateRow = ({ setTemplate, params, updateSetTemplate, user, destroy, allowDestroy, basePath }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const destroyHandler = () => {
        destroy(new SetTemplate({ ...setTemplate, destroyed: true}));
    }

    if(setTemplate.destroyed) {
        return '';
    } else {
        return (
            <FormikWrapper 
                key={setTemplate.setKey(user)}
                initialValues={ setTemplate.formValues(user) }
                validate={(values) => setTemplate.update(values,true,t)}
                submit={(values) => {
                    setTemplate.update(values);
                    return updateSetTemplate(setTemplate,user);
                }}
                initialErrors={{}}
                autoSubmit
                render={({ handleSubmit, submitState, ...rest }) => {
                    const entries = Object.entries(params)
                    return (
                        <form className="spreadsheet-row" onSubmit={handleSubmit} id={`st-${setTemplate.id}-form`}>
                            {entries.map(([param,title]) => {
                                if(param === 'referenceTemplateId') {
                                    return (
                                        <div className="spreadsheet-cell clickable" id={`ref-weight-modal-btn-${setTemplate.id}`} key={param} onClick={() => history.push(stReferenceWeightModalPathFor(basePath,setTemplate.id))}>
                                            <FontAwesomeIcon icon={["far","edit"]} />
                                        </div>
                                    )
                                } else {
                                    const classNames = classnames("spreadsheet-cell", { "wol-checkbox": (param === 'optional')});
                                    const style = ['targetPace','targetRpe','experienceLevel'].includes(param) ? { maxWidth: `${100/entries.length}vw`} : {}
                                    return (
                                        <div className={classNames} key={param} style={style}>
                                            <SetTemplateField 
                                                setTemplate={setTemplate}
                                                param={param}
                                                {...rest}
                                            />
                                        </div>
                                    )
                                }
                            })}
                            {allowDestroy && (
                                <ConfirmActionButton onClick={destroyHandler} render={( { onClick, ready }) => (
                                        <div className="spreadsheet-cell icon-cell destroy-btn" key={'destroy'} onClick={onClick}>
                                            <FontAwesomeIcon icon='times' color={ready ? 'red' : null} />
                                        </div>
                                )} />
                            )}
                        </form>
                    )
                }}
            />
        )
    }
}

SetTemplateRow = requestStateHandler({ destroy: 'setTemplate'})(SetTemplateRow)

const SpreadsheetHeaderRow = ({ mappedParams, allowDestroy }) => {

    return (
        <div className="spreadsheet-head">
            {Object.entries(mappedParams).map(([attr,title]) => (
                <div className="spreadsheet-head-cell" key={attr}>
                    {title}
                </div>
            ))}
            {allowDestroy && <div className="spreadsheet-head-cell" key="destroy"></div>}
        </div>
    )
}

const ExerciseTemplateSpreadsheet = ({ exerciseTemplate, updateSetTemplate, destroy, allowDestroy, user, basePath }) => {
    const { t } = useTranslation();
    const params = exerciseTemplate.mappedTemplateParams(t,user)

    return (
        <div className="spreadsheet-form">
            <SpreadsheetHeaderRow 
                mappedParams={params}
                allowDestroy={allowDestroy}
            />
            {exerciseTemplate.setTemplates.map(setTemplate => (
                <SetTemplateRow 
                    basePath={basePath}
                    key={setTemplate.id}
                    setTemplate={setTemplate}
                    user={user}
                    destroy={destroy}
                    allowDestroy={allowDestroy}
                    params={params}
                    updateSetTemplate={updateSetTemplate}
                />
            ))}
        </div>
    )
}

export { ExerciseTemplateSpreadsheet }

const ExerciseTemplateHeader = ({ exerciseTemplate, basePath, destroyWtc, dragHandleProps }) => {
    const { t } = useTranslation();

    return (
        <div className="display-flex wol-exercise-container" id={`et-card-header-${exerciseTemplate.id}`}>
            <div className="wol-right-icon mr0" {...dragHandleProps}>
                <FontAwesomeIcon icon="arrows-alt-v" />
            </div>
            <div className="wol-exercise-title abbr-text grow ml0" id={`et-${exerciseTemplate.id}-title`}>
                <span>{exerciseTemplate.exercisableName(t)}</span>
                {exerciseTemplate.hasProgressionOverrides() && (<IconTip size='1x' icon="info-circle" className="font-grey" msg={t("progression override tip")}/>)}
            </div>
            <Dropdown
                contentComp='ul'
                options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                triggerRender={({ ref, target }) => {
                    return (
                            <div className="wol-right-icon mr0" ref={ref} data-target={target} id={`et-dropdown-btn-${exerciseTemplate.id}`}>
                                <FontAwesomeIcon icon="ellipsis-h" />
                            </div>
                    )
                }}
                contentRender={({ recalcDims }) => {

                    const destroyOption = (
                        <ConfirmActionButton 
                            onClick={() => destroyWtc()}
                            render={({ onClick, ready, clickRef }) => (
                                <li onClick={onClick} ref={clickRef} className={ready ? null : 'dont-close-dropdown'} id={`et-destroy-btn-${exerciseTemplate.id}`}>
                                    <span className={ready ? 'red-text' : null}>
                                        <FontAwesomeIcon icon="times" /> {t("Delete")}
                                    </span>
                                </li>
                            )}
                        />
                    )

                    if(exerciseTemplate.isVideoOnly()) {
                        return destroyOption;
                    }

                    return (
                        <React.Fragment>
                            <li>
                                <Link to={swapExerciseTemplatePathFor(exerciseTemplate.getActionParams())} id={`et-swap-btn-${exerciseTemplate.id}`}>
                                    <FontAwesomeIcon icon="exchange-alt" /> {t("Swap Out")}
                                </Link>
                            </li>
                            <li>
                                <Link to={etSettingsModalPathFor(basePath,exerciseTemplate.id)} id={`et-settings-btn-${exerciseTemplate.id}`}>
                                    <FontAwesomeIcon icon="cog" /> {t("Settings")}
                                </Link>
                            </li>
                            {!exerciseTemplate.isProgression() && (<li>
                                <Link to={exerciseInfoModalPathFor(basePath,exerciseTemplate.exercisableId)} id={`et-exercise-info-btn-${exerciseTemplate.id}`}>
                                    <FontAwesomeIcon icon="info-circle" /> {t("Exercise Info")}
                                </Link>
                            </li>)}
                            {exerciseTemplate.isProgression() && (<li>
                                <Link to={progressionShowPathFor(exerciseTemplate.exercisableId)} id={`et-exercise-info-btn-${exerciseTemplate.id}`}>
                                    <FontAwesomeIcon icon="info-circle" /> {t("Progression Info")}
                                </Link>
                            </li>)}
                            {destroyOption}
                        </React.Fragment>
                    )
                }}
            />
        </div>
    )
}

const ExerciseTemplateCard = ({ exerciseTemplate, basePath, destroyWtc, updateSetTemplate, addSetTemplate, destroySetTemplate, dragHandleProps, draggableRef, user, ...draggableProps }) => {
    const classNames = classnames("workout-log wtc-card",{ "mt20": !exerciseTemplate.isSupersetChild() })
    const { t } = useTranslation();

    return (
        <div {...draggableProps} ref={draggableRef}>
            <DestroyableCollection 
                destroy={destroySetTemplate}
                collection={exerciseTemplate.setTemplates}
                render={({ destroy, allowDestroy }) => (
                    <Card className={classNames} id={`et-card-${exerciseTemplate.id}`}>
                        <ExerciseTemplateHeader 
                            exerciseTemplate={exerciseTemplate} 
                            basePath={basePath} 
                            dragHandleProps={dragHandleProps} 
                            destroyWtc={destroyWtc}
                        />
                        {!exerciseTemplate.isMaxTest() && !exerciseTemplate.isVideoOnly() && (<ExerciseTemplateSpreadsheet 
                            exerciseTemplate={exerciseTemplate} 
                            updateSetTemplate={updateSetTemplate} 
                            destroySetTemplate={destroySetTemplate} 
                            user={user} 
                            destroy={destroy}
                            allowDestroy={allowDestroy}
                            basePath={basePath}
                        />)}
                        {exerciseTemplate.isMaxTest() && (
                            <div className="pa20 text-center slight-top-border">
                                {t("Test for a new reps rep max",{ reps: exerciseTemplate.repMaxReps })}
                            </div>
                        )}
                        {exerciseTemplate.isVideoOnly() && (
                            <div className="text-center">
                                <ExerciseVideo exercise={exerciseTemplate.exercisable} autoplay={false} fallbackToYT />
                            </div>
                        )}
                    </Card>
                )}
            />
            {exerciseTemplate.isSuperset() && (<div className="superset-label" id={`superset-label-for-${exerciseTemplate.id}`}><FontAwesomeIcon icon="arrow-down"/> {exerciseTemplate.supersetLabel(t)} <FontAwesomeIcon icon="arrow-up"/></div>)}
            {!exerciseTemplate.isSuperset() && exerciseTemplate.canHaveMultipleSets() && (<div className="text-right">
                <DefaultAsyncActionButton
                    color="white" 
                    className={`no-upcase card-radius add-st-btn-${exerciseTemplate.id}`} 
                    loaderType='loaderOnly'
                    action={addSetTemplate.bind(null,exerciseTemplate.getActionParams())}
                >
                    <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("Add Sets")}
                </DefaultAsyncActionButton>
            </div>)}
        </div>
    )
}

export const ExerciseGroupCard = ({ exerciseGroup, destroy, primaryAction, dragHandleProps, draggableRef, ...draggableProps }) => {
    const { t } = useTranslation();
    const history = useHistory();

    const cardProps = draggableRef ? { ...draggableProps, ref: draggableRef } : {};
    const deleteComp = (<ConfirmActionButton onClick={() => destroy()} render={({ ready, onClick }) => (
        <ListCardAction id={`destroy-group-${exerciseGroup.id}-btn`} onClick={onClick}>
            <FontAwesomeIcon color={ready ? 'red' : null} icon="times" />
        </ListCardAction>
    )} />)

    return (
        <ListCard {...cardProps} ref={draggableRef} className="ml0 mb0 mt20 mr0 wtc-card">
            {dragHandleProps && (<ListCardAction className="left no-border" {...dragHandleProps}>
                    <FontAwesomeIcon icon="arrows-alt-v" />
            </ListCardAction>)}
            <ListCardTitle className="large-title ex-group-name">
                {exerciseGroup.defaultedName(t)}
                <div className="exercise-subtitle ml0">{t('Exercise Group')}</div>
            </ListCardTitle>
            {primaryAction && deleteComp}
            <ListCardAction id={`edit-group-${exerciseGroup.id}-btn`} onClick={() => history.push(editExerciseGroupPathFor(exerciseGroup.workoutRoutine.id,exerciseGroup.id))}>
                <FontAwesomeIcon icon={["far","edit"]} />
            </ListCardAction>
            {!primaryAction && deleteComp}
            {primaryAction && primaryAction}
        </ListCard>
    )
}

let ChildCard = ({ child, basePath, updateSetTemplate, addSetTemplate, destroySetTemplate, user, destroyWtc, ...draggableProps }) => {
    const template = child.child;
    const destroy = () => {
        destroyWtc(new WorkoutTemplateChild({ ...child, destroyed: true }));
    }

    if(child.destroyed) {
        return '';
    } else if(template.isExerciseTemplate()) {
        return (
            <ExerciseTemplateCard 
                user={user}
                exerciseTemplate={template} 
                basePath={basePath} 
                updateSetTemplate={updateSetTemplate} 
                addSetTemplate={addSetTemplate}
                destroySetTemplate={destroySetTemplate}
                destroyWtc={destroy}
                {...draggableProps} 
            />
        )
    } else {
        return <ExerciseGroupCard exerciseGroup={template} destroy={destroy} {...draggableProps} />;
    }

}

ChildCard = requestStateHandler({ destroyWtc: 'child' })(ChildCard)

let Children = ({ workoutTemplate, updateChildOrder, updateSetTemplate, addSetTemplate, destroySetTemplate, destroyWtc, basePath, user }) => {
    const onDragEnd = result => {
        if(!result.destination) {
            return;
        }

        const newTemplate = new WorkoutTemplate({ ...workoutTemplate });
        const newWtc = workoutTemplate.workoutTemplateChildren.map(wtc => {
            const newWtc = new WorkoutTemplateChild({ ...wtc, workoutTemplate: newTemplate });
            if(newWtc.child.isExerciseTemplate()) {
                newWtc.child = new ExerciseTemplate({ ...newWtc.child, workoutTemplateChild: newWtc });
            }
            return newWtc;
        });
        newTemplate.workoutTemplateChildren = newWtc;
        newTemplate.reorderChildren(result.source.index,result.destination.index);
        updateChildOrder(newTemplate);
    }

    const children = workoutTemplate.workoutTemplateChildren;

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`wt-children-${workoutTemplate.id}`}>
                {(droppableProvided, droppableSnapshot) => (
                    <div ref={droppableProvided.innerRef}>
                        {children.map((child,index) => (
                            <Draggable draggableId={`${child.id}`} key={child.id} index={index}>
                                {(draggableProvided, draggableSnapshot) => (
                                    <ChildCard 
                                        child={child} 
                                        user={user}
                                        basePath={basePath} 
                                        destroyWtc={destroyWtc}
                                        updateSetTemplate={updateSetTemplate}
                                        addSetTemplate={addSetTemplate}
                                        destroySetTemplate={destroySetTemplate}
                                        draggableRef={draggableProvided.innerRef}
                                        dragHandleProps={draggableProvided.dragHandleProps}
                                        {...draggableProvided.draggableProps}
                                    />
                                )}
                            </Draggable>
                            
                        ))}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

Children = requestStateHandler({ updateChildOrder: 'workoutTemplate' })(Children);

class EditTemplateSuccess extends React.Component {

    constructor(props) {
        super(props);
        this.mediaQuery = window.matchMedia('(max-width: 992px)');
        this.state = { hideAddExercises: this.mediaQuery.matches };
        this.mediaQuery.addListener(this.screenChangeListener);
    }

    render() {
        const { 
            workoutRoutine, 
            workoutTemplateId, 
            updateTemplate, 
            updateChildOrder, 
            updateSetTemplate, 
            addSetTemplate, 
            destroySetTemplate, 
            warmupOptions, 
            user, imageCategories,
            isGroup, destroyWtc, t 
        } = this.props;
        const { hideAddExercises } = this.state;

        const workoutTemplate = workoutRoutine && workoutRoutine.findWorkoutTemplate(workoutTemplateId);
        const tWarmupOptions = warmupOptions.map(option => (option.text === 'None' ? { ...option, text: t('None') } : option));
        const imageCats = user.isAdmin() ? [ {text: '', value: ''}, ...imageCategories.map(cat => ({ text: cat.baseName, value: cat.id })) ] : [];
    
        if(workoutTemplate) {
            const basePath = isGroup ? editExerciseGroupPathFor(workoutRoutine.id,workoutTemplateId) : editWorkoutTemplatePathFor(workoutRoutine.id,workoutTemplateId);
    
            return (
                <div className="row limit-50-h">
                    <div className="col s12 m12 l5 offset-l1">
                        <div className="m-center-column">
                            <h4 className="conversion-heading ml0 hide-on-med-and-down">{isGroup ? t('Exercise Group') : t('Workout')}</h4>
                            <FormikWrapper 
                                initialValues={ workoutTemplate.formValues() }
                                submit={updateTemplate}
                                initialErrors={{}}
                                autoSubmit
                                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                    const inputProps = _.pick(rest,formikAutoSubmitTextProps);
                                    return (
                                        <CardForm onSubmit={handleSubmit} className="mt20">
                                            <div className="valign-wrapper">
                                                <InputWithErrors 
                                                    label={workoutTemplate.nameLabel(t)}
                                                    className="inline flex-grow"
                                                    name="name"
                                                    id="nameField"
                                                    component={TextInput}
                                                    {...inputProps}
                                                />
                                                {!workoutTemplate.isWorkout() && (<Checkbox
                                                    inputProps={{ 
                                                        name: 'alternator', 
                                                        onChange: handleAutoSubmitChange, 
                                                        onBlur: rest.handleBlur, 
                                                        value: true,
                                                        checked: rest.values.alternator
                                                    } } 
                                                    label={t('Alternating?')} 
                                                    filled 
                                                    className="ml10 no-wrap"
                                                    tooltip={t('alternate tip')}
                                                />)}
                                            </div>
                                            {workoutTemplate.isWorkout() && (<div className="valign-wrapper">
                                                <div>
                                                    <div className="font-grey">{t('Warmup')}</div>
                                                    <SelectInput 
                                                        name="warmupId" 
                                                        collection={tWarmupOptions} 
                                                        browserDefault 
                                                        value={rest.values.warmupId} 
                                                        onChange={handleAutoSubmitChange} 
                                                        onBlur={rest.handleBlur} 
                                                        style={{ minWidth: '140px'}}
                                                    />
                                                </div>
                                                <div className="ml15">
                                                    <div className="font-grey">{t('Cooldown')}</div>
                                                    <SelectInput 
                                                        name="cooldownId" 
                                                        collection={tWarmupOptions} 
                                                        browserDefault 
                                                        value={rest.values.cooldownId} 
                                                        onChange={handleAutoSubmitChange} 
                                                        onBlur={rest.handleBlur} 
                                                        style={{ minWidth: '140px'}}
                                                    />
                                                </div>
                                            </div>)}
                                            {user.isAdmin() && (<div className="valign-wrapper mt10">
                                                <div>
                                                    <div className="font-grey">{t('Image Category')}</div>
                                                    <SelectInput 
                                                        name="imageCategoryId" 
                                                        collection={imageCats} 
                                                        browserDefault 
                                                        value={rest.values.imageCategoryId} 
                                                        onChange={handleAutoSubmitChange} 
                                                        onBlur={rest.handleBlur} 
                                                        style={{ minWidth: '140px'}}
                                                    />
                                                </div>
                                            </div>)}
                                        </CardForm>
                                    )
                                }}       
                            />
                            <Children 
                                workoutTemplate={workoutTemplate} 
                                basePath={basePath} 
                                updateChildOrder={updateChildOrder} 
                                updateSetTemplate={updateSetTemplate}
                                addSetTemplate={addSetTemplate}
                                destroySetTemplate={destroySetTemplate}
                                destroyWtc={destroyWtc}
                                user={user}
                            />
                        </div>
                    </div>
                    {!hideAddExercises && (<div className="col s12 m12 l5 hide-on-med-and-down">
                        <div className="m-center-column">
                            <h4 className="conversion-heading ml0">{t('Add Exercises')}</h4>
                            <SectionTabs activeTab={1} hideIfOne render={({ activeTab }) => {
                                return (
                                    <AddExerciseTabs
                                        workoutTemplate={workoutTemplate} 
                                        activeTab={activeTab+1} 
                                        basePath={basePath}
                                    />
                                )
                            }}>
                                <SectionTab>{t("My Exercises")}</SectionTab>
                                <SectionTab>{t("Search")}</SectionTab>
                                {!workoutTemplate.isGroup() && (<SectionTab>{t("Exercise Groups")}</SectionTab>)}
                            </SectionTabs>
                        </div>
                    </div>)}
                    <BottomButtons className="hide-on-large-only">
                        <LinkButton 
                                    rounded 
                                    color="primary" 
                                    className="no-upcase shadow" 
                                    id={`add-exercises-btn`}
                                    to={workoutTemplate.addExercisesPath()}
                                >
                                    <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("Add Exercises")}
                        </LinkButton>
                    </BottomButtons>
                    <ExerciseInfoModal basePath={basePath} baseMatch={basePath} />
                    <ExerciseTemplateSettingsModal basePath={basePath} baseMatch={basePath} workoutTemplate={workoutTemplate} />
                    <ReferenceWeightModal basePath={basePath} baseMatch={basePath} workoutTemplate={workoutTemplate} />
                </div>
            )
        } else {
            return (<Redirect to={resolvedHomePath()} />)
        }
    }

    screenChangeListener = e => this.setState({ hideAddExercises: e.matches });

    componentWillUnmount() {
        this.mediaQuery.removeListener(this.screenChangeListener);
    }
}

const mapDispatchToTemplateProps = dispatch => ({
    updateTemplate: (data) => dispatch(updateWorkoutTemplate(data)),
    updateSetTemplate: (setTemplate,user) => dispatch(updateSetTemplate(setTemplate,user)),
    addSetTemplate: (data) => dispatch(addSetTemplate(data)),
    destroySetTemplate: (data) => dispatch(destroySetTemplate(data)),
    destroyWtc: (data) => dispatch(destroyWtc(data)),
    updateChildOrder: (template) => dispatch(updateWtChildOrder(template))
})

const mapStateToTemplateProps = state => ({
    warmupOptions: warmupOptionsSelector(state),
    imageCategories: workoutImageCatSelector(state),
    user: userRecordSelector(state)
})

EditTemplateSuccess = connect(mapStateToTemplateProps,mapDispatchToTemplateProps)(EditTemplateSuccess);

const transitionMap = {
    rules: [
        [[ ...allMainMatches, workoutLogMatch, workoutPreviewMatch, editRoutinePath, editRoutineStandaloneMatch ] ,transitions.slideOut],
        [[progressionPickMatch, editExerciseGroupMatch, progressionShowMatch,swapExerciseTemplateMatch, addExercisesMatch], transitions.slideOver]
    ]
};

const egTransitionMap = {
    rules: [
        [[ ...allMainMatches, workoutLogMatch, workoutPreviewMatch, editRoutinePath, editRoutineStandaloneMatch, editWorkoutTemplateMatch,addExercisesMatch ] ,transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch,swapExerciseTemplateMatch,addExercisesToGroupMatch], transitions.slideOver]
    ]
};

let ExitRoutineButton = ({ exitPath }) => {
    const history = useHistory();

    if(exitPath) {
        return (
            <SimpleNavRightButton id="exit-activity-btn" icon="times" className="pr20" onClick={() => history.push(exitPath)} />
        )
    } else {
        return null;
    }
}

const mapStateToERBProps = state => ({
    exitPath: preRoutineEditorPath(state)
})

ExitRoutineButton = connect(mapStateToERBProps,null)(ExitRoutineButton);

class EditWorkoutTemplate extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(this.isGroup() ? egTransitionMap : transitionMap);
    }

    render() {
        const { t, scrollRef, loadRoutine, trainer, user, workoutRoutine, match: { params: { id, workoutTemplateId }}, warmupsNeedLoading } = this.props;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{this.isGroup() ? t("Edit Exercise Group") : t("Edit Workout")}</SimpleNavTitle>
                    <ExitRoutineButton />
                </SimpleNav>
                <SimpleNavContainer>
                    <RoutineUserReconciler user={user} workoutRoutine={workoutRoutine} trainer={trainer}>
                        <Loader
                            load={loadRoutine.bind(null,id,warmupsNeedLoading)}
                            preloaded={() => (workoutRoutine && workoutRoutine.isLoaded())}
                            successComponent={EditTemplateSuccess}
                            type='page'
                            workoutRoutine={workoutRoutine}
                            workoutTemplateId={workoutTemplateId}
                            t={t}
                            isGroup={this.isGroup()}
                        />
                    </RoutineUserReconciler>
                </SimpleNavContainer>
            </Page>
        )
    }

    isGroup = () => {
        const { location } = this.props;
        return location.pathname.includes('exercise_group');
    }
}

export { ExitRoutineButton }    

export default connect(mapStateToStandaloneProps,mapDispatchToProps)(withTranslation()(EditWorkoutTemplate));