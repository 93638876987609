import { RecordBase, registerInflection } from  'lib/record-base';
import * as _ from 'lib/utilities';
import { addExercisesToGroupPathFor, addExercisesPathFor } from 'config/paths';

export class WorkoutTemplateChild extends RecordBase {
    static NAME = 'WorkoutTemplateChild'
    static ASSOCS = { 
        workoutTemplate: { type: 'belongsTo' },
        child: { type: 'belongsTo', poly: true }
    }

    destroyParams() {
        return { workoutRoutineId: this.workoutTemplate.workoutRoutine.id, workoutTemplateId: this.workoutTemplate.id, childId: this.id }
    }
}

registerInflection('workoutTemplateChild','workoutTemplateChildren',WorkoutTemplateChild);

export class WorkoutTemplate extends RecordBase {
    static NAME = 'WorkoutTemplate'
    static ASSOCS = { 
        workouts: { type: 'hasMany' },
        workoutTemplateChildren: { type: 'hasMany', sortAttr: 'childOrder' },
        workoutTemplateParents: { type: 'hasMany', tableName: 'workoutTemplateChildren', inverse: 'child' },
        workoutTemplateParent: { type: 'hasOne', tableName: 'workoutTemplateChildren', inverse: 'child' },
        workoutRoutine: { type: 'belongsTo' },
        workoutRoutineDays: { type: 'hasMany' },
        owner: { type: 'belongsTo', tableName: 'users' }
    }
    static IMAGE_CATEGORIES = [
        ['Upper body1',0],['Upper body2',1],['Upper body3',2],
        ['Lower body1',3],['Lower body2',4],['Lower body3',5],
        ['Abs1',6],['Abs2',7],['Abs3',8],
        ['Push1',9],['Push2',10],['Push3',11],
        ['Pull1',12],['Pull2',13],['Pull3',14],
        ['Arms1',15],['Arms2',16],['Arms3',17]
    ]

    exerciseSearchValues() {
        return { searchTerm: '', equipmentTypes: [''], simpleTypes: [''] }
    }

    actionParams() {
        return { workoutRoutineId: this.workoutRoutine.id, workoutTemplateId: this.id }
    }

    addExercisesPath() {
        return this.isGroup() ? addExercisesToGroupPathFor(this.actionParams()) : addExercisesPathFor(this.actionParams());
    }

    user() {
        return this.workoutRoutine && this.workoutRoutine.user;
    }

    defaultedName(t) {
        return _.isBlank(this.name) ? t("Untitled") : this.name;
    }

    isExerciseTemplate() {
        return false;
    }

    isExerciseGroup() {
        return true;
    }

    routineCycleName(t) {
        if(this.isGroup()) {
            if(this.workoutTemplateParents.length > 0) {
                return this.workoutTemplateParents[0].workoutTemplate.routineCycleName();
            }
            return t('Untitled cycle');
        } else {
            return this.workoutRoutineDays[0].routineCycle.defaultedName(t);
        }
    }

    isGroup() {
        return this.workoutRoutineDays.length <= 0;
    }

    isAlternator() {
        return this.alternator;
    }

    isWorkout() {
        return !this.isGroup();
    }

    formValues() {
        return _.parseObjForForm({ 
            id: this.id,
            workoutRoutineId: this.workoutRoutineId,
            name: this.name, 
            alternator: this.alternator, 
            warmupId: this.warmupId, 
            cooldownId: this.cooldownId, 
            imageCategoryId: this.imageCategoryId
        })
    }

    nameLabel(t) {
        return this.isGroup() ? t('Group Name') : t('Workout Name')
    }

    directChildren() {
        return this.workoutTemplateChildren.map(wtc => wtc.child);
    }

    prevChild(child) {
        const curIndex = _.findIndex(this.workoutTemplateChildren,wtc => wtc.id === child.id);
        return this.workoutTemplateChildren[curIndex-1];
    }

    nextChild(child) {
        const curIndex = _.findIndex(this.workoutTemplateChildren,wtc => wtc.id === child.id);
        return this.workoutTemplateChildren[curIndex+1];
    }

    supersetFor(child) {
        return _.find(this.supersets(),superset => _.some(superset, supersetChild => supersetChild.id === child.id));
    }

    supersets() {
        let curSuperset = [];
        let supersets = [];
        this.directChildren().forEach(child => {
            if(child.isExerciseTemplate()) {
                curSuperset.push(child);
                if(!child.isSuperset()) {
                    supersets.push(curSuperset);
                    curSuperset = [];
                }
            } else {
                if(curSuperset.length > 0) {
                    supersets.push(curSuperset);
                }
                curSuperset = [];
            }
        });
        return supersets;
    }

    reorderChildren(startIndex,endIndex) {
        const [removed] = this.workoutTemplateChildren.splice(startIndex, 1);
        this.workoutTemplateChildren.splice(endIndex, 0, removed);
    }

    childOrderParams() {
        return { 
            id: this.id, 
            workoutRoutineId: this.workoutRoutineId, 
            children: this.workoutTemplateChildren.map((wtc,index) => ({ id: wtc.id, childOrderPosition: index }))
        }
    }

    exerciseTemplates() {
        return _.filter(this.directChildren(),child => child.isExerciseTemplate())
    }

    setTemplates() {
        return _.flatMap(this.exerciseTemplates(), et => et.setTemplates);
    }

    workoutTemplateChildrenIds() {
        return this.workoutTemplateChildren.map(wtc => wtc.id);
    }

    workoutTemplateParentsIds() {
        return this.workoutTemplateParents.map(wt => wt.id);
    }

    destroyWtcIds() {
        return _.uniq(_.concat(this.workoutTemplateChildrenIds(),this.workoutTemplateParentsIds()));
    }

    exerciseTemplateIds() {
        return this.exerciseTemplates().map(et => et.id);
    }

    setTemplateIds() {
        return this.setTemplates().map(st => st.id);
    }

    destroyParams() {
        return {
            workoutTemplateChildrenIds: this.destroyWtcIds(),
            exerciseTemplateIds: this.exerciseTemplateIds(),
            setTemplateIds: this.setTemplateIds()
        }
    }

    findExerciseTemplate(id) {
        return _.find(this.exerciseTemplates(),child => (child.id === Number(id)));
    }

    findSetTemplate(id) {
        return _.find(this.setTemplates(),st => (st.id === Number(id)));
    }
}

registerInflection('workoutTemplate','workoutTemplates',WorkoutTemplate);