import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { workoutLogMatch, progressionPickMatch, workoutPreviewMatch, workoutDoMatch, swapExerciseSpecPathFor, progressionShowMatch, allMainMatches } from 'config/paths';
import withWorkout from 'partials/RoutineStateHOC';
import { standaloneStartWorkout, swapExerciseSpec } from 'redux/actions';
import { connect } from 'react-redux';
import { dateFormat } from 'config/settings';
import moment from 'moment';
import { StandaloneExerciseSearch } from 'partials/ExerciseSearchForm';
import { Redirect } from "components/Routing";
import { PickButtonLC } from 'views/ProgressionPick';
import { LoadingContainerParent } from 'components/LoadingHOC';
import { resolvedHomePath } from 'redux/helpers';

const actionComp = (swapExerciseSpec,spec,afterAction) => ({ exercisable, setBlockModals }) => {

    return (<LoadingContainerParent 
        load={swapExerciseSpec.bind(null,spec,exercisable)}
        preloaded={() => false}
        component={PickButtonLC}
        setBlockModals={setBlockModals}
        successCallback={(data) => afterAction(exercisable,data)}
        failCallback={() => setBlockModals(false)}
        className={`ex-swap-btn-${exercisable.id}`}
        skipAutoLoad
    />)
}

class SwapSpecCore extends React.Component {

    constructor(props) {
        super(props);
        const { startWorkout, date } = props;
        this.finish = this.finish.bind(this);
        startWorkout(date.format(dateFormat));
    }

    render() {
        const { specId, workout, swapExerciseSpec } = this.props;
        const spec = workout && workout.getSpec(specId);


        if(workout && spec) {
            return (
                <StandaloneExerciseSearch 
                    context={{id: spec.id, type: 'ExerciseSpecification'}} 
                    initialValues={spec.exerciseSearchValues()} 
                    basePath={swapExerciseSpecPathFor(workout.date,spec.id)}
                    actionComp={actionComp(swapExerciseSpec,spec,this.finish)}
                />
            )
        } else {
            return (<Redirect to={resolvedHomePath()} />)
        }
    }

    finish(exercisable,data) {
        const { history } = this.props;

        if(!this.finished) {
            history.go(-1);
            this.finished = true;
        }
    }

    componentWillUnmount() {
        this.finished = true;
    }
}

const mapDispatchToProps = dispatch => ({
    startWorkout: (date) => dispatch(standaloneStartWorkout(date)),
    swapExerciseSpec: (spec,exercisable) => dispatch(swapExerciseSpec(spec,exercisable))
})

const WithWorkout = withWorkout('dirty','page',true)(connect(null,mapDispatchToProps)(SwapSpecCore))

const transitionMap = {
    rules: [
        [[ ...allMainMatches, workoutLogMatch, workoutPreviewMatch, workoutDoMatch ] ,transitions.slideOut],
        [[progressionPickMatch,progressionShowMatch], transitions.slideOver]
    ]
};

class SwapExerciseSpec extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { match: { params: { date, id }}, history, t, scrollRef } = this.props;

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t("Swap Exercise")}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <WithWorkout 
                        date={moment(date)}
                        specId={id}
                        history={history}
                    />
                </SimpleNavContainer>
            </Page>
        )
    }
}



export default withTranslation()(SwapExerciseSpec);