import React from 'react';
import { WorkoutRoutine } from 'lib/classes';
import { AutoAdvanceButton, FixedSizeQuestion, FlowSubtext, FullWidthNextButton, SmallImageCardOption } from 'components/FlowForm';
import { FlowAnswerButton } from 'components/Button';
import * as _ from 'lib/utilities';
import { AppReviewCard, CalculatingScreen, Illustration, ReachGoalByScreen, WorkoutTimes, workoutSubmitAttrs } from 'partials/OnboardingComponents';
import { CheckButtons, SelectButton, WeekdayButtonSelect } from 'components/Form';
import SummaryCard from './OnboardingSummaryCard';
import { brandName, dateFormat } from 'config/settings';
import moment from 'moment';
import homeF from 'assets/img/bedroom_workout_f.webp';
import homeM from 'assets/img/bedroom_workout_m.webp';
import aestheticsF from 'assets/img/AestheticsF.png';
import strengthF from 'assets/img/StrengthF.png';
import aestheticsM from 'assets/img/AestheticsM.png';
import strengthM from 'assets/img/StrengthM.png';
import laurie from 'assets/img/Laurie.webp';
import jeff from 'assets/img/Jeff.webp';
import josh from 'assets/img/Josh.webp';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { ExerciseVideo } from './ExerciseImage';
import Carousel from 'components/Carousel';
import LinkButton from 'components/LinkButton';
import { homePath } from 'config/paths';
import NotificationRequestorWrapper from './NotificationRequestorWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SMART_REMINDERS_TIP } from 'config/tooltips';

export class BeforeWorkoutPlanScreen extends React.Component {

    constructor(props) {
        super(props);
        const { loadBannableProgs } = this.props;
        loadBannableProgs().catch(() => console.log("Failed to load bannable progressions."));
    }

    render() {
        const { user, flowProps, t } = this.props;

        return (
            <React.Fragment>
                <FixedSizeQuestion text={t("beforeworkoutplanmsg")} />
                <FlowSubtext>
                    {t('beforeworkoutplansub')}
                </FlowSubtext>
                <SummaryCard flowProps={flowProps} t={t} user={user} highlight="workoutPlan" reachGoalPace="moderate" />
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t("Continue")}
                />
            </React.Fragment>
        )
    }
}

const areasF = [
    "Arms",
    "Abs",
    "Butt",
    "Legs",
    "Full Body"
]

const areasM = [
    "Arms",
    "Shoulders",
    "Chest",
    "Abs",
    "Legs",
    "Full Body"
]


export const FocusAreasQ = ({ flowProps, t }) => {
    const { values: { gender } } = flowProps;
    const areas = gender ? areasF : areasM;

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('musgrpfocusq')} />
            <div>
                <CheckButtons {...flowProps} name="focusAreas" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {areas.map((area,i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={area} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(area)}</span>
                                </SelectButton>
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const gymTypes = {
    "fullgym": WorkoutRoutine.GYM,
    "homegym": WorkoutRoutine.GYM_BW
}

export const GymTypeQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('gymtypeq')} />
            {Object.entries(gymTypes).map(([str,val],i) => {

                return (
                    <AutoAdvanceButton key={val} {...flowProps} attr="gymType" value={val} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`gym-type-${val}-btn`}>
                            <span>{t(str)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const BannableProgQ = ({ prog, flowProps, t, }) => {
    const exercise = prog.defaultExercise();
    const clickHandler = enable => () => {
        const { values, setValues, advance } = flowProps;
        const { allowedExerciseProgressionIds=[''] } = values;
        const newValues = { 
            ...values, 
            allowedExerciseProgressionIds: enable ? _.union(_.noBlanks(allowedExerciseProgressionIds),[prog.id]) : _.arrayForForm(_.without(allowedExerciseProgressionIds,prog.id))
        };
        setValues(newValues);
        return advance({ ...newValues });
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('bannableprogq', { exercise: prog.pluralName.toLowerCase() })} />
            <FlowSubtext className="mb15">
                {prog.equipmentDescription}
            </FlowSubtext>
            <div className="mb20">
                <ExerciseVideo exercise={exercise} autoplay fallbackToYT fixedAspect />
            </div>
            <FlowAnswerButton color="primary" rounded onClick={clickHandler(true)} id={`yes-btn`}>
                <span>{t('Yes')}</span>
            </FlowAnswerButton>
            <FlowAnswerButton color="primary" rounded onClick={clickHandler(false)} id={`no-btn`}>
                <span>{t('No')}</span>
            </FlowAnswerButton>
        </React.Fragment>
    )
}

export const HomeWorkoutsWorkScreen = ({ flowProps, t }) => {
    const { values: { gender } } = flowProps;
    
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mt0 pt0" text={t('minimaleqpmsg')} />
            <FlowSubtext className="text-left mb10 ml10">
                {t("minimaleqpsub1")}
            </FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">
                {t("minimaleqpsub2")}
            </FlowSubtext>
            <Illustration src={gender ? homeF : homeM} width={480} height={480} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Continue')}
            />
        </React.Fragment>
    )
}

export const WorkoutFocusQuestion = ({ flowProps, t, user } ) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("Which do you care about more?")} />
            <div className="no-wrap">
                <AutoAdvanceButton {...flowProps} attr="routineFocus" value="0" render={({ onClick }) => (
                    <SmallImageCardOption id="aesthetics-btn" src={user.female() ? aestheticsF : aestheticsM} alt="" onClick={onClick} title={t("Aesthetics")}  />
                )} />
                <AutoAdvanceButton {...flowProps} attr="routineFocus" value="1" render={({ onClick }) => (
                    <SmallImageCardOption id="strength-btn" src={user.female() ? strengthF : strengthM} alt="" onClick={onClick} title={t("Strength")}  />
                )} />
            </div>
        </React.Fragment>
    )
}

export const TrainingDaysQuestion = ({ flowProps, t }) => {
    const { setFieldValue, values, setFieldTouched, errors: { trainingDays: error }, touched: { trainingDays: touched } } = flowProps;
    const subtitleCnames = classnames("flow-question-subtitle",{"red-text": error && touched});
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("Which days do you want to workout?")} />
            <div className={subtitleCnames}>{t("Pick at least 2 non-consecutive days")}</div>
            <WeekdayButtonSelect 
                startDay={0} 
                name="trainingDays"
                values={values} 
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                dayNames={moment.weekdaysShort()} 
            />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

export const WorkoutTimesQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("How much time do you have to workout?")} />
            <FlowSubtext className="mb15">
                {t('pickworkouttimetip')}
            </FlowSubtext>
            <div className="text-center">
                <WorkoutTimes flowProps={flowProps} t={t} />
            </div>
        </React.Fragment>
    )
}

export const StartDateQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t("When do you want to start your routine?")} />
            {_.nextWeekCollection(dateFormat).map(({ text, value },i) => {

                return (
                    <AutoAdvanceButton key={value} {...flowProps} attr="routineStart" value={value} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`routine-start-${i}-btn`}>
                            <span>{text}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const CalculatingRoutineScreen = ({ flowProps, assignRoutine, t }) => {
    const { advance, values } = flowProps;
    const { noRoutine } = values;
    const subtitles = noRoutine ? [t('Adjusting your plan'),t('Wrapping things up')] : [t('Building workouts'),t('Adding strength tests'),t('Wrapping things up')];
    const switchPcts = noRoutine ? [70] : [40,75];

    return (
        <CalculatingScreen
            duration={noRoutine ? 4 : 7}
            subtitles={subtitles}
            subtitleSwitchPcts={switchPcts}
            load={() => assignRoutine({ ..._.pick(flowProps.values,workoutSubmitAttrs), hasBasicProfile: true })}
            successCallback={() => advance(values)}
        />
    )
}

export const ThirdGoalByScreen = ({ flowProps, t }) => {
    const { values: { similarUsers3 } } = flowProps;

    return (
        <ReachGoalByScreen
            flowProps={flowProps}
            t={t}
            pace="fast"
            title={t("rgb2msg")}
            subtextRender={({ endDate }) => (
                <React.Fragment>
                    <FlowSubtext className="text-left mb10 ml20 mt15">{t('rgb2text1',{ num: _.numberWithCommas(similarUsers3), brand_name: brandName(), date: endDate.format('MMM Do') })}</FlowSubtext>
                </React.Fragment>
            )}
        />
    )
}

export const showFastGoalPace = values => {
    const { noRoutine, dailyStepGoal, weightGoal } = values;

    if(noRoutine && (_.isBlank(dailyStepGoal) || weightGoal !== -1)) {
        return false;
    }

    return true;
}

export const AfterWorkoutPlanScreen = ({ user, flowProps, t }) => {
    const { values: { noRoutine } } = flowProps;

    return (
        <React.Fragment>
            <FixedSizeQuestion text={noRoutine ? t("aftertprofilemsg") : t("ywpir")} />
            <FlowSubtext>
                {t('ywpirsub')}
            </FlowSubtext>
            <SummaryCard 
                flowProps={flowProps} 
                t={t} 
                user={user} 
                reachGoalPace={showFastGoalPace(flowProps.values) ? 'fast' : 'moderate'} 
                highlight={noRoutine ? "training" : "workoutPlan"} 
            />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

export const SmartRemindersQ = ({ flowProps, t, dismissTooltip }) => {
    const [showSpinner, setShowSpinner] = React.useState(false);
    const clickHandler = enable => () => {
        if(showSpinner) {
            return;
        }

        const { values, setValues, advance } = flowProps;
        const newValues = { 
            ...values, 
            enableSmartReminders: enable
        };

        setValues(newValues);

        if(enable) {
            setShowSpinner(true);
            dismissTooltip(SMART_REMINDERS_TIP);
            setTimeout(() => advance({ ...newValues }),3000);
        } else {
            advance({ ...newValues });
        }
    }
    
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("smartremindersq")} />
            <FlowSubtext className="text-left mb10 ml10">
                {t("smartreminderssub1")}
            </FlowSubtext>
            <FlowSubtext className="text-left mb10 ml10">
                {t("smartreminderssub2")}
            </FlowSubtext>
            {[true,false].map((val,i) => {
                return (
                    <FlowAnswerButton color="primary" rounded onClick={clickHandler(val)}>
                        {val && showSpinner && <FontAwesomeIcon icon={'spinner'} spin />} <span>{t(`smartremindersa${i+1}`)}</span>
                    </FlowAnswerButton>
                )
            })}
            {showSpinner && <NotificationRequestorWrapper />}
        </React.Fragment>
    )
}

const howRewardAnswers = [
    'howra1',
    'howra2',
    'howra3',
    'howra4',
    'howra5',
    'Other'
]

export const HowRewardQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('howrewardq')} />
            <div>
                <CheckButtons {...flowProps} name="selfRewards" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {howRewardAnswers.map((ans,i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={ans} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(ans)}</span>
                                </SelectButton>                            
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const howFeelAnswers = [
    'howfeela1',
    'howfeela2',
    'howfeela3',
    'howfeela4',
    'howfeela5',
    'howfeela6',
    'Other'
]

export const HowFeelQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('howfeelq')} />
            <div>
                <CheckButtons {...flowProps} name="successFeels" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {howFeelAnswers.map((ans,i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={ans} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(ans)}</span>
                                </SelectButton>                            
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const userReviews = (t) => {
    return [
        { imgSrc: josh, weightLoss: 30, review: { reviewer: 'Josh', title: t("joshrtitle"), text: t("joshreview")} },
        { imgSrc: laurie, weightLoss: 30, review: { reviewer: 'Laurie', title: t("lauriertitle"), text: t("lauriereview", { brand_name: brandName()})} },
        { imgSrc: jeff, weightLoss: 100, review: { reviewer: 'Jeff', title: t("jeffrtitle"), text: t("jeffreview")} }
    ]
}

export const SocialProofScreen = ({ flowProps, t }) => {
    return (
        <div className="container">
            <div className="row text-center limit-50-h">
                <div className="col s12">
                    <FixedSizeQuestion text={<Trans i18nKey="yigcmsg" values={{ num: '100,000'}}><b></b></Trans>} className="mb0" />
                </div>
                <div className="col s12 review-slider">
                    <Carousel 
                        items={userReviews(t).map((props,i) => ({ id: i+1, children: (<AppReviewCard {...props} t={t} />)}))}
                        options={{ fullWidth: false, indicators: false, useFullSlide: true }}
                    />
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </div>
    )
}

export const FinishingTouchesScreen = ({ flowProps, t }) => {
    const { advance, values } = flowProps;
    const subtitles = [t("Applying finishing touches")];

    return (
        <CalculatingScreen
            duration={3}
            subtitles={subtitles}
            subtitleSwitchPcts={[]}
            successCallback={() => advance(values)}
        />
    )
}

export const FinalSummaryScreen = ({ user, flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("ypirtgmsg")} />
            <SummaryCard 
                flowProps={flowProps} 
                t={t} 
                user={user} 
                reachGoalPace={showFastGoalPace(flowProps.values) ? 'fast' : 'moderate'} 
            />
            <LinkButton 
                className="btn-full-width btn-bottom-center ff-next-btn"
                color="primary"
                to={user.afterSetupPath('onboard',homePath())}
            ><span>{t("Let's do this!")}</span></LinkButton>
        </React.Fragment>
    )
}