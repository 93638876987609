import { Meal, MealType, MacroParams } from './classes';
import * as _ from 'lib/utilities';

export class MealSearchCategory {
    static getSuitableCats = (categories,{ dietType,acceptableFoodTypes,dishType,excludedKeywords }) => {
        const foodTypes = acceptableFoodTypes.split(',');
        return _.sortBy(_.filter(categories,cat => (cat.matches(dietType,foodTypes,dishType,excludedKeywords) >= 9)),cat => -cat.matches(dietType,foodTypes,dishType,excludedKeywords));
    }

    static tagsCol = (categories,t,includeAll=true) => {
        let tags = includeAll ? [{ text: t('All'), value: '' }] : [];
        categories.forEach(cat => {
            tags.push({ text: cat.name, value: cat.id });
        })
        return tags;
    }

    constructor(obj) {
        Object.assign(this,obj);
    }

    matches(dietType,foodTypes,dishType,excludedKeywords) {
        dishType = dishType === 'side_dish' ? dishType : 'main_dish';
        if(this.dishType === dishType && this.dietTypes.includes(dietType) && _.intersection(this.acceptableFoodTypes,foodTypes).length > 0) {
            const foodType = (foodTypes.includes('lunch') || foodTypes.includes('dinner')) ? 'dinner' : foodTypes[0];
            if(this.acceptableFoodTypes.includes(foodType)) {
                const allergyMap = this.countMap[dietType][foodType];
                const relevant = _.intersection(Object.keys(allergyMap),excludedKeywords);
                if (relevant.length > 0) {
                    let count = 9999;
                    relevant.forEach(allergyTag => {
                        if(allergyMap[allergyTag] < count) {
                            count = allergyMap[allergyTag];
                        }
                    })
                    return count;
                } else {
                    return allergyMap['none'];
                }
            }
        }
        return 0;
    }
}

const defaultOffPlanSearchParams = user =>({
    recipes: [],
    more: true,
    loadedPage: 0,
    initialParams: { keywords: '' }
})

export class SearchSeed {
    constructor(data) {
        Object.assign(this,(data || {}));
    }

    matchesContext({ type, id }) {
        if(type === 'browseMeals' || type === 'browseRecipes') {
            return (_.isBlank(this.mealTypeId) && _.isBlank(this.userMealId))
        } else {
            const contextId = type === 'MealType' ? this.mealTypeId : this.userMealId;
            return contextId === id;
        }
    }

    alreadyLoaded() {
        return false;
    }

    browserAlreadyLoaded() {
        return false;
    }

    initValues() {
        return { ...this }
    }

    canEditMeals() {
        return false;
    }

    offPlanSearchParams(user) {
        return defaultOffPlanSearchParams(user);
    }

    mealCount() {
        return 1;
    }
}

export class MealSearch {

    static ALL_MEAL_TYPES_VALUE = 'breakfast,dinner,lunch,snack';
    static mealTypesForForm = t => ([
        { text: `${t('All')}`, value: this.ALL_MEAL_TYPES_VALUE },
        { text: t('Breakfast'), value: 'breakfast' },
        { text: `${t('Lunch')}/${t('Dinner')}`, value: 'dinner,lunch' },
        { text: t('Snack'), value: 'snack' }
    ])

    static dishTypesCol = t => ([
        { text: t('All'), value: 'both' },
        { text: t('Main'), value: 'main_dish' },
        { text: t('Side'), value: 'side_dish' }
    ])

    constructor(mealSearch,results,tables) {
        Object.assign(this,mealSearch);
        this.results = Meal.mealCollection(results,tables);
    }

    matchesContext({ type, id }) {
        if(type === 'browseMeals' || type === 'browseRecipes') {
            return _.isBlank(this.contextId);
        } else {
            return this.contextId === id && this.contextType === type;
        }
    }

    hasContext() {
        return !_.isBlank(this.contextId);
    }

    canEditMeals() {
        return this.hasContext();
    }

    isRecipeSearch() {
        return this.type === 'RecipeSearch';
    }

    isMealSearch() {
        return !this.isRecipeSearch();
    }

    alreadyLoaded() {
        return true;
    }

    browserAlreadyLoaded() {
        return !this.needsClearing;
    }

    acceptableFoodTypesForForm() {
        if(!this.acceptableFoodTypes || this.acceptableFoodTypes.length === 0 || _.difference(MealType.ON_PLAN_CATS,this.acceptableFoodTypes).length === 0) {
            return this.constructor.ALL_MEAL_TYPES_VALUE;
        } else if(this.acceptableFoodTypes.includes('dinner') || this.acceptableFoodTypes.includes('lunch')) {
            return 'dinner,lunch';
        } else {
            return this.acceptableFoodTypes[0];
        }
    }

    initValues() {
        return {};
    }

    formValues() {
        const obj = {
            id: this.id,
            dietType: this.dietType,
            acceptableFoodTypes: this.acceptableFoodTypesForForm(),
            mealSearchCategoryId: this.mealSearchCategoryId,
            excludedKeywords: this.excludedKeywords || [],
            keywords: this.keywords,
            targetCalories: this.targetCalories,
            macroFields: MacroParams.buildForForm(this.macroFields,true),
            customSearch: this.customSearch
        }
        if(this.isRecipeSearch()) {
            obj.dishType = this.dishType;
        }
        return _.parseObjForForm(obj);
    }

    fullExclusionTags(allergyTags) {
        let alwaysShown = _.uniq(_.noBlanks([ ...(this.userExcludedKeywords || []), ...(this.excludedKeywords || []) ]));
        let allergens = _.filter(allergyTags,([label,val]) => alwaysShown.includes(val));
        let allergenVals = allergens.map(([label,val]) => val);
        let nonAllergenVals = _.difference(alwaysShown,allergenVals);

        return [ ...allergens, ...nonAllergenVals.map(val => [val,val]) ];
    }

    possibleRecipeTypes() {
        return this.isRecipeSearch() ? this.constructor.RECIPE_TYPES : _.without(this.constructor.RECIPE_TYPES,['side'])
    }

    hasMore() {
        return this.more;
    }

    offPlanSearchParams(user) {
        if(this.needsClearingForOffPlan) {
            return defaultOffPlanSearchParams(user);
        } else {
            return {
                recipes: _.uniqBy(this.results.map(meal => meal.mainDish().recipe),'id'),
                more: this.more,
                loadedPage: this.resultIndex,
                initialParams: { keywords: this.keywords }
            }
        }
    }

    fitPlanMacroSummary(t) {
        if(this.originalMacros) {
            return _.macroTargetsSummary(t,this.originalMacros)
        }
        return null;
    }

    effectiveMacroParams() {
        if(this.customSearch || !this.hasContext()) {
            return { ...this.macroParams, calories: this.targetCalories };
        }
        return this.originalMacros;
    }

    originalCalorieTarg() {
        if(this.hasContext()) {
            return (this.originalMacros || {}).calories
        }
        return null;
    }

    macroWarningFor(t,meal) {
        const targs = this.effectiveMacroParams();
        const macroHash = meal.macroHash();
        const warnings = MacroParams.macroWarnings(t,targs,macroHash);
        if(warnings) {
            return `${warnings} ${t('Total')}: ${_.macroHashSummary(t,macroHash)}`
        }
        return null;
    }

    showLimitedResultsWarning() {
        const cals = this.originalCalorieTarg();
        return !this.customSearch && cals && cals <= 60;
    }

    mealCount() {
        return (this.targetDates && this.targetDates.length >= 1) ? this.targetDates.length : 10;
    }
}