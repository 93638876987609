import { RecordBase, registerInflection } from 'lib/record-base';
import { workoutResetPathFor, workoutDoPathFor } from 'config/paths';
import * as _ from 'lib/utilities';
import moment from 'moment';

export class Workout extends RecordBase {
    static NAME = 'Workout'
    static DONT_PERSIST = ['warmup','cooldown','orderedSets','orderedIndicesArr'];
    static ASSOCS = { 
        user: { type: 'belongsTo' },
        exerciseSpecifications: { type: 'hasMany', sortAttr: 'workoutOrder' },
        workoutTemplate: { type: 'belongsTo' },
        cardioTemplate: { type: 'belongsTo', tableName: 'workoutTemplates' }
    }

    static imagesPathSuffix = `img/workout_images/`

    static imagesBasePath = () => _.publicUrl() + this.imagesPathSuffix;

    static restDayImage(user) {
        return `${this.imagesBasePath()}rest_1_${user.female() ? 'f' : 'm'}.png`
    }

    displayName(t) {
        return _.isBlank(this.name) ? t('Untitled workout') : this.name;
    }

    workoutRoutineId() {
        return this.workoutTemplate.workoutRoutineId;
    }

    durationStr() {
        if(_.isBlank(this.startedAt) || _.isBlank(this.finishedAt)) {
            return null;
        }

        const startTime = moment(this.startedAt);
        const endTime = moment(this.finishedAt);
        const elapsedMillis = endTime.diff(startTime);
        const seconds = Math.max(elapsedMillis/1000,0);

        if(seconds === 0) {
            return null;
        }

        return _.stopwatchFormat(seconds);
    }

    alreadyStarted() {
        return (this.warmedUp && this.warmup) || this.isAnyLogged();
    }

    startPath() {
        if(this.alreadyStarted()) {
            return workoutResetPathFor(this.date);
        } else {
            return this.initialPath();
        }
    }

    initialPath() {
        const set = this.firstUnloggedSet();
        if(set) {
            return set.pathFor(set.pathSuffix());
        } else {
            return workoutDoPathFor(this.date,'done');
        }
    }

    nextPath(currentSet,checkRest=false) {
        const curSuffix = currentSet.pathSuffix(checkRest);
        const nextSet = this.nextSetFor(currentSet);
        if(curSuffix === 'rest') {
            return currentSet.pathFor(curSuffix);
        } else if(nextSet) {
            return nextSet.pathFor(nextSet.pathSuffix(false));
        } else {
            return this.donePath();
        }
    }

    donePath() {
        return workoutDoPathFor(this.date,'done');
    }

    nextSetFor(set) {
        const list = this.orderedExerciseSets();
        return list[list.indexOf(set) + 1];
    }

    rateableExerciseSpecs() {
        return _.filter(this.exerciseSpecifications, spec => !spec.isWarmupSetType());
    }

    orderedExerciseSets() {
        return (this.orderedSets = this.orderedSets || _.flatMap(this.allSupersets(),superset => superset.exerciseSets()))
    }

    orderedIndices() {
        return (this.orderedIndicesArr = this.orderedIndicesArr || _.flatMap(this.orderedExerciseSets(),set => set.workoutSetIndex()).concat(['done']))
    }

    allSupersets() {
        const warmupSupersets = this.warmup ? this.warmup.supersets() : [];
        const cooldownSupersets = this.cooldown ? this.cooldown.supersets() : [];
        return _.concat(warmupSupersets,this.supersets(),cooldownSupersets);
    }

    supersets() {
        return _.compact(this.exerciseSpecifications.map(spec => spec.supersetArr()));
    }

    timeDone() {
        const doneSets = _.filter(this.orderedExerciseSets(),set => set.logged());
        return _.reduce(doneSets,(totalTime,set) => (totalTime + set.estimatedTime()),0);
    }

    estimatedTime() {
        return _.reduce(this.orderedExerciseSets(),(totalTime,set) => (totalTime + set.estimatedTime()),0);
    }

    percentDone() {
        const pct = (this.timeDone()/Math.max(this.estimatedTime(),1))*100;
        return pct;
    }

    estTimeStr(t) {
        if(!this.isFullyLoaded()) {
            return '';
        }
        const time = Math.round(_.roundToF(this.estimatedTime(),300)/60);
        return `${Math.max(time-10,0)}-${time+5} ${t('mins')}`
    }

    warmupId() {
        return this.workoutTemplate && this.workoutTemplate.warmupId;
    }

    cooldownId() {
        return this.workoutTemplate && this.workoutTemplate.cooldownId;
    }

    warmupTitle(t) {
        if(this.warmup.isDefaultWarmup()) {
            return t("Warmup/mobility");
        } else {
            return t("Warmup");
        }
    }

    warmupDesc(t) {
        if(this.warmup.isDefaultWarmup()) {
            return `${t("A quick series of exercises to warm you up")} (${Math.round(this.warmup.estimatedTime()/60)+1} ${t('mins')})`
        } else {
            return t("5 - 10 minutes of walking")
        }
    }

    cooldownTitle(t) {
        if(this.cooldown.isDefaultWarmup()) {
            return t("Cooldown/stretching");
        } else {
            return t("Cooldown");
        }
    }

    cooldownDesc(t) {
        if(this.cooldown.isDefaultWarmup()) {
            return `${t("A series of stretches to improve flexibility")} (${Math.round(this.cooldown.estimatedTime()/60)+1} ${t('mins')})`;
        } else {
            return t("5 - 10 minutes of walking");
        }
    }

    isDefaultWarmup() {
        return this.exerciseSpecifications.length > 1;
    }

    imagePath() {
        return `${Workout.imagesBasePath()}${this.imageName}`
    }

    isFullyLogged() {
        return _.every(this.workSets(),set => set.logged());
    }

    isAnyLogged() {
        return (this.loggedWorkSets().length > 0)
    }

    needsCooldown() {
        return (this.cooldown && !this.cooledDown);
    }

    needsWarmup() {
        return (this.warmup && !this.warmedUp && !this.isAnyLogged());
    }

    firstUnloggedSet() {
        if(this.isFullyLogged()) {
            if(this.needsCooldown()) {
                const set = _.find(this.orderedExerciseSets(),set => set.workoutSection() === 'cooldown');
                return set ? set : null;
            } else {
                return null;
            }
        } else if(this.needsWarmup()) {
            return this.orderedExerciseSets()[0];
        } else {
            const set = _.find(this.orderedExerciseSets(),set => (set.unlogged() && set.workoutSection() === 'workout'))
            return set ? set : null;
        }
    }

    currentSection(setIndex) {
        const set = this.setByIndex(setIndex);
        if(set) {
            return set.workoutSection();
        }
        return 'workout';
    }

    shouldBeSynced() {
        return (this.isAnyLogged())
    }

    needsSyncing() {
        return ['failed','unsynced'].includes(this.syncStatus) && this.shouldBeSynced();
    }

    isFullyLoaded() {
        return (this.exerciseSpecifications && this.exerciseSpecifications.length !== 0)
    }

    exerciseSpecificationIds() {
        return this.exerciseSpecifications.map(spec => spec.id);
    }

    exerciseSets() {
        return _.flatten(this.exerciseSpecifications.map(spec => spec.exerciseSets));
    }

    exerciseSetIds() {
        return this.exerciseSets().map(set => set.id);
    }

    hasAllSets(setIds) {
        return _.difference(setIds,this.exerciseSetIds()).length <= 0
    }

    workSets() {
        return _.flatten(this.exerciseSpecifications.map(spec => spec.workSets()));
    }

    loggedSets() {
        return _.filter(this.exerciseSets(),set => set.logged());
    }

    loggedWorkSets() {
        return _.filter(this.workSets(),set => set.logged());
    }

    loggedSpecIds() {
        return _.filter(this.exerciseSpecifications,spec => spec.anyWorkLogged()).map(spec => spec.id);
    }

    syncWeights(sets) {
        let usedKeys = [];
        let updatedSets = [];
        sets.forEach(set => {
            const loadingKey = set.loadingKey();
            if((!_.isBlank(set.weight) || !_.isBlank(set.arbitraryMeasure)) && !usedKeys.includes(loadingKey)) {
                const linked = set.linkedSets();
                linked.forEach(linkedSet => {
                    linkedSet.weight = set.weight;
                    linkedSet.arbitraryMeasure = set.arbitraryMeasure;
                });
                updatedSets = [ ...updatedSets, ...linked ];
                usedKeys.push(loadingKey);
            }
        })

        return updatedSets;
    }

    isStarted() {
        return (this.warmedUp || this.loggedSets().length > 0)
    }

    getSet(id) {
        return _.find(this.exerciseSets(),set => (set.id === Number(id)));
    }

    getSpec(id) {
        return _.find(this.allSpecs(),spec => (spec.id === Number(id)));
    }

    specIndex(spec) {
        return this.exerciseSpecifications.indexOf(spec);
    }

    specByIndex(index) {
        return this.exerciseSpecifications[index];
    }

    allSpecs() {
        let specs = [];
        if(this.warmup) {
            specs =specs.concat(this.warmup.exerciseSpecifications);
        }

        specs = specs.concat(this.exerciseSpecifications);

        if(this.cooldown) {
            specs = specs.concat(this.cooldown.exerciseSpecifications);
        }

        return specs;
    }

    setByIndex(setIndex) {
        if(setIndex === 'done') {
            return null;
        } else {
            const match = setIndex.match(/(\d+)-(warmup|work)-(\d+)/);
            if(match) {
                const [ , specId, setType, typeIndex ] = match;
                const spec = this.getSpec(specId);
                if(spec && spec.exerciseSets.length > 0) {
                    const numIndex = Number(typeIndex);
                    if(setType === 'warmup') {
                        const sets = spec.warmupSets();
                        const set = sets[numIndex] || spec.workSets()[0] || spec.exerciseSets[0];
                        if(set) {
                            return set;
                        }
                    } else {
                        const sets = spec.workSets();
                        const set = sets[numIndex] || sets[sets.length-1] || spec.exerciseSets[spec.exerciseSets.length-1];
                        if(set) {
                            return set;
                        }
                    }
                }
            }
        }
        return this.firstUnloggedSet();
    }

    setIndex(set) {
        return this.orderedExerciseSets().indexOf(set);
    }

    extractForLog() {
        return this.extract([
            'name',
            'imageCategoryId',
            'logged',
            'cooledDown',
            'warmedUp',
            'workoutNumber',
            'cardioNumber',
            'date',
            'difficultyRating',
            'convenienceRating',
            'userFeedback',
            'startedAt',
            'finishedAt',
            'previewShown',
            'cardioTemplateId',
            'workoutTemplateId',
            { 
                exerciseSpecifications: [
                    'id',
                    'exerciseId',
                    'workoutOrder',
                    'setType',
                    'deloaded',
                    'superset',
                    'supersetStarted',
                    'stalledDays',
                    'reconcileNeeded',
                    'difficultyRating',
                    'minThreshold', { 
                        exerciseSets: [
                            'id',
                            'logType',
                            'reps',
                            'weight',
                            'unbroken',
                            'time',
                            'distance',
                            'arbitraryMeasure',
                            'restTime',
                            'amrap',
                            'setOrder',
                            'minReps',
                            'maxReps',
                            'warmup'
                        ]
            }
        ]}]);
    }
}

registerInflection('workout', 'workouts', Workout);