import React from 'react';
import { useTranslation } from 'react-i18next';
import { swapMealPathFor, editTempMealPath, editWeeklyMealMatch, editRecipeMatch, emailPaywallMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { userWithMealPlanSelector, mealSearchFullSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import SearchPage, { SelectMealButton } from 'partials/MealAndRecipeSearch';
import { replaceMeals, startEditTempMeal, startSwapMeals, updateMealSearch } from 'redux/actions';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { resolvedHomePath } from 'redux/helpers';

const transitionMap = {
    rules: [
        [[...mealPlanSlideOutPaths, editWeeklyMealMatch ],transitions.slideOut],
        [[editTempMealPath,editRecipeMatch,...emailPaywallMatches], transitions.slideOver]
    ]
};

const mapStateToProps = state => ({
    user: userWithMealPlanSelector(state),
    mealSearch: mealSearchFullSelector(state)
})

const mapDispatchToProps = dispatch => ({
    startSwapMeals: data => dispatch(startSwapMeals(data)),
    searchAction: (data,page) => dispatch(updateMealSearch(data,page)),
    selectAction: (data) => dispatch(replaceMeals(data)),
    startEditTempMeal: meal => dispatch(startEditTempMeal(meal))
})

const FindMealReplacementPage = ({ history, match: { params: { id: urlMealTypeId, week } }, user, mealSearch, startSwapMeals, selectAction, startEditTempMeal, ...props }) => {
    const { t } = useTranslation();
    let redirect = null;
    let searchAlreadyLoaded = null;
    urlMealTypeId = Number(urlMealTypeId);
    const mealType = user.findMealType(urlMealTypeId)
    let title = t('Replace X',{ meal_count: t('Meals')});
    const mealAction = ({ mealSearch, meal }) =>  selectAction({ contextId: mealSearch.contextId, sideDishes: meal.rawMealObject().sideDishes });
    const recipeAction = ({ mealSearch, meal }) => selectAction({ contextId: mealSearch.contextId, recipeId: meal.recipeMeals[0].recipeId })
    const getClickCardProps = (tabType,meal) => {
        const seed = tabType === 'recipes' ? { recipeId: meal.mainRecipe().id, needRandom: true } : meal.rawMealObject();
        const onClick = () => {
            startEditTempMeal(seed);
            history.push(editTempMealPath);
        }
        return { onClick }
    }

    if(!mealSearch.matchesContext({ type: 'MealType', id: urlMealTypeId })) {
        redirect = resolvedHomePath();
    } else {
        searchAlreadyLoaded = () => mealSearch.alreadyLoaded();
        const catStr = `${_.upperFirst(mealType.category)}s`;
        let mealCount;
        if(mealSearch.alreadyLoaded()) {
            mealCount = mealSearch.targetDates.length;
        } else {
            mealCount = _.filter(_.union(mealSearch.dates,mealSearch.weekdays),thing => !_.isBlank(thing)).length;
        }
        title = t('Replace X',{ meal_count: t(`X ${catStr}`,{ count: mealCount })});
    }

    return (
        <SearchPage
            mealSearch={mealSearch}
            searchAlreadyLoaded={searchAlreadyLoaded}
            redirect={redirect}
            title={title}
            user={user}
            transitionMap={transitionMap}
            ActionComp={<SelectMealButton action={mealAction} />}
            RecipeActionComp={<SelectMealButton action={recipeAction} />}
            initAction={startSwapMeals.bind(null,mealSearch.initValues())}
            basePath={swapMealPathFor(urlMealTypeId,week)}
            getClickCardProps={getClickCardProps}
            {...props}
        />
    )
}

export default connect(mapStateToProps,mapDispatchToProps)(FindMealReplacementPage)