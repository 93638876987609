import React from 'react';
import { Route } from 'react-router-dom';
import { withRouter } from 'react-router';
import { TransitionGroup, Transition } from 'react-transition-group';
import * as _ from 'lib/utilities';
import { modalPathFor } from 'config/paths';
import { UpperRightBtn } from './Button';
import classnames from 'classnames';
import PlaceholderImage from './PlaceholderImage';
import { Link } from './Routing';

export const PlaceholderBackedImg = ({ src, blankMessage, className, fullViewPath, imgId }) => {
    const Comp = _.isBlank(fullViewPath) ? 'div' : Link;
    const addlProps = !_.isBlank(fullViewPath) ? { to: fullViewPath } : {};

    return (
        <Comp className={classnames("ex-vid-parent limit-height", { [className]: className })} {...addlProps} >
            <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
            {src && (<img src={src}  alt="" id={imgId} />)}
            {!src && blankMessage && (
                <div className="vid-upload-txt">
                    {blankMessage}
                </div>
            )}
        </Comp>
    )
}
class ImageViewerCore extends React.Component {

    constructor(props) {
        super(props);
        if(_.isBlank(this.getUrl())) {
            this.props.history.go(-1);
        }
        this.ref = React.createRef();
    }

    componentDidMount() {
        window.getComputedStyle(this.ref.current).getPropertyValue('transform'); //somehow necessary to get transition to work
    }

    render() {
        const { history, status } = this.props;
        const url = this.getUrl();

        return (
            <div className={classnames("img-view-cont", { [status]: status })} ref={this.ref}>
                <div className="img-view-bcg" onClick={() => history.go(-1)}>
                    <UpperRightBtn />
                </div>
                <img src={url} alt="" />
            </div>
        )
    }

    getUrl = () => {
        const { match, getImageUrl } = this.props;
        const url = getImageUrl(match);

        return url;
    }
}

let ImageViewer = (props) => {
    
    const { pathSuffix, exact, location, match, history, ...otherProps } = props;
    const { options: { inDuration } = {} } = props;

    return (
        <TransitionGroup>
            <Transition key={location.pathname} timeout={inDuration || 300}>
                { state => (
                    <Route location={location} path={modalPathFor(pathSuffix,location.pathname)} exact={exact} render={(routeProps) => (
                        <ImageViewerCore {...routeProps} {...otherProps} status={state} />
                    )} />
                )}
            </Transition>
        </TransitionGroup>
    )

}

ImageViewer = withRouter(ImageViewer);

export default ImageViewer;