import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import SearchPage, { SelectMealButton, SelectWithServingsButton } from 'partials/MealAndRecipeSearch';

export const selectActionCreator = (action,recipeId) => ({ meal }) => action({ recipeId: _.isBlank(recipeId) ? '' : Number(recipeId), replacementId: meal.recipeMeals[0].recipeId, replacementServings: meal.recipeMeals[0].servings })

const RecipeReplacementPage = ({ basePath, id, getPath, redirect, title, user, mealSearch, startSwapRecipe, selectAction, transitionMap, ...rest }) => {
    const { t } = useTranslation();
    let searchAlreadyLoaded = null;
    const getClickCardProps = (tabType,meal) => {
        return { linkTo: getPath({ sideDishStubs: meal.urlStub() }) }
    }

    if(!redirect) {
        searchAlreadyLoaded = () => mealSearch.alreadyLoaded();
    }

    return (
        <SearchPage
            mealSearch={mealSearch}
            searchAlreadyLoaded={searchAlreadyLoaded}
            redirect={redirect}
            title={t(title)}
            user={user}
            transitionMap={transitionMap}
            ActionComp={<SelectMealButton action={selectAction} />}
            RecipeActionComp={<SelectWithServingsButton action={selectAction} />}
            initAction={startSwapRecipe.bind(null,mealSearch.initValues())}
            basePath={basePath}
            getClickCardProps={getClickCardProps}
            {...rest}
        />
    )
}

export default RecipeReplacementPage;