import React, { useState } from 'react';
import { Redirect } from "components/Routing";
import { ScrollableFormModal } from 'components/Modal';
import RecipeMealRow from 'partials/RecipeMealRow';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import loadingContainer from 'components/LoadingHOC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { makeUserMealFromIdSel } from 'redux/selectors';
import { loadRecipes, logMeal } from 'redux/actions';
import { Formik } from 'formik';
import * as Yup from 'yup';
import PlusMinusToggle, { PlusMinusField } from 'components/PlusMinusToggle';

const filterSubmitValues = (values) => {
    const recipeMeals = [];
    values.recipeMeals.forEach(recipeMeal => {
        recipeMeals.push(_.getAttrIndif(recipeMeal,['readableServings','mainDish','recipeId']));
    })
    return { id: values.id, recipeMeals }
}

const cleanInitialValues = (userMeal) => {
    return userMeal;
}

class SubmitSuccess extends React.Component {

    constructor(props) {
        super(props);
        props.setTriggerRatingRequest(true);
        props.close();
    }

    render() {
        return '';
    }
}

const SubmitDefault = ({ t, close }) => {

    return (
        <React.Fragment>
            <Button key="cancel-btn" rounded color="primary" outlined onClick={close} ><FontAwesomeIcon icon="ban" /> {t("Cancel")}</Button> 
            <Button key="log-btn" tag='button' id="log-meal-modal-btn" rounded color="primary" type="submit" className="ml5"><FontAwesomeIcon icon="check" /> {t("Log")}</Button>
        </React.Fragment>
    )
}

const SubmitLOC = loadingContainer({
    "DEFAULT": SubmitDefault,
    "SUCCESS": SubmitSuccess
})

const RecipeMealServingsField = ({ recipeMeal, index, errors, setFieldValue, handleBlur, t }) => {
    const fieldName = `recipeMeals.${index}.servings`;
    const error = _.get(errors,fieldName);
    const { recipe } = recipeMeal;
    const changeHandler = (newVal,type) => {
        newVal = _.isBlank(newVal) ? '' : newVal/recipeMeal.countPerServing()
        setFieldValue(fieldName,newVal);
    }

    return (
        <PlusMinusToggle 
            tighter
            inline
            value={recipeMeal.readableServings()} 
            setValue={changeHandler}  
            error={error} 
            render={({ value, error, onChange}) => {
                return (
                    <PlusMinusField 
                    label={recipe.defaultedServingName(t)}
                    onChange={onChange} 
                    onBlur={handleBlur} 
                    value={value} 
                    error={error} 
                    name={fieldName}/>
                )
        }} />
    )
}

class Form extends React.Component {

    constructor(props) {
        super(props);
        this.state = { status: "DEFAULT" };
        this.setStatus = this.setStatus.bind(this);
        this.loadRecipes = this.loadRecipes.bind(this);
        props.setTriggerRatingRequest(false);
        
        if(props.userMeal) {
            this.loadRecipes();
        }
    }

    setStatus(status) {
        this.setState({ status: status });
    }

    loadRecipes() {
        const { userMeal, loadRecipes } = this.props;
        return loadRecipes(userMeal.recipeIds());
    }

    render() {
        const { userMeal, submitAction, close, basePath, user, t, renderFooter, renderHeader, renderScrollable } = this.props;

        if(!userMeal) {
            return (<Redirect to={basePath} />)
        } else {
            return (
                <React.Fragment>
                    {renderHeader({ children: (
                        <div className="left-align-block">
                            <b>{t("What did you eat?")}</b>
                            <div className="subtitle">
                                <FontAwesomeIcon icon="info-circle" className="info-color" /> {t("Leave as is to log full meal")}
                            </div>
                        </div>
                    )})}
                    <Formik initialValues={ cleanInitialValues({ ...userMeal }) }
                        validationSchema={Yup.object().shape({
                            recipeMeals: Yup.array().of(Yup.object().shape({
                                servings: Yup.number().min(0).required()
                            }))
                        })}
                        onSubmit={_.formikHandlerCreator(this,this.setStatus.bind(this),submitAction,filterSubmitValues)}
                        initialErrors={{}} >
                            {({ setFieldValue, handleBlur, handleSubmit, values, errors }) => {
                                return (
                                    
                                        <form onSubmit={handleSubmit}>
                                            {renderScrollable({ children: values.recipeMeals.map((recipeMeal,index) => {
                                                const updatedRecMeal = _.find(userMeal.recipeMeals,(recMeal) => (recMeal.id === recipeMeal.id));
                                                if(updatedRecMeal) {
                                                    recipeMeal.recipe = updatedRecMeal.recipe;
                                                }
                                        
                                                return (
                                                    <RecipeMealRow key={recipeMeal.id} recipeMeal={recipeMeal} index={index} user={user} type='log' load={this.loadRecipes} contentRender={() => {
                                                        return (
                                                            <div className="text-center mt10" id={`rm-servings-field-${recipeMeal.id}`}>
                                                                <RecipeMealServingsField recipeMeal={recipeMeal} index={index} errors={errors} handleBlur={handleBlur} setFieldValue={setFieldValue} t={t} />
                                                            </div>
                                                        )
                                                    }} />
                                                )
                                            })})}
                                            {renderFooter({ children: (
                                                <SubmitLOC close={close} setTriggerRatingRequest={this.props.setTriggerRatingRequest} loadState={this.state.status} t={t} />
                                            )})}
                                        </form>
                                    
                                )
                            }}
                    </Formik>
                </React.Fragment>
            )
        }
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }
}

const makeMapStateToProps = () => {
    const userMealSelector = makeUserMealFromIdSel();
    return (state,props) => ({
        userMeal: userMealSelector(state,props)
    })
}

const mapDispatchToProps = dispatch => ({
    loadRecipes: (ids) => dispatch(loadRecipes(ids)),
    submitAction: (data) => dispatch(logMeal(data))
})

const ConnectedForm = connect(makeMapStateToProps,mapDispatchToProps)(Form);

const MealLogModal = ({ pathMatch, basePath, user, requestRating }) => {

    const { t } = useTranslation();
    const [triggerRatingRequest,setTriggerRatingRequest] = useState(false); 
    const closeCallback = () => {
        if(triggerRatingRequest) {
            requestRating();
        }
    }


    return (
        <React.Fragment>
            <ScrollableFormModal fullWidth path={pathMatch} exact scrollable closeCallback={closeCallback} render={(modalProps) => (
                <ConnectedForm 
                    {...modalProps}
                    id={modalProps.match.params.id}
                    t={t}
                    user={user}
                    basePath={basePath}
                    setTriggerRatingRequest={setTriggerRatingRequest}
                />
            )} />
        </React.Fragment>
    )
}

export default MealLogModal;