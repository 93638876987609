import React from 'react';
import RouteModal, { ModalHeader, ModalFooter } from 'components/Modal';
import { signupModalPathFor } from 'config/paths';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { resolvedStartPath } from 'redux/helpers';

const ModalContent = ({ closeAndGo }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b><FontAwesomeIcon icon="user-plus" /> {t("Sign Up First")}</b>
            </ModalHeader>
            <div className="pa20 text-center">
                {t("It only takes a minute")}
            </div>
            <ModalFooter defaults>
                <Button rounded noShadow color="primary" onClick={() => closeAndGo(resolvedStartPath())}>
                    <FontAwesomeIcon icon="user-plus" />
                    {t('Sign Up')}
                </Button>
            </ModalFooter>
        </React.Fragment>
    )
}

export const SignupModal = ({ basePath }) => {
    return (
        <RouteModal fullWidth limitWidth path={signupModalPathFor(basePath)} exact render={({ closeAndGo }) => (
            <ModalContent closeAndGo={closeAndGo} />
        )} />
    )
}