import React from 'react';
import { allMainMatches, wimageCatsPath } from 'config/paths';
import { workoutImageCatSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { updateWimageCat } from 'redux/actions';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import FormikWrapper from 'components/FormikWrapper';
import * as _ from 'lib/utilities';
import { CardForm, FullInput, LoaderOnlySubmitButton, MultivalueCheckbox } from 'components/Form';
import TextInput from 'components/TextInput';
import { SlightEmphasisIconNote } from 'components/Typography';

const transitionMap = {
    rules: [
        [[...allMainMatches,wimageCatsPath],transitions.slideOut],
        [[], transitions.slideOver]
    ]
};

const successCallbackCreator = (history) => () => {
    history.go(-1);
}

const variantNames = ({ baseName, gendered, bwAndWeighted }) => {
    let bwVars = [''];
    if(bwAndWeighted && !_.isBlank(bwAndWeighted)) {
        bwVars = ['_bw','_wt'];
    }

    let genVars = [''];
    if(gendered && !_.isBlank(gendered)) {
        genVars = ['_f','_m'];
    }

    let variants = [];
    for(let bwStub of bwVars) {
        for(let genStub of genVars) {
            variants.push(`${baseName}${bwStub}${genStub}.png`)
        }
    }

    return variants.join(', ')
}

class WorkoutImageCatCore extends React.Component {

    render() {
        const { categories, update, history, match: { params: { id }} } = this.props;
        let initialVals = { baseName: '', bwAndWeighted: false, gendered: false, genericCardio: false };

        if(id !== 'new') {
            const category = _.find(categories,cat => cat.id === Number(id));
            if(category) {
                initialVals = _.parseObjForForm(_.pick(category,['id','baseName','bwAndWeighted','gendered','genericCardio']));
            }
        }

        return (
            <div className="row">
                <div className="col s12">
                    <FormikWrapper 
                        initialValues={initialVals}
                        validate={({ baseName }) => {
                            if(_.isBlank(baseName)) {
                                return { baseName: "can't be blank" }
                            }
                            if(!baseName.match(/^\w+$/)) {
                                return { baseName: "only use letters, numbers, and underscored" }
                            }
                        }}
                        initialErrors={{}}
                        submit={update}
                        successCallback={successCallbackCreator(history)}
                        render={({ handleSubmit, submitState, ...formikProps }) => {
                            return (
                                <CardForm className="pa20" onSubmit={handleSubmit}>
                                    <div className="valign-wrapper">
                                        <FullInput
                                            className="flex-grow"
                                            name="baseName"
                                            label={"Base Name"}
                                            component={TextInput}
                                            formikProps={formikProps}
                                        />
                                    </div>
                                    <div className="valign-wrapper mt10">
                                        <MultivalueCheckbox 
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            name="gendered"
                                            label={"Does this category have a separate image for men and women?"}
                                            setFieldValue={formikProps.setFieldValue}
                                            values={formikProps.values}
                                        />
                                    </div>
                                    <div className="valign-wrapper mt10">
                                        <MultivalueCheckbox 
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            name="bwAndWeighted"
                                            label={"Does this category have a separate image for bodyweight and weighted?"}
                                            setFieldValue={formikProps.setFieldValue}
                                            values={formikProps.values}
                                        />
                                    </div>
                                    <div className="valign-wrapper mt10">
                                        <MultivalueCheckbox 
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            name="genericCardio"
                                            label={"Should this category be used for generic cardio workouts?"}
                                            setFieldValue={formikProps.setFieldValue}
                                            values={formikProps.values}
                                        />
                                    </div>
                                    <SlightEmphasisIconNote 
                                        variant="warning"
                                        text={`Please provide images with the following names to the system administrator before attaching this category to any workouts: ${variantNames(formikProps.values)}`}
                                    />
                                    <div className="text-center pa10">
                                        <LoaderOnlySubmitButton noShadow icon="check" label="Save" loadState={submitState} />
                                    </div>
                                </CardForm>
                            )
                        }}
                    />                
                </div>
            </div>
        )
    }
}

const WorkoutImageCatPage = ({ scrollRef, setupTransitions, match: { params: { id }}, match, ...props }) => {

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={id === 'new' ? "New Category" : "Edit Category"}
        >
            <WorkoutImageCatCore 
                match={match}
                {...props}
            />
        </SimpleNavPage>
    )
}

const mapStateToProps = state => ({
    categories: workoutImageCatSelector(state)
})

const mapDispatchToProps = dispatch => ({
    update: (data) => dispatch(updateWimageCat(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(WorkoutImageCatPage);