import React, { useState } from 'react';
import { SlightEmphasisIconNote } from 'components/Typography';
import { analyticsSelector, hasUnsavedChangesSel, muxUploadProgs, seenTooltips, shouldShowTipCreator, showMpExitButton, trainerRecordSelector, trainerSelector, userRecordSelector, userSelector } from 'redux/selectors';
import { deactivateRecipe, dislikeRecipe, dismissTooltip, getAppLinks, jumpToBrowser, likeRecipe, startMuxUpload, tempDismissTip } from 'redux/actions';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import Tooltipped from 'components/Tooltipped';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import requestStateHandler from 'components/RequestStateHandler';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { Link } from 'components/Routing';
import Dropdown, { DropdownItem, DropdownLink, DropdownTip, MultiLevelDropdown } from 'components/Dropdown';
import Button, { ConfirmActionButton, SimpleConfirmActionButton } from 'components/Button';
import { BasicModal } from 'components/Modal';
import { likeRecipeForClientsPathFor } from 'config/paths';
import { useHistory, useLocation } from 'react-router-dom';
import { DefaultSelect, InputWithErrors, ModalSelectListing, VideoUploadBtn } from 'components/Form';
import { SplitButtonRow } from 'components/SplitButtonRow';
import { ClientFilterButton } from './ClientDropdowns';
import { User } from 'lib/classes';
import downloadAppIos from 'assets/img/download_app_ios.svg';
import blankProfileImg from 'assets/img/BlankProfile.png';
import ClientScroller from './ClientScroller';
import TextInput, { AutoSubmitTextInput } from 'components/TextInput';
import { SimpleNavRightButton } from 'components/Nav';
import { resolvedHomePath } from 'redux/helpers';
import { Loader } from 'components/LoadingHOC';
import { TrainerSelectScroller } from './TrainerScroller';
import PWAPrompt from 'react-ios-pwa-prompt'
import { ExerciseVideo } from './ExerciseImage';
import PlaceholderImage from 'components/PlaceholderImage';
import { ListCardAction } from 'components/List';


export const handleServingChgCreator = (setServings) => (values,setValues) => (newVal) => {
    setServings(newVal);
    setValues({ ...values, servings: newVal });
}

export const handleSeqChangeCreator = (setSeq) => (values,setValues) => (newVal) => {
    setSeq(newVal);
    setValues({ ...values, seq: newVal });
}

let InitialTipPopup = ({ tipName, shouldShowTip, dismiss, tempDismiss, title, children, id, btn1Label, btn2Label, btn1Action, btn2Action, btn1Primary, btn2Primary, alwaysDismissForever }) => {
    const { t } = useTranslation();

    if(!shouldShowTip) {
        return '';
    }

    return (
        <BasicModal
            startOpen
            fullWidth
            className="limit-width"
            options={{ dismissible: false, endingTop: '10vh' }}
            triggerRender={() => ''} 
            contentRender={() => {
                return (
                    <React.Fragment>
                        <div className="center info-color pt15 pb15" id={id}>
                            <div style={{ fontSize: '100px', lineHeight: 1}}>
                                <FontAwesomeIcon icon="info-circle" />
                            </div>
                        </div>
                        <div className="text-center limit-50-h">
                            <h1>{title || t('Heads Up!')}</h1>
                            {children}
                            <div className="pa20">
                                <Button 
                                    color="primary" 
                                    noShadow 
                                    outlined={!btn1Primary}
                                    rounded 
                                    className="modal-close" 
                                    onClick={() => {
                                        setTimeout(() => {
                                            dismiss(tipName);
                                            btn1Action && btn1Action();
                                        },350);
                                    }}
                                >
                                    {btn1Label || t("Don't Show Again")}
                                </Button>
                                <Button 
                                    color="primary" 
                                    noShadow 
                                    rounded 
                                    outlined={!btn2Primary}
                                    className="modal-close ml5" 
                                    onClick={() => {
                                        setTimeout(() => {
                                            alwaysDismissForever ? dismiss(tipName) : tempDismiss(tipName);
                                            btn2Action && btn2Action();
                                        },350);
                                    }}
                                >
                                    {btn2Label || t("Got It")}
                                </Button>
                            </div>
                        </div>

                    </React.Fragment>
                )
            }} 
        />
    )
}

const mapStateToPopupProps = (state,{ tipName }) => ({
    shouldShowTip: shouldShowTipCreator(tipName)(state)
})

const mapDispatchToPopupProps = dispatch => ({
    dismiss: tipName => dispatch(dismissTooltip(tipName)),
    tempDismiss: tipName => dispatch(tempDismissTip(tipName))
})

InitialTipPopup = connect(mapStateToPopupProps,mapDispatchToPopupProps)(InitialTipPopup);

let SlightEmphasisTip = ({ dismiss, tipName, seenTooltips, ...props }) => {
    const [seen, setSeen] = useState(seenTooltips && seenTooltips.includes(tipName));
    const newDismiss = () => {
        setSeen(true);
        dismiss(tipName);
    }
    
    if(seen) {
        return '';
    } else {
        return (
            <SlightEmphasisIconNote 
                dismiss={newDismiss}
                {...props}
            />
        )
    }
}

const mapStateToTipProps = state => ({
    seenTooltips: seenTooltips(state)
})

const mapDispatchToTipProps = dispatch => ({
    dismiss: (tipName) => dispatch(dismissTooltip(tipName))
})

SlightEmphasisTip = connect(mapStateToTipProps,mapDispatchToTipProps)(SlightEmphasisTip);


let InitialTip = ({ tipName, onlyAfter, text, delay, dismiss, seenTooltips, children, onClick, noMouseOver, options, passThroughProps, ...tipProps }) => {
    const seen = seenTooltips && seenTooltips.includes(tipName);
    const prevNeeded = _.difference(onlyAfter || [],seenTooltips || []).length > 0;
    const [hide, setHide] = useState(false);
    const doDismiss = () => {
        setHide(true);
        if(!seen)
            dismiss(tipName);
    }
    const clickHandler = () => {
        onClick && onClick();
        doDismiss();
    }

    return (
        <Tooltipped 
            dismissCallback={doDismiss}
            autoShowAfter={delay}
            options={{
                html: text,
                classes: ['square'],
                ...options
            }}
            onClick={clickHandler}
            onMouseOver={noMouseOver ? null : doDismiss}
            disableTip={seen || hide || prevNeeded}
            passThroughProps={passThroughProps}
            {...tipProps}
        >
            {children}
        </Tooltipped>
    )
}

InitialTip = connect(mapStateToTipProps,mapDispatchToTipProps)(InitialTip)

export const InitialTippedButton = (props) => {

    return (<InitialTip {...props} noMouseOver />)
}

export const getLikeRecipeFor = (likeRecipe,recipeId) => suffix => (liked) => {
    if(liked) {
        return likeRecipe({ recipeId, type: `do${suffix}`});
    } else {
        return likeRecipe({ recipeId, type: `undo${suffix}`});
    }
}

export const LikeRecipeDropdown = ({ trainer, user, recipe, user: { clientLikedRecipeIds, trainerLikedRecipeIds }, likeRecipe, triggerRender }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const trainerLiked = trainerLikedRecipeIds.includes(recipe.staticId());
    const userLiked = clientLikedRecipeIds.includes(recipe.staticId());
    const showUserOption = !trainerLiked;
    const ownedByOther = recipe.isOwnedSolelyByOtherTrainer(trainer);
    const canLikeForUser = user.id === trainer.id || user.trainerId === trainer.id;
    const allowLike = !ownedByOther && canLikeForUser;
    const clientLikeRecipe = likeRecipe('');

    let userOption = null;
    if(showUserOption) {
        if(trainer.id === user.id) {
            userOption = (<DropdownLink 
                icon={'user'}
                label={t("Like for Specific Client(s)")}
                id="like-for-clients-btn"
                to={likeRecipeForClientsPathFor(pathname,recipe.id)}
            />)
        } else {
            userOption = (<DropdownItem 
                    icon={'user'} 
                    label={userLiked ? t("Unlike for this client") : t("Like for this client")} 
                    className={classnames({ 'red-text': userLiked })} 
                    id="like-for-client-btn" 
                    onClick={() => clientLikeRecipe(!userLiked)} 
            />)
        }
    }


    return (
        <React.Fragment>
            <MultiLevelDropdown
                options={ { coverTrigger: false, closeOnClick: true, closeOnChildClick: true } }
                triggerRender={triggerRender}
            >
                {!allowLike && (<DropdownTip text={ownedByOther ? t('cant favorite recipe') : t('cant favorite recipe for client')} />)}
                {allowLike && userOption}
                {allowLike && (<DropdownItem icon={'users'} label={trainerLiked ? t("Unlike for all my clients") : t("Like for all my clients")} id="like-for-all-clients-btn" onClick={() => likeRecipe('_trainer')(!trainerLiked)} />)}
            </MultiLevelDropdown>
        </React.Fragment>
    )
}

let LikeRecipeButtonCore = ({ isLiked, likeRecipe, className }) => {
    const props = isLiked ? { icon: 'heart', className: 'red-text' } : { icon: ['far','heart'], className: 'font-grey'}

    return (
        <div className={classnames("bottom-right me-heart",{ [className]: className })} onClick={() => likeRecipe(!isLiked)}>
            <div className="btn-flat me-heart-style">
                <FontAwesomeIcon {...props} />
            </div>
        </div>
    )
}

LikeRecipeButtonCore = requestStateHandler({ likeRecipe: 'isLiked' })(LikeRecipeButtonCore)

let LikeRecipeButton = ({ trainer, user, user: { likedRecipeIds, clientLikedRecipeIds, trainerLikedRecipeIds }, likeRecipe, recipe, className }) => {
    const recipeId = recipe.staticId();
    const isLiked = likedRecipeIds.includes(recipeId);

    if(!trainer) {
        return (<LikeRecipeButtonCore likeRecipe={getLikeRecipeFor(likeRecipe,recipe.id)('')} isLiked={isLiked} className={className} />);
    }

    const trainerLiked = trainerLikedRecipeIds.includes(recipeId);

    return (
        <LikeRecipeDropdown 
            trainer={trainer}
            user={user}
            likeRecipe={getLikeRecipeFor(likeRecipe,recipe.id)}
            recipe={recipe}
            triggerRender={({ ref, target }) => {
                const anyLiked = clientLikedRecipeIds.includes(recipeId) || trainerLiked;
                const props = anyLiked ? { icon: 'heart', className: 'red-text' } : { icon: ['far','heart'], className: 'font-grey'}

                return (
                    <div id={`like-recipe-drop-${recipe.id}`} className={classnames("bottom-right me-heart",{ [className]: className })} ref={ref} data-target={target}>
                        <div className="btn-flat me-heart-style">
                            <FontAwesomeIcon {...props} />
                        </div>
                    </div>
                )
            }}
        />
    )
}

const mapStateToLikeProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToLikeProps = dispatch => ({
    likeRecipe: ({ type, recipeId, clientId }) => dispatch(likeRecipe({type,recipeId,clientId}))
})

LikeRecipeButton = connect(mapStateToLikeProps,mapDispatchToLikeProps)(LikeRecipeButton)

let DontRecommendButtonCore = ({ isDisliked, isOwned, dislikeRecipe, trainer, type, id="dislike-recipe-btn" }) => {
    const { t } = useTranslation();
    const props = isDisliked ? { icon: (isOwned ? 'undo' : 'thumbs-down') } : { icon: (isOwned ? 'times' : ['far','thumbs-down']) }
    let msg;
    if(isDisliked) {
        if(isOwned) {
            msg = t('Undo Delete');
        } else {
            if(trainer) {
                msg = type === 'user' ? t('Un-dislike for this client') : t('Un-dislike for all clients');
            } else {
                msg = t("Won't Be Recommended");
            }
        }
    } else {
        if(isOwned) {
            msg = t('Delete');
        } else {
            if(trainer) {
                msg = type === 'user' ? t('Dislike for this client') : t('Dislike for all my clients');
            } else {
                msg = t("Don't Recommend Again");
            }
        }
    }
    return (
        <li onClick={() => dislikeRecipe(!isDisliked)} id={id}>
            <span className={classnames({'orange-text': (isDisliked && !isOwned), 'red-text': (isDisliked && isOwned)})}>
                <FontAwesomeIcon {...props} /> {msg}
            </span>
        </li>
    )
}

DontRecommendButtonCore = requestStateHandler({ dislikeRecipe: 'isDisliked' })(DontRecommendButtonCore)

let DontRecommendButton = ({ user, trainer, user: { dislikedRecipeIds, clientDislikedRecipeIds, trainerDislikedRecipeIds }, dislikeRecipe, deactivateRecipe, recipe }) => {
    const isOwned = recipe.isOwnedBy(trainer || user);
    const isDisliked = isOwned ? recipe.isInactive() : dislikedRecipeIds.includes(recipe.staticId());

    const newDislikeRecipe = (suffix,owned) => disliked => {
        if(owned) {
            return deactivateRecipe({ recipeId: recipe.id, type: `${disliked ? 'do' : 'undo'}${suffix}`});
        } else {
            return dislikeRecipe({ recipeId: recipe.id, type: `${disliked ? 'do' : 'undo'}${suffix}`});
        }
    }

    if(!trainer) {
        return (
            <DontRecommendButtonCore 
                dislikeRecipe={newDislikeRecipe('')} 
                isDisliked={isDisliked} 
                isOwned={isOwned} 
                trainer={trainer} 
                type='user' 
            />
        )
    }

    const trainerDisliked = isOwned ? recipe.isInactive() : trainerDislikedRecipeIds.includes(recipe.staticId());
    const canDislike = trainer.id === user.id || user.trainerId === trainer.id;

    if(canDislike) {
        return (
            <React.Fragment>
                {trainer.id !== user.id && !trainerDisliked && (
                    <DontRecommendButtonCore 
                        dislikeRecipe={newDislikeRecipe('',false)} 
                        isDisliked={clientDislikedRecipeIds.includes(recipe.staticId())} 
                        isOwned={false} 
                        trainer={trainer} 
                        type='user' 
                    />
                )}
                <DontRecommendButtonCore 
                    dislikeRecipe={newDislikeRecipe('_trainer',isOwned)} 
                    isDisliked={trainerDisliked} 
                    isOwned={isOwned}
                    trainer={trainer} 
                    id="dislike-recipe-trainer-btn"
                    type='trainer' 
                />
            </React.Fragment>
        )
    }

    return '';
}

const mapStateToDislikeProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToDislikeProps = dispatch => ({
    dislikeRecipe: ({ type, recipeId }) => dispatch(dislikeRecipe({type,recipeId})),
    deactivateRecipe: ({ type, recipeId }) => dispatch(deactivateRecipe({ type, recipeId })),
    likeRecipe: ({ type, recipeId }) => dispatch(likeRecipe({type,recipeId}))
})

DontRecommendButton = connect(mapStateToDislikeProps,mapDispatchToDislikeProps)(DontRecommendButton)

export const DislikeRecipeDropdown = ({ triggerRender, recipe }) => {

    return (
        <MultiLevelDropdown
            options={ { coverTrigger: false, closeOnClick: true, closeOnChildClick: true } }
            triggerRender={triggerRender}
        >
            <DontRecommendButton recipe={recipe} />
        </MultiLevelDropdown>   
    )
}

const TrainerBadgeIcon = () => {

    return (
        <div>
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={'circle'} style={{ color: '#ffc517' }} size='2x' className="shadowed-circle-icon" />
                <FontAwesomeIcon icon={'award'} color={'white'} size='2x' transform="shrink-4 right-2"  />
            </span>
        </div>
    )
}

export const TrainerBadge = ({ color='orange', className }) => {
    const { t } = useTranslation();
    return (
        <Tooltipped
            options={{ 
                html: t("recipe rec coach"),
                classes: ['square']
            }}
            component='div'
            className={classnames('warning-icon',{ [className]: className })}
        >
            <TrainerBadgeIcon color={color} innerIcon="award" transform="shrink-2" />
        </Tooltipped>
    )
}

let TrainerBadgeWithContext = ({ user, trainer, recipe, ...rest }) => {

    if(!user || !user.isTrainerLikedRecipe(recipe) || (trainer && user.id === trainer.id)) {
        return '';
    }

    return (
        <TrainerBadge {...rest} />
    )
}

const mapStateToTbadgeProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

TrainerBadgeWithContext = connect(mapStateToTbadgeProps)(TrainerBadgeWithContext)

const EllipsisCircle = () => {

    return (
        <div className="circle-img standalone">
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="200" fill="white" />
                <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize="150px" fontWeight="500" fill="black">...</text>
            </svg>
        </div>
    )
}

export const UserGroupImage = ({ users }) => {
    let list;
    let needsEllipsis = false;
    let classNum;
    if(users.length > 9) {
        list = users.slice(0,8);
        needsEllipsis = true;
        classNum = 9;
    } else {
        list = users.slice(0,9);
        classNum = list.length;
    }

    if(list.length <= 1) {
        return <UserIcon user={list[0]} lg />
    } else {
        return (
            <div className={`group-img-cont n${classNum}`}>
                {_.uniqBy(list,'id').map(u => (<UserIcon user={u} standalone key={u.id} />))}
                {needsEllipsis && <EllipsisCircle />}
            </div>
        )
    }

}

export const UserProfileImage = ({ user }) => {
    const path = user.profileImagePath('mediumThumb');
    const noImage = path === blankProfileImg;

    if(noImage) {
        return (
            <svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
                <rect width="200" height="200" fill={_.stringToColor(user.fullName())} />
                <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize="90px" fontWeight="500" fill="white">{user.initials()}</text>
            </svg>
        )
    } else {
        return (
            <img src={path} alt="" />
        )
    }
}

export const UserIcon = ({ user, lg, xs, standalone, className }) => (<div className={classnames("circle-img ml0",{ sm: !(lg || xs), xs, standalone, [className]: className })}>
    <UserProfileImage user={user} />
</div>)

export const TrainerSelectListing = ({ trainer, excludeTrainerIds, title, prependOpts=[], formikProps, name, single, noCheck, id, preSelectedIds=[], ...rest }) => {
    const { t } = useTranslation();
    const [filters,setFilters] = useState({ status: 'active', query: '' })
    if(excludeTrainerIds) excludeTrainerIds = excludeTrainerIds.map(id => Number(id));
    let col = [ ...prependOpts ];
    const noSelf = excludeTrainerIds && excludeTrainerIds.includes(trainer.id);
    if(!noSelf) {
        col.push({ text: t('Me'), value: trainer.id, icon: (<UserIcon user={trainer} />) });
    }

    let excludeIds = [ ...(excludeTrainerIds || []), trainer.id ];

    if(!_.isBlank(filters.query)) {
        col = [];
        excludeIds = [ ...(excludeTrainerIds || []) ];
    }

    return (
        <div id={id}>
            {title && (<div className="font-grey">
                {title}
            </div>)}
            <div className='modal-list-header mt5'>
                <AutoSubmitTextInput 
                    label={t('Search all team members')}
                    noHelperText
                    className="cli-search-field"
                    value={filters.query}
                    submitForm={() => {}}
                    submitBeforeUnmount
                    timeout={300}
                    inputProps={ {
                        autoFocus: true,
                        name: "query",
                        onChange: e => setFilters({ ...filters, query: e.target.value })
                    } }
                />
            </div>
            <TrainerSelectScroller 
                trainer={trainer}
                filters={filters}
                context="trainerSelect"
                preSelectedIds={preSelectedIds}
                excludeIds={excludeIds}
                render={({ trainers }) => {

                    return (
                        <ModalSelectListing
                            col={[ ...col, ...trainers.map(t1 => ({ text: t1.sortName(), value: t1.id, icon: (<UserIcon user={t1} />) })) ]}
                            name={name}
                            single={single}
                            noCheck={noCheck}
                            {...rest}
                            {...formikProps}
                        />
                    )
                }}
            />
        </div>
    )
}

export const ClientSearchButton = ({ toggleFocus, topQuery, setTopQuery, status, showAll, overrideLabel, focusByDefault=false }) => {
    const [focused,setFocused] = useState(focusByDefault);
    const [query,setQuery] = useState(topQuery);
    const { t } = useTranslation();
    const setFocusFinal = val => {
        setFocused(val);
        toggleFocus(val);
    }

    if(focused || !_.isBlank(query) || !_.isBlank(topQuery)) {
        return (
            <AutoSubmitTextInput 
                label={t(overrideLabel || "Search all clients", { type: showAll ? '' : _.lowerFirst((status === 'inactive' ? t('Deactivated') : t('Active')))})}
                noHelperText
                className="cli-search-field"
                value={query}
                submitForm={() => setTopQuery(query)}
                submitBeforeUnmount
                timeout={300}
                inputProps={ {
                    autoFocus: true,
                    name: "query",
                    onChange: e => setQuery(e.target.value),
                    onBlur: () => setFocusFinal(false),
                    onFocus: () => setFocusFinal(true)
                } }
            />
        )
    }

    return (
        <Button noShadow outlined color="primary" variant="icon-only" className="ml5" onClick={() => setFocusFinal(true)}>
            <FontAwesomeIcon icon={'search'}></FontAwesomeIcon>
        </Button>
    )
}

export const ClientSelectListing = ({ trainer, initialFilters, initialSelected, getSelectProps=client => ({}), formikProps, numName, selectedNum, listingProps, excludeFilters, containerClass, isModal, selectSort, context }) => {
    initialSelected = selectSort ? (initialSelected || _.get(formikProps.values,listingProps.name)) : null;
    const [filters,setFilters] = useState({ trainerId: 'me', status: 'active', sort: selectSort ? 'selectedAsc' : trainer.defaultClientSort(), selected: initialSelected, query: '', ...initialFilters });
    const showFilters = ((trainer.activeClientCount || 0) > 10) || (trainer.activeClients().length > 10);
    const numSelected = _.isBlank(selectedNum) ? (!_.isBlank(numName) ? _.compact(_.get(formikProps.values,numName)).length : null) : selectedNum;
    const [searchIsFocused,setSearchIsFocused] =  useState(false);
    const hideNonSearch = searchIsFocused || !_.isBlank(filters.query);
    const { t } = useTranslation();

    const filterBtn = (<ClientFilterButton 
        key="client-filter-btn"
        trainer={trainer} 
        filters={filters} 
        setFilters={setFilters}
        className={"ml5"} 
        hidden={hideNonSearch}
        omit={excludeFilters}
        isModal={isModal}
    />)

    const sortSelect = (
        <DefaultSelect
            key="sort-select"
            id="sort-select"
            className={classnames("ml5 inline-block",{ hidden: hideNonSearch })}
            name='sort'
            collection={User.sortCol(t,selectSort,trainer)}
            values={filters}
            handleChange={(e) => setFilters({ ...filters, sort: e.target.value })}
    />)

    const searchBtn = (
        <ClientSearchButton 
            key="search-btn"
            toggleFocus={val => setSearchIsFocused(val)}
            topQuery={filters.query}
            setTopQuery={query => setFilters({...filters, query })}
            status={filters.status}
        />
    )

    const leftChildren = [filterBtn,searchBtn,sortSelect];

    return (
        <div className={containerClass}>
            {showFilters && (<SplitButtonRow
                shrinkRight
                className={"mb10 flex-end"}
                leftChildren={leftChildren}
                rightChildren={_.isBlank(numSelected) ? [] : [
                    <div key="sel-msg" className="underline font-grey mb5">{t('X selected', { num: numSelected }) }</div>
                ]}
            />)}
            <ClientScroller 
                trainer={trainer}
                filters={filters}
                metricType={'none'}
                context={context}
                uneditableFilters={['hasBasicProfile']}
                render={({ clients }) => {
                    
                    return (
                        <ModalSelectListing
                            col={clients.map(client => ({ text: client.fullName(), value: client.id, icon: (<UserIcon user={client} />), ...getSelectProps(client) }))}
                            {...listingProps}
                            {...formikProps}
                        />
                    )
                }}
            />
        </div>
    )
}

const FatsecretWatermark = ({ side='right' }) => {

    return (
        <div style={{position: 'absolute', top: '5px', [side]: '5px'}}>
            <Link to="https://platform.fatsecret.com" target="_blank" rel="noopener noreferrer">
                <img src="https://platform.fatsecret.com/api/static/images/powered_by_fatsecret.png" srcSet="https://platform.fatsecret.com/api/static/images/powered_by_fatsecret_2x.png 2x, https://platform.fatsecret.com/api/static/images/powered_by_fatsecret_3x.png 3x" border="0" alt="" />
            </Link>
        </div>
    )
}

let JumpLink = React.forwardRef(({ jumpToBrowser, children, ...props },ref) => {

    if(window.isCordova) {
        const onClick = (e) => {
            e.preventDefault();
            jumpToBrowser(props.to);
        }
        return <a children={children} {...props} onClick={onClick} ref={ref} />;
    } else {
        return (<Link children={children} target="_blank" {...props} ref={ref} />)
    }
});

let CordovaDownloadButton = ({ url, render, jumpToBrowser }) => {
    const [downloading,setDownloading] = useState(false);

    const onClick = (e) => {
        e.preventDefault();
        setDownloading(true);
        jumpToBrowser(url);
        setTimeout(() => setDownloading(false),2000);
    }

    return render({ onClick, downloading });    
}

const mapDispatchToJumpProps = dispatch => ({
    jumpToBrowser: path => dispatch(jumpToBrowser(path))
})

JumpLink = connect(null,mapDispatchToJumpProps)(JumpLink)
CordovaDownloadButton = connect(null,mapDispatchToJumpProps)(CordovaDownloadButton)

const logAppStoreVisitEvent = (afterPath) => () => {
    _.mixpanelTrack('Web to App Store Visit');
    setTimeout(() => (window.location.href = afterPath),50);
}

const SlashedPrice = ({ oldPrice, discountPrice, dark, suffix }) => (<span><del className={classnames("price-slash", { dark })}>{`$${oldPrice}`}</del> {`$${discountPrice}${suffix ? suffix : ''}`}</span>)

class GetAppButtonsCore extends React.Component {
    render() {
        return (
            <div className="get-app-btns">
                <a href={this.playStoreLink()} onClick={logAppStoreVisitEvent(this.playStoreLink())}>
                    <img alt="Get it on Google Play" src="https://play.google.com/intl/en_us/badges/images/generic/en_badge_web_generic.png" />
                </a>
                <a href={this.appStoreLink()} className="app-store" onClick={logAppStoreVisitEvent(this.appStoreLink())}>
                    <img alt="Download on the App Store" src={downloadAppIos} />
                </a>
            </div>
        )
    }

    appStoreLink = () => {
        const { analytics: { originalSource, originalCampaign }={}, responseData: { linkInfo: { ios, override} } } = this.props;
        if(!_.isBlank(originalSource) && !override) {
            return `https://app.appsflyer.com/id1326334081?pid=${originalSource}&c=${originalCampaign || ''}`
        } else {
            return ios;
        }
    }

    playStoreLink = () => {
        const { analytics: { originalSource, originalCampaign }={}, responseData: { linkInfo: { android, override} } } = this.props;
        if(!_.isBlank(originalSource) && !override) {
            return `https://app.appsflyer.com/com.strongrfastr.cordova?pid=${originalSource}&c=${originalCampaign || ''}`
        } else {
            return android;
        }
    }
}

const GetPwaButton = ({ finishCallback }) => {
    const { t } = useTranslation();
    const [showPwaPrompt,setShowPwaPrompt] = useState(false);

    return (
        <React.Fragment>
            <div className="text-center pt30">
                <Button color="blue" rounded noShadow className="no-upcase" onClick={() => {
                    if(window.pwaInstallPromptEvent) {
                        window.pwaInstallPromptEvent.userChoice.then(() => (finishCallback && finishCallback()))
                        window.pwaInstallPromptEvent.prompt();
                    } else {
                        setShowPwaPrompt(true);
                    }
                }}>
                    <FontAwesomeIcon icon={['far','download']} /> {t('Install the App')}
                </Button>
            </div>
            {showPwaPrompt && (<PWAPrompt 
                timesToShow={99999} 
                delay={0} 
                onClose={() => (finishCallback && finishCallback())} 
                copyTitle={t('Install the App')} 
                copyBody={t('aths description')} 
                copyAddHomeButtonLabel={`2) ${t('aths instruction')}`} 
                copyClosePrompt={t('Dismiss')}
                permanentlyHideOnDismiss={false}
            />)}
        </React.Fragment>
    )
}

class GetAppButtons extends React.Component {

    render() {
        const { getAppLinks, finishCallback, ...rest} = this.props;

        if(_.getAppPromptType() === 'pwa') {
            return (
                <GetPwaButton finishCallback={finishCallback} />
            )
        }

        return (
            <Loader 
                successComponent={GetAppButtonsCore}
                type="blank"
                load={getAppLinks}
                preloaded={() => false}
                {...rest}
            />
        )
    }

}

const mapGaStateToProps = (state) => ({
    analytics: analyticsSelector(state),
    user: userRecordSelector(state)
})

const mapDispatchToGaProps = dispatch => ({
    getAppLinks: () => dispatch(getAppLinks())
})

GetAppButtons = connect(mapGaStateToProps,mapDispatchToGaProps)(GetAppButtons);

let ExitMealPlanButton = ({ shouldShow, alwaysShow, forMenu, className, hasUnsavedChanges, hideIfUnsavedChanges }) => {
    const history = useHistory();
    const { t } = useTranslation();

    if(hasUnsavedChanges && hideIfUnsavedChanges) {
        return null;
    }

    if(shouldShow || alwaysShow) {
        if(forMenu) {
            return (
                <DropdownLink label={t("Exit to Home")} icon="times" to={resolvedHomePath()} />
            )
        } else {
            return (
                <SimpleNavRightButton id="exit-activity-btn" icon="times" className={className ? className : "pr20"} onClick={() => history.push(resolvedHomePath())} />
            )
        }
    } else {
        return null;
    }
}

const mapStateToEMPProps = (state) => ({
    shouldShow: showMpExitButton(state),
    hasUnsavedChanges: hasUnsavedChangesSel(state),

})

ExitMealPlanButton = connect(mapStateToEMPProps,null)(ExitMealPlanButton);

let SIAC = ({ trainer, user, render }) => (render({ signedInAsClient: _.signedInAsClient(trainer,user) })) 

const mapStateToSIACProps = state => ({
    user: userSelector(state),
    trainer: trainerSelector(state)
})

SIAC = connect(mapStateToSIACProps)(SIAC);

let MuxUpoadMsg = ({ record,uploadProgs }) => {
    const { t } = useTranslation();
    let uploadMsg = null;

    if(record.muxPlaybackId === 'uploading') {
        const pct = uploadProgs && uploadProgs[`${record.constructor.NAME}|${record.id}`];
        if(pct && pct < 100) {
            uploadMsg = `${t('Video is uploading')} ${pct}%`;
        }
    } else if(record.muxPlaybackId === 'failed') {
        uploadMsg = <React.Fragment><FontAwesomeIcon color="red" icon="times" /> {t('Video failed to upload')}</React.Fragment>;
    }

    return uploadMsg && <div className="tiny-text faint-color">{uploadMsg}</div>;
}

const mapStateToMuxUploadProps = state => ({
    uploadProgs: muxUploadProgs(state)
})

MuxUpoadMsg = connect(mapStateToMuxUploadProps)(MuxUpoadMsg);

const VideoWillUploadMsg = ({ t, file, clearFile }) => {

    return (
        <div className="ex-vid-parent">
            <PlaceholderImage width={480} height={270} color="black" className="placeholder-svg" />
            <div className="vid-upload-txt">
                <div className="mb20">
                    <FontAwesomeIcon icon={["far","video"]} color="white" /> {file.name}
                </div>
                <div>
                    <SimpleConfirmActionButton component={Button} onClick={clearFile} variant="flat" color="white" noShadow>
                        <FontAwesomeIcon icon={"times"} /> {t('Delete')}
                    </SimpleConfirmActionButton>
                </div>
            </div>
        </div>
    )
}

const VideoUploadFields = ({ record, inputProps, t, videoFileRef, onChange: inOnChange, fixedAspect, name, formikProps, readOnly, primaryBtn, allowYT=true }) => {
    const [vidKey,setVideKey] = React.useState(_.random(999999));
    const [selectedVid,setSelectedVid] = React.useState(null);
    const showYTField = allowYT && !(record && record.muxAssetId);
    const err = formikProps && name && _.get(formikProps.errors,name);
    const touched = formikProps && name && _.get(formikProps.touched,name);
    const onChange = inOnChange || (e => {
        const file = e.target.files[0];
        if(file) {
            setSelectedVid(file);
            if(name && formikProps) {
                const { setFieldTouched, setFieldValue } = formikProps;
                setFieldTouched(name);
                setFieldValue(name,'noop');
            }
        }
    })
    const clearFile = () => {
        setVideKey(_.random(999999));
        setSelectedVid(null);
    }

    return (
        <React.Fragment>
            {selectedVid && (<VideoWillUploadMsg file={selectedVid} clearFile={clearFile} t={t} />)}
            {!selectedVid && record && (record.hasMuxVideo() || record.hasYouTubeVideo()) && (<div className="ex-vid-container">
                <ExerciseVideo exercise={record} fallbackToYT fixedAspect={fixedAspect} videoOnly />
            </div>)}
            <div className="valign-wrapper cntr mt5">
                {showYTField && (<InputWithErrors 
                    label={`YouTube ${t("URL")}`}
                    className="inline flex-grow"
                    name="videoUrl"
                    component={TextInput}
                    maxLength={120}
                    inProps={{ disabled: readOnly }}
                    {...inputProps}
                />)}
                {showYTField && (<div className="or-div">Or</div>)}
                <VideoUploadBtn ref={videoFileRef} outlined={!primaryBtn} key={vidKey} onChange={onChange} disabled={readOnly} />
            </div>
            {err && touched && <div className="red-text"><FontAwesomeIcon icon="times" /> {err}</div>}
        </React.Fragment>
    )
}

let InstantVideoUploadField = ({ startMuxUpload, onChange: inOnChange, ...rest }) => {
    const { record } = rest;

    return (
        <VideoUploadFields  
            onChange={(e) => {
                const file = e.target.files[0];
                if(file) {
                    startMuxUpload({ id: record.id, type: record.constructor.NAME },file);
                }
                inOnChange && inOnChange(e);
            }}
            {...rest}
        />
    )
}

const mapDispatchToIVUFProps = dispatch => ({
    startMuxUpload: (data,file) => dispatch(startMuxUpload(data,file))
})

InstantVideoUploadField = connect(null,mapDispatchToIVUFProps)(InstantVideoUploadField);

const MyRecordOptions = ({ record, importRecord, newDestroy, t, trainer, isDb, setImportCount, extraOpts }) => {
    const { pathname } = useLocation();
    const importSuccess = () => {
        if(isDb) {
            setImportCount();
        }
    }
    const canEdit = trainer.canEditTrainerRecord(record);

    return (
        <React.Fragment>
            {canEdit && (<li>
                <Link to={record.editPath(pathname)} id={`wrt-edit-btn-${record.id}`}>
                    <FontAwesomeIcon icon={['far','edit']} /> {t("Edit")}
                </Link>
            </li>)}
            {!canEdit && record.viewPath(pathname) && (<li>
                <Link to={record.viewPath(pathname)} id={`wrt-view-btn-${record.id}`}>
                    <FontAwesomeIcon icon={"eye"} /> {t("View")}
                </Link>
            </li>)}
            <li onClick={() => importRecord({ id: record.id, team: false },!isDb).then(importSuccess)} id={`copy-btn-${record.id}`}>
                <span>
                    <FontAwesomeIcon icon={['far','copy']} /> {(trainer.showTeamFeatures() || !canEdit) ? t("Copy to My Account") : t('Copy')}
                </span>
            </li>
            {trainer.showTeamFeatures() && (<li onClick={() => importRecord({ id: record.id, team: true },!isDb).then(importSuccess)} id={`team-copy-btn-${record.id}`}>
                <span>
                    <FontAwesomeIcon icon={['far','users']} /> {t("Copy to Team Level")}
                </span>
            </li>)}
            {extraOpts}
            {canEdit && (<ConfirmActionButton
                onClick={() => newDestroy()}
                render={({ onClick, ready, clickRef }) => (
                    <li onClick={onClick} ref={clickRef} className={ready ? null : 'dont-close-dropdown'} id={`wrt-delete-btn-${record.id}`}>
                        <span className={ready ? 'red-text' : null}>
                            <FontAwesomeIcon icon="times" /> {t("Delete")}
                        </span>
                    </li>
                )}
            />)}
        </React.Fragment>
    )
}

const MyRecordDropdown = ({ record, importRecord, newDestroy, t, isDb, setImportCount, trainer, extraOpts }) => {

    return (
        <Dropdown 
                contentComp='ul'
                options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                triggerRender={({ ref, target }) => {
                    return (
                            <ListCardAction ref={ref} data-target={target} id={`wrt-dropdown-btn-${record.id}`}>
                                <FontAwesomeIcon icon="ellipsis-h" />
                            </ListCardAction>
                    )
                }}
                contentRender={({ recalcDims }) => {
                    return (
                        <MyRecordOptions
                            record={record} 
                            importRecord={importRecord} 
                            newDestroy={newDestroy} 
                            t={t} 
                            trainer={trainer}
                            isDb={isDb} 
                            setImportCount={setImportCount}
                            extraOpts={extraOpts}
                        />
                    )
                }}
            />
    )
}

export { 
    SlashedPrice, 
    InitialTipPopup, 
    SlightEmphasisTip, 
    InitialTip, 
    LikeRecipeButton, 
    DontRecommendButton, 
    FatsecretWatermark, 
    TrainerBadgeWithContext, 
    JumpLink, 
    CordovaDownloadButton, 
    GetAppButtons, 
    ExitMealPlanButton,
    SIAC,
    MuxUpoadMsg,
    VideoUploadFields,
    InstantVideoUploadField,
    MyRecordDropdown
};