import React, { Component, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { basename, newClientModalPathFor, pastDuePath, trainerHomePathFor } from 'config/paths';
import withTrainer from 'partials/TrainerLoader';
import { connect } from 'react-redux';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { MainHeading, SlightEmphasisIconNote } from 'components/Typography';
import { useTranslation, withTranslation } from 'react-i18next';
import { AllCheck, AverageDashCard, ClientDashCard, TitleRow } from 'partials/ClientCards';
import { billableClientCount, loadClientsForDash, sendInvite, setClientFilters } from 'redux/actions';
import { CheckButtons, DefaultSelect } from 'components/Form';
import { SplitButtonRow } from 'components/SplitButtonRow';
import * as _ from 'lib/utilities';
import { clientFiltersSelector, clientsScrollMetaSel } from 'redux/selectors';
import { ClientActionsButton, ClientFilterButton } from 'partials/ClientDropdowns';
import { User } from 'lib/classes';
import { TagModal } from 'partials/TagModals';
import { NewClientModal } from 'partials/EditTrainerModal';
import { ActivityTab, ActivityTabs } from 'components/BottomNav';
import classnames from 'classnames';
import { FixedButtonTPB, LinkButtonTPB } from 'partials/ProRequiredButton';
import PickMealPlanPopup from 'partials/PickMealPlanPopup';
import { ClientSearchButton, InitialTipPopup } from 'partials/Utilities';
import { SIGN_IN_AS_CLIENT_TIP } from 'config/tooltips';
import { BrandingSetupCard, FormsSetupCard } from 'partials/SetupCards';
import ClientScroller, { getClientScrollInfo } from 'partials/ClientScroller';
import { ReassignModal, TrainerSelectModal } from 'partials/TrainerSelectModal';
import { Loader } from 'components/LoadingHOC';
import { ExportClientRoutinePDFModal } from 'partials/ExportPDFModal';
import { Link } from 'components/Routing';
import { ClientFormsModal } from 'partials/TrainerFormModals';

export const MetricTabs = ({ trainer, notTop }) => {
    const { t } = useTranslation();
    const types = ['summary','nutrition','exercise','habits'];
    if(trainer.needsFirstMpButton()) {
        return '';
    }

    return (
        <ActivityTabs className={classnames({ 'not-top': notTop, 'off-white': notTop, 'bottom-border': notTop })}>
            {types.map((type,index) => <ActivityTab id={"dash-tab-link-"+index} path={trainerHomePathFor(type)} label={t(_.capitalize(type))} key={type} />)}
        </ActivityTabs>
    )
}

class ClientCardList extends React.Component {

    render() {
        const { selected, setSelected, clients, metrics, metricType, t, trainer, basePath, sendInvite, showBillingTags } = this.props;

        const needsFirstMpButton = trainer.needsFirstMpButton()

        const showAvg = metricType === 'summary' && trainer.isAppTrainer() && !needsFirstMpButton;

        const check = clients.length <= 100 ? (
            <AllCheck clients={clients} selected={selected} setSelected={setSelected} />
        ) : null;
    
        return (
            <React.Fragment>
                {!needsFirstMpButton && (<TitleRow trainer={trainer} metricType={metricType} check={showAvg ? null : check} />)}
                {showAvg && <AverageDashCard clients={clients} check={check} metrics={metrics} trainer={trainer} metricType={metricType} />}
                <CheckButtons 
                    values={{ selected }}
                    name='selected'
                    setFieldTouched={() => {}}
                    setFieldValue={(name,newVal) => setSelected(newVal)}
                    render={({ toggleHandler, values }) => {
                        return (
                            <React.Fragment>
                                {clients.map(client => (
                                    <ClientDashCard 
                                        t={t}
                                        basePath={basePath}
                                        trainer={trainer}
                                        key={client.id}
                                        client={client} 
                                        metrics={(metrics || {})[client.id]} 
                                        metricType={metricType}
                                        selected={values} 
                                        toggleHandler={toggleHandler} 
                                        sendInvite={sendInvite}
                                        showBillingTags={showBillingTags}
                                    />
                                ))}
                            </React.Fragment>
                        )
                    }}
                />
            </React.Fragment>
        )
    }
}

ClientCardList = withTranslation()(ClientCardList);

const ClientListLoader = ({ trainer, filters, selected, setSelected, type, basePath, sendInvite }) => {

    return (
        <ClientScroller 
            trainer={trainer}
            filters={filters}
            metricType={type}
            context="dash"
            render={({ clients, metrics }) => {
                if(trainer.needsFirstMpButton() && _.some(clients, c => (c.eligibleForFirstMpBtn() && !c.sampleClient))) {
                    clients = _.filter(clients,c => !c.sampleClient);
                }
                
                return (
                    <ClientCardList 
                        selected={selected}
                        setSelected={setSelected}
                        clients={clients}
                        metricType={type}
                        trainer={trainer}
                        basePath={basePath}
                        metrics={metrics}
                        sendInvite={sendInvite}
                        showBillingTags={filters.status === 'billable'}
                    />
                )
            }}
        />
    )
}



let ClientControls = ({ trainer, filters, setFilters, selected, clients, basePath }) => {
    const { t } = useTranslation();
    const finalFilters = filters;
    const [searchIsFocused,setSearchIsFocused] =  useState(false);
    const hideNonSearch = searchIsFocused || !_.isBlank(finalFilters.query);

    let rightChildren = [
        <DefaultSelect
            key="sort-select"
            id="sort-select"
            className={classnames("mr5 flex-shrink", { hidden: hideNonSearch })}
            name='sort'
            collection={User.sortCol(t,false,trainer)}
            values={finalFilters}
            handleChange={(e) => setFilters({ ...filters, sort: e.target.value })}
        />
    ];
    if(trainer.isAppTrainer()) {
        rightChildren.push(
                <DefaultSelect
                    key="lookback-select"
                    id="lookback-select"
                    className={classnames("mr5 inline-block", { hidden: hideNonSearch })}
                    name='lookback'
                    collection={User.progressPeriodsCol(t,true,true)}
                    values={finalFilters}
                    handleChange={(e) => setFilters({ ...filters, lookback: _.castNumericInput(e.target.value) })}
                />
        )
    }

    

    let leftChildren = [
        <ClientFilterButton 
            key="client-filter-btn"
            trainer={trainer} 
            filters={finalFilters} 
            setFilters={setFilters} 
            hidden={hideNonSearch}
            className="ml5" 
        />,
        <ClientSearchButton 
            key="search-btn"
            toggleFocus={val => setSearchIsFocused(val)}
            topQuery={finalFilters.query}
            setTopQuery={query => setFilters({...filters, query })}
            status={finalFilters.status}
            showAll
        />,
        <ClientActionsButton 
            key="client-actions-btn"
            trainer={trainer} 
            selected={selected} 
            clients={clients} 
            className={classnames("ml5", { hidden: hideNonSearch })} 
            basePath={basePath}
        />
    ]

    leftChildren.unshift(
        <LinkButtonTPB
            type={null}
            key="new-client-btn"
            to={newClientModalPathFor(basePath)}
            rounded
            outlined={trainer.needsFirstMpButton()}
            color="primary"
            className="hide-on-med-and-down"
            noShadow
            allowUninit={false}
        >
            <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span>{t("New")}</span>
        </LinkButtonTPB>
    )

    return (
        <SplitButtonRow 
            className={"mb10"}
            rightClasses={classnames("valign-wrapper left ml20", { hidden: hideNonSearch })}
            leftClasses={'valign-wrapper'}
            leftChildren={leftChildren}
            rightChildren={rightChildren}
        />
    )
}

const ClientControlsAndList = ({ trainer, filters, setFilters, type, basePath, isLarge, showTabs, clientsScrollMeta, sendInvite }) => {
    const [selected,setSelected] = useState([]);
    let { clients } = getClientScrollInfo({ trainer, clientsScrollMeta, context: 'dash', metricType: type, filters });
    if(trainer.needsFirstMpButton() && _.some(clients, c => (c.eligibleForFirstMpBtn() && !c.sampleClient))) {
        clients = _.filter(clients,c => !c.sampleClient);
    }
    const props = { trainer, filters, selected, setSelected, basePath, type, sendInvite };


    return (
        <div className="page-height-cont" id={`dash-for-${filters.lookback}-days`}>
            <ClientControls 
                trainer={trainer} 
                selected={selected} 
                clients={clients} 
                filters={filters} 
                setFilters={setFilters}
                basePath={basePath}
            />
            {isLarge && showTabs && (<React.Fragment>
                <MetricTabs trainer={trainer} notTop />
                <div className="mt25"></div>
            </React.Fragment>)}
            <ClientListLoader {...props} />
            <FixedButtonTPB id="new-client-btn" icon="plus" className="hide-on-large-only" outlined={trainer.needsFirstMpButton()} to={newClientModalPathFor(basePath)} allowUninit={false} />
            {trainer.showBrandingSetupCard() && (
                <BrandingSetupCard />
            )}
            {trainer.showFormsSetupCard() && (<FormsSetupCard />)}
        </div>
    )
}

const BillableCountCore = ({  t, responseData: { count }}) => {
    return <div className="header-note">{t('count billable clients', { count })}</div>;
}

const BillableClientCount = ({ getBillableClientCount, t, filters }) => {

    if(filters.status === 'billable') {
        return (<Loader
            successComponent={BillableCountCore}
            type="blank"
            load={getBillableClientCount}
            preloaded={() => false}
            t={t}
        />)
    }

    return '';
}

const PastDueWarning = ({ trainer }) => {
    const text = trainer.canManageOwnAccount() ? <React.Fragment>Your account is past due, please <Link to={pastDuePath}>update your payment method.</Link></React.Fragment> : 'Your account is past due, please notify the account owner that the payment method needs to be updated or access will be cut off.'

    return (
        <div className="mt20">
            <SlightEmphasisIconNote variant="warning" text={text} />
        </div>
    )
}

class TrainerHome extends Component {

    render() {
        const { trainer, filters, setFilters, t, isLarge, showTabs, match: { params: { metrics: metricType } }, history, clientsScrollMeta, sendInvite, getBillableClientCount } = this.props;
        const basePath = trainerHomePathFor(metricType);
        const finalFilters = trainer.validClientFilters(filters);
        const filteredTrainer = _.isBlank(finalFilters.trainerId) ? null : trainer.activeTrainerById(finalFilters.trainerId);

        return (
            <CenteredActivityContainer wide>
                {trainer && trainer.trainerSubIsPastDue() && <PastDueWarning trainer={trainer} />}
                <MainHeading>
                    <div className="valign-wrapper flex-end">
                        <div>{filteredTrainer ? t("name's Clients", { name: filteredTrainer.initialsName() }) : t('Client Overview')}</div>
                        {trainer.isTrainerAdmin() && <BillableClientCount filters={filters} t={t} getBillableClientCount={getBillableClientCount} />}
                    </div>
                </MainHeading>
                <ClientControlsAndList 
                    trainer={trainer}
                    filters={finalFilters}
                    setFilters={setFilters}
                    sendInvite={sendInvite}
                    isLarge={isLarge}
                    showTabs={showTabs}
                    type={metricType}
                    basePath={basePath}
                    clientsScrollMeta={clientsScrollMeta}
                />
                <TagModal trainer={trainer} basePath={basePath} />
                <NewClientModal trainer={trainer} baseMatch={basePath} />
                <ClientFormsModal trainer={trainer} doLoad />
                <ReassignModal trainer={trainer} />
                <TrainerSelectModal trainer={trainer} initialTrainerId={finalFilters.trainerId} submit={({ trainerId }) => setFilters({ ...filters, trainerId})} />
                <ExportClientRoutinePDFModal />
                <PickMealPlanPopup basePath={basePath} />
                {trainer.showSignInAsClientPop() && (<InitialTipPopup 
                    tipName={SIGN_IN_AS_CLIENT_TIP} 
                    id="sign-in-as-client-tip" 
                    btn1Label={t('Dismiss')} 
                    btn2Label={t('Sign In As Client')} 
                    btn2Primary
                    btn2Action={() => {
                        const path = trainer.firstClientSignInPath();
                        if(window.isCordova) {
                            history.push(path);
                        } else {
                            window.open(`${basename}${path}`,'_blank');
                        }
                    }} 
                    alwaysDismissForever
                >
                    <div>
                        <p className="pl20 pr20">
                            {t("sign in as client tip2")}
                        </p>
                        <p className="pl20 pr20">
                            {t("sign in as client tip1")}
                        </p>
                    </div>
                </InitialTipPopup>)}
            </CenteredActivityContainer>
        );
    }
}

const mapStateToProps = state => ({
    filters: { ...clientFiltersSelector(state), showAllOnTextSearch: true },
    clientsScrollMeta: clientsScrollMetaSel(state)
})

const mapDispatchToProps = dispatch => ({
    loadClientsForDash: params => dispatch(loadClientsForDash(params)),
    setFilters: filters => dispatch(setClientFilters(filters)),
    sendInvite: id => dispatch(sendInvite({ id })),
    getBillableClientCount: () => dispatch(billableClientCount())
})


export default connect(mapStateToProps,mapDispatchToProps)(withTrainer(false)(withTranslation()(TrainerHome)));
