import BlankErrorBoundary from 'components/BlankErrorBoundary';
import React, { Suspense, lazy } from 'react';

// Lazy-load the component
const NotificationRequestor = lazy(() => import('./NotificationRequestor'));

const NotificationRequestorWrapper = () => {

    return (<BlankErrorBoundary>
        <Suspense fallback={''}>
            <NotificationRequestor />
        </Suspense>
    </BlankErrorBoundary>)
}

export default NotificationRequestorWrapper;