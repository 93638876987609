import React, {Component} from 'react';
import BasicProfileForm, { GoalQuestion, EmailQuestion } from 'partials/BasicProfileFlow';
import Page from'components/Page';
import { rootPath, startFlow, startEnd, startPath, homeMatch, startBase, quickstartBase, webPathFor, startPathFor, conversionMatch, appExitPath, homePath, canHitEmailOrPaywallMatches, paywallMatch, trainerHomePath, allMainMatches, trainerSignupMatch, trainerConversionMatch, switchAccountMatch, trainerBasicProfileMatch, inviteMatch, pdfSetupDonePath, mealPlanSetupMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { FlowNav } from 'components/FlowForm';
import { clearInitialSignup } from 'redux/actions';
import { connect } from 'react-redux';
import Connector from 'partials/WebsiteConnector';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import * as _ from 'lib/utilities';

const goalPageTransitionMap = {
    rules: [
        [[startFlow,startEnd,homeMatch],transitions.scaleIn],
        [rootPath,transitions.slideOut],
        [allMainMatches,transitions.slideOut]
    ]
};

export class SignupGoalPage extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(goalPageTransitionMap);
    }

    render() {
        const { scrollRef } = this.props;

        return (
        <Page ref={scrollRef} white>
            <FlowNav />
            <GoalQuestion history={this.props.history}  />
        </Page>
        );
    }
}

export const flowTransitionMap = {
    rules: [
        [startPath,transitions.scaleOut],
        [[startEnd,homeMatch,inviteMatch,pdfSetupDonePath,mealPlanSetupMatch],transitions.slideOver],
        [rootPath,transitions.slideOut]
    ]
};

class SignupFlowPage extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(flowTransitionMap);
    }

    render() {
        const { scrollRef, history } = this.props;

        return (
        <Page ref={scrollRef}>
            <BasicProfileForm 
                basePath={startBase}
                exitPath={appExitPath()}
                startIndex={2}
                history={history}
            />
        </Page>
        );
    }
}

const emailTransitionMap = {
    rules: [
        [[conversionMatch,paywallMatch,switchAccountMatch,trainerConversionMatch],transitions.slideOver],
        [[...canHitEmailOrPaywallMatches,trainerSignupMatch,trainerBasicProfileMatch],transitions.slideOut]
    ]
};

class TrainerInvitePage extends Component {
    constructor(props) {
        super(props);
        props.setupTransitions(emailTransitionMap);
        const { history, user } = props;

        if(!user) {
            history.replace(appExitPath())
        } else if(!user.hasValidInvite()) {
            if(user.isTrainer()) {
                history.replace(trainerHomePath);
            } else {
                history.replace(homePath());
            }
        }
    }

    render() {
        const { scrollRef, match: { params: { context } } } = this.props;

        return (
        <Page white ref={scrollRef}>
            <EmailQuestion context={context} />
        </Page>
        );
    }
}

class SignupEmailPage extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(emailTransitionMap);
        const { history, user, trainer } = props;
        const curUser = trainer || user;
        if(curUser && !_.isBlank(curUser.email)) {
            history.replace(homePath());
        }
    }

    render() {
        const { scrollRef, match: { params: { context }} } = this.props;

        return (
        <Page white ref={scrollRef}>
            <EmailQuestion context={context} />
        </Page>
        );
    }
}

const mapStateToEmailProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

TrainerInvitePage = connect(mapStateToEmailProps)(TrainerInvitePage); 
SignupEmailPage = connect(mapStateToEmailProps)(SignupEmailPage);

export { SignupEmailPage, TrainerInvitePage }

const mapDispatchToQInitProps = dispatch => ({
    beforeAction: () => dispatch(clearInitialSignup()),
    afterPath: startPathFor(3)
})

const QuickstartInit = connect(null, mapDispatchToQInitProps)(Connector);

export { QuickstartInit }

export class Quickstart extends Component {
    constructor(props) {
        super(props);
        props.setupTransitions(flowTransitionMap);
    }

    render() {
        const { scrollRef, history } = this.props;

        return (
        <Page ref={scrollRef}>
            <BasicProfileForm 
                basePath={quickstartBase}
                exitPath={webPathFor('/')}
                startIndex={1}
                history={history}
            />
        </Page>
        );
    }
}

export default SignupFlowPage;
