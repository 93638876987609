import classnames from 'classnames';
import { TwoWayInfiniteScroll } from 'components/InfiniteScroll';
import { Loader } from 'components/LoadingHOC';
import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { discardMessages, loadChatMessages, markMessagesRead, muteChat, sendChatMessage, toggleArchiveChat } from 'redux/actions';
import { UserIcon } from './Utilities';
import * as _ from 'lib/utilities';
import Button, { ConfirmActionButton, UpperRightBtn } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'components/Routing';
import { DropdownItem, DropdownLink, MultiLevelDropdown } from 'components/Dropdown';
import { useHistory, useLocation } from 'react-router-dom';
import { modalPathFor } from 'config/paths';
import { ChatInfoModal, chatInfoModalSuffix, EditChatModal, editChatModalSuffix } from './NewChatModal';
import { IconNote, SlightEmphasisIconNote } from 'components/Typography';
import { MinimalImageUpload } from 'components/Form';
import ImageViewer from 'components/ImageViewer';

export const imageViewSuffix = '/message/:messageId/image';

let ChatMenu = ({ user, chat, options, triggerRender, archiveChat, muteChat }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const owned = chat.owner().id === user.id;
    const muted = chat.isMuted(user);

    return (
        <MultiLevelDropdown
            options={ options || { coverTrigger: false, alignment: 'right', closeOnClick: true, closeOnChildClick: true } }
            triggerRender={triggerRender}
        >
            {owned && chat.isGroup() && (<DropdownLink label={t("Edit Group")} icon={['far','edit']} to={modalPathFor(editChatModalSuffix,pathname, { chatId: chat.id })} id="edit-group-btn" />)}
            {!owned && chat.isGroup() && (<DropdownLink label={t("Group Info")} icon={'info-circle'} to={modalPathFor(chatInfoModalSuffix,pathname, { chatId: chat.id })} />)}
            {owned && chat.isGroup() && !chat.isArchived() && (<ConfirmActionButton onClick={() => archiveChat(chat.id)} render={({ ready, onClick }) => (
                <DropdownItem label={t("Archive")} icon={['far','folder-download']} onClick={onClick} className={!ready ? "dont-close-dropdown" : ''} compClassName={(ready ? "red-text" : '')} />
            )} />)}
            {owned && chat.isGroup() && chat.isArchived() && (<DropdownItem label={t("Unarchive")} icon={'undo'} onClick={() => archiveChat(chat.id)} id="unarchive-btn" />)}
            {!muted && (<DropdownItem label={t("Mute")} icon={['far','bell-slash']} onClick={() => muteChat(chat.id)} id="mute-btn" />)}
            {muted && (<DropdownItem label={t("Unmute")} icon={['far','bell']} onClick={() => muteChat(chat.id)} id="unmute-btn" />)}
        </MultiLevelDropdown>
    )
}

const mapDispatchToMenuProps = dispatch => ({
    archiveChat: (chatId) => dispatch(toggleArchiveChat(chatId)),
    muteChat: (chatId) => dispatch(muteChat(chatId))
})

ChatMenu = connect(null,mapDispatchToMenuProps)(ChatMenu)

export const ChatMenuTrigger = React.forwardRef(({ target },ref) => {

    return (
        <div className="chat-menu" ref={ref} data-target={target}>
            <FontAwesomeIcon icon="ellipsis-h" />
        </div>
    )
})

export const ChatHeader = ({ chat, user, standalone }) => {
    const { t } = useTranslation();

    return (
        <div className={classnames("chat-header",{ standalone })}>
            <div className="chat-title">
                {chat.displayName(user,t)}
            </div>
            <ChatMenu 
                user={user}
                chat={chat}
                triggerRender={({ ref, target }) => (<ChatMenuTrigger ref={ref} target={target} />)}
            />
        </div>
    )
}

const ResponseToMedia = ({ responseTo }) => {
    const cname = 'media-cont';

    if(responseTo.chatMsgReplyImg()) {
        return (
            <div className={cname}>
                <img src={responseTo.chatMsgReplyImg()} alt="" />
            </div>
        )
    }

    if(responseTo.chatMsgReplyIcon()) {
        return (
            <div className={cname}>
                <FontAwesomeIcon icon={responseTo.chatMsgReplyIcon()} />
            </div>
        )
    }

    return '';
}

const ResponseToBox = ({ responseTo, self, user }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div className={classnames("response-to-block",{ self })} onClick={() => history.push(responseTo.chatMsgClickLink(user))}>
            <ResponseToMedia responseTo={responseTo} />
            <div className="text-cont">
                <div className="reply-title">{responseTo.chatMsgReplyTitle(t)}</div>
                <div className="reply-sub">{responseTo.chatMsgReplySubtitle(t)}</div>
            </div>
        </div>
    )
}

const MessageImage = ({ chatMessage, imgSrc }) => {
    const { pathname } = useLocation();

    return (
        <Link className="msg-img" to={modalPathFor(imageViewSuffix,pathname,{ messageId: chatMessage.resolvedId() })}>
            <img src={imgSrc} alt="" />
        </Link>
    )

}

const ChatMessageBox = React.forwardRef(({ chatMessage, self, isFirst, extraTopPadding, showTstamp, sendMessage, setSelMsg, user },ref) => {
    const lines = chatMessage.lines();
    const imgSrc = chatMessage.getImageSrc();
    const failed = chatMessage.failedToSend();
    const { t } = useTranslation();

    return (
        <div className={classnames("msg-cont", { self })} ref={ref}>
            <div className="bubble-wrap">
                <div 
                    className={classnames("chat-bubble",{ 'group-first': isFirst, mt20: (!isFirst && extraTopPadding) })} 
                    onClick={e => {
                        if(failed) {
                            sendMessage(_.pick(chatMessage,['tempKey','senderId','chatId','body']));
                        } else {
                            setSelMsg(chatMessage.id);
                        }
                    }}
                >
                    {chatMessage.responseTo && (<ResponseToBox responseTo={chatMessage.responseTo} self={self} user={user} />)}
                    {imgSrc && (
                        <MessageImage chatMessage={chatMessage} imgSrc={imgSrc} />
                    )}
                    <div className="msg-txt">
                        <span>
                            {lines.map((line,index) => (
                                <React.Fragment key={index}>
                                    {index > 0 && (<br/>)}
                                    {line.map((txt,index) => {
                                        if(index % 2 === 1) {
                                            return <Link key={index} to={txt} target="_blank" alwaysExternal>{_.abbrText(txt,80)}</Link>
                                        }

                                        return txt;
                                    })}
                                </React.Fragment>
                            ))}
                        </span>
                    </div>
                </div>
                {!failed && showTstamp && (<div className={classnames("tiny-text font-grey", { 'text-right': self })}>{chatMessage.sentAt().format('h:mm a')}{self && chatMessage.read && ` - ${t('Read')}`}</div>)}
                {chatMessage.failedToSend() && (<div className={classnames("tiny-text error-color", { 'text-right': self })}>{t('Failed to send')}</div>)}
            </div>
        </div>
    )
})

const Avi = ({ user, withTstamp }) => {

    return (
        <div className={classnames("avi-cont", { 'with-tstamp': withTstamp })}>
            <UserIcon user={user} />
        </div>
    ) 
}

const ChatCore = ({ user, chat, sendMessage, discardMessages, messageRefsMap }) => {
    const [firstUnreadMsg] = useState(chat.unreadMessages(user)[0]);
    const [selMsg,setSelMsg] = useState(null);
    const selMsgId = selMsg ? selMsg : (chat.lastMessage() && chat.lastMessage().id);
    const failedMessages = chat.failedMessages();
    const { t } = useTranslation();

    return (
        <div className="chat-rows-wrapper">
            {chat.bubbleGroups(firstUnreadMsg).map((bubbleGroup,index) => {

                if(bubbleGroup.unreadMarker) {
                    return (
                        <div className="unread-marker" key={'unread'}>{t('Unread Messages')} <FontAwesomeIcon icon="angle-down" /></div>
                    )
                }

                if(bubbleGroup.dateBreak) {
                    return (
                        <div className="chat-date-break" key={bubbleGroup.dateBreak.valueOf()}>{bubbleGroup.dateBreak.format("dddd, MMMM Do YYYY, h:mm a")}</div>
                    )
                }

                if(bubbleGroup.event) {
                    const { event } = bubbleGroup;
                    return (
                        <div className="chat-date-break" key={`e${event.id}`}>{event.message(t)}</div>
                    )
                }

                const self = user.id === bubbleGroup.sender.id;
                const lastMsg = bubbleGroup.messageInfos[bubbleGroup.messageInfos.length-1].msg;
                const aviTstamp = lastMsg.id === selMsgId || lastMsg.failedToSend();

                return (
                    <div className={classnames("chat-bgroup-wrap",{ 'first-group': index === 0 })} key={`${bubbleGroup.sender.id}-${bubbleGroup.messageInfos[0].msg.sentAt().valueOf()}`}>
                        {!self && (<Avi user={bubbleGroup.sender} withTstamp={aviTstamp} />)}
                        <div className="chat-bubble-group">
                            {!self && (<div className="sender-name">{bubbleGroup.sender.fullName()}</div>)}
                            {bubbleGroup.messageInfos.map(({ msg, extraTopPadding },index) => {
                                return (
                                    <ChatMessageBox 
                                        key={msg.id || msg.tempKey}
                                        chatMessage={msg} 
                                        self={self} 
                                        user={user}
                                        isFirst={index === 0} 
                                        extraTopPadding={extraTopPadding} 
                                        showTstamp={selMsgId === msg.id} 
                                        sendMessage={sendMessage}
                                        ref={messageRefsMap[msg.id]}
                                        setSelMsg={id => {
                                            if(id === selMsg) {
                                                setSelMsg(null)
                                            } else {
                                                setSelMsg(id)
                                            }
                                        }}
                                    />
                                )
                            })}
                        </div>
                        {self && (<Avi user={bubbleGroup.sender} withTstamp={aviTstamp} />)}
                    </div>
                )
            })}
            {failedMessages.length > 0 && (
                <div className="text-center pa10">
                    <ConfirmActionButton onClick={() => discardMessages(failedMessages.map(m => m.tempKey))} render={({ ready, onClick }) => (
                        <Button color={ready ? "red" : "grey"} rounded outlined onClick={onClick} id="discard-failed-msgs">
                            <FontAwesomeIcon icon={['fal','trash']} size='1x'/><span>{t('Discard Failed Messages')}</span>
                        </Button>
                    )} />
                </div>
            )}
        </div>
    )
}

class ChatScroller extends React.Component {

    constructor(props) {
        super(props);
        this.scrollRef = React.createRef();
        const { setScrollToBottom } = this.props;
        setScrollToBottom(this.scrollToBottom);
    }

    componentDidMount() {
        this.lastScrollPos = this.scrollRef.current.scrollTop;
        this.scrollToUnread();
    }

    render() {
        const { user, chat, loadMessages, sendMessage, discardMessages } = this.props;
        const { topDone, bottomDone } = chat;
        this.updateMsgRefs();

        return (
                <TwoWayInfiniteScroll 
                    load={dir => loadMessages({dir, ...chat.loadParams(dir) })}
                    topDone={topDone}
                    bottomDone={bottomDone}
                    className="chat-scroll"
                    scrollRef={this.scrollRef}
                    scrollCallback={this.scrollCallback}
                    id="chat-scroller"
                    render={() => {
    
                        return (
                            <ChatCore 
                                user={user} 
                                chat={chat} 
                                sendMessage={sendMessage} 
                                discardMessages={discardMessages} 
                                messageRefsMap={this.messageRefsMap}
                            />
                        )
                    }}
                />
        )
    }

    scrollToBottom = () => {
        this.scrollRef.current.scrollTop = this.scrollRef.current.scrollHeight;
    }

    scrollToUnread = () => {
        const { chat, forceBottom, user } = this.props;
        const unread = chat.unreadMessages(user);
        if(forceBottom || unread.length === 0) {
            this.scrollToBottom();
            return;
        }

        const topUnreadRef = this.messageRefs[0] && this.messageRefs[0][1]; //0th index is that last read msg
        const topUnreadId = this.messageRefs[0] && this.messageRefs[0][0];
        if(topUnreadId && chat.topDone && chat.topCutoff === topUnreadId) {
            this.scrollRef.current.scrollTop = 1;
        } else if(topUnreadRef && topUnreadRef.current) {
            this.scrollRef.current.scrollTop = topUnreadRef.current.offsetTop - 5;
        } else {
            this.scrollToBottom();
        }
    }

    scrollCallback = ({ bottomEdge, type }) => {
        clearTimeout(this.queueReadTimeout);
        this.queueReadTimeout = setTimeout(this.queueMarkRead(bottomEdge),250);

        if(type === 'scroll') {
            const { scrollCallback } = this.props;
            if(!this.skipScrollCallback && this.scrollRef.current && scrollCallback) {
                const scrollPos = this.scrollRef.current.scrollTop;
                const diff = this.scrollRef.current.scrollTop - this.lastScrollPos;
                const dir = diff > 0 ? 'down' : 'up';
                const atBottom = scrollPos >= (this.scrollableHeight() - 30);
                scrollCallback({ dir, atBottom, atTop: scrollPos <= 30 })
                this.lastScrollPos = scrollPos;
            }
            this.skipScrollCallback = false;
        }
    }

    markViewedRead = (attempt=0) => {
        if(!this.markingRead) {
            const { user, chat, markMessagesRead } = this.props;
            const unread = chat.unreadMessages(user);
            const sentMaxSeen = this.maxSeenId;
            const seenIds = _.filter(unread,m => (m.id <= sentMaxSeen)).map(m => m.id);
            if(seenIds.length > 0) {
                this.markingRead = true;
                markMessagesRead({ messageIds: seenIds, chatId: chat.id }).then(() => {
                    this.markingRead = false;
                    if(this.maxSeenId > sentMaxSeen) {
                        this.markViewedRead();
                    }
                }).catch(e => {
                    this.markingRead = false;
                    if(attempt < 2) {
                        this.markViewedRead(attempt+1)
                    }
                })
            }
        }
    }

    queueMarkRead = bottomEdge => () => {
        const { user, chat } = this.props;
        const unread = chat.unreadMessages(user);
        const oldMaxSeenId = this.maxSeenId;
        unread.forEach(m => {
            if(!this.maxSeenId || m.id > this.maxSeenId) {
                const ref = this.messageRefsMap[m.id];
                if(ref && ref.current && ref.current.getBoundingClientRect().top < bottomEdge - 50) {
                    this.maxSeenId = m.id;
                }
            }
        })
        if(!_.isBlank(this.maxSeenId) && (!oldMaxSeenId || this.maxSeenId > oldMaxSeenId)) {
            clearTimeout(this.markReadTimeout);
            this.markReadTimeout = setTimeout(this.markViewedRead,500);
        }
    }

    updateMsgRefs = () => {
        const { user, chat } = this.props;
        const unread = chat.scrolleventMessages(user);
        const unreadIds = unread.map(m => m.id);

        this.messageRefsMap = this.messageRefsMap || {};
        this.messageRefsMap = _.pick(this.messageRefsMap,unreadIds);
        unread.forEach(m => {
            if(!this.messageRefsMap[m.id]) {
                this.messageRefsMap[m.id] = this.messageRefsMap[m.id] || React.createRef();
            }
        })
        this.messageRefs = _.sortBy(Object.entries(this.messageRefsMap).map(([id,ref]) => ([Number(id),ref])),([id,ref]) => id);
    }

    scrollableHeight = () => {
        return this.scrollRef.current.scrollHeight - this.scrollRef.current.clientHeight;
    }

    getSnapshotBeforeUpdate(prevProps, prevState) {
        const prevScrollTop = this.scrollRef.current.scrollTop;
        const prevScrollableHeight = this.scrollableHeight();
        const vals = { prevScrollTop, prevScrollableHeight };

        return vals;
    }

    componentDidUpdate(prevProps,prevState,snapshot) {
        const { chat: oldChat } = prevProps;
        const { chat } = this.props;
        const oldIds = oldChat.messages.map(m => m.id);
        const ids = chat.messages.map(m => m.id);
        const oldLastLoaded = oldIds.length === 0 ? 0 : oldIds[oldIds.length-1];
        const oldFirstLoaded = oldIds.length === 0 ? Infinity : oldIds[oldIds.length-1];
        const { prevScrollTop, prevScrollableHeight, bottomWasDone } = snapshot;
        const matchedIds = _.intersection(oldIds,ids).length;

        //no new messages
        if(matchedIds === oldIds.length && matchedIds.length === ids.length) {
            return;
        }


        if(chat.bottomCutoff !== oldChat.bottomCutoff) {
            this.scrollRef.current.scrollTop = prevScrollTop;
            //this means the user is triggering infinite scroll load by scrolling down, no scroll behavior needed
            return;
        }

        const newIds = _.difference(ids,oldIds);

        if(newIds[0] > oldLastLoaded) { //new messages received
            if(bottomWasDone && prevScrollTop >= (prevScrollableHeight - 30)) {
                this.scrollToBottom();
            }
        } else if(newIds[newIds.length-1] < oldFirstLoaded) { //loading messages from scrolling up, need to manually maintain scroll position
            const newScrollableHeight = this.scrollRef.current.scrollHeight - this.scrollRef.current.clientHeight;
            this.skipScrollCallback = true;
            this.scrollRef.current.scrollTop = prevScrollTop + (newScrollableHeight - prevScrollableHeight);
        }
    }
}

const ChatScrollWrapper = (props) => {
    const { t } = useTranslation();
    const { chat } = props;

    if(chat) {
        return <ChatScroller {...props} />
    }

    return (
        <div className="mb50">
            <SlightEmphasisIconNote text={t("no chat err")} />
        </div>
    )
}

const DraftImage = ({ draftImage, clearDraftImage }) => {

    return (
        <div className="draft-img">
            <UpperRightBtn onClick={() => clearDraftImage()} />
            <img src={draftImage} alt="" />
        </div>
    )
}
class Chat extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToBottom = null;
        const chat = this.getChat();
        this.textRef = React.createRef();
        this.state = { cutoffId: '', showZoomBtn: false, chatIsLoaded: !!chat, reloadTrigger: '', draftImage: null, hasDraft: false }
    }

    componentDidMount() {
        this.ensureChatLoaded();
    }

    componentDidUpdate() {
        this.ensureChatLoaded();
    }

    render() {
        const { loadMessages, user, chatId, desktop, standalone, sendMessage, discardMessages, markMessagesRead, t } = this.props;
        const { cutoffId, showZoomBtn, reloadTrigger, draftImage, hasDraft } = this.state;
        const chat = user.chatById(chatId);
        const archived = (chat && chat.isArchived()) || !user.hasActiveMessaging();
        const showSendBtn = (!_.isBlank(draftImage) || hasDraft);

        return (
            <div className={classnames("chat-activity", { desktop, standalone } )}>
                <div className="chat-wrapper main">
                    <Loader
                        type="padded"
                        key={`${cutoffId}-${reloadTrigger}`}
                        load={() => loadMessages({ chatId, cutoffId })}
                        preloaded={() => false}
                        successComponent={ChatScrollWrapper}
                        loadMessages={loadMessages}
                        setScrollToBottom={this.setScrollToBottom}
                        scrollCallback={this.scrollCallback}
                        forceBottom={cutoffId === 'bottom'}
                        sendMessage={sendMessage}
                        discardMessages={discardMessages}
                        markMessagesRead={markMessagesRead}
                        user={user}
                        chat={chat}
                    />
                </div>
                {chat && (
                    <React.Fragment>
                        <div className="chat-wrapper">
                            <div className="zoom-bottom-cont">
                                <div className={classnames("zoom-to-bottom", { inactive: !showZoomBtn })} onClick={this.zoomToBottom}>
                                    <FontAwesomeIcon icon="angle-down" />
                                </div>
                            </div>
                        </div>
                        <div className="chat-wrapper">
                            <div className="chat-rows-wrapper">
                                <div className="new-msg-wrap">
                                    {draftImage && (<DraftImage draftImage={draftImage} clearDraftImage={this.clearDraftImage} />)}
                                    <div className="new-msg-inputs-wrap">
                                        {archived && (<div className="new-msg archived">
                                            <IconNote text={t('archived msg')} />
                                        </div>)}
                                        {!archived && (<textarea name="message" maxLength={2500} className={classnames("new-msg",{'with-img': !!draftImage})} onKeyPress={this.keyPressHandler} onPaste={this.onPaste} ref={this.textRef} onInput={this.onInput}>
                                        </textarea>)}
                                        {!showSendBtn && (<MinimalImageUpload 
                                            callback={(result) => this.setState({ draftImage: result }) }
                                            render={({ field, id }) => {
                                                return (
                                                    <div>
                                                        {field}
                                                        <label className="msg-action-btn" htmlFor={id}>
                                                            <FontAwesomeIcon icon="image" />
                                                        </label>
                                                    </div>
                                                )
                                            }}
                                        />)}
                                        {showSendBtn && (
                                            <div>
                                                <div className="msg-action-btn send" onClick={() => this.submitMessage()}>
                                                    <FontAwesomeIcon icon="paper-plane" />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <EditChatModal />
                        <ChatInfoModal />
                        <ImageViewer
                            pathSuffix={imageViewSuffix}
                            getImageUrl={({ params: { messageId }}) => (chat && chat.imgSrcFor(messageId))} 
                        />
                    </React.Fragment>
                )}
            </div>
        );
    }

    setScrollToBottom = fn => (this.scrollToBottom = fn);

    getChat = () => this.props.user.chatById(this.props.chatId);

    clearDraftImage = () => this.setState({ draftImage: null });

    ensureChatLoaded = () => {
        const chat = this.getChat();
        const { chatIsLoaded } = this.state;
        if(!chatIsLoaded && chat) {
            this.setState({ chatIsLoaded: true });
        } else if(chatIsLoaded && !chat) {
            this.setState({ chatIsLoaded: false, reloadTrigger: Math.random() })
        }
    }

    keyPressHandler = (e) => {
        const useMobileBehavior = (_.isMobile() && process.env.REACT_APP_TEST !== 'true');
        if(e.key === 'Enter' && !(e.shiftKey || useMobileBehavior)) {
            e.preventDefault();
            this.submitMessage();
        }
    }

    submitMessage = () => {
        const { sendMessage, user } = this.props;
        const chat = this.getChat();
        const { draftImage } = this.state;
        const el = this.textRef.current;
        if(el.value.trim().length > 0 || draftImage) {
            const vals = {senderId: user.id, chatId: chat.id, body: el.value };
            if(draftImage) {
                vals.image = draftImage;
            }
            sendMessage(vals);
            el.value = '';
            this.onInput();
            this.clearDraftImage();
            setTimeout(() => this.zoomToBottom(),1);
        }
    }

    zoomToBottom = () => {
        const chat = this.getChat();
        if(chat.bottomDone) {
            this.scrollToBottom && this.scrollToBottom();
        } else {
            this.setState({ cutoffId: 'bottom' });
        }
    }

    scrollCallback = ({ dir, atBottom }) => {
        const { showZoomBtn } = this.state;

        if(showZoomBtn) {
            if(dir === 'up' || atBottom) {
                this.setState({ showZoomBtn: false });
            }
        } else {
            if(dir === 'down' && !atBottom) {
                this.setState({ showZoomBtn: true });
            }
        }
    }

    onInput = (e) => {
        const { hasDraft } = this.state;
        const el = this.textRef.current;
        const newHasDraft = !_.isBlank(el.value);
        el.style.height = '';
        el.style.height = `${el.scrollHeight}px`;
        if(newHasDraft !== hasDraft) {
            this.setState({ hasDraft: newHasDraft });
        }
    }

    onPaste = (e) => {
        const items = (e.clipboardData || e.originalEvent.clipboardData).items;

        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if(item.type.indexOf('image') !== -1) {
                const selection = window.getSelection();
                if (!selection.rangeCount) return false;
                selection.deleteFromDocument();
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onload = (e) => {
                    this.setState({ draftImage: e.target.result });
                }; 
                reader.readAsDataURL(blob);
                e.preventDefault();
            }
        }
    }
}

const mapDispatchToProps = dispatch => ({
    loadMessages: data => dispatch(loadChatMessages(data)),
    sendMessage: data => dispatch(sendChatMessage(data)),
    discardMessages: tempKeys => dispatch(discardMessages(tempKeys)),
    markMessagesRead: data => dispatch(markMessagesRead(data))
})

export { ChatMenu }

export default withTranslation()(connect(null,mapDispatchToProps)(Chat))