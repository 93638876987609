import React, {  } from 'react';
import { modalPathFor } from 'config/paths';
import { connect } from 'react-redux';
import { SlightEmphasisIconNote } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { assignTrainer, deactivateTrainers } from 'redux/actions';
import * as _ from 'lib/utilities';
import { FullFormModal } from 'components/Modal';
import { TrainerSelectListing } from 'partials/Utilities';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const deactivateSuffix = '/deactivate/:ids';

let DeactivateModal = ({ trainer, location, deactivate }) => {
    const match = modalPathFor(deactivateSuffix,location.pathname);
    const { t } = useTranslation();

    return (
        <FullFormModal 
            fullWidth 
            path={match} 
            scrollable 
            exact 
            icon={'question-circle'}
            title={`${t("Reassign Clients To")}:`}
            footerProps={({ formikProps, formikProps: { values: { trainerIds: ids }}}) => {
                return { saveIcon: 'times', saveLabel: t('Deactivate'), saveColor: 'red', disabled: ids.includes(trainer.getMasterAccount().id) }
            }}
            formProps={ (props) => {
                const { modalProps: { match: { params: { ids }}}} = props;
                return ({
                    initialValues: { trainerIds: ids.split('_').map(id => Number(id)), replaceId: '' },
                    submit: values => {
                        if(!values.trainerIds.includes(trainer.getMasterAccount().id)) {
                            return deactivate(values);
                        }
                        return Promise.reject({ message: 'SERVERERR' })
                    },
                    initialErrors: {}
                })
            }}
            render={({ modalProps, ...formikProps }) => {
                const { values: { trainerIds: ids } } = formikProps;

                if(ids.includes(trainer.getMasterAccount().id)) {
                    return (
                        <div className="pa10">
                            <SlightEmphasisIconNote variant="warning" text={t('cant deactivate owner')} />
                        </div>
                    )
                }

                return (
                    <div className="pa10" >
                        <TrainerSelectListing 
                            trainer={trainer}
                            excludeTrainerIds={ids}
                            prependOpts={[{ text: t("No one (deactivate them)"), value: ''}]}
                            formikProps={formikProps}
                            name={'replaceId'}
                            single
                        />
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToDeactivateProps = dispatch => ({
    deactivate: data => dispatch(deactivateTrainers(data))
})

DeactivateModal = connect(null,mapDispatchToDeactivateProps)(DeactivateModal);

export const reassignSuffix = '/reassign/:type/:ids';

let ReassignModal = ({ trainer, assignTrainer }) => {
    const { pathname } = useLocation();
    const match = modalPathFor(reassignSuffix,pathname);
    const { t } = useTranslation();

    return (
        <FullFormModal 
            fullWidth 
            path={match} 
            scrollable 
            exact 
            autoSubmit
            icon={'question-circle'}
            title={`${t("Reassign Clients To")}:`}
            footerProps={{ saveIcon: 'check', saveLabel: t('Reassign') }}
            formProps={ (props) => {
                const { modalProps: { match: { params: { ids, type }}}} = props;
                const resolvedIds = ids.split('_');
                const initialValues = type === 'trainer' ? { fromTrainerIds: resolvedIds, trainerId: '' } : { clientIds: resolvedIds, trainerId: '' };
                return ({
                    initialValues,
                    submit: values => assignTrainer(values),
                    initialErrors: { trainerId: 'required' },
                    validationSchema:  Yup.object().shape({
                        trainerId: Yup.number().required()
                    })
                })
            }}
            render={({ modalProps, ...formikProps }) => {
                const { match: { params: { ids, type }}} = modalProps;
                const resolvedIds = ids.split('_');

                let excludeIds = null;
                if(type === 'trainer') {
                    excludeIds = resolvedIds;
                } else if(resolvedIds.length === 1) {
                    const cli = trainer.clientById(resolvedIds[0]);
                    excludeIds = cli && [cli.trainerId];
                }

                return (
                    <div className="pa10" >
                        <TrainerSelectListing 
                            trainer={trainer}
                            excludeTrainerIds={excludeIds}
                            formikProps={formikProps}
                            name={'trainerId'}
                            single
                        />
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToReassignProps = dispatch => ({
    assignTrainer: params => dispatch(assignTrainer(params))
})

ReassignModal = connect(null,mapDispatchToReassignProps)(ReassignModal);

export const trainerSelectSuffix = '/trainer_select_modal';

export const TrainerSelectModal = ({ trainer, icon, initialTrainerId, excludeSelf, title, submit, noAll, allLabel }) => {
    const { pathname } = useLocation();
    const match = modalPathFor(trainerSelectSuffix,pathname);
    const { t } = useTranslation();

    return (
        <FullFormModal 
            fullWidth 
            path={match} 
            scrollable 
            exact 
            noFooter
            icon={icon || "users"}
            title={title || t('Select Team Member')}
            formProps={ ({ modalProps: { close } }) => {
                const id = trainer.resolvedFilterTid(initialTrainerId);

                return ({
                    initialValues: { trainerId: (_.isNumeric(id) ? Number(id) : '') },
                    submit: values => {
                        submit(values);
                        close();
                        return Promise.resolve({ status: 'SUCCESS' });
                    },
                    initialErrors: { trainerId: 'required' },
                    autoSubmit: true
                })
            }}
            render={({ modalProps, ...formikProps }) => {


                return (
                    <div className="pa10" >
                        <TrainerSelectListing 
                            trainer={trainer}
                            formikProps={formikProps}
                            name={'trainerId'}
                            excludeTrainerIds={excludeSelf ? [trainer.id] : []}
                            preSelectedIds={(_.isNumeric(initialTrainerId) ? [Number(initialTrainerId)] : [])}
                            noCheck
                            single
                            prependOpts={noAll ? [] : [{ text: (allLabel || t('All')), value: '', icon: <FontAwesomeIcon icon="users" className="ml10 mb5" />}]}
                        />
                    </div>
                )
            }}
        />
    )
}

export { DeactivateModal, ReassignModal }