import React from 'react';
import { accountPath, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { getInvoices } from 'redux/actions';
import { connect } from 'react-redux';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { Loader } from 'components/LoadingHOC';
import { SlightEmphasisIconNote } from 'components/Typography';
import Card, { BasicCardHeader } from 'components/Card';
import moment from 'moment';
import * as _ from 'lib/utilities';
import { Link } from 'components/Routing';

const transitionMap = {
    rules: [
        [[...allMainMatches,accountPath],transitions.slideOut]
    ]
};

const InvoiceCard = ({ invoice, t }) => {

    return (
        <React.Fragment>
            <Card>
                <BasicCardHeader className="pt15">
                    {t('date Billing Period', { date: `${moment(invoice.startDate).format('MMM Do')} - ${moment(invoice.endDate).format('MMM Do, YYYY')}` })}
                </BasicCardHeader>
                <div className="inv-table">
                    <div className="ithead">
                        <div className="ls">
                            Initial Invoice:
                        </div>
                        <div className="rs">
                            x{invoice.baseCliCount + invoice.extraClientCount} clients for {invoice.months} months
                        </div>
                    </div>
                    <div className="itrow">
                        <div className="ls">
                            Flat Fee (for first {invoice.baseCliCount} clients):
                        </div>
                        <div className="rs">
                            ${invoice.baseFee.toFixed(2)}
                        </div>
                    </div>
                    <div className="itrow">
                        <div className="ls">
                            Extra Client Charge x {invoice.extraClientCount}:
                        </div>
                        <div className="rs">
                            ${invoice.extraClientCharges.toFixed(2)}
                        </div>
                    </div>
                    <div className="itrow">
                        <div className="ls underline">
                            Total for Period:
                        </div>
                        <div className="rs underline">
                            ${invoice.paidUsage.toFixed(2)}
                        </div>
                    </div>
                    {!_.isBlank(invoice.adjustments) && (
                        <React.Fragment>
                            <div className="itrow gap-down">
                                <div className="ls">
                                    Adjustments from Previous Period:
                                </div>
                                <div className="rs">
                                    ${invoice.adjustments.toFixed(2)}
                                </div>
                            </div>
                            <div className="itrow">
                                <div className="ls underline">
                                    Total for Invoice:
                                </div>
                                <div className="rs underline">
                                    ${invoice.total.toFixed(2)}
                                </div>
                            </div>
                        </React.Fragment>
                    )}
                </div>

                {invoice.actualUsageLines && invoice.actualUsageLines.length > 0 && (
                <React.Fragment>
                    <div className="inv-table">
                        <div className="ithead">
                            <div className="ls">
                                Actual Usage:
                            </div>
                            <div className="rs">
                                x{invoice.actualCliCount} avg clients for {invoice.months} months
                            </div>
                        </div>
                        {invoice.actualUsageLines.map(line => (
                            <div className="itrow">
                                <div className="ls">
                                    {line.durationStr} x {line.clientCount} clients{line.planDescription ? ` (${line.planDescription})` : ''}:
                                </div>
                                <div className="rs">
                                    ${line.amount.toFixed(2)}
                                </div>
                            </div>
                        ))}
                        <div className="itrow">
                            <div className="ls underline">
                                Actual Usage Total:
                            </div>
                            <div className="rs underline">
                                ${invoice.actualUsage.toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className="inv-table">
                        <div className="ithead">
                            <div className="ls">
                                Adjustments for next period&#42;:
                            </div>
                            <div className="rs">
                                ${invoice.adjustmentsForNext.toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className="tiny-text pt15 pb10 pl20">
                        &#42;Actual Usage Total - Total for Period
                    </div>
                </React.Fragment>)}
                {invoice.pdfLink && (
                    <div className="text-center pt25 pb25">
                        <Link to={invoice.pdfLink} target="_blank">Download PDF of Invoice</Link>
                    </div>
                )}
            </Card>
        </React.Fragment>
    )
}

const InvoicesCore = ({ responseData: { invoices }}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {invoices.length === 0 && <SlightEmphasisIconNote text={t('no invoices')} />}
            {invoices.map(invoice => (<InvoiceCard key={invoice.id} invoice={invoice} t={t} />))}
        </React.Fragment>
    )
    
}

class TrainerInvoices extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, getInvoices } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("View Invoices")}
            >
                <CenteredActivityContainer>
                    <Loader 
                        successComponent={InvoicesCore}
                        load={getInvoices}
                        preloaded={() => false}
                        type="page"
                    />
                </CenteredActivityContainer>
            </SimpleNavPage>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    getInvoices: () => dispatch(getInvoices())
})

export default withTranslation()(connect(null,mapDispatchToProps)(TrainerInvoices));