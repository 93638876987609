import React, {useEffect, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TextInput, { NumericSelectInput, SelectInput, TextArea } from 'components/TextInput';
import Collapse from 'components/Collapse';
import Button from "components/Button";
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import Slider, { Range } from "rc-slider";
import { IconTip, FlexIconTip } from 'components/Tooltipped';
import { useTranslation } from 'react-i18next';
import loadingContainer from 'components/LoadingHOC';
import Card from 'components/Card';
import moment from 'moment';
import { dateFormatRegex } from 'config/settings';
import DatePicker from './DatePicker';
import { useHistory, useLocation } from 'react-router-dom';
import { modalPathFor } from 'config/paths';
import TagsInput from 'react-tagsinput';
import { PlaceholderBackedImg } from './ImageViewer';

export const formikAutoSubmitTextProps = [
    'errors', 
    'values', 
    'touched', 
    'handleChange', 
    'handleAutoSubmitBlur'
];

export const formikInputProps = [
    'errors', 
    'values', 
    'touched', 
    'handleChange', 
    'handleBlur'
];

export const formikInputWrapperProps = [
    'errors', 
    'values', 
    'touched', 
    'handleChange', 
    'handleBlur',
    'setFieldValue',
    'setFieldTouched'
];

export const fakeFormikProps = valueNames => ({ errors: {}, touched: {}, values: valueNames.reduce((acc,key) => ({ ...acc, [key]: '' }),{}), handleChange: (() => {}), handleBlur: (() => {}), setFieldValue: (() => {}), setFieldTouched: (() => {}) })

export const ListingRightBtn = ({ onClick, icon, id, className }) => {

    return (
        <div id={id} className={classnames("listing-right-btn",{ [className]: className })} onClick={onClick}>
            <FontAwesomeIcon icon={icon} className="vert-align-mid" />
        </div>
    )
}

export const FormToggle = ({ expanded, setExpanded, id }) => {
    const icon = expanded ? 'chevron-up' : 'chevron-down';

    return (
        <div id={id} className="inline-block right mr15 clickable" onClick={() => setExpanded(!expanded)}>
            <FontAwesomeIcon icon={icon} className="vert-align-mid" />
        </div>
    )
}

export const MultivalueCheckbox = ({ checkedVal, uncheckedVal, name, label, wrapLabel, className, setFieldValue, values, tooltip, coreOnly, id }) => {
    const Component = coreOnly ? CheckboxCore : Checkbox;
    const curVal = _.get(values,name);
    const isChecked = curVal === checkedVal || (checkedVal === false && _.isBlank(curVal)) || (curVal === false && _.isBlank(checkedVal));

    return (<Component
        inputProps={{ 
            name, 
            onChange: (e) => {
                if(e.target.checked) {
                    setFieldValue(name,checkedVal);
                } else {
                    setFieldValue(name,uncheckedVal);
                }
            }, 
            checked: isChecked
        } } 
        wrapLabel={wrapLabel}
        label={label} 
        filled 
        className={className}
        tooltip={tooltip}
        id={id}
    />)
}

export const DefaultTextArea = ({ name, label, values, handleChange, handleBlur, className, inProps }) => {

    return (
        <div className={className}>
            <div className="font-grey">{label}</div>
            <TextArea name={name} value={values[name]} onChange={handleChange} onBlur={handleBlur} {...inProps} />
        </div>
    )
}

export const DefaultSelect = ({ name, className, label, labelHtml, collection, handleBlur, handleChange, values, errors, touched, tooltip, selectProps, numeric, setFieldValue }) => {
    const Comp = numeric ? NumericSelectInput : SelectInput;

    const selectComp =  (
        <Comp
            name={name} 
            collection={collection} 
            browserDefault 
            value={_.get(values,name)} 
            onChange={handleChange} 
            onBlur={handleBlur} 
            setFieldValue={setFieldValue}
            {...selectProps}
        />
    )

    if(collection.length > 0) {
        const error = _.get(touched,name) && _.get(errors,name);

        return (
            <div className={className}>
                {!_.isBlank(label) && <div className="font-grey no-wrap">{label}</div>}
                {labelHtml}
                {tooltip && (
                    <div className="display-flex">
                        {selectComp}
                        <FlexIconTip icon='question-circle' msg={tooltip} />
                    </div>

                )}
                {!tooltip && selectComp}
                {error && <div className="red-text"><FontAwesomeIcon icon="times" /> {error}</div>}
            </div>
        )
    } else {
        return '';
    }
}

export const CardForm = ({ children, className, align='left', ...rest }) => {
    const classNames = classnames("pa10",{ [className]: className, "text-left": align === 'left', "text-center": align === 'center'})
    return (
        <Card>
            <form className={classNames} style={{width: '100%'}} {...rest}>
                {children}
            </form>
        </Card>
    )
} 

export const ModalForm = (props) => {
    const { className, centered, ...rest } = props;
    const classNames = classnames({'modal-form': true, 'centered-form': centered, [className]: className});
    return (
        <form className={classNames} {...rest} />
    )
}

const SubmitSettingsDefault = ({ close, id, saveChildren, cancelChildren, saveText, disabled, saveColor="primary", noCancel }) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {!noCancel && (<Button key="cancel-btn" rounded color="primary" outlined onClick={close} >{cancelChildren || (<React.Fragment><FontAwesomeIcon icon="ban" /> {t("Cancel")}</React.Fragment>)}</Button>)}
            <Button key="log-btn" tag='button' type="submit" disabled={disabled} rounded color={saveColor} id={id} className="ml5">{saveChildren || (<React.Fragment><FontAwesomeIcon icon="check" /> {saveText || t("Save")}</React.Fragment>)}</Button>
        </React.Fragment>
    )
}

const IconOnlyDefault = ({ children }) => {

    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    )
}

export const IconOnlySubmitLC = loadingContainer({
    "DEFAULT": IconOnlyDefault,
    "SUCCESS": IconOnlyDefault
}, { type: 'icon'})

export class ModalSubmitSuccess extends React.Component {

    constructor(props) {
        super(props);
        props.close();
    }

    render() {
        return '';
    }
}

export const ModalSubmitLC = loadingContainer({
    "DEFAULT": SubmitSettingsDefault,
    "SUCCESS": ModalSubmitSuccess
})

export const ModalSubmitNoClose = loadingContainer({
    "DEFAULT": SubmitSettingsDefault,
    "SUCCESS": SubmitSettingsDefault
})

export const InnerFormContainer = (props) => {
    const { className, ...rest } = props;
    const classNames = classnames({'inner-form-container': true, [className]: className});
    return (
        <div className={classNames} {...rest} />
    )
}

export const CollapsibleFormSection = (props) => {
    const { header, startOpen, children, dontRenderUntilShow, alwaysShowDivider } = props;
    const [ expanded, setExpanded ] = useState(startOpen);
    const [ hasBeenOpened, setHasBeenOpened ] = useState(startOpen);
    const renderChildren = hasBeenOpened || !dontRenderUntilShow;
    const headerClasses = classnames("section-title", {[props.headerClasses]: props.headerClasses})

    return (
        <React.Fragment>
            <div className="form-section-header" style={{ cursor: 'pointer'}} onClick={() => { setExpanded(!expanded); setHasBeenOpened(true) }}>
                <div className={headerClasses}>
                    {header}
                </div>
                <div className="expand-icon clickable">
                    {<FontAwesomeIcon icon={expanded ? 'angle-down' : 'angle-right'} size='1x' />}
                </div>
            </div>
            {alwaysShowDivider && (<div className="divider mb10"></div>)}
            <Collapse isOpen={expanded}>
                {!alwaysShowDivider && (<div className="divider mb10"></div>)}
                {renderChildren && children}
            </Collapse>
        </React.Fragment>
    )
}

export const FormSectionHeader = (props) => {
    const { header, divider } = props;
    const className = classnames({'form-section-header': true, 'mb10': !divider})
    return (
        <React.Fragment>
            <div className={className}>
                <div className="section-title">
                    {header}
                </div>
            </div>
            {divider && (<div className="divider mb10"></div>)}
        </React.Fragment>
    )
}

export const FullTextArea = ({ name, formikProps, label, labelClassName, height, className, ...rest }) => {
    const error = _.get(formikProps.touched,name) && _.get(formikProps.errors,name);
    const hasError = !_.isBlank(error);

    return (
        <div className={classnames("text-left", { [className]: className })}>
            {!_.isBlank(label) && <div className={classnames({"font-grey no-wrap": !labelClassName, [labelClassName]: labelClassName })}>{label}</div>}
            <TextArea
                name={name} 
                value={_.get(formikProps.values,name)} 
                onChange={formikProps.handleChange} 
                onBlur={formikProps.handleBlur} 
                height={height || '75px'}
                className={classnames({invalid: hasError })}
                {...rest}
            />
            {hasError && (<div className="error-color">{error}</div>)}
        </div>
    )
}

export const FullInput = ({ formikProps, ...rest }) => {
    const inputProps = _.pick(formikProps,rest.autoSubmit ? formikAutoSubmitTextProps : formikInputProps);

    return (
        <InputWithErrors 
            {...inputProps}
            {...rest}
        />
    )
}

export const InputWithErrors = ({ 
    name, 
    errors, 
    values, 
    touched, 
    handleChange, 
    handleBlur,
    handleAutoSubmitBlur, 
    inProps, 
    component, 
    ...rest}) => {
    const inputProps = { ...inProps, value: _.get(values,name), onChange: handleChange, onBlur: handleBlur || handleAutoSubmitBlur, name: name }
    const error = _.get(touched,name) && _.get(errors,name)
    const Component = component || TextInput;
    return (
        <Component
            inputProps={inputProps}
            error={error}
            {...rest}
        />
    )
}

export const Checkbox = ({ label, inputProps, error, tooltip, filled, className, checkClassName, id, tipOpts }) => {
    
    const containerClassNames = classnames('option-container no-wrap', {[className]: className});

    return (
        <React.Fragment>
            <div className={containerClassNames}>
                <CheckboxCore label={label} inputProps={inputProps} filled={filled} id={id} wrapLabel className={checkClassName} />
                {tooltip && (<IconTip icon='question-circle' size="1x" msg={tooltip} inline tipOpts={tipOpts} />)}
            </div>
            <div className="red-text">{error}</div>
        </React.Fragment>
    )
}

export const CheckboxCore = ({ inputProps, label, filled, className, labelClasses, middle, valign, id, wrapLabel, onClick, indeterminate }) => {
    const checkRef = useRef();
    useEffect(() => {
        checkRef.current.indeterminate = indeterminate;
    }, [indeterminate]);
    const classNames = classnames({'filled-in': filled, [className]: className, 'is-checked': (inputProps && inputProps.checked)});

    return (
        <label id={id} onClick={onClick} className={labelClasses}>
            <input {...inputProps} className={classNames} type="checkbox" ref={checkRef} />
            <span className={classnames('text-left',{ 'middle-align': middle, 'normal-wrap': wrapLabel, valign })}>{label}</span>
        </label>
    )
}

export class SingleSelectCheckboxGroup extends React.Component {
  
    handleChange = event => {
      const target = event.currentTarget;
      const targVal = target.checked ? target.value : '';
  
      this.props.onChange(this.props.name, targVal);
    };
  
    handleBlur = () => {
      // take care of touched
      this.props.onBlur(this.props.name, true);
    };
  
    render() {
      const { values, errors, name, touched, children } = this.props;
      const value = _.get(values,name);
      const error = _.get(touched,name) && _.get(errors,name);
  
      return (
          <React.Fragment>
            {React.Children.map(children, child => {
              return React.cloneElement(child, {
                inputProps: {
                  ...child.props.inputProps,
                  name: name,
                  checked: (value === child.props.inputProps.value),
                  onChange: this.handleChange,
                  onBlur: this.handleBlur
                }
              });
            })}
            { <div className="red-text" >{error}</div> }
          </React.Fragment>
      );
    }
  }
export class CheckboxGroup extends React.Component {
  
    handleChange = event => {
      const target = event.currentTarget;
      const { values, name } = this.props;
      const value = _.get(values,name) || [];
      let valueArray = [...value] || [];
      const targVal = _.isNumeric(target.value) ? Number(target.value) : target.value;

      if (target.checked) {
        valueArray.push(targVal);
      } else {
        valueArray.splice(valueArray.indexOf(targVal), 1);
      }
  
      this.props.onChange(this.props.name, _.arrayForForm(valueArray));
    };
  
    handleBlur = () => {
      // take care of touched
      this.props.onBlur(this.props.name, true);
    };
  
    render() {
      const { values, errors, name, touched, children } = this.props;
      const value = _.get(values,name);
      const error = _.get(touched,name) && _.get(errors,name);
  
      return (
          <React.Fragment>
            {React.Children.map(children, child => {
              return React.cloneElement(child, {
                inputProps: {
                  ...child.props.inputProps,
                  name: name,
                  checked: (value && value.includes(child.props.inputProps.value)),
                  onChange: this.handleChange,
                  onBlur: this.handleBlur
                }
              });
            })}
            { <div className="red-text" >{error}</div> }
          </React.Fragment>
      );
    }
  }

export const RCButtonWithErrors = ({ 
    name,
    label,
    errors, 
    values, 
    touched, 
    handleChange,
    setFieldValue, 
    handleBlur,
    tooltip,
    inProps,
    ...rest}) => {
        
    const curVal = _.get(values,name);
    const selected = curVal === inProps.value;
    const onChange = _.isNumeric(inProps.value) ? (e) => { e.target.checked && setFieldValue(name,Number(e.target.value)) } : handleChange;
    const inputProps = { ...inProps, checked: selected, onChange: onChange, onBlur: handleBlur, name: name };
    const error = _.get(touched,name) && _.get(errors,name);
    
    return (
        <React.Fragment>
            <div className="option-container" {...rest}>
                <label>
                    <input {...inputProps} />
                    <span>{label}</span>
                </label>
                {tooltip && (<IconTip icon='question-circle' msg={tooltip} />)}
            </div>
            <div className="red-text">{error}</div>
        </React.Fragment>
    )
}

export const ManualSubmitButton = ({ icon='check', label, submitForm, submitState, ...rest }) => {
    return(
        <Button color="primary" rounded onClick={submitForm} {...rest}>
            <FontAwesomeIcon icon={submitState === 'REQUEST' ? 'spinner' : icon} size='1x' spin={submitState === 'REQUEST'} /><span>{label}</span>
        </Button>
    )
}

export const SubmitButton = ({ icon, label, loadingContainerId, defaultComponent, ...rest }) => {

    return(
        <Button color="primary" rounded tag="button" type="submit" {...rest}>
            <FontAwesomeIcon icon={icon} size='1x' /><span>{label}</span>
        </Button>
    )
}

export const LoadingSubmitButton = loadingContainer({
    "DEFAULT": SubmitButton,
    "SUCCESS": SubmitButton
})

export const LoaderOnlySubmitButton = loadingContainer({
    "DEFAULT": SubmitButton,
    "SUCCESS": SubmitButton
},{ type: 'loaderOnly' })

export const SingleOptionSlider = ({ options, value, setValue, className }) => {
    const values = Object.values(options).map((option) => option.value);
    const classNames = classnames({[className]: className, 'slider-container': true})
    const marks = {};
    const activeIndex = values.indexOf(value);
    Object.keys(options).forEach((label,index) => marks[index] = {style: {}, label: (
        <div onClick={(e) => e.preventDefault()}>
            <label className={index === activeIndex ? 'active' : ''}>
                {label}
            </label>
            <div className={`slider-option-subtitle ${index === activeIndex ? 'active' : ''}`}>{options[label].subtitle}</div>
        </div>
    )});

    return (
        <div className={classNames}>
            <Slider
                className="single-option-slider"
                defaultValue={value}
                marks={marks}
                step={null}
                vertical
                reverse
                min={0}
                max={values.length-1}
                onChange={(value) => setValue(values[value])}
            />
        </div>
    )
}

export const PercentagesSlider = ({ setFieldValue, name, values, submitForm, minPercent=10, maxPercent=75 }) => {
    const { [name]: valMap } = values;
    const sliderValues = [];
    const keys = [];
    let total = 0;
    const len = Object.values(valMap).length;
    Object.entries(valMap).forEach(([label,value],index) => {
        if(index < len-1) {
            total = total + value;
            sliderValues.push(total);
        }
        keys.push(label);
    })

    const handleChange = newValues => {
        const newMap = {};
        let lastVal = 0;
        newValues.forEach((val,index) => {
            const attemptVal = val - lastVal;
            const remaining = newValues.length - index;
            const calcMinPercent = remaining === 1 ? Math.max((100 - maxPercent - lastVal),minPercent) : minPercent;
            const calcMaxPercent = Math.min(100 - remaining*minPercent - lastVal,maxPercent);
            let actualVal = Math.min(Math.max(attemptVal,calcMinPercent),calcMaxPercent);
            newMap[keys[index]] = actualVal;
            lastVal = lastVal + actualVal;
        })
        newMap[keys[newValues.length]] = Math.max(Math.min(100 - lastVal,maxPercent),minPercent);
        setFieldValue(name,newMap);
    }

    const autoSubmit = () => {
        submitForm && submitForm();
    }

    return (
        <React.Fragment>
            <Range count={sliderValues.length-1} value={sliderValues} pushable onChange={handleChange} onAfterChange={autoSubmit} railStyle={{ backgroundColor: '#abe2fb'}} />
            <div className="font-grey" style={{whiteSpace: 'nowrap', fontSize: '10px'}}>
                {keys.map((key) => (
                    <div key={key} style={{ width: `${valMap[key]}%`, textAlign: 'center', display: 'inline-block'}}>
                        {key} - {valMap[key]}%
                    </div>
                ))}
            </div>
        </React.Fragment>
    )
}

export const ToggleButton = ({ options, value, setValue, className, id }) => {
    const values = Object.values(options);
    const nextIndex = (values.indexOf(value)+1) % values.length;
    const nextValue = values[nextIndex];

    return (
        <div className={classnames("btn btn-toggle",{[className]: className})} id={id} onClick={() => setValue(nextValue)}>
            <div className="btn-toggle-content">
                {Object.entries(options).map((entry) => (<div key={entry[1]} className={value === entry[1] ? 'active' : ''}>{entry[0]}</div>))}
            </div>
        </div>
    )
}

export const ToggleButton2 = React.forwardRef(({ options, value, setValue, className, onClick, id },ref) => {
    const numOptions = options.length;
    const curOptionIndex = _.findIndex(options,opt => opt.value === value);
    const nextVal = options[(curOptionIndex+1)%numOptions].value;
    const clickHandler = () => {
        onClick && onClick();
        setValue(nextVal);
    }

    return (
        <div id={id} className={classnames("btn-toggle-2-container z-depth-0 ml5",{ [className]: className})} onClick={clickHandler} ref={ref}>
            {options.map(({ value: optVal, icon },index) => {
                const classNames = classnames('btn-toggle-2',{ 'divider-right': (index < numOptions-1), active: optVal === value})
                return (
                    <div className={classNames} key={optVal}>
                        <FontAwesomeIcon icon={icon} />
                    </div>
                )
            })}
        </div>
    )
})

export const ToggleButton3 = React.forwardRef(({ options, value, setValue, onClick, className, id },ref) => {
    const numOptions = options.length;
    const curOptionIndex = _.findIndex(options,opt => opt.value === value);
    const nextVal = options[(curOptionIndex+1)%numOptions].value;
    const clickHandler = () => {
        onClick && onClick();
        setValue(nextVal);
    }
    const classNames = classnames("toggle-3 auto-margins",{ [className]: className })

    return (
        <div className={classNames} id={id} ref={ref} onClick={clickHandler}>
            {[0,1].map(index => (
                <div 
                    key={index}
                    className={classnames(['left','right'][index],{ active: options[index].value === value, inactive: options[index].value !== value })}
                >
                        {options[index].text}
                </div>
            ))}
        </div>
    )
})

export const arrayToggleCreator = (values,setValue,psuedoSingle) => (newValue) => {
    let newVals;
    if(psuedoSingle) {
        newVals = [newValue];
    } else {
        if(_.isBlank(values)) {
            newVals = [newValue];
        } else if(values.includes(newValue)) {
            newVals = _.without(values,newValue);
        } else {
            newVals = values.concat([newValue]);
        }
    }
    setValue(_.arrayForForm(newVals));
}

export const radioToggleCreator = (value,setValue,allowBlank) => (newValue) => {
    if(value === newValue && allowBlank) {
        setValue('');
    } else {
        setValue(newValue);
    }
}

export const WeekdayButtonSelect = ({ startDay, name, values, setFieldValue, setFieldTouched, dayNames, single, psuedoSingle, id, touched, errors }) => {
    const { [name]: err } = errors || {};
    const { [name]: isTouched } = touched || {};

    return (
        <React.Fragment>
            <CheckButtons 
                single={single}
                psuedoSingle={psuedoSingle}
                name={name}
                values={values}
                setFieldValue={setFieldValue}
                setFieldTouched={setFieldTouched}
                render={({ toggleHandler, values }) => {
                    return (
                        <div className="no-wrap" id={id || ''}>
                            {_.weekdays(startDay).map((wday) => {
                                const isActive = single ? (wday === values) : (!_.isBlank(values) && values.includes(wday));
                                
                                return (
                                    <div key={wday} className={`card signup-date-card buttonize inline ${isActive ? 'active' : ''}`} onClick={() => toggleHandler(wday)} id={id ? `${id}-${wday}` : ''}>
                                        <div className={`signup-date-card-title`}>{dayNames[wday]}</div>
                                    </div>
                                )
                            })}
                        </div>
                    )
                }}
            />
            {isTouched && err && (<div className="red-text"><FontAwesomeIcon icon="times" /> {err}</div>)}
        </React.Fragment>
    )
}

export const DateButtonSelect = ({ dates, name, values, setFieldValue, setFieldTouched, single, wrap, stretch }) => {

    return (
        <CheckButtons 
            single={single}
            name={name}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            render={({ toggleHandler, values }) => {
                const cnames = classnames({ 'no-wrap': !wrap, 'stretch-cards': stretch });
                return (
                    <div className={cnames}>
                        {dates.map(({ text, value },index) => {
                            const isActive = single ? (value === values) : values.includes(value);
                            
                            return (
                                <div id={`date-sel-${index}`} key={value} className={`card signup-date-card buttonize inline ${isActive ? 'active' : ''}`} onClick={() => toggleHandler(value)}>
                                    <div className="center">
                                        <div className={`signup-date-card-title`}>{text}</div>
                                        <div className={`signup-date-card-subtitle`}>{moment(value).format('MMM Do')}</div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )
            }}
        />
    )
}

export const SmallButtonSelect = ({ options, name, values, setFieldValue, setFieldTouched, multiple, noWrap, noUpcase, minWidth, minWidthLarge, space, allowBlank, firstLabel, lastLabel, idBase }) => {
    return (
        <CheckButtons 
            single={!multiple}
            allowBlank={allowBlank}
            name={name}
            values={values}
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            render={({ toggleHandler, values }) => {
                return (
                    <div className={classnames('select-btns', { 'no-wrap': noWrap, 'min-width': minWidth, 'min-width-large': minWidthLarge, 'no-upcase': noUpcase })}>
                        {options.map(({ text, value }) => {
                            const isActive = multiple ? values.includes(value) : values === value;
                            return (
                                <Button id={idBase ? `select-${idBase}-${value}-btn` : ''} key={value} noShadow className={classnames({'ml5': space, 'mb5': space})} color="primary" outlined={!isActive} onClick={() => toggleHandler(value)}>{text}</Button>
                            )
                        })}
                        <div className="clearfix"></div>
                        <div className="first-label">{firstLabel}</div>
                        <div className="last-label">{lastLabel}</div>
                    </div>
                )
            }}
        />
    )
}

const setValueCreator = ({ name, setFieldValue, setFieldTouched, submitForm, autoSubmitForm }) => (newVal) => {
    setFieldTouched(name,true,false);
    setFieldValue(name,newVal);
    if(autoSubmitForm && submitForm) {
        submitForm();
    }
}

export const CheckButtons = ({ values, name, setFieldValue, setFieldTouched, submitForm, autoSubmitForm, single, psuedoSingle, allowBlank, render }) => {
    const value = _.get(values,name);
    const setValue = setValueCreator({name, setFieldValue, setFieldTouched, submitForm, autoSubmitForm});
    const toggleHandler = single ? radioToggleCreator(value,setValue,allowBlank) : arrayToggleCreator(value,setValue,psuedoSingle);

    return (
        <React.Fragment>
            {render({ toggleHandler, values: value })}
        </React.Fragment>
    )
}

const MultiNameCheckButtons = ({ values, names, setFieldValue, setFieldTouched, submitForm, autoSubmitForm, single, allowBlank, render }) => {
    const renderParams = {};
    names.forEach(name => {
        const value = _.get(values,name);
        const setValue = setValueCreator({name, setFieldValue, setFieldTouched, submitForm, autoSubmitForm});
        const toggleCreator = single ? radioToggleCreator : arrayToggleCreator;
        const toggleHandler = toggleCreator(value,setValue,allowBlank);
        renderParams[name] = { toggleHandler, values: value };
    })

    return (
        <React.Fragment>
            {render(renderParams)}
        </React.Fragment>
    )
}

export const MultiToggleButton = ({ toggleHandler, value, activeType, children, className, ...rest }) => {
    const classNames = classnames('btn btn-allergy', { active: activeType === 1, active2: activeType === 2, [className]: className });

    return (
        <div className={classNames} onClick={() => toggleHandler(value)} {...rest}>
            <div className="btn-content">
                {children || value}
            </div>
        </div>
    )
}

export const TagButton = ({ toggleHandler, value, values, children, className, ...rest }) => {
    let active = Array.isArray(values) ? values.includes(value) : (values === value);
    const classNames = classnames('btn btn-allergy', { active, [className]: className });

    return (
        <div className={classNames} onClick={() => toggleHandler(value)} {...rest}>
            <div className="btn-content">
                {children}
            </div>
        </div>
    )
}

export const TagButtons =({ col, buttonProps, btnId, inverted, ...rest }) => {

    return (
        <CheckButtons 
                {...rest}
                render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {col.map(({ text, value }) => (
                                <TagButton {...buttonProps} inverted={inverted} toggleHandler={toggleHandler} values={values} value={value} key={value} id={btnId && btnId(value)}>
                                    {text}
                                </TagButton>
                            ))}
                        </React.Fragment>
                    )
                }}
            />
    )
}

export const SelectButton = ({ toggleHandler, value, values, children, overrideClassNames, ...rest }) => {
    const classNames = classnames({'btn btn-wide btn-select': !overrideClassNames, [overrideClassNames]: overrideClassNames, 'active': (values && values.includes(value))});

    return (
        <div>
            <div className={classNames} onClick={() => toggleHandler(value)} {...rest}>
                <div className="btn-content">
                    {children}
                </div>
            </div>
        </div>
    )
}

const imageChangeHandlerCreator = (setFieldValue,name,imgId='myimage',imageValidator=null,setImgSrc=null) => (e) => {
  const file  =  e.currentTarget.files[0];
  if(file) {
    const reader = new FileReader();
    const imgTag = document.getElementById(imgId);
    if(imgTag) imgTag.title = file.name;
    reader.onload = function(event) {
        const success = () => {
            if(setImgSrc) {
                setImgSrc(event.target.result);
            } else if(imgTag) {
                imgTag.src = event.target.result;
            }
            setFieldValue(name, event.target.result);
        }
        if(!imageValidator) {
            success();
        } else {
            imageValidator(event.target.result,success);
        }
    };
    reader.readAsDataURL(file);
  }
};


export const FileUpload = (props) => {
  const { setFieldValue, name, defaultImageUrl, className, outlined, imgStyle, idOverride, imgClass, containerClass, imageValidator, disabled, btnLabel, imgElem, setImgSrc } = props;
  const fieldId = idOverride || 'file-upload-btn';
  const imgId = `${fieldId}-img`;
  const handleChange = imageChangeHandlerCreator(setFieldValue,name,imgId,imageValidator,setImgSrc);

  return (
    <div className={classnames({ [containerClass]: containerClass })}>
        <div>
            {!imgElem && (<img 
                className={classnames({ [imgClass]: imgClass })} 
                src={defaultImageUrl} 
                alt="" 
                id={imgId} 
                style={imgStyle || { maxWidth: '100%', maxHeight: '180px'}}
            />)}
            {imgElem}
        </div>
        <input type={'file'} onChange={(o) => handleChange(o)} id={fieldId} hidden accept='image/png,image/gif,image/jpeg'/>
        <label htmlFor={fieldId} className={classnames("btn btn-primary btn-no-shadow",{ 'btn-outlined': (outlined && !disabled), disabled, [className]: className })}>{btnLabel || 'Choose File'}</label>
    </div>
  );
}

export const PlaceholderFileUpload = (props) => {
    const { defaultImageUrl, blankMessage, fullViewPath, ...rest } = props;
    const [imgSrc,setImgSrc] = useState(defaultImageUrl);
    const fieldId = rest.idOverride || 'file-upload-btn';
    const imgId = `${fieldId}-img`;

    return (
        <FileUpload 
            {...rest} 
            setImgSrc={setImgSrc}
            imgElem={_.isBlank(imgSrc) ? null : (<PlaceholderBackedImg src={imgSrc} blankMessage={blankMessage} className="mb5" fullViewPath={fullViewPath} imgId={imgId} />)}
        />
    )
}

export const FileGrabber = (props) => {
    const { accept, fileHandler, idOverride, outlined, label, className, flat, btnClasses } = props;
    const fieldId = idOverride || 'file-grabber-btn';
    const handleChange = e => {
        const file  =  e.currentTarget.files[0];
        if(file) {
            fileHandler(file);
        }
    };
  
    return (
      <div className={className}>
          <input type={'file'} onChange={(o) => handleChange(o)} id={fieldId} hidden accept={accept} />
          <label htmlFor={fieldId} className={classnames("btn-no-shadow",{ 'btn-outlined': outlined, [btnClasses]: btnClasses, 'btn-flat': flat, btn: !flat, 'btn-primary': !btnClasses })}>{label}</label>
      </div>
    );
}

export const MinimalImageUpload = (props) => {
    const { callback, render } = props;
    const handleChange = imageChangeHandlerCreator((name,result) => callback(result),'noop');
    const field = (<input type={'file'} onChange={(o) => handleChange(o)} id="file-upload-btn" hidden accept='image/png,image/gif,image/jpeg'/>);
  
    return render({ field, id: 'file-upload-btn' });
  }

export const ModalListingBox = ({ selected, children, className, center, hasError, onClick, ...rest }) => {

    return (
        <div className={classnames('modal-listing-box mt5 mb10', { [className]: className, selected, error: hasError, 'align-left': !center, 'text-center': center, clickable: onClick })} onClick={onClick} {...rest} >
            {children}
        </div>
    )
}

export const ModalListingCheck = ({ 
    name, 
    checkState,
    hasError,
    toggleHandler, 
    text, 
    subtitle, 
    boxClass, 
    center, 
    value, 
    hideUnselected, 
    expand, 
    noCollapse,
    expanded, 
    expandToggleId, 
    titleBlockClasses, 
    children, 
    noCheck,
    icon,
    rightBtns
}) => {

    const checkClickOnly = (expand || rightBtns);

    if(hideUnselected && checkState === 'unchecked') {
        return '';
    }

    hasError = hasError && (!expand || !expanded);

    return (
        <ModalListingBox className={boxClass} center={center} hasError={hasError} selected={checkState !== 'unchecked'} onClick={checkClickOnly ? null : () => toggleHandler(value)} id={`listing-box-${value}`}>
            {!noCheck && (<div className="left ml15">
                <CheckboxCore
                    inputProps={{ 
                        name, 
                        value,
                        readOnly: !expand,
                        checked: checkState === 'checked',
                        onChange: (checkClickOnly ? (e => toggleHandler(value)) : null)
                    }} 
                    indeterminate={checkState === 'indeterminate'}
                    id={`listing-box-check-${value}`}
                    filled 
                    className={classnames('middle-align',{ 'has-error': hasError})}
                    labelClasses={checkClickOnly ? null : "no-pointer-events"}
                />
            </div>)}
            {icon && (<div className="left ml15 pt5 pb5" id={`listing-ico-${value}`}>
                {icon}
            </div>)}
            <div className={classnames({ "modal-listing-title-block": !titleBlockClasses, [titleBlockClasses]: titleBlockClasses })}>
                <div>{text}</div>
                <div className="subtitle">{subtitle}</div>
            </div>
            {rightBtns && rightBtns}
            {expand && !noCollapse && (<FormToggle expanded={expanded} setExpanded={expand} id={expandToggleId} />)}
            {expand && !noCollapse && <div className="clearfix"></div>}
            {expand && (
                <Collapse isOpen={expanded} className="child-cont">
                    {children}
                </Collapse>
            )}
        </ModalListingBox>
    )
}

export const BasicModalListingBox = ({ title, subtitle, icon, ...rest }) => {

    return (
        <ModalListingBox {...rest}>
            {icon && (<div className="left ml15">
                {icon}
            </div>)}
            <div className="modal-listing-title-block">
                <div>{title}</div>
                {subtitle && (<div className="subtitle">{subtitle}</div>)}
            </div>
        </ModalListingBox>
    )
}

export const ModalSelectListing = ({ col, handleBlur, name, names, getName, inverted, getInverted, handleChange, boxClass, hideUnselected, label, noCheck, ...rest}) => {

    return (
        <MultiNameCheckButtons 
                {...rest}
                names={name ? [name] : names}
                handleBlur={handleBlur}
                render={(renderParams) => {
                    const noneSelected = name ? (Array.isArray(renderParams[name].values) ? _.compact(renderParams[name].values).length === 0 : _.isBlank(renderParams[name].values)) : false;
                    return (
                        <React.Fragment>
                            {col.map(({ text, value, subtitle, icon, rightBtns }) => {
                                const resolvedName = getName ? getName(value) : name;
                                const values = renderParams[resolvedName].values;
                                const invertedVal = getInverted ? getInverted(value) : inverted;
                                let selected = Array.isArray(values) ? values.includes(value) : values === value;
                            
                                if(invertedVal) {
                                    selected = !selected;
                                }

                                return (
                                    <ModalListingCheck 
                                        boxClass={boxClass}
                                        checkState={selected ? 'checked' : 'unchecked'}
                                        key={value}
                                        name={resolvedName} 
                                        text={text} 
                                        subtitle={subtitle}
                                        toggleHandler={renderParams[resolvedName].toggleHandler} 
                                        value={value} 
                                        noCheck={noCheck}
                                        icon={icon}
                                        rightBtns={rightBtns}
                                        hideUnselected={hideUnselected && !noneSelected}
                                    />
                                )
                            })}
                        </React.Fragment>
                    )
                }}
            />
    )
}

const datePickSuffix = '/pick_date';
export const DatePickWrapper = ({ setFieldValue, render }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [{ name, defaultDate, ...options },setPickerProps] = useState({});
    const initDatePick = newProps => {
        setPickerProps(newProps);
        history.push(modalPathFor(datePickSuffix,pathname));
    }

    return (
        <React.Fragment>
            {render({ initDatePick })}
            {name && (<DatePicker
                suffix={datePickSuffix}
                name={name}
                setFieldValue={setFieldValue}
                options={ { defaultDate: defaultDate && defaultDate.toDate(), ...options } }
            />)}
        </React.Fragment>
    )
}

export const ButtonDatePickField = ({ className, name, values, touched, setFieldValue, errors, label, defaultDate=moment(), fallbackText=(val => val), initDatePick, options, id, showClear }) => {
    const dateVal = _.get(values,name);
    const isDate = dateVal && dateFormatRegex.test(dateVal);
    const text = isDate ? moment(dateVal).format('ddd MMM Do') : fallbackText(dateVal);
    const error = _.get(touched,name) && _.get(errors,name);
    const init = () => initDatePick({ name, defaultDate, ...options })

    if(isDate) {
        return (
            <div className="position-relative">
                <div className="btn-over-btn-right" onClick={() => setFieldValue(name,'')}>
                    <FontAwesomeIcon icon="times" className="red-text" />
                </div>
                <Button
                    rounded
                    color="primary"
                    outlined
                    className={className}
                    onClick={init}
                >
                    <span>{text}</span>
                </Button>
            </div>
        )
    }

    return (
        <div>
            <Button
                rounded
                color="primary"
                onClick={init}
                className={className}
            >
                <FontAwesomeIcon icon="calendar-alt" /><span>{label}</span>
            </Button>
            {error && (<div className="red-text"><FontAwesomeIcon icon="times" /> {error}</div>)}
        </div>
    )

}

export const DatePickField = ({ className, name, values, touched, setFieldValue, errors, label, defaultDate=moment(), fallbackText=(val => val), initDatePick, options, id, showClear }) => {
    const dateVal = _.get(values,name);
    const isDate = dateVal && dateFormatRegex.test(dateVal);
    const text = isDate ? moment(dateVal).format('ddd MMM Do') : fallbackText(dateVal);
    const error = _.get(touched,name) && _.get(errors,name);

    return (
        <div className={className}>
            <div className="font-grey">
                {label}
            </div>
            <div >
                <span className="clickable" id={id || ''} onClick={() => initDatePick({ name, defaultDate, ...options })}><FontAwesomeIcon icon="calendar-alt" /> {text} </span>
                {showClear && !_.isBlank(dateVal) && (
                    <span className="clickable" onClick={() => setFieldValue(name,'')}><FontAwesomeIcon icon="times" className="ml10 red-text" /></span>
                )}
            </div>
            {error && (<div className="red-text"><FontAwesomeIcon icon="times" /> {error}</div>)}
        </div>
    )

}

export const VideoUploadBtn = React.forwardRef((props,ref) => {
    const [finalFieldId] = React.useState(`video-upload-field-${_.random(99999)}`)

    return (
        <React.Fragment>
            <input type="file" id={finalFieldId} accept={'video/mp4,video/x-m4v,video/*'} hidden ref={ref} onChange={props.onChange} disabled={props.disabled} />
            <label htmlFor={finalFieldId} className={classnames("btn btn-primary btn-no-shadow",{ 'btn-outlined': (props.outlined && !props.disabled), 'disabled': props.disabled })}>
                <div className="btn-content">
                    <FontAwesomeIcon icon={['far','video']} /> {props.label || 'Record/upload'}
                </div>
            </label>
        </React.Fragment>
    )
})

export const InstantVideoUploadField = ({ startUpload, onChange: inOnChange, ...rest }) => {
    const onChange = e => {
        const file = e.target.files[0];
        if(file) {
            startUpload(file);
        }
        inOnChange && inOnChange(e);
    }

    return (
        <VideoUploadBtn onChange={onChange} {...rest} />
    )
}

export const FullTagsInput = ({ formikProps, label, name, placeholder, contClassName }) => {
    const { setFieldValue, setFieldTouched } = formikProps;
    const value = _.get(formikProps.values,name);
    const touched = _.get(formikProps.touched,name);
    const errors = _.get(formikProps.errors,name);

    return (
        <div className={contClassName}>
            <div className="font-grey">{label}</div>
            <TagsInput
                addOnBlur
                value={_.without(value,'').slice(0,20)} 
                onChange={(tags) => {
                    setFieldValue(name,_.arrayForForm(tags));
                    setTimeout(() => setFieldTouched(name),10);
                }}
                inputProps={{
                    className: 'react-tagsinput-input',
                    placeholder,
                    name: name
                }}
            />
            {touched && errors && (<div className="red-text"><FontAwesomeIcon icon="times" /> {errors}</div>)}
        </div>
    )
}