import React from 'react';
import { ServingsToggleForm } from 'partials/ServingsToggleForm';
import * as Utils from 'lib/utilities';
import { PaddedIconNote } from 'components/Typography';
import InfiniteScroll from 'components/InfiniteScroll';
import { ScrollableModalContent } from 'components/Modal';
import KeepChildren from 'components/KeepChildren';
import { allMacrosWithCals } from 'lib/classes';
import * as _ from 'lib/utilities';
import { dateFormat } from 'config/settings';

const handleUMServingChgCreator = (userMeal,values,setValues) => (newVal) => {
    userMeal.setServings(newVal);
    setValues({ ...values, servings: newVal, ..._.pick(userMeal,allMacrosWithCals)});
}

class RecentUserMealForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { status: 'DEFAULT' }
        this.setStatus = this.setStatus.bind(this);
    }

    render() {
        const { userMeal, log, t, date, ...rest } = this.props;
        const { id, ...formFields } = userMeal;
        const { status } = this.state;
        formFields.date = date.format(dateFormat);

        const formProps = {
            t,
            formFields,
            id,
            description: Utils.abbrText(userMeal.description,25,'untitled'),
            macroHash: userMeal.loggedMacroHash.bind(userMeal),
            onSubmit: Utils.formikHandlerCreator(this,this.setStatus,log.bind(null,id)),
            setValueCreator: handleUMServingChgCreator.bind(null,userMeal),
            status,
            servingsText: Utils.abbrText(userMeal.servingsText,35),
            ...rest
        }

        return (<ServingsToggleForm {...formProps} />);
    }

    setStatus(status) {
        if(status === 'SUCCESS') {
            const { triggerRequestRating } = this.props;
            triggerRequestRating && triggerRequestRating();
        }
        this.setState({ status });
    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }
}

const RecentFoodsTab = ({ userMeals, page, more, load, log, t, calcNonScrollDim, date, triggerRequestRating }) => {

    return (
        <React.Fragment>
            <InfiniteScroll 
                load={load} 
                initialPage={1}
                loadedPage={page} 
                fullyLoaded={!more} 
                component={ScrollableModalContent} 
                calcNonScrollDim={calcNonScrollDim}
                records={userMeals}
                noResultsComp={<PaddedIconNote variant="info" text={t("You haven't logged anything recently.")} />}
                id="recent-user-meals-scroll"
                render={({ maxIndex }) => {
                    return (
                        <KeepChildren>
                            {userMeals.map((userMeal,index) => {
                                if(index < maxIndex) {
                                    return (
                                        <RecentUserMealForm 
                                            key={"user-meal-" + userMeal.id}
                                            className="recent-user-meal-form"
                                            userMeal={userMeal} 
                                            borderBottom 
                                            triggerRequestRating={triggerRequestRating}
                                            log={log} t={t} date={date} 
                                        /> 
                                    )
                                } else {
                                    return '';
                                }
                            })}
                        </KeepChildren>
                    )
                }}
            />
        </React.Fragment>
    )
}

export default RecentFoodsTab;