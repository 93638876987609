import React, { Component, useState } from 'react';
import Page from'components/Page';
import RouteModal, { ModalFooter, ModalHeader } from 'components/Modal';
import { withRouter } from 'react-router';
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import * as _ from 'lib/utilities';
import * as transitions from 'assets/transitions';
import { workoutPlanPath, mealPlanPath, emailSignupMatch, canHitEmailOrPaywallMatches, switchAccountPath, switchAccountMatch, homePath } from 'config/paths';
import { paymentModalPathFor } from 'redux/helpers';
import Card, { CardContent } from 'components/Card';
import {CardNumberElement, CardExpiryElement, CardCvcElement, Elements, StripeProvider, injectStripe } from 'react-stripe-elements';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BottomButtons } from 'components/Button';
import CircularProgress from 'components/CircularProgress';
import { errorMessageMap } from 'components/LoadingHOC';
import { initStripeSub, initProSuccess } from 'redux/actions';
import { userRecordSelector, paywallRedirectSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { Redirect } from "components/Routing";
import { supportEmail, brandName } from 'config/settings';
import spinach1 from 'assets/img/spinach1.png';
import spinach2 from 'assets/img/spinach2.png';
import spinach3 from 'assets/img/spinach3.png';
import barbell from 'assets/img/barbell.png';
import { FlowNav } from 'components/FlowForm';
import forbes from 'assets/img/featured/forbes.png';
import nbc from 'assets/img/featured/nbc.png';
import readersDigest from 'assets/img/featured/readersdigest.png';
import shape from 'assets/img/featured/shape.png';
import today from 'assets/img/featured/today.png';
import womensHealth from 'assets/img/featured/womenshealth.png';
import yahooLifestyle from 'assets/img/featured/yahoolifestyle.png';
import { Link } from 'components/Routing';
import Carousel from 'components/Carousel';
import slide0 from 'assets/img/conversion/slide0.png';
import slide0Generic from 'assets/img/conversion/slide0_generic.png';
import slide1 from 'assets/img/conversion/slide1.png';
import slide2 from 'assets/img/conversion/slide2.png';
import slide3 from 'assets/img/conversion/slide3.png';
import slide4 from 'assets/img/conversion/slide4.png';
import slide5 from 'assets/img/conversion/slide5.png';
import slide6 from 'assets/img/conversion/slide6.png';
import slide7 from 'assets/img/conversion/slide7.png';
import slide8 from 'assets/img/conversion/slide8.png';
import { SlightEmphasisIconNote } from 'components/Typography';
import { SlashedPrice } from 'partials/Utilities';
import { UserReview } from 'partials/OnboardingComponents';

export const billCycleLengths = { annually: 12, 'tri-monthly': 3, threemonths: 3, quarterly: 3, monthly: 1 }

const ANNUALLY_HIGH = process.env.REACT_APP_TEST === 'true' ? 'price_1KFl4vJYv58k8P3LyZcRTVeI' : 'price_1KBOIiJYv58k8P3L7vUQGdhl';
const QUARTERLY_HIGH = process.env.REACT_APP_TEST === 'true' ? 'price_1Jovb9JYv58k8P3LIEGXlDbB' : 'price_1KBOHYJYv58k8P3L5BS9SRXk';

const hasNativePurchase = () => {
    return window.cordova && !_.isBlank(window.appVersion) && _.isNumeric(window.appVersion) && Number(window.appVersion) >= 205;
}

const logSubscriptionEvent = ({ planType }) => {
    const fbq = window.fbq;
    const value = planType.includes('annually') ? 25 : 15;

    fbq('track', 'Purchase', {value: value, currency: 'USD'});
}

const transitionMap = {
    rules: [
        [[...canHitEmailOrPaywallMatches,emailSignupMatch,switchAccountMatch],transitions.slideOut]
    ]
};

const AppleSubscriptionTerms = () => {
    const str = _.isAndroid() ? 'Play Store Account' : 'iTunes Account';
    return (
        <Card className="pa10 faint-color tiny-text text-left">
            <div className="mb5">After your free trial has ended, payment will be charged to your {str}.</div>
            <div className="mb5">Your subscription will automatically renew unless you cancel at least 24 hours before the end of the current period.</div>
            <div className="mb5">Your {str} will be charged for renewal within 24 hours prior to the end of the current period, at the same price you initially paid.</div>
            <div className="mb5">Auto-renew can be turned off at any time from your {str} settings and your subscription will remain valid until the end of the current period.</div>
            <div className="mb5">If you purchase a Pro subscription before the end of your free trial, any unused portion of your trial will be forfeited.</div>
            <br/>
            <div>Privacy policy: <Link to="https://www.strongrfastr.com/privacy">https://www.strongrfastr.com/privacy</Link></div>
            <div>Terms of use: <Link to="https://www.strongrfastr.com/temrs_of_service">https://www.strongrfastr.com/terms_of_service</Link></div>
        </Card>
    )
}

const SecureIcon = () => (
    <svg 
        width="27px" 
        height="33px" 
        viewBox="0 0 27 33" 
        version="1.1" 
        xmlns="http://www.w3.org/2000/svg">
            <title>Secure Icon</title>
            <desc>Created with Sketch.</desc>
            <defs></defs>
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-174.000000, -490.000000)" fill="#8BC34A">
                    <g transform="translate(82.000000, 490.000000)">
                        <g transform="translate(92.000000, 0.000000)">
                            <path d="M13.5,0 L27,7.39655172 L27,7.88965517 C27,25.0724138 14.025,32.6965517 13.875,32.7724138 L13.5,33 L13.125,32.7724138 C12.975,32.6965517 0,25.0724138 0,7.88965517 L0,7.39655172 L13.5,0 Z M2.00736536,8.58195702 C2.15121613,15.31624 4.39456212,20.9215661 8.06577404,25.4939686 C9.40043517,27.1562557 10.8333446,28.5609645 12.2633307,29.7094735 C12.7231226,30.0787604 13.5029817,30.6407117 13.5029817,30.6407117 C13.5029817,30.6407117 14.2738681,30.0811773 14.7366693,29.7094735 C16.1666554,28.5609645 17.5995648,27.1562557 18.934226,25.4939686 C22.6042316,20.9230685 24.8473095,15.3199234 24.9924922,8.5885948 L13.509135,2.28551935 L2.00736536,8.58195702 Z M18.09375,12.8017241 C18.46875,13.1810345 18.46875,13.787931 18.09375,14.1293103 L12.58125,19.7051724 C12.39375,19.8948276 12.16875,19.9706897 11.90625,19.9706897 C11.64375,19.9706897 11.41875,19.8568966 11.23125,19.7051724 L8.90625,17.3534483 C8.53125,16.9741379 8.53125,16.3672414 8.90625,16.0258621 C9.28125,15.6465517 9.88125,15.6465517 10.21875,16.0258621 L11.86875,17.6948276 L16.74375,12.8017241 C17.15625,12.4224138 17.75625,12.4224138 18.09375,12.8017241 Z">
                            </path>
                        </g>
                    </g>
                </g>
            </g>
    </svg>
)

const CoreStartButton = ({ product, handleFreeClick, className, ...rest  }) => {
    const [spin,setSpin] = useState(false);
    const { t } = useTranslation();
    const buttonStr = (product && _.isBlank(product.moneyBackString)) ? 'Upgrade to Pro' : "Start 7-day Trial"
    const classNames = classnames("no-upcase no-wrap",{ [className]: className })

    return (
        <Button
            {...rest} 
            color="workout-setup" 
            className={classNames}
            id={`start-free-trial-btn`}
            onClick={() => {
                if(product) {
                    if(hasNativePurchase()) {
                        setSpin(true);
                        setTimeout(() => setSpin(false),3500)
                    }
                    product.initiateCheckout();
                } else {
                    handleFreeClick();
                }
            }}
        >
            <FontAwesomeIcon icon={spin ? 'spinner' : 'check'} spin={spin}></FontAwesomeIcon> <span>{product ? t(buttonStr) : t('Start Free Plan')}</span>
        </Button>
    )
}

const StartButton = ({ product, handleFreeClick, storeStatus, initStripeAction: submitAction, initProSuccess, ...rest }) => {

    const badStatuses = ['verifying','retrying','verified','duplicate_error','fail_error']

    if(badStatuses.includes(storeStatus)) {
        return '';
    }

    return (
        <CoreStartButton product={product} handleFreeClick={handleFreeClick} {...rest} />
    )
}

const DiscountTerms = ({ hadTrial, frequency, product }) => {

    return (
        <div>
            ${product.dAmount} USD billed {hadTrial ? 'immediately' : 'at end of trial'} 
            {product.dLength === frequency && (`. $${product.amount} billed every ${frequency} months thereafter.`)}
            {product.dLength !== frequency && (` and for first ${product.dLength} months. $${product.amount} billed every ${frequency} months thereafter.`)}
        </div>
    )
}

const SubmitContent = ({ user, product, status, submit }) => {
    const { t } = useTranslation();
    let info, buttonTitle;

    if(product) {
        const hadTrial = product.forTrainers ? (user && user.hadTrainerTrial()) : (user && user.hadTrial);
        const frequency = billCycleLengths[product.frequency || normalizedPlanType(product.planType)];
        if(hadTrial) {
            buttonTitle = t('Upgrade');
            info = (
                <div className="mt10 faint-color">
                    {!product.dAmount && (<div>{t("amount USD billed every X months starting immediately",{ amount: `$${product.amount}`, months: frequency })}.</div>)}
                    {product.dAmount && (<DiscountTerms product={product} frequency={frequency} hadTrial />)}
                    <div>{t("Cancel anytime to prevent auto-renewal")}</div>
                    <div>{t("Subscription charges are non-refundable")}.</div>
                </div>
            )
        } else {
            buttonTitle = t('Start Free Trial');
            info = (
                <div className="mt10 faint-color">
                    {!product.dAmount && (<div>{t("amount USD billed every X months starting at the end of your trial",{ amount: `$${product.amount}`, months: frequency })}.</div>)}
                    {product.dAmount && (<DiscountTerms product={product} frequency={frequency} />)}
                    <div>{t("Cancel anytime to prevent auto-renewal")}</div>
                    <div>{t("Subscription charges are non-refundable")}.</div>
                </div>
            )
        }
    } else {
        info = null;
        buttonTitle = t("Update Payment Method");
    }

    return (
        <div className="mt25">
            <SecureIcon />

            {info}

            <div className="mt20">
                {status !== 'charging' && (
                <Button color="primary" id="stripe-upgrade-btn" rounded onClick={submit}>
                    <FontAwesomeIcon icon="check" size="1x" />
                    <span>{buttonTitle}</span>
                </Button>)}
                {status === 'charging' && (<CircularProgress />)}
            </div>
        </div>
    )
}

class CheckoutForm extends Component {
    constructor(props) {
      super(props);
      this.submit = this.submit.bind(this);
      this.state = { status: 'initial' };
      this.mounted = true;
      this.safeSetState = this.safeSetState.bind(this);
    }

    safeSetState(state) {
        if(this.mounted) {
            this.setState(state);
        }
    }
  
    async submit(ev) {
        const { product, submitAction, initProSuccess, close, setCloseCallback, t } = this.props;

        this.safeSetState({ status: 'charging'});
        let { error, token } = await this.props.stripe.createToken();
        if(error) {
            this.safeSetState({ status: 'error', message: error.message });
        } else {
            submitAction({token: token.id, ...(product && product.getPlanParams()) }).then(({ data }) => {
                if(!data.hasOwnProperty('error')) {
                    this.safeSetState({ status: 'success'});
                    if(product) {
                        logSubscriptionEvent(product.getPlanParams());
                    }
                    if(this.mounted) {
                        setCloseCallback(() => {
                            initProSuccess(data);
                        });
                        close();
                    } else {
                        initProSuccess(data);
                    }
                } else {
                    this.safeSetState({ status: 'error', message: data.error });
                }
            }).catch((e) => {
                console.log(e)
                const { status } = e;
                const addendum = status === 'SERVERERR' ? '' : t("You have not been charged.");
                this.safeSetState({ status: 'error', message: `${errorMessageMap(t)[status]} ${addendum}` });
            })
        }
    }
  
    render() {

        const { user, product } = this.props;

        return (
            <div className="display-inline-block pa15" style={{maxWidth: '350px', minWidth: '250px'}} id={product ? `${normalizedPlanType(product.planType)}-payment-form` : 'update-payment-form'}>
                <CardNumberElement id="card-number-cont"/>
                <CardExpiryElement id="card-expiry-cont" />
                <CardCvcElement id="card-cvc-cont" />

                {this.state.status === 'error' && (<div className="red-text" id="card-error-msg"><FontAwesomeIcon icon="times" size="1x" /> {this.state.message}</div>)}
                
                <SubmitContent user={user} product={product} status={this.state.status} submit={this.submit} />

            </div>
        );
    }

    componentWillUnmount() {
        this.mounted = false;
    }
}
  
const StripeForm = injectStripe(CheckoutForm);

const StripeModalCore = (props) => {
    const { product, t, i18n, submitAction, initProSuccess, user, modalProps, setCloseCallback } = props;

    return (
        <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUB_KEY} locale={i18n.language}>
            <Elements>
                <StripeForm 
                    t={t} 
                    product={product} 
                    submitAction={submitAction} 
                    initProSuccess={initProSuccess} 
                    close={modalProps.close} 
                    user={user}
                    setCloseCallback={setCloseCallback} 
                />
            </Elements>
        </StripeProvider>
    );
}

export class StripeModal extends React.Component {

    constructor(props) {
        super(props);
        this.setCloseCallback = this.setCloseCallback.bind(this);
        this.state = { closeCallback: props.closeCallback };
    }

    setCloseCallback(callback) {
        const fullCallback = () => {
            this.props.closeCallback && this.props.closeCallback();
            callback();
        }
        this.setState({closeCallback: fullCallback});
    }

    render() {
        const { t, path, user, disallowNonOwner } = this.props;
        const allowNonOwner = !disallowNonOwner;

        return (
            <RouteModal closeCallback={this.state.closeCallback} path={path} exact fullWidth scrollable render={(modalProps) => {
                if(allowNonOwner || user.canManageOwnAccount()) {
                    return (
                        <React.Fragment>
                            <ModalHeader defaults>
                                <FontAwesomeIcon icon="credit-card" size="2x" className="success-color vert-align-mid" />
                                <span> {t("Payment Details")}</span>
                            </ModalHeader>
                            <div className="pa15 text-center">
                                <StripeModalCore 
                                    {...this.props}
                                    modalProps={modalProps}
                                    setCloseCallback={this.setCloseCallback}
                                />
                            </div>
                        </React.Fragment>
                    )
                }

                return (
                    <React.Fragment>
                        <ModalHeader defaults>
                            <span> {t("Contact Account Owner")}</span>
                        </ModalHeader>
                        <div className="pa15">
                            <SlightEmphasisIconNote 
                                text={t('only owner billing tip', { name: user.getMasterAccount().fullName() })}
                            />
                        </div>
                        <ModalFooter defaults>
                            <Button color="primary" rounded noShadow className="modal-close">
                                {t('Ok')}
                            </Button>
                        </ModalFooter>
                    </React.Fragment>
                )
            }} />
        )
    }
}

const trimmedAlias = (alias) => (alias || '').replace('now','').replace('low','').replace('mid','').replace('2022','').replace(`${_.packagePrefix()}.`,'');

const normalizedPlanType = (planType) => {
    if(planType === QUARTERLY_HIGH) {
        return 'threemonths';
    } else if(planType === ANNUALLY_HIGH) {
        return 'annually';
    }
    let res = trimmedAlias(planType);
    if(res === 'tri-monthly') {
        return 'threemonths';
    }
    return res;
}

const getPlanTypeAndPrices = user => {
    const hasDiscount = user.isFromDiscountedAffiliate();
    return {
        annualPrice: hasDiscount ? 80 : 79.99,
        dAnnualPrice: hasDiscount ? 55.99 : null,
        annualMonthlyPrice: hasDiscount ? 4.67 : 6.67,
        annualPlan: ANNUALLY_HIGH,
        triMonthlyPrice: hasDiscount ? 53 : 52.99,
        dTriMonthlyPrice: hasDiscount ? 37.11 : null,
        triMonthlyMonthlyPrice: hasDiscount ? 12.37 : 17.66,
        triMonthlyPlan: QUARTERLY_HIGH
    }
}

const getIapPrice = (iap) => {
    const pp = _.find(iap.getOffer().pricingPhases,pp => pp.priceMicros > 0);

    return pp ? pp.price : '';
}

export const withStore = (Component) => {

    const defaultProducts = (t,user) => {
        return [
            { name: t("Annual Pro Plan"), initiateCheckout: () => {}, topPriceStr: '', bottomPriceStr: '', moneyBackString: (user && user.hadTrial) ? '' : t("7-day free trial"), ptAmountStr: '$80', primary: true, planType: ANNUALLY_HIGH, getPlanParams: () => ({ planType: 'annually'}) },
            { name: t("X Month Pro Plan", {months: 3}), initiateCheckout: () => {}, topPriceStr: '', bottomPriceStr: '', moneyBackString: (user && user.hadTrial) ? '' : t("7-day free trial"), ptAmountStr: '$80', primary: false, planType: QUARTERLY_HIGH, getPlanParams: () => ({ planType: 'threemonths'}) }
        ]
    }

    const mapStateToProps = state => ({
        user: userRecordSelector(state),
        paywallRedirect: paywallRedirectSelector(state)
    })

    const mapDispatchToProps = (dispatch) => ({
        initStripeAction: data => dispatch(initStripeSub(data)),
        initProSuccess: data => dispatch(initProSuccess({ proExpires: data.proExpires }))
    })

    class Store extends React.Component {

        //products have: initiateCheckout(), topPriceStr, bottomPriceStr, moneyBackString, primary
        constructor(props) {
            super(props);

            let products;
            let initialStatus = 'initial';
            let initialDupInfo = {};
            this.paymentModalOpen = false;
            this.buildProducts = this.buildProducts.bind(this);
            this.handlePaymentModalClose = this.handlePaymentModalClose.bind(this);
            this.initStripePayment = this.initStripePayment.bind(this);
            this.getCurrentIAPs = this.getCurrentIAPs.bind(this);
            this.refreshIAPs = this.refreshIAPs.bind(this);
            this.buildIAP = this.buildIAP.bind(this);

            //initialize stripe or IAP store
            if(hasNativePurchase()) {
                products = defaultProducts(props.t,props.user);
                const store = _.getIapStore();
                //window.cordova.platformId = ios,android
                store.purchaseApproval = (transaction) => {
                    this.setState({storeStatus: 'verifying'});
                }

                store.verificationSuccess = (receipt) => {
                    if(_.some(receipt.collection, vp => !vp.isExpired)) {
                        this.setState({storeStatus: 'verified'});
                    } else {
                        this.setState({storeStatus: 'initial'});
                    }
                }

                store.verificationFailure = (err) => {
                    const { storeStatus } = this.state;
                    const errMsg = err.message;
                    if(errMsg && errMsg.includes('duplicate')) {
                        const vals = errMsg.split('|');
                        this.setState({storeStatus: 'duplicate_error', duplicateInfo: {email: vals[1], provider: vals[2]} });
                    } else if(storeStatus === 'verifying') {
                        this.setState({storeStatus: 'fail_error'});
                    }                    
                }

                store.ready(() => {
                    const { TransactionState } = window.CdvPurchase;
                    let stateSet = false;
                    store.when().productUpdated(this.refreshIAPs);
                    for(let planType of this.possiblePlans()) {
                        const product = store.get(planType,_.getIapPlatform());
                        if(product) {
                            const approvedTransaction = _.find(store.localTransactions,transaction => (transaction.state === TransactionState.APPROVED && transaction.products[0].id === product.id));
                            if(!stateSet) {
                                if(approvedTransaction) {
                                    approvedTransaction.verify();
                                    stateSet = true;
                                    if(this.mounted) {
                                        this.setState({ storeStatus: 'verifying'});
                                    } else {
                                        initialStatus = 'verifying';
                                    }
                                } else if (product.duplicate) {
                                    stateSet = true;
                                    if(this.mounted) {
                                        this.setState({ storeStatus: 'duplicate_error', duplicateInfo: product.duplicateInfo });
                                    } else {
                                        initialStatus = 'duplicate_error';
                                        initialDupInfo = product.duplicateInfo;
                                    }
                                }
                            }
                        }
                    }

                    if(this.mounted) {
                        this.refreshIAPs();
                    } else {
                        products = this.getCurrentIAPs();
                    }
                    //get products and set state to new products
                })
            } else {
                //build products
                products = this.buildProducts();
            }
            
            this.state = { storeStatus: initialStatus, products: products, selectedProduct: products[0], duplicateInfo: initialDupInfo };
            this.mounted = true;
        }

        possiblePlans() {
            return _.isAndroid() ? [`${_.packagePrefix()}.annually2022`,`${_.packagePrefix()}.threemonths2022`] : [`${_.packagePrefix()}.annuallynow`,`${_.packagePrefix()}.threemonthsnow`];
        }

        buildProducts() {
            const { t, user } = this.props;
            let products = defaultProducts(t,user);
            const { 
                annualPrice,
                dAnnualPrice,
                annualMonthlyPrice,
                annualPlan,
                triMonthlyPrice,
                dTriMonthlyPrice,
                triMonthlyMonthlyPrice,
                triMonthlyPlan 
            } = getPlanTypeAndPrices(user);


            const annualMoStr = `$${annualMonthlyPrice}/${t('month abbr')}`;
            products[0].topPriceStr = dAnnualPrice ? (<SlashedPrice oldPrice={annualPrice} discountPrice={dAnnualPrice} />) : annualMoStr;
            products[0].bottomPriceStr = dAnnualPrice ? annualMoStr : t("amount USD every year", { amount: `$${annualPrice}`});
            products[0].planType = annualPlan;
            products[0].amount = annualPrice;
            products[0].dAmount = dAnnualPrice;

            const triMoStr = `$${triMonthlyMonthlyPrice}/${t('month abbr')}`
            products[1].topPriceStr = dTriMonthlyPrice ? (<SlashedPrice oldPrice={triMonthlyPrice} discountPrice={dTriMonthlyPrice} dark />) : triMoStr;
            products[1].bottomPriceStr = dTriMonthlyPrice ? triMoStr : t("amount USD every X months", { amount: `$${triMonthlyPrice}`, months: 3} );
            products[1].planType = triMonthlyPlan;
            products[1].amount = triMonthlyPrice;
            products[1].dAmount = dTriMonthlyPrice;

            if(user.isFromDiscountedAffiliate()) {
                products[0].discount = products[1].discount = 30;
                products[0].dLength = products[1].dLength = 12;
                products[0].tagStr = `${user.discountedAffiliateBrand()} exclusive`;
            }

            products.forEach( product => { 
                product.initiateCheckout = () => { this.initStripePayment(product) }
                product.getPlanParams = () => ({ planType: product.planType })
            });

            return products;
        }

        buildIAP(iap) {
            const { t, user } = this.props;
            const defaults = defaultProducts(t,user);
            const alias = trimmedAlias(iap.id);
            const store = _.getIapStore();
            let product;

            if(alias === 'annually') {
                product = defaults[0];
            } else if (alias === 'threemonths') {
                product = defaults[1];
            } else {
                return null;
            }

            let divisor;
            if(alias === 'threemonths') {
                divisor = 3;
            } else if (alias === 'biannually') {
                divisor = 6;
            } else {
                divisor = 12;
            }

            product.initiateCheckout = () => {
                if(store.get(iap.id,_.getIapPlatform()).owned) {
                    this.setState({storeStatus: 'verified'});
                } else {
                    store.logOrder = true;
                    iap.getOffer().order();
                }
            }

            const price = getIapPrice(iap);

            if(price.match(/^.\d+\.?\d*$/g)) {
                const cur = price[0];
                const num = Number(price.slice(1));
                const totalPrice = price;
                const monthlyPrice = `${cur}${Math.round((num/divisor)*100)/100.0}`;

                product.ptAmountStr = `${cur}${Math.round(num/5)*5}`;
                  
                if(_.isIOS()) {
                    product.topPriceStr = totalPrice;
                    product.bottomPriceStr = `${monthlyPrice}/${t('month abbr')}`;
                } else {
                    product.topPriceStr = `${monthlyPrice}/${t('month abbr')}`;
                    product.bottomPriceStr = t("amount every X months", { amount: `${totalPrice}`, months: divisor} );
                }
            } else {
                product.topPriceStr = price;
                product.bottomPriceStr = t("amount every X months", { amount: `${price}`, months: divisor} );
            }

            return product;
        }

        refreshIAPs() {
            this.setState({products: this.getCurrentIAPs()});
        }

        getCurrentIAPs() {
            const { t, user } = this.props;
            const store = _.getIapStore();
            let products = defaultProducts(t,user);
            for(let planType of this.possiblePlans()) {
                const planIndex = planType.includes('annually') ? 0 : 1;
                const product = store.get(planType,_.getIapPlatform());
                //sometimes store is ready before products are loaded(?) so if store doesn't return anything it's a noop
                if(product) {
                    products[planIndex] = this.buildIAP(product);
                }
            }
            return products;
        }

        initStripePayment(product) {
            if(!this.paymentModalOpen) {
                this.paymentModalOpen = true;
                this.setState({ selectedProduct: product });
                this.props.history.push(paymentModalPathFor(this.props.match.params.context));
            }
        }

        handlePaymentModalClose() {
            this.paymentModalOpen = false;
        }

        componentDidUpdate(prevProps) {
            if(prevProps.t !== this.props.t) {
                if(hasNativePurchase()) {
                    this.refreshIAPs();
                } else {
                    this.setState({ products: this.buildProducts()});
                }
            }
        }

        render() {
            const modal = (
                <StripeModal 
                    closeCallback={this.handlePaymentModalClose} 
                    product={this.state.selectedProduct} 
                    t={this.props.t} 
                    i18n={this.props.i18n}
                    submitAction={this.props.initStripeAction}
                    initProSuccess={this.props.initProSuccess}
                    user={this.props.user}
                    path={paymentModalPathFor(this.props.match.params.context)}
                />
            )

            return (
                <Component {...this.props} storeStatus={this.state.storeStatus} duplicateInfo={this.state.duplicateInfo} products={this.state.products} modal={modal} />
            )
        }

        componentWillUnmount() {
            if(hasNativePurchase()) {
                const store = _.getIapStore();
                store.purchaseApproval = null;
                store.verificationFailure = null;
                store.verificationSuccess = null;
                store.off(this.refreshIAPs);
            }
        }
    }

    return connect(mapStateToProps,mapDispatchToProps)(withRouter(withTranslation()(Store)));
}

const SubscriptionCard = ({ product, id, setSelected, isSelected }) => {
    const { t } = useTranslation();
    const classNames = classnames("buttonize subscription-cards",{"annual-card": isSelected });
    const normalizedType = normalizedPlanType(product.planType);

    return (
        <Card className={classNames} 
            onClick={() => {
                setSelected(normalizedType);
                product.initiateCheckout();
            }} 
            id={id}
        >
            {product.discount && <div className="discount-tag">{product.discount}% off first year</div>}
            {normalizedType === 'annually' && (<div className="annual-savings-flag">{product.tagStr || t('Save 60%')}</div>)}
            <CardContent className="subscription-type-text-container no-wrap">
                <div className="title">{product.name}</div>
                <div className="subtitle">
                    {!_.isBlank(product.moneyBackString) && (<div>+ {product.moneyBackString}</div>)}
                    <div>+ {t("Customizable weekly meal plan")}</div>
                    <div>+ {t("Proven workout routine")}</div>
                </div>
            </CardContent>
            <CardContent className="subscription-price-container">
                <div className="title no-wrap">{product.topPriceStr}</div>
                <div className="subtitle right">{product.bottomPriceStr}</div>
            </CardContent>
        </Card>
    )
}

const FreeSubscriptionCard = ({ onClick, id, isSelected, user }) => {
    const { t } = useTranslation();
    const classNames = classnames("buttonize subscription-cards",{"annual-card": isSelected });
    return (
        <Card className={classNames} onClick={onClick} id={id}>
            <CardContent className="subscription-type-text-container">
                <div className="title">{t("Free Plan")}</div>
            </CardContent>
            <CardContent className="subscription-price-container">
                <div className="subtitle">
                    {user.hasSofterPaywall() && (<div>{t("Limited customizability")}</div>)}
                    {!user.hasSofterPaywall() && (<div>{t("No weekly meal plan")}</div>)}
                    <div>{t("No advanced analytics")}</div>
                </div>
            </CardContent>
        </Card>
    )
}

export const DuplicateError = ({ duplicateInfo }) => {
    const { t } = useTranslation();
    const msg = t("purchase duplicate error",{brand_name: brandName(), account_info: `${duplicateInfo.email} - ${t('Sign in with type', { login_type: _.oauthProviderString(duplicateInfo.provider) })}`, email: supportEmail});
    
    return (
        <div className="text-center red-text white-bcg pa5">
            <FontAwesomeIcon icon="times" size="2x" />
            <div>{msg}</div>
            <div className="mt15">
                <Link to={switchAccountPath}>{t("Sign In")}</Link>
            </div>
        </div>
    )
}

export const SubscriptionCards = ({ 
    showTrialFlow, 
    showMoreOptions, 
    startButton,
    includeFree, 
    products, 
    storeStatus, 
    duplicateInfo, 
    setSelected, 
    selected, 
    t, ids, 
    handleFreeClick, 
    user,
    initStripeAction, 
    initProSuccess }) => {

        const selProd = _.find(products,product => normalizedPlanType(product.planType) === selected);

        if(storeStatus === 'verifying' || storeStatus === 'retrying') {
            return (
                <div className="text-center white-bcg pa5">
                    <CircularProgress />
                    <div>{storeStatus === 'verifying' ? t("Verifying purchase...") : t("purchase retrying message")}</div>
                </div>
            )
        } else if (storeStatus === 'verified') {
            return (
                <div className="text-center success-color white-bcg pa5">
                    <FontAwesomeIcon icon="check-circle" size="2x" />
                    <div>{t("Verified.")}</div>
                </div>
            )
        } else if(storeStatus === 'duplicate_error') {
            return (<DuplicateError duplicateInfo={duplicateInfo} />);
        } else if(storeStatus === 'fail_error') {
            const msg = t("purchase fail error",{email: supportEmail});
            return (
                <div className="text-center red-text white-bcg pa5">
                    <FontAwesomeIcon icon="times" size="2x" />
                    <div>{msg}</div>
                </div>
            )
        } else if(showTrialFlow) {
            return (
                <div className="free-trial-flow-wrap">
                    <div className="typography-conversion title-text mt15 text-center">
                        {t("How your free trial works")}
                    </div>
                    <div className="free-trial-flow">
                        <div className="sidebar"></div>
                        <div className="text-block">
                            <FontAwesomeIcon icon="lock" className="step-icon"></FontAwesomeIcon>
                            <div className="typography-conversion header-text mt5 mb5">
                                {t("Today")}
                            </div>
                            <div className="ml5">{t("Start your full access to the customizable weekly meal plan, simple grocery list, and more")}.</div>
                        </div>
                        <div className="text-block">
                            <FontAwesomeIcon icon="bell" className="step-icon"></FontAwesomeIcon>
                            <div className="typography-conversion header-text mt5 mb5">
                                {t("Day X", { day: '5' })}
                            </div>
                            <div className="ml5">{t("We'll send you a reminder that your free trial is about to end")}.</div>
                        </div>
                        <div className="text-block pb10">
                            <FontAwesomeIcon icon="trophy" className="step-icon trophy"></FontAwesomeIcon>
                            <div className="typography-conversion header-text mt5 mb5">
                                {t("Day X", { day: '7' })}
                            </div>
                            <div className="ml5">{t("You'll be charged today, cancel anytime before")}.</div>
                        </div>
                    </div>
                    <div className="text-center mb10 mt20">
                        <div>{t("7-day free trial")}, then</div>
                        <div><b>{selProd.topPriceStr}</b> ({selProd.bottomPriceStr})</div>
                    </div>
                    {startButton}
                    <Button block color="grey" onClick={showMoreOptions} rounded variant="flat" className="btn mt5 btn-white">
                        <FontAwesomeIcon icon={'list'} style={{ opacity: '0' }}></FontAwesomeIcon><span className="flex-grow">{t('View all plans')}</span>
                    </Button>
                </div>
            )

        } else {
            return (
                <React.Fragment>
                    {products.map(product => {
                        const props = {
                            key: product.planType,
                            product,
                            id: ids ? `${normalizedPlanType(product.planType)}-btn` : null,
                            isSelected: normalizedPlanType(product.planType) === selected, 
                            setSelected
                        }

                        return <SubscriptionCard {...props} />
                    })}
                    {includeFree && (
                        <FreeSubscriptionCard 
                            key="free" 
                            onClick={() => { setSelected(null); handleFreeClick(); }} 
                            id={ids ? `free-plan-btn` : null} 
                            isSelected={selected === null} 
                            user={user}
                        />
                    )}
                </React.Fragment>
            )
        }

}

const featureList = (t) => {
    return [
        { label: t("Workouts"), basic: true, pro: true },
        { label: t("1-Day Meal Plan"), basic: true, pro: true },
        { label: t("Customizable Weekly Meal Plan"), basic: false, pro: true },
        { label: t("Grocery List"), basic: false, pro: true },
        { label: t("Customize Workouts"), basic: false, pro: true },
        { label: t("Barcode Scanning"), basic: false, pro: true },
        { label: t("Macro Analytics"), basic: false, pro: true },
        { label: t("Add Custom Recipes"), basic: false, pro: true }
    ]
}

const softFeatureList = (t) => {
    return [
        { label: t("Workouts"), basic: true, pro: true },
        { label: t("Weekly Meal Plan"), basic: true, pro: true },
        { label: t("Grocery List"), basic: true, pro: true },
        { label: t("Fully Customizable Meal Plan"), basic: false, pro: true },
        { label: t("Customize Workouts"), basic: false, pro: true },
        { label: t("Barcode Scanning"), basic: false, pro: true },
        { label: t("Macro Analytics"), basic: false, pro: true },
        { label: t("Add Custom Recipes"), basic: false, pro: true }
    ]
}

const userReviews = (t) => {
    return [
        { reviewer: 'Ian M.', title: t("Excellent"), text: t("user review 1")},
        { reviewer: 'Kate S.', title: t("Beautiful App"), text: t("user review 2")},
        { reviewer: 'Chris S.', title: t("Love the Meal Planning Aspect"), text: t("user review 3")},
        { reviewer: 'David E.', title: t("Finally Something Simple"), text: t("user review 4")},
    ]
}

const FeatureIcon = () => (
    <FontAwesomeIcon icon="check" className="success-color" size="1x" />
)

const LockedFeatureIcon = () => (
    <FontAwesomeIcon icon="lock" size="1x" />
)

const AsSeenOnLogo = ( { logo } ) => {
    return (
        <img src={logo} style={{ maxWidth: '150px', display: 'inline-block', padding: '20px'}} alt="" />
    )
}

const AsSeenOnSection = ({ t }) => {

    return (
        <div className="secondary-background text-center mb25">
            <div className="faint-color">{t("As seen on")}</div>
            {[forbes,nbc,readersDigest,shape,today,womensHealth,yahooLifestyle].map((logo,index) => (<AsSeenOnLogo key={index} logo={logo} />))}
        </div>
    )
}

const FeatureCarouselSection = ({ t, user, context }) => {
    let firstSlide = _.isPrimaryDomain() ? slide0 : slide0Generic;
    let slides = [firstSlide,slide1,slide2,slide3,slide4,slide5,slide6,slide7,slide8];
    if(_.excludeProgressPhoto() || context === 'onboard') {
        slides = [slide1,slide2,slide3,slide4,slide5,slide6,slide7,slide8];
    }
    const carouselItems = slides.map((src,index) => ({ id: index+1, src }));

    return (
        <div className="mb25 low-indicators">
            <div className="center-align typography-conversion title-text">
                {t("Get More When You Go Pro")}
            </div>
            <Carousel
                items={carouselItems} 
                initialActive={1} 
                options={{ fullWidth: true, indicators: true }} 
            />
        </div>
    )
}

class ConversionPage extends Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
        this.redirectOnly = props.user.isPro();
        this.handleFreeClick = this.handleFreeClick.bind(this);
        this.isPaywall = (props.location.pathname.includes('/paywall'));

        this.state = { selected: 'annually', showTrialFlow: false }

    }

    redirectPath() {
        const { paywallRedirect, match: { params: { context }}} = this.props;
        if (context === 'workout_plan') {
            return workoutPlanPath;
        } else if(context === 'meal_plan') {
            return mealPlanPath;
        } else if (context === 'home' || context === 'scan_food') {
            return homePath();
        } else if(!_.isBlank(paywallRedirect)) {
            return paywallRedirect;
        } else {
            return homePath();
        }
    }

    handleFreeClick() {
        const { history } = this.props;
        history.push(this.redirectPath());
    }

    includeFree() {
        return !this.isPaywall;
    }

    showMoreOptions = () => this.setState({showTrialFlow: false})

    getSelectedProduct = () => {
        const { selected } = this.state;
        const { products } = this.props;
        if(selected) {
            return _.find(products,product => (product.planType && normalizedPlanType(product.planType) === selected))
        }
        return null;
    }

    setSelected = (selected) => this.setState({ selected })

    render() {
        const { t, products, storeStatus, duplicateInfo, user, scrollRef, match: { params: { context }}, modal, initStripeAction, initProSuccess } = this.props;
        const { selected, showTrialFlow } = this.state;
        const showPickPlan = !showTrialFlow;
        const isPro = user.isPro();
        const resolvedFeatureList = user.hasSofterPaywall() ? softFeatureList(t) : featureList(t);

        if(this.redirectOnly) {
            return (
                <div><Redirect to={this.redirectPath()} /></div>
            )
        } else {
            const subhead = (user.isFromDiscountedAffiliate() && !window.isCordova) ? t("Claim your exclusive discount") : t('Pick a plan')
            const startButton = (
                <StartButton 
                    storeStatus={storeStatus}
                    product={this.getSelectedProduct()} 
                    handleFreeClick={this.handleFreeClick}
                    initStripeAction={initStripeAction}
                    initProSuccess={initProSuccess}
                    rounded={!showTrialFlow}
                    noShadow={showTrialFlow}
                    block={showTrialFlow}
                />
            )

            return (
                <Page white ref={scrollRef}>
                    <FlowNav useEx />
                    <div className="garnish-container" style={{zIndex: 1}}>
                        <img src={spinach1} className="spinach-1 conversion extra-small-screen-disappear" alt="" />
                        <img src={spinach3} className="spinach-3 conversion extra-small-screen-disappear" alt="" />
                        <img src={spinach2} className="spinach-2 conversion extra-small-screen-disappear" alt="" />
                    </div>
                    <div style={{position: 'fixed', top: '300px'}}>
                        <img src={barbell} className="barbell conversion extra-small-screen-disappear wide-width-disappear" alt="" />
                    </div>
                    <div style={{zIndex: 2, position: 'relative'}} >
                        <div className="row mb0 mt-inset-50">
                            <div className="col s12 m6 l4 offset-l2">
                                <h4 className="conversion-heading ml0">
                                    {(!this.isPaywall || user.hadTrial) && (
                                        <span>
                                            {user.hadTrial ? t('Upgrade to Pro') : t('Start Your Free Trial')}<br/>
                                            {showPickPlan && (<small className="faint-color">{subhead}</small>)}
                                        </span>
                                    )}
                                    {this.isPaywall && !user.hadTrial && (
                                    <span>
                                        <FontAwesomeIcon icon="lock" /> {context === 'upgrade' ? t('Start Your Free Trial') : t("Free Trial Required")}<br/>
                                        {showPickPlan && (<small className="faint-color">{subhead}</small>)}
                                    </span>)}
                                </h4>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col s12 m6 l4 offset-l2">
                                <SubscriptionCards 
                                    includeFree={this.includeFree()}
                                    storeStatus={storeStatus} 
                                    duplicateInfo={duplicateInfo}
                                    products={products} 
                                    selected={selected}
                                    setSelected={this.setSelected} 
                                    handleFreeClick={this.handleFreeClick}
                                    initStripeAction={initStripeAction}
                                    initProSuccess={initProSuccess}
                                    showMoreOptions={this.showMoreOptions}
                                    showTrialFlow={showTrialFlow}
                                    startButton={startButton}
                                    user={user}
                                    ids
                                    t={t}
                                />
                                <div className="mt20">
                                    <div className="secondary-background mb20">
                                        <div className="thin underline typography-conversion left-align">
                                            {t("Did you know?")}
                                        </div>
                                        <div className="center-align typography-conversion title-text">
                                            {t("A good personal trainer costs", { price: '$100'})}
                                        </div>
                                        <div className="thin center-align typography-conversion subtitle-paragraph">
                                            <Trans i18nKey="personal trainer comparison string">
                                                With Pro, you can get a <i>full year</i> of personalized training &amp; nutrition planning for less than single hour of personal training
                                            </Trans>
                                        </div>
                                    </div>
                                    <FeatureCarouselSection t={t} user={user} context={context} />
                                    <div className="center-align typography-conversion title-text mt25 mb5">
                                        {t("All the Tools You Need")}
                                    </div>
                                    <table className="striped feature-comparison mb25">
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("Basic")}</th>
                                                <th>{t("Pro")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {resolvedFeatureList.map(feature => (
                                                <tr key={feature.label}>
                                                    <td key='label'>{feature.label}</td>
                                                    <td key='basic'>{feature.basic ? (<FeatureIcon />) : (<LockedFeatureIcon />)}</td>
                                                    <td key='pro'>{feature.pro ? (<FeatureIcon />) : (<LockedFeatureIcon />)}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="col s12 m6 l4">
                                <div className="secondary-background mb25">
                                    <div className="center-align typography-conversion title-text">
                                        {t("Be Part of Our 100k+ Transformations")}
                                    </div>
                                    {userReviews(t).map((review,index) => (<UserReview key={index} review={review}/>))}
                                </div>
                                <AsSeenOnSection t={t} />
                                <SubscriptionCards 
                                    includeFree={this.includeFree()}
                                    storeStatus={storeStatus} 
                                    duplicateInfo={duplicateInfo}
                                    products={products} 
                                    handleFreeClick={this.handleFreeClick}
                                    initStripeAction={initStripeAction}
                                    selected={selected}
                                    setSelected={this.setSelected} 
                                    initProSuccess={initProSuccess}
                                    showMoreOptions={this.showMoreOptions}
                                    showTrialFlow={showTrialFlow}
                                    startButton={startButton}
                                    user={user}
                                    t={t}
                                />
                                {(_.isIOS() || _.isAndroid()) && (<AppleSubscriptionTerms />)}
                            </div>
                        </div>
                    </div>
                    {isPro && (<Redirect to={this.redirectPath()} />)}
                    {modal}
                    {!showTrialFlow && (
                        <BottomButtons>
                            {startButton}
                        </BottomButtons>
                    )}
                </Page>
            );
        }
    }
}

export default withStore(ConversionPage);
