import React, {PureComponent} from 'react';
import Page from 'components/Page';
import CenteredContainer from 'components/CenteredContainer';
import LoginForm from 'components/LoginForm';
import * as transitions from 'assets/transitions';
import { FlowNav } from 'components/FlowForm';
import { accountPath, ambiguousMainMatches, canHitEmailOrPaywallMatches, conversionMatch, emailSignupMatch, paywallMatch, switchAccountMatch, trainerBasicProfileMatch, trainerConversionMatch, trainerSignupMatch } from 'config/paths';
import { userRecordSelector } from 'redux/selectors';
import { matchPath } from 'react-router-dom';
import { connect } from 'react-redux';

const transitionMap = {
    rules: [
        [/\/start/i,transitions.slideOver],
        [[conversionMatch,trainerConversionMatch],transitions.slideOver],
        [/\/$/,transitions.slideOut],
        [[...ambiguousMainMatches,emailSignupMatch,accountPath],transitions.slideOut]
    ]
};

const switcherTransitionMap = {
    rules: [
        [[conversionMatch,paywallMatch,trainerConversionMatch],transitions.slideOver],
        [[...canHitEmailOrPaywallMatches,trainerSignupMatch,trainerBasicProfileMatch,emailSignupMatch],transitions.slideOut]
    ]
};


class SignIn extends PureComponent {

    constructor(props) {
        super(props)
        const { location: { pathname } } = this.props;
        const isSwitcher = matchPath(pathname,{ path: switchAccountMatch });
        const tmap = isSwitcher ? switcherTransitionMap : transitionMap;
        props.setupTransitions(tmap)
    }

    render() {
        const { scrollRef, user, location: { pathname }, match: { params: { redirectTo }} } = this.props;
        const isSwitcher = !!matchPath(pathname,{ path: switchAccountMatch });

        return (
        <Page ref={scrollRef} white>
            <FlowNav />
            <CenteredContainer>
                <LoginForm user={user} redirectTo={redirectTo} isSwitcher={isSwitcher}></LoginForm>
            </CenteredContainer>
        </Page>
        );
    }
}

const mapStateToProps = state => ({
    user: userRecordSelector(state)
})

export default connect(mapStateToProps)(SignIn);
