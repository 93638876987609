import React from 'react';
import { connect } from 'react-redux';
import { trainerMainMatches, clientDetailsPath, clientDetailsMatch, clientMealPlanSettingsPath, clientMealPlanSettingsMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import withTrainer from 'partials/TrainerLoader';
import { loadClient, sendInvite, updateClientProfiles } from 'redux/actions';
import { LoaderOnlySubmitButton } from 'components/Form';
import { BasicProfileForm } from './Settings';
import { Loader } from 'components/LoadingHOC';
import { useHistory } from 'react-router-dom';
import { BottomButtons } from 'components/Button';
import { syncClientSetupValues } from 'partials/EditTrainerModal';
import { HideOnExit } from 'components/RouteTransitionMap';

const setupTransitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [[clientDetailsMatch,clientMealPlanSettingsMatch], transitions.slideOver]
    ]
};

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [[clientDetailsMatch], transitions.slideOut]
    ]
};

const goesToClientPath = (setupOwnProfile,isNew) => (isNew && !setupOwnProfile);
const shouldSendInvite = (setupOwnProfile,isNew,client) => (isNew && setupOwnProfile && client.inviteNeeded());
const saveBtnIconLabel = (setupOwnProfile,isNew,client,t) => {
    if(goesToClientPath(setupOwnProfile,isNew)) {
        return ['arrow-right',t('Next')];
    }

    return shouldSendInvite(setupOwnProfile,isNew,client) ? ['paper-plane',t('Send Invitation Email')] : ['check',t('Save')];
}

const ClientBasicProfileCore = ({ client, updateClient, isNew, sendInvite, trainer }) => {
    const { t } = useTranslation();
    const history = useHistory();

    //client can be nulled when going back to dashboard, don't want to crash
    if(!client) {
        return '';
    }

    const submitRender = ({ values: { setupOwnProfile }, errors, submitState }) => {
        const [icon,label] = saveBtnIconLabel(setupOwnProfile,isNew,client,t);
        return (
            <BottomButtons>
                <LoaderOnlySubmitButton 
                    id="submit-profile-btn"
                    icon={icon} 
                    label={label} 
                    className="btn-wide" 
                    loadState={submitState} 
                    disabled={!_.isEmpty(errors)} 
                />
            </BottomButtons>
        )
    }


    return (
        <div className="row limit-50-h">
            <div className="col s12 m12 l10 offset-l1">
                <BasicProfileForm 
                    isClientSetup={true}
                    user={client} 
                    submitRender={submitRender} 
                    sendInvite={sendInvite}
                    submit={(values) => {
                        const clientIds = [client.id];
                        values = syncClientSetupValues(values);
                        if(values.setupOwnProfile) {
                            return updateClient({ ..._.pick(values,'setupOwnProfile','setupOwnMpPrefs','pickOwnRoutine','canPickInitialRecipes','autoDeliverMpPdf'), clientIds });
                        }
                        const newVals = { ...values, clientIds };
                        if(!Object.keys(values).includes('eodRemind')) {
                            newVals.hasBasicProfile = true;
                        }
                        return updateClient(newVals);
                    }}
                    successCallback={({ values: { setupOwnProfile }}) => {
                        if(goesToClientPath(setupOwnProfile,isNew)) {
                            if(trainer.showNutritionOnly()) {
                                history.replace(clientMealPlanSettingsPath(client.id,'setup'))
                            } else {
                                history.replace(clientDetailsPath(client.id));
                            }
                        } else {
                            if(shouldSendInvite(setupOwnProfile,isNew,client)) {
                                sendInvite(client.id);
                            }
                            history.goBack();
                        }
                    }}
                />
            </div>
        </div>
    )
}

class ClientBasicProfile extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, trainer, loadClient, updateClient, sendInvite, match: { params: { id, context }} } = this.props;
        const isNew = context === 'setup';
        const client = trainer.clientById(id);

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={isNew ? setupTransitionMap : transitionMap} 
                setupTransitions={setupTransitions} 
                title={isNew ? t('Set Up Client') : t('Client Profile')}
            >
                <HideOnExit>
                    <Loader 
                        successComponent={ClientBasicProfileCore}
                        load={loadClient.bind(null,id)}
                        preloaded={() => false}
                        type="page"
                        client={client}
                        isNew={isNew}
                        updateClient={updateClient}
                        sendInvite={sendInvite}
                        trainer={trainer}
                    />
                </HideOnExit>
            </SimpleNavPage>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    loadClient: id => dispatch(loadClient(id)),
    updateClient: data => dispatch(updateClientProfiles(data)),
    sendInvite: id => dispatch(sendInvite({ id }))
})

export default connect(null,mapDispatchToProps)(withTranslation()(withTrainer(false)(ClientBasicProfile)))