import ActivityContainer from 'components/ActivityContainer';
import Card, { CardImageWithPlaceholder } from 'components/Card';
import React from 'react';
import mealPlanSignup from 'assets/img/MealPlanSignup.png';
import workoutSignupM from 'assets/img/WorkoutSignupM.png';
import workoutSignupF from 'assets/img/WorkoutSignupF.png';
import { useTranslation } from 'react-i18next';
import { SlightEmphasisIconNote } from 'components/Typography';
import { userRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { NUTRITION, TRAINING } from 'config/settings';
import moment from 'moment';
import * as _ from 'lib/utilities';
import { ClientFormsTodoSection } from './SetupCards';

let PlanNotReadyCard = ({ user, type }) => {
    const { t } = useTranslation();
    type = type || user.trainerPlanType();
    let img;
    let title ;
    if(type === NUTRITION) {
        img = mealPlanSignup;
        title = "no nplan";
    } else if(type === TRAINING) {
        img = user.female() ? workoutSignupF : workoutSignupM;
        title = "no tplan";
    } else {
        img = mealPlanSignup;
        title = "no plan";
    }

    return (
        <React.Fragment>
            <h4 className="conversion-heading ml0">{t(title)}</h4>
            <Card id="plan-not-ready-card">
                <CardImageWithPlaceholder src={img} noContent width={500} height={334} />
                <div className="image-card-note">
                    <SlightEmphasisIconNote text={t("plan not ready tip")} />
                </div>
            </Card>
        </React.Fragment>
    )
}

const mapStateToProps = state => ({
    user: userRecordSelector(state)
})

PlanNotReadyCard = connect(mapStateToProps)(PlanNotReadyCard);

let PlanNotReady = ({ user, type }) => {
    const forms = (user && user.getClientDashFormsOn(moment())) || [];
    const unsubmittedForms = _.filter(forms,f => f.isNotSubmitted());

    return (
        <ActivityContainer>
            <div className="col s12 m10 offset-m1 l6 offset-l3">
                <div className="mt75">
                    {unsubmittedForms.length === 0 && (<PlanNotReadyCard type={type} />)}
                    {unsubmittedForms.length > 0 && (<ClientFormsTodoSection forms={forms} />)}
                </div>
            </div>
        </ActivityContainer>
    )
}



export { PlanNotReady, PlanNotReadyCard }