import * as _ from 'lib/utilities';
import NoImage from 'assets/img/NoImageExercise.png';

export const videoImgConcern = {

    youtubeId() {
        if(_.isBlank(this.videoUrl)) {
            return null;
        } else {
            if(this.videoUrl.match(/\/embed\//)) {
                const attempt = this.videoUrl.split('embed/')[1];
                return attempt.split('?')[0];
            } else if(this.videoUrl.match(/\/shorts\//)) {
                const attempt = this.videoUrl.split('shorts/')[1];
                return attempt.split('?')[0];
            } else {
                let attempt = this.videoUrl.split('v=')[1];
                attempt = attempt || this.videoUrl.split('be/')[1];
                if(attempt) {
                    return attempt.split('#')[0].split('&')[0].split('?')[0];
                }
                return null;
            }
        }
    },

    youtubeVideoStart() {
        if(_.isBlank(this.videoUrl)) {
            return 0;
        }

        const attempt = this.videoUrl.split('t=')[1];
        if(attempt) {
            const num = attempt.split('#')[0].split('&')[0];
            if(_.isNumeric(num)) {
                return Number(num);
            }
        }
        return 0;
    },

    hasYouTubeVideo() {
        return !_.isBlank(this.youtubeId());
    },

    hasImage() {
        return (this.image && this.image.url && !this.image.url.includes('NoImage')) || this.hasMuxVideo();
    },

    hasMuxVideo() {
        return !_.isBlank(this.muxPlaybackId);
    },

    muxVideoReady() {
        return this.hasMuxVideo() && this.muxPlaybackId !== 'uploading' && this.muxPlaybackId !== 'failed' && this.muxPlaybackId !== 'processing';
    },

    hasMp4() {
        return (this.image && this.image.url && this.image.url.includes('.mp4'));
    },

    hasFullVideo() {
        return !_.isBlank(this.youtubeId()) && !this.hasMuxVideo();
    },

    muxVideoUrl() {
        return `https://stream.mux.com/${this.muxPlaybackId}.m3u8`
    },

    muxPosterUrl() {
        return `https://image.mux.com/${this.muxPlaybackId}/thumbnail.webp`;
    },

    resolvedThumbUrl() {
        if(this.hasMuxVideo() && this.muxVideoReady()) {
            return `https://image.mux.com/${this.muxPlaybackId}/thumbnail.webp?width=540&height=300&fit_mode=pad`
        } else if(this.image && this.image.thumb && this.image.thumb.url && !this.image.thumb.url.includes('NoImageExercise')) {
            return this.image.thumb.url;
        } else if(this.hasFullVideo()) {
            return `https://img.youtube.com/vi/${this.youtubeId()}/mqdefault.jpg`;
        } else {
            return NoImage;
        }
    },

    videoUploadableBy(user) {
        return (user.id === this.ownerId || user.id === this.userId);
    }

}