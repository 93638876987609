import { FullFormModal } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { DefaultTextArea, FullInput, MultivalueCheckbox, ToggleButton } from 'components/Form';
import TextInput from 'components/TextInput';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { checkExportLimits, downloadPlanPdf, downloadRoutinePdf, jumpToBrowser, sendPlanPdfEmail, sendRoutinePdfEmail, tSubscribeImmediately } from 'redux/actions';
import { JumpLink } from './Utilities';
import { trainerRecordSelector } from 'redux/selectors';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateYupSchema, yupToFormErrors } from 'formik';
import { previewMpPdfUrlFor, previewRoutinePdfUrlFor } from 'lib/api';
import { Loader } from 'components/LoadingHOC';
import { SlightEmphasisIconNote } from 'components/Typography';

const checkboxes = {
    includeMealPlan: 'Include meal plan summary',
    includeRecipes: 'Include full recipes',
    includeGlist: 'Include grocery list',
    includeHabits: 'Include habits',
    includePics: 'Include recipe pictures',
    includeLinks: 'Include links to scalable recipes on strongrfastr.com'
}

const placeholderFromVals = (vals,pdfType) => {
    if(pdfType === 'workout') {
        return 'Your personalized workout plan is ready';
    }

    const { includeMealPlan, includeRecipes, includeGlist, includeHabits } = vals;
    const strs = [];
    if(includeMealPlan) strs.push('meal plan');
    if(strs.length === 0 && includeRecipes) strs.push('recipes');
    if(includeGlist) strs.push('grocery list');
    if(includeHabits) strs.push('habits');
    return strs.length <= 2 ? `Your ${_.listToSentence(strs)} for the week` : `Your ${_.listToSentence(strs)}`;
}

const validateCreator = (t,type) => values => {
    let errs = {};
    const { includeMealPlan, includeRecipes, includeGlist, includeHabits, delivery } = values;

    if(type !== 'workout' && !includeGlist && !includeHabits && !includeRecipes && !includeMealPlan) {
        errs['includeHabits'] = t('must include at least one section');
    }

    if(delivery === 'email') {
        let schema = Yup.object().shape({
            email: Yup.string().email().required()
        });
        try {
            validateYupSchema(values, schema,true);
        } catch(validationError) {
            errs = { ...errs, ...yupToFormErrors(validationError) };
        }
    }

    return errs;
}

const ExportPdfForm = ({ modalProps, t, pdfType, id, ...rest }) => {

    const { values, setFieldValue } = rest;
    const includeErr = rest.errors && rest.errors.includeHabits;
    const isEmail = (values.delivery === 'email');

    return (
        <div className="pa10" id={id ? id : ''} style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
            {!window.isCordova && (<div className="text-center mb20">
                <ToggleButton 
                    id="toggle-delivery-btn"
                    className="btn-no-shadow" 
                    options={{[t('Email')]: 'email', [t('Download')]: 'download'}} 
                    value={values.delivery} 
                    setValue={delivery => setFieldValue('delivery',delivery)} 
                />
            </div>)}
            {isEmail && (
                <React.Fragment>
                    <div className="valign-wrapper">
                        <FullInput
                            className="flex-grow with-placeholder mb0"
                            name="subject"
                            label={t('Subject')}
                            component={TextInput}
                            formikProps={rest}
                            onlyErrorText
                            inProps={{
                                placeholder: placeholderFromVals(values,pdfType)
                            }}
                        />
                        <FullInput
                            className="ml5 mb0"
                            name="email"
                            label={t('Recipient email')}
                            component={TextInput}
                            onlyErrorText
                            formikProps={rest}
                        />
                    </div>
                </React.Fragment>
            )}
            <DefaultTextArea 
                className="mt10 mb10"
                name="body"
                label={isEmail ? t('Message') : t('Note')}
                inProps={{
                    maxLength: 2500,
                    placeholder: (isEmail ? 'Your plan is attached.' : 'Any notes you want to include at the top of the pdf.'),
                    id: "body-field"
                }}
                {...rest}
            />
            {pdfType !== 'workout' && Object.entries(checkboxes).map(([attr,label]) => {

                return (
                    <div key={attr}>
                        <MultivalueCheckbox 
                            id={`${attr}-check`}
                            name={attr}
                            checkedVal={true}
                            uncheckedVal={false}
                            label={t(label)} 
                            {...rest}
                        />
                        {attr === 'includeHabits' && includeErr && (
                            <div className="red-text">
                                <FontAwesomeIcon icon="times" /> {includeErr}
                            </div>
                        )}
                    </div>
                )
            })}
            
            {pdfType !== 'workout' && (<div className="text-center mb10 mt10">
                        <MultivalueCheckbox 
                            name={'saveToDefaults'}
                            checkedVal={true}
                            uncheckedVal={false}
                            label={t("Remember these settings")} 
                            id="save-to-defaults-check"
                            {...rest}
                        />
            </div>)}
            {(!window.isCordova || window.cordova.InAppBrowser) && (<div className="text-center mt10 mb10">
                <JumpLink to={pdfType === 'workout' ? previewRoutinePdfUrlFor(values) : previewMpPdfUrlFor(values)} className="underline font-grey clickable">
                    {t('Preview or print')}
                </JumpLink>
            </div>)}
        </div>
    )
}

class MscMsg extends React.Component {

    constructor(props) {
        super(props);
        const { setFormType } = props;
        setFormType('msc');
    }

    render() {
        const { t } = this.props;

        return (
            <div className="pa10">
                <SlightEmphasisIconNote text={t('msc export msg')} />
            </div>
        )
    }
}

class FreeTrialTotalMsg extends React.Component {

    constructor(props) {
        super(props);
        const { setFormType } = props;
        setFormType('total');
    }

    render() {
        const { t } = this.props;

        return (
            <div className="pa10">
                <SlightEmphasisIconNote text={t("free trial pdf limit msg")} />
            </div>
        )
    }
}

const ExportPdfModalMiddle = ({ responseData: { limit }={}, setFormType, ...rest }) => {
    const { t } = useTranslation();

    if(limit === 'msc') {
        return (
            <MscMsg setFormType={setFormType} t={t} />
        )
    } else if(limit === 'total') {
        return (
            <FreeTrialTotalMsg setFormType={setFormType} t={t} />
        )
    }

    return (
        <ExportPdfForm {...rest} t={t} />
    )
}

const ExportPdfModalLoader = ({ checkExportLimits, subscribeImmediately, setFormType, ...rest }) => {
    const { values: { clientId } } = rest;

    return (
        <Loader
            successComponent={ExportPdfModalMiddle}
            load={() => checkExportLimits({ clientId })}
            preloaded={() => true}
            alwaysLoad
            type="padded"
            setFormType={setFormType}
            {...rest}
        />
    )
}

const ExportPDFModalCore = ({ checkExportLimits, subscribeImmediately, path, trainer, emailSubmit, downloadSubmit, initialVals }) => {
    const { t } = useTranslation();
    const [formType,setFormType] = React.useState(null);

    return (
        <FullFormModal
            fullWidth
            path={path} 
            scrollable 
            exact 
            icon={['far','file-export']}
            title={t('Export PDF')}
            formProps={(props) => {

                return {
                    initialValues: initialVals(props),
                    submit: values => {
                        if(formType === 'total') {
                            if(trainer.isTrainerAdmin()) {
                                return subscribeImmediately();
                            } else {
                                return Promise.resolve({ status: 'SUCCESS' });
                            }
                        } else if(formType === 'msc') {
                            return Promise.resolve({ status: 'SUCCESS' });
                        } else if(values.delivery === 'email') {
                            return emailSubmit(values);
                        } else {
                            if(window.isCordova) {
                                return Promise.reject({ status: 'SERVERERR' });
                            } else {
                                return downloadSubmit(values); 
                            }
                        }
                    },
                    validate: (formType === 'total' ? values => ({}) : validateCreator(t))
                }
            }}
            footerProps={({ formikProps: { values: { delivery } }}) => {
                let saveLabel, saveIcon;
                if(formType === 'total') {
                    if(trainer.isTrainerAdmin()) {
                        saveLabel = t('Subscribe Now');
                        saveIcon = 'credit-card';
                    } else {
                        saveLabel = t('Ok');
                        saveIcon = 'check';
                    }
                } else if(formType === 'msc') {
                    saveLabel = t('Ok');
                    saveIcon = 'check';
                } else if(delivery === 'email') {
                    saveLabel = t('Send');
                    saveIcon = ['far','paper-plane'];
                } else {
                    saveLabel = t('Download');
                    saveIcon = ['far','download'];
                }
                return { saveIcon, saveLabel, id: 'get-pdf-btn' }
            }}
            render={({ modalProps, ...rest }) => {        
                
                return (
                    <ExportPdfModalLoader 
                        modalProps={modalProps}
                        checkExportLimits={checkExportLimits}
                        subscribeImmediately={subscribeImmediately}
                        setFormType={setFormType}
                        {...rest}
                    />
                )
                
            }}
        />
    )
}

export const exportClientPDFSuffix = '/export_pdf/:clientId/:week'

let ExportClientPDFModal = ({ trainer, emailSubmit, downloadSubmit, checkExportLimits, subscribeImmediately }) => {
    const { pathname } = useLocation();
    const path = modalPathFor(exportClientPDFSuffix,pathname);
    

    return (
        <ExportPDFModalCore 
            path={path}
            trainer={trainer}
            emailSubmit={emailSubmit}
            downloadSubmit={downloadSubmit}
            jumpToBrowser={jumpToBrowser}
            initialVals={({ modalProps: { match: { params: { clientId, week } } } }) => {
                return trainer.pdfExportInitialVals(week,clientId);
            }}
            checkExportLimits={checkExportLimits}
            subscribeImmediately={subscribeImmediately}
        />
    )
}

export const exportCurrentPDFSuffix = '/export_pdf'

let ExportCurrentPDFModal = ({ trainer, emailSubmit, downloadSubmit, client, week, checkExportLimits, subscribeImmediately }) => {
    const { pathname } = useLocation();
    const path = modalPathFor(exportCurrentPDFSuffix,pathname);

    return (
        <ExportPDFModalCore 
            path={path}
            trainer={trainer}
            emailSubmit={emailSubmit}
            downloadSubmit={downloadSubmit}
            jumpToBrowser={jumpToBrowser}
            initialVals={() => trainer.pdfExportInitialVals(week,client)}
            checkExportLimits={checkExportLimits}
            subscribeImmediately={subscribeImmediately}
        />
    )
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    emailSubmit: data => dispatch(sendPlanPdfEmail(data)),
    downloadSubmit: data => dispatch(downloadPlanPdf(data)),
    checkExportLimits: data => dispatch(checkExportLimits(data)),
    subscribeImmediately: () => dispatch(tSubscribeImmediately())
})

ExportCurrentPDFModal = connect(mapStateToProps,mapDispatchToProps)(ExportCurrentPDFModal)

export { ExportCurrentPDFModal }



const ExportRoutinePDFModalCore = ({ path, emailSubmit, downloadSubmit, initialVals }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth
            path={path} 
            scrollable 
            exact 
            icon={['far','file-export']}
            title={t('Export PDF')}
            formProps={(props) => {

                return {
                    initialValues: initialVals(props),
                    submit: values => {
                        if(values.delivery === 'email') {
                            return emailSubmit(values);
                        } else {
                            if(window.isCordova) {
                                return Promise.reject({ status: 'SERVERERR' });
                            } else {
                                return downloadSubmit(values); 
                            }
                        }
                    },
                    validate: validateCreator(t,'workout')
                }
            }}
            footerProps={({ formikProps: { values: { delivery } }}) => {
                let saveLabel, saveIcon;
                if(delivery === 'email') {
                    saveLabel = t('Send');
                    saveIcon = ['far','paper-plane'];
                } else {
                    saveLabel = t('Download');
                    saveIcon = ['far','download'];
                }
                return { saveIcon, saveLabel, id: 'get-pdf-btn' }
            }}
            render={({ modalProps, ...rest }) => {        
                
                return (
                    <ExportPdfForm
                        modalProps={modalProps}
                        pdfType="workout"
                        t={t}
                        id={`export-routine-${rest.values.id}-modal`}
                        {...rest}
                    />
                )
                
            }}
        />
    )
}

export const exportRoutinePDFSuffix = '/export_routine_pdf/:routineId'

let ExportRoutinePDFModal = ({ trainer, user, emailSubmit, downloadSubmit }) => {
    const { pathname } = useLocation();
    const path = modalPathFor(exportRoutinePDFSuffix,pathname);
    

    return (
        <ExportRoutinePDFModalCore 
            path={path}
            trainer={trainer}
            emailSubmit={emailSubmit}
            downloadSubmit={downloadSubmit}
            jumpToBrowser={jumpToBrowser}
            initialVals={({ modalProps: { match: { params: { routineId } } } }) => {
                return trainer.routinePdfExportInitialVals(Number(routineId),_.signedInAsClient(trainer,user) ? user : null);
            }}
        />
    )
}

export const exportClientRoutinePDFSuffix = '/export_routine_pdf/:clientId'

let ExportClientRoutinePDFModal = ({ trainer, emailSubmit, downloadSubmit }) => {
    const { pathname } = useLocation();
    const path = modalPathFor(exportClientRoutinePDFSuffix,pathname);

    return (
        <ExportRoutinePDFModalCore 
            path={path}
            trainer={trainer}
            emailSubmit={emailSubmit}
            downloadSubmit={downloadSubmit}
            jumpToBrowser={jumpToBrowser}
            initialVals={({ modalProps: { match: { params: { clientId } } } }) => trainer.clientRoutineExportVals(clientId)}
        />
    )
}

const mapDispatchToRoutineProps = dispatch => ({
    emailSubmit: data => dispatch(sendRoutinePdfEmail(data)),
    downloadSubmit: data => dispatch(downloadRoutinePdf(data))
})

ExportRoutinePDFModal = connect(mapStateToProps,mapDispatchToRoutineProps)(ExportRoutinePDFModal)
ExportClientRoutinePDFModal = connect(mapStateToProps,mapDispatchToRoutineProps)(ExportClientRoutinePDFModal)

export { ExportRoutinePDFModal, ExportClientRoutinePDFModal }

export default connect(mapStateToProps,mapDispatchToProps)(ExportClientPDFModal)