import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card';
import { CheckboxCore } from 'components/Form';
import MediaQuery from 'components/MediaQuery';
import { clientBasicProfilePath, clientDetailsPath, clientMealPlanSettingsPath } from 'config/paths';
import React, { useMemo, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BareMacroBarChart, DailyComplianceBarChart, PercentileDonutChart, WeightSparkline } from './ProgressCharts';
import * as _ from 'lib/utilities';
import classnames from 'classnames';
import { ClientDropdown } from './ClientDropdowns';
import LinkButton from 'components/LinkButton';
import Tooltipped from 'components/Tooltipped';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { UserProfileImage } from './Utilities';
import { AsyncSpinnerButton } from 'components/Button';

const rectMetrics = ['weight','volume','bwVolume','routine'];

const largeRectMetrics = ['loggedMacros','weeklyMacros','loggedWorkouts','loggedHabits'];

const metricLabels = {
    nutritionCompliance: 'Nutrition Compliance',
    exerciseCompliance: 'Exercise Compliance',
    habitCompliance: 'Habit Compliance',
    weight: 'Weight Progress',
    routine: 'Workout Plan',
    volume: 'Lifting Volume',
    bwVolume: 'Bodyweight Volume',
    loggedMacros: 'Logged Macros',
    weeklyMacros: 'Planned Macros',
    loggedWorkouts: 'Logged Workouts',
    loggedHabits: 'Logged Habits'
}

const tipMap = {
    nutritionCompliance: 'nutrition compliance tip',
    exerciseCompliance: 'exercise compliance tip',
    habitCompliance: 'habit compliance tip',
    weight: null,
    routine: null,
    volume: 'lifting volume tip',
    bwVolume: 'bodyweight volume tip',
    loggedMacros: 'logged macros tip',
    weeklyMacros: 'planned macros tip'
}

const activeMetrics = ({ trainer,metricType,isLarge }) => {
    let arr = [];
    if(trainer.isAppTrainer()) {
        if(metricType === 'summary') {
            if(isLarge) {
                arr = ['nutritionCompliance','exerciseCompliance','habitCompliance'];
            } else {
                if(trainer.showNutritionOnly()) {
                    arr = ['nutritionCompliance','habitCompliance'];
                } else if(trainer.showTrainingOnly()) {
                    arr = ['exerciseCompliance','habitCompliance'];
                } else {
                    arr = ['nutritionCompliance','exerciseCompliance'];
                }
            }
        } else if(metricType === 'exercise') {
            arr = ['loggedWorkouts']//isLarge ? ['volume','bwVolume'] : ['volume']
        } else if(metricType === 'habits') {
            arr = ['loggedHabits'];
        } else {
            arr = ['loggedMacros']
        }
    } else {
        if(isLarge) {
            arr = ['weight','weeklyMacros']
        } else {
            arr = ['weeklyMacros']
        }
    }
    return arr;
}

const ClientCheck = ({ client, isSelected, toggleHandler }) => {

    return (
        <div className="check-container">
            <CheckboxCore
                inputProps={{ 
                    name:'selected', 
                    value: client.id,
                    checked: isSelected,
                    onChange: toggleHandler
                } } 
                filled 
                middle
                className="low-em"
                id={`client-${client.id}-check`}
            />
        </div>
    )
}

const largeQuery = '(min-width: 750px)';

const rectType = metric => {
    if(largeRectMetrics.includes(metric)) {
        return 'large';
    } else if(rectMetrics.includes(metric)) {
        return 'rect'
    }
    return null;
}

export const getDims = (isLarge,metric,cnt) => {
    const rect = rectType(metric);
    if(rect === 'large') {
        if(isLarge && cnt < 2) {
            return { height: 100, width: 250 }
        } else {
            return { height: 100, width: 200 }
        }
    } else if(rect) {
        if(isLarge) {
            if(cnt <= 2) {
                return { height: 80, width: 140 }
            }
            return { height: 80, width: 80 }
        } else if(cnt < 2) {
            return { height: 80, width: 140 }
        } else {
            return { height: 60, width: 60 }
        }
    } else {
        return isLarge ? { height: 80, width: 80 } : { height: 60, width: 60 };
    }
}

const DashMetric = ({ isLarge, active, metric, data, noDataMsg, t, client, trainer }) => {
    const dims = getDims(isLarge,metric,active.length);
    const id = client ? `${metric}-${client.id}-chart` : null;

    switch(metric) {
        case 'habitCompliance':
        case 'nutritionCompliance':
        case 'exerciseCompliance': {
            return (
                <PercentileDonutChart 
                    percentile={data} 
                    {...dims} 
                    centerLabel={true} 
                    redGreen 
                    noDataMsg={noDataMsg} 
                    wrapperId={id}
                />
            )
        }
        case 'weight': {
            return (<WeightSparkline weight={data} {...dims} beginAtZero={false} units={trainer.isMetric() ? t('kgs') : t('lbs')} id={id} />)
        }
        case 'volume': {
            return (<WeightSparkline 
                weight={data} 
                {...dims} 
                color="#5cb8b8" 
                units={trainer.isMetric() ? `${t('kgs')}/${t('week')}` : `${t('lbs')}/${t('week')}`} 
                id={id}
            />)
        }
        case 'bwVolume': {
            return (<WeightSparkline 
                weight={data} 
                {...dims} 
                color="#8BC34A" 
                units={`${t('reps')}/${t('week')}`} 
                id={id} 
            />)
        }
        case 'routine': {
            const msg = noDataMsg === 'none' ? '' : t("None");
            return (<b id={id}>{(_.isBlank(data) || data === 'Meal Plan Only') ? msg : data}</b>);
        }
        case 'weeklyMacros': {
            return (
                <BareMacroBarChart 
                    data={data} 
                    {...dims} 
                    noDataMsg={noDataMsg} 
                    user={client} 
                    id={id} 
                />
            )
        }
        case 'loggedMacros': {
            return (
                <BareMacroBarChart 
                    data={data} 
                    {...dims} 
                    noDataMsg={noDataMsg} 
                    user={client} 
                    showWarnings={_.macroWarningsCheckFor(client)} 
                    id={id}
                />
            )
        }
        case 'loggedWorkouts': {
            return (
                <DailyComplianceBarChart 
                    data={data} 
                    {...dims} 
                    noDataMsg={noDataMsg} 
                    user={client} 
                    label={t('Exercise Compliance')} 
                    type="exercise"
                    id={id}
                />
            )
        }
        case 'loggedHabits': {
            return (
                <DailyComplianceBarChart 
                    data={data} 
                    {...dims} 
                    noDataMsg={noDataMsg} 
                    user={client} 
                    label={t('Habit Compliance')} 
                    type="habits"
                    id={id}
                />
            )
        }
        default:
            return metric;
    }
}

const DashMetrics = ({ isLarge, metrics, client, trainer, metricType, onClick }) => {
    const noDataMsg = metrics ? '-' : 'none';
    const active = activeMetrics({ trainer, metricType, isLarge });
    const metricClasses = classnames("metric",{ clickable: onClick, one: active.length === 1, two: active.length === 2, three: active.length >= 3 })
    metrics = metrics || {};
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {active.map(key => {
                return (
                    <div key={key} className={metricClasses} onClick={onClick}>
                        <DashMetric 
                            client={client}
                            metric={key} 
                            data={metrics[key]} 
                            isLarge={isLarge} 
                            active={active} 
                            noDataMsg={noDataMsg} 
                            trainer={trainer}
                            t={t} 
                        />
                    </div>
                )
            })}
        </React.Fragment>
    )
}

const MetricsPlaceholder = ({ trainer, metricType, isLarge }) => {
    const active = activeMetrics({ trainer, metricType, isLarge });
    const metricClasses = classnames("metric",{ one: active.length === 1, two: active.length === 2, three: active.length >= 3 })

    return (
        <React.Fragment>
            {active.map(metric => (<div className={metricClasses} key={metric}></div>))}
        </React.Fragment>
    )
}

const FirstMpButton = ({ client, link }) => {
    const { t } = useTranslation();

    return (
        <div className="filler center pr15">
            <LinkButton 
                noShadow 
                color="primary" 
                to={link} 
                id="generate-first-mp-btn"
                rounded
            >
                <FontAwesomeIcon icon={'random'}></FontAwesomeIcon> 
                {t('Generate First Meal Plan')}
            </LinkButton>
        </div>
    )
}

const SendInviteButton = ({ client, sendInvite }) => {
    const { t } = useTranslation();

    return (
        <div className="filler text-right pr15">
            <AsyncSpinnerButton
                buttonProps={{
                    noShadow: true,
                    color: 'primary',
                    label: t('Send Invitation Email'),
                    id: `send-${client.id}-invite-btn`
                }}
                icon={['far','paper-plane']}
                action={() => sendInvite(client.id)}
            />
        </div>
    )
}

const SetupClientButton = ({ client }) => {
    const { t } = useTranslation();

    return (
        <div className="filler text-right pr15">
            <LinkButton
                rounded 
                noShadow
                color="primary" 
                id={`setup-client-${client.id}-btn`}
                className="setup-client-btn"
                to={clientBasicProfilePath(client.id,'setup')}
            >
                <FontAwesomeIcon icon={"arrow-right"}></FontAwesomeIcon> {t("Set Up Client")}
            </LinkButton>
        </div>
    )
}

const ActiveTag = ({ client, t }) => {
    let tagColor = '';
    const laMom = client.lastActiveMom();
    if(laMom) {
        const diff = moment().diff(laMom,'days');
        if(diff > 5) {
            tagColor = 'rt';
        } else if(diff > 3) {
            tagColor = 'ot';
        } else if(diff > 1) {
            tagColor = 'yt';
        }
    } else {
        tagColor = 'gt';
    }

    return (
        <div 
            title={client.lastActiveAt && moment(client.lastActiveAt).format('YYYY-MM-DD hh:mm')} 
            className={classnames("last-active-tag ml5", {[tagColor]: tagColor })}
        >
                {t('Active')}: {client.lastActiveAtStr(t)[0]}
        </div>  
    )
}

const NeededTag = ({ strs, t }) => {

    return (
        <React.Fragment>
            {Object.entries(strs).map(([type,str]) => {
                return (
                    <div key={type} className="last-active-tag ot hide-on-small-only ml5">
                        {type === 'needs' ? t('Needs') : t('Needs to set up own')}: {str}
                    </div>  
                )
            })}
        </React.Fragment>
    )
}

const RegularTag = ({ tag }) => {
    const color = _.stringToColor(tag);

    return (
        <div className="last-active-tag hide-on-small-only" style={{ border: `1px solid ${color}`, color }}>
            {tag}
        </div>  
    )
}

const BillingTags = ({ client, t }) => {
    const billingStart = moment(client.billingStartedAt);
    const billingEnd = moment(client.billingStartedAt).add(30,'days');

    return (
        <React.Fragment>
            {client.isActive() && (<div className="last-active-tag">
                {t('Active')}
            </div>)}
            {client.isInactive() && (<div className="last-active-tag rt">
                {t('Deactivated')}
            </div>)}
            {client.isInactive() && (
                <Tooltipped className="last-active-tag ot ml5" options={{ html: `Billing for this client started on ${billingStart.format('ddd MMM Do')}. Clients are billable for a minimum of 30 days.`, classes: ['square'] }}>
                    {t('Billable until date', { date: billingEnd.format('ddd MMM Do') })}
                </Tooltipped>
            )}
        </React.Fragment>
    )
}

const ClientTags = ({ neededStr, showActiveTag, showBillingTags, regularTags, client, t }) => {

    
    return (
        <div className="cli-tag-cont">
            {regularTags.map(tag => (<RegularTag key={tag} tag={tag} />))}
            {neededStr && <NeededTag strs={neededStr} client={client} t={t} />}
            {showActiveTag && <ActiveTag client={client} t={t} />}
            {showBillingTags && <BillingTags client={client} t={t} />}
        </div>
    )

}

export const GenericDashCard = ({ children, user, selected, toggleHandler, clickLink, profileTag, extraTopPadding, cardRef }) => {
    const isSelected = selected.includes(user.id);
    const history = useHistory();

    return (
        <MediaQuery 
            query={largeQuery}
            render={({ queryMatches: isLarge }) => (
                <Card className={classnames("client-dash-card", {'extra-top-padding': extraTopPadding, selected: isSelected})} id={`client-card-${user.id}`} ref={cardRef}>
                    <div className="metric-container">
                        <ClientCheck client={user} isSelected={isSelected} toggleHandler={() => toggleHandler(user.id)} />
                        <div className="profile clickable position-relative" onClick={() => history.push(clickLink)}>
                            <div className="profile-wrap clickable">
                                <div className="circle-img ml0">
                                    <UserProfileImage user={user} />
                                </div>
                                <div className="profile-name text-center">
                                    {user.sortName()}
                                    {isLarge && (<div className="tiny-text font-grey">{user.email}</div>)}
                                </div>
                            </div>
                            {profileTag}
                        </div>
                        {React.Children.map(children, child => {
                            if(child) {
                                return React.cloneElement(child,{ isLarge });
                            }

                            return child;
                        })}
                    </div>
                </Card>
            )}
        />
    )
}

const getClickLink = (client,trainer) => {
    if(client.showGenerateFirstMpBtn(trainer)) {
        if(client.sampleClient) {
            return clientMealPlanSettingsPath(client.id,'first');
        } else {
            return client.createFirstMealPlanPath();
        }
    } else if(client.hasBasicProfile) {
        return clientDetailsPath(client.id);
    } else {
        return clientBasicProfilePath(client.id,'setup');
    }
}

function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
  
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting),
        ),
      [],
    );
  
    useEffect(() => {
      observer.observe(ref.current);
  
      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);
  
    return isIntersecting;
  }

export const ClientDashCard = ({ trainer, client, metrics, selected, toggleHandler, metricType, basePath, t, sendInvite, showBillingTags }) => {
    const cardRef = useRef(null);
    const inView = useIsInViewport(cardRef);
    const clickLink = getClickLink(client,trainer);
    const history = useHistory();
    const genOnly = client.showGenerateFirstMpBtn(trainer);
    const showSendInvite = client.setupOwnProfile && trainer.isAppTrainer() && client.inviteNeeded() && client.isActive();
    const showActiveTag = !showBillingTags && client.hasBasicProfile && trainer.isAppTrainer();
    const neededStr = !showBillingTags && client.needsSetupStr(t);
    const regularTags = _.noBlanks((metrics && metrics.tags) || []);
    const showTags = showBillingTags || showActiveTag || !!neededStr || regularTags.length > 0;
    const showSetupBtn = !client.hasBasicProfile && !showSendInvite && client.isActive();
    const hasPrimaryButton = showSendInvite || showSetupBtn;

    //Fragment around active at div required to avoid generic dash card mapping isLarge onto a div

    return (
        <GenericDashCard user={client} selected={selected} toggleHandler={toggleHandler} clickLink={clickLink} extraTopPadding={showTags} cardRef={cardRef}>
            {genOnly && (<FirstMpButton client={client} link={clickLink} />)}
            {!genOnly && !hasPrimaryButton && inView && <DashMetrics client={client} trainer={trainer} metricType={metricType} metrics={metrics} onClick={() => history.push(clickLink)} />}
            {!genOnly && !hasPrimaryButton && !inView && <MetricsPlaceholder trainer={trainer} metricType={metricType} />}
            {!genOnly && !hasPrimaryButton && <ClientDropdown trainer={trainer} client={client} basePath={basePath} />}
            {!genOnly && showSetupBtn && <SetupClientButton client={client} />}
            {!genOnly && showSendInvite && <SendInviteButton client={client} sendInvite={sendInvite} />}
            {!genOnly && showTags && <ClientTags client={client} neededStr={neededStr} showActiveTag={showActiveTag} showBillingTags={showBillingTags} regularTags={regularTags} t={t} />}
        </GenericDashCard>
    )
}

export const AllCheck = ({ clients, selected, setSelected}) => {
    const allIds = clients.map(client => client.id);
    const topCheckSel = (allIds.length > 0 && _.difference(allIds,selected).length === 0);

    return (
        <div className="check-container all-check">
            <CheckboxCore
                id="select-all-clients-check"
                inputProps={{ 
                    name:'selected', 
                    value: selected,
                    onChange: () => {
                        if(topCheckSel) {
                            setSelected([]);
                        } else {
                            setSelected(allIds);
                        }
                    },
                    checked: topCheckSel
                } } 
                className="low-em"
                filled 
                middle
            />
        </div>
    )
}

export const AverageDashCard = ({ clients, metrics, trainer, metricType, check }) => {
    const { t } = useTranslation();
    if(metrics) {
        const relKeys = clients.map(client => client.id);
        const vals = Object.values(_.pick(metrics,relKeys));
        metrics = { weight: null, nutritionCompliance: null, exerciseCompliance: null, habitCompliance: null };
        if(vals.length > 0) {
            ['nutritionCompliance','exerciseCompliance','habitCompliance'].forEach(metric => {
                const relVals = _.filter(vals,cliMetrics => !_.isBlank(cliMetrics[metric]))
                metrics[metric] = Math.round(relVals.reduce((acc,cur) => acc+cur[metric],0)/relVals.length)
            })
        }
    }

    return (
        <MediaQuery 
            query={largeQuery}
            render={({ queryMatches: isLarge }) => (
                <div className={classnames("client-dash-card average")}>
                    <div className="metric-container">
                        <div className="profile ml5">
                            {t('Average Stats')}:
                            {check}
                        </div>
                        <DashMetrics metrics={metrics} isLarge={isLarge} trainer={trainer} metricType={metricType}  />
                        <div className="dropdown-container"></div>
                    </div>
                </div>
            )}
        />
    )
}

const MetricTitles = ({ isLarge, trainer, metricType }) => {
    const { t } = useTranslation();
    const active = activeMetrics({ trainer, metricType, isLarge });
    const metricClasses = classnames("metric",{ one: active.length === 1, two: active.length === 2, three: active.length >= 3 });

    return (
        <React.Fragment>
            {active.map(metric => {
                const str = metricLabels[metric];
                const tip = tipMap[metric];
                const { width } = getDims(isLarge,metric,active.length);
                return (
                    <Tooltipped className={metricClasses} key={str} options={{ html: tip ? t(tip) : '', classes: ['square'] }} disableTip={!tip}>
                        <div className="metric-title" id={`metric-${metric}-title`} style={ { width: `${width}px` }}>
                            <div className="inline-block position-relative">
                            {t(str)} {tip && <FontAwesomeIcon icon='question-circle' className="q-icon" />}
                            </div>
                        </div>
                    </Tooltipped>
                )
            })}
        </React.Fragment>
    )
}

export const GenericTitleRow = ({ children, check }) => {

    return (
        <MediaQuery 
            query={largeQuery}
            render={({ queryMatches: isLarge }) => (
                <div className="client-dash-card title-row">
                    <div className="metric-container">
                        <div className="profile ml5">
                            {check}
                        </div>
                        {React.Children.map(children,child => React.cloneElement(child, { isLarge }))}
                        <div className="dropdown-container"></div>
                    </div>
                </div>
            )}
        />
    )
}

export const TitleRow = ({ trainer, metricType, check }) => {

    return (
        <GenericTitleRow check={check}>
            <MetricTitles trainer={trainer} metricType={metricType} />
        </GenericTitleRow>
    )
}