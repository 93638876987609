import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { editRecipePathFor, showPortionsModalPathFor } from 'config/paths';
import Button, { DefaultAsyncActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, withRouter } from 'react-router-dom';
import ActivityContainer from 'components/ActivityContainer';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import { Loader } from 'components/LoadingHOC';
import { loadAllRecipeVersions, restoreRecipeDefaults, startEditingRecipe } from 'redux/actions';
import { connect } from 'react-redux';
import Card from 'components/Card';
import { DontRecommendButton, ExitMealPlanButton, LikeRecipeButton, TrainerBadgeWithContext } from './Utilities';
import Dropdown from 'components/Dropdown';
import { Redirect } from 'components/Routing';
import { ScrollableFormModal } from 'components/Modal';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import Page, { SimpleNavPage } from 'components/Page';
import { FixedTopCarousel } from 'components/Carousel';
import { TagButtons } from 'components/Form';
import { SelectInput } from 'components/TextInput';
import { SlightEmphasisIconNote } from 'components/Typography';
import LinkButton, { BackButton } from 'components/LinkButton';
import RecipeMealRow from './RecipeMealRow';
import ProRequiredButton from './ProRequiredButton';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { RecipeMeal } from 'lib/classes';
import { LikeRecipeForClientsPopup } from './PickClientPopups';
import MacroSummary from './MacroSummary';

const PortionsModalContent = ({ meals, recipes, loadRecipes, renderHeader, renderScrollable, renderFooter }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {renderHeader()}
            {renderScrollable({ children: (
                <div className="text-center">
                    {meals.map((userMeal,index) => {
                        const classNames = classnames("mt30 text-left")
                        const recipeMeals = userMeal.recipeMeals.map(rm => new RecipeMeal({ ...rm, recipe: _.find(recipes,recipe => recipe.id === rm.recipeId)}))
                        return (
                            <div key={userMeal.id} className={classNames} style={{ maxWidth: '350px', width: '100%', display: 'inline-block' }}>
                                <div className="center title-medium">{userMeal.eaterName(t)}</div>
                                <div className="center"><MacroSummary macroHash={userMeal.macroHash()} className="main-head-sub" /></div>
                                {recipeMeals.map(recipeMeal => {
                                    return (
                                        <RecipeMealRow 
                                            key={recipeMeal.id} 
                                            recipeMeal={recipeMeal} 
                                            index={0} 
                                            type='portions' 
                                            load={loadRecipes} 
                                            contentRender={({ infoIcon }) => {
                                                return (
                                                    <div className="text-center mt10">
                                                        {`${recipeMeal.readableServings()} ${recipeMeal.servingName(t)}`}
                                                        {infoIcon}
                                                    </div>
                                                )
                                            }} />
                                    )
                                })}
                            </div>
                        )
                    })}
                </div>
            )})}
            {renderFooter({ children: (
                <Button rounded color="primary" className="modal-close" >
                    {t('Ok')}
                </Button>
            ) })}
        </React.Fragment>
    )
 
}

const ShowPortionsModal = ({ basePath, meals, recipes, loadRecipes }) => {
    const { t } = useTranslation();
    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            path={showPortionsModalPathFor(basePath)} 
            exact 
            icon={['far','user-friends']}
            title={t("Portions Summary")}
            render={({ renderFooter, renderScrollable, renderHeader }) => (
                <PortionsModalContent 
                    meals={meals}
                    recipes={recipes}
                    loadRecipes={loadRecipes}
                    renderFooter={renderFooter}
                    renderScrollable={renderScrollable}
                    renderHeader={renderHeader}
                />
            )} 
        />
    )
}

const ActionMenuContents = ({ user, trainer, recipe, startEditingRecipe, restoreDefaults, t, history, wrapperLoadState: loadState }) => {
    
    const hideClientEdit = trainer && trainer.id === user.id;
    const trainerRecipe = trainer && recipe.editableVersionFor(trainer);
    const trainerEditReady = loadState === 'SUCCESS';
    const hideTrainerEdit = trainerEditReady && !trainerRecipe;
    const showTrainerUndo = (trainerRecipe && trainerRecipe.isOverrideFor(trainer));
    const trainerEditClick = () => {
        if(trainerEditReady) {
            let showMasterEditTip = false;
            if(trainer.id !== user.id) {
                if(recipe.ownerId && recipe.ownerId === user.id) {
                    showMasterEditTip = 'master edit tip client override'
                }
            }
            startEditingRecipe(trainer,trainerRecipe,false,showMasterEditTip);
            history.push(editRecipePathFor(1));
        }
    }
    return (
        <React.Fragment>
            {recipe.isFullyLoaded() && !hideClientEdit && (<li>
                <ProRequiredButton  
                    user={user}
                    context="recipe_editor"
                    proClickHandler={() => {
                        startEditingRecipe(user,recipe,false);
                        history.push(editRecipePathFor(1))
                    }}
                    blockTypes={['old','hard','soft']}
                    render={({ onClick }) => (
                        <span className="clickable" id="edit-client-recipe-btn" onClick={onClick}>
                            <FontAwesomeIcon icon={['far','edit']} /> {trainer ? t("Edit Client Version") : t("Edit Recipe")}
                        </span>
                    )}

                />
            </li>)}
            {!hideTrainerEdit && (
                <li>
                    <span className="clickable" id={trainerEditReady ? "edit-master-recipe-btn" : null} onClick={trainerEditClick}>
                        {!trainerEditReady && <FontAwesomeIcon icon="spinner" spin />}
                        {trainerEditReady && <FontAwesomeIcon icon={['far','edit']} />} 
                        {` ${hideClientEdit ? t("Edit Recipe") : t("Edit Master Version")}`}
                    </span>
                </li>
            )}
            {recipe.isFullyLoaded() && (<li>
                <ProRequiredButton  
                    user={user}
                    context="recipe_editor"
                    proClickHandler={() => {
                        startEditingRecipe(trainer || user,recipe,true);
                        history.push(editRecipePathFor(1));
                    }}
                    blockTypes={['old','hard','soft']}
                    render={({ onClick }) => (
                        <span className="clickable" id="copy-recipe-btn" onClick={onClick}>
                            <FontAwesomeIcon icon={['far','copy']} /> {t("Copy Recipe")}
                        </span>
                    )}
                />
            </li>)}
            {!trainer && recipe.isOverrideFor(user) && (<li>
                <ProRequiredButton  
                    user={user}
                    context="restore_defaults"
                    proClickHandler={() => {
                        restoreDefaults(recipe.id);
                    }}
                    blockTypes={['old','hard','soft']}
                    render={({ onClick }) => (
                        <span onClick={onClick}>
                            <FontAwesomeIcon icon="cloud-download-alt" /> {t("Restore Defaults")}
                        </span>
                    )}
                />
            </li>)}
            {trainer && user.id !== trainer.id && recipe.isOverrideFor(user) && (<li>
                <span className="clickable" id="clear-client-edits-btn" onClick={() => restoreDefaults(recipe.id)}>
                    <FontAwesomeIcon icon="undo" /> {t("Clear Client Edits")}
                </span>
            </li>)}
            {showTrainerUndo && (<li>
                <span className="clickable" id={trainerEditReady ? "clear-master-edits-btn" : null } onClick={() => {
                    if(trainerEditReady) {
                        restoreDefaults(trainerRecipe.id);
                    }
                }}>
                    {!trainerEditReady && <FontAwesomeIcon icon="spinner" spin />}
                    {trainerEditReady && <FontAwesomeIcon icon="undo" />} 
                    {` ${hideClientEdit ? t("Clear Edits") : t('Clear Master Edits')}`}
                </span>
            </li>)}
            <DontRecommendButton recipe={recipe} />
        </React.Fragment>
    )
}

const ActionMenuContentsWrapper = ({ trainer, recipe, wrapperLoadState, ...rest }) => {
    const props = { trainer, recipe, ...rest };

    return (
        <ActionMenuContents 
            {...props}
            wrapperLoadState={wrapperLoadState}
        />
    )
}

class RecipeActionMenu extends React.Component {

    render() {

        const { user, trainer } = this.props;
        const isTrainerEditing = _.signedInAsClient(trainer,user);
        
        if(isTrainerEditing || user.allowedToEditOwnMealPlan()) {
            return (
                <Dropdown 
                        contentComp='ul'
                        options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                        triggerRender={({ ref, target }) => {
                            return (
                                    <div className="wol-right-icon" ref={ref} data-target={target} id={`recipe-action-menu`}>
                                        <FontAwesomeIcon icon="ellipsis-h" />
                                    </div>
                            )
                        }}
                        contentRender={({ recalcDims }) => {
                            return (
                                <ActionMenuContentsWrapper {...this.props} />
                            )
                        }}
                    />
            )
        }

        return (
            <div style={{ marginLeft: 'auto', height: '40px' }}></div>
        );
    }
}


const mapStateToUserCtxtProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToMenuProps = dispatch => ({
    startEditingRecipe: (user,recipe,isCopy,showMasterEditWarning) => dispatch(startEditingRecipe(user,recipe,isCopy,showMasterEditWarning)),
    restoreDefaults: id => dispatch(restoreRecipeDefaults(id))
})

RecipeActionMenu = connect(mapStateToUserCtxtProps,mapDispatchToMenuProps)(withTranslation()(withRouter(RecipeActionMenu)))

const DatesItem = ({ weeklyMeal, className }) => {

    if(weeklyMeal) {
        return (
            <div className={classnames('rp-date', {[className]: className})}>
                {weeklyMeal.datesStr()}
            </div>
        )
    }

    return '';
}

const MacroSubheading = ({ children }) => {
    return (
        <div className="basic-heading-sub ml10">{children}</div>
    )
}

const MiniProfileButtons = ({ sharedProfiles, miniProfileId, setMiniProfile, wrap }) => {
    const { t } = useTranslation();
    const col = [{ text: t('Everyone'), value: '' }, { text: _.capitalize(t('you')), value: 'you' }, ...sharedProfiles.map(profile => ({ text: profile.name, value: profile.id }))]
    const classNames = classnames("added-people-wrapper", { wrap })

    return (
        <div className="display-flex">
            <p className="no-wrap">{t('Show for')}:</p>
            <div className={classNames}>
                <TagButtons
                    btnId={(val) => `toggle-profile-${val}`}
                    col={col}
                    setFieldTouched={() => {}}
                    setFieldValue={(name,value) => setMiniProfile(value)}
                    name={'miniProfileId'}
                    values={{ miniProfileId }}
                    single
                    buttonProps={{ className: 'btn-no-shadow' }}
                />
            </div>
        </div>
    )

}

const pickIngredientControlProps = props => _.pick(props,['recipeMeal','showShareItems','sharedProfiles','miniProfileId','setMiniProfile','mealCount','mealPrepDays','setMealPrepDays'])

const IngredientControls = ({ recipeMeal, showShareItems, sharedProfiles, miniProfileId, setMiniProfile, mealCount, mealPrepDays, setMealPrepDays, wrap }) => {
    const { t } = useTranslation();
    const col = [];
    for(let i=0; i < mealCount; i++) {
        col.push({ text: t('X day(s)', { days: (i+1) }), value: i+1 })
    }

    return (
        <div>
            {showShareItems && <MiniProfileButtons sharedProfiles={sharedProfiles} miniProfileId={miniProfileId} setMiniProfile={setMiniProfile} wrap={wrap} />}
            <div className="display-flex">
                <p>{t('Meal prep')}:</p>
                <SelectInput
                    name={'mealPrepDays'} 
                    collection={col} 
                    browserDefault 
                    value={mealPrepDays} 
                    onChange={(e) => setMealPrepDays(_.castNumericInput(e.target.value))} 
                    className="inline select-signup-medium ml5"
                />
            </div> 
            {recipeMeal.showServings() && (<div className="display-flex">
                <p>{t('Makes')}: <span className="font-grey">{recipeMeal.readableServings()} {recipeMeal.servingName(t)}</span></p>
            </div>)}
        </div>
    )
}

const RecipeInstructions = ({ recipe }) => {
    const steps = recipe.instructionSteps();
    const { t } = useTranslation();

    if(steps && steps.length > 0) {
        return (
            <ol className="rp-ol">
                {recipe.instructionSteps().map((step,index) => (<li key={index} className="mt15 mb15">{step}</li>))}
            </ol>
        )
    } else {
        return (<div className="ml10 mr10 mb10"><SlightEmphasisIconNote text={t('This recipe doesn\'t have any instructions')} /></div>)
    }

}

class IngredientListing extends React.Component {

    constructor(props) {
        super(props);
        this.state = { showLoadButton: false };
        this.showLoadTimeout = setTimeout(() => 
        {
            const { recipeMeal: { recipe }} = this.props;
            if(!recipe.isFullyLoaded()) {
                this.setState({ showLoadButton: true })
            }
        },7000)
    }

    render() {
        const { showLoadButton } = this.state;
        const { recipeMeal: { recipe }, recipeMeal, load, t, notEatingComp } = this.props;

        if(recipe.isFullyLoaded()) {
            if(!notEatingComp) {
                return (
                    <React.Fragment>
                        {recipeMeal.fullIngredientList().map(({ id, qtyText, nameText },index) => {
                            return (
                                <React.Fragment key={id}>
                                    {index !== 0 && (<div className="divider-line rp mt10"></div>)}
                                    <div className="recipe-ingredient">
                                        <div className="basic-heading">{nameText}</div>
                                        <div className="basic-heading-sub">{qtyText}</div>
                                    </div>
                                </React.Fragment>
    
                            )
                        })}
                    </React.Fragment>
                )
            } else {
                return notEatingComp;
            }
        } else {
            if(showLoadButton) {
                return (
                    <div className="text-center pa20">
                        <div>
                            <FontAwesomeIcon icon="times" className="error-color" /> {t('Recipe failed to load...')}
                        </div>
                        <DefaultAsyncActionButton 
                            rounded 
                            color="primary" 
                            className="no-upcase" 
                            noShadow
                            outlined 
                            id="load-recipes-btn"
                            action={load}
                        >
                            {t("Try Again")}
                        </DefaultAsyncActionButton>
                    </div>
                )
            } else {
                return (<div className="pa20 text-center">
                    {t('Loading...')}
                </div>)
            }
        }
    }

    componentWillUnmount() {
        clearTimeout(this.showLoadTimeout);
    }
}

export const SimpleNavBack = (props) => {
    return (
        <BackButton render={({ back }) => (
            <div className="back-arrow" onClick={back}>
                <FontAwesomeIcon icon='chevron-left' />
            </div>
        )} />
    )
}

const PortionsModalButton = ({ basePath, variant }) => {
    const { t } = useTranslation();
    const classNames = classnames("icon-placement",{ "top-right": variant === 'mobile', 'bottom-left': variant === 'desktop'})

    return (
        <div className={classNames}>
            <LinkButton noColor className="semitransparent dark" to={showPortionsModalPathFor(basePath)} id="portions-modal-btn">
                <FontAwesomeIcon icon="user-friends" />
                {t('Portions')}
            </LinkButton>
        </div>
    )
}


const NameHeading = ({ recipe, showMacros, fullMacroSummary }) => {

    return (
        <React.Fragment>
            <h3 className="mr10 ml10 mb5 mt0">{recipe.name}</h3>
            {showMacros && <MacroSubheading>{fullMacroSummary}</MacroSubheading>}
        </React.Fragment>
    )
}

const MobileHeader = ({ showShareItems, basePath }) => {

    return (
        <div style={{ position: 'fixed', zIndex: '8', width: '100%' }}>
            <div className="icon-placement top-left">
                <BackButton render={({ back }) => (
                    <div className="btn semitransparent-circle btn-round-icon" onClick={back}>
                        <FontAwesomeIcon icon="chevron-left" />
                    </div>
                )}/>
            </div>
            {showShareItems && (<PortionsModalButton variant="mobile" basePath={basePath} />)}
        </div>
    )
}

class ViewMealDesktopCore extends React.Component {

    render() {
        const { 
            meal, 
            confirmComp, 
            weeklyMeal, 
            recipeMeal, 
            setActiveRecipe, 
            t, 
            showMacros, 
            fullMacroSummary,
            loadRecipes,
            showShareItems,
            basePath,
            portionsModal,
            notEatingComp,
            wrapperLoadState
        } = this.props;
        const { recipe } = recipeMeal;

        return (
            <React.Fragment>
                <ActivityContainer>
                    <div className="col s12">
                        <div className="max-list ml-center mt0">
                            <div className="position-relative">
                                <div className="display-flex">
                                    {meal.recipeMeals.length > 1 && (
                                        <React.Fragment>
                                            <div className="btn semitransparent-circle btn-round-icon arrows-hide" onClick={() => setActiveRecipe(meal.prevRecipeId(recipe.staticId()))}>
                                                <FontAwesomeIcon icon="chevron-left" />
                                            </div>
                                            <div className="btn semitransparent-circle btn-round-icon arrows-hide right" onClick={() => setActiveRecipe(meal.nextRecipeId(recipe.staticId()))}>
                                                <FontAwesomeIcon icon="chevron-right" />
                                            </div>
                                        </React.Fragment>
                                    )}
                                    <div className="image-center-crop large rounded-corners">
                                        <svg width="500" viewBox="0 0 500 334"></svg>
                                        <img src={recipe.imageUrl('mediumThumb')} alt="" />
                                        <TrainerBadgeWithContext recipe={recipe} />
                                        <LikeRecipeButton recipe={recipe} />
                                    </div>
                                    {showShareItems && (<PortionsModalButton variant="desktop" basePath={basePath} />)}
                                </div>
                            </div>
                            <div>
                                <DatesItem weeklyMeal={weeklyMeal} />
                                <RecipeActionMenu recipe={recipe} wrapperLoadState={wrapperLoadState} />
                            </div>
                            <NameHeading recipe={recipe} showMacros={showMacros} fullMacroSummary={fullMacroSummary} />
                        </div>
                    </div>
                    <div className="col s12">
                        <div className="max-list ml-center">
                            <Card className="pb30">
                                <div className="recipe-ingredients-container">
                                    <div className="wt-routine mb20 ml0">{t('Ingredients')}</div>
                                    <div className="divider-line rp mb10"></div>
                                    <IngredientControls {...pickIngredientControlProps(this.props)} wrap />
                                    <div className="divider-line rp mt10"></div>
                                    <IngredientListing t={t} recipeMeal={recipeMeal} load={loadRecipes} notEatingComp={notEatingComp} />
                                </div>
                            </Card>
                        
                            <Card className="pb30 mt20">
                                    <div className="wt-routine mb20 ml0">{t('Recipe')}</div>
                                    <RecipeInstructions recipe={recipe} />
                            </Card>
                        </div>
                    </div>
                </ActivityContainer>
                {confirmComp}
                {portionsModal}
                <LikeRecipeForClientsPopup />
            </React.Fragment>
        )
    }
}

class ViewMealMobileCore extends React.Component {

    render() {
        const { 
            meal, 
            confirmComp, 
            weeklyMeal, 
            recipeMeal, 
            setActiveRecipe, 
            t, 
            showMacros, 
            fullMacroSummary,
            loadRecipes,
            showShareItems,
            basePath,
            portionsModal,
            notEatingComp,
            wrapperLoadState
        } = this.props;
        const { recipe } = recipeMeal;

        return (
            <React.Fragment>
                <MobileHeader showShareItems={showShareItems} basePath={basePath} />
                <FixedTopCarousel 
                    initialActive={recipeMeal.recipe.staticId()}
                    items={meal.carouselItems().map(item => ({...item, children: (
                        <React.Fragment>
                            <TrainerBadgeWithContext recipe={item.recipe} className="bottom-left" />
                            <LikeRecipeButton recipe={item.recipe} />
                        </React.Fragment>
                    )}))}
                    onCycleTo={setActiveRecipe}
                />
                <div className="sliding-background">
                    <div className="rp-header-wrapper">
                        <DatesItem weeklyMeal={weeklyMeal} />
                        <RecipeActionMenu recipe={recipe} wrapperLoadState={wrapperLoadState} />
                    </div>
                    <NameHeading recipe={recipe} showMacros={showMacros} fullMacroSummary={fullMacroSummary} />
                    <div className="recipe-ingredients-container">
                        <div className="divider-line rp mt30 mb10"></div>
                        <IngredientControls {...pickIngredientControlProps(this.props)} />
                        <div className="divider-line rp mt10"></div>
                        <div className="center title-medium thin mb20">{t("Ingredients")}</div>
                        <IngredientListing t={t} recipeMeal={recipeMeal} load={loadRecipes} notEatingComp={notEatingComp} />
                        <div className="divider-line rp mt35"></div>
                    </div>
                    
                    <div className="center title-medium thin mb20">{t("Recipe")}</div>
                    <RecipeInstructions recipe={recipe} />
                </div>
                {confirmComp}
                {portionsModal}
                <LikeRecipeForClientsPopup />
            </React.Fragment>
        )
    }
}

let ViewMealLoader = ({ meal, loadRecipes, Success, ...rest }) => {
    const history = useHistory();
    const { t } = useTranslation();

    return (
        <Loader
            load={loadRecipes}
            preloaded={() => meal.recipeMealsLoaded()}
            alwaysLoad
            type='page'
            successComponent={Success}
            meal={meal}
            history={history}
            t={t}
            loadRecipes={loadRecipes}
            {...rest}
        />
    )
}

const ViewMealDesktop = ({ scrollRef, transitionMap, setupTransitions, redirect, ...props }) => {
    const { t } = useTranslation();

    return (
        <SimpleNavPage
            whiteMode
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t('View Recipe(s)')}
            navChildren={<ExitMealPlanButton />}
        >
            {redirect && <Redirect to={redirect} />}
            {!redirect && <ViewMealLoader {...props} Success={ViewMealDesktopCore} />}        
        </SimpleNavPage>
    )
}

class ViewMealMobile extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(props.transitionMap);
    }

    render() {
        const { scrollRef, transitionMap, setupTransitions, redirect, ...props } = this.props;

        return (
            <Page ref={scrollRef} noScrollPadding>
                {redirect && <Redirect to={redirect} />}
                {!redirect && <ViewMealLoader {...props} Success={ViewMealMobileCore} />}
            </Page>
        )
    }
}


class ViewMealParent extends React.Component {

    constructor(props) {
        super(props);
        const { focusId, activeTab, meal, redirect, miniProfile } = this.props;
        let activeRecipeId = activeTab;
        if(!redirect) {
            if(_.isBlank(activeRecipeId))
                activeRecipeId = focusId;
            if(_.isBlank(activeRecipeId) || !meal.recipeMealFor(Number(activeRecipeId)))
                activeRecipeId = meal.mainRecipeId();
        }
        this.state = { activeRecipeId: Number(activeRecipeId), miniProfileId: (miniProfile ? miniProfile.id : ''), mealPrepDays: 1 }
    }

    componentDidMount() {
        if(window.isCordova && window.plugins && window.plugins.insomnia) {
            window.plugins.insomnia.keepAwake();
        }
    }

    componentWillUnmount() {
        if(window.isCordova && window.plugins && window.plugins.insomnia) {
            window.plugins.insomnia.allowSleepAgain();
        }
    }

    render() {
        const { meal, weeklyMeal, activeTab, focusId, setActiveTab, mealCount, t, redirect, location, loadRecipes, ...rest } = this.props;
        let showShareItems = false;
        const basePath = location.pathname.replace('/portions','');
        let props = { 
            redirect,
            loadRecipes,
            basePath,
            ...rest 
        };

        if(!redirect) {
            const { activeRecipeId, miniProfileId, mealPrepDays } = this.state;
            let parentDish = meal.recipeMealFor(activeRecipeId) || meal.mainDish();
            let recipeMeal = parentDish;
            let sharedProfiles = null;
            if(weeklyMeal) {
                let childMeal;
                if(_.isBlank(miniProfileId)) {
                    childMeal = weeklyMeal.totalMeal();
                } else if(miniProfileId === 'you') {
                    childMeal = meal;
                } else {
                    childMeal = weeklyMeal.sharedMealFor(miniProfileId) || meal;
                }
                recipeMeal = childMeal.recipeMealFor(parentDish.staticRecipeId()) || parentDish.servingsCopy(0);
                sharedProfiles = weeklyMeal.sharedProfiles();
            }
            recipeMeal.recipe = meal.recipeMealFor(recipeMeal.staticRecipeId()).recipe; //to get fully loaded recipe
            const macroSummary = recipeMeal.recipe ? _.macroHashSummary(t,recipeMeal.macroHash(),true,true) : '';
            recipeMeal = recipeMeal.servingsCopy(mealPrepDays*recipeMeal.servings);
            
    
            showShareItems = weeklyMeal && sharedProfiles && sharedProfiles.length > 0;
            const showMacros = (!weeklyMeal || !showShareItems || (recipeMeal.servings > 0 && !_.isBlank(miniProfileId)));
            const miniProfileName = this.miniProfileName(miniProfileId,sharedProfiles);
            const fullMacroSummary = showShareItems ? `${t('Per meal (name)',{ name: miniProfileName })}: ${macroSummary}` : `${macroSummary} (${t('per meal')})`
            const boundLoadRecipes = loadRecipes.bind(null,meal.recipeIdsNeedLoading());
            const notEatingComp = (_.isBlank(miniProfileName) || recipeMeal.servings > 0) ? null : (<div id="not-eating-recipe-msg" className="ml10 mr10 mb10"><SlightEmphasisIconNote text={t("Name won't be eating this recipe",{ name: miniProfileName })} /></div>)

            props = { 
                setMiniProfile: this.setMiniProfile, 
                setActiveRecipe: this.setActiveRecipe, 
                setMealPrepDays: this.setMealPrepDays,
                recipeMeal, 
                meal, 
                weeklyMeal, 
                miniProfileId, 
                showMacros, 
                fullMacroSummary,
                showShareItems,
                mealPrepDays,
                mealCount,
                sharedProfiles,
                notEatingComp,
                portionsModal: (showShareItems && (
                    <ShowPortionsModal 
                        basePath={basePath}
                        meals={[ weeklyMeal.initMeal(), ...weeklyMeal.sharedMeals() ]}
                        recipes={meal.recipeMeals.map(rm => rm.recipe)}
                        loadRecipes={boundLoadRecipes}
                    />
                )),
                ...props,
                loadRecipes: boundLoadRecipes,
            };
        }

        return (
            <React.Fragment>
                <ResponsiveContainer 
                    breakpoint={522}
                    render={({ belowBreakpoint }) => {
                        if(belowBreakpoint) {
                            return <ViewMealMobile {...props} />
                        }
            
                        return <ViewMealDesktop {...props} />
                    }}
                />
            </React.Fragment>
        )
    }
    
    setActiveRecipe = (activeId) => {
        const { setActiveTab: persistActive } = this.props;
        const id = Number(activeId);
        persistActive(id);
        this.setState({ activeRecipeId: id });
    }

    setMiniProfile = (miniProfileId) => {
        this.setState({ miniProfileId })
    }

    miniProfileName = (miniProfileId,sharedProfiles) => {
        const { t } = this.props;
        if(_.isBlank(miniProfileId)) {
            return '';
        }else if(miniProfileId === 'you') {
            return t('you');
        } else {
            return _.find(sharedProfiles,profile => profile.id === miniProfileId).name;
        }

    }

    setMealPrepDays = (mealPrepDays) => {
        this.setState({ mealPrepDays })
    }
}

const mapDispatchToProps = dispatch => ({
    loadRecipes: ids => dispatch(loadAllRecipeVersions(ids))
})

ViewMealParent = connect(null,mapDispatchToProps)(withTranslation()(ViewMealParent));

export { ViewMealParent }