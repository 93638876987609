import React from 'react';
import { ScrollableFormModal } from 'components/Modal';
import { etSettingsModalMatchFor, stReferenceWeightModalMatchFor } from 'config/paths';
import { useTranslation } from 'react-i18next';
import { updateExerciseTemplate, loadProgressionRequirements, loadAutoregTemplates, updateStRefWeight, loadStReferences } from 'redux/actions';
import { connect } from 'react-redux';
import FormikWrapper from 'components/FormikWrapper';
import * as _ from 'lib/utilities';
import { userRecordSelector, progressionSchemeRecordsSelector, bannableProgSelector, getEtEprSelector } from 'redux/selectors';
import { ModalForm, InnerFormContainer, CollapsibleFormSection, DefaultSelect, MultivalueCheckbox, DefaultTextArea } from 'components/Form';
import { limitPatternHandler } from 'components/TextInput';
import { ExerciseTemplate } from 'lib/classes';
import { Redirect } from "components/Routing";
import { DependencyForm } from 'components/DependencyForm';
import { Loader } from 'components/LoadingHOC';
import { IconNote } from 'components/Typography';
import Button from 'components/Button';


const AutoregSection = ({ record, scheme, responseData: { setTemplates }, ...rest}) => {
    const { t } = useTranslation();
    if(Object.values(setTemplates).length === 0) {
        return (<div className="pa5 text-center">
            <div className="left-align-block">
                <IconNote text="Looks like there aren't any AMRAP sets to autoregulate from." /> 
            </div>
        </div>)
    }
    const opts = Object.values(setTemplates).map(st => ({ text: st.referenceDesc, value: st.id}));
    opts.unshift({ text: 'No autoregulation', value: ''});
    const col = record.weightIncreaseCol(t);

    return (
        <React.Fragment>
            <DefaultSelect 
                className="mt20"
                name='progressionParams.amrapSourceId'
                label={t('Set to use')}
                collection={opts}
                {...rest}
            />
            <div className="font-grey no-wrap mt20">Performance thresholds</div>
            <div className="pa5 text-center">
                {[1,2,3].map(index => {
                    const name = `progressionParams.repThreshold${index}`;
                    return (
                            <div className="mb10">
                                <input 
                                    name={name}
                                    value={_.get(rest.values,name)}
                                    type="number"
                                    onChange={rest.handleChange}
                                    onBlur={rest.handleBlur}
                                    onKeyPress={limitPatternHandler(/[0-9]+/)}
                                    className="in-text-input mr5"
                                />

                                or more reps, add 

                                <DefaultSelect 
                                    className="inline-block ml5"
                                    name={`progressionParams.weightIncrease${index}`}
                                    collection={col}
                                    {...rest}
                                />
                            </div>
                    )
                })}
                <div>Otherwise, increase weight by <SchemeParamText param='weightIncrease' record={record} scheme={scheme} t={t} {...rest} /></div>
            </div>
        </React.Fragment>
    )
}

let AutoregulationSectionLoader = ({ record, scheme, loadAutoregTemplates, ...rest }) => {

    return (
        <Loader 
            load={loadAutoregTemplates.bind(null,record.getActionParams())}
            type="padded"
            preloaded={() => false}
            successComponent={AutoregSection}
            record={record}
            scheme={scheme}
            {...rest}
        />
    )
}
 const mapDispatchToAutoregProps = dispatch => ({
     loadAutoregTemplates: data => dispatch(loadAutoregTemplates(data))
 })

 AutoregulationSectionLoader = connect(null,mapDispatchToAutoregProps)(AutoregulationSectionLoader);

const SchemeParamText = ({ record, scheme, param, t, ...rest }) => {
    const name = `progressionParams.${param}`;
    const curVal = _.get(rest.values,name);
    const [col] = scheme.getColAndTip(record,param,curVal,t);

    if(col) {
        const opt = _.find(col,obj => obj.value === curVal);
        return `${opt.text}`;
    } else {
        return `${curVal}`;
    }
}

const SchemeParamInput = ({ record, scheme, param, t, ...rest }) => {
    const name = `progressionParams.${param}`;
    const curVal = _.get(rest.values,name);
    const [col,tooltip] = scheme.getColAndTip(record,param,curVal,t);

    if(col) {
        return (
            <React.Fragment>
                <DefaultSelect 
                    className="inline-block"
                    name={name}
                    collection={col}
                    tooltip={tooltip}
                    {...rest}
                />
                {scheme.isAutoregulated() && param === 'weightIncrease' && '*'}
            </React.Fragment>
        )
    } else {
        const pattern = param === 'percentIncrease' ? /[0-9.]+/ : /[0-9]/
        return (
            <input 
                name={name}
                value={curVal}
                type="number"
                step="any"
                onChange={rest.handleChange}
                onBlur={rest.handleBlur}
                onKeyPress={limitPatternHandler(pattern)}
                className="in-text-input"
            />
        );
    }
}

const SchemeDescription = ({ scheme, ...rest }) => {
    const map = scheme.descriptionMap();

    return (
        <React.Fragment>
            {map.strings.map((string,index) => {
                const param = map.params[index];
                const paramAlreadyHit = param && map.params.indexOf(param) < index;
                return (
                    <React.Fragment key={param ? `${param}-${index}` : `str${index}`}>
                        {string}
                        {paramAlreadyHit && <SchemeParamText param={param} scheme={scheme} {...rest} />}
                        {param && map.params.indexOf(param) >= index && <SchemeParamInput param={param} scheme={scheme} {...rest} />}
                    </React.Fragment>
                )
            })}
        </React.Fragment>
    )

}

const ProgressionSchemeSection = ({ record, ...rest }) => {
    const { t } = useTranslation();
    const scheme = record.selectedProgressionScheme();

    return (
        <div id={`scheme-${scheme && scheme.id}-description`}>
            <DefaultSelect 
                className="mt20"
                name="progressionSchemeId"
                label={t('Progression scheme')}
                collection={record.progressionSchemeCol()}
                {...rest}
            />
            {scheme && <div className="pa5 mt10" style={{ lineHeight: '40px' }}><SchemeDescription record={record} scheme={scheme} t={t} {...rest} /></div>}
            {scheme && scheme.isAutoregulated() && (
                <CollapsibleFormSection header="*Autoregulation" dontRenderUntilShow>
                    <AutoregulationSectionLoader 
                        record={record}
                        scheme={scheme}
                        {...rest}
                    />
                </CollapsibleFormSection>
            )}
        </div>
    )
}

const ExerciseTemplateSettingsForm = ({
    renderFooter, 
    renderScrollable, 
    updateTemplate, 
    exerciseTemplate, 
    user, 
    progressionSchemes, 
    bannableProgs, 
    exerciseProgressionRequirements
}) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <DependencyForm
                record={exerciseTemplate}
                context={{ currentUser: user, progressionSchemes, bannableProgs, eprs: exerciseProgressionRequirements  }}
                submit={updateTemplate}
                initialErrors={{}}
                render={({ handleSubmit, submitState, record, ...rest }) => {
                    return (
                        <ModalForm centered onSubmit={handleSubmit}>
                            {renderScrollable({ children: (
                                <InnerFormContainer className="pa10" style={{width: '100%'}}>
                                    <div className="valign-wrapper flex-end">
                                        <DefaultSelect 
                                            className="flex-grow"
                                            name="setType"
                                            label={t('Set type')}
                                            collection={record.selectCol(t,'setType')}
                                            tooltip={record.setTypeTooltip(t)}
                                            {...rest}
                                        />
                                        {record.formSupersetType() === 'check' && (<MultivalueCheckbox 
                                            name="supersetType"
                                            checkedVal='always'
                                            uncheckedVal='never'
                                            label={`${t('Superset')}?`}
                                            className="ml10 mb5"
                                            tooltip={t('template superset tip')}
                                            {...rest}
                                        />)}
                                        {record.formSupersetType() === 'select' && (<DefaultSelect 
                                            name="supersetType"
                                            label={`${t('Superset')}?`}
                                            className="ml10"
                                            tooltip={t('template superset tip')}
                                            collection={ExerciseTemplate.SUPERSET_COL}
                                            {...rest}
                                        />)}
                                    </div>
                                    {record.isMaxTest() && (
                                        <DefaultSelect 
                                            className="mt20"
                                            name="repMaxReps"
                                            label={t('Test for')}
                                            collection={record.repMaxRepsCol(t)}
                                            {...rest}
                                        />
                                    )}
                                    {record.allowWeightType() && (<div className="valign-wrapper flex-end mt20">
                                        <DefaultSelect 
                                                className="flex-grow"
                                                name="weightType"
                                                label={t('Weight type')}
                                                collection={record.selectCol(t,'weightType')}
                                                tooltip={record.weightTypeTooltip(t)}
                                                {...rest}
                                        />
                                        {record.repsDefined() && (<MultivalueCheckbox 
                                            name="warmup"
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            label={`${t('Warmup')}?`} 
                                            className="ml10 mb5"
                                            tooltip={t('template warmup tip')}
                                            {...rest}
                                        />)}
                                        </div>
                                    )}
                                    {record.isReferential() && (
                                        <DefaultSelect 
                                            className="mt20"
                                            name="referenceType"
                                            label={t('Reference type')}
                                            collection={ExerciseTemplate.referenceTypeCol(t)}
                                            {...rest}
                                        />
                                    )}
                                    {record.isPercentMax() && (
                                        <DefaultSelect 
                                            className="mt20"
                                            name="repMaxReps"
                                            label={t('Based on')}
                                            collection={record.repMaxRepsCol(t)}
                                            {...rest}
                                        />
                                    )}
                                    {record.allowDistanceType() && (
                                        <DefaultSelect 
                                            className="mt20"
                                            name="distanceType"
                                            label={t('Distance unit')}
                                            collection={record.selectCol(t,'distanceType')}
                                            {...rest}
                                        />
                                    )}
                                    <DefaultTextArea 
                                        className="mt20"
                                        name="comments"
                                        label={t('Other comments')}
                                        {...rest}
                                    />
                                    {record.showGeneratorParams(user) && (
                                        <React.Fragment>
                                            <DefaultSelect 
                                                className="mt20"
                                                name="genderSpecific"
                                                label={'Gender specific?'}
                                                collection={ExerciseTemplate.GENDER_COL}
                                                {...rest}
                                            />
                                        </React.Fragment>
                                        
                                    )}
                                    {record.allowEprs() && rest.values.exerciseProgressionRequirements.map((values,index) => {
                                        const prog = _.find(bannableProgs,prog => prog.id === values.exerciseProgressionId);

                                        if(prog) {
                                            return (
                                                <DefaultSelect 
                                                    key={prog.id}
                                                    className="mt20 inline-block mr10"
                                                    name={`exerciseProgressionRequirements.${index}.requirement`}
                                                    label={`${prog.name} requirement?`}
                                                    collection={[{ text: 'None', value: ''},{ text: 'Required', value: 1}, { text: 'Banned', value: 0 }]}
                                                    {...rest}
                                                />
                                            )
                                        } else {
                                            return '';
                                        }

                                    })}
                                    {record.allowProgressionScheme() && (
                                        <ProgressionSchemeSection 
                                            record={record}
                                            {...rest}
                                        />
                                    )}
                                </InnerFormContainer>
                            )})}
                            {renderFooter({ loadState: submitState })}
                        </ModalForm>
                    )
                }}
            />
        </React.Fragment>
    )
}

const ExerciseTemplateSettingsLoader = ({ loadProgressionRequirements, exerciseTemplate, user, exerciseProgressionRequirements, renderHeader, ...rest }) => {
    const alreadyLoaded = !_.isBlank(exerciseProgressionRequirements) && exerciseProgressionRequirements.length > 0;

    return (
        <React.Fragment>
            {renderHeader()}
            <Loader 
                load={loadProgressionRequirements.bind(null,exerciseTemplate.getActionParams())}
                preloaded={() => (!user.isAdmin() || exerciseTemplate.isWeighted() || alreadyLoaded)}
                type='padded'
                successComponent={ExerciseTemplateSettingsForm}
                exerciseTemplate={exerciseTemplate}
                user={user}
                exerciseProgressionRequirements={exerciseProgressionRequirements}
                {...rest}
            />
        </React.Fragment>
    )
}

const ExerciseTemplateSettingsContent = ({ id, workoutTemplate, basePath, ...rest }) => {

    const exerciseTemplate = workoutTemplate.findExerciseTemplate(id);

    if(exerciseTemplate) {
        return (<ExerciseTemplateSettingsLoader exerciseTemplate={exerciseTemplate} {...rest} />)
    } else {
        return (<Redirect to={basePath} />)
    }
}

const mapStateToEtSettingsProps = (state,props) => {
    const progressionRequirementsSelector = getEtEprSelector(Number(props.id));

    return {
        user: userRecordSelector(state),
        progressionSchemes: progressionSchemeRecordsSelector(state),
        bannableProgs: bannableProgSelector(state),
        exerciseProgressionRequirements: progressionRequirementsSelector(state)
    }
}

const mapDispatchToEtSettingsProps = dispatch => ({
    updateTemplate: (data) => dispatch(updateExerciseTemplate(data)),
    loadProgressionRequirements: (data) => dispatch(loadProgressionRequirements(data))
})

const ConnectedEtSettingsContent = connect(mapStateToEtSettingsProps,mapDispatchToEtSettingsProps)(ExerciseTemplateSettingsContent);


export const ExerciseTemplateSettingsModal = ({ basePath, workoutTemplate }) => {
    const { t } = useTranslation();
    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={etSettingsModalMatchFor(basePath)} 
            exact 
            title={t('Settings')}
            icon="cog"
            render={({ close, renderHeader, renderFooter, renderScrollable, match: { params: { id }} }) => (
                <ConnectedEtSettingsContent 
                    close={close} 
                    workoutTemplate={workoutTemplate} 
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                    renderScrollable={renderScrollable}
                    id={id}
                    basePath={basePath}
                />
        )} />
    )
}




const RefWeightForm = ({
    renderFooter, 
    renderScrollable, 
    updateSetTemplate, 
    setTemplate, 
    responseData: { setTemplates }
}) => {
    const { t } = useTranslation();
    const exerciseTemplate = setTemplate.exerciseTemplate;
    let wtName = 'weight';
    let wtLabel = 'lbs';
    if(exerciseTemplate.isMetric()) {
        wtName = 'kgWeight';
        wtLabel = 'kgs';
    }
    let options = Object.values(setTemplates).map(st => ({ text: st.referenceDesc, value: st.id }));
    options.unshift({text: '', value: ''});

    return (
        <FormikWrapper 
            initialErrors={{}}
            initialValues={ setTemplate.refWeightFormValues() }
            validate={(values) => setTemplate.update(values,false,t)}
            submit={updateSetTemplate}
            render={({ handleSubmit, submitState, ...rest }) => {

                return (
                    <ModalForm onSubmit={handleSubmit}>
                        {renderScrollable({ children: (
                            <div className="pa10 text-center" id="ref-weight-form">
                                <div className="left-align-block">
                                    {options.length > 1 && (<React.Fragment>
                                        <div className="display-flex" style={ { lineHeight: '40px' } }>
                                        {!setTemplate.isPercentRef() && (<React.Fragment>
                                                <input 
                                                    name={wtName}
                                                    value={_.get(rest.values,wtName)}
                                                    type="number"
                                                    step="any"
                                                    onChange={rest.handleChange}
                                                    onBlur={rest.handleBlur}
                                                    onKeyPress={limitPatternHandler(/[0-9.]+/)}
                                                    className="in-text-input mr5"
                                                />
                                                {t(wtLabel)}
                                                <DefaultSelect 
                                                    className="inline-block ml5"
                                                    name={'offsetType'}
                                                    collection={[{text: t('less than'), value: -1},{ text: t('more than'), value: 1}]}
                                                    {...rest}
                                                />
                                        </React.Fragment>)}
                                        {setTemplate.isPercentRef() && (<React.Fragment>
                                                <input 
                                                    name={'repMaxPercent'}
                                                    value={_.get(rest.values,'repMaxPercent')}
                                                    type="number"
                                                    step="any"
                                                    onChange={rest.handleChange}
                                                    onBlur={rest.handleBlur}
                                                    onKeyPress={limitPatternHandler(/[0-9.]+/)}
                                                    className="in-text-input mr5"
                                                />
                                                {t('percent of')}
                                        </React.Fragment>)}
                                        <DefaultSelect 
                                            className="inline-block ml5"
                                            name='referenceTemplateId'
                                            collection={options}
                                            {...rest}
                                        />
                                    </div>
                                    {exerciseTemplate.repsDefined() && (<div className="mt10">
                                        <MultivalueCheckbox 
                                            name="referenceReps"
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            label={`${t('Copy reps from selected set?')}`} 
                                            {...rest}
                                        />
                                    </div>)}
                                    </React.Fragment>)}
                                    {options.length <= 1 && (
                                        <div className="pa25"><IconNote text={t('no set references tip')} /></div>
                                    )}
                                </div>
                            </div>
                        )})}
                        {options.length > 1 && renderFooter({ loadState: submitState })}
                        {options.length <= 1 && renderFooter({ children: (
                            <Button rounded className="modal-close">{t("Ok")}</Button>
                        )})}
                    </ModalForm>
                )
            }}
        />
    )
}

const RefWeightLoader = ({ user, updateSetTemplate, loadStReferences, setTemplate, renderHeader, ...rest }) => {

    return (
        <React.Fragment>
            {renderHeader()}
            <Loader 
                load={loadStReferences.bind(null,setTemplate.actionParams())}
                preloaded={() => false}
                type='padded'
                successComponent={RefWeightForm}
                setTemplate={setTemplate}
                updateSetTemplate={updateSetTemplate}
                user={user}
                {...rest}
            />
        </React.Fragment>
    )
}

const RefWeightContent = ({ id, workoutTemplate, basePath, ...rest }) => {

    const setTemplate = workoutTemplate.findSetTemplate(id);

    if(setTemplate) {
        return (<RefWeightLoader setTemplate={setTemplate} {...rest} />)
    } else {
        return (<Redirect to={basePath} />)
    }
}

const mapDispatchToRefWeightProps = dispatch => ({
    updateSetTemplate: (data) => dispatch(updateStRefWeight(data)),
    loadStReferences: (data) => dispatch(loadStReferences(data))
})

const ConnectedRefWeightContent = connect(null,mapDispatchToRefWeightProps)(RefWeightContent);


export const ReferenceWeightModal = ({ basePath, workoutTemplate }) => {
    const { t } = useTranslation();
    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={stReferenceWeightModalMatchFor(basePath)} 
            exact 
            title={t('Weight Setup')}
            icon="cog"
            render={({ close, renderHeader, renderFooter, renderScrollable, match: { params: { id }} }) => (
                <ConnectedRefWeightContent 
                    close={close} 
                    workoutTemplate={workoutTemplate} 
                    renderHeader={renderHeader}
                    renderFooter={renderFooter}
                    renderScrollable={renderScrollable}
                    id={id}
                    basePath={basePath}
                />
        )} />
    )
}