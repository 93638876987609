import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import moment from 'moment';
import { allMeasures } from 'lib/body-measurements';
import { AssessmentResult } from './assessment-result';
import { bodyMeasurementsPath, clientBmShortcutPathFor, clientProgressPicsShortcutPathFor, modalPathFor, progressPicsPath, strtstFormPathFor } from 'config/paths';
import { StrengthStandards } from './user';
import progPhotoPlaceholder from 'assets/img/progPhotoPlaceholder.png';
import { viewFormFieldHistorySuffix } from 'partials/TrainerFormModals';

export class FormField extends RecordBase {
    static NAME = 'FormField'
    static ASSOCS = {
        form: { type: 'belongsTo' },
        dataEntry: { type: 'belongsTo', poly: true },
        assessment: { type: 'belongsTo' },
        exercise: { type: 'belongsTo' },
        chatMessages: { type: 'hasMany', inverse: 'responseTo' }
    }

    static ARRAY_FIELDS = ['textValues'];

    static MISC_FIELDS = t => ([
        { text: t('Header'), value: 'header', formTypes: ['intake','assessment','check_in'] },
        { text: t('Text Block'), value: 'text_block', formTypes: ['intake','assessment','check_in'] }
    ])

    static INTAKE_FIELDS = t => ([
        { text: t('Long Text'), value: 'long_text', formTypes: ['intake','assessment','check_in'] },
        { text: t('Short Text'), value: 'short_text', formTypes: ['intake','assessment','check_in'] },
        { text: t('Select Box'), value: 'select', formTypes: ['intake','assessment','check_in'] },
        { text: t('Number Field'), value: 'numeric', formTypes: ['intake','assessment','check_in'] },
        { text: t('Number Slider'), value: 'numeric_slider', formTypes: ['intake','assessment','check_in'] },
        { text: t('Checkboxes'), value: 'check', formTypes: ['intake','assessment','check_in'] },
        { text: t('Phone Number'), value: 'phone', formTypes: ['intake'] },
        { text: t('Signature'), value: 'signature', formTypes: ['intake'] },
    ])

    static ASSESSMENT_FIELDS = t => ([
        { text: t('Assessment'), value: 'assessment', formTypes: ['assessment','check_in'], subtype: 'assessment' },
        { text: t('Strength Test'), value: 'strength_test', formTypes: ['assessment','check_in'], subtype: 'exercise' },
        { text: t('Form Check'), value: 'form_check', formTypes: ['assessment','check_in'], subtype: 'exercise' },
        { text: t('Body Measurement'), value: 'body_measurement', formTypes: ['assessment','check_in'], subtype: 'bodypart' }
    ])

    static CHECK_IN_FIELDS = t => ([
        { text: t('Progress Photo'), value: 'progress_photo', formTypes: ['assessment','check_in'] },
        { text: t('Mood'), value: 'mood', formTypes: ['intake','assessment','check_in'] },
        { text: t('Energy Level'), value: 'energy_level', formTypes: ['intake','assessment','check_in'] },
        { text: t('Stress Level'), value: 'stress_level', formTypes: ['intake','assessment','check_in'] },
        { text: t('Hunger Level'), value: 'hunger_level', formTypes: ['intake','assessment','check_in'] },
        { text: t('Sleep Quality'), value: 'sleep_quality', formTypes: ['intake','assessment','check_in'] }
    ])

    static CHARTABLE_FIELD_TYPES = ['numeric','numeric_slider','mood','energy_level','stress_level','hunger_level','sleep_quality'];

    static FIELD_TYPES = (t,formType) => {

        if(formType === 'assessment') {
            return [
                ...this.MISC_FIELDS(t),
                ...this.ASSESSMENT_FIELDS(t),
                ...this.CHECK_IN_FIELDS(t),
                ...this.INTAKE_FIELDS(t)
            ]
        } else if(formType === 'check_in') {
            return [
                ...this.MISC_FIELDS(t),
                ...this.CHECK_IN_FIELDS(t),
                ...this.INTAKE_FIELDS(t),
                ...this.ASSESSMENT_FIELDS(t)
            ]
        } else {
            return [
                ...this.MISC_FIELDS(t),
                ...this.INTAKE_FIELDS(t),
                ...this.CHECK_IN_FIELDS(t),
                ...this.ASSESSMENT_FIELDS(t)
            ]
        }
    }

    static photoAngles = (t,anyLabel) => ([{ text: (anyLabel === undefined ?  _.capitalize(t('any')) : anyLabel), value: '' }, { text: _.capitalize(t('front')), value: 'front' }, { text: _.capitalize(t('back')), value: 'back' }, { text: _.capitalize(t('side')), value: 'side' }])
    static bodyParts = t => allMeasures.map(bpart => ({ text: _.capitalize(t(bpart)), value: bpart }))

    static defaultPrompt = (t,fieldType) => {
        switch(fieldType) {
            case 'mood':
            case 'energy_level':
            case 'stress_level':
            case 'hunger_level':
            case 'sleep_quality':
                return t(`${fieldType} prompt`);
            case 'phone':
                return t('Phone number');
            case 'signature':
                return t('Signature')
            default:
                return ''
        }
    }

    static defaultNew = (t,form,fieldType) => {
        return new this({ fieldType, form, formId: form.id, prompt: this.defaultPrompt(t,fieldType), options: [], multiple: false, optional: false, minVal: null, maxVal: null });
    }

    assignedDate() {
        return this.form.assignedDate;
    }

    defaultAssessmentResult() {
        const { assessment, exercise, dataEntry } = this;
        return dataEntry || new AssessmentResult({ source: (assessment || exercise) });
    }

    isNew() {
        return _.isBlank(this.id);
    }

    resolvedId() {
        return this.isNew() ? 'new' : this.id;
    }

    editValues() {
        const extraAttrs = { fieldType: this.fieldType };
        return _.parseObjForForm({ formId: this.formId, id: this.id, prompt: this.prompt, optional: this.optional, ...this.typeSpecificEditValues(), ...extraAttrs });
    }

    promptFieldLabel(t) {
        switch(this.fieldType) {
            case 'header':
                return t('Header');
            case 'text_block': 
                return t('Text');
            default:
                return t('Prompt');
        }
    }

    destroyParams() {
        return { formId: this.formId, id: this.id }
    }

    fillableValues(viewer) {
        return _.parseObjForForm({ id: this.id, ...this.typeSpecificFillValues(viewer) });
    }

    typeSpecificFillValues(viewer) {
        const names = this.fillValueNames(viewer);
        const vals = _.getAttrIndif(this,names);
        Object.entries(vals).forEach(([k,v]) => {
            if(this.constructor.ARRAY_FIELDS.includes(k)) {
                vals[k] = _.isBlank(vals[k]) ? [] : vals[k];
            }
        })

        if(this.isReadOnly(viewer)) {
            vals.readOnly = true;
        }

        return vals;
    }

    typeSpecificEditValues() {
        switch(this.fieldType) {
            case 'check':
            case 'select':
                return { options: this.options || [], multiple: this.multiple };
            case 'numeric_slider':
                return { minVal: this.minVal, maxVal: this.maxVal };
            case 'body_measurement':
            case 'progress_photo':
                return { modifier: this.modifier };
            default:
                return {};
        }
    }

    isResultedByTrainer() {
        return (this.fieldType === 'assessment' || this.fieldType === 'form_check' || this.fieldType === 'strength_test' || this.form.isTrainerAdministered());
    }

    fillValueNames(viewer) {
        if(this.fieldType === 'assessment' || this.fieldType === 'form_check') {
            return ['result','resultNum','leftResult','rightResult','leftResultNum','rightResultNum'];
        } else if(this.fieldType === 'progress_photo' || this.fieldType === 'signature') {
            return ['uploadImage','defaultImage'];
        } else if(['header','text_block'].includes(this.fieldType)) {
            return [];
        }

        return [this.fillValueName(viewer)];
    }

    fillValueName(viewer) {
        switch(this.fieldType) {
            case 'check':
            case 'select':
                if(this.multiple) {
                    return 'textValues';
                }
                return 'textValue';
            case 'numeric':
            case 'numeric_slider':
            case 'mood':
            case 'energy_level':
            case 'stress_level':
            case 'hunger_level':
            case 'sleep_quality':
                return 'numericValue';
            case 'assessment':
            case 'form_check':
            case 'strength_test':
            case 'signature':
            case 'progress_photo': 
                return '';
            case 'body_measurement':
                return (viewer.isMetric() ? 'metricSize' : 'imperialSize');
            default:
                return 'textValue';
        }
    }

    isInteractiveField() {
        switch(this.fieldType) {
            case 'signature':
            case 'phone':
            case 'header':
            case 'text_block': 
                return false;
            default:
                return true;
        }
    }

    canBeMessaged(viewer) {
        if(this.form.isAssignedForm() && this.form.userId !== viewer.id && !this.form.isTrainerAdministered()) {
            return this.isInteractiveField();
        }
        return false;
    }

    sendMessageLabel(t) {
        return `'${this.getPrompt(t)}'`;
    }

    sendMessageVals() {
        return { responseToId: this.id, responseToType: 'FormField' };
    }

    isReadOnly(viewer) {
        if(this.isExampleContext()) {
            return true;
        }

        if(this.isResultedByTrainer()) {
            return false;
        }

        if(this.fieldType === 'signature' && !this.form.viewerIsAssignee(viewer)) {
            return true;
        }

        return ['header','text_block'].includes(this.fieldType) || this.form.isUneditable();
    }

    fillableWrapperClasses() {
        if(this.fieldType === 'numeric_slider') {
            return 'mb50';
        }

        if(this.fieldType === 'header') {
            return null;
        }

        if(this.fieldType === 'text_block') {
            return 'mb20';
        }

        return 'mb40';
    }

    validateFillableValues(viewer,values,t) {
        const { id, readOnly, ...vals } = values;

        if(readOnly || this.optional) {
            return {};
        }

        if(this.form.viewerIsAssignee(viewer)) {
            if(['form_check','assessment'].includes(this.fieldType)) {
                if(this.dataEntry && this.dataEntry.hasMuxVideo()) {
                    return {};
                }
                return { test: t('Required') };
            } else if(this.fieldType === 'strength_test') {
                if(this.strengthTestCompleted()) {
                    return {}
                }
                return { test: t('Required')  };
            } else if(['progress_photo','signature'].includes(this.fieldType)) {
                if(this.hasImage()) {
                    return {}
                }
                return { uploadImage: t('Required') }
            }
        } else if(['assessment','form_check','strength_test','progress_photo','signature'].includes(this.fieldType)) {
            return {};
        }

        const errs = {}
        Object.entries(vals).forEach(([k,v]) => {
            if(Array.isArray(v)) {
                if(_.noBlanks(v).length === 0) {
                    errs[k] = t('Required');
                }
            } else if(_.isBlank(v)) {
                errs[k] = t('Required');
            }
        })

        return errs;
    }

    isActive() {
        return !this.inactive;
    }

    textBlockParagraphs(viewer) {
        let finalPrompt = (this.prompt || '');
        const masterAccount = viewer.getMasterAccount();

        if(masterAccount && !_.isBlank(masterAccount.businessName)) {
            finalPrompt = finalPrompt.replaceAll('{{business_name}}',masterAccount.businessName);
        }

        if(this.form.isAssignedForm()) {
            finalPrompt = finalPrompt.replaceAll('{{client_name}}',this.form.user.fullName());
        }

        return finalPrompt.split(/\n+/);
    }

    moodLabels(t) {
        switch(this.fieldType) {
            case 'mood':
                return [t('Bad'),t('Good')]
            case 'energy_level':
                return [t('Low'),t('High')]
            case 'stress_level':
                return [t('High'),t('Low')]
            case 'hunger_level':
                return [t('Hungry'),t('Satisfied')]
            case 'sleep_quality':
                return [t('Poor'),t('Good')]
            default:
                return []
        }
    }

    signDate() {
        const mom = _.isBlank(this.signatureDate) ? moment() : moment(this.signatureDate);
        return mom.format('MMMM Do, YYYY');
    }

    signerName(t) {
        if(this.form.user) {
            return this.form.user.fullName()
        }

        return t('Client Name')
    }

    signatureCompleted() {
        return this.fieldType === 'signature' && !_.isBlank(this.signatureDate) && this.hasImage();
    }

    isExampleContext() {
        return _.isBlank(this.form) || _.isBlank(this.form.user);
    }

    repMaxStr(viewer,t) {
        return `${this.reps} reps with ${this.unitWeightStr(viewer,t)}`; 
    }

    isMetric(viewer) {
        return viewer.isMetric(this.exercise);
    }

    unitWeight(viewer) {
        if(this.isMetric(viewer)) {
            return _.roundToF(this.weight/2.2,viewer.mincrement(this.exercise)/2.2);
        } else {
            return this.weight;
        }
    }

    unitWeightStr(viewer,t) {
        return `${this.unitWeight(viewer)} ${this.weightLabel(viewer,t)}`
    }

    baseWeight(viewer) {
        if(this.form.isAssignedForm()) {
            const weight = this.form.user.currentWeight || 0;
            if(this.isMetric(viewer)) {
                return weight/2.2;
            }

            return weight;
        }

        return 0;
    }

    estOneRmStr(viewer,t) {
        const oneRm = StrengthStandards.oneRepMax(this.unitWeight(viewer),this.reps,this.baseWeight(viewer));

        return `${oneRm} ${this.weightLabel(viewer,t)}`;
    }

    weightLabel(viewer,t) {
        let units = t('lbs');
        if(this.isMetric(viewer)) {
            units = t('kgs');
        }

        if(this.exercise.needsDumbellInfo()) {
            return t("units per dumbbell",{ units })
        }

        return units;
    }

    strengthTestCompleted() {
        return !_.isBlank(this.weight) && !_.isBlank(this.reps);
    }

    defaultImage() {
        return this.image && this.image.url;
    }

    uploadImage() {
        return '';
    }

    hasImage() {
        return this.image && this.image.url && !this.image.url.includes('NoImage');
    }

    getPromptPlaceholder(t,modifier) {
        if(this.fieldType === 'progress_photo') {
            if(_.isBlank(modifier)) {
                return t("Take or upload a progress photo")
            } else {
                return t("Take or upload a progress photo of", { angle: t(modifier) })
            }
        } else if(this.fieldType === 'body_measurement') {
            return t('body measurement', { bodypart: _.capitalize(t(modifier)) });
        }

        return '';
    }

    getPrompt(t) {
        switch(this.fieldType) {
            case 'assessment':
                return this.assessment.name;
            case 'form_check':
                return `${this.exercise.name} ${t('form check')}`;
            case 'strength_test':
                return `${this.exercise.name} ${t('strength test')}`;
            default:
                return _.isBlank(this.prompt) ? this.getPromptPlaceholder(t,this.modifier) : this.prompt;
        }
    }

    strtstPath() {
        if(this.form.isAssignedForm()) {
            return strtstFormPathFor(this.form.user.id,this.form.id,this.exercise.id,this.assignedDate(),0);
        }

        return '';
    }

    chatMsgReplyImg() {
        switch(this.fieldType) {
            case 'assessment':
            case 'form_check':
                if(this.dataEntry && this.dataEntry.hasImage()) {
                    return this.dataEntry.resolvedThumbUrl();
                } else {
                    return (this.assessment || this.exercise).resolvedThumbUrl();
                }
            case 'strength_test':
                return this.exercise.resolvedThumbUrl();
            case 'progress_photo':
                if(this.dataEntry) {
                    return this.dataEntry.resolvedThumbUrl();
                }
                return progPhotoPlaceholder;
            default:
                return null;
        }
    }

    chatMsgReplyIcon() {
        return null;
    }

    chatMsgReplyTitle(t) {
        return this.getPrompt(t);
    }

    chatMsgReplySubtitle(t) {
        return this.form.assignedDateStr();
    }

    chatMsgClickLink(viewer) {
        return this.form.chatMsgClickLink(viewer);
    }

    viewHistoryPath(viewer,siac,pathname) {
        switch(this.fieldType) {
            case 'progress_photo':
                if(siac || this.form.viewerIsAssignee(viewer)) {
                    return progressPicsPath;
                }
                return clientProgressPicsShortcutPathFor(this.form.user.id);
            case 'body_measurement':
                if(siac || this.form.viewerIsAssignee(viewer)) {
                    return bodyMeasurementsPath;
                }
                return clientBmShortcutPathFor(this.form.user.id)
            default:
                return modalPathFor(viewFormFieldHistorySuffix,pathname, { ffId: this.id });
        }
    }

    historyIsChart() {
        return this.constructor.CHARTABLE_FIELD_TYPES.includes(this.fieldType);
    }
}

registerInflection('formField','formFields',FormField);