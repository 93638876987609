import React from 'react';
import classnames from 'classnames';

export const ChartPlaceholder = ({ message, className }) => {
    const classNames = classnames("text-center pt50 pb50",{[className]: className});
    return (
        <div className={classNames}>{message}</div>
    )
}

class ChartErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      // Update state so the next render will show the fallback UI.
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
      // You can also log the error to an error reporting service
    }
  
    render() {
        const { errorMsg } = this.props;
      if (this.state.hasError) {
        // You can render any custom fallback UI
        return <ChartPlaceholder className="red-text" message={errorMsg}/>;
      }
  
      return this.props.children; 
    }
}

export default ChartErrorBoundary;