import { FullFormModal, ScrollableFormModal, SimpleContentModal, TabbedFormModal } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { BasicModalListingBox, DefaultTextArea, FullInput } from 'components/Form';
import TextInput from 'components/TextInput';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { activateMessaging, createChat, updateChat } from 'redux/actions';
import { ClientSelectListing, UserIcon } from './Utilities';
import { chatUserSelector } from 'redux/selectors';
import { useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlightEmphasisIconNote } from 'components/Typography';
import Button from 'components/Button';

const mapStateToUserProps = state => ({
    user: chatUserSelector(state)
})

export const turnOffChatModalSuffix = '/deactivate';

let TurnOffChatModal = ({ activate }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(turnOffChatModalSuffix,pathname);

    return (
        <SimpleContentModal 
            path={path}
            title={t("Are you sure?")}
            buttonRender={({ close }) => {
                return (
                    <Button color="red" rounded onClick={() => { activate({ messagingOn: false }); close() }}>
                        <FontAwesomeIcon icon={"ban"} />
                        {t('Turn Off Messaging')}
                    </Button>
                )
            }}
            render={() => {

                return (
                    <div className="pa20 text-center">
                        <SlightEmphasisIconNote text={t('turn off msg tip')} />
                    </div>
                )
            }}
        />
    )
}

let ChatSettingsModal = ({ user, submit, title, icon, submitText, suffix, initial }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(suffix,pathname);

    return (
        <FullFormModal 
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={icon}
            title={title}
            formProps={ () => {            
                return ({
                    initialValues: { messagingOn: true, welcomeMessage: _.isBlank(user.welcomeMessage) ? 'Hey {{name}}, you can message me here if you have any questions about your plan' : user.welcomeMessage },
                    submit: submit,
                    validationSchema: Yup.object().shape({
                        welcomeMessage: Yup.string().required()
                    })
                })
            }}
            footerProps={{ id: 'activate-chat-btn', saveLabel: submitText, saveIcon: 'check' }}
            render={({ modalProps: { match: { params: { chatId } } }, ...formikProps }) => {
                
                return (
                    <div className="pa20">
                        <DefaultTextArea 
                            className="mt10 mb10"
                            name="welcomeMessage"
                            label={t('Client Welcome Message')}
                            inProps={{
                                maxLength: 2500,
                                placeholder: t('Required'),
                                id: "msg-field"
                            }}
                            {...formikProps}
                        />
                        <SlightEmphasisIconNote text={`${initial ? `${t('welcome msg tip')} ` : ''}${t('name interp tip')}`} />
                    </div>                
                )
            }}
        />
    )
}

export const activateChatModalSuffix = '/activate'

let ActivateChatModal = ({ user, activate }) => {
    const { t } = useTranslation();

    return (
        <ChatSettingsModal 
            user={user}
            submit={activate}
            title={t('Activate Messaging')}
            icon={'comments-alt'}
            submitText={t('Activate')}
            suffix={activateChatModalSuffix}
            initial
        />
    )
}

export const updateChatSettingsModalSuffix = '/settings'

let UpdateChatSettingsModal = ({ user, activate }) => {
    const { t } = useTranslation();

    return (
        <ChatSettingsModal 
            user={user}
            submit={activate}
            title={t('Settings')}
            icon={'cog'}
            submitText={t('Save')}
            suffix={updateChatSettingsModalSuffix}
        />
    )
}

const mapDispatchToActivateProps = dispatch => ({
    activate: data => dispatch(activateMessaging(data))
})

TurnOffChatModal = connect(mapStateToUserProps,mapDispatchToActivateProps)(TurnOffChatModal)
ActivateChatModal = connect(mapStateToUserProps,mapDispatchToActivateProps)(ActivateChatModal)
UpdateChatSettingsModal = connect(mapStateToUserProps,mapDispatchToActivateProps)(UpdateChatSettingsModal)

export const chatInfoModalSuffix = '/chat_info/:chatId'

let ChatInfoModal = ({ user }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(chatInfoModalSuffix,pathname);

    return (
        <ScrollableFormModal
            fullWidth 
            noOverflow 
            limitWidth 
            path={path} 
            exact 
            title={t('Group Info')}
            icon="info-circle"
            render={({ close, closeAndGo, renderHeader, renderFooter, renderScrollable, match: { params: { chatId } } }) => {
                const chat = user.chatById(chatId);
                const members = chat.members();

                return (
                    <React.Fragment>
                        {renderHeader()}
                        {renderScrollable({
                            children: (
                                <div className="pa20">
                                    <div className="modal-list-header">
                                        <FullInput
                                            label={t("Group Name")}
                                            name="name"
                                            component={TextInput}
                                            formikProps={{ values: { name: chat.displayName(user,t) } }}
                                            inProps={{ disabled: true }}
                                        />
                
                                        {members.map(member => (
                                            <BasicModalListingBox
                                                icon={<UserIcon user={member} />}
                                                title={member.fullName()}
                                            />
                                        ))}
                                    </div>
                                </div>
                            )
                        })}
                        {renderFooter({ children: (
                            <Button color="primary" rounded className="modal-close">
                                <span>{t('Ok')}</span>
                            </Button>
                        )})}
                    </React.Fragment>
                )
            }} 
        />
    )
}

ChatInfoModal = connect(mapStateToUserProps,null)(ChatInfoModal);

const RecipientSelect = ({ trainer, formikProps, single, ...rest }) => {
    const { t } = useTranslation();

    return (
        <div className="text-center pt20 pb20 pr10 pl10">
            {!single && (
                <div className="modal-list-header">
                    <FullInput
                        label={t("Group Name")}
                        name="name"
                        component={TextInput}
                        formikProps={formikProps}
                    />
                </div>
            )}
            <ClientSelectListing 
                isModal
                containerClass={"modal-list-header"}
                trainer={trainer}
                formikProps={formikProps}
                listingProps={{ 
                    name: single ? 'clientId' : 'clientIds',
                    single,
                    noCheck: single
                }}
                excludeFilters={['trainerId','status']}
                numName={single ? null : 'clientIds'}
                context="new-chat"
                {...rest}
            />
        </div>
    )
}

export const editChatModalSuffix = '/edit_chat/:chatId'

let EditChatModal = ({ user, updateChat }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(editChatModalSuffix,pathname);

    return (
        <FullFormModal 
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={['far','edit']}
            title={t("Edit Group")}
            formProps={ ({ modalProps: { match: { params: { chatId } } } }) => {
                const chat = user.chatById(chatId);
            
                return ({
                    initialValues: { chatId: chat.id, name: chat.name, clientIds: chat.otherMembers(user).map(u => u.id) },
                    submit: updateChat,
                    validationSchema: Yup.object().shape({
                        name: Yup.string().required(),
                        clientIds: Yup.array().compact().required().min(1)
                    })
                })
            }}
            footerProps={{ id: 'save-chat-btn' }}
            render={({ modalProps: { match: { params: { chatId } } }, ...formikProps }) => {
                
                return (
                    <RecipientSelect trainer={user} formikProps={formikProps} selectSort />
                )
            }}
        />
    )
}

const mapStateToEditProps = state => ({
    user: chatUserSelector(state)
})

const mapDispatchToEditProps = dispatch => ({
    updateChat: data => dispatch(updateChat(data))
})

EditChatModal = connect(mapStateToEditProps,mapDispatchToEditProps)(EditChatModal);


export const newChatModalSuffix = '/new_message';

const NewChatModal = ({ trainer, hasSidebar, selectChat, createChat }) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const path = modalPathFor(newChatModalSuffix,pathname);
    const { t } = useTranslation();
    const successCallback = ({ data }) => {
        const chat = Object.values(data.chats)[0];
        history.go(-1);
        setTimeout(() => selectChat(chat),1);
    }

    const sharedTabProps = { disableInvalid: true }
    const tabs = [{
        title: _.capitalize(t('client')),
        tabRender: ({ modalProps, loaderProps, ...formikProps }) => (<RecipientSelect trainer={trainer} formikProps={formikProps} single key="single" />),
        formProps: {
            initialValues: { name: '', clientId: '', chatType: 'single' },
            valuesFilter: values => {
                const { clientId, ...rest } = values;
                return { clientIds: [clientId], ...rest };
            },
            validationSchema: Yup.object().shape({
                clientId: Yup.number().required()
            }),
            submit: data => createChat(data),
            autoSubmit: true,
            successCallback
        },
        noFooter: true,
        ...sharedTabProps
    },{
        title: t("Group"),
        tabRender: ({ modalProps, loaderProps, ...formikProps }) => (<RecipientSelect trainer={trainer} formikProps={formikProps} key="group" />),
        formProps: {
            initialValues: { name: '', clientIds: [''], chatType: 'group' },
            initialErrors: { name: 'required' },
            validationSchema: Yup.object().shape({
                name: Yup.string().required(),
                clientIds: Yup.array().compact().required().min(1)
            }),
            submit: data => createChat(data),
            successCallback
        },
        footerProps: { saveIcon: 'plus', saveLabel: t('Create Group'), dontCloseOnDone: true },
        ...sharedTabProps
    }]
    return (
        <TabbedFormModal 
            fullWidth
            path={path} 
            scrollable 
            exact 
            icon={'plus'}
            title={t('New Message')}
            tabs={tabs}
        />
    )

}

const mapDispatchToProps = dispatch => ({
    createChat: data => dispatch(createChat(data))
})

export { EditChatModal, ChatInfoModal, ActivateChatModal, UpdateChatSettingsModal, TurnOffChatModal }
export default connect(null,mapDispatchToProps)(NewChatModal);