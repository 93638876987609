import { RecordBase, registerInflection } from  'lib/record-base';
import * as _ from 'lib/utilities';
import moment from 'moment';
import NoImage from 'assets/img/NoImageExercise.png';
import { assignRoutineToClientsPathFor, editRoutineStandlonePathFor } from 'config/paths';

export class WorkoutRoutine extends RecordBase {
    static NAME = 'WorkoutRoutine'
    static ASSOCS = {
        user: { type: 'hasOne', inverse: 'assignedRoutine', fk: 'assignedRoutineId' },
        workoutTemplates: { type: 'hasMany' },
        routineCycles: { type: 'hasMany', sortAttr: 'routineOrder' },
        owner: { type: 'belongsTo', inverse: 'workoutRoutines', tableName: 'users' },
        source: { type: 'belongsTo', inverse: 'userVersions', tableName: 'workoutRoutines' },
        userVersions: { type: 'hasMany', tableName: 'workoutRoutines', inverse: 'source', fk: 'sourceId' }
    }
    static EXPERIENCE_LEVELS = ['beginner','intermediate','advanced'];
    static GENDERS = ['Both','Male','Female'];
    static GYM = 2;
    static GYM_BW = 1;
    static BW = 0;
    static MPO_ID = 16174 //going to regret this

    static equipmentTypesCol = (t) => [{ text: t("Weighted - barbell focused"), value: this.GYM }, { text: t("Bodyweight - minimal equipment"), value: this.GYM_BW }, { text: t("Bodyweight - no equipment"), value: this.BW } ]

    isLoaded() {
        return this.routineCycles && this.routineCycles.length > 0;
    }

    isRoutine() {
        return true;
    }

    isMulticycle() {
        return this.multicycle;
    }

    isGenerator() {
        return this.generator;
    }

    workoutCycles() {
        return this.cycles(0);
    }

    cardioCycles() {
        return this.cycles(1);
    }

    cycles(type) {
        const cycles =  _.filter(this.routineCycles,cycle => cycle.subType === type);
        if(this.isMulticycle()) {
            return cycles;
        }
        return [cycles[0]];
    }

    formValues(admin) {
        let attrs = ['id','name','description','multicycle','repeating'];
        if(admin) {
            attrs = attrs.concat(['published','sourceUrl','experienceLevels','equipmentType','includesCardio','genders','focus','generator','addCardio']);
            attrs.experienceLevels = attrs.experienceLevels || [];
        }
        return _.parseObjForForm(_.pick(this,attrs));
    }

    hasUserChanges() {
        return this.userChanges;
    }

    isAssignedTo(user) {
        return (this.user && user && this.user.id === user.id)
    }

    hasSource() {
        return !_.isBlank(this.sourceId);
    }

    isUserVersion() {
        return this.hasSource() || !_.isBlank(this.originalSourceId);
    }

    canRestoreDefaults(user) {
        return this.hasUserChanges() && this.isAssignedTo(user) && this.hasSource();
    }

    canRestartRoutine(user) {
        if(!this.user) {
            return false;
        }
        const createdAtCutoff = moment(this.user.createdAt).add(3,'days');
        return this.isAssignedTo(user) && createdAtCutoff.isSameOrBefore(moment());
    }

    findWorkoutTemplate(id) {
        return _.find(this.workoutTemplates,workoutTemplate => (workoutTemplate.id === Number(id)));
    }

    showUserExerciseGroups() {
        return this.owner && this.owner.isTrainer();
    }

    exerciseGroups() {
        const routineLevel = _.sortBy(_.filter(this.workoutTemplates,wt => wt.isGroup()),'name');
        const userLevel = this.showUserExerciseGroups() ? _.sortBy([ ...this.owner.workoutTemplates ],'name') : [];
        return _.uniqBy([ ...userLevel, ...routineLevel ],'id');
    }

    resolvedName(t) {
        return _.fallbackText(this.name,t('Untitled'))
    }

    resolvedImageUrl() {
        if(!_.isBlank(this.imageName)) {
            return _.publicUrl() + `img/${this.imageName}`
        } else {
            return NoImage;
        }
    }

    resolvedDescription(t) {
        if(_.isBlank(this.description)) {
            return t("This record doesn't have a description", { record_name: t('routine')})
        }
        return this.description;
    }

    showCycleWeeks(type) {
        return !this.repeating || this.cycles(type).length > 1;
    }

    isActive() {
        return !this.inactive && !this.isUserVersion();
    }

    assignedUsers() {
        return _.filter(_.compact(this.userVersions.map(wrt => wrt.user)),user => !user.isInactive());
    }

    hasAssignedUsers() {
        return this.assignedUsers().length > 0;
    }

    isValidForFilter() {
        return (this.isActive() || this.hasAssignedUsers());
    }

    editPath() {
        return editRoutineStandlonePathFor(this.id);
    }

    assignToClientsPath() {
        return assignRoutineToClientsPathFor(this.id);
    }
}

registerInflection('workoutRoutine','workoutRoutines',WorkoutRoutine);