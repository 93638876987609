import { RecordBase, registerInflection } from 'lib/record-base';
import moment from 'moment';
import * as _ from 'lib/utilities';

export class SchedulableSetting extends RecordBase {
    static NAME = 'SchedulableSetting';
    static ASSOCS = {
        source: { type: 'belongsTo', poly: true },
        user: { type: 'belongsTo' }
    }

    static typeOptions = (t,simple,isForDefaults,freqType,frequency) => (_.compact([
        simple ? null : { value: SchedulableSetting.ONCE, text: (isForDefaults ? t('Assign once for client intake') : t('Assign once on specific date') ) }, 
        simple ? null : { value: SchedulableSetting.WEEKLY, text: t('Every X weeks', { weeks: ((!_.isBlank(frequency) && freqType === SchedulableSetting.WEEKLY) ? frequency : 'X') }) }, 
        { value: SchedulableSetting.WEEKDAYS, text: t('Specific days each week') }, 
        { value: SchedulableSetting.MODULO, text: t('Every X days', { days: ((!_.isBlank(frequency) && freqType === SchedulableSetting.MODULO) ? frequency : 'X') }) }, 
        { value: SchedulableSetting.WORKOUT_DAYS, text: t('Every workout day') }
    ]))

    static WEEKDAYS = 0;
    static MODULO = 1;
    static WORKOUT_DAYS = 2;
    static WEEKLY = 3;
    static ONCE = 4;

    static SELECTED = 2;
    static INDETERMINATE = 1;
    static UNSELECTED = 0;

    static validate = (isForDefaults,t) => settings => {
        const errors = {};

        if(_.isBlank(settings.startDate) && !isForDefaults) {
            errors.startDate = t('Required')
        }

        if(settings.freqType === SchedulableSetting.MODULO || settings.freqType === SchedulableSetting.WEEKLY) {
            if(_.isBlank(settings.frequency) || settings.frequency < 1) {
                errors.frequency = t("must be at least", { count: 1 });
            }
        } 
        
        if(settings.freqType === SchedulableSetting.WEEKDAYS || settings.freqType === SchedulableSetting.WEEKLY) {
            if(_.isBlank(settings.weekdays) || _.noBlanks(settings.weekdays).length === 0) {
                errors.weekdays = t('Pick at least', { count: 1 });
            }
        }

        return errors;
    }

    endMoment() {
        return this.endDate ? moment(this.endDate) : moment().add(9999,'days');
    }

    isCurrent() {
        return moment().isSameOrBefore(this.endMoment());
    }

    isHabit() {
        return this.sourceType === 'Habit';
    }

    isSelfAdministered() {
        return !!this.selfAdmin;
    }

    editDefaultsValues() {
        return _.parseObjForForm({ ..._.pick(this,['freqType','frequency']), selfAdmin: this.isSelfAdministered(), weekdays: this.weekdays || [] });
    }

}

registerInflection('schedulableSetting','schedulableSettings',SchedulableSetting);
