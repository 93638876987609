import { videoImgConcern } from './video-img-concern';
import { modalPathFor } from 'config/paths';
import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { editAssessmentModalSuffix} from 'partials/AssessmentModals';

export class Assessment extends RecordBase {
    static NAME = 'Assessment'
    static ASSOCS = {
        owner: { type: 'belongsTo', tableName: 'users', inverse: 'ownedAssessments' },
        formFields: { type: 'hasMany' },
        assessmentResults: { type: 'hasMany', inverse: 'source' }
    }
    static resultTypes = t => ([{ text: t('Free text'), value: 'free_text'}, { text: t('Numeric'), value: 'numeric' }])

    resolvedName(t) {
        return _.fallbackText(this.name,t('Untitled'))
    }

    editPath(pathname) {
        return modalPathFor(editAssessmentModalSuffix,pathname, { id: this.id });
    }

    viewPath(pathname) {
        return null;
    }

    assignToClientsPath() {
        return null;
    }

    isTeam() {
        return this.teamLevel;
    }

    editValues() {
        return _.parseObjForForm(_.pick(this,['id','name','teamLevel','resultType','resultUnits','bilateral','videoUrl','instructions']));
    }

    isFreeText() {
        return _.isBlank(this.resultType) || this.resultType === 'free_text';
    }

    isNumeric() {
        return this.resultType === 'numeric';
    }

    isBilateral() {
        return this.bilateral;
    }

    isDeleted() {
        return this.inactive;
    }

    isVisible(user,filters={}) {
        if(this.isDeleted()) {
            return false;
        }

        if(this.ownerId === user.id) {
            return true;
        }

        return this.isPublished();
    }

    instructionEntries(t) {
        if(_.isBlank(this.instructions)) {
            return [t("assessment cli instructions")];
        }

        return this.instructions.split(/\r\n/);
    }

    getTagList(viewer) {
        return viewer.recordRelationshipTag(this);
    }
}

Object.assign(Assessment.prototype,videoImgConcern);
registerInflection('assessment','assessments',Assessment);