import React from 'react';
import { isOldApp } from 'lib/utilities';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';

const WebLogin = ({ onSuccess, onFailure, render }) => {
    const login = useGoogleLogin({
        flow: 'implicit',
        onSuccess,
        onError: onFailure,
        onNonOAuthError: error => onFailure({ error })
    })

    return (
        render({onClick: login, disabled: false})
    )
}

class GoogleLogin extends React.Component {

    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
        this.preSuccessCallback = this.preSuccessCallback.bind(this)
        this.preFailCallback = this.preFailCallback.bind(this)
    }

    render() {
        if(window.cordova || isOldApp()) {
            return (
                <React.Fragment>
                    {this.props.render({onClick: this.onClick, disabled: false})}
                </React.Fragment>
            )
        } else {
            return (
                <GoogleOAuthProvider clientId={this.props.clientId}>
                    <WebLogin {...this.props} />
                </GoogleOAuthProvider>
            )
        }
    }

    preSuccessCallback(userData) {
        const { onSuccess } = this.props;
        const { userId, ...rest } = userData
        onSuccess({ googleId: userId, ...rest })
    }

    preFailCallback(error) {
        const { onFailure } = this.props;
        onFailure({error: true, details: error})
    }

    onClick() {
        if(isOldApp()) {
            this.preFailCallback('Please upgrade to the latest version of Strongr Fastr to use Google sign in')
        } else {
            let { clientId } = this.props
            window.plugins.googleplus.login({webClientId: clientId},this.preSuccessCallback,this.preFailCallback)
        }
    }
}

export default GoogleLogin;