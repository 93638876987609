import { RecordBase, registerInflection } from 'lib/record-base';
import { videoImgConcern } from './video-img-concern';

export class AssessmentResult extends RecordBase {
    static NAME = 'AssessmentResult'
    static ASSOCS = {
        formField: { type: 'hasOne', inverse: 'dataEntry' },
        source: { type: 'belongsTo', poly: true }
    }

    isFormCheck() {
        return this.source && this.source.constructor.NAME === 'Exercise';
    }

    isAssessment() {
        return !this.source || this.source.constructor.NAME === 'Assessment';
    }

    isBilateral() {
        if(this.isAssessment()) {
            return this.source.isBilateral();
        }

        return false;
    }

    assessmentName(t) {
        if(this.isAssessment()) {
            return this.source.name;
        }

        return `${this.source.name} ${t('form check')}`;
    }

    retakeButtonText(t) {
        if(this.isAssessment(t)) {
            return t('Retake the Assessment');
        }

        return t("Resubmit Form Check");
    }

    takeButtonText(t) {
        if(this.hasMuxVideo()) {
            return this.retakeButtonText(t);
        }

        if(this.isAssessment(t)) {
            return t('Take the Assessment');
        }

        return t("Submit Form Check");
    }

    isFreeText() {
        if(this.isAssessment()) {
            return this.source.isFreeText();
        }

        return true;
    }

    isNumeric() {
        if(this.isAssessment()) {
            return this.source.isNumeric();
        }

        return false;
    }

}

Object.assign(AssessmentResult.prototype,videoImgConcern);
registerInflection('assessmentResult','assessmentResults',AssessmentResult);