import React from 'react';
import WebLogin from 'react-apple-login';
import { isOldIosApp, isIOS, isNumeric } from 'lib/utilities';
import { withRouter } from 'react-router-dom';

class AppleLogin extends React.Component {

    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
        this.preSuccessCallback = this.preSuccessCallback.bind(this)
        this.preFailCallback = this.preFailCallback.bind(this)
        this.state = { isProcessing: false }
    }

    componentDidMount() {
        const { location: { search }, didLoad } = this.props;
        if(didLoad) {
            return;
        }
        const paramTokens = search && search.replace(/^\?/,'').split('&');
        if(paramTokens && paramTokens.length > 0) {
            const { callback } = this.props;
            let data = {};
            paramTokens.map(token => token.split('=')).forEach(([key,val]) => {
                if(key === 'id_token') {
                    data.authorization = { code: 'noop', id_token: val };
                } else if(key === 'first_name') {
                    data.user = { name: { firstName: val }}
                }
            })
            callback(data);
        }
    }

    render() {
        const { didLoad, location, history, match, ...rest } = this.props;
        if(this.useNativeSignIn()) {
            return (
                <React.Fragment>
                    {this.props.render({onClick: this.onClick, isProcessing: this.state.isProcessing, disabled: false})}
                </React.Fragment>
            )
        } else {
            return (
                <WebLogin {...rest} />
            )
        }
    }

    useNativeSignIn() {
        return (isIOS() || isOldIosApp());
    }

    isIos13OrGreater() {
        if(window.device) {
            const version = (window.device.version || '12').split('.')[0];
            if(isNumeric(version) && Number(version) >= 13) {
                return true;
            }
        }
        return false;
    }

    preSuccessCallback({ authorizationCode, email, identityToken, fullName={} }) {
        const { givenName: firstName } = fullName;
        this.finalCallback({ authorization: { code: authorizationCode, id_token: identityToken }, user: { email, name: { firstName }} })
    }

    preFailCallback({ error, localizedDescription }) {
        this.finalCallback({error: true, details: `${error}: ${localizedDescription}` })
    }

    finalCallback = (data) => {
        const { callback } = this.props;
        this.setState({isProcessing: false});
        callback(data);
    }

    onClick() {
        if(isOldIosApp()) {
            this.preFailCallback('Please upgrade to the latest version of Strongr Fastr to use Apple sign in')
        } else if(!this.isIos13OrGreater()) {
            this.preFailCallback('Apple sign in requires iOS 13 or higher')
        } else {
            this.setState({isProcessing: true})
            window.cordova.plugins.SignInWithApple.signin({ requestedScopes: [0, 1] },this.preSuccessCallback,this.preFailCallback)
        }
    }
}

export default withRouter(AppleLogin);