import React, { useState } from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation, useTranslation } from 'react-i18next';
import { workoutPreviewMatch, workoutLogMatch, strengthTestPathFor, 
     workoutDoMatch, swapExerciseSpecMatch, strengthTestBaseFor, allMainMatches, strtstFormBaseFor, strtstFormPathFor, viewAssignedFormMatch, editTrainerFormMatch, viewClientAssignedFormMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { connect } from 'react-redux';
import { standaloneStartWorkout, loadStrengthTest, submitStrengthTestSet, submitStrengthTest, initFormFieldDataEntry, submitFormStrengthTest } from 'redux/actions';
import { ExerciseInfoModal } from 'partials/ExerciseModals';
import withWorkout from 'partials/RoutineStateHOC';
import { dateFormat } from 'config/settings';
import { useLocation, useHistory } from 'react-router-dom';
import { Redirect } from "components/Routing";
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import { getStrengthTestSelector, userWithFormsSelector } from 'redux/selectors';
import { FixedWorkoutNum, InstructionBox } from 'partials/WorkoutInputs';
import { ExerciseVideo } from 'partials/ExerciseImage';
import * as _ from 'lib/utilities';
import Button from 'components/Button';
import moment from 'moment';
import loadingContainer, { Loader, LoadingContainerParent, PDLoading } from 'components/LoadingHOC';
import { ExerciseInfoMenu } from 'partials/ExerciseModals';
import { CountdownTimer } from 'partials/ConnectedTimers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { buildTransitionMap } from 'views/WorkoutDo';
import { resolvedHomePath } from 'redux/helpers';
import { goWrapper } from 'redux/helpers';
import { RatingButtons } from 'components/RatingButtons';
import { loadTrainerForm } from 'lib/api';

const basePathFor = ({ spec,clientId,formId,exerciseId,date }) => {
    if(spec) {
        return strengthTestBaseFor(spec.id,date);
    }

    return strtstFormBaseFor(clientId,formId,exerciseId,date);
}

const pathFor = ({ spec,clientId,formId,exerciseId,date,setIndex,suffix }) => {
    if(spec) {
        return strengthTestPathFor(spec.id,date,setIndex,suffix);
    }

    return strtstFormPathFor(clientId,formId,exerciseId,date,setIndex,suffix);
}

const allSetIndices = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

const transitionMap = {
    rules: [
        [[...allMainMatches,workoutPreviewMatch,workoutLogMatch,workoutDoMatch,swapExerciseSpecMatch,viewAssignedFormMatch,viewClientAssignedFormMatch,editTrainerFormMatch],transitions.slideOut],
    ]
};

const TestResultLC = loadingContainer({
    'SUCCESS': PDLoading
}, { type: 'padded' });

const RestScreen = ({ urlParams, set, setIndex }) => {
    const nextSet = set.strengthTest.getNextSet(setIndex+1);
    const history = useHistory();
    const { t } = useTranslation();
    const nextPath = pathFor({ ...urlParams, setIndex: setIndex+1 });

    return (
        <CountdownTimer seconds={set.restTime()} autoStart onFinish={() => (history.replace(nextPath))} render={({ display, status }) => {
    
            return (
                <div className="text-center">
                    <div className="wo-wt-ex-title rest-title">{t('Rest')}</div>
                    <div className="wo-wt-ex-num">{display}</div>
                    <div className="mt20">
                        <Button onClick={() => history.replace(nextPath)} color="grey" outlined rounded id="skip-rest-btn">
                            <FontAwesomeIcon icon="step-forward"></FontAwesomeIcon>
                            {t('Skip rest')}
                        </Button>
                    </div>
                    <div className="wo-wt-ex-subtitle rest mt20">
                        {t('Next up')} {nextSet.reps} {t('Reps')}{` @${nextSet.unitWeight()} ${nextSet.weightLabel(t)}`}
                    </div>
                </div>
            )
        }}/>
    );
}

export const StrengthTestPrompt = ({ handlerCreator, reps, isometric }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="wo-wt-ex-title">{t('How hard was it?')}</div>
            <div>
                <Button color="white" onClick={handlerCreator(5)} id="set-failed-btn">
                    {isometric ? t("Couldn't hold for time", { time: _.stopwatchFormat(reps) } ) : t("Couldn't do X reps", { reps } )}
                </Button>
            </div>
            <RatingButtons easyLabel={t('Very Easy')} hardLabel={t('Very Hard')} handlerCreator={handlerCreator} setIds />
        </React.Fragment>
    )
}

const setDoneHandlerCreator = (urlParams,set,setIndex,submitSet,history,setResult,rpe) => (e) => {
    const { weight, reps } = set;
    submitSet(urlParams.date,{ exerciseId: set.strengthTest.exercise.id, set: { weight, reps, rpe }, setIndex, specId: (urlParams.spec && urlParams.spec.id) });
    const result = set.result(rpe,setIndex);
    if(result) {
        setResult(result);
    } else {
        let suffix = '';
        let newSetIndex = setIndex + 1;
        if(set.restTime() > 0) {
            suffix = 'rest';
            newSetIndex = setIndex;
        }
        history.push(pathFor({ ...urlParams, setIndex: newSetIndex, suffix }));
    }
}

class StrengthTestSet extends React.Component {

    constructor(props) {
        super(props);
        const { basePath, setIndex, suffix, urlParams, setupTransitions } = props;
        const prevMatches = suffix === 'rest' ? [pathFor({ ...urlParams, setIndex })] : [];
        const afterMatches = suffix === 'rest' ? [] : [pathFor({ ...urlParams,setIndex,suffix: 'rest'})];
        const defaultRules = [[prevMatches,transitions.flowFormOut],[afterMatches,transitions.flowFormIn]]
        setupTransitions(buildTransitionMap(basePath,defaultRules,`${setIndex}`,allSetIndices.map(index => `${index}`)));
    }

    render() {
        const { strengthTest, setIndex, suffix, urlParams, submitSet, setResult, history, t } = this.props;
        const set = strengthTest.getNextSet(setIndex);
        const repsLabel = strengthTest.exercise.isUnilateral() ? t('X per side',{ unit: t('Reps')}) : t('Reps');
        const isAlternating = strengthTest.exercise.isAlternating();

        if(suffix === 'rest') {
            return (
                <div style={{width: '100%'}}>
                    <RestScreen 
                        urlParams={urlParams}
                        set={set}
                        setIndex={setIndex}
                    />
                </div>
            )
        } else {
            return (
                <div style={{width: '100%'}}>
                    <div className="wo-wt-details-wrapper">
                        <InstructionBox 
                            className="mb20"
                            title={`${t('Instructions')}:`}
                            instructions={`${t('Attempt the given number of reps with the given weight and indicate how difficult it was')}${t('Stop if your form falls apart')}${isAlternating ? t('One cycle through each side is a single rep') : ''}`}
                            postInstructions={[t('Form tips'),strengthTest.exercise.instructions]}
                        />
                        <div className="valign-wrapper cntr respo">
                            <FixedWorkoutNum 
                                key='reps'
                                content={set.reps}
                                subtitle={repsLabel}
                            />
                            <FixedWorkoutNum 
                                key='weight'
                                content={set.unitWeight()}
                                subtitle={set.weightLabel(t)}
                            />
                        </div>
                        <div className="wo-wt-divider"></div>
                        <StrengthTestPrompt handlerCreator={setDoneHandlerCreator.bind(null,urlParams,set,setIndex,submitSet,history,setResult)} reps={set.reps} />
                    </div>
                </div>
            )
        }
    }
}

const StrengthTestCore = ({ strengthTest, urlParams, setIndex, submitSet, submitTest, finishCallback }) => {

    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const [result,setResult] = useState(null);
    const basePath = basePathFor(urlParams);

    return (
        <div className="text-center">
            <div className="set-container pb10">
                <div className="ex-vid-container">
                    <ExerciseVideo exercise={strengthTest.exercise} fallbackToYT autoplay />
                </div>
                <div className="text-right">
                    <div className="wo-wt-ex-tab" id="strtst-exercise-name">{strengthTest.exercise.fullName(t)}</div>
                    <ExerciseInfoMenu 
                        exercise={strengthTest.exercise} 
                        basePath={pathFor(urlParams)}
                    />
                </div>
                <div className="clearfix"></div>
                {result && (
                    <LoadingContainerParent 
                        load={submitTest.bind(null, { exerciseId: strengthTest.exercise.id, date: urlParams.date, reps: result.reps, weight: result.weight, clientId: urlParams.clientId })}
                        preloaded={() => false}
                        component={TestResultLC}
                        successCallback={() => {
                            console.log('finishing');
                            console.log(urlParams);
                            finishCallback && finishCallback();
                            goWrapper(history,-setIndex-1);
                        }}
                    />
                )}
                {!result && (<div style={{overflowX: 'hidden', maxWidth: '480px', position: 'relative', minHeight: '450px'}}>
                    <RouteTransitionMap location={location} canUpdate noOverflow>
                        {_.flatMap(['','rest'], suffix => (
                            allSetIndices.map((setNum) => (
                                <StrengthTestSet 
                                    key={`${suffix}-${setNum}`}
                                    path={pathFor({ ...urlParams, setIndex: setNum, suffix })}
                                    exact
                                    urlParams={urlParams}
                                    strengthTest={strengthTest}
                                    setIndex={setNum}
                                    suffix={suffix}
                                    submitSet={submitSet}
                                    setResult={setResult}
                                    t={t}
                                    history={history}
                                    basePath={basePath}
                                />
                            ))
                        ))}
                    </RouteTransitionMap>
                </div>)}
            </div>
        </div>
    );
}

const StrengthTestLC = loadingContainer({
    'SUCCESS': StrengthTestCore
}, { type: 'page' })

let StrengthTestLoader = ({ urlParams, setIndex, strengthTest, loadStrengthTest, submitSet, submitTest, finishCallback }) => {
    const { exerciseId, date, clientId } = urlParams;

    return (
        <LoadingContainerParent 
            load={loadStrengthTest.bind(null, { exerciseId, date, clientId })}
            preloaded={() => !!strengthTest}
            component={StrengthTestLC}
            urlParams={urlParams}
            setIndex={setIndex}
            strengthTest={strengthTest}
            submitSet={submitSet}
            submitTest={submitTest}
            finishCallback={finishCallback}
        />
    )
}

const mapStateToProps = (state,props) => {
    const { exerciseId, date } = props.urlParams;
    const strengthTestSelector = getStrengthTestSelector(exerciseId,date);
    return {
        strengthTest: strengthTestSelector(state)
    }
}

const mapDispatchToProps = dispatch => ({
    loadStrengthTest: (params) => dispatch(loadStrengthTest(params)),
    submitSet: (date,data) => dispatch(submitStrengthTestSet(date,data))
})

StrengthTestLoader = connect(mapStateToProps, mapDispatchToProps)(StrengthTestLoader);

class StrengthTestSuccess extends React.Component {

    constructor(props) {
        super(props);
        const { date, startWorkout } = props;
        startWorkout(date.format(dateFormat));
    }

    render() {
        const { workout, specId, setIndex, submitTest } = this.props;
        const spec = workout && workout.getSpec(specId);

        if(spec && spec.isStrengthTestable()) {
            return (<StrengthTestLoader 
                urlParams={{ spec, exerciseId: spec.resolvedExerciseId(), date: spec.date() }}
                setIndex={setIndex}
                submitTest={submitTest}
            />)
        } else {
            return <Redirect to={resolvedHomePath()} />
        }
    }
}

const WithWorkout = withWorkout('dirty','page',true)(StrengthTestSuccess)

class StrengthTestPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { location, match: { params: { specId, date, setIndex }}, t, scrollRef, startWorkout, submitTest } = this.props;
        const basePath = strengthTestPathFor(specId,date,setIndex);

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t('Strength Test')}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <WithWorkout date={moment(date)} specId={specId} setIndex={setIndex} location={location} startWorkout={startWorkout} submitTest={submitTest} />
                    <ExerciseInfoModal basePath={basePath} baseMatch={basePath}/>
                </SimpleNavContainer>
            </Page>
        )
    }
}

const mapDispatchToStartWorkout = dispatch => ({
    startWorkout: (date) => dispatch(standaloneStartWorkout(date)),
    submitTest: (params) => dispatch(submitStrengthTest(params))
})

const StrengthTestFormLoader = ({ formId, form, loadForm, ...rest }) => {

    return (
        <Loader 
            successComponent={StrengthTestLoader}
            type="page"
            load={() => loadForm(formId)}
            preloaded={() => !!form}
            {...rest}
        />
    )

}

class StrengthTestFormPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { user, loadForm, initDataEntry, submitTest, match: { params: { clientId, formId, exerciseId, date, setIndex }}, t, scrollRef } = this.props;
        const basePath = strtstFormPathFor(exerciseId,date,setIndex);
        let form;
        if(user.id === Number(clientId)) {
            form = user.getClientFormById(formId);
        } else {
            const client = user.clientById(clientId);
            form = client && client.getClientFormById(formId);
        }

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t('Strength Test')}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <StrengthTestFormLoader 
                        formId={formId} 
                        form={form} 
                        loadForm={loadForm}
                        urlParams={{ clientId, formId, exerciseId, date }}
                        finishCallback={() => {
                            form.strengthTestsForExercise(exerciseId).forEach(ff => {
                                initDataEntry({ clientId, formId, id: ff.id });
                            })
                        }}
                        setIndex={setIndex} 
                        submitTest={submitTest}
                    />
                    <ExerciseInfoModal basePath={basePath} baseMatch={basePath}/>
                </SimpleNavContainer>
            </Page>
        )
    }
}

const mapStateToFormProps = (state) => ({
    user: userWithFormsSelector(state)
})

const mapDispatchToFormProps = dispatch => ({
    loadForm: id => dispatch(loadTrainerForm({ id })),
    initDataEntry: (data) => dispatch(initFormFieldDataEntry(data)),
    submitTest: (params) => dispatch(submitFormStrengthTest(params))
})

StrengthTestFormPage = withTranslation()(connect(mapStateToFormProps,mapDispatchToFormProps)(StrengthTestFormPage));

export { StrengthTestFormPage }
export default withTranslation()(connect(null,mapDispatchToStartWorkout)(StrengthTestPage));