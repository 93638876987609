import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import moment from 'moment';

export class MealType extends RecordBase {
    static NAME = 'MealType'
    static ON_PLAN_CATS = ['breakfast','lunch','dinner','snack'];
    static CATEGORIES = [ ...this.ON_PLAN_CATS, 'protein','misc'];
    static DEFAULT_PERCENTS = {'breakfast': 25, 'lunch': 40, 'dinner': 40, 'snack1': 20, 'snack2': 20, 'snack3': 30 }

    static ASSOCS = {
        user: { type: 'belongsTo' },
        userMeals: { type: 'hasMany' }
    }

    static sortedCats = (map) => _.sortBy(Object.entries(map),([cat,val]) => this.ON_PLAN_CATS.indexOf(cat))
    static keyFor = (cat,minSnacks) => (cat === 'snack' ? `${cat}${minSnacks || 1}` : cat);
    static defaultPercentFor = (cat,minSnacks) => this.DEFAULT_PERCENTS[this.keyFor(cat,minSnacks)];

    getPercentDailyIntake(minSnacks) {
        return _.isBlank(this.percentDailyIntake) ? (this.constructor.defaultPercentFor(this.category,minSnacks) || 0) : this.percentDailyIntake;
    }

    isActive() {
        return !this.inactive;
    }

    isOnPlanType() {
        return this.constructor.ON_PLAN_CATS.includes(this.category);
    }

    isActiveOnPlan() {
        return this.isActive() && this.isOnPlanType();
    }

    userMealsBetween(startDate,endDate) {
        const startMom = startDate ? moment(startDate) : moment().subtract(9999,'days');
        const endMom = endDate ? moment(endDate) : moment().add(9999,'days');
        return _.filter(this.userMeals,um => {
            if(um.date) {
                const mom = moment(um.date);
                return mom.isBetween(startMom,endMom,'day','[]');
            }
            return false;
        })
    }

    forwardMatchesFor(userMeal,endDate) {
        const userMeals = this.userMealsBetween(userMeal.date, endDate);
        return _.filter(userMeals, um => (um.key() === userMeal.key()));
    }

}

registerInflection('mealType','mealTypes',MealType);