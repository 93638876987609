import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import { createFormField, deleteFormField, initFormFieldDataEntry, updateFormField, updateFormFieldOrder, updateProgressChartsLocal, updateTrainerForm } from 'redux/actions';
import { DefaultSelect, FullInput, FullTagsInput, FullTextArea, ManualSubmitButton, MultivalueCheckbox, fakeFormikProps } from 'components/Form';
import TextInput, { NumberInput, TextArea } from 'components/TextInput';
import requestStateHandler from 'components/RequestStateHandler';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Form, FormField } from 'lib/classes';
import Card from 'components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BottomButtons, ConfirmActionButton } from 'components/Button';
import classnames from 'classnames';
import { DestroyableCollection } from 'components/DestroyableCollection';
import * as _ from 'lib/utilities';
import { BodyMeasurementField, CheckboxField, FieldError, LongTextField, MoodField, NumberField, NumericSlider, PhoneField, ProgressPhotoField, SelectField, ShortTextField, SignatureField } from 'components/FormFields';
import { useHistory, useLocation } from 'react-router-dom';
import { addAssessmentsToFormPath, addExercisesToFormPath, clientProgressShortcutPathFor, modalPathFor, progressPathFor, weightCalcModalPostfix } from 'config/paths';
import LinkButton from 'components/LinkButton';
import { TakeAssessmentModal, takeAssessmentModalSuffix } from './AssessmentModals';
import { ExerciseVideo } from './ExerciseImage';
import { BasicFormFields, FormFieldHistoryModal } from './TrainerFormModals';
import CircularProgress from 'components/CircularProgress';
import ImageViewer from 'components/ImageViewer';
import { WeightCalculatorModal } from './ExerciseModals';
import { SendMessageModal, sendMsgModalSuffix } from './SendMessageModal';
import { DropdownItem, DropdownLink, MultiLevelDropdown } from 'components/Dropdown';
import { SIAC, SlightEmphasisTip } from './Utilities';
import moment from 'moment';
import { STRTST_AUTO_WEIGHT_TIP } from 'config/tooltips';

const formValidator = (viewer,form,afterSubmitStatus,t) => values => {
    if(afterSubmitStatus === values.status) {
        const formFieldErrs = values.formFields.map(ff => {
            const field = form.getFieldById(ff.id);
            if(field) {
                return field.validateFillableValues(viewer,ff,t);
            } else {
                return {};
            }
        })
    
        if(_.every(formFieldErrs,errs => _.isEmpty(errs))) {
            return {};
        }
    
        return { formFields: formFieldErrs }
    }

    return {};

}

const formFieldFormValidator = t => values => {
    const { fieldType, prompt, options, minVal, maxVal } = values;
    const promptOptional = ['body_measurement','progress_photo'];
    const optionsTypes = ['check','select'];
    const errors = {};

    if(_.isBlank(prompt) && !promptOptional.includes(fieldType)) {
        errors.prompt = t('Required');
    }

    if((_.isBlank(options) || _.noBlanks(options).length === 0) && optionsTypes.includes(fieldType)) {
        errors.options = t('Required');
    }

    if(fieldType === 'numeric_slider') {
        let anyBlank = false;
        if(_.isBlank(minVal)) {
            anyBlank = true;
            errors.minVal = t('Required');
        }

        if(_.isBlank(maxVal)) {
            anyBlank = true;
            errors.maxVal = t('Required');
        }

        if(!anyBlank) {
            if(minVal >= maxVal) {
                errors.maxVal = t("must be greater than", { count: minVal });
            }
        }
    }

    return errors;
}

const UneditableFieldHeader = ({ text, dragHandleProps, deleteField, t }) => {
    const handleProps = dragHandleProps;

    return (
        <div className="valign-wrapper">
            <div className="flex-grow field-label" {...handleProps}><FontAwesomeIcon className="faint-color" icon="arrows-alt-v" /> {text}</div>
            <DeleteButton deleteField={deleteField} t={t} className="inline-icon clickable" />
        </div>
    )
}

const StrengthTestResult = ({ formField, t, viewer }) => {

    return (
        <React.Fragment>
        {!formField.form.viewerIsAssignee(viewer) && (<div className="vid-width-cont mb10">
            <SlightEmphasisTip tipName={STRTST_AUTO_WEIGHT_TIP} text={t('strtst auto weight tip')} />
        </div>)}
        <div className={classnames("strtst-res-cont")}>
            <div className="valign-wrapper text-center mt10">
                {formField.reps !== 1 && (
                    <div className="flex-grow">
                        <div className="font-grey lh1 max-estimate"><b>{formField.estOneRmStr(viewer,t)}</b></div>
                        <div className="faint-color">{t('Estimated XRM', { reps: 1 })}</div>
                    </div>
                )}
                <div className="flex-grow">
                    <div className="font-grey lh1 max-estimate"><b>{formField.unitWeightStr(viewer,t)}</b></div>
                    <div className="faint-color">{t('Reported XRM', { reps: formField.reps })}</div>
                </div>
            </div>
        </div>
        </React.Fragment>
    )
}

const StrengthTestField = ({ formField, name: baseName, readOnly, formikProps, viewType, isEditable, dragHandleProps, deleteField, viewer, label, labelClassName }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const { exercise } = formField;

    const modalPath = modalPathFor(weightCalcModalPostfix,pathname,{ id: exercise.id });

    const header = isEditable ? (<UneditableFieldHeader 
        text={formField.getPrompt(t)} 
        dragHandleProps={dragHandleProps} 
        deleteField={deleteField} 
        t={t}
    />) : (<div className={labelClassName}>
        {label}
    </div>)

    if(formField.strengthTestCompleted()) {
        return (
            <div>
                {header}
                <StrengthTestResult 
                    formField={formField} 
                    t={t} 
                    viewer={viewer} 
                    readOnly={readOnly} 
                    modalPath={modalPath}
                />
            </div>
        )
    }

    return (
        <div>
            {header}
            <div className="assess-res-cont">
                <LinkButton 
                    className="flex-grow text-center mr5"
                    to={formField.strtstPath()}
                    outlined
                    rounded
                    id={`take-test-${formField.id}-btn`}
                    disabled={readOnly}
                >
                    <FontAwesomeIcon icon="play-circle" />
                    <span>{viewType === 'client' ? t('Take Strength Test') : t('Administer Strength Test')}</span>
                </LinkButton>
                <span>{` ${t('Or')} `}</span>
                <LinkButton 
                    className="flex-grow text-center ml5"
                    to={modalPath}
                    outlined
                    rounded
                    disabled={readOnly}
                >
                    <FontAwesomeIcon icon="calculator" />
                    <span>{viewType === 'client' ? t("Enter Your Max") : t("Enter Client's Max")}</span>
                </LinkButton>
            </div>
            <FieldError name={`${baseName}test`} formikProps={formikProps} alwaysTouched />
        </div>
    )
}

const BilateralAssessmentFields = ({ assessmentResult, baseName, readOnly, formikProps }) => {
    const { t } = useTranslation();

    return (
        <div className="assess-res-cont">
            {assessmentResult.isFreeText() && (<FullTextArea
                name={`${baseName}leftResult`} 
                label={t('Left result')}
                formikProps={formikProps}
                stopModalDrag
                className="flex-grow"
                disabled={readOnly}
            />)}
            {assessmentResult.isFreeText() && (<FullTextArea
                name={`${baseName}rightResult`} 
                label={t('Right result')}
                formikProps={formikProps}
                stopModalDrag
                className="flex-grow ml5"
                disabled={readOnly}
            />)}
            {assessmentResult.isNumeric() && (<FullInput
                name={`${baseName}leftResultNum`} 
                label={t('Left result')}
                formikProps={formikProps}
                helperText={_.isBlank(assessmentResult.source.resultUnits) ? null : assessmentResult.source.resultUnits}
                inProps={{ disabled: readOnly }}
                component={NumberInput}
                className="flex-grow"
            />)}
            {assessmentResult.isNumeric() && (<FullInput
                name={`${baseName}rightResultNum`} 
                label={t('Right result')}
                formikProps={formikProps}
                helperText={_.isBlank(assessmentResult.source.resultUnits) ? null : assessmentResult.source.resultUnits}
                inProps={{ disabled: readOnly }}
                component={NumberInput}
                className="flex-grow ml5"
            />)}
        </div>
    )
}

const UnilateralAssessmentField = ({ assessmentResult, baseName, readOnly, formikProps }) => {
    const { t } = useTranslation();

    return (
        <div className="assess-res-cont" >
            {assessmentResult.isFreeText() && (<FullTextArea
                name={`${baseName}result`} 
                label={t('Result')}
                formikProps={formikProps}
                stopModalDrag
                disabled={readOnly}
                className="flex-grow"
            />)}
            {assessmentResult.isNumeric() && (<FullInput
                name={`${baseName}resultNum`} 
                label={t('Result')}
                formikProps={formikProps}
                helperText={_.isBlank(assessmentResult.resultUnits) ? null : assessmentResult.resultUnits}
                inProps={{ disabled: readOnly }}
                component={NumberInput}
                className="flex-grow"
            />)}
        </div>
    )
}

const RecordClientBtn = ({ takePath, t }) => {

    return (
        <LinkButton to={takePath} variant="flat" noShadow color="white" className="valsub">
            <FontAwesomeIcon icon={["far","video"]} /> <span>{t('Record Client')}</span>
        </LinkButton>
    )
}

const AssessmentResultCore = ({ assessmentResult, header, formikProps, readOnly, baseName, blankMsg, noResult }) => {

    return (
        <div className="asses-res">
            {header}
            <ExerciseVideo exercise={assessmentResult} fixedAspect blankMessage={blankMsg} videoOnly />
            {!noResult && assessmentResult.isBilateral() && (<BilateralAssessmentFields 
                assessmentResult={assessmentResult}
                baseName={baseName}  
                readOnly={readOnly}
                formikProps={formikProps}
            />)}
            {!noResult && !assessmentResult.isBilateral() && (<UnilateralAssessmentField
                assessmentResult={assessmentResult}
                baseName={baseName}
                readOnly={readOnly}
                formikProps={formikProps}
            />)}
        </div>

    )
}

export const AssessmentResultView = ({ formField, viewer }) => {

    if(formField.dataEntry && formField.dataEntry.hasMuxVideo()) {
        return (
            <AssessmentResultCore 
                assessmentResult={formField.dataEntry}
                header={<div className="field-label">{moment(formField.fieldDate).format('MMM Do YYYY')}</div>}
                formikProps={{ ...fakeFormikProps([]), values: { formFields: [formField.fillableValues(viewer)] } }}
                readOnly
                baseName={`formFields.0.`}
                noResult={viewer.isClient()}
            />
        )
    }

    return '';

}

export const AssessmentField = ({ formField, name: baseName, readOnly, formikProps, viewType, isEditable, dragHandleProps, deleteField, label, labelClassName }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const assessmentResult = formField.defaultAssessmentResult();

    const header = isEditable ? (<UneditableFieldHeader 
        text={formField.getPrompt(t)} 
        dragHandleProps={dragHandleProps} 
        deleteField={deleteField} 
        t={t}
    />) : (<div className={labelClassName}>{label}</div>)

    const takePath = modalPathFor(takeAssessmentModalSuffix,pathname, { id: formField.id });

    if(viewType === 'client') {
        return (
            <div>
                {header}
                {assessmentResult.hasMuxVideo() && (<ExerciseVideo 
                    exercise={assessmentResult} 
                    fixedAspect 
                    blankMessage={formField.isExampleContext() ? t("remote cli assess vid tip") : t('no cli vid for assess')} 
                    videoOnly 
                />)}
                <div className="assess-res-cont">
                    <LinkButton 
                        className={"flex-grow text-center"}
                        to={takePath}
                        outlined
                        rounded
                        id={`take-test-${formField.id}-btn`}
                        disabled={readOnly}
                    >
                        <FontAwesomeIcon icon={["far","video"]} />
                        <span>{assessmentResult.takeButtonText(t)}</span>
                    </LinkButton>
                </div>
                <FieldError name={`${baseName}test`} formikProps={formikProps} alwaysTouched />
            </div>
        )
    }

    const blankMsg = formField.isExampleContext() ? t("remote cli assess vid tip") : <div><div>{t('no cli vid for assess')}</div><div className="text-center mt10"><RecordClientBtn t={t} takePath={takePath} /></div></div>;

    return (
        <AssessmentResultCore 
            assessmentResult={assessmentResult}
            header={header}
            formikProps={formikProps}
            readOnly={readOnly}
            baseName={baseName}
            blankMsg={blankMsg}
        />
    )
}

const AddFieldForm = ({ form, setNewField, hide }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <div id="add-field-form" className={classnames("text-right", { hide })}>
            <FormikWrapper 
                initialValues={{ fieldType: FormField.FIELD_TYPES(t)[0].value }}
                submit={({ fieldType }) => {
                    setNewField(FormField.defaultNew(t,form,fieldType));
                    return Promise.resolve({ status: 'SUCCESS'});
                }}
                initialErrors={{}}
                render={({ submitForm, ...formikProps }) => {
                    const { values: { fieldType } } = formikProps;
                    const clickHandler = () => {
                        if(fieldType === 'assessment') {
                            history.push(addAssessmentsToFormPath(form.id));
                        } else if(fieldType === 'form_check' || fieldType === 'strength_test') {
                            history.push(addExercisesToFormPath(form.id,fieldType))
                        } else {
                            submitForm();
                        }

                    }
                    
                    return (
                        <React.Fragment>
                            <DefaultSelect
                                name="fieldType" 
                                className="mr5 inline-block text-left"
                                label={t('Field Type')}
                                collection={FormField.FIELD_TYPES(t,form.formType)} 
                                {...formikProps} 
                            />
                            <Button id="add-field-btn" color="primary" outlined={form.formFields.length > 0} noShadow onClick={clickHandler}>
                                <FontAwesomeIcon icon="plus" />
                                {t('Add')}
                            </Button>
                        </React.Fragment>
                    )
                }}
            />
        </div>
    )
}

const EditButton = ({ setEditMode, forField, id }) => {

    return (
        <div id={id} className={classnames("upper-right-btn faint-color", { 'for-field': forField })} onClick={() => setEditMode(true)}>
            <FontAwesomeIcon icon={'pencil'} />
        </div>
    )
}

const DeleteButton = ({ t, deleteField, className }) => {

    return (
        <ConfirmActionButton onClick={deleteField} render={({ onClick, ready }) => {
            return (
                <div className={classnames({ [className]: className, "upper-right-btn for-field": !className,  'error-color': ready, 'faint-color': !ready})} onClick={onClick}>
                    <FontAwesomeIcon icon={'trash'} /> {ready && t('Delete')}
                </div>
            )
        }}/>
    )
}

const SaveSectionButton = ({ setEditMode, submitForm, submitState, errors }) => {
    const {t} = useTranslation();

    return (
        <div className="text-center mt5">
            <Button color="primary" outlined rounded className="mr5" onClick={() => setEditMode(false)}>
                {t('Cancel')}
            </Button>
            <ManualSubmitButton 
                label={t('Save')} 
                submitState={submitState} 
                submitForm={submitForm} 
                disabled={!_.isBlank(errors) && !_.isEmpty(errors)}
                noShadow
                id="save-btn"
            />
        </div>
    )
}

const MessageableLabel = ({ formField, viewer, setMsgRecord, updateProgressCharts, t }) => {
    const { pathname } = useLocation();
    const history = useHistory();
    const clickHandler = () => {
        setMsgRecord(formField);
        setTimeout(() => history.push(modalPathFor(sendMsgModalSuffix,pathname, { clientId: formField.form.user.id })))
    }


    return (
        <div className="label-w-menu">
            <div className="label-cont">
                {formField.getPrompt(t)}
            </div>
            <MultiLevelDropdown
                    options={ { closeOnClick: true, closeOnChildClick: true } }
                    triggerRender={({ ref, target }) => {
                        return (
                            <div className="menu-cont" ref={ref} data-target={target} id={`field-${formField.id}-menu`}>
                                <FontAwesomeIcon className="faint-color" icon={'ellipsis-h'} />
                            </div>
                        )
                    }}
                >
                    {formField.canBeMessaged(viewer) && (<DropdownItem id="msg-btn" label={t("Message Client")} icon="comments-alt" onClick={clickHandler} />)}
                    
                    <SIAC render={({ signedInAsClient }) => (
                        <DropdownItem 
                            id="view-history-btn" 
                            label={t('View History')} 
                            icon={'history'} 
                            onClick={() => {
                                if(formField.fieldType === 'strength_test') {
                                    const path = (formField.form.viewerIsAssignee(viewer) || signedInAsClient) ? progressPathFor(formField.exerciseId) : clientProgressShortcutPathFor(formField.form.user.id,formField.exerciseId);
                                    updateProgressCharts({ exerciseId: formField.exercise.id, exerciseName: formField.exercise.name });
                                    setTimeout(() => history.push(path),1);
                                } else {
                                    history.push(formField.viewHistoryPath(viewer,signedInAsClient,pathname));
                                }
                            }} 
                        />
                    )} />
                    {formField.fieldType === 'strength_test' && formField.strengthTestCompleted() && (
                        <DropdownLink 
                            id="redo-tst-btn" 
                            label={t('Edit Max')} 
                            icon={['far','edit']} 
                            to={modalPathFor(weightCalcModalPostfix,pathname,{ id: formField.exercise.id })} 
                        />
                    )}
                    {['assessment','form_check'].includes(formField.fieldType) && !formField.form.viewerIsAssignee(viewer) && (
                        <DropdownLink 
                            id="show-assess-btn" 
                            label={formField.fieldType === 'assessment' ? t('Assessment Instructions') : t('Form Check Instructions')} 
                            icon={'info-circle'} 
                            to={modalPathFor(takeAssessmentModalSuffix,pathname, { id: formField.id })} 
                        />
                    )}
                </MultiLevelDropdown>

        </div>
    )
}

export const FillableFormField = ({ fieldIndex, formField, isEditable, isFillable, setEditMode, setMsgRecord, updateProgressCharts, formikProps, viewType, dragHandleProps, deleteField, viewer, overrideLabel }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const prefix = `formFields.${fieldIndex}.`;
    let label = formField.getPrompt(t);
    if(overrideLabel) {
        label = overrideLabel;
    } else if(isEditable) {
        label = (<span className="clickable" id={`edit-btn-${formField.id}`} onClick={() => setEditMode(true)}><FontAwesomeIcon className="faint-color" icon={'pencil'} /> {formField.getPrompt(t)}</span>);
    } else if(formField.isInteractiveField() && formField.form.isAssignedForm()) {
        label = (<MessageableLabel formField={formField} viewer={viewer} setMsgRecord={setMsgRecord} updateProgressCharts={updateProgressCharts} t={t} />)
    }
    const labelClassName = 'field-label';
    const shared = { t, name: `${prefix}${formField.fillValueName(viewer)}`, label, optional: formField.optional, multiple: formField.multiple, readOnly: (!isFillable || formField.isReadOnly(viewer)), formikProps, labelClassName, viewType }

    
    switch(formField.fieldType) {
        case 'mood':
        case 'energy_level':
        case 'stress_level':
        case 'hunger_level':
        case 'sleep_quality':
            return (
                <MoodField 
                    {...shared} 
                    labels={formField.moodLabels(t)}
                />
            )
        case 'long_text':
            return (
                <LongTextField
                    {...shared}
                />
            )
        case 'short_text': 
            return (
                <ShortTextField
                    {...shared}
                />
            )
        case 'select':
            return (
                <SelectField 
                    {...shared}
                    isEditable={isEditable}
                    options={formField.options}
                />
            )
        case 'check':
            return (
                <CheckboxField {...shared} options={formField.options} />
            )
        case 'numeric': 
            return (
                <NumberField {...shared} />
            )
        case 'phone':
            return (
                <PhoneField {...shared} />
            )
        case 'numeric_slider': 
                return (
                    <NumericSlider {...shared} min={formField.minVal} max={formField.maxVal} />
                )
        case 'signature': 
            return (
                <SignatureField 
                    {...shared}
                    signatureDate={formField.signDate()}
                    signerName={formField.signerName(t)}
                />
            )
        case 'header':
            return (
                <h2 className="form-head">{label}</h2>
            )
        case 'text_block':
            return (
                <div className={classnames('form-tblock',{ "clickable": isEditable })} id={`edit-btn-${formField.id}`} onClick={isEditable ? (() => setEditMode(true)) : null}>
                    {formField.textBlockParagraphs(viewer).map((p,i) => (<p key={i}>{i === 0 && isEditable ? (<FontAwesomeIcon className="faint-color" icon={'pencil'} />) : null} {p}</p>))}
                </div>
            )
        case 'form_check':
        case 'assessment':
            return (<AssessmentField {...shared} formField={formField} isEditable={isEditable} dragHandleProps={dragHandleProps} deleteField={deleteField} />)
        case 'strength_test':
            return (<StrengthTestField {...shared} formField={formField} viewer={viewer} isEditable={isEditable} dragHandleProps={dragHandleProps} deleteField={deleteField} />)
        case 'progress_photo':
            return (<ProgressPhotoField 
                        {...shared} 
                        fullViewPath={modalPathFor(imageViewSuffix,pathname,{ formFieldId: formField.id })} 
                        formField={formField}
                    />)
        case 'body_measurement':
            return (<BodyMeasurementField {...shared} modifier={formField.modifier} isMetric={viewer.isMetric()} />)
        default:
            return ''
    }
}

const FormFieldFormCore = ({ formField, formikProps }) => {
    const { t } = useTranslation();
    const promptPlaceholder = formField.getPromptPlaceholder(t,formikProps.values.modifier);

    const promptField = (
        <FullInput
            className={classnames("flex-grow mr5", { 'with-placeholder': !_.isBlank(promptPlaceholder) })}
            inProps={{ placeholder: promptPlaceholder }}
            name="prompt"
            label={formField.promptFieldLabel(t)}
            component={TextInput}
            formikProps={formikProps}
            onlyErrorText
            focusOnMount
        />
    )

    switch(formField.fieldType) {
        case 'header':
        case 'signature':
            return (
                <div>
                    <div className="valign-wrapper resp">
                        {promptField}
                    </div>
                </div>

            )
        case 'phone':
        case 'short_text':
        case 'numeric':
        case 'long_text':
        case 'mood':
        case 'energy_level':
        case 'stress_level':
        case 'hunger_level':
        case 'sleep_quality':
            return (
                <div>
                    <div className="valign-wrapper resp">
                        {promptField}
                    </div>
                </div>

            )
        case 'check':
        case 'select': 
    
            return (
                <div>
                    <div className="valign-wrapper resp">
                        {promptField}
                    </div>
                    <div>
                        <MultivalueCheckbox 
                            checkedVal={true}
                            uncheckedVal={false}
                            name={'multiple'}
                            label={`${t('Multiselect')}?`}
                            wrapLabel={false}
                            setFieldValue={formikProps.setFieldValue}
                            values={formikProps.values}
                            id="multi-check"
                        />
                    </div>
                    <FullTagsInput contClassName="mt20" label={t('Options')} name="options" placeholder={t('Add options')} formikProps={formikProps} />
                </div>
            )
        case 'text_block': 
                return (
                    <div className="text-left">
                        <div className="font-grey">{formField.promptFieldLabel(t)}</div>
                        <TextArea
                            name="prompt" 
                            value={formikProps.values.prompt} 
                            onChange={formikProps.handleChange} 
                            onBlur={formikProps.handleBlur} 
                            stopModalDrag
                            focusOnMount
                            height={'75px'}
                        />
                    </div>
                )
        case 'numeric_slider':
            return (
                <div>
                    <div className="valign-wrapper resp">
                        {promptField}
                    </div>
                    <div className="valign-wrapper">
                        {['minVal','maxVal'].map((attr,i) => (
                            <FullInput
                                className={classnames("mt0 flex-grow", { ml5: 1 > 0})}
                                name={attr}
                                label={attr === 'minVal' ? t('Minimum') : t('Maximum')}
                                onlyErrorText
                                component={NumberInput}
                                formikProps={formikProps}
                            />
                        ))}
                    </div>
                </div>
            )
        case 'body_measurement':
        case 'progress_photo':
            const { fieldType: ft } = formField;
            const label = ft === 'progress_photo' ? t('Photo Angle') : t('Body Part')
            const col = ft === 'progress_photo' ? FormField.photoAngles(t) : FormField.bodyParts(t);
            return (
                <div>
                    <div className="valign-wrapper resp">
                        {promptField}
                     </div>
                    <div className="display-flex mb10">
                        <DefaultSelect
                            name="modifier" 
                            className="flex-grow"
                            label={label}
                            collection={col} 
                            {...formikProps} 
                        />
                    </div>
                </div>
            )
        default:
            return ''
    }
}

const FormFieldForm = ({ formField, save, deleteField, setEditMode, dragHandleProps, hasMultipleFields }) => {
    const { t } = useTranslation();
    const validator = formFieldFormValidator(t);
    const initVals = formField.editValues();

    return (
        <FormikWrapper 
            initialValues={initVals}
            submit={values => save(values)}
            initialErrors={validator(initVals)}
            validate={validator}
            successCallback={() => setEditMode(false)}
            render={({ handleSubmit, ...formikProps }) => {
                
                return (
                    <div className="field-edit-cont">
                        {!formField.isNew() && (<DeleteButton deleteField={deleteField} t={t} />)}
                        <div className={classnames("drag-handle", { hide: !hasMultipleFields})} {...dragHandleProps}>
                            <FontAwesomeIcon icon="arrows-alt-v" />
                        </div>
                        <FormFieldFormCore formField={formField} formikProps={formikProps} />
                        <SaveSectionButton {...formikProps} setEditMode={setEditMode} />
                    </div>
                )
            }}
        />
    )
}

let FormFieldBlock = ({ formField, createField, deleteField, allowDestroy, updateField, setMsgRecord, updateProgressCharts, dragHandleProps, draggableProps, draggableRef, isEditable, isFillable, fieldIndex, fillFormikProps, cancelNewField, viewType, viewer }) => {
    const [editMode,setEditMode] = useState(formField.isNew());
    const deleteF = () => {
        const newField = new FormField({ ...formField, inactive: true });

        deleteField(newField);
    }
    
    const setEditModeFinal = mode => {
        if(!mode && formField.isNew()) {
            cancelNewField();
        } else {
            setEditMode(mode);
        }
    }

    return (
        <div {...draggableProps} ref={draggableRef}>
                {!editMode && (<div className="hide" {...dragHandleProps}></div>)}
                {!editMode && (
                    <div className={classnames("position-relative", { [formField.fillableWrapperClasses()]: true })} style={{ minHeight: '40px'}}>
                        <FillableFormField 
                            fieldIndex={fieldIndex}
                            formField={formField}
                            isFillable={isFillable}
                            isEditable={isEditable}
                            formikProps={fillFormikProps}
                            setEditMode={setEditModeFinal}
                            viewType={viewType}
                            viewer={viewer}
                            dragHandleProps={dragHandleProps}
                            deleteField={deleteF}
                            setMsgRecord={setMsgRecord}
                            updateProgressCharts={updateProgressCharts}
                        />
                    </div>
                )}

                {editMode && (<FormFieldForm 
                    formField={formField} 
                    save={formField.isNew() ? createField : updateField} 
                    deleteField={deleteF} 
                    dragHandleProps={dragHandleProps} 
                    hasMultipleFields={allowDestroy}
                    setEditMode={setEditModeFinal}
                />)}
        </div>
    )
}

FormFieldBlock = requestStateHandler({ deleteField: 'formField' })(FormFieldBlock)

let Fields = ({ 
    form, 
    setNewField, 
    newField, 
    updateFieldOrder, 
    deleteField, 
    updateField, 
    isEditable, 
    isFillable, 
    fillFormikProps, 
    setNewFieldPosition,
    setMsgRecord, 
    updateProgressCharts,
    newFieldPosition, 
    createField,
    viewType, 
    viewer  }) => {
        const { t } = useTranslation();
        newFieldPosition = _.isBlank(newFieldPosition) ? form.formFields.length : newFieldPosition;
        const fields = [ ...form.formFields ];
        if(newField) {
            fields.splice(newFieldPosition,0,newField);
        }

        const onDragEnd = result => {
            if(!result.destination) {
                return;
            }

            //if new field
            if(fields[result.source.index].isNew()) {
                setNewFieldPosition(result.destination.index);
            } else {
                const newForm = new Form({ ...form });
                const newFields = form.formFields.map(field => {
                    const newField = new FormField({ ...field, form: newForm });
                    return newField;
                });
                newForm.formFields = newFields;
                newForm.reorderFields(result.source.index,result.destination.index);
                updateFieldOrder(newForm);
            }
        }

        return (
            <DestroyableCollection 
                destroy={deleteField}
                collection={fields}
                render={({ destroy, allowDestroy }) => (
                    <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId={`form-${form.id}`}>
                            {(droppableProvided, droppableSnapshot) => (
                                <div ref={droppableProvided.innerRef}>
                                    {fields.map((field,index) => (
                                        <Draggable draggableId={`${field.id}`} key={field.resolvedId()} index={index}>
                                            {(draggableProvided, draggableSnapshot) => (
                                                <FormFieldBlock
                                                    t={t}
                                                    deleteField={destroy}
                                                    allowDestroy={allowDestroy}
                                                    formField={field}
                                                    viewType={viewType}
                                                    updateField={updateField}
                                                    createField={createField}
                                                    setMsgRecord={setMsgRecord}
                                                    updateProgressCharts={updateProgressCharts}
                                                    draggableRef={draggableProvided.innerRef}
                                                    dragHandleProps={draggableProvided.dragHandleProps}
                                                    draggableProps={draggableProvided.draggableProps}
                                                    isEditable={isEditable}
                                                    isFillable={isFillable}
                                                    fieldIndex={index}
                                                    viewer={viewer}
                                                    fillFormikProps={fillFormikProps}
                                                    cancelNewField={() => {
                                                        setNewFieldPosition(null);
                                                        setNewField(null);
                                                    }}
                                                />
                                            )}
                                        </Draggable>
                                        
                                    ))}
                                    {droppableProvided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
            )}/>
        )
}

Fields = requestStateHandler({ updateFieldOrder: 'form' })(Fields);

const EditTitleSection = ({ form, viewer, updateForm, setEditMode, t }) => {

    return (
        <FormikWrapper 
            initialValues={form.basicInfoValues(viewer)}
            validationSchema={Yup.object().shape({
                shortName: Yup.string().required(),
                formType: Yup.string().required()
            })}
            submit={values => updateForm(values)}
            successCallback={() => setEditMode(false)}
            initialErrors={{}}
            render={({ handleSubmit, ...rest }) => {
                
                return (
                    <div>
                        <BasicFormFields formikProps={rest} />
                        <SaveSectionButton {...rest} setEditMode={setEditMode} />
                    </div>
                )
            }}
        />
    )
}

const TitleSection = ({ form, viewType, viewer, isEditable, updateForm }) => {
    const { t } = useTranslation();
    const [editMode,setEditMode] = useState(false);

    if(editMode) {
        return (
            <EditTitleSection 
                form={form}
                updateForm={updateForm}
                setEditMode={setEditMode}
                viewer={viewer}
            />
        )
    }

    return (
        <div>
            {isEditable && (<EditButton setEditMode={setEditMode} id={'edit-head-btn'} />)}
            <h1>{form.resolvedName(t)}</h1>
            {form.instructionParagraphs().map((para,i) => (
                <p key={i}>
                    {para}
                </p>
            ))}
        </div>
    )

}

const SubmitBtn = ({ form, formikProps, t, afterSubmitStatus }) => {
    const { submitState, submitForm, setFieldValue, values: { status }, errors } = formikProps;

    if(submitState === 'REQUEST' && status === afterSubmitStatus) {
        return (<CircularProgress />);
    }

    const clickHandler = () => {
        setFieldValue('status',afterSubmitStatus);
        setTimeout(() => submitForm(),1);
    }

    return (
        <Button rounded onClick={clickHandler} disabled={!_.isEmpty(errors)} id="tform-submit-btn">
            <FontAwesomeIcon icon="check" />
            <span>{form.submitBtnText(t)}</span>
        </Button>
    )

}

const imageViewSuffix = '/form_fields/:formFieldId/image'

let TrainerForm = ({ createField, isEditable, ...props }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const [newField,setNewField] = useState(null);
    const [newFieldPosition,setNewFieldPosition] = useState(null);
    const [msgRecord,setMsgRecord] = useState(null);
    const { isFillable, fillForm, form, viewer, initDataEntry, updateProgressCharts } = props;
    const [afterSubmitStatus] = useState(form.afterSubmitStatus(viewer));
    const doneRef = React.useRef(false);

    const { t } = useTranslation();
    const isEditableFinal = isEditable && _.isBlank(newField);

    const finalCreateField = data => createField({ ...data, formOrderPosition: newFieldPosition || form.formFields.length });

    return (
        <React.Fragment>
            <FormikWrapper 
                initialValues={form.fillableValues(viewer)}
                validate={formValidator(viewer,form,afterSubmitStatus,t)}
                valuesFilter={values => form.filterSubmitValues(values)}
                submit={isFillable ? (values => fillForm(values)) : (values => Promise.resolve({ status: 'SUCCESS' }))}
                autoSubmit={isFillable}
                autoSubmitByDefault={isFillable}
                autoSubmitTimeout={750}
                initialErrors={{}}
                successCallback={({ values })=> {
                    if(afterSubmitStatus && afterSubmitStatus === values.status && !doneRef.current) {
                        doneRef.current = true;
                        history.go(-1);
                    }
                }}
                render={({ handleSubmit, ...rest }) => {
                    const showSubmitBtn = afterSubmitStatus && form.status !== afterSubmitStatus;
                    
                    return (
                        <React.Fragment>
                            <Card className="pa15 tform" id={`tform-${form.id}`}>
                                <div className="tform-body">
                                    <TitleSection {...props} isEditable={isEditableFinal} viewer={viewer} />
                                    <Fields 
                                        {...props} 
                                        isEditable={isEditableFinal}
                                        createField={finalCreateField} 
                                        fillFormikProps={rest} 
                                        setNewField={setNewField}
                                        newField={newField} 
                                        setNewFieldPosition={setNewFieldPosition} 
                                        newFieldPosition={newFieldPosition} 
                                        setMsgRecord={setMsgRecord}
                                        updateProgressCharts={updateProgressCharts}
                                    />
                                    <BottomButtons>
                                        {showSubmitBtn && (<SubmitBtn form={form} formikProps={rest} t={t} afterSubmitStatus={afterSubmitStatus} />)}
                                        {form.showMessaging(viewer) && (
                                            <Button 
                                                className={classnames('shadow',{ ml5: showSubmitBtn })} 
                                                outlined 
                                                rounded 
                                                id={`send-form-msg-btn`}
                                                onClick={() => {
                                                    setMsgRecord(form);
                                                    setTimeout(() => history.push(modalPathFor(sendMsgModalSuffix,pathname,{ clientId: form.user.id })),1);
                                                }}
                                            >
                                                <FontAwesomeIcon icon="comments-alt" /> {t('Message Client')}
                                            </Button>)}
                                    </BottomButtons>
                                </div>
                            </Card>
                            {form.isAssignedForm() && (<TakeAssessmentModal form={form} validateForm={rest.validateForm} readOnly={!form.viewerIsAssignee(viewer)} />)}
                            {form.isAssignedForm() && (<WeightCalculatorModal 
                                showVideo 
                                date={form.assignedDate}
                                clientId={form.user.id}
                                type="report"
                                callback={(status,formikBag,data,setCalcStatus,values) => {
                                    form.strengthTestsForExercise(values.exerciseId).forEach(ff => {
                                        initDataEntry({ clientId: form.user.id, formId: form.id, id: ff.id });
                                    })
                                    setCalcStatus('success');
                                }}
                            />)}
                            {form.isAssignedForm() && viewer.isTrainer() && (
                                <SendMessageModal record={msgRecord} />
                            )}
                            {form.isAssignedForm() && (
                                <FormFieldHistoryModal form={form} viewer={viewer} />
                            )}
                        </React.Fragment>
                    )
                }}
            />
            <AddFieldForm form={form} setNewField={setNewField} hide={!isEditableFinal} />
            <ImageViewer
                pathSuffix={imageViewSuffix}
                getImageUrl={({ params: { formFieldId }}) => (form && form.imgSrcFor(formFieldId))} 
            />
        </React.Fragment>
    )

}

const mapDispatchToProps = dispatch => ({
    updateForm: (data,flash) => dispatch(updateTrainerForm(data,flash)),
    updateFieldOrder: form => dispatch(updateFormFieldOrder(form)),
    createField: data => dispatch(createFormField(data)),
    updateField: data => dispatch(updateFormField(data)),
    deleteField: data => dispatch(deleteFormField(data)),
    initDataEntry: data => dispatch(initFormFieldDataEntry(data)),
    updateProgressCharts: data => dispatch(updateProgressChartsLocal(data))
})

export default connect(null,mapDispatchToProps)(TrainerForm)