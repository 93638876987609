import React, { useState } from 'react';
import RouteModal, { ModalHeader, ModalFooter } from 'components/Modal';
import { deloadPathFor, undeloadPathFor } from 'config/paths';
import loadingContainer, { LoadingContainerParent, PDLoading } from 'components/LoadingHOC';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconOptionCard } from 'components/Card';
import { IconNote } from 'components/Typography';
import { deload, undeload } from 'redux/actions';
import { connect } from 'react-redux';


class DeloadSuccess extends React.Component {

    componentDidMount() {
        const { close } = this.props;
        close();
    }

    render() {
        return <PDLoading />;
    }

}

const DefaultDeloadContent = ({ setDeloadType }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="left-align-block" style={{ maxWidth: '400px', lineHeight: '1.2'}}>
                <IconNote variant="info" text={t("Deloading temporarily reduces the difficulty of your workouts to allow your body to recover and rebuild after weeks or months of training")} />
            </div>
            <IconOptionCard 
                className="no-shadow"
                title={t("Reset and rebuild")} 
                subtitle={t("Deload (by 10% or one bodyweight progression) and progress back up over time")} 
                icon={'undo'} 
                id='reset-and-rebuild-btn' 
                onClick={() => setDeloadType('reset')} 
            />
            <IconOptionCard 
                className="no-shadow"
                title={t("One week deload")} 
                subtitle={t("Deload and stop progressing for time before resuming normal progress", { time: t("one week") })} 
                icon={'calendar-alt'} 
                id='one-week-deload-btn' 
                onClick={() => setDeloadType('one_week')} 
            />
            <IconOptionCard 
                className="no-shadow"
                title={t("Two week deload")} 
                subtitle={t("Deload and stop progressing for time before resuming normal progress", { time: t("two weeks") })} 
                icon={'calendar-alt'} 
                id='two-week-deload-btn' 
                onClick={() => setDeloadType('two_week')} 
            />
        </React.Fragment>

    )
}

const DeloadLC = loadingContainer({
    "SUCCESS": DeloadSuccess
},  { type: 'padded' });

const DeloadContent = ({ deload, close }) => {
    const [deloadType,setDeloadType] = useState(null);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b>{t("How do you want to deload?")}</b>
            </ModalHeader>
            <div className="pa10 text-center">
                {!deloadType && (
                    <DefaultDeloadContent setDeloadType={setDeloadType} />
                )}
                {deloadType && (
                    <LoadingContainerParent 
                        component={DeloadLC}
                        preloaded={() => false}
                        load={deload.bind(null,deloadType)}
                        close={close}
                    />
                )}
            </div>
            <ModalFooter defaults>
                <Button rounded noShadow outlined color="primary" className="modal-close">
                    <FontAwesomeIcon icon="ban" />
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </React.Fragment>
    )
}

const mapDispatchToDeloadProps = dispatch => ({
    deload: (type) => dispatch(deload(type))
})

const ConnectedDeloadContent = connect(null,mapDispatchToDeloadProps)(DeloadContent);

export const DeloadModal = ({ basePath }) => {
    return (
        <RouteModal fullWidth limitWidth path={deloadPathFor(basePath)} exact render={({ close }) => (
            <ConnectedDeloadContent close={close} />
        )} />
    )
}

const UndeloadContent = ({ undeload, close }) => {
    const [confirmed,setConfirmed] = useState(false);
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton>
                <b>{t('Undo deload')}: {t("Are you sure?")}</b>
            </ModalHeader>
                {confirmed && (
                    <LoadingContainerParent 
                        component={DeloadLC}
                        preloaded={() => false}
                        load={undeload}
                        close={close}
                    />
                )}
                {!confirmed && (
                    <div className="pb10 pt75 text-center">
                        <Button rounded noShadow outlined color="red" className="modal-close">
                            <FontAwesomeIcon icon="ban" />
                            {t('Cancel')}
                        </Button>
                        <Button rounded noShadow outlined color="primary" className="ml5" id="confirm-undeload-btn" onClick={() => setConfirmed(true)}>
                            <FontAwesomeIcon icon="check" />
                            {t('Undo deload')}
                        </Button>
                    </div>
                )}
        </React.Fragment>
    )
}

const mapDispatchToUndeloadProps = dispatch => ({
    undeload: () => dispatch(undeload())
})

const ConnectedUndeloadContent = connect(null,mapDispatchToUndeloadProps)(UndeloadContent);


export const UndeloadModal = ({ basePath }) => {
    return (
        <RouteModal fullWidth limitWidth path={undeloadPathFor(basePath)} exact render={({ close }) => (
            <ConnectedUndeloadContent close={close} />
        )} />
    )
}