import { applyMiddleware, createStore, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
//import monitorReducersEnhancer from './enhancers/monitorReducers'
//import loggerMiddleware from './middleware/logger'

import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';

import rootReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancers = composeEnhancers(applyMiddleware(thunkMiddleware));

const dataJwtRemover = createTransform(
  (state,key) => {
    if(state) {
      const { jwt, ...rest } = state;
      return rest;
    }
    return state;
  },
  null,
  { whitelist: ['data'] }
)

const trainerJwtRemover = createTransform(
  (state,key) => {
    if(state && state.cache) {
      const { cache, ...rest } = state;
      const { jwt, ...dataCache } = cache;
      return { ...rest, cache: dataCache };
    }
    return state;
  },
  null,
  { whitelist: ['trainerData'] }
)

const persistConfig = {
    key: 'root',
    storage: storage,
    stateReconciler: autoMergeLevel2,
    blacklist: ['ui','actionLogs'],
    transforms: [dataJwtRemover,trainerJwtRemover]
};

const pReducer = persistReducer(persistConfig, rootReducer);

export default function configureStore(initialState) {

  const store = createStore(pReducer, initialState, enhancers);

  const persistor = persistStore(store);

  return { store, persistor };
}