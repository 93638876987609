import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import MFormSelect from 'assets/js/select';
import * as _ from 'lib/utilities';

export class AutoSubmitTextInput extends React.Component {

    componentDidMount() {
        this.prevValue = this.props.value;
    }

    componentDidUpdate() {
        if(this.props.value !== this.prevValue) {
            this.prevValue = this.props.value;
            if(this.curTimeout) {
                clearTimeout(this.curTimeout);
            }
            this.curTimeout = setTimeout(() => {
                this.curTimeout = null;
                this.props.submitForm();
            },this.props.timeout);
        }
    }

    render() {
        const { value, submitForm, timeout, inputProps, submitBeforeUnmount, ...rest} = this.props;
        inputProps.value = value;
        return (
            <TextInput inputProps={inputProps} {...rest} />
        )
    }

    componentWillUnmount() {
        if(this.curTimeout) {
            clearTimeout(this.curTimeout);
        }

        if(this.props.submitBeforeUnmount) {
            this.props.submitForm();
        }
    }

}

class InputField extends React.Component {

    constructor(props) {
        super(props);
        this.inputRef = React.createRef();
    }

    componentDidMount() {
        const { focusOnMount } = this.props;
        if(focusOnMount) {
            this.focusInput();
        }
    }

    render() {
        const { label, noHelperText, onlyErrorText, iconPrefix, error, helperText, inputProps, align, inputProps: { className }, render, className: restClassName, focusOnMount, ...rest } = this.props
        const classes = classnames( { 'input-field': true, [restClassName]: restClassName })
        const inClasses = classnames({'invalid': error, [className]: className })
        const showHelperText = !noHelperText && (!onlyErrorText || !_.isBlank(error));
        return (
            <div className={classes} style={ {textAlign: align || 'left' }} {...rest}>
                {iconPrefix && <FontAwesomeIcon icon={iconPrefix} className="prefix" />}
                {!_.isBlank(label) && (<label className={((inputProps.value || inputProps.value === 0) && inputProps.value !== '' && 'active') + ' no-wrap'} onClick={this.focusInput}>{label}</label>)}
                {render({className: inClasses, inputProps, ref: this.inputRef})}
                {showHelperText && <span className="helper-text" style={{ whiteSpace: 'nowrap'}}>{error || helperText}</span>}
            </div>
        )
    }

    focusInput = () => (this.inputRef.current && this.inputRef.current.focus())
}

export class InstantSubmitField extends React.Component {

    componentDidMount() {
        this.prevValue = this.props.value;
    }

    componentDidUpdate() {
        if(this.props.value !== this.prevValue) {
            this.prevValue = this.props.value;
            this.props.submitForm();
        }
    }

    render() {
        const { submitForm, inputProps, component, ...rest} = this.props;
        const Component = component;
        if(inputProps) {
            inputProps.value = this.props.value;
            rest.inputProps = inputProps;
        }
        return (
            <Component {...rest} />
        )
    }

}

const triggerValueUpdate = (domSelect,newValue) => {
    if(!!domSelect.multiple) {
        const opts = Array.from(domSelect.options);
        opts.forEach(opt => opt.selected = newValue.includes(opt.value))
    } else {
        domSelect.value = newValue;
    }

    setTimeout(() => domSelect.dispatchEvent(new Event('change', { bubbles: true})),0);
}

const reconcileValues = (domSelect,multiple,collection,valMethod,disabledOptions,value) => {
    let selectValues = collection.map(col => `${col[valMethod]}`);
    if(disabledOptions) {
        selectValues = _.filter(selectValues,val => !disabledOptions(val))
    }
    if(multiple) {
        let needsChange = false;
        const val = Array.isArray(value) ? value : [];
        let passedVals = [];
        val.forEach(arrVal => {
            if(!_.isBlank(arrVal)) {
                if(selectValues.includes(`${arrVal}`)) {
                    passedVals.push(`${arrVal}`);
                } else {
                    needsChange = true;
                }
            }
        })
        if(needsChange) {
            triggerValueUpdate(domSelect,passedVals);
        }
    } else {
        if(_.isBlank(value)) {
            if(!_.some(selectValues,val => _.isBlank(val))) {
                triggerValueUpdate(domSelect,selectValues[0] || '');
            }
        } else {
            if(!_.some(selectValues,val => `${val}` === `${value}`)) {
                triggerValueUpdate(domSelect,selectValues[0] || '');
            }
        }
    }
}

export class SelectInput extends React.Component {

    constructor(props) {
        super(props);
        this.selectRef = React.createRef();
        this.browserDefault = props.browserDefault;
    }

    componentDidMount() {
        this.select = MFormSelect.init(this.selectRef.current, {});

        const { collection, multiple, value, valMethod='value', disabledOptions, skipReconcile } = this.props;
        if(!skipReconcile) {
            reconcileValues(this.selectRef.current,multiple,collection,valMethod,disabledOptions,value);
        }
    }

    componentDidUpdate() {
        const { collection, multiple, value, valMethod='value', disabledOptions, skipReconcile } = this.props;
        if(!skipReconcile) {
            reconcileValues(this.selectRef.current,multiple,collection,valMethod,disabledOptions,value);
        }
    }

    render() {
        const { collection, multiple, skipReconcile, selected, disabledOptions, valMethod='value', textMethod='text', className, browserDefault, setFieldValue, onChange, ...rest } = this.props;
        const classNames = classnames({ [className]: className, "browser-default": this.browserDefault});
        
        
        return (
            <select ref={this.selectRef} multiple={multiple} className={classNames} onChange={e => {
                console.log(`handling change event on ${e.target.name}`);
                onChange(e);
            }} {...rest}>
                {collection.map((option,index) => {
                    const text = option[textMethod];
                    const value = option[valMethod];
                    const dis = disabledOptions ? disabledOptions(value) : false;
                    
                    return (<option disabled={dis} value={value} key={value}>{text}</option>)
                })}
            </select>
        )
    }

    componentWillUnmount() {
        if(!this.browserDefault && this.select && this.selectRef.current) {
            this.select.destroy();
        }
    }

}

export const NumericSelectInput = ({ setFieldValue, name, ...rest }) => {
    return (
        <SelectInput {...rest} onChange={e => setFieldValue(name,_.castNumericInput(e.target.value))} name={name} />
    )
}

export const limitPatternHandler = (pattern) => (e) => {
    const k = String.fromCharCode(e.which);
    if(!k.match(pattern)) {
        e.preventDefault()
    }
}

function InputCore({ defaultClass, type, inputProps, limitPattern, ...props }) {
    const { className } = props;

    if(type === 'number' && inputProps && !_.isBlank(inputProps.value)) {
        inputProps.value = Number(inputProps.value);
        inputProps.step = 'any';
    }

    const classNames = classnames({[defaultClass]: defaultClass, [className]: className})
    return (
        <InputField {...props} inputProps={inputProps} className={classNames} render={({ className, inputProps, ref}) => {
            return (
                <input type={type} onKeyPress={limitPattern && limitPatternHandler(limitPattern)} {...inputProps} className={className} ref={ref} />
            );
        }} />
    )
}

export function NumberInput(props) {
    return (<InputCore defaultClass="medium-number" type="number" {...props} />)
}

export function PhoneInput(props) {
    return (<InputCore defaultClass="medium-number" type="tel" {...props} />)
}

export function SmallerNumberInput(props) {
    return (<InputCore defaultClass="smaller-number" type="number" {...props} />)
}

export function SmallerTextInput(props) {
    return (<InputCore defaultClass="smaller-text" type="text" {...props} />)
}

export function ExtraSmallNumberInput(props) {
    return (<InputCore defaultClass="small-size" type="number" {...props} />)
}

export function ExtraSmallTextInput(props) {
    return (<InputCore defaultClass="small-size" type="text" {...props} />)
}

export function SmallTextInput(props) {
    return (<InputCore defaultClass="small-text" type="text" {...props} />)
}

export function FullWidthNumberInput(props) {
    return (<InputCore type="number" {...props} />)
}

export function NumberCell({ error, limitPattern, className, ...props }) {
    const classNames = classnames("input-cell browser-default", { error: error, [className]: className })

    return (
        <input className={classNames} onKeyPress={limitPattern && limitPatternHandler(limitPattern)} type="number" step="any" {...props} />
    )
}

export function TextCell({ error, className, limitPattern, ...props }) {
    const classNames = classnames("input-cell browser-default", { error: error, [className]: className })    

    return (
        <input className={classNames} type="text" onKeyPress={limitPattern && limitPatternHandler(limitPattern)} {...props} />
    )
}

export function TelCell({ error, className, limitPattern, ...props }) {
    const classNames = classnames("input-cell browser-default", { error: error, [className]: className })    

    return (
        <input className={classNames} onKeyPress={limitPattern && limitPatternHandler(limitPattern)} type="tel" {...props} />
    )
}

export function SelectCell({ className, ...props }) {
    const classNames = classnames("input-cell",{ [className]: className});
    return (
        <SelectInput className={classNames} {...props} />
    )
}

const onTimeClick = (e) => {
    e.target.setSelectionRange(999,999)
}

const onTimeFocus = (e) => {
    e.target.setSelectionRange(999,999)
}

const onTimeChange = (onChange,allowRange) => (e) => {
    let vals,addDash;
    if(allowRange && e.target.value.includes('-')) {
        vals = e.target.value.split('-').slice(0,2);
        addDash = vals.length <= 1;
    } else {
        vals = [e.target.value];
        addDash = false;
    }
    let newVals = vals.map((val,index) => {
        if(_.isBlank(val) && index > 0) {
            return val;
        }
        let strippedVal = val.replace(/[^0-9]/g,'').replace(/^0+/,'');
        const leadingZerosNeeded = Math.max(3 - strippedVal.length,0);
        for(let i=0; i < leadingZerosNeeded; i++) {
            strippedVal = '0' + strippedVal;
        }
    
        const flippedVal = strippedVal.split('').reverse().join('')
        const segments = flippedVal.match(/.{1,2}/g);
        const reversedRes = segments.slice(0,3).join(':') + segments.slice(3,segments.length).join('');
        return reversedRes.split('').reverse().join('');
    })

    newVals = _.filter(newVals,(val,index) => (index === 0 || val !== '0:00'))

    e.target.value = `${newVals.join('-')}${addDash ? '-' : ''}`;
    e.target.setSelectionRange(999,999);
    onChange && onChange(e);
}

export function TimeField({ component: Component, onChange, allowRange, ...props }) {
    return (<Component onChange={onTimeChange(onChange,allowRange)} onFocus={onTimeFocus} onClick={onTimeClick} {...props} />)
}

class TextArea extends React.Component {

    constructor(props) {
        super(props);
        this.areaRef = React.createRef();
    }

    componentDidMount() {
        const { stopModalDrag } = this.props;
        const { focusOnMount } = this.props;
        if(stopModalDrag) {
            this.areaRef.current.addEventListener('touchstart',this.stopModalDrag);
            this.areaRef.current.addEventListener('mousedown',this.stopModalDrag);
        }

        if(focusOnMount) {
            this.areaRef.current && this.areaRef.current.focus();
        }
    }

    render() {
        let { maxLength, stopModalDrag, rows, height, focusOnMount, ...props } = this.props;
        const style = height ? { width: '100%', height } : { width: '100%' };
        maxLength = maxLength || 1000;

        return (
            <textarea ref={this.areaRef} maxLength={maxLength} style={style} rows={rows || 3} {...props} />
        )
    }

    stopModalDrag = (e) => {
        if(this.areaRef.current.scrollHeight > this.areaRef.current.clientHeight) {
            e.stopPropagation();
        }
    }

    componentWillUnmount() {
        const { stopModalDrag } = this.props;
        if(stopModalDrag) {
            this.areaRef.current.removeEventListener('touchstart',this.stopModalDrag);
            this.areaRef.current.removeEventListener('mousedown',this.stopModalDrag);
        }
    }
}

export function TelInput(props) {
    return (<InputCore type="tel" {...props} />)
}

export function FullTimeField({ inputProps, allowRange, ...rest }) {
    const { onChange, onFocus, onClick, ...restInProps } = inputProps;
    return (<TelInput inputProps={{ onChange: onTimeChange(onChange,allowRange), onFocus: onTimeFocus, onClick: onTimeClick, ...restInProps }} {...rest} />)
}

export { TextArea };

export default function TextInput(props) {
    return (<InputCore type="text" {...props} />)
}