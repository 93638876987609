import React from 'react';
import ReactDOM from 'react-dom';
import Tooltip from 'assets/js/tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { get, isBlank, elemIsAtTop } from 'lib/utilities';

export default class Tooltipped extends React.Component {

    constructor(props) {
        super(props);
        this.tooltippedRef = React.createRef();
    }

    componentDidMount(){
        const { disableTip } = this.props;
        if(!disableTip) {
            this.resetTip();
        }
    }

    resetTip = () => {
        const { autoShowAfter } = this.props;
        if(this.tooltip) {
            this.tooltip.destroy();
            this.tooltip = null;
        }
        const autoOpen = !isBlank(autoShowAfter);
        this.initializeTip();
        if(autoOpen && !this.tipShowHandler) {
            this.tipShowHandler = setTimeout(this.handleTooltipShow,autoShowAfter);
        }
    }

    componentDidUpdate(prevProps) {
        const oldCont = get(prevProps,'options.html');
        const newCont = get(this.props,'options.html');
        const { disableTip } = this.props;

        if(disableTip) {
            if(this.tooltip) {
                clearTimeout(this.tipShowHandler);
                this.tooltip.destroy();
                this.tooltip = null;
            }
        } else if(prevProps.disableTip) {
            this.resetTip();
        } else if(typeof oldCont === 'string') {
            if(oldCont !== newCont) {
                this.resetTip();
            }
        }
    }

    render() {
        const { children, options, component, autoShowAfter, dismissCallback, onlyShowVisible, disableTip, passThroughProps, ...rest } = this.props;
        const Component = component || 'div';
        return (
            <Component ref={this.tooltippedRef} {...rest} {...passThroughProps}>
                {children}
            </Component>
        )
    }

    componentWillUnmount() {
        if(this.tooltip) {
            this.tooltip.destroy();
        }
        clearTimeout(this.tipShowHandler);
    }

    handleTooltipShow = () => {
        const { disableTip, options: { position='bottom' }={} } = this.props;
        
        if(!disableTip) {
            const tooltip = this.tooltippedRef.current;
            if(tooltip && this.isShowable()) {
                const rect = tooltip.getBoundingClientRect();
                const topOffset = position === 'bottom' ? 10 : 100;
                const bottomOffset = position === 'bottom' ? 100 : 10;
                if(rect.bottom <= window.innerHeight - bottomOffset && rect.top > topOffset) {
                    if(elemIsAtTop(tooltip)) {
                        this.tooltip.open();
                        return;
                    }
                }
            }
            this.tipShowHandler = setTimeout(this.handleTooltipShow,1000);
        }
    }

    initializeTip() {
        const { autoShowAfter, dismissCallback, options: { html } } = this.props;
        const autoOpen = !isBlank(autoShowAfter);
        let newHtml = html;
        let hasActions = false;
        if(React.isValidElement(html)) {
            let contents = document.createElement('div');
            ReactDOM.render(html,contents);
            newHtml = contents;
        } else if(typeof html === 'string') {
            let remove = document.createElement('div');
            remove.classList.add('remove-btn');
            ReactDOM.render((<FontAwesomeIcon icon="times" />),remove);
            remove.onclick = () => { 
                this.dismiss();
                if(dismissCallback) { 
                    dismissCallback() 
                };  
            };

            let contents = document.createElement('div');
            contents.innerHTML = html;
            contents.prepend(remove);
            newHtml = contents;
            hasActions = true;
        }
        const opts = hasActions ? { ...this.props.options, classes: [...(this.props.options.classes || []), 'has-actions'] } : this.props.options;
        this.tooltip = Tooltip.init(this.tooltippedRef.current,{ ...opts, autoOpen, html: newHtml });
    }

    dismiss = () => {
        if(this.tooltip) {
            this.tooltip.close();
        }
    }

    isShowable = () => {
        const { onlyShowVisible } = this.props;
        if(!onlyShowVisible) {
            return true;
        }
        const tooltip = this.tooltippedRef.current;
        if(tooltip) {
            var style = window.getComputedStyle(tooltip);
            return (style.display !== 'none')
        }
        return false;
    }
}

export const IconTip = ({ icon, msg, size, className, containerClassName, id, inline, tipOpts }) => {
    const classNames = classnames("low-opacity-icon vert-align-mid", {[className]: className})
    const containerClassNames = classnames('clickable-icon vert-align-mid',{ [containerClassName]: containerClassName})

    return (
        <Tooltipped 
            key={msg}
            options={{ 
                html: msg,
                classes: ['square'],
                ...tipOpts
            }}
            component='span'
            className={containerClassNames}
        >
            <FontAwesomeIcon icon={icon} className={classNames} id={id ? id : ''} size={size || '2x'} style={inline && { position: 'relative', top: '-2px', left: '-8px'}}/>
        </Tooltipped>
    )
}

export const TooltippedText = ({ text, tip }) => {

    return (
        <Tooltipped 
            key={tip}
            options={{ 
                html: tip,
                classes: ['square']
            }}
            component='div'
            className="clickable position-relative"
        >
            <span className="underline">{text}</span> <FontAwesomeIcon icon="question-circle" className="low-opacity-icon vert-align-mid" size='1x' style={{ position: 'relative', top: '-2px', left: '0px'}}/>
        </Tooltipped>
    )

}

export const PlainTippedText = ({ children, tip, comp, className }) => {

    return (
        <Tooltipped 
            key={tip}
            options={{ 
                html: tip,
                classes: ['square'],
                noClick: true
            }}
            component={comp || 'div'}
            className={className}
        >
           {children}
        </Tooltipped>
    )

}

export const FlexIconTip = ({ icon, msg, size, id, className }) => {
    return (
        <Tooltipped 
            key={msg}
            options={{ 
                html: msg,
                classes: ['square']
            }}
            component='div'
            className={classnames('flex-icon-tip', { [className]: className } )}
        >
            <FontAwesomeIcon icon={icon} className='low-opacity-icon' id={id ? id : ''} size={size || '2x'} />
        </Tooltipped>
    )
}

const UpperRightTooltipped = ({ text, tip }) => {

    return (
        <Tooltipped className={"inline-block position-relative"} key={tip} options={{ html: tip, classes: ['square'] }}>
            {text} {tip && <FontAwesomeIcon icon='question-circle' className="q-icon" />}
        </Tooltipped>
    )
}

export const TooltippedLabel = ({ className, center, ...props }) => {
    const classNames = classnames('font-grey',{ 'text-left': !center, 'text-center': center})

    return (
        <div className={classNames}>
            <UpperRightTooltipped {...props} />
        </div>
    )
}