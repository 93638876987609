import React from 'react';
import { useTranslation } from 'react-i18next';
import { offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, swapRecipePathFor, addRecipePathFor, addRecipeMatch, swapRecipeMatch, userMealMatch, viewRecipeReplacementMatch, userMealPathFor, emailPaywallMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { userWithMealPlanSelector, getMealInfoMatrixSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { Redirect } from 'components/Routing';
import { EditMealLoader } from 'partials/EditMealCore';
import * as _ from 'lib/utilities';
import { randomizeSides, replaceRecipe, setupReplaceRecipe } from 'redux/actions';
import { setRecipeMealServings } from 'redux/actions';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { resolvedHomePath } from 'redux/helpers';
import { ExitMealPlanButton } from 'partials/Utilities';

const transitionMap = {
    rules: [
        [[...mealPlanSlideOutPaths, offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch],transitions.slideOut],
        [[addRecipeMatch,swapRecipeMatch,userMealMatch,viewRecipeReplacementMatch,...emailPaywallMatches], transitions.slideOver]
    ]
};

let EditMealWrapper = ({ mealInfoMatrix, userMeal, destroyDish, randomizeMeal, setupReplaceRecipe, setServings, history }) => {

    if(userMeal) {
        const weeklyMeal = mealInfoMatrix.matchingMealForUserMeal(userMeal);
        if(weeklyMeal && !weeklyMeal.isProteinSupp()) {
            return (
                <EditMealLoader
                    mealInfoMatrix={mealInfoMatrix} 
                    weeklyMeal={weeklyMeal} 
                    meal={weeklyMeal.initMeal()}
                    destroyDish={destroyDish.bind(null,weeklyMeal)}
                    randomizeMeal={randomizeMeal.bind(null,weeklyMeal)}
                    setServings={setServings.bind(null,weeklyMeal)}
                    getRecipeCardPath={(recipeId) => userMealPathFor(weeklyMeal.id(),recipeId)}
                    goToReplaceRecipe={(recipeId) => {
                        setupReplaceRecipe({ recipeId, userMealId: weeklyMeal.id() });
                        history.push(recipeId ? swapRecipePathFor(weeklyMeal.id(),recipeId) : addRecipePathFor(weeklyMeal.id()))
                    }}
                />
            )
        }
    }

    return (
        <Redirect to={resolvedHomePath()} />
    )
}

const mapStateToCoreProps = (state,props) => {
    let { user, match: { params: { category, date }}} = props;
    const userMeal = user.findUserMeal(category,date);
    const week = user.weekForUserMeal(userMeal);
    const mealInfoMatrixSelector = getMealInfoMatrixSelector(week);
    return {
        mealInfoMatrix: mealInfoMatrixSelector(state),
        userMeal
    }
}

const mapDispatchToCoreProps = dispatch => ({
    destroyDish: (weeklyMeal, recipeMeal) => dispatch(replaceRecipe(weeklyMeal,{ recipeId: recipeMeal.recipeId })),
    randomizeMeal: (weeklyMeal,excludedIds) => dispatch(randomizeSides(weeklyMeal,{ excludedIds })),
    setupReplaceRecipe: data => dispatch(setupReplaceRecipe(data)),
    setServings: (weeklyMeal,data) => dispatch(setRecipeMealServings(weeklyMeal,data))
})

EditMealWrapper = connect(mapStateToCoreProps,mapDispatchToCoreProps)(EditMealWrapper)

const EditWeeklyMealPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, user, match, history, match: { params: { category }} } = props;

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t('Edit Meal', { meal: t(_.capitalize(category))})}
            navChildren={<ExitMealPlanButton />}
        >
            <EditMealWrapper user={user} match={match} history={history} />
        </SimpleNavPage>
    )
}

const mapStateToProps = state => ({
    user: userWithMealPlanSelector(state)
})

export default connect(mapStateToProps)(EditWeeklyMealPage)