import * as api from 'lib/api';
import * as _ from 'lib/utilities';
import { basename, history } from './paths';
import { setVideoAutoplay, deviceReady, initProSuccess, setAnalyticsData, registerAttribution, setViewportHeight } from 'redux/actions';

const logSubscriptionEvent = (type) => {
    const tPrice = 52;
    const yPrice = 80;
    let mult = 0.45;

    const price = type.includes('threemonths') ? tPrice*mult*0.85 : yPrice*mult*0.85;

    try {
        window.facebookConnectPlugin.logPurchase(price,'USD',() => {},() => {});
    } catch(e) {}
    try {
        if(_.hasAppsFlyer()) {
            window.plugins.appsFlyer.logEvent('af_purchase', {'af_currency' : 'USD', 'af_revenue' : `${price}`});
        }
    } catch(e) {}
    try {
        window.cordova.plugins.firebase.analytics.logEvent("subscribed", {value: price});
    } catch(e) {}
}

const supportsVideoAutoplay = function(callback) {

    if (typeof callback !== "function") return false;

    var v = document.createElement("video");

    if('play' in v) {
        var vRes = v.play();
        if(vRes instanceof Promise) {
            vRes.catch(() => {});
        }

        if(!v.paused || vRes instanceof Promise) {
            callback(true);
        } else {
            callback(false);
        }
    } else {
        callback(false);
    }

};

const initAppsFlyer = (store) => {
    const attrSuccess = (result) => {
        const data = JSON.parse(result)['data']
        if (data) {
            if (data['af_status'] === 'Non-organic') {
                if(data['media_source']) {
                    const source = data['media_source'];
                    let keyword;
                    if(data['is_fb'] === true || data['is_fb'] === 'true') {
                        keyword = data['ad_id'];
                    } else {
                        keyword = data['af_keywords'];
                        if(_.isBlank(keyword)) {
                            keyword = data['af_keyword_id'] || data['keyword_id'];
                        }
                    }
                    if(data['campaign'] && data['campaign'].includes('rtarg')) {
                        store.dispatch(registerAttribution({lastSource: `${data['campaign']}_${keyword}` }))
                    } else {
                        store.dispatch(registerAttribution({original_source: source, original_campaign: data['campaign'], original_keyword: keyword, result: result}))
                    }
                }
            }
        }

    }
 
    const attrFail = (result) => {};

    const opts = {
        devKey: 'w2XYZV4uqKNFmye5dMYF2B',
        isDebug: false,
        onInstallConversionDataListener: true
    }

    if(window.cordova.platformId === 'ios') {
        opts.appId = '1326334081';
    }
    
    //don't want to trigger an appsflyer session when we're opening in the background due to HK update or other listener
    if(document.visibilityState !== 'hidden') {
        window.plugins.appsFlyer.initSdk(opts, attrSuccess, attrFail);
    }
}

const aliasFromProductId = id => id.replace(`${_.packagePrefix()}.`,'');

const initIAPs = (store,reduxStore) => {
    const { ProductType, ErrorCode, LogLevel } = window.CdvPurchase;
    store.verbosity = LogLevel.DEBUG;
    for(let planType of ['threemonths','biannually','annually']) {
        store.register({
            id: `${_.packagePrefix()}.${planType}now`,
            type: ProductType.PAID_SUBSCRIPTION,
            platform: _.getIapPlatform()
        })
    }

    if(_.isAndroid()) {
        for(let planType of ['threemonths2022','annually2022']) {
            store.register({
                id: `${_.packagePrefix()}.${planType}`,
                type: ProductType.PAID_SUBSCRIPTION,
                platform: _.getIapPlatform()
            })
        }
    }

    //hacky fix to https://github.com/j3k0/cordova-plugin-purchase/issues/1055
    //store.once("product").expired(prod => store.ready(true));

    store.validator = (receipt,callback) => {
        api.verifyIAP(receipt).then((response) => {
            if(response.ok) {
                callback({ ok: true, data: { latestReceipt: true, id: receipt.id, transaction: receipt.transaction } });
                reduxStore.dispatch(initProSuccess({ proExpires: response.data.proExpires }));
            } else if(response.data && response.data.code === 6778003) {
                callback({ ok: true, data: { latestReceipt: true, id: receipt.id, transaction: receipt.transaction } });
            } else {
                let message = response.data.error ? response.data.error.message : 'Receipt verification failed';
                callback({ ok: false, code: response.data.code, message, data: { latestReceipt: true } });
            }
        }).catch(({ message }) => {
            callback({ ok: false, code: ErrorCode.ERR_VERIFICATION_FAILED, message });
        });
    }

    store.when().approved(function(transaction) {
        if (transaction.products && transaction.products[0] && (transaction.products[0].id === _.androidPackageName() || transaction.products[0].id === _.iosPackageName())) {

            transaction.finish();
            return;
        }

        if(store.purchaseApproval) {
            store.purchaseApproval(transaction);
        }

        transaction.verify();
    });
  
    store.when().verified(function(receipt) { 
        receipt.finish();
        if (store.verificationSuccess) {
            store.verificationSuccess(receipt);
        }

        if(store.logOrder) {
            store.logOrder = null;
            logSubscriptionEvent(aliasFromProductId(receipt.sourceReceipt.lastTransaction().products[0].id));
        }
    });

    store.when().unverified(({ receipt, payload: err }) => {
        if(store.verificationFailure) {
            store.verificationFailure(err);
        }
    
        if (err.message && err.message.includes('duplicate')) {
            const str = err.message;
            const vals = str.split('|');
            const prod = _.isAndroid() ? store.get(`${_.packagePrefix()}.annually2022`,_.getIapPlatform()) : store.get(`${_.packagePrefix()}.annuallynow`,_.getIapPlatform());
            prod.duplicate = true;
            prod.duplicateInfo = { email: vals[1], provider: vals[2] };
        }
    })

    store.initialize().then(() => store.update()).then(() => store.restorePurchases());
}

const initSwipeAnimHandlers = () => {

    let xDown = null;                                                        
    let yDown = null;
    var animOverrideTimeout = null;
    
    const handleTouchStart = (e) => {
        const firstTouch = e.touches.item(0);
        xDown = firstTouch.clientX;                                      
        yDown = firstTouch.clientY; 
    }
    
    const handleTouchMove = (e) => {
        if ( _.isBlank(xDown) || _.isBlank(yDown) ) {
            return;
        }
    
        var xUp = e.touches[0].clientX;                                    
        var yUp = e.touches[0].clientY;
    
        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        var screenWidth = ((window.screen && (window.screen.availWidth || window.screen.width)) || window.innerWidth);
        var edgeThreshold = screenWidth*0.1;
        var threshold = 80;
    
        if ( Math.abs(xDiff) > threshold && Math.abs( xDiff ) > Math.abs( yDiff ) ) {/*most significant*/

            if ((xDiff > 0 && xDown <= edgeThreshold) || (xDiff < 0 && xDown >= (screenWidth - edgeThreshold))) {
                if(animOverrideTimeout) {
                    clearTimeout(animOverrideTimeout);
                }
                document.body.classList.add('disable-transitions');
                animOverrideTimeout = setTimeout(() => {
                    document.body.classList.remove('disable-transitions');
                    animOverrideTimeout = null;
                },500)
            }                       
        } 

                     
    }

    document.addEventListener('touchstart', handleTouchStart, false);        
    document.addEventListener('touchmove', handleTouchMove, false);
    document.addEventListener('touchend',() => {
        /* reset values */
        xDown = null;
        yDown = null; 
    }, false);
}

const initKeyboardShowHandler = () => {

    window.addEventListener('keyboardWillShow',() => {
        document.body.classList.add('keyboard-showing')
    })
    window.addEventListener('keyboardDidHide',() => {
        document.body.classList.remove('keyboard-showing');
        try {
            document.querySelector('.app-scroll-container').scrollTop = document.querySelector('.app-scroll-container').scrollTop
        } catch(e) {

        }//hacky fix for weird bug where if you scroll down with keyboard open you can't scroll back up when it closes in android webview
    })
}

const initUncaughtErrorLogging = (store) => {
    window.addEventListener('unhandledrejection', (e) => {
        try {
            const error = e.reason || {};
            const now = new Date();
            const discardError = (error.name === undefined || error.name === 'AbortError') && _.isBlank(error.stack);
            if(!discardError && (!window.lastAppError || (now.getTime() - window.lastAppError.getTime()) > 10000)) {
                api.logError({ 
                    name: `${error.name} (unhandled rejection)`, 
                    message: error.message, 
                    backtrace: error.stack, 
                    url: window.location.pathname, 
                    platform: _.platformString(), 
                    userAgent: navigator.userAgent,
                    clientState: JSON.stringify(store.getState())
                })
                window.lastAppError = new Date();
            }
        } catch(e) {
            console.log('oh well');
        }
    })
}

const initMixpanel = () => {
    const { mixpanel, mixpanelToken, appVersion, appBrandName } = window;
    if(mixpanel) {
        mixpanel.init(mixpanelToken, { debug: process.env.NODE_ENV !== 'production', track_pageview: false, persistence: 'localStorage', loaded: mixpanel => {
            mixpanel.register({ app_platform: _.platformString(), app_version: appVersion, app_name: appBrandName });
            if(!_.isMixpanelDeviceRegistered()) {
                api.registerMixpanelDeviceId(mixpanel.get_distinct_id());
            }
        } });
    }
}

const goToDeepLink = path => {
    if(basename !== '/') {
        const replaceRegex = new RegExp(`^${basename}`);
        path = path.replace(replaceRegex,'');
    }
    _.clearCurrentUser();
    history.push(path);
}

const setWih = store => () => {
    const height = window.visualViewport.height;
    document.documentElement.style.setProperty('--wih', `${height}px`);
    store.dispatch(setViewportHeight(height));
    window.scrollBy(0,-9000); //weird hack needed to fix issue on iPhone Safari where keyboard opening permanently scrolls window down
}

const initApp = (store) => {
    window.webPaymentMethod = 'basic';

    if(process.env.REACT_APP_TEST === 'true') {
        document.body.classList.add('allow-overflow');
    }

    window.addEventListener("beforeinstallprompt", (event) => {
        window.pwaInstallPromptEvent = event;
    });

    document.addEventListener('deviceready', function() {
        window.cordova.plugin.http.setDataSerializer('utf8');
        window.cordova.plugin.http.setHeader('Accept','application/json');
        setTimeout(() => { 
            store.dispatch(deviceReady()); 
        },1);

        initKeyboardShowHandler();

        let fbActivateSuccess = function() {};
        let fbActivateFail = function() {};

        window.facebookConnectPlugin.activateApp(fbActivateSuccess,fbActivateFail);

        initIAPs(_.getIapStore(),store);

        if(_.hasAppsFlyer()) {
            initAppsFlyer(store);
        }

        if(_.isIOS() && _.appVersion() >= 241 && window.asaAttribution) {
            window.asaAttribution.fetchAttributionToken(token => {
                api.setAsaAttribution(token);
            }, error => {
                console.log(error);
            })
        }

        if(_.appVersion() >= 220) {
            if(window.cordova.plugins.firebase.messaging.handleOpenPayload) {
                window.cordova.plugins.firebase.messaging.handleOpenPayload((payload) => {
                    window.cordova.plugins.firebase.messaging.clearNotifications();
                    if(!_.isBlank(payload['click-url'])) {
                        goToDeepLink(payload['click-url']);
                    }
                });
            }
    
            if(window.cordova.plugins.firebase.dynamiclinks) {
                //need all this weirdness because on iOS onDynamicLink only called if app already in background. Meanwhile on Android, getDynamic link only fires if app starting cold
                let linkHandled = false;
                const resetLinkHandled = () => (linkHandled = false);
                const linkHandler = type => payload => {
                    if(!linkHandled && payload && payload.deepLink) {
                        linkHandled = true;
                        const url = new URL(payload.deepLink);
                        goToDeepLink(url.pathname);
                        setTimeout(resetLinkHandled,1000);
                    }
                }
                
                window.cordova.plugins.firebase.dynamiclinks.getDynamicLink().then(linkHandler('get'));
                window.cordova.plugins.firebase.dynamiclinks.onDynamicLink(linkHandler('on'));
            }
        }
    })

    if(window.isCordova) {
        document.addEventListener("deviceready",() => {
            store.dispatch(setAnalyticsData({ appPlatform: window.cordova.platformId }));
            if(window.cordova.platformId === 'ios') {
                initSwipeAnimHandlers();
            }
            
            //need timeout because otherwise locale not set yet and breaks api call
            setTimeout(() => {
                initMixpanel();
                _.mixpanelTrack('App Open',{ visibility_state: document.visibilityState });
            },1);
        })
    } else {
        setTimeout(() => initMixpanel(),1); //need timeout because otherwise locale not set yet and breaks api call

        if (navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', (event) => {
                if (!event.data.action) {
                  return;
                }
              
                if(event.data.action === 'redirect-from-notificationclick') {
                    goToDeepLink(event.data.clickUrl);
                }
            })
            // Make sure this code block is run once your page is fully loaded and ready
            navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then(registration => {
                if(registration && registration.active) {
                    registration.active.postMessage("client-ready");
                    registration.getNotifications().then((notifications) => {
                        notifications.forEach(notification => notification.close());
                    });
                }
            });
          }

        store.dispatch(setAnalyticsData({ appPlatform: 'web' }));

        if(window.skipTracking === 'true') {
            //make fbq a noop
            window.fbq = () => {};
        } else {
            const fbqInit = function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)};
            fbqInit(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');
            window.fbq('init', `${process.env.REACT_APP_FB_PIXEL_ID}`);
        }

    }

    supportsVideoAutoplay((result) => {
        store.dispatch(setVideoAutoplay(result));
    });

    //flaky bullshit
    setInterval(() => {
        if(document.body.classList.contains('modal-open') && !document.querySelector('.modal.open') && !document.querySelector('.modal.closing')) {
            document.body.classList.remove('modal-open');
        }
    },3500)

    initUncaughtErrorLogging(store);


    setWih(store)();
    window.visualViewport.addEventListener('resize', () => {
        setWih(store)();
    });
    
}

export default initApp;
