import { muteWorkoutTimersSelector } from 'redux/selectors';
import { CountdownTimer as CountdownCore } from 'components/Timers';
import { connect } from 'react-redux';
import { muteTimers } from 'redux/actions';

const mapStateToProps = state => ({
    soundOn: !muteWorkoutTimersSelector(state)
})

const mapDispatchToProps = dispatch => ({
    muteTimers: mute => dispatch(muteTimers(mute))
})

const CountdownTimer = connect(mapStateToProps)(CountdownCore);

const UnconnectedMuteTimerButton = ({ soundOn, muteTimers, render }) => {
    return render({ soundOn, muteTimers })
}
const MuteTimerButton = connect(mapStateToProps,mapDispatchToProps)(UnconnectedMuteTimerButton);

export { CountdownTimer, MuteTimerButton }