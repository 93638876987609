import { RecordBase, allMacrosWithCals, registerInflection } from 'lib/record-base';
import { FoodWeight } from 'lib/food-weight';
import * as _ from 'lib/utilities';

export class Food extends RecordBase {
    static NAME = 'Food'
    static ASSOCS = {
        foodWeights: { type: 'hasMany', sortAttr: 'sortScore' },
        owner: { type: 'belongsTo', tableName: 'users' }
    }

    static newDefault(initialName) {
        const obj = { commonName: (initialName || '') };
        allMacrosWithCals.forEach((macro) => (obj[macro] = ''))
        return obj;
    }

    static foodWeightsFromWeights(weights) {
        return weights.map(weight => (new FoodWeight({ measurement: weight[0], seq: weight[1], grams: weight[2] })));
    }

    constructor(obj) {
        super(obj);
        if(!_.isBlank(this.carbs) && !_.isBlank(this.fiber)) {
                this.carbs = this.carbs - this.fiber;
        }
    }

    foodWeightFor(seq) {
        return _.find(this.foodWeights, fw => fw.seq === seq)
    }

    weightsForForm(alwaysInclude=null,allowNew=false,t=null) {
        let col = _.compact(this.foodWeights.map(foodWeight => (foodWeight.isActive(alwaysInclude) ? [foodWeight.measurement,foodWeight.seq,foodWeight.grams,foodWeight.amount] : null)))
        if(allowNew) {
            col = [ ...col, [`+ ${t('New unit')}`,''] ];
        }
        return col;
    }

    defaultSeq() {
        if(this.foodWeights && this.foodWeights.length > 0) {
            return this.foodWeights[0].seq;
        } else {
            return 1;
        }
    }

    defaultServings() {
        if(this.foodWeights && this.foodWeights.length > 0) {
            return this.foodWeights[0].amount || 1;
        } else {
            return 1;
        }
    }

    macroHash(seq,servings,mult) {
        if(!_.isBlank(this.id)) {
            const foodWeight = _.find(this.foodWeights,foodWeight => (foodWeight.seq === seq))
            if(foodWeight) {
                const hash = this.macroHashForGrams(foodWeight.grams*servings*(_.isBlank(mult) ? 1 : mult));
                hash.servingInfo = `${servings} ${_.abbrText(foodWeight.measurement,25)} (${foodWeight.gramStr(servings)})`;
                return hash;
            }
        }
        return null;
    }

    macroHashForGrams(grams) {
        const mult = grams/100;
        const hash = {};
        allMacrosWithCals.forEach(type => { hash[type] = _.isNumeric(this[type]) ? Math.round(Number(this[type])*mult) : 0 });
        return hash;
    }

    macroSummary(seq,servings,t) {
        const hash = this.macroHash(seq,servings);
        if(hash && !_.isEmpty(hash)) {
            return `${hash.servingInfo} - ${hash.calories}${t('cals')}, ${hash.protein}${t('protein_abbr')}, ${hash.carbs}${t('carb_abbr')}, ${hash.fat}${t('fat_abbr')}`;
        }
        return this.longDescription;
    }

    effectiveId() {
        if(this.fatsecretId) {
            return "fs" + this.fatsecretId;
        }
        return this.id;
    }

    milliliters(grams) {
        let result = null;
        for(let foodWeight of (this.foodWeights || [])) {
            result = foodWeight.milliliters(grams/foodWeight.grams,true);
            if(result) {
                break;
            }
        }
        return result;
    }

    formValues() {
        return { commonName: this.name, ..._.pick(this,['id', ...allMacrosWithCals]) }
    }

    foodWeightFormValues() {
        return this.foodWeights.map(foodWeight => (_.pick(foodWeight,['id','amount','measurement','grams','isDefault','seq'])))
    }
}

registerInflection('food','foods',Food);