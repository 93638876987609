import React, { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { pickMealDaysModalPathFor, addPeopleToMealModalPathFor, adjustServingsModalMatchFor, editTempMealPath, adjustServingsModalPathFor } from 'config/paths';
import { paywallPathFor } from 'redux/helpers';
import Button, { BottomButtons, ConfirmActionButton, DefaultAsyncActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SelectDaysModal } from 'partials/PickDaysModal';
import { useHistory } from 'react-router-dom';
import ActivityContainer from 'components/ActivityContainer';
import { dateFormat } from 'config/settings';
import { AddPeopleToMealModal } from 'partials/AddPeopeModal';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import { Loader } from 'components/LoadingHOC';
import { dismissTooltip, loadRecipes } from 'redux/actions';
import { connect } from 'react-redux';
import Card, { InfoCard } from 'components/Card';
import { DestroyableCollection } from 'components/DestroyableCollection';
import { DontRecommendButton, InitialTip, LikeRecipeButton, SIAC, TrainerBadgeWithContext } from './Utilities';
import Dropdown from 'components/Dropdown';
import { Link, Redirect } from 'components/Routing';
import { ScrollableFormModal } from 'components/Modal';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import { RecipeMealServingToggle } from './MealAndRecipeSearch';
import { MacroPieChartCard } from './MacroPieChart';
import MacroSummary from './MacroSummary';
import IngredientInfo from './IngredientInfo';
import { seenTooltips, userRecordSelector } from 'redux/selectors';
import { LikeRecipeForClientsPopup } from './PickClientPopups';
import { goWrapper } from 'redux/helpers';
import ProRequiredButton from './ProRequiredButton';

const AdjustServingsContent = ({ setServings, meal, match: { params: { recipeId } }, basePath, renderHeader, renderScrollable, renderFooter }) => {
    const recipeMeal = meal.recipeMealFor(Number(recipeId));
    const [servings,setLocalServings] = useState(recipeMeal && recipeMeal.servings);

    if(recipeMeal) {
        return (
            <React.Fragment>
                {renderHeader()}
                <FormikWrapper
                    initialValues={ { recipeId: recipeMeal.recipeId, servings: recipeMeal.readableServings() } }
                    submit={(values) => setServings({ recipeId: values.recipeId, servings: recipeMeal.actualServings(values.servings) })}
                    validationSchema={Yup.object().shape({
                        servings: Yup.number().moreThan(0).required()
                    })}
                    initialErrors={{}}
                    render={({ handleSubmit, submitState, values, setValues, errors, handleBlur }) => {
                        return (
                            <form onSubmit={handleSubmit}>
                                {renderScrollable({ children: (
                                    <div className="text-center">
                                        <div className="pt20 pb20 pl10 pr10">
                                            <RecipeMealServingToggle
                                                recipeMeal={recipeMeal}
                                                values={values}
                                                setValues={setValues}
                                                errors={errors}
                                                handleBlur={handleBlur}
                                                setServings={setLocalServings}
                                            />
                                            <div className="mt15 lh1">
                                                {recipeMeal.recipe.isFullyLoaded() && (
                                                    <div className="slight-emphasis-bcg pa20 text-center left-align-block font-grey">
                                                        <IngredientInfo recipeId={recipeMeal.recipeId} servings={servings} includeMacros />
                                                    </div>
                                                )}
                                                {!recipeMeal.recipe.isFullyLoaded() && (
                                                    <div className="text-center">
                                                        <MacroSummary macroHash={recipeMeal.recipe.macroHash(servings)} />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                )})}
                                {renderFooter({ loadState: submitState, disabled: !!errors.servings, id: 'save-servings-btn' })}
                            </form>
                        )
                        
                        
                    }}
                />
            </React.Fragment>
        )
    } else {
        return <Redirect to={basePath} />
    }
 
}

const AdjustServingsModal = ({ basePath, meal, setServings }) => {
    const { t } = useTranslation();
    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={adjustServingsModalMatchFor(basePath)} 
            exact 
            icon={['far','edit']}
            title={t("Adjust Portions")}
            render={({ renderFooter, renderScrollable, renderHeader, match }) => (
                <AdjustServingsContent 
                    meal={meal}
                    basePath={basePath}
                    match={match}
                    renderFooter={renderFooter}
                    renderScrollable={renderScrollable}
                    renderHeader={renderHeader}
                    setServings={setServings}
                />
            )} 
        />
    )
}

const DateClump = React.forwardRef(({ clump, startIndex, siblings, onClick, active },ref) => {

    if(Array.isArray(clump)) {
        const widPct = clump.length/siblings*100;
        const pct = clump.length/(siblings-1);
        const startPct = startIndex/(siblings-1);
        const startOffset = -startPct/pct*100;
        const remainingPct = 1-startPct;
        const endOffset = remainingPct/pct*100;
        const gradient = `linear-gradient(90deg, #55b86a ${startOffset}%, #85d080 ${endOffset}%)`
        return (
            <div className="date-gradient-btn buttonize" style={{ width: `${widPct}%`, background: gradient }} onClick={onClick} ref={ref}>
                 {clump.map(dt => (<DateClump key={dt.format(dateFormat)} clump={dt} siblings={clump.length} active={true} />))}
            </div>
        )
    } else {
        return (
            <div className={classnames("me-date",{ active })} style={{ width: `${1/siblings*100}%` }} ref={ref}>{clump.format('dd')[0]}</div>
        )
    }
})

const DayAndShareHeader = ({ weeklyMeal }) => {
    const clumps = weeklyMeal.dateClumps();
    const { t } = useTranslation();
    let startIndex = 0;
    const history = useHistory();
    const sharedCount = weeklyMeal.isShareable() ? weeklyMeal.sharedProfileIds().length : 0;
    let activeHit = false;

    return (
        <SIAC render={({ signedInAsClient }) => (
            <div className="me-date-container mb10">
                {clumps.map((clump,index) => {
                    const thisStartIndex = startIndex;
                    startIndex = startIndex + (Array.isArray(clump) ? clump.length : 1);
                    const passThroughProps = {
                        clump,
                        siblings: (weeklyMeal.isShareable() ? 8 : 7),
                        startIndex: thisStartIndex,
                        history 
                    }
                    const onClick = (weeklyMeal.user().allowedToEditOwnMealPlan() || signedInAsClient) ? (() => history.push(pickMealDaysModalPathFor(weeklyMeal.editPath()))) : (() => {});
                    let finalClump = null;

                    if(!activeHit && Array.isArray(clump)) {
                        activeHit = true;
                        finalClump = (
                            <InitialTip
                                key={thisStartIndex}
                                onlyAfter={['click_recipe_card']}
                                tipName={'pick_meal_days_tip'} 
                                text={t("Tap an active weekday to pick the days you'll be eating this meal")} 
                                delay={8000}
                                component={DateClump}
                                passThroughProps={passThroughProps}
                                onClick={onClick}
                            />
                        )
                    } else {
                        finalClump = (
                            <DateClump 
                                key={thisStartIndex}
                                {...passThroughProps}
                                onClick={onClick}
                            />
                        )
                    }

                    return finalClump;
                })}
                {weeklyMeal.isShareable() && (
                    <ProRequiredButton 
                        user={weeklyMeal.user()}
                        context="add_people_meal"
                        proClickHandler={() => history.push(addPeopleToMealModalPathFor(weeklyMeal.editPath()))}
                        blockTypes={['soft']}
                        render={({ onClick }) => (
                            <Button 
                                color="primary" 
                                id="share-meal-modal-btn"
                                className="btn-square-icon" 
                                rounded 
                                outlined 
                                style={{ width: '12.5%', marginLeft: '5px', height: '40px', overflow: 'visible'}}
                                onClick={onClick}
                                >
                                    {sharedCount > 0 && <span className="notification-icon primary" style={{ top: '-6px', right: '-4px'}} >{sharedCount}</span>}
                                    <FontAwesomeIcon icon="user-plus" />
                            </Button>
                        )}
                    />
                )}
            </div>
        )} />
        
    )
}

let RecipeCard = ({ user, recipeMeal, isMain, className, destroy, allowDestroy, goToReplaceRecipe, basePath, getRecipeCardPath, dismissTooltip, seenTooltips }) => {
    const classNames = classnames("mec-wrapper mb10",{'me-side': !isMain, [className]: className });
    const macroHash = recipeMeal.macroHash();
    const { t } = useTranslation();
    const newDestroy = () => destroy(recipeMeal);
    const recipePath = user.hasProAccess(['old','hard']) ? getRecipeCardPath(recipeMeal.staticRecipeId()) : paywallPathFor('view_recipe',user);
    let img = (<img src={recipeMeal.recipe.imageUrl('mediumThumb')} alt="" />);
    if(isMain) {
        img = (<InitialTip
                    tipName={'click_recipe_card'} 
                    text={t("Tap a card to view the full recipe")} 
                    delay={8000}
                >
                    {img}
                </InitialTip>
        )
    }

    return (
        <div className={classNames}>
            <Card id={`recipe-meal-${recipeMeal.recipeId}-card`} className="meal-editor-card buttonize ma0" linkTo={recipePath}>
                {img}
                {<TrainerBadgeWithContext recipe={recipeMeal.recipe} />}
                <div className="me-title">{recipeMeal.recipe.name}</div>
                <div className="me-subtitle">{_.macroHashSummary(t,macroHash,false,true)}</div>
                <div className="me-subtitle">{macroHash.calories}{t('cals')}</div>
            </Card>
            {allowDestroy && (<div className="top-left">
                <ConfirmActionButton 
                    onClick={newDestroy}
                    render={({ ready, onClick }) => (
                        <Button variant="floating" noColor className={classnames("transparent-floating")} id={`remove-recipe-${recipeMeal.recipeId}-btn`} onClick={onClick}>
                            <FontAwesomeIcon icon="times" className={ready ? 'red-text' : ''} />
                        </Button>
                    )}
                />
            </div>)}
            <LikeRecipeButton recipe={recipeMeal.recipe} />
            <div className="rm-actions-container no-wrap">
                <Button 
                    variant="floating" 
                    id={`swap-recipe-${recipeMeal.recipeId}-btn`} 
                    noColor 
                    className={classnames("grey-floating mr5")} 
                    onClick={() => {
                        if(!seenTooltips.includes('swap_recipe_meal')) {
                            dismissTooltip('swap_recipe_meal');
                        }
                        goToReplaceRecipe(recipeMeal.recipeId)
                    }}
                >
                    <FontAwesomeIcon icon="exchange-alt" />
                </Button>
                <Dropdown 
                    contentComp='ul'
                    options={{constrainWidth: false, alignment: 'right', coverTrigger: false, noWrap: true }}
                    triggerRender={({ ref, target }) => {
                        return (
                            <Button 
                                variant="floating" 
                                noColor 
                                className={classnames("grey-floating")} 
                                id={`recipe-${recipeMeal.recipeId}-dropdown-btn`} 
                                ref={ref} 
                                data-target={target}
                                onClick={() => {
                                    if(!seenTooltips.includes('recipe_meal_menu')) {
                                        dismissTooltip('recipe_meal_menu');
                                    }
                                }}
                            >
                                <FontAwesomeIcon icon="ellipsis-h" />
                            </Button>
                        )
                    }}
                    contentRender={({ recalcDims }) => {
                        return (
                            <React.Fragment>
                                <DontRecommendButton recipe={recipeMeal.recipe} />
                                <li>
                                    <Link to={adjustServingsModalPathFor(basePath,recipeMeal.staticRecipeId())} id={`adjust-servings-${recipeMeal.recipeId}-btn`}>
                                        <FontAwesomeIcon icon={['far','edit']} /> {t("Adjust Portions")}
                                    </Link>
                                </li>
                            </React.Fragment>
                        )
                    }}
                />
            </div>
        </div>
    )
}

const InfoBlock = ({ title, subtitle }) => {

    return (
        <div className="me-nut-container">
            <div className="text-center">
                <div className="me-details">{title}</div>
                <div className="me-subtitle ml0">{subtitle}</div>
            </div>
        </div>
    )
}

const RandomizeButton = ({ children, id, onClick, className }) => {
    const { t } = useTranslation();

    return (<Button
            rounded 
            color="primary" 
            className={className} 
            outlined 
            noShadow
            onClick={onClick}
            id={id}
        >
            {children} <span className="flex-grow text-center">{t("Randomize Sides")}</span>
        </Button>
    )
}

class EditMealCore extends React.Component {

    constructor(props) {
        super(props);
        this.excludedIds = props.meal.sideRecipeIds();
    }

    componentDidUpdate() {
        this.excludedIds = _.union(this.props.meal.sideRecipeIds(),this.excludedIds).slice(0,8)
    }

    render() {
        const { 
            weeklyMeal, 
            meal, 
            destroyDish, 
            randomizeMeal, 
            setServings, 
            goToReplaceRecipe, 
            t, 
            getMacroWarning, 
            confirmAction, 
            history,
            getRecipeCardPath,
            dismissTooltip,
            seenTooltips,
            user
        } = this.props;
        const outerDishes = meal.orderedDishes();
        const basePath = weeklyMeal ? weeklyMeal.editPath() : editTempMealPath;
        const macroHash = meal.macroHash();
        const warning = getMacroWarning(t,meal);
        const hasAddFood = seenTooltips.includes('swap_recipe_meal');

        return (
            <ActivityContainer className="mb100">
                <div className="col s12 m12 l6" id="edit-meal-container">
                    <div className="meal-editor-container">
                        {weeklyMeal && (<DayAndShareHeader weeklyMeal={weeklyMeal} />)}
                        <DestroyableCollection 
                            collection={outerDishes}
                            destroy={destroyDish}
                            idAttr={'recipeId'}
                            render={({ destroy, allowDestroy, pendingDestructions }) =>{
                                const dishes = meal.orderedDishes(pendingDestructions);
                                const main = dishes[0];
                                const sides = dishes.slice(1);
                                const sharedProps = { user, destroy, allowDestroy, goToReplaceRecipe, basePath, getRecipeCardPath, dismissTooltip, seenTooltips }
                                return (
                                    <React.Fragment>
                                        <RecipeCard 
                                            recipeMeal={main} 
                                            isMain 
                                            {...sharedProps}
                                        />
                                        {sides.length > 0 && (<div className="side-dish-container">
                                            {sides.map((recipeMeal,index) => {
                                                const className = (index%2) === 0 ? 'mr5' : 'ml5';
                                                return (<RecipeCard
                                                    key={recipeMeal.recipeId} 
                                                    recipeMeal={recipeMeal} 
                                                    className={className} 
                                                    {...sharedProps}
                                                />)
                                            })}
                                        </div>)}
                                        <div className="display-flex">
                                            {main.canBeMainDish() && (<DefaultAsyncActionButton 
                                                hasAddFood={hasAddFood}
                                                Comp={RandomizeButton}
                                                LoaderWrapper={RandomizeButton}
                                                className={classnames("flex-grow text-center", { mr5: hasAddFood })}
                                                loaderType="icon"
                                                id={`randomize-meal-btn`}
                                                action={() => randomizeMeal(this.getExcludedIds())}
                                            >
                                                <FontAwesomeIcon icon={'random'}></FontAwesomeIcon> 
                                            </DefaultAsyncActionButton>)}
                                            {hasAddFood && (<Button
                                                className={main.canBeMainDish() ? '' : 'flex-grow text-center'}
                                                rounded 
                                                color="primary" 
                                                outlined 
                                                noShadow
                                                id={`add-food-btn`}
                                                onClick={() => goToReplaceRecipe(null)}
                                            >
                                                <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span className="flex-grow text-center">{t("Add Food")}</span>
                                            </Button>)}
                                        </div>
                                    </React.Fragment>
                                )
                            }}
                        />
                        
                    </div>
                    <div className="divider-line me mt25 mb25 hide-on-large-only"></div>
                </div>
                <div className="col s12 m12 l6" id="meal-info-container">
                    <div className="meal-editor-container">
                        <MacroPieChartCard warning={warning} macroHash={macroHash} />
                        <InfoCard>
                            <InfoBlock 
                                title={<React.Fragment><FontAwesomeIcon icon={['far','clock']} /> {meal.totalActiveTime()} {t('mins')}</React.Fragment>} 
                                subtitle={t('prep time')}
                            />
                            <div className="divider-line vertical"></div>
                            <InfoBlock 
                                title={<React.Fragment>{_.xArray(meal.dollarSigns()).map(ds => (<FontAwesomeIcon icon={['far','dollar-sign']} />))}</React.Fragment>} 
                                subtitle={null}
                            />
                        </InfoCard>
                    </div>
                </div>
                <WeeklyMealModals weeklyMeal={weeklyMeal} />
                <LikeRecipeForClientsPopup />
                <AdjustServingsModal 
                    basePath={basePath} 
                    meal={meal} 
                    setServings={setServings} 
                />
                {confirmAction && (<BottomButtons>
                    <DefaultAsyncActionButton 
                        rounded 
                        color="primary" 
                        className="no-upcase" 
                        id={`confirm-replacement-btn`}
                        action={() => confirmAction({ meal })}
                        successCallback={(data) => goWrapper(history,-2)}
                    >
                        <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Confirm")}
                    </DefaultAsyncActionButton>
                </BottomButtons>)}
            </ActivityContainer>
        )
    }

    getExcludedIds = () => {
        if(this.excludedIds.length === 0) {
            return [''];
        }
        return this.excludedIds;
    }
}

const WeeklyMealModals = ({ weeklyMeal }) => {

    return (
        <React.Fragment>
            {weeklyMeal && (<SelectDaysModal 
                basePath={weeklyMeal.editPath()} 
                weeklyMeal={weeklyMeal} 
                selectType="pick" 
            />)}
            {weeklyMeal && (<AddPeopleToMealModal
                mealInfoMatrix={weeklyMeal.infoMatrix} 
                basePath={weeklyMeal.editPath()}
                weeklyMeal={weeklyMeal}
            />)}
        </React.Fragment>
    )
}

const EditOffPlanMeal = ({ weeklyMeal }) => {
    const { t } = useTranslation();
    
    return (
        <ActivityContainer>
            <div className="col s12 m8 offset-m2 l6 offset-l3" id="edit-off-plan-meal-container">
                <div className="meal-editor-container">
                    <DayAndShareHeader weeklyMeal={weeklyMeal} />
                    <div className="mec-wrapper">
                        <Card id={`off-plan-meal-card`} className="meal-editor-card ma0">
                            <img src={weeklyMeal.mainImageUrl('mediumThumb')} alt="" />
                            <div className="me-title">{t("Off-plan meal")}</div>
                            <div className="me-subtitle">{t('Recommended')}: {_.macroHashSummary(t,weeklyMeal.initMeal().unloggedMacroHash(),true,true)}</div>
                        </Card>
                    </div>
                </div>
            </div>
            <WeeklyMealModals weeklyMeal={weeklyMeal} />
        </ActivityContainer>
    )
}

EditMealCore = withTranslation()(EditMealCore)

let EditMealLoader = ({ 
    meal, 
    mealInfoMatrix, 
    weeklyMeal, 
    loadRecipes, 
    destroyDish, 
    randomizeMeal, 
    setServings, 
    getMacroWarning, 
    goToReplaceRecipe, 
    confirmAction,
    getRecipeCardPath,
    dismissTooltip,
    seenTooltips,
    user
}) => {
    const ids = meal.recipeIdsNeedLoading();
    const load = loadRecipes.bind(null,ids);
    const history = useHistory();

    return (
        <Loader
            load={load}
            preloaded={() => meal.recipeMealsLoaded()}
            alwaysLoad
            type='page'
            successComponent={(weeklyMeal && weeklyMeal.isOffPlan()) ? EditOffPlanMeal : EditMealCore}
            meal={meal}
            weeklyMeal={weeklyMeal}
            mealInfoMatrix={mealInfoMatrix}
            destroyDish={destroyDish}
            randomizeMeal={randomizeMeal}
            setServings={setServings}
            confirmAction={confirmAction}
            history={history}
            getMacroWarning={weeklyMeal ? (t) => weeklyMeal.macroWarningFor(t) : getMacroWarning}
            goToReplaceRecipe={goToReplaceRecipe}
            getRecipeCardPath={getRecipeCardPath}
            dismissTooltip={dismissTooltip}
            seenTooltips={seenTooltips}
            user={user}
        />
    )
}

const mapDispatchToLoaderProps = dispatch => ({
    loadRecipes: ids => dispatch(loadRecipes(ids)),
    dismissTooltip: tipName => dispatch(dismissTooltip(tipName))
})

const mapStateToProps = state => ({
    seenTooltips: seenTooltips(state),
    user: userRecordSelector(state)
})

EditMealLoader = connect(mapStateToProps,mapDispatchToLoaderProps)(EditMealLoader)

export { EditMealLoader }