import React from 'react';
import { connect } from 'react-redux';
import { workoutSetupMatch, pickExercisePath, emailPaywallMatches, allMainMatches, switchAccountMatch, accountPath, trainerBrandingMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { userRecordSelector } from 'redux/selectors';
import { cancelSubscription } from 'redux/actions';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import { Checkbox, CheckboxGroup, FullInput, LoadingSubmitButton, ModalSelectListing } from 'components/Form';
import TextInput from 'components/TextInput';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';
import { FlowQuestion } from 'components/FlowForm';

const transitionMap = {
    rules: [
        [[ ...allMainMatches,accountPath],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,switchAccountMatch,trainerBrandingMatch], transitions.slideOver]
    ]
};

let CancelPage = (props) => {
    const { scrollRef, setupTransitions, user, history, cancelSubscription } = props;

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={'Cancel Subcription'}
        >
            <CenteredActivityContainer>
                <FormikWrapper
                    initialValues={ { email: user.email, cancelReasons: [''], otherCancelReason: '', elaboration: '', marketing: '', call: '' } }
                    validate={values => {
                        let errs = {};
                        const { cancelReasons, otherCancelReason } = values;
                        if(_.compact(cancelReasons).length === 0) {
                            errs.cancelReasons = 'pick at least one';
                        }

                        if(cancelReasons.includes('__other_option__') && _.isBlank(otherCancelReason)) {
                            errs.otherCancelReason = 'required';
                        }
                        
                        return errs;
                    }}
                    validationSchema={Yup.object().shape({ 
                        cancelReasons: Yup.array().compact().min(1).required(),
                        call: Yup.boolean().required()
                    })}
                    submit={values => cancelSubscription('trainer',values)}
                    successCallback={() => history.go(-1)}
                    initialErrors={{}}
                    render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                        const { touched, errors } = rest;
                    
                        return (
                            <form className="pa15" onSubmit={handleSubmit}>
                                <div className="mb20">
                                    <FlowQuestion text={"Why are you cancelling?"} />
                                    <CheckboxGroup 
                                        values={rest.values} 
                                        errors={rest.errors} 
                                        touched={rest.touched} 
                                        onChange={rest.setFieldValue} 
                                        onBlur={rest.setFieldTouched} 
                                        name="cancelReasons" 
                                    >
                                        {['clients had issues','too expensive','bug/technical issue','confusing/hard to use','missing features','I found an alternative','not what I expected'].map((reason,index) => (
                                            <Checkbox key={reason} inputProps={{ value: reason } } label={_.capitalize(reason)} filled id={'reason-check-' + index} />
                                        ))}
                                        <Checkbox key={'__other_option__'} inputProps={{ value: '__other_option__' } } label={'Other'} filled />
                                    </CheckboxGroup>
                                    {rest.values.cancelReasons.includes('__other_option__') && (<FullInput
                                        name="otherCancelReason"
                                        label={null}
                                        component={TextInput}
                                        inProps={{ placeholder: 'Please specify' }}
                                        formikProps={rest}
                                    />)}
                                </div>

                                <div className="mb20">
                                    <FlowQuestion text={"Optional: care to elaborate?"} />   
                                    <FullInput
                                        name="elaboration"
                                        label={null}
                                        component={TextInput}
                                        inProps={{ placeholder: 'Your answer' }}
                                        formikProps={rest}
                                    />
                                </div>

                                <div className="mb30">
                                    <FlowQuestion text={"Would you be willing to chat with us for 15-30 mins about your experience with the software for $50?"} /> 
                                    <ModalSelectListing
                                        col={[{text: 'Yes', value: true}, {text: "No I don't want $50", value: false}]}
                                        name={'call'}
                                        single
                                        boxClass="non-modal"
                                        {...rest}
                                    />
                                    {touched.call && errors.call && (<div className="red-text"><FontAwesomeIcon icon="times" ></FontAwesomeIcon> required</div>)}
                                </div>

                                <div className="mb30">
                                    <FlowQuestion text={"Optional: where would you look for nutrition software recommendations in your industry? (any specific forums, websites, influencers, etc)"} /> 
                                    <FullInput
                                        name="marketing"
                                        label={null}
                                        component={TextInput}
                                        inProps={{ placeholder: 'Your answer' }}
                                        formikProps={rest}
                                    />
                                </div>

                                <div className="text-center">
                                    <LoadingSubmitButton icon="times" label={"Cancel Subscription"} loadState={submitState} color="red" id="confirm-cancel-btn" />
                                </div>
                            </form>
                        )
                    }}
                />
            </CenteredActivityContainer>
 
        </SimpleNavPage>
    )
}

const mapStateToProps = (state) => ({
    user: userRecordSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    cancelSubscription: (type,feedback) => dispatch(cancelSubscription(type,feedback))
})

export default connect(mapStateToProps,mapDispatchToProps)(CancelPage)