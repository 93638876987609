import Page from 'components/Page';
import React, { useState } from 'react';
import * as transitions from 'assets/transitions';
import { canHitEmailOrPaywallMatches, clientDetailsMatch, contactPath, emailSignupMatch, switchAccountMatch, trainerBasicProfileMatch, trainerHomePath, trainerSignupMatch, trainerSignupPath } from 'config/paths';
import { FlowNav } from 'components/FlowForm';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';
import { checkForBreakingUpdate, createUserLead } from 'redux/actions';
import * as _ from 'lib/utilities';
import ActivityContainer from 'components/ActivityContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RespSectionTabs, SectionTab } from 'components/BottomNav';
import classnames from 'classnames';
import Button, { AsyncSpinnerButton } from "components/Button";
import svgLogo from 'assets/img/logo_with_text.svg';
import Collapse from 'components/Collapse';
import { Link } from 'components/Routing';
import { FULL, NUTRITION, TPAYWALL, TRAINING } from 'config/settings';
import { SimpleContentModal } from 'components/Modal';
import { SlightEmphasisIconNote } from 'components/Typography';
import { baseClients, signupFirstPathFor, withStore } from 'partials/TrainerStore';

const transitionMap = {
    rules: [
        [[contactPath],transitions.slideOver],
        [[...canHitEmailOrPaywallMatches,trainerSignupMatch,trainerBasicProfileMatch,emailSignupMatch,switchAccountMatch, clientDetailsMatch],transitions.slideOut]
    ]
};

const nutritionFeatures = {
    client: [ ...(new Array(6).keys()) ].map(ind => `cnfeature${ind+1}`),
    trainer: [ ...(new Array(8).keys()) ].map(ind => `tnfeature${ind+1}`)
}

const trainingFeatures = {
    client: [ ...(new Array(6).keys()) ].map(ind => `cwfeature${ind+1}`),
    trainer: [ ...(new Array(7).keys()) ].map(ind => `twfeature${ind+1}`)
}

const getCheckCreator = featureType => product => {
    if(product.planType === FULL) {
        return (<Check />)
    } else if(product.planType === featureType) {
        return (<Check />)
    }
    return '';
}

const getCta = (product,context) => {
    let cta = 'Start Free Trial';
    if(!product.hasTrial) {
        cta = (context === TPAYWALL && !product.isTrialing) ? 'Upgrade' : 'Select Plan';
    }
    return cta;
}

const SwitchPlanButton = ({ product, icon, ...rest }) => {

    return (
        <AsyncSpinnerButton 
            successCallback={product.onSuccess}
            buttonProps={rest}
            icon={icon}
            action={() => product.initiateCheckout()}
        />
    )
}

const SelectButton = ({ product, outlined, context, t, className, iconOnly, icon="check", id }) => {
    const variant = (iconOnly ? 'round-icon' : null);
    const sharedBtnProps = { rounded: true,  outlined, noShadow: true, className, variant, id };

    if(product.selectable) {
        if(product.isUpgrade) {
            return (
                <SwitchPlanButton 
                    product={product}
                    icon={icon}
                    {...sharedBtnProps}
                    label={iconOnly ? null : t(getCta(product,context))}
                />
            )
        } else {
            return (
                <Button {...sharedBtnProps} onClick={() => product.initiateCheckout()}>
                    <FontAwesomeIcon icon={icon} /> {!iconOnly && t(getCta(product,context))}
                </Button>
            )
        }
    } else {
        return (
            <Button rounded noShadow outlined disabled noColor className={className} variant={variant} id={id}>
                <FontAwesomeIcon icon={icon} /> {!iconOnly && t('Current Plan')}
            </Button>
        )
    }
}

const ProductTab = ({ product, isLarge, t, context }) => {
    const classNames = classnames(`product ${product.planType}`, { "show-border": isLarge });

    return (
        <div className={classNames}>
            {isLarge && (<div className="plan-name">{product.name}</div>)}
            <div className="price">
                {false && product.originalMonthlyAmount && (<React.Fragment><del className="price-slash">${product.originalMonthlyAmount}</del>&nbsp;</React.Fragment>)}
                {product.topPriceStr}{(product.selectable && product.isUpgrade) && '*'}
                <div className="subtitle">
                    USD
                </div>
                {product.discount && <div className="discount-tag-unpos">{product.discount}% off first year</div>}
            </div>
            <div className="left-align-block pa5 mb75">
                {!_.isBlank(product.moneyBackString) && (<div className="trial-info">
                    {product.moneyBackString}
                </div>)}
                <ul className="fa-ul feature-summary">
                    {product.featureSummary.map(feature => (
                        <li key={feature}><span className="fa-li"><FontAwesomeIcon icon="check" className="success-color" /></span> {feature}</li>
                    ))}
                </ul>
            </div>
            <div className="cta-cont">
                <SelectButton 
                    product={product} 
                    isLarge={isLarge} 
                    context={context} 
                    t={t} 
                    outlined={(isLarge && !product.primary)} 
                    id={`select-${product.planType}-btn`}
                />
            </div>
        </div>
    )
}

const FeatureRow = ({ products, featureText, getProdInfo }) => {

    return (
        <tr>
            <td className="empty"></td>
            <td className="offset"></td>
            <td className="feature-text">{featureText}</td>
            {products.map(product => (
                <td key={product.planType} className="check-cell">{getProdInfo(product)}</td>
            ))}
        </tr>
    )
}

const SubHeaderRow = ({ text, prodCount }) => {

    return (
        <tr>
            <td className="empty"></td>
            <td className="sub-header" colSpan={2 + prodCount}>{text}</td>
        </tr>
    )
}

const Check = () => (<FontAwesomeIcon icon="check" className="success-color" />)

const FeatureTable = ({ products, context, t }) => {

    return (
        <table className="t-conv-feat-table">
            <thead className="main-head">
                <tr>
                    <th colSpan="3"></th>
                    {products.map(product => (
                        <th key={product.planType}>
                            <div className={classnames(`prod ${product.planType}`,{})}>
                                <div className="prod-text">{product.shortName}</div>
                                {!_.isBlank(product.moneyBackString) && (
                                    <div className="details">
                                        {product.moneyBackString}
                                    </div>
                                )}
                                <div className="details">
                                    {product.topPriceStr}
                                </div>
                                <div className="mt10">
                                    <SelectButton product={product} outlined={!product.primary} context={context} t={t} className="cta-text" id={`select-${product.planType}-btn2`} />
                                    <div className="cta-icon">
                                        <SelectButton 
                                            product={product} 
                                            outlined={!product.primary} 
                                            context={context} t={t} 
                                            iconOnly
                                            icon="check"
                                        />
                                    </div>
                                </div>
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            <thead>
                <tr>
                    <td className="section-head" colSpan={3 + products.length}>{t("Basics")}</td>
                </tr>
            </thead>
            <tbody>
                <FeatureRow products={products} featureText={t('Clients included')} getProdInfo={() => baseClients} />
                <FeatureRow products={products} featureText={t('Extra clients')} getProdInfo={(product) => product.extraClientPrice} />
                <FeatureRow products={products} featureText={t(`${t("iOS/Android/web app access for clients")} (${t('optional')})`)} getProdInfo={(product) => (<Check />)} />
                <FeatureRow products={products} featureText={t("Dashboard with overview of clients")} getProdInfo={(product) => (<Check />)} />
                <FeatureRow products={products} featureText={t("Body weight tracking")} getProdInfo={(product) => (<Check />)} />
                <FeatureRow products={products} featureText={t("Body measurement tracking")} getProdInfo={(product) => (<Check />)} />
                <FeatureRow products={products} featureText={t("Habit tracking")} getProdInfo={(product) => (<Check />)} />
                <FeatureRow products={products} featureText={t("Unlimited trainers")} getProdInfo={(product) => (<Check />)} />
            </tbody>
            <thead>
                <tr>
                    <td className="section-head" colSpan={3 + products.length}>{t("Meal Plan & Nutrition")}</td>
                </tr>
            </thead>
            <tbody>
                <SubHeaderRow text={t("Trainer features")} prodCount={products.length} />
                {nutritionFeatures.trainer.map(feature => (
                    <FeatureRow 
                        key={feature}
                        products={products} 
                        featureText={t(feature)} 
                        getProdInfo={getCheckCreator(NUTRITION)} 
                    />
                ))}
                <SubHeaderRow text={t("Client features")} prodCount={products.length} />
                <FeatureRow products={products} featureText={t('customizable meal planner')} getProdInfo={getCheckCreator(NUTRITION)} />
                {nutritionFeatures.client.map(feature => (
                    <FeatureRow 
                        key={feature}
                        products={products} 
                        featureText={t(feature)} 
                        getProdInfo={getCheckCreator(NUTRITION)} 
                    />
                ))}
            </tbody>
            <thead>
                <tr>
                    <td className="section-head" colSpan={3 + products.length}>{t("Training")}</td>
                </tr>
            </thead>
            <tbody>
                <SubHeaderRow text={t("Trainer features")} prodCount={products.length} />
                {trainingFeatures.trainer.map(feature => (
                    <FeatureRow 
                        key={feature}
                        products={products} 
                        featureText={t(feature)} 
                        getProdInfo={getCheckCreator(TRAINING)} 
                    />
                ))}
                <SubHeaderRow text={t("Client features")} prodCount={products.length} />
                {trainingFeatures.client.map(feature => (
                    <FeatureRow 
                        key={feature}
                        products={products} 
                        featureText={t(feature)} 
                        getProdInfo={getCheckCreator(TRAINING)} 
                    />
                ))}
            </tbody>
        </table>
    )
}

const FrequentlyAskedQ = ({ question, answer }) => {
    const [expanded,setExpanded] = useState(false);

    return (
        <React.Fragment>
            <div className="t-conv-faq-q" onClick={() => setExpanded(!expanded)}>
                {question}
                <FontAwesomeIcon icon={expanded ? 'angle-down' : 'angle-right'} className="tilde" />
            </div>
            <Collapse isOpen={expanded}>
                {answer}
            </Collapse>
        </React.Fragment>
    )
}

const FrequentlyAskedQuestions = () => {
    const { t } = useTranslation();

    return (
        <div>
            <FrequentlyAskedQ 
                question={t("client bill q")} 
                answer={t("client bill a2")}
            />
            <FrequentlyAskedQ 
                question={t("pdf or app q")} 
                answer={<div>
                    <p>{t('pdf or app a1')}</p>
                    <ol>
                        <li><b>{t("PDF/email/paper")}:</b> {t('pdf or app a2')}</li>
                        <li><b>{t("App/Web")}:</b> {t('pdf or app a3')}</li>
                    </ol>
                    <p>{t('pdf or app a4')}</p>
                    <p>{t('pdf or app a5')}</p>
                </div>}
            />
            <FrequentlyAskedQ 
                question={t("how works q")} 
                answer={<div>
                    <p>{t('how works a1')}</p>
                    <p>{t('how works a2')}</p>
                    <p>{t('how works a3')}</p>
                    <p>{t('how works a4')}</p>
                </div>}
            />
            <FrequentlyAskedQ 
                question={t("custom recipes q")} 
                answer={<div>
                    <p>{t('custom recipes a1')}</p>
                    <p>{t('custom recipes a2')}</p>
                    <p>{t('custom recipes a3')}</p>
                </div>}
            />
        </div>
    )
}

const chargeMsg = (user,frequency) => {
    if(user.isTrainerTrialing()) {
        return 'upgrade trial tip';
    } else if(frequency !== user.trainerSubFreq()) {
        return 'upgrade immediate charge'
    } else {
        return  'upgrade plan tip';
    }
}

const SignupFirstModal = ({ location, context }) => {
    const path = signupFirstPathFor(location.pathname);
    const { t } = useTranslation();

    return (
        <SimpleContentModal 
            path={path}
            title={t("Sign Up First")}
            buttonRender={({ closeAndGo }) => {
                return (
                    <Button color="primary" rounded onClick={() => closeAndGo(trainerSignupPath)}>
                        <FontAwesomeIcon icon={"arrow-right"} />
                        {t('Sign Up')}
                    </Button>
                )
            }}
            render={() => {

                return (
                    <div className="pa20 text-center">
                        <SlightEmphasisIconNote text={t('trainer signup first')} />
                    </div>
                )
            }}
        />
    )
}
class TrainerConversionPage extends React.Component {

    constructor(props) {
        super(props);
        const { setupTransitions } = props;
        setupTransitions(transitionMap);
    }

    render() {
        const { scrollRef, modal, products, frequency, t, user, location, match: { params: { context }} } = this.props;
        const isPaywall = context === TPAYWALL;
        const hasActivePlan = user.hasActiveTrainerSub();

        return (
            <Page white ref={scrollRef}>
                <FlowNav />
                <ActivityContainer>
                    <div className="position-relative">
                        <img className="t-conv-logo" src={svgLogo} alt="logo" />
                    </div>
                    <div className="col s12">
                        <div className="t-conv-title mt-inset-60 mb10">
                            {isPaywall && <React.Fragment><FontAwesomeIcon icon="lock" /> {(user && user.hadTrainerTrial()) ? t('Upgrade required') : t('Start Your Free Trial')}</React.Fragment>}
                            {!isPaywall && t("A plan that fits your needs")}
                        </div>

                        <div>
                            <RespSectionTabs activeTab={_.findIndex(products,p => p.primary)} render={({ activeTabs, isLarge }) => {
                                return (
                                    <div className="t-conv-prod-box">
                                        {user && user.isFromDiscountedAffiliate() && (<div class="affiliate-disc-flag">{user.discountedAffiliateBrand()} exclusive discount</div>)}
                                        {products.map((product,index) => {
                                            if(!activeTabs.includes(index)) {
                                                return '';
                                            }

                                            return (
                                                <ProductTab 
                                                    product={product} 
                                                    isLarge={isLarge} 
                                                    t={t} 
                                                    context={context} 
                                                    key={product.planType}
                                                />
                                            )
                                        })}
                                    </div>
                                )
                            }}>
                                {products.map(product => (
                                    <SectionTab className={product.planType} key={product.planType}>{product.name}</SectionTab>
                                ))}
                            </RespSectionTabs>
                        </div>

                        {hasActivePlan && (<div className="mt10 font-grey lh1">
                            *{t(chargeMsg(user,frequency))}
                        </div>)}

                        <div className="mt75">
                            <div className="t-conv-title mb15">
                                {t("What comes with each plan?")}
                            </div>
                            <FeatureTable products={products} context={context} t={t} />
                        </div>

                        {modal}
                    </div>
                    <div className="col s12 m12 l8 offset-l2">
                        <div className="mt75">
                            <div className="t-conv-title mb25 text-center">
                                {t("Frequently Asked Questions")}
                            </div>
                        </div>
                        <FrequentlyAskedQuestions />

                        <div className="mt75 text-center">
                            <div className="t-conv-title">
                                {t("Have other questions?")}
                            </div>
                            <div className="contact-text">
                                <Link to={contactPath}>{t('Contact us')}</Link>
                            </div>
                        </div>
                    </div>
                    <SignupFirstModal location={location} context={context} />
                </ActivityContainer>
            </Page>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    createUserLead: userLead => dispatch(createUserLead(userLead)),
    checkBreak: () => dispatch(checkForBreakingUpdate())
})

const mapStateToProps = state => ({
    successPath: trainerHomePath
})

export default connect(mapStateToProps,mapDispatchToProps)(withStore(true)(withTranslation()(TrainerConversionPage)));