import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { likeRecipe, dislikeRecipe } from 'redux/actions';
import * as _ from 'lib/utilities';
import { connect } from 'react-redux';
import { trainerRecordSelector } from 'redux/selectors';
import { DislikeRecipeDropdown, getLikeRecipeFor, LikeRecipeDropdown } from './Utilities';

class RecipeLikeButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = { overrideStatus: null }
    }

    getCurStatus() {
        if(this.state.overrideStatus) {
            return this.state.overrideStatus;
        }

        const { user, recipe, trainer } = this.props;
        if(trainer) {
            const { clientDislikedRecipeIds, trainerDislikedRecipeIds, clientLikedRecipeIds, trainerLikedRecipeIds } = user;
            if([ ...clientDislikedRecipeIds, ...trainerDislikedRecipeIds].includes(recipe.staticId())) {
                return 'disliked';
            } else if([ ...clientLikedRecipeIds, ...trainerLikedRecipeIds ].includes(recipe.staticId())) {
                return 'liked'
            } else {
                return 'none';
            }
        }

        if(user.dislikedRecipeIds.includes(recipe.staticId()) || (trainer && user.trainerDislikedRecipeIds.includes(recipe.staticId()))) {
            return 'disliked';
        } else if(user.likedRecipeIds.includes(recipe.staticId()) || (trainer && user.trainerLikedRecipeIds.includes(recipe.staticId()))) {
            return 'liked';
        } else {
            return 'none';
        }
    }

    render() {
        const { recipe, user, trainer, likeRecipe } = this.props;
        const curStatus = this.getCurStatus();
        const heartClasses = classnames(`icon-btn-container like-recipe-btn-${recipe.id}`,{"red-text": curStatus === 'liked'});
        const thumbsClasses = classnames(`icon-btn-container dislike-recipe-btn-${recipe.id}`,{"orange-text": curStatus === 'disliked'});
        const heartIcon = curStatus === 'liked' ? 'heart' : ['far','heart'];
        const thumbIcon = curStatus === 'disliked' ? 'thumbs-down' : ['far','thumbs-down'];

        if(!trainer) {
    
            return (
                <div className="recipe-like-btns-container">
                    <div className={heartClasses} onClick={this.clickHandler.bind(this,'liked')}>
                        <FontAwesomeIcon icon={heartIcon} />
                    </div>
                    <div className={thumbsClasses} onClick={this.clickHandler.bind(this,'disliked')}>
                        <FontAwesomeIcon icon={thumbIcon} />
                    </div>
                </div>
            )
        }

        return (
            <div className="recipe-like-btns-container">
                <LikeRecipeDropdown 
                    trainer={trainer}
                    user={user}
                    likeRecipe={getLikeRecipeFor(likeRecipe,recipe.id)}
                    recipe={recipe}
                    triggerRender={({ ref, target }) => {
                        return (
                            <div className={heartClasses} ref={ref} data-target={target}>
                                <FontAwesomeIcon icon={heartIcon} />
                            </div>
                        )
                    }}
                />
                {!recipe.isOwnedBy(trainer) && (<DislikeRecipeDropdown 
                    recipe={recipe}
                    triggerRender={({ ref, target }) => {
                        return (
                            <div className={thumbsClasses} ref={ref} data-target={target}>
                                <FontAwesomeIcon icon={thumbIcon} />
                            </div>
                        )
                    }}
                />)}
            </div>
        )

    }

    clickHandler(btnType) {
        const curStatus = this.getCurStatus();
        const { dislikeRecipe, likeRecipe, recipe } = this.props;
        let type = 'do';
        if(curStatus === btnType) {
            type = 'undo';
            this.setState({ overrideStatus: 'none'});
        } else {
            this.setState({ overrideStatus: btnType});
        }
        const action = btnType === 'disliked' ? dislikeRecipe : likeRecipe;
        this.sendAction(action.bind(this,{recipeId: recipe.staticId(), type}));
    }


    sendAction(action) {
        this.cancelablePromise = _.makeCancelable(action());
        const respHandler = response => {
            if(!response.isCanceled) {
                this.setState({ overrideStatus: null });
            }
        }
        this.cancelablePromise.promise.then(respHandler).catch(respHandler);
    }

    componentWillUnmount() {
        if(this.cancelablePromise) {
            this.cancelablePromise.cancel();
        }
    }
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    likeRecipe: (params) => dispatch(likeRecipe(params)),
    dislikeRecipe: (params) => dispatch(dislikeRecipe(params))
})

export default connect(mapStateToProps,mapDispatchToProps)(RecipeLikeButtons)