import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react';
import classnames from 'classnames';

class SignaturePad extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = React.createRef();
    this.state = { isSigned: !!this.props.initialSignature };
  }

  componentDidMount() {
    if (this.props.initialSignature) {
      this.drawImage(this.props.initialSignature);
    } else if(!this.props.readOnly) {
      this.drawTts();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.initialSignature !== prevProps.initialSignature) {
      this.drawImage(this.props.initialSignature);
    }
  }

  drawName = () => {
    const { name } = this.props;
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas first
    const pxWidth = 26*30;
    const fontSize = name.length > 26 ? Math.floor(pxWidth/name.length) : 30;
    ctx.font = `${fontSize}px 'Fasthand', normal`; // Set a cursive font style
    ctx.textAlign = "left"; // Align text to center for better positioning
    ctx.fillStyle = '#222';
    ctx.fillText(name, 10, canvas.height / 2 + 10); // Adjust positioning as needed

    this.setState({ isSigned: true});
    this.props.onEnd(canvas.toDataURL('image/png'));
  };

  drawTts = () => {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas first
    ctx.font = `20px 'Roboto', normal`; // Set a cursive font style
    ctx.textAlign = "center"; // Align text to center for better positioning
    ctx.fillStyle = '#999';
    ctx.fillText('TAP TO SIGN', canvas.width / 2, canvas.height / 2 + 10); // Adjust positioning as needed
  };

  clearSignature = () => {
    this.drawTts(); 
    this.setState({ isSigned: false });
    this.props.onEnd('');
  };

  drawImage = (imageSrc) => {
    const canvas = this.canvasRef.current;
    const ctx = canvas.getContext('2d');
    const image = new Image();
    image.onload = () => {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
    image.src = imageSrc;
  };

  render() {
    const { readOnly } = this.props;
    const { isSigned } = this.state;
    return (
      <div className={classnames("signature-cont", { 'clickable': !(readOnly || isSigned) })} onClick={(readOnly || isSigned) ? null : this.drawName}>
        <span className="force-load-fasthand"></span>
        <canvas
          ref={this.canvasRef}
          width="320"
          height="40"
          style={{ borderBottom: '2px solid #ddd', maxWidth: '100%' }}
        />
        {!isSigned && !readOnly && (<FontAwesomeIcon icon={['fal','pen-nib']} size="2x" />)}
      </div>
    );
  }
}

export default SignaturePad;
