import * as _ from 'lib/utilities';
import moment from 'moment';
import { calRoundFactor, dateFormat } from 'config/settings';
import { editWeeklyMealPathFor, userMealPathFor } from 'config/paths';
import { MacroParams, Meal } from './classes';
import { coreMacros, macroCals } from './record-base';

export const macroMisses = (macroHash,targets) => {
    let retVal = {}
    Object.entries(targets).forEach(([macro,target]) => {
        const val = macroHash[macro];
        let missedTarget = false;
        let targetSym = '';
        if(target) {
            if(macro === 'calories') {
                targetSym = '';
                missedTarget = Math.abs(val - target.amount) > 60;
            } else if(target.dir < 0) {
                targetSym = '≤';
                missedTarget = val > target.amount + 1;
            } else {
                targetSym = '≥';
                missedTarget = val < target.amount - 1;
            }
            retVal[macro] = { ...target, missedTarget, targetSym }
        }
    })
    return retVal;
}

class WeeklyMeal {

    constructor(infoMatrix,userMeals) {
        this.userMeals = userMeals;
        this.infoMatrix = infoMatrix;
    }

    category() {
        return this.initMeal().category();
    }

    firstDate() {
        return this.initMeal().date;
    }

    categoryTitle(t) {
        const cat =  this.category();
        if(cat === 'protein') {
            return t('Protein Supplements');
        } else {
            return t(_.capitalize(cat) + 's');
        }
    }

    lockedDays() {
        if(this.isLocked()) {
            if(this.infoMatrix.isFullWeek()) {
                return this.dates().map(dt => _.wday(dt))
            }
            return null;
        } else {
            return [];
        }
    }

    initialPickDayValues(lockedDays) {
        const ordered = this.orderedWeekdays();
        const startIndex = ordered.indexOf(_.wday(this.infoMatrix.startMoment()));
        lockedDays = _.filter(lockedDays, wday => (ordered.indexOf(wday) < startIndex));
        return _.parseObjForForm({
            weekdays: lockedDays,
            dates: this.rawDates(),
            lockMeals: this.isLocked(),
            mealTypeId: this.mealType().id,
            userMealId: this.id(),
            startDate: this.infoMatrix.startDate
        })
    }

    id() {
        return this.initMeal().id;
    }

    user() {
        return this.infoMatrix.user;
    }

    planCardPath(isTrainerEditing,miniProfileId) {
        if(this.weeklyMealParent) {
            return this.weeklyMealParent.planCardPath(isTrainerEditing,this.initMeal().miniProfileId);
        }

        if(miniProfileId && this.mainDish()) {
            return userMealPathFor(this.id(),this.mainDish().staticRecipeId(),miniProfileId);
        }

        if(!isTrainerEditing && !this.user().allowedToEditOwnMealPlan() && this.mainDish()) {
            return userMealPathFor(this.id(),this.mainDish().staticRecipeId());
        }

        return this.editPath();
    }

    editPath() {
        if(this.isProteinSupp()) {
            return null;
        } else {
            return editWeeklyMealPathFor(this.initMeal());
        }
    }

    userMealIds() {
        return this.userMeals.map(um => um.id);
    }

    initMeal() {
        return this.userMeals[0];
    }

    mealType() {
        return this.initMeal().mealType;
    }

    key() {
        return this.initMeal().key();
    }

    mealCount() {
        return this.userMeals.length;
    }

    addMeal(um) {
        this.userMeals.push(um)
    }

    sideDishes() {
        return this.initMeal().sideDishes();
    }

    mainDish() {
        return this.initMeal().mainDish();
    }

    matches(userMeal) {
        return this.key() === userMeal.key();
    }

    rawDates() {
        return this.userMeals.map(um => um.date);
    }

    dates() {
        return this.userMeals.map(um => moment(um.date));
    }

    dateClumps(includeOffDays=true) {
        const weekDates = this.allWeekDates();
        const dates = this.dates();
        let clumps = [];
        let lastClump = [];
        let newClumpPushed = false;
        weekDates.forEach(dt => {
            if(_.some(dates,onDate => onDate.isSame(dt))) {
                lastClump.push(dt);
                if(!newClumpPushed) {
                    clumps.push(lastClump);
                    newClumpPushed = true;
                }
            } else {
                lastClump = [];
                newClumpPushed = false;
                if(includeOffDays) {
                    clumps.push(dt);
                }
            }
        })
        return clumps;
    }

    datesStr() {
        return this.dateClumps(false).map(clump => (clump.length > 1 ? `${clump[0].format('ddd')}-${clump[clump.length-1].format('ddd')}` : clump[0].format('ddd')))
    }

    allWeekDates() {
        return this.allWeekDatesCached = this.allWeekDatesCached || this.user().allMealPlanDates(this.infoMatrix.week);
    }

    activeDates() {
        return this.infoMatrix.weekDates();
    }

    relevantDnp() {
        const date = this.firstDate();
        return this.user().dnpOn(date)
    }

    appropriateDates() {
        const dnp = this.relevantDnp();
        return _.filter(this.allWeekDates(),date => this.user().dnpOn(date).id === dnp.id).map(dt => dt.format(dateFormat));
    }

    mealTypeActive() {
        return this.initMeal().mealTypeActive()
    }

    genericDates() {
        return _.filter(this.allWeekDates(),dt => dt.isBefore(this.infoMatrix.startMoment()));
    }

    orderedWeekdays() {
        return this.allWeekDates().map(dt => _.wday(dt))
    }

    isShared() {
        return this.initMeal().isShared();
    }

    isLocked() {
        return this.initMeal().isLocked();
    }

    isOffPlan() {
        return this.initMeal().isOffPlan()
    }

    isPinned(pinnedMealIds) {
        return _.some(this.userMeals,um => pinnedMealIds.includes(um.id))
    }

    isProteinSupp() {
        return this.initMeal().isProteinSupp();
    }

    isShareable() {
        return !this.isOffPlan() && !this.isProteinSupp();
    }

    mainName(t,tempState=null) {
        return this.initMeal().mainName(t,tempState)
    }

    mainImageUrl(size,tempState=null) {
        return this.initMeal().mainImageUrl(size,tempState)
    }

    isSharedFor(miniProfiles) {
        const ids = this.initMeal().sharedProfileIds();
        return _.every(miniProfiles,miniProfile => ids.includes(miniProfile.id))
    }

    showShareIcon(miniProfilesRemoved) {
        const ids = this.initMeal().sharedProfileIds();
        return _.difference(ids,miniProfilesRemoved.map(mp => mp.id)).length > 0
    }

    sharedProfiles() {
        return this.initMeal().sharedProfiles();
    }

    sharedProfileIds() {
        return this.initMeal().sharedProfileIds();
    }

    sharedProfileIdsAfterRemoval(miniProfileIds) {
        return _.difference(this.sharedProfileIds(),miniProfileIds);
    }

    sharedProfileIdsAfterAdding(miniProfileIds) {
        return _.union(this.sharedProfileIds(),miniProfileIds);
    }

    allForwardUserMeals() {
        if(this.isLocked()) {
            const startDate = moment(this.userMeals[this.userMeals.length-1].date);
            const forwardMeals = _.filter(this.mealType().userMeals,um => (moment(um.date).isAfter(startDate) && um.key() === this.key()));
            return [ ...this.userMeals, ...forwardMeals ];
        } else {
            return this.userMeals;
        }
    }

    shareMapAfterRemoval(miniProfileIds) {
        let profileIds = this.sharedProfileIdsAfterRemoval(miniProfileIds);
        return this.shareMapFor(profileIds);
    }

    shareMapAfterAdding(miniProfileIds) {
        let profileIds = this.sharedProfileIdsAfterAdding(miniProfileIds);
        return this.shareMapFor(profileIds);
    }

    shareMapFor(miniProfileIds) {
        miniProfileIds = miniProfileIds.length === 0 ? [''] : miniProfileIds;
        return _.mapValues(_.keyBy(this.allForwardUserMeals(),um => um.id),um => miniProfileIds)
    }

    unlockIds() {
        if(this.isLocked()) {
            const startDate = moment(this.initMeal().date);
            const unlockMeals = _.filter(this.mealType().userMeals,um => (moment(um.date).isBefore(startDate) && um.key() === this.key()));
            return unlockMeals.map(um => um.id)
        } else {
            return [];
        }
    }

    destroyedSharedMeals() {
        const allSharedMeals = this.allSharedMeals();
        const recipeMealIds = _.flatMap(allSharedMeals,um => um.recipeMeals).map(rm => rm.id);
        return { recipeMealIds, userMealIds: allSharedMeals.map(um => um.id) }
    }

    sharedMeals() {
        return this.initMeal().sharedMeals;
    }

    sharedMealFor(miniProfileId) {
        return _.find(this.sharedMeals(),um => (um.miniProfileId === miniProfileId))
    }

    isSharedMeal() {
        return !_.isBlank(this.initMeal().miniProfileId);
    }

    totalMeal() {
        let raw = this.initMeal().rawMealObject();
        const recipesTable = _.keyBy(this.initMeal().recipes(),rec => rec.id);
        this.sharedMeals().forEach(um => {
            um.recipeMeals.forEach(rm => {
                const side = _.find(raw.sideDishes,side => side.recipeId === rm.recipeId);
                if(side) {
                    side.servings = side.servings + rm.servings;
                }
            })
        })
        return new Meal(raw,recipesTable);
    }

    allSharedMeals() {
        return _.flatMap(this.allForwardUserMeals(),um => um.sharedMeals)
    }

    allDestroyedMeals() {
        const allSharedMeals = this.allSharedMeals();
        const allMeals = this.allForwardUserMeals();
        return {
            recipeMealIds: _.flatMap(_.union(allSharedMeals,allMeals),um => um.recipeMeals).map(rm => rm.id),
            userMealIds: allSharedMeals.map(um => um.id)
        }
    }

    macroWarningFor(t) {
        return this.infoMatrix.macroWarningFor(t,this.rawDates());
    }

    sharedMealVersion(miniProfile) {
        if(this.sharedMealFor(miniProfile.id)) {
            const newUserMeals = this.userMeals.map(um => _.find(um.sharedMeals,sm => (sm.miniProfileId === miniProfile.id)));
            newUserMeals.forEach((um,i) => {
                um.date = this.userMeals[i].date;
                um.mealType = this.userMeals[i].mealType;
            });

            const wm = new WeeklyMeal(this.infoMatrix,newUserMeals);
            wm.weeklyMealParent = this;
            newUserMeals.forEach(um => um.weeklyMealParent = wm);

            return wm;
        }

        return null;
    }
}

export class MealInfoMatrix {

    static blankOffPlanMap(user,offPlanState=0) {
        const startDate = user.mealPlanStartDateFor('current');
        const mts = user.sortedActiveOnPlanMts();
        const endDate = moment(startDate).add(6,'days');
        let map = {};

        for(let m = moment(startDate); m.isSameOrBefore(endDate); m.add(1,'days')) {
            const dateStr = m.format(dateFormat);

            for(let mt of mts) {
                map[mt.id] = map[mt.id] || {};
                map[mt.id][dateStr] = offPlanState;
            }
        }
        return map;
    }

    constructor(user,baseMacroParams,macroParamDays,week) {
        this.user = user;
        this.week = week;
        if(week !== 'single' && !user.mealPlanInitialized()) {
            return;
        }
        this.baseMacroParams = baseMacroParams;
        this.macroParamDays = macroParamDays;

        let umDateMapper;
        if(week === 'single') {
            const date = moment().startOf('day');
            this.startDate = date.format(dateFormat);
            this.endDate = date;
            umDateMapper = dateStr => um => !um.date;
        } else {
            this.startDate = user.mealPlanStartDateFor(week);
            this.endDate = user.mealPlanEndDateFor(week);
            umDateMapper = dateStr => um => (um.date === dateStr)
        }

        this.mealsByDate = {};

        const mts = user.planMealTypes();
        this.mealsByType = {};
        this.weeklyMeals = [];
        this.umsToWms = {};
        this.dates = [];

        mts.forEach(mt => (this.mealsByType[mt.category] = []))

        for(let m = moment(this.startDate); m.isSameOrBefore(this.endDate); m.add(1,'days')) {
            this.dates.push(m.clone());
            const dateStr = m.format(dateFormat);
            this.mealsByDate[dateStr] = _.flatMap(mts,mt => _.filter(mt.userMeals,umDateMapper(dateStr)));
            this.mealsByDate[dateStr].forEach(um => {

                let weeklyMeal = _.find(this.weeklyMeals,wm => wm.matches(um));
                if(weeklyMeal) {
                    weeklyMeal.addMeal(um);
                } else {
                    weeklyMeal = new WeeklyMeal(this,[um]);
                    this.weeklyMeals.push(weeklyMeal);
                    this.mealsByType[um.mealType.category].push(weeklyMeal);
                }
                this.umsToWms[um.id] = weeklyMeal;
            });
        }
        this.mealsByType = _.omitBy(this.mealsByType,cat => cat.length === 0)
        this.initializeOffPlanMacros();
    }

    macroParamsFor(date) {
        if(_.isBlank(date)) {
            return this.baseMacroParams;
        }

        const wday = _.wday(moment(date));
        return this.macroParamDays[wday];
    }

    isFirstWeek() {
        return this.user.mealPlanStart === this.startDate;
    }

    allUserMeals() {
        return _.flatten(Object.values(this.mealsByDate));
    }

    allSharedProfiles() {
        return _.uniqBy(_.flatMap(this.weeklyMeals, weeklyMeal => weeklyMeal.sharedProfiles()),'id');
    }

    allWeeklyMeals() {
        return this.weeklyMeals;
    }

    numDays() {
        return this.endDate.diff(this.startDate,'days') + 1;
    }

    macroHashFor(date,skipOffPlan=false) {
        const dateStr = typeof date === 'string' ? date : date.format(dateFormat);
        const key = `${dateStr}-${skipOffPlan}`;
        this.macrosByDate = this.macrosByDate || {};
        return (this.macrosByDate[key] = this.macrosByDate[key] || _.hashSum(this.mealsByDate[dateStr].map(um => (um.isOffPlan() && skipOffPlan) ? { calories: 0, protein: 0, fat: 0, carbs: 0, fiber: 0 } : um.unloggedMacroHash())));
    }

    macroHashForProfile(date,miniProfile) {
        const dateStr = typeof date === 'string' ? date : date.format(dateFormat);
        return (_.hashSum(this.mealsByDate[dateStr].map(um => um.miniProfileMacroHash(miniProfile))));
    }

    totalMacroHash() {
        if(this.cachedTotalMacroHash) {
            return this.cachedTotalMacroHash;
        }
        let hashes = [];
        for(let m = moment(this.startDate); m.isSameOrBefore(this.endDate); m.add(1,'days')) {
            hashes.push(this.macroHashFor(m));
        }
        return (this.cachedTotalMacroHash = _.hashSum(hashes));
    }

    avgMacroHash(round=true) {
        if(this.cachedAvgMacros && this.cachedAvgMacros[round]) {
            return this.cachedAvgMacros[round];
        }
        const totals = this.totalMacroHash();
        const avg = _.multiplyHash(totals,1/this.numDays());
        if(round) {
            avg.calories = _.roundToF(avg.calories,calRoundFactor);
        }
        this.cachedAvgMacros = {}
        return (this.cachedAvgMacros[round] = avg);
    }

    avgMacroHashFor(dates) {
        return _.multiplyHash(_.hashSum(dates.map(dt => this.macroHashFor(dt))),1/dates.length)
    }

    avgMacroHashForProfile(miniProfile) {
        let hashes = [];
        let numDays = 0;
        for(let m = moment(this.startDate); m.isSameOrBefore(this.endDate); m.add(1,'days')) {
            const newHsh = this.macroHashForProfile(m,miniProfile);
            hashes.push(newHsh);
            if(newHsh.calories > 5) {
                numDays += 1;
            }
        }

        const totals = _.hashSum(hashes);
        if(numDays === 0) {
            return totals;
        }

        const avg = _.multiplyHash(totals,1/numDays);
        avg.calories = _.roundToF(avg.calories,calRoundFactor);
        return avg;
    }

    weeklyGramMacroTargets(miniProfile) {
        if(this.hasSpecificDnps() || !!miniProfile) {
            return null;
        }
        return this.macroTargets = this.macroTargets || macroMisses(this.avgMacroHash(false),{ ...this.macroParamsFor().remaining({},this.user.baseTargetCalories()), calories: { amount: this.calorieTarg(), displayAmount: this.roundedCalorieTarg() } });
    }

    hasSpecificDnps() {
        return this.user.hasSpecificDnps();
    }

    calorieTarg(date) {
        return this.user.dnpOn(date).targetCalories();
    }

    roundedCalorieTarg(date) {
        return _.roundToF(this.user.dnpOn(date).targetCalories(),calRoundFactor);
    }

    rawMacroParams(date) {
        return this.macroParamsFor(date).rawMacroParams(this.calorieTarg(date));
    }

    macroHashesForDailyChart(miniProfile) {
        let hashes = {};
        for(let m = moment(this.startDate); m.isSameOrBefore(this.endDate); m.add(1,'days')) {
            hashes[m.format('ddd')] = miniProfile ? this.macroHashForProfile(m,miniProfile) : this.macroHashFor(m);
        }
        return hashes;
    }

    warningsIgnored() {
        return this.user.ignoreWarningsFor && this.user.ignoreWarningsFor.includes(this.week);
    }

    hasWarnings(miniProfile) {
        if(miniProfile || this.warningsIgnored()) {
            return false;
        }
        return !_.isBlank(this.macroWarningFor(s => s));
    }

    hasWarningsWithTrainer(isTrainerEditing) {
        if(this.user.allowedToEditOwnMealPlan() || isTrainerEditing) {
            return this.hasWarnings();
        }

        return false;
    }

    weekDates() {
        return this.dates;
    }

    stubDate() {
        return this.user.activeWeekStart(this.week);
    }

    isPublished() {
        if(this.user.mealPlanInitialized()) {
            return this.user.isMealPlanPublished(this.stubDate());
        }

        return true;
    }

    showRequestPlanScreen(isTrainerEditing,genState) {
        if(isTrainerEditing || !this.user.isClient()) {
            return false;
        }

        if(!this.isPublished()) {
            return true;
        }

        if(genState === 'ungenerated' && !this.user.allowedToEditOwnMealPlan()) {
            return true;
        }

        return false;
    }

    showFullMealPlanView(isTrainerEditing) {
        const mealPlanState = this.user.mealPlanGenerateState(this.week);

        return (mealPlanState === 'generated' || (['partial','dirty'].includes(mealPlanState) && !isTrainerEditing && !this.user.allowedToEditOwnMealPlan()));
    }

    shouldShowRestartPlan(isTrainerEditing) {
        return this.shouldShowMacroWarnings(isTrainerEditing);
    }

    shouldShowMacroWarnings(isTrainerEditing,miniProfile) {
        return (this.user.allowedToEditOwnMealPlan() || isTrainerEditing) && !miniProfile;
    }

    categoryMealMap(miniProfile) {
        if(miniProfile) {
            return this.categoryMealMapForProfile(miniProfile);
        }
        return this.mealsByType;
    }

    categoryMealMapForProfile(profile) {
        let newMap = {};
        Object.entries(this.categoryMealMap()).forEach(([cat,meals]) => {
            const newMeals = _.compact(meals.map(wm => wm.sharedMealVersion(profile)));
            if(newMeals.length > 0) {
                newMap[cat] = newMeals;
            }
        })

        return newMap;
    }

    mealsOnDate(dt,miniProfile) {
        let meals = this.mealsByDate[dt.format(dateFormat)] || [];
        if(miniProfile) {
            meals = _.compact(meals.map(um => {
                const newUm = um.sharedMealFor(miniProfile);
                if(newUm) {
                    newUm.date = um.date;
                    newUm.mealType = um.mealType;
                    return newUm;
                }

                return null;
            }));
        }

        return meals;
    }

    weeklyMealForUserMeal(userMeal) {
        return this.weeklyMealForUserMealId(userMeal.id);
    }

    planCardPathFor(userMeal,isTrainerEditing) {
        if(userMeal.sharedMealParent) {
            return this.weeklyMealForUserMeal(userMeal.sharedMealParent).planCardPath(isTrainerEditing,userMeal.miniProfileId);
        } else {
            return this.weeklyMealForUserMeal(userMeal).planCardPath(isTrainerEditing);
        }
    }

    weeklyMealForUserMealId(userMealId) {
        return this.umsToWms[userMealId];
    }

    matchingMealForUserMeal(userMeal,forEdit=true) {
        let meal = this.weeklyMealForUserMeal(userMeal)
        if(!meal) {
            const userMeals = userMeal.forwardMatchesFor(this.endDate);
            if(forEdit) {
                for(let um of userMeals) {
                    meal = this.weeklyMealForUserMeal(um);
                    if(meal) {
                        break;
                    }
                }
            } else if(userMeals.length === 0) {
                return null;
            } else {
                return new WeeklyMeal(this,userMeals);
            }
        }
        return meal;
    }

    weeklyMealsForIds(ids) {
        return _.compact(ids.map(id => this.weeklyMealForUserMealId(id)));
    }

    allUserMealIds() {
        return this.allUserMeals().map(um => um.id);
    }

    pinnedIds() {
        return _.filter(this.allUserMeals(),um => um.isPinned()).map(um => um.id);
    }

    startMoment() {
        return this.startMomentCached = this.startMomentCached || moment(this.startDate);
    }

    isFullWeek() {
        return this.startMoment().isSame(this.user.activeWeekStart(this.week),'day');
    }

    offPlanMealMap() {
        let map = {};

        for(let m = moment(this.startDate); m.isSameOrBefore(this.endDate); m.add(1,'days')) {
            const dateStr = m.format(dateFormat);
            const ums = this.mealsByDate[dateStr];
            for(let um of ums) {
                map[um.mealType.id] = map[um.mealType.id] || {};
                map[um.mealType.id][dateStr] = um.offPlanState();
            }
        }
        return map;
    }

    showEditingFeatures(isTrainerEditing) {
        return isTrainerEditing || this.user.allowedToEditOwnMealPlan();
    }

    macroWarningFor(t,dates) {
        if(!this.showEditingFeatures()) {
            return null;
        }

        if(dates) {
            const macroHash = this.avgMacroHashFor(dates);
            const macroParams = this.rawMacroParams(dates[0]);
            return MacroParams.macroWarnings(t,macroParams,macroHash);
        }

        const dnpMap = {};
        this.weekDates().forEach(dt => {
            const dnp = this.user.dnpOn(dt);
            dnpMap[dnp.id] = dnpMap[dnp.id] || { dnp, dates: [] };
            dnpMap[dnp.id].dates.push(dt);
        })
        const dnpDates = Object.values(dnpMap);

        if(dnpDates.length === 1) {
            return this.macroWarningFor(t,dnpDates[0].dates);
        }
        
        return _.compact(dnpDates.map(({ dnp, dates }) => {
            const warnings = this.macroWarningFor(t,dates);
            if(warnings) {
                return `${dnp.displayName(t)} (${dnp.daysSummary(t)}): ${warnings}`
            }
            return null;
        })).join(' ')

    }

    isSingle() {
        return this.week === 'single';
    }

    initializeOffPlanMacros() {
        for(let m = moment(this.startDate); m.isSameOrBefore(this.endDate); m.add(1,'days')) {
            const targs = this.rawMacroParams(m);
            const usedUp = this.macroHashFor(m,true);
            const meals = _.filter(this.mealsOnDate(m),meal => meal.isOffPlan());
            if(meals.length > 0) {
                const totalUnits = meals.map(meal => meal.mealType.percentDailyIntake).reduce((total,pct) => (total+pct),0);
                const remainingParams = MacroParams.recommendedMacros(targs,usedUp);
                const overallTargets = { calories: remainingParams.calories, ..._.mapValues(_.omit(remainingParams,'calories'),params => params[0]) };
                meals.forEach(meal => {
                    const pct = meal.mealType.percentDailyIntake/totalUnits;
                    let baseHash = _.multiplyHash(overallTargets,pct);
                    let calsRemaining = baseHash.calories - MacroParams.caloriesFor(baseHash);

                    if(calsRemaining < 0) {
                        const newHash = _.pick(baseHash,coreMacros);
                        let remainingMacroCnt = Object.keys(newHash).length;
                        let cutsNeeded = Math.abs(calsRemaining);
                        Object.entries(newHash).forEach(([macro,val]) => {
                            const cutAmt = Math.min(cutsNeeded/remainingMacroCnt,macroCals[macro]*val);
                            newHash[macro] = Math.max(newHash[macro] - cutAmt/macroCals[macro],0);
                            cutsNeeded = cutsNeeded - cutAmt;
                            remainingMacroCnt = remainingMacroCnt - 1;
                        })
                        calsRemaining = 0;
                        baseHash = { ...baseHash, ...newHash };
                    }

                    const macrosMissed = _.difference(coreMacros,Object.keys(baseHash));
                    macrosMissed.forEach(macro => {
                        baseHash[macro] = Math.max((calsRemaining/macrosMissed.length)/macroCals[macro],0)
                    })

                    if(_.isBlank(baseHash.fiber)) {
                        baseHash.fiber = Math.min(5,baseHash.carbs*0.5);
                        baseHash.carbs = baseHash.carbs - baseHash.fiber;
                    }


                    meal.unloggedMacros = _.mapValues(baseHash,val => Math.round(val));
                })
            }
        }
    }

}