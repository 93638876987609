import { createSelector } from 'reselect';
import * as _ from 'lib/utilities';
import { User, UserMeal, Meal, Recipe, Food, MacroParams, ExerciseProgression, WorkoutRoutine, MealType, WeightRecord, Workout, Exercise, ExerciseSetting, StrengthTest, ProgressionTest, ProgressionScheme, ExerciseProgressionRequirement, Notification, GroceryItem, Ingredient, Habit, ActivityLog, Form, Assessment } from 'lib/classes';
import moment from 'moment';
import { calRoundFactor, dateFormat } from 'config/settings';
import { MealInfoMatrix } from 'lib/meal-info-matrix';
import { SearchSeed, MealSearch, MealSearchCategory } from 'lib/meal-search';
import { matchPath } from 'react-router';
import { addExercisesMatch, clientDetailsMatch, clientMealPlansMatch, editExerciseGroupMatch, editRoutinePath, editRoutineStandaloneMatch, editWorkoutTemplateMatch, mainMatches, recipeConfirmPaths, standaloneMealPlanMatch, trainerMainMatches } from 'config/paths';
import { UPGRADE_POPUP } from 'config/tooltips';
import { DailyNutritionProfile } from 'lib/daily-nutrition-profile';

const allDataSelector = state => state.data;
export const deviceReadySelector = state => (state.ui && state.ui.deviceReady);
export const viewportHeightSelector = state => (state.ui && state.ui.viewportHeight);
export const historySelector = state => state.history;
export const userSelector = (state) => state.data.user;
export const usersSelector = state => state.data.users;
export const jwtSelector = state => state.data.jwt;
export const cacheRefreshedSelector = (state) => state.ui.cacheRefreshed;
export const needsProPopupSelector = state => state.ui.needsProPopup;
export const failedRequestSelector = state => state.ui.failedRequest;
export const successRequestSelector = state => state.ui.successRequest;
export const showSaveFlashSelector = state =>  state.ui.showSaveFlash;
export const appRenderKeySelector = state => state.ui.appRenderKey;
export const renderKeySelector = state => state.ui.renderKey;
export const trainerClientCountsSel = state => state.ui.clientCounts;
export const unsavedChangesSelector = state => state.ui.unsavedState;
export const loadedDatesSelector = state => (state.data.loadedDates || []);
export const loadedMpDatesSelector = state => (state.data.loadedMpDates || []);
export const dailyNutritionProfilesSel = state => state.data.dailyNutritionProfiles;
export const nutritionParametersSelector = state => state.data.nutritionParameters;
export const userMealsSelector = state => state.data.userMeals;
export const recUmsSelector = state => state.data.recentUserMeals;
export const signupFlowSelector = state => state.wip.signupFlow;
export const onboardingFlowSelector = state => state.wip.onboardingFlow;
export const exerciseProgsSelector = state => state.data.exerciseProgressions;
export const allergyTagsSelector = state => (state.data.allergyTags || []);
export const recommendedCalsSelector = state => state.data.user && state.data.user.recommendedCalories && _.roundToF(state.data.user.recommendedCalories,calRoundFactor);
export const recipesSelector = state => ((state.data.recipes && state.data.recipes) || {});
export const analyticsSelector = state => state.analytics;
export const lastLoadedWorkoutDaySel = state => state.data.lastLoadedWorkoutDay;
export const autoplaySelector = state => state.ui.supportsAutoplay;
export const paywallRedirectSelector = state => state.ui.paywallRedirect;
export const muteWorkoutTimersSelector = state => (state.data.user && state.data.user.muteTimers);
export const errorLogsSelector = state => state.tempData.errorLogs;
export const bodyMeasurementsSelector = state => state.tempData.bodyMeasurements;
export const mealPlanViewTypeSel = state => state.persistentUi.mealPlanViewType;
export const pinnedMealsSelector = (state,props) => state.tempData.pinnedMeals[(props.mealInfoMatrix && props.mealInfoMatrix.startDate) || null];
export const offPlanMealMapSelector = (state,props) => state.tempData.offPlanMeals[(props.mealInfoMatrix && props.mealInfoMatrix.startDate) || null];
export const addPeopleToMealsSelector = (state,props) => state.tempData.addPeopleToMeals[(props.mealInfoMatrix && props.mealInfoMatrix.startDate) || null];
export const workoutImageCatSelector = state => (state.data.workoutImageCategories ? Object.values(state.data.workoutImageCategories) : [])
export const glistPreferencesSelector = state => state.wip.glistPreferences;
export const myRecipesFiltersSelector = state => state.wip.myRecipesFilters;
export const teamRecipesFiltersSelector = state => state.wip.teamRecipesFilters;
export const trainerSelector = state => state.trainerData.trainer;
export const trainerForRoutinesSel = state => state.persistentUi.trainerForRoutines;
export const trainerForHabitsSel = state => state.persistentUi.trainerForHabits;
export const viewLogsDateSelector = state => (state.persistentUi.viewLogsDate ? moment(state.persistentUi.viewLogsDate) : moment());
export const chatsMetaSelector = state => state.tempData.chatsMeta;
export const clientsScrollMetaSel = state => state.tempData.clientsScrollMeta;
export const trainersScrollMetaSel = type => state => state.tempData[`${type}ScrollMeta`];
export const muxUploadProgs = state => state.ui.uploadProgress;
export const videoUploadsSel = state => state.persistentUi.videoUploads;
export const filterBtnFiltsSel = state => state.ui.filterBtnFilts;
export const frontendAbTest1Sel = state => state.frontendAbTest1 && state.frontendAbTest1.version;
export const scannerDeviceIdSel = state => state.persistentUi.scannerDeviceId;
const pdfExportSettingsSel = state => state.data.pdfExportSettings;
const trainerNotesSelector = state => state.data.trainerNotes;
const subscriptionsSelector = state => state.data.subscriptions;
const trainerSignupSelector = state => state.wip.trainerSignup;
const propsDateSelector = (state,props) => (props.date && props.date.format(dateFormat));
const propsUserSelector = (state,props) => props.user;
const propsIdSelector = (state,props) => props.id;
const propsPlanTypeSel = (state,props) => props.match.params.planType;
const propsLocationSel = (state,props) => props.location;
const mealPlanFlowWipSelector = state => state.wip.mealPlanSetupFlow;
const workoutRoutinesSelector = state => state.data.workoutRoutines;
const workoutTemplateChildrenSelector = state => state.data.workoutTemplateChildren;
const possibleRoutineIdsSelector = state => (state.data.possibleRoutines && state.data.possibleRoutines.match);
const unpossibleRoutineIdsSelector = state => (state.data.possibleRoutines && state.data.possibleRoutines.other);
const initialRecipeOptionsPreSel = state => state.tempData.initialRecipeOptions;
const mealTypesSelector = state => state.data.mealTypes;
const recipeMealsSelector = state => state.data.recipeMeals;
const weightRecordsSelector = state => state.data.weightRecords;
const workoutsSelector = state => state.data.workouts;
const exerciseSpecificationsSelector = state => state.data.exerciseSpecifications;
const exerciseSetsSelector = state => state.data.exerciseSets;
const exerciseTemplatesSelector = state => state.data.exerciseTemplates;
const workoutTemplatesSelector = state => state.data.workoutTemplates;
const setTemplatesSelector = state => state.data.setTemplates;
const exercisesSelector = state => state.data.exercises;
const progexSelector = state => state.data.progressionExercises;
const exerciseSettingsSelector = state => state.data.exerciseSettings;
const warmupsSelector = state => (state.data.warmups || {});
const notificationArrSelector = state => state.data.notifications;
const habitsSelector = state => state.data.habits;
const habitLogsSelector = state => state.data.habitLogs;
const activityLogsSelector = state => state.data.activityLogs;
const schedulableSettingsSelector = state => state.data.schedulableSettings;
const habitsByIdsSelector = (state,{ recordIds }) => _.pick(habitsSelector(state),recordIds);
const mpInfoStubsSel = state => state.data.mpInfoStubs;
const chatsSelector = state => state.data.chats;
const chatMembershipsSelector = state => state.data.chatMemberships;
const chatMessagesSelector = state => state.data.chatMessages;
const chatEventsSelector = state => state.data.chatEvents;
const currentActivitySelector = state => state.wip.currentActivity;
const formsSelector = state => state.data.forms;
const formsByIdsSelector = (state,{ recordIds }) => _.pick(formsSelector(state),recordIds);
const formFieldsSelector = state => state.data.formFields;
const userDataEntriesSelector = state => state.data.userDataEntries;
const assessmentsSelector = state => state.data.assessments;
const assessmentResultsSelector = state => state.data.assessmentResults;
const progressPhotosSelector = state => state.data.progressPhotos;
//COMMENT FOR GENERATOR, DO NOT TOUCH. ADD SELECTORS ABOVE HERE

export const curActivitySel = createSelector( 
    currentActivitySelector,
    (activity) => {
        if(activity) {
            return ActivityLog.createFromRedux(activity);
        }

        return null;
    }
)

export const warmupOptionsSelector = state => {
    let options = [{ text: 'None', value: '' }];
    if(state.data.warmups) {
        Object.values(state.data.warmups.workouts).forEach(workout => {
            options.push({ text: workout.name, value: workout.id });
        })
    }
    return options;
}
const warmupsWithExercisesSel = createSelector(
    exercisesSelector,
    warmupsSelector,
    (exercises,warmups) => ({ ...(warmups || {}), exercises })
)
const exerciseSearchSelector = state => state.data.exerciseSearch;
export const warmupsNeedLoadingSel = state => _.isEmpty(warmupsSelector(state))

export const defaultUserSelector = createSelector(
    userSelector,
    trainerSelector,
    (user,trainer) => {
        let usr = (trainer || user);
        if(trainer && user && trainer.id === user.id) {
            usr = { ...trainer, ...user };
        }

        if(usr) {
            return User.newWithAssocs(usr,{});
        }
        return User.newWithAssocs(User.BASIC_PROFILE_DEFAULTS,{});
    }
)

export const bmUserSelector = createSelector(
    userSelector,
    user => {
        if(user) {
            return User.newWithAssocs(user,{});
        }
        return User.newWithAssocs(User.BASIC_PROFILE_DEFAULTS,{});
    }
)

export const userRecordSelector = createSelector(
    userSelector,
    exerciseSettingsSelector,
    exercisesSelector,
    mealTypesSelector,
    dailyNutritionProfilesSel,
    nutritionParametersSelector,
    notificationArrSelector,
    subscriptionsSelector,
    usersSelector,
    habitsSelector,
    schedulableSettingsSelector,
    habitLogsSelector,
    pdfExportSettingsSel,
    mpInfoStubsSel,
    formsSelector,
    workoutRoutinesSelector,
    (user,exerciseSettings,exercises,mealTypes,dailyNutritionProfiles,nutritionParameters,notifications,subscriptions,users,habits,schedulableSettings,habitLogs,pdfExportSettings,mpInfoStubs,forms,workoutRoutines) => (user && User.newWithAssocs(user,{ exerciseSettings, exercises, mealTypes, dailyNutritionProfiles, nutritionParameters, subscriptions, users, habits, schedulableSettings, habitLogs, pdfExportSettings, mpInfoStubs, forms, workoutRoutines, notifications: _.keyBy(notifications || [],'id') }))
)

export const userWithActivityLogsSel = createSelector(
    userSelector,
    activityLogsSelector,
    exerciseSpecificationsSelector,
    exerciseSetsSelector,
    exercisesSelector,
    (user,activityLogs,exerciseSpecifications,exerciseSets,exercises) => (user && User.newWithAssocs(user,{ activityLogs, exerciseSpecifications, exerciseSets, exercises }))
)

export const userWithProgressPhotosSel = createSelector(
    userSelector,
    progressPhotosSelector,
    (user,progressPhotos) => (user && User.newWithAssocs(user,{ progressPhotos }))
)

export const hasSingleDayMealsSel = createSelector(
    userMealsSelector,
    (userMeals) => {
        if(userMeals && _.some(Object.values(userMeals),userMeal => (_.isBlank(userMeal.date) && !_.isBlank(userMeal.mealTypeId)))) {
            return true;
        }
        return false;
    }
)


export const notificationRecordsSelector = createSelector(
    notificationArrSelector,
    notifications => notifications.map(notification => new Notification(notification))
)

const assignedRoutineSelector = createSelector(
    workoutRoutinesSelector,
    userRecordSelector,
    (workoutRoutines,user) => (workoutRoutines[user.assignedRoutineId] && new WorkoutRoutine(workoutRoutines[user.assignedRoutineId]))
)

export const hasEmailSelector = createSelector(
    userSelector,
    (user) => (user && !!user.email)
)

export const hasBasicProfileSelector = createSelector(
    userSelector,
    (user) => (user && user.hasBasicProfile)
);

export const signupFlowDataSelector = createSelector(
    signupFlowSelector,
    defaultUserSelector,
    (signupData,user) => {
        const inData = signupData || user.initialSignupValues();
        if(inData.unitPreference === 1) {
            const { footHeight, inchHeight, currentWeight, ...data } = inData;
            return { ...data, hasBasicProfile: true };
        } else {
            const { metricHeight, metricWeight, ...data } = inData;
            return { ...data, hasBasicProfile: true };
        }
    }
)

export const onboardingFlowDataSelector = createSelector(
    onboardingFlowSelector,
    (signupData) => {
        if(signupData) {
            return signupData;
        }

        return { 
            unitPreference: 0, 
            simpleWeight: 200, 
            simpleGoalWeight: 180, 
            age: 45, 
            simpleHeight: 66, 
            activityLevel: 0, 
            similarUsers1: _.random(30000,50000), 
            similarUsers2: _.random(10000,30000), 
            similarUsers3: _.random(3000,10000),
            dailyNutritionProfiles: DailyNutritionProfile.buildForForm([])
         };
    }
)

export const trainerSignupFlowSel = createSelector(
    trainerSignupSelector,
    defaultUserSelector,
    propsPlanTypeSel,
    (signupData,user,planType) => {
        let vals =  (signupData && !_.isEmpty(signupData)) ? signupData : user.trainerSignupValues(planType);
        return vals;
    }
)

export const trainerInviteDataSelector = createSelector(
    signupFlowSelector,
    signupFlowDataSelector,
    defaultUserSelector,
    (signupFlowData,signupData,user) => {
        const data = { email: user.email, firstName: user.firstName, password: '' };
        //only want to pull in the signup flow data for a client who has seen the signup flow and doesn't already have a basic profile
        const ignoreSignupData = !signupFlowData || user.hasBasicProfile || user.isTrainer();
        
        if(ignoreSignupData) {
            return data;
        }

        return ({ ...signupData, ...data })
    }
)

export const workoutFlowSelector = createSelector(
    userSelector,
    (user) => {
        let vals = _.pick(user,['needsCardio','gymType','routineFocus','liftingExperience','trainingDays','workoutTime','allowedExerciseProgressionIds','newRoutine','routineStart','workoutRemind','workoutRemindPm'])
        vals.routineStart = _.todayParam(dateFormat);
        vals.noRoutine = false;
        _.defaultValues(vals,{liftingExperience: 0, workoutTime: '', workoutRemindPm: false });
        return _.parseObjForForm(vals);
    }
)

export const mealPlanFlowSelector = createSelector(
    userRecordSelector,
    allergyTagsSelector,
    mealPlanFlowWipSelector,
    (user,allergyTags,wip) => {
        const mnpArr = user.mnpFormValArr();
        if(wip) {
            wip.dailyNutritionProfiles = mnpArr;
            return wip;
        } else {
            return user && user.mealPlanValues(allergyTags,true);
        }
    }
)

export const initialRecipeOptionsSelector = createSelector(
    initialRecipeOptionsPreSel,
    recipesSelector,
    (dnpOptions,recipes) => {
        if(!dnpOptions) {
            return null;
        }

        let topResults = {}
        Object.entries(dnpOptions).forEach(([dnpId,dnpVals]) => {
            const { id, name, ...options } = dnpVals;
            let results = { id, name };
            Object.entries(options).forEach(([category,vals]) => {
                results[category] = { 
                    recipes: _.recordsFromIds(recipes,vals.recipeIds,Recipe), 
                    initialExclusions: vals.initialExclusions, 
                    pageExcluded: vals.pageExcluded,
                    finished: vals.finished, 
                    seed: vals.seed, 
                    page: vals.page, 
                    meetsMacros: vals.meetsMacros,
                    mealSearchCategoryId: vals.mealSearchCategoryId
                };
            })
            topResults[dnpId] = results;
        })


        return topResults;
    }
)

const bprogIdsSelector = state => state.data.bannableProgressions

export const bannableProgSelector = createSelector(
    bprogIdsSelector,
    exerciseProgsSelector,
    progexSelector,
    exercisesSelector,
    (bprogIds,exerciseProgressions,progressionExercises,exercises) => (bprogIds && exerciseProgressions && ExerciseProgression.where(prog => bprogIds.map(id => Number(id)).includes(prog.id), { exerciseProgressions, progressionExercises, exercises }))
)

const oauthDataFn = (data) => {
    const { email, password, ...newData } = data;
    return newData;
}

export const oauthSignupDataSelector = createSelector(
    signupFlowDataSelector,
    oauthDataFn
)

export const trainerOauthSignupDataSelector = createSelector(
    trainerSignupFlowSel,
    oauthDataFn
)

export const recUmsIdsSelector = createSelector(
    recUmsSelector,
    (recUms) => (recUms && recUms.ids)
)

export const recUmsPageSelector = createSelector(
    recUmsSelector,
    (recUms) => ((recUms && recUms.page) || 0)
)

export const recUmsMoreSelector = createSelector(
    recUmsSelector,
    (recUms) => ((recUms && 'more' in recUms) ? recUms.more : true)
)

export const recentUserMealsSelector = createSelector(
    recUmsIdsSelector,
    userMealsSelector,
    (ids,userMeals) => _.recordsFromIds(userMeals,ids,UserMeal,(adder,other) => (adder.recentIsEquivalent(other)))
)

const offPlanRecipeSearchSel = state => state.data.offPlanRecipeSearch;

export const offPlanRecipeSearchMoreSel = createSelector(
    offPlanRecipeSearchSel,
    (offPlanRecipeSearch) => ((offPlanRecipeSearch && 'more' in offPlanRecipeSearch) ? offPlanRecipeSearch.more : true)
); 

export const offPlanRecipeSearchPageSel = createSelector(
    offPlanRecipeSearchSel,
    (offPlanRecipeSearch) => ((offPlanRecipeSearch && offPlanRecipeSearch.page) || 0)
);

export const offPlanRecipeSearchQuerySel = createSelector(
    userRecordSelector,
    offPlanRecipeSearchSel,
    (user,offPlanRecipeSearch) => ((offPlanRecipeSearch && offPlanRecipeSearch.queryParams) || { keywords: '', dietType: user.dietTag() })
);

const offPlanRecipeSearchStubSel = createSelector(
    offPlanRecipeSearchSel,
    (offPlanRecipeSearch) => ((offPlanRecipeSearch && offPlanRecipeSearch.recipeStubs) || { })
);

export const offPlanRecipeSearchSelector = createSelector(
    recipesSelector,
    offPlanRecipeSearchStubSel,
    (recipes,servingStubs) => _.recordsFromPartials(recipes,servingStubs,Recipe)
)

const ingredientsSelector = state => state.data.ingredients;

export const makeIngredientsSelector = () => (
    createSelector(
        ingredientsSelector,
        ingredients => ingredients
    )
)

export const baseActiveMacroParamsSelector = createSelector(
    userRecordSelector,
    user => user.baseActiveMacroParams()
)

const activeMacroParamDaysSel = createSelector(
    userRecordSelector,
    user => user.activeMacroParamDaysArr()
)

export const seenTooltips = state => ((state.data && state.data.user && state.data.user.seenTooltips) || []);

export const tooltipSeenCreator = (tipName) => createSelector(
    seenTooltips,
    (seenTipsArr) => (seenTipsArr && seenTipsArr.includes(tipName))
)

export const possibleRoutinesSelector = createSelector(
    workoutRoutinesSelector,
    possibleRoutineIdsSelector,
    (routines,ids) => _.recordsFromIds(routines,ids,WorkoutRoutine)
)

export const unpossibleRoutinesParams = createSelector(
    possibleRoutinesSelector,
    (routines) => ({ excludedFnames: routines.map(routine => routine.imageName), excludeIds: routines.map(routine => (_.isBlank(routine.generatorParentId) ? routine.id : routine.generatorParentId)) })
)

export const unpossibleRoutinesSelector = createSelector(
    workoutRoutinesSelector,
    unpossibleRoutineIdsSelector,
    (routines,ids) => (ids && _.recordsFromIds(routines,ids,WorkoutRoutine))
)

export const loggedMealsForDateSel = createSelector(
        propsUserSelector,
        propsDateSelector,
        userMealsSelector,
        recipeMealsSelector,
        recipesSelector,
        (user,date,userMeals,recipeMeals,recipes) => {
            const mtids = user.mealTypes.map(mt => mt.id);
            if(userMeals) {
                let loggedMeals = UserMeal.where(userMeal => (mtids.includes(userMeal.mealTypeId) && userMeal.logType === UserMeal.LOGGED && userMeal.date === date), { userMeals, recipeMeals, recipes });
                return loggedMeals;
            } else {
                return [];
            }
    }
)

export const loggedMacrosForDateSel = createSelector(
    loggedMealsForDateSel,
    (userMeals) => {
        if(userMeals.length > 0) {
            return _.hashSum(userMeals.map(userMeal => userMeal.loggedMacroHash()));
        } else {
            return null;
        }
    }
)

export const makeWeightRecordForDateSel = () => {
    return createSelector(
        propsDateSelector,
        weightRecordsSelector,
        (date,weightRecords) => {
            const rec = _.find(weightRecords,weightRecord => (weightRecord.date === date));
            if(rec) {
                return new WeightRecord(rec);
            } else {
                return null;
            }
        }
    )
}

export const makeUserMealFromIdSel = () => {
    return createSelector(
        propsIdSelector,
        userMealsSelector,
        mealTypesSelector,
        recipeMealsSelector,
        recipesSelector,
        ingredientsSelector,
        foodWeightsSelector,
        foodsSelector,
        (id,userMeals,mealTypes,recipeMeals,recipes,ingredients,foodWeights,foods) => {
            if(userMeals && userMeals[id]) {
                return UserMeal.newWithAssocs(userMeals[id],{ mealTypes,recipeMeals,recipes,ingredients,foodWeights,foods });
            } else {
                return null;
            }
        }
    )
}

export const userMealMealTypeSelector = (state,props) => (props.record.mealTypeId ? new MealType(state.data.mealTypes[props.record.mealTypeId]) : null);

export const userWithRoutineSelector = createSelector(
    userRecordSelector,
    assignedRoutineSelector,
    (user,routine) => {
        user.assignedRoutine = routine;
        return user;
    }
)

export const propsMacroTargetsSel = (state,props) => {
    const { targets } = props;
    if(targets) {
        const { calories, ...macros } = targets;
        return { targetCals: _.roundToF(calories,calRoundFactor), macroParams: MacroParams.newFromDailyTargets(macros)}
    }
    return null;
}

const calcRemainingMacros = (user,date,loggedMeals,targets) => {
    const dnp = user.dnpOn(date);
    const macroParams = targets ? targets.macroParams : dnp.activeMacroParams();
    const usedMacros = _.hashSum(loggedMeals.map(userMeal => userMeal.loggedMacroHash()));
    const targetCals = targets ? targets.targetCals : dnp.targetCalsReadable();
    let remaining = macroParams.remaining(usedMacros,targetCals);
    remaining.calories = targetCals - (usedMacros.calories || 0);
    remaining.flexibleMacros = macroParams.coreFlexibleMacros();
    remaining.targetCalories = targetCals;
    return remaining;
}

export const remainingMacrosSel = createSelector(
        propsUserSelector,
        propsDateSelector,
        loggedMealsForDateSel,
        propsMacroTargetsSel,
        calcRemainingMacros
)

const workoutsForDates = (user,workouts,cachedData,startDate,endDate) => {
    let map = {};
    const workoutMap =_.keyBy(Object.values(workouts),'date');
    while(startDate.isSameOrBefore(endDate)) {
        const date = startDate.format(dateFormat);
        let obj;
        let cached = false;
        if(cachedData[date]) {
            obj = Object.values(cachedData[date].workouts)[0];
            cached = true;
        } else {
            obj = workoutMap[date];
        }

        if(obj) {
            map[date] = Workout.newWithAssocs(obj,{ users: { [user.id]: user }}, { user: {} });
            if(cached) {
                map[date].cached = true;
            }
        }
        startDate.add(1,'days');
    }
    return map;
}

const warmupWorkoutsSel = state => (state && state.workouts);
const warmupSpecsSel = state => (state && state.exerciseSpecifications);
const warmupSetsSel = state => (state && state.exerciseSets);
const warmupExercisesSel = state => (state && state.exercises);
const warmupSelectors = {};
const makeWarmupSelector = (id) => {
    return createSelector(
        warmupWorkoutsSel,
        warmupSpecsSel,
        warmupSetsSel,
        warmupExercisesSel,
        (workouts,exerciseSpecifications,exerciseSets,exercises) => {
             return Workout.find(id, { workouts, exerciseSpecifications, exerciseSets, exercises }, { 
                exerciseSpecifications: { 
                    exerciseSets: {}, 
                    exercise: {} 
                } 
            });
        }
    )
}
const getWarmupSelector = (id) => {
    warmupSelectors[id] = warmupSelectors[id] || makeWarmupSelector(id);
    return warmupSelectors[id];
}

const workoutBuilder = (
    workoutFinder,
    singular,
    user,
    workouts,
    exerciseSpecifications,
    exerciseSets,
    exerciseTemplates,
    setTemplates,
    exercises,
    exerciseProgressions,
    exerciseSettings,
    workoutTemplates,
    warmupsState,
    isCached, 
    users,
    subscriptions) => {

    if(!workouts) {
        return null;
    }

    const finalWorkoutFinder = workout => (workout.userId === user.id && workoutFinder(workout));
    const tables = { users: { ...users, [user.id]: user }, workouts, exerciseSpecifications, exerciseSets, exerciseTemplates, setTemplates, exercises, exerciseProgressions, exerciseSettings, workoutTemplates, subscriptions };
    const wrks = Workout.where(finalWorkoutFinder, tables, { 
        user: { 
            exerciseSettings: {}, 
            assignedRoutine: {},
            trainer: {
                master: {
                    trainerSubscription: {}
                },
                trainerSubscription: {}
            },
            master: {
                trainerSubscription: {}
            },
            clients: {},
            children: {}
        }, 
        exerciseSpecifications: {
            exerciseSets: { setTemplate: {} },
            exerciseTemplate: { setTemplates: {}, exercisable: {} },
            exercise: {}
        }, 
        workoutTemplate: {}, 
        cardioTemplate: {} 
    });

    if(isCached) {
        wrks.forEach(wrk => (wrk.cached = true))
    }

    wrks.forEach(workout => {
        for(let attr of ['warmup','cooldown']) {
            const id = workout[`${attr}Id`]();
            if(id) {
                workout[attr] = getWarmupSelector(id)(warmupsState);
                if(workout[attr]) {
                    workout[attr][`is${_.upperFirst(attr)}`] = true;
                    workout[attr].user = workout.user;
                    workout[attr].parentWorkout = workout;
                }
            } else {
                workout[attr] = null;
            }
        }
    })

    if(singular) {
        if(wrks.length > 0) {
            return wrks[0];
        } else {
            return null;
        }
    } else {
        return wrks;
    }
}

const wodSelectors = {};
const notCachedSelector = state => false;
const makeMakeWODSelector = (workoutFinderMaker,singular) => date => {
    return createSelector(
        userSelector,
        workoutsSelector,
        exerciseSpecificationsSelector,
        exerciseSetsSelector,
        exerciseTemplatesSelector,
        setTemplatesSelector,
        exercisesSelector,
        exerciseProgsSelector,
        exerciseSettingsSelector,
        workoutTemplatesSelector,
        warmupsWithExercisesSel,
        notCachedSelector,
        usersSelector,
        subscriptionsSelector,
        workoutBuilder.bind(null,workoutFinderMaker(date),singular)
    )
}
export const makeWODSelector =  makeMakeWODSelector(date => workout => (workout.date === date),true)

export const getWodSelector = date => {
    wodSelectors[date] = wodSelectors[date] || makeWODSelector(date);
    return wodSelectors[date];
}

const wodLogSelectors = {};
export const makeWODLogSelector =  makeMakeWODSelector(date => workout => ((workout.logDate || workout.date) === date),false)

export const getWodLogSelector = date => {
    wodLogSelectors[date] = wodLogSelectors[date] || makeWODLogSelector(date);
    return wodLogSelectors[date];
}

const allCachedWorkoutDatesSel = state => Object.keys(state.wip.cachedWorkouts || {})
const allCachedWorkoutsSel = state => (state.wip.cachedWorkouts || {});
const makeCachedWorkoutSliceSel = (date,slice) => state => state.wip.cachedWorkouts && state.wip.cachedWorkouts[date] && state.wip.cachedWorkouts[date][slice];
const cachedWorkoutsSelector = date => makeCachedWorkoutSliceSel(date,'workouts');
const cachedExerciseSpecsSelector = date => makeCachedWorkoutSliceSel(date,'exerciseSpecifications');
const cachedExerciseSetsSelector = date => makeCachedWorkoutSliceSel(date,'exerciseSets');
const cachedExercisesSelector = date => makeCachedWorkoutSliceSel(date,'exercises');
const cachedExerciseProgsSelector = date => makeCachedWorkoutSliceSel(date,'exerciseProgressions');
export const cachedProgexSelector = date => makeCachedWorkoutSliceSel(date,'progressionExercises');
const cachedExerciseTemplatesSelector = date => makeCachedWorkoutSliceSel(date,'exerciseTemplates');
const cachedSetTemplatesSelector = date => makeCachedWorkoutSliceSel(date,'setTemplates');
const cachedWorkoutTemplatesSelector = date => makeCachedWorkoutSliceSel(date,'workoutTemplates');
const cachedStrengthTestsSelector = date => makeCachedWorkoutSliceSel(date,'strengthTests');
const cachedProgressionTestsSelector = date => makeCachedWorkoutSliceSel(date,'progressionTests');
const isCachedSelector = state => true;

const cachedWODSelectors = {};
export const makeCachedWODSelector = (date) => {
    return createSelector(
        userSelector,
        cachedWorkoutsSelector(date),
        cachedExerciseSpecsSelector(date),
        cachedExerciseSetsSelector(date),
        cachedExerciseTemplatesSelector(date),
        cachedSetTemplatesSelector(date),
        cachedExercisesSelector(date),
        cachedExerciseProgsSelector(date),
        exerciseSettingsSelector,
        cachedWorkoutTemplatesSelector(date),
        warmupsWithExercisesSel,
        isCachedSelector,
        usersSelector,
        subscriptionsSelector,
        workoutBuilder.bind(null,workout => true,true)
    )
}

export const getCachedWodSelector = date => {
    cachedWODSelectors[date] = cachedWODSelectors[date] || makeCachedWODSelector(date);
    return cachedWODSelectors[date];
}

const activeWorkoutsSelectors = {};
export const makeActiveWorkoutsSelector = (date) => {
    return createSelector(
        userWithRoutineSelector,
        workoutsSelector,
        allCachedWorkoutsSel,
        (user,workouts,cachedData) => {
            const weekStarts = user.activeWorkoutWeeks(moment(date));
            const startDate = weekStarts[0];
            const endDate = _.weekEnd(weekStarts[2],user.routineStartDate().day());
            return workoutsForDates(user,workouts,cachedData,startDate,endDate);
        }
    )    
}

export const getActiveWorkoutsSelector = date => {
    activeWorkoutsSelectors[date] = activeWorkoutsSelectors[date] || makeActiveWorkoutsSelector(date);
    return activeWorkoutsSelectors[date];
}

const comprehensiveWodSelectors = {};
export const makeComprehensiveWodSelector = date => {
    return createSelector(
        getWodSelector(date),
        getCachedWodSelector(date),
        (wod,cachedWod) => (cachedWod || wod)
    )
}

export const getComprehensiveWodSelector = date => {
    comprehensiveWodSelectors[date] = comprehensiveWodSelectors[date] || makeComprehensiveWodSelector(date);
    return comprehensiveWodSelectors[date];
}

const exerciseSelectors = {};
export const makeExerciseSelector = (id,date) => {
    if(date) {
        return createSelector(
            exercisesSelector,
            cachedExercisesSelector(date),
            (exercises,cachedExercises) => {
                return Exercise.find(id,{ exercises: (exercises || {}) },{}) || Exercise.find(id, { exercises: (cachedExercises || {}) },{});
            }
        )
    } else {
        return createSelector(
            exercisesSelector,
            (exercises) => {
                return Exercise.find(id,{ exercises },{});
            }
        )
    }
}

export const getExerciseSelector = (id,date) => {
    const key = `${id}-${date}`;
    exerciseSelectors[key] = exerciseSelectors[key] || makeExerciseSelector(id,date);
    return exerciseSelectors[key];
}

export const cachedWorkoutRecordsSelector = state => {
    const cachedDates = allCachedWorkoutDatesSel(state);
    return _.filter(cachedDates.map(date => getCachedWodSelector(date)(state)),workout => !!workout);
}

const exerciseSettingSelectors = {};
const makeExerciseSettingSelector = (id) => {
    return createSelector(
        exerciseSettingsSelector,
        (exerciseSettings) => ExerciseSetting.where(setting => (setting.exerciseId === Number(id)),{ exerciseSettings })[0]
    )
}
export const getExerciseSettingSelector = id => {
    exerciseSettingSelectors[id] = exerciseSettingSelectors[id] || makeExerciseSettingSelector(id);
    return exerciseSettingSelectors[id];
}

const exerciseProgressionSelectors = {}
const makeExerciseProgressionSelector = (id,date) => {
    const sel = (exerciseProgressions,progressionExercises,exercises) => {
        progressionExercises = progressionExercises || {};
        exerciseProgressions = exerciseProgressions || {};
        exercises = exercises || {};
        return ExerciseProgression.find(id,{ exerciseProgressions, progressionExercises, exercises }, { progressionExercises: { exercise: {}} })
    }
    
    if(date) {
        return createSelector(
            cachedExerciseProgsSelector(date),
            cachedProgexSelector(date),
            cachedExercisesSelector(date),
            sel
        )
    } else {
        return createSelector(
            exerciseProgsSelector,
            progexSelector,
            exercisesSelector,
            sel
        )
    }

}
export const getExerciseProgressionsSelector = (id,date) => {
    const key = `${id}-${date ? date : ''}`;
    exerciseProgressionSelectors[key] = exerciseProgressionSelectors[key] || makeExerciseProgressionSelector(id,date);
    return exerciseProgressionSelectors[key];
}

const strengthTestSelectors = {};
const makeStrengthTestSelector = (exerciseId,date) => {

    return createSelector(
        userRecordSelector,
        getExerciseSelector(exerciseId,date),
        cachedStrengthTestsSelector(date),
        (user,exercise,strengthTests) => {
            if(user && exercise) {
                const obj = strengthTests && strengthTests[exerciseId];
                if(obj) {
                    return new StrengthTest({ ...obj, exercise, user });
                } else {
                    return new StrengthTest({ sets: [], exercise, user });
                }
            }
            return null;
        }
    )
}
export const getStrengthTestSelector = (id,date) => {
    const key = `${id}-${date ? date : ''}`;
    strengthTestSelectors[key] = strengthTestSelectors[key] || makeStrengthTestSelector(id,date);
    return strengthTestSelectors[key];
}

const progressionTestSelectors = {};
const makeProgressionTestSelector = (progressionId,reps,date) => {

    return createSelector(
        userRecordSelector,
        getExerciseProgressionsSelector(progressionId,date),
        cachedProgressionTestsSelector(date),
        (user,progression,progressionTests) => {
            if(user && progression && progression.exercises().length > 0) {
                const obj = progressionTests && progressionTests[progressionId];
                if(obj && obj.reps === reps) {
                    return new ProgressionTest({ ...obj, progression, user });
                } else {
                    return new ProgressionTest({ sets: [], reps, progression, user });
                }
            }
            return null;
        }
    )
}
export const getProgressionTestSelector = (progressionId,reps,date) => {
    const key = `${progressionId}-${reps}-${date}`;
    progressionTestSelectors[key] = progressionTestSelectors[key] || makeProgressionTestSelector(progressionId,reps,date);
    return progressionTestSelectors[key];
}

const strengthTestSpecIdsSelectors = {};
const makeStrengthTestSpecIdsSelector = (date) => {
    return createSelector(
        cachedStrengthTestsSelector(date),
        cachedProgressionTestsSelector(date),
        (strengthTests,progressionTests) => {
            const objs = [ ...Object.values(strengthTests || {}), ...Object.values(progressionTests || {}) ];
            return _.compact(objs.map(obj => obj.specId));
        }
    )
}
export const getStrengthTestSpecIdsSelector = (date) => {
    strengthTestSpecIdsSelectors[date] = strengthTestSpecIdsSelectors[date] || makeStrengthTestSpecIdsSelector(date);
    return strengthTestSpecIdsSelectors[date];
}

export const exerciseSearchResultsSel = createSelector(
    exercisesSelector,
    exerciseProgsSelector,
    exerciseSearchSelector,
    (exercises,exerciseProgressions,resultsData) => {
        if(resultsData && resultsData.results) {
            return resultsData.results.map(result => {
                if(result.type === 'Exercise') {
                    return Exercise.find(result.id, { exercises });
                } else {
                    return ExerciseProgression.find(result.id, { exerciseProgressions });
                }
            })
        } else {
            return [];
        }

    }
)
export const exerciseSearchPageSel = state => state.data.exerciseSearch && state.data.exerciseSearch.page;
export const exerciseSearchMoreSel = state => state.data.exerciseSearch && state.data.exerciseSearch.more;
export const exerciseSearchCtxSel = state => state.data.exerciseSearch && state.data.exerciseSearch.context;
export const exerciseSearchFormValsSel = state => state.data.exerciseSearch && state.data.exerciseSearch.formValues;

const routineCyclesSelector = state => state.data.routineCycles;
const workoutRoutineDaysSelector = state => state.data.workoutRoutineDays;
const progressionSchemesSelector = state => state.data.progressionSchemes;
export const progressionSchemeRecordsSelector = state => {
    const vals = Object.values(state.data.progressionSchemes || {});
    return vals.map(schemeObj => new ProgressionScheme(schemeObj));
}

const workoutRoutineSelectors = {};
const makeWorkoutRoutineSelector = (id) => {
    return createSelector(
        userSelector,
        usersSelector,
        exerciseTemplatesSelector,
        setTemplatesSelector,
        exercisesSelector,
        exerciseProgsSelector,
        exerciseSettingsSelector,
        workoutTemplatesSelector,
        routineCyclesSelector,
        workoutRoutineDaysSelector,
        workoutRoutinesSelector,
        workoutTemplateChildrenSelector,
        progressionSchemesSelector,
        (user,
        users,
        exerciseTemplates,
        setTemplates,
        exercises,
        exerciseProgressions,
        exerciseSettings,
        workoutTemplates,
        routineCycles,
        workoutRoutineDays,
        workoutRoutines, 
        workoutTemplateChildren,
        progressionSchemes) => {
            if(id) {
                const cycleIncludes = {
                    workoutRoutineDays: {},
                    workoutRoutine: {}
                }
                const wrtdIncludes = {
                    workoutTemplate: {},
                    routineCycle: {}
                }
                const wtcIncludes = {
                    child: {
                        setTemplates: {},
                        progressionScheme: {},
                        exercisable: {}
                    } 
                }
                const wtIncludes = {
                    workoutTemplateChildren: wtcIncludes,
                    workoutTemplateParents: wtcIncludes,
                    workoutRoutineDays: {},
                    workoutRoutine: {}
                }
                const userIncludes = {
                    workoutTemplates: wtIncludes,
                    exerciseSettings: {}
                }
                const wrtIncludes = { 
                    user: userIncludes,
                    owner: userIncludes,
                    workoutTemplates: wtIncludes,
                    routineCycles: cycleIncludes
                }
                cycleIncludes.workoutRoutine = wrtIncludes;
                wtIncludes.workoutRoutine = wrtIncludes;
                cycleIncludes.workoutRoutineDays = wrtdIncludes;
                wrtdIncludes.routineCycle = cycleIncludes;
                wtIncludes.workoutRoutineDays = wrtdIncludes;
                wrtdIncludes.workoutTemplate = wtIncludes;
                wtcIncludes.workoutTemplate = wtIncludes;
                wtcIncludes.child = { ...wtcIncludes.child, ...wtIncludes };
                return WorkoutRoutine.find(id, { 
                    users: users ? { ...users, [user.id]: user } : { [user.id]: user }, 
                    exerciseTemplates, 
                    setTemplates, 
                    exercises, 
                    exerciseProgressions, 
                    exerciseSettings, 
                    workoutTemplates,
                    routineCycles,
                    workoutRoutineDays,
                    workoutRoutines,
                    workoutTemplateChildren,
                    progressionSchemes
                }, wrtIncludes);
            } else {
                return null;
            }
 
        }
    )
}
export const getWorkoutRoutineSelector = (id) => {
    workoutRoutineSelectors[id] = workoutRoutineSelectors[id] || makeWorkoutRoutineSelector(id);
    return workoutRoutineSelectors[id];
}

const createCachedSelector = (makeSelector,makeKey=key => key) => {
    const selectors = {};

    return (...args) => {
        const key = makeKey(...args);
        selectors[key] = selectors[key] || makeSelector(...args);
        return selectors[key];
    }
}

const eprSelector = state => state.data.exerciseProgressionRequirements;
export const getEtEprSelector = createCachedSelector((exerciseTemplateId) => {

    return createSelector(
        eprSelector,
        (exerciseProgressionRequirements) => {
            return ExerciseProgressionRequirement.where(epr => epr.exerciseTemplateId === exerciseTemplateId, { exerciseProgressionRequirements });
        }
    )
})

export const progressChartsSelector = state => state.wip.progressCharts;

const miniProfilesSelector = state => state.data.miniProfiles;

export const userWithMealPlanSelector = createSelector(
        userSelector,
        mealTypesSelector,
        userMealsSelector,
        recipeMealsSelector,
        recipesSelector,
        miniProfilesSelector,
        usersSelector,
        subscriptionsSelector,
        mpInfoStubsSel,
        dailyNutritionProfilesSel,
        nutritionParametersSelector,
        (user,mealTypes,userMeals,recipeMeals,recipes,miniProfiles,users,subscriptions,mpInfoStubs,dailyNutritionProfiles,nutritionParameters) => {
            return User.newWithAssocs(user,{ mealTypes, userMeals, recipeMeals, recipes, miniProfiles, users, subscriptions, mpInfoStubs, dailyNutritionProfiles, nutritionParameters });
        }
)

export const getMealInfoMatrixSelector = createCachedSelector((week) => {

    return createSelector(
        userWithMealPlanSelector,
        baseActiveMacroParamsSelector,
        activeMacroParamDaysSel,
        (user,baseMacroParams,macroParamDays) => new MealInfoMatrix(user,baseMacroParams,macroParamDays,week)
    )
})

const foodsSelector = state => state.data.foods;
const foodWeightsSelector = state => state.data.foodWeights;
export const foodDBSearchMoreSel = state => (state.ui.foodDBSearch && state.ui.foodDBSearch.more);
export const foodDBSearchPageSel = state => ((state.ui.foodDBSearch && state.ui.foodDBSearch.page) || 0);
export const foodDBSearchQuerySel = state => ((state.ui.foodDBSearch && state.ui.foodDBSearch.query) || '');
export const foodDBSearchSel = state => state.data.foodDBSearch;
export const foodDBSearchSelector = createSelector(
    foodsSelector,
    foodWeightsSelector,
    foodDBSearchSel,
    (foods,foodWeights,foodDBSearch) => {
        if(foodDBSearch) {
            let { results } = foodDBSearch;
            results = results.map(result => {
                return _.isNumeric(result) ? Food.find(result,{ foods, foodWeights }) : new Food({ ...result, foodWeights: [] });
            })
            return {
                ...foodDBSearch,
                results
            }
        } else {
            return {
                more: false,
                page: 0,
                query: '',
                results: []
            }
        }
    }
)

const rawTempMealSelector = state => state.data.tempMeal;
export const tempMealSelector = createSelector(
    rawTempMealSelector,
    recipesSelector,
    ingredientsSelector,
    foodWeightsSelector,
    foodsSelector,
    (raw,recipes,ingredients,foodWeights,foods) => {
        if(raw) {
            if(raw.needRandom !== true) {
                return Meal.mealCollection([raw],{ recipes,ingredients,foodWeights,foods })[0];
            } else {
                return raw;
            }
        }
        return null;
    }

)

const rawScannedFoodSel = state => state.data.currentScannedFood;
export const scannedFoodSelector = createSelector(
    foodsSelector,
    foodWeightsSelector,
    rawScannedFoodSel,
    (foods,foodWeights,scanned) => {
        if(scanned) {
            return Food.find(scanned.foodId,{ foods, foodWeights });
        }
        return null;
    }
)

const foodIdSelector = (state,{ match: { params: { foodId } } }) => {
    return foodId;
}

export const foodSelector = createSelector(
    foodIdSelector,
    foodsSelector,
    foodWeightsSelector,
    rawScannedFoodSel,
    (foodId,foods,foodWeights,scanned) => {
        if(foodId && _.isNumeric(foodId)) {
            return Food.find(Number(foodId),{ foods, foodWeights });
        }
        return null;
    }
)

export const initialFoodNameSelector = createSelector(
    foodIdSelector,
    (foodId) => {
        if(!_.isBlank(foodId) && !_.isNumeric(foodId)) {
            return foodId;
        }
        return '';
    }
)

const mealSearchSelector = state => state.data.mealSearch;
export const mealSearchFullSelector = createSelector(
    mealSearchSelector,
    recipesSelector,
    ingredientsSelector,
    foodWeightsSelector,
    foodsSelector,
    (mealSearch,recipes,ingredients,foodWeights,foods) => {
        if(!mealSearch || _.isBlank(mealSearch.searchParams)) {
            return new SearchSeed(mealSearch);
        } else {
            return new MealSearch({ ...mealSearch.searchParams, more: mealSearch.more } ,mealSearch.results,{ recipes, ingredients, foodWeights, foods });
        }
    }
)

const recipeSearchSelector = state => state.data.recipeSearch;
export const recipeSearchFullSelector = createSelector(
    recipeSearchSelector,
    recipesSelector,
    ingredientsSelector,
    foodWeightsSelector,
    foodsSelector,
    (recipeSearch,recipes,ingredients,foodWeights,foods) => {
        if(!recipeSearch || _.isBlank(recipeSearch.searchParams)) {
            return new SearchSeed(recipeSearch);
        } else {
            return new MealSearch({ ...recipeSearch.searchParams, more: recipeSearch.more } ,recipeSearch.results,{ recipes, ingredients, foodWeights, foods });
        }
    }
)

const msCategorySelector = state => state.data.mealSearchCategories;
export const msCategoryRecordSelector = createSelector(
    msCategorySelector,
    (categories) => {
        if(categories) {
            return Object.values(_.omit(categories,'timestamp')).map(cat => new MealSearchCategory(cat))
        }
        return null;
    }
)

const recentRecipesResultsSelector = state => state.data.recentRecipes;
export const recentRecipesSelector = createSelector(
    recentRecipesResultsSelector,
    recipesSelector,
    ingredientsSelector,
    foodWeightsSelector,
    foodsSelector,
    (results,recipes,ingredients,foodWeights,foods) => {
        if(results) {
            const { more, page, recipeIds } = results;
            const meals = recipeIds.map(recId => Meal.mealObjectForRecipeId(recId,1));
            return {
                more,
                page,
                results: Meal.mealCollection(meals,{ recipes, ingredients, foodWeights, foods })
            }
        } else {
            return { more: true, page: 0, results: []}
        }

    }
)

export const getFullRecipeSelector = createCachedSelector((recipeId) => {

    return createSelector(
        recipesSelector,
        ingredientsSelector,
        foodWeightsSelector,
        foodsSelector,
        (recipes,ingredients,foodWeights,foods) => {
            return Recipe.find(recipeId,{ recipes, ingredients, foodWeights, foods });
        }
    )
})

export const getMealSelector = createCachedSelector((sideDishStubs) => {
    const finalStubs = _.parseDishStubs(sideDishStubs);
    const sideDishes = finalStubs.map(([ recipeId, servings ],index) => ({ recipeId, servings, mainDish: (index === 0) }));
    const selectors = sideDishes.map(sideDish => getFullRecipeSelector(sideDish.recipeId));
    return createSelector(
        ...selectors,
        (...recipes) => {
            return new Meal({ sideDishes }, _.keyBy(_.compact(recipes), rec => rec.id));
        }
    )
})

const rawGroceryListSelector = state => state.data.groceryList;
export const groceryListSelector = createSelector(
    rawGroceryListSelector,
    foodWeightsSelector,
    foodsSelector,
    recipeMealsSelector,
    (rawList,foodWeights,foods,recipeMeals) => {
        if(rawList) {
            if(moment(rawList.startDate).isSameOrAfter(moment(),'day')) {
                const { needed, bought } = rawList;
                const mapItems = groupName => groceryItem => GroceryItem.newWithAssocs({ ...groceryItem, groupName },{ foodWeights, foods, recipeMeals })
                const mapGroup = group => ({ ...group, groceryItems: group.groceryItems.map(mapItems(group.name)) })
                return {
                    needed: needed.map(mapGroup),
                    bought: bought.map(mapGroup)
                }
            }
        }
        return null;
    }
)

const getTrainerObj = (trainer,users,user) => {
    let obj = null;
    if(trainer) {
        obj = (users && users[trainer.id]) || trainer;
        if (obj) {
            obj = { ...obj, ...trainer };
        }
        
        if(user && user.id === trainer.id) {
            obj = { ...obj, ...user };
        }
    }
    return obj;
}

export const recipeEditorUserSelector = createSelector(
    userSelector,
    foodWeightsSelector,
    foodsSelector,
    subscriptionsSelector,
    usersSelector,
    (user,foodWeights,foods,subscriptions,users) => (user && User.newWithAssocs(user, { foods, foodWeights, subscriptions, users }))
)


const clientTagsSelector = state => state.data.clientTags;
const recipePreferencesSelector = state => state.data.recipePreferences;
export const trainerRecordSelector = createSelector(
    usersSelector,
    trainerSelector,
    workoutRoutinesSelector,
    clientTagsSelector,
    mealTypesSelector,
    dailyNutritionProfilesSel,
    nutritionParametersSelector,
    foodsSelector,
    subscriptionsSelector,
    recipePreferencesSelector,
    habitsSelector,
    pdfExportSettingsSel,
    userSelector,
    mpInfoStubsSel,
    exercisesSelector,
    formsSelector,
    (users,trainer,workoutRoutines,clientTags,mealTypes,dailyNutritionProfiles,nutritionParameters,foods,subscriptions,recipePreferences,habits,pdfExportSettings,user,mpInfoStubs,exercises,forms) => {
        let obj = getTrainerObj(trainer,users,user)
        return obj && User.newWithAssocs(obj, { users, workoutRoutines, clientTags, mealTypes, dailyNutritionProfiles, nutritionParameters, subscriptions, foods, recipePreferences, habits, pdfExportSettings, mpInfoStubs, exercises, forms });
    }
)

export const recipeDraftSelector = state => state.wip.recipeDraft;
export const recipeEditorSelector = createSelector(
    trainerRecordSelector,
    userRecordSelector,
    recipeDraftSelector,
    foodWeightsSelector,
    foodsSelector,
    (trainer,user,recipeDraft,foodWeights,foods) => {
        return {
            recipeDraft: recipeDraft || Recipe.defaultDraft(trainer || user),
            recipe: new Recipe({
                ...recipeDraft,
                ingredients: _.compact(recipeDraft.ingredients).map(ingredient => Ingredient.newWithAssocs(ingredient, { foodWeights, foods }))
            })
        }
    }
)

export const trainerWithTeamRecsSelector = createSelector(
    usersSelector,
    trainerSelector,
    recipesSelector,
    subscriptionsSelector,
    recipePreferencesSelector,
    (users,trainer,recipes,subscriptions,recipePreferences) => {
        let obj = getTrainerObj(trainer,users)
        return obj && User.newWithAssocs(obj, { users, subscriptions, recipePreferences, recipes });
    }
)

export const recipeEditorTrainerSelector = createSelector(
    foodWeightsSelector,
    foodsSelector,
    subscriptionsSelector,
    usersSelector,
    trainerSelector,
    (foodWeights,foods,subscriptions,users,trainer) => {
        const obj = getTrainerObj(trainer,users);
        return (obj && User.newWithAssocs(obj, { users, foods, foodWeights, subscriptions }))
    }
)

export const defaultTrainerRoutinesSel = createSelector(
    workoutRoutinesSelector,
    (workoutRoutines) => WorkoutRoutine.where(wrt => (_.isBlank(wrt.ownerId) && _.isBlank(wrt.sourceId)), { workoutRoutines })
)

export const trainerWithFormsSelector = createSelector(
    userSelector,
    usersSelector,
    trainerSelector,
    formsSelector,
    schedulableSettingsSelector,
    formFieldsSelector,
    userDataEntriesSelector,
    subscriptionsSelector,
	assessmentsSelector,
    exercisesSelector,
    assessmentResultsSelector,
    progressPhotosSelector,
	(user,users,trainer,forms,schedulableSettings,formFields,userDataEntries,subscriptions,assessments,exercises,assessmentResults,progressPhotos) => {
        let obj = getTrainerObj(trainer,users,user)
        return obj && User.newWithAssocs(obj, { users, subscriptions, forms, schedulableSettings, formFields, userDataEntries , assessments, exercises, assessmentResults, progressPhotos });
    }
)

export const userWithFormsSelector = createSelector(
    userSelector,
    usersSelector,
    formsSelector,
    schedulableSettingsSelector,
    formFieldsSelector,
    userDataEntriesSelector,
    subscriptionsSelector,
	assessmentsSelector,
    exercisesSelector,
    assessmentResultsSelector,
    progressPhotosSelector,
	(user,users,forms,schedulableSettings,formFields,userDataEntries,subscriptions,assessments,exercises,assessmentResults,progressPhotos) => {
        return user && User.newWithAssocs(user, { users, subscriptions, forms, schedulableSettings, formFields, userDataEntries , assessments, exercises, assessmentResults, progressPhotos });
    }
)

export const dbFormsSelector = createSelector(
    formsSelector,
    (forms) => {
        return Form.where(form => (_.isBlank(form.ownerId) && form.status === 'published'), { forms })
    }
)

export const getDbFormSelector = createCachedSelector((formId) => {

    return createSelector(
        formsSelector,
        schedulableSettingsSelector,
        formFieldsSelector,
        userDataEntriesSelector,
        assessmentsSelector,
        exercisesSelector,
        (forms,schedulableSettings,formFields,userDataEntries,assessments,exercises) => {
            return Form.find(Number(formId), { forms, schedulableSettings, formFields, userDataEntries, assessments, exercises })
        }
    )
})

export const trainerDataForSwitchSel = createSelector(
    allDataSelector,
    userSelector,
    trainerSelector,
    (data,user,trainer) => {
        if(trainer && user && user.id === trainer.id) {
            return { ...data };
        }

        return null;
    }
)

export const trainerCacheSelector = state => state.trainerData.cache;

export const needToLoadExerciseGroupsSel = createSelector(
    userRecordSelector,
    user => {
        return (user && user.needsToLoadExerciseGroups())
    }
)

export const preRoutineEditorPath = createSelector(
    historySelector,
    (history) => {
        let result = null;
        if(history) {
            const { entries } = history;
            if(entries) {
                const endMatches = [editRoutinePath,editRoutineStandaloneMatch];
                const continueMatches = [editWorkoutTemplateMatch,editExerciseGroupMatch,addExercisesMatch];
                const ordered = _.reverse([ ...entries ]); //latest entries first
                for(let i=0; i < ordered.length; i++) {
                    const entry = ordered[i];
                    const { path } = entry;
                    if(matchPath(path,{ path: endMatches })) {
                        result = ordered[i+1];
                        if(result) {
                            result = result.path;
                        }
                        break;
                    } else if(!matchPath(path,{ path: continueMatches })) {
                        break;
                    }
                }
            }
        }
        return result;
    }
)

export const showMpExitButton = createSelector(
    historySelector,
    (history) => {
        let result = false;
        if(history) {
            const { entries } = history;
            if(entries) {
                const endMatches = [standaloneMealPlanMatch];
                const ordered = _.reverse([ ...entries ]); //latest entries first
                for(let i=0; i < ordered.length; i++) {
                    const entry = ordered[i];
                    const { path } = entry;
                    if(matchPath(path,{ path: endMatches })) {
                        result = true;
                        break;
                    }
                }
            }
        }
        return result;
    }
)

export const dbRoutinesSelector = createSelector(
    workoutRoutinesSelector,
    (workoutRoutines) => {
        return WorkoutRoutine.where(wrt => (wrt.showToTrainers && _.isBlank(wrt.ownerId)), { workoutRoutines })
    }
)

const tempDismissedTipsSel = state => state.persistentUi.tempTipDismissals;
export const shouldShowTipCreator = tipName => state => {
    const seenTips = seenTooltips(state);
    if(seenTips && seenTips.includes(tipName)) {
        return false;
    }

    const dismissed = tempDismissedTipsSel(state);
    const wasDismissedAt = dismissed[tipName];

    if(!_.isBlank(wasDismissedAt) && moment().subtract(24,'hours').isBefore(moment(wasDismissedAt))) {
        return false;
    }

    return true;
}

export const shouldShowUpgradePopup = createSelector(
    userRecordSelector,
    tempDismissedTipsSel,
    (user,dismissed) => {
        if(_.isBlank(user) || user.isPro() || user.isTrainer() || user.isClient()) {
            return false;
        }

        let lastDismissedAt = (dismissed && dismissed[UPGRADE_POPUP] && moment(dismissed[UPGRADE_POPUP])) || user.createdAtMom();
        let skipPopupVal = _.getCookie('skip_paywall_popup');
        if(!_.isBlank(skipPopupVal) && moment(lastDismissedAt).isBefore(moment(skipPopupVal))) {
            lastDismissedAt = skipPopupVal;
        }

        if(moment().subtract(72,'hours').isBefore(moment(lastDismissedAt))) {
            return false;
        }
        
        return true;
    }
)

export const clientFiltersSelector = state => state.persistentUi.clientFilters;
export const trainerFiltersSelector = state => state.persistentUi.trainerFilters;

const prePathCount = prePaths => history => {
    let result = -1;
    if(history) {
        const { entries } = history;
        if(entries) {
            const endMatches = prePaths;
            const ordered = _.reverse([ ...entries ]); //latest entries first
            for(let i=0; i < ordered.length; i++) {
                const entry = ordered[i];
                const { path } = entry;
                if(matchPath(path,{ path: endMatches })) {
                    result = -i;
                    break;
                }
            }
        }
    }
    return result;
}

export const preMpEditorBack = createSelector(
    historySelector,
    prePathCount([clientDetailsMatch,clientMealPlansMatch, ...trainerMainMatches])
)

export const preCliMessagingBack = createSelector(
    historySelector,
    prePathCount(mainMatches)
)

export const trainerWithDetailsSel = createSelector(
        userSelector,
        usersSelector,
        trainerSelector,
        workoutRoutinesSelector,
        clientTagsSelector,
        mealTypesSelector,
        dailyNutritionProfilesSel,
        nutritionParametersSelector,
        workoutsSelector,
        subscriptionsSelector,
        recipePreferencesSelector,
        trainerNotesSelector,
        habitsSelector,
        schedulableSettingsSelector,
        habitLogsSelector,
        pdfExportSettingsSel,
        mpInfoStubsSel,
        formsSelector,
        (user,users,trainer,workoutRoutines,clientTags,mealTypes,dailyNutritionProfiles,nutritionParameters,workouts,subscriptions,recipePreferences,trainerNotes,habits,schedulableSettings,habitLogs,pdfExportSettings,mpInfoStubs,forms) => {
            let obj = getTrainerObj(trainer,users,user)
            return obj && User.newWithAssocs(obj, { 
                users, 
                workoutRoutines, 
                clientTags, 
                mealTypes, 
                dailyNutritionProfiles,
                nutritionParameters, 
                subscriptions, 
                workouts, 
                recipePreferences, 
                trainerNotes, 
                habits, 
                schedulableSettings, 
                habitLogs, 
                pdfExportSettings,
                mpInfoStubs,
                forms
            });
        }
)

export const dbHabitsSelector = createSelector(
    habitsSelector,
    (habits) => {
        return Habit.where((h => (_.isBlank(h.ownerId))), { habits })
    }
)

export const habitRecordsByIdsSel = createSelector(
    habitsByIdsSelector,
    (habits) => {
        return Habit.where((h => true), { habits });
    }
)

export const formRecordsByIdsSel = createSelector(
    formsByIdsSelector,
    (forms) => {
        return Form.where((f => true), { forms });
    }
)

export const isSignedInAsClientSel = createSelector(
    trainerSelector,
    userSelector,
    (trainer,user) => {
        return trainer && user && trainer.id !== user.id;
    }
)

const chatCacheSelector = state => state.wip.chatCache;

export const chatUserSelector = createSelector(
    userSelector,
    usersSelector,
    clientTagsSelector,
    chatsSelector,
    chatMembershipsSelector,
    chatMessagesSelector,
    chatEventsSelector,
    chatCacheSelector,
    formsSelector,
    formFieldsSelector,
    assessmentsSelector,
    assessmentResultsSelector,
    exercisesSelector,
    progressPhotosSelector,
    (user,users,clientTags,chats,chatMemberships,chatMessages,chatEvents,cachedMessages,forms,formFields,assessments,assessmentResults,exercises,progressPhotos) => {
        return User.newWithAssocs(user,{ users, clientTags, chats, chatMemberships, chatEvents, forms, formFields, assessments, assessmentResults, exercises, progressPhotos, chatMessages: { ...cachedMessages, ...chatMessages } });
    }
)

const hasRecipeDraftSel = createSelector(
    recipeDraftSelector,
    (recipeDraft) => {
        return (recipeDraft && recipeDraft.hasChanges && _.filter(recipeDraft.ingredients,i => !i._destroy).length > 0);
    }
)

export const needsUnsavedChangesPopupSel = createSelector(
    propsLocationSel,
    unsavedChangesSelector,
    hasRecipeDraftSel,
    (location,unsavedChanges,hasRecipeDraft) => {
        const needsUnsavedChangesPopup = 
        (unsavedChanges && matchPath(location.pathname,{ path: unsavedChanges.match })) || 
        (hasRecipeDraft && matchPath(location.pathname,{ path: recipeConfirmPaths }));
        return needsUnsavedChangesPopup;
    }
)

export const hasUnsavedChangesSel = createSelector(
    unsavedChangesSelector,
    hasRecipeDraftSel,
    (unsavedChanges,hasRecipeDraft) => {
        return (unsavedChanges || hasRecipeDraft)
    }
)

const recipeIdsPropsSel = (state,props) => props.recipeIds;

export const recipesByIdsSelector = createSelector(
    recipeIdsPropsSel,
    recipesSelector,
    (recipeIds,recipes) => {
        return Recipe.where(r => recipeIds.includes(r.id), { recipes });
    }
)

export const dbAssessmentsSelector = createSelector(
    assessmentsSelector,
    (assessments) => {
        return Assessment.where(assessment => (_.isBlank(assessment.ownerId) && (_.isBlank(assessment.inactive) || assessment.inactive === false)), { assessments })
    }
)