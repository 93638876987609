import React from 'react';
import Card from 'components/Card';
import classnames from 'classnames';
import { LoadingContainerParent } from './LoadingHOC';
import { MultiPickButtonLC } from 'views/ProgressionPick';
import { Link } from './Routing';
import { AsyncSpinnerCore } from './Button';
import { MultiLevelDropdown } from './Dropdown';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ListItem = (props) => {
    return (
        <li className="collection-item">
            {props.children}
        </li>
    )
}

export const ListCard = React.forwardRef(({ children, className, collapse, ...rest },ref) => {
    const classNames = classnames("swap", { [className]: className})
    return (
        <Card className={classNames} {...rest} ref={ref}>
            <div className={classnames("woo-exercise-list swap",{ pb0: !!collapse})}>
                {children}
            </div>
            {collapse}
        </Card>
    )
})

export const ListCardAction = React.forwardRef(({ onClick, children, className, ...rest },ref) => {
    const classNames = classnames('ex-swap-btn',{ [className]: className });
    
    return (<div className={classNames} onClick={onClick} {...rest} ref={ref}>
        {children}
    </div>)
})

export const ListCardActionLink = React.forwardRef(({ to, children, className, ...rest },ref) => {
    const classNames = classnames('ex-swap-btn',{ [className]: className });
    
    return (<Link className={classNames} to={to} {...rest} ref={ref}>
        {children}
    </Link>)
})

export const ListCardTitle = ({ children, className, withTags, linkTo, onClick }) => {
    const classNames = classnames('title-container',{ [className]: className, 'position-relative': withTags, 'clickable': onClick });
    const Comp = linkTo ? Link : 'div';
    const addlProps = linkTo ? { to: linkTo } : { onClick };

    return (
        <Comp className={classNames} {...addlProps} >
            <div className="valign-wrapper" style={{height: '100%'}}>
                <div className="exercise-title abbr-text">{children}</div>
            </div>
        </Comp>

    )
}

export const ListCardTitleCollapse = ({ children, className, onClick }) => {
    const classNames = classnames('title-container clickable',{ [className]: className });

    return (
        <div className={classNames} onClick={onClick} >
            <div className="valign-wrapper" style={{height: '100%'}}>
                <div className="exercise-title abbr-text">{children}</div>
            </div>
        </div>

    )
}

export const ListCardDrop = ({ children, isModal, triggerId  }) => {

    return (
        <MultiLevelDropdown
            fixed={isModal}
            options={ { coverTrigger: true, alignment: 'right', closeOnClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <ListCardAction ref={ref} data-target={target} id={triggerId}>
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </ListCardAction>
                )
            }}
        >
            {children}
        </MultiLevelDropdown>
    )
}

export const AsyncCardAction = ({ load, icon, label, id, successCallback }) => (
    <LoadingContainerParent
        load={load}
        id={id}
        preloaded={() => false}
        icon={icon}
        label={label}
        component={MultiPickButtonLC}
        skipAutoLoad
        successCallback={successCallback}
    />
)

export const AsyncListCardAction = (props) => (<AsyncSpinnerCore comp={ListCardAction} {...props} />)

export default (props) => {
    return (
        <ul className="collection">
            {props.children}
        </ul>
    )
}

