import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';

export const PlusMinusField = ({ className, label, error, ...rest }) => {
    className = classnames({
        "invalid": error,
        [className]: className
    })
    
    return (
        <div className="small-number-input no-wrap">
            <input type="number" step="any" className={className} {...rest} />
            <label>{label}</label>
        </div>
    )
}

export class BlockingInputValidator extends React.Component {

    constructor(props) {
        super(props);
        this.previousValue = null;
        this.currentValue = props.value;
        this.valueReset = false;
    }

    componentDidUpdate() {
        if (this.currentValue !== this.props.value) {
            this.previousValue = this.currentValue;
            this.currentValue = this.props.value;
        }

        if(this.props.error && !this.valueReset) {
            this.valueReset = true;
            this.props.setValue(this.previousValue);
        } else {
            this.valueReset = false;
        }
    }

    render() {
        const Component = this.props.component;
        return (<Component {...this.props} />)
    }

}

export default ({ className, tighter, inline, large, value, error, setValue, render }) => {

    const classNames = classnames({
        "tighter": tighter,
        "inline-toggle": inline,
        [className]: className,
        "plus-minus-toggle": true,
        "large-input-toggle": large
    })

    const onChange = (e) => {
        setValue(_.castNumericInput(e.target.value));
    }

    return (
        <div className={classNames}>
            {render({value,error,onChange})}
            <div className="minus-toggle" onClick={setValue.bind(null,Math.max(value-1,0))}>
                <FontAwesomeIcon icon="minus" />
            </div>
            <div className="plus-toggle" onClick={setValue.bind(null,value+1)}>
                <FontAwesomeIcon icon="plus" />
            </div>
        </div>
    )
}