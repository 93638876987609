import React, { useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Loader } from 'components/LoadingHOC';

export const UpperRightBtn = ({ className, ...rest }) => {

    return (
        <div className={classnames("upper-right-btn low-opacity-icon",{ [className]: className })} {...rest}>
            <FontAwesomeIcon icon={'times'} /> 
        </div>
    )
}

export const BottomButtons = ({ children, className }) => {
    const classNames = classnames("bottom-center-action-container align-items-center",{ [className]: className})

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}

const AsyncActionDefault = ({ render, load, disableAction }) => {
    return render({ onClick: () => (!disableAction && load()) });
}

export const AsyncActionButton = ({ action, render, successCallback, failCallback, type, disableAction, preloaded, LoaderWrapper, className, buttonProps }) => {
    const props = className ? { className } : {};

    return (
        <Loader
            successComponent={AsyncActionDefault}
            defaultComponent={AsyncActionDefault}
            type={type}
            load={action}
            render={render}
            preloaded={preloaded || (() => false)}
            skipAutoLoad={!preloaded}
            successCallback={successCallback}
            failCallback={failCallback}
            disableAction={disableAction}
            buttonProps={buttonProps}
            {...props}
            LoaderWrapper={LoaderWrapper}
        />
    )
}

export const DefaultAsyncActionButton = ({ action, successCallback, failCallback, loaderType, Comp, LoaderWrapper, disableAction, preloaded, className, buttonProps, ...rest }) => {
    Comp = Comp || Button;
    const props = className ? { className } : {  }
    return (
        <AsyncActionButton 
            action={action}
            successCallback={successCallback}
            failCallback={failCallback}
            type={loaderType}
            LoaderWrapper={LoaderWrapper}
            disableAction={disableAction}
            preloaded={preloaded}
            className={className}
            buttonProps={buttonProps}
            render={({ onClick }) => (<Comp {...rest} {...props} {...buttonProps} onClick={onClick} />)}
        />
    )
}

const SpinnerUnderlineText = ({ label, children, ...rest }) => {

    return (<div {...rest} >
        {children} {label && (<span className="underline clickable">{label}</span>)}
    </div>
)}

const SpinnerButton = ({ label, children, ...rest }) => {

    return (<Button {...rest} >
        {children} {label && (<span className="flex-grow text-center">{label}</span>)}
    </Button>
)}

export const AsyncSpinnerCore = ({ icon, buttonProps, comp, ...rest }) => {

    return (
        <DefaultAsyncActionButton 
            Comp={comp}
            LoaderWrapper={comp}
            loaderType="icon"
            buttonProps={buttonProps}
            {...rest}
        >
            {icon && (<FontAwesomeIcon icon={icon}></FontAwesomeIcon>)}
        </DefaultAsyncActionButton>
    )
}

export const AsyncSpinnerButton = (props) => (<AsyncSpinnerCore comp={SpinnerButton} {...props} />)
export const AsyncFixedSpinnerButton = (props) => (<AsyncSpinnerCore comp={FixedButtonCore} {...props} />)
export const AsyncSpinnerText = (props) => (<AsyncSpinnerCore comp={SpinnerUnderlineText} {...props} />)

export const UnderlineText = ({ label, icon, ...rest }) => {

    return (
        <span className="faint-color clickable" {...rest}>
            {icon && (<FontAwesomeIcon icon={icon} />)}
            <span className="underline">{label}</span>
        </span>
    )
}

export const ConfirmActionButton = ({render, onClick}) => {
    const [ready,setReady] = useState(false);
    const clickHandler = ready ? onClick : (() => setReady(true));

    return (
        render({ ready, onClick: clickHandler })
    )
}

export const SimpleConfirmActionButton = ({ component, className, onClick, ...rest}) => {
    const Component = component;

    return (
        <ConfirmActionButton {...rest} onClick={onClick} render={({ready, onClick}) => {
            const classNames = classnames({ [className]: true, "red-text": ready });
            const addlProps = (Component === Button && ready) ? { color: 'red' } : {};
            return (
                <Component className={classNames} onClick={onClick} {...rest} {...addlProps} />
            )
        }} />
    )
}

export const IconButton = (props) => {
    const { className, ...rest } = props;
    const classNames = classnames({
        "low-opacity-icon clickable": true, 
        [className]: className
    });
    return (
        <FontAwesomeIcon className={classNames} {...rest} />
    )
}

export const RetryLoadButton = (props) => {
    return (
        <div className="text-center ma15">
            <Button color="primary" onClick={props.load} rounded>Try Again</Button>
        </div>
    )
}

export const LoaderActionButton = ({ component, action, ...rest }) => {
    return (
        <Loader
            successComponent={component}
            defaultComponent={component}
            type="loaderOnly"
            load={action}
            preloaded={() => false}
            skipAutoLoad
            {...rest}
        />
    )
}

const FixedButtonCore = ({ onClick, className, containerClass, children, outlined, ...rest }) => {

    return (
        <div className={classnames("fixed-action-btn",{ [containerClass]: containerClass })}>
            <div className={classnames("btn-floating btn-large btn-primary",{ [className]: className, 'btn-outlined shadow': outlined })} onClick={onClick} {...rest} >
                {children}
            </div>
        </div>
    )
}

export const FixedButton = ({ icon, spin, ...rest }) => {

    return (
        <FixedButtonCore {...rest}>
            <FontAwesomeIcon icon={icon} spin={spin} />
        </FixedButtonCore>
    )
}

export const FlowAnswerButton = props => {

    return (
        <Button 
            className="flow-answer-btn"
            noShadow
            {...props}
        />
    )

}

const Button = React.forwardRef((props,ref) => {
    const { tag,
            variant,
            size,
            rounded,
            color,
            outlined,
            className,
            children,
            waves,
            block,
            disabled,
            noShadow,
            noColor,
            ...rest } = props
    const isDefault = (!variant || variant === 'raised')
    const useBtnClass = isDefault || variant === 'round-icon' || variant === 'icon-only';
    const autoContent = !(variant === 'floating' || variant === 'round-icon' || variant === 'icon-only');
    const lightBcg = (outlined || variant === 'flat')
    const classes = classnames({
        'waves-effect': waves !== 'none',
        ['waves-'+(waves || 'light')]: waves || !lightBcg,
        btn: useBtnClass,
        ['btn-' + size]: size,
        ['btn-' + variant]: !isDefault,
        'btn-rounded': rounded,
        ['btn-' + (color || 'primary')]: !noColor,
        [color + '-text']: (variant === 'flat'),
        'btn-outlined': !disabled && outlined,
        'btn-block': block,
        'btn-no-shadow': noShadow,
        [className]: className
    })

    const Component = tag ? tag : 'div'

    return (
        <Component className={classes} disabled={disabled} {...rest} ref={ref} >
            {autoContent && (<div className='btn-content'>
                {props.children}
            </div>)}
            {!autoContent && props.children}
        </Component>
    )
})

export default Button