import React, { useState } from 'react';
import { regenerateWeekPathFor, editWeeklyMealMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import { MealView, NutritionModalButton } from 'partials/MealPlanViews';
import { mapStateToMpProps, mapDispatchToMpProps, ViewToggleButton } from './MealPlan'
import { connect } from 'react-redux';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';
import Button, { BottomButtons, DefaultAsyncActionButton } from 'components/Button';
import { useHistory } from 'react-router-dom';
import { regenerateMealPlan, setPinnedMeals } from 'redux/actions';
import { pinnedMealsSelector } from 'redux/selectors';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { resolvedHomePath } from 'redux/helpers';
import { ExitMealPlanButton } from 'partials/Utilities';

const transitionMap = {
    rules: [
        [mealPlanSlideOutPaths,transitions.slideOut],
        [[editWeeklyMealMatch], transitions.slideOver]
    ]
};

const PinButton = ({ weeklyMeal, userMeal, mealInfoMatrix, className, pinMeals, unpinMeals, pinnedUserMealIds }) => {
    const { t } = useTranslation();
    const isDaily = !!userMeal;
    const locked = weeklyMeal ? weeklyMeal.isLocked() : userMeal.isLocked();
    const ids = weeklyMeal ? weeklyMeal.userMealIds() : [userMeal.id];
    const active = locked || (weeklyMeal ? weeklyMeal.isPinned(pinnedUserMealIds) : pinnedUserMealIds.includes(userMeal.id));
    let icon = 'thumbtack';
    let msg = t('Pinned');
    if(locked) {
        icon = 'lock';
        msg = t('Locked');
    } else if(!active) {
        icon = 'thumbtack';
        msg = t('Pin');
    }

    const onClick = () => {
        if(locked) {
            return;
        } else if(active) {
            unpinMeals(ids);
        } else {
            pinMeals(ids);
        }
    }

    return (<div className={classnames({[className]: className, active, 'locked-pin-btn': locked })} onClick={onClick} id={`pin-meal-${ids[0]}-btn`}>
        <span>
            {icon && (<FontAwesomeIcon icon={icon} />)} {isDaily && icon && <br/>}
            {msg}
        </span>
    </div>)

}

let RegenerateMealPlanCore = ({ week, mealInfoMatrix, ignoreWarnings, viewType, regeneratePlan, setPinnedIds, pinnedUserMealIds }) => {
    const { t } = useTranslation();
    const history = useHistory();
    pinnedUserMealIds = pinnedUserMealIds ? _.intersection(pinnedUserMealIds,mealInfoMatrix.allUserMealIds()) : mealInfoMatrix.pinnedIds();
    const [hasGenerated,setHasGenerated] = useState(false);
    const pinMeals = (ids) => setPinnedIds(_.uniq([ ...pinnedUserMealIds, ...ids ]),mealInfoMatrix.startDate);
    const unpinMeals = (ids) => setPinnedIds(_.difference(pinnedUserMealIds,ids),mealInfoMatrix.startDate);
    
    return (
        <React.Fragment>

            <MealView 
                actionType="regen" 
                week={week} 
                mealInfoMatrix={mealInfoMatrix} 
                ignoreWarnings={ignoreWarnings} 
                viewType={viewType} 
                basePath={regenerateWeekPathFor(week)}
                ActionComp={(<PinButton 
                    pinnedUserMealIds={pinnedUserMealIds} 
                    pinMeals={pinMeals}
                    unpinMeals={unpinMeals}
                />)}
            />
            <BottomButtons>
                {hasGenerated && (
                    <Button id="regenerate-done-btn" rounded color="primary" className="mr5 shadow" outlined onClick={() => history.goBack()}>
                        <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Done")}
                    </Button>
                )}
                <DefaultAsyncActionButton 
                    loaderType={"loaderOnly"}
                    rounded 
                    color="primary" 
                    id={`regenerate-plan-btn`}
                    action={regeneratePlan.bind(null,{ pinnedUserMealIds, userMealIds: mealInfoMatrix.allUserMealIds(), startDate: mealInfoMatrix.startDate })}
                    successCallback={(data) => setHasGenerated(true)}
                >
                        <FontAwesomeIcon icon={'random'}></FontAwesomeIcon> {t("Regenerate")}
                </DefaultAsyncActionButton>
            </BottomButtons>
        </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
    regeneratePlan: (data) => dispatch(regenerateMealPlan(data)),
    setPinnedIds: (pinnedMeals,startDate) => dispatch(setPinnedMeals(pinnedMeals,startDate))
})

const mapStateToProps = (state,props) => ({
    pinnedUserMealIds: pinnedMealsSelector(state,props)
})

const redirectCheckCreator = (user,week) => () => {
    const genState = user.mealPlanGenerateState(week);
    if(!user.mealPlanInitialized() || genState === 'ungenerated') {
        return resolvedHomePath();
    }

    return null;
}

RegenerateMealPlanCore = connect(mapStateToProps,mapDispatchToProps)(RegenerateMealPlanCore);

const RegenerateMealPlanPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, week, mealInfoMatrix, ignoreWarnings, viewType, setViewType } = props;

    return (
        <SimpleNavPage
            darkMode
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            containerClass="with-drop-btn"
            title={t('Regenerate')}
            redirectCheck={redirectCheckCreator(mealInfoMatrix.user,mealInfoMatrix.week)}
            navChildren={(<div className="mr5 mb5 align-middle display-flex ab-right">
                <NutritionModalButton mealInfoMatrix={mealInfoMatrix} basePath={regenerateWeekPathFor(week)} className="mb1" isTrainerEditing={true} />
                <ViewToggleButton className="dark-mode simple-nav-resp" viewType={viewType} setViewType={setViewType} />
                <ExitMealPlanButton className="hide-on-small-and-down with-flex" />
            </div>)}
        >
            <RegenerateMealPlanCore 
                week={week}
                mealInfoMatrix={mealInfoMatrix}
                ignoreWarnings={ignoreWarnings}
                viewType={viewType}
            />
        </SimpleNavPage>
    )
}

export default connect(mapStateToMpProps,mapDispatchToMpProps)(RegenerateMealPlanPage);