import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BottomButtons, DefaultAsyncActionButton } from 'components/Button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { regenUserMeal } from 'redux/actions';
import { RegenSingleDayButton } from 'views/MealPlan';
import { UnskippedMeal } from 'views/Today';
import { InitialTip } from './Utilities';
import * as _ from 'lib/utilities';

const RegenerateTabCore = React.forwardRef(({ id, onClick, children },ref) => {
    const { t } = useTranslation();
    return (
        <div className="card-tab recipe-card clickable" id={id} onClick={onClick} ref={ref}>
            {children} {t("Regenerate")}
        </div>
    )
})

const RegenerateTabTip = ({ onClick, children, ...props }) => {
    const { t } = useTranslation();

    return (
        <InitialTip
            tipName={'randomize_single_day_meal'}
            delay={5000}
            text={t("randomize single meal tip")}
            component={RegenerateTabCore}
            onClick={onClick}
            passThroughProps={props}
        >
            {children}
        </InitialTip>
    )
}

const RegenerateTab = ({ userMeal, regenMeal, withTip }) => {
    const [excludedMains,setExcludedMains] = useState([]);
    const [excludedSides,setExcludedSides] = useState([]);
    const Comp = withTip ? RegenerateTabTip : RegenerateTabCore;
    
    return (
        <DefaultAsyncActionButton 
            Comp={Comp}
            LoaderWrapper={Comp}
            loaderType="icon"
            color="primary" 
            id={`regenerate-meal-${userMeal.id}-btn`}
            action={() => {
                const sideIds = userMeal.sideRecipeIds();
                const takeSidesCount = _.random(0,sideIds.length);
                setExcludedSides( [ ..._.shuffle(sideIds).slice(0,takeSidesCount), ...excludedSides.slice(0,5) ] )
                setExcludedMains( [ userMeal.mainRecipeId(), ...excludedMains.slice(0,7) ] )
                return regenMeal(userMeal,[ ...excludedMains, ...excludedSides ]);
            }}
        >
                <FontAwesomeIcon icon={'random'}></FontAwesomeIcon>
        </DefaultAsyncActionButton>
    )
} 

let SingleDayMeals = ({ mealInfoMatrix, regenMeal }) => {
    const meals = mealInfoMatrix.allUserMeals();
    const { t } = useTranslation();

    return (
        <div style={{flexGrow: '1', minWidth: 0, marginTop: '10px'}}>
            <div className="row">
                <div className="col s12">
                    <div className="meal-plan-column-cont">
                        {meals.map((userMeal,index) => {
                            return (
                                <div className="mb30" key={userMeal.id}>
                                    <h4 className="conversion-heading ml15">{userMeal.logTitle(userMeal.mealType,t)}</h4>
                                    <UnskippedMeal 
                                        user={mealInfoMatrix.user} 
                                        userMeal={userMeal} 
                                        t={t} 
                                        type={'single'} 
                                        topTab={<RegenerateTab regenMeal={regenMeal} userMeal={userMeal} withTip={index === 0} />} 
                                    />
                                </div>
                            )
                        })}
                        <BottomButtons className="has-bot-nav">
                            <RegenSingleDayButton meals={meals} context="bottom" />
                        </BottomButtons>
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapDispatchToProps = dispatch => ({
    regenMeal: (userMeal,excludedIds) => dispatch(regenUserMeal(userMeal,excludedIds))
}) 

SingleDayMeals = connect(null,mapDispatchToProps)(SingleDayMeals)

export default SingleDayMeals;