import React from 'react';
import { connect } from 'react-redux';
import { setPaywallRedirect } from 'redux/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { pastDuePath, signupModalPathFor } from 'config/paths';
import { fullTrainerConvPathFor, paywallPathFor } from 'redux/helpers';
import LinkButton from 'components/LinkButton';
import { trainerRecordSelector, userRecordSelector } from 'redux/selectors';
import { BottomNavChild } from 'components/BottomNav';
import { DropdownLink } from 'components/Dropdown';
import { AsyncSpinnerText, FixedButton } from 'components/Button';
import { TPAYWALL } from 'config/settings';

let TrainerPaywallButton = ({ trainer, user, type, proPath, closeAndGo, render, allowUninit=true }) => {
    const history = useHistory();
    let path;
    const hasAccess = (trainer && trainer.hasTrainerAccess(type,allowUninit));
    if(trainer && trainer.showPastDueScreen()) {
        path = pastDuePath;
    } else {
        path = hasAccess ? proPath : fullTrainerConvPathFor(TPAYWALL);
    }

    const onClick = () => {
        if(closeAndGo) {
            closeAndGo(path);
        } else {
            history.push(path);
        }
    }
    
    return render({ hasAccess, path, onClick });
}

const mapStateToTrainerProps = state => ({
    trainer: trainerRecordSelector(state),
    user: userRecordSelector(state)
})

TrainerPaywallButton = connect(mapStateToTrainerProps)(TrainerPaywallButton);

export { TrainerPaywallButton }

export const AsyncSpinnerTextTPB = ({ action, type, allowUninit, ...rest }) => {
    return (
        <TrainerPaywallButton 
            type={type}
            proPath={''}
            allowUninit={allowUninit}
            render={({ hasAccess, path, onClick }) => {

                return (
                    <AsyncSpinnerText
                        {...rest}
                        action={() => {
                            if(hasAccess) {
                                return action();
                            }
                            onClick();
                            return Promise.resolve({ status: 'SUCCESS' });
                        }}
                    />
                )
            }}
        />
    )
}

export const BottomNavChildTPB = ({ path: inPath, type, ...props }) => {

    return (
        <TrainerPaywallButton 
            type={type}
            proPath={inPath}
            render={({ hasAccess, path, onClick }) => {

                return (
                    <BottomNavChild 
                        path={path} 
                        pathProps={{ path: inPath }} 
                        {...props} 
                    />
                )
            }}
        />
    )
}

export const LinkButtonTPB = ({ type, to, allowUninit, ...rest }) => {
    return (
        <TrainerPaywallButton 
            type={type}
            proPath={to}
            allowUninit={allowUninit}
            render={({ hasAccess, path, onClick }) => {

                return (
                    <LinkButton 
                        to={path}
                        {...rest}
                    />
                )
            }}
        />
    )
}

export const FixedButtonTPB = ({ type, to, allowUninit, ...rest }) => {
    return (
        <TrainerPaywallButton 
            type={type}
            proPath={to}
            allowUninit={allowUninit}
            render={({ hasAccess, path, onClick }) => {

                return (
                    <FixedButton 
                        onClick={onClick} 
                        {...rest}
                    />
                )
            }}
        />
    )
}

export const DropdownLinkTPB = ({ type, to, allowUninit, ...rest }) => {
    return (
        <TrainerPaywallButton 
            type={type}
            proPath={to}
            allowUninit={allowUninit}
            render={({ hasAccess, path, onClick }) => {

                return (
                    <DropdownLink 
                        to={path}
                        {...rest}
                    />
                )
            }}
        />
    )
}

export const ProLinkButton = ({ user, to, blockTypes, subType, context, ...rest }) => {
    const isPro = user && user.hasProAccess(blockTypes);
    const newTo = isPro ? to : paywallPathFor(context || 'paywall',user);
    return (
        <LinkButton to={newTo} {...rest} />
    )
}

const ProRequiredButton = ({ user, proClickHandler, proPath, blockTypes, subType, context, setPaywallRedirect, closeAndGo, render }) => {
    const history = useHistory();
    const location = useLocation();
    const isPro = user && user.hasProAccess(blockTypes);


    const onClick = (e) => {
        e.preventDefault();
        const isSignedUp = user && user.hasBasicProfile;
        if(isPro) {
            if(proClickHandler) {
                proClickHandler();
            } else {
                if(closeAndGo) {
                    closeAndGo(proPath);
                } else {
                    history.push(proPath);
                }
            }
        } else if(isSignedUp) {
            setPaywallRedirect(proPath);
            if(closeAndGo) {
                closeAndGo(paywallPathFor(context || 'paywall',user))
            } else {
                history.push(paywallPathFor(context || 'paywall',user));
            }
        } else {
            if(closeAndGo) {
                closeAndGo(signupModalPathFor(location.pathname.replace(/\/signup_first$/,'')))
            } else {
                history.push(signupModalPathFor(location.pathname.replace(/\/signup_first$/,'')));
            }
        }
    }

    return (
        render({ onClick, isPro })
    )
}

const mapDispatchToProps = dispatch => ({
    setPaywallRedirect: (path) => dispatch(setPaywallRedirect(path))
})

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(ProRequiredButton);