import React, {  } from 'react';
import { createTrainer, destroyWorkoutRoutine, importRoutine, loadTrainerRoutines, setTrainerForRoutines } from 'redux/actions';
import { connect } from 'react-redux';
import { AsyncSpinnerButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { myRoutinesPath, routinesBase } from 'config/paths';
import withTrainer from 'partials/TrainerLoader';
import { dbRoutinesSelector, trainerForRoutinesSel } from 'redux/selectors';
import { useTranslation, withTranslation } from 'react-i18next';
import ActivityContainer from 'components/ActivityContainer';
import * as _ from 'lib/utilities';
import ResponsiveSections from 'components/ResponsiveSections';
import { WorkoutRoutineCard } from 'partials/OnboardingComponents';
import { NewRoutineModal } from 'partials/EditRoutineModals';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import { TRAINING } from 'config/settings';
import IndexPage from 'partials/IndexPage';
import { User } from 'lib/classes';
import Connector from 'partials/WebsiteConnector';
import { ExportRoutinePDFModal } from 'partials/ExportPDFModal';
import LinkButton from 'components/LinkButton';
import { previewRoutinePdfUrlFor } from 'lib/api';

const RoutineCardActions = ({ routine, t, importRoutine }) => {

    return (
        <React.Fragment>
            <LinkButton noShadow outlined color="primary" variant="icon-only" to={previewRoutinePdfUrlFor({ id: routine.id},true,false)} target="_blank">
                <FontAwesomeIcon icon={'info-circle'}></FontAwesomeIcon> 
            </LinkButton>
            <AsyncSpinnerButton 
                buttonProps={{
                    noShadow: true,
                    outlined: true,
                    color: 'primary',
                    label: t('Import'),
                    className: "ml5 import-db-routine-btn"
                }}
                icon={['far','file-import']}
                action={() => importRoutine(routine.id)}
            />
        </React.Fragment>
    )
}

const RoutinesCardList = ({ records: workoutRoutines, importRecord }) => {

    const { t } = useTranslation();
    const finalRoutines = _.filter(workoutRoutines,wrt => !wrt.inactive);

    return (
        <ResponsiveSections sectionWidth={3}>
            {_.sortBy(finalRoutines,wrt => wrt.resolvedName(t)).map((routine) => (<WorkoutRoutineCard key={routine.id} routine={routine} t={t} actions={<RoutineCardActions routine={routine} t={t} importRoutine={importRecord} />} />))}
        </ResponsiveSections>
    )
}

const mapDispatchToRoutineInitProps = dispatch => ({
    beforeAction: () => dispatch(createTrainer({ trainer: { trainerType: User.TRAINER_TYPES.app }, planType: 'training' })),
    afterPath: myRoutinesPath
})

const WorkoutRoutinesInit = connect(null, mapDispatchToRoutineInitProps)(Connector);

export { WorkoutRoutinesInit }
class WorkoutRoutines extends React.Component {

    render() {
        const { routineImports, clearRoutineImports, dbRoutines, match: { params: { type }}, match, importRoutine, loadRoutines, addRoutineImport, t, ...rest } = this.props;
        const extraProps = type === 'db' ? { Wrapper: ActivityContainer, successComponent: RoutinesCardList } : {};

        return (
            <TrainerPaywall type={TRAINING}>
                <IndexPage 
                    {...extraProps}
                    {...rest}
                    importCount={routineImports}
                    clearImports={clearRoutineImports}
                    recordName={'WorkoutRoutines'}
                    recordNameP={t('routines')}
                    recordNameS={_.capitalize(t('routine'))}
                    dbRecords={dbRoutines}
                    match={match}
                    importRecord={importRoutine}
                    basePath={routinesBase}
                    load={loadRoutines}
                    addImport={addRoutineImport}
                    title={t("Workout Routines")}
                    NewModal={NewRoutineModal}
                    ExportModal={ExportRoutinePDFModal}
                />
            </TrainerPaywall>
        )
    }
}

const mapStateToProps = state => ({
    dbRoutines: dbRoutinesSelector(state),
    trainerId: trainerForRoutinesSel(state)
})

const mapDispatchToProps = dispatch => ({
    loadRoutines: params => dispatch(loadTrainerRoutines(params)),
    importRoutine: (id,popup) => dispatch(importRoutine(id,popup)),
    destroy: (routine) => dispatch(destroyWorkoutRoutine(routine.id)),
    selectTrainer: trainerId => dispatch(setTrainerForRoutines(trainerId))
})

export default withTrainer(false)(connect(mapStateToProps,mapDispatchToProps)(withTranslation()(WorkoutRoutines)));
