import React from 'react';
import BodyMeasurements from 'partials/BodyMeasurements';
import { workoutSetupMatch, pickExercisePath, emailPaywallMatches, allMainMatches, bodyMeasurementsPath, clientDetailsMatch, viewClientAssignedFormMatch, viewAssignedFormMatch, progressMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import PaywallWrapper from 'partials/PaywallWrapper';
import ClientSwitcher from 'partials/ClientSwitcher';
import { FemaleGraphic, MaleGraphic } from 'partials/AnimatedBodySvgs';

const transitionMap = {
    rules: [
        [[...allMainMatches,progressMatch,clientDetailsMatch, viewClientAssignedFormMatch, viewAssignedFormMatch],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath], transitions.slideOver]
    ]
};


const AnatomicGifGenPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions } = props;
    const defaults = ['#64FFDA','#9C27B0','#03A9F4'];
    const redYellowOrange = ['#ee0606','#ff9900','#f3ff00'];
    const greenToGrey = ['#17de32','#656565'];

    return (

            <div>
                <MaleGraphic gradientColors={{ default: greenToGrey }} />
                <FemaleGraphic gradientColors={{ default: greenToGrey }} />
            </div>

    )
}

export default AnatomicGifGenPage