import React from 'react';
import Card from 'components/Card';
import PlaceholderImage from 'components/PlaceholderImage';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import Tooltipped from 'components/Tooltipped';
import { ListCard } from 'components/List';

export const ExerciseListSmallThumb = ({ exercise }) => {

    return (
        <div className="img-container">
            <PlaceholderImage width={320} height={180} color="white" className="width-placeholder-svg" />
            <img src={exercise.resolvedThumbUrl()} alt="" />
        </div>
    )
}

export const ActionContainer = ({ onClick, children, className, ...rest }) => {
    const classNames = classnames('action-container',{ [className]: className });
    
    return (<div className={classNames} onClick={onClick} {...rest} >
        {children}
    </div>)
}

export const ExerciseProgressionListing = ({ exercises, actionRender }) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {exercises.map((exercise,index) => {
                
                return (
                    <ListCard key={`${exercise.id}${exercise.arbitraryMeasureVal}`}>
                        <ExerciseListSmallThumb exercise={exercise} />
                        <div className="title-container">
                            <div className="exercise-title abbr-text">{exercise.listName()}</div>
                            <div className="exercise-subtitle">{t('Level num',{ level: index+1 })}</div>
                        </div>
                        {actionRender({ exercise })}
                    </ListCard>
                )
            })}
        </React.Fragment>
    )
}

export const ExerciseSearchListing = ({ exercisables, maxIndex, actionRender, hideGeneric }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {exercisables.map((exercisable,index) => {
                
                if(index > maxIndex || (hideGeneric && exercisable.isGeneric())) {
                    return '';
                } else {
                    return (
                        <ListCard key={`${exercisable.id}-${index}`}>
                            <ExerciseListSmallThumb exercise={exercisable} />
                            <div className="title-container">
                                <div className="exercise-title abbr-text">{exercisable.fullName(t)}</div>
                                <div className="exercise-subtitle">{exercisable.translatedMuscleGroups(t).join(', ')}</div>
                            </div>
                            {actionRender({ exercisable })}
                        </ListCard>
                    )
                }
            })}
        </React.Fragment>
    )
}

export const ExerciseSpecListing = ({ workout, actionRender }) => {
    const supersets = workout.supersets();

    return (
        <React.Fragment>
            {supersets.map(superset => {
                return (
                    <Superset key={superset.firstSpec().id} superset={superset} actionRender={actionRender} />
                )
            })}
        </React.Fragment>
    )
}

export const Superset = ({ superset, actionRender }) => {
    const { t } = useTranslation();
    const classNames = classnames("woo-exercise-list swap")
    const cardClassNames = classnames("swap",{'pt5 pb5 pl5': !superset.isRegularSpec()})
    const tip = superset.tooltip(t);
    const label = superset.firstSpec().supersetLabel(t);
    return (
        <Card className={cardClassNames} key={superset.firstSpec().id}>
            {!superset.isRegularSpec() && (<Tooltipped 
                        key={tip}
                        options={{ 
                            html: tip,
                            classes: ['square']
                        }}
                        className={`${label.toLowerCase()}-tip`}
                    >
                        <div className="superset">{label}</div>
                    </Tooltipped>)}
            {superset.specsArr().map((spec,index) => (
                <div className={classNames} key={spec.id}>
                    <ExerciseListSmallThumb exercise={spec.exercise} />
                    <div className="title-container">
                        <div className="exercise-title abbr-text">{spec.exercisableName(t)}</div>
                        <div className="exercise-subtitle">{spec.summary(t)}</div>
                    </div>
                    {actionRender({ spec })}
                </div>
            ))}
        </Card>
    )
}