import React from 'react';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import { Formik } from 'formik';
import { InfiniteScrollForm } from 'components/InfiniteScroll';
import { AutoSubmitTextInput } from 'components/TextInput';
import { ScrollableModalContent } from 'components/Modal';
import DBLogForm from 'partials/DBLogForm';
import { ServingsToggleForm } from 'partials/ServingsToggleForm';
import MacroSummary from 'partials/MacroSummary';
import { ActionBox } from 'components/RecordPanel';
import Dropdown from 'components/Dropdown';
import { IngredientLoadingContainer } from 'partials/IngredientInfo';
import { NewRecipeButton } from 'partials/MealAndRecipeSearch';
import { PaddedIconNote } from 'components/Typography';

class RecipesLogForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { servings: this.props.recipe.countPerServing || 1 }
        this.handleServingChgCreator = this.handleServingChgCreator.bind(this);
    }

    render() {
        let { recipe, loadFullRecipe, ...rest } = this.props;
        const { servings } = this.state;

        loadFullRecipe = loadFullRecipe.bind(null,recipe.id);
        const preloaded = () => (recipe.isFullyLoaded());
        const load = loadFullRecipe.bind(recipe.id);
        const id = recipe.id;
    
        return (
            <DBLogForm 
                {...rest} 
                subtitle={<MacroSummary macroHash={recipe.macroHash(servings/(recipe.countPerServing || 1))} />}
                record={{ ...recipe, servings }}  
                id={id} 
                render={(formProps) => {
                    return (
                        <ServingsToggleForm 
                            borderBottom
                            {...formProps}
                            setValueCreator={this.handleServingChgCreator}
                            servingsText={recipe.defaultedServingName(rest.t)}
                            infoButton={
                                <Dropdown 
                                    options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                                    triggerRender={({ ref, target }) => {
                                        return (
                                            <ActionBox 
                                                ref={ref}
                                                data-target={target}
                                                id={'show-recipe-info-' + id}
                                                icon='info-circle'
                                                className="show-recipe-info-btn info-color dropdown-trigger" 
                                            />
                                        )
                                    }}
                                    contentRender={({ recalcDims }) => {
                                        return (
                                            <LoadingContainerParent 
                                                load={load}
                                                preloaded={preloaded}
                                                recipe={recipe}
                                                onSuccess={recalcDims}
                                                servings={servings}
                                                component={IngredientLoadingContainer}
                                            />
                                        )
                                    }}
                                />
                                
                            }
                        />
                    )
                }} 
            />
        )
    }

    handleServingChgCreator(values,setValues) {
        const handler = (newVal) => {
            this.setState({servings: newVal});
            setValues({ ...values, servings: newVal });
        }
        return handler;
    } 
}

const RecipesList = ({ recipes, maxIndex, log, loadFullRecipe, t, date, triggerRequestRating }) => {
    return (
        recipes.map((recipe,index) => {
            if(index < maxIndex) {
                return (
                    <RecipesLogForm 
                        recipe={recipe} 
                        key={recipe.id}
                        topLevel={{
                            variant: "narrow",
                            borderBottom: true, 
                            borderTop: (index === 0)
                        }}
                        log={log}
                        loadFullRecipe={loadFullRecipe}
                        date={date} 
                        t={t}
                        triggerRequestRating={triggerRequestRating}
                    />
                )
            } else {
                return '';
            }
        })
    )
}

const RecipesLoadingContainer = loadingContainer({
    'SUCCESS': RecipesList
})

const RecipesTab = (props) => {
    const { 
        user,
        trainer,
        log, 
        loadFullRecipe, 
        t, 
        calcNonScrollDim, 
        date,
        load,
        triggerRequestRating,
        recipeSearch,
        closeAndGo,
        startEditingRecipe
     } = props;

     const { initialParams, more, loadedPage, recipes } = recipeSearch.offPlanSearchParams(user);

     const recipeListProps = { recipes, log, loadFullRecipe, t, date, triggerRequestRating };

     return (
        <InfiniteScrollForm
            initialPage={1}
            initialMsg={t("Enter a search term to find recipes in the database.")}
            noResultsComp={<PaddedIconNote variant="info" text={t("No recipes match your search.")} />}
            initialValues={initialParams}
            component={ScrollableModalContent} 
            records={recipes}
            calcNonScrollDim={calcNonScrollDim}
            loadedPage={loadedPage}
            load={load}
            more={more}
            id="recipes-tab-scroll"
            render={({ maxIndex, startValues, submitLoad, retryLoad, status }) => {
                return (
                    <React.Fragment>
                        <Formik initialValues={ startValues }
                            onSubmit={(values) => {
                                submitLoad(values);
                            }}
                            validate={(values) => ({}) }
                            initialErrors={{}} >
                            {({ handleBlur, handleChange, handleSubmit, values }) => {
                                return (
                                    <form className="pr10 pl10 modal-search-bar" onSubmit={handleSubmit}>
                                        <AutoSubmitTextInput 
                                            label={t("Search")}
                                            value={values.keywords}
                                            submitForm={() => submitLoad(values)}
                                            timeout={500}
                                            className="search-field"
                                            inputProps={ {
                                                name: "keywords",
                                                onBlur: handleBlur,
                                                onChange: handleChange,
                                                id: 'db-recipes-query-field'
                                            } }
                                        />
                                        <NewRecipeButton user={user} trainer={trainer} closeAndGo={closeAndGo} startEditingRecipe={startEditingRecipe} outlined className="ml5 input-field" />
                                    </form>
                                )
                            }}
                        </Formik>
                        {<RecipesLoadingContainer maxIndex={maxIndex} load={retryLoad} loadState={status} {...recipeListProps} />}
                    </React.Fragment>
                )
            }}
        >
        </InfiniteScrollForm>
    )
}

export default RecipesTab;