import { ExerciseImplementation, registerInflection } from 'lib/record-base';
import { User } from 'lib/user';
import * as _ from 'lib/utilities';
import { workoutDoPathFor } from 'config/paths';


class Superset {

    constructor(specs) {
        this.specs = specs;
    }

    exerciseSets(wasUninitId) {
        let sets = [];
        let workSets = [];
        let remaining = [];

        for(const spec of this.specs) {
            for(const set of spec.exerciseSets) {
                if(set.warmup || set.needsInitialization() || (wasUninitId && set.id === wasUninitId)) {
                    sets.push(set);
                } else {
                    workSets.push(set);
                }
            }
            remaining.push(workSets);
            workSets = [];
        }

        const maxLen = Math.max(...remaining.map(specSets => specSets.length));

        remaining = _.filter(remaining,specSets => specSets.length > 0);
        for(let i=0; i < maxLen; i++) {
            remaining.forEach(specSets => sets.push(specSets.shift()));
            remaining = _.filter(remaining,specSets => specSets.length > 0);
        }

        return sets;
    }

    afterConfirmPath() {
        const nextSet = this.exerciseSets()[0];
        return nextSet.pathFor(nextSet.pathSuffix(false));
    }

    afterInitialPath(curSet) {
        const spec = curSet.exerciseSpecification;
        const warmupSets = spec.warmupSets();

        if(warmupSets.length > 0) {
            return warmupSets[0].pathFor(warmupSets[0].pathSuffix(false));
        } else {
            const otherSpec = _.find(this.specs,otherSpec => otherSpec.needsInitialization());
            if(otherSpec) {
                return otherSpec.exerciseSets[0].pathFor('initial');
            } else {
                return this.specs[0].workSets()[0].pathFor();
            }
        }
    }

    orderedPaths() {
        let paths = [];
        let remaining = [];

        for(const spec of this.specs) {
            for(const set of spec.exerciseSets) {
                paths.push(set.pathFor('superset_preview'));
                paths.push(set.pathFor('initial'));
            }
            paths.push(spec.genericSetMatch('warmup','superset_preview'));
            paths.push(spec.genericSetMatch('work','superset_preview'));
            paths.push(spec.genericSetMatch('warmup','initial'));
            paths.push(spec.genericSetMatch('work','initial'));

            for(const set of spec.warmupSets()) {
                paths.push(set.pathFor());
                paths.push(set.pathFor('rest'));
            }
            paths.push(spec.genericSetMatch('warmup',''));
            paths.push(spec.genericSetMatch('warmup','rest'))

            const workSets = spec.workSets();

            if(workSets.length === 0) {
                paths.push(spec.genericSetMatch('work',''));
                paths.push(spec.genericSetMatch('work','rest'))
            } else {
                remaining.push(spec.workSets());
            }
        }

        const maxLen = Math.max(...remaining.map(specSets => specSets.length));

        for(let i=0; i < maxLen; i++) {
            remaining.forEach(specSets => {
                const set = specSets.shift();
                paths.push(set.pathFor());
                paths.push(set.pathFor('rest'));
            });
            remaining = _.filter(remaining,specSets => specSets.length > 0);
        }

        return paths;
    }

    isSuperset() {
        return this.specs.length === 2;
    }

    isCircuit() {
        return this.specs.length > 2;
    }

    isRegularSpec() {
        return this.specs.length <= 1;
    }

    needsPreview() {
        if(this.isRegularSpec()) {
            return false;
        }

        return _.every(this.specs,spec => !spec.supersetStarted)
    }

    firstSpec() {
        return this.specs[0];
    }

    specsArr() {
        return this.specs;
    }

    label(t,addArticle=false) {
        if(this.isCircuit()) {
            return addArticle ? t('a circuit') : t('Circuit');
        } else {
            return addArticle ? t('a superset') : t('Superset');
        }
    }

    tooltip(t) {
        if(this.isCircuit()) {
            return t('circuit tip')
        } else {
            return t('superset tip')
        }
    }

    instructions(t) {
        if(this.isCircuit()) {
            return [t('What is a circuit?'),this.tooltip(t)];
        } else {
            return [t('What is a superset?'),this.tooltip(t)];
        }
    }

}

export class ExerciseSpecification extends ExerciseImplementation {
    static NAME = 'ExerciseSpecification'
    static ASSOCS = { 
        workout: { type: 'belongsTo' },
        activityLog: { type: 'hasOne' },
        exerciseSets: { type: 'hasMany', sortAttr: 'setOrder' },
        exerciseTemplate: { type: 'belongsTo' },
        exercise: { type: 'belongsTo' }
    }

    resolvedWorkout() {
        if(!this.workout) return null;

        if(this.workout.isWarmup || this.workout.isCooldown) {
            return this.workout.parentWorkout;
        } else {
            return this.workout;
        }
    }

    resolvedParent() {
        return this.resolvedWorkout() || this.activityLog;
    }

    date() {
        return this.resolvedParent().date;
    }

    resolvedExerciseId() {
        if(this.hasValidTemplate()) {
            return this.exerciseTemplate.resolvedExerciseId() || this.exerciseId;
        } else {
            return this.exerciseId;
        }
    }

    workoutSection() {
        if(this.workout.isWarmup) {
            return 'warmup';
        } else if(this.workout.isCooldown) {
            return 'cooldown';
        } else {
            return 'workout';
        }
    }

    genericSetMatch(type,suffix) {
        const setIndex =  `${this.id}-${type}-(\\d*)`;
        return workoutDoPathFor(this.date(),setIndex,suffix);
    }

    exerciseSearchValues() {
        return { searchTerm: '', equipmentTypes: [''], simpleTypes: this.exercisable().simpleTypes(), contextId: this.id, contextType: 'ExerciseSpecification' }
    }

    timeDone() {
        const doneSets = _.filter(this.exerciseSets,set => set.logged());
        return _.reduce(doneSets,(totalTime,set) => (totalTime + set.estimatedTime()),0)
    }

    estimatedTime() {
        return _.reduce(this.exerciseSets,(totalTime,set) => (totalTime + set.estimatedTime()),0);
    }

    workoutIndex() {
        return this.workout.specIndex(this);
    }

    prevSpec() {
        const indx = this.workoutIndex();
        return this.workout.specByIndex(indx-1);
    }

    nextSpec() {
        const indx = this.workoutIndex();
        return this.workout.specByIndex(indx+1);
    }

    isSuperset() {
        return this.superset && !!this.nextSpec();
    }

    isSupersetChild() {
        const prev = this.prevSpec();
        return prev && prev.isSuperset();
    }

    supersetArr(childToo=false) {
        if(this.isSupersetChild()) {
            if(childToo) {
                return this.prevSpec().supersetArr(true);
            } else {
                return null;
            }
        } else if (this.isSuperset()) {
            let nextS = this.nextSpec();
            let specs = [this,nextS];
            while(nextS.isSuperset()) {
                nextS = nextS.nextSpec();
                specs.push(nextS)
            }
            return new Superset(specs);
        } else {
            return new Superset([this]);
        }
    }

    supersetLabel(t) {
        return this.supersetArr(true).label(t);
    }

    exerciseName() {
        return this.exercise.name;
    }

    exercisableName(t) {
        return this.hasValidTemplate() ? this.exerciseTemplate.exercisableName(t) : this.exerciseName();
    }

    exercisable() {
        return this.hasValidTemplate() ? this.exerciseTemplate.exercisable : this.exercise;
    }

    uniqueWeights() {
        return _.uniq(_.compact(this.workSets().map(set => set.unitWeight())))
    }

    weightsStr(t) {
        if(this.isWeighted()) {
            const weights = this.uniqueWeights();
            if(weights.length === 0) {
                return '';
            } else {
                return `@${weights.join(',')} ${this.weightSuffix(t)}`
            }
        } else {
            return '';
        }
    }

    uniqueSets(t) {
        let sets = {};
        this.workSets().forEach(set => {
            const key = set.abbrGoal(t);
            if(!sets[key]) {
                sets[key] = 1;
            } else {
                sets[key] += 1;
            }
        })
        return sets;
    }

    setsStr(t) {
        return Object.entries(this.uniqueSets(t)).map(([setStr,count]) => `${count}x${setStr}`).join(', ');
    }

    summary(t) {
        if(this.isVideoOnly()) {
            return t("Follow along with the video");
        } else if(this.isOpenEndedActivity()) {
            return t('An open-ended activity');
        } else if(this.isTimedActivity()) {
            return _.stopwatchFormat(this.exerciseSets[0].time);
        } else if(this.isMaxTest()) {
            return t("Test for a new reps rep max",{ reps: this.exerciseTemplate.repMaxReps })
        } else {
            let initialStr = '';
            if(this.needsTest()) {
                initialStr = t("Initial strength test, then");
            } else if(this.warmupSets().length > 0) {
                initialStr = t("Warmup sets, then");
            }
            return _.compact([initialStr,this.setsStr(t),this.weightsStr(t)]).join(' ')
        }
    }

    comments() {
        return this.hasValidTemplate() ? this.exerciseTemplate.comments : null;
    }

    user() {
        return this.resolvedParent().user;
    }

    warmupSets() {
        return _.filter(this.exerciseSets,set => set.warmup)
    }

    workSets() {
        return _.filter(this.exerciseSets,set => !set.warmup)
    }

    workSetIds() {
        return this.workSets().map(set => set.id)
    }

    fullyLogged() {
        return _.every(this.workSets(),set => set.logged());
    }

    anyLogged() {
        return _.some(this.exerciseSets,set => set.logged());
    }

    anyWorkLogged() {
        return _.some(this.workSets(),set => set.logged());
    }

    defaultPlateCalcSet() {
        const predicate = this.anyWorkLogged() ? (set) => (!set.warmup && !set.logged()) : set => !set.logged();
        let index = _.findIndex(this.exerciseSets,predicate);
        if(index === -1) {
            index = Math.max(this.exerciseSets.length-1,0);
        }
        return index;
    }

    exerciseSetting() {
        return this.user().exerciseSettingFor(this.exerciseId);
    }

    isMetric() {
        return this.user().isMetric(this.exercise);
    }

    isWeighted() {
        return this.exercise.isWeighted();
    }

    hasArbMeasure() {
        return this.exercise.hasArbMeasure();
    }

    arbTip() {
        return _.isBlank(this.exercise.arbitraryTip) ? null : this.exercise.arbitraryTip;
    }

    hasLoading() {
        return (this.isWeighted() || this.hasArbMeasure());
    }

    arbitraryMeasure() {
        return this.exercise.arbitraryMeasure;
    }

    needsDumbellInfo() {
        return this.exercise.needsDumbellInfo();
    }

    isUnilateral() {
        return this.exercise.isUnilateral();
    }

    isAlternating() {
        return this.exercise.isAlternating();
    }

    isIsometric() {
        return this.exercise.isIsometric();
    }

    isGeneralActivity() {
        return this.exercise.isGeneralActivity();
    }

    needsPerSide() {
        return (this.isUnilateral() && !this.isTimed());
    }

    useMeters() {
        return !this.exerciseSets[0] || this.exerciseSets[0].useMeters(this.user().isMetric());
    }

    useMetricDistance() {
        return this.user().isMetric();
    }

    hasValidTemplate() {
        return !!this.exerciseTemplate && !this.templateDirty;
    }

    hasBaseWeight() {
        return this.exercise.hasBaseWeight();
    }

    isMaxTest() {
        return this.hasValidTemplate() ? this.exerciseTemplate.isMaxTest() : false;
    }

    isRegularReps() {
        return this.setType === 0 || (this.setType === 4 && !this.isMaxTest());
    }

    isPercentMax() {
        return this.hasValidTemplate() && this.exerciseTemplate.isPercentMax();
    }

    isRpe() {
        return this.hasValidTemplate() ? this.exerciseTemplate.isRpe() : true;
    }

    isReferential() {
        return this.hasValidTemplate() && this.exerciseTemplate.isReferential();
    }

    isSpecificWeight() {
        return this.hasValidTemplate() && this.exerciseTemplate.isSpecificWeight();
    }

    repMaxReps() {
        return this.hasValidTemplate() ? this.exerciseTemplate.repMaxReps : 5;
    }

    isCardio() {
        return this.exercise.cardio;
    }

    isGeneric() {
        return this.exercise.generic;
    }

    isVideoOnly() {
        return this.exercise.isVideoOnly();
    }

    isBarbell() {
        return this.exercise.isBarbell();
    }

    isProgression() {
        return this.hasValidTemplate() && this.exerciseTemplate.isProgression();
    }

    canTestRepMax() {
        if(this.hasValidTemplate()) {
            return this.exerciseTemplate.canTestRepMax();
        } else if(this.isRpe()) {
            return (this.repsDefined() && this.exercise.hasRepMax());
        } else {
            return false;
        }
    }

    isStrengthTestable() {
        return (this.isWeighted() && this.canTestRepMax());
    }

    needsStrengthTest() {
        return this.isStrengthTestable() && this.user().needsStrengthTest(this.resolvedExerciseId())
    }

    needsProgTest() {
        return this.isProgression() && (this.reconcileNeeded || this.user().needsProgTest(this.exercisable()));
    }

    needsTest() {
        return (this.needsStrengthTest() || this.needsProgTest())
    }

    firstSetNeedsWeight() {
        if(!this.isWeighted()) {
            return false;
        }
        const workSets = this.workSets();
        return workSets[0] && !workSets[0].isWeightSet();
    }

    needsToPickWeight() {
        if(!this.firstSetNeedsWeight()) {
            return false;
        } else {
            return this.hasValidTemplate() ? this.exerciseTemplate.needsToPickWeight() : !this.canTestRepMax();
        }
    }

    needsInitialization() {
        if(this.isVideoOnly()) {
            return false;
        }

        return (this.isGeneric() || this.needsTest() || this.needsToPickWeight());
    }

    hasInitDoneScreen() {
        return this.isProgression() || this.isWeighted();
    }

    weightSuffix(t) {
        return t(User.weightSuffix(this.user().unitsFor(this.exercise)));
    }

    weightHash(t) {
        let units = this.weightSuffix(t);
        let key = 'weight';
        if(this.isMetric()) {
            key = 'kgWeight';
        }

        return ({ [key]: `${t("Weight")}(${units})`} )
    }

    repsHash(t) {
        if(this.isIsometric()) {
            return { isoTime: t("Time") };
        } else {
            return { reps: t("Reps") };
        }
    }

    distHash(t) {
        let key = 'mileDistance';
        let units = 'mi';

        if(this.useMeters()) {
            key = 'distance';
            units = 'm';
        } else if(this.isMetric()) {
            key = 'kmDistance';
            units = 'km';
        }

        return { [key]: t("Dist(units)", { units })};
    }

    logParams(t) {
        if(this.isVideoOnly()) {
            return { logType: ''}
        }

        const goal = { goal: t("Goal") };
        let wt = {};
        if(this.isWeighted()) {
            wt = this.weightHash(t);
        } else if(this.hasArbMeasure()) {
            wt = { arbitraryMeasure: _.startCase(this.arbitraryMeasure()) };
        }

        let hsh = {};
        switch(this.setType) {
            case 0:
            case 1:
            case 4: {
                hsh = { ...wt, ...goal, ...this.repsHash(t) };
                break;
            }
            case 2: {
                hsh = { ...wt, ...goal, unbroken: t("Unbroken?") };
                break;
            }
            case 3:
            case 102:
            case 103: {
                hsh = { ...wt, ...goal };
                break;
            }
            case 100: {
                hsh = { ...wt, ...goal, minSecTime: t("Time"), ...this.distHash(t)};
                break;
            }
            case 101: {
                hsh = { ...wt, ...goal, ...this.distHash(t), minSecTime: t("Time") };
                break;
            }
            case 105:
            case 104: {
                hsh = { ...wt, ...goal, minSecTime: t("Time") };
                break;
            }
            default: {
                break;
            }
        }

        if(!this.isMaxTest() && !this.isGeneralActivity()) {
            hsh = { ...hsh, restTime: t("Rest")};
        }

        return { ...hsh, logType: '' };
    }

    uniqueLoadingSets() {
        let sets = [];
        let usedKeys = [];
        this.workSets().forEach(set => {
            const setKey = set.loadingKey();
            if(!usedKeys.includes(setKey)) {
                sets.push(set);
                usedKeys.push(setKey);
            }
        })
        return sets;
    }
}

registerInflection('exerciseSpecification','exerciseSpecifications',ExerciseSpecification);