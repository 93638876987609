import React from 'react';
import { connect } from 'react-redux';
import { allMainMatches, accountPath, trainerBrandingPathFor, trainerBrandingMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import * as _ from 'lib/utilities';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { createBrandedApp, loadBrandedAppSetupInfo } from 'redux/actions';
import { Loader } from 'components/LoadingHOC';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import Card from 'components/Card';
import { MainHeading, SlightEmphasisIconNote } from 'components/Typography';
import { Link } from 'components/Routing';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import { BottomButtons } from 'components/Button';
import { DefaultTextArea, FileUpload, FullInput, LoaderOnlySubmitButton } from 'components/Form';
import TextInput from 'components/TextInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const transitionMap = {
    rules: [
        [[...allMainMatches,accountPath],transitions.slideOut],
        [trainerBrandingMatch,transitions.slideOver]
    ]
};

const BrandedAppSetupSuccess = ({ history, createBrandedApp,responseData: { brandingIsSetup, formValues }}) => {
    const { t } = useTranslation();
    const { foregroundIconUrl, ...initialVals } = formValues;
    initialVals.foregroundIcon = '';

    const noLogo = _.isBlank(foregroundIconUrl) || foregroundIconUrl.match(/NoLogoAvailable/);
    let validationSchema = {
        appName: Yup.string().max(30).required(),
        appSubtitle: Yup.string().max(30).required(),
        appDescription: Yup.string().max(4000).required()
    }

    let defaultImageUrl = foregroundIconUrl;
    if(noLogo) {
        validationSchema.foregroundIcon = Yup.mixed().required();
        defaultImageUrl = '';
    }

    return (
    <CenteredActivityContainer>
        {!brandingIsSetup && (
            <SlightEmphasisIconNote text={<React.Fragment>You need to set up general branding for your account before you can set up your iOS/Android app branding. Follow the flow <Link to={trainerBrandingPathFor(1)}>here</Link>.</React.Fragment>} />
        )}
        {brandingIsSetup && (
            <React.Fragment>
                <MainHeading>Setup Instructions</MainHeading>
                <FormikWrapper 
                    initialValues={ { ...initialVals } }
                    validationSchema={Yup.object().shape(validationSchema)}
                    submit={values => {
                        const { foregroundIcon, ...rest } = values;
                        const payload = _.isBlank(foregroundIcon) ? rest : { ...rest, foregroundIcon: _.dataURItoBlob(foregroundIcon) };
                        return createBrandedApp(payload);
                    }}
                    successCallback={() => {
                        history.go(-1);
                    }}
                    initialErrors={(noLogo || _.some(['appName','appSubtitle','appDescription'],(key) => _.isBlank(formValues[key]))) ? { foregroundIcon: 'Required', appName: 'Required', appSubtitle: 'Required', appDescription: 'Required' } : {}}
                    render={({ submitState, handleSubmit, ...formikProps }) => {
                        const { setFieldError, setFieldTouched } = formikProps;
                        const { errors: { foregroundIcon: imgErr }, touched: { foregroundIcon: imgTouched } } = formikProps;
                        const resolvedIconUrl = _.isBlank(formikProps.values.foregroundIcon) ? defaultImageUrl : formikProps.values.foregroundIcon;

                        return (
                            <form onSubmit={handleSubmit} className="mb100">
                                <Card className="pa20">
                                    <ol>
                                        <li>
                                            Follow the steps <Link to={'https://www.strongrfastr.com/help/faq#ForProfessionalSubscribersHowdoIsetupmyAppleDeveloperaccount'} target='_blank'>here</Link> to set up your Apple Developer account.
                                        </li>
                                        <li>
                                            Provide an app name (must be 30 characters or less):
                                            <div>
                                                <div className="valign-wrapper">
                                                    <FullInput
                                                        className="flex-grow"
                                                        name="appName"
                                                        label={'App Name'}
                                                        component={TextInput}
                                                        formikProps={formikProps}
                                                    />
                                                </div>
                                            </div>
                                        </li>
                                        <li>
                                            Provide an app subtitle (must be 30 characters or less):
                                            <div>
                                                <FullInput
                                                    className="flex-grow"
                                                    name="appSubtitle"
                                                    label={'App Subtitle'}
                                                    component={TextInput}
                                                    formikProps={formikProps}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            Provide a description for your app (4000 characters or less):
                                            <div>
                                                <DefaultTextArea
                                                    className="mt10 mb10"
                                                    name="appDescription"
                                                    inProps={{
                                                        maxLength: 4000,
                                                        placeholder: t('Required'),
                                                        height: 250
                                                    }}
                                                    {...formikProps}
                                                />
                                            </div>
                                        </li>
                                        <li>
                                            Provide a foreground icon. This should be a square png image at least 1024x1024 in size with a simple white icon and a transparent background. 
                                            Your app icons will be created by overlaying this image on top of your brand color. 
                                            The image should also work if clipped to a circle shape because certain platforms use circular icons.
                                            <div>
                                                <FileUpload
                                                    containerClass="inline-block"
                                                    imgClass="branded-app-icon"
                                                    imgStyle={{}}
                                                    name="foregroundIcon"
                                                    setFieldValue={formikProps.setFieldValue}
                                                    defaultImageUrl={resolvedIconUrl}
                                                    imageValidator={(result,onSuccess) => {
                                                        const image = new Image();
                                                        setFieldTouched('foregroundIcon',true);

                                                        image.onload = function() {
                                                            const width = this.width;
                                                            const height = this.height;
                                            
                                                            if(width < 1024 || height < 1024) {
                                                                setFieldError('foregroundIcon','Image must be at least 1024x1024');
                                                            } else if(width !== height) {
                                                                setFieldError('foregroundIcon','Image must be square');
                                                            } else {
                                                                onSuccess();
                                                            }
                                                        };
                                            
                                                        image.src = result;
                                                    }}
                                                />
                                                {!_.isBlank(resolvedIconUrl) && (
                                                    <div className="ml10 inline-block vert-align-top">
                                                        <img src={resolvedIconUrl} className="branded-app-icon circle" alt="" />
                                                    </div>
                                                )}
                                                {imgErr && imgTouched && (<div className="red-text"><FontAwesomeIcon icon="times" /> {imgErr}</div>)}
                                            </div>
                                        </li>
                                    </ol>
                                </Card>
                                <BottomButtons>
                                    <LoaderOnlySubmitButton 
                                        icon="check" 
                                        label={t('Submit')} 
                                        className="btn-wide" 
                                        loadState={submitState} 
                                        disabled={!_.isEmpty(formikProps.errors)} 
                                    />
                                </BottomButtons>
                            </form>
                        )
                    }}
                />
            </React.Fragment>
        )}
    </CenteredActivityContainer>
    )
}

class BrandedAppSetupPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, load, createBrandedApp, history } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title='Set Up Your Branded App'
            >
                <Loader
                    successComponent={BrandedAppSetupSuccess}
                    type="page"
                    load={load}
                    createBrandedApp={createBrandedApp}
                    history={history}
                    preloaded={() => false}
                />
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = () => ({

})

export const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadBrandedAppSetupInfo()),
    createBrandedApp: (values) => dispatch(createBrandedApp(values))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(BrandedAppSetupPage))