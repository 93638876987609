import { Eatable, allMacrosWithCals, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import NoImageFood from 'assets/img/NoImageFood.png';
import thumbNoImageFood from 'assets/img/thumbNoImageFood.png';
import mediumThumbNoImageFood from 'assets/img/mediumThumbNoImageFood.png';

export const noRecipeImages = { NoImageFood, thumbNoImageFood, mediumThumbNoImageFood };


export class Recipe extends Eatable {
    static NAME = 'Recipe'

    static ASSOCS = {
        recipeMeals: { type: 'hasMany' },
        ingredients: { type: 'hasMany' },
        overrideParent: { type: 'belongsTo', fk: 'overriddenId', tableName: 'recipes', inverse: 'overrides'  },
        overrides: { type: 'hasMany', fk: 'overriddenId', tableName: 'recipes', inverse: 'overrideParent' },
        userMeals: { type: 'hasMany', inverse: 'source' }
    }

    static DEFAULT_VALUES = {
        name: '',
        servings: 1,
        mainOrSide: 'main',
        foodType: 'dinner',
        standalone: 1,
        sideCategory: 'dinner carb/fat',
        instructions: '',
        activeTime: 0,
        totalTime: 0,
        cookTime: 0,
        ingredients: []
    }

    static mainOrSideTagCol = t => ([
        { text: t('Main'), value: 'main' },
        { text: t('Side'), value: 'side' }
    ])

    static foodTypesForForm = t => ([
        { text: t('Breakfast'), value: 'breakfast' },
        { text: t('Lunch'), value: 'lunch' },
        { text: t('Dinner'), value: 'dinner' },
        { text: t('Snack'), value: 'snack' }
    ])

    static sideCategories = ["dinner carb/fat", "veggie", "salad", "dairy snack", "nuts", "other snacks", "fruit", "drink/shake", "breakfast side", "soup/stew/misc"];

    static sideCategoryCol = t => (this.sideCategories.map( cat => ({ text: t(cat), value: cat })))

    static defaultDraft = (user) => {
        const obj = {
            ...this.DEFAULT_VALUES,
            defaultImage: mediumThumbNoImageFood,
            ingredients: [],
            saveToFavorites: true
        }

        if(!user || user.isTrainer()) {
            obj.overrideAllergyTags = [''];
            obj.dietTags = ['standard'];
            obj.complexity = 0;
            if(user && user.showAdvancedRecipeFields()) {
                obj.isMealKit = false;
            }
        }

        if(user && user.isTrainerRecipeAdmin() && user.hasOtherTrainers()) {
            obj.teamShared = true;
        }

        return obj;
    }

    static parseForSubmit = draft => {
        let newDraft = _.mergeDefaults(draft,{ ...this.DEFAULT_VALUES, name: 'untitled' });
        if(!_.isBlank(draft.overriddenId)) {
            newDraft = _.omit(newDraft,['foodType','mainOrSide','standalone','sideCategory','isMealKit']);
        }
        return newDraft;
    }

    macroHash(servings) {
        return _.multiplyHash(_.pick(this,allMacrosWithCals),servings)
    }

    urlStub(servings,convertServings=false) {
        servings = convertServings ? servings/(this.countPerServing || 1) : servings;
        return `${this.id}_${servings}`;
    }

    fullCalcdMacros() {
        return _.hashSum(_.filter(this.ingredients,i => !i._destroy).map(ingredient => ingredient.macroHash()))
    }

    calcdMacroHash() {
        return _.multiplyHash(this.fullCalcdMacros(),(1/(this.servings || 1)))
    }

    readableServings(servings) {
        if(_.isBlank(servings)) {
            return '';
        }
        return _.roundToF(servings*(this.countPerServing || 1),0.01)
    }

    ingredientMult(servings) {
        return servings/this.servingsPerBatch;
    }

    ingredientFor(food) {
        return _.find(this.ingredients,ingredient => ingredient.foodWeight.foodId === food.id)
    }

    imageUrl(size) {
        const url = _.isBlank(size) ? (this.image && this.image.url) : (this.image && this.image[size] && this.image[size].url);
        if(url && !url.includes('CustomRecipe.png') && !url.includes('NoImageFood.jpg')) {
            return url;
        } else {
            return noRecipeImages[`${size}NoImageFood`];
        }
    }

    costPerServing() {
        return (this.singleBatchCost || 0)/this.servingsPerBatch;
    }

    isFullyLoaded() {
        return !!this.ingredientIds;
    }

    isCategorizeLoaded() {
        return !!this.mainOrSide;
    }

    servingsForCals(cals) {
        if(_.isBlank(cals)) {
            return 1;
        }
        cals = Math.max(cals,0);
        return Math.min(Math.max(_.roundToF(cals/this.calories,0.25),0.25),20)
    }

    canBeMainDish() {
        return this.mainOrSide === 'main';
    }

    staticId() {
        return this.overriddenId || this.id;
    }

    instructionSteps() {
        return _.isBlank(this.instructions) ? [] : this.instructions.split(/\n/)
    }

    ingredientEditorFields(isEdit) {
        return this.ingredients.map(ingredient => ingredient.editorFields(isEdit));
    }

    editorFields(user,isCopy,showMasterEditWarning=false) {
        const isEdit = !isCopy && this.canBeDirectlyEditedBy(user);
        let attrs;
        if(isCopy) {
            attrs = { duplicateSourceId: this.id, saveToFavorites: true }
        } else {
            attrs = this.canBeDirectlyEditedBy(user) ? { id: this.id, showMasterEditWarning } : { overriddenId: this.staticId(), forTrainer: user.isTrainer(), showMasterEditWarning }
        }

        attrs.defaultImage = this.imageUrl('mediumThumb');
        attrs.image = '';
        attrs.servings = this.servingsPerBatch;
        attrs.standalone = this.standalone ? 1 : 0;
        let pickFields = ['name','mainOrSide','foodType','sideCategory','instructions','activeTime','cookTime','totalTime'];
        if(isEdit) {
            pickFields.push('overriddenId');
        }
        if(user.isTrainer()) {
            attrs.overrideAllergyTags = this.overrideAllergyTags || [''];
            attrs.dietTags = this.dietTags ? this.dietTags.split(',') : [''];
            attrs.complexity = this.complexity || 0;
            if(isEdit && _.isBlank(this.overriddenId) && user.showAdvancedRecipeFields()) {
                attrs.isMealKit = this.isMealKit;
            }

            if(user.isTrainerRecipeAdmin() && user.hasOtherTrainers() && isCopy) {
                attrs.teamShared = true;
            }
        }
        attrs = { ...attrs, ..._.pick(this,pickFields), ingredients: this.ingredientEditorFields(isEdit) };
        return _.parseObjForForm(_.mergeDefaults(attrs,Recipe.DEFAULT_VALUES))
    }

    isOverrideFor(user) {
        return (this.canBeDirectlyEditedBy(user) && !_.isBlank(this.overriddenId))
    }

    isOwnedBy(user) {
        return (this.canBeDirectlyEditedBy(user) && _.isBlank(this.overriddenId))
    }

    isDirectlyOwnedBy(user) {
        return this.canBeDirectlyEditedBy(user) && !this.teamShared && _.isBlank(this.overriddenId);
    }

    isOwnedSolelyByOtherTrainer(user) {
        return !_.isBlank(this.ownerId) && !this.canBeDirectlyEditedBy(user) && _.isBlank(this.overriddenId) && !this.isTeamRecipeFor(user);
    }

    isTeamRecipeFor(user) {
        return (user.isTrainer() && this.teamShared && user.getMasterAccount().id === this.ownerId)
    }

    canBeDirectlyEditedBy(user) {
        return this.ownerId === user.id || (user.isTrainerRecipeAdmin() && this.isTeamRecipeFor(user));
    }

    isDbRecipe() {
        return (_.isBlank(this.overriddenId) && _.isBlank(this.ownerId));
    }

    canBeEditedBy(trainer) {
        if(trainer.isTrainerRecipeAdmin()) {
            return !this.isOwnedSolelyByOtherTrainer(trainer);
        }

        return this.ownerId === trainer.id;
    }

    editableVersionFor(trainer) {
        if(trainer && this.canBeEditedBy(trainer)) {
            if(this.canBeDirectlyEditedBy(trainer)) {
                return this;
            } else {
                const base = this.overrideParent || this;
                const override = _.find(base.overrides,rec => (!rec.inactive && rec.canBeDirectlyEditedBy(trainer)));
                return override || base;
            }
        }
        return null;
    }

    isInactive() {
        return !!this.inactive;
    }

    isActive() {
        return !this.isInactive();
    }

    defaultedServingName(t) {
        if(_.isBlank(this.servingName)) {
            return t('serving(s)');
        }

        return this.servingName;
    }
}

registerInflection('recipe','recipes',Recipe);

window.Recipe = Recipe