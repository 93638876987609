import React from 'react';
import { useTranslation } from 'react-i18next';
import { macroCals, allMacros } from 'lib/classes';
import * as _ from 'lib/utilities';
import ChartWrapper from 'components/ChartWrapper'
import { macroColors } from 'config/settings';

const MacroBarChart = ({ macroHashes, titleFontSize=18, width, height=200 }) => {

    const data = { datasets: [], labels: Object.keys(macroHashes) };
    const { t } = useTranslation();

    allMacros.forEach(macro => {
        const calVals = [];
        Object.entries(macroHashes).forEach(([label,macros]) => {
            let calVal = Math.round((macros[macro] || 0)*macroCals[macro]);;
            calVals.push(calVal);
        })
        if(_.filter(calVals,val => (val >= 10)).length > 0) {
            data.datasets.push({
                data: calVals,
                backgroundColor: macroColors[macro],
                label: _.capitalize(macro === 'carbs' ? t('net carbs') : t(macro)),
                macro
            })
        }
    })

    const options = { 
        scales: {
            xAxes: [{
              stacked: true,
              gridLines: {
                display: false,
              }
            }],
            yAxes: [{
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
              type: 'linear',
            }]
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                boxWidth: 10
            }
        },
        title: {
            display: false,
            text: 'Test title',
            fontSize: titleFontSize
        },
        animation: {
            duration: 0
        },
        tooltips: {
            callbacks: {
                label: (tooltipObj, data) => {
                    const label = data.datasets[tooltipObj.datasetIndex].macro || '';
                    const value = data.datasets[tooltipObj.datasetIndex].data[tooltipObj.index];
                    return `${label}: ${Math.round(value/macroCals[label.toLowerCase()])}${t('g')} (${value}${t('cals')})`
                }
            }
        },
        plugins: {
            labels: {
                render: 'percentage',
                fontSize: 10,
                fontColor: '#ffffff',
                position: 'outside',
                textMargin: -10
            }
        }
    }

    const config = { data, options };

    return (
        <ChartWrapper chartType="bar" {...config} height={200} />
    )

}

export default MacroBarChart;