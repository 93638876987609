import React from 'react';
import classnames from 'classnames';
import * as _ from 'lib/utilities';

class ResponsiveTabs extends React.Component {

    constructor(props) {
        super(props)
        this.mediaQuery = window.matchMedia('screen and (max-width: 992px)');
        this.state = { hideInactive: this.mediaQuery.matches, activeTab: props.activeTab || 0 };
        this.mediaQuery.addListener(this.screenChangeListener);
    }

    render() {
        const { tabs, maxL, setActiveTab: persistActive, noNav } = this.props;
        const { hideInactive, activeTab } = this.state;
        const largeTabsLen = _.filter(tabs,([title,render,largeTitle]) => (largeTitle !== false)).length;
        const cols = Math.min(Math.floor(12/largeTabsLen),maxL || 12);
        const offset = Math.floor((12 - cols*largeTabsLen)/2);
        const className = `col s12 m12 l${cols} limit-50-h`;
        const handleClick = (index) => () => {
            this.setActiveTab(index);
            persistActive && persistActive(index);
        }
        const tabClassNames = classnames("activity-tabs responsive",{"for-simple-nav": !noNav})

        return (
            <React.Fragment>
                <div className={tabClassNames}>
                    {tabs.map(([title,render],index) => (
                        <div key={index} id={`responsive-tab-${index}`} className={classnames('activity-tab',{ active: (activeTab === index)})} onClick={handleClick(index)}>{title}</div>
                    ))}
                </div>
                <div className="row">
                    {tabs.map(([title,render,largeTitle],index) => {
                        const classNames = classnames(className,{[`offset-l${offset}`]: index === 0, 'hide-on-med-and-down': (activeTab !== index) })

                        if(!hideInactive && largeTitle === false) {
                            return '';
                        }

                        return (
                            <div key={index} className={classNames}>
                                <div className="m-center-column">
                                    <h4 className="conversion-heading ml0 hide-on-med-and-down">{largeTitle || title}</h4>
                                    {(!hideInactive || activeTab === index) && render({ hideInactive })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </React.Fragment>
        )
    }

    setActiveTab = (tab) => this.setState({ activeTab: tab })

    screenChangeListener = e => this.setState({ hideInactive: e.matches });

    componentWillUnmount() {
        this.mediaQuery.removeListener(this.screenChangeListener);
    }
}

export default ResponsiveTabs;