import React, { useState } from 'react';
import { allMainMatches, editTrainerFormMatch, modalPathFor, trainerFormsPath } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPageWithTabs } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { dbAssessmentsSelector, trainerWithFormsSelector } from 'redux/selectors';
import { createFormField, deleteTrainerAssessment, importTrainerAssessment, loadTrainerAssessments } from 'redux/actions';
import { connect } from 'react-redux';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { SlightEmphasisIconNote } from 'components/Typography';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { FixedButton } from 'components/Button';
import { useHistory, useLocation } from 'react-router-dom';
import * as _ from 'lib/utilities';
import { AsyncListCardAction, ListCard, ListCardAction, ListCardTitleCollapse } from 'components/List';
import Collapse from 'components/Collapse';
import requestStateHandler from 'components/RequestStateHandler';
import { Loader } from 'components/LoadingHOC';
import MediaQuery from 'components/MediaQuery';
import AnimTo from 'components/AnimTo';
import { TagButtons, fakeFormikProps } from 'components/Form';
import { EditAssessmentModal, NewAssessmentModal, TakeAssessmentBody, newAssessmentModalSuffix } from 'partials/AssessmentModals';
import { ExerciseListSmallThumb } from 'partials/ExerciseListing';
import { MuxUpoadMsg, MyRecordDropdown } from 'partials/Utilities';
import { AssessmentResult, FormField } from 'lib/classes';
import { AssessmentField } from 'partials/TrainerForm';
import { RecordTags } from 'partials/RecordUtilities';



const transitionMap = {
  rules: [
      [[...allMainMatches,editTrainerFormMatch,trainerFormsPath],transitions.slideOut]
  ]
};

const ViewAssessment = ({ t, assessment }) => {
  const [viewType, setViewType] = useState({ type: 'client' });
  const fakeFormField = new FormField({ assessment, dataEntry: new AssessmentResult({ source: assessment }) });

  return (
    <div className="pa10 slight-top-border">
        <div className="font-grey text-center">{t('Assessment Preview')}</div>
        <div className="text-center pb10">
          <TagButtons
            single
            col={[{ text: t('Client View'), value: 'client' }, { text: t('Coach View'), value: 'trainer' }]}
            setFieldTouched={() => {}}
            setFieldValue={(name,value) => setViewType({ type: value })}
            name='type'
            buttonProps={ { className: 'ma2 btn-no-shadow' } }
            btnId={value => `${value}-view-btn`}
            values={viewType}
          />
      </div>
      <div className="slight-rounded-border pa5">
        {viewType.type === 'client' && (<TakeAssessmentBody 
            readOnly 
            t={t} 
            assessmentResult={fakeFormField.dataEntry} 
            formikProps={fakeFormikProps(['result','resultNum','leftResult','rightResult','leftResultNum','rightResultNum','video'])}
          />)}
        {viewType.type === 'trainer' && (
          <AssessmentField 
            formField={fakeFormField} 
            name="" 
            readOnly 
            forceVideo
            assessmentResult={fakeFormField.dataEntry} 
            formikProps={fakeFormikProps(['result','resultNum','leftResult','rightResult','leftResultNum','rightResultNum','video'])} 
          />)}
      </div>
    </div>
  )
}

let RecordListing = ({ trainer, record, tabType, importRecord, addRecord, t, destroy, setImportCount }) => {

    const [expanded,setExpanded] = useState(false);

    const newDestroy = () => {
        const newRecord = new record.constructor({ ...record, inactive: true });
        destroy(newRecord);
    }

    if(record.inactive) {
        return '';
    }

    return (
      <ListCard className="wrt-list-card" id={`rec-card-${record.id}`} collapse={<Collapse isOpen={expanded}>
          <ViewAssessment t={t} assessment={record} />
        </Collapse>}>
            <ExerciseListSmallThumb exercise={record} />
            <ListCardTitleCollapse className="position-relative" onClick={() => setExpanded(!expanded)}>
                {tabType === 'personal' && (
                    <RecordTags record={record} viewer={trainer} listCard />
                )}
                {record.resolvedName(t)}
                <MuxUpoadMsg record={record} />
            </ListCardTitleCollapse>
          <ListCardAction onClick={() => setExpanded(!expanded )} className="info-btn">
              <FontAwesomeIcon icon={"info-circle"} />
          </ListCardAction>
          <MyRecordDropdown
              record={record} 
              t={t} 
              newDestroy={newDestroy} 
              importRecord={importRecord} 
              isDb={tabType === 'db'} 
              setImportCount={setImportCount}
              trainer={trainer} 
          />
          {addRecord && (
            <AsyncListCardAction
              icon={'plus'}
              action={() => addRecord(record)}
              className="add-ass-btn"
            />
          )}
      </ListCard>
    )
}

RecordListing = requestStateHandler({ destroy: 'record' })(RecordListing)

const RecordsListCore = ({ trainer, records, tabType, importRecord, addRecord, setImportCount, destroy }) => {
    const { t } = useTranslation();


    return (<React.Fragment>
        {_.sortBy(records,assessment => assessment.resolvedName(t)).map(rec => (
          <RecordListing
            key={rec.id}
            record={rec}
            tabType={tabType}
            t={t}
            importRecord={importRecord}
            setImportCount={setImportCount}
            addRecord={addRecord}
            destroy={destroy}
            trainer={trainer}
          />
        ))}
    </React.Fragment>)
}

const RecordsList = ({ trainer, dbAssessments, recordType: type, importRecord, destroy, isLarge, setImportCount, addRecord, setActiveTab, filters, setFilters }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { pathname } = useLocation();
    const finalRecords = type === 'db' ? dbAssessments : trainer.getRecordsForFilters('Assessments',filters,t);
    const newPath = modalPathFor(newAssessmentModalSuffix,pathname);
    const listProps = { trainer, records: finalRecords, tabType: type, importRecord, destroy, addRecord };

    const emptyMessage = `${t('no assessments')} ${t('import from db tip')}`;

    return (
      <div className="mt15 mb150">
          {type === 'personal' && (<div className="text-center">
              <TagButtons
                single
                col={[{ text: t('All'), value: 'all' }, { text: t('Mine'), value: 'my' }, { text: t('Team'), value: 'team' }]}
                btnId={value => `${value}-filt-btn`}
                setFieldTouched={() => {}}
                setFieldValue={(name,value) => setFilters({ type: value })}
                name='type'
                values={filters}
              />
          </div>)}
          {type === 'personal' && (
            <LinkButton
              to={newPath}
              rounded
              color="primary"
              outlined={finalRecords.length !== 0}
              className="hide-on-med-and-down"
            >
                <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> <span>{t("New")}</span>
            </LinkButton>
          )}
          {finalRecords.length === 0 && (
            <div className="mb10 mt10">
                    <SlightEmphasisIconNote text = {emptyMessage} btn={<Button rounded id="to-db-btn" outlined onClick={() => setActiveTab(2)}><FontAwesomeIcon icon="arrow-right" /> {t("Go to Database Tab")}</Button>} />
            </div>
          )}
          {finalRecords.length > 0 && (<RecordsListCore {...listProps} setImportCount={setImportCount} />)}
          {type === 'personal' && (
            <React.Fragment>
                <EditAssessmentModal trainer={trainer} />
                <NewAssessmentModal trainer={trainer} />
                {!isLarge && <FixedButton id="new-assessment-btn" icon="plus" onClick={() => history.push(newPath)} />}
            </React.Fragment>
          )}
      </div>
    )
}

class RecordsListWrap extends React.Component {

    constructor(props) {
        super(props);
        const { setImportCount, recordType } = this.props;

        if(recordType === 'personal') {
            setImportCount(0);
        }
    }

    render() {

        return (<RecordsList {...this.props} />)
    }
}

class AssessmentsIndexPage extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            importCount: 0,
            filters: { type: 'all' }
        }
    }

    render() {
        const { trainer, dbAssessments, loadAssessments, addRecord: addRecordBase, importAssessment, destroy, activeTab, setActiveTab, scrollRef, setupTransitions, t, match: { params: { formId } } } = this.props;
        const { importCount, filters } = this.state;
        const recordType = activeTab === 2 ? 'db' : 'personal';
        const allRecords = trainer.allTrainerAssessments();
        const addRecord = record => addRecordBase({ formId, fieldType: 'assessment', assessmentId: record.id });

        return (
          <SimpleNavPageWithTabs
            scrollRef={scrollRef}
            transitionMap={transitionMap}
            setupTransitions={setupTransitions}
            title={t('Add Assessments')}
            tabLabels={ [<React.Fragment>{t("Mine")}{importCount > 0 && (<AnimTo top={'150px'} left={'150px'} tag='b' className="primary-text"> +{importCount}</AnimTo>)}</React.Fragment>,t("Database")]}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          >
              <MediaQuery query={'(min-width: 993px)'} render={({ queryMatches, setActiveTab }) => (
                <CenteredActivityContainer>
                    <Loader
                      key={recordType}
                      type="page"
                      alwaysLoad
                      successComponent={RecordsListWrap}
                      preloaded={() => allRecords.length > 0}
                      load={() => loadAssessments({ type: recordType })}
                      dbAssessments={dbAssessments}
                      recordType={recordType}
                      importRecord={importAssessment}
                      destroy={destroy}
                      trainer={trainer}
                      isLarge={queryMatches}
                      loadRecords={loadAssessments}
                      addRecord={addRecord}
                      setImportCount={this.setImportCount}
                      setActiveTab={setActiveTab}
                      filters={filters}
                      setFilters={this.setFilters}
                    />
                </CenteredActivityContainer>
              )} />
          </SimpleNavPageWithTabs>
        )
    }

    setImportCount = (count) => {
        const { importCount } = this.state;

        this.setState({ importCount: _.isBlank(count) ? (importCount+1) : count });
    }

    setFilters = (filters) => this.setState({ filters })
}

const mapStateToProps = state => ({
    dbAssessments: dbAssessmentsSelector(state),
    trainer: trainerWithFormsSelector(state)
})

const mapDispatchToProps = dispatch => ({
    loadAssessments: params => dispatch(loadTrainerAssessments(params)),
    importAssessment: (params,popup) => dispatch(importTrainerAssessment(params,popup)),
    destroy: (assessment) => dispatch(deleteTrainerAssessment(assessment.id)),
    addRecord: data => dispatch(createFormField(data,'Added')) //TODO
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(AssessmentsIndexPage));