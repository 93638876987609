import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Card from 'components/Card';
import * as _ from 'lib/utilities';
import { dateFormat } from 'config/settings';
import LinkButton from 'components/LinkButton';
import { nutritionOverridesPath, mealPlanNutritionModalPathFor, regenerateWeekPathFor, generateNewWeekPathFor, modalPathFor } from 'config/paths';
import { NutritionInfoWeeklyChart, NutritionInfoDailyChart, NutritionInfoModal, MacroErrorTip } from './NutritionInfoCharts';
import { useHistory, useLocation } from 'react-router-dom';
import Tooltipped from 'components/Tooltipped';
import { InitialTip, SIAC } from './Utilities';
import { WEEKLY_MEAL_DAYS } from 'config/tooltips';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { MealPlanStandaloneContext } from 'views/MealPlanSetupFlow';
import { Link } from 'components/Routing';
import MacroSummary from './MacroSummary';
import { selectDaysForCopySuffix } from './PickDaysModal';

const nutritionSideBarPct = 0.25;
const nutritionSidebarMin = 360;
const weeklyCardMargin = 7;
const weeklyCardMaxWidth = 300;
const weeklyCardMinWidth = 145;
const maxWeeklyCardContainerWidth = 1300;

const LockedIcon = () => {

    return (
        <div className="locked-meal-icon">
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={'lock'} color="white" />
                <FontAwesomeIcon icon={['far','lock']} color="grey" />
            </span>
        </div>
    )
}

const SharedIcon = () => {

    return (
        <div className="added-person-icon">
            <span className="fa-layers fa-fw">
                <FontAwesomeIcon icon={'user-friends'} color="white" />
                <FontAwesomeIcon icon={['far','user-friends']} color="grey" />
            </span>
        </div>
    )
}

export const ProteinInfoButton = ({ className, msg, addendClass='protein-info-btn' }) => {
    const { t } = useTranslation();
    msg = msg || t("protein supplement tip");

    return (<Tooltipped
        key={msg}
        options={{ 
            html: msg,
            classes: ['square']
        }}
        component='div'
        className={classnames({[className]: className, [addendClass]: addendClass })}
    >
        <span>
            <FontAwesomeIcon icon="info-circle" />
        </span>
    </Tooltipped>)
}

export const NutritionModalButton = ({ mealInfoMatrix, basePath, className, hasWhiteBcg, isTrainerEditing }) => {
    const { t } = useTranslation();
    const colorProps = hasWhiteBcg ? { outlined: true, color: 'primary' } : { noColor: true };

    return (
        <LinkButton id="nutrition-modal-btn" className={classnames("semitransparent large-screen-disappear position-relative",{ [className]: className, 'mr15': mealInfoMatrix.isSingle() })} style={{overflow: 'visible'}} noShadow {...colorProps} to={mealPlanNutritionModalPathFor(basePath)}>
            <FontAwesomeIcon icon="chart-pie" />
            {t('Nutrition')}
            {mealInfoMatrix.hasWarningsWithTrainer(isTrainerEditing) && <span className="notification-icon" style={{ top: '-6px', right: '-4px'}} >!</span>}
        </LinkButton>
    )
}

const WeeklyCardDates = React.forwardRef(({ weeklyMeal },ref) => {
    const allDates = weeklyMeal.allWeekDates();
    const dates = weeklyMeal.dates();
    let isLeftEdge = true;

    return (
        <div className="date-container" ref={ref}>
            {allDates.map((date,index) => {
                const hide = !_.some(dates,dt => dt.isSame(date));
                const isRightEdge = !_.some(dates,dt => dt.isSame(date.clone().add(1,'days')));
                const curLeftEdge = isLeftEdge;
                isLeftEdge = hide;
                return (
                    <div 
                        className={classnames("date-contents",{ 'border-radius-left': curLeftEdge, 'border-radius-right': isRightEdge, "invisible": hide })}
                        key={date.format('dd')}
                    >
                        {date.format('dd')[0]}
                    </div>
                )
            })}
        </div>
    )
})

export const MealCardSubtitle = ({ children }) => {

    return (
        <div className="type-card-subtitle lh1 abbr-text">
            {children}
        </div>
    )
}

export const SideDishes = ({ sideDishes }) => {

    return (
        <React.Fragment>
            {sideDishes.map(sideDish => (
                <React.Fragment key={sideDish.key()}>
                    + {sideDish.recipe.name}
                    <br />
                </React.Fragment>
            ))}
        </React.Fragment>
    )
}

export const SideDishesSubtitle = ({ sideDishes }) => {

    return (
        <MealCardSubtitle>
            <SideDishes sideDishes={sideDishes} />
        </MealCardSubtitle>
    )
}

export const MealCardTitle = ({ children }) => {
    return (
        <div className="type-card-title">
            {children}
        </div>
    )
}

export const MealCardImage = ({ imageUrl }) => {

    return  (
        <React.Fragment>
            <img 
                src={imageUrl} 
                className="type-card-img" 
                alt="" 
            />
            <div style={{lineHeight: 0}}>
                <svg className="type-card-img-placeholder" width={500} height={0} viewBox="0 0 500 0"></svg>
            </div>
        </React.Fragment>
    )
}

export const SideDishImages = ({ sideDishes }) => {

    return (
        <div className="side-img-container">
            {sideDishes.map(sideDish => (
                <div key={sideDish.id || sideDish.recipeId}>
                    <img 
                        src={sideDish.recipe.imageUrl('thumb')} 
                        className="type-card-side-img" 
                        alt="" 
                    />
                </div>
            ))}
        </div>
    )
}

export const MealCard = ({ className, children, actionComp, warningIcon, trainerBadge, heart, style, ...rest }) => {
    const cardClassNames = classnames("type-card buttonize-color-only",{[className]: className });

    return (
        <div className="type-card-rel-wrap" style={style}>
            <Card className={cardClassNames} {...rest} >
                {children}
            </Card>
            {actionComp}
            {warningIcon || trainerBadge}
            {heart}
        </div>
    )
}

const WeeklyMealCard = ({ weeklyMeal, style, ActionComp, shareCheck, showDayTip, signedInAsClient, isTrainerEditing }) => {
    const { t } = useTranslation();
    const isProtein = weeklyMeal.isProteinSupp();
    const className = "type-button-area";
    
    return (
        <MealCard
            className={weeklyMeal.category()}
            style={style}
            actionComp={isProtein ? <ProteinInfoButton className={className} /> : React.cloneElement(ActionComp, { weeklyMeal, mealInfoMatrix: weeklyMeal.infoMatrix, className })}
            id={`weekly-meal-${weeklyMeal.id()}-card`}
            linkTo={weeklyMeal.planCardPath(isTrainerEditing)}
        >
            <SideDishImages sideDishes={weeklyMeal.sideDishes()} />
            {showDayTip && (<InitialTip
                    component={WeeklyCardDates}
                    tipName={WEEKLY_MEAL_DAYS}
                    text={t('weekly meal days')}
                    delay={8000}
                    weeklyMeal={weeklyMeal}
            />)}
            {!showDayTip && (<WeeklyCardDates weeklyMeal={weeklyMeal} />)}
            {shareCheck(weeklyMeal) && <SharedIcon />}
            {weeklyMeal.isLocked() && <LockedIcon />}
            <MealCardImage imageUrl={weeklyMeal.mainImageUrl('mediumThumb')} />
            <MealCardTitle>{weeklyMeal.mainName(t)}</MealCardTitle>
            {!isProtein && <SideDishesSubtitle sideDishes={weeklyMeal.sideDishes()} />}
            {isProtein && <MealCardSubtitle>{t('X scoops',{ count: weeklyMeal.mainDish().servings })}</MealCardSubtitle>}
            {signedInAsClient && (<MacroSummary macroHash={weeklyMeal.initMeal().unloggedMacroHash()} className="mcard-macro-sum meal" />)}
        </MealCard>
    )
}

export const MealCardWrapper = ({ children }) => {

    return (
        <div className="type-cards-wrapper">
            {children}
        </div>
    )
}

export const MealCardInnerContainer = ({ children, className, ...props }) => {

    return (
        <div className={classnames("meal-type-container mb5", { [className]: className })} {...props}>
            {children}
        </div>
    )
}

export class MealCardContainer extends React.Component {

    constructor(props) {
        super(props);
        window.addEventListener('resize',this.resizeListener);
        this.state = { windowWidth: window.innerWidth };
    }

    render() {
        const { windowWidth } = this.state;
        let availWidth = this.availWidth();
        const { minMeals, maxMeals, maxSides, render, alwaysWrap, sidesCap=3 } = this.props;
        
        let gutters = 0;
        const containerStyle = {};
        let skipWidth = false;
        if(maxMeals <= 1) {
            containerStyle.display = 'inline-block';
            containerStyle.marginLeft = '10px';
            containerStyle.marginRight = '10px';
            gutters = 20;
            skipWidth = true;
        }
        availWidth = availWidth - gutters;
        const isSmall = (windowWidth <= 600 && !alwaysWrap);
        const divisor = (isSmall || maxMeals <= 1) ? minMeals : Math.max(minMeals,2);
        let inclusiveWidth = (1/divisor)*availWidth;
        const cardWidth = inclusiveWidth - weeklyCardMargin*2;
        let width = Math.max(Math.min(cardWidth,weeklyCardMaxWidth),weeklyCardMinWidth);
        let totalWidth = width + weeklyCardMargin*2;
        let cardsCanFit = Math.min(Math.floor(availWidth/totalWidth),maxMeals);
        let topContainerWidth = cardsCanFit*totalWidth;
        const remainder = availWidth - topContainerWidth;
        const maxExpansion = weeklyCardMaxWidth - width;
        if(!isSmall && remainder > 10 && maxExpansion > 0) {
            width = width + Math.min(remainder/cardsCanFit,maxExpansion);
            totalWidth = width + weeklyCardMargin*2;
            topContainerWidth = cardsCanFit*totalWidth;
        }
        const cardStyle = { width, height: `${240 + 15*(Math.min(maxSides,sidesCap))}px` };  

    
        return (
            <div className="meals-section-container" style={(isSmall || skipWidth) ? {} : { width: `${topContainerWidth}px`}}>
                {render({ containerStyle, cardStyle })}
            </div>
        )
    }

    availWidth = () => {
        const { noSidebar } = this.props;
        const { windowWidth } = this.state;
        const sideBarWidth = (window.innerWidth <= 992 || noSidebar) ? 0 : Math.max(nutritionSideBarPct*windowWidth,nutritionSidebarMin);
        return Math.min(windowWidth - sideBarWidth - 15,maxWeeklyCardContainerWidth);
    }

    resizeListener = (e) => {
        if(window.innerWidth !== this.state.windowWidth) {
            this.setState({ windowWidth: window.innerWidth });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize',this.resizeListener);
    }
}

class MealTypeListing extends React.Component {

    render() {
        const { t, mealInfoMatrix, ActionComp, lessMarginTop, shareCheck, noSidebar, signedInAsClient, miniProfile } = this.props;
        const map = mealInfoMatrix.categoryMealMap(miniProfile);
        const minMeals = Object.values(map).reduce((curMinLength,meals) => (meals.length < curMinLength ? meals.length : curMinLength),999);
        const maxMeals = Object.values(map).reduce((curMaxLength,meals) => (meals.length > curMaxLength ? meals.length : curMaxLength),0);
        const maxSides = _.flatten(Object.values(map)).reduce((curMaxSides,meal) => (curMaxSides > meal.sideDishes().length ? curMaxSides : meal.sideDishes().length),0);
    
        return (
            <MealPlanStandaloneContext.Consumer>
                {clientId => {
                    const isTrainerEditing = !_.isBlank(clientId) || signedInAsClient;

                    return(
                        <MealCardContainer
                            noSidebar={noSidebar}
                            minMeals={minMeals}
                            maxMeals={maxMeals}
                            sidesCap={signedInAsClient ? 4 : 3}
                            maxSides={signedInAsClient ? maxSides+1 : maxSides}
                            render={({ containerStyle, cardStyle }) => {

                                return (
                                    <React.Fragment>
                                        {Object.entries(map).map(([category,meals],index) => {
                                            return (
                                                <MealCardInnerContainer className={classnames({ mt25: (index === 0 && !lessMarginTop), mt15: (index === 0 && lessMarginTop) })} style={containerStyle} key={category}>
                                                    <h4 className={classnames("conversion-heading spacing-2",{ mt0: (index === 0 && lessMarginTop) })}>
                                                        {meals[0].categoryTitle(t)}
                                                    </h4>
                                                    <MealCardWrapper>
                                                        {meals.map((meal,mealIndex) => (
                                                            <WeeklyMealCard 
                                                                weeklyMeal={meal} 
                                                                style={cardStyle} 
                                                                key={meal.initMeal().id} 
                                                                ActionComp={ActionComp} 
                                                                shareCheck={shareCheck || ((weeklyMeal) => weeklyMeal.isShared())}
                                                                showDayTip={index === 0 && mealIndex === 0}
                                                                signedInAsClient={signedInAsClient}
                                                                isTrainerEditing={isTrainerEditing}
                                                                miniProfile={miniProfile}
                                                            />
                                                        ))}
                                                        <div style={{opacity: 0, height: 0}}>t</div>
                                                    </MealCardWrapper>
                                                </MealCardInnerContainer>
                                            )
                                        })}
                                    </React.Fragment>
                                )
                            }}
                        />
                    )
                }}
            </MealPlanStandaloneContext.Consumer>
        )
    }
}

MealTypeListing = withTranslation()(MealTypeListing);

export { MealTypeListing }

const DailyMealCard = ({ userMeal, tempState, lockCheck, ActionComp, mealInfoMatrix, signedInAsClient, isTrainerEditing }) => {
    const { t } = useTranslation();
    const showSidesAndShared = _.isBlank(tempState);
    const isLocked = lockCheck ? lockCheck(userMeal) : userMeal.isLocked();
    const isProtein = userMeal.isProteinSupp();
    const className = "button-placeholder buttonize no-shadow";
    const link = mealInfoMatrix.planCardPathFor(userMeal,isTrainerEditing);

    return (
        <div className="meal-cards-day-wrap">
            <div className="sideways-text">{userMeal.logTitle(null,t,true)}</div>
            <Card className={"meal-cards-day-view buttonize-color-only"} id={`daily-meal-${userMeal.id}-card`} linkTo={link}>
                <div className="position-relative">
                    <div className="display-flex">
                        <div className="image-meal-card-day position-relative">
                            <img 
                                src={userMeal.mainImageUrl('mediumThumb',tempState)} 
                                alt=""
                            />
                            {userMeal.isShared() && showSidesAndShared && <SharedIcon />}
                            {isLocked && <LockedIcon />}
                        </div>
                        <div className="today-recipe-details" style={{minWidth: '0'}}>
                            <div className="meal-plan-recipe-title lh1">{userMeal.mainName(t,tempState)}</div>
                            <div style={{ fontSize: '12px' }} >
                                <div className="low-emphasis lh1 abbr-text">
                                    {userMeal.isProteinSupp() && t('X scoops',{ count: userMeal.mainDish().servings })}
                                    {showSidesAndShared && userMeal.sideDishes().map(sideDish => (
                                        <React.Fragment key={sideDish.id}>
                                            + {sideDish.recipe.name}
                                            <br />
                                        </React.Fragment>
                                    ))}
                                </div>
                            </div>
                            {signedInAsClient && (<MacroSummary macroHash={userMeal.unloggedMacroHash()} className="mcard-macro-sum" />)}
                        </div>
                    </div>
                </div>
            </Card>
            {isProtein && (<ProteinInfoButton className={className} />)}
            {!isProtein && React.cloneElement(ActionComp, { userMeal, mealInfoMatrix, className })}
        </div>
    )
}

export const MealDayListing = ({ mealInfoMatrix, getTempState, lockCheck, excludeProtein, ActionComp, signedInAsClient, actionType, miniProfile }) => {
    const { pathname } = useLocation();

    return (
        <MealPlanStandaloneContext.Consumer>
            {clientId => {
                const isTrainerEditing = !_.isBlank(clientId) || signedInAsClient;
                return (
                    <div className="meal-plan-column-cont">
                        {mealInfoMatrix.weekDates().map(dt => {
                            const meals = mealInfoMatrix.mealsOnDate(dt,miniProfile);
            
                            if(meals.length > 0) {
                                return (
                                    <div className="mb30" key={dt.format(dateFormat)}>
                                        <h4 className="conversion-heading ml15">
                                            <div className="valign-wrapper">
                                                {dt.format('dddd')}
                                                {signedInAsClient && actionType === 'swap' && (<LinkButton noShadow outlined color="primary" variant="icon-only" className="ml10" to={modalPathFor(selectDaysForCopySuffix,pathname, { date: dt.format(dateFormat) })}>
                                                        <FontAwesomeIcon icon={['far','copy']}></FontAwesomeIcon>
                                                </LinkButton>)}
                                            </div>
                                            {signedInAsClient && (
                                                <MacroSummary macroHash={miniProfile ? mealInfoMatrix.macroHashForProfile(dt,miniProfile) : mealInfoMatrix.macroHashFor(dt)} className="main-head-sub" />
                                            )}
                                        </h4>
                                        {meals.map(userMeal => (<DailyMealCard 
                                            userMeal={userMeal} 
                                            tempState={getTempState && getTempState(userMeal)}
                                            lockCheck={lockCheck}
                                            key={userMeal.id} 
                                            ActionComp={ActionComp} 
                                            mealInfoMatrix={mealInfoMatrix}
                                            signedInAsClient={signedInAsClient}
                                            isTrainerEditing={isTrainerEditing}
                                        />))}
                                    </div>
                                )
                            } else {
                                return '';
                            }
                        })}
                    </div>
                )
            }}
        </MealPlanStandaloneContext.Consumer>
    )
}

const RegenHeader = () => {
    const { t } = useTranslation();

    return (
        <div className="text-center">
            <h4 className="font-white center mb5">{t('Pin the meals you want to keep')}</h4>
            <p className="ma0 center thin-subtitle font-white">{t("Unpinned meals will be randomized")}</p>
        </div>
    )
}

const MealListing = ({ viewType, mealInfoMatrix, actionType, ActionComp, miniProfile }) => {
    const { t } = useTranslation();

    return (
        <SIAC 
            render={({ signedInAsClient }) => (
                <div style={{flexGrow: '1', minWidth: 0, paddingBottom: '100px' }}>
                    {actionType === 'regen' && (<RegenHeader />)}
                    {viewType === 'type' && (<MealTypeListing 
                        miniProfile={miniProfile}
                        mealInfoMatrix={mealInfoMatrix} 
                        ActionComp={ActionComp} 
                        lessMarginTop={actionType === 'regen'} 
                        signedInAsClient={signedInAsClient}
                    />)}
                    {viewType === 'day' && (<MealDayListing 
                        miniProfile={miniProfile}
                        mealInfoMatrix={mealInfoMatrix} 
                        ActionComp={ActionComp} 
                        signedInAsClient={signedInAsClient}
                        actionType={actionType}
                    />)}
                    {actionType === 'swap' && (
                        <MealPlanStandaloneContext.Consumer>
                            {clientId => {
                                const isTrainerEditing = !_.isBlank(clientId) || signedInAsClient;

                                if(mealInfoMatrix.shouldShowRestartPlan(isTrainerEditing)) {
                                    return (
                                        <div className="center mt50">
                                            <Link className="underline" id="restart-week-btn" to={generateNewWeekPathFor(mealInfoMatrix.week,clientId)}>
                                                <FontAwesomeIcon icon="undo" /> {t('Restart this week from scratch')}
                                            </Link>
                                        </div>
                                    )
                                }

                                return '';
                            }}
                        </MealPlanStandaloneContext.Consumer>
                    )}
                </div>
            )}
        />
    )
}

export const SidebarContainer = ({ children, className }) => {

    return (
        <div className={classnames("hide-on-med-and-down",{ [className]: className })} style={{minWidth: '360px', paddingLeft: '10px', paddingRight: '10px', width: '25%'}}>
            {children}
        </div>
    )
} 

const NutritionSidebar = ({ mealInfoMatrix, showEditBtn, showRegenBtn, isTrainerEditing, miniProfile }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const showWarnings = mealInfoMatrix.shouldShowMacroWarnings(isTrainerEditing,miniProfile);

    const macroWarnings = showWarnings ? mealInfoMatrix.macroWarningFor(t) : null;
    const macroErrorProps = showRegenBtn ? { onClick: () => history.push(regenerateWeekPathFor(mealInfoMatrix.week)), warnings: macroWarnings } : { warnings: macroWarnings };
    const macroError = <MacroErrorTip {...macroErrorProps} />

    return (
        <SidebarContainer className="nutrition-background">
            <div style={{ position: 'fixed', minWidth: '360px', paddingLeft: '10px', paddingRight: '10px', width: '25%', left: 0} }>
            <div className="valign-wrapper mt20 nutrition-header-wrapper">
                <div className="meal-plan-heading-2 mr10">{t('Nutrition')}</div>
                {showEditBtn && (mealInfoMatrix.user.canEditOwnMacros() || isTrainerEditing) && (<LinkButton rounded noShadow color="primary" outlined to={nutritionOverridesPath}>
                    <FontAwesomeIcon icon={["far","edit"]} />
                    {t('Edit Goals')}
                </LinkButton>)}
            </div>
            <Card className="nutrition-card">
                <NutritionInfoWeeklyChart 
                    isSingle={mealInfoMatrix.isSingle()}
                    macroHash={miniProfile ? mealInfoMatrix.avgMacroHashForProfile(miniProfile) : mealInfoMatrix.avgMacroHash()} 
                    targets={mealInfoMatrix.weeklyGramMacroTargets(miniProfile)}
                    macroError={macroError}
                    warnings={macroWarnings}
                />
            </Card>
            {!mealInfoMatrix.isSingle() && (<Card className="nutrition-card">
                <NutritionInfoDailyChart macroHashes={mealInfoMatrix.macroHashesForDailyChart(miniProfile)} />
            </Card>)}
            </div>
        </SidebarContainer>
    )
}

export class MealView extends React.Component {

    render() {
        const { mealInfoMatrix, ignoreWarnings, viewType, actionType, miniProfile, ActionComp, basePath, children } = this.props;

        return (
            <ResponsiveContainer 
                render={({ belowBreakpoint }) => {
                    return (
                        <MealPlanStandaloneContext.Consumer>
                            {clientId => {
                                const isTrainerEditing = !_.isBlank(clientId);

                                return (
                                    <div className="limit-50-h display-flex">
                                        {!belowBreakpoint && <NutritionSidebar 
                                            miniProfile={miniProfile}
                                            mealInfoMatrix={mealInfoMatrix} 
                                            isTrainerEditing={isTrainerEditing}
                                            showEditBtn={actionType === 'swap' || mealInfoMatrix.isSingle()}
                                            showRegenBtn={actionType === 'swap'}
                                        />}
                                        {children || (<MealListing 
                                            viewType={viewType} 
                                            mealInfoMatrix={mealInfoMatrix} 
                                            actionType={actionType} 
                                            miniProfile={miniProfile}
                                            ActionComp={ActionComp} 
                                        />)}
                                        <NutritionInfoModal
                                            miniProfile={miniProfile}
                                            mealInfoMatrix={mealInfoMatrix} 
                                            basePath={basePath} 
                                            ignoreWarnings={ignoreWarnings} 
                                            isTrainerEditing={isTrainerEditing}
                                            linkToRegenForErrors={actionType === 'swap'}
                                        />
                                    </div>
                                )
                            }}
                        </MealPlanStandaloneContext.Consumer>
                    )
                }}
            />
            
        )
    }

}