import React from 'react';
import classnames from 'classnames';

export default function ActivityContainer({ children, className, contClassName }) {
    return (
        <div className={classnames("container",{ [contClassName]: contClassName })}>
            <div className={classnames("row limit-50-h", { [className]: className })}>
                {children}
            </div>
        </div>
    )
}

export const Container = ({children}) => (<div className="container">{children}</div>)

export const CenteredActivityContainer = ({ children, className, wide=false }) => (
    <ActivityContainer className={className}>
            <div className="col s12 l8 offset-l2">
                <div className={classnames({"m-center-column": !wide})}>
                    {children}
                </div>
            </div>
    </ActivityContainer>
)