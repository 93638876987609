import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';

export class Ingredient extends RecordBase {
    static NAME = 'Ingredient'
    static ASSOCS = {
        recipe: { type: 'belongsTo' },
        foodWeight: { type: 'belongsTo' }
    }

    humanReadableQty(mult,includeName=true,includeGrams=false) {
        return this.foodWeight.humanReadableQty(mult*this.amount,includeName,includeGrams);
    }

    foodName() {
        return this.foodWeight.food.name;
    }

    fullFoodName() {
        if(!_.isBlank(this.modifier)) {
            return `${this.foodName()}, ${this.modifier}`
        }
        return this.foodName();
    }

    effectiveNutritionMult() {
        return _.isBlank(this.nutritionMultiplier) ? 1 : this.nutritionMultiplier;
    }

    editorFields(isEdit) {
        let attrs = ['seq','amount','foodWeightId','nutritionMultiplier'];
        if(isEdit) {
            attrs.push('id');
        }
        return { ..._.pick(this,attrs), foodId: this.foodWeight.food.id, tmpId: Math.random() }
    }

    macroHash() {
        return _.omit(this.foodWeight.food.macroHash(this.foodWeight.seq,this.amount*this.effectiveNutritionMult()),'servingInfo');
    }

    formKey() {
        return this.id || this.tmpId;
    }
}

registerInflection('ingredient','ingredients',Ingredient);