import React from 'react';
import { allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { chatUserSelector, preCliMessagingBack } from 'redux/selectors';
import { connect } from 'react-redux';
import { SimpleNavBack } from 'components/Nav';
import { ChatActivity } from './TrainerMessaging';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut]
    ]
};

class ClientMessagingPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, backCnt } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("Messaging")}
                white
                noScrollPadding
                containerClass={'min-padding'}
                backBtn={<SimpleNavBack backNum={backCnt} />}
            >
                <ChatActivity {...this.props} standalone />
            </SimpleNavPage>
        )
    }

}

const mapStateToProps = (state) => ({
    user: chatUserSelector(state),
    backCnt: preCliMessagingBack(state)
})

export default withTranslation()(connect(mapStateToProps)(ClientMessagingPage));