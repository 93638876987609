import React, { useState } from 'react';
import { ModalListingCheck } from './Form';
import * as _ from 'lib/utilities';

const MultiformWrapper = ({ initialExpanded, initialSelected, render, passValuesUp }) => {
    const [selection,setSelection] = useState({ selected: initialSelected, isTouched: false })
    const [expandedMap,setExpandedMap] = useState(initialExpanded);

    return render({ 
        selection: selection.selected, 
        setSelection: vals => {
            setSelection({ selected: { ...vals }, isTouched: true });
            passValuesUp && passValuesUp(vals);
        }, 
        expandedMap, 
        setExpandedMap, 
        isTouched: selection.isTouched 
    })
}

const MultiformEntry = ({ expanded, value, children, toggleSelect, toggleExpand, ...rest }) => {

    return (
        <ModalListingCheck
            name="selected"
            value={value}
            expand={targState => toggleExpand(value,targState)}
            expanded={expanded}
            toggleHandler={val => toggleSelect(val)}
            {...rest}
        >
            {children}
        </ModalListingCheck>
    )

}

const nextStateMap = { indeterminate: 'unchecked', unchecked: 'checked', checked: 'indeterminate' };

const getNextState = (curState,allowIndeterminate) => {
    if(allowIndeterminate) {
        return nextStateMap[curState];
    }

    if(curState === 'checked') {
        return 'unchecked';
    } else {
        return 'checked';
    }
}

export const Multiform = ({ col, entryFormRender, initialExpanded, initialSelected, render, passValuesUp, selectedVal, indeterminateVal, uncheckedVal, expandCallback, selectCallback }) => {
    const stateMap = { [selectedVal]: 'checked', [indeterminateVal]: 'indeterminate', [uncheckedVal]: 'unchecked' };
    const invertedStateMap = { checked: selectedVal, indeterminate: indeterminateVal, unchecked: uncheckedVal };

    return (
        <MultiformWrapper 
            initialExpanded={initialExpanded}
            initialSelected={initialSelected}
            passValuesUp={passValuesUp}
            render={(outerProps) => {
                const finalCol = typeof col === 'function' ? col(outerProps) : col;

                const entries = (
                    <React.Fragment>
                        {finalCol.map((props,index) => {
                            const { value, allowIndeterminate: inAllow } = props;
                            const { selection, setSelection, expandedMap, setExpandedMap } = outerProps;
                            const allowIndeterminate = (inAllow && !_.isBlank(indeterminateVal));
                            const checkState = (!allowIndeterminate && selection[value] !== selectedVal) ? 'unchecked' : stateMap[selection[value]];

                            const toggleSelect = (val,targState,skipCallback=false) => {
                                const nextState = getNextState(checkState,allowIndeterminate);
                                targState = targState === undefined ? nextState : stateMap[targState];
                                if(targState !== checkState) {
                                    setSelection({ ...selection, [val]: invertedStateMap[targState] });
                                    if(selectCallback && !skipCallback) {
                                        selectCallback({ value, index, toggleExpand, oldState: invertedStateMap[checkState], newState: invertedStateMap[targState] });
                                    }
                                }
                            }
                                
                            const toggleExpand = (val,targState,skipCallback=false) => {
                                const isExpanded = expandedMap[val];
                                targState = _.isBlank(targState) ? !isExpanded : targState;
                                if(targState !== isExpanded) {
                                    setExpandedMap(!targState ? _.omit(expandedMap,val) : { [val]: true });
                                    if(expandCallback && !skipCallback) {
                                        expandCallback({ value, index, toggleSelect, oldState: isExpanded, newState: targState });
                                    }
                                }
                            }

                            return (
                                <MultiformEntry toggleExpand={toggleExpand} toggleSelect={toggleSelect} expanded={expandedMap[value]} checkState={checkState} key={value} {...props}>
                                    {entryFormRender({ ...outerProps, ...props, checkState, toggleExpand, toggleSelect })}
                                </MultiformEntry>
                            )
                        })}
                    </React.Fragment>
                )

                return render({ ...outerProps, entries })
            }}
        />
    )
}