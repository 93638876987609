import React from 'react';

export class ResponsiveContainer extends React.Component {

    constructor(props) {
        super(props);
        const { breakpoint } = props;
        this.mediaQuery = window.matchMedia(`screen and (max-width: ${breakpoint || 992}px)`);
        this.state = { belowBreakpoint: this.mediaQuery.matches };
        this.mediaQuery.addListener(this.screenChangeListener);
    }

    render() {
        const { belowBreakpoint } = this.state;
        const { render } = this.props;

        return render({ belowBreakpoint });
    }

    screenChangeListener = e => this.setState({ belowBreakpoint: e.matches });

    componentWillUnmount() {
        this.mediaQuery.removeListener(this.screenChangeListener);
        this.mediaQuery = null;
    }

}