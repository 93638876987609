import { PageWithTransitions } from 'components/Page';
import React from 'react';
import * as transitions from 'assets/transitions';
import { mealPlanSetupMatch, startFlow } from 'config/paths';
import ActivityContainer from 'components/ActivityContainer';
import Card, { CardImageWithPlaceholder } from 'components/Card';
import mealPlanSignup from 'assets/img/MealPlanSignup.png';
import { SlightEmphasisIconNote } from 'components/Typography';
import { useTranslation } from 'react-i18next';

const transitionMap = {
    rules: [
        [[startFlow,mealPlanSetupMatch],transitions.slideOut]
    ]
};

let SetupFinishedCard = () => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <h4 className="conversion-heading ml0">{t("You're all set")}</h4>
            <Card id="plan-not-ready-card">
                <CardImageWithPlaceholder src={mealPlanSignup} noContent width={500} height={334} />
                <div className="image-card-note">
                    <SlightEmphasisIconNote text={t("pdf setup done msg")} />
                </div>
            </Card>
        </React.Fragment>
    )
}

export default class PDFSetupDone extends React.Component {

    render() {
        const { scrollRef, setupTransitions } = this.props;

        return (
            <PageWithTransitions 
                scrollRef={scrollRef} 
                setupTransitions={setupTransitions} 
                transitionMap={transitionMap}
            >
                <ActivityContainer>
                    <div className="col s12 m10 offset-m1 l6 offset-l3">
                        <div className="mt75">
                            <SetupFinishedCard />
                        </div>
                    </div>
                </ActivityContainer>
            </PageWithTransitions>
        )
    }
}