import React from 'react';
import { connect } from 'react-redux';
import { trainerMainMatches, clientDetailsMatch } from 'config/paths'; //trainerHabitPath should be passed in as createRecordsPath
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import FormikWrapper from 'components/FormikWrapper';
import { FlowQuestion } from 'components/FlowForm';
import { CheckboxCore, DatePickWrapper, LoaderOnlySubmitButton } from 'components/Form';
import { SlightEmphasisIconNote } from 'components/Typography';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import { Loader } from 'components/LoadingHOC';
import { ClientSelectListing, SlightEmphasisTip } from 'partials/Utilities';
import { INDETERMINATE_TIP } from 'config/tooltips';
import Button, { BottomButtons } from 'components/Button';
import { Multiform } from 'components/ExpandableMultiform';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldArray } from 'formik';
import { useHistory } from 'react-router-dom';
import LinkButton from 'components/LinkButton';
import { SchedulableSetting } from 'lib/classes';
import { SharedSchedulingFields } from 'partials/RecordUtilities';

const allTouched = {'freqType': true,'weekdays': true,'frequency': true,'startDate': true,'endDate': true };

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [clientDetailsMatch,transitions.slideOut],
        [[], transitions.slideOver]
    ]
};

const validateRecordSetting =  ({ selectState, dontSubmit, ...settings },t,addlValidations=null) => {
    if(selectState === SchedulableSetting.UNSELECTED || dontSubmit) {
        return null;
    }

    let errors = SchedulableSetting.validate(false,t)(settings);

    if(addlValidations) {
        errors = { ...errors, ...addlValidations(settings) };
    }

    return _.isEmpty(errors) ? null : errors;

}

const validateAll = (t,addlValidations=null) => ({ records, clientIds }) => {
    const recordErrors = [];
    records.forEach(h => recordErrors.push(validateRecordSetting(h,t,addlValidations)));
    let errs = {};

    if(_.noBlanks(clientIds).length <= 0) {
        errs = { clientIds: t('Pick at least', { count: 1 }) }
    }

    if(_.noBlanks(recordErrors).length > 0 ) {
        errs.records = recordErrors;
    }

    if(_.isEmpty(errs)) {
        return null;
    }

    return errs;
}

const parseSettingsMapToFormik = settingsMap => {
    const records = [];
    Object.entries(settingsMap).forEach(([recordId,{ settings, settings: { weekdays }, clientIds, dontSubmit }]) => {
        const record = { recordId: recordId, dontSubmit, wasDontSubmit: dontSubmit, ...settings, weekdays: _.isBlank(weekdays) ? [] : weekdays };
        if(_.isBlank(clientIds)) {
            record.selectState = SchedulableSetting.SELECTED;
        } else if(clientIds.length === 0) {
            record.selectState = SchedulableSetting.UNSELECTED;
            record.clientIds = clientIds;
        } else {
            record.selectState = SchedulableSetting.INDETERMINATE;
            record.clientIds = clientIds;
        }
        records.push(_.parseObjForForm(record));
    })

    return { records: _.sortBy(records,r => [r.selectState === SchedulableSetting.UNSELECTED,r.name]) };
}

const valuesFilter = ({ records, clientIds: topLevelClientIds }) => {
    const newRecords = records.map(h => {
        const { selectState, dontSubmit, wasDontSubmit, clientIds, recordId, startDate, ...settings } = h;
        const base = { recordId, selectState };
        if(startDate !== 'various') {
            settings.startDate = startDate;
        }

        if(dontSubmit || selectState === SchedulableSetting.UNSELECTED) {
            return base;
        }

        if(selectState === SchedulableSetting.SELECTED) {
            return { ...base, settings };
        }

        return { ...base, clientIds, settings };
    })

    return { records: newRecords, clientIds: topLevelClientIds };
}

const RecordSettingsForm = ({ index, record, recordName, toggleSelect, toggleExpand, checkState, t, initDatePick, ...formikProps }) => {
    const prefix = `records.${index}.`;
    const dontSubmit = _.get(formikProps.values,`${prefix}dontSubmit`);
    const wasDontSubmit = _.get(formikProps.values,`${prefix}wasDontSubmit`);

    const { setFieldValue} = formikProps;

    if(dontSubmit) {
        const text = (<React.Fragment>
        {t("differing schedules", { record_name: t(recordName) })}
        <div className="center mt25">
            <Button
                noShadow
                outlined
                color={'primary'}
                id="assign-new-schedule-btn"
                onClick={() => setFieldValue(`${prefix}dontSubmit`,false)}
            >
                <FontAwesomeIcon icon={['far','edit']} /> {t('Assign New Schedule')}
            </Button>
        </div>
    </React.Fragment>)

        return (
            <div className="ma10 child-cont">
                <SlightEmphasisIconNote text={text} />
            </div>
        )
    }

    return (<div className="text-left ma10">
        <SharedSchedulingFields 
            prefix={prefix} 
            record={record} 
            formikProps={formikProps} 
            initDatePick={initDatePick}
        />
        {wasDontSubmit && (
            <div className="mt10 mb10 text-center">
                <Button
                    noShadow
                    outlined
                    color={'red'}
                    id="unassign-new-schedule-btn"
                    onClick={() => setFieldValue(`${prefix}dontSubmit`,true)}
                >
                    <FontAwesomeIcon icon={'times'} /> {t('Cancel')}
                </Button>
            </div>
        )}
    </div>)

}

let AssignRecordsForm = ({ responseData: { settingsMap }, records: inRecords, clients, assignSettings: submit, single, trainer, recordName, createRecordsPath, createRecordsTip, createRecordsBtnLabel, addlValidations }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const initVals = parseSettingsMapToFormik(settingsMap);
    const { records: inFormikRecords } = initVals;
    const isNewAssignment = _.every(inFormikRecords,h => h.selectState === SchedulableSetting.UNSELECTED);
    let prompt
    if(single) {
        prompt = t('Set up record schedule', { record_name: t(recordName) })
    } else {
        prompt = isNewAssignment ? t("Select records to assign", { p_record_name: t(`${recordName}s`) }) : t('Records for object', { p_record_name: _.capitalize(t(`${recordName}s`)), object: clients.length === 1 ? clients[0].shortName() : t('selected clients') });
    }

    inRecords = _.keyBy(inRecords,h => h.id);

    return (
        <React.Fragment>
            <FlowQuestion text={prompt} className="mb0" />
            {_.isEmpty(inRecords) && (
                <SlightEmphasisIconNote text={(
                    <React.Fragment>
                        {t('no records created tip', { record_name: t(`${recordName}s`)})} {createRecordsTip}
                        <div className="center mt25">
                            <LinkButton
                                noShadow
                                outlined
                                color={'primary'}
                                id="go-to-habits-btn"
                                to={createRecordsPath}
                            >
                                <FontAwesomeIcon icon={'arrow-left'} /> {createRecordsBtnLabel}
                            </LinkButton>
                        </div>
                    </React.Fragment>
                )} />
            )}
            {!_.isEmpty(inRecords) && (
                <FormikWrapper 
                    initialValues={ { ...initVals, clientIds: single ? [''] : clients.map(c => c.id) } }
                    initialErrors={single ? { clientIds: 'pick one' } : {}}
                    submit={submit}
                    validate={validateAll(t,addlValidations)}
                    valuesFilter={valuesFilter}
                    successCallback={() => history.goBack()}
                    render={({ submitState, ...formikProps }) => {
                        const { handleSubmit, values: { records, clientIds }, errors: { records: recordErrors, clientIds: clientIdErrs }, setValues, setFieldTouched, setFieldValue, setTouched, touched } = formikProps;
                        const hasIndeterminate = !single && _.some(records, h => (h.clientIds && _.noBlanks(h.clientIds).length > 0));
                        const passValuesUp = selectionMap => {
                            setValues({ records: records.map(r => ({ ...r, selectState: selectionMap[r.recordId] })), clientIds });
                        }
        
                        return(
                            <form onSubmit={handleSubmit} id="assign-records-form">
                                {hasIndeterminate && (
                                    <SlightEmphasisTip variant="none" tipName={INDETERMINATE_TIP} id="indeterminate-tip" text={(
                                        <React.Fragment>
                                            <CheckboxCore
                                                inputProps={{ 
                                                    name: 'noop', 
                                                    value: '',
                                                    readOnly: true,
                                                    checked: false
                                                }} 
                                                indeterminate={true}
                                                filled 
                                                className={'middle-align'}
                                                labelClasses={"no-pointer-events"}
                                            />
                                            {t("indeterminate note", { record_name: t(recordName)})}
                                        </React.Fragment>
                                    )} />
                                )}
                                <DatePickWrapper 
                                    setFieldTouched={setFieldTouched}
                                    setFieldValue={setFieldValue}
                                    render={({ initDatePick }) => {
                                        return (
                                            <Multiform
                                                col={({ expandedMap }) => ([ ...records.map((h,index) => {

                                                    let props = { 
                                                        value: h.recordId, 
                                                        index, 
                                                        text: inRecords[h.recordId].resolvedName(t), 
                                                        record: inRecords[h.recordId], 
                                                        expandToggleId: `habit-${h.recordId}-toggle`, 
                                                        boxClass: 'non-modal', 
                                                        allowIndeterminate: (h.clientIds && _.noBlanks(h.clientIds).length > 0),
                                                        hasError: (recordErrors && !!recordErrors[index]), 
                                                        noCheck: single,
                                                        noCollapse: single,
                                                        subtitle: (recordErrors && recordErrors[index] && !expandedMap[h.recordId] && (<span className="red-text">{t("Settings are invalid")}</span>)) 
                                                    }

                                                    if(single) {
                                                        props.checkState = 'unchecked';
                                                    }
                                                    return props;
                                                }) ])}
                                                initialExpanded={single ? { [Object.values(inRecords)[0].id]: true } : {}}
                                                initialSelected={_.mapValues(_.keyBy(records,r => r.recordId),v => v.selectState)}
                                                selectedVal={SchedulableSetting.SELECTED}
                                                uncheckedVal={SchedulableSetting.UNSELECTED}
                                                indeterminateVal={SchedulableSetting.INDETERMINATE}
                                                passValuesUp={passValuesUp}
                                                expandCallback={({ value, index, toggleSelect, oldState: wasExpanded, newState: isExpanded }) => {
                                                    if(!wasExpanded && isExpanded) {
                                                        if(records[index].selectState === SchedulableSetting.UNSELECTED) {
                                                            toggleSelect(value,SchedulableSetting.SELECTED,true);
                                                        } else {
                                                            const errors = validateRecordSetting({ ...records[index], selectState: SchedulableSetting.SELECTED },val => val);
                                                            if(!_.isBlank(errors)) {
                                                                const newRecordsTouched = touched.records ? [ ...touched.records ] : records.map(h => null);
                                                                newRecordsTouched[index] = { ...allTouched };
                                                                setTouched({ ...touched, records: newRecordsTouched });
                                                            }
                                                        }
                                                    }
                                                }}
                                                selectCallback={({ value, index, toggleExpand, oldState, newState }) => {
                                                    if(oldState === SchedulableSetting.UNSELECTED && newState !== SchedulableSetting.UNSELECTED) {
                                                        const errors = validateRecordSetting({ ...records[index], selectState: SchedulableSetting.SELECTED },val => val);
                                                        if(!_.isBlank(errors)) {
                                                            toggleExpand(value,true,true);
                                                        }
                                                    } else if(oldState !== SchedulableSetting.UNSELECTED && newState === SchedulableSetting.UNSELECTED) {
                                                        toggleExpand(value,false,true);
                                                    }
                                                }}
                                                entryFormRender={({ record, index, toggleExpand, toggleSelect, checkState }) => {
                        
                                                    return (
                                                        <RecordSettingsForm 
                                                            recordName={recordName}
                                                            index={index}
                                                            record={record}
                                                            toggleExpand={toggleExpand}
                                                            toggleSelect={toggleSelect}
                                                            checkState={checkState}
                                                            t={t}
                                                            initDatePick={initDatePick}
                                                            {...formikProps}
                                                        />
                                                    )
                                                }}
                                                render={({ entries, selection, expandedMap, isTouched })=> {
                                                    selection = Object.keys(_.pickBy(selection,selected => selected)).map(id => Number(id));
                        
                                                    return (
                                                        <React.Fragment>
                                                            <FieldArray render={() => (<React.Fragment>{entries}</React.Fragment>)} />
                                                            <BottomButtons>
                                                                <LoaderOnlySubmitButton 
                                                                    icon="check" 
                                                                    label={t("Save")} 
                                                                    className="btn-wide" 
                                                                    loadState={submitState} 
                                                                    disabled={_.compact(recordErrors).length > 0 || clientIdErrs} 
                                                                />
                                                            </BottomButtons>
                                                        </React.Fragment>
                                                    )
                                                }}
                                            />
                                        )
                                    }}
                                />
                                {single && (
                                    <React.Fragment>
                                        <FlowQuestion text={t("Select clients to assign")} />
                                        <ClientSelectListing 
                                            trainer={trainer}
                                            initialFilters={{hasBasicProfile: true}}
                                            formikProps={formikProps}
                                            numName={'clientIds'}
                                            listingProps={ { boxClass: "non-modal", name: 'clientIds' } }
                                            excludeFilters={['trainerId','status']}
                                        />
                                    </React.Fragment>
                                )}
                            </form>
                        )
                    }}
                />
            )}
        </React.Fragment>
    )

}

const mapStateToFormProps = (state,{ recordsByIdsSel, responseData: { settingsMap } }) => ({
    records: recordsByIdsSel(state,{ recordIds: Object.keys(settingsMap) })
})

AssignRecordsForm = connect(mapStateToFormProps)(AssignRecordsForm);

export class AssignRecord extends React.Component {

    render() {
        const { 
            scrollRef, 
            setupTransitions, 
            loadSettings,
            assignSettings,
            match: { params: { id: idStr } },
            trainer,
            title,
            formProps
         } = this.props;
         const id = Number(idStr);

        return (
            <TrainerPaywall type={null}>
                <SimpleNavPage
                    scrollRef={scrollRef} 
                    transitionMap={transitionMap} 
                    setupTransitions={setupTransitions} 
                    title={title}
                >
                    <CenteredActivityContainer>
                        <Loader 
                            type="page"
                            load={() => loadSettings({ habitId: id })}
                            preloaded={() => false}
                            successComponent={AssignRecordsForm}
                            clients={null}
                            assignSettings={assignSettings}
                            single
                            trainer={trainer}
                            {...formProps}
                        />
                    </CenteredActivityContainer>
                </SimpleNavPage>
            </TrainerPaywall>
        )
    }
}

export class AssignRecords extends React.Component {

    render() {
        const { 
            scrollRef, 
            setupTransitions, 
            loadSettings,
            assignSettings,
            trainer,
            title,
            formProps,
            match: { params: { clientIds: clientIdsStr } }
         } = this.props;
         const clientIds = clientIdsStr.split('_');

        return (
            <TrainerPaywall type={null}>
                <SimpleNavPage
                    scrollRef={scrollRef} 
                    transitionMap={transitionMap} 
                    setupTransitions={setupTransitions} 
                    title={title}
                >
                    <CenteredActivityContainer>
                        <Loader 
                            type="page"
                            load={() => loadSettings({ clientIds })}
                            preloaded={() => false}
                            successComponent={AssignRecordsForm}
                            clients={trainer.clientsByIds(clientIds)}
                            assignSettings={assignSettings}
                            {...formProps}
                        />
                    </CenteredActivityContainer>
                </SimpleNavPage>
            </TrainerPaywall>
        )
    }
}