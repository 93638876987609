import { SetImplementation, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';

export class SetTemplate extends SetImplementation {
    static NAME = 'SetTemplate'
    static ASSOCS = { 
        exerciseTemplate: { type: 'belongsTo' },
        exerciseSets: { type: 'hasMany', sortAttr: 'setOrder' },
    }

    actionParams() {
        return { ...this.exerciseTemplate.getActionParams(), id: this.id }
    }

    refWeightFormValues() {
        const attrs = ['id','referenceTemplateId','referenceReps'];
        if(this.isPercentRef()) {
            attrs.push('repMaxPercent');
        } else {
            attrs.push('offsetType');
            if(this.exerciseTemplate.isMetric()) {
                attrs.push('kgWeight');
            } else {
                attrs.push('weight');
            }
        }
        return _.parseObjForForm({ ...this.extract(attrs), ...this.exerciseTemplate.getActionParams() })
    }

    isPercentRef() {
        return this.exerciseTemplate.referenceType === 0;
    }

    formValueKeys(user) {
        return Object.keys(this.exerciseTemplate.mappedTemplateParams((val) => val,user));
    }

    formValues(user) {
        return _.parseObjForForm(this.extract(this.formValueKeys(user)));
    }

    setKey(user) {
        return `${this.id}-${Object.keys(this.formValues(user)).join('-')}`
    }

    submitValues(user) {
        const keys = this.exerciseTemplate.getSetTemplateParams(user);

        return _.parseObjForForm({ 
            ...this.extract(keys), 
            id: this.id,
            ...this.exerciseTemplate.getActionParams() 
        });
    }

    targetPace(setVal) {
        if(setVal !== undefined) {
            this.targetRpe = setVal;
        } else {
            return this.targetRpe;
        }
    }

    reps(setVal) {
        if(setVal !== undefined) {
            if(_.isBlank(setVal)) {
                this.minReps = null;
                this.maxReps = null;
            } else {
                const parts = setVal.split('-');
                this.minReps = _.isNumeric(parts[0]) ? Number(parts[0]) : null;
                this.maxReps = _.isNumeric(parts[1]) ? Number(parts[1]) : null;
            }
        } else {
            let str = `${this.minReps}`;
            if(!_.isBlank(this.maxReps)) {
                str = `${str}-${this.maxReps}`
            }
            return str;
        }
    }

    isoTime(setVal) {
        if(setVal !== undefined) {
            if(_.isBlank(setVal)) {
                this.minReps = null;
                this.maxReps = null;
            } else {
                const vals = _.filter(setVal.split('-'),val => !_.isBlank(val))
                vals.forEach((val,index) => {
                    const attr = index > 0 ? 'maxReps' : 'minReps';
                    this[attr] = _.parseStopwatchFormat(val);
                })
                if(vals.length <= 1) {
                    this.maxReps = null;
                }
                if(vals.length <= 0) {
                    this.minReps = null;
                }
            }
        } else {
            if(_.isBlank(this.minReps)) {
                return '';
            } else {
                let str = `${_.stopwatchFormat(this.minReps)}`;
                if(!_.isBlank(this.maxReps) && this.maxReps > 0) {
                    str = `${str}-${_.stopwatchFormat(this.maxReps)}`;
                }
                return str;
            }
        }
    }

    minSecRestTime(setVal) {
        return this.timeSetter(setVal,'restTime');
    }

    validate(t) {
        const toValidate = this.exerciseTemplate.getSetTemplateParams();
        let errors = {};
        toValidate.forEach(attr => {
            switch(attr) {
                case 'reps': {
                    if(_.isBlank(this.minReps) || this.minReps <= 0) {
                        errors.reps = true;
                    }
                    break;
                }
                case 'count':
                case 'restTime':
                case 'time':
                case 'distance': {
                    if(_.isBlank(this[attr]) || this[attr] < 0 || this[attr] % 1 > 0) {
                        errors[attr] = true;
                    }
                    break;
                }
                case 'repMaxPercent': {
                    if(_.isBlank(this.repMaxPercent) || this.repMaxPercent < 0 || this.repMaxPercent > 200) {
                        errors.repMaxPercent = true;
                    }
                    break;
                }
                default: {
                    break;
                }
            }
        })
        return errors;
    }

    isIsometric() {
        return this.exerciseTemplate.isIsometric();
    }

    hasBaseWeight() {
        return this.exerciseTemplate.hasBaseWeight();
    }
}

registerInflection('setTemplate','setTemplates',SetTemplate);