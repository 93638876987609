import React from 'react';
import { connect } from 'react-redux';
import { trainerRecordSelector, userSelector } from 'redux/selectors';
import { switchToTrainer } from 'redux/actions';
import { Loader } from 'components/LoadingHOC';
import * as _ from 'lib/utilities';
import { RedirectContext } from 'components/RouteTransitionMap';

const withTrainer = (showBeforeLoad,type="page",tsel) => (Component) => {

    //TODO needs to handle case when already mounted and client gets swapped in
    //TODO: clientSwitch -> while in flight a trainer space breaking update called -> client switch returns, trainer swapped out -> trainer space call runs and marks breaking update (but updates/data will not be in sync with actual user data - meanwhile breaking update will indicate they are)
    class TrainerLoader extends React.Component {
        constructor(props) {
            super(props);
            this.preloaded = this.preloaded.bind(this);
        }

        render() {
            const { switchToTrainer, trainer, user, ...rest } = this.props;
            const disableRedirects = this.context;

            if(disableRedirects && !this.preloaded()) {
                return <div></div>;
            }

            return (
                <Loader
                    key={user ? user.id : trainer.id}
                    successComponent={Component}
                    type={type}
                    load={switchToTrainer}
                    preloaded={this.preloaded}
                    trainer={trainer}
                    {...rest}
                />
            )
        }

        needsSwitch = () => {
            const { user, trainer } = this.props;
            return _.isBlank(user) || trainer.id !== user.id;
        }

        preloaded() {
            const { user, trainer } = this.props;
            
            return (user && user.id === trainer.id);
        }
    }

    TrainerLoader.contextType = RedirectContext;

    const mapStateToProps = (state) => ({
        trainer: tsel ? tsel(state) : trainerRecordSelector(state),
        user: userSelector(state)  
    })

    const mapDispatchToProps = dispatch => ({
        switchToTrainer: () => dispatch(switchToTrainer())
    });

    return connect(mapStateToProps,mapDispatchToProps)(TrainerLoader)
}

export default withTrainer;