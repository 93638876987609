import { FullFormModal, SubmitButtonFooter } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { DefaultSelect, FullInput, MultivalueCheckbox, formikInputProps } from 'components/Form';
import TextInput, { TextArea } from 'components/TextInput';
import { connect } from 'react-redux';
import { createTrainerAssessment, initFormFieldDataEntry, updateTrainerAssessment } from 'redux/actions';
import Button from 'components/Button';
import { useLocation } from 'react-router-dom';
import { Assessment, AssessmentResult } from 'lib/classes';
import { FlexIconTip } from 'components/Tooltipped';
import { VideoUploadFields } from './Utilities';
import MuxUploadForm from './MuxUploadForm';
import { ExerciseVideo } from './ExerciseImage';
import * as Yup from 'yup';

export const takeAssessmentModalSuffix = `/assessment/:id/take`;

export const TakeAssessmentBody = React.forwardRef(({ assessmentResult, t, formikProps, name, readOnly },ref) => {
    const { source } = assessmentResult;
    const { values: { video } } = formikProps;

    return (
        <div className="pb20">
            {_.isBlank(video) && !assessmentResult.hasMuxVideo() && (
                <React.Fragment>
                    <div className="ex-vid-container pt5 pb5">
                        <ExerciseVideo exercise={source} videoOnly />
                    </div>
                    <div className="text-center">
                        <div className="left-align-block vid-width-cont pa10">
                            <div><b>{t('Instructions')}</b></div>
                            <div className="exercise-instructions font-grey">
                                {assessmentResult.isFormCheck() && (<p>{t('form check instructions')}</p>)}
                                {source.instructionEntries(t).map((entry,index) => (<p key={index}>{entry}</p>))}
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            )}
            <div className="ex-vid-container pt5 pb5">
                <VideoUploadFields 
                    record={assessmentResult} 
                    t={t} 
                    allowYT={false} 
                    videoFileRef={ref} 
                    formikProps={formikProps} 
                    name={name} 
                    fixedAspect
                    readOnly={readOnly}
                    primaryBtn={_.isBlank(video)}
                />
            </div>
        </div>
    )
})

let TakeAssessmentModal = ({ form, submit, validateForm, readOnly }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth
            path={modalPathFor(takeAssessmentModalSuffix,pathname)}
            scrollable
            exact
            icon={"universal-access"}
            preProcessor={({ modalProps }) => ({ formField: form.getFieldForModal(modalProps) })}
            title={({ formField }) => formField.defaultAssessmentResult().assessmentName(t)}
            FormikComp={MuxUploadForm}
            formProps={({ formField }) => {
                return {
                    initialValues: { clientId: (form.user ? form.user.id : null), formId: form.id, id: formField.id, video: '' },
                    submit,
                    validationSchema: Yup.object().shape({ video: Yup.string().required() }),
                    initialErrors: { video: 'required' },
                    recordType: formField.dataEntryType,
                    afterUploadStart: () => validateForm()
                }
            }}
            footerProps={readOnly ? { render: ({ close }) => (<Button onClick={close} primary rounded>{t('Ok')}</Button>)} : null}
            render={({ modalProps, videoFileRef, formField, ...rest }) => {
                return (
                    <TakeAssessmentBody 
                        ref={videoFileRef}
                        t={t}
                        name="video"
                        assessment={formField.assessment}
                        assessmentResult={formField.dataEntry || (new AssessmentResult({ formField, source: (formField.assessment || formField.exercise)}))}
                        formikProps={rest}
                        readOnly={readOnly}
                    />
                )
            }}
        />
    )
}

const mapDispatchToTakeProps = dispatch => ({
    submit: data => dispatch(initFormFieldDataEntry(data))
})

TakeAssessmentModal = connect(null,mapDispatchToTakeProps)(TakeAssessmentModal);

const AssessmentModal = ({ path, submit, initialVals, title, icon, viewOnly, trainer }) => {
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth
            path={path}
            scrollable
            exact
            icon={icon}
            title={title}
            recordType={'Assessment'}
            FormikComp={MuxUploadForm}
            formProps={({ modalProps }) => {
                const vals = initialVals(modalProps);
                return {
                    initialValues: vals,
                    submit
                }
            }}
            footerProps={viewOnly ? { render: () => (<SubmitButtonFooter><Button color="primary" className="modal-close" rounded>{t('Ok')}</Button></SubmitButtonFooter>)} : null}
            render={({ modalProps, videoFileRef, ...rest }) => {
                const { values, handleBlur, handleChange } = rest;
                const inputProps = _.pick(rest,formikInputProps);
                const assessment = trainer.getTrainerRecordForModal(modalProps,'Assessment');

                return (
                    <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <div className="valign-wrapper">
                            <FullInput
                                className="flex-grow"
                                name="name"
                                label={t('Name')}
                                component={TextInput}
                                formikProps={rest}
                                onlyErrorText
                                inProps={viewOnly ? { disabled: true } : {}}
                            />
                            {trainer.showTeamFeatures() && (<MultivalueCheckbox 
                                name="teamLevel"
                                id="team-level-check"
                                checkedVal={true}
                                uncheckedVal={false}
                                values={rest.values}
                                setFieldValue={rest.setFieldValue}
                                label={t('Team level?')} 
                                filled 
                                className="no-wrap ml10"
                                tooltip={t('team level tip')}
                            />)}
                        </div>
                        <div className="valign-wrapper">
                            <DefaultSelect
                                className="mr15"
                                name="resultType" 
                                label={t('Result type')}
                                collection={Assessment.resultTypes(t)} 
                                {...rest} 
                            />
                            {values.resultType === 'numeric' && (
                                <React.Fragment>
                                    <FullInput
                                        name="resultUnits"
                                        label={`${t('Units')} (${t('Optional')})`}
                                        component={TextInput}
                                        formikProps={rest}
                                        onlyErrorText
                                        inProps={viewOnly ? { disabled: true } : {}}
                                    />
                                    <FlexIconTip icon="question-circle" msg={t('result units tip')} size="1x" className="mr15" />
                                </React.Fragment>
                            )}
                            <MultivalueCheckbox 
                                name="bilateral"
                                id="bilateral-check"
                                checkedVal={true}
                                uncheckedVal={false}
                                values={rest.values}
                                setFieldValue={rest.setFieldValue}
                                label={t('Bilateral?')} 
                                filled 
                                className="no-wrap pt20"
                                tooltip={t('bilateral tip')}
                            />
                        </div>
                        <div className="mt15">
                            <div className="font-grey no-wrap mb5">
                                {t('assessment video title')} ({t('Optional')})
                                <FlexIconTip icon="question-circle" msg={t('assessment video tip')} size="1x" className="mr15" />
                            </div>
                            <VideoUploadFields record={assessment} inputProps={inputProps} t={t} videoFileRef={videoFileRef} />
                        </div>
                        <div className="mt15">
                            <div className="font-grey no-wrap mb5">
                                {t('Client text instructions')} ({t('Optional')})
                            </div>
                            <TextArea
                                placeholder={t('assessment cli instructions')}
                                name={"instructions"} 
                                value={_.get(values,'instructions')} 
                                onChange={handleChange} 
                                onBlur={handleBlur} 
                                height={'75px'}
                            />
                        </div>
                    </div>
                )

            }}
        />
    )
}

export const newAssessmentModalSuffix = '/new';

let NewAssessmentModal = ({ submit, trainer }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <AssessmentModal
            path={modalPathFor(newAssessmentModalSuffix,pathname)}
            icon="plus"
            title={t('New Assessment')}
            initialVals={() => ({ name: '', teamLevel: false, bilateral: false, videoUrl: '', resultType: 'free_text', resultUnits: '', instructions: '' })}
            trainer={trainer}
            submit={submit}
            assessment={null}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(createTrainerAssessment(data))
})

NewAssessmentModal = connect(null,mapDispatchToProps)(NewAssessmentModal);

export const editAssessmentModalSuffix = '/edit/:id';

let EditAssessmentModal = ({ submit, trainer }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(editAssessmentModalSuffix,pathname);

    return (
        <AssessmentModal
            path={path}
            icon={['far','edit']}
            title={t('Edit Assessment')}
            initialVals={(modalProps) => {
                const assessment = trainer.getTrainerRecordForModal(modalProps,'Assessment');
                return assessment.editValues();
            }}
            trainer={trainer}
            submit={submit}
        />
    )
}

const mapDispatchToEditProps = dispatch => ({
    submit: data => dispatch(updateTrainerAssessment(data))
})

EditAssessmentModal = connect(null,mapDispatchToEditProps)(EditAssessmentModal);

export { NewAssessmentModal, EditAssessmentModal, TakeAssessmentModal }