import React from 'react';
import { ModalFooter, SubmitButtonFooter, ScrollableModalContent } from 'components/Modal';
import { Formik, FieldArray } from 'formik';
import * as Yup from 'yup';
import { useTranslation, withTranslation } from 'react-i18next';
import { allMacrosWithCals, allMacros, coreMacros, caloriesFromMacros } from 'lib/classes';
import { roundToF, isNumeric, formikHandlerCreator } from 'lib/utilities';
import TextInput, { NumberInput, SmallTextInput } from 'components/TextInput';
import Button, { IconButton } from 'components/Button';
import { Food } from 'lib/classes';
import * as _ from 'lib/utilities';
import { LowOpacityText } from 'components/Typography';
import { ModalForm, InnerFormContainer, CollapsibleFormSection, FormSectionHeader, InputWithErrors, SubmitButton, formikInputProps } from 'components/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tooltipped from 'components/Tooltipped';
import Toast from 'components/Toast';
import loadingContainer from 'components/LoadingHOC';
import { dateFormat } from 'config/settings';
import { baseActiveMacroParamsSelector, tooltipSeenCreator, trainerSelector } from 'redux/selectors';
import { dismissTooltip } from 'redux/actions';
import { connect } from 'react-redux';

const normalizeInitValues = (values) => {

    for(let foodWeight of values.foodWeights) {
        if(!_.isBlank(foodWeight.grams) && !_.isBlank(foodWeight.amount)) {
            foodWeight.grams = foodWeight.amount*foodWeight.grams;
        }
    }

    if(values.foodWeights[0].grams) {
        const mult = Number(values.foodWeights[0].grams)/100.0;

        for(let macro of allMacrosWithCals) {
            if(values[macro]) {
                let val = Number(values[macro])
                values[macro] = macro === 'calories' ? Math.ceil(val*mult) : roundToF(val*mult,0.1);
            }
        }
    }

    if(values.carbs && values.fiber) {
        values.carbs = Number(values.carbs) - Number(values.fiber);
    }

    return values;
}

const normalizeSubmitValues = (values) => {
    const newFoodWeights = values.foodWeights.map(foodWeight => ({ ...foodWeight }));
    const newValues = { ...values, foodWeights: newFoodWeights };
    if(values.foodWeights[0].grams) {
        const mult = 100.0/values.foodWeights[0].grams;

        for(let macro of allMacrosWithCals) {
            if(values[macro]) {
                newValues[macro] = macro === 'calories' ? Math.ceil(values[macro]*mult) : roundToF(values[macro]*mult,0.1);
            }
        }
    }

    for(let foodWeight of newValues.foodWeights) {
        if(!_.isBlank(foodWeight.grams) && !_.isBlank(foodWeight.amount)) {
            foodWeight.grams = foodWeight.grams/foodWeight.amount;
        }
    }
    
    if(!_.isBlank(newValues.fiber)) {
        const carbAmt = _.isBlank(newValues.carbs) ? 0 : Number(newValues.carbs);
        newValues.carbs  = carbAmt + Number(newValues.fiber);
    }
    return newValues
}

const changeHandlerCreator = (values,setValues,callback,showToast) => (e) => {
    const macro = e.target.name;
    const newVal = e.target.value;
    const newValues = { ...values, [macro]: newVal };
    if(disableCalories(newValues) || adjustCalories(newValues)) {
        newValues.calories = caloriesFromMacros(_.pick(newValues,allMacros));
        setValues( newValues );
        if(showToast) {
            showToast();
        }
    } else if(callback) {
        callback(e);
    } else {
        setValues( newValues );
    }
    
}

const servingName = (foodWeight,defaultName,forEaten=false) => {
    if(!foodWeight.measurement || foodWeight.measurement === '') {
        return defaultName;
    } else {
        if(foodWeight.amount && !forEaten) {
            return foodWeight.amount + ' ' + foodWeight.measurement;
        } else {
            if(forEaten) {
                return _.capitalize(foodWeight.measurement);
            } else {
                return foodWeight.measurement;
            }
        }
    }
}

const disableCalories = (values) => {
    return (_.every(coreMacros,(macro) => (values[macro] && isNumeric(values[macro]))))
}

const adjustCalories = (values) => {
    return (isNumeric(values.calories) && caloriesFromMacros(_.pick(values,allMacros)) > values.calories);
}

const MacroInputField = (props) => {
    const { inputProps, helperText, ...rest } = props;
    return (
        <NumberInput
            className="inline"
            helperText={helperText && (<LowOpacityText component='i'>{helperText}</LowOpacityText>)}
            inputProps={inputProps}
            {...rest}
        />
    )
}

export const FoodWeightFieldsRow = ({ baseName, ...rest }) => {
    const { t } = useTranslation();
    let labels = {};
    if(_.get(rest.values,`${baseName}amount`) > 1) {
        labels.amount = _.capitalize(t('quantity'))
    }
    labels = { ...labels,
        measurement: _.capitalize(t('unit')), 
        grams:_.capitalize(t('Weight(g)'))
    };

    let fieldsArr = [];
    for(let [attr,label] of Object.entries(labels)) {
        const component = attr === 'measurement' ? SmallTextInput : NumberInput;
        fieldsArr.push(
            (
                <InputWithErrors
                    key={`${attr}`}
                    label={label}
                    className="inline"
                    style={{maxWidth: '80px'}}
                    component={component}
                    name={`${baseName}${attr}`}
                    {...rest}
                />
            )
        )
    }

    return (
        <React.Fragment>
            {fieldsArr}
        </React.Fragment>
    )

}

const FoodWeightRow = ({ foodWeight, index, remove, setFieldValue, noBaseName, ...rest }) => {
    const baseName = `foodWeights.${index}.`;
    
    return (
        <div>
            <FoodWeightFieldsRow baseName={baseName} {...rest} />
            { index > 0 && (<IconButton icon="times" size="2x" className="ml15" onClick={() => { foodWeight.id ? setFieldValue(`${baseName}inactive`,true) : remove(index) }} />)}
        </div>
    )
}

const FoodWeightFields = ({ foodWeights, t, ...rest }) => {

    return (
        <FieldArray name="foodWeights" render={(arrayHelpers) => {
            return (
                <React.Fragment>
                    {foodWeights.map((foodWeight,index) => (
                        !foodWeight.inactive && 
                        <FoodWeightRow 
                            key={index}
                            foodWeight={foodWeight} 
                            index={index} 
                            remove={arrayHelpers.remove}
                            {...rest}
                        />
                    ))}
                    <div className="text-center mb20">
                        <Button rounded outlined onClick={() => arrayHelpers.push({amount: '', measurement: '', grams: '', isDefault: false, seq: foodWeights.length+1 })}>
                                <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("Add Unit")}
                        </Button>
                    </div>
                </React.Fragment>
            );
        }} />
    )
}

const DefaultSubmit = ({ label }) => {
    return (
        <SubmitButton id="submit-food-form-btn" icon="check" label={label} />
    )
}

const SubmitSuccess = ({ label, t, close }) => {
    if(close) {
        close();
        return '';
    }

    return (
        <React.Fragment>
            <div className="success-color" id="new-food-success-msg"><FontAwesomeIcon icon="check"></FontAwesomeIcon>{t('logged')}</div>
            <SubmitButton icon="check" id="submit-food-form-btn" label={label} />
        </React.Fragment>
    )
}

const SubmitLOC = loadingContainer({
    "DEFAULT": DefaultSubmit,
    "SUCCESS": SubmitSuccess
})

const makeStatusHandler = (setState,triggerRequestRating,resetQuery) => (status,formikBag,data,values) => {
    if(status === 'SUCCESS') {
        formikBag.resetForm();
        triggerRequestRating && triggerRequestRating();
        if(resetQuery) {
            //need timeout for index to update
            setTimeout(() => resetQuery(values.commonName),400);
        }
    }
    setState({ submitState: status });
}

class FoodForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = { tooltipState: props.tooltipSeen ? 2 : 0, submitState: 'DEFAULT' };

    }

    componentWillUnmount() {
        if(this.loadPromise) {
            this.loadPromise.cancel();
        }
    }

    render() {
        const { footerRef, servingsEaten, submit, macroParams, calcNonScrollDim, dismissTooltip, date, defaultFooter, t, triggerRequestRating, actionTitle, close, initialName, resetQuery, trainer } = this.props;
        const food = this.props.food ? this.props.food.formValues() : Food.newDefault(initialName);
        const servName = t('servings');
        const servEatenName = t('Servings');
        const foodWeights = this.props.food ? this.props.food.foodWeightFormValues() : [{amount: 1, measurement: servName, grams: 100.0, isDefault: true, seq: 1 }];
        const showToast = this.state.tooltipState === 0 ? () => this.setState({ tooltipState: 1}) : null;
        const macroValidations = {};

        if(!_.isBlank(trainer)) {
            macroParams.setOverrideRequiredMacros([ ...coreMacros ]);
        }

        for(let macro of allMacros) {
            macroValidations[macro] = Yup.number().min(0);
        }
        macroParams.markValidations(macroValidations);


        return (
            <React.Fragment>
                <Formik initialValues={ normalizeInitValues({ ...food, foodWeights, servingsEaten, date: date && date.format(dateFormat) }) }
                        onSubmit={formikHandlerCreator(this,makeStatusHandler(this.setState.bind(this),triggerRequestRating,resetQuery),submit,normalizeSubmitValues)}
                        validationSchema={Yup.object().shape({
                            commonName: Yup.string().required(),
                            calories: Yup.number().moreThan(0).required(),
                            servingsEaten: servingsEaten ? Yup.number().moreThan(0).required() : null,
                            ...macroValidations,
                            foodWeights: Yup.array().of(Yup.object().shape({
                                amount: Yup.number().moreThan(0),
                                grams: Yup.number().moreThan(0),
                                measurement: Yup.string()
                            }))
                        })}
                        initialErrors={{}} >
                        {({ handleBlur, handleChange, handleSubmit, values, touched, errors, setValues, setFieldValue }) => {
                            const perServing = t('per serving',{ serving: servingName(values.foodWeights[0], servName) });
                            const thingsEaten = t('Things Eaten', { thing: servingName(values.foodWeights[0], servEatenName, true) })
                            const formikProps = { handleBlur, handleChange, values, touched, errors, setFieldValue };
                            const inputProps = _.pick(formikProps,formikInputProps)
                            const changeHandler = changeHandlerCreator(values,setValues,handleBlur,showToast);
                            let calorieField = (<InputWithErrors
                                key="calories-field"
                                label={t("Calories")}
                                helperText={perServing}
                                name="calories"
                                inProps={ {
                                    disabled: disableCalories(values)
                                } }
                                component={MacroInputField}
                                {...inputProps}
                                handleBlur={changeHandler}
                            />);

                            if(disableCalories(values)) {
                                calorieField = (
                                <Tooltipped 
                                    options={{ 
                                        html: t('calorie tooltip'),
                                        classes: ['square']
                                }}
                                    className="inline-block"
                                >
                                    {calorieField}
                                </Tooltipped>);
                            }

                            return (
                                <ModalForm centered onSubmit={handleSubmit}>
                                    <ScrollableModalContent calcNonScrollDim={calcNonScrollDim}>
                                        <InnerFormContainer>
                                        
                                            <div key="name-row">
                                                <InputWithErrors
                                                    label={t("Name")}
                                                    className="inline"
                                                    name="commonName"
                                                    id="commonNameField"
                                                    component={TextInput}
                                                    {...inputProps}
                                                />
                                                {servingsEaten && <InputWithErrors 
                                                    label={thingsEaten}
                                                    className="inline"
                                                    name="servingsEaten"
                                                    component={NumberInput}
                                                    {...inputProps}
                                                />}
                                                {!servingsEaten && calorieField}
                                            </div>
                                            <div key="macro-row">
                                                {servingsEaten && calorieField}
                                                { macroParams.requiredMacros().map((macro) => (
                                                    <InputWithErrors
                                                        key={macro+"-field"}
                                                        label={macro === 'carbs' ? t('Carbs(g)(net)') : _.capitalize(t(`${macro} g`))}
                                                        helperText={perServing}
                                                        name={macro}
                                                        component={MacroInputField}
                                                        {...inputProps}
                                                        handleBlur={changeHandler}
                                                    />
                                                )) }
                                            </div>
                                            <CollapsibleFormSection header={t("Optional")}>
                                                { macroParams.optionalMacros().map((macro) => {
                                                    if(macro === 'alcohol' && (!values.alcohol || values.alcohol === '')) {
                                                        return null;
                                                    } else {
                                                        return (
                                                            <InputWithErrors
                                                                key={macro+"-field"}
                                                                label={macro === 'carbs' ? t('Carbs(g)(net)') : _.capitalize(t(`${macro} g`))}
                                                                helperText={perServing}
                                                                name={macro}
                                                                component={MacroInputField}
                                                                {...inputProps}
                                                                handleBlur={changeHandler}
                                                            />
                                                        )
                                                    }
                                                })}
                                                <div className="slight-emphasis-bcg pa10">
                                                    <FormSectionHeader divider header={t("Serving Info")}/>
                                                    <FoodWeightFields 
                                                        foodWeights={values.foodWeights} 
                                                        {...formikProps} 
                                                        t={t} 
                                                    />
                                                </div>
                                                
                                            </CollapsibleFormSection>
                                        
                                        </InnerFormContainer>
                                    </ScrollableModalContent>
                                    <ModalFooter ref={footerRef} scrollable defaults={defaultFooter}>
                                        <SubmitButtonFooter>
                                            <SubmitLOC loadState={this.state.submitState} label={actionTitle} t={t} close={close} />
                                        </SubmitButtonFooter>
                                    </ModalFooter>
                                </ModalForm>
                            )
                        }}
                </Formik>
                {this.state.tooltipState === 1 && (
                    <Toast 
                        message={t('calorie update title')} 
                        collapseContent={t('calorie update info')} 
                        completeCallback={() => this.setState({tooltipState: 2})} 
                        okCallback={dismissTooltip}
                        moreCallback={dismissTooltip}
                        moreText={t('More')}
                        dismissText={t('Ok')}
                        displayLength={30000}
                        id="calorie-update-toast"
                    />
                )}
            </React.Fragment>
        )
    }
}

const mapNewStateToProps = state => ({
    macroParams: baseActiveMacroParamsSelector(state),
    tooltipSeen: tooltipSeenCreator('calories_macros_update')(state),
    trainer: trainerSelector(state)
});

const mapNewDispatchToProps = (dispatch) => ({
    dismissTooltip: () => dispatch(dismissTooltip('calories_macros_update'))
})

export default connect(mapNewStateToProps,mapNewDispatchToProps)(withTranslation()(FoodForm));