
import React from 'react';
import { useTranslation } from 'react-i18next';
import FormikWrapper from 'components/FormikWrapper';
import { CardForm, DefaultSelect } from 'components/Form';
import { User } from 'lib/classes';
import { Loader } from 'components/LoadingHOC';
import Card, { BasicCardHeader } from 'components/Card';
import * as _ from 'lib/utilities';
import { SlightEmphasisTip } from './Utilities';
import { WeightChartCard, Chart, NoDataMessage, SmallNoDataMessage } from './ProgressCharts';
import { macroColors } from 'config/settings';
import MacroPieChart from './MacroPieChart';
import ActivityContainer from 'components/ActivityContainer';

const TdeeDatum = ({ upperTitle, lowerTitle, value, className, id }) => {
    const { t } = useTranslation();
    return (
        <div className="flex-grow mt10 mb10">
            <div className="faint-color">
                {upperTitle}
            </div>
            {!_.isBlank(value) && (
                <div id={id ? id : ''}><b className={className} style={{ fontSize: '18px'}}>{value}</b></div>
            )}
            {_.isBlank(value) && (
                <SmallNoDataMessage t={t} id={`${id}-no-data`} />
            )}
            <div className="faint-color">
                {lowerTitle}
            </div>
        </div>
    )
}

const TdeeData = ({ tdeeData, user }) => {
    const { t } = useTranslation();
    const { tdee, tdeeOffset, tdeeGain1, tdeeGain2, tdeeLose1, tdeeLose2 } = tdeeData;
    const lowerTitle = `${t('cals')}/${t('day')}`;
    const metric = user.isMetric();
    const units = metric ? t('kgs') : t('lbs');

    return (
        <div className="row text-center mb0">
            <div className="col s12 m12 l4">
                <Card className="pa10">
                    <BasicCardHeader>{t('Historical')}</BasicCardHeader>
                    <div className="row">
                        <div className="col s12 m12 l12">
                            <div className="valign-wrapper">
                                <TdeeDatum 
                                    upperTitle={t('TDEE')}
                                    value={tdee}
                                    lowerTitle={lowerTitle}
                                    className="success-color"
                                    id="tdee-datum"
                                />
                                <TdeeDatum 
                                    upperTitle={t('avg daily surplus')}
                                    value={tdeeOffset}
                                    lowerTitle={lowerTitle}
                                    className={(tdeeOffset && tdeeOffset < 0) ? 'red-text' : 'success-color'}
                                    id="surplus-datum"
                                />
                            </div>
                        </div>
                    </div>
                </Card>                
            </div>
            <div className="col s12 m12 l8">
                <Card className="pa10">
                    <BasicCardHeader>{t('Projections')}</BasicCardHeader>
                    <div className="row">
                        <div className="col s12 m6 l6">
                            <div className="valign-wrapper">
                                <TdeeDatum 
                                    upperTitle={t('gain weight/month', { weight: (metric ? `1 ${units}` : `2 ${units}`) })}
                                    value={tdeeGain1}
                                    lowerTitle={lowerTitle}
                                    className="success-color"
                                    id="gain1-datum"
                                />
                                <TdeeDatum 
                                    upperTitle={t('lose weight/month', { weight: (metric ? `2 ${units}` : `4 ${units}`) })}
                                    value={tdeeLose1}
                                    lowerTitle={lowerTitle}
                                    className="red-text"
                                    id="lose1-datum"
                                />
                            </div>
                        </div>
                        <div className="col s12 m6 l6">
                            <div className="valign-wrapper">
                                <TdeeDatum 
                                    upperTitle={t('gain weight/month', { weight: (metric ? `2 ${units}` : `4 ${units}`) })}
                                    value={tdeeGain2}
                                    lowerTitle={lowerTitle}
                                    className="success-color"
                                    id="gain2-datum"
                                />
                                <TdeeDatum 
                                    upperTitle={t('lose weight/month', { weight: (metric ? `4 ${units}` : `8 ${units}`) })}
                                    value={tdeeLose2}
                                    lowerTitle={lowerTitle}
                                    className="red-text"
                                    id="lose2-datum"
                                />
                            </div>
                        </div>
                    </div>
                </Card>          
            </div>
        </div>
    )
}

export const LinearMacroChart = ({ linearMacroData }) => {
    const { t } = useTranslation();

    return (
        <Card className="pa10">
            <BasicCardHeader>{t('Macro History')}</BasicCardHeader>
            <Chart 
                id="macro-line-chart"
                data={linearMacroData} 
                yAxesOpts={{ stacked: true }}
                chartOpts={ {
                    legend: {
                        position: 'bottom',
                        labels: {
                            boxWidth: 10
                        }
                    }
                }}
                datasetOpts={Object.values(macroColors).map(color => ({ fill: true, borderColor: color, backgroundColor: `${color}`}))} 
            />
        </Card>
    )
}

const MacroCharts = ({ user, responseData: { tdeeData, linearMacroData, pieChartMacroData, weight }}) => {
    const { t } = useTranslation();

    return (
        <div className="row" id="macro-charts">
            <div className="col s12 m12 l12">
                <div className="mb10">
                    <SlightEmphasisTip text={t('macro analytics frequency tip')} tipName='macro_analytics_freq_tip' />
                </div>
                <TdeeData tdeeData={tdeeData} user={user} />
            </div>
            <div className="col s12 l4">
                <LinearMacroChart linearMacroData={linearMacroData} />
            </div>
            <div className="col s12 l4">
                <Card className="pa10">
                    <BasicCardHeader className="pb0">
                        {t('Avg Daily Macros')}
                    </BasicCardHeader>
                    {pieChartMacroData && <MacroPieChart macros={pieChartMacroData} titleFontSize={12} percentFontSize={10} maxWidth={'250px'} />}
                    {!pieChartMacroData && (<NoDataMessage id="macro-pie-chart-no-data" />)}
                </Card>
            </div>
            <div className="col s12 l4">
                <WeightChartCard weight={weight} />
            </div>
        </div>
    )
}

const MacroAnalytics = ({ progressChartFormData, updateMacroAnalytics, updateProgressChartsLocal, user }) => {
    const { t } = useTranslation();
    const { analyticsLookback: lookbackDays } = progressChartFormData;

    return (
        <ActivityContainer>
                <div className="col s12 m12 l12">
                    <FormikWrapper 
                        initialValues={ _.pick(progressChartFormData,'analyticsLookback') }
                        submit={updateProgressChartsLocal}
                        initialErrors={{}}
                        autoSubmit
                        render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                            return (
                                <CardForm onSubmit={handleSubmit} className="text-center">
                                    <DefaultSelect
                                        className="inline-block"
                                        label={t('Time period')}
                                        name="analyticsLookback"
                                        collection={User.progressPeriodsCol(t,true)}
                                        {...rest}
                                        handleChange={handleAutoSubmitChange}
                                    />
                                    
                                </CardForm>
                            )
                        }}
                    />
                    <Loader 
                        successComponent={MacroCharts}
                        user={user}
                        type="padded"
                        load={updateMacroAnalytics.bind(null,lookbackDays)}
                        preloaded={() => false}
                        key={lookbackDays}
                    />
                </div>
        </ActivityContainer>
    )
}

export default MacroAnalytics;