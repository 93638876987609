import React from 'react';
import { FullFormModal } from 'components/Modal';
import { editTrainerFormPath, modalPathFor, viewClientAssignedFormPathFor } from 'config/paths';
import { Trans, useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { DefaultSelect, FullInput, MultivalueCheckbox, fakeFormikProps } from 'components/Form';
import TextInput, { TextArea } from 'components/TextInput';
import { connect } from 'react-redux';
import { createTrainerForm, deleteTrainerForm, loadClient, loadFormFieldHistory, setDefaultFormScheduling } from 'redux/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { FormField, SchedulableSetting } from 'lib/classes';
import { SlightEmphasisTip } from './Utilities';
import * as Yup from 'yup';
import { FilterButtonWrapper, RecordTags, SharedSchedulingFields } from './RecordUtilities';
import { FORM_DEFAULTS_TIP } from 'config/tooltips';
import { Form } from 'lib/classes';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SlightEmphasisIconNote } from 'components/Typography';
import { Link } from 'components/Routing';
import classnames from 'classnames';
import { DropdownDelete, DropdownItem, MultiLevelDropdown } from 'components/Dropdown';
import { AssessmentResultView, FillableFormField } from './TrainerForm';
import moment from 'moment';
import { Chart } from './ProgressCharts';

export const viewFormFieldHistorySuffix = '/history/:ffId';

const numericChartData = (formFields) => {
    if(_.uniq(_.filter(formFields,ff => !_.isBlank(ff.numericValue)).map(ff => ff.fieldDate)).length > 1) {
        let data = [['Date','Value']];
        const ffs = _.sortBy(formFields, ff => ff.fieldDate);
        ffs.forEach(ff => data.push([ff.fieldDate,ff.numericValue]));
        return data;
    }

    return null;
}

let FormFieldHistoryModal = ({ viewer, form, loadHistory }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <FullFormModal
            fullWidth
            path={modalPathFor(viewFormFieldHistorySuffix,pathname)}
            scrollable
            exact
            icon={"history"}
            preProcessor={({ modalProps: { match: { params: { ffId }}} }) => ({ formField: form.fieldById(ffId) })}
            loadForm={({ formField }) => loadHistory({ clientId: form.user.id, formId: form.id, id: formField.id })}
            title={({ formField }) => formField.getPrompt(t)}
            formProps={({ formField }) => {
                return {
                    initialValues: {},
                    submit: () => Promise.resolve({ status: 'SUCCESS'})
                }
            }}
            footerProps={{ noCancel: true, saveLabel: t('Ok') }}
            render={({ modalProps, formField, loaderProps: { responseData: { formFields, assessments, exercises, assessmentResults } }, ...rest }) => {
                const fields = FormField.where(ff => true,{ formFields, assessments, assessmentResults, exercises });

                if(fields.length === 0) {
                    return <SlightEmphasisIconNote text={t('No history to show')} />
                } else if(formField.historyIsChart()) {
                    return (
                        <div className="pa20">
                            <Chart 
                                data={numericChartData(fields)} 
                                datasetOpts={[{ borderColor: '#8BC34A', backgroundColor: '#8BC34A66' }]} 
                                id={`ff-${formField.id}-chart`}
                            />
                        </div>
                    );
                } else if(['form_check','assessment'].includes(formField.fieldType)) {
                    return (
                        <div className="pa20">
                            {fields.map(ff => {

                                if(ff.dataEntry && ff.dataEntry.hasMuxVideo()) {

                                    return (
                                        <div className="hist-entry">
                                            <AssessmentResultView 
                                                formField={ff}
                                                viewer={viewer}
                                            />
                                        </div>
                                    )
                                }

                                return '';
                            })}
                        </div>
                    )
                }

                return (
                    <div className="pa20">
                        {fields.map(ff => {
                            
                            return (
                                <div className="hist-entry">
                                    <FillableFormField 
                                        fieldIndex={0}
                                        formField={ff}
                                        isEditable={false}
                                        isFillable={false}
                                        formikProps={{ ...fakeFormikProps([]), values: { formFields: [ff.fillableValues(viewer)] }}}
                                        viewType={'trainer'}
                                        overrideLabel={moment(ff.fieldDate).format('MMM Do YYYY')}
                                    />
                                </div>
                            )
                        })}
                    </div>
                )
            }}
        />
    )
}

const mapDispatchToHistoryProps = dispatch => ({
    loadHistory: (data) => dispatch(loadFormFieldHistory(data))
})

FormFieldHistoryModal = connect(null,mapDispatchToHistoryProps)(FormFieldHistoryModal);

export const viewAllFormsModalSuffix = '/:clientId/all_forms';

const ClientFormCard = ({ form, client, t, isModal, closeAndGo, history, destroy }) => {
    const status = form.filterStatus();
    const viewFn = () => {
        const path = viewClientAssignedFormPathFor(client.id,form.id);
        if(isModal) {
            closeAndGo(path);
        } else {
            history.push(path);
        }
    }

    return (
        <ListCard className={classnames("mb5 cli-form-entry", { "no-shadow": isModal })} id={`form-entry-${form.id}`}>
            <ListCardAction className="inactive">
                <FontAwesomeIcon icon={form.cardIcon()} />
            </ListCardAction>
            <ListCardTitle withTags onClick={viewFn}>
                <RecordTags listCard tagList={[status]} />
                {form.resolvedName(t)}
                <div className="tiny-text faint-color">
                    {form.assignedDateStr()}
                </div>
            </ListCardTitle>
            <MultiLevelDropdown
                fixed={isModal}
                options={ { closeOnClick: true, closeOnChildClick: true } }
                triggerRender={({ ref, target }) => {
                    return (
                        <ListCardAction className="no-border" ref={ref} data-target={target} id={`form-${form.id}-menu-btn`}>
                            <FontAwesomeIcon icon='ellipsis-v'></FontAwesomeIcon>
                        </ListCardAction>
                    )
                }}
            >
                <DropdownItem onClick={viewFn} label={t('View')} icon="eye" />
                <DropdownDelete id="delete-btn" action={() => destroy({ id: form.id, clientId: client.id })} label={t('Delete')} icon={"times"} />
            </MultiLevelDropdown>
        </ListCard>
    )
}

let FormsList = ({ forms, client, t, isModal, closeAndGo, destroy }) => {
    const history = useHistory();
    const { pathname } = useLocation();
    const formLimit = isModal ? forms.length : 4;
    const hasMore = (!isModal && client.hasMoreAssignedForms(formLimit));
    const visibleForms = forms.slice(0,formLimit);

    if(visibleForms.length === 0) {
        return (
            <SlightEmphasisIconNote text={`${t('no forms for client')} ${isModal ? '' : t('click to assign')}`} />
        )
    }

    return (
        <React.Fragment>
            {visibleForms.map(f => (<ClientFormCard key={f.id} form={f} client={client} t={t} isModal={isModal} history={history} closeAndGo={closeAndGo} destroy={destroy} />))}
            {hasMore && (<Link to={modalPathFor(viewAllFormsModalSuffix,pathname, { clientId: client.id })} className="underline">{t('View all')}</Link>)}
        </React.Fragment>
    )
}

const mapDispatchToFormsListProps = dispatch => ({
    destroy: data => dispatch(deleteTrainerForm(data))
})

FormsList = connect(null,mapDispatchToFormsListProps)(FormsList)

export const FormsListWrapper = ({ client, t, render, isModal }) => {

    return (
        <FilterButtonWrapper
            filterKey={'trainerForms'}
            fixed={isModal}
            initialFilters={{ status: null }}
            className="ml5"
            options={{ status: { label: '', options: Form.formStatuses(t) } }}
            render={({ filterBtn, filters }) => {
                const forms = client.getClientFormsFor('trainer',filters);
                return render({ forms, filterBtn });
            }}
        />
    )
}

let ClientFormsModal = ({ trainer, loadForms, doLoad }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <FullFormModal
            fullWidth
            path={modalPathFor(viewAllFormsModalSuffix,pathname)}
            scrollable
            exact
            icon={"file-alt"}
            preProcessor={({ modalProps: { match: { params: { clientId }}} }) => ({ client: trainer.clientById(clientId) })}
            loadForm={doLoad ? ({ client }) => loadForms(client.id) : null}
            title={({ client }) => t("names forms", { name: client.shortName() })}
            formProps={({ client }) => {
                return {
                    initialValues: {},
                    submit: () => Promise.resolve({ status: 'SUCCESS'})
                }
            }}
            footerProps={{ noCancel: true, saveLabel: t('Ok') }}
            render={({ modalProps, client, ...rest }) => {
                return (
                    <FormsListWrapper 
                        client={client}
                        t={t}
                        isModal
                        render={({ forms, filterBtn }) => {
                            return (
                                <div className="pa20">
                                    <div className="mb10">{filterBtn}</div>
                                    <FormsList forms={forms} t={t} client={client} isModal closeAndGo={modalProps.closeAndGo} />
                                </div>
                            )
                        }}
                    />
                )
            }}
        />
    )
}

const mapDispatchToClientFormsProps = dispatch => ({
    loadForms: (id) => dispatch(loadClient(id))
})

ClientFormsModal = connect(null,mapDispatchToClientFormsProps)(ClientFormsModal);

export const BasicFormFields = ({ formikProps }) => {
    const { t } = useTranslation();

    return (
        <div>
            <div className="valign-wrapper flex-start">
                <FullInput
                    className="flex-grow"
                    onlyErrorText
                    name="shortName"
                    label={t('Name')}
                    component={TextInput}
                    formikProps={formikProps}
                />
                <DefaultSelect
                    name="formType" 
                    className="ml5"
                    label={t('Form Type')}
                    collection={Form.formTypes(t)} 
                    {...formikProps} 
                />
            </div>
            {formikProps.values.teamLevel !== undefined && (
                <div className="mb10">
                    <MultivalueCheckbox 
                        name="teamLevel"
                        id="team-level-check"
                        checkedVal={true}
                        uncheckedVal={false}
                        values={formikProps.values}
                        setFieldValue={formikProps.setFieldValue}
                        label={t('Team level?')} 
                        filled 
                        className="no-wrap"
                        tooltip={t('team level tip')}
                    />
                </div>
            )}
            <div className="text-left">
                <div className="font-grey">{t("Description")}</div>
                <TextArea
                    placeholder={`${t('Optional')} - ${t('trainer form desc tip')}`}
                    name="description" 
                    value={formikProps.values.description} 
                    onChange={formikProps.handleChange} 
                    onBlur={formikProps.handleBlur} 
                    stopModalDrag
                    height={'75px'}
                />
            </div>
            <div className="text-left">
                <div className="font-grey">{t("Client instructions")}</div>
                <TextArea
                    placeholder={`${t('Optional')} - ${t('trainer form instr tip')}`}
                    name="instructions" 
                    value={formikProps.values.instructions} 
                    onChange={formikProps.handleChange} 
                    onBlur={formikProps.handleBlur} 
                    stopModalDrag
                    height={'75px'}
                />
            </div>
        </div>
    )
}

export const newModalSuffix = '/new';

let NewFormModal = ({ submit, trainer }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth
            path={modalPathFor(newModalSuffix,pathname)}
            scrollable
            exact
            icon={"plus"}
            title={t("New")}
            formProps={({ modalProps }) => ({
                initialValues: Form.newDefault().basicInfoValues(trainer),
                validationSchema: Yup.object().shape({
                    shortName: Yup.string().required(),
                    formType: Yup.string().required()
                }),
                submit,
                initialErrors: {},
                successCallback: ({ formikBag, data, values }) => {
                    const id = Object.values(data.forms)[0].id;
                    modalProps.closeAndGo(editTrainerFormPath(id));
                }
            })}
            footerProps={{ saveIcon: 'arrow-right', saveLabel: 'Next', dontCloseOnDone: true }}
            render={({ modalProps, ...rest }) => {

                return (
                    <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <BasicFormFields formikProps={rest} />
                    </div>
                )

            }}
        />
    )
}

const mapDispatchToNewProps = dispatch => ({
    submit: data => dispatch(createTrainerForm(data))
})

NewFormModal = connect(null,mapDispatchToNewProps)(NewFormModal)

export const defaultFormScheduleSuffix = '/:id/default_schedule';

let DefaultFormScheduleModal = ({ submit, trainer }) => {
    const { pathname } = useLocation();
    const { t } = useTranslation();

    return (
        <FullFormModal
            fullWidth
            path={modalPathFor(defaultFormScheduleSuffix,pathname)}
            scrollable
            exact
            icon={"calendar-alt"}
            title={t("Set Up Defaults")}
            preProcessor={({ modalProps: { match: { params: { id } } } }) => ({ form: trainer.getFormById(id) })}
            formProps={({ form }) => {
                const vals = form.editDefaultScheduleVals();
                return {
                    initialValues: vals,
                    submit,
                    validate: SchedulableSetting.validate(true,t),
                    initialErrors: SchedulableSetting.validate(true,t)(vals) 
                }
            }}
            footerProps={{}}
            render={({ modalProps, form, ...rest }) => {

                return (
                    <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                        <SlightEmphasisTip text={<Trans i18nKey="form defaults tip"><b></b></Trans>} tipName={FORM_DEFAULTS_TIP} />
                        <SharedSchedulingFields 
                            record={form} 
                            formikProps={rest} 
                            prefix=''
                            isForDefaults
                        />
                    </div>
                )

            }}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(setDefaultFormScheduling(data))
})

DefaultFormScheduleModal = connect(null,mapDispatchToProps)(DefaultFormScheduleModal)

export { NewFormModal, DefaultFormScheduleModal, ClientFormsModal, FormsList, FormFieldHistoryModal }