import React from 'react';
import TextInput, { TextArea } from 'components/TextInput';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { submitContactForm } from 'redux/actions';
import FormikWrapper from 'components/FormikWrapper';
import { userRecordSelector } from 'redux/selectors';
import { LoadingSubmitButton, DefaultSelect, FullInput, DefaultTextArea } from 'components/Form';
import * as Yup from 'yup';
import { FullFormModal } from 'components/Modal';
import Button from 'components/Button';
import { SlightEmphasisIconNote } from 'components/Typography';
import { modalPathFor } from 'config/paths';
import { useLocation } from 'react-router-dom';


const mapStateToProps = state => ({
    user: userRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(submitContactForm(data))
})

export const ContactForm = connect(mapStateToProps,mapDispatchToProps)(({ user, submit, successCallback, subject, showType, validate }) => {
    const { t } = useTranslation();

    return (
        <FormikWrapper
            initialValues={{ noFlash: true, contactType: 'feedback', subject: subject || '', email: (user ? (user.email || '') : ''), message: '' }}
            initialErrors={{}}
            validationSchema={validate ? Yup.object().shape({
                email: Yup.string().email().required(),
                message: Yup.string().required()
            }) : null}
            successCallback={successCallback}
            submit={submit}
            render={({ handleSubmit, submitState, ...rest }) => {
                const { values, handleChange, handleBlur } = rest;
                
                return (
                    <form onSubmit={handleSubmit}>
                        {showType && (
                            <div className="valign-wrapper flex-start">
                                <DefaultSelect
                                    name="contactType" 
                                    label={t('Contact Type')}
                                    collection={[{ text: t('Feedback'), value: 'feedback'}, {text: t('Bug Report'), value: 'bug report'}, { text: t('Question'), value: 'question'}, { text: t('Billing'), value: 'billing'}]} 
                                    {...rest} 
                                />
                                <FullInput
                                    className="ml5"
                                    name="subject"
                                    label={t('Subject')}
                                    component={TextInput}
                                    formikProps={rest}
                                />
                                <FullInput
                                    className="ml5"
                                    name="email"
                                    label={t('Email')}
                                    component={TextInput}
                                    formikProps={rest}
                                />
                            </div>
                        )}
                        <div className="text-center">
                            <TextArea maxLength={2500} rows={10} style={{ width: '100%', height: '150px' }} name='message' value={values.message} onChange={handleChange} onBlur={handleBlur} />
                        </div>
                        <div className="mt10 text-center">
                            <LoadingSubmitButton icon="check" label={t("Submit")} loadState={submitState} id="submit-contact-btn" />
                        </div>
                    </form>
                )
            }}
        />
    )
})

export const contactModalSuffix = '/contact'

export const ContactFormModal = connect(null,mapDispatchToProps)(({ submit, icon, title, prompt, contactType, subject, completeMsg  }) => {

    const { t } = useTranslation();
    const [submitted,setSubmitted] = React.useState(false);
    const { pathname } = useLocation();
    const path = modalPathFor(contactModalSuffix,pathname);

    return (
        <FullFormModal 
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={icon}
            title={title}
            formProps={ () => {            
                return ({
                    initialValues: { contactType, subject, noFlash: true, message: '' },
                    submit: submit,
                    validationSchema: Yup.object().shape({
                        message: Yup.string().required()
                    }),
                    successCallback: () => setSubmitted(true)
                })
            }}
            footerProps={submitted ? { children: (<Button rounded className="modal-close">{t('Ok')}</Button>) } : { saveLabel: t('Send'), saveIcon: 'paper-plane', dontCloseOnDone: true }}
            render={({ modalProps, ...formikProps }) => {
                
                return (
                    <div className="pa20">
                        {prompt && (<SlightEmphasisIconNote text={submitted ? completeMsg : prompt} />)}
                        {!submitted && (<DefaultTextArea
                            className="mt10 mb10"
                            name="message"
                            inProps={{
                                maxLength: 4000,
                                placeholder: t('Required'),
                                height: 150
                            }}
                            {...formikProps}
                        />)}
                        
                    </div>                
                )
            }}
        />
    )
})