import React, { useState } from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import { workoutPreviewMatch, workoutLogMatch, progressionTestPathFor, 
     workoutDoMatch, swapExerciseSpecMatch, progressionTestBaseFor, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { connect } from 'react-redux';
import { standaloneStartWorkout, loadExProgression, submitProgTest, submitProgressionTestSet } from 'redux/actions';
import { ExerciseInfoModal } from 'partials/ExerciseModals';
import withWorkout from 'partials/RoutineStateHOC';
import { dateFormat } from 'config/settings';
import { useLocation, useHistory } from 'react-router-dom';
import { Redirect } from "components/Routing";
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import { getProgressionTestSelector } from 'redux/selectors';
import { FixedWorkoutNum, InstructionBox } from 'partials/WorkoutInputs';
import { ExerciseVideo } from 'partials/ExerciseImage';
import * as _ from 'lib/utilities';
import moment from 'moment';
import loadingContainer, { LoadingContainerParent, PDLoading } from 'components/LoadingHOC';
import { ExerciseInfoMenu } from 'partials/ExerciseModals';
import { CountdownTimer } from 'partials/ConnectedTimers';
import { buildTransitionMap, RestScreen } from 'views/WorkoutDo';
import { TimerControls } from 'partials/ExerciseSetFields';
import { StrengthTestPrompt } from 'views/StrengthTest';
import { ArbitraryMeasureField } from 'partials/ExerciseSetFields';
import { resolvedHomePath } from 'redux/helpers';
import { goWrapper } from 'redux/helpers';


const allSetIndices = [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15];

const transitionMap = {
    rules: [
        [[...allMainMatches,workoutPreviewMatch,workoutLogMatch,workoutDoMatch,swapExerciseSpecMatch],transitions.slideOut],
    ]
};

const TestResultLC = loadingContainer({
    'SUCCESS': PDLoading
}, { type: 'padded' });

const RestScreenWrapper = ({ spec, set, setIndex }) => {
    const nextSet = set.progressionTest.getNextSet(setIndex+1);
    const { t } = useTranslation();
    const nextPath = progressionTestPathFor(spec.id,spec.date(),setIndex+1);
    const nextExercise = nextSet.exercise();

    return (
        <RestScreen 
            time={set.restTime()} 
            nextPath={nextPath} 
            autoAdvance  
            nextExercise={nextExercise}
            nextGoal={nextExercise.isIsometric() ? t("Do time",{ time: _.stopwatchFormat(nextSet.reps())} ) : t("Do reps",{ reps: `${nextSet.reps()} ${t('Reps')}`})}
        />
    )
}

const setDoneHandlerCreator = (spec,set,setIndex,submitSet,history,setResult,rpe) => (e) => {
    submitSet(spec.date(),{ progressionId: set.progressionTest.progression.id, reps: set.reps(), set: { level: set.level, rpe }, setIndex, specId: spec.id });
    const result = set.result(rpe,setIndex);
    if(result) {
        setResult(result);
    } else {
        let suffix = '';
        let newSetIndex = setIndex + 1;
        if(set.restTime() > 0) {
            suffix = 'rest';
            newSetIndex = setIndex;
        }
        history.push(progressionTestPathFor(spec.id,spec.date(),newSetIndex,suffix));
    }
}

const IsometricSet = ({ set }) => {
    const { t } = useTranslation();
    const exercise = set.exercise();
    const repsLabel = exercise.isUnilateral() ? t('per side') : _.timeLabel(set.reps(),t);
    const hasArbMeas = !_.isBlank(exercise.arbitraryMeasureVal) && !_.isBlank(exercise.arbitraryMeasure);
    const instructions = (
        <React.Fragment>
            <Trans i18nKey="Start the timer and hold for duration">
                <b>{{ duration: _.stopwatchFormat(set.reps()) }}</b>
            </Trans>
            {t('Stop if your form falls apart')}
        </React.Fragment>
    )

    return (
        <CountdownTimer seconds={set.reps()} render={({ display, ...timerProps }) => {
            return (
                <React.Fragment>
                    <InstructionBox 
                        className="mb20"
                        title={`${t('Instructions')}:`}
                        instructions={instructions}
                        postInstructions={[t('Form tips'),exercise.instructions]}
                    />
                    <div className="valign-wrapper cntr respo">
                        <FixedWorkoutNum 
                            content={display}
                            title={t('Hold for')}
                            subtitle={repsLabel}
                        />
                        {hasArbMeas && (<ArbitraryMeasureField exercise={exercise} />)}
                        {!hasArbMeas && (
                            <div className="wo-wt-input-container">
                                <TimerControls timerProps={timerProps} />
                            </div>
                        )}
                    </div>
                    {hasArbMeas && (
                        <div className="mt20">
                            <TimerControls timerProps={timerProps} />
                        </div>
                    )}
                </React.Fragment>
            )
        }}/>
    )
}

const RepsSet = ({ set }) => {
    const exercise = set.exercise();
    const { t } = useTranslation();
    const repsLabel = exercise.isUnilateral() ? t('X per side',{ unit: t('Reps')}) : t('Reps');

    return (
        <React.Fragment>
            <InstructionBox 
                className="mb20"
                title={`${t('Instructions')}:`}
                instructions={`${t('Attempt the given number of reps and indicate how difficult it was')}${t('Stop if your form falls apart')}${exercise.isAlternating() ? t('One cycle through each side is a single rep') : ''}`}
                postInstructions={[t('Form tips'),exercise.instructions]}
            />
            <div className="valign-wrapper cntr respo">
                <FixedWorkoutNum 
                    key='reps'
                    content={set.reps()}
                    title={t('Do')}
                    subtitle={repsLabel}
                />
                {!_.isBlank(exercise.arbitraryMeasureVal) && !_.isBlank(exercise.arbitraryMeasure) && (
                    <ArbitraryMeasureField exercise={exercise} />
                )}
            </div>
        </React.Fragment>
    )
}

const ProgressionTestSetCore = ({ spec, progressionTest, setIndex, submitSet, history, result, setResult, submitTest }) => {
    const set = progressionTest.getNextSet(setIndex);
    const exercise = set.exercise();
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <div className="ex-vid-container">
                    <ExerciseVideo exercise={exercise} fallbackToYT autoplay />
            </div>
            <div className="text-right">
                <div className="wo-wt-ex-tab">{exercise.fullName(t)}</div>
                <ExerciseInfoMenu 
                    exercise={exercise} 
                    basePath={progressionTestPathFor(spec.id,spec.date(),setIndex)} 
                />
            </div>
            <div className="clearfix"></div>
            {result && (
                    <LoadingContainerParent 
                        load={submitTest.bind(null, { ...result, date: spec.date() })}
                        preloaded={() => false}
                        component={TestResultLC}
                        successCallback={() => goWrapper(history,-setIndex-1)}
                    />
                )}
            {!result && (<div className="wo-wt-details-wrapper">
                {exercise.isIsometric() && <IsometricSet set={set} />}
                {!exercise.isIsometric() && <RepsSet set={set} />}
                <div className="wo-wt-divider"></div>
                <StrengthTestPrompt 
                    handlerCreator={setDoneHandlerCreator.bind(null,spec,set,setIndex,submitSet,history,setResult)} 
                    reps={set.reps()} 
                    isometric={exercise.isIsometric()}
                />
            </div>)}
        </React.Fragment>
    )
}

class ProgressionTestSet extends React.Component {

    constructor(props) {
        super(props);
        const { basePath, setIndex, suffix, spec, setupTransitions } = props;
        const prevMatches = suffix === 'rest' ? [progressionTestPathFor(spec.id,spec.date(),setIndex)] : [];
        const afterMatches = suffix === 'rest' ? [] : [progressionTestPathFor(spec.id,spec.date(),setIndex,'rest')];
        const defaultRules = [[prevMatches,transitions.flowFormOut],[afterMatches,transitions.flowFormIn]]
        setupTransitions(buildTransitionMap(basePath,defaultRules,`${setIndex}`,allSetIndices.map(index => `${index}`)));
    }

    render() {
        const { progressionTest, setIndex, suffix, spec, submitSet, result, setResult, submitTest, history } = this.props;
        
        return (
            <div className="text-center">
                <div className="set-container pb10">
                    {suffix !== 'rest' && (
                        <ProgressionTestSetCore  
                            spec={spec}
                            progressionTest={progressionTest}
                            setIndex={setIndex}
                            submitSet={submitSet}
                            setResult={setResult}
                            result={result}
                            submitTest={submitTest}
                            history={history}
                        />
                    )}
                    {suffix === 'rest' && (
                        <RestScreenWrapper 
                            spec={spec}
                            set={progressionTest.getNextSet(setIndex)}
                            setIndex={setIndex}
                        />
                    )}
                </div>
            </div>
        )
    }
}

const ProgressionTestCore = ({ progressionTest, spec, setIndex, submitSet, submitTest }) => {

    const location = useLocation();
    const history = useHistory();
    const { t } = useTranslation();
    const [result,setResult] = useState(null);
    const basePath = progressionTestBaseFor(spec.id,spec.date());

    return (
        <div style={{overflowX: 'hidden', maxWidth: '100vw'}}>
            <RouteTransitionMap location={location} canUpdate noOverflow>
                {_.flatMap(['rest',''],(suffix) => (
                    allSetIndices.map((setNum) => {
                        return (
                            <ProgressionTestSet 
                                    key={`${suffix}-${setNum}`}
                                    path={progressionTestPathFor(spec.id,spec.date(),setNum,suffix)}
                                    exact
                                    spec={spec}
                                    progressionTest={progressionTest}
                                    setIndex={setNum}
                                    suffix={suffix}
                                    submitSet={submitSet}
                                    submitTest={submitTest}
                                    result={result}
                                    setResult={setResult}
                                    t={t}
                                    history={history}
                                    basePath={basePath}
                            />
                        )
                    })
                ))}
            </RouteTransitionMap>
        </div>
    );
}

const ProgressionTestLC = loadingContainer({
    'SUCCESS': ProgressionTestCore
}, { type: 'page' })

let ProgressionTestLoader = ({ progressionTest, loadProgression, spec, setIndex, submitSet, submitTest }) => {
    return (
        <LoadingContainerParent 
            load={loadProgression.bind(null, spec.exercisable().id, spec.date())}
            preloaded={() => !!progressionTest}
            component={ProgressionTestLC}
            spec={spec}
            setIndex={setIndex}
            progressionTest={progressionTest}
            submitSet={submitSet}
            submitTest={submitTest}
        />
    )
}

const mapStateToProps = (state,props) => {
    const progressionTestSelector = getProgressionTestSelector(props.spec.exercisable().id,props.spec.exerciseTemplate.minRepsForProg(),props.spec.date());
    return {
        progressionTest: progressionTestSelector(state)
    }
}

const mapDispatchToProps = dispatch => ({
    loadProgression: (id,date) => dispatch(loadExProgression(id,date)),
    submitSet: (date,{ set, setIndex, progressionId, reps, specId }) => dispatch(submitProgressionTestSet(date,{ set, setIndex, progressionId, reps, specId })),
    submitTest: (params) => dispatch(submitProgTest(params))
})

ProgressionTestLoader = connect(mapStateToProps, mapDispatchToProps)(ProgressionTestLoader);

class ProgressionTestSuccess extends React.Component {

    constructor(props) {
        super(props);
        const { date, startWorkout } = props;
        startWorkout(date.format(dateFormat));
    }

    render() {
        const { workout, specId, setIndex } = this.props;
        const spec = workout && workout.getSpec(specId);

        if(spec && spec.isProgression()) {
            return (<ProgressionTestLoader 
                spec={spec}
                setIndex={setIndex}
            />)
        } else {
            return <Redirect to={resolvedHomePath()} />
        }
    }
}

const WithWorkout = withWorkout('dirty','page',true)(ProgressionTestSuccess)

class ProgressionTestPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { location, match: { params: { specId, date, setIndex }}, t, scrollRef, startWorkout } = this.props;
        const basePath = progressionTestPathFor(specId,date,setIndex);

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{t('Strength Test')}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <WithWorkout date={moment(date)} specId={specId} setIndex={setIndex} location={location} startWorkout={startWorkout} />
                    <ExerciseInfoModal basePath={basePath} baseMatch={basePath}/>
                </SimpleNavContainer>
            </Page>
        )
    }
}

const mapDispatchToStartWorkout = dispatch => ({
    startWorkout: (date) => dispatch(standaloneStartWorkout(date))
})

export default withTranslation()(connect(null,mapDispatchToStartWorkout)(ProgressionTestPage));