import React from 'react';
import { Checkbox, CheckboxCore } from 'components/Form';
import { SelectInput, NumericSelectInput, ExtraSmallNumberInput, ExtraSmallTextInput } from 'components/TextInput';
import { coreMacros, MacroParams } from 'lib/classes';
import { IconTip } from 'components/Tooltipped';
import * as _ from 'lib/utilities';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

const handleCheckChangeCreator = (setFieldValue,name) => (e) => {
    if(e.target.checked) {
        setFieldValue(name, false);
    } else {
        setFieldValue(name, true);
    }
}

const handleRecCheckChange = (setFieldValue,colName) => e => {
    if(e.target.checked) {
        setFieldValue(`${colName}.0._destroy`, true);
        setTimeout(() => setFieldValue(`${colName}.1._destroy`, true),1); //seems like a weird formik bug with array fields where if you do this without a timeout it will pull in the old value and overwrite the previous setFieldValue call
    } else {
        setFieldValue(`${colName}.0._destroy`, false);
    }
}

const handleManualCheckChange = (setFieldValue,colName) => e => {
    if(e.target.checked) {
        setFieldValue(`${colName}.0._destroy`, false);
    } else {
        setFieldValue(`${colName}.0._destroy`, true);
    }
}

const ImpliedParamField = ({ impliedParam, errors, noChecks, colName, t }) => {
    const error = _.get(errors,`${colName}.2.amount`);
    let tipMacros = { macro1: t(impliedParam.nutrientStr) };
    const otherMacros = _.without(coreMacros,impliedParam.nutrientStr);
    otherMacros.forEach((macro,index) => {
        tipMacros[`macro${2+index}`] = t(macro)
    })

    return (
        <div className="custom-macro-container">
            {!noChecks && (<CheckboxCore 
                inputProps={ { 
                    disabled: true,
                    checked: true
                } }
                className='middle-align less-padding'
                filled 
            />)}
            <SelectInput 
                browserDefault
                disabled
                className="inline select-signup-medium ml5 mr5"
                name={`compareType`}
                collection={MacroParams.compareTypesForForm(t,true)}
                value={impliedParam.compareType}
            />
            <ExtraSmallTextInput 
                className="inline ma0"
                inputProps={{
                    disabled: true,
                    name: "rangeStr",
                    value: impliedParam.rangeStr,
                    className: 'text-center'
                }}
            />
            <SelectInput 
                browserDefault 
                className="inline select-signup-medium ml5"
                name={`nutrientId`}
                collection={MacroParams.nutrientIdsForForm(t,[])}
                value={impliedParam.nutrientId}
                disabled
            />
            <IconTip key={impliedParam.nutrientId} icon='question-circle' msg={t('implied macro tooltip', tipMacros)} size='2x' />
            {error && (<div className="red-text">{error}</div>)}
        </div>
    )
}

const CustomMacroField = ({ nutritionParameter, index, flowProps, otherParams, colName, noChecks, t, percentOnly }) => {
    const baseName = `${colName}.${index}.`;
    const { setFieldValue, handleChange, handleBlur } = flowProps;
    const excludeMacros = otherParams.map(param => MacroParams.ID_MACROS[param.nutrientId]);
    const amountName = `${baseName}amount`;
    const { errors, touched } = flowProps;
    const error = _.get(touched,amountName) && _.get(errors,amountName);
    const amountDisabled = noChecks ? false : nutritionParameter._destroy;
    const nonAmountDisabled = amountDisabled || nutritionParameter.nutrientId === MacroParams.FIBER_ID;

    return (
        <div className="custom-macro-container">
            {!noChecks && (<CheckboxCore 
                inputProps={ { 
                    checked: !nutritionParameter._destroy, 
                    onChange: handleCheckChangeCreator(setFieldValue,`${baseName}_destroy`),
                } }
                className='middle-align less-padding'
                id={`custom-macro-check-${index}`}
                filled 
            />)}
            <NumericSelectInput 
                browserDefault
                disabled={nonAmountDisabled}
                className={classnames("inline select-signup-medium mr5",{ ml5: !noChecks })}
                name={`${baseName}compareType`}
                collection={MacroParams.compareTypesForForm(t)}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={nutritionParameter.compareType}
            />
            <ExtraSmallNumberInput 
                className="inline ma0"
                inputProps={{
                    disabled: amountDisabled,
                    name: amountName,
                    onChange: handleChange,
                    onBlur: handleBlur,
                    value: nutritionParameter.amount
                }}
            />
            <NumericSelectInput 
                browserDefault 
                className="inline select-signup-tiny ml5"
                name={`${baseName}unitType`}
                collection={MacroParams.unitTypesForForm(t)}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={nutritionParameter.unitType}
                disabled={nonAmountDisabled || percentOnly}
            />
            <NumericSelectInput 
                browserDefault 
                className="inline select-signup-medium ml5"
                name={`${baseName}nutrientId`}
                collection={nutritionParameter.nutrientId === MacroParams.FIBER_ID ? MacroParams.fiberForForm(t) : MacroParams.nutrientIdsForForm(t,excludeMacros)}
                setFieldValue={setFieldValue}
                onBlur={handleBlur}
                value={nutritionParameter.nutrientId}
                disabled={nonAmountDisabled}
            />
            {error && (<div className="red-text">{error}</div>)}
        </div>
    )
}

const RecCheckbox = ({ isChecked, setFieldValue, colName }) => {
    const { t } = useTranslation();

    return (
        <Checkbox 
            inputProps={ { 
                checked: isChecked, 
                onChange: handleRecCheckChange(setFieldValue,colName),
            } }
            className="ml10 mb10"
            label={t("Use Recommendation")}
            filled
        />
    )
}

const ManualCheckbox = ({ isChecked, setFieldValue, colName }) => {
    const { t } = useTranslation();

    return (
        <Checkbox 
            id="custom-macros-check"
            inputProps={ { 
                checked: isChecked, 
                onChange: handleManualCheckChange(setFieldValue,colName),
            } }
            className="ml10 mb10"
            label={t("Manually Input")}
            filled
        />
    )
}

export const CustomMacroFields = ({ nutritionParameters, flowProps, calTarget, colName='dailyNutritionProfiles.0.nutritionParameters', noChecks, noRecCheck, percentOnly, t }) => {
    const activeParams = MacroParams.activeParams(nutritionParameters,noChecks);
    const impliedParam = MacroParams.impliedParam(activeParams,calTarget,t,noChecks);
    const alwaysShowParams = noChecks || noRecCheck;
    const showRecBox = !alwaysShowParams;
    const recIsChecked =  activeParams.length === 0;
    const showParams = alwaysShowParams || !recIsChecked;
    const showManualBox = showRecBox && recIsChecked;
    const paramBoxes = nutritionParameters.map((nutritionParam,index) => (
        <CustomMacroField 
            key={nutritionParam.nutrientId} 
            nutritionParameter={nutritionParam} 
            t={t} 
            flowProps={flowProps} 
            index={index} 
            otherParams={_.without(nutritionParameters,nutritionParam)}
            colName={colName}
            noChecks={noChecks}
            percentOnly={percentOnly}
        />
    ))

    return (
        <React.Fragment>
            {showRecBox && <RecCheckbox setFieldValue={flowProps.setFieldValue} isChecked={recIsChecked} colName={colName} />}
            {showManualBox && <ManualCheckbox setFieldValue={flowProps.setFieldValue} isChecked={!recIsChecked} colName={colName} />}
            {showParams && paramBoxes}
            {impliedParam && (<ImpliedParamField impliedParam={impliedParam} t={t} errors={flowProps.errors} noChecks={noChecks} colName={colName} />)}
        </React.Fragment>
    )
}

export const UserMacroFields = ({ user, flowProps: { values: { calorieOverride }}, flowProps, dailyNutritionProfile, ...rest }) => {
    dailyNutritionProfile = dailyNutritionProfile || user.mainNutritionProfile();
    const calTarget = dailyNutritionProfile.targetCalories(calorieOverride);

    return (
        <CustomMacroFields 
            calTarget={calTarget}
            flowProps={flowProps}
            {...rest}
        />
    )
}