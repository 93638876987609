const noAnim = process.env.REACT_APP_TEST === 'true'
export const none = { enteringClassNames: '', leavingClassNames: '', timeout: 1 };
export const fadeIn = noAnim ? none : { enteringClassNames: 'fade', leavingClassNames: '', timeout: 300 };
export const fadeAway = noAnim ? none : { enteringClassNames: '', leavingClassNames: 'fade', timeout: 300 };
export const slideDown = noAnim ? none : { enteringClassNames: '', leavingClassNames: 'slide-up', timeout: 300 };
export const slideUp = noAnim ? none : { enteringClassNames: 'slide-up', leavingClassNames: '', timeout: 300 };
export const slideOver = noAnim ? none : { enteringClassNames: 'slide-over', leavingClassNames: '', timeout: 300 };
export const slideOut = noAnim ? none : { enteringClassNames: '', leavingClassNames: 'slide-over', timeout: 300 };
export const flowFormIn = noAnim ? none : { enteringClassNames: 'flow-form-in', leavingClassNames: 'flow-form-in', timeout: 300 };
export const flowFormOut = noAnim ? none : { enteringClassNames: 'flow-form-out', leavingClassNames: 'flow-form-out', timeout: 300 };
export const scaleIn = noAnim ? none : { enteringClassNames: 'scale-in', leavingClassNames: '', timeout: 300 };
export const scaleOut = noAnim ? none : { enteringClassNames: '', leavingClassNames: 'scale-in', timeout: 300 };
