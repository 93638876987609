import { registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { BasicProfile } from './user';

export class MiniProfile extends BasicProfile {
    static NAME = 'MiniProfile'

    static ASSOCS = {
        user: { type: 'belongsTo' },
        userMeals: { type: 'hasMany' }
    }

    static calorieMethods = (t) => ([
        { text: t('Recommended calories'), value: '' },
        { text: t('Specific calories'), value: 1 },
        { text: t('pct of my calories'), value: 2 }
    ])

    static defaultValues = (user) => {
        let vals = { id: '', name: '', age: '', gender: false, weightGoal: -1, unitPreference: _.isBlank(user.unitPreference) ? 0 : user.unitPreference, calorieMethod: '', calorieGoal: '', alwaysAddMeals: [''] }
        if(user.isMetric()) {
            vals.metricHeight = '';
            vals.metricWeight = '';
        } else {
            vals.footHeight = '';
            vals.inchHeight = '';
            vals.currentWeight = '';
        }
        return vals;
    }

    formValues() {
        const defaults = MiniProfile.defaultValues(this.user);
        const vals = this.extract(Object.keys(defaults),true);
        if(_.isBlank(this.alwaysAddMeals)) {
            vals.alwaysAddMeals = [''];
        }
        vals.unitPreference = this.user.unitPreference;
        return _.parseObjForForm(vals);
    }

    currentWeight() {
        return this.weight;
    }

}

registerInflection('miniProfile','miniProfiles',MiniProfile);