import { FullFormModal } from 'components/Modal';
import { clientBasicProfilePath, editClientModalMatchFor, modalPathFor, newClientModalPathFor, newTrainerModalPathFor } from 'config/paths';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import { Checkbox, DefaultSelect, FileUpload, FullInput } from 'components/Form';
import TextInput from 'components/TextInput';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import { createChildTrainer, createClient, updateClients, updateTrainer } from 'redux/actions';
import { SlightEmphasisTip, TrainerSelectListing } from './Utilities';
import { CLIENT_EMAIL_TIP } from 'config/tooltips';
import Button from 'components/Button';
import loadingContainer from 'components/LoadingHOC';
import { clientFiltersSelector } from 'redux/selectors';
import { useLocation } from 'react-router-dom';
import blankProfileImg from 'assets/img/BlankProfile.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { validateYupSchema, yupToFormErrors } from 'formik';

export const syncClientSetupValues = values => {
    if(values.setupOwnProfile !== undefined) {
        const { canEditOwnMealPlan, ...newVals } = values;
        if(!values.setupOwnProfile || values.setupOwnProfile === '') {
            values = { ...newVals, setupOwnMpPrefs: false, pickOwnRoutine: false, canPickInitialRecipes: false, autoDeliverMpPdf: false };
        } else if(!values.setupOwnMpPrefs || values.setupOwnMpPrefs === '') {
            values = { ...newVals, canPickInitialRecipes: false, autoDeliverMpPdf: false };
        } else if(canEditOwnMealPlan !== undefined) {
            values = { ...newVals, canEditOwnMealPlan };
        } else {
            values = { ...newVals };
        }
    }
    return values;
}

const cliSetupChecks = [
    { name: 'assignDefaultForms', label: 'Assign default forms', id: "assign-default-forms-check", tip: 'assign def forms tip' },
    { name: 'setupOwnProfile', label: 'setup own profile', id: "setup-own-profile-check", tip: 'own profile tip' },
    { name: 'setupOwnMpPrefs', label: 'setup own mp prefs', id: "setup-own-mp-prefs-check", tip: 'own mp prefs tip' },
    { name: 'canEditOwnMealPlan', label: "allow edit own mp", id: 'edit-own-mp-check', tip: 'edit own mp tip' },
    { name: 'pickOwnRoutine', label: 'setup own workouts', id: "setup-own-workouts-check", tip: 'own routine tip' },
    { name: 'canPickInitialRecipes', label: 'can pick initial recs', id: 'pick-initial-recs-check', tip: 'can pick initial recs tip'},
    { name: 'autoDeliverMpPdf', label: 'auto deliver mp pdf', id: 'auto-deliver-mp-check', tip: 'auto deliver mp pdf tip' }
]

export const ClientSetupChecks = ({ formikProps, className, checkClassName, isPdf, user }) => {
    const { values, handleChange, handleBlur } = formikProps;
    const { t } = useTranslation();
    let exclude = [];

    if(isPdf) {
        exclude.push('pickOwnRoutine');
    }

    if(isPdf || !values.setupOwnMpPrefs) {
        exclude.push('canEditOwnMealPlan');
    }

    if(user.showTrainingOnly()) {
        exclude.push('setupOwnMpPrefs');
        exclude.push('canEditOwnMealPlan');
    } else if(user.showNutritionOnly()) {
        exclude.push('pickOwnRoutine');
    }

    if(!isPdf || !values.setupOwnMpPrefs) {
        exclude.push('canPickInitialRecipes');
        exclude.push('autoDeliverMpPdf');
    }

    return (
        <div className={className}>
            {cliSetupChecks.map(vals => {
                const { name, label, id, tip } = vals;
                let showCheck = false;
                if(name === 'assignDefaultForms') {
                    showCheck = formikProps.values.assignDefaultForms !== undefined;
                } else {
                    showCheck = (name === 'setupOwnProfile' || values.setupOwnProfile);
                }

                if(showCheck && !exclude.includes(name)) {
                    return (
                        <div className={checkClassName} key={name}>
                            <Checkbox 
                                inputProps={{ 
                                    name: name, 
                                    onChange: handleChange, 
                                    onBlur: handleBlur, 
                                    value: true,
                                    checked: values[name]
                                } } 
                                label={t(label)} 
                                filled
                                id={id}
                                checkClassName="low-em"
                                className="no-wrap max80vw"
                                tooltip={t(tip)}
                            />
                        </div>
                    )
                }

                return '';
            })}
        </div>
    )
}

const SubmitDefault = ({ showPdfStuff, disabled, submit }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Button 
                disabled={disabled} 
                outlined
                rounded 
                color="primary" 
                id="create-btn"
                onClick={() => submit('create',false)}
            >
                {t('Create')}
            </Button>
            {showPdfStuff && (<Button 
                disabled={disabled} 
                rounded 
                color="primary" 
                className="ml5" 
                id="set-up-btn"
                onClick={() => submit('create',true)}
            >
                <FontAwesomeIcon icon="arrow-right" />
                {t('Create & Set Up')}
            </Button>

            )}
            {!showPdfStuff && (<Button 
                disabled={disabled} 
                outlined 
                rounded 
                color="primary" 
                className="ml5" 
                id="invite-btn"
                onClick={() => submit('invite',false)}
            >
                {t('Create & Invite')}
            </Button>)}
        </React.Fragment>
    )
}

const SubmitLC = loadingContainer({
    "DEFAULT": SubmitDefault,
    "SUCCESS": SubmitDefault
})

const newUserValidate = showPdfStuff => values => {
    const yupObj = Yup.object().shape({
        firstName: Yup.string().required(),
        lastName: Yup.string(),
        email: (showPdfStuff && !values.setupOwnProfile) ? Yup.string().email() : Yup.string().email().required()
    });

    try {
        validateYupSchema(values, yupObj,true);
        return {};
    } catch(validationError) {
        return yupToFormErrors(validationError);
    }
}

const NewUserModal = ({ path, submit, initialVals, title, icon, type, trainer, isEdit }) => {
    const { t } = useTranslation();
    const [emailErr,setEmailErr] = useState(null);
    const showPdfStuff = type === 'client' && trainer.isPdfTrainer();
    const [goSetup,setGoSetup] = useState(false);

    return (
        <FullFormModal
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={icon}
            title={title}
            formProps={({ modalProps, modalProps: { close, closeAndGo }}) => {
                const vals = initialVals(modalProps);
                return {
                    initialValues: { ...vals, type: 'create' },
                    submit: values => {
                        values = _.imageFormFilter()(values);
                        values = _.omit(values,['defaultImage','isOwner']);
                        values = syncClientSetupValues(values);
                        if(isEdit && type === 'client' && !trainer.isTrainerAdmin() && values.trainerId !== trainer.id) {
                            return submit(values,'Reassigned');
                        } else {
                            return submit(values);
                        }
                    },
                    successCallback: ({ status,formikBag,data }) => {
                        if(data.error) {
                            setEmailErr(data.error);
                        } else {
                            if(emailErr) {
                                setEmailErr(null);
                            }

                            if(goSetup) {
                                const { users } = data;
                                const id = Object.values(users)[0].id;
                                closeAndGo(clientBasicProfilePath(id,'setup'));
                            } else {
                                close();
                            }
                        }
                    },
                    validate: newUserValidate(showPdfStuff),
                    initialErrors: _.isBlank(vals.firstName) ? { firstName: 'required' } : {}
                }
            }}
            footerProps={isEdit ? { id: 'save-edits-btn', dontCloseOnDone: true } : ({ formikProps: { submitForm, setFieldValue, values }}) => {
                const submit = (type,goSetup=false) => {
                    setGoSetup(goSetup);
                    setFieldValue('type',type);
                    submitForm();
                }

                return {
                    render: (props) => (<SubmitLC {...props} showPdfStuff={!values.setupOwnProfile && showPdfStuff} submit={submit} />)
                }
            }}
            render={({ modalProps, ...rest }) => {
                const { values: { isOwner, ogTrainerId } } = rest;
                const showPdfMsgs = (showPdfStuff && !rest.values.setupOwnProfile);

                return (
                    <div className="pa10" style={{ maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' }}>
                        {(isEdit || type === 'trainer') && (<div className="mb15 text-center">
                            <div className="font-grey">{t('Profile Picture')} ({t('optional')})</div>
                            <FileUpload
                                name="image"
                                setFieldValue={rest.setFieldValue}
                                defaultImageUrl={_.isBlank(rest.values.image) ? rest.values.defaultImage : rest.values.image}
                                outlined
                            />
                        </div>)}
                        <div className="valign-wrapper">
                            <FullInput
                                className="flex-grow"
                                name="firstName"
                                label={t('First Name')}
                                component={TextInput}
                                formikProps={rest}
                            />
                            <FullInput
                                className="ml10 flex-grow"
                                name="lastName"
                                label={t('Last Name')}
                                component={TextInput}
                                formikProps={rest}
                            />
                        </div>
                        <div className="valign-wrapper flex-start">
                            <FullInput
                                className="flex-grow"
                                name="email"
                                label={showPdfMsgs ? `${t('Email')} (${t('Optional')})` : t('Email')}
                                component={TextInput}
                                formikProps={rest}
                            />
                            {type === 'trainer' && (
                                <DefaultSelect
                                    label={t('Role')}
                                    className="ml5"
                                    id="role-select-drop"
                                    name='trainerRole'
                                    tooltip={isOwner ? null : <Trans i18nKey="trainer roles tip"><b></b><br/><b></b></Trans>}
                                    collection={isOwner ? [{ text: t('owner'), value: 'admin'}] : [{ text: t('standard member'), value: 'member' }, { text: t('admin'), value: 'admin' }]}
                                    {...rest}
                                />
                            )}
                        </div>
                        {emailErr && (<div className="shift20-up red-text" id="client-email-err">{emailErr}</div>)}
                        {showPdfMsgs && (<div className="shift20-up">
                            <SlightEmphasisTip tipName={CLIENT_EMAIL_TIP} text={t("client email tip")} />
                        </div>)}
                        {(type === 'client' && !isEdit && rest.values.setupOwnProfile !== undefined) && (
                            <ClientSetupChecks formikProps={rest} className="shift15-up" user={trainer} isPdf={trainer.isPdfTrainer()} />
                        )}
                        {type === 'client' && (trainer.isTrainerAdmin() || isEdit) && trainer.hasOtherTrainers() && (
                            <TrainerSelectListing
                                title={t('Assign to')}
                                trainer={trainer}
                                formikProps={rest}
                                name={'trainerId'}
                                preSelectedIds={_.compact([ogTrainerId])}
                                single
                            />
                        )}
                    </div>
                )
            }} 
        />
    )
}

let NewTrainerModal = ({ baseMatch, submit }) => {
    const { t } = useTranslation();

    return (
        <NewUserModal 
            path={newTrainerModalPathFor(baseMatch)}
            icon="plus"
            title={t('New Trainer')}
            initialVals={() => ({ firstName: '', lastName: '', email: '', trainerRole: 'member', defaultImage: blankProfileImg })}
            type="trainer"
            submit={submit}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(createChildTrainer(data))
})

NewTrainerModal = connect(null,mapDispatchToProps)(NewTrainerModal);

export const editTrainerSuffix = '/edit/:trainerId';

let EditTrainerModal = ({ trainer, submit }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <NewUserModal 
            path={modalPathFor(editTrainerSuffix,pathname)}
            icon={["far","edit"]}
            title={t('View/Edit Team Member')}
            initialVals={({ match: { params: { trainerId }}}) => {
                const editTrainer = trainer.trainerById(trainerId);
                return _.parseObjForForm(editTrainer.initialValsForTrainerEdit());
            }}
            trainer={trainer}
            type="trainer"
            submit={submit}
            isEdit
        />
    )
}

const mapDispatchToEditTrainerProps = dispatch => ({
    submit: (data) => dispatch(updateTrainer(data))
})

EditTrainerModal = connect(null,mapDispatchToEditTrainerProps)(EditTrainerModal);

let NewClientModal = ({ trainer, baseMatch, submit, filters }) => {
    const { t } = useTranslation();
    const finalFilters = trainer.validClientFilters(filters);

    return (
        <NewUserModal 
            path={newClientModalPathFor(baseMatch)}
            icon="plus"
            title={t('New Client')}
            initialVals={() => {
                const id = trainer.resolvedFilterTid(finalFilters.trainerId);
                const ogTrainerId = _.isBlank(id) ? trainer.id : id;
                return trainer.initialNewClientVals(ogTrainerId);
            }}
            trainer={trainer}
            type="client"
            submit={submit}
        />
    )
}

const mapDispatchToClientProps = dispatch => ({
    submit: (data) => dispatch(createClient(data))
})

const mapStateToClientProps = state => ({
    filters: clientFiltersSelector(state)
})

NewClientModal = connect(mapStateToClientProps,mapDispatchToClientProps)(NewClientModal);

let EditClientModal = ({ trainer, baseMatch, submit }) => {
    const { t } = useTranslation();

    return (
        <NewUserModal 
            path={editClientModalMatchFor(baseMatch)}
            icon={["far","edit"]}
            title={t('Edit Client')}
            initialVals={({ match: { params: { clientId }}}) => {
                const client = trainer.clientById(clientId);
                return _.parseObjForForm({ ..._.pick(client || {},['firstName','lastName','email','trainerId']), clientIds: [clientId], defaultImage: (client && client.profileImagePath('mediumThumb')) });
            }}
            trainer={trainer}
            type="client"
            submit={submit}
            isEdit
        />
    )
}

const mapDispatchToEditClientProps = dispatch => ({
    submit: (data,popupOnSuccess) => dispatch(updateClients(data,popupOnSuccess))
})

EditClientModal = connect(null,mapDispatchToEditClientProps)(EditClientModal);

export { NewTrainerModal, EditTrainerModal, NewClientModal, EditClientModal }