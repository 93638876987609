import React from 'react';
import ActivityContainer from 'components/ActivityContainer';
import PlaceholderImage from 'components/PlaceholderImage';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import LinkButton from 'components/LinkButton';
import * as _ from 'lib/utilities';
import moment from 'moment';
import { dateFormat, TRAINING } from 'config/settings';
import { getActiveWorkoutsSelector, userWithRoutineSelector, lastLoadedWorkoutDaySel, userRecordSelector, trainerRecordSelector } from 'redux/selectors';
import { loadUser, clearInitialSignup, switchClient } from 'redux/actions';
import { connect } from 'react-redux';
import loadingContainer, { Loader, LoadingContainerParent } from 'components/LoadingHOC';
import WorkoutCard, { WorkoutNotSyncedIcon, WorkoutTimeEstimate } from 'partials/WorkoutCard';
import Card from 'components/Card';
import { SectionTabs, SectionTab } from 'components/BottomNav';
import classnames from 'classnames';
import { useHistory } from 'react-router-dom';
import { workoutPreviewPathFor, editRoutinePath, workoutPlanPath, deloadPathFor, undeloadPathFor, pickNewRoutineModalPathFor, allMainMatches, rootPath, clientDetailsMatch, standaloneWorkoutPlanPathFor } from 'config/paths';
import { WorkoutPlanStandaloneSetupSection } from 'partials/SetupCards';
import withWorkout from 'partials/RoutineStateHOC';
import { SyncWorkoutsModal } from 'partials/WorkoutSyncModal';
import Button from 'components/Button';
import ProRequiredButton from 'partials/ProRequiredButton';
import { DeloadModal, UndeloadModal } from 'partials/DeloadModals';
import { SignupModal } from 'partials/SignupModal';
import Connector from 'partials/WebsiteConnector';
import { PickNewRoutineModal } from 'partials/EditRoutineModals';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import FloatingActionButton, { FabChild } from 'components/FAB';
import { Workout } from 'lib/classes';
import { PlanNotReady } from 'partials/PlanNotReady';
import { SimpleNavPage } from 'components/Page';
import * as transitions from 'assets/transitions';
import { workoutPlanSlideOverPaths } from 'partials/MainLayout';
import { HideOnExit } from 'components/RouteTransitionMap';
import { TrainerPaywall } from 'partials/PaywallWrapper';

const basePathFor = clientId => (clientId ? standaloneWorkoutPlanPathFor(clientId) : workoutPlanPath)

const WeekTabDay = ({ curDate, date, workouts, t }) => {
    const workout = workouts[date.format(dateFormat)];
    const isActive = curDate.isSame(date,'day');
    const classNames = classnames({"wt-cards": true, "buttonize": !!workout,"active": isActive, "inactive": date.isBefore(curDate,'day')});
    const isLogged = workout && workout.logged;
    const medalClassNames = classnames("wt-medal",{"active": isLogged, "inactive": (!isActive && (!workout || !workout.logged))});
    const history = useHistory();
    const clickHandler = workout ? (() => history.push(workoutPreviewPathFor(workout.date))) : null;

    return (
        <div className="position-relative">
            <Card className={classNames} onClick={clickHandler}>
                <div key="day" className={classnames("wt-days",{ rest: !workout })}>{isActive ? t("Today") : date.format('ddd')}</div>
                <div key="name" className={classnames("wt-workout-title", { workout: !!workout })}>{workout ? _.abbrText(workout.displayName(t),50) : t("Rest Day")}</div>
                {workout && (<FontAwesomeIcon icon={isLogged ? "medal" : ['fal',"medal"]} className={medalClassNames}></FontAwesomeIcon>)}
            </Card>
            <WorkoutPlanContext.Consumer>
                {clientId => (<WorkoutNotSyncedIcon workout={workout} basePath={basePathFor(clientId)} />)}
            </WorkoutPlanContext.Consumer>
            
        </div>
    )
}

const WeekTab = ({ curDate, startDate, workouts }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {_.nextWeekDates(startDate).map(date => {
                
                return (
                    <WeekTabDay key={date.format(dateFormat)} curDate={curDate} date={date} workouts={workouts} t={t} />
                )
            })}
        </React.Fragment>
    )
}

const WeekTabLOC = loadingContainer({
    "SUCCESS": WeekTab
},{ type: "padded" })

class WorkoutWeekTab extends React.Component {

    constructor(props) {
        super(props);
        this.preloaded = this.preloaded.bind(this);
    }

    render() {
        const { startDate, workouts, loadUser, date } = this.props;

        return (
            <LoadingContainerParent 
                component={WeekTabLOC}
                startDate={startDate}
                curDate={date}
                workouts={workouts}
                load={loadUser.bind(null,date)}
                preloaded={this.preloaded}
            />
        )
    }

    preloaded() {
        const { startDate, lastLoadedDate } = this.props;
        const lastNeededDate = startDate.clone().add(6,'days');
        return lastNeededDate.isSameOrBefore(lastLoadedDate,'day');
    }
    
}

const EditDeloadButtons = ({ t, user, type, belowBreakpoint }) => {
    const deloadButtonType = user.deloadButtonType();
    const sharedEditProps = { user, proPath: editRoutinePath, blockTypes: ['hard','soft'], context: 'routine_editor' }
    const history = useHistory();

    if(!user.allowedToEditOwnRoutine() || (type === 'card' && belowBreakpoint) || (type === 'fixed' && !belowBreakpoint)) {
        return '';
    }

    return (
        <WorkoutPlanContext.Consumer>
            {clientId => {
                if(clientId) {
                    return '';
                }
                if(belowBreakpoint) {
                    return (<FloatingActionButton
                        options={{ position: 'top' }}
                        id={"edit-routine-opts-btn"} 
                        btnClass="btn-primary" 
                        icon={"pencil"}
                    >
                        {deloadButtonType === 'do' && <FabChild label={t('Deload')} icon="level-down-alt" className="red" onClick={() => history.push(deloadPathFor(basePathFor(clientId)))} />}
                        {deloadButtonType === 'undo' && <FabChild label={t('Undo deload')} icon="level-up-alt" className="red" onClick={() => history.push(undeloadPathFor(basePathFor(clientId)))} />}
                        <ProRequiredButton  
                            {...sharedEditProps}
                            render={({ onClick }) => (
                                <FabChild label={t('Edit Routine')} icon="pencil-alt" className="carb-color" onClick={onClick} />
                            )}
        
                        />
                        <FabChild label={t('Pick New Routine')} icon="exchange-alt" className="fat-color" onClick={() => history.push(pickNewRoutineModalPathFor(basePathFor(clientId)))} />
                    </FloatingActionButton>)
                } else {
                    return (<div>
                        <ProRequiredButton  
                            {...sharedEditProps}
                            render={({ onClick }) => (
                                <Button key="edit-routine-btn" rounded color="primary" outlined onClick={onClick}>
                                    <FontAwesomeIcon icon="pencil-alt"></FontAwesomeIcon> {t("Edit Routine")}
                                </Button>
                            )}
        
                        />
                        <LinkButton key="new-routine-btn" id="new-routine-btn" className="ml5" rounded color="primary" outlined to={pickNewRoutineModalPathFor(basePathFor(clientId))}>
                            <FontAwesomeIcon icon="exchange-alt"></FontAwesomeIcon> {t("Pick New Routine")}
                        </LinkButton>
                        {deloadButtonType === 'do' && (<LinkButton key="deload-btn" id="deload-btn" className="ml5" rounded color="primary" outlined to={deloadPathFor(basePathFor(clientId))}>
                            <FontAwesomeIcon icon="level-down-alt"></FontAwesomeIcon> {t("Deload")}
                        </LinkButton>)}
                        {deloadButtonType === 'undo' && (<LinkButton key="undeload-btn" id="undeload-btn" className="ml5" rounded color="red" outlined to={undeloadPathFor(basePathFor(clientId))}>
                            <FontAwesomeIcon icon="level-up-alt"></FontAwesomeIcon> {t("Undo deload")}
                        </LinkButton>)}
                    </div>)
                }
            }}
        </WorkoutPlanContext.Consumer>
    )
}

const WorkoutImageHeader = ({ workout, user }) => {
    const { t } = useTranslation();
    const imagePath = workout ? workout.imagePath() : Workout.restDayImage(user);
    const workoutName = workout ? _.abbrText(workout.displayName(t),30) : t("Rest Day");
    const history = useHistory();
    const clickHandler = workout ? (() => history.push(workoutPreviewPathFor(workout.date))) : null;

    return (
        <WorkoutPlanContext.Consumer>
            {clientId => (
            <div className={classnames("image-center-crop large pinned workout-head",{'with-simple-nav': !_.isBlank(clientId)})}>
                <WorkoutPlanContext.Consumer>
                    {clientId => (<WorkoutNotSyncedIcon workout={workout} basePath={basePathFor(clientId)} />)}
                </WorkoutPlanContext.Consumer>
                <PlaceholderImage width={500} height={334} color="primary" />
                {imagePath && (<img src={imagePath} alt="" />)}
                <div className="today-time bottom-left">
                    
                </div>
                <div className="today-workout main">
                    <div>{t("Today's Workout")}</div>
                    <div className="today-workout-title-card">
                        {workoutName}
                        {workout && (<WorkoutTimeEstimate workout={workout} t={t} />)}
                    </div>
                </div>
                {workout && (<div className="wt-play-btn clickable play-workout-btn-tst" onClick={clickHandler}>
                    {!workout.logged && (<FontAwesomeIcon icon="play-circle" />)}
                    {workout.logged && (<FontAwesomeIcon icon="medal" className="medal-color" />)}
                </div>)}
            </div>
            )}
        </WorkoutPlanContext.Consumer>
        )
}

const SlidingContainer = ({ children }) => {
    return (
        <div className="sliding-background wp-page">
            <div className="row limit-50-h">
                {children}
            </div>
        </div>
    )
}

const WorkoutPlanSuccess = ({ date, workout, workouts, user, loadUser, lastLoadedDate }) => {

    const primaryWorkout = workout;
    const weeks = user.activeWorkoutWeeks();
    const { t } = useTranslation();

    return (
        <div>
            <ResponsiveContainer breakpoint={500} render={({ belowBreakpoint }) => {
                const TopComp = belowBreakpoint ? SlidingContainer : ActivityContainer;
                return (
                    <React.Fragment>
                        {belowBreakpoint && <WorkoutImageHeader workout={primaryWorkout} user={user} />}
                        <EditDeloadButtons t={t} user={user} belowBreakpoint={belowBreakpoint} type="fixed" />
                        <TopComp>
                            {!belowBreakpoint && (<div className="col s12 m12 l6">
                                <div className="max-list ml-center mt0">
                                <h4 className="conversion-heading ml0">{t("Today's Workout")}</h4>
                                <WorkoutPlanContext.Consumer>
                                    {clientId => (<WorkoutCard workout={primaryWorkout} basePath={basePathFor(clientId)} user={user} />)}
                                </WorkoutPlanContext.Consumer>
                                <h4 className="conversion-heading ml0">{t("Plan Info")}</h4>
                                <Card>
                                    <div className="wt-routine">{user.assignedRoutine.name}</div>
                                    <div className="divider wt-dividers "></div>
                                    <div>
                                        <div className="wt-total-days">
                                            {user.assignedRoutine.frequencyStr}
                                            <div className="days-subtitle">{t("days per week")}</div>
                                        </div>
                                        <div className="wt-min">
                                            {user.assignedRoutine.estTimeStr}
                                            <div className="min-subtitle">{t("mins per day")}</div>
                                        </div>
                                    </div>
                                    <div className="divider clearfix wt-dividers "></div>
                                    <div className="wt-details pl5 pr5">{user.assignedRoutine.description}</div>
                                    <div className="divider wt-dividers "></div>
                                    <div className="deload-btns db-left">
                                        <EditDeloadButtons t={t} user={user} belowBreakpoint={belowBreakpoint} type="card" />
                                    </div>
                                </Card>
                                </div>
                            </div>)}
                            <div className="col s12 m12 l6">
                                <div className="max-list ml-center mt0">
                                    {belowBreakpoint && (<div className="wt-routine-title">{user.assignedRoutine.name}</div>)}
                                    <h4 className="conversion-heading ml0 hide-on-med-and-down">{t("Workout Schedule")}</h4>
                                    <SectionTabs activeTab={1} render={({ activeTab }) => {
                                        return (
                                            <WorkoutWeekTab 
                                                lastLoadedDate={lastLoadedDate} 
                                                startDate={weeks[activeTab]} 
                                                key={weeks[activeTab].format(dateFormat)} 
                                                loadUser={loadUser} 
                                                workouts={workouts} 
                                                date={date} 
                                            />
                                        )
                                    }}>
                                        <SectionTab>{t("Last Week")}</SectionTab>
                                        <SectionTab>{t("This Week")}</SectionTab>
                                        <SectionTab>{t("Next Week")}</SectionTab>
                                    </SectionTabs>
                                </div>
                            </div>
                        </TopComp>
                </React.Fragment>
                )
            }} />
        </div>
    )
}

class WorkoutPlanCore extends React.Component {

    render() {
        const { date, user, loadUser, workout, workouts, lastLoadedDay } = this.props;
        if(user.workoutPlanSetupAndVisible()) {
            return (
                <WorkoutPlanSuccess 
                    date={date}
                    user={user}
                    workouts={workouts}
                    loadUser={loadUser}
                    workout={workout}
                    lastLoadedDate={moment(lastLoadedDay)}
                />
            )
        } else if(user.canPickOwnRoutine()) {
            return (
                <WorkoutPlanStandaloneSetupSection user={user} />
            )
        } else {
            return (
                <PlanNotReady type={TRAINING} />
            )
        }
    }
}

const mapDispatchToAssignRoutineProps = dispatch => ({
    beforeAction: () => dispatch(clearInitialSignup()),
    afterPath: workoutPlanPath
})

export const AssignRoutine = connect(null,mapDispatchToAssignRoutineProps)(Connector)


const mapStateToWPProps = (state,props) => {
    const workoutsSelector = getActiveWorkoutsSelector(props.date.format(dateFormat));
    return {
        user: userWithRoutineSelector(state),
        workouts: workoutsSelector(state,props),
        lastLoadedDay: lastLoadedWorkoutDaySel(state)
    }
}


const mapDispatchToWPProps = dispatch => ({
    loadUser: (curDate) => dispatch(loadUser({ curDate }))
})

const ConnectedWorkoutPlan = connect(mapStateToWPProps,mapDispatchToWPProps)(WorkoutPlanCore);
const WithWorkout = withWorkout('fullLoad','page',false,true)(ConnectedWorkoutPlan);

class WorkoutPlan extends React.Component {

    constructor(props) {
        super(props);
        this.date = moment(moment().format(dateFormat));
    }

    render() {
        return (
            <React.Fragment>
                <WithWorkout date={this.date} />
                <WorkoutPlanContext.Consumer>
                    {clientId => (
                        <React.Fragment>
                            <SyncWorkoutsModal basePath={basePathFor(clientId)} />  
                            <DeloadModal basePath={basePathFor(clientId)} />
                            <UndeloadModal basePath={basePathFor(clientId)} />
                            <SignupModal basePath={basePathFor(clientId)} />
                            <PickNewRoutineModal basePath={basePathFor(clientId)} />
                        </React.Fragment>
                    )}
                </WorkoutPlanContext.Consumer>
            </React.Fragment>
        )
    }
}

const standaloneTmap = {
    rules: [
        [[...allMainMatches,clientDetailsMatch,rootPath],transitions.slideOut],
        [workoutPlanSlideOverPaths,transitions.slideOver]
    ]
};

const WorkoutPlanContext = React.createContext(null);

let StandaloneWorkoutPlanPage = ({ trainer, user, scrollRef, setupTransitions, switchClient, match: { params: { clientId } } }) => {
    const { t } = useTranslation();
    const client = (user.id === Number(clientId)) ? user : trainer.clientById(clientId);

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            setupTransitions={setupTransitions} 
            transitionMap={standaloneTmap}
            title={t("name's workout plan", { name: (client && client.shortName()) })}
        >
            <TrainerPaywall type={TRAINING}>
                <WorkoutPlanContext.Provider value={client && client.id}>
                    <div className="wp-container">
                        <HideOnExit>
                            <Loader
                                load={() => switchClient(clientId)}
                                preloaded={() => (user.id === client.id)}
                                successComponent={WorkoutPlan}
                                type='page'
                            />
                        </HideOnExit>
                    </div>
                </WorkoutPlanContext.Provider>
            </TrainerPaywall>
        </SimpleNavPage>
    )
}

const mapStateToStandaloneProps = state => ({
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state)
})

const mapDispatchToStandaloneProps = dispatch => ({
    switchClient: (clientId,focusDate) => dispatch(switchClient(clientId,focusDate))
})

StandaloneWorkoutPlanPage = connect(mapStateToStandaloneProps,mapDispatchToStandaloneProps)(StandaloneWorkoutPlanPage);

export { StandaloneWorkoutPlanPage }
export default WorkoutPlan;