import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { userRecordSelector } from 'redux/selectors';
import { restoreSubscription } from 'redux/actions';
import { Loader, PDLoading } from 'components/LoadingHOC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DuplicateError, withStore } from 'views/Conversion';
import { supportEmail } from 'config/settings';
import Button from 'components/Button';
import * as _ from 'lib/utilities';

class RestoreVerified extends React.Component {

    constructor(props) {
        super(props);
        const { setShowDelete } = this.props;
        setShowDelete(false);
    }

    render() {
        const { t } = this.props;

        return (
            <div className="text-center success-color white-bcg pa5">
                <FontAwesomeIcon icon="check-circle" size="2x" />
                <div>{t("Verified.")}</div>
            </div>
        )
    }
}

const SubscriptionRestored = ({ storeStatus, duplicateInfo, user, attempt, setAttempt, setShowDelete }) => {

    const { t } = useTranslation();

    if(user.isPro()) {

        return (
            <RestoreVerified setShowDelete={setShowDelete} t={t} />
        )
    } else if(storeStatus === 'duplicate_error') {

        return (<DuplicateError duplicateInfo={duplicateInfo} />)

    } else {
        return (
            <div className="text-center red-text white-bcg pa5">
                <FontAwesomeIcon icon="times" size="2x" />
                <div>{t("Couldn't find an active subscription for this account",{support_email: supportEmail })}</div>
                {attempt < 4 && (<div className="ma20 text-center">
                    <Button color="primary" onClick={() => setAttempt(attempt+1)} rounded>{t('Try Again')}</Button>
                </div>)}
            </div>
        )
    }
}

class SubscriptionRestorer extends React.Component {

    constructor(props) {
        super(props);

        if(window.cordova) {
            const store = _.getIapStore();
            store.update().then(() => store.restorePurchases()).then(() => this.setState({ storeChecked: true }));
            this.state = { storeChecked: false }
        } else {
            this.state = { storeChecked: true }
        }
    }

    render() {
        const { storeChecked } = this.state;
        const { user, storeStatus, duplicateInfo, restore, attempt, setAttempt, setShowDelete } = this.props;

        if(storeChecked && storeStatus !== 'verifying') {
            return (
                <Loader
                    successComponent={SubscriptionRestored}
                    user={user}
                    type="padded"
                    load={restore}
                    storeStatus={storeStatus}
                    duplicateInfo={duplicateInfo}
                    preloaded={() => user.isPro()}
                    attempt={attempt}
                    setAttempt={setAttempt}
                    setShowDelete={setShowDelete}
                />
            )
        } else {
            return (
                <PDLoading />
            )
        }
    }

    componentWillUnmount() {
        if(this.refreshTimeout) {
            clearTimeout(this.refreshTimeout);
        }
    }
}

SubscriptionRestorer = withStore(SubscriptionRestorer);

const SyncSubscription = (props) => {
    const { user, restore, setShowDelete } = props;
    const { t } = useTranslation();
    const [ active,setActive ] = useState(false);
    const [ attempt,setAttempt ] = useState(1);

    if(active) {
        return (
            <SubscriptionRestorer
                key={attempt}
                user={user}
                restore={restore}
                attempt={attempt}
                setShowDelete={setShowDelete}
                setAttempt={setAttempt}
            />
        )
    } else {
        return (
            <div className="ma20 text-center">
                <Button color="primary" onClick={() => setActive(true)} rounded>
                    <FontAwesomeIcon icon="cloud-download-alt" />
                    {t('Restore Subscription')}
                </Button>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    user: userRecordSelector(state)
})

export const mapDispatchToProps = dispatch => ({
    restore: () => dispatch(restoreSubscription())
})

export default connect(mapStateToProps,mapDispatchToProps)(SyncSubscription)