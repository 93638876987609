import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import Collapse from 'components/Collapse';
import { SlightEmphasisIconNote } from 'components/Typography';
import { useTranslation } from 'react-i18next';
import { logHabit } from 'redux/actions';
import { connect } from 'react-redux';
import FormikWrapper from 'components/FormikWrapper';
import { CheckboxCore, MultivalueCheckbox } from 'components/Form';
import { Habit, HabitLog } from 'lib/classes';
import moment from 'moment';
import { dateFormat } from 'config/settings';
import classnames from 'classnames';
import Progress from 'components/Progress';

const HabitLogPreview = ({ type, name, description, goalQuantity }) => {
    const habit = new Habit({ name, description, type, goalQuantity });
    const habitLog = new HabitLog({ habit, date: moment().format(dateFormat), logged: false, goalQuantity, loggedQuantity: Math.floor(goalQuantity/3) });

    if(type === 'steps') {
        return (
            <React.Fragment>
                <div className="mt10">
                    <div className="faint-color tiny-text">
                        In the iOS and Android app (steps pulled from phone's step counter)
                    </div>
                    <ProgBarCard habitLog={habitLog} />
                </div>
                <div className="mt10">
                    <div className="faint-color tiny-text">
                        On platforms without a step counter (client has to manually check off)
                    </div>
                    <CheckOffQtyCard habitLog={habitLog} />
                </div>
            </React.Fragment>
        )
    }

    return (
        <UnconnectedHabitLogCard 
            habitLog={habitLog}
            className="mt0"
        />
    )
}

const ProgBarCard = ({ habitLog, className }) => {
    const { t } = useTranslation();

    return (
        <ListCard className={classnames("habit-log-card",{ [className]: className })}>
            <div className="flex-grow ml10 mr10 pt5">
                <div className="position-relative">
                    <div className="font-grey">
                        <FontAwesomeIcon icon={habitLog.goalIcon()} /> {habitLog.goalTitle(t)}
                    </div>
                    <Progress percent={habitLog.percentComplete()} fat gradient classNames="mt0" />
                    {[0,habitLog.goalQuantity].map((qty,index) => (
                        <span key={qty} className="progress-bar-rule" style={{ left: `${index*100}%` }}>
                            <div className="rule-label">
                                <div className="no-wrap">{qty} {habitLog.goalSuffix(t)}</div>
                            </div>
                        </span>
                    ))}
                </div>
            </div>
        </ListCard>
    )
}

const CheckOffQtyCard = ({ habitLog, log, readOnly, className }) => {
    const { t } = useTranslation();

    return (
        <ListCard className={classnames("habit-log-card",{ [className]: className })} >
            <ListCardAction className="left no-border inactive">
                {!readOnly && (<FormikWrapper 
                    initialValues={habitLog.initialFormValues()}
                    submit={log ? log : () => Promise.resolve({ status: 'SUCCESS' })}
                    autoSubmit
                    render={(rest) => {

                        return (
                            <MultivalueCheckbox 
                                name="loggedQuantity"
                                checkedVal={habitLog.goalQuantity}
                                uncheckedVal={0}
                                coreOnly
                                {...rest}
                                id={`habit-log-${habitLog.id}-check`}
                            />
                        )
                    }}
                />)}
            </ListCardAction>
            <ListCardTitle>{habitLog.habit.resolvedName(t)}</ListCardTitle>
        </ListCard>
    )
}

const QtyHabitLogCard = ({ habitLog, log, readOnly, className }) => {

    if(habitLog.showQuantityCard(readOnly)) {
        return (
            <ProgBarCard habitLog={habitLog} className={className} />
        )
    }

    return (
        <CheckOffQtyCard habitLog={habitLog} log={log} readOnly={readOnly} className={className} />
    )

}

const UnconnectedHabitLogCard = ({ habitLog, log, readOnly, className, overrideQty }) => {
    const { t } = useTranslation();
    const [expanded,setExpanded] = useState(false);
    const { habit } = habitLog;

    if(habitLog.isQuantityType()) {
        return (
            <QtyHabitLogCard 
                habitLog={habitLog}
                log={log}
                readOnly={readOnly}
                className={className}
                forceQty={overrideQty}
            />
        )
    }

    return (
        <ListCard className={classnames("habit-log-card",{ [className]: className })} collapse={(<Collapse isOpen={expanded}>
            <div className="ma5"><SlightEmphasisIconNote text={habit.resolvedDescription(t)} /></div>
        </Collapse>)}>
            <ListCardAction className="left no-border inactive">
                {!readOnly && (<FormikWrapper 
                    initialValues={habitLog.initialFormValues()}
                    submit={log ? log : () => Promise.resolve({ status: 'SUCCESS' })}
                    autoSubmit
                    render={(rest) => {

                        return (
                            <CheckboxCore 
                                inputProps={{ 
                                    name: 'logged', 
                                    onChange: rest.handleAutoSubmitChange, 
                                    onBlur: rest.handleBlur, 
                                    value: true,
                                    checked: rest.values.logged,
                                    readOnly: readOnly
                                } } 
                                filled 
                                id={`habit-log-${habitLog.id}-check`}
                            />
                        )
                    }}
                />)}
                {readOnly && (<FontAwesomeIcon id={`habit-${habitLog.isLogged() ? 'check' : 'ex'}-${habitLog.habitId}`} className={habitLog.isLogged() ? "success-color" : "red-text"} icon={habitLog.isLogged() ? "check" : "times"} />)}
            </ListCardAction>
            <ListCardTitle>{habit.resolvedName(t)}</ListCardTitle>
            <ListCardAction onClick={() => setExpanded(!expanded )}>
                <FontAwesomeIcon icon={"info-circle"} />
            </ListCardAction>
        </ListCard>
    )
}

const mapDispatchToProps = dispatch => ({
    log: data => dispatch(logHabit(data))
})

const HabitLogCard = connect(null,mapDispatchToProps)(UnconnectedHabitLogCard)

export { HabitLogCard, HabitLogPreview }