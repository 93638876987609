import Component from './component';
import $ from './cash';
import anim from './anime';
import M from './global';

  const useAnim = process.env.REACT_APP_TEST !== 'true'

  let _defaults = {
    opacity: 0.5,
    inDuration: useAnim ? 300 : 0,
    outDuration: useAnim ? 300 : 0,
    onOpenStart: null,
    onOpenEnd: null,
    onCloseStart: null,
    onCloseEnd: null,
    preventScrolling: true,
    dismissible: true,
    startingTop: '4%',
    endingTop: '10%'
  };

  /**
   * @class
   *
   */
  class Modal extends Component {
    /**
     * Construct Modal instance and set up overlay
     * @constructor
     * @param {Element} el
     * @param {Object} options
     */
    constructor(el, options) {
      super(Modal, el, options);

      this.el.M_Modal = this;

      /**
       * Options for the modal
       * @member Modal#options
       * @prop {Number} [opacity=0.5] - Opacity of the modal overlay
       * @prop {Number} [inDuration=250] - Length in ms of enter transition
       * @prop {Number} [outDuration=250] - Length in ms of exit transition
       * @prop {Function} onOpenStart - Callback function called before modal is opened
       * @prop {Function} onOpenEnd - Callback function called after modal is opened
       * @prop {Function} onCloseStart - Callback function called before modal is closed
       * @prop {Function} onCloseEnd - Callback function called after modal is closed
       * @prop {Boolean} [dismissible=true] - Allow modal to be dismissed by keyboard or overlay click
       * @prop {String} [startingTop='4%'] - startingTop
       * @prop {String} [endingTop='10%'] - endingTop
       */
      this.options = $.extend({}, Modal.defaults, options);

      /**
       * Describes open/close state of modal
       * @type {Boolean}
       */
      this.isOpen = false;

      this.id = this.$el.attr('id');
      this._openingTrigger = undefined;
      this.$overlay = $('<div class="modal-overlay"></div>');
      this.el.tabIndex = 0;
      this._nthModalOpened = 0;
      this.dragStart = null;
      this.dragY = 0;

      Modal._count++;
      this._setupEventHandlers();
    }

    static get defaults() {
      return _defaults;
    }

    static init(els, options) {
      return super.init(this, els, options);
    }

    /**
     * Get Instance
     */
    static getInstance(el) {
      let domElem = !!el.jquery ? el[0] : el;
      return domElem.M_Modal;
    }

    /**
     * Teardown component
     */
    destroy() {
      Modal._count--;
      this._removeEventHandlers();
      this.el.removeAttribute('style');
      this.$overlay.remove();
      this.el.M_Modal = undefined;
    }

    /**
     * Setup Event Handlers
     */
    _setupEventHandlers() {
      this._handleOverlayClickBound = this._handleOverlayClick.bind(this);
      this._handleModalCloseClickBound = this._handleModalCloseClick.bind(this);
      this._handleMouseDownBound = this._handleMouseDown.bind(this);
      this._handleMouseMoveBound = this._handleMouseMove.bind(this);
      this._handleMouseUpBound = this._handleMouseUp.bind(this);
      this._handleScrollingBound = this._handleScrolling.bind(this);


      if (Modal._count === 1) {
        document.body.addEventListener('click', this._handleTriggerClick);
      }
      this.$overlay[0].addEventListener('click', this._handleOverlayClickBound);
      this.el.addEventListener('click', this._handleModalCloseClickBound);
      this.el.addEventListener('mousedown',this._handleMouseDownBound,);
      this.el.addEventListener('touchstart',this._handleMouseDownBound);
      document.addEventListener('scroll',this._handleScrollingBound);
    }

    /**
     * Remove Event Handlers
     */
    _removeEventHandlers() {
      if (Modal._count === 0) {
        document.body.removeEventListener('click', this._handleTriggerClick);
      }
      this.$overlay[0].removeEventListener('click', this._handleOverlayClickBound);
      this.el.removeEventListener('click', this._handleModalCloseClickBound);
      this.el.removeEventListener('mousedown',this._handleMouseDownBound);
      this.el.removeEventListener('mousemove',this._handleMouseMoveBound);
      this.el.removeEventListener('mouseup',this._handleMouseUpBound);
      this.el.removeEventListener('touchstart',this._handleMouseDownBound);
      this.el.removeEventListener('touchmove',this._handleMouseMoveBound);
      this.el.removeEventListener('touchend',this._handleMouseUpBound);
      document.removeEventListener('scroll',this.handleScrollingBound);
    }

    /**
     * Handle Trigger Click
     * @param {Event} e
     */
    _handleTriggerClick(e) {
      let $trigger = $(e.target).closest('.modal-trigger');
      if ($trigger.length) {
        let modalId = M.getIdFromTrigger($trigger[0]);
        let modalInstance = document.getElementById(modalId).M_Modal;
        if (modalInstance) {
          modalInstance.open($trigger);
        }
        e.preventDefault();
      }
    }

    /**
     * Handle Overlay Click
     */
    _handleOverlayClick() {
      if (this.options.dismissible) {
        this.close();
      }
    }

    /**
     * Handle Modal Close Click
     * @param {Event} e
     */
    _handleModalCloseClick(e) {
      let $closeTrigger = $(e.target).closest('.modal-close');
      if ($closeTrigger.length) {
        this.close();
      }
    }

    _handleScrolling(e) {

      if(this.dragStart) {
        this.dragStart = null;
      }
    }

    _handleMouseDown(e) {
      if (this.options.dismissible && e.type === 'touchstart') {
        this.dragStart = e.touches[0].clientY;
        this.el.addEventListener('mousemove',this._handleMouseMoveBound);
        this.el.addEventListener('mouseup',this._handleMouseUpBound);
        this.el.addEventListener('touchmove',this._handleMouseMoveBound);
        this.el.addEventListener('touchend',this._handleMouseUpBound);      
      }
    }

    _handleMouseMove(e) {
      let currentY;
      if(e.type === 'touchmove') {
        currentY = e.touches[0].clientY;

        if(this.dragStart) {
          this.dragged = true;
          this.dragY = currentY - this.dragStart;
          if(this.dragY > 0) {
            this.el.style.transform = `translateY(${this.dragY}px)`;
          }
        }
      }
    }

    _handleMouseUp(e) {
      let currentY;
      let close = false;
      if(e.type === 'touchend') {
        currentY = e.changedTouches[0].clientY;

        if(this.dragStart && this.dragged) {
          this.dragY = currentY - this.dragStart;
          if(this.dragY > 125) {
            close = true;
          }
        }
        this.el.removeEventListener('mousemove',this._handleMouseMoveBound);
        this.el.removeEventListener('mouseup',this._handleMouseUpBound);
        this.el.removeEventListener('touchmove',this._handleMouseMoveBound);
        this.el.removeEventListener('touchend',this._handleMouseUpBound);
        if(close) {
          this.close();
        } else {
          let unDragOptions = {
            targets: this.el,
            duration: this.options.inDuration/2,
            easing: 'easeOutCubic',
            translateY: '0'
          };
          anim(unDragOptions);
        }
        this.dragStart = null;
        this.dragY = 0;
        this.dragged = false;
      }
    }

    /**
     * Handle Keydown
     * @param {Event} e
     */
    _handleKeydown(e) {
      // ESC key
      if (e.keyCode === 27 && this.options.dismissible) {
        this.close();
      }
    }

    /**
     * Handle Focus
     * @param {Event} e
     */
    _handleFocus(e) {
      // Only trap focus if this modal is the last model opened (prevents loops in nested modals).
      if (!this.el.contains(e.target) && this._nthModalOpened === Modal._modalsOpen) {
        this.el.focus();
      }
    }

    /**
     * Animate in modal
     */
    _animateIn() {
      // Set initial styles
      document.body.classList.add('modal-open');
      $.extend(this.el.style, {
        display: 'block',
        opacity: 1,
        transform: 'translateY(100%)',
        top: this.options.endingTop,
        position: 'fixed'
      });
      $.extend(this.$overlay[0].style, {
        display: 'block',
        opacity: this.options.opacity
      });

      // Define modal animation options
      let enterAnimOptions = {
        targets: this.el,
        duration: this.options.inDuration,
        easing: 'easeOutQuad',
        // Handle modal onOpenEnd callback
        complete: () => {
          this.el.style.removeProperty('position');
          if (typeof this.options.onOpenEnd === 'function') {
            this.options.onOpenEnd.call(this, this.el, this._openingTrigger);
          }
        }
      };

      // Bottom sheet animation
      if (this.el.classList.contains('bottom-sheet')) {
        $.extend(enterAnimOptions, {
          bottom: 0,
          opacity: 1
        });
        anim(enterAnimOptions);

        // Normal modal animation
      } else {
        $.extend(enterAnimOptions, {
          translateY: "0"
        });
        anim(enterAnimOptions);
      }
    }

    /**
     * Animate out modal
     */
    _animateOut(endOverflow) {
      document.body.style.overflow = 'hidden';
      $.extend(this.el.style, {
        display: 'block',
        opacity: 1,
        transform: `translateY(${((this.dragY || 0)/(this.el.clientHeight || 1))*100}%)`,
        top: this.options.endingTop
      });

      // Define modal animation options
      let exitAnimOptions = {
        targets: this.el,
        duration: this.options.outDuration,
        easing: 'easeInQuad',
        // Handle modal ready callback
        complete: () => {
          this.el.style.removeProperty('position');
          this.el.classList.remove('closing');
          this.el.style.display = 'none';
          this.$overlay.remove();
          document.body.style = endOverflow;
          if(Modal._modalsOpen === 0) {
            document.body.classList.remove('modal-open');
          }

          // Call onCloseEnd callback
          if (typeof this.options.onCloseEnd === 'function') {
            this.options.onCloseEnd.call(this, this.el);
          }
        }
      };

      // Bottom sheet animation
      if (this.el.classList.contains('bottom-sheet')) {
        $.extend(exitAnimOptions, {
          bottom: '-100%',
          opacity: 0
        });
        anim(exitAnimOptions);

        // Normal modal animation
      } else {
        $.extend(exitAnimOptions, {
          translateY: "100%"
        });
        anim(exitAnimOptions);
      }
    }

    /**
     * Open Modal
     * @param {cash} [$trigger]
     */
    open($trigger) {
      if (this.isOpen) {
        return;
      }

      this.isOpen = true;
      Modal._modalsOpen++;
      this._nthModalOpened = Modal._modalsOpen;

      // Set Z-Index based on number of currently open modals
      this.$overlay[0].style.zIndex = 1000 + Modal._modalsOpen * 2;
      this.el.style.zIndex = 1000 + Modal._modalsOpen * 2 + 1;

      // Set opening trigger, undefined indicates modal was opened by javascript
      this._openingTrigger = !!$trigger ? $trigger[0] : undefined;

      // onOpenStart callback
      if (typeof this.options.onOpenStart === 'function') {
        this.options.onOpenStart.call(this, this.el, this._openingTrigger);
      }

      if (this.options.preventScrolling) {
        document.body.style.overflow = 'hidden';
      }

      this.el.classList.add('open');
      this.el.insertAdjacentElement('afterend', this.$overlay[0]);

      if (this.options.dismissible) {
        this._handleKeydownBound = this._handleKeydown.bind(this);
        this._handleFocusBound = this._handleFocus.bind(this);
        document.addEventListener('keydown', this._handleKeydownBound);
        document.addEventListener('focus', this._handleFocusBound, true);
      }

      anim.remove(this.el);
      anim.remove(this.$overlay[0]);
      this._animateIn();

      // Focus modal
      this.el.focus();

      return this;
    }

    /**
     * Close Modal
     */
    close() {
      if (!this.isOpen) {
        return;
      }

      this.isOpen = false;
      Modal._modalsOpen--;
      this._nthModalOpened = 0;

      // Call onCloseStart callback
      if (typeof this.options.onCloseStart === 'function') {
        this.options.onCloseStart.call(this, this.el);
      }

      this.el.classList.remove('open');
      this.el.classList.add('closing');


      // Enable body scrolling only if there are no more modals open.
      let endOverflow = document.body.style.overflow;

      if (this.options.dismissible) {
        document.removeEventListener('keydown', this._handleKeydownBound);
        document.removeEventListener('focus', this._handleFocusBound, true);
      }

      anim.remove(this.el);
      anim.remove(this.$overlay[0]);
      this._animateOut(endOverflow);
      return this;
    }
  }

  /**
   * @static
   * @memberof Modal
   */
  Modal._modalsOpen = 0;

  /**
   * @static
   * @memberof Modal
   */
  Modal._count = 0;

export default Modal;
