import * as _ from 'lib/utilities';


export const trainerFormsConcern = {

    activeTeamForms(user) {
        if(this.isMaster()) {
            return _.filter(this.ownedForms,form => (form.isTeam() && form.isVisible(user || this)));
        }

        return this.getMasterAccount().activeTeamForms(this);
    },

    activeTrainerOwnedForms() {
        return _.filter(this.ownedForms,form => (!form.isTeam() && form.isVisible(this)));
    },

    activeTrainerPersonalForms() {
        return [ ...this.activeTeamForms(), ...this.activeTrainerOwnedForms() ];
    },

    getFormById(id) {
        return _.find(this.activeTrainerPersonalForms(),form => (form.id === Number(id)));
    }
}