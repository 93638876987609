import React from 'react';
import Charts from 'partials/ProgressCharts';
import { useTranslation } from 'react-i18next';
import { workoutSetupMatch, pickExercisePath, emailPaywallMatches, allMainMatches, progressPath, clientDetailsMatch, bodyMeasurementsPath, viewAssignedFormMatch, viewClientAssignedFormMatch, progressPathFor } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import PaywallWrapper from 'partials/PaywallWrapper';
import ClientSwitcher from 'partials/ClientSwitcher';
import * as _ from 'lib/utilities';


const transitionMap = {
    rules: [
        [[ ...allMainMatches, clientDetailsMatch, viewAssignedFormMatch, viewClientAssignedFormMatch ],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,bodyMeasurementsPath], transitions.slideOver]
    ]
};

export const MainProgressPage = (props) => {
    const { setActiveTab, activeTab } = props;

    return (
        <PaywallWrapper blockTypes={['hard','soft']} context="progress">
            <Charts setActiveTab={setActiveTab} activeTab={activeTab} isMain />
        </PaywallWrapper>
    )
}

export const ClientProgressShortcut = (props) => {
    const { match: { params: { exerciseId } } } = props;
    const { t } = useTranslation();

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('Progress')} afterPath={_.isBlank(exerciseId) ? progressPath : progressPathFor(exerciseId)} />
    )
}

const ProgressPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, setActiveTab, activeTab, match: { params: { exerciseId } } } = props;
    const resolvedActiveTab = (!_.isBlank(exerciseId) && _.isBlank(activeTab)) ? 2 : activeTab;

    return (
        <PaywallWrapper blockTypes={['hard','soft']} context="progress">
            <SimpleNavPage
                className="with-responsive-tabs"
                containerClass="with-responsive-tabs"
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t('Progress')}
            >
                <Charts setActiveTab={setActiveTab} activeTab={resolvedActiveTab} exerciseId={exerciseId} />
            </SimpleNavPage>
        </PaywallWrapper>
    )
}

export default ProgressPage;