import React, {Component} from 'react';
import { withTranslation, useTranslation } from 'react-i18next';
import FlowForm, { FlowPageContent, FlowQuestionContainer, FlowQuestion, NextButton, SmallCardOption } from 'components/FlowForm';
import { homePath, mainMatches, trainerMainMatches, rootPath, trainerSignupBase, appExitPath, emailSignupMatch, trainerConversionMatch, trainerHomePath, trainerSignupPathFor, trainerBasicProfileBaseFor, trainerBasicProfileMatch, trainerSignupMatch } from 'config/paths';
import { Redirect } from "components/Routing";
import { formikHandlerCreator } from 'lib/utilities';
import { FullInput, LoaderOnlySubmitButton, ToggleButton } from 'components/Form';
import { advanceTrainerSignup, createTrainer, clearTrainerSignup, updateTBasicProfile } from 'redux/actions';
import { trainerRecordSelector, trainerSignupFlowSel, userRecordSelector } from 'redux/selectors'
import { connect } from 'react-redux';
import { User } from 'lib/classes';
import TextInput from 'components/TextInput';
import * as _ from 'lib/utilities';
import * as transitions from 'assets/transitions';
import Page from 'components/Page';
import { fullTrainerConvPathFor, resolvedHomePath } from 'redux/helpers';
import { FlexIconTip } from 'components/Tooltipped';
import { IconNote } from 'components/Typography';
import { Loader } from 'components/LoadingHOC';
import { useHistory } from 'react-router-dom';
import { FULL, NUTRITION, TRAINING, TSIGNUP } from 'config/settings';
import Connector from 'partials/WebsiteConnector';

const flowTransitionMap = {
    rules: [
        [mainMatches,transitions.slideOut],
        [trainerMainMatches,transitions.slideOver],
        [rootPath,transitions.slideOut],
        [[emailSignupMatch,trainerConversionMatch,trainerBasicProfileMatch],transitions.slideOver]
    ]
};

const bpTransitionMap = {
    rules: [
        [[...mainMatches,...trainerMainMatches,trainerSignupMatch],transitions.slideOut],
        [[emailSignupMatch,trainerConversionMatch],transitions.slideOver],
        [rootPath,transitions.slideOut]
    ]
};

const getGoToPaywall = values => false;

const NameAndUnitsQuestion = ({ basicProfileOnly, flowProps, loadState }) => {
    const { t } = useTranslation();
    const { values, setFieldValue } = flowProps;

    return (
        <div>
            {basicProfileOnly && (<FlowQuestion text={t('Make an account')} />)}
            <div className="valign-wrapper">
                <FullInput
                    label={t("Business Name")}
                    className="flex-grow"
                    name="businessName"
                    component={TextInput}
                    formikProps={flowProps}
                />
                <FlexIconTip icon='question-circle' msg={t('bizname tip')} className="mb15" />
            </div>
            <div className="valign-wrapper">
                <FullInput
                    label={t("First Name")}
                    name="firstName"
                    component={TextInput}
                    formikProps={flowProps}
                />
                <FullInput
                    label={t("Last Name")}
                    name="lastName"
                    className="ml5"
                    component={TextInput}
                    formikProps={flowProps}
                />
            </div>
            <div>
                <div className="font-grey">{t("Unit Preference")}</div>
                <ToggleButton 
                    id='toggle-units-btn'
                    className="btn-no-shadow no-capital" 
                    options={{[`${t('lbs')}/${t('ft')}`]: 0, [`${t('kgs')}/${t('cm')}`]: 1}} 
                    value={values.unitPreference} 
                    setValue={value => setFieldValue('unitPreference',value)} 
                />
            </div>
            <div className="text-center mt25">
                {!basicProfileOnly && (<NextButton 
                    className="btn-wide mt20"
                    label={t('Next')} 
                    advance={flowProps.advance} 
                    validateForm={flowProps.validateForm} 
                    values={flowProps.values} 
                    isValid={flowProps.isValid}
                    setTouched={flowProps.setTouched}
                    id='next-btn'
                />)}
                {basicProfileOnly && (
                    <LoaderOnlySubmitButton 
                        icon="arrow-right" 
                        label={t("Next")} 
                        className="btn-wide mt20" 
                        loadState={loadState} 
                        id="next-btn"
                    />
                )}
            </div>
        </div>
    )
}

const validateForm = (t,skipPlanType,page,values) => {
    switch(page) {
        case 1: {
            if(skipPlanType) {
                return {};
            }
            const errs = {};
            ['businessName','firstName','lastName'].forEach(attr => {
                if(_.isBlank(values[attr])) {
                    errs[attr] = 'required';
                }
            })
            return errs;
        }
        default: {
            return {};
        }
    }
}

const MakeTrainerLoader = ({ user, createTrainerAction, flowProps: { setValues, values }, component, flowProps, ...rest }) => {
    const history = useHistory();
    const goToPaywall = getGoToPaywall(values);

    return (
        <Loader
            successComponent={() => ''}
            defaultComponent={component}
            successCallback={() => {
                if(goToPaywall) {
                    history.push(fullTrainerConvPathFor(TSIGNUP));
                } else {
                    history.push(trainerHomePath);
                }
            }}
            type="loaderOnly"
            load={() => {
                const { planType, ...trainer } = values;
                return createTrainerAction({ planType, trainer }); 
            }}
            setValues={setValues}
            values={values}
            preloaded={() => false}
            skipAutoLoad
            user={user}
            flowProps={flowProps}
            {...rest}
        />
    )
}

const AppOrPdfCore = ({ load, skipPlanType, t, flowProps: { setValues, values, advance } }) => {
    const clickHandler = value => () => {
        if(skipPlanType) {
            setValues({ ...values, trainerType: value });
            setTimeout(load,0);
        } else {
            advance({ ...values, trainerType: value },true)
        }
    }

    return (
        <React.Fragment>
            <SmallCardOption 
                onClick={clickHandler(User.TRAINER_TYPES.app)} 
                title={t("Via App/Web")} 
                subtitle={t("app trainer description")}
                id='app-trainer-btn'
                className="larger"
            />
            <SmallCardOption 
                onClick={clickHandler(User.TRAINER_TYPES.pdf)} 
                title={t("Via PDF/email/paper")} 
                subtitle={t("pdf trainer description")}
                id='pdf-trainer-btn' 
                className="larger"
            />
            <div className="mt50">
                <IconNote text={t('you can change later')} />
            </div>      
        </React.Fragment>
    )
}

const AppOrPdfQuestion = ({ user, skipPlanType, createTrainerAction, flowProps }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <FlowQuestion text={t("app or pdf trainer question")} />
            <div className="inline-block">
                <MakeTrainerLoader 
                    user={user}
                    skipPlanType={skipPlanType}
                    createTrainerAction={createTrainerAction}
                    flowProps={flowProps}
                    component={AppOrPdfCore}
                    t={t}
                />
            </div>
        </React.Fragment>
    )
}

const FeatureTypeQuestionsCore = ({ load, setValues, values }) => {
    const { t } = useTranslation();
    const clickHandler = value => () => {
        setValues({ ...values, planType: value });
        setTimeout(load,0);
    }

    return (
        <React.Fragment>
            <SmallCardOption 
                onClick={clickHandler(NUTRITION)} 
                title={t("Nutrition")} 
                subtitle={t("nutrition features desc")}
                id='nutrition-features-btn'
                className="larger"
            />
            <SmallCardOption 
                onClick={clickHandler(TRAINING)} 
                title={t("Training")} 
                subtitle={t("training features desc")}
                id='training-features-btn' 
                className="larger"
            />
            <SmallCardOption 
                onClick={clickHandler(FULL)} 
                title={t("Both")} 
                id='full-features-btn' 
                className="larger"
            />
        </React.Fragment>
    )
}

const FeatureTypeQuestion = ({ user, createTrainerAction, flowProps: { setValues, values, advance }, flowProps }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            <FlowQuestion text={t("Which features are you interested in?")} />
            <div className="inline-block text-center">
                <MakeTrainerLoader 
                    user={user}
                    createTrainerAction={createTrainerAction}
                    flowProps={flowProps}
                    component={FeatureTypeQuestionsCore}
                />
            </div>
        </React.Fragment>
    )
}

class TrainerProfileFlow extends Component {

    constructor(props) {
        super(props);
        this.state = { loadState: 'DEFAULT', errorMsg: null };
        this.setSubmitState = this.setSubmitState.bind(this);
    }

    setSubmitState(status,formikBag,data) {
        if(status === 'SUCCESS') {
            const { history, match: { params: { context } } } = this.props;
            const basicProfileOnly = !_.isBlank(context);
            this.setState({ loadState: 'SUCCESS', errorMsg: data.error });
            if(basicProfileOnly) {
                setTimeout(() => history.push(fullTrainerConvPathFor(context),0))
            }
        } else {
            this.setState({ loadState: status, errorMsg: null })
        }
    }

    render() {
        let { t, submit, advanceAction, initialValues, basePath, exitPath, startIndex, user, createTrainerAction, match: { params: { planType, context } } } = this.props;
        const { loadState } = this.state;
        const basicProfileOnly = !_.isBlank(context);
        const initVals = basicProfileOnly ? user.trainerSignupValues(planType) : initialValues
        const planTypeProvided = !_.isBlank(planType) && !_.isBlank(initialValues.planType);
        const goToPaywall = getGoToPaywall(initVals);
        const skipPlanType = (planTypeProvided || goToPaywall);

        return (
            <FlowForm 
                initialValues={initVals}
                validate={validateForm.bind(this,t,skipPlanType)}
                validateOnMount={true}
                advanceCallback={advanceAction}
                onSubmit={formikHandlerCreator(this,this.setSubmitState,submit)}
                basePath={basePath} 
                nextPage={(page,values) => (page+1)}
                exitPath={exitPath}
                startIndex={startIndex}>
                    <FlowPageContent render={(flowProps) => {
                            return (
                                <FlowQuestionContainer>
                                    <NameAndUnitsQuestion 
                                        basicProfileOnly={basicProfileOnly}
                                        loadState={loadState}
                                        flowProps={flowProps} 
                                        t={t} 
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent render={(flowProps) => {
                            return (
                                <FlowQuestionContainer className="larger">
                                    <AppOrPdfQuestion 
                                        user={user}
                                        flowProps={flowProps}
                                        createTrainerAction={createTrainerAction}
                                        skipPlanType={skipPlanType}
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
                    <FlowPageContent render={(flowProps) => {
                            return (
                                <FlowQuestionContainer className="larger">
                                    <FeatureTypeQuestion 
                                        user={user}
                                        flowProps={flowProps}
                                        createTrainerAction={createTrainerAction}
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
            </FlowForm>
        );
    }

    componentWillUnmount() {
        if(this.loadPromise){
            this.loadPromise.cancel();
        }
    }
}

const mapStateToProps = (state,props) => ({
    initialValues: trainerSignupFlowSel(state,props),
    user: trainerRecordSelector(state) || userRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(updateTBasicProfile(data)),
    advanceAction: (data) => dispatch(advanceTrainerSignup(data)),
    createTrainerAction: data => dispatch(createTrainer(data))
})

class TrainerFlowPage extends Component {

    constructor(props) {
        super(props);
        const { match: { params: { context } } } = this.props;
        const isBasicProfile = !_.isBlank(context);

        props.setupTransitions(isBasicProfile ? bpTransitionMap : flowTransitionMap);
    }

    render() {
        const { scrollRef, user, t, ...rest } = this.props;
        const { match: { params: { context } } } = this.props;
        const isBasicProfile = !_.isBlank(context);
        const shouldRedirect = user && !user.canBecomeTrainer(true) && !isBasicProfile;

        return (
        <Page ref={scrollRef}>
            <TrainerProfileFlow
                basePath={isBasicProfile ? trainerBasicProfileBaseFor(context) : trainerSignupBase}
                {...rest}
                user={user}
                exitPath={(user && user.hasBasicProfile) ? homePath() : appExitPath(true)}
                startIndex={1}
            />
            {shouldRedirect && <Redirect to={resolvedHomePath()} />}
        </Page>
        );
    }
}

const mapDispatchToMpInitProps = dispatch => ({
    beforeAction: () => dispatch(clearTrainerSignup()),
    afterPath: trainerSignupPathFor(2,NUTRITION)
})

const TrainerMpInit = connect(null, mapDispatchToMpInitProps)(Connector);

export { TrainerMpInit }

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(TrainerFlowPage));
