import React from 'react';
import * as _ from 'lib/utilities';
import * as api from 'lib/api';
import { connect } from 'react-redux';
import { androidStepsUpdate } from 'redux/actions';

class AndroidStepCounterPrompt extends React.Component {

    constructor(props) {
        super(props);
        const { user, updateSteps } = this.props;
        if(_.hasStepCounter() && !user.showNutritionOnly()) {
            if(!!window.stepcounter.start) {
                window.stepcounter.start(api.androidStepsUpdateUrl());
            }
            if(!!window.stepcounter.read) {
                window.stepcounter.read(res => updateSteps(res), err => console.log(err));
                this.setpcountInterval = setInterval(() => {
                    window.stepcounter.read(res => updateSteps(res), err => console.log(err));
                },1800000)
            }
        }
    }

    render() {
        return '';
    }

    componentWillUnmount() {
        if(this.setpcountInterval) {
            clearInterval(this.setpcountInterval)
        }
    }
}

const mapDispatchToProps = dispatch => ({
    updateSteps: data => dispatch(androidStepsUpdate(data))
})

export default connect(null,mapDispatchToProps)(AndroidStepCounterPrompt);