import React from 'react';
import Button from 'components/Button';
import { BackButton } from 'components/LinkButton';
import Progress from 'components/Progress';
import { withRouter } from "react-router";
import { Redirect } from "components/Routing";
import { Link } from 'components/Routing';
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import { Formik } from 'formik';
import * as transitions from 'assets/transitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { isEmpty } from 'lib/utilities';
import { IconTip } from 'components/Tooltipped';
import * as _ from 'lib/utilities';

const FlowBackButton = ({ useEx }) => {
    return (
        <BackButton render={({ back }) => (
            <div className="back-arrow" onClick={back}>
                <FontAwesomeIcon icon={useEx ? 'times' : 'chevron-left'} />
            </div>
        )} />
    )
}

const FlowExitButton = ({ exitPath }) => {
    return (
        <Link to={exitPath} className="exit-btn">
            <FontAwesomeIcon icon='times' />
        </Link>
    )
}

export const FlowNav = ({ page, totalPages, progress, exitPath, useEx }) => {
    const classNames = classnames({ 'flow-nav': true, 'no-bcg': !progress })
    return (
        <div className={classNames}>
            <FlowBackButton useEx={!exitPath && useEx} />
            { exitPath && (<FlowExitButton exitPath={exitPath} />)}
            { progress && (<Progress percent={(page/(totalPages))*100} />)}
        </div>
    )
}

function buildTransitionMap(basePath,defaultRules,page,suffix='',pageCnt=30) {
    let rules = defaultRules || [];

    if(page >= 1) {
        let prevPagesStr = `${basePath}/(`;
        let curPage = 0;
        while(curPage < page) {
            if(curPage !== 0) {
                prevPagesStr += '|';
            }
            prevPagesStr += curPage;
            curPage++;
        }
        prevPagesStr += `)${suffix}`;
        rules.push([new RegExp(prevPagesStr),transitions.flowFormOut])
    }

    let nextPagesStr = `${basePath}/(`;
    let curPage = page+1;
    while(curPage < page+pageCnt) {
        if(curPage !== page+1) {
            nextPagesStr += '|';
        }
        nextPagesStr += curPage;
        curPage++;
    }
    nextPagesStr += `)${suffix}`;
    rules.push([new RegExp(nextPagesStr),transitions.flowFormIn])
    
    return {
        rules
    };
}

class FlowFormPage extends React.Component {

    constructor(props) {
        super(props);
        const { basePath, page, defaultRules } = props;
        const tmap = buildTransitionMap(basePath,defaultRules,page);
        props.setupTransitions(tmap);
    }

    componentDidMount() {
        this.props.validateForm && this.props.validateForm();
    }

    render() {
        return (this.props.children);
    }
}

export const SmallImageCardOption = ({ title, onClick, src, alt, ...rest }) => {
    const classNames = classnames({ "card signup-recipe-card buttonize inline": true });

    return (
        <div className={classNames} onClick={onClick} {...rest} >
            <div>
                <img className="signup-recipe-card-content" src={src} alt={alt} />
                <p className="signup-recipe-card-title">{title}</p>
            </div>
        </div>
    )

}

export const SmallCardOption = ({ title, subtitle, primary, noColorTitle, onClick, className, ...rest }) => {
    const classNames = classnames({"card small-signup buttonize": true, "primary": primary, [className]: className });
    const titleCNames = classnames({"card-title": true, "card-title-all-colors": true, "card-title-green": !primary && !noColorTitle})

    return (
        <div className={classNames} onClick={onClick} {...rest} >
            <div className="card-content">
                <span className={titleCNames}>{title}</span>
                <p className="card-subtitle">{subtitle}</p>
            </div>
        </div>
    )

}

export const autoAdvanceSetValue = ({ setFieldValue, advance, values }) => (name,newVal) => {
    const newValues = { ...values, [name]: newVal };
    setFieldValue(name,newVal);
    advance(newValues);
}

export const AutoAdvanceButton = ({ attr, value, values, setFieldValue, advance, render }) => {
    const onClick = () => {
        let newValues = { ...values };
        _.set(newValues,attr,value);
        setFieldValue(attr,value);
        advance(newValues);
    }
    return (
        render({ onClick })
    )
}

export const FullWidthNextButton = ({ className, ...props }) => {

    return (
        <SimpleNextButton 
            className={classnames('btn-full-width btn-bottom-center ff-next-btn',{ [className]: className})}
            {...props}
        />
    )
}
export const SimpleNextButton = ({ flowProps, ...rest }) => {
    const { validateForm, advance, setTouched, values, isValid } = flowProps;
    const formProps = { validateForm, advance, setTouched, values, isValid };

    return (
        <NextButton {...formProps} {...rest} />
    )
}

export const NextButton = ({ defaultComponent, validateForm, advance, setTouched, values, isValid, label, className, ...rest }) => {
    const onClick = () => {
        validateForm().then((errs) => {
            if(isEmpty(errs)) {
                advance(values);
            } else {
                const touched = {};
                Object.keys(errs).forEach((attr) => (touched[attr] = true));
                setTouched(errs);
            }
        });
    }
    className = className || "btn-wide btn-bottom-center ff-next-btn";

    return (
        <Button color="primary" disabled={!isValid} rounded className={className} onClick={onClick} {...rest}><span>{label}</span></Button>
    )
}

export const FlowQuestion = ({ text, className, color='grey', tooltip, ...rest }) => {
    const classNames = classnames({ "choose-goal-heading": true, [`${color}-font`]: color, [className]: className });
    return (
        <div>
            <h4 className={classNames} {...rest} >
                {text}
                {tooltip && (<IconTip icon='question-circle' msg={tooltip} size='1x' />)}
            </h4>
        </div>
    )
}

export const FixedSizeQuestion = ({ className, ...props }) => {

    return (
        <FlowQuestion color={null} className={classnames('fixed-size',{ [className]:  className })} {...props} />
    )
}

export const FlowSubtext = ({ className, children }) => {

    return (
        <div className={classnames("flow-subtext font-grey", { [className]: className })}>
            {children}
        </div>
    )
}

export const FlowChooseGoal = ({ children, fullScreen, className }) => {
    const classNames = classnames({'choose-goal': true, 'full-screen-support': fullScreen, [className]: className })
    return (
        <div className={classNames}>
            {children}
        </div>
    )
}

export const FlowPageContent = ({ formikBag, advance, block, render }) => {

    return (
        <FlowChooseGoal className={block ? 'display-block' : ''}>
            {render({ ...formikBag, advance })}
        </FlowChooseGoal>
    )
}

export const FlowQuestionContainer = ({ children, fixedBackground, noLimitH, className, ...rest }) => {
    const classNames = classnames({"signup-question-container": true, 'fixed-background': fixedBackground, "limit-50-h": !noLimitH, [className]: className })
    return (
        <div className={classNames} {...rest}>
            {children}
        </div>
    )
}

class FormCore extends React.Component {

    constructor(props) {
        super(props);
        this.advance = this.advance.bind(this);
    }

    render() {
        return (
            <form ref={this.props.scrollRef} onSubmit={this.props.handleSubmit}>
                {this.props.render({ advance: this.advance })}
            </form>
        )
    }

    advance(values,formikToo=false,afterPath=null) {
        const { history, nextPage, basePath, page, setValues } = this.props;
        const path = afterPath || `${basePath}/${nextPage(page,values)}`;
        if(formikToo) {
            setValues(values);
        }
        const callbackResp = this.props.advanceCallback(values);
        if(callbackResp && typeof callbackResp.then === 'function') {
            callbackResp.then(() => {
                history.push(path);
            });
        } else {
            history.push(path);
        }
    }
}

export const getHighestValidPage = (validate, values, currentPage) => {
    for(let i=1; i < currentPage; i++) {
        if(!isEmpty(validate(i,values))) {
            return i;
        }
    }
    return currentPage;
}

const crawlToHighestCore = (page,getNextPage,validate, values, currentPage) => {
    const nextPage = getNextPage(page,values);
    if(!isEmpty(validate(page,values) || page === currentPage)) {
        return page;
    }
    
    if(nextPage > currentPage) {
        return currentPage;
    }

    return crawlToHighestCore(nextPage,getNextPage,validate,values,currentPage);
}

export const crawlToHighestValidPage = getNextpage => (validate, values, currentPage) => {
    return crawlToHighestCore(1,getNextpage,validate,values,currentPage);
}

let MinFlow = ({ startIndex=1, basePath, exitPath, progress, totalPages, children, location, match: { params: { page }}}) => {
    const pg = Number(page);

    return (
        <React.Fragment>
            <FlowNav page={pg-startIndex} totalPages={totalPages} progress={progress} exitPath={exitPath} />
            <RouteTransitionMap location={location} canUpdate noOverflow>
                {React.Children.map(children,(child,index) => {
                    const page = startIndex+index;
                    const path = `${basePath}/${page}`;
                    return (
                        <FlowFormPage 
                            key={path} 
                            path={path} 
                            page={page} 
                            basePath={basePath} 
                        >
                            {React.cloneElement(child)}
                        </FlowFormPage>
                    )
                })}
            </RouteTransitionMap>
        </React.Fragment>
    )
}

MinFlow = withRouter(MinFlow);
class FlowForm extends React.Component {

    constructor(props) {
        super(props);
        this.validator = this.validator.bind(this);
        this.currentPage = this.getCurrentPage();
        this.scrollRef = React.createRef();
        this.setInitialPage();
    }

    setInitialPage() {
        const { validate, initialValues, highestValidPageFn } = this.props;
        const page = (highestValidPageFn || getHighestValidPage)(validate,initialValues,this.getCurrentPage());
        if(page !== this.getCurrentPage()){
            this.redirectTo = page;
        }

    }

    getCurrentPage() {
        const { match } = this.props;
        return Number(match.params.page);
    }

    validator(values) {
        const { validate } = this.props;
        return validate(this.getCurrentPage(),values);
    }

    componentDidMount() {
        this.redirectTo = null;
    }

    render() {
        const { children, location, history, match, basePath, startIndex=1, defaultRules, nextPage, validate, advanceCallback, progress, exitPath, ...rest } = this.props;
        if(this.redirectTo) {
            return (<Redirect to={`${basePath}/${this.redirectTo}`} />)
        } else {
            const page = this.getCurrentPage();
            const totalPages = React.Children.count(children);
            return (
                <React.Fragment>
                    <FlowNav page={page-startIndex} totalPages={totalPages} progress={progress} exitPath={exitPath} />
                    <Formik 
                    {...rest} 
                    validateOnMount
                    validate={this.validator}  >
                    {(formikBag) => {
                        return (
                            <FormCore 
                                page={page} 
                                nextPage={nextPage} 
                                basePath={basePath} 
                                history={history} 
                                handleSubmit={formikBag.handleSubmit}
                                scrollRef={this.scrollRef}
                                advanceCallback={advanceCallback}
                                setValues={formikBag.setValues}
                                render={({ advance }) => (
                                
                                <RouteTransitionMap location={location} canUpdate noOverflow>
                                    {React.Children.map(children,(child,index) => {
                                        const page = startIndex+index;
                                        const path = `${basePath}/${page}`;
                                        return (
                                            <FlowFormPage 
                                                key={path} 
                                                path={path} 
                                                page={page} 
                                                basePath={basePath} 
                                                defaultRules={defaultRules}
                                                validateForm={formikBag.validateForm}
                                            >
                                                {React.cloneElement(child,{ formikBag, advance })}
                                            </FlowFormPage>
                                        )
                                    })}
                                </RouteTransitionMap>
                            )} />
                        )
                    }} 
                    </Formik>
                </React.Fragment>
            )
        }
        
    }

    componentDidUpdate() {
        const newPage = this.getCurrentPage();
        if(this.currentPage !== newPage) {
            this.scrollRef.current.scrollTop = 0;
            window.scrollTo(0,0);
            this.currentPage = newPage;
        }
    }
}

export { MinFlow }

export default withRouter(FlowForm);