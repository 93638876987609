import React from 'react';
import { User } from 'lib/classes';
import { AutoAdvanceButton, FixedSizeQuestion, FlowSubtext, FullWidthNextButton } from 'components/FlowForm';
import { FlowAnswerButton } from 'components/Button';
import * as _ from 'lib/utilities';
import { ExclusionTags } from './UserFields';
import varietyOfFoods from 'assets/img/variety_of_foods.webp';
import { CalculatingScreen, Illustration, PointListItem } from 'partials/OnboardingComponents';
import { CheckButtons, SelectButton } from 'components/Form';
import SummaryCard from './OnboardingSummaryCard';
import { brandName } from 'config/settings';


export const DietTypeQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('specdiettypeq')} />
            {User.DIET_TAGS.map((tag,i) => {

                return (
                    <AutoAdvanceButton key={tag} {...flowProps} attr="dietType" value={i} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`diet-${i}-btn`}>
                            <span>{tag === 'standard' ? t('nojustwantres') : t(_.capitalize(tag))}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const FoodAllergiesQ = ({ flowProps, allergyTags, t }) => {
    const { values: { excludedKeywords } } = flowProps;
    const nothingSelected = _.isBlank(excludedKeywords) || _.noBlanks(excludedKeywords).length === 0;

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('Do you have any food allergies?')} />
            <ExclusionTags allergyTags={allergyTags} {...flowProps} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={nothingSelected ? t('No') : t('Next')}
            />
        </React.Fragment>
    )
}

const badHabits = [
    'Eating late at night',
    'Eating sweets',
    'Drinking soda/soft drinks',
    'Drinking alcohol',
    'Eating fatty/salty foods',
    'None of the above'
];

export const FoodsAndHabitsQ = ({ flowProps, t }) => {
    
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('foodshabitsq')} />
            <div>
                <CheckButtons {...flowProps} name="badHabits" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {badHabits.map((habit,i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={habit} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(habit)}</span>
                                </SelectButton>
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

export const EatAnythingScreen = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30 mt0 pt0" text={t('sfyoucaneatfaves', { brand_name: brandName()})} />
            <FlowSubtext className="mb10">{t('foodsyoulovegood')}</FlowSubtext>
            <FlowSubtext className="mb10">{t('sfguidewithflex')}</FlowSubtext>
            <Illustration src={varietyOfFoods} width={480} height={480} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Got It')}
            />
        </React.Fragment>
    )
}

export const WeeklyFoodBudgetQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('onbbudgetq')} />
            {['$','$$','$$$','$$$$'].map((budgetStr,i) => {

                return (
                    <AutoAdvanceButton key={budgetStr} {...flowProps} attr="budget" value={i} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`budget-${i}-btn`}>
                            <span>{budgetStr}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const cookingSkills = [
    "I never cook and don't know how",
    "I can cook basics but it's a chore",
    "I'm proficient at it",
    "I like and am good at cooking",
    "I love to cook and do it daily"
];

export const CookingSkillsQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('onbcookingq')} />
            {cookingSkills.map((cookingSkills,i) => {

                return (
                    <AutoAdvanceButton key={cookingSkills} {...flowProps} attr="complexityPreference" value={i} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`complexity-${i}-btn`}>
                            <span>{t(cookingSkills)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const cookingPreferences = [
    "Minimal work/cooking time",
    "",
    "Somewhere in the middle",
    "",
    "Maximum variety"
];

export const VarietyQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('onbvarietyq')} />
            {cookingPreferences.map((varietyPref,i) => {

                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="weeklyVariety" value={i} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`variety-${i}-btn`}>
                            <span>{i+1}{_.isBlank(varietyPref) ? '' : ` - ${t(varietyPref)}`}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const tastesScreenPoints = [
    { icon: 'mortar-pestle', title: 'Simple, curated recipes', desc: 'simplerecssub' },
    { icon: 'shopping-basket', title: 'Smart grocery list', desc: 'smartglistsub' },
    { icon: 'balance-scale-left', title: 'Flexibility & balance', desc: 'mpflexibilitysub' }
]

export const TastesMasteredScreen = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30 mt0 pt0" text={t('tastesschedulescreenmsg')} />
            <div>
                <div className="onb-ol">
                    {tastesScreenPoints.map(({ icon, title, desc },i) => {

                        return (
                            <PointListItem key={i} icon={icon}>
                                <label>{t(title)}:</label> {t(desc)}
                            </PointListItem>
                        )
                    })}
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Got It')}
            />
        </React.Fragment>
    )
}

const mealEnergyLevels = [
    "I get sleepy when I'm hungry",
    "I'm tired after I eat",
    "I have enough energy during the day",
    "I feel tired when I overeat",
    "I feel irritable when I'm hungry"
];

export const MealEnergyLevelsQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t('mealenergyq')} />
            <div>
                <CheckButtons {...flowProps} name="mealEnergyLevels" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {mealEnergyLevels.map((energy,i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={energy} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(energy)}</span>
                                </SelectButton>
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const proteinEatingHabits = [
    "I build all of my meals around protein",
    "I eat protein every day",
    "I don't focus on eating protein"
];


export const ProteinHabitsQ = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t('proteinstyleq')} />
            <FlowSubtext className="mb20">
                {t('foodswithproteindesc')}
            </FlowSubtext>
            {proteinEatingHabits.map((proteinHabit,i) => {

                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="proteinStyle" value={[2,1,0][i]} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`protein-style-${i}-btn`}>
                            <span>{t(proteinHabit)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

export const CalculatingMacrosScreen = ({ flowProps, t }) => {
    const { advance, values } = flowProps;

    return (
        <CalculatingScreen
            duration={5}
            subtitles={[t('Calculating macros'),t('Compiling results')]}
            subtitleSwitchPcts={[75]}
            successCallback={() => advance(values)}
        />
    )
}

export const AfterMacrosScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("macrosset")} />
            <FlowSubtext>
                {t('macrossetsub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} user={user} highlight="nutrition" reachGoalPace="slow" />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}