import React from 'react';
import { syncWorkoutsModalPathFor } from 'config/paths';
import RouteModal, { ModalHeader, ModalFooter } from 'components/Modal';
import { Redirect } from "components/Routing";
import { connect } from 'react-redux';
import { cachedWorkoutRecordsSelector } from 'redux/selectors';
import { logWorkout } from 'redux/actions';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import Button from 'components/Button';
import { withTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';

const FinishLC = loadingContainer({
    "DEFAULT": ({ load, t }) => (<Button color="primary" onClick={load} rounded id="sync-workouts-btn"><FontAwesomeIcon icon="cloud-upload-alt" />{t("Sync")}</Button>),
    "SUCCESS": () => ('')
})

class SyncWorkoutsModalCore extends React.Component {


    constructor(props) {
        super(props);
        this.state = { skipAutoLoad: true };
        this.load = this.load.bind(this);
    }

    render() {
        const { t, basePath } = this.props;
        const nextWorkout = this.nextWorkout();
        const currentWorkout = this.currentWorkout();
        let workout = (currentWorkout && currentWorkout.syncStatus !== 'synced') ? currentWorkout : nextWorkout;
        if(workout && workout.syncStatus === 'synced') {
            workout = null
        }

        return (
            <React.Fragment>
                <ModalHeader defaults exitButton>
                    <b>{t("Workout Not Synced")}</b>
                </ModalHeader>
                <div className="pa20 text-center" id="sync-workout-modal-content">
                    <FontAwesomeIcon icon="info-circle" className="info-color" /> {t("This workout couldn't be synced with the server due to network connectivity issues")}
                </div>
                <ModalFooter defaults>
                    {!workout && (<Redirect to={basePath} />)}
                    <LoadingContainerParent 
                        component={FinishLC}
                        load={this.load}
                        basePath={basePath}
                        t={t}
                        key={workout ? workout.date : null}
                        preloaded={() => !workout}
                        skipAutoLoad={this.state.skipAutoLoad}
                    />
                </ModalFooter>
            </React.Fragment>
        )
    }

    load() {
        const { syncWorkout } = this.props;
        const workout = this.nextWorkout();
        this.setState({skipAutoLoad: false, currentWorkoutDate: workout.date });
        return syncWorkout(workout)
    }

    currentWorkout() {
        const { cachedWorkouts } = this.props;
        const { currentWorkoutDate } = this.state;
        return _.find(cachedWorkouts,workout => workout.date === currentWorkoutDate);
    }

    nextWorkout() {
        return this.unsyncedWorkouts()[0];
    }

    unsyncedWorkouts() {
        const { cachedWorkouts } = this.props;
        const unsynced = _.filter(cachedWorkouts, workout => (workout.syncStatus === 'failed'));
        return _.sortBy(unsynced,workout => (new Date(workout.date)))
    }

}

const mapStateToProps = state => ({
    cachedWorkouts: cachedWorkoutRecordsSelector(state)
});

const mapDispatchToProps = dispatch => ({
    syncWorkout: (workout) => dispatch(logWorkout(workout))
});

const ConnectedModalCore = connect(mapStateToProps,mapDispatchToProps)(withTranslation()(SyncWorkoutsModalCore));

export const SyncWorkoutsModal = ({ basePath }) => {

    return (
        <RouteModal fullWidth path={syncWorkoutsModalPathFor(basePath)} exact render={(modalProps) => {
            return (
                <ConnectedModalCore basePath={basePath} />
            )
        }} />
    )
}