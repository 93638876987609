import * as _ from 'lib/utilities';
import blankProfileImg from 'assets/img/BlankProfile.png';
import { WorkoutRoutine } from './workout-routine';
import { dateFormat, FULL, NUTRITION, TRAINING } from 'config/settings';
import moment from 'moment';
import { mealPlanPickPathFor, standaloneMealPlanPathFor, switchForGeneratePathFor } from 'config/paths';

export const trainerClient = {

    canPickOwnRoutine() {
        if(!this.isClient()) {
            return true;
        }
        return this.pickOwnRoutine;
    },

    setupOwnMealPlanPrefs() {
        if(this.isClient()) {
            return this.setupOwnMpPrefs;
        }
        return true;
    },

    canEditOwnMacros() {
        if(this.isClient()) {
            return false;
        }
        return true;
    },

    trainerDidInitialWorkoutSetup() {
        return (this.workoutPlanInitialized() || this.canPickOwnRoutine());
    },

    trainerDidInitialMpSetup() {
        return (this.mealPlanInitialized() || this.setupOwnMealPlanPrefs());
    },

    isInactive() {
        return this.accountStatus === this.constructor.INACTIVE;
    },

    isActive() {
        return !this.isInactive();
    },

    isBillable() {
        return this.isActive() || moment(this.billingStartedAt).add(30,'days').isSameOrAfter(moment());
    },

    routineHasCustomizations() {
        return this.assignedRoutine && this.assignedRoutine.hasUserChanges()
    },

    initials() {
        if(!_.isBlank(this.firstName)) {
            if(!_.isBlank(this.lastName)) {
                return `${this.firstName[0]}${this.lastName[0]}`.toUpperCase();
            }
            return this.firstName.substring(0,2).toUpperCase();
        } else if(!_.isBlank(this.lastName) && this.lastName.length >= 2) {
            return this.lastName.substring(0,2).toUpperCase();
        } else if(!_.isBlank(this.email)) {
            return this.email.substring(0,2).toUpperCase();
        } else {
            return 'NA';
        }
    },

    fullName() {
        return _.fallbackText(_.compact([this.firstName,this.lastName]).join(' '),_.fallbackText(this.email,'No Name'))
    },

    shortName() {
        return _.fallbackText(this.firstName,this.email);
    },

    sortName() {
        return this.fullName();
    },

    routineWillBeSynced() {
        return this.routineQueuedForSync;
    },

    needsRoutineSyncCheck(unsyncedRoutineIds) {
        return this.isActive() && !this.routineWillBeSynced() && this.assignedRoutine && unsyncedRoutineIds.includes(this.assignedRoutine.sourceId)
    },

    profileImagePath(size) {
        const url = _.isBlank(size) ? (this.image && this.image.url) : (this.image && this.image[size] && this.image[size].url);
        if(url && !url.includes('NoImageAvailable')) {
            return url;
        } else {
            return blankProfileImg;
        }
    },

    matchesNoRoutine() {
        return _.isBlank(this.assignedRoutineId) || (this.assignedRoutine && this.assignedRoutine.sourceId === WorkoutRoutine.MPO_ID);
    },

    isAssignedToRoutine(id) {
        return (this.assignedRoutine && this.assignedRoutine.sourceId === id);
    },

    fullTagList() {
        const tagsList = this.trainerTags[0];
        if(tagsList) {
            return tagsList.tags;
        }
        return []
    },

    showNutritionOnly() {
        if(this.isClientOrTrainer()) {
            return this.trainerPlanType() === NUTRITION;
        }
        return false;
    },

    showTrainingOnly() {
        if(this.isClientOrTrainer()) {
            return this.trainerPlanType() === TRAINING;
        }
        return false;
    },

    showAllFeatures() {
        if(this.isClientOrTrainer()) {
            return this.trainerPlanType() === FULL;
        }
        return true;    
    },
    
    trainerPlanType() {
        if(this.isClientOrTrainer()) {
            return this.getTrainerSubscription() && this.getTrainerSubscription().planType;
        }
        return FULL; 
    },

    getMasterAccount() {
        if(this.isMaster() || !this.isClientOrTrainer()) {
            return this;
        } else if(this.isChild()) {
            return this.master;
        } else if(this.isClient()) {
            return this.trainer.getMasterAccount();
        }
        return null;
    },

    eligibleForFirstMpBtn() {
        return this.hasBasicProfile && this.mealPlanInitialized();
    },

    showGenerateFirstMpBtn(trainer) {
        return trainer.needsFirstMpButton() && this.eligibleForFirstMpBtn();
    },

    showDeactivatedPopup(loggedInTrainer,path) {
        if(this.isClient()) {
            if(this.isInactive()) {
                return true;
            } else {
                const expiry = this.trainerSubExpires();
                if(expiry) {
                    const offset = (this.trainerSubStatus() === 'canceled' || _.isBlank(this.trainerSubStatus())) ? 5 : 10;
                    return expiry.add(offset,'days').isBefore(moment(),'day')
                } else {
                    return false;
                }
            }
        } else if(this.isTrainer()) {
            return this.isInactive();
        }
        return false;
    },

    numMealPlanWeeks() {
        if(!this.mealPlanInitialized() || !this.lastMealPlanDay) {
            return 0;
        }
        const days = this.lastMealPlanDayMom().diff(this.mealPlanStartMom(),'days');
        return Math.ceil(days/7)
    },

    activeMealPlanWeeks() {
        return this.mealPlanWeeksList().length;
    },

    lastMealPlanWeek() {
        const weeks = this.mealPlanWeeksList();
        if(weeks.length === 0) {
            return -1;
        }

        return weeks[0].week;
    },

    createNewMealPlanPath() {
        return switchForGeneratePathFor(this.id,this.numMealPlanWeeks());
    },

    createFirstMealPlanPath() {
        return switchForGeneratePathFor(this.id,this.isAppClient() ? 'current' : 0);
    },

    mealPlanWeeksList() {
        const weeks = [ ...Array(this.numMealPlanWeeks()).keys() ].reverse();
        const res = [];
        weeks.forEach(week => {
            const stub = _.find(this.mpInfoStubs,stub => (stub.formattedDate() === this.activeWeekStart(week).format(dateFormat)));
            if(!stub || !stub.hidden) {
                res.push({ name: (stub ? stub.name : null), week });
            }
        })
        return res;
    },

    createNewMealPlanPathApp() {
        if(_.isBlank(this.lastMealPlanDay)) {
            return switchForGeneratePathFor(this.id,'current');
        }

        const lastMom = this.lastMealPlanDayMom();
        const week = _.find(['current','next','third','fourth'],week => lastMom.isBefore(this.activeWeekStart(week)));
        if(week) {
            return switchForGeneratePathFor(this.id,week);
        } else {
            return null;
        }
    },

    currentMealPlansDateList() {
        if(_.isBlank(this.lastMealPlanDay)) {
            return [];
        }

        const current = this.activeWeekStart('current');
        const next = this.activeWeekStart('next');
        const third = this.activeWeekStart('third');
        const fourth = this.activeWeekStart('fourth');

        const lastMom = this.lastMealPlanDayMom();

        return _.filter([{ name: 'current', startDate: current }, { name: 'next', startDate: next }, { name: 'third', startDate: third }, { name: 'fourth', startDate: fourth }],({ startDate }) => lastMom.isSameOrAfter(startDate));
    },

    nextMealPlanWeek(curWeek,dir) {
        const list = this.mealPlanWeeksList();
        const curInd = _.findIndex(list,({ week }) => (week === curWeek));
        const nextWeek = list[curInd-dir];
        if(nextWeek) {
            return nextWeek.week;
        }
        return null;
    },

    resolvedMpNameScheme() {
        if(this.isClient()) {
            return this.trainer.resolvedMpNameScheme();
        }

        return this.mpNameScheme; 
    },

    mealPlanWeekName(week,noFallback=false) {
        week = Number(week);
        const stub = _.find(this.mpInfoStubs,stub => (stub.formattedDate() === this.activeWeekStart(week).format(dateFormat)));
        if(stub && !_.isBlank(stub.name)) {
            return stub.name;
        }
        return noFallback ? '' : _.weekTitle(week,this.resolvedMpNameScheme());
    },

    mealPlanWeeksCol(t) {
        return this.mealPlanWeeksList().map(({ name, week }) => ({ text: (_.isBlank(name) ? _.weekTitle(week,this.resolvedMpNameScheme()) : name), value: week }))
    },

    isMealPlanPublished(startDate) {
        if(typeof startDate !== 'string') {
            startDate = startDate.format(dateFormat);
        }
        const stub = _.find(this.mpInfoStubs,stub => (stub.formattedDate() === startDate));

        if(stub) {
            return stub.published;
        }

        return true;
    },

    isMealPlanClientCreated(startDate) {
        if(typeof startDate !== 'string') {
            startDate = startDate.format(dateFormat);
        }
        const stub = _.find(this.mpInfoStubs,stub => (stub.formattedDate() === startDate));

        if(stub) {
            return stub.clientCreated;
        }

        return false;
    },

    isPdfClient() {
        if(this.isClient() && this.trainer.isPdfTrainer()) {
            return true;
        }
        return false;
    },

    isAppClient() {
        return this.isClient() && this.trainer.isAppTrainer();
    },

    editViewMpsPath(basePath) {
        if(this.isAppClient()) {
            return standaloneMealPlanPathFor(this.id,'current');
        }

        if(this.activeMealPlanWeeks() === 1) {
            return standaloneMealPlanPathFor(this.id,0);
        }

        return mealPlanPickPathFor(basePath,this.id);
    },

    hasNoRoutine() {
        return _.isBlank(this.assignedRoutine) || this.assignedRoutine.name === 'Meal Plan Only';
    },

    assignedRoutineName(t) {
        if(this.hasNoRoutine()) {
            return t('None');
        }
        return this.assignedRoutine.name;
    },

    routineEndStr(t) {
        if(this.hasNoRoutine()) {
            return null;
        }

        if(this.assignedRoutine.repeating) {
            return t('Repeats indefinitely');
        }

        if(_.isBlank(this.routineEndDate)) {
            return null;
        }

        const mom = moment(this.routineEndDate);
        const daysLeft = mom.diff(moment(),'days');

        return t('Ends in X days', { days: daysLeft })
    },

    trainerFinishedSetup() {
        return this.trainer.setupFinishedForClient(this);
    },
    
    clientWorkoutOn(date) {
        return _.find(this.workouts,w => w.date === date)
    },

    showClientToDo() {
        if(this.isPdfClient()) {
            return false;
        }
        return true;
    },

    clientToDoType() {
        if(this.showNutritionOnly()) {
            if(this.trainerDidInitialMpSetup()) {
                return 'full';
            } else {
                return 'sample';
            }
        } else if(this.showTrainingOnly()) {
            if(this.trainerDidInitialWorkoutSetup()) {
                return 'workout';
            } else {
                return 'sample';
            }
        } else {
            if(this.trainerDidInitialMpSetup()) {
                return 'full'
            } else if(this.trainerDidInitialWorkoutSetup()) {
                return 'workout';
            } else {
                return 'sample';
            }
        }
    },

    inviteStatusStr(t) {
        if(this.inviteSent()) {
            return t('Sent');
        } else if(this.inviteClaimed()) {
            return t('Accepted');
        } else {
            return t('Not Sent');
        }
    },

    activeTrainerNotes() {
        return _.sortBy(_.filter(this.cTrainerNotes,note => note.trainerId === this.trainerId),note => -moment.utc(note.createdAt).valueOf())
    },

    activeTrainerNoteById(id) {
        return _.find(this.cTrainerNotes,n => n.id === Number(id));
    },

    needsProfileSetup() {
        return !this.hasBasicProfile && !this.setupOwnProfile;
    },

    needsWorkoutSetup() {
        return !this.needsProfileSetup() && !this.workoutPlanInitialized();
    },

    needsMealPlanSetup() {
        return !this.needsProfileSetup() && !this.mealPlanInitialized();
    },

    needsSetupStr(t) {
        const ownNeeds = [];
        const needs = [];
        const finalNeeds = {};

        if(this.needsMealPlanSetup() && !this.showTrainingOnly()) {
            if(this.setupOwnMealPlanPrefs()) {
                ownNeeds.push('Meal Plan');
            } else {
                needs.push('Meal Plan');
            }
        }

        if(this.needsWorkoutSetup() && !this.showNutritionOnly()) {
            if(this.canPickOwnRoutine()) {
                ownNeeds.push('Workouts');
            } else {
                needs.push('Workouts');
            }
        }

        if(needs.length === 0 && ownNeeds.length === 0) {
            return null;
        }

        if(needs.length > 0) {
            finalNeeds.needs = `${needs.map(need => t(need).toLowerCase()).join(' & ')} ${t('setup')}`;
        }

        if(ownNeeds.length > 0) {
            finalNeeds.ownNeeds = `${ownNeeds.map(need => t(need).toLowerCase()).join(' & ')}`
        }

        return finalNeeds;
    },

    getClientFormById(id) {
        return _.find(this.assignedForms,f => f.id === Number(id));
    },

    sortClientForms(viewerType,forms) {
        return _.sortBy(forms,f => f.statusSort(viewerType), f => f.assignedDateHourInv(), f => f.shortName);
    },

    getClientFormsFor(viewerType,filters) {
        return this.sortClientForms(viewerType,_.filter(this.assignedForms,f => (!f.isDeleted() && (_.isBlank(filters.status) || f.filterStatus() === filters.status))));
    },

    getClientDashFormsOn(date) {
        const baseForms = _.filter(this.assignedForms,f => (!f.isDeleted() && !f.isTrainerAdministered() && f.effectiveDate().isSameOrBefore(date,'days')));
        //only take most recent instance of each form
        const groupedAndSorted = _.groupBy(_.sortBy(baseForms,f => f.effectiveDateSecInv()),f => f.parentFormId);
        const mostRecents = Object.values(groupedAndSorted).map(arr => arr[0]);
        const dateForms = _.filter(mostRecents,f => f.isEffectivelyOnDashDate(date));
        const uniquedForms = _.sortBy(dateForms, f => f.assignedDateHourInv());

        return this.sortClientForms('client',uniquedForms);
    },

    hasMoreAssignedForms(limit) {
        return this.assignedForms.length > limit;
    }
}