import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import React from 'react';
import { connect } from 'react-redux';
import { userWithActivityLogsSel } from 'redux/selectors';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ConfirmActionButton } from 'components/Button';
import { destroyActivityLog } from 'redux/actions';
import { ActivityLog } from 'lib/classes';
import requestStateHandler from 'components/RequestStateHandler';
import { SlightEmphasisIconNote } from 'components/Typography';
import LinkButton from 'components/LinkButton';
import { logActivityPathFor } from 'config/paths';
import * as _ from 'lib/utilities';
import { ResponsiveContainer } from 'components/ResponsiveContainer';

const SummaryText = ({ activityLog, t }) => {
    const secs = activityLog.secondsDuration();
    const dist = activityLog.unitDistance();
    const hr = activityLog.avgHeartRate;
    const cals = activityLog.caloriesBurned;

    return (
        <div className="tiny-text faint-color">
            {secs && secs > 1 && (<span><FontAwesomeIcon icon={['far','clock']} /> {_.setTimeFormat(secs,t)}</span>)}
            {dist && dist > 1 && (<span className="ml10"><FontAwesomeIcon icon={'ruler-combined'} /> {dist} {activityLog.distSuffix(t)}</span>)}
            {hr && hr > 1 && (<span className="ml10"><FontAwesomeIcon icon={'heart-rate'} /> {hr}{t('bpm')}</span>)}
            {cals && cals > 1 && (<span className="ml10"><FontAwesomeIcon icon={'fire'} /> {cals}{t('cals')}</span>)}
        </div>
    )
}

let ActivityLogCard = ({ activityLog, unlog, readOnly, className, t }) => {

    const destroy = () => {
        unlog(new ActivityLog({ ...activityLog, deleted: true }));
    }

    if(activityLog.isDeleted()) {
        return '';
    }

    return (
        <ListCard className={classnames("activity-log-card",{ [className]: className })} >
            <ListCardAction className="left no-border inactive">
                <FontAwesomeIcon className={"success-color"} icon={"check-circle"} />
            </ListCardAction>
            <ListCardTitle>
                {activityLog.name()}
                <SummaryText activityLog={activityLog} t={t} />
            </ListCardTitle>
            {!readOnly && (<ConfirmActionButton 
                onClick={() => destroy()}
                render={({ ready, onClick }) => {

                    return (
                        <ListCardAction className="no-border" id={`delete-activity-${activityLog.id}-btn`} onClick={onClick}>
                            <FontAwesomeIcon icon={"times"} className={classnames({ 'faint-color': !ready, 'red-text': ready })} />
                        </ListCardAction>
                    )
                }}
            />)}
        </ListCard>
    )
}

ActivityLogCard = requestStateHandler({ unlog: 'activityLog' })(ActivityLogCard);

const ActivityLogList = ({ user, unlog, date, showEmptyMsg, readOnly, fixedBtn }) => {
    const { t } = useTranslation();
    const logs = user.activityLogsByDate(date);
    const logBtn = (<div className="text-right mt5">
        <LinkButton rounded color="primary" outlined className="shadow" to={logActivityPathFor(date)} id="activiy-log-btn">
            <FontAwesomeIcon icon="plus"></FontAwesomeIcon> {t("Log Activity")}
        </LinkButton>
    </div>)

    return (
        <ResponsiveContainer render={({ belowBreakpoint }) => {

            if(logs.length === 0 && showEmptyMsg) {
                return (
                    <React.Fragment>
                        <SlightEmphasisIconNote text={t("Nothing logged today.")} />
                        {!readOnly && !belowBreakpoint && logBtn}
                        {!readOnly && belowBreakpoint && fixedBtn}
                    </React.Fragment>
                )
            }

            return (
                <React.Fragment>
                    {logs.map((activityLog) => {
                        return (
                            <ActivityLogCard 
                                key={activityLog.id}
                                activityLog={activityLog}
                                unlog={unlog}
                                readOnly={readOnly}
                                t={t}
                            />
                        )
                    })}
                    {!readOnly && !belowBreakpoint && logBtn}
                    {!readOnly && belowBreakpoint && fixedBtn}
                </React.Fragment>
            )
        }} />
    )
}


const mapDispatchToProps = dispatch => ({
    unlog: activityLog => dispatch(destroyActivityLog(activityLog.id))
})

const mapStateToProps = state => ({
    user: userWithActivityLogsSel(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(ActivityLogList);