import React, { useState } from 'react';
import { NavLink } from 'components/Routing';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import { matchPath, useLocation } from 'react-router';
import { isBlank, compact } from 'lib/utilities';
import MediaQuery from './MediaQuery';
import * as _ from 'lib/utilities';


export function ActivityTabs({ className, ...props}) {
    const classNames = classnames('activity-tabs',{ [className]: className });

    return (
        <div className={classNames}>
            {props.children}
        </div>
    )
}

export const SimpleActivityTabs = ({isMain, ...props}) => (
    <ActivityTabs className={isMain ? "" : "for-simple-nav"} {...props} />
)

export function SectionTabs({ activeTab, children, render, hideIfOne, noBorder, white }) {
    const [curTab,setActiveTab] = useState(activeTab);
    const show = hideIfOne ? compact(children).length > 1 : true;
    return (
        <React.Fragment>
            {show && (<div className={classnames("activity-tabs not-top", { 'off-white': !white, 'no-border': noBorder })}>
                {React.Children.map(children,(child,index) => {
                    if(child) {
                        return React.cloneElement(child, { ...child.props, active: (index === curTab), onClick: () => setActiveTab(index) });
                    }
                    return '';
                })}
            </div>)}
            {render({ activeTab: curTab })}
        </React.Fragment>
    )
}

export function RespSectionTabs({ activeTab, children, render, breakpoint=750 }) {
    const [curTab,setActiveTab] = useState(activeTab || 0);
    const mediaQuery = `(min-width: ${breakpoint}px)`;
    const tabCount = _.compact(children).length;
    return (
        <MediaQuery
            query={mediaQuery}
            render={({ queryMatches: isLarge }) => (
                <React.Fragment>
                    {!isLarge && (<div className="activity-tabs not-top off-white">
                        {React.Children.map(children,(child,index) => {
                            if(child) {
                                return React.cloneElement(child, { ...child.props, active: (index === curTab), onClick: () => setActiveTab(index) });
                            }
                            return '';
                        })}
                    </div>)}
                    {render({ activeTabs: isLarge ? [ ...Array(tabCount).keys() ] : [curTab], isLarge })}
                </React.Fragment>
        )}
    />
    )
}

export function ActivityTab({ label, path, icon, ...others }) {
    const location = useLocation();
    const active = matchPath(location.pathname,{ path });

    return (
        <NavLink to={path} className="activity-tab" active={active} {...others}>
            {icon && <FontAwesomeIcon icon={active ? icon : ['far',icon]} />} {label}
        </NavLink>
    )
}

export const SimpleActivityTabsFull = ({ Tab, tabLabels, setActiveTab, activeTab, isMain }) => {

    return (
        <SimpleActivityTabs isMain={isMain}>
            {tabLabels.map((label,index) => (
                <Tab id={`top-tab-${index}`} key={index} label={label} setActiveTab={setActiveTab} active={(index+1) === activeTab} tabIndex={index+1} />
            ))}
        </SimpleActivityTabs>
    )
}

export function NonLinkActivityTab({ label, tabIndex, setActiveTab, active, id }) {
    const classNames = classnames('activity-tab',{ active })
    return (
        <div className={classNames} onClick={() => !active && setActiveTab(tabIndex)} id={id}>
            {label}
        </div>
    )
}

export function SectionTab({ active, onClick, children, className }) {
    const classNames = classnames("activity-tab clickable", {'active': active, [className]: className });

    return (
        <div className={classNames} onClick={onClick}>
            {children}
        </div>
    )
}

export function BottomNavChild({ label, icon, path, pathProps, id, notifications, onClick }) {
    pathProps = pathProps || { path };
    const location = useLocation();
    const isActive = (match,location) => matchPath(location.pathname,pathProps);
    const active = isActive({},location);
    const finalIcon = active ? icon : ['fal',icon];


    return (
        <li id={id ? id : ''}>
            <NavLink to={path} active={active} onClick={onClick}>
                <div className="position-relative inline-block">
                    {!isBlank(notifications) && notifications > 0 && <span id={`menu-notify-${notifications}-icon`} className="notification-icon">{notifications}</span>}
                    {typeof icon === 'string' && <FontAwesomeIcon icon={finalIcon} size="3x" />}
                    {typeof icon !== 'string' && icon}
                    <div>{label}</div>
                </div>
            </NavLink>
        </li>
    )
}

export default function BottomNav({ children, className}) {
    return (

            <div className={classnames("bottom-nav",{ [className]: className })}>
                {children}
            </div>

    )
}