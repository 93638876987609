import React from 'react';
import { useTranslation } from 'react-i18next';
import { teamRecipesFiltersSelector, trainerWithTeamRecsSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { DefaultAsyncActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as _ from 'lib/utilities';
import { Loader } from 'components/LoadingHOC';
import { TagButtons } from 'components/Form';
import { Meal } from 'lib/classes';
import { instantCopyRecipe, teamRecipesLoad, updateTeamRecipesFilters } from 'redux/actions';
import { RecipeListing, TypeButton } from './MealAndRecipeSearch';
import { SlightEmphasisIconNote } from 'components/Typography';
import classNames from 'classnames';
import { TrainerSelectButton } from './IndexPage';
import { TrainerSelectModal } from './TrainerSelectModal';

export const ImportRecipeButton = ({ action, meal, trainer, addRecipeImport }) => {
    const { t } = useTranslation();
    const recipe = meal.mainRecipe();

    if(!recipe.isDirectlyOwnedBy(trainer)) {
        return '';
    }

    return (
        <DefaultAsyncActionButton 
            Comp={TypeButton}
            loaderType="icon"
            LoaderWrapper={TypeButton}
            action={() => action(recipe.id)}
            successCallback={() => addRecipeImport()}
            id={`import-recipe-${recipe.id}-btn`}
        >
            <FontAwesomeIcon icon={['far','file-import']} className="mr5" /> {t("Import")}
        </DefaultAsyncActionButton>
    )
}

const TeamRecipesTabSuccess = ({ user, otherTrainer, recipes, mealSearch, linksEnabled, getClickCardProps, importRecipe, addRecipeImport }) => {
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {recipes.length === 0 && (<div className="row limit-50-h">
                <div className="col s12 m12 l8 offset-l2">
                    <SlightEmphasisIconNote text={t(otherTrainer ? "trainer has no recipes" : "team has no recipes")} />
                </div>
            </div>)}
            {recipes.length > 0 && (<div className="limit-50-h display-flex">
                <RecipeListing
                    user={user}
                    meals={Meal.mealsForRecipes(_.uniqBy(recipes,rec => rec.staticId()))}
                    mealSearch={mealSearch}
                    ActionComp={otherTrainer ? <ImportRecipeButton action={importRecipe} trainer={otherTrainer} addRecipeImport={addRecipeImport} /> : null}
                    linksEnabled={linksEnabled}
                    getClickCardProps={getClickCardProps}
                />
            </div>)}
        </React.Fragment>
    )
}

const FiltersCard = ({ trainer, trainers, filters, updateFilters, t }) => {
    const formProps = { setFieldTouched: () => {}, setFieldValue: (name,value) => updateFilters({ trainerId: value }), name: 'trainerId', values: filters };
    const usePopup =  trainer.usePopupForTrainerSelect();
    let col = [ {text: t('Team'), value: '' }, ...trainers.map(trainer => ({ text: trainer.initialsName(), value: trainer.id }))];

    return (
        <div className={classNames("pa10", { "text-center": !usePopup })}>
            {!usePopup && (<TagButtons
                col={col}
                {...formProps}
                single
                btnId={(id) => `show-${id}-recipes`}
                buttonProps={{ className: 'btn-no-shadow ma2' }}
            />)}
            {usePopup && (
                <div className="inline-block">
                    <div className="font-grey no-wrap">{t('Team member')}:</div>
                    <TrainerSelectButton
                        trainer={trainer}
                        selTrainerId={filters.trainerId}
                    />
                </div>
            )}
        </div>
    )
}

let TeamRecipesTab = ({ user, mealSearch, loadRecipes, importRecipe, basePath, linksEnabled, getClickCardProps, filters, updateFilters, trainer, addRecipeImport }) => {
    const { t } = useTranslation();
    const trainers = trainer.allOtherTrainers();
    const ids = trainers.map(t => t.id);
    filters = ids.includes(filters.trainerId) ? filters : { trainerId: '' };

    if(!trainer.hasOtherTrainers()) {
        return (
            <div className="row limit-50-h">
                <div className="col s12 m12 l8 offset-l2">
                    <SlightEmphasisIconNote text={t("no other trainers")} id="no-team-note" />
                </div>
            </div>
        );
    }

    const otherT = trainer.trainerById(filters.trainerId);
    const recipes = trainer.teamRecipesFor(filters.trainerId)

    return (
        <React.Fragment>
            <div className="row limit-50-h">
                <div className="col s12 m12 l8 offset-l2">
                    <FiltersCard 
                        t={t}
                        user={user}
                        filters={filters} 
                        trainers={trainers}
                        trainer={trainer}
                        updateFilters={updateFilters} 
                    />
                    <TrainerSelectModal trainer={trainer} initialTrainerId={otherT ? otherT.id : ''} submit={({ trainerId }) => updateFilters({ trainerId })} excludeSelf={true} allLabel={t('Team')} />
                </div>
            </div>
            <Loader 
                key={filters.trainerId}
                load={() => loadRecipes(filters.trainerId)}
                preloaded={() => (recipes.length > 0)}
                alwaysLoad
                type='page'
                recipes={recipes}
                mealSearch={mealSearch}
                user={user}
                basePath={basePath}
                linksEnabled={linksEnabled}
                getClickCardProps={getClickCardProps}
                successComponent={TeamRecipesTabSuccess}
                otherTrainer={otherT}
                filters={filters}
                updateFilters={updateFilters}
                importRecipe={importRecipe}
                addRecipeImport={addRecipeImport}
            />
        </React.Fragment>
    )
}

const mapStateToRecipeTabProps = state => ({
    filters: teamRecipesFiltersSelector(state),
    trainer: trainerWithTeamRecsSelector(state)
})

const mapDispatchToRecipeTabProps = dispatch => ({
    importRecipe: recipeId => dispatch(instantCopyRecipe(recipeId)),
    loadRecipes: trainerId => dispatch(teamRecipesLoad(trainerId)),
    updateFilters: filters => dispatch(updateTeamRecipesFilters(filters))
})

TeamRecipesTab = connect(mapStateToRecipeTabProps,mapDispatchToRecipeTabProps)(TeamRecipesTab);

export default TeamRecipesTab;