import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const ContentBox = ({ className, children }) => {
    const classNames = classnames({
        "content-box": true,
        [className]: className
    })

    return (
        <div className={classNames}>
            <div>
                {children}
            </div>
        </div>
    )
}

export const ActionBox = React.forwardRef(({ side, icon, label, wider, children, inactive, className, iconProps, ...rest },ref) => {
    const classNames = classnames({
        "action-box": true,
        "left": side === 'left',
        "wider": wider,
        "inactive": inactive,
        [className]: className
    })

    return (
        <div className={classNames} {...rest} ref={ref}>
            <div className="action-icon">
                { icon && (<FontAwesomeIcon icon={icon} {...iconProps} />) }
                { label && <div>{label}</div> }
                {children}
            </div>
        </div>
    )
})

export const ActionButton = ({ defaultIcon, defaultLabel, failLabel='failed', successLabel, status, ...rest }) => {
    if (status === 'DEFAULT') {
        return (
            <ActionBox icon={defaultIcon} label={defaultLabel} {...rest} />
        )
    } else if (status === 'REQUEST') {
        return (
            <ActionBox icon={['fas','spinner']} iconProps={ {spin: true } } label={defaultLabel} {...rest} />
        )
    } else if (status === 'MAINTENANCE' || status === 'SERVERERR' || status === 'NETERR') {
        return (
            <ActionBox icon={defaultIcon} label={(<span className="red-text">failed</span>)} {...rest} />
        )
    } else if(status === 'SUCCESS') {
        return (
            <ActionBox icon={defaultIcon} label={successLabel} {...rest} />
        )
    } else if(status === 'INVALID') {
        return (
            <ActionBox icon={defaultIcon} label="" inactive {...rest} className="low-emphasis" />
        )
    }
}

export default ({ variant, children, borderTop, borderBottom, className }) => {
    const classNames = classnames({
        "wide": variant === 'wide',
        "narrow": variant === 'narrow',
        "border-top": borderTop,
        "border-bottom": borderBottom,
        "record-panel": true,
        [className]: className
    })

    return (
        <div className={classNames}>
            {children}
        </div>
    )
}