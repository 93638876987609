import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer, SimpleNavRightButton } from 'components/Nav';
import { withTranslation, useTranslation, Trans } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { editWorkoutTemplateMatch, editRoutinePath, routineSettingsPathFor, restoreRoutineDefaultsPathFor, restartRoutinePathFor, editWorkoutTemplatePathFor, copyWorkoutModalPathFor, cycleSettingsModalPathFor, editRoutineStandlonePathFor, settingsMatch, importWorkoutTemplatesPathFor, editRoutineStandaloneMatch, myRoutinesPath, clientDetailsMatch, modalPathFor } from 'config/paths';
import { Loader } from 'components/LoadingHOC';
import { getWorkoutRoutineSelector, trainerRecordSelector, userRecordSelector, userSelector, warmupsNeedLoadingSel } from 'redux/selectors';
import { connect } from 'react-redux';
import { loadWorkoutRoutine, updateRoutineCycle, updateCycleDayOrder, destroyWrtd, createWrtd, createRoutineCycle, destroyRoutineCycle, switchToTrainer } from 'redux/actions';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames';
import moment from 'moment';
import * as _ from 'lib/utilities';
import ResponsiveTabs from 'components/ResponsiveTabs';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import { IconNote } from 'components/Typography';
import { ConfirmActionButton, DefaultAsyncActionButton } from 'components/Button';
import { Dropdown } from 'components/Dropdown';
import { Link, useHistory } from 'react-router-dom';
import { Redirect } from 'components/Routing';
import { SettingsModal, RestoreDefaultsModal, RestartRoutineModal, CopyWorkoutModal, CycleSettingsModal, ImportWorkoutTemplatesModal } from 'partials/EditRoutineModals';
import requestStateHandler from 'components/RequestStateHandler';
import { RoutineCycle as RoutineCycleClass, WorkoutRoutineDay as WorkoutRoutineDayClass } from 'lib/classes';
import { DestroyableCollection } from 'components/DestroyableCollection';
import LinkButton from 'components/LinkButton';
import { AsyncCardAction } from 'components/List';
import ClientSwitcher from 'partials/ClientSwitcher';
import { EDIT_CLIENT_ROUTINE } from 'config/tooltips';
import { InitialTipPopup } from 'partials/Utilities';
import { resolvedHomePath } from 'redux/helpers';
import { workoutPlanSlideOutPaths } from 'partials/MainLayout';
import { RedirectContext } from 'components/RouteTransitionMap';
import { DropdownLinkTPB } from 'partials/ProRequiredButton';
import { TRAINING } from 'config/settings';
import { ExportRoutinePDFModal, exportRoutinePDFSuffix } from 'partials/ExportPDFModal';
import { previewRoutinePdfUrlFor } from 'lib/api';


const transitionMap = {
    rules: [
        [[editRoutineStandaloneMatch,editRoutinePath],transitions.none],
        [editWorkoutTemplateMatch, transitions.slideOver],
        [[...workoutPlanSlideOutPaths,settingsMatch,clientDetailsMatch],transitions.slideOut]
    ]
};

const WeekdayButton = ({ title, onClick, active, wday }) => {
    const className = classnames(`btn btn-setting2 cycle-wday-${wday}`,{ active })

    return (
        <div className={className} onClick={onClick}>
            <div className="btn-content"><span>{title}</span></div>
        </div>
    )
}

let WeekdayButtons = ({ updateWorkoutDays, workoutDays, startWeekday }) => {
    const weekdays = moment.weekdaysShort();

    return (
        <div className="display-flex">
            {_.weekdays(startWeekday).map(wday => {
                const isActive = workoutDays.includes(wday);
                const afterClick = isActive ? _.filter(workoutDays,day => (day !== wday)) : [ ...workoutDays, wday ];
                const handleClick = () => ((workoutDays.length > 1 || !isActive) ? updateWorkoutDays(afterClick) : []);
                return (
                    <WeekdayButton key={wday} title={weekdays[wday]} onClick={handleClick} active={isActive} wday={wday} />
                )
            })}
        </div>
    )
}

WeekdayButtons = requestStateHandler({ updateWorkoutDays: 'workoutDays' })(WeekdayButtons)

const RoutineCycleHeader = ({ cycle, updateCycle, destroyCycle, basePath, allowDestroy }) => {
    const { t } = useTranslation();
    const history = useHistory();
    const updateWorkoutDays = (workoutDays) => updateCycle({ workoutRoutineId: cycle.workoutRoutineId, id: cycle.id, workoutDays });
    const destroy = () => {
        const newCycle = new RoutineCycleClass({ ...cycle, destroyed: true });
        destroyCycle(newCycle);
    }

    return (
        <div id={`cycle-header-${cycle.id}`}>
            {cycle.showSettings() && (
                <div className="multicycle-header">
                    {allowDestroy && (<ConfirmActionButton onClick={destroy} render={({ ready, onClick }) => (
                        <div className="ex-swap-btn no-border clickable destroy-btn" onClick={onClick}>
                            <FontAwesomeIcon icon="times" color={ready ? 'red' : null} />
                        </div>
                    )} />)}
                    <div className="ex-swap-btn no-border clickable" id={`cycle-settings-${cycle.id}-btn`} onClick={() => history.push(cycleSettingsModalPathFor(basePath,cycle.id))}>
                        <FontAwesomeIcon icon="cog" />
                    </div>
                    <div className="title-medium pt10">{cycle.defaultedName(t)}</div>
                    <div className="subtitle-small">{cycle.showCycleWeeks() ? t('X Week(s)',{ weeks: cycle.length }) : t('Repeating')}</div>
                </div>
            )}
            {!cycle.showSettings() && (<div className="subtitle-reg pt5">{cycle.daysTitle(t)}:</div>)}
            <WeekdayButtons workoutDays={cycle.workoutDays} startWeekday={cycle.startWeekday()} updateWorkoutDays={updateWorkoutDays} />
            <div className="subtitle-reg">{t('Workouts')}:</div>
        </div>
    )
}

let WorkoutRoutineDay = ({ cycle, trainer, workoutRoutineDay, dragHandleProps, destroyWrtd, draggableRef, basePath, index, createWrtd,  ...draggableProps }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { routineCycleId, workoutTemplateId } = workoutRoutineDay;
    const destroy = () => {
        const newWrtd = new WorkoutRoutineDayClass({...workoutRoutineDay, destroyed: true });
        destroyWrtd(newWrtd);
    }

    return (
        <ListCard {...draggableProps} ref={draggableRef} id={`wrtd-${workoutRoutineDay.id}-${index}`} className={workoutRoutineDay.destroyed ? 'hidden' : null}>
            <ListCardAction className="left no-border" id={`wrtd-${workoutRoutineDay.id}-handle`} {...dragHandleProps}>
                    <FontAwesomeIcon icon="arrows-alt-v" />
            </ListCardAction>
            <ListCardTitle className={`workout-title-${workoutRoutineDay.id}`}>{workoutRoutineDay.defaultedName(t)}</ListCardTitle>
            {trainer && (<AsyncCardAction icon="plus"  load={() => createWrtd({ routineCycleId, workoutTemplateId, type: 'repeat' })} />)}
            {!trainer && (<ListCardAction className={`copy-wrtd-${workoutRoutineDay.id}-btn`} onClick={() => history.push(copyWorkoutModalPathFor(basePath,routineCycleId,workoutTemplateId))}>
                <FontAwesomeIcon icon={["far","copy"]} />
            </ListCardAction>)}
            <ListCardAction className={`edit-wrtd-${workoutRoutineDay.id}-btn`} onClick={() => history.push(editWorkoutTemplatePathFor(workoutRoutineDay.workoutRoutine().id,workoutRoutineDay.workoutTemplateId))}>
                <FontAwesomeIcon icon={["far","edit"]} />
            </ListCardAction>
            <ConfirmActionButton onClick={destroy} render={({ ready, onClick }) => (
                <ListCardAction onClick={onClick} className={`destroy-wrtd-${workoutRoutineDay.id}-btn`}>
                    <FontAwesomeIcon color={ready ? 'red' : null} icon="times" />
                </ListCardAction>
            )} />
        </ListCard>
    )
 
}

WorkoutRoutineDay = requestStateHandler({ destroyWrtd: 'workoutRoutineDay' })(WorkoutRoutineDay);


const WorkoutRoutineDays = ({ cycle, updateDayOrder, destroyWrtd, basePath, createWrtd, trainer }) => {

    const onDragEnd = result => {
        if(!result.destination) {
            return;
        }

        const newCycle = new RoutineCycleClass({ ...cycle, workoutRoutineDays: [ ...cycle.workoutRoutineDays ]});
        newCycle.reorderDays(result.source.index,result.destination.index);
        updateDayOrder(newCycle);
    }

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId={`cycle-days-${cycle.id}`}>
                {(droppableProvided, droppableSnapshot) => (
                    <div ref={droppableProvided.innerRef}>
                        {cycle.workoutRoutineDays.map((wrtDay,index) => (
                            <Draggable draggableId={`${wrtDay.id}`} key={wrtDay.id} index={index}>
                                {(draggableProvided, draggableSnapshot) => (
                                    <WorkoutRoutineDay 
                                        draggableRef={draggableProvided.innerRef}
                                        cycle={cycle}
                                        workoutRoutineDay={wrtDay} 
                                        key={wrtDay.id} 
                                        destroyWrtd={destroyWrtd}
                                        dragHandleProps={draggableProvided.dragHandleProps}
                                        basePath={basePath}
                                        index={index}
                                        createWrtd={createWrtd}
                                        trainer={trainer}
                                        {...draggableProvided.draggableProps}
                                    />
                                )}
                            </Draggable>
                        ))}
                        {droppableProvided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    )
}

let RoutineCycle = ({ cycle, trainer, updateCycle, updateDayOrder, destroyWrtd, createWrtd, destroyCycle, basePath, allowDestroy }) => {
    const isEmpty = cycle.workoutRoutineDays.length === 0;
    const { t } = useTranslation();
    const history = useHistory();

    const importButton = trainer ? (
        <LinkButton
            rounded 
            color="primary" 
            className="no-upcase shadow mr5" 
            outlined 
            noShadow
            id={'import-workouts-btn'}
            to={importWorkoutTemplatesPathFor(basePath,cycle.id)}
        >
            <FontAwesomeIcon icon={['far','file-import']}></FontAwesomeIcon> {`${t("Import")}/${t("Copy")}`}
        </LinkButton>
    ) : null;

    if(cycle.destroyed) {
        return '';
    } else {
        return (
            <div className="secondary-bkgd mb20 pb5 pl10 pr10 text-left">
                <RoutineCycleHeader cycle={cycle} updateCycle={updateCycle} basePath={basePath} destroyCycle={destroyCycle} allowDestroy={allowDestroy} />
                <WorkoutRoutineDays cycle={cycle} updateDayOrder={updateDayOrder} destroyWrtd={destroyWrtd} createWrtd={createWrtd}  trainer={trainer} basePath={basePath} />
                {isEmpty && (<div className="text-center pa20">
                    <IconNote text={t('No workouts')} />
                </div>)}
                <div className="pa5 text-right">
                    {importButton}
                    <DefaultAsyncActionButton 
                        rounded 
                        loaderType="icon"
                        color="primary" 
                        className="no-upcase" 
                        outlined 
                        noShadow 
                        id={`add-workout-to-${cycle.id}-btn`}
                        action={createWrtd.bind(null,{ routineCycleId: cycle.id })}
                        successCallback={(data) => history.push(editWorkoutTemplatePathFor(cycle.workoutRoutine.id,Object.keys(data.workoutTemplates)[0]))}
                    >
                        <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("New")}
                    </DefaultAsyncActionButton>
                </div>
            </div>
        )
    }
}

RoutineCycle = requestStateHandler({ updateDayOrder: 'cycle', destroyCycle: 'cycle' })(RoutineCycle);

let CycleTab = ({ trainer, workoutRoutine, subType, cycles, updateCycle, updateDayOrder, destroyWrtd, createWrtd, createCycle, destroyCycle, basePath }) => {
    const { t } = useTranslation();    

    return (
        <DestroyableCollection 
            collection={cycles}
            destroy={destroyCycle}
            render={({ destroy, allowDestroy }) =>(
                <div className="mt0">
                    {cycles.map(cycle => (<RoutineCycle 
                        cycle={cycle} 
                        key={cycle.id} 
                        updateCycle={updateCycle} 
                        updateDayOrder={updateDayOrder} 
                        destroyWrtd={destroyWrtd}
                        createWrtd={createWrtd}
                        destroyCycle={destroy}
                        basePath={basePath}
                        allowDestroy={allowDestroy}
                        trainer={trainer}
                    />))}
                    {workoutRoutine.isMulticycle() && (<div className="mt15 text-center">
                        <DefaultAsyncActionButton 
                            rounded 
                            color="grey" 
                            className="no-upcase shadow add-cycle-btn" 
                            outlined 
                            id={`add-cycle-${subType}-btn`}
                            action={createCycle.bind(null,{ workoutRoutineId: workoutRoutine.id, subType })}
                        >
                            <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("Add Cycle")}
                        </DefaultAsyncActionButton>
                    </div>)}
                </div>
            )}
        />
    )
}

const mapDispatchToCycleProps = dispatch => ({
    updateCycle: (data) => dispatch(updateRoutineCycle(data)),
    updateDayOrder: (cycle) => dispatch(updateCycleDayOrder(cycle)),
    destroyCycle: (cycle) => dispatch(destroyRoutineCycle(cycle)),
    destroyWrtd: (wrtd) => dispatch(destroyWrtd(wrtd)),
    createWrtd: (data) => dispatch(createWrtd(data)),
    createCycle: (data) => dispatch(createRoutineCycle(data))
})

const mapStateToCycleProps = state => ({
    trainer: trainerRecordSelector(state)
})

CycleTab = connect(mapStateToCycleProps,mapDispatchToCycleProps)(CycleTab);

const EditRoutineSuccess = ({ workoutRoutine, basePath, activeTab, setActiveTab, match }) => {
    const { t } = useTranslation();
    const workoutKey = t('Workout schedule');
    const cardioKey = t('Cardio schedule');
    const tabs = [ 
        [workoutKey,() => <CycleTab cycles={workoutRoutine && workoutRoutine.workoutCycles()} basePath={basePath} workoutRoutine={workoutRoutine} subType={RoutineCycleClass.WORKOUT} />],
        [cardioKey,() => <CycleTab cycles={workoutRoutine && workoutRoutine.cardioCycles()} basePath={basePath} workoutRoutine={workoutRoutine} subType={RoutineCycleClass.CARDIO} />],
    ]

    if(workoutRoutine) {
        return (
            <React.Fragment>
                <ResponsiveTabs 
                    tabs={tabs}
                    maxL={5}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                />
                <ExportRoutinePDFModal />
                <SettingsModal basePath={basePath} workoutRoutine={workoutRoutine} />
                <RestoreDefaultsModal basePath={basePath} workoutRoutine={workoutRoutine} />
                <RestartRoutineModal basePath={basePath} workoutRoutine={workoutRoutine} />
                <CycleSettingsModal basePath={basePath} workoutRoutine={workoutRoutine} />
                <CopyWorkoutModal basePath={basePath} workoutRoutine={workoutRoutine} />
                <ImportWorkoutTemplatesModal basePath={basePath} workoutRoutine={workoutRoutine} match={match} />
                <span id="routine-loaded" style={{ display: 'none'}}></span>
            </React.Fragment>
        )
    } else {
        return (<Redirect to={resolvedHomePath()} />)
    }
}

class EditRoutinePage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
    }

    render() {
        const { 
            t, 
            scrollRef, 
            workoutRoutine, 
            user, 
            trainer, 
            loadRoutine, 
            setActiveTab, 
            activeTab, 
            basePath=editRoutinePath, 
            warmupsNeedLoading, 
            match, 
            match: { params: { id }},
            location: { pathname}
        } = this.props;
        const isClientRoutine = trainer && user && user.id !== trainer.id;
        const showClientEditPopup = trainer && user && user.id !== trainer.id;
        let title = t("Edit Routine");
        if(isClientRoutine) {
            title = t("name's Routine", { name: user.shortName() })
        } else if(trainer && user && user.id === trainer.id && workoutRoutine) {
            title = workoutRoutine.resolvedName(t);
        }

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow className="with-responsive-tabs">
                    <SimpleNavTitle>{title}</SimpleNavTitle>
                    {(workoutRoutine && workoutRoutine.isLoaded()) && (<Dropdown 
                        contentComp='ul'
                        options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
                        triggerRender={({ ref, target }) => {
                            return (
                                <SimpleNavRightButton ref={ref} data-target={target} id="routine-menu-btn" icon="ellipsis-v" className="pr20" />
                            )
                        }}
                        contentRender={({ recalcDims }) => {
                            return (
                                <React.Fragment>
                                    <li>
                                        <Link to={routineSettingsPathFor(basePath)} id={`routine-settings-btn`}>
                                            <FontAwesomeIcon icon="cog" /> {t("Routine Settings")}
                                        </Link>
                                    </li>
                                    <DropdownLinkTPB type={TRAINING} id="pdf-routine-btn" label={t("Export PDF")} icon={['far','file-export']} to={modalPathFor(exportRoutinePDFSuffix,pathname,{ routineId: workoutRoutine.id })} allowUninit={false} />
                                    {!window.isCordova && (<DropdownLinkTPB type={TRAINING} id="routine-summary-btn" label={t("Routine Summary")} icon="eye" to={previewRoutinePdfUrlFor({ id: workoutRoutine.id },true,false)} allowUninit={false} linkTarget="_blank" />)}
                                    {workoutRoutine.canRestartRoutine(user) && basePath === editRoutinePath && (<li>
                                        <Link to={restartRoutinePathFor(basePath)} id={`restart-routine-modal-btn`}>
                                            <FontAwesomeIcon icon="undo" /> {t("Restart Routine")}
                                        </Link>
                                    </li>)}
                                    {workoutRoutine.canRestoreDefaults(user) && basePath === editRoutinePath && (<li>
                                        <Link to={restoreRoutineDefaultsPathFor(basePath)} id={`restore-defaults-modal-btn`}>
                                            <FontAwesomeIcon icon="cloud-download-alt" /> {t("Restore Defaults")}
                                        </Link>
                                    </li>)}
                                </React.Fragment>
                            )
                        }}
                    />)}
                </SimpleNav>
                <SimpleNavContainer className="with-responsive-tabs">
                    <RedirectContext.Consumer>
                            {switchDisabled => {
                                if(switchDisabled) {
                                    return '';
                                }

                                return (
                                    <RoutineUserReconciler workoutRoutine={workoutRoutine} user={user} trainer={trainer}>
                                        <Loader
                                            load={loadRoutine.bind(null,(id ? Number(id) : user.assignedRoutineId),warmupsNeedLoading)}
                                            preloaded={() => (workoutRoutine && workoutRoutine.isLoaded())}
                                            successComponent={EditRoutineSuccess}
                                            type='page'
                                            workoutRoutine={workoutRoutine}
                                            basePath={basePath}
                                            activeTab={activeTab}
                                            setActiveTab={setActiveTab}
                                            match={match}
                                        />
                                        {showClientEditPopup && (<InitialTipPopup tipName={EDIT_CLIENT_ROUTINE} id="edit-client-routine-tip" >
                                            <div>
                                                <p className="pl20 pr20">
                                                    <Trans i18nKey='edit client routine tip' values={ { name: user.fullName() }}><Link to={myRoutinesPath}></Link></Trans>
                                                </p>
                                                <p className="pl20 pr20">
                                                    {t("edit client routine tip2")}
                                                </p>
                                            </div>
                                        </InitialTipPopup>)}
                                    </RoutineUserReconciler>
                                )
                            }}
                        </RedirectContext.Consumer>
                </SimpleNavContainer>
            </Page>
        )
    }
}

class RoutineUserReconciler extends React.Component {

    constructor(props) {
        super(props);
        this.reconcileUser();
    }

    componentDidUpdate(prevProps) {
        this.reconcileUser();
    }

    render() {

        return (
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        )
    }

    reconcileUser = () => {
        const { workoutRoutine, user, trainer, switchToTrainer } = this.props;
        if(trainer && workoutRoutine && workoutRoutine.ownerId === trainer.id && (!user || user.id !== trainer.id)) {
            switchToTrainer();
        }
    }
}

const mapDispatchToReconcileProps = dispatch => ({
    switchToTrainer: () => dispatch(switchToTrainer())
})

RoutineUserReconciler = connect(null,mapDispatchToReconcileProps)(RoutineUserReconciler);

export const EditRoutineShortcut = (props) => {
    const { t } = useTranslation();

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('Edit Routine')} afterPath={editRoutinePath} />
    )
}

const mapStateToProps = (state) => {
    const routineSelector = state.data.user ? getWorkoutRoutineSelector(state.data.user.assignedRoutineId) : () => null;
    return {
        user: userRecordSelector(state),
        trainer: trainerRecordSelector(state),
        workoutRoutine: routineSelector(state),
        warmupsNeedLoading: warmupsNeedLoadingSel(state)
    }
}

export const mapDispatchToProps = dispatch => ({
    loadRoutine: (id,loadWarmups) => dispatch(loadWorkoutRoutine(id,loadWarmups))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(EditRoutinePage));

export const mapStateToStandaloneProps = (state,props) => {
    const { match: { params: { id }}} = props;
    const routineSelector = getWorkoutRoutineSelector(Number(id));
    return {
        user: userSelector(state),
        trainer: trainerRecordSelector(state),
        workoutRoutine: routineSelector(state),
        warmupsNeedLoading: warmupsNeedLoadingSel(state),
        basePath: editRoutineStandlonePathFor(id)
    }
}

const EditRoutineStandalonePage = connect(mapStateToStandaloneProps,mapDispatchToProps)(withTranslation()(EditRoutinePage));

export { EditRoutineStandalonePage, RoutineUserReconciler };

