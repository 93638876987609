import { RecordBase, registerInflection } from 'lib/record-base';
import { pickNewRoutineModalPathFor, deloadPathFor, notificationsPath, undeloadPathFor } from 'config/paths';

export class Notification extends RecordBase {
    static NAME = 'Notification'

    static ASSOCS = {
        user: { type: 'belongsTo' }
    }

    linkPath(user) {
        if(this.sourceType === 'WorkoutRoutine') {
            return pickNewRoutineModalPathFor(notificationsPath);
        } else if(this.sourceType === 'ExerciseSpecification') {
            if(user.deloadButtonType() === 'undo') {
                return undeloadPathFor(notificationsPath);
            } else {
                return deloadPathFor(notificationsPath);
            }
        } else {
            return null;
        }
    }

}

registerInflection('notification','notifications',Notification);