import React from 'react';
import { useTranslation } from 'react-i18next';
import { offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, swapMealMatch, swapTempRecipeMatch, addTempRecipeMatch, swapTempRecipePathFor, addTempRecipePathFor, viewTempMealMatch, viewTempRecipeReplacementMatch, viewTempMealPathFor, emailPaywallMatches, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { userWithMealPlanSelector, mealSearchFullSelector, tempMealSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { Redirect } from 'components/Routing';
import { EditMealLoader } from 'partials/EditMealCore';
import { Loader } from 'components/LoadingHOC';
import { getRandomMeal, replaceMeals, replaceTempRecipe, setTempRecipeMealServings, setupReplaceRecipe } from 'redux/actions';
import { resolvedHomePath } from 'redux/helpers';
import { ExitMealPlanButton } from 'partials/Utilities';

const transitionMap = {
    rules: [
        [[...allMainMatches, offPlanMealsSetupMatch, addPeopleToMealsMatch, regenerateWeekMatch, swapMealMatch],transitions.slideOut],
        [[swapTempRecipeMatch,addTempRecipeMatch,viewTempMealMatch,viewTempRecipeReplacementMatch,...emailPaywallMatches], transitions.slideOver]
    ]
};

const EditMealWrapper = ({ mealSearch, tempMeal, loadRandomMeal, destroyDish, randomizeMeal, setServings, setupReplaceRecipe, history, confirmAction }) => {
    let load, preloaded;
    if(tempMeal.needRandom) {
        load = loadRandomMeal.bind(null, { mealTypeId: mealSearch.contextId, recipeId: tempMeal.recipeId });
        preloaded = () => false;
    } else {
        load = () => {};
        preloaded = () => true;
    }

    return (
        <Loader
            load={load}
            preloaded={preloaded}
            type='page'
            successComponent={EditMealLoader}
            goToReplaceRecipe={(recipeId) => {
                setupReplaceRecipe({ recipeId, mealTypeId: mealSearch.contextId });
                history.push(recipeId ? swapTempRecipePathFor(mealSearch.contextId,recipeId) : addTempRecipePathFor(mealSearch.contextId))
            }}
            meal={tempMeal}
            destroyDish={destroyDish.bind(null,tempMeal)}
            setServings={setServings.bind(null,tempMeal)}
            getMacroWarning={(t,meal) => mealSearch.macroWarningFor(t,meal)}
            confirmAction={confirmAction}
            getRecipeCardPath={(recipeId) => viewTempMealPathFor(mealSearch.mealCount(),recipeId)}
            randomizeMeal={randomizeMeal.bind(null,mealSearch.contextId,tempMeal.needRandom ? null : tempMeal.mainRecipeId())}
        />
    )
}

const EditTempMealPage = (props) => {
    const { t } = useTranslation();
    const { scrollRef, setupTransitions, loadRandomMeal, destroyDish, randomizeMeal, setupReplaceRecipe, setServings, history } = props;

    return (
        <SimpleNavPage
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t('Edit Replacement')}
            navChildren={<ExitMealPlanButton />}
        >
            <TempMealWrapper 
                render={({ redirect, user, mealSearch, tempMeal, selectAction }) => {

                    if(!redirect) {

                        return (<EditMealWrapper 
                            user={user} 
                            tempMeal={tempMeal} 
                            loadRandomMeal={loadRandomMeal} 
                            mealSearch={mealSearch} 
                            destroyDish={destroyDish} 
                            randomizeMeal={randomizeMeal}
                            setServings={setServings}
                            setupReplaceRecipe={setupReplaceRecipe}
                            history={history}
                            confirmAction={selectAction}
                        />)
                    }

                    return <Redirect to={redirect} />

                }}
            />
        </SimpleNavPage>
    )
}

let TempMealWrapper = ({ tempMeal, confirmAction, mealSearch, render, ...rest }) => {
    const redirect = (mealSearch.canEditMeals() && tempMeal) ? null : resolvedHomePath();
    const newConfirm = ({ meal }) => confirmAction({ ...meal.rawMealObject(), contextId: mealSearch.contextId });

    return render({ tempMeal, selectAction: newConfirm, redirect, mealSearch, ...rest })
}

const mapDispatchToProps = dispatch => ({
    loadRandomMeal: data => dispatch(getRandomMeal(data)),
    destroyDish: (meal,recipeMeal) => dispatch(replaceTempRecipe(meal, { recipeId: recipeMeal.recipeId })),
    randomizeMeal: (mealTypeId,recipeId,excludedIds) => dispatch(getRandomMeal({ mealTypeId, recipeId, excludedIds }, true)),
    setupReplaceRecipe: data => dispatch(setupReplaceRecipe(data)),
    setServings: (meal,data) => dispatch(setTempRecipeMealServings(meal,data))
})

const mapDispatchToWrapperProps = dispatch => ({
    confirmAction: data => dispatch(replaceMeals(data))
})

const mapStateToWrapperProps = state => ({
    user: userWithMealPlanSelector(state),
    mealSearch: mealSearchFullSelector(state),
    tempMeal: tempMealSelector(state)
})

TempMealWrapper = connect(mapStateToWrapperProps,mapDispatchToWrapperProps)(TempMealWrapper)

export { TempMealWrapper }

export default connect(null,mapDispatchToProps)(EditTempMealPage)