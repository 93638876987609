import React from 'react';
import { useTranslation } from 'react-i18next';
import { LowOpacityText } from 'components/Typography';
import { macroHashSummary } from 'lib/utilities';

export const MacroSummaryText = ({ macroHash }) => {
    const { t } = useTranslation();

    return (
        <span>{macroHashSummary(t,macroHash)}</span>
    )
}

const MacroSummary = ({ macroHash, t, ...rest }) => {
    
    return (
        <LowOpacityText {...rest}>
            <MacroSummaryText macroHash={macroHash} />
        </LowOpacityText>
    )
}

export default MacroSummary;