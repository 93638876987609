import React from 'react';
import { addAssessmentsToFormMatch, addExercisesToFormMatch, allMainMatches, strtstFormMatch, trainerFormsPath } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import ActivityContainer from 'components/ActivityContainer';
import { getDbFormSelector, trainerWithFormsSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import TrainerForm from 'partials/TrainerForm';
import { TagButtons } from 'components/Form';
import { Loader } from 'components/LoadingHOC';
import { deleteTrainerForm, importTrainerForm, loadTrainerForm } from 'redux/actions';
import { DropdownDelete, DropdownItem, MultiLevelDropdown } from 'components/Dropdown';
import { SimpleNavRightButton } from 'components/Nav';

const transitionMap = {
    rules: [
        [[ ...allMainMatches, trainerFormsPath ],transitions.slideOut],
        [[ addAssessmentsToFormMatch, addExercisesToFormMatch, strtstFormMatch ],transitions.slideOver]
    ]
};

class EditTrainerForm extends React.Component {

    constructor(props) {
        super(props);

        this.state = { viewType: 'client' }
    }

    render() {
        const { trainer, loadForm, scrollRef, setupTransitions, t, dbForm, importForm, destroy, match: { params: { id } }, history } = this.props;
        const form = trainer.getFormById(id) || dbForm;
        const isEditable = trainer.canEditTrainerRecord(form);
        let title = isEditable ? t('Edit Form') : t('View Form');
        if(form) {
            title = isEditable ? form.editName(t) : form.viewName(t);
        }


        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={title}
                navChildren={(
                    <MultiLevelDropdown
                        options={ { coverTrigger: false, alignment: 'right', closeOnClick: true, closeOnChildClick: true } }
                        triggerRender={({ ref, target }) => {
                            return (
                                <SimpleNavRightButton 
                                    ref={ref} 
                                    data-target={target} 
                                    id="form-menu-btn" 
                                    icon="ellipsis-v" 
                                    className="pr20" 
                                />
                            )
                        }}
                    >
                        <DropdownItem label={(trainer.showTeamFeatures() || !isEditable) ? t("Copy to My Account") : t('Copy')} icon={['far','copy']} onClick={() => importForm({ id: form.id, team: false },true)} id="copy-btn" />
                        {trainer.showTeamFeatures() && (<DropdownItem label={t("Copy to Team Level")} icon={['far','users']} onClick={() => importForm({ id: form.id, team: true },true)} id="copy-team-btn" />)}
                        {isEditable && (<DropdownDelete action={() => destroy(form).then(() => history.go(-1))} label={t('Delete')} icon={"times"} id="delete-btn" />)}
                    </MultiLevelDropdown>
                )}
            >
                    <ActivityContainer>
                        <div className="col s12 l10 offset-l1">
                            {form.isAssessment() && (<div className="text-center">
                                <TagButtons
                                    single
                                    col={[{ text: t('Client View'), value: 'client' }, { text: t('Coach View'), value: 'trainer' } ]}
                                    setFieldTouched={() => {}}
                                    setFieldValue={(name,value) => this.setState({ viewType: value })}
                                    name='viewType'
                                    values={{ viewType: this.state.viewType }}
                                />
                            </div>)}
                            <Loader
                                load={() => loadForm(id)}
                                preloaded={() => !_.isBlank(form)}
                                alwaysLoad
                                successComponent={TrainerForm}
                                type='page'
                                form={form}
                                viewType={this.state.viewType}
                                isEditable={isEditable}
                                viewer={trainer}
                            />
                        </div>
                </ActivityContainer>
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = (state,{ match: { params: { id } } }) => ({
    trainer: trainerWithFormsSelector(state),
    dbForm: getDbFormSelector(id)(state)
})

const mapDispatchToProps = dispatch => ({
    loadForm: id => dispatch(loadTrainerForm({ id })),
    importForm: (params,popup) => dispatch(importTrainerForm(params,popup)),
    destroy: (form) => dispatch(deleteTrainerForm({ id: form.id }))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(EditTrainerForm));