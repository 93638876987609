import React, {Component, useState} from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { FlowQuestionContainer, FlowQuestion, SmallCardOption, MinFlow, FlowChooseGoal } from 'components/FlowForm';
import { mainMatches, trainerMainMatches, rootPath, emailSignupMatch, trainerConversionMatch, trainerHomePath, trainerSignupMatch, trainerBrandingBase, trainerBrandingPathFor, contactPath, modalPathFor, brandedAppSetupPath } from 'config/paths';
import { Link, UnderlineLink } from "components/Routing";
import { FileUpload, FullInput, LoaderOnlySubmitButton, LoadingSubmitButton } from 'components/Form';
import { uploadTrainerLogo, getCustomDomain, setCustomDomain, updateSettings, dismissTooltip } from 'redux/actions';
import { trainerRecordSelector } from 'redux/selectors'
import { connect } from 'react-redux';
import TextInput from 'components/TextInput';
import * as _ from 'lib/utilities';
import * as transitions from 'assets/transitions';
import Page from 'components/Page';
import { TooltippedLabel } from 'components/Tooltipped';
import { SlightEmphasisIconNote } from 'components/Typography';
import { Loader } from 'components/LoadingHOC';
import FormikWrapper from 'components/FormikWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Yup from 'yup';
import { SETUP_BRANDING_TIP } from 'config/tooltips';
import Button, { AsyncSpinnerButton } from 'components/Button';
import LinkButton from 'components/LinkButton';
import { ContactFormModal, contactModalSuffix } from 'partials/ContactForm';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

const bpTransitionMap = {
    rules: [
        [[emailSignupMatch,trainerConversionMatch,contactPath],transitions.slideOver],
        [[...mainMatches,...trainerMainMatches,trainerSignupMatch,brandedAppSetupPath],transitions.slideOut],
        [rootPath,transitions.slideOut]
    ]
};

const hostlyRegex = /\.hostly\.app$/;

class BrandingComplete extends React.Component {

    constructor(props) {
        super(props);
        const { dismissTip } = this.props;
        dismissTip(SETUP_BRANDING_TIP);
    }


    render() {
        const { responseData: { domain: customDomain }, t } = this.props;
        const isHostly = !_.isBlank(customDomain) && hostlyRegex.test(customDomain);
        const ownDomain = !_.isBlank(customDomain) && !isHostly;

        return (
            <React.Fragment>
                <FontAwesomeIcon icon="check-circle" className="success-color" size="3x" />
                <FlowQuestion text={t(`Branding setup${ownDomain ? ' almost' : ''} complete!`)} id="branding-complete" />

                {ownDomain && (<div className="mt25">
                    <Trans i18nKey={"dont forget dns"} values={{ domain: customDomain }}>
                        <Link to={'https://www.strongrfastr.com/help/faq#ForProfessionalSubscribersHowdoIsetupmycustomdomain'} target="_blank"></Link><Link to={contactPath}></Link>
                    </Trans> 
                </div>)}
                
                <div className="mt50 no-wrap">
                    <LinkButton rounded color="primary" to={trainerHomePath} outlined={isHostly || ownDomain} id="back-home-btn">
                        <FontAwesomeIcon icon="home"></FontAwesomeIcon> {t("Back to Home")}
                    </LinkButton>
                    {isHostly && (<LinkButton rounded color="primary" to={`https://${customDomain}`} target="_blank" className="ml5" id="hostly-link-btn">
                        <FontAwesomeIcon icon="external-link-alt"></FontAwesomeIcon> {t("Visit My Domain")}
                    </LinkButton>)}
                    {ownDomain && (<LinkButton rounded color="primary" to={'https://www.strongrfastr.com/help/faq#ForProfessionalSubscribersHowdoIsetupmycustomdomain'} target="_blank" className="ml5" id="setup-domain-btn">
                        <FontAwesomeIcon icon="external-link-alt"></FontAwesomeIcon> {t("Setup Instructions")}
                    </LinkButton>)}
                </div>
            </React.Fragment>
        )
    }
}

const BrandingCompleteStep = ({ t, dismissTip, getCustomDomain }) => {

    return (
        <FlowQuestionContainer>
            <CustomDomainLoader 
                t={t}
                getCustomDomain={getCustomDomain}
                dismissTip={dismissTip}
                component={BrandingComplete}
            />
        </FlowQuestionContainer>
    )
}

const FeatureRow = ({ products, featureText, getProdInfo }) => {

    return (
        <tr>
            <td className="feature-text">{featureText}</td>
            {products.map(product => (
                <td key={product} className="check-cell">{getProdInfo(product)}</td>
            ))}
        </tr>
    )
}

const Check = () => (<FontAwesomeIcon icon="check" className="success-color" />)
const Ex = () => (<FontAwesomeIcon icon="times" className="error-color" />)

const AppTypeTable = ({ setHide, load, t }) => {
    const b = 'Branded App';
    const s = 'Strongr Fastr App';

    const products = ['Branded App','Strongr Fastr App'];
    const clickHandlerCreator = prod => () => {
        setHide(prod === b);
        setTimeout(load,0);
    }
    return (
        <React.Fragment>
            <table className="t-conv-feat-table">
                <thead className="main-head">
                    <tr>
                        <th colSpan="1"></th>
                        {products.map(type => (
                            <th key={type}>
                                <div className={classnames(`prod`,{ training: type === b, nutrition: type === s})}>
                                    <div >{type}</div>
                                    <div className="mt10">
                                        <Button rounded noShadow outlined onClick={clickHandlerCreator(type)} id={type === b ? 'hide-app-prompt-btn' : 'show-app-prompt-btn'}>
                                            <FontAwesomeIcon icon={"check"} /> {t('Select')}
                                        </Button>
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <FeatureRow products={products} featureText={t("tappf4")} getProdInfo={(product) => (product === b ? <Check /> : <React.Fragment><Ex />*</React.Fragment>)} />
                    <FeatureRow products={products} featureText={`${t("tappf5")}**`} getProdInfo={(product) => (product === b ? <Check /> : <Ex />)} />
                    <FeatureRow products={products} featureText={t('tappf1')} getProdInfo={(product) => (product === b ? <Ex /> : <Check />)} />
                    <FeatureRow products={products} featureText={t("tappf2")} getProdInfo={(product) => (product === b ? <Ex /> : <Check />)} />
                    <FeatureRow products={products} featureText={t("tappf3")} getProdInfo={(product) => (product === b ? <Ex /> : <Check />)} />
                    <FeatureRow products={products} featureText={t("tappf6")} getProdInfo={(product) => (<Check />)} />
                </tbody>
            </table>
            <div className="mt10 tiny-text text-left">*your branding will be applied to the app once your clients sign in. But the App Store listings and app icon will be Strongr Fastr branded</div>
            <div className="tiny-text text-left">**using Progressive Web App technology</div>
        </React.Fragment>

    )
}

const AppPromptCore = ({ t, load, setHide, history }) => {
    const { pathname } = useLocation();

    return (
        <React.Fragment>
            <AppTypeTable t={t} setHide={setHide} load={load} />
            <div className="mt25">
                <UnderlineLink to={modalPathFor(contactModalSuffix,pathname)}>I want a branded app on the App/Play Store</UnderlineLink>
            </div>
        </React.Fragment>
    )
}

const AppPromptLoader = ({ t, submit, history }) => {
    const [hideClientAppPrompt,setHide] = useState(null);

    return (
        <Loader
            defaultComponent={AppPromptCore}
            successComponent={AppPromptCore}
            type="padded"
            load={() => submit({ hideClientAppPrompt })}
            preloaded={() => false}
            skipAutoLoad
            t={t}
            setHide={setHide}
            successCallback={() => history.push(trainerBrandingPathFor(8))}
            history={history}
        />
    )
}

const ShowAppPrompt = ({ t, submit, history }) => {

    return (
        <FlowQuestionContainer className="larger">
            <FlowQuestion text={t("which tapp")} />
            <div className="inline-block">
                <AppPromptLoader 
                    t={t}
                    submit={submit}
                    history={history}
                />
            </div>
        </FlowQuestionContainer>
    )
}

const SetupPassword = ({ user, t, param, submit, history }) => {
    const params = param.split('-');
    const provider = params[0];
    const skipAppPrompt = params[1] === 'true';
    const nextPage = skipAppPrompt ? 8 : 7;
    const providerStr = _.oauthProviderString(provider);
    const [emailErr,setEmailErr] = useState(null);

    return (
        <FlowQuestionContainer className="larger">
            <FlowQuestion text={t("sign in with x not supported", { provider: providerStr })} />
            <FormikWrapper
                initialValues={ { email: user.email || '', password: '', passwordConfirmation: '' } }
                validationSchema={Yup.object().shape({ 
                    email: Yup.string().email().required(),
                    password: Yup.string().min(5).max(128).required(), 
                    passwordConfirmation: Yup.string().required().oneOf([Yup.ref('password')], t("doesn't match"))})}
                submit={submit}
                successCallback={({ status,formikBag,data }) => {
                    if(data.error) {
                        setEmailErr(data.error);
                    } else {
                        if(emailErr) {
                            setEmailErr(null);
                        }
                        history.push(trainerBrandingPathFor(nextPage))
                    }
                }}
                initialErrors={{}}
                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                    return (
                        <form onSubmit={handleSubmit} className="mt25">
                            <div >
                                <FullInput
                                    className="flex-grow"
                                    name="email"
                                    label={t('Email')}
                                    component={TextInput}
                                    formikProps={rest}
                                />
                                <FullInput
                                    className=""
                                    name="password"
                                    label={t('New Password')}
                                    component={TextInput}
                                    inProps={{ type: 'password' }}
                                    formikProps={rest}
                                />
                                <FullInput
                                    name="passwordConfirmation"
                                    label={t('Confirm')}
                                    component={TextInput}
                                    inProps={{ type: 'password' }}
                                    formikProps={rest}
                                />
                                <div className="mt25">
                                    <LoadingSubmitButton 
                                        icon="check" 
                                        label={t("Save")} 
                                        loadState={submitState} 
                                    />
                                </div>
                            </div>
                        </form>
                    )
                }}
            />
        </FlowQuestionContainer>
    )
}

const ConfirmNoDomainCore = ({ t, dismissTip, setCustomDomain, responseData: { domain }}) => {
    const [confirmed,setConfirmed] = useState(_.isBlank(domain));

    if(confirmed) {
        return (
            <BrandingComplete 
                dismissTip={dismissTip}
                responseData={ { domain: null } }
                t={t}
            />
        )
    }

    return (
        <React.Fragment>
            <FlowQuestion text={t("need to delete domain")} />
            <AsyncSpinnerButton
                buttonProps={{
                    color: 'red',
                    className: 'mt50 no-upcase',
                    label: t('Deactivate domain', { domain }),
                    id: 'delete-domain-btn'
                }}
                icon={"times"}
                action={() => setCustomDomain({ domain: '' })}
                successCallback={() => setConfirmed(true)}
            />
        </React.Fragment>
    )
}

const ConfirmNoDomain = ({ t, dismissTip, getCustomDomain, setCustomDomain }) => {

    return (
        <FlowQuestionContainer>
            <CustomDomainLoader 
                t={t}
                dismissTip={dismissTip}
                getCustomDomain={getCustomDomain}
                setCustomDomain={setCustomDomain}
                component={ConfirmNoDomainCore}
            />
        </FlowQuestionContainer>
    )
}

const CustomDomainLoader = ({ component, getCustomDomain, ...rest }) => {

    return (
        <Loader
            successComponent={component}
            type="padded"
            load={getCustomDomain}
            preloaded={() => false}
            {...rest}
        />
    )
}

const DomainCore = ({ user, isSubdomain, history, setCustomDomain, t, responseData: { domain: currentDomain, passType, hasBrandedAppSub } }) => {
    let initialSub = currentDomain;
    if(isSubdomain && !_.isBlank(currentDomain) && hostlyRegex.test(currentDomain)) {
        initialSub = currentDomain.replace(hostlyRegex,'');
    }
    const hasPass = (passType === 'password' || user.sawTooltip(SETUP_BRANDING_TIP));
    const nextPage = hasBrandedAppSub ? 8 : 7;
    const hasCurrent = !_.isBlank(currentDomain);
    const [isDup,setIsDup] = useState(false);
    const regex = isSubdomain ? /^[A-Za-z0-9](?:[A-Za-z0-9-]{0,61}[A-Za-z0-9])?$/ : /^([a-zA-Z0-9]([-a-zA-Z0-9]{0,61}[a-zA-Z0-9])?\.)+([a-zA-Z0-9]{1,2}([-a-zA-Z0-9]{0,252}[a-zA-Z0-9])?)\.([a-zA-Z]{2,63})$/
    let initErrs = _.isBlank(initialSub) ? { domain: t('Required') } : {};
    if(_.isEmpty(initErrs) && !regex.test(initialSub)) {
        initErrs = { domain: t('invalid domain') };
    }


    return (
        <FormikWrapper
            initialValues={ { domain: initialSub } }
            validate={values => {
                const { domain } = values;
                if(_.isBlank(domain)) {
                    return { domain: t('Required')}
                }

                if(regex.test(domain)) {
                    return {};
                }

                return { domain: t('invalid domain') }
            }}
            submit={setCustomDomain}
            valuesFilter={values => ({ domain: (isSubdomain ? `${values.domain}.hostly.app` : values.domain) })}
            successCallback={({ data }) => {
                if(data.duplicate) {
                    setIsDup(true);
                } else if(hasPass) {
                    history.push(trainerBrandingPathFor(nextPage));
                } else {
                    history.push(trainerBrandingPathFor(6,`${passType}-${hasBrandedAppSub}`));
                }
            }}
            initialErrors={initErrs}
            render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                const { errors } = rest;
                const willOverwrite = (hasCurrent && initialSub !== rest.values.domain);

                return (
                    <form onSubmit={handleSubmit}>
                        <div className="valign-wrapper">
                            <FullInput
                                label={t(isSubdomain ? "Subdomain" : "Your Domain")}
                                className="flex-grow"
                                name="domain"
                                component={TextInput}
                                formikProps={ { ...rest, handleChange: e => {
                                    if(isDup) {
                                        setIsDup(false);
                                    }
                                    return rest.handleChange(e);
                                } } }
                            />
                            {isSubdomain && (<div>.hostly.app</div>)}
                        </div>
                        {isDup && (<div className="red-text"><FontAwesomeIcon icon="times" /> {t('domain unavailable')}</div>)}
                        <SlightEmphasisIconNote text={t(isSubdomain ? 'subdomain tip' : 'domain tip')} />
                        <LoaderOnlySubmitButton 
                            icon={willOverwrite ? 'times' : 'arrow-right'} 
                            label={willOverwrite ? t('overwrite domain',{ domain: currentDomain }) : t("Next")} 
                            className="mt50 no-upcase" 
                            color={willOverwrite ? 'red' : 'primary'}
                            loadState={submitState} 
                            disabled={!_.isEmpty(errors)} 
                            id="next-btn"
                        />
                    </form>
                )
            }}
        />
    )
}

const DomainSetup = ({ user, history, t, isSubdomain, getCustomDomain, setCustomDomain }) => {

    return (
        <FlowQuestionContainer>
            <FlowQuestion text={`${t(isSubdomain ? 'Set your subdomain' : 'Set your domain')}:`} />
            <CustomDomainLoader 
                isSubdomain={isSubdomain}
                history={history}
                t={t}
                getCustomDomain={getCustomDomain}
                setCustomDomain={setCustomDomain}
                component={DomainCore}
                user={user}
            />
        </FlowQuestionContainer>
    )
}

const DomainTypeQuestion = ({ user, page, history, t }) => {

    return (
        <FlowQuestionContainer className={"larger"}>
            <FlowQuestion text={user.isPdfTrainer() ? 'Pick a domain for you & your team to use:' : 'Pick a domain for you & your clients to use:'} />
            <div className="inline-block">
                <SmallCardOption 
                    onClick={() => history.push(trainerBrandingPathFor(page+2))} 
                    title={t('White Label Domain')} 
                    subtitle={<React.Fragment><div>{t("white label domain tip")}</div><div className="mt10"><b>{t('No technical setup required.')}</b></div></React.Fragment>}
                    className="larger"
                    id="hostly-btn"
                />
                <SmallCardOption 
                    onClick={() => history.push(trainerBrandingPathFor(page+3))} 
                    title={t('Your Own Domain')} 
                    subtitle={t("custom domain tip")}
                    className="larger"
                    id="custom-domain-btn"
                />
            </div>
        </FlowQuestionContainer>
    )
}

const ColorSelectBtn = ({ color, clickHandler, selected }) => {

    return (
        <div className="color-select-btn" style={{ backgroundColor: color }} id={`color-${color.replace('#','')}-btn`} onClick={() => clickHandler(color)}>
            {selected && (<FontAwesomeIcon icon="check" color="white" />)}
        </div>
    )
}

const ColorSelect = ({ name, t, ...formikProps }) => {
    const { values, setFieldValue } = formikProps;
    const colors = ['#e51c23','#EB5757','#e91e63','#9c27b0','#673ab7','#3f51b5','#2196F3','#03a9f4','#00bcd4','#009688','#4CAF50','#8bc34a','#cddc39','#ecd613','#ffc107','#ff9800','#ff5722','#795548','#607d8b','#7D7D7D']
    const value = values[name];
    const notCustom = _.isBlank(value) || colors.includes(value);
    const [custom,setCustom] = useState(!notCustom);
    const clickHandler = color => {
        if(_.isHexColor(color)) {
            const hsl = _.hexToHSL(color);
            _.setAppColor(hsl);
        }
        setFieldValue(name,color);
    }


    return (
        <div className="mb25">
            <TooltippedLabel center text={t('App Color')} tip={t('app color tip')} />
            {!custom && (
                <React.Fragment>
                    {colors.map(color =>(<ColorSelectBtn key={color} color={color} selected={value === color} clickHandler={clickHandler} />))}
                    <div className="color-select-btn" id="custom-color-btn" onClick={() => setCustom(true)}>
                        <span className="underline">{t('Custom')}</span>
                    </div>
                    <div className='inline-block'></div>
                </React.Fragment>
            )}
            {custom && (
                <div className="valign-wrapper cntr">
                    <div className="color-select-btn" onClick={() => setCustom(false)}>
                        <FontAwesomeIcon icon="times" color="black" />
                    </div>
                    <FullInput
                        className="ml5 flex-grow"
                        name={name}
                        label={t('Hex Code')}
                        component={TextInput}
                        formikProps={{ ...formikProps, handleChange: e => clickHandler(e.target.value) }}
                    />
                </div>
            )}
        </div>
    )
}

class LogoColorIcon extends React.Component {

    constructor(props) {
        super(props);

        const style = getComputedStyle(document.documentElement);
        this.initialHSL = { h: style.getPropertyValue('--primary-h'), s: style.getPropertyValue('--primary-s'), l: style.getPropertyValue('--primary-l') }
    }

    render() {
        const { user, submit, history, page, t } = this.props;

        return (
            <FlowQuestionContainer>
                <FlowQuestion text={'Optional: set your logo, app color, and app icon'} />
                <div className="inline-block text-center">
                    
                    <FormikWrapper
                        initialValues={ user.logoColorIconVals() }
                        validate={values => {
                            const { brandColor } = values;
                            if(_.isBlank(brandColor) || _.isHexColor(brandColor)) {
                                return {}
                            }
    
                            return { brandColor: t('invalid hex code')}
                        }}
                        submit={submit}
                        valuesFilter={values => _.imageFormFilter('defaultLogo','logo')(_.imageFormFilter('defaultAppIcon','appIcon')(values))}
                        successCallback={() => {
                            this.initialHSL = null;
                            history.push(trainerBrandingPathFor(page+1));
                        }}
                        initialErrors={{}}
                        render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                            const { errors } = rest;
    
                            return (
                                <form onSubmit={handleSubmit}>
                                    <div className="mb25 display-flex" style={{ minWidth: '320px' }}>
                                        <div className="flex-grow">
                                            <div className="inline-block" style={{ maxWidth: '150px' }}>
                                                <TooltippedLabel center text={t('Logo')} tip={t('logo tip')} />
                                                <FileUpload
                                                    name="logo"
                                                    setFieldValue={rest.setFieldValue}
                                                    imgStyle={{ maxWidth: '100%', maxHeight: '63px'}}
                                                    defaultImageUrl={_.isBlank(rest.values.logo) ? rest.values.defaultLogo : rest.values.logo}
                                                    idOverride="logo-upload"
                                                />
                                            </div>
                                        </div>
                                        <div className="flex-grow">
                                            <div className="inline-block" style={{ maxWidth: '150px' }}>
                                                <TooltippedLabel center text={`${t('App Icon')}`} tip={t('app icon tip')} />
                                                <FileUpload
                                                    name="appIcon"
                                                    setFieldValue={rest.setFieldValue}
                                                    imgStyle={{ maxWidth: '63px', maxHeight: '63px'}}
                                                    defaultImageUrl={_.isBlank(rest.values.appIcon) ? rest.values.defaultAppIcon : rest.values.appIcon}
                                                    idOverride="app-icon-upload"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <ColorSelect name="brandColor" t={t} {...rest} />
                                    <LoaderOnlySubmitButton 
                                        icon="arrow-right" 
                                        label={t("Next")} 
                                        className="btn-wide mt25" 
                                        loadState={submitState} 
                                        disabled={!_.isEmpty(errors)} 
                                        id="next-btn"
                                    />
                                </form>
                            )
                        }}
                    />
                </div>
            </FlowQuestionContainer>
        )
    }

    componentWillUnmount() {
        if(this.initialHSL) {
            _.setAppColor(this.initialHSL,false);
        }
    }
}

const mapStateToProps = (state,props) => ({
    user: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(updateSettings(data,false)),
    submitLogo: data => dispatch(uploadTrainerLogo(data)),
    getCustomDomain: () => dispatch(getCustomDomain()),
    setCustomDomain: data => dispatch(setCustomDomain(data)),
    dismissTip: tipName => dispatch(dismissTooltip(tipName))
})

class TrainerBrandingPage extends Component {

    constructor(props) {
        super(props);
        const { setupTransitions } = props;
        setupTransitions(bpTransitionMap);
    }

    render() {
        const { scrollRef, user, t, history, submit, submitLogo, getCustomDomain, setCustomDomain, dismissTip, match: { params: { param }} } = this.props;

        return (
        <Page ref={scrollRef}>
            <MinFlow basePath={trainerBrandingBase}>
                <FlowChooseGoal key={1}>
                    <LogoColorIcon page={1} user={user} t={t} history={history} submit={submitLogo} />
                </FlowChooseGoal>
                <FlowChooseGoal key={2}>
                    <DomainTypeQuestion page={2} user={user} t={t} history={history} />
                </FlowChooseGoal>
                <FlowChooseGoal key={3}>
                    <ConfirmNoDomain t={t} dismissTip={dismissTip} setCustomDomain={setCustomDomain} getCustomDomain={getCustomDomain} />
                </FlowChooseGoal>
                <FlowChooseGoal key={4}>
                    <DomainSetup user={user} isSubdomain history={history} t={t} getCustomDomain={getCustomDomain} setCustomDomain={setCustomDomain} />
                </FlowChooseGoal>
                <FlowChooseGoal key={5}>
                    <DomainSetup user={user} history={history} t={t} getCustomDomain={getCustomDomain} setCustomDomain={setCustomDomain} />
                </FlowChooseGoal>
                <FlowChooseGoal key={6}>
                    <SetupPassword history={history} t={t} user={user} submit={submit} param={param} />
                </FlowChooseGoal>
                <FlowChooseGoal key={7}>
                    <ShowAppPrompt history={history} t={t} submit={submit} />
                </FlowChooseGoal>
                <FlowChooseGoal key={8}>
                    <BrandingCompleteStep t={t} getCustomDomain={getCustomDomain} dismissTip={dismissTip} />
                </FlowChooseGoal>
            </MinFlow>
            <ContactFormModal 
                title="Branded App Store Listing Inquiry" 
                prompt={t('branded app inquiry prompt')} 
                contactType={'branded app inquiry'} 
                subject={'A trainer is inquiring about a branded iOS/Android app'} 
                completeMsg={t('branded app inquiry success')}
            />
        </Page>
        );
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(TrainerBrandingPage));
