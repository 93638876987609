import React from 'react';
import Page from 'components/Page';
import { SimpleNav, SimpleNavTitle, SimpleNavContainer } from 'components/Nav';
import { Redirect } from "components/Routing";
import withWorkout from 'partials/RoutineStateHOC';
import { withTranslation, useTranslation } from 'react-i18next';
import { workoutPreviewMatch, workoutLogMatch, progressionPickPathFor, 
    exerciseInfoModalPathFor, progressionPickMatch, workoutDoMatch, swapExerciseSpecMatch, progressionShowPathFor, allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import moment from 'moment';
import { ExerciseProgressionListing } from 'partials/ExerciseListing';
import { getExerciseProgressionsSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import { loadExProgression, submitProgTest, standaloneStartWorkout, handleProgPickFail } from 'redux/actions';
import { ExerciseInfoModal } from 'partials/ExerciseModals';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgressSmall } from 'components/CircularProgress';
import * as _ from 'lib/utilities';
import { dateFormat } from 'config/settings';
import classnames from 'classnames';
import { ListCardAction } from 'components/List';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { resolvedHomePath } from 'redux/helpers';

const transitionMap = {
    rules: [
        [[...allMainMatches,workoutPreviewMatch,workoutLogMatch,workoutDoMatch,swapExerciseSpecMatch],transitions.slideOut],
    ]
};

const ListLC = loadingContainer({
    "SUCCESS": ExerciseProgressionListing
}, { type: 'padded' });


const PickButton = ({ load, error, requesting, className, icon, label, id }) => {
    const { t } = useTranslation()
    const classNames = classnames("prog-pick-btn", { [className]: className});

    return (
        <ListCardAction className={classNames} onClick={load} id={id ? id : ''}>
            {requesting && (<div style={{ width: '40px', height: '20px' }}><CircularProgressSmall color="primary" /></div>)}
            {!requesting && <FontAwesomeIcon icon={icon || "check"} />}
            {(!requesting && label) && (<div className="action-label">{label}</div>)}
            {error && <div className="red-text prog-pick-err" >{t('failed')}</div>}
        </ListCardAction>
    )
}

class RequestingPickButton extends React.Component {

    constructor(props) {
        super(props);
        const { setBlockModals } = this.props;
        setBlockModals && setBlockModals(true);
    }

    render() {
        return (
            <PickButton requesting />
        )
    }
}

const SuccessPickButton = () => ('')

export const PickButtonLC = loadingContainer({
    "DEFAULT": PickButton,
    'REQUEST': RequestingPickButton, 
    'NETERR': PickButton, 
    'SERVERERR': PickButton, 
    'MAINTENANCE': PickButton,
    "SUCCESS": SuccessPickButton
})

export const MultiPickButtonLC = loadingContainer({
    "DEFAULT": PickButton,
    'REQUEST': RequestingPickButton, 
    'NETERR': PickButton, 
    'SERVERERR': PickButton, 
    'MAINTENANCE': PickButton,
    "SUCCESS": PickButton
})

class ProgressionPickList extends React.Component {

    constructor(props) {
        super(props);
        this.state = { blockModals: false };
        this.refreshHeader();
    }

    componentDidUpdate(prevProps) {
        if(this.props.t !== prevProps.t) {
            this.refreshHeader();
        }
    }

    render() {
        const { progression, loadProgression, submitProgTest, basePath, afterAction, reps, date, context, history } = this.props;
        const { blockModals } = this.state;

        const actionRender = ({ exercise }) => (
            <React.Fragment>
                {!blockModals && (<ListCardAction 
                    onClick={() => (history.push(exerciseInfoModalPathFor(basePath,exercise.id)))} 
                    className={`${`progex-${exercise.id}`} ${context === 'show' && 'no-border extra-padding'}`}
                >
                    <FontAwesomeIcon icon="info-circle" />
                </ListCardAction>)}
                {context !== 'show' && (<LoadingContainerParent 
                    load={submitProgTest.bind(null,{ exerciseProgressionId: progression.id, exerciseId: exercise.id, reps, date, arbitraryMeasure: exercise.arbitraryMeasureVal })}
                    preloaded={() => false}
                    component={PickButtonLC}
                    setBlockModals={(val) => this.setState({blockModals: val})}
                    successCallback={(data) => afterAction(exercise,data)}
                    failCallback={() => this.setState({blockModals: false})}
                    className={`prog-pick-btn-${exercise.id}`}
                    skipAutoLoad
                />)}
            </React.Fragment>
        )
    
        return (
            <LoadingContainerParent 
                load={loadProgression.bind(null,progression.id,date)}
                preloaded={() => progression.progressionExercises.length > 0}
                component={ListLC}
                exercises={progression.exercises()}
                actionRender={actionRender}
            />
        )
    }

    refreshHeader() {
        const { progression, setHeader, t } = this.props;
        setHeader(_.startCase(progression.fullName(t)));
    }
}

const mapStateToProps = (state,props) => {
    let { id } = props;
    id = Number(id);
    const progressionSelector = getExerciseProgressionsSelector(id,props.date);
    return {
        progression: progressionSelector(state)
    }
}

const mapDispatchToProps = dispatch => ({
    loadProgression: (id,date) => dispatch(loadExProgression(id,date))
})

const ConnectedPickList = connect(mapStateToProps,mapDispatchToProps)(ProgressionPickList);

class ProgressionPickCore extends React.Component {

    constructor(props) {
        super(props);
        this.finish = this.finish.bind(this);
        this.getSpec = this.getSpec.bind(this);
        const { startWorkout, date } = this.props;
        startWorkout(date.format(dateFormat))
    }

    render() {
        const { workout, id, basePath, t, submitProgTest, context, setHeader, history } = this.props;
        const spec = workout && workout.getSpec(id);
    
        if(spec && spec.isProgression()) {
            const exerciseTemplate = spec.exerciseTemplate;
            return (
                <React.Fragment>
                    {context !== 'show' && (<div className="label-3 text-center ml10 mr10 mb15 mt20">
                        <FontAwesomeIcon icon="info-circle" className="primary-text" /> {t("Pick an exercise that’s moderately challenging to do for reps or time", {reps_or_time: exerciseTemplate.minRepsForProgStr(t) })}
                    </div>)}
                    <ConnectedPickList 
                        id={exerciseTemplate.exercisableId}
                        date={workout.date}
                        reps={exerciseTemplate.minRepsForProg()}
                        afterAction={this.finish}
                        submitProgTest={submitProgTest} 
                        basePath={basePath} 
                        context={context}
                        setHeader={setHeader}
                        history={history}
                        t={t}
                    />
                </React.Fragment>
            )
        } else {
            return (
                <Redirect to={resolvedHomePath()} />
            )
        }
    }

    getSpec() {
        const { workout, id } = this.props;
        const spec = workout && workout.getSpec(id);
        return spec;
    }

    finish(exercise,data) {
        const { history, id, handleFail, workout } = this.props;
        const affectedIds = Object.keys(data.exerciseSpecifications);
        if(!affectedIds.includes(id)) {
            handleFail(workout.date,id,exercise.id);
        }

        if(!this.finished) {
            history.go(-1);
            this.finished = true;
        }
    }

    componentWillUnmount() {
        this.finished = true;
    }
}

const mapDispatchToSubmitProgTest = dispatch => ({
    submitProgTest: (params) => dispatch(submitProgTest(params)),
    handleFail: (date,id,exerciseId) => dispatch(handleProgPickFail(date,id,exerciseId))
})

const WithWorkout = withWorkout('dirty','page',true)(connect(null,mapDispatchToSubmitProgTest)(ProgressionPickCore))

class ProgressionPickPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
        const { t } = this.props;
        this.state = { header: t('Exercise Progression') };
    }

    render() {
        const { startWorkout, match: { params: { date, specId: id }}, location, history, t, scrollRef } = this.props;
        const basePath = progressionPickPathFor(id,date);

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{this.state.header}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <CenteredActivityContainer>
                        <WithWorkout 
                            date={moment(date)}
                            startWorkout={startWorkout}
                            id={id} 
                            context={'pick'} 
                            t={t} 
                            location={location} 
                            history={history} 
                            basePath={basePath} 
                            setHeader={(newHeader) => this.setState({header: newHeader})} 
                        />
                    </CenteredActivityContainer>
                    <ExerciseInfoModal basePath={basePath} baseMatch={progressionPickMatch}/>
                </SimpleNavContainer>
            </Page>
        )
    }
}

class ProgressionShowPage extends React.Component {

    constructor(props) {
        super(props);
        props.setupTransitions(transitionMap);
        const { t } = this.props;
        this.state = { header: t('Exercise Progression') };
    }

    render() {
        const { match: { params: { id }}, t, scrollRef, history } = this.props;
        const basePath = progressionShowPathFor(id);

        return (
            <Page ref={scrollRef}>
                <SimpleNav primary shadow>
                    <SimpleNavTitle>{this.state.header}</SimpleNavTitle>
                </SimpleNav>
                <SimpleNavContainer>
                    <CenteredActivityContainer>
                        <ConnectedPickList 
                            id={id}
                            basePath={basePath}
                            context={'show'}
                            setHeader={(newHeader) => this.setState({header: newHeader})} 
                            history={history}
                            t={t}
                        />
                    </CenteredActivityContainer>
                    <ExerciseInfoModal basePath={basePath} baseMatch={basePath}/>
                </SimpleNavContainer>
            </Page>
        )
    }
}

ProgressionShowPage = withTranslation()(ProgressionShowPage);

export { ProgressionShowPage }


const mapDispatchToStartWorkout = dispatch => ({
    startWorkout: (date) => dispatch(standaloneStartWorkout(date))
})

export default withTranslation()(connect(null,mapDispatchToStartWorkout)(ProgressionPickPage));