import { dateFormat } from 'config/settings';
import { RecordBase, registerInflection } from 'lib/record-base';
import moment from 'moment';

export class MpInfoStub extends RecordBase {
    static NAME = 'MpInfoStub';
    static ASSOCS = {
        user: { type: 'belongsTo' }
    }

    weekStartMom() {
        return moment(this.weekStartDate);
    }

    formattedDate() {
        return this.weekStartMom().format(dateFormat);
    }

}

registerInflection('mpInfoStub','mpInfoStubs',MpInfoStub);
