import React from 'react';
import { allMainMatches, bodyMeasurementsPath, clientBmShortcutMatch, clientDetailsMatch, clientProgressPicsShortcutMatch, clientProgressShortcutMatch, progressMatch, progressPicsPath, strtstFormMatch, viewChatMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import ActivityContainer from 'components/ActivityContainer';
import { trainerWithFormsSelector, userWithFormsSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import * as _ from 'lib/utilities';
import TrainerForm from 'partials/TrainerForm';
import { Loader } from 'components/LoadingHOC';
import { fillTrainerForm, loadTrainerForm, reviewTrainerForm } from 'redux/actions';
import withTrainer from 'partials/TrainerLoader';

const transitionMap = {
    rules: [
        [[ ...allMainMatches, clientDetailsMatch, viewChatMatch ],transitions.slideOut],
        [[ strtstFormMatch, clientProgressPicsShortcutMatch, progressPicsPath, bodyMeasurementsPath, clientBmShortcutMatch, progressMatch, clientProgressShortcutMatch ],transitions.slideOver]
    ]
};

class ViewAssignedForm extends React.Component {

    render() {
        const { trainer, user, loadForm, scrollRef, setupTransitions, t, fillForm, reviewForm, match: { params: { clientId, formId } } } = this.props;
        let form;
        if(_.isBlank(clientId)) {
            form = user.getClientFormById(formId);
        } else {
            const client = trainer.clientById(clientId);
            form = client && client.getClientFormById(formId);
        }


        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={form ? form.viewName(t) : t('View Form')}
            >
                <ActivityContainer>
                    <div className="col s12 l10 offset-l1">
                        <Loader
                            load={() => loadForm(formId)}
                            preloaded={() => (!_.isBlank(form) && form.formFields.length > 0)}
                            alwaysLoad
                            successComponent={TrainerForm}
                            type='page'
                            form={form}
                            viewType={trainer ? 'trainer' : 'client'}
                            isFillable={true}
                            fillForm={trainer ? reviewForm : fillForm}
                            viewer={trainer || user}
                        />
                    </div>
                </ActivityContainer>
            </SimpleNavPage>
        )
    }
}

const mapStateToTrainerProps = (state) => ({
    user: userWithFormsSelector(state),
    trainer: trainerWithFormsSelector(state)
})

const mapDispatchToTrainerProps = dispatch => ({
    loadForm: id => dispatch(loadTrainerForm({ id })),
    fillForm: data => dispatch(fillTrainerForm(data)),
    reviewForm: data => dispatch(reviewTrainerForm(data))
})

const TrainerViewAssignedForm = connect(mapStateToTrainerProps,mapDispatchToTrainerProps)(withTranslation()(withTrainer(false,'page',trainerWithFormsSelector)(ViewAssignedForm)));
const ClientViewAssignedForm = connect(mapStateToTrainerProps,mapDispatchToTrainerProps)(withTranslation()(ViewAssignedForm));

export { TrainerViewAssignedForm, ClientViewAssignedForm }