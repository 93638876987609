import { switchCliAccountPathFor } from 'config/paths';
import { dateFormat, FULL } from 'config/settings';
import { GENERATE_FIRST_MP_TIP, MESSAGING_LIVE, SETUP_BRANDING_TIP, SETUP_FORMS_TIP } from 'config/tooltips';
import * as _ from 'lib/utilities';
import moment from 'moment';
import * as Yup from 'yup';
import { PdfExportSetting } from './pdf-export-setting';
import { trainerFormsConcern } from './trainer-forms-concern';
import { trainerModelFns } from './trainer-model-fns';

export const trainer = {

    trainerSignupValues(planType=null) {
        return _.parseObjForForm({ 
            firstName: this.firstName || '', 
            lastName: this.lastName || '', 
            businessName: this.businessName || '', 
            unitPreference: this.unitPreference || 0, 
            trainerType: this.trainerType || '',
            allowClientSelectWorkouts: this.allowClientSelectWorkouts || false,
            planType: planType
        });
    },

    setupStepsCol(t) {
        const initial = this.isPdfTrainer() ? [] : [{ text: t('Needs Invite'), value: 'needs_invite' }, { text: t('Invite Pending'), value: 'invite_pending' }]
        initial.push({ text: t('Needs Profile Setup'), value: 'needs_profile' });
        if(!this.showTrainingOnly()) {
            initial.push({ text: t('Needs Meal Plan Setup'), value: 'needs_meal_plan' });
        }

        if(!this.showNutritionOnly()) {
            initial.push({ text: t('Needs Workout Setup'), value: 'needs_workouts' });
        }

        return initial;
    },

    isChild() {
        return this.isTrainer() && !_.isBlank(this.master);
    },

    isMaster() {
        return this.isTrainer() && !this.isChild();
    },

    allTrainers() {
        if(this.isChild()) {
            return this.master.allTrainers()
        }

        return _.concat([this],this.children);
    },

    allActiveTrainers() {
        if(this.isChild()) {
            return this.master.allActiveTrainers()
        }

        return _.concat([this],_.filter(this.children,child => !child.isInactive()));
    },

    allInactiveTrainers() {
        if(this.isChild()) {
            return this.master.allInactiveTrainers()
        }

        return _.filter(this.children,child => child.isInactive());
    },

    allOtherTrainers(except=[],presel=[]) {
        return _.sortBy(_.filter(this.allActiveTrainers(),trainer => (trainer.id !== this.id && !except.includes(trainer.id))),t => (presel.includes(t.id) ? '00000' : t.sortName()));
    },

    hasLargeTeam() {
        return this.teamSize > 10;
    },

    usePopupForTrainerSelect() {
        return this.hasLargeTeam();
    },

    hasOtherTrainers() {
        return this.teamSize > 1;
    },

    showTeamFeatures() {
        return this.hasOtherTrainers() && this.isTrainerAdmin();
    },

    showAdvancedRecipeFields() {
        return false;
    },

    allClients() {
        if(this.master) {
            if(this.isTrainerAdmin()) {
                return this.master.allClients();
            }
            return this.clients;
        }

        return _.flatMap(this.allTrainers(),trainer => trainer.clients);
    },

    activeClients() {
        return _.filter(this.clients, user => !user.isInactive())
    },

    defaultClientSort() {
        return this.isAppTrainer() ? 'nameAsc' : 'lastActiveDesc';
    },

    clientsFor(filters,ids) {
        const { trainerId, routineId, tag, gender, goal, status, query, setup, showAllOnTextSearch } = filters;
        const fixedMatchIds = _.filter(ids,id => (typeof id === 'string' || id instanceof String));
        ids = _.difference(ids,fixedMatchIds);
        let matches = [];
        const fixedMatches = _.orderedArrayPick(fixedMatchIds.map(id => Number(id)),this.allClients());

        if(_.isBlank(trainerId)) {
            matches = this.isTrainerAdmin() ? this.allClients() : this.clients;
        } else {
            matches = this.trainerById(trainerId).clients;
        }

        matches = _.orderedArrayPick(ids.map(id => Number(id)),matches);

        matches = _.filter(matches,client => (!client.sampleClient || client.trainerId === this.id));
        if(_.isBlank(query)) {
            if(routineId) {
                if(routineId === 'none') {
                    matches = _.filter(matches,user => user.matchesNoRoutine());
                } else {
                    matches = _.filter(matches,user => user.isAssignedToRoutine(routineId));
                }
            }

            if(!_.isBlank(setup)) {
                if(setup === 'needs_invite') {
                    matches = _.filter(matches,match => match.inviteNeeded())
                } else if(setup === 'invite_pending') {
                    matches = _.filter(matches,match => match.invitePending())
                } else if(setup === 'needs_profile') {
                    matches = _.filter(matches,match => match.needsProfileSetup())
                } else if(setup === 'needs_workouts') {
                    matches = _.filter(matches, match => match.needsWorkoutSetup())
                } else if(setup === 'needs_meal_plan') {
                    matches = _.filter(matches, match => match.needsMealPlanSetup())
                }
            }
    
            if(!_.isBlank(tag)) {
                matches = _.filter(matches, match => match.fullTagList().map(tag => tag.toLowerCase()).includes(tag.toLowerCase()))
            }
    
            if(!_.isBlank(gender)) {
                matches = _.filter(matches, user => (this.constructor.male(gender) === user.male()));
            }
    
            if(!_.isBlank(goal)) {
                matches = _.filter(matches, user => (user.weightGoal === goal));
            }
        }

        if(_.isBlank(query) || !showAllOnTextSearch) {
            if(status === 'inactive') {
                matches = _.filter(matches, user => user.isInactive());
            } else if(status === 'billable') {
                matches = _.filter(matches, user => user.isBillable());
            } else {
                matches = _.filter(matches, user => !user.isInactive());
            }
        }

        return [ ...fixedMatches, ...matches]; 
    },

    validClientFilters(filters) {
        let { trainerId, routineId } = filters;
        if(_.isBlank(trainerId) && _.isBlank(routineId)) {
            return filters;
        }

        if(trainerId) {
            if(this.isTrainerAdmin() && this.hasOtherTrainers()) {
                trainerId = this.activeTrainerById(trainerId) ? trainerId : null;
            } else {
                trainerId = null;
            }
        }

        if(routineId && routineId !== 'none') {
            const routine = this.routineById(routineId);
            const routineValid = (routine && routine.isValidForFilter());
            if(!routineValid) {
                routineId = null;
            }
        }

        return { ...filters, trainerId, routineId };
    },

    allRoutines() {
        return _.flatMap(this.allTrainers(),trainer => trainer.workoutRoutines);
    },

    allActiveRoutines() {
        return _.flatMap(this.allTrainers(),trainer => trainer.activeWorkoutRoutines());
    },

    teamWorkoutRoutines(trainerId) {
        return _.filter(this.allActiveRoutines(),wrt => (_.isBlank(trainerId) ? wrt.ownerId !== this.id : wrt.ownerId === trainerId))
    },

    allActiveHabits() {
        return _.flatMap(this.allTrainers(),trainer => trainer.activeTrainerHabits());
    },

    teamTrainerHabits(trainerId) {
        return _.filter(this.allActiveHabits(),h => (_.isBlank(trainerId) ? h.ownerId !== this.id : h.ownerId === trainerId))
    },

    teamRecipesFor(trainerId) {
        if(_.isBlank(trainerId)) {
            return _.filter(this.teamRecipes,rec => rec.isActive());
        }

        const otherT = this.trainerById(trainerId);
        if(otherT) {
            return _.sortBy([ ...otherT.visibleActiveRecipes(() => true), ...otherT.activeLikedRecipes(() => true) ], rec => rec.name);
        }

        return [];
    },

    isTrainerAdmin() {
        return this.isTrainer() && (this.trainerRole === 'admin' || this.isMaster());
    },

    showVshredFeatures() {
        return this.email && this.email.includes('@vshred.com')
    },

    isTrainerRecipeAdmin() {
        return this.isTrainerAdmin();
    },

    canCreateFoods() {
        return this.isTrainerRecipeAdmin() || !this.isTrainer();
    },

    trainerRoleStr(t) {
        if(this.isMaster()) {
            return t('owner');
        } else if(this.trainerRole === 'admin') {
            return t('admin');
        } else {
            return t('standard member');
        }
    },

    activeNonSampleClients() {
        return _.filter(this.activeClients(),client => !client.sampleClient);
    },

    assignedClientsStr(count,t) {
        if(_.isBlank(count)) {
            return '';
        }
        return t("num clients", { num: count });
    },
        
    isClient() {
        return !_.isBlank(this.trainerId);
    },

    isTrainer() {
        return (this.type === 'Trainer');
    },

    isClientOrTrainer() {
        return (this.isClient() || this.isTrainer());
    },

    needsToLoadExerciseGroups() {
        return this.isTrainer() && !this.exerciseGroupsLoaded;
    },

    unsyncedRoutines() {
        return _.filter(this.activeWorkoutRoutines(), wrt => wrt.hasUserChanges());
    },

    unsyncedClients() {
        const editedRoutines = this.unsyncedRoutines();
        const routineIds = editedRoutines.map(wrt => wrt.id);
        const affectedUsers = _.filter(this.clients, client => client.needsRoutineSyncCheck(routineIds));
        return affectedUsers;
    },

    initialsName() {
        return _.fallbackText(_.startCase(_.compact([this.firstName,(this.lastName || '')[0]]).join(' ')),this.email)
    },

    resolvedTrainerForRecords(id) {
        if(_.isBlank(id)) {
            return '';
        }
        const ids = this.allOtherTrainers().map(trainer => trainer.id);
        if(ids.includes(id)) {
            return id;
        }

        return '';
    },

    activeWorkoutRoutines() {
        return _.filter(this.workoutRoutines, wrt => wrt.isActive());
    },

    activeTrainerHabits() {
        return _.filter(this.ownedHabits, h => h.isActive());
    },

    resolvedFilterTid(tid) {
        if(tid === 'me') {
            return this.id;
        }
        return tid;
    },

    trainerById(id) {
        if(id === 'me') {
            return this;
        }
        return _.find(this.allTrainers(),trainer => trainer.id === Number(id));
    },

    trainersByIds(ids) {
        ids = ids.map(id => Number(id));
        return _.orderedArrayPick(ids,this.allTrainers());
    },

    trainersForFilters(filters,ids) {
        const trainers = this.trainersByIds(ids);
        const { status, query, showAllOnTextSearch } = filters;

        if(_.isBlank(query) || !showAllOnTextSearch) {
            if(status === 'inactive') {
                return _.filter(trainers,t => t.isInactive());
            } else {
                return _.filter(trainers,t => t.isActive());
            }
        }

        return trainers;
    },

    activeTrainerById(id) {
        if(id === 'me') {
            return this;
        }
        return _.find(this.allActiveTrainers(),trainer => trainer.id === Number(id));
    },

    routineById(id) {
        return _.find(this.workoutRoutines,wrt => wrt.id === Number(id));
    },

    habitById(id) {
        return _.find(this.ownedHabits,h => h.id === Number(id))
    },

    filterRoutines() {
        return _.filter(this.workoutRoutines, wrt => wrt.isValidForFilter());
    },

    clientsByIds(ids) {
        ids = ids.map(id => Number(id));
        return _.filter(this.allClients(), client => ids.includes(client.id));
    },

    clientById(id) {
        return this.clientsByIds([id])[0];
    },

    tagFilterList(tag=null) {
        let arr = _.flatMap(this.clientTags,ctag => ctag.tags);
        arr = _.uniqBy(_.compact(arr),ttag => ttag.toLowerCase());
        if(tag && !arr.map(ttag => ttag.toLowerCase()).includes(tag.toLowerCase())) {
            arr.unshift(tag);
        }
        return arr;
    },

    allTags() {
        return this.tagFilterList();
    },

    clientTagsForAll(clients) {
        return _.compact(_.intersectionBy(...clients.map(client => client.fullTagList(),ttag => ttag.toLowerCase())));
    },

    clientTagsForAny(clients) {
        return _.uniqBy(_.compact(_.flatMap(clients,client => client.fullTagList())),ttag => ttag.toLowerCase());
    },

    isPdfTrainer() {
        if(this.isChild()) {
            return this.master.isPdfTrainer();
        }
        return this.trainerType === this.constructor.TRAINER_TYPES.pdf;
    },

    isAppTrainer() {
        if(this.isChild()) {
            return this.master.isAppTrainer();
        }
        return this.trainerType === this.constructor.TRAINER_TYPES.app || _.isBlank(this.trainerType); 
    },

    showClientMetricTabs() {
        return this.isAppTrainer();
    },

    hadTrainerTrial() {
        return (this.getTrainerSubscription() && this.getTrainerSubscription().hadTrial);
    },

    isTrainerTrialing() {
        return (this.trainerSubStatus() === 'trialing');
    },

    isTrainerSubInit() {
        const sub = this.getTrainerSubscription();
        return (!_.isBlank(sub) && !sub.uninitialized());
    },

    trainerSubStatus() {
        return this.getTrainerSubscription() && this.getTrainerSubscription().stripeSubStatus;
    },

    tBulkMultiplier() {
        const disc = this.getTrainerSubscription() && this.getTrainerSubscription().bulkDiscount;
        if(disc) {
            return (100 - disc)/100.0;
        }

        return null;
    },

    hasActiveTrainerSub() {
        return ['active','trialing'].includes(this.trainerSubStatus())
    },

    activeTrainerPlanType() {
        if(this.hasActiveTrainerSub()) {
            return this.trainerPlanType()
        }
        return null;
    },

    trainerSubFreq() {
        if(this.getTrainerSubscription()) {
            return this.getTrainerSubscription().stripeSubFrequency;
        }
        return null;
    },

    trainerSubIsOldPrice() {
        if(this.getTrainerSubscription()) {
            return this.getTrainerSubscription().isOldPrice;
        }
        return false;
    },

    trainerSubExpires() {
        const expiry = this.getTrainerSubscription().planExpires;
        return _.isBlank(expiry) ? null : moment(expiry);
    },

    clientsCanPickOwnRoutine() {
        if(this.isChild()) {
            return this.master.clientsCanPickOwnRoutine()
        }
        return true;
    },

    hasTrainerAccess(type,allowUninit=true) {
        if(allowUninit && this.getTrainerSubscription() && this.getTrainerSubscription().uninitialized()) {
            return true;
        }
        const types = [FULL,type];
        const planType = this.trainerPlanType();
        if(planType && (!type || types.includes(planType))) {
            const expiry = this.trainerSubExpires();
            const offset = 4;
            return expiry && expiry.add(offset,'days').isSameOrAfter(moment(),'day');
        }
        return false;
    },

    canManageOwnAccount() {
        if(this.isClient() || this.isChild()) {
            return false;
        }
        return true;
    },

    getTrainerSubscription() {
        if(this.gotTrainerSub) {
            return this.cachedTrainerSub;
        }
        this.cachedTrainerSub = this.getTrainerSubCore();
        this.gotTrainerSub = true;
        return this.cachedTrainerSub;
    },

    getTrainerSubCore() {
        if(this.isMaster()) {
            return this.trainerSubscription;
        } else if(this.isChild()) {
            return this.master.getTrainerSubscription();
        } else if(this.isClient()) {
            return this.trainer.getTrainerSubscription();
        }
        return null;
    },

    subsForAccountPage(showPersonal) {
        let arr = [];
        if(this.isTrainerAdmin()) {
            arr.push(this.getMasterAccount().trainerSubscription);
        }
        if(showPersonal) {
            arr.push(this.subscription);
        }
        return _.filter(_.compact(arr), sub => sub.exists());
    },

    trainerSubIsPastDue() {
        return (this.trainerSubStatus() === 'past_due' || this.trainerSubStatus() === 'incomplete');
    },

    showPastDueScreen() {
        if(this.trainerSubStatus() === 'incomplete') {
            return true;
        } else if(this.trainerSubStatus() === 'past_due') {
            const expiry = this.trainerSubExpires();
            const offset = 4;
            return expiry && expiry.add(offset,'days').isSameOrBefore(moment(),'day');
        }

        return false;
    },

    ownsClient(client) {
        return client.trainerId === this.id;
    },

    recipeLikedForClientIds(recipeId) {
        return _.compact(_.filter(this.recipePreferences,pref => (pref.isLike() && pref.recipeId === Number(recipeId))).map(pref => pref.clientId));
    },

    setupFinishedForClient(client) {
        if(this.showNutritionOnly()) {
            return client.trainerDidInitialMpSetup();
        } else if(this.showTrainingOnly()) {
            return client.trainerDidInitialWorkoutSetup();
        } else {
            return client.trainerDidInitialMpSetup() && client.trainerDidInitialWorkoutSetup();
        }
    },

    getTrainerType() {
        return this.getMasterAccount().trainerType || this.constructor.TRAINER_TYPES.app;
    },

    initialValsForAccount() {
        const base = { image: '', defaultImage: this.profileImagePath('mediumThumb') };
        let rest = null;
        if(this.isTrainerAdmin()) {
            rest = _.pick(this,['firstName','lastName','businessName','autoDeactivateClients']);
            rest.trainerType = this.getMasterAccount().trainerType || this.constructor.TRAINER_TYPES.app;
        } else if(this.isContributor()) {
            rest = _.pick(this,['firstName','lastName','businessName','bio']);
        } else {
            rest = _.pick(this,['firstName','lastName']);
        }
        if(this.isTrainer()) {
            rest.unitPreference = this.unitPreference;
        }
        return _.parseObjForForm({ ...rest, ...base });
    },

    initialBrandingVals() {

    },

    yupForNames() {
        const vals = this.initialValsForAccount();
        const shape = _.mapValues(_.keyBy(Object.keys(_.pick(vals,['firstName','lastName','businessName'])),attr => attr),attr => Yup.string().required())
        return Yup.object().shape(shape)
    },

    initialValsForTrainerEdit() {
        const base = { image: '', defaultImage: this.profileImagePath('mediumThumb'), isOwner: this.isMaster() };
        return { ..._.pick(this,['id','firstName','lastName','email','trainerRole']), ...base };
    },

    pdfExportInitialVals(week,clientOrId) {
        const client = _.isNumeric(clientOrId) ? this.clientById(clientOrId) : clientOrId;
        const startDate = client.activeWeekStart(week).format(dateFormat);
        const defKeys = Object.keys(PdfExportSetting.DEFAULTS);
        const obj = { ..._.pick(this.pdfExportSetting || PdfExportSetting.DEFAULTS,defKeys), userId: this.id, clientId: client.id, email: client.email, startDate, saveToDefaults: !this.pdfExportSetting };
        if(window.isCordova) obj.delivery = 'email';

        return _.parseObjForForm(obj);
    },

    routinePdfExportInitialVals(routineId,client=null) {
        const obj = { ..._.pick(this.pdfExportSetting || PdfExportSetting.DEFAULTS,['delivery']), id: routineId, email: (client ? client.email : '') };
        if(window.isCordova) obj.delivery = 'email';

        return _.parseObjForForm(obj);
    },

    clientRoutineExportVals(clientOrId) {
        const client = _.isNumeric(clientOrId) ? this.clientById(clientOrId) : clientOrId;
        return this.routinePdfExportInitialVals(client.assignedRoutineId,client);
    },

    needsFirstMpButton() {
        return !this.sawTooltip(GENERATE_FIRST_MP_TIP) && !this.showTrainingOnly();
    },

    firstClientSignInPath() {
        const client = this.activeNonSampleClients()[0] || this.clients[0];
        return switchCliAccountPathFor(client.id);
    },

    usedPersonalMealPlanner() {
        return (this.hasPersonalAccount && this.mealPlanInitialized());
    },

    showPersonalAccountOption() {
        return (this.hasPersonalAccount || this.mealPlanInitialized() || this.workoutPlanInitialized())
    },

    showSignInAsClientPop() {
        return this.isAppTrainer() && this.sawTooltip(GENERATE_FIRST_MP_TIP) && !this.showTrainingOnly() && this.activeClients().length > 0 && !this.usedPersonalMealPlanner();
    },

    showBrandingSetupCard() {
        return this.isMaster() && this.sawTooltip(GENERATE_FIRST_MP_TIP) && !this.sawTooltip(SETUP_BRANDING_TIP);
    },

    showFormsSetupCard() {
        return this.isMaster() && this.isAppTrainer() && this.sawTooltip(GENERATE_FIRST_MP_TIP) && !this.sawTooltip(SETUP_FORMS_TIP);
    },

    appIconImagePath(size) {
        return this.getImagePathForSize('appIcon',size);
    },

    logoColorIconVals() {
        if(!this.isMaster()) {
            return this.getMasterAccount().logoColorIconVals();
        }

        return {
            logo: '',
            appIcon: '',
            brandColor: this.brandColor || '',
            defaultLogo: this.logoSource('mediumThumb'),
            defaultAppIcon: this.appIconImagePath('mediumThumb'),
        }
    },

    clientSelfSetupAttrs() {
        return ['setupOwnProfile','setupOwnMpPrefs','pickOwnRoutine','canPickInitialRecipes','autoDeliverMpPdf'];
    },

    defaultNewClientSetupVals() {
        let vals;
        if(this.setupOwnProfile) {
            vals = _.pick(this,this.clientSelfSetupAttrs());
            vals.canEditOwnMealPlan = !!this.canEditOwnMealPlan;
        } else {
            vals = _.arrToObj(this.clientSelfSetupAttrs(),attr => false);
            vals.canEditOwnMealPlan = true;
        }

        return vals;
    },

    initialNewClientVals(ogTrainerId) {
        const extras = this.defaultNewClientSetupVals();
        const vals = { 
            firstName: '', 
            lastName: '', 
            email: '',
            ...extras,
            trainerId: ogTrainerId, 
            ogTrainerId  
        };

        if(this.isAppTrainer()) {
            vals.assignDefaultForms = true;
        }

        return _.parseObjForForm(vals);
    },

    hasActiveMessaging() {
        if(this.isTrainer()) {
            return this.messagingOn;
        } else if(this.isClient()) {
            return this.trainer.hasActiveMessaging();
        } else {
            return false;
        }
    },


    showMessagingLiveNotif() {
        if(this.sawTooltip(MESSAGING_LIVE) || process.env.REACT_APP_TEST === 'true') {
            return false;
        }

        return this.createdAtMom().isBefore(moment('2022-09-17'),'day');
    },

    canEditTrainerRecord(record) {
        if(_.isBlank(record.ownerId)) {
            return false;
        }

        if(record.ownerId === this.id) {
            return true;
        }

        if(this.isTrainerAdmin() && record.isTeam() && record.ownerId === this.getMasterAccount().id) {
            return true;
        }

        return false;
    },

    recordRelationshipTag(record) {
        if(this.hasOtherTrainers()) {
            if(record.ownerId && record.teamLevel) {
                return ['team'];
            } else if(record.ownerId && record.ownerId === this.id) {
                return ['mine'];
            }
        }

        return [];
    },

    ...trainerFormsConcern,
    ...trainerModelFns
}