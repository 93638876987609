import { editTrainerFormPath, viewAssignedFormPathFor, viewClientAssignedFormPathFor } from 'config/paths';
import { RecordBase, registerInflection } from 'lib/record-base';
import * as _ from 'lib/utilities';
import { SchedulableSetting } from './schedulable-setting';
import moment from 'moment';

export class Form extends RecordBase {
    static NAME = 'Form'
    static ASSOCS = {
        topLevelSchedulableSetting: { type: 'hasOne', tableName: 'schedulableSettings', where: ss => !_.isBlank(ss.clientFormGroupId), inverse: 'source' },
        formFields: { type: 'hasMany', sortAttr: 'formOrder', where: ff => !ff.inactive },
        owner: { type: 'belongsTo', tableName: 'users', inverse: 'ownedForms' },
        user: { type: 'belongsTo', tableName: 'users', inverse: 'assignedForms' },
        schedulableSettings: { type: 'hasMany', inverse: 'source' },
        chatMessages: { type: 'hasMany', inverse: 'responseTo' }
    }

    static FORM_SORT_ORDER_TRAINER = ['submitted','not_submitted','upcoming','completed'];
    static FORM_SORT_ORDER_CLIENT = ['not_submitted','submitted','upcoming','completed'];
    static VIEW_NAMES_MAP = { intake: 'Form', check_in: 'Check-in', assessment: 'Assessment' };

    static DEFAULT_NEW_VALS = { shortName: '', formType: 'intake', description: '', instructions: '', teamLevel: false };

    static formTypes = t => ([{ text: t('Intake'), value: 'intake' }, {text: t('Check-in'), value: 'check_in'}, {text: t('Assessment'), value: 'assessment'}])

    static formStatuses = t => ([{ text: t('Upcoming'), value: 'upcoming' }, { text: t('Not submitted'), value: 'not_submitted' }, { text: t('Needs review'), value: 'submitted' }, { text: t('Completed'), value: 'completed' }])

    static newDefault() {
        return new Form({ ...this.DEFAULT_NEW_VALS, formFields: [] });
    }

    exerciseSearchValues(fieldType) {
        const addlParams = fieldType === 'strength_test' ? { contextId: 'add_strength_test' } : {};
        return { searchTerm: '', equipmentTypes: [''], simpleTypes: [''], ...addlParams }
    }

    resolvedName(t) {
        return _.fallbackText(this.shortName,t('Untitled'));
    }

    resolvedDescription(t) {
        return _.fallbackText(this.description,t("This record doesn't have a description",{ record_name: t('form') }))
    }

    instructionParagraphs() {
        return (this.instructions || '').split(/\n+/)
    }

    editPath() {
        return editTrainerFormPath(this.id);
    }

    viewPath() {
        return editTrainerFormPath(this.id);
    }

    assignToClientsPath() {
        return '';
    }

    isDeleted() {
        return this.status === 'deleted';
    }

    isPublished() {
        return this.status === 'published';
    }

    isVisible(user,filters={}) {
        if(this.isDeleted()) {
            return false;
        }

        const { ownershipType, formType, isDefault } = filters;

        if(!_.isBlank(formType) && this.formType !== formType) {
            return false;
        }

        if(!_.isBlank(ownershipType) && user.recordRelationshipTag(this)[0] !== ownershipType) {
            return false;
        }

        if(!_.isBlank(isDefault) && this.isDefault() !== isDefault) {
            return false;
        }

        if(this.ownerId === user.id) {
            return true;
        }

        return this.isPublished();
    }

    isTeam() {
        return this.teamLevel;
    }

    isDefault() {
        return !_.isBlank(this.topLevelSchedulableSetting);
    }

    basicInfoValues(editor) {
        const attrs = ['id','shortName','formType','description','instructions'];
        if(editor && editor.showTeamFeatures()) {
            attrs.push('teamLevel');
        }
        return _.parseObjForForm(_.pick(this,attrs))
    }

    fillableValues(viewer) {
        const vals = {
            id: this.id,
            status: this.status === 'assigned' ? 'started' : this.status,
            formFields: this.formFields.map(ff => ff.fillableValues(viewer))
        }

        if(this.isAssignedForm() && !this.viewerIsAssignee(viewer)) {
            vals.clientId = this.userId;
        }

        return vals;
    }

    reorderFields(startIndex,endIndex) {
        const [removed] = this.formFields.splice(startIndex, 1);
        this.formFields.splice(endIndex, 0, removed);
    }

    fieldOrderParams() {
        return { 
            id: this.id, 
            formFields: this.formFields.map((field,index) => ({ id: field.id, formOrderPosition: index }))
        }
    }

    getFieldById(id) {
        const numId = Number(id);
        return _.find(this.formFields,ff => ff.id === numId);
    }

    getFieldForModal(modalProps) {
        const { match: { params: { id } } } = modalProps;
        return this.getFieldById(id);
    }

    nonEmptyFormFields() {
        return this.formFields;
    }

    filterSubmitValues(values) {
        const newFormFields = [];
        values.formFields.forEach(ff => {
            const { defaultImage, uploadImage, readOnly, ...rest } = ff;
            if(!readOnly) {
                if(_.isBlank(uploadImage)) {
                    newFormFields.push(rest);
                } else {
                    console.log(ff);
                    console.log(rest);
                    newFormFields.push({ ...rest, image: _.dataURItoBlob(uploadImage) });
                }
            }
        })
        return { ...values, formFields: newFormFields };
    }

    getTagList(viewer) {
        let tags = [...viewer.recordRelationshipTag(this) ];

        if(this.ownerId && this.isDefault()) {
            tags.push('default');
        }

        return tags;
    }

    sendMessageLabel(t) {
        let str;
        if(this.isAssessment()) {
            str = t('Assessment').toLowerCase();
        } else if(this.isCheckIn()) {
            str = t('Check-in').toLowerCase();
        } else {
            str = t("form");
        }

        return t("this object", { object: str });
    }

    sendMessageVals() {
        return { responseToId: this.id, responseToType: 'Form' };
    }

    viewerIsAssignee(viewer) {
        return viewer.id === this.user.id;
    }

    submitBtnText(t) {
        if(this.isAssignedForm()) {
            if(this.isNotSubmitted()) {
                return t('Submit');
            } else {
                return t('Mark Reviewed');
            }
        }

        return null;
    }

    afterSubmitStatus(viewer) {
        if(this.isAssignedForm()) {
            if(this.isNotSubmitted()) {
                if(this.viewerIsAssignee(viewer)) {
                    return 'submitted';
                } else if(this.isTrainerAdministered()) {
                    return 'completed';
                }
            } else if(this.needsReview() && !this.viewerIsAssignee(viewer)) {
                return 'completed';
            }
        } 

        return null;
    }

    isAssignedForm() {
        return !_.isBlank(this.user);
    }

    isAssessment() {
        return this.formType === 'assessment';
    }

    isTrainerAdministered() {
        return (this.isAssessment() && !this.selfAdmin);
    }

    isCheckIn() {
        return this.formType === 'check_in';
    }

    isIntake() {
        return this.formType === 'intake';
    }

    notStarted() {
        return this.status === 'assigned';
    }

    isStarted() {
        return this.status === 'started';
    }

    needsReview() {
        return this.status === 'submitted';
    }

    isCompleted() {
        return this.status === 'completed';
    }

    editDefaultScheduleVals() {
        if(this.topLevelSchedulableSetting) {
            return { formId: this.id, ...this.topLevelSchedulableSetting.editDefaultsValues() };
        }

        if(this.isCheckIn()) {
            return { formId: this.id, freqType: SchedulableSetting.WEEKLY, frequency: 1, weekdays: [0], selfAdmin: true }
        }

        return { formId: this.id, freqType: SchedulableSetting.ONCE, frequency: '', weekdays: [''], selfAdmin: true }
    }

    assignedDateMom() {
        return moment(this.assignedDate);
    }

    effectiveDate() {
        if(_.isBlank(this.filledOutAt)) {
            return this.assignedDateMom();
        }

        return moment(this.filledOutAt);
    }

    isEffectivelyOnDashDate(date) {
        if(this.filterStatus(date) === 'not_submitted' && date.isSame(moment(),'day')) {
            return true;
        }

        return this.effectiveDate().isSame(date,'day');
    }

    assignedDateStr() {
        return this.assignedDateMom().format('ddd MMM Do, YYYY');
    }

    assignedDateHourInv() {
        return -Math.floor(this.assignedDateMom().valueOf()/(3600*1000));
    }

    effectiveDateSecInv() {
        return -Math.floor(this.effectiveDate().valueOf()/1000);
    }

    statusSort(viewerType) {
        return viewerType === 'trainer' ? Form.FORM_SORT_ORDER_TRAINER.indexOf(this.filterStatus()) : Form.FORM_SORT_ORDER_CLIENT.indexOf(this.filterStatus());
    }

    filterStatus() {
        if(this.filterStatusCached) {
            return this.filterStatusCached
        }
        if(['started','assigned'].includes(this.status)) {
            if(this.assignedDateMom().isAfter(moment(),'days')) {
                return (this.filterStatusCached = 'upcoming');
            }
            return (this.filterStatusCached = 'not_submitted');
        }

        return (this.filterStatusCached = this.status);
    }

    isNotSubmitted() {
        return (['not_submitted','upcoming'].includes(this.filterStatus()))
    }

    isAlreadySubmitted() {
        return !this.isNotSubmitted();
    }

    cardIcon(showCliCompCheck) {
        if(showCliCompCheck && this.isAlreadySubmitted()) {
            return 'check';
        }

        if(this.isAssessment()) {
            return 'universal-access';
        } else if(this.isCheckIn()) {
            return 'badge-check';
        }

        return 'file-alt';
    }

    viewName(t) {
        return t(`View ${Form.VIEW_NAMES_MAP[this.formType]}`);
    }

    editName(t) {
        return t(`Edit ${Form.VIEW_NAMES_MAP[this.formType]}`);
    }

    fieldById(id) {
        id = Number(id);
        return _.find(this.formFields,f => (f.id === id));
    }

    imgSrcFor(formFieldId) {
        const field = this.fieldById(formFieldId);
        return field && field.defaultImage();
    }

    strengthTestsForExercise(exerciseId) {
        return _.filter(this.formFields,ff => (ff.fieldType === 'strength_test' && ff.exerciseId === Number(exerciseId)));
    }

    isSignedAndSubmitted() {
        return (this.status === 'submitted' || this.status === 'completed') && _.some(this.formFields,ff => ff.signatureCompleted());
    }

    isUneditable() {
        return this.isSignedAndSubmitted() || this.status === 'completed';
    }

    showMessaging(viewer) {
        return (this.isAssignedForm() && this.user.id !== viewer.id && !this.isTrainerAdministered());
    }

    chatMsgReplyImg() {
        return null;
    }

    chatMsgReplyIcon() {
        return this.cardIcon(false);
    }

    chatMsgReplyTitle(t) {
        return this.resolvedName(t);
    }

    chatMsgReplySubtitle(t) {
        return this.assignedDateStr();
    }

    chatMsgClickLink(viewer) {
        if(this.viewerIsAssignee(viewer)) {
            return viewAssignedFormPathFor(this.id);
        }

        return viewClientAssignedFormPathFor(this.user.id,this.id);
    }
}

registerInflection('form','forms',Form);