import React from 'react';
import MDatePicker from 'assets/js/datepicker';
import { Route, withRouter } from 'react-router-dom';
import { Transition, TransitionGroup } from 'react-transition-group';
import moment from 'moment';
import { dateFormat } from 'config/settings';
import { modalPathFor } from 'config/paths';

const defaultOptions = () => ({
    autoClose: true,
    defaultDate: moment().toDate(),
    setDefaultDate: true
})

class RouteDatePicker extends React.Component {

    constructor(props) {
        super(props);
        this.pickerRef = React.createRef();
        this.handleManualClose = this.handleManualClose.bind(this);
        this.close = this.close.bind(this);
        this.popped = false;
    }

    render() {

        return (
            <input type="text" className="datepicker hidden" ref={this.pickerRef} />
        )
    }

    componentDidMount() {
        this.picker = MDatePicker.init(this.pickerRef.current, { ...defaultOptions(), ...this.props.options, onClose: this.handleManualClose, onSetInput: this.onSetInput });
        this.picker.open();
    }

    componentDidUpdate() {
        if (!this.popped && this.props.status === 'exiting') {
            this.popped = true;
            this.picker.close();
        }
    }

    componentWillUnmount() {
        if(this.picker) {
            this.picker.close();
            this.picker.destroy();
        }
    }

    handleManualClose() {
        if(!this.popped) {
            this.popped = true;
            this.props.history.goBack();
        }
    }

    onSetInput = (date) => {
        const { name, setFieldValue } = this.props;

        setFieldValue(name,moment(date).format(dateFormat));
    }

    close() {
        this.picker.close();
    }
}

let DatePicker = ({ triggerRender, suffix, ...props }) => {
    
    const { location, location: { pathname }, match, history, ...otherProps } = props;
    const { options: { inDuration } = {} } = props;
    const path = modalPathFor(suffix,pathname);

    return (
        <React.Fragment>
            {triggerRender && triggerRender({ onClick: () => history.push(path)})}
            <TransitionGroup>
                <Transition key={pathname} timeout={inDuration || 300}>
                    { state => (
                        <Route location={location} path={path} exact render={(routeProps) => {
                            return(
                                <RouteDatePicker {...routeProps} {...otherProps} status={state} />
                            )
                        }} />
                    )}
                </Transition>
            </TransitionGroup>
        </React.Fragment>
    )

}

DatePicker = withRouter(DatePicker);

export default DatePicker;