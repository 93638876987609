import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { workoutSetupMatch, pickExercisePath, emailPaywallMatches, allMainMatches, clientDetailsMatch, bodyMeasurementsPath, progressPicsPath, modalPathFor, viewAssignedFormMatch, viewClientAssignedFormMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import PaywallWrapper from 'partials/PaywallWrapper';
import ClientSwitcher from 'partials/ClientSwitcher';
import { Loader } from 'components/LoadingHOC';
import { userWithProgressPhotosSel } from 'redux/selectors';
import { connect } from 'react-redux';
import { destroyProgressPhoto, loadProgressPhotos } from 'redux/actions';
import ActivityContainer from 'components/ActivityContainer';
import { SlightEmphasisIconNote } from 'components/Typography';
import PinchZoomCanvas from 'components/PinchZoomCanvas';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import { TagButtons } from 'components/Form';
import { FormField } from 'lib/classes';
import { BottomButtons, ConfirmActionButton } from 'components/Button';
import LinkButton from 'components/LinkButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NewProgressPhotoModal, newProgressPhotoModalSuffix } from 'partials/ProgressPhotoModal';
import { DropdownItem, MultiLevelDropdown } from 'components/Dropdown';


const transitionMap = {
    rules: [
        [[ ...allMainMatches, clientDetailsMatch, viewAssignedFormMatch, viewClientAssignedFormMatch ],transitions.slideOut],
        [[workoutSetupMatch,...emailPaywallMatches,pickExercisePath,bodyMeasurementsPath], transitions.slideOver]
    ]
};

const PhotoMenu = ({ photo, deletePhoto }) => {

    const { t } = useTranslation();

    return (
        <MultiLevelDropdown
            options={{ coverTrigger: false, alignment: 'right', closeOnClick: true, closeOnChildClick: true } }
            triggerRender={({ ref, target }) => {
                return (
                    <div className="prog-photo-menu" ref={ref} data-target={target}>
                        <FontAwesomeIcon icon="ellipsis-h" />
                    </div>
                )
            }}
        >
            <ConfirmActionButton onClick={() => deletePhoto(photo.id)} render={({ ready, onClick }) => (
                <DropdownItem label={t("Delete")} icon={"times"} onClick={onClick} className={!ready ? "dont-close-dropdown" : ''} compClassName={(ready ? "red-text" : '')} />
            )} />
        </MultiLevelDropdown>
    )
}

const SelFrame = ({ photo, deletePhoto }) => {

    return (
        <div style={{ width: '100%', height: '100%', overflow: 'hidden', position: 'absolute', top: 0, zIndex: 999, pointerEvents: 'none' }} className={'sel-prog-photo'}>
            <PhotoMenu photo={photo} deletePhoto={deletePhoto} />
        </div>
    )
}

const ProgPhotoOption = ({ photo, sel, setSelPhoto }) => {

    return (
        <div className={classnames("prog-photo-itm",{ 'sel-prog-photo': sel })} style={{ backgroundImage: `url('${photo.image.thumb.url}')` }} onClick={() => setSelPhoto(photo.id)}>
            <div className="dt-label">{photo.dtLabel()}</div>
        </div>
    )
}

const PinchZoomPhoto = ({ photo, side, selSide, setSelSide, deletePhoto }) => {
    const isSel = (selSide === side);

    return (
        <div className="photo-view">
            {isSel && (<SelFrame photo={photo} deletePhoto={deletePhoto} />)}
            <PinchZoomCanvas imageUrl={photo.image.url} clickHandler={() => setSelSide(isSel ? null : side)} />
        </div>
    )
}

const ProgressPhotos = ({ leftPhoto, rightPhoto, selSide, setSelSide, deletePhoto }) => {

    return (
      <div className="photos-container">
        <PinchZoomPhoto photo={leftPhoto} side={'left'} selSide={selSide} setSelSide={setSelSide} deletePhoto={deletePhoto} />
        <PinchZoomPhoto photo={rightPhoto} side={'right'} selSide={selSide} setSelSide={setSelSide} deletePhoto={deletePhoto} />
      </div>
    );
};

const ProgressPhotoViewer = ({ user, angle, deletePhoto }) => {
    const [selSide,setSelSide] = useState('left');
    const [selLeftPhoto,setSelLeftPhoto] = useState(null);
    const [selRightPhoto,setSelRightPhoto] = useState(null);
    const items = user.orderedProgressPhotos(angle);
    const leftPhoto = _.find(items,i => (i.id === selLeftPhoto)) || items[0];
    const rightPhoto = _.find(items,i => (i.id === selRightPhoto)) || items[items.length-1];
    const setSelPhoto = id => {
        if(selSide === 'right') {
            setSelRightPhoto(id);
        } else {
            setSelLeftPhoto(id);
        }
    }
    const selPhoto = selSide === 'right' ? rightPhoto : leftPhoto;


    return (
        <div className="pb100">
            <ProgressPhotos 
                leftPhoto={leftPhoto} 
                rightPhoto={rightPhoto} 
                selSide={selSide} 
                setSelSide={setSelSide} 
                deletePhoto={deletePhoto}
            />
            {selSide && (<div className="text-center mt20" style={{ overflow: 'hidden'}} >
                <div className="photo-itms-wrapper">
                    {items.map(i => (<ProgPhotoOption photo={i} sel={i.id === selPhoto.id} setSelPhoto={setSelPhoto} />))}
                </div>
            </div>)}
        </div>
    )

}

const ProgressPhotosContainer = ({ user, pathname, deletePhoto }) => {
    const { t } = useTranslation();
    const [angle,setAngle] = useState('');

    return (
        <ActivityContainer>
            <div className="text-center mb10">
              <TagButtons
                single
                col={FormField.photoAngles(t)}
                setFieldTouched={() => {}}
                setFieldValue={(name,value) => setAngle(value)}
                name='angle'
                values={{ angle }}
                buttonProps={ { className: 'ma2' } }
              />
            </div>
            {user.hasAnyProgressPhotos(angle) && (<ProgressPhotoViewer user={user} angle={angle} deletePhoto={deletePhoto} />)}
            {!user.hasAnyProgressPhotos(angle) && (
                <div className="pa15">
                    <SlightEmphasisIconNote text={t('no prog photos')} />
                </div>
            )}
            <BottomButtons>
                <LinkButton id="add-photo-btn" rounded color="primary" to={modalPathFor(newProgressPhotoModalSuffix,pathname)}>
                    <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t("New")}
                </LinkButton>
            </BottomButtons>
            <NewProgressPhotoModal />
        </ActivityContainer>
    )
}

export const ClientProgressPicsShortcut = (props) => {
    const { t } = useTranslation();

    return (
        <ClientSwitcher {...props} transitionMap={transitionMap} title={t('Progress Photos')} afterPath={progressPicsPath} />
    )
}

const ProgressPhotosPage = (props) => {
    const { t } = useTranslation();
    const { user, scrollRef, setupTransitions, loadPhotos, deletePhoto, location: { pathname } } = props;

    return (
        <PaywallWrapper blockTypes={['hard','soft']} context="progress">
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t('Progress Photos')}
            >
                <Loader 
                    successComponent={ProgressPhotosContainer}
                    user={user}
                    type="page"
                    load={loadPhotos}
                    deletePhoto={deletePhoto}
                    preloaded={() => (user.progressPhotos && user.progressPhotos.length > 0)}
                    alwaysLoad
                    pathname={pathname}
                />
            </SimpleNavPage>
        </PaywallWrapper>
    )
}

const mapDispatchToProps = dispatch => ({
    loadPhotos: () => dispatch(loadProgressPhotos()),
    deletePhoto: (id) => dispatch(destroyProgressPhoto({ id }))
})

const mapStateToProps = state => ({
    user: userWithProgressPhotosSel(state)
})

export default connect(mapStateToProps,mapDispatchToProps)(ProgressPhotosPage)