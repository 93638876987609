import React from 'react';
import * as transitions from 'assets/transitions';
import { editRecipeMatch, editWeeklyMealMatch, emailPaywallMatches, homePathFor, logMealModalPathFor, viewSharedMealsMatch } from 'config/paths';
import { ViewMealParent } from 'partials/ViewMealCore';
import { connect } from 'react-redux';
import { mapUserMealPathAndStateToProps } from './FindRecipeReplacement';
import { getMealSelector } from 'redux/selectors';
import { isNumeric } from 'lib/utilities';
import { useTranslation } from 'react-i18next';
import Button, { BottomButtons } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import PaywallWrapper from 'partials/PaywallWrapper';
import { resolvedHomePath } from 'redux/helpers';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import * as _ from 'lib/utilities';

const transitionMap = {
    rules: [
        [[...mealPlanSlideOutPaths,editWeeklyMealMatch,viewSharedMealsMatch],transitions.slideOut],
        [[editRecipeMatch,...emailPaywallMatches],transitions.slideOver]
    ]
};

const FinishAndLogButton = ({ date, userMealId }) => {
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <BottomButtons>
            <Button
                rounded 
                color="primary" 
                className="no-upcase" 
                id={`finish-and-log-btn`}
                onClick={() => {
                    history.go(-1);
                    setTimeout(() => history.push(logMealModalPathFor(homePathFor(moment(date)),userMealId)),350)
                }}
            >
                <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Finish & Log")}
            </Button>
        </BottomButtons>
    )
}

let ViewMealWrapper = (props) => (<ViewMealParent {...props} />)

const mapStateToWrapperProps = (state,props) => {
    const { redirect, userMeal } = props;
    const mealSelector = redirect ? ((state) => null) : getMealSelector(userMeal.urlStub());

    return {
        meal: mealSelector(state)
    }
 
}

ViewMealWrapper = connect(mapStateToWrapperProps)(ViewMealWrapper);

const UserMealPage = ({ mealInfoMatrix, userMeal, match: { params: { recipeId: focusId, logDate } }, ...props }) => {
    let redirect = resolvedHomePath();
    let weeklyMeal = null;
    if(userMeal) {
        weeklyMeal = mealInfoMatrix.matchingMealForUserMeal(userMeal, false);
        if(weeklyMeal && !weeklyMeal.isOffPlan()) {
            redirect = null;
        }
    }
    let confirmComp = null;
    let miniProfile = null;

    if(logDate && !redirect) {
        if(logDate.match(/^\d+$/)) {
            miniProfile = _.find(weeklyMeal.sharedProfiles(), profile => profile.id === Number(logDate))
        } else {
            confirmComp = (<FinishAndLogButton date={logDate} userMealId={userMeal.id} />)
        }
    }

    return (
        <PaywallWrapper blockTypes={['old','hard']} context="view_recipe">
            <ViewMealWrapper
                weeklyMeal={weeklyMeal} 
                userMeal={userMeal}
                redirect={redirect}
                transitionMap={transitionMap}
                mealCount={weeklyMeal && weeklyMeal.mealCount()}
                focusId={isNumeric(focusId) ? Number(focusId) : null}
                confirmComp={confirmComp}
                miniProfile={miniProfile}
                {...props}
            />
        </PaywallWrapper>
    )
} 

export default connect(mapUserMealPathAndStateToProps(false))(UserMealPage);
