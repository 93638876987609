import React from 'react';
import ChartWrapper from './ChartWrapper';
import * as _ from 'lib/utilities';

const hiddenStyle = { pointRadius: 0, showLine: false, fill: false }

export class AnimatedLineChart extends React.Component {

    constructor(props) {
        super(props);
        const { data } = this.props;
        this.state = { dataIndices: Array.from({ length: data.datasets.length }, (x, i) => 0) };
        this.animTimeoutIds = Array.from({ length: data.datasets.length }, (x, i) => 0);
    }

    componentDidMount() {
        const { data: { datasets: inSets } } = this.props;

        inSets.forEach((set,i) => {
            const { delay } = set;
            this.animTimeoutIds[i] = setTimeout(() => this.incrementDataSet(i),delay);
        })
    }

    render() {
        const { scales, chartOpts, chartProps } = this.props;

        return (
            <ChartWrapper
                chartType="line"
                data={this.getChartData()}
                options={{ scales, ...chartOpts }}
                {...chartProps}
            />
        )
    }

    componentWillUnmount() {
        this.animTimeoutIds.forEach(id => clearTimeout(id));
    }

    getChartData = () => {
        const { dataIndices } = this.state;
        const { data: { datasets: inSets } } = this.props;
        const datasets = [];

        inSets.forEach((set,i) => {
            const { data, delay, duration, finishCallback, incrementCallback, label, ...styling } = set;
            const curIndex = dataIndices[i];
            datasets.push({ data, ...hiddenStyle, label: `${label}-hidden` });
            datasets.push({ data: data.slice(0,curIndex), ...styling, label });
        })

        return { datasets };
    }

    incrementDataSet = index => {
        const { dataIndices } = this.state;
        const { data: { datasets: inSets } } = this.props;
        const dset = inSets[index];
        const curInd = dataIndices[index];
        const newIndices = [ ...dataIndices ];
        newIndices[index] = curInd+1;

        this.setState({ dataIndices: newIndices });
        const { duration, finishCallback, incrementCallback } = dset;
        if(newIndices[index] < dset.data.length) {
            const next = _.easeInNextDelay(newIndices[index],dset.data.length,duration);
            incrementCallback && incrementCallback(newIndices[index]);
            this.animTimeoutIds[index] = setTimeout(() => this.incrementDataSet(index),next);
        } else {
            incrementCallback && incrementCallback(newIndices[index]);
            finishCallback && finishCallback();
        }
    }
}