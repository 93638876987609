import React, {Component, useEffect, useState} from 'react';
import { withTranslation, Trans } from 'react-i18next';
import FlowForm, { FlowPageContent, FlowQuestionContainer, AutoAdvanceButton, FlowSubtext, FullWidthNextButton, FixedSizeQuestion, crawlToHighestValidPage } from 'components/FlowForm';
import { AsyncSpinnerButton, FlowAnswerButton } from 'components/Button';
import { onboardBase } from 'config/paths';
import { Link } from "components/Routing";
import { formikHandlerCreator } from 'lib/utilities';
import { ButtonDatePickField, CheckButtons, DatePickWrapper, SelectButton, SingleOptionSlider, ToggleButton } from 'components/Form';
import { newEmailUser, advanceOnboardingFlow, initMacros, updateMealPlan, setCalorieSchedule, setOffPlanMeals, initMiniProfiles, startMealPlan, loadBannableProgs, loadUserWorkoutTimes, assignNewRoutine, dismissTooltip } from 'redux/actions';
import { allergyTagsSelector, bannableProgSelector, initialRecipeOptionsSelector, onboardingFlowDataSelector, recommendedCalsSelector, trainerRecordSelector, userRecordSelector } from 'redux/selectors'
import { connect } from 'react-redux';
import { validateCustomMacros, validateSelectedMeals, validateWeightAttr } from 'partials/UserFields';
import { User, WorkoutRoutine } from 'lib/classes';
import { brandName, citations } from 'config/settings';
import classnames from 'classnames';
import * as _ from 'lib/utilities';
import SummaryCard from 'partials/OnboardingSummaryCard';
import { flowTransitionMap } from './SignUp';
import Page from 'components/Page';
import BodyFatSelector from 'partials/BodyFatSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import SelectWheel from 'components/SelectWheel';
import blackLogo from 'assets/img/logo_with_text_black.png';
import { ComparativeChart } from 'partials/EstimatedProgressChart';
import { CalculatingScreen, CustomMacrosQuestion, Illustration, MacroRecommendationsPage, PointListItem, ReachGoalByScreen, RoutineSelectQuestion, filterSubmitValues, mixpanelProperties, preloadOnboardingImages, validateTrainingDays } from 'partials/OnboardingComponents';
import { AfterMacrosScreen, CalculatingMacrosScreen, CookingSkillsQ, DietTypeQ, EatAnythingScreen, FoodAllergiesQ, FoodsAndHabitsQ, MealEnergyLevelsQ, ProteinHabitsQ, TastesMasteredScreen, VarietyQ, WeeklyFoodBudgetQ } from 'partials/OnboardingMacrosSection';
import { AfterMealPlanScreen, BeforeMealPlanScreen, ChildrenCountQ, FastingBreakQ, FastingLearnScreen, FastingQ, FastingStartQ, FoodExclusionsQ, LunchTypeQ, MealPlanStartDateQ, MealTypesQ, OffPlanMealsQ, PeopleAddedToPlanSuccess, RecipePickerStep, RoomatesCountQ, SecondGoalByScreen, ShareMealsQ, SharedMealTypesQ, WeekendsEatMoreQ, WeekendsEatMoreSuccess } from 'partials/OnboardingMealPlanSection';
import { AfterTrainingProfileScreen, AnyStrengthTrainQ, BeforeTrainingProfileScreen, BeginnerSuperpowerScreen, CardioInfoScreen, DyrQFour, DyrQOne, DyrQThree, DyrQTwo, EnjoyCardioQ, NoProgOverloadScreen, ProgressiveOverloadScreen, SetStepGoalQ, SleepAmountQ, TrainingExpQ, TrainingTypeQ } from 'partials/OnboardingTrainingSection';
import { AfterWorkoutPlanScreen, BannableProgQ, BeforeWorkoutPlanScreen, CalculatingRoutineScreen, FinalSummaryScreen, FinishingTouchesScreen, FocusAreasQ, GymTypeQ, HomeWorkoutsWorkScreen, HowFeelQ, HowRewardQ, SmartRemindersQ, SocialProofScreen, StartDateQ, ThirdGoalByScreen, TrainingDaysQuestion, WorkoutFocusQuestion, WorkoutTimesQuestion, showFastGoalPace } from 'partials/OnboardingWorkoutPlanSections';
import twiceAsEffective from 'assets/img/twice_as_effective.png';

const logoDelay = 2500;

const validateNonBlank = (attr,values,t) => {
    if(_.isBlank(values[attr])) {
        return { [attr]: t('Required') }
    }
    return {};
}

const validateNonEmptyArr = (attr,values,t) => {
    if(_.isBlank(values[attr]) || _.noBlanks(values[attr]).length === 0) {
        return { [attr]: t('Required') }
    }
    return {};
}

const validateForm = (t,user,bannableProgs,page,values) => {
    switch(page) {
        case 1: {
            return {};
        }
        case 2: {
            return {};
        }
        case 3: {
            return {};
        }
        case 4: {
            return {};
        }
        case 5: {
            return {};
        }
        case 6: {
            return validateNonBlank('curBodyFat',values,t);
        }
        case 7: {
            return validateNonBlank('goalBodyFat',values,t);
        }
        case 10: {
            return validateWeightAttr('simpleWeight')(values);
        }
        case 11: {
            return validateWeightAttr('simpleGoalWeight')(values);
        }
        case 22: {
            return validateNonEmptyArr('healthRisks',values,t);
        }
        case 29: {
            return validateNonBlank('dietType',values,t);
        }
        case 30: {
            return {};
        }
        case 31: {
            return validateNonEmptyArr('badHabits',values,t);
        }
        case 33: {
            return validateNonBlank('budget',values,t);
        }
        case 34: {
            return validateNonBlank('complexityPreference',values,t);
        }
        case 35: {
            return validateNonBlank('weeklyVariety',values,t);
        }
        case 37: {
            return validateNonEmptyArr('mealEnergyLevels',values,t);
        }
        case 38: {
            return validateNonBlank('proteinStyle',values,t);
        }
        case 41: {
            return validateCustomMacros(t,user)(values);
        }
        case 45: {
            return validateNonBlank('lunchStyle',values,t);
        }
        case 48: {
            console.log(values);
            return validateNonBlank('fastEndRemind',values,t);
        }
        case 49: {
            return validateNonBlank('fastStartRemind',values,t);
        }
        case 50: {
            return validateSelectedMeals(t)(values);
        }
        case 51: {
            return validateNonBlank('mealPlanWeekday',values,t);
        }
        case 59: {
            return validateNonEmptyArr('sharedMealTypes',values,t);
        }
        case 65: {
            return validateNonBlank('dailyStepGoal',values,t);
        }
        case 80: {
            return validateNonEmptyArr('focusAreas',values,t);
        }
        case 81: {
            return validateNonBlank('gymType',values,t);
        }
        case afterEquipStart(bannableProgs)+3: {
            return validateTrainingDays(values,t);
        }
        case afterEquipStart(bannableProgs)+6: {
            return validateNonBlank('routineStart',values,t);
        }
        default: {
            return {};
        }
    }
}

const ageArr = Array.from({ length: 85 }, (x, i) => (i + 16));

const weightArrs = {
    0: Array.from({ length: 520 }, (x, i) => (i + 80)),
    1: Array.from({ length: 300 }, (x, i) => (i + 35))
}

const heightArrs = {
    0: Array.from({ length: 61 }, (x, i) => (i + 36)).map(inches => ({ text: _.inchesToFtIn(inches), value: inches })),
    1: Array.from({ length: 150 }, (x, i) => (i + 92)).map(cm => ({ text: `${cm}`, value: cm }))
}

const approachPoints = [
    { icon: 'fire', desc: 'sfacalcont' },
    { icon: 'meat', desc: 'sfamacros' },
    { icon: 'utensils-alt', desc: 'sfamealplan' },
    { icon: 'dumbbell', desc: 'sfastrength' }
]

const approachDonts = [
    { icon: 'times', desc: 'sfarestrictive' },
    { icon: 'times', desc: 'sfafeelbad' },
    { icon: 'times', desc: 'sfanocardio' }
]

const approachDontsGain = [
    { icon: 'times', desc: 'sfatrainnonut' },
    { icon: 'times', desc: 'sfafeelbad' },
    { icon: 'times', desc: 'sfanopain' }
]

const BeforeNutritionScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("nextupmacros")} />
            <FlowSubtext>
                {t('nextupmacrossub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} highlight="nutrition" reachGoalPace="slow" user={user} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

const AfterMetablicScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("aftermetabolicmsg")} />
            <FlowSubtext>
                {t('aftermetabolicsub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} highlight="body" reachGoalPace="slow" user={user} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

const FirstGoalEstimateScreen = ({ user, flowProps, t, submit }) => {
    const { values: { similarUsers1 } } = flowProps;
    const [loaded,setLoaded] = useState(false);

    if(!loaded) {
        return (
            <CalculatingScreen 
                subtitles={[t('Finding similar users'),t('Running analysis'),t('Compiling results')]}
                subtitleSwitchPcts={[35,70]}
                successCallback={() => setLoaded(true)}
                load={() => submit(filterSubmitValues(flowProps.values))}
            />
        )
    }

    return (
        <ReachGoalByScreen 
            flowProps={flowProps}
            t={t}
            pace="slow"
            title={t("Metabolic Analysis")}
            addlPoints={(
                <PointListItem icon="fire" cntr>
                    <Trans i18nKey={'yourbodyburns'} values={{ calories: Math.round(user.dailyTdee)}}><label></label></Trans>
                </PointListItem>
            )}
            subtextRender={({ endDate }) => (
                <React.Fragment>
                    <FlowSubtext className="text-left mb10 ml20 mt15">{t('metanalysisinfo1',{ num: _.numberWithCommas(similarUsers1 || 6478), brand_name: brandName(), date: endDate.format('MMM Do') })}</FlowSubtext>
                    <FlowSubtext className="text-left mb10 ml20">{t('metanalysisinfo3')}</FlowSubtext>
                    <FlowSubtext className="text-left mb10 ml20">{t('metanalysisinfo2', {calories: Math.round(user.dailyTdee)})}</FlowSubtext>
                </React.Fragment>
            )}
        />
    )
}

const PlanPaceQuestion = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("bestplanpace")} className="mb30" />
            {['afap','slowandsteady','someinmiddle'].map((pace,i) => {
                
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="planPace" value={[2,0,1][i]} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`pace-${i}-btn`}>
                            <span>{t(pace)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const healthierHappierKeys = (t,weightGoal) => ([
    { title: t('allcausemortaltitle'), desc: (weightGoal === -1 ? t('allcausemortalsubloss') : t('allcausemortalsubloss')), icon: 'heartbeat', citation: citations.mortalityRisk },
    { title: t('mentalhealthtitle'), desc: 'mentalhealthsub', icon: 'head-side-brain', citation: citations.mentalHealth }
])

const HealthierHappierScreen = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30 mt0 pt0" text={t('brandhealthierhappier', { brand_name: brandName() })} />
            <div>
                <div className="onb-ol">
                    {healthierHappierKeys(t,weightGoal).map(({ title, desc, icon, citation },i) => {

                        return (
                            <div className="onb-ol-li" key={i}>
                                <div className="icon">
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                                <div className="title-cont">
                                    <label>{title}{citation && (<Link to={citation} target="_blank"><sup>{i+1}</sup></Link>)}:</label> {desc === 'mentalhealthsub' ? <Trans i18nKey={desc}><b></b><b></b></Trans> : desc}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Got It")}
            />
        </React.Fragment>
    )
}

const HealthRisksQuestion = ({ flowProps, t }) => {
    const { values: { gender } } = flowProps;
    const hbp = 'High blood pressure';
    const db = 'Diabetes';
    const hc = 'High cholesterol';
    const dpr = 'Depression';
    const ot = 'Other';
    const none = 'None';
    const risks = User.female(gender) ? [hbp,db,hc,'Insomnia','Ostearthritis',dpr,ot,none] : ['Testosterone deficiency','Heart disease or stroke',hbp,db,hc,dpr,ot,none];

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t("healthrisksq")} />
            <div>
                <CheckButtons {...flowProps} name="healthRisks" render={({ toggleHandler, values }) => {
                    return (
                        <React.Fragment>
                            {risks.map((risk,i) => (
                                <SelectButton 
                                    id={`select-${i}-btn`} 
                                    key={i} 
                                    toggleHandler={toggleHandler} 
                                    value={risk} 
                                    overrideClassNames={'btn flow-answer-btn btn-select'}
                                    values={values}>
                                        <span>{t(risk)}</span>
                                </SelectButton>
                            ))}
                        </React.Fragment>
                    )
                }}/>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Next')}
            />
        </React.Fragment>
    )
}

const WeightReboundIllustration = ({ t }) => {
    const [showLogo,setShowLogo] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => setShowLogo(true),logoDelay);

        return () => clearTimeout(timeoutId);
    },[])

    return (
        <Illustration >
            <ComparativeChart type="rebound" />
            <div className="title">{t("Weight Rebound")}</div>
            <div className={classnames("fat-loss sf-label fade-in", { in: showLogo })}>
                {_.isPrimaryDomain() && (<img src={blackLogo} alt="logo" className="overlay" />)}
                {!_.isPrimaryDomain() && brandName()}
            </div>
            <div className="rdiets-label" style={{ bottom: '43%' }}>
                {t("Most weight loss methods")}
            </div>
            <div className="bot-axis-lbl ll">{t('Month X', { num: 1 })}</div>
            <div className="bot-axis-lbl rl">{t('Month X', { num: 12 })}</div>
        </Illustration>
    )
}

const StokesMetabolismScreen = ({ flowProps, t }) => {
    const { values: { gender, unitPreference } } = flowProps;
    const gStr = User.female(gender) ? 'f' : 'm';

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb10 mt0 pt0" text={t('stokesmetabolism', { brand_name: brandName() })} />
            <WeightReboundIllustration flowProps={flowProps} t={t} />
            <FlowSubtext className="mb10 mt15 text-left">
                {t(`retainleanmasssub${gStr}`)}
            </FlowSubtext>
            <FlowSubtext className="mb10 text-left">
                {t('everylblostfightsyou', { units: _.getUnitStr(unitPreference,t) })}<Link to={citations.stokesMetabolism} target="_blank"><sup>1</sup></Link>
            </FlowSubtext>
            <FlowSubtext className="mb10 text-left">
                <Trans i18nKey={'mostplansbad'}><b></b><b></b></Trans>
            </FlowSubtext>
            <FlowSubtext className="mb10 text-left">
                {t('brandisdifferent', { brand_name: brandName() })}
            </FlowSubtext>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t('Got It')}
            />
        </React.Fragment>
    )
}

const TwiceAsEffectiveIllustration = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;

    return (
        <Illustration 
            src={twiceAsEffective} 
            width={480} 
            height={460} 
            imgClass="slight-bottom-border"
            subtitle={<React.Fragment>{t('basedonstudies')}<Link to={weightGoal === 1 ? citations.twiceEffectMuscle : citations.twiceEffectLose} target="_blank"><sup>1</sup></Link> {weightGoal === 1 ? t('typicalmethodsgain') : t('typicalmethodslose')}</React.Fragment>}
        >
            <div className="title">{weightGoal === 1 ? t("Muscle Gained") : t("Fat Lost")}</div>
            <div className="sf-label bar-l">{t('Typical methods')}</div>
            <div className="sf-label bar-r">
                {_.isPrimaryDomain() && (<img src={blackLogo} alt="logo" className="overlay" />)}
                {!_.isPrimaryDomain() && brandName()}
            </div>
            <div className="sf-label bar-r-top">
                2X<span role="img" aria-label="">&#128170;</span>
            </div>
        </Illustration>
    )
}

const StrongrFastrTwiceAsEffective = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;
    const title = weightGoal === 1 ? 'sfdoublegains' : 'sfdoublefatloss';

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb10 mt0 pt0" text={t(title, { brand_name: brandName() })} />
            <FlowSubtext className="mb20">
                {t('sfdoubleresultssub')}
            </FlowSubtext>
            <TwiceAsEffectiveIllustration flowProps={flowProps} t={t} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={weightGoal === 1 ? t('Got It') : t("Next")}
            />
        </React.Fragment>
    )
}

const LastTimeIdealBodyQuestion = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("lastidealbodyq")} />
            {[t("X months", { num: '0-6' }),t("X months", { num: '6-12' }),t('X years',{ num: '1-3' }),t('More than X years', { num: '3' })].map((str,i) => {
                
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="lastTimeIdealBody" value={str} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`ideal-bod-time-${i}-btn`}>
                            <span>{str}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const HeightQuestion = ({ flowProps, t }) => {
    const { values: { unitPreference } } = flowProps;
    return (
        <React.Fragment>
            <FixedSizeQuestion  className="mb30" text={t("How tall are you?")} />
            <SelectWheel 
                {...flowProps}
                name="simpleHeight"
                options={heightArrs[unitPreference]}
                unitLabel={unitPreference === User.METRIC ? t('cm') : null}
            />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

const AgeQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion  className="mb30" text={t("How old are you?")} />
            <SelectWheel 
                {...flowProps}
                name="age"
                options={_.collectorize(ageArr,true)}
            />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

const BeforeBodyAnalysisScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("beforebodymsg")} />
            <FlowSubtext>
                {t('beforebodysub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} highlight="body" user={user} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

const AfterGoalScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("aftergoalsmsg")} />
            <FlowSubtext>
                {t('aftergoalssub')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} highlight="goals" user={user} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

const SpecialOccasionDate = ({ flowProps, t }) => {
    const { setFieldValue, setFieldTouched, values: { specialEvent, specialEventDate } } = flowProps;
    const eventStr = _.isBlank(specialEvent) ? _.lowerFirst(t('Important date')) : _.lowerFirst(t(specialEvent));
    const dateSelected = !_.isBlank(specialEventDate);

    return (
        <DatePickWrapper 
            setFieldValue={setFieldValue}
            setFieldTouched={setFieldTouched}
            render={({ initDatePick }) => {

                return (
                    <React.Fragment>
                        <FixedSizeQuestion className="text-left" text={t("specevtgreat")} />
                        <FixedSizeQuestion className="text-left mb30" text={t("specevtwhen", { event: eventStr })} />
                        <ButtonDatePickField
                            name={`specialEventDate`}
                            label={t('Select date')}
                            initDatePick={initDatePick}
                            defaultDate={moment().add(3,'months')}
                            options={{ minDate: moment().add(1,'days').toDate() }}
                            id={`event-date-select`}
                            className="flow-answer-btn"
                            {...flowProps}
                        />
                        <FullWidthNextButton 
                            flowProps={flowProps}
                            outlined={!dateSelected}
                            label={dateSelected ? t("Next") : t('Skip')}
                        />
                    </React.Fragment>
                )
            }}
        />
    )
}

const SpecialOccasionQuestion = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;
    const goalMap = { 0: 'tone up', 1: 'build muscle', [-1]: 'lose weight' };
    const goalStr = t(goalMap[weightGoal]);
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("goaloccasionq", { goal: goalStr })} />
            {["No","Vacation","Sporting event","Important date","Adventure trip","Birthday","Beach trip","Wedding"].map((event,i) => {
                
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="specialEvent" value={event === 'No' ? '' : event} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`event-${i}-btn`}>
                            <span>{t(event)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const SustainedFatLossIllustration = ({ t }) => {
    const [showLogo,setShowLogo] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => setShowLogo(true),logoDelay);

        return () => clearTimeout(timeoutId);
    },[])

    return (
        <Illustration title={t('Your Body Fat')}>
            <ComparativeChart type="lose" />
            <div className="title">{t("Your Body Fat")}</div>
            <div className={classnames("fat-loss sf-label fade-in", { in: showLogo })}>
                {_.isPrimaryDomain() && (<img src={blackLogo} alt="logo" className="overlay" />)}
                {!_.isPrimaryDomain() && brandName()}
            </div>
            <div className="rdiets-label">
                {t("Restrictive diets")}
            </div>
            <div className="bot-axis-lbl ll">{t('Month X', { num: 1 })}</div>
            <div className="bot-axis-lbl rl">{t('Month X', { num: 6 })}</div>
        </Illustration>
    )
}

const SustainedGainsIllustration = ({ t }) => {
    const [showLogo,setShowLogo] = useState(false);

    useEffect(() => {
        const timeoutId = setTimeout(() => setShowLogo(true),logoDelay);

        return () => clearTimeout(timeoutId);
    },[])

    return (
        <Illustration>
            <ComparativeChart type="gain" />
            <div className="title">{t('Your Muscle Mass')}</div>
            <div className={classnames("muscle-gain sf-label fade-in", { in: showLogo })}>
                {_.isPrimaryDomain() && (<img src={blackLogo} alt="logo" className="overlay" />)}
                {!_.isPrimaryDomain() && brandName()}
            </div>
            <div className="rdiets-label bs">
                {t("sfadumb")}
            </div>
            <div className="bot-axis-lbl ll">{t('Month X', { num: 1 })}</div>
            <div className="bot-axis-lbl rl">{t('Month X', { num: 6 })}</div>
        </Illustration>
    )
}

const StrongrFastrApproach = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb10 mt0 pt0" text={t('sfapproachtitle', { brand_name: brandName() })} />
            {weightGoal !== 1 && (<SustainedFatLossIllustration t={t} />)}
            {weightGoal === 1 && (<SustainedGainsIllustration t={t} />)}
            <FixedSizeQuestion className="mb15 mt25 pt0 text-left" text={t('sfapproachdos', { brand_name: brandName() })} />
            <div>
                <div className="onb-ol">
                    {approachPoints.map(({ desc, icon },i) => {

                        return (
                            <div className="onb-ol-li pt5 pb5 cntr" key={i}>
                                <div className="icon">
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                                <div className="title-cont">
                                    {desc !== 'sfamealplan' && (<label>{t(desc, {brand_name: brandName() })}</label>)}
                                    {desc === 'sfamealplan' && (<label><Trans i18nKey={desc}><FontAwesomeIcon icon="check" className="success-color" /></Trans></label>)}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <FixedSizeQuestion className="mb15 mt25 pt0 text-left" text={t('sfadonts')} />
            <div>
                <div className="onb-ol">
                    {(weightGoal === 1 ? approachDontsGain : approachDonts).map(({ desc, icon },i) => {

                        return (
                            <div className="onb-ol-li pt5 pb5 cntr" key={i}>
                                <div className="icon bad">
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                                <div className="title-cont">
                                    <label>{t(desc, {brand_name: brandName() })}</label>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Got It")}
            />
        </React.Fragment>
    )
}

const WeightField = ({ type, flowProps, t }) => {
    const attr = type === 'goal' ? 'simpleGoalWeight' : 'simpleWeight';
    const { setFieldValue, values: { unitPreference, weightGoal, simpleWeight }, values, setValues } = flowProps;
    const toggleUnits = val => {
        setValues({ ...values, unitPreference: val, simpleWeight: ((val === User.METRIC) ? 85 : 200), simpleGoalWeight: ((val === User.METRIC) ? 70 : 175), simpleHeight: ((val === User.METRIC) ? 168 : 66) });
    }
    const newSetFieldValue = (name,val) => {
        if(name === 'simpleWeight') {
            const wtArr = weightArrs[unitPreference];
            const min = wtArr[0];
            const max = wtArr[wtArr.length-1];
            const newVals = { ...values, simpleWeight: val, simpleGoalWeight: Math.max(Math.min(Math.floor(val*(1 + weightGoal*0.1)),max),min) };
            setValues(newVals);
        } else {
            const weightDiff = val - simpleWeight;
            if(weightGoal !== 0 && weightDiff !== 0) {
                setValues({ ...values, simpleGoalWeight: val, weightGoal: (weightDiff > 0 ? 1 : -1) });
            } else {
                setFieldValue(name,val);
            }
        }
    }
    const wtLabel = unitPreference === User.METRIC ? t('kgs') : t('lbs');

    return (
        <div>
            {type === 'current' && (<ToggleButton 
                options={{[t('lbs')]: 0, [t('kgs')]: 1}} 
                value={unitPreference} 
                setValue={toggleUnits} 
                className="no-capital mb10" 
                id="wt-toggle-btn"
            />)}
            <SelectWheel 
                key={unitPreference}
                {...flowProps}
                setFieldValue={newSetFieldValue}
                name={attr}
                options={_.collectorize(weightArrs[unitPreference],true)}
                unitLabel={wtLabel}
            />
        </div>
    )

}

const GoalWeightQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion  className="mb15" text={t("What's your ideal weight?")} />
            <WeightField type={'goal'} flowProps={flowProps} t={t} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

const CurrentWeightQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("What's your current weight?")} />
            <FlowSubtext className="mb15">
                {t('curwtnotsure')}
            </FlowSubtext>
            <WeightField type={'current'} flowProps={flowProps} t={t} />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

const sciKeys = values => {
    const g = values.gender ? 'f' : 'm';

    if(values.weightGoal === -1) {
        return [
            { icon: 'fire', title: 'Calorie Deficit', desc: 'caldefdesc', citation: citations.calories },
            { icon: 'dumbbell', title: 'Strength Training', desc: `strtrndesc${g}loss`, citation: citations.rt },
            { icon: 'meat', title: 'Protein Intake', desc: `prtintdesc${g}loss`, citation: citations.protein },
            { icon: 'calendar-check', title: 'Sustainable Practices', desc: 'sustpractdesc', citation: citations.consistency }
        ];
    } else {
        const goalKey = values.weightGoal === 0 ? 'tone' : 'gain';

        return [
            { icon: 'fire', title: (goalKey === 'tone' ? 'Calorie Balance' : 'Calorie Surplus'), desc: (goalKey === 'tone' ? 'calbaldesc' : 'calsurpdesc'), citation: citations.calories },
            { icon: 'dumbbell', title: 'Strength Training', desc: `strtrndesc${goalKey}`, citation: citations.rt },
            { icon: 'meat', title: 'Protein Intake', desc: `prtintdescgain`, citation: citations.protein },
            { icon: 'calendar-check', title: 'Sustainable Practices', desc: 'sustpractdesc', citation: citations.consistency }
        ];
    }
}

const ScientificKeys = ({ flowProps, t }) => {
    const { values: { weightGoal }, values } = flowProps;

    let question = 'skbtflose';
    if(weightGoal === 1) {
        question = 'skbtfgain';
    } else if(weightGoal === 0) {
        question = 'skbtftone';
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t(question)} />
            <div>
                <div className="onb-ol">
                    {sciKeys(values).map(({ title, desc, icon, citation },i) => {

                        return (
                            <div key={i} className="onb-ol-li">
                                <div className="icon">
                                    <FontAwesomeIcon icon={icon} />
                                </div>
                                <div className="title-cont">
                                    <label>{t(title)}{citation && (<Link to={citation} target="_blank"><sup>{i+1}</sup></Link>)}:</label> {t(desc, {brand_name: brandName() })}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Got It")}
            />
        </React.Fragment>
    )
}

const ScientificKeysLearnMoreQ = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;

    let question = 'scikeysloseq';
    if(weightGoal === 1) {
        question = 'scikeysgainq';
    } else if(weightGoal === 0) {
        question = 'scikeystoneq';
    }

    return (
        <React.Fragment>
            <FixedSizeQuestion className="mb30" text={t(question)} />
            <AutoAdvanceButton {...flowProps} attr="sciKeysMore" value={true} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="yes-btn">
                    <span>{t('Yes')}</span>
                </FlowAnswerButton>
            )} />

            <AutoAdvanceButton {...flowProps} attr="sciKeysMore" value={false} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="no-btn">
                    <span>{t('No')}</span>
                </FlowAnswerButton>
            )} />
        </React.Fragment>
    )
}

const GoalBodyFatQuestion = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("goalbfq")} className="mt0 pt0" />
            <BodyFatSelector
                attr={'goalBodyFat'}
                flowProps={flowProps}
                t={t}
            />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

const CurrentBodyFatQuestion = ({ flowProps, t }) => {

    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("estcurbf")} className="mt0 pt0" />
            <FlowSubtext>
                {t('estcurbfsub')}
            </FlowSubtext>
            <BodyFatSelector
                attr={'curBodyFat'}
                flowProps={flowProps}
                t={t}
            />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Next")}
            />
        </React.Fragment>
    )
}

const MainMotivationQuestion = ({ flowProps, t }) => {
    const { values: { weightGoal } } = flowProps;
    const goalMap = { 0: 'tone up', 1: 'build muscle', [-1]: 'lose weight' };
    const goalStr = t(goalMap[weightGoal]);
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("mainmotivationq", { goal: goalStr })} />
            {["Improve physical appearance","Engage more with friends and family","Become healthier","Feel better day-to-day","Other"].map((motivation,i) => {
                
                return (
                    <AutoAdvanceButton key={i} {...flowProps} attr="mainMotivation" value={motivation} render={({ onClick }) => (
                        <FlowAnswerButton color="primary" rounded onClick={onClick} id={`motivation-${i}-btn`}>
                            <span>{t(motivation)}</span>
                        </FlowAnswerButton>
                    )} />
                )
            })}
        </React.Fragment>
    )
}

const MainGoalQuestion = ({ flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("What's your primary goal?")} />
            <AutoAdvanceButton {...flowProps} attr="weightGoal" value={-1} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="lose-fat-btn">
                    <span>{t('Lose Fat')}</span>
                </FlowAnswerButton>
            )} />

            <AutoAdvanceButton {...flowProps} attr="weightGoal" value={1} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="build-muscle-btn">
                    <span>{t('Build Muscle')}</span>
                </FlowAnswerButton>
            )} />
            <AutoAdvanceButton {...flowProps} attr="weightGoal" value={0} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="tone-up-btn">
                    <span>{t('Get Toned')}</span>
                </FlowAnswerButton>
            )} />
        </React.Fragment>
    )
}

const GenderQuestion = ({ flowProps, t }) => {
    const [nonBinary,setNonBinary] = useState(false);
    return (
        <React.Fragment>
            <FixedSizeQuestion text={nonBinary? t('biosexq') : t("What is your gender?")} />
            {nonBinary && (
                <FlowSubtext className="mb15">
                    {t('biosexsub1')}
                </FlowSubtext>
            )}
            {nonBinary && (
                <FlowSubtext className="mb20">
                    {t('biosexsub2')}
                </FlowSubtext>
            )}
            <AutoAdvanceButton {...flowProps} attr="gender" value={false} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="male-gender-btn">
                    <span>{nonBinary ? t('Male') : t("I'm a man")}</span>
                </FlowAnswerButton>
            )} />

            <AutoAdvanceButton {...flowProps} attr="gender" value={true} render={({ onClick }) => (
                <FlowAnswerButton color="primary" rounded onClick={onClick} id="female-gender-btn">
                    <span>{nonBinary ? t('Female') : t("I'm a woman")}</span>
                </FlowAnswerButton>
            )} />
            {!nonBinary && (
                <FlowAnswerButton color="primary" rounded onClick={() => setNonBinary(true)} id="enby-gender-btn">
                    <span>{t("I'm non-binary")}</span>
                </FlowAnswerButton>
            )}
        </React.Fragment>
    )
}

const FirstElementScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("onbfirststep")} />
            <FlowSubtext>
                {t('onbfirststepmsg', { brand_name: brandName() })}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} highlight="goals" user={user} forceBlank="goals" />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Continue")}
            />
        </React.Fragment>
    )
}

const InitialScreen = ({ user, flowProps, t }) => {
    return (
        <React.Fragment>
            <FixedSizeQuestion text={t("Hi there!")} />
            <FlowSubtext>
                {t('onbmsg1')}
            </FlowSubtext>
            <SummaryCard flowProps={flowProps} t={t} user={user} forceBlank="goals" />
            <FullWidthNextButton 
                flowProps={flowProps}
                label={t("Let's go!")}
            />
        </React.Fragment>
    )
}

class ActivityLevelQuestion extends React.Component {

    render() {
        const { flowProps, flowProps: { setFieldValue, values: { activityLevel } }, t } = this.props;
        const options = User.activityLevelCol(t,'slider');

        return (
            <React.Fragment>
                <FixedSizeQuestion text={t("How active are you?")} className="mt0 pt0 mb20" />
                <SingleOptionSlider options={options} value={activityLevel} setValue={(val) => setFieldValue('activityLevel',val) } className="ml0" />
                <FullWidthNextButton 
                    flowProps={flowProps}
                    label={t("Next")}
                />
            </React.Fragment>
        )
    }
}

const getNextPage = bannableProgs => (page,values) => {
    switch(page) {
        case 8: {
            if(values.sciKeysMore) {
                return page+1;
            }
            return page+2;
        }
        case 13: {
            if(_.isBlank(values.specialEvent)) {
                return page+2;
            }
            return page+1;
        }
        case 20: {
            if(values.weightGoal === 1) {
                return page+2;
            }
            return page+1;
        }
        case 40: {
            if(_.get(values,'dailyNutritionProfiles.0.overrideCalories')) {
                return page+1;
            }
            return page+2;
        }
        case 45: {
            if(values.weightGoal === 1) {
                return page+5;
            }
            return page+1;
        }
        case 46: {
            if(values.tryIfAnswer === 'Learn more') {
                return page+1;
            } else if(values.tryIfAnswer === 'Yes') {
                return page+2;
            }
            return page+4;
        }
        case 47: {
            return page-1;
        }
        case 52: {
            if(values.flexibleWeekends) {
                return page+1;
            }
            return page+2;
        }
        case 56: {
            if(_.noBlanks(values.familyTypes).length === 0) {
                return page+5;
            } else if(values.familyTypes.includes('Children')) {
                return page+1;
            } else if(values.familyTypes.includes('Roommates')) {
                return page+2;
            }
            return page+3;
        }
        case 57: {
            if(values.familyTypes.includes('Roommates')) {
                return page+1;
            }

            return page+2;
        }
        case 64: {
            if(values.trainingType === 2) {
                return wrapUpLoadPage(bannableProgs);
            } else if(values.trainingType === 1) {
                return page+1;
            } else {
                return page+2;
            }
        }
        case 65: {
            return wrapUpLoadPage(bannableProgs);
        }
        case 72: {
            if(values.anyStrengthTraining) {
                return page+1;
            }

            return page+2;
        }
        case 73: {
            if(values.liftingExperience < 2) {
                return page+1;
            }

            return page+2;
        }
        case 81: {
            if(values.gymType === WorkoutRoutine.GYM) {
                return page + numBannableProgs(bannableProgs) + 2;
            }
            return page+1;
        }
        case wrapUpLoadPage(bannableProgs): {
            if(showFastGoalPace(values)) {
                return page+1;
            }
            return page+2;
        }
        case wrapUpLoadPage(bannableProgs)+5: {
            if(_.excludeProgressPhoto()) {
                return page+2;
            }
            return page+1;
        }
        default: {
            return page+1;
        }
    }
}

const firstPages = [
    InitialScreen,
    FirstElementScreen,
    GenderQuestion,
    MainGoalQuestion,
    MainMotivationQuestion,
    CurrentBodyFatQuestion,
    GoalBodyFatQuestion,
    ScientificKeysLearnMoreQ,
    ScientificKeys,
    CurrentWeightQuestion,
    GoalWeightQuestion,
    StrongrFastrApproach,
    SpecialOccasionQuestion,
    SpecialOccasionDate,
    AfterGoalScreen,
    BeforeBodyAnalysisScreen,
    AgeQuestion,
    HeightQuestion,
    LastTimeIdealBodyQuestion,
    StrongrFastrTwiceAsEffective,
    StokesMetabolismScreen,
    HealthRisksQuestion,
    HealthierHappierScreen,
    ActivityLevelQuestion,
    PlanPaceQuestion,
    FirstGoalEstimateScreen,
    AfterMetablicScreen,
    BeforeNutritionScreen,
    DietTypeQ,
    FoodAllergiesQ,
    FoodsAndHabitsQ,
    EatAnythingScreen,
    WeeklyFoodBudgetQ,
    CookingSkillsQ,
    VarietyQ,
    TastesMasteredScreen,
    MealEnergyLevelsQ,
    ProteinHabitsQ,
    CalculatingMacrosScreen
]
 const secondPages = [
    AfterMacrosScreen,
    BeforeMealPlanScreen,
    FoodExclusionsQ,
    LunchTypeQ,
    FastingQ,
    FastingLearnScreen,
    FastingBreakQ,
    FastingStartQ,
    MealTypesQ,
    MealPlanStartDateQ,
    WeekendsEatMoreQ,
    WeekendsEatMoreSuccess,
    OffPlanMealsQ,
    SecondGoalByScreen,
    ShareMealsQ,
    ChildrenCountQ,
    RoomatesCountQ,
    SharedMealTypesQ,
    PeopleAddedToPlanSuccess
 ]

 const thirdPages = [
    AfterMealPlanScreen,
    BeforeTrainingProfileScreen,
    TrainingTypeQ,
    SetStepGoalQ,
    DyrQOne,
    DyrQTwo,
    DyrQThree,
    DyrQFour,
    ProgressiveOverloadScreen,
    NoProgOverloadScreen,
    AnyStrengthTrainQ,
    TrainingExpQ,
    BeginnerSuperpowerScreen,
    SleepAmountQ,
    EnjoyCardioQ,
    CardioInfoScreen,
    AfterTrainingProfileScreen,
    BeforeWorkoutPlanScreen,
    FocusAreasQ,
    GymTypeQ
 ]

 const fourthPages = [
    HomeWorkoutsWorkScreen,
    WorkoutFocusQuestion,
    TrainingDaysQuestion,
    WorkoutTimesQuestion
 ]

 const fifthPages = [
    StartDateQ,
    CalculatingRoutineScreen,
    ThirdGoalByScreen,
    AfterWorkoutPlanScreen,
    SmartRemindersQ,
    HowRewardQ,
    HowFeelQ
 ]

const firstThreeLen = firstPages.length + secondPages.length + thirdPages.length + 3;
const afterEquipStart = bannableProgs => (firstThreeLen + numBannableProgs(bannableProgs));
const wrapUpLoadPage = bannableProgs => afterEquipStart(bannableProgs) + 7;
const numBannableProgs = bannableProgs => (bannableProgs ? bannableProgs.length : 4);
class OnboardingFlowPage extends Component {

    constructor(props) {
        super(props);
        this.state = { loadState: 'DEFAULT', errorMsg: null };
        this.setSubmitState = this.setSubmitState.bind(this);
        props.setupTransitions(flowTransitionMap);
        this.props.loadBannableProgs().catch(() => console.log("Failed to load bannable progressions."));
        preloadOnboardingImages();
    }

    setSubmitState(status,formikBag,data) {
        this.setState({ loadState: status, errorMsg: null });
    }

    render() {
        let { 
            t, 
            submit, 
            advanceAction, 
            initialValues, 
            exitPath, 
            user, 
            scrollRef, 
            allergyTags, 
            initMacros, 
            submitMealPlan, 
            setCalorieSchedule, 
            setOffPlanMeals, 
            initMiniProfiles, 
            startMealPlan, 
            bannableProgs, 
            assignRoutine,
            loadBannableProgs,
            dismissTooltip
        } = this.props;

        return (
            <Page white ref={scrollRef} className="fixed-next-flow">
                <FlowForm 
                    initialValues={initialValues}
                    validate={validateForm.bind(this,t,user,bannableProgs)}
                    validateOnMount={true}
                    advanceCallback={data => {
                        _.mixpanelPeopleSet(_.pick(data,mixpanelProperties));
                        return advanceAction(data);
                    }}
                    onSubmit={formikHandlerCreator(this,this.setSubmitState,submit)}
                    basePath={onboardBase} 
                    nextPage={getNextPage(bannableProgs)}
                    highestValidPageFn={crawlToHighestValidPage(getNextPage(bannableProgs))}
                    exitPath={exitPath}
                    startIndex={1}
                >
                    {firstPages.map((Comp,pg) => {
                        return (
                            <FlowPageContent key={pg} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <Comp flowProps={flowProps} t={t} user={user} submit={submit} allergyTags={allergyTags} />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                    <FlowPageContent render={(flowProps) => {
                            return (

                                    <MacroRecommendationsPage 
                                        flowProps={flowProps} 
                                        t={t} 
                                        recMacroParams={user.onbRecMacroParams()} 
                                        recommendedCalories={user.onbRecCalsReadable()} 
                                        initMacros={values => initMacros(filterSubmitValues(values,true))}
                                        user={user}
                                    />

                            )
                    }} />
                    <FlowPageContent render={(flowProps) => {
                            return (
                                <FlowQuestionContainer noLimitH>
                                    <CustomMacrosQuestion 
                                        flowProps={flowProps} 
                                        t={t} 
                                        recommendedCalories={user.recommendedCalsReadable()} 
                                        user={user} 
                                        submitBtn={(<AsyncSpinnerButton
                                            buttonProps={{
                                                className: 'btn-full-width btn-bottom-center ff-next-btn',
                                                color: 'primary',
                                                disabled: !flowProps.isValid,
                                                label: t('Next'),
                                                id: `submit-macros-btn`
                                            }}
                                            successCallback={() => flowProps.advance(flowProps.values)}
                                            icon={'arrow-right'}
                                            action={() => {
                                                const { dailyNutritionProfiles: dnpsIn } = flowProps.values;
                                                const dailyNutritionProfiles = [{ ...dnpsIn[0], id: user.mainNutritionProfile().id }];
                                                return submitMealPlan({ ...filterSubmitValues(flowProps.values,true), dailyNutritionProfiles });
                                            }}
                                        />)}
                                    />
                                </FlowQuestionContainer>
                            )
                    }} />
                    {secondPages.map((Comp,pg) => {
                        return (
                            <FlowPageContent key={pg} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <Comp 
                                            flowProps={flowProps} 
                                            t={t} 
                                            user={user} 
                                            submit={submit} 
                                            allergyTags={allergyTags} 
                                            setCalorieSchedule={setCalorieSchedule}
                                            setOffPlanMeals={setOffPlanMeals}
                                            initMiniProfiles={initMiniProfiles}
                                        />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                    <FlowPageContent key='pickRecipes' block render={(flowProps) => {
                            return (
                                <RecipePickerStep
                                    flowProps={flowProps} 
                                    startMealPlan={startMealPlan}
                                    user={user}
                                />
                            )
                    }} />
                    {thirdPages.map((Comp,pg) => {
                        return (
                            <FlowPageContent key={pg} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <Comp 
                                            flowProps={flowProps} 
                                            t={t} 
                                            user={user} 
                                            submit={submit} 
                                            loadBannableProgs={loadBannableProgs}
                                        />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                    {(bannableProgs || []).map(prog => {
                        return (
                            <FlowPageContent key={prog.id} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <BannableProgQ 
                                            flowProps={flowProps} 
                                            t={t} 
                                            prog={prog}
                                        />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                    {fourthPages.map((Comp,pg) => {
                        return (
                            <FlowPageContent key={pg} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <Comp 
                                            flowProps={flowProps} 
                                            t={t} 
                                            user={user} 
                                            submit={submit} 
                                        />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                    <FlowPageContent block render={(flowProps) => {
                            return (
                                <RoutineSelectQuestion flowProps={flowProps} t={t} />
                            )
                    }} />
                    {fifthPages.map((Comp,pg) => {
                        return (
                            <FlowPageContent key={pg} render={(flowProps) => {
                                return (
                                    <FlowQuestionContainer>
                                        <Comp 
                                            flowProps={flowProps} 
                                            t={t} 
                                            user={user} 
                                            submit={submit} 
                                            assignRoutine={assignRoutine}
                                            dismissTooltip={dismissTooltip}
                                        />
                                    </FlowQuestionContainer>
                                )
                            }} />
                        )
                    })}
                    <FlowPageContent block render={(flowProps) => {
                            return (
                                <SocialProofScreen flowProps={flowProps} t={t} />
                            )
                    }} />
                    <FlowPageContent render={(flowProps) => {
                        return (
                            <FlowQuestionContainer>
                                <FinishingTouchesScreen 
                                    flowProps={flowProps} 
                                    t={t} 
                                    user={user} 
                                />
                            </FlowQuestionContainer>
                        )
                    }} />
                    <FlowPageContent render={(flowProps) => {
                        return (
                            <FlowQuestionContainer>
                                <FinalSummaryScreen 
                                    flowProps={flowProps} 
                                    t={t} 
                                    user={user} 
                                />
                            </FlowQuestionContainer>
                        )
                    }} />
                </FlowForm>
            </Page>
        );
    }

    componentWillUnmount() {
        if(this.loadPromise){
            this.loadPromise.cancel();
        }
    }
}

//be careful reading state here because newEmailUser wipes all data and reloads user show view
//which caused multiple bugs with bannable progs not being loaded when expected
const mapStateToProps = state => ({
    initialValues: onboardingFlowDataSelector(state),
    user: userRecordSelector(state),
    trainer: trainerRecordSelector(state),
    allergyTags: allergyTagsSelector(state),
    recommendedCalories: recommendedCalsSelector(state),
    recipeOptions: initialRecipeOptionsSelector(state),
    bannableProgs: bannableProgSelector(state)
})

const mapDispatchToProps = dispatch => ({
    submit: (data) => dispatch(newEmailUser(data)),
    advanceAction: (data) => dispatch(advanceOnboardingFlow(data)),
    initMacros: data => dispatch(initMacros(data)),
    submitMealPlan: (data) => dispatch(updateMealPlan(data,true,false)),
    setCalorieSchedule: data => dispatch(setCalorieSchedule(data)),
    setOffPlanMeals: data => dispatch(setOffPlanMeals(data)),
    initMiniProfiles: data => dispatch(initMiniProfiles(data)),
    startMealPlan: (selectedRecipes,startDate) => dispatch(startMealPlan(selectedRecipes,startDate)),
    loadBannableProgs: () => dispatch(loadBannableProgs()),
    loadWorkoutTimes: (data) => dispatch(loadUserWorkoutTimes(data)),
    assignRoutine: data => dispatch(assignNewRoutine(data)),
    dismissTooltip: tip => dispatch(dismissTooltip(tip))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(OnboardingFlowPage));
