import { homePath } from 'config/paths';
import { APPLE_HEALTH_SYNC_TIP, SETUP_NOTIFICATIONS_TIP, SMART_REMINDERS_TIP } from 'config/tooltips';
import * as _ from 'lib/utilities';

export const userLayout = {
    mealPlanSetupAndVisible() {
        if(!this.isClient()) {
            return this.mealPlanInitialized();
        }
        return (this.mealPlanInitialized() && !this.showTrainingOnly());
    },

    workoutPlanSetupAndVisible() {
        if(!this.isClient()) {
            return this.workoutPlanInitialized();
        }
        return (this.workoutPlanInitialized() && !this.showNutritionOnly());
    },

    showStandaloneActivityLog() {
        if(!this.mealPlanInitialized() && !this.workoutPlanInitialized()) {
            return false;
        }

        if(this.isClient()) {
            return !this.workoutPlanSetupAndVisible() && !this.showNutritionOnly();
        }
        return !this.workoutPlanSetupAndVisible();
    },

    showAppleHealthSyncCard() {
        if(_.hasAppleHealth() && this.sawTooltip(SETUP_NOTIFICATIONS_TIP) && !this.sawTooltip(APPLE_HEALTH_SYNC_TIP)) {
            if(!this.isClient()) {
                return this.workoutPlanInitialized() || this.mealPlanInitialized();
            }
    
            if(this.showNutritionOnly()) {
                return this.mealPlanInitialized();
            }
    
            if(this.showTrainingOnly()) {
                return this.workoutPlanInitialized();
            }
    
            return (this.workoutPlanInitialized() || this.mealPlanInitialized());
        }

       return false;
    },

    showNotificationsSetupCard() {
        const isApp = (window.isCordova || _.isPWA());

        if(!isApp || this.sawTooltip(SETUP_NOTIFICATIONS_TIP)) {
            return false;
        }

        if(!this.isClient()) {
            return this.workoutPlanInitialized() || this.mealPlanInitialized();
        }

        if(this.showNutritionOnly()) {
            return this.mealPlanInitialized();
        }

        if(this.showTrainingOnly()) {
            return this.workoutPlanInitialized();
        }

        return (this.workoutPlanInitialized() || this.mealPlanInitialized());
    },

    shouldRequestNotification() {
        const isApp = (window.isCordova || _.isPWA());

        if(!isApp) {
            return false;
        }

        if(this.isTrainer()) {
            return true;
        }

        if(!this.sawTooltip(SETUP_NOTIFICATIONS_TIP) && !this.sawTooltip(SMART_REMINDERS_TIP)) {
            return false;
        }

        return (this.mealPlanInitialized() || this.workoutPlanInitialized() || this.isClient());
    },

    showDayTabs() {
        return (this.mealPlanSetupAndVisible() || this.workoutPlanSetupAndVisible());
    },

    showWorkoutSetupCard() {
        return (!this.workoutPlanInitialized() && !this.showNutritionOnly() && this.canPickOwnRoutine() && !this.sawTooltip('setup_workout_card'))
    },

    showWorkoutPlanNotReadyCard() {
        return this.isClient() && !this.showNutritionOnly() && !this.workoutPlanInitialized() && !this.canPickOwnRoutine();
    },

    showMealPlanSetupCard() {
        return (!this.mealPlanInitialized() && this.setupOwnMealPlanPrefs() && !this.showTrainingOnly() && !this.sawTooltip('setup_meal_plan_card'))
    },

    showMealPlanNotReadyCard() {
        return !this.setupOwnMealPlanPrefs() && !this.showTrainingOnly() && !this.mealPlanInitialized();
    },

    isUninitClient() {
        if(this.isClient()) {
            if(!this.hasBasicProfile) {
                return true;
            }

            if(!this.showTrainingOnly() && (this.mealPlanInitialized() || this.setupOwnMealPlanPrefs())) {
                return false;
            }

            if(!this.showNutritionOnly() && (this.workoutPlanInitialized() || this.canPickOwnRoutine())) {
                return false;
            }

            return true;
        }
        return false;
    },

    showSignedInNav() {
        return this.canSeeMainLayout();
    },

    canSeeMainLayout() {
        return (this.hasBasicProfile || (this.isClient() && this.inviteClaimed()));
    },

    showSetupOwnProfileCheck() {
        return (this.isAppClient() && !this.hasBasicProfile && !this.inviteClaimed()) || (this.isPdfClient() && !_.isBlank(this.email) && !this.hasBasicProfile);
    },

    showDefaultFormsCheck() {
        return (this.isAppClient() && !this.hasSetupForms());
    },

    afterClientInvitePath() {
        return homePath();
    },

    showLimitedClientMenu() {
        return (this.isUninitClient() && this.inviteClaimed())
    },

    showCliDetailsChatLink() {
        return !!this.primaryChatId && this.isAppClient() && this.hasActiveMessaging();
    }
}