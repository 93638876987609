import React from 'react';
import WebLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { isOldApp } from 'lib/utilities';

class FacebookLogin extends React.Component {

    constructor(props) {
        super(props)
        this.onClick = this.onClick.bind(this)
        this.preSuccessCallback = this.preSuccessCallback.bind(this)
        this.preFailCallback = this.preFailCallback.bind(this)
        this.finalCallback = this.finalCallback.bind(this)
        this.state = { isProcessing: false, isDisabled: false }
    }

    render() {
        if(window.cordova || isOldApp()) {
            return (
                <React.Fragment>
                    {this.props.render({onClick: this.onClick, isProcessing: this.state.isProcessing, isDisabled: this.state.isDisabled})}
                </React.Fragment>
            )
        } else {
            return (
                <WebLogin {...this.props} />
            )
        }
    }

    preSuccessCallback(userData) {
        window.facebookConnectPlugin.api(`${userData.authResponse.userID}?fields=email&access_token=${userData.authResponse.accessToken}`,[],this.finalSuccess(userData.authResponse),this.preFailCallback);
    }

    finalSuccess = (initialResponse) => (emailResponse) => {
        this.finalCallback({ ...initialResponse, ...emailResponse });
    }

    preFailCallback(error) {
        this.finalCallback({error: true, details: JSON.stringify(error)})
    }

    finalCallback(data) {
        const { callback } = this.props
        this.setState({isProcessing: false})
        callback(data)
    }

    onClick() {
        if(isOldApp()) {
            this.finalCallback({ error: true, details: 'Please upgrade to the latest version of Strongr Fastr to use Facebook sign in'})
        } else {
            let { fields } = this.props
            this.setState({isProcessing: true})
            fields = fields.split(',').map(field => (field === 'first_name' ? 'public_profile' : field))
            window.facebookConnectPlugin.login(fields,this.preSuccessCallback,this.preFailCallback)
        }
    }
}

export default FacebookLogin;