import { RecordBase, registerInflection} from 'lib/record-base';
import * as _ from 'lib/utilities';

export class Subscription extends RecordBase {
    static NAME = 'Subscription'

    static ASSOCS = {
        user: { type: 'belongsTo' }
    }

    exists() {
        const subGone = this.source === 'stripe' && this.stripeSubGone;
        return (!_.isBlank(this.source) && !subGone);
    }

    isTrainerSub() {
        return this.type === 'TrainerSubscription';
    }

    actionType() {
        return this.isTrainerSub() ? 'trainer' : '';
    }

    uninitialized() {
        return _.isBlank(this.planExpires);
    }
}

registerInflection('subscription','subscriptions',Subscription);