import React from 'react';
import { allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { withTranslation } from 'react-i18next';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import { GetAppButtons } from 'partials/Utilities';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut]
    ]
};

class GetTheAppPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title={t("Get the App")}
            >
                <CenteredActivityContainer>
                    <div className="text-center">
                        <GetAppButtons />
                    </div>
                </CenteredActivityContainer>
            </SimpleNavPage>
        )
    }
}

export default withTranslation()(GetTheAppPage);