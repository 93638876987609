import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { requestMealPlan } from 'redux/actions';
import { DefaultTextArea } from 'components/Form';
import { FullFormModal } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import { useLocation } from 'react-router-dom';
import { dateFormat } from 'config/settings';

const mapDispatchToProps = dispatch => ({
    submit: data => dispatch(requestMealPlan(data))
})

export const requestPlanModalSuffix = '/request_plan'

export const RequestMealPlanModal = connect(null,mapDispatchToProps)(({ submit, startDate }) => {

    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(requestPlanModalSuffix,pathname);

    return (
        <FullFormModal 
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={'paper-plane'}
            title={t('Request a Plan')}
            formProps={ () => {            
                return ({
                    initialValues: { message: '', startDate: ((typeof startDate === 'string') ? startDate : startDate.format(dateFormat)) },
                    submit: submit
                })
            }}
            footerProps={{ saveLabel: t('Send Request'), saveIcon: 'paper-plane' }}
            render={({ modalProps, ...formikProps }) => {
                
                return (
                    <div className="pa20">
                        <DefaultTextArea
                            className="mt10 mb10"
                            name="message"
                            label={t('Message')}
                            inProps={{
                                maxLength: 4000,
                                placeholder: t('Optional'),
                                height: 50
                            }}
                            {...formikProps}
                        />
                    </div>                
                )
            }}
        />
    )
})