import React from 'react';
import PlusMinusToggle, { PlusMinusField } from 'components/PlusMinusToggle';
import MacroSummary from 'partials/MacroSummary';
import { SelectInput } from 'components/TextInput';
import RecordPanel, { ActionBox, ContentBox, ActionButton } from 'components/RecordPanel';
import { Formik } from 'formik';
import * as Yup from 'yup';
import * as _ from 'lib/utilities';

export const ServingsToggleFormCore = ({ weights, values, setValueCreator, errors, setValues, handleBlur, servingsText, setSeqCreator, handleChange }) => {

    return (
        <React.Fragment>
            <PlusMinusToggle 
                tighter={!!weights}
                inline={!!weights}
                value={values.servings} 
                setValue={setValueCreator(values, setValues)}  
                error={errors.servings} 
                render={({ value, error, onChange}) => {
                    return (
                        <PlusMinusField label={servingsText}
                        onChange={onChange} 
                        onBlur={handleBlur} 
                        value={value} 
                        error={error} 
                        name="servings"/>
                    )
                }} />
                {weights && (
                <SelectInput 
                    collection={weights} 
                    valMethod={1} 
                    textMethod={0}
                    value={values.seq}
                    onChange={(e) => (setSeqCreator ? setSeqCreator(values,setValues)(_.isBlank(e.target.value) ? e.target.value : Number(e.target.value)) : handleChange(e))}
                    browserDefault 
                    className="small-inline"
                    name="seq" 
                />)}
        </React.Fragment>
    )
}

export const ServingsToggleForm = ({ 
    t, 
    formFields, 
    onSubmit, 
    id, 
    description, 
    macroHash, 
    setValueCreator, 
    setSeqCreator,
    status, 
    servingsText, 
    weights, 
    infoButton,
    defaultIcon,
    ...rest }) => {

    return (
        <Formik initialValues={ formFields }
                validationSchema={Yup.object().shape({
                    servings: Yup.number().moreThan(0).required()
                })}
                onSubmit={onSubmit}
                initialErrors={{}} >
                {({ submitForm, setValues, handleBlur, handleChange, values, errors, isValid }) => {
                    return (
                        <RecordPanel {...rest} >
                            <ActionButton 
                                id={'log-food-btn-' + id}
                                defaultIcon={defaultIcon || 'check'} 
                                successLabel={defaultIcon === 'plus' ? t('Added') : t("logged")}
                                failLabel={t("failed")} 
                                className="log-food-btn success-color" 
                                status={isValid ? status : 'INVALID'} 
                                onClick={submitForm} 
                            />
                            {infoButton}
                            {description && (
                            <ActionBox wider side="left" inactive>
                                <div>{description}</div>
                                <MacroSummary macroHash={macroHash()} />
                            </ActionBox>
                            )}
                            
                            <ContentBox className="text-center no-wrap">
                                <ServingsToggleFormCore 
                                    weights={weights}
                                    values={values}
                                    setValueCreator={setValueCreator}
                                    errors={errors}
                                    setValues={setValues}
                                    handleBlur={handleBlur}
                                    servingsText={servingsText}
                                    setSeqCreator={setSeqCreator}
                                    handleChange={handleChange}
                                />  
                            </ContentBox>
                        </RecordPanel>
                    )
            }}
        </Formik>
    )
}