import React from 'react';
import FAB from 'assets/js/buttons';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class FloatingActionButtonCore extends React.PureComponent {

    constructor(props) {
        super(props);
        const { forwardedRef } = props;
        this.fabRef = forwardedRef || React.createRef();
    }

    componentDidMount() {
        this.fab = FAB.init(this.fabRef.current,{hoverEnabled: false});
    }

    render() {
        const { containerClass, btnClass, children, icon, id, forwardedRef, className, ...rest } = this.props;

        return (
            <div className={classnames("fixed-action-btn direction-top",{[containerClass]: containerClass, [className]: className})} {...rest} ref={this.fabRef}>
                <div className={classnames("btn-floating btn-large trigger",{ [btnClass]: btnClass })} id={id}>
                    <FontAwesomeIcon icon={icon} />
                </div>
                <ul>
                    {children}
                </ul>
            </div>
        )
    }

    componentWillUnmount() {
        this.fab.destroy();
    }

}

const FloatingActionButton = React.forwardRef((props,ref) => <FloatingActionButtonCore {...props} forwardedRef={ref} />)

export const FabChild = ({ label, className, icon, onClick, id }) => {

    return (
        <li className="position-relative clickable fab-container" onClick={onClick}>
            <div className="fab-label-container">
                <div className={classnames("fab-label", { [className]: className })}>{label}</div>
            </div>
            <div className={classnames("btn-floating",{ [className]: className })} id={id}>
                <FontAwesomeIcon icon={icon} />
            </div>
        </li>
    )
}

export default FloatingActionButton;