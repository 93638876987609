import React from 'react';
import { connect } from 'react-redux';
import Button from "components/Button";
import FacebookLogin from 'components/FacebookLogin';
import GoogleLogin from 'components/GoogleLogin';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import loadingContainer from './LoadingHOC';
import { Redirect } from "components/Routing";
import { verifyOauth } from "redux/actions";
import { oauthSignupDataSelector } from 'redux/selectors';
import * as Utils from 'lib/utilities';
import { useTranslation } from 'react-i18next';
import { EmailMissingForm } from 'components/EmailLoginForm';
import AppleLogin from 'components/AppleLogin';

const typeNameMap = { apple: 'Apple', google: 'Google', fb: 'Facebook'}
const appleRedirectPath = '/api/v1/user/pre_verify_apple';

const Default = ({ error, signup, didLoad, onlyApple, ...rest }) => (
    <React.Fragment>
        {!onlyApple && !signup && (<FacebookOption signup={signup} {...rest} />)}
        {!onlyApple && (<GoogleOption signup={signup} {...rest} />)}
        {(!signup || Utils.isIOS()) && <AppleOption didLoad={didLoad} signup={signup} {...rest} />}
        {error && <div id="oauth-verify-error" className="red-text mb20">{error}</div>}
    </React.Fragment>
)

const Success = ({error, successPath, ...rest}) => {
    if(error) {
        return <Default error={error} {...rest} />
    } else {
        return <Redirect to={successPath} />
    }
}

const LoadingContainer = loadingContainer(
    {
        'SUCCESS': Success, 
        'DEFAULT': Default
    }
)

class OAuthButtons extends React.Component {

    constructor(props) {
        super(props);
        this.verifyOauth = this.verifyOauth.bind(this);
        this.setLoadState = this.setLoadState.bind(this);
        this.setCurStatus= this.setCurStatus.bind(this);
        this.errorReset = this.errorReset.bind(this);
        this.didLoad = false;
        this.state = { status: 'DEFAULT', loginError: null };
    }

    render() {
        if(this.state.oauthData) {
            const {label, successPath, curStatus } = this.props;
            return (
                <EmailMissingForm 
                    label={label} 
                    successPath={successPath} 
                    curStatus={curStatus}
                    oauthData={this.state.oauthData} 
                    loginUser={this.verifyOauth} 
                    setError={curStatus === 'emailMissing' ? null : this.errorReset}
                />
            )
        } else {
            return (
                <LoadingContainer 
                    verifyOauth={this.verifyOauth}
                    didLoad={this.didLoad}
                    loadState={this.state.status} 
                    error={this.state.loginError} 
                    setLoadState={this.setLoadState} 
                    signup={!!this.props.userData}
                    successPath={this.props.successPath}
                    setCurStatus={this.setCurStatus}
                    onlyApple={this.props.onlyApple}
                />
            )
        }
    }

    verifyOauth(data) {
        const { userData, isSwitcher } = this.props;
        if(userData) {
            const { email, firstName, ...oauthData } = data;
            const user = { ...userData, email, firstName };
            data = { signup: true, user, ...oauthData };
        }
        data = { ...data, isSwitcher };
        this.didLoad = true;
        this.cancelable = Utils.makeCancelable(this.props.verifyOauth(data));
        return this.cancelable.promise;
    }

    setCurStatus(data,status,loginError=null) {
        const { setCurStatus } = this.props;
        setCurStatus(status);
        this.setState({ oauthData: data, loginError });
    }

    errorReset(error) {
        this.setCurStatus(null,'initial',error);
    }

    setLoadState(state) {
        this.setState(state);
    }

    componentWillUnmount() {
        if(this.cancelable) {
            this.cancelable.cancel();
        }
    }
}

const mapDispatchToProps = dispatch => ({
    verifyOauth: (data) => dispatch(verifyOauth(data))
})

export default connect(null,mapDispatchToProps)(OAuthButtons);

const mapStateToSignupProps = state => ({
    userData: oauthSignupDataSelector(state)
})

const mapStateToTrainerProps = state => ({
    userData: {}
})

export const SignupOAuthButtons = connect(mapStateToSignupProps,mapDispatchToProps)(OAuthButtons);
export const TrainerSignupOAuthButtons = connect(mapStateToTrainerProps,mapDispatchToProps)(OAuthButtons);

function verifyResponseCreator(verifyOauth,setLoadState,type,t,signup,setCurStatus) {
    return function(response) {
        let data = { type }
        let error = null;
        if(response.error) {
            error = response.details
        } else if(type === 'fb') {
            data['access_token'] = response.accessToken
            data['fb_user_id'] = response.userID
        } else if(type === 'google') {
            data['access_token'] = response['access_token'] || response['accessToken'];
        } else if(type === 'apple') {
            data.access_token = response.authorization.code;
            data.identity_token = response.authorization.id_token;
            const { user } = response;
            if(user && user.name && user.name.firstName) {
                data.firstName = user.name.firstName;
            }
        }
        
        if(data['access_token']) {
            if(signup) {
                const status = (type === 'fb' && !response.email) ? 'emailMissing' : 'tos';
                setCurStatus(data,status);
            } else {
                setLoadState({status: 'REQUEST'});
                verifyOauth(data).then((response) => {
                    if(!response.isCanceled) {
                        setLoadState({status: response.status, loginError: response.data.error});
                    }
                })
                .catch((response) => {
                    if(!response.isCanceled) {
                        setLoadState({status: response.status});
                    }
                })
            }
        } else {
            setLoadState({ status: 'SUCCESS', loginError: error || (t("couldn't login with",{login_type: (typeNameMap[type])}))});
        }
    }
}

function FacebookOption({ verifyOauth, setLoadState, signup, setCurStatus }) {
    const { t } = useTranslation();
    const label = signup ? t('Continue with type',{login_type: 'Facebook'}) : t('Sign in with type',{login_type: 'Facebook'});
    return (
        <div className="mb10">
            <FacebookLogin
                appId={process.env.REACT_APP_FB_ID}
                fields="email,first_name"
                disableMobileRedirect={true}
                render={ renderProps => {
                    let [icon, spin] = [['fab','facebook-f'], false]
                    if(renderProps.isProcessing) {
                        [icon, spin] = [['fas','spinner'], true]
                    }
                    return(
                        <Button id="facebook-login-btn" color="facebook" className="no-upcase" rounded block onClick={() => setLoadState({ status: 'SUCCESS', loginError: "Sign in with Facebook is no longer available. If you signed up using Facebook, you can access your account by using 'Forgot your password?' to set a password for your account. Enter the email that was associated with your Facebook account when you first signed up for Strongr Fastr. If you don't have access to that email, please contact us." })} disabled={renderProps.isDisabled} >
                            <FontAwesomeIcon icon={icon} size='1x' className='em-1-5' spin={spin} /><span>{label}</span>
                        </Button>
                    )
                }}
                callback={verifyResponseCreator(verifyOauth,setLoadState,'fb',t,signup,setCurStatus)} />
        </div>
    )
}

function GoogleOption({ verifyOauth, setLoadState, signup, setCurStatus }) {
    const { t } = useTranslation();
    const callback = verifyResponseCreator(verifyOauth,setLoadState,'google',t,signup, setCurStatus);
    const label = signup ? t('Continue with type',{login_type: 'Google'}) : t('Sign in with type',{login_type: 'Google'});
    return (
        <div className="mb10">
            <GoogleLogin
                clientId={process.env.REACT_APP_GOOGLE_CLIENT}
                render={ renderProps => {
                    return(
                        <Button id="google-login-btn" color="google" className="no-upcase" rounded block onClick={renderProps.onClick} disabled={renderProps.disabled} >
                            <FontAwesomeIcon icon={['fab','google']} size='1x' className='em-1-5' /><span>{label}</span>
                        </Button>
                    )
                }}
                onSuccess={callback}
                onFailure={callback} />
        </div>
    )
}

function AppleOption({ verifyOauth, setLoadState, signup, didLoad, setCurStatus }) {
    const { t } = useTranslation();
    const callback = verifyResponseCreator(verifyOauth, setLoadState, 'apple', t, signup, setCurStatus)
    const label = signup ? t('Continue with type',{login_type: 'Apple'}) : t('Sign in with type',{login_type: 'Apple'});
    return (
        <div className="mb10">
            <AppleLogin
                didLoad={didLoad}
                clientId={process.env.REACT_APP_APPLE_CLIENT}
                redirectURI={`${window.location.protocol}//${window.location.host}${appleRedirectPath}`}
                scope="name email"
                responseType="code id_token"
                responseMode="form_post"
                usePopup={!(window.isCordova || Utils.isOldApp())}
                render={ renderProps => {
                    let [icon, spin] = [['fab','apple'], false]
                    if(renderProps.isProcessing) {
                        [icon, spin] = [['fas','spinner'], true]
                    }
                    return(
                        <Button id="apple-login-btn" color="apple" className="no-upcase" rounded block onClick={renderProps.onClick} disabled={renderProps.disabled} >
                            <FontAwesomeIcon icon={icon} size='1x' className='em-1-5' spin={spin} /><span>{label}</span>
                        </Button>
                    )
                }}
                callback={callback} />
        </div>
    )
}
