import * as _ from 'lib/utilities';
import { fromPairs, isNumeric } from 'lib/utilities';

export const coreMacros = ['protein','fat','carbs'];
export const allMacros = coreMacros.concat(['fiber','alcohol']);
export const coreMacrosWithCals = ['calories'].concat(coreMacros);
export const allMacrosWithCals = ['calories'].concat(allMacros);
export const zeroedMacros = fromPairs(allMacrosWithCals.map(macro => [macro,0]));
export const macroCals = { protein: 4.0, fat: 9.0, carbs: 4.0, alcohol: 7.0, fiber: 4.0 }

export const caloriesFromMacros = (macros) => {
    return allMacros.map((macro) => {
        if(macros[macro] && isNumeric(macros[macro])) {
            return Math.max(Number(macros[macro])*macroCals[macro],0.0);
        } else {
            return 0.0;
        }
    }).reduce((total,num) => (total + num))
}

const buildBelongsToAssoc = (rec,tables,includes,defaults,{ assoc, tableName, ...mapping }) => {
    if(tables[tableName] && !rec[assoc]) {
        const table = tables[tableName];
        const fk = mapping.fk || `${assoc}Id`;
        if(rec[fk]) {
            const obj = table[rec[fk]];
            if(obj) {
                rec[assoc] = mapping.classVal.newWithAssocs(obj,tables,includes,defaults,false);
            }
        }
    }
}

const buildHasManyAssoc = (rec,tables,includes,defaults,mapping) => {
    buildHasAssoc(rec,tables,includes,defaults,mapping);
}

const buildHasOneAssoc = (rec,tables,includes,defaults,mapping) => {
    buildHasAssoc(rec,tables,includes,defaults,mapping,'one');
}

const buildHasAssoc = (rec,tables,includes,defaults,{ tableName, assoc, hasAssocKey, classVal, invAssoc, sortAttr, fk: defFk, where },type='many') => {
    if(tables[tableName] && !rec[assoc]) {
        const table = tables[tableName];
        let fk,denorm;
        if(defFk || invAssoc) {
            fk = defFk || `${invAssoc}Id`;
            denorm = false;
        } else {
            denorm = true;
        }
        const fkVals = [rec.id];
    
        if(type === 'many') {
            const mapFn = obj => (classVal.newWithAssocs(obj,tables,includes,defaults,false));
            let ids = rec[hasAssocKey];
            if(typeof ids === 'function') {
                ids = ids();
            }
            let results = denorm ? _.pick(table,ids) : _.selectWithFKs(table,fk,fkVals);
            if(where) {
                results = _.filter(results,where);
            }
            const mappedResults = _.mapValues(results,mapFn);
            let recordCol = Object.values(mappedResults);
            if(sortAttr) {
                rec[assoc] = _.sortBy(recordCol,record => (typeof record[sortAttr] === 'function' ? record[sortAttr]() : record[sortAttr]));
            } else {
                rec[assoc] = recordCol;
            }
        } else {
            const results = where ? _.filter(table,where) : table;
            let id = rec[hasAssocKey];
            if(typeof id === 'function') {
                id = id();
            }
            const assocRec = denorm ? results[id] : _.findWithFKs(results,fk,fkVals);
            if(assocRec) {
                rec[assoc] = classVal.newWithAssocs(assocRec,tables,includes,defaults,false);
            } else {
                rec[assoc] = null;
            }
        }
    }
}

export class RecordBase {

    static inverse(assoc,mapping,otherClass) {
        let inverseName = mapping.inverse;
        if(!inverseName) {
            inverseName = _.lowerFirst(this.NAME);
            if(mapping.type === 'belongsTo') {
                const otherAssocs = otherClass.ASSOCS;
                if(!otherAssocs[inverseName] || otherAssocs[inverseName].type === 'hasMany') {
                    inverseName = INFLECTIONS.plurals[inverseName];
                }
            }
        }

        if(!otherClass.ASSOCS[inverseName]) {
            throw(new Error(`Cannot find inverse association for ${assoc} association of ${this.NAME} on class ${otherClass.NAME}. Tried ${inverseName}`))
        }

        return [inverseName,otherClass.ASSOCS[inverseName]];
    }

    static classFromMapping([assoc,mapping]) {
        if(mapping.classVal) {
            return INFLECTIONS.classes[mapping.classVal];
        }
        let singularName = INFLECTIONS.singulars[assoc] ? INFLECTIONS.singulars[assoc] : assoc;
        if(mapping.tableName) {
            singularName = INFLECTIONS.singulars[mapping.tableName];
        }
        
        return INFLECTIONS.classes[singularName];
    }

    static classFromPolyMapping(polyType) {
        if(!polyType){
            return null;
        }
        return INFLECTIONS.classes[_.lowerFirst(polyType)];
    }

    static tableNameFromMapping([assoc,mapping]) {
        if(mapping.tableName) {
            return mapping.tableName;
        }
        const pluralName = INFLECTIONS.plurals[assoc] ? INFLECTIONS.plurals[assoc] : assoc;
        return pluralName;
    }

    static tableNameFromPolyMapping(polyType) {
        if(!polyType){
            return null;
        }
        return INFLECTIONS.plurals[_.lowerFirst(polyType)];
    }

    static classTableName() {
        return INFLECTIONS.plurals[_.lowerFirst(this.NAME)];
    }

    static defaultChildAssoc(assoc,mapping,otherClass) {
        if(mapping.hasAssocKey) {
            return null;
        }
        const [invAssoc,invMapping] = this.inverse(assoc,mapping,otherClass);
        if(invMapping.type !== 'hasMany') {
            return invAssoc;
        } else {
            return null;
        }
    }

    static ASSOCS = {};

    static find(id,tables,includes=null) {
        id = _.isNumeric(id) ? Number(id) : id;
        const record = (tables[this.classTableName()] || {})[id];
        if(record) {
            return this.newWithAssocs(record,tables,includes);
        } else {
            return null;
        }
    }

    static where(predicate,tables,includes=null) {
        Object.entries(tables).forEach(([tableName,table]) => {
            tables[tableName] = { ...(table || {}) };
        })
        const results = _.filter(Object.values(tables[this.classTableName()]),predicate);
        return results.map(result => this.newWithAssocs(result,tables,includes,false));
    }

    static newWithAssocs(obj,tables,includes=null,defaulted={},fromRedux=true) {

        if(obj instanceof RecordBase) {
            return obj;
        } else {
            if(fromRedux) {
                Object.entries(tables).forEach(([tableName,table]) => {
                    tables[tableName] = { ...(table || {}) };
                })
            }
            let newRec = new this(obj);
            const classTableName = this.classTableName();
            tables[classTableName] = tables[classTableName] || {};
            tables[classTableName][newRec.id] = newRec;
    
            const assocs = this.ASSOCS;
            const defaultedPick = _.pick(defaulted,Object.keys(assocs));
            let neededAssocKeys = _.difference(Object.keys(assocs),Object.keys(defaulted));
            if(includes) {
                neededAssocKeys = _.intersection(Object.keys(includes),neededAssocKeys);
            }
            const neededAssocs = _.pick(assocs,neededAssocKeys);
            Object.assign(newRec,defaultedPick);
    
            Object.entries(neededAssocs).forEach(([assoc,mapping]) => {
                let otherClass, tableName;
                if(mapping.poly) {
                    const polyType = newRec[`${assoc}Type`];
                    otherClass = this.classFromPolyMapping(polyType);
                    tableName = this.tableNameFromPolyMapping(polyType)
                } else {
                    otherClass = this.classFromMapping([assoc,mapping]);
                    tableName = this.tableNameFromMapping([assoc,mapping]);
                }
                
                if(otherClass && tableName) {
                    const defaultChildAssoc = this.defaultChildAssoc(assoc,mapping,otherClass);;
                    const nestedIncludes = includes && includes[assoc];
                    let defaults = {  };
                    if(defaultChildAssoc) {
                        defaults[defaultChildAssoc] = newRec;
                    }
        
                    if(mapping.type === 'hasMany') {
                        buildHasManyAssoc(newRec,tables,nestedIncludes,defaults,{ ...mapping, classVal: otherClass, tableName, assoc, invAssoc: defaultChildAssoc });
                    } else if(mapping.type === 'hasOne') {
                        buildHasOneAssoc(newRec,tables,nestedIncludes,defaults,{ ...mapping, classVal: otherClass, tableName, assoc, invAssoc: defaultChildAssoc });
                    } else {
                        buildBelongsToAssoc(newRec,tables,nestedIncludes,defaults,{ ...mapping, classVal: otherClass, tableName, assoc });
                    }
                }
            })
            return newRec;
        }
    }

    static updateAll(inputRecords,values) {
        const tableName = this.classTableName();
        const errs = { };
        const updates = values[tableName];
        inputRecords.forEach((rec,index) => {
            const thisErrs = rec.update(updates[index]);
            if(!_.isEmpty(thisErrs)) {
                errs[tableName] = errs[tableName] || {};
                errs[tableName][index] = thisErrs;
            }
        })
        return errs;
    }

    static formDependencyKeys() {
        return Object.keys(this.FORM_DEPENDENCIES || {});
    } 

    static breakingKeyFor(id) {
        return `${this.NAME}${id}`;
    } 

    constructor(obj) {
        Object.assign(this,obj);
    }

    update(attrHash,rejectInvalid=false,t=(val)=>(val)) {
        if(rejectInvalid && typeof this.validate === 'function') {
            const oldVals = this.extract(Object.keys(attrHash));
            _.setAttrIndif(this,attrHash);
            const errs = this.validate(t);
            if(_.isEmpty(errs)) {
                return {};
            } else {
                _.setAttrIndif(this,oldVals);
                return errs;
            }
        } else {
            _.setAttrIndif(this,attrHash);
            return typeof this.validate === 'function' ? this.validate(t) : {};
        }
    }

    extract(attrs,all=false) {
        const nested = attrs[attrs.length-1];
        let nestedAttrs = {};
        if(typeof nested === 'object') {
            attrs = attrs.slice(0,attrs.length-1);
            Object.entries(nested).forEach(([assoc,assocAttrs]) => {
                const assocRecords = this[assoc];
                if(assocRecords) {
                    if(Array.isArray(assocRecords)) {
                        nestedAttrs[assoc] = assocRecords.map(record => record.extract(assocAttrs,all))
                    } else {
                        nestedAttrs[assoc] = assocRecords.extract(assocAttrs,all);
                    }
                }
            })
        }
        return { ..._.getAttrIndif(this,attrs,all), ...nestedAttrs }
    }

    persistAttrs() {
        const assocs = Object.keys(this.constructor.ASSOCS);
        const nonPersistAttrs = this.constructor.DONT_PERSIST || [];
        return _.omitBy(this,(val,attr) => (assocs.includes(attr) || nonPersistAttrs.includes(attr) || (typeof val === 'function')));
    }

    renormalize(tables=null,includes=null) {
        let includeSelf = true;
        if(includes && typeof includes === 'function') {
            ([includeSelf,includes] = includes(this));
        }

        if(!includeSelf) {
            return tables;
        }

        tables = tables || {};
        const classTableName = this.constructor.classTableName();

        if(tables[classTableName] && tables[classTableName][this.id]) {
            return tables;
        } else {
            tables[classTableName] = tables[classTableName] || {};
            tables[classTableName][this.id] = { ...this.persistAttrs() };
        }

        let assocs = includes ? _.pick(this.constructor.ASSOCS,Object.keys(includes)) : this.constructor.ASSOCS;

        Object.entries(assocs).forEach(([assoc,mapping]) => {
            const newIncludes = includes ? includes[assoc] : null;
            const recs = this[assoc];
            if(recs) {
                if(mapping.type === 'hasMany') {
                    recs.forEach(rec => rec.renormalize(tables,newIncludes));
                } else {
                    recs.renormalize(tables,newIncludes);
                }
            }
        })

        return tables;
    }

    resolveFormValues(vals) {
        
        return _.mapValues(vals,(val,key) => this.resolveWithAllowed(key,val));
        
    }

    resolveWithAllowed(attr,curVal) {
        const allowedValsFn = this[`allowed${_.capitalize(attr)}Arr`]
        if(allowedValsFn && typeof allowedValsFn === 'function') {
            const allowedVals = allowedValsFn();
            if(allowedVals.includes(curVal)) {
                return curVal;
            }
            return allowedVals[0];
        } else {
            return curVal;
        }
    }

    updateForForm(attr,val) {
        if(this.constructor.FORM_DEPENDENCIES.includes(attr)) {
            this.update({ [attr]: val }, false);
            return { ..._.pick(this.formValues(),this.constructor.FORM_DEPENDENCIES) }
        } else {
            return { [attr]: val }
        }
    }
}

export class Eatable extends RecordBase {

}

export class ExerciseImplementation extends RecordBase {
    static TESTABLE_SET_TYPES = [0,2,3]

    isTimed() {
        return this.setType === 1;
    }

    isFixedReps() {
        return this.setType === 2;
    }

    isWarmupReps() {
        return this.setType === 3;
    }

    isTimeWork() {
        return this.setType === 100;
    }

    isTimeWarmup() {
        return this.setType === 102;
    }

    isDistanceWork() {
        return this.setType === 101;
    }

    isDistanceWarmup() {
        return this.setType === 103;
    }

    isOpenEndedActivity() {
        return this.setType === 105;
    }

    isTimedActivity() {
        return this.setType === 104;
    }

    isTimeBased() {
        return (this.isTimeWork() || this.isTimeWarmup());
    }

    isDistanceBased() {
        return (this.isDistanceWork() || this.isDistanceWarmup());
    }

    isWarmupSetType() {
        return (this.isTimeWarmup() || this.isDistanceWarmup() || this.isWarmupReps());
    }

    repsDefined() {
        return this.constructor.TESTABLE_SET_TYPES.includes(this.setType);
    }

}

export class SetImplementation extends RecordBase {

    singleRepGoalStr(t,includeSuffix=false) {
        return this.isIsometric() ? `${_.stopwatchFormat(this.minReps)}${this.isAmrap() ? '+' : ''}` : `${this.minReps}${this.isAmrap() ? '+' : ''} ${includeSuffix ? t('Reps').toLowerCase() : ''}`;
    }

    rangeRepGoalStr(t,includeSuffix=false) {
        if(_.isBlank(this.maxReps) || this.isAmrap()) {
            return this.singleRepGoalStr(t,includeSuffix);
        } else {
            return this.isIsometric() ? `${_.stopwatchFormat(this.minReps)}-${_.stopwatchFormat(this.maxReps)}` : `${this.minReps}-${this.maxReps} ${includeSuffix ? t('Reps').toLowerCase() : ''}`;
        }
    }

    isoTimeLabel(t) {
        return _.timeLabel(_.isBlank(this.maxReps) ? this.minReps : this.maxReps,t);
    }

    isWeightSet() {
        return !_.isBlank(this.weight);
    }

    kgWeight(setVal) {
        if(setVal !== undefined) {
            this.weight = _.isBlank(setVal) ? null : _.roundToF(setVal*2.2,0.001);
        } else {
            return !_.isBlank(this.weight) ? _.roundToF(this.weight/2.2,0.001) : null;
        }
    }

    minSecTime(setVal) {
        return this.timeSetter(setVal,'time');
    }

    timeSetter(setVal,attr) {
        if(setVal !== undefined) {
            if(setVal) {
                this[attr] = _.parseStopwatchFormat(setVal);
            } else {
                this[attr] = null;
            }
        } else {
            return _.stopwatchFormat(this[attr] || 0);
        }
    }

    kmDistance(setVal) {
        if(setVal !== undefined) {
            this.distance = _.isBlank(setVal) ? null : setVal*1000;
        } else {
            return !_.isBlank(this.distance) ? _.roundToF(this.distance/1000,0.01) : null;
        }
    }

    mileDistance(setVal) {
        if(setVal !== undefined) {
            this.distance = _.isBlank(setVal) ? null : setVal*1600;
        } else {
            return !_.isBlank(this.distance) ? _.roundToF(this.distance/1600,0.01) : null;
        }
    }
}

const INFLECTIONS = {
    plurals: {},

    singulars: {},

    classes: {}
}

export const registerInflection = (singular,plural,classConstructor) => {
    INFLECTIONS.plurals = { ...INFLECTIONS.plurals, [singular]: plural };
    INFLECTIONS.singulars = _.invert(INFLECTIONS.plurals);
    INFLECTIONS.classes = { ...INFLECTIONS.classes, [singular]: classConstructor };
}