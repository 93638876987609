import React from 'react';
import ActivityContainer from 'components/ActivityContainer';
import InfiniteScroll from 'components/InfiniteScroll';
import { SlightEmphasisIconNote } from 'components/Typography';
import { ListCard, ListCardTitle, ListCardAction, ListCardActionLink } from 'components/List';
import { ErrorLog } from 'lib/error-log';
import Button, { ConfirmActionButton, BottomButtons, LoaderActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorLogPathFor } from 'config/paths';
import requestStateHandler from 'components/RequestStateHandler';
import { errorLogsSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { loadErrorLogs, destroyErrorLog, clearErrorLogs, refreshErrorLogs } from 'redux/actions';

let ErrorLogListing = ({ record, destroy }) => {
    const errorLog = new ErrorLog(record);
    const newDestroy = () => {
        const newRecord = { ...record, destroyed: true };
        destroy(newRecord);
    }

    if(record.destroyed) {
        return ''
    } else {
        return (
        <ListCard >
            <ListCardTitle>{errorLog.getTitle()}</ListCardTitle>
            <ListCardActionLink to={errorLogPathFor(errorLog.id)}>
                <FontAwesomeIcon icon={"eye"} />
            </ListCardActionLink>
            <ConfirmActionButton onClick={newDestroy} render={({ ready, onClick }) => (
                <ListCardAction onClick={onClick}>
                    <FontAwesomeIcon color={ready ? 'red' : null} icon="times" />
                </ListCardAction>
            )} />
        </ListCard>
        )
    }
}

ErrorLogListing = requestStateHandler({ destroy: 'record' })(ErrorLogListing);

const ClearButton = ({ load }) => {

    return (
        <ConfirmActionButton onClick={load} render={({ ready, onClick }) => (
            <Button 
                rounded 
                outlined
                color={ready ? "red" : "primary"} 
                className="shadow"
                onClick={onClick}
            >
                    <FontAwesomeIcon icon={'times'}></FontAwesomeIcon> Clear Logs
            </Button>
        )} />    
        
    )
}

const RefreshButton = ({ refresh }) => {

    return (
        <Button 
            rounded 
            outlined
            className="ml5 shadow"
            onClick={refresh}
        >
                <FontAwesomeIcon icon={'undo'}></FontAwesomeIcon> Refresh
        </Button>
    )
}

class ErrorLogsPage extends React.Component {

    render() {
        const { load, errorLogs: { records, page, more }, destroy, clear, refresh } = this.props;
        const firstId = (records && records[0]) ? records[0].id : null;

        return (
            <ActivityContainer>
                    <div className="col s12">
                        <h4 className="conversion-heading ml0 mb0">Error Logs</h4>
                        <InfiniteScroll 
                            load={page => load(firstId,page)} 
                            initialPage={page}
                            loadedPage={page || 0} 
                            fullyLoaded={records && !more} 
                            records={records}
                            noResultsComp={<SlightEmphasisIconNote text={"No errors to show"} variant="info" />}
                            render={({ maxIndex }) => {
                                return (
                                    <React.Fragment>
                                        {records && records.map((record,index) => {
                                            if(index < maxIndex) {
                                                return (
                                                    <ErrorLogListing 
                                                        key={record.id}
                                                        record={record}
                                                        destroy={destroy}
                                                    />
                                                )
                                            } else {
                                                return '';
                                            }
                                        })}
                                    </React.Fragment>
                                )
                            }}
                        />
                        <BottomButtons>
                            <LoaderActionButton 
                                component={ClearButton}
                                action={clear}
                            />
                            <RefreshButton refresh={refresh} />
                        </BottomButtons>
                    </div>
            </ActivityContainer>
        )
    }
}

const mapStateToProps = state => ({
    errorLogs: errorLogsSelector(state)
})

const mapDispatchToProps = dispatch => ({
    load: (firstId,page) => dispatch(loadErrorLogs(firstId,page)),
    destroy: id => dispatch(destroyErrorLog(id)),
    clear: () => dispatch(clearErrorLogs()),
    refresh: () => dispatch(refreshErrorLogs())
})

export default connect(mapStateToProps,mapDispatchToProps)(ErrorLogsPage);