import React from 'react';
import { connect } from 'react-redux';
import { allMainMatches } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import { deactivateBrandedApp, loadBrandedApps, markBrandedAppInitialized, updateBrandedApp } from 'redux/actions';
import { Loader } from 'components/LoadingHOC';
import { CenteredActivityContainer } from 'components/ActivityContainer';
import Card, { BasicCardHeader } from 'components/Card';
import { Link } from 'components/Routing';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import * as _ from 'lib/utilities';
import { FullInput, LoadingSubmitButton } from 'components/Form';
import TextInput from 'components/TextInput';
import Button, { AsyncSpinnerButton, ConfirmActionButton } from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Collapse from 'components/Collapse';

const transitionMap = {
    rules: [
        [allMainMatches,transitions.slideOut]
    ]
};


const BuildInfoSection = ({ buildInfo }) => {
    const [expanded,setExpanded] = React.useState(false);

    return (
        <React.Fragment>
            <div onClick={() => setExpanded(!expanded)}>
                Build Info Dump
                <FontAwesomeIcon icon={expanded ? 'angle-down' : 'angle-right'} className="tilde" />
            </div>
            <Collapse isOpen={expanded}>
                {Object.entries(buildInfo).map(([key,value]) => (
                    <div>
                        <b>{key}:</b> {value}
                    </div>
                ))}
            </Collapse>
        </React.Fragment>
    )
}

const BrandedAppsSuccess = ({ initialize, deactivate, update, responseData }) => {
    const { t } = useTranslation();

    return (
       <CenteredActivityContainer>
            {Object.entries(responseData).map(([id, buildInfo ]) => {
                const { foregroundIconUrl, ...dump } = buildInfo;
                
                return (
                    <Card className="pa20">
                        <BasicCardHeader>
                            {buildInfo.appName} ({buildInfo.inactive ? 'Inactive' : (buildInfo.initialized ? 'Initialized' : 'Not Initialized')})
                            <div className="tiny-text">{buildInfo.authorEmail} - {buildInfo.businessName}</div>
                        </BasicCardHeader>
                        <div className="text-center">
                            <div className="inline-block">
                                <img src={buildInfo.foregroundIconUrl} className="branded-app-icon" style={{ backgroundColor: `${buildInfo.brandColor} !important`}} alt="" />
                            </div>
                            <div className="inline-block ml10">
                                <img src={buildInfo.foregroundIconUrl} className="branded-app-icon circle" style={{ backgroundColor: `${buildInfo.brandColor} !important`}} alt="" />
                            </div>
                            <div>
                                <img src={buildInfo.logoUrl} alt="" style={{ maxWidth: '200px', maxHeight: '200px' }} />
                            </div>
                            <div>
                                <Link to={buildInfo.appUrl}>{buildInfo.appUrl}</Link>
                            </div>
                        </div>

                        {!buildInfo.flagship && (<FormikWrapper
                                initialValues={ { appleTeamId: buildInfo.appleTeamId || '', itcTeamId: buildInfo.itcTeamId } }
                                validationSchema={Yup.object().shape({ appleTeamId: Yup.string().required(), itcTeamId: Yup.string().required() })}
                                submit={values => update({ id, ...values })}
                                initialErrors={{}}
                                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                        return (
                                            <form className="pa20 valign-wrapper cntr" onSubmit={handleSubmit}>
                                                <div className="valign-wrapper">
                                                    <FullInput
                                                        className="flex-grow"
                                                        name="appleTeamId"
                                                        label={'Apple Team ID (of form 4XKH65HW32)'}
                                                        component={TextInput}
                                                        formikProps={rest}
                                                    />
                                                    <FullInput
                                                        className="flex-grow"
                                                        name="itcTeamId"
                                                        label={'ITC Team ID (from fastlane)'}
                                                        component={TextInput}
                                                        formikProps={rest}
                                                    />
                                                    <div className="ml15">
                                                        <LoadingSubmitButton noShadow icon="check" label={t("Save")} loadState={submitState} />
                                                    </div>
                                                </div>
                                            </form>
                                        )
                                }}
                        />)}

                        {!buildInfo.flagship && buildInfo.initialized && (<FormikWrapper
                                initialValues={ { playStoreUrl: buildInfo.playStoreUrl || '', appStoreUrl: buildInfo.appStoreUrl || ''} }
                                validationSchema={Yup.object().shape({ playStoreUrl: Yup.string().required(), appStoreUrl: Yup.string().required() })}
                                submit={values => update({ id, ...values })}
                                initialErrors={{}}
                                render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                        return (
                                            <form className="pa20" onSubmit={handleSubmit}>
                                                <div className="">
                                                    <FullInput
                                                        className="flex-grow"
                                                        name="playStoreUrl"
                                                        label={'Play Store URL'}
                                                        component={TextInput}
                                                        formikProps={rest}
                                                    />
                                                </div>
                                                <div className="">
                                                    <FullInput
                                                        className="flex-grow"
                                                        name="appStoreUrl"
                                                        label={'App Store URL'}
                                                        component={TextInput}
                                                        formikProps={rest}
                                                    />
                                                </div>
                                                <div className="mt15">
                                                    <LoadingSubmitButton noShadow icon="check" label={t("Save")} loadState={submitState} />
                                                </div>
                                            </form>
                                        )
                                }}
                        />)}

                        {!buildInfo.flagship && !_.isBlank(buildInfo.appleTeamId) && (<div className="text-center">
                            {!buildInfo.initialized && (<AsyncSpinnerButton
                                buttonProps={{
                                    noShadow: true,
                                    outlined: true,
                                    color: 'primary',
                                    label: `Mark Initialized`
                                }}
                                icon={'check'}
                                action={() => initialize(id)}
                            />)}
                            {!buildInfo.inactive && (<ConfirmActionButton
                                onClick={() => deactivate(id)}
                                render={({ onClick, ready }) => {
                                    if(ready) {
                                        return (
                                            <AsyncSpinnerButton
                                                buttonProps={{
                                                    noShadow: true,
                                                    outlined: true,
                                                    color: 'red',
                                                    label: `Deactivate`,
                                                    className: 'ml5'
                                                }}
                                                icon={'times'}
                                                action={onClick}
                                            />
                                        )
                                    }

                                    return (
                                        <Button color="grey" className="ml5" outlined onClick={onClick}><FontAwesomeIcon icon="times" /> Deactivate</Button>
                                    )

                                }}
                            />)}
                            
                        </div>)}

                        <div className="mt20">
                            <BuildInfoSection buildInfo={dump} />
                        </div>
                    </Card>
                )
            })}
       </CenteredActivityContainer>
    )
}

class ErrorLogPage extends React.Component {

    render() {
        const { scrollRef, setupTransitions, load, initialize, deactivate, update } = this.props;

        return (
            <SimpleNavPage
                scrollRef={scrollRef} 
                transitionMap={transitionMap} 
                setupTransitions={setupTransitions} 
                title='Branded Apps'
            >
                <Loader
                    successComponent={BrandedAppsSuccess}
                    type="page"
                    load={load}
                    preloaded={() => false}
                    initialize={initialize}
                    deactivate={deactivate}
                    update={update}
                />
            </SimpleNavPage>
        )
    }
}

const mapStateToProps = () => ({

})

export const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadBrandedApps()),
    initialize: id => dispatch(markBrandedAppInitialized(id)),
    deactivate: id => dispatch(deactivateBrandedApp(id)),
    update: (data) => dispatch(updateBrandedApp(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(ErrorLogPage))