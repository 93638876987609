import React from 'react';
import { connect } from 'react-redux';
import { SimpleNavPage, SimpleNavPageWithTabs } from 'components/Page';
import { switchClient } from 'redux/actions';
import { Redirect } from "components/Routing";
import { Loader } from 'components/LoadingHOC';

export const SwitchFinished = ({ afterPath }) => (<Redirect to={afterPath} />)

const ClientSwitcher = (props) => {
    const { scrollRef, setupTransitions, match: { params: { clientId, focusDate } }, transitionMap, title, switchClient, afterPath, tabs }  = props;
    const Comp = tabs ? SimpleNavPageWithTabs : SimpleNavPage;

    return (
        <Comp
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={title}
            tabs={tabs}
        >
            <Loader
                load={() => switchClient(clientId,focusDate)}
                preloaded={() => false}
                successComponent={SwitchFinished}
                afterPath={afterPath}
                type='page'
            />
        </Comp>
    )
}

export const mapDispatchToCliSwitchProps = dispatch => ({
    switchClient: (clientId,focusDate) => dispatch(switchClient(clientId,focusDate))
})

export default connect(null,mapDispatchToCliSwitchProps)(ClientSwitcher)