import { Checkbox, DefaultSelect, MultivalueCheckbox } from 'components/Form';
import { FullFormModal } from 'components/Modal';
import { modalPathFor } from 'config/paths';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { User } from 'lib/classes';
import * as _ from 'lib/utilities';
import { trainerRecordSelector } from 'redux/selectors';
import { connect } from 'react-redux';
import { Link } from 'components/Routing';
import { TooltippedLabel } from 'components/Tooltipped';
import { dismissTooltip, updateSettings } from 'redux/actions';
import { SETUP_NOTIFICATIONS_TIP } from 'config/tooltips';
import { SlightEmphasisIconNote } from 'components/Typography';

const stubs = Object.keys(User.NOTIFICATION_DEFAULTS);

const fieldLabels = (stub,t) => {
    switch(stub) {
        case 'workout': {
            return t('Workout reminder');
        }
        case 'eod': {
            return <TooltippedLabel text={t('End of day reminder')} tip={t('eod tip')} />
        }
        case 'fastStart': {
            return <TooltippedLabel text={t('Start fasting reminder')} tip={t('fasting remind tip')} />
        }
        case 'fastEnd': {
            return <TooltippedLabel text={t('End fasting reminder')} tip={t('fasting remind tip')} />
        }
        default: {
            return t('Log meal reminder', { meal: t(_.capitalize(stub)).toLowerCase() })
        }
    }
}

export const NotificationSettingsDescription = ({ user }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <table style={{ maxWidth: '200px'}}>
            <tbody>
                <tr style={{ border: 'none' }}>
                    <td className="text-left pb5 pt0 pl0 pr0 font-grey no-wrap">{t('Notification Settings')}</td>
                    <td className="text-right pb5 pt0 pl0 pr0 no-wrap">
                        <Link to={modalPathFor(notificationSettingsSuffix,pathname)} id="edit-notifications-btn" className="underline">{t('View')}{`/${t('Edit')}`}</Link>
                    </td>
                </tr>
                {user.notificationSettingsStrs(t).map(([label,value],i) => {
                    return (
                        <tr style={{ border: 'none' }} key={i}>
                            <td className="text-left pa0">{label}:</td>
                            <td className="text-right pa0 remind-value">{value}</td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

const ReminderField = ({ base, formikProps, t }) => {
    const isOn = formikProps.values[`${base}RemindOn`];

    return (
        <div className="inline-block pa15 text-left">
            <div className="font-grey no-wrap">{fieldLabels(base,t)}</div>
            <div className="valign-wrapper flex-end">
                <MultivalueCheckbox 
                    name={`${base}RemindOn`}
                    checkedVal={true}
                    uncheckedVal={false}
                    coreOnly
                    id={`${base}Remind-check`}
                    className="low-em"
                    {...formikProps}
                />
                <DefaultSelect
                    name={`${base}Remind`}
                    collection={User.workoutRemindCol(t)}
                    selectProps={{
                        disabled: !isOn
                    }}
                    {...formikProps}
                />
                <DefaultSelect
                    name={`${base}RemindPm`}
                    collection={User.workoutRemindPmCol(t)}
                    selectProps={{
                        disabled: !isOn
                    }}
                    {...formikProps}
                />
            </div>
        </div>
    )
}

export const notificationSettingsSuffix = '/notifications';

let NotificationSettingsModal = ({ user, submit, dismissTooltip }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(notificationSettingsSuffix,pathname);
    const firstView = !user.sawTooltip(SETUP_NOTIFICATIONS_TIP);
    const initVals = user.notificationSettingsValues(firstView);

    return (
        <FullFormModal
            fullWidth
            path={path} 
            scrollable 
            exact 
            icon={"bell"}
            title={t('Notification Settings')}
            formProps={{
                initialValues: initVals,
                submit,
                valuesFilter: values => {
                    const newVals = { ...values };
                    stubs.forEach(base => {
                        if(!newVals[`${base}RemindOn`]) {
                            newVals[`${base}Remind`] = '';
                        }
                        delete newVals[`${base}RemindOn`];
                    });
                    if(!newVals.passToDefaults) {
                        delete newVals.passToDefaults;
                    }
                    return newVals;
                },
                successCallback: () => dismissTooltip(SETUP_NOTIFICATIONS_TIP)
            }}
            render={({ modalProps, ...rest }) => {                

                return (
                    <div className="pa10">
                        <SlightEmphasisIconNote text={t('reminder only sent')} />
                        {stubs.map(base => {

                            return (
                                <ReminderField 
                                    key={base}
                                    base={base}
                                    t={t}
                                    formikProps={rest}
                                />
                            )
                        })}
                        <div className="pa15">
                            <Checkbox 
                                inputProps={{ 
                                    name: 'remindToPlanMeals', 
                                    onChange: rest.handleChange, 
                                    onBlur: rest.handleBlur, 
                                    value: true,
                                    checked: rest.values.remindToPlanMeals
                                } } 
                                label={t('weekly email remind')} 
                                filled
                                id="remind-to-plan-meals-check"
                                checkClassName="low-em"
                                className="no-wrap"
                            />
                        </div>
                    </div>
                )
            }}
        />
    )
}

const mapStateToProps = state => ({
    trainer: trainerRecordSelector(state)
})

const mapDispatchToProps = dispatch => ({
    dismissTooltip: tipName => dispatch(dismissTooltip(tipName)),
    submit: (data) => dispatch(updateSettings(data)),
})

NotificationSettingsModal = connect(mapStateToProps,mapDispatchToProps)(NotificationSettingsModal)

export { NotificationSettingsModal }