import React from 'react';
import classnames from 'classnames';

const PlaceholderImage = ({ width, height, color, className, ...rest }) => {

    const classNames = classnames({ [className]: className, [color]: color})

    return (
        <svg width={width} viewBox={`0 0 ${width} ${height}`} className={classNames} {...rest} />
    )
}

export default PlaceholderImage;