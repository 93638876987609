import React from 'react';

export default function CenteredContainer(props) {
    return (
        <div className="centered-container">
            <div className="center-child">
                {props.children}
            </div>
        </div>
    )
}