import React from 'react';
import * as _ from 'lib/utilities';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const OffPlanMealPlanTable = ({ mtMap, offPlanMealMap, setOffPlanType, t }) => {
    const mealTypeIds = Object.keys(offPlanMealMap);
    const dates = Object.keys(offPlanMealMap[mealTypeIds[0]]);

    const handleCellClick = (mealTypeId, date) => {
        const currentType = offPlanMealMap[mealTypeId][date];
        const newType = currentType === 2 ? 0 : 2;
        setOffPlanType(mealTypeId, date, newType);
    };

  return (
    <table className="meal-plan-table">
      <thead>
        <tr>
            <th></th>
          {dates.map(date => <th key={date}>{moment(date).format('ddd')}</th>)}
        </tr>
      </thead>
      <tbody>
        {mealTypeIds.map(mealTypeId => (
          <tr key={mealTypeId}>
            <td className="mtitle">{t(_.capitalize(mtMap[mealTypeId].category))}</td>
            {dates.map(date => (
              <td
                key={date}
                className={offPlanMealMap[mealTypeId][date] === 2 ? 'selected' : ''}
                onClick={() => handleCellClick(mealTypeId, date)}
              >
                {offPlanMealMap[mealTypeId][date] === 2 ? <FontAwesomeIcon icon="check" className="success-color" /> : ''}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default OffPlanMealPlanTable;
