import React, { useState } from 'react';
import RouteModal, { ModalHeader, ModalFooter, ScrollableFormModal, FullFormModal } from 'components/Modal';
import { exerciseInfoModalMatchFor, exerciseSettingsModalMatchFor, plateCalculatorModalMatchFor, exerciseInfoModalPathFor, editExerciseModalMatchFor, modalPathFor, weightCalcModalPostfix } from 'config/paths';
import { connect } from 'react-redux';
import { getExerciseSelector, getComprehensiveWodSelector, getExerciseSettingSelector, userRecordSelector } from 'redux/selectors';
import { Redirect } from "components/Routing";
import Tabs from 'components/Tabs';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { ExerciseVideo, ExerciseFullVideo } from 'partials/ExerciseImage';
import * as _ from 'lib/utilities';
import * as Yup from 'yup';
import FormikWrapper from 'components/FormikWrapper';
import { CollapsibleFormSection, formikInputWrapperProps, ModalSubmitLC, DefaultSelect, ModalForm, formikInputProps, InnerFormContainer, InputWithErrors, MultivalueCheckbox, FullInput } from 'components/Form';
import TextInput, { TextArea } from 'components/TextInput';
import loadingContainer, { LoadingContainerParent } from 'components/LoadingHOC';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { loadStrengthTest, submitStrengthTest, updateExerciseSettings, updateExercise, createCustomExercise, insertNewExerciseToSearchTop, submitFormStrengthTest } from 'redux/actions';
import { WorkoutInput } from 'partials/WorkoutInputs';
import { IconNote } from 'components/Typography';
import { ExerciseSetting, Exercise } from 'lib/classes';
import { ExtraSmallNumberInput, NumericSelectInput } from 'components/TextInput';
import classnames from 'classnames';
import PlateCalculator from 'lib/plate-calculator';
import { IconTip } from 'components/Tooltipped';
import Dropdown from 'components/Dropdown';
import { Link, useLocation } from 'react-router-dom';
import { Exercisable } from 'lib/exercisable';
import { dateFormat } from 'config/settings';
import moment from 'moment';
import { VideoUploadFields } from './Utilities';
import MuxUploadForm from './MuxUploadForm';

export const ExerciseInfoMenu = ({ exercise, basePath }) => {
    const { t } = useTranslation();

    return (
        <Dropdown 
            contentComp='ul'
            options={{constrainWidth: false, alignment: 'right', coverTrigger: false}}
            triggerRender={({ ref, target }) => {
                return (
                        <div className="wol-right-icon" ref={ref} data-target={target}>
                            <FontAwesomeIcon icon="ellipsis-h" />
                        </div>
                )
            }}
            contentRender={({ recalcDims }) => {
                return (
                    <React.Fragment>
                        <li>
                            <Link to={exerciseInfoModalPathFor(basePath,exercise.id)}>
                                <FontAwesomeIcon icon="info-circle" /> {t("Exercise Info")}
                            </Link>
                        </li>
                    </React.Fragment>
                )
            }}
        />
    )
}

const ExerciseInfoFooter = () => {
    const { t } = useTranslation();
    return (
        <ModalFooter defaults>
            <Button rounded color="primary" className="modal-close">{t('Ok')}</Button>
        </ModalFooter>
    )
}

const ExerciseInfoHeader = ({ exercise, tabs }) => {

    const headProps = tabs ? { defaultFont: true } : { defaults: true };

    return (
        <ModalHeader {...headProps} exitButton>
            <b>{exercise.name}</b>
            {tabs}
        </ModalHeader>
    )
}

const ExerciseInstructions = ({ exercise }) => {
    const { t } = useTranslation();

    if(_.isBlank(exercise.instructions)) {
        if(exercise.hasImage() || exercise.hasFullVideo()) {
            return '';
        } else {
            return (<div className="pa20 text-center" id={`no-more-exercise-info`}>{t("No additional information available for this exercise")}</div>);
        }
    } else {
        return (
            <div className="text-center">
                <div className="left-align-block pa15">
                    <div className="exercise-instructions font-grey">
                        {exercise.instructionEntries().map((entry,index) => (<p key={index}>{entry}</p>))}
                    </div>
                </div>
            </div>
        )
    }
}

const ExerciseFullVideoTab = ({ exercise }) => {
    return (
        <div className="ex-vid-container pt5 pb5">
            <ExerciseFullVideo exercise={exercise} autoplay />
        </div>
    )
}

const ExerciseOverviewTab = ({ exercise }) => {
    return (
        <div className="ex-vid-container pt5 pb5">
            <ExerciseVideo exercise={exercise} autoplay />
        </div>
    )
}

const TabbedExerciseInfo = ({ exercise, addContentInit }) => {
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Tabs addContentInit={addContentInit} 
                tabs={[
                        {title: t("Overview"), content: (<ExerciseOverviewTab exercise={exercise} />)},
                        {title: t("Full Video"), content: (<ExerciseFullVideoTab exercise={exercise} />)}
                    ]} 
                render={({tabs,tabContents}) => {
                return (
                    <div id="exercise-info-modal-content">
                        <ExerciseInfoHeader exercise={exercise} tabs={tabs} />
                        {tabContents}
                        <ExerciseInstructions exercise={exercise} />
                        <ExerciseInfoFooter />
                    </div>
                )
            }} />
        </React.Fragment>
    )
}

const ExerciseInfoContent = ({ exercise, basePath, addContentInit }) => {

    if(exercise) {
        if(exercise.hasImage() && exercise.hasFullVideo()) {
            return (<TabbedExerciseInfo exercise={exercise} addContentInit={addContentInit} /> )
        } else {
            return (
                <div id="exercise-info-modal-content">
                    <ExerciseInfoHeader exercise={exercise} />
                    {exercise.hasImage() && (<ExerciseOverviewTab exercise={exercise} />)}
                    {exercise.hasFullVideo() && (<ExerciseFullVideoTab exercise={exercise} />)}
                    <ExerciseInstructions exercise={exercise} />
                    <ExerciseInfoFooter />
                </div>
            )
        }
    } else {
        return (<Redirect to={basePath} />)
    }
}

const mapStateToExerciseInfoProps = (state,props) => {
    const exerciseSelector = getExerciseSelector(props.id,props.date);
    return {
        exercise: exerciseSelector(state)
    }
}

const ConnectedExerciseInfo = connect(mapStateToExerciseInfoProps)(ExerciseInfoContent);

export const ExerciseInfoModal = ({ basePath, baseMatch }) => {

    return (
        <RouteModal fullWidth path={exerciseInfoModalMatchFor(baseMatch)} exact render={(modalProps) => {
            const { match: { params: { id, date } } } = modalProps;
            return (
                <ConnectedExerciseInfo id={id} date={date} basePath={basePath} addContentInit={modalProps.addContentInit} />
            )
        }} />
    )
}

const normalizeWeight = values => {
    const { kgWeight, ...rest } = values;
    if(kgWeight) {
        rest.weight = kgWeight*2.2;
        return rest;
    } else {
        return values;
    }

}

const SubmitWeightCalcDefault = ({ close, type }) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Button key="cancel-btn" rounded color="primary" outlined onClick={close} ><FontAwesomeIcon icon="ban" /> {t("Cancel")}</Button> 
            <Button key="log-btn" tag='button' type="submit" rounded color="primary" id="submit-strtst-btn" className="ml5"><FontAwesomeIcon icon="calculator" /> {type === 'report' ? t('Save') : t("Calculate")}</Button>
        </React.Fragment>
    )
}

const SubmitWeightCalcLC = loadingContainer({
    "DEFAULT": SubmitWeightCalcDefault,
    "SUCCESS": SubmitWeightCalcDefault
})

const ReportMaxFields = ({ exercise, weightKey, ...formikProps}) => {
    const inputProps = _.pick(formikProps,formikInputWrapperProps);
    const { t } = useTranslation();
    const { values } = formikProps;

    return (
        <div className="wo-wt-form-wrapper">
            <div className="pa10 text-center">
                <div className="wo-wt-ex-title lh1 no-wrap position-relative" style={{ fontSize: '20px'}}>
                    {t('Enter most recent max')} 
                    <IconTip size='1x' icon="question-circle" className="font-grey" containerClassName="offside-tip" msg={t('rep max tip')}/>
                </div>
                <div className="valign-wrapper cntr respo mart40 flex-end">
                    <WorkoutInput 
                        exercise={exercise}
                        field='reps'
                        inProps={{id:'strtst-reps-field'}}
                        {...inputProps}
                    />
                    <WorkoutInput 
                        exercise={exercise}
                        field={weightKey}
                        inProps={{id:'strtst-wt-field'}}
                        {...inputProps}
                    />
                </div>
                {values.trainingMaxPercent !== undefined && (<CollapsibleFormSection header={t('Advanced')}>
                    <div className="valign-wrapper cntr">
                        <WorkoutInput 
                            exercise={exercise}
                            field='trainingMaxPercent'
                            inProps={{id:'strtst-tmax-field'}}
                            {...inputProps}
                        />
                    </div>
                </CollapsibleFormSection>)}
            </div>
        </div>
    )
}

const WeightCalculatorForm = ({ user, exercise, date, submit, submitFormTest, render, responseData, callback, clientId }) => {

    const weightKey = user.isMetric(exercise) ? 'kgWeight' : 'weight';
    const { trainingMaxPercent, weight, ...otherFields } = responseData;
    const [calcStatus,setCalcStatus] = useState('initial');
    if(!_.isBlank(trainingMaxPercent)) {
        otherFields.trainingMaxPercent = trainingMaxPercent;
    }

    if(_.isBlank(otherFields.reps)) {
        otherFields.reps = 5;
    }

    if(weightKey === 'kgWeight') {
        otherFields.kgWeight = _.isBlank(weight) ? '' : _.roundToF(weight/2.2,0.001);
    } else {
        otherFields.weight = weight;
    }

    if(!_.isBlank(clientId)) {
        otherFields.clientId = clientId;
    }

    return (
        <FormikWrapper
            initialValues={ _.parseObjForForm({ ...otherFields, date, exerciseId: exercise.id }) }
            valuesFilter={normalizeWeight}
            submit={_.isBlank(clientId) ? submit : submitFormTest}
            successCallback={({ status,formikBag,data,values }) => (callback ? callback(status,formikBag,data,setCalcStatus,values) : setCalcStatus('success'))}
            validationSchema={Yup.object().shape({
                reps: Yup.number().moreThan(0).integer().max(16).required(),
                [weightKey]:  Yup.number().moreThan(0).required(),
                trainingMaxPercent: Yup.number().min(80).max(100)
            })}
            initialErrors={{}}
            calcStatus={calcStatus}
            weightKey={weightKey}
            render={(props) => render({ ...props, exercise })}
        >

        </FormikWrapper>
    )
}

const WeightCalcLC = loadingContainer({
    "SUCCESS": WeightCalculatorForm
},{ type: 'padded'});

let WeightCalculatorLoader = ({ exerciseId, date, clientId, loadStrengthTest, component: Component, ...rest }) => {

    return (
        <LoadingContainerParent 
            component={Component}
            load={loadStrengthTest.bind(null,{ exerciseId, date, clientId })}
            preloaded={() => false}
            date={date}
            clientId={clientId}
            {...rest}
        />
    )
}

const mapStateToWeightCalcProps = (state,props) => {
    const exerciseSelector = getExerciseSelector(props.exerciseId,props.date);
    return {
        user: userRecordSelector(state),
        exercise: exerciseSelector(state)
    }
}

const mapDispatchToWeightCalcProps = (dispatch) => ({
    loadStrengthTest: (params) => dispatch(loadStrengthTest(params)),
    submit: (params) => dispatch(submitStrengthTest(params)),
    submitFormTest: (params) => dispatch(submitFormStrengthTest(params)),
})

WeightCalculatorLoader = connect(mapStateToWeightCalcProps,mapDispatchToWeightCalcProps)(WeightCalculatorLoader)

const WeightCalculatorContent = ({ exerciseId, date, renderHeader, renderFooter, renderScrollable, close, callback, showVideo, clientId, type }) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            {renderHeader()}
            <WeightCalculatorLoader 
                exerciseId={exerciseId} 
                clientId={clientId}
                date={date} 
                component={WeightCalcLC} 
                callback={callback}
                render={({ exercise, submitState, handleSubmit, calcStatus, weightKey, ...formikProps}) => {
                
                    if(calcStatus === 'failed') {
                        return (
                            <React.Fragment>
                                {renderScrollable({ children: (
                                    <div className="pa20 text-center">
                                        <div className="left-align-block" id="strength-calc-failed">
                                            <IconNote variant="warning" text={t("strength calc failed")} />
                                        </div>
                                    </div>
                                )})}
                                {renderFooter({ children: (
                                    <Button rounded className="modal-close">{t("Ok")}</Button>
                                )})}
                            </React.Fragment>
                        )
                    } else {

                        if(calcStatus === 'success') {
                            close();
                        }

                        return (
                            <form onSubmit={handleSubmit}>
                                {renderScrollable({ children: (
                                    <React.Fragment>
                                        {showVideo && (<div className="ex-vid-container">
                                            <ExerciseVideo exercise={exercise} autoplay fallbackToYT />
                                        </div>)}
                                        <div className="wo-wt-ex-tab-wrapper">
                                            <div className="wo-wt-ex-tab">{exercise.name}</div>
                                        </div>
                                        <div className="clearfix"></div>
                                        <ReportMaxFields exercise={exercise} {...formikProps} weightKey={weightKey} />
                                    </React.Fragment>
                                )})}
                                {renderFooter({ children: (
                                    <SubmitWeightCalcLC close={close} loadState={submitState} type={type} />
                                )})}
                            </form>
                        )
                    }
            }} />
        </React.Fragment>
    )
}

export const WeightCalculatorModal = ({ callback, showVideo, date, clientId, type }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();

    return (
        <ScrollableFormModal 
            fullWidth 
            path={modalPathFor(weightCalcModalPostfix,pathname)} 
            scrollable 
            exact 
            icon="calculator"
            title={type === 'report' ? t('Report a Max') : t('Weight Calculator')}
            render={(modalProps) => {
                const { match: { params: { id } } } = modalProps;
                return (
                    <WeightCalculatorContent 
                        {...modalProps} 
                        showVideo={showVideo}
                        exerciseId={id} 
                        date={date || moment().format(dateFormat)} 
                        callback={callback} 
                        clientId={clientId}
                        type={type}
                    />
                )
        }} />
    )
}

class ExerciseSettingsCore extends React.Component {

    constructor(props) {
        super(props);
        const { user, exercise, exerciseSetting, date } = this.props;
        const defaultUnits = user.defaultExerciseSettingUnits(exerciseSetting,exercise.equipmentType);
        const defMincrementType = user.defaultMincrementType(exerciseSetting);
        const defaultUnitMincrement = user.defaultUnitMincrement(exerciseSetting,exercise.equipmentType,defaultUnits);
        this.initVals = { 
            date, 
            exerciseId: exercise.id, 
            unitMincrement: defaultUnitMincrement, 
            units: defaultUnits, 
            mincrementType: defMincrementType, 
            notes: (exerciseSetting ? exerciseSetting.notes : '') 
        };
        this.state = { placeholder: user.mincrementPlaceholder(defaultUnits, exercise.equipmentType, defMincrementType) };
    }

    render() {
        const { exercise, close, submit, t } = this.props;

        return (
            <React.Fragment>
                <ModalHeader defaults exitButton noShadow >
                    <span className="abbr-text"><FontAwesomeIcon icon="cog" /> <b>{exercise.name} {t('Settings').toLowerCase()}</b></span>
                </ModalHeader>
                <FormikWrapper
                    initialValues={ this.initVals }
                    submit={submit}
                    valuesFilter={this.convertValues}
                    validationSchema={Yup.object().shape({
                        unitMincrement: Yup.number().moreThan(0)
                    })}
                    initialErrors={{}}
                    render={({ submitState, handleChange, handleSubmit, handleBlur, values, setFieldValue, errors, touched }) => {

                        return (
                            <form onSubmit={handleSubmit} >
                                <div className="pa10 text-left">
                                    <div className="font-grey">{t("Notes")}</div>
                                    <TextArea name="notes" value={values.notes} onChange={handleChange} onBlur={handleBlur} />
                                </div>

                                {exercise.isWeighted() && (<div className="pa10 text-left">
                                    <div className="font-grey">{t("Round weight to nearest")}</div>
                                    <div className="no-wrap">
                                        <ExtraSmallNumberInput 
                                            className="inline ma0"
                                            inputProps={{
                                                placeholder: this.state.placeholder,
                                                name: 'unitMincrement',
                                                onChange: handleChange,
                                                onBlur: handleBlur,
                                                value: values.unitMincrement,
                                                className: classnames({ invalid: (errors.unitMincrement && touched.unitMincrement)})
                                            }}
                                        />
                                        <NumericSelectInput 
                                            browserDefault 
                                            className="inline select-signup-medium ml5"
                                            id="ex-set-units-select"
                                            name='units'
                                            collection={ExerciseSetting.unitsForForm(exercise,t)}
                                            setFieldValue={(field,value) => this.setFieldHandler(setFieldValue,values,field,value)}
                                            onBlur={handleBlur}
                                            value={values.units}
                                        />
                                        <NumericSelectInput 
                                            browserDefault 
                                            className="inline small-inline default-font ml5"
                                            id="mincrement-type-select"
                                            name='mincrementType'
                                            collection={ExerciseSetting.overrideTypeForForm(exercise,t)}
                                            setFieldValue={(field,value) => this.setFieldHandler(setFieldValue,values,field,value)}
                                            onBlur={handleBlur}
                                            value={values.mincrementType}
                                            style={{ width: 'fit-content', maxWidth: '175px' }}
                                        />
                                    </div>
                                </div>)}
                                <ModalFooter defaults noShadow>
                                    <ModalSubmitLC close={close} loadState={submitState} id="submit-ex-setting-btn" />
                                </ModalFooter>
                            </form>
                        )
                    }}
                />
            </React.Fragment>
        )
    }

    setFieldHandler(setFieldValue,curValues,field,value) {
        setFieldValue(field,value);
        const units = field === 'units' ? value : curValues.units;
        const mincrementType = field === 'mincrementType' ? value : curValues.mincrementType;
        this.refreshPlaceholder(units,mincrementType);
    }

    refreshPlaceholder(units,mincrementType) {
        const {user, exercise } = this.props;
        this.setState({placeholder: user.mincrementPlaceholder(units,exercise.equipmentType,mincrementType) })
    }

    convertValues(values) {
        const { unitMincrement, ...rest } = values;
        if(rest.units === 1) {
            rest.mincrement = unitMincrement*2.2;
        } else {
            rest.mincrement = unitMincrement;
        }
        return rest;
    }
}

const ExerciseSettingsContent = ({ user, exercise, exerciseSetting, basePath, close, date, submit }) => {
    const { t } = useTranslation();
    if(exercise) {
        return (
            <ExerciseSettingsCore 
                user={user} 
                exercise={exercise} 
                exerciseSetting={exerciseSetting} 
                close={close}
                date={date}
                submit={submit}
                t={t}
            />
        )
    } else {
        return (<Redirect to={basePath} />)
    }
}

const mapStateToSettingsProps = (state,props) => {
    const settingSelector = getExerciseSettingSelector(props.id);
    const exerciseSelector = getExerciseSelector(props.id,props.date);
    return {
        exerciseSetting: settingSelector(state),
        exercise: exerciseSelector(state),
        user: userRecordSelector(state)
    }
}

const mapDispatchToSettingsProps = dispatch => ({
    submit: (params) => dispatch(updateExerciseSettings(params))
})

const ConnectedSettingsContent = connect(mapStateToSettingsProps,mapDispatchToSettingsProps)(ExerciseSettingsContent);

export const ExerciseSettingsModal = ({ basePath, baseMatch }) => {
    return (
        <RouteModal fullWidth path={exerciseSettingsModalMatchFor(baseMatch)} exact render={(modalProps) => {
            const { match: { params: { id, date } } } = modalProps;
            return (
                <ConnectedSettingsContent {...modalProps} id={id} date={date} basePath={basePath} />
            )
        }} />
    )
}

const PlateCalculatorContent = ({ workout, id }) => {

    const { t } = useTranslation();
    const spec = workout.getSpec(id);
    const [selected,setSelected] = useState(spec.defaultPlateCalcSet());
    const plateCalculator = new PlateCalculator(spec,t);
    const results = plateCalculator.results();
    const keys = Object.keys(results);
    const selectCol = keys.map((label,index) => ({ text: label, value: index}))
    const showSelect = keys.length > 1;
    const selResult = results[keys[selected]];
    const lastEntry = selResult ? Object.keys(selResult).length-1 : 0;

    return (
        <React.Fragment>
            <ModalHeader defaults exitButton noShadow >
                <span className="abbr-text"><FontAwesomeIcon icon="dot-circle" /> <b>{t('Plate Calculator')}</b></span>
            </ModalHeader>
            {showSelect && (<div className="pa20 text-center slight-bottom-border">
                <NumericSelectInput 
                    browserDefault 
                    className="inline small-inline default-font ml5"
                    name='selectedSet'
                    collection={selectCol}
                    setFieldValue={(field,value) => setSelected(value)}
                    value={selected}
                    style={{ width: 'fit-content', maxWidth: '175px' }}
                />
            </div>)}
            {selResult && Object.entries(selResult).map(([type,num],index) => {
                const isMsg = ['noPlates','residual'].includes(type);
                const topClassNames = classnames({'plate-calc-row': !isMsg, 'text-center pa20': isMsg, 'slight-bottom-border': index !== lastEntry });

                if(type === 'bar') {
                    return (
                        <div key={index} className={topClassNames}>
                            <div className="plate-calc-cell">
                                <span className="med-quarter">{t("Bar")}</span>
                            </div>
                            <div className="plate-calc-cell">
                                <span className="med-quarter">=</span>
                            </div>
                            <div className="plate-calc-cell">
                                <span className="med-quarter">{num}</span>
                            </div>
                        </div>
                    )
                } else if(type === 'noPlates') {
                    return (
                        <div key={index} className={topClassNames} id="plate-calc-no-plates">
                            <div className="left-align-block" style={ { maxWidth: '500px'} }>
                                <IconNote variant="info" text={t("plate calc no plates message")} />
                            </div>
                        </div>
                    )
                } else if(type === 'residual') {
                    return (
                        <div key={index} className={topClassNames} id="plate-calc-residual">
                            <div className="left-align-block" style={ { maxWidth: '500px'} }>
                                <IconNote variant="warning" text={t("plate calc residual message", { weight: num })} />
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div key={index} className={topClassNames}>
                            <div className="plate-calc-cell">
                                <span className="med-quarter">{num}</span>
                                <div>{t('per side')}</div>
                            </div>
                            <div className="plate-calc-cell">
                                <span className="med-quarter"><FontAwesomeIcon icon="times" /></span>
                            </div>
                            <div className="plate-calc-cell">
                                <span className="med-quarter">{type}</span>
                                <div>{t('plate(s)')}</div>
                            </div>
                        </div>
                    )
                }
            })}
            {!selResult && (<div className="text-center pa20">
                <div className="left-align-block" style={ { maxWidth: '500px'} } id="plate-calc-no-weight">
                    <IconNote variant="info" text={t("plate calc no weight message")} />
                </div>
            </div>)}
            <ModalFooter defaults noShadow>
                <Button className="modal-close" rounded color="primary" id="close-plate-calc-btn"><FontAwesomeIcon icon="check" /> {t("Ok")}</Button>
            </ModalFooter>
        </React.Fragment>
    )
}

const mapStateToPlateCalcProps = (state,props) => {
    const workoutSelector = getComprehensiveWodSelector(props.date);
    return {
        workout: workoutSelector(state)
    }
}

const ConnectedPlateCalculatorContent = connect(mapStateToPlateCalcProps)(PlateCalculatorContent);

export const PlateCalculatorModal = ({ basePath, baseMatch }) => {
    return (
        <RouteModal fullWidth limitWidth path={plateCalculatorModalMatchFor(baseMatch)} exact render={(modalProps) => {
            const { match: { params: { id, date } } } = modalProps;
            return (
                <ConnectedPlateCalculatorContent {...modalProps} id={id} date={date} basePath={basePath} />
            )
        }} />
    )
}

export const newActivityTypeModalSuffix = '/new_activity_type';

let NewActivityTypeModal = ({ submit, insertToTop }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(newActivityTypeModalSuffix,pathname);
    const resolvedEx = new Exercise({ equipmentType: 102, repType: 4, cardio: true });

    return (
        <FullFormModal 
            path={path} 
            scrollable 
            fullWidth={false}
            exact 
            title={t('Activity Type')}
            icon="plus"
            formProps={ () => {       
                
                return ({
                    initialValues: { ...resolvedEx.formValues(), owned: true },
                    submit,
                    validationSchema: Yup.object().shape({
                        name: Yup.string().required()
                    }),
                    initialErrors: {},
                    successCallback: ({ formikBag, data: { exercises }, values }) => {
                        insertToTop({ exerciseId: Object.keys(exercises)[0] })
                    }
                })
            }}
            footerProps={{ saveLabel: t('Save'), saveIcon: 'check', noCancel: true }}
            render={({ modalProps, ...formikProps }) => {
                return (
                    <div className="pa20">
                        <div className="valign-wrapper cntr">
                            <FullInput
                                label={t("Name")}
                                className="flex-grow"
                                name="name"
                                component={TextInput}
                                maxLength={120}                                
                                formikProps={formikProps}
                            />
                        </div>
                        <div>
                            <MultivalueCheckbox 
                                name="repType"
                                id="has-dist-check"
                                checkedVal={2}
                                uncheckedVal={4}
                                values={formikProps.values}
                                setFieldValue={formikProps.setFieldValue}
                                label={t('has distance q')} 
                                filled 
                                className="no-wrap"
                            />
                        </div>
                    </div>                
                )
            }}
        />
    )
}

const mapDispatchToProps = (dispatch) => ({
    submit: data => dispatch(createCustomExercise(data)),
    insertToTop: data => dispatch(insertNewExerciseToSearchTop(data))
})

NewActivityTypeModal = connect(null,mapDispatchToProps)(NewActivityTypeModal);

export { NewActivityTypeModal }

const EditExerciseContent = ({ exercise, submit, create, renderHeader, renderFooter, renderScrollable, createSuccessCallback }) => {
    const { t } = useTranslation();
    const resolvedEx = exercise || new Exercise({ equipmentType: 8, repType: 0 });
    const disabled = !!exercise;
    const isCreate = _.isBlank(resolvedEx.id);
    
    return (
        <React.Fragment>
            {renderHeader({ children: (
                <React.Fragment>
                    <FontAwesomeIcon icon="cog" /> {exercise ? t('Edit Exercise') : t('New Exercise')}
                </React.Fragment>
            )})}
            <MuxUploadForm
                recordType={'Exercise'}
                initialValues={ { ...resolvedEx.formValues(), owned: true } }
                submit={values => {
                    if(isCreate) {
                        return create({ ...values })
                    } else {
                        return submit(values);
                    }
                }}
                validationSchema={Yup.object().shape({
                    name: Yup.string().required(),
                    videoUrl: Yup.string().matches(/^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/, { message: t('invalid YouTube URL'), excludeEmptyString: true })
                })}
                successCallback={isCreate ? createSuccessCallback : null}
                initialErrors={{}}
                render={({ handleSubmit, submitState, videoFileRef, ...rest }) => {
                    const inputProps = _.pick(rest,formikInputProps);
                    return (
                        <ModalForm centered onSubmit={handleSubmit}>
                            {renderScrollable({ children: (
                                <InnerFormContainer className="pa10" style={{width: '100%'}}>
                                    <div className="display-flex">
                                        <InputWithErrors 
                                            label={t("Name")}
                                            className="inline flex-grow"
                                            name="name"
                                            id="nameField"
                                            component={TextInput}
                                            maxLength={120}
                                            {...inputProps}
                                        />
                                        {!rest.values.videoOnly && Number(rest.values.repType) !== 4 && (<DefaultSelect 
                                            className="ml5"
                                            name="equipmentType" 
                                            label={t('Equipment type')}
                                            collection={Exercisable.equipmentTypesCol(t)} 
                                            selectProps={{ disabled }}
                                            {...rest} 
                                        />)}
                                    </div>
                                    <VideoUploadFields record={exercise} inputProps={inputProps} t={t} videoFileRef={videoFileRef} />
                                    {!exercise && (<div className="display-flex mb20">
                                        <MultivalueCheckbox 
                                            name="videoOnly"
                                            id="video-only-check"
                                            checkedVal={true}
                                            uncheckedVal={false}
                                            values={rest.values}
                                            setFieldValue={rest.setFieldValue}
                                            label={t('video only check')} 
                                            filled 
                                            className="no-wrap"
                                            tooltip={t('video only tip')}
                                        />
                                    </div>)}
                                    {!rest.values.videoOnly && (
                                        <React.Fragment>
                                            <div className="display-flex">
                                                {Number(rest.values.repType) !== 4 && (<DefaultSelect 
                                                    className="flex-grow"
                                                    selectProps={ {multiple: true, className: 'auto-height', disabled: !!exercise}}
                                                    name="simpleTypes" 
                                                    label={t('Exercise types')}
                                                    collection={Exercisable.simpleTypesCol(t)} 
                                                    {...rest} 
                                                    handleChange={rest.handleArrayChange}
                                                />)}
                                                <DefaultSelect 
                                                    className="ml5"
                                                    name="repType" 
                                                    label={t('Logging type')}
                                                    selectProps={{ disabled }}
                                                    collection={Exercisable.repTypesCol(t)} 
                                                    {...rest}
                                                />
                                            </div>
                                            <div className="text-left mt10">
                                                <div className="font-grey">{t("Description")}</div>
                                                <TextArea 
                                                    name="instructions" 
                                                    value={rest.values.instructions} 
                                                    onChange={rest.handleChange} 
                                                    onBlur={rest.handleBlur} 
                                                    stopModalDrag
                                                />
                                            </div>
                                        </React.Fragment>
                                    )}
                                </InnerFormContainer>
                            )})}
                            {renderFooter({ loadState: submitState, saveIcon:'check', saveLabel: t('Save') })}
                        </ModalForm>
                    )
                }}
            />
        </React.Fragment>
    )
}

const mapStateToEditExerciseProps = (state,props) => {
    const exerciseSelector = getExerciseSelector(props.id);
    return {
        exercise: exerciseSelector(state),
        user: userRecordSelector(state)
    }
}

const mapDispatchToEditExerciseProps = dispatch => ({
    submit: (params) => dispatch(updateExercise(params)),
    create: (params) => dispatch(createCustomExercise(params))
})

const ConnectedEditExerciseContent = connect(mapStateToEditExerciseProps,mapDispatchToEditExerciseProps)(EditExerciseContent);

export const EditExerciseModal = ({ basePath, createSuccessCallback }) => {
    return (
        <ScrollableFormModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={editExerciseModalMatchFor(basePath)} 
            exact 
            render={({ renderFooter, renderScrollable, renderHeader, match: { params: { id }} }) => (
                <ConnectedEditExerciseContent 
                    renderFooter={renderFooter} 
                    renderScrollable={renderScrollable} 
                    renderHeader={renderHeader} 
                    id={id} 
                    createSuccessCallback={createSuccessCallback}
                />
            )} 
        />
    )
}