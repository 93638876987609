import React from 'react';
import { connect } from 'react-redux';
import { trainerMainMatches, clientBasicProfileMatch, clientMealPlanSettingsPath, clientMealPlanSettingsMatch, assignClientRoutinePathFor, assignClientRoutineMatch, clientDetailsPath, standaloneMealPlanMatch, editRoutineShortcutPathFor, editRoutineStandaloneMatch, editRoutineShortcutMatch, standaloneWorkoutPlanPathFor, standaloneWorkoutPlanMatch, workoutPreviewShortcutPathFor, workoutPreviewShortcutMatch, workoutPreviewMatch, editClientModalPathFor, newNoteModalPathFor, editNoteModalPathFor, clientProgressShortcutPathFor, clientProgressShortcutMatch, clientMacroAnalyticsShortcutPathFor, clientMacroAnalyticsShortcutMatch, macroAnalyticsPath, standaloneMealPlanPathFor, viewLogsPath, viewLogsShortcutMatch, viewLogsShortcutPathFor, switchCliAccountPathFor, trainerConversionMatch, assignClientHabitsMatch, assignClientHabitsPathFor, clientBasicProfilePath, modalPathFor, clientBmShortcutPathFor, bodyMeasurementsPath, clientBmShortcutMatch, viewChatPath, viewChatMatch, switchForGenerateMatch, generateNewWeekMatch, clientMealPlansMatch, clientMealPlansPath, assignFormsToClientsPath, assignFormsToClientsMatch, viewClientAssignedFormMatch, clientProgressPicsShortcutPathFor, progressPicsPath, clientProgressPicsShortcutMatch, progressMatch } from 'config/paths';
import * as transitions from 'assets/transitions';
import { SimpleNavPage } from 'components/Page';
import { useTranslation, withTranslation } from 'react-i18next';
import * as _ from 'lib/utilities';
import withTrainer from 'partials/TrainerLoader';
import { deleteTrainerNote, loadClient, loadClientStats, logClientWeight, sendInvite, updateClient, updateMpInfoStub } from 'redux/actions';
import { Loader } from 'components/LoadingHOC';
import ActivityContainer from 'components/ActivityContainer';
import Card from 'components/Card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconNote, MainHeading, SlightEmphasisIconNote } from 'components/Typography';
import { TrainerPaywall } from 'partials/PaywallWrapper';
import classnames from 'classnames';
import LinkButton from 'components/LinkButton';
import { MultiLevelDropdown } from 'components/Dropdown';
import Button, { ConfirmActionButton } from 'components/Button';
import { AsyncSpinnerTextTPB, DropdownLinkTPB } from 'partials/ProRequiredButton';
import { dateFormat, NUTRITION, TRAINING } from 'config/settings';
import { remainingMacrosSel, trainerWithDetailsSel } from 'redux/selectors';
import { OverviewCard } from './Today';
import moment from 'moment';
import { MacroParams } from 'lib/macro-param';
import { BlurOverlay } from 'components/BlurOverlay';
import WorkoutCard from 'partials/WorkoutCard';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { EditClientModal } from 'partials/EditTrainerModal';
import { EditTrainerNoteModal, NewTrainerNoteModal } from 'partials/TrainerNoteModal';
import { SplitButtonRow } from 'components/SplitButtonRow';
import { DefaultSelect, FullInput, MinimalImageUpload } from 'components/Form';
import { User } from 'lib/classes';
import { useState } from 'react';
import { ComplianceChartCard, WeightAndActivityChartCard, WeightChart } from 'partials/ProgressCharts';
import { ListCard, ListCardAction, ListCardTitle } from 'components/List';
import FormikWrapper from 'components/FormikWrapper';
import * as Yup from 'yup';
import { WeightRecord } from 'lib/classes';
import TextInput, { SmallerNumberInput } from 'components/TextInput';
import blankProfileImg from 'assets/img/BlankProfile.png';
import ExportPDFModal, { exportClientPDFSuffix, ExportClientRoutinePDFModal, exportClientRoutinePDFSuffix } from 'partials/ExportPDFModal';
import { FullFormModal } from 'components/Modal';
import { UserProfileImage } from 'partials/Utilities';
import { HideOnExit } from 'components/RouteTransitionMap';
import { previewRoutinePdfUrlFor } from 'lib/api';
import { ClientFormsModal, FormsList, FormsListWrapper } from 'partials/TrainerFormModals';

const transitionMap = {
    rules: [
        [trainerMainMatches,transitions.slideOut],
        [[
            clientBasicProfileMatch,
            clientMealPlanSettingsMatch,
            assignClientHabitsMatch,
            assignClientRoutineMatch,
            standaloneMealPlanMatch,
            editRoutineStandaloneMatch,
            editRoutineShortcutMatch,
            standaloneWorkoutPlanMatch,
            workoutPreviewShortcutMatch,
            workoutPreviewMatch,
            clientProgressShortcutMatch,
            progressMatch,
            clientMacroAnalyticsShortcutMatch,
            macroAnalyticsPath,
            viewLogsPath,
            viewLogsShortcutMatch,
            trainerConversionMatch,
            bodyMeasurementsPath,
            clientBmShortcutMatch,
            viewChatMatch,
            switchForGenerateMatch,
            generateNewWeekMatch,
            clientMealPlansMatch,
            assignFormsToClientsMatch,
            viewClientAssignedFormMatch,
            progressPicsPath,
            clientProgressPicsShortcutMatch
        ],transitions.slideOver]
    ]
};

const mapStateToOverviewProps = (state,props) => ({
    remainingMacros: remainingMacrosSel(state,props)
})

const ShowFormsSection = ({ client, t }) => {

    return (
        <div id="assigned-forms-sect">
            <FormsListWrapper 
                client={client}
                t={t}
                render={({ forms, filterBtn }) => {
                    return (
                        <React.Fragment>
                            <MainHeading>
                                <div className="valign-wrapper">
                                    <div className="abbr-text flex-shrink">{t("fcas")}</div>
                                    {filterBtn}
                                    <LinkButton noShadow outlined color="primary" variant="icon-only" className="ml2" id="new-forms-btn" style={{ minWidth: '40px' }} to={assignFormsToClientsPath([client.id])}>
                                        <FontAwesomeIcon icon={'cog'}></FontAwesomeIcon>
                                    </LinkButton>
                                </div>
                            </MainHeading>
                            <FormsList forms={forms} client={client} t={t} />
                        </React.Fragment>
                    )
                }}
            />
        </div>
    )
}

const SetupFormsCard = ({ client, t }) => {
    return <ClientSetupCard prompt={t("Set Up Forms, Assessments, & Check-ins")} to={assignFormsToClientsPath([client.id])} id="setup-forms-btn" />;
}

const FormsSection = ({ client, t }) => {

    if(client.hasSetupForms()) {
        return (
            <ShowFormsSection client={client} t={t} />
        )
    }

    return (
        <div id="assigned-forms-sect">
            <MainHeading>
                {t("fcas")}
            </MainHeading>
            <SetupFormsCard client={client} t={t} />
        </div>
    )
}

export const ConnectedOverviewCard = connect(mapStateToOverviewProps)(OverviewCard);

const SampleOverviewCard = ({ client }) => {
    const { t } = useTranslation();
    const remainingMacros = MacroParams.sampleRemainingMacros(client);
    const user = { workoutPlanSetupAndVisible: () => true, sample: true, habitLogsByDate: date => [] };
    const workout = { name: 'Upper Body A', displayName: () => 'Upper Body A', id: 99999999, logged: false, date: moment().format(dateFormat) };

    return (
        <OverviewCard 
            user={user} 
            remainingMacros={remainingMacros} 
            workout={workout} 
            square 
            overlay={<BlurOverlay msg={t("no to do list yet")} />}
            playIcon="arrow-right"
        />
    )
}

const GoalCont = ({ children }) => (<div className="goal-cont">{children}</div>)
const GoalLabel = ({ label }) => (<div className="agoal-label">{label}</div>)
const GoalText = ({ children, title }) => (<div title={title} className="agoal-text">{children}</div>)
const GoalSub = ({ children }) => (<div className="agoal-sub">{children}</div>)
const ActionCont = ({ children }) => (<div className="action-cont">{children}</div>)

const GoalCard = ({ children, className, ...rest }) => {
    return (
        <Card className={classnames("client-goal-card", { [className]: className })} {...rest}>
            {children}
        </Card>
    )
}

const ClientSetupCard = ({ to, prompt, id }) => {
    return (
        <Card className="client-setup-card buttonize" linkTo={to} id={id ? id : ''}>
            <div className="prompt valign-wrapper">
                <div className="text-left flex-grow">
                    {prompt}
                </div>
                <div className="text-right">
                    <FontAwesomeIcon icon="arrow-right" />
                </div>
            </div>
        </Card>
    )
}

const NutritionGoals = ({ client }) => {
    const { t } = useTranslation();
    const title = client.hasSpecificDnps() ? t('Varies by day') : _.macroTargetsSummary(t,client.baseRawActiveMacroParams(),' | ',true);
    
    return (
        <GoalCard id="nutrition-goals-card">
            <GoalCont>
                <GoalLabel label={`${t('Nutrition')}:`} />
                <GoalText title={title}>{title}</GoalText>
            </GoalCont>
            <ActionCont>
                {client.isAppClient() && client.mealPlanInitialized() && (<LinkButton color="primary" to={clientMealPlansPath(client.id)} id="view-mp-btn" rounded outlined>
                    {t('Meal Plans')}
                </LinkButton>)}
                <LinkButton noShadow outlined color="primary" variant="icon-only" className="ml5" id="mp-settings-btn" to={clientMealPlanSettingsPath(client.id,'edit')}>
                    <FontAwesomeIcon icon="cog"></FontAwesomeIcon>
                </LinkButton>
            </ActionCont>
        </GoalCard>
    )
}

const SetupNutritionGoals = ({ client, t }) => {
    return <ClientSetupCard prompt={t('Set Up Meal Plan')} to={clientMealPlanSettingsPath(client.id,'setup')} id="setup-client-mp-btn" />;
}

const NutritionGoalsCard = ({ client, t  }) => {

    if(client.trainerDidInitialMpSetup()) {
        return <NutritionGoals client={client} t={t} />
    }

    return <SetupNutritionGoals client={client} t={t} />
}

const WorkoutGoals = ({ client }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const initialized = client.workoutPlanInitialized();
    const endStr = client.routineEndStr(t);
    const title = initialized ? client.assignedRoutineName(t) : t("Not picked yet");
    
    return (
        <GoalCard id="workout-goals-card">
            <GoalCont>
                <GoalLabel label={`${t('Workout Plan')}:`} />
                <GoalText title={title}>{title}</GoalText>
                {endStr && (<GoalSub>{endStr}</GoalSub>)}
            </GoalCont>
            <ActionCont>
                {initialized && client.isAppClient() && (<LinkButton color="primary" to={standaloneWorkoutPlanPathFor(client.id)} id="view-wp-btn" rounded outlined>
                    {t('View')}
                </LinkButton>)}
                {initialized && client.isPdfClient() && !window.isCordova && (<LinkButton color="primary" to={previewRoutinePdfUrlFor({ id: client.assignedRoutineId},true,false)} id="view-wp-btn" rounded outlined target="_blank">
                    {t('View')}
                </LinkButton>)}
                <MultiLevelDropdown
                    options={ { closeOnClick: true, closeOnChildClick: true } }
                    triggerRender={({ ref, target }) => {
                        return (
                            <Button noShadow outlined color="primary" variant="icon-only" id="wp-menu-btn" className="ml5" ref={ref} data-target={target}>
                                <FontAwesomeIcon icon='ellipsis-v'></FontAwesomeIcon>
                            </Button>
                        )
                    }}
                >
                    {initialized && (<DropdownLinkTPB 
                            type={TRAINING} 
                            id="edit-client-wrt-btn"
                            label={t("Edit Routine")} 
                            icon={["far","edit"]} 
                            to={editRoutineShortcutPathFor(client.id)}
                        />)}
                    <DropdownLinkTPB type={TRAINING} id="assign-routine-btn" label={t("Assign New Routine")} icon="exchange-alt" to={assignClientRoutinePathFor([client.id])} />
                    <DropdownLinkTPB type={TRAINING} id="pdf-workout-btn" label={t("Export PDF")} icon={['far','file-export']} to={modalPathFor(exportClientRoutinePDFSuffix,pathname,{ clientId: client.id })} allowUninit={false} />
                </MultiLevelDropdown>
            </ActionCont>
        </GoalCard>
    )
}

const SetupWorkoutGoals = ({ client, t }) => {
    return <ClientSetupCard prompt={t('Set Up Workouts')} to={assignClientRoutinePathFor([client.id])} id="setup-client-wrt-btn" />;
}

const WorkoutGoalsCard = ({ client, t }) => {
    if(client.trainerDidInitialWorkoutSetup()) {
        return <WorkoutGoals client={client} t={t} />
    }

    return <SetupWorkoutGoals client={client} t={t} />
}

const HabitGoals = ({ client }) => {
    const { t } = useTranslation();
    const activeHabits = client.activeAssignedHabits();
    const title = activeHabits.map(h => h.resolvedName(t)).join(', ');
    
    return (
        <GoalCard id="habit-goals-card">
            <GoalCont>
                <GoalLabel label={`${t('Habits')} (${activeHabits.length}):`} />
                <GoalText title={title}>{activeHabits.length > 0 ? title : t('No habits assigned')}</GoalText>
            </GoalCont>
            <ActionCont>
                <LinkButton noShadow outlined color="primary" variant="icon-only" className="ml5" id="habit-settings-btn" to={assignClientHabitsPathFor([client.id])}>
                    <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon>
                </LinkButton>
            </ActionCont>
        </GoalCard>
    )
}

const HabitGoalsCard = ({ client, t }) => {
    if(client.hasSetupHabits()) {
        return <HabitGoals client={client} />
    }

    return <ClientSetupCard id="setup-habits-btn" prompt={t('Set Up Habits')} to={assignClientHabitsPathFor([client.id])} />;
}

const AssignedGoalsSection = ({ client, t, trainer }) => {

    return (
        <div id="assigned-goals-sect">
            <MainHeading>
                {t("Assigned Goals")}
            </MainHeading>
            {!trainer.showTrainingOnly() && (<NutritionGoalsCard client={client} t={t} />)}
            {!trainer.showNutritionOnly() && (<WorkoutGoalsCard client={client} t={t} />)}
            <HabitGoalsCard client={client} t={t} />
        </div>
    )
}


const ToDoListSection = ({ client, t }) => {
    const dt = moment();
    const type = client.clientToDoType();
    const workout = client.clientWorkoutOn(dt.format(dateFormat));
    const getWorkoutPath = workout => workoutPreviewShortcutPathFor(client.id,workout.date);
    const allLogsLink = (<Link to={viewLogsShortcutPathFor(client.id)} className="underline">{t('View all client logs')}</Link>);

    return (
        <div id="todo-list-sect" className="mb20">
            <MainHeading>
                {t("Todays Goals")}
            </MainHeading>
            {type === 'full' && (<ConnectedOverviewCard 
                user={client} 
                date={dt} 
                workout={workout} 
                square 
                getWorkoutPath={getWorkoutPath} 
                playIcon="arrow-right"
            >{allLogsLink}</ConnectedOverviewCard>)}
            {type === 'sample' && (
                <SampleOverviewCard client={client} />
            )}
            {type === 'workout' && (
                <div>
                    <WorkoutCard user={client} workout={workout} getWorkoutPath={getWorkoutPath} playIcon="arrow-circle-right" className="mb0" />
                    {allLogsLink}
                </div>
            )}
        </div>
    )
}

const ClientInfoSection = ({ client, sendInvite, t }) => {
    const isPdf = client.isPdfClient();
    const created = moment(client.createdAt);
    const tags = client.fullTagList();

    return (
        <div id="client-info-sect">
            <MainHeading>
                {t("Client Info")}
            </MainHeading>
            <Card>
                <div className="pl20 pt20 pr20 pb10 slight-bottom-border">
                    <LinkButton noShadow outlined color="primary" variant="icon-only" className="float-right" id="edit-info-btn" to={editClientModalPathFor(clientDetailsPath(client.id),client.id)}>
                        <FontAwesomeIcon icon={'pencil'}></FontAwesomeIcon>
                    </LinkButton>
                    <div>{client.fullName()}</div>
                    <div>{_.isBlank(client.email) ? t('No email provided') : <a href={`mailto:${client.email}`}>{client.email}</a>}</div>
                    <div>{t("Trainer")}: {client.trainer.fullName()}</div>
                </div>
                <div className="pl20 pt10 pr20 pb20">
                    <div><span className="font-grey">{t("Added On")}:</span> {created.format('MMM Do, YYYY')} ({t('X days ago', { days: moment().diff(created,'days') })})</div>
                    {!isPdf && (
                        <React.Fragment>
                            <div>
                                <span className="font-grey">
                                    {t("Invite Status")
                                }</span>: {client.inviteStatusStr(t)}
                                {!client.inviteClaimed() && _.isBlank(client.email) && (
                                    <span> ({t('add email to invite')})</span>
                                )}
                            </div>
                            {!client.inviteClaimed() && !_.isBlank(client.email) && (<div className="ml20">
                                <AsyncSpinnerTextTPB
                                    buttonProps={{
                                        label: client.inviteSent() ? t('Resend Invitation Email') : t("Send Invitation Email"),
                                        id: "send-invite-btn"
                                    }}
                                    action={() => sendInvite(client.id)}
                                    type={null}
                                    allowUninit={false}
                                />
                            </div>)}
                        </React.Fragment>
                    )}
                    <div><span className="font-grey">{t("Tags")}</span>: {tags.length === 0 ? t('None') : tags.join(', ')}</div>
                </div>
            </Card>
        </div>
    )
}

const DeleteNoteButton = ({ note, deleteNote, t }) => {

    return (
        <ConfirmActionButton onClick={() => deleteNote({ id: note.id, clientId: note.clientId })} render={({ ready, onClick }) => (
            <span id={`delete-note-${note.id}-btn`} className={classnames("underline clickable ml15",{ 'red-text': ready })} onClick={onClick}>{t('Delete')}</span>
        )} />
    )
}

const TrainerNote = ({ client, note, t, deleteNote, isOwned }) => {

    return (
        <div className="slight-bottom-border mb10">
            <div className="tnote-title mb5">
                {note.subject}
            </div>
            <SplitButtonRow 
                leftChildren={[
                    <span className="faint-color" key="date">{moment(note.createdAt).format('M/D/YYYY')}</span>
                ]}
                rightChildren={[
                    (<Link key="edit" to={editNoteModalPathFor(clientDetailsPath(client.id),note.id)} id={`edit-note-${note.id}-btn`} className="underline">{t('View')}{isOwned ? `/${t('Edit')}` : ''}</Link>),
                    (isOwned ? <DeleteNoteButton key="delete" note={note} t={t} deleteNote={deleteNote} /> : '')
                ]}
            />
        </div>
    )
}

const NoteListCard = ({ children }) => (<Card className="trainer-note-list"><div className="note-cont">{children}</div></Card>)

const TrainerNotesSection = ({ client, trainer, t, deleteNote }) => {
    const notes = client.activeTrainerNotes();
    const isOwned = client.trainerId === trainer.id;

    return (
        <div id="trainer-notes-sect">
            <MainHeading>
                <div className="valign-wrapper">
                    {t("Trainer's Notes")}
                    <LinkButton noShadow outlined color="primary" variant="icon-only" className="ml10" id="new-note-btn" to={newNoteModalPathFor(clientDetailsPath(client.id))}>
                        <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon>
                    </LinkButton>
                </div>
            </MainHeading>
            <NoteListCard>
                    {notes.length === 0 && (
                        <SlightEmphasisIconNote id="no-trainer-notes-tip" text={t("no trainer notes tip")} />
                    )}
                    {notes.map(note => <TrainerNote key={note.id} client={client} note={note} t={t} deleteNote={deleteNote} isOwned={isOwned} />)}
            </NoteListCard>
        </div>
    )
}

const ProgressSectionCharts = ({ trainer, responseData: { weight, activity, linearMacroData, volume, compliance }={} }) => {

    return (
        <React.Fragment>
            <div>
                <ComplianceChartCard data={compliance} trainer={trainer} />
            </div>
            <div>
                <WeightAndActivityChartCard weight={weight} activity={activity} />
            </div>
        </React.Fragment>
    )
}

const ProgressSection = ({ client, t, loadStats, trainer }) => {
    const [filters,setFilters] = useState({ lookback: 7 });

    return (
        <div id="progress-sect">
            <SplitButtonRow 
                className="cntr mt10"
                leftChildren={[
                    <MainHeading key="header" className="no-wrap mb0 mt0">
                        <span>{t('Progress')}/{t('Stats')}</span>
                        <MultiLevelDropdown
                            options={ { closeOnClick: true, closeOnChildClick: true } }
                            triggerRender={({ ref, target }) => {
                                return (
                                    <Button noShadow outlined color="primary" variant="icon-only" className="ml5" ref={ref} data-target={target} id="progress-menu-btn">
                                        <FontAwesomeIcon icon='ellipsis-v'></FontAwesomeIcon>
                                    </Button>
                                )
                            }}
                        >
                            <DropdownLinkTPB 
                                type={null} 
                                id="view-progress-btn"
                                label={t("View Overall Progress")} 
                                icon="chart-line"
                                to={clientProgressShortcutPathFor(client.id)}
                            />
                            <DropdownLinkTPB 
                                type={null} 
                                id="view-progress-pics-btn"
                                label={t("View Progress Pics")} 
                                icon="image"
                                to={clientProgressPicsShortcutPathFor(client.id)}
                            />
                            <DropdownLinkTPB 
                                type={null} 
                                id="view-body-measurements-btn"
                                label={t("View Body Measurements")} 
                                icon="ruler-combined"
                                to={clientBmShortcutPathFor(client.id)}
                            />
                            <DropdownLinkTPB 
                                type={null} 
                                id="view-macro-analytics-btn"
                                label={t("View Macro Analytics")} 
                                icon="chart-pie"
                                to={clientMacroAnalyticsShortcutPathFor(client.id)}
                            />
                        </MultiLevelDropdown>
                    </MainHeading>
                ]}
                rightChildren={[
                    <DefaultSelect
                        key="select"
                        id="lookback-select"
                        className="mr5 inline-block"
                        name='lookback'
                        collection={User.progressPeriodsCol(t,true,true)}
                        values={filters}
                        handleChange={(e) => setFilters({ ...filters, lookback: _.castNumericInput(e.target.value) })}
                    />
                ]}
            />
            <Loader 
                key={`${client.id}-${filters.lookback}`}
                successComponent={ProgressSectionCharts}
                load={() => loadStats({ clientId: client.id, lookbackDays: filters.lookback })}
                preloaded={() => true}
                alwaysLoad
                trainer={trainer}
                type="padded"
            />
        </div>
    )
}

const renameMpSuffix = '/rename_mp_week/:week'

const RenameMpModal = ({ submit, client }) => {
    const { t } = useTranslation();
    const { pathname } = useLocation();
    const path = modalPathFor(renameMpSuffix,pathname);

    return (
        <FullFormModal
            fullWidth 
            path={path} 
            scrollable 
            exact 
            icon={['far','pencil']}
            title={t('Rename Meal Plan')}
            formProps={({ modalProps: { match: { params: { week } } } }) => {
                const vals = { startDate: client.activeWeekStart(week).format(dateFormat), userId: client.id, name: client.mealPlanWeekName(week,true), mpNameScheme: client.resolvedMpNameScheme(), mode: 'rename' };
                return {
                    initialValues: vals,
                    submit
                }
            }}
            render={({ modalProps, ...rest }) => {

                const { setFieldValue, values: { mode } } = rest;
                const divProps = { className: 'pa10', style: { maxWidth: '500px', marginLeft: 'auto', marginRight: 'auto' } };

                if(mode === 'rename') {
                    return (
                        <div {...divProps}>
                            <div className="valign-wrapper">
                                <FullInput
                                    className="flex-grow"
                                    name="name"
                                    label={t('Name')}
                                    component={TextInput}
                                    formikProps={rest}
                                />
                            </div>
                            <div className="center">
                                <div className="underline font-grey clickable" onClick={() => setFieldValue('mode','scheme')}>
                                    {t("change mp name scheme")}
                                </div>
                            </div>
                        </div>
                    )
                } else {
                    return (
                        <div {...divProps}>
                            <div className="valign-wrapper">
                                <FullInput
                                    className="flex-grow"
                                    name="mpNameScheme"
                                    label={t('Meal Plan Name Scheme')}
                                    component={TextInput}
                                    formikProps={rest}
                                />
                            </div>
                            <div className="mb15">
                                <SlightEmphasisIconNote text={t('mp name scheme tip')} />
                            </div>
                            <div className="center">
                                <div className="underline font-grey clickable" onClick={() => setFieldValue('mode','rename')}>
                                    {t('rename only mp')}
                                </div>
                            </div>
                        </div>
                    )
                }

            }} 
        />
    )
}

const MealPlanWeekCard = ({ client, name, week, t, macros, pathname, updateMpInfo }) => {

    return (
        <ListCard className="mp-week-card">
            <ListCardTitle linkTo={standaloneMealPlanPathFor(client.id,week)} className="pl15">
                <span className="mp-list-title">{_.fallbackText(name,_.weekTitle(week,client.resolvedMpNameScheme()))}</span>
                {macros && <div className="exercise-subtitle ml0">{_.macroHashSummary(t,macros)}</div>}
            </ListCardTitle>
            <MultiLevelDropdown
                options={ { closeOnClick: true, closeOnChildClick: true } }
                triggerRender={({ ref, target }) => {
                    return (
                        <ListCardAction className="no-border extra-padding" id={`mp-${week}-menu-btn`} ref={ref} data-target={target}>
                            <FontAwesomeIcon icon="ellipsis-v" />
                        </ListCardAction>
                    )
                }}
            >
                <DropdownLinkTPB type={NUTRITION} id="view-mp-btn" label={`${t('View')}/${t("Edit")}`} icon={["far","edit"]} to={standaloneMealPlanPathFor(client.id,week)} />
                <DropdownLinkTPB type={NUTRITION} id="pdf-mp-btn" label={t("Export PDF")} icon={['far','file-export']} to={modalPathFor(exportClientPDFSuffix,pathname,{ clientId: client.id, week })} allowUninit={false} />
                <DropdownLinkTPB type={NUTRITION} id="copy-mp-btn" label={t("Copy")} icon={['far','copy']} to={standaloneMealPlanPathFor(client.id,week,true)} />
                <DropdownLinkTPB type={NUTRITION} id="rename-mp-btn" label={t("Rename")} icon={['far','pencil']} to={modalPathFor(renameMpSuffix,pathname,{ week })} />
                <ConfirmActionButton 
                    onClick={() => updateMpInfo({ startDate: client.activeWeekStart(week).format(dateFormat), userId: client.id, hidden: true })}
                    render={({ onClick, ready, clickRef }) => (
                        <li onClick={onClick} ref={clickRef} className={ready ? null : 'dont-close-dropdown'}>
                            <span className={ready ? 'red-text' : null}>
                                <FontAwesomeIcon icon="times" /> {t("Delete")}
                            </span>
                        </li>
                    )}
                />
            </MultiLevelDropdown>
        </ListCard>
    )
}

const MealPlansSection = ({ client, t, macroInfo, updateMpInfo }) => {
    const { pathname } = useLocation();
    const [showAll,setShowAll] = useState(false);
    const weeks = client.mealPlanWeeksList();
    const maxCount = 10;

    return (
        <div id="meal-plans-sect">
            <MainHeading>
                <div className="valign-wrapper">
                    {t("Meal Plans")}
                    {client.mealPlanInitialized() && (<LinkButton noShadow outlined={weeks.length > 0} color="primary" variant="icon-only" id="new-mp-btn" className="ml10" to={client.createNewMealPlanPath()}>
                        <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon>
                    </LinkButton>)}
                </div>
            </MainHeading>
            {!client.mealPlanInitialized() && (
                <SlightEmphasisIconNote text={
                    <React.Fragment>
                        {t("set up mp profile first")}
                        <div className="center mt25">
                            <LinkButton noShadow color="primary" to={clientMealPlanSettingsPath(client.id,'setup')} id="setup-client-mp-btn2">
                                <FontAwesomeIcon icon={'plus'}></FontAwesomeIcon> {t('Set Up Meal Plan')}
                            </LinkButton>
                        </div>
                    </React.Fragment>} 
                />
            )}
            {weeks.length === 0 && client.mealPlanInitialized() && (
                <div className="pa20" id="no-meal-plans-tip">
                    <IconNote text={`${t("no meal plans for client")} ${t("use plus button above")}`} />
                </div>
            )}
            {weeks.length > 0 && weeks.map(({ name, week },index) => {

                if(showAll || index < maxCount) {
                    return (
                        <MealPlanWeekCard 
                            key={index} 
                            client={client} 
                            name={name} 
                            week={week} 
                            t={t} 
                            macros={macroInfo && macroInfo[index]} 
                            pathname={pathname} 
                            updateMpInfo={updateMpInfo}
                        />
                    )
                }

                return '';
            })}
            {weeks.length > maxCount && !showAll && (
                <div className="text-right underline clickable" onClick={() => setShowAll(true)}>{t('show all')}</div>
            )}
        </div>
    )
}

const WeightLogForm = ({ trainer, formikProps }) => {
    const { t } = useTranslation();
    const { submitState } = formikProps;
    const loading = submitState === 'LOADING';

    return (
        <div className="valign-wrapper">
            <FullInput
                label={t(User.weightSuffix(trainer.unitPreference))}
                className="inline"
                name="weight"
                component={SmallerNumberInput}
                formikProps={formikProps}
            />
            <DefaultSelect
                className="ml5"
                name='date'
                collection={_.lastWeekCollection(dateFormat)}
                {...formikProps}
            />
            <Button tag="button" rounded color="primary" className="ml5" id="log-weight-btn" outlined type="submit">
                <FontAwesomeIcon icon={loading ? "spinner" : "check"} spin={loading} /> {t("Log")}
            </Button> 
        </div>
    )

}

const WeightLogSection = ({ weight, client, trainer, logWeight, triggerReload }) => {
    const { t } = useTranslation();

    return (
        <div id="weight-log-sect">
            <MainHeading>{t('Weight Log')}</MainHeading>
            <Card className="pa10">
                <div className="slight-bottom-border pb10">
                    <FormikWrapper 
                        initialValues={ { weight: '', units: trainer.unitPreference, date: moment().format(dateFormat), clientId: client.id } }
                        validationSchema={Yup.object().shape({
                            weight: WeightRecord.weightValidationObj(trainer.unitPreference)
                        })}
                        submit={logWeight}
                        successCallback={() => triggerReload()}
                        initialErrors={{ weight: 'required' }} 
                        render={({handleSubmit, ...formikProps}) => {
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <WeightLogForm trainer={trainer} formikProps={formikProps} />
                                    </form>
                                )
                        }}
                    />
                </div>
                <div className="pt10 pb10">
                    <WeightChart weight={weight} />
                </div>
                <div className="pa10 text-center slight-top-border">
                    <LinkButton noShadow outlined color="primary" to={clientBmShortcutPathFor(client.id)}>
                        <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon> {t('Body Measurements')}
                    </LinkButton>
                </div>
            </Card>
        </div>
    )
}

const ChatLink = ({ trainer, client }) => {
    const msgCount = trainer.unreadMessageCountFor(client.primaryChatId);

    return (
        <div className={classnames("position-relative inline-block")}>
            {msgCount > 0 && (<span className="notification-icon for-btn">{msgCount}</span>)}
            <LinkButton noShadow outlined color="grey" variant="icon-only" id="client-messages-btn" className="no-shrink btn-group-right" to={viewChatPath(client.primaryChatId)}>
                <FontAwesomeIcon icon={['fal','comments-alt']}></FontAwesomeIcon>
            </LinkButton>
        </div>
    )
}

const HeaderButtons = ({ trainer, client, linkProps }) => {
    const showSignInAs = client.isAppClient();
    const showChatLink = client.showCliDetailsChatLink();

    return (
        <React.Fragment>
            <LinkButton noShadow outlined color="grey" variant="icon-only" className={classnames("ml10 no-shrink",{ "btn-group-left": (showSignInAs || showChatLink) })} to={clientBasicProfilePath(client.id,'edit')}>
                <FontAwesomeIcon icon="cog"></FontAwesomeIcon>
            </LinkButton>
            {showSignInAs && (<LinkButton noShadow outlined color="grey" variant="icon-only" className={classnames("no-shrink", { 'btn-group-right': !showChatLink, 'btn-group-mid': showChatLink })} to={switchCliAccountPathFor(client.id)} {...linkProps}>
                <FontAwesomeIcon icon="external-link-alt"></FontAwesomeIcon>
            </LinkButton>)}
            {showChatLink && <ChatLink trainer={trainer} client={client} />}
        </React.Fragment>
    )
}

const ClientDetailsCore = ({ client, clientId, trainer, sendInvite, deleteNote, loadStats, logWeight, triggerReload, updateClient, updateMpInfo, responseData: { weight, weeklyPlansMacroData }={} }) => {
    const { t } = useTranslation();
    const base = clientDetailsPath(clientId);

    const formsSetup = client && client.hasSetupForms();
    const setupFinished = client && client.trainerFinishedSetup();
    const todoSection = (client && client.showClientToDo()) ? (<ToDoListSection client={client} t={t} />) : '';
    const linkProps = window.isCordova ? {} : { target: '_blank' };

    return (
        <ActivityContainer contClassName="wider">
            {client && client.isActive() && (<React.Fragment>
                <div className="col s12">
                    <div className="valign-wrapper mb20">
                        <MinimalImageUpload 
                            callback={(result) => updateClient({ clientId, image: _.dataURItoBlob(result) })}
                            render={({ field, id }) => {
                                const path = client.profileImagePath('mediumThumb');
                                return (
                                    <div className="position-relative">
                                        {field}
                                        <label htmlFor={id} className="circle-img ml0 mr10 clickable">
                                            <UserProfileImage user={client} />
                                        </label>
                                        {path === blankProfileImg && (<FontAwesomeIcon icon="plus-circle" className="circle-img-icon" />)}
                                    </div>
                                )
                            }}
                        />
                        <h1 className="mt0 mb0 abbr-text">{client.fullName()}</h1>
                        <HeaderButtons trainer={trainer} client={client} linkProps={linkProps} />
                    </div>
                </div>
                <div className="col s12 m10 offset-m1 l4">
                    {trainer.isAppTrainer() && !formsSetup && <FormsSection client={client} t={t} />}
                    {setupFinished && todoSection}
                    <AssignedGoalsSection client={client} trainer={trainer} t={t} />
                    {!setupFinished && todoSection}
                    {trainer.isPdfTrainer() && <WeightLogSection weight={weight} logWeight={logWeight} trainer={trainer} client={client} triggerReload={triggerReload} />}
                </div>
                <div className="col s12 m10 offset-m1 l4">
                    {trainer.isAppTrainer() && (
                        <React.Fragment>
                            <ProgressSection client={client} t={t} loadStats={loadStats} trainer={trainer} />
                            {formsSetup && <FormsSection client={client} t={t} />}
                        </React.Fragment>
                    )}
                    {trainer.isPdfTrainer() && (<MealPlansSection client={client} t={t} macroInfo={weeklyPlansMacroData} updateMpInfo={updateMpInfo} />)}
                </div>
                <div className="col s12 m10 offset-m1 l4">
                    <TrainerNotesSection client={client} t={t} deleteNote={deleteNote} trainer={trainer} />
                    <ClientInfoSection client={client} t={t} sendInvite={sendInvite} />
                </div>
                <EditClientModal trainer={trainer} baseMatch={base} />
                <NewTrainerNoteModal trainer={trainer} client={client} baseMatch={base} />
                <EditTrainerNoteModal trainer={trainer} client={client} baseMatch={base} />
                <ExportPDFModal />
                <ExportClientRoutinePDFModal />
                <ClientFormsModal trainer={trainer} />
                <RenameMpModal client={client} submit={updateMpInfo} />
            </React.Fragment>)}
            {client && client.isInactive() && ((<div className="col s12 m10 l6 offset-m1 offset-l3">
                <SlightEmphasisIconNote id="client-inactive" text={t("This client has been deactivated")} />
            </div>))}
            {!client && (<div className="col s12 m10 l6 offset-m1 offset-l3">
                <SlightEmphasisIconNote id="no-client-found" text={t("client nonexistence")} />
            </div>)}
        </ActivityContainer>
    )
}

const ClientDetailsWrapper = ({ responseData, loadState, load, firstTry, ...props }) => {
    const { trainer, loadStats, client } = props;
    const [key,setKey] = useState(1);
    const triggerReload = () => setKey(key+1);

    if(trainer.isAppTrainer()) {
        return (
            <ClientDetailsCore {...props} />
        )
    } else {
        return (
            <Loader 
                key={key}
                successComponent={ClientDetailsCore}
                load={() => loadStats({ clientId: client.id, lookbackDays: 999999 })}
                preloaded={() => true}
                alwaysLoad
                type="page"
                triggerReload={triggerReload}
                {...props}
            />
        )
    }

}

class ClientDetails extends React.Component {

    render() {
        const { scrollRef, setupTransitions, t, trainer, loadClient, sendInvite, deleteNote, loadStats, logWeight, updateClient, updateMpInfo, match: { params: { clientId: id }} } = this.props;
        const client = trainer.clientById(id);

        return (
            <TrainerPaywall type={null}>
                <SimpleNavPage
                    scrollRef={scrollRef} 
                    transitionMap={transitionMap} 
                    setupTransitions={setupTransitions} 
                    title={t('Client Details')}
                >
                    <HideOnExit>
                        <Loader 
                            successComponent={ClientDetailsWrapper}
                            load={() => loadClient(id)}
                            preloaded={() => (client && client.isFullyLoaded())}
                            alwaysLoad
                            type="page"
                            client={client}
                            clientId={id}
                            trainer={trainer}
                            sendInvite={sendInvite}
                            deleteNote={deleteNote}
                            loadStats={loadStats}
                            logWeight={logWeight}
                            updateClient={updateClient}
                            updateMpInfo={updateMpInfo}
                        />
                    </HideOnExit>
                </SimpleNavPage>
            </TrainerPaywall>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    updateClient: data => dispatch(updateClient(data)),
    loadClient: (id) => dispatch(loadClient(id)),
    sendInvite: (id) => dispatch(sendInvite({ id })),
    deleteNote: data => dispatch(deleteTrainerNote(data)),
    loadStats: data => dispatch(loadClientStats(data)),
    logWeight: data => dispatch(logClientWeight(data)),
    updateMpInfo: data => dispatch(updateMpInfoStub(data))
})

export default connect(null,mapDispatchToProps)(withTranslation()(withTrainer(false,'page',trainerWithDetailsSel)(ClientDetails)))