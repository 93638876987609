import React, { useState } from 'react';
import { CountupTimer } from 'components/Timers';
import { CountdownTimer } from 'partials/ConnectedTimers';
import classnames from 'classnames';
import { WorkoutInput, FixedWorkoutNum, UnbrokenQuestion } from 'partials/WorkoutInputs';
import { formikInputWrapperProps } from 'components/Form';
import * as _ from 'lib/utilities';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconTip } from 'components/Tooltipped';



export const ArbitraryMeasureField = ({ exercise }) => {

    const { arbitraryMeasureVal, arbitraryMeasure, arbitraryTip } = exercise;

    return (
        <div className="wo-wt-input-container" id={`arb-meas-container`}>
            <div className="wo-wt-ex-subtitle">
                <div className="inline-tooltip-container">
                    {arbitraryMeasure}{!_.isBlank(arbitraryTip) && (<IconTip size='1x' icon="info-circle" containerClassName='inline-tooltip' className="font-grey" msg={arbitraryTip}/>)}
                </div>
            </div>
            <div className="wo-wt-ex-num">
                {arbitraryMeasureVal}
            </div>
            <div className="wo-wt-ex-subtitle">
            </div>
        </div>
    )
}

const SetRowContainer = ({ className, children }) => {
    const cnames = classnames("valign-wrapper cntr respo mt20",{[className]: className});
    return (
        <div className={cnames}>
            {children}
        </div>
    )
}

const ExerciseSetField = ({ field, fieldParams, exerciseSet, inputProps, timerProps, onFocus, rows, setFieldValue, submitForm }) => {
    const { t } = useTranslation();
    const { display: timerDisplay, secondsElapsed, secondsRemaining } = timerProps || {};
    const repLabel = exerciseSet.isUnilateral() ? t('X per side',{ unit: t('Reps')}) : t('Reps');

    switch(field) {
        case 'weight':
        case 'kgWeight':
        case 'arbitraryMeasure': {
            return (
                <WorkoutInput 
                    exercise={exerciseSet.exercise()}
                    field={field}
                    inProps={{id:`set-${field}-field`, onFocus}}
                    {...inputProps}
                />
            )
        }
        case 'reps': {
            return (
                <WorkoutInput 
                    exercise={exerciseSet.exercise()}
                    field='reps'
                    inProps={{id:`set-reps-field`, onFocus}}
                    {...inputProps}
                />
            )
        }
        case 'repsGoal': {
            return (
                <FixedWorkoutNum 
                    id={`set-${field}-field`}
                    title={t("Do")}
                    content={exerciseSet.rangeRepGoalStr(t)}
                    subtitle={repLabel}
                />
            )
        }
        case 'repMaxReps': {
            return (
                <FixedWorkoutNum 
                    id={`set-${field}-field`}
                    title={t("Record a new")}
                    content={exerciseSet.minReps}
                    subtitle={t('rep max')}
                />
            )
        }
        case 'isoRange': {
            return (
                <FixedWorkoutNum 
                    id={`set-${field}-field`}
                    title={t("Hold for")}
                    content={exerciseSet.rangeRepGoalStr(t)}
                    subtitle={exerciseSet.isoTimeLabel(t)}
                />
            )
        }
        case 'countdown': {
            const defTitle = t('Timer');
            const { title, subtitle } = fieldParams;
            return (
                <FixedWorkoutNum 
                    id={`set-${field}-field`}
                    title={title || defTitle}
                    content={timerDisplay}
                    subtitle={subtitle || _.timeLabel(secondsRemaining,t)}
                />
            )
        }
        case 'countup': {
            return (
                <FixedWorkoutNum 
                    id={`set-${field}-field`}
                    title={t("Timer")}
                    content={timerDisplay}
                    subtitle={_.timeLabel(secondsElapsed,t)}
                />
            )
        }
        case 'distance':
        case 'kmDistance':
        case 'mileDistance': {
            return (
                <WorkoutInput 
                    exercise={exerciseSet.exercise()}
                    field={field}
                    inProps={{id:`set-${field}-field`, onFocus}}
                    {...inputProps}
                />
            )
        }
        case 'distanceGoal':
        case 'kmDistanceGoal':
        case 'mileDistanceGoal': {
            let subtitle = t('meters');
            if(field === 'kmDistanceGoal') {
                subtitle = t('kilometers');
            } else if(field === 'mileDistanceGoal') {
                subtitle = t('miles');
            }
            const attr = field.replace('Goal','');
            return (
                <FixedWorkoutNum 
                    id={`set-${field}-field`}   
                    title={t('Do dist',{ dist: ''})}
                    content={attr === 'distance' ? exerciseSet[attr] : exerciseSet[attr]()}
                    subtitle={subtitle}
                />
            )
        }
        case 'unbroken': {
            return (
                <UnbrokenQuestion 
                    id={`set-${field}-field`}
                    className={rows > 1 ? 'mt20' : ''}
                    yesHandler={() => {
                        setFieldValue('unbroken',true);
                        submitForm();
                    }}
                    noHandler={() => {
                        setFieldValue('unbroken',false);
                        submitForm();
                    }}
                />
            )
        }
        default: {
            return '';
        }
    }
}

const DoneButton = ({ handler }) => {
    const { t } = useTranslation();
    
    return (
        <div className="mt25">
            <Button rounded color="primary" onClick={handler} id={'set-done-btn'}>
                <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon> {t("Done")}
            </Button>
        </div>
    )
}

export const TimerControls = ({ timerProps, onFinish, onPause, onRestart, includeDone  }) => {
    const { pause,restart,reset,status,start } = timerProps;
    const showPlayBtn = status === 'unstarted' || status === 'paused';
    const showPauseBtn = !showPlayBtn && status !== 'finished';
    const showResetBtn = status !== 'unstarted';
    const { t } = useTranslation();

    const finishSet = () => (onFinish && onFinish())

    const pauseSet = () => {
        onPause && onPause();
        pause();
    }

    const restartSet = () => {
        onRestart && onRestart();
        if(status === 'running') {
            restart();
        } else {
            reset();
        }
    }


    return (
        <React.Fragment>
            {showPlayBtn && (<Button variant="round-icon" color="primary" onClick={start} id="start-timer-btn">
                <FontAwesomeIcon icon="play"></FontAwesomeIcon>
            </Button>)}
            {showPauseBtn && (<Button variant="round-icon" color="primary" outlined onClick={pauseSet} id="pause-timer-btn">
                <FontAwesomeIcon icon="pause"></FontAwesomeIcon>
            </Button>)}
            {showResetBtn && (<Button variant="round-icon" color="primary" className="ml5" outlined onClick={restartSet} id="restart-timer-btn">
                <FontAwesomeIcon icon="undo"></FontAwesomeIcon>
            </Button>)}
            {includeDone && (<Button id={"set-done-btn"} rounded color={status === 'unstarted' ? 'grey' : 'primary'} className="ml5" outlined={status !== 'finished'} onClick={finishSet}>
                <FontAwesomeIcon icon="arrow-right"></FontAwesomeIcon> {t("Done")}
            </Button>)}
        </React.Fragment>
    )
}

const TimerControlsWrapper = ({ timerProps, formikProps, timerField }) => {
    const { submitForm, setFieldValue } = formikProps;
    const { secondsElapsed,secondsRemaining } = timerProps;
    const [unbroken,setUnbroken] = useState(true);


    const onFinish = () => {
        if(timerField === 'unbroken') {
            setFieldValue('unbroken',(unbroken && secondsRemaining <= 0.2));
        } else {
            setFieldValue(timerField,secondsElapsed);
        }

        submitForm();
    }

    const onPause = () => setUnbroken(false);

    const onRestart = () => setUnbroken(true);

    return (<TimerControls timerProps={timerProps} onFinish={onFinish} onPause={onPause} onRestart={onRestart} includeDone />)

}

const ExerciseSetFieldsCore = ({ exerciseSet, formikProps, timerProps, fields, timerField, onFocus }) => {
    const loadingField = exerciseSet.loadingField();
    const inputProps = _.pick(formikProps,formikInputWrapperProps);

    if(loadingField) {
        fields = { [loadingField]: null, ...fields }
    }

    const entries = Object.entries(fields);
    const row1 = entries.slice(0,2);
    const row2 = entries.slice(2);
    const rows = _.filter([row1,row2],row => (row.length > 0));
    const flexClass = (rows.length === 1 && 'unbroken' in fields) ? 'flex-start' : 'flex-end';
    
    return (
        <React.Fragment>
            {rows.map(row => (
                <SetRowContainer key={row[0]} className={flexClass}>
                    {row.map(([field,params]) => (
                        <ExerciseSetField 
                            key={field} 
                            field={field}
                            fieldParams={params}
                            exerciseSet={exerciseSet} 
                            inputProps={inputProps} 
                            timerProps={timerProps} 
                            onFocus={onFocus}
                            rows={rows.length}
                            setFieldValue={formikProps.setFieldValue}
                            submitForm={formikProps.submitForm}
                        />
                    ))}
                </SetRowContainer>
            ))}
            <div className="mt25">
            {timerProps && <TimerControlsWrapper timerProps={timerProps} formikProps={formikProps} timerField={timerField} />}
            {!(timerProps || 'unbroken' in fields) && <DoneButton handler={formikProps.submitForm} />}
            </div>
        </React.Fragment>
    )
}

export const ExerciseSetFields = ({ exerciseSet, formikProps }) => {
    const [ autoSubmit, setAutoSubmit ] = useState(true);
    const { handleBlur, ...newFormikProps } = formikProps;
    const onFocus = (e) => {
        setAutoSubmit(false);
    }
    newFormikProps.handleBlur = (e) => {
        handleBlur(e);
        setAutoSubmit(true);
    }
    const { t } = useTranslation();
    const fields = exerciseSet.walkthroughFields(t);
    let timerType = null;
    let seconds = 0;
    let timerField = null;
    if(fields.countdown) {
        timerType = 'countdown';
        seconds = fields.countdown.seconds;
        timerField = fields.countdown.field;
    } else if(fields.countup) {
        timerType = 'countup';
        timerField = fields.countup.field;
    }

    if(timerType) {
        const Component = timerType === 'countup' ? CountupTimer : CountdownTimer;
        return (
            <Component 
                autoStart={exerciseSet.autoStarts()} 
                onFinish={() => {
                    if(exerciseSet.autoAdvances() && autoSubmit) {
                        formikProps.submitForm();
                    }
                }}
                seconds={seconds} 
                render={(timerProps) => (
                    <ExerciseSetFieldsCore 
                        exerciseSet={exerciseSet} 
                        fields={fields} 
                        formikProps={newFormikProps} 
                        timerProps={timerProps} 
                        timerField={timerField}
                        onFocus={onFocus}
                    />
                )} 
            />
        )
    } else {
        return (
            <ExerciseSetFieldsCore 
                exerciseSet={exerciseSet} 
                fields={fields} 
                formikProps={newFormikProps} 
                timerProps={null} 
                onFocus={onFocus}
            />
        )
    }
}