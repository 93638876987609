import React from 'react';
import { SimpleNavPage, SimpleNavPageWithTabs } from 'components/Page';
import { useTranslation } from 'react-i18next';
import * as transitions from 'assets/transitions';
import { userMealMatch, viewMealPathMatch, viewRecipeReplacementMatch, viewTempMealMatch, viewTempRecipeReplacementMatch, editRecipeMatchFor, swapRecipeMatch, swapTempRecipeMatch, swapMealMatch, recipeBrowserPath, editRecipePathFor, editIngredientModalMatchFor, editIngredientModalPathFor, addIngredientModalMatchFor, addIngredientModalPathFor, foodModalMatchFor, foodModalPathFor, editRecipeMatch, addIngredientsPath, allMainMatches } from 'config/paths';
import { connect } from 'react-redux';
import { RouteTransitionMap } from 'components/RouteTransitionMap';
import * as _ from 'lib/utilities';
import { allergyTagsSelector, foodSelector, initialFoodNameSelector, recipeEditorSelector, recipeEditorTrainerSelector, recipeEditorUserSelector } from 'redux/selectors';
import ActivityContainer, { CenteredActivityContainer } from 'components/ActivityContainer';
import FormikWrapper from 'components/FormikWrapper';
import { addIngredient, cacheRecipeChanges, loadRecipeDraft, loadMyFoods, destroyFood, updateFood, createRecipe, updateRecipe, createRecipeOverride } from 'redux/actions';
import { FileUpload, formikAutoSubmitTextProps, InputWithErrors, MultivalueCheckbox, TagButtons } from 'components/Form';
import TextInput, { NumberInput, TextArea } from 'components/TextInput';
import Card from 'components/Card';
import RouteModal, { ModalHeader } from 'components/Modal';
import { Redirect } from 'components/Routing';
import ScannedFoodModal, { FoodAmountSelectForm } from 'partials/ScannedFoodModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button, { BottomButtons, ConfirmActionButton, DefaultAsyncActionButton, SimpleConfirmActionButton } from 'components/Button';
import LinkButton from 'components/LinkButton';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { FullSvgMacroChart } from 'partials/MacroCircleSvg';
import { ResponsiveContainer } from 'components/ResponsiveContainer';
import { SectionTab, SectionTabs } from 'components/BottomNav';
import DatabaseTab, { FoodsList } from './FoodLogDatabaseTab';
import { Loader } from 'components/LoadingHOC';
import { useHistory } from 'react-router-dom';
import classnames from 'classnames';
import { SlightEmphasisIconNote } from 'components/Typography';
import requestStateHandler from 'components/RequestStateHandler';
import { ActionBox } from 'components/RecordPanel';
import FoodForm from 'partials/FoodForm';
import { Recipe, User } from 'lib/classes';
import { resolvedFoodsLabel, resolvedHomePath } from 'redux/helpers';
import { mealPlanSlideOutPaths } from 'partials/MainLayout';
import { goWrapper } from 'redux/helpers';
import { ExitMealPlanButton } from 'partials/Utilities';

const secondStepFields = recipeDraft => {
    const shared = ['image','instructions','defaultImage','overrideAllergyTags','dietTags'];

    if(_.isBlank(recipeDraft.overriddenId)) {
        return _.pick(recipeDraft,['mainOrSide','foodType','standalone','sideCategory', 'teamShared','saveToFavorites','complexity','isMealKit',...shared])
    }

    return _.pick(recipeDraft,shared);
}

class CategorizeStep extends React.Component {

    constructor(props) {
        super(props);
        const { setupTransitions } = this.props;
        setupTransitions({ rules: [
            [editRecipeMatchFor(1), transitions.flowFormOut]
        ]})
    }

    render() {
        const { recipeDraft, cacheRecipeChanges, allergyTags, t } = this.props;

        return (
            <ActivityContainer>
                <div className="col s12 m12 l8 offset-l2">
                    <Card className="mt20 pa10">
                        <FormikWrapper 
                            initialValues={ secondStepFields(recipeDraft) }
                            submit={values => {
                                cacheRecipeChanges(values);
                                return Promise.resolve({ status: 'SUCCESS' });
                            }}
                            initialErrors={{}}
                            autoSubmit
                            render={({ handleSubmit, submitState, handleAutoSubmitChange, handleAutoSubmitBlur, ...rest }) => {
                                const isMain = rest.values.mainOrSide === 'main';
                                const { values: { overrideAllergyTags, dietTags, saveToFavorites, teamShared, complexity, isMealKit } } = rest;
                                return (
                                    <form onSubmit={handleSubmit}>
                                        <div className="small-form-header">{t('Instructions')} <span className="parenthetical">({t('optional')})</span></div>
                                        <TextArea 
                                            name="instructions" 
                                            value={rest.values.instructions} 
                                            onChange={handleAutoSubmitChange} 
                                            onBlur={handleAutoSubmitBlur} 
                                            placeholder={t("Separate each step with a new line")}
                                            height={'100px'}
                                        />
                                        {dietTags && (
                                            <React.Fragment>
                                                <div className="small-form-header">{t('Diet types')} ({t("select all that apply")})</div>
                                                <TagButtons
                                                    btnId={(val) => `diet-tag-${val}`}
                                                    col={User.dietTypesForSearchCol(t)}
                                                    {...rest}
                                                    name={'dietTags'}
                                                    buttonProps={ { className: 'ma2 btn-no-shadow' } }
                                                />
                                            </React.Fragment>
                                        )}
                                        {overrideAllergyTags && (
                                            <React.Fragment>
                                                <div className="small-form-header">{t('Food allergens')}</div>
                                                <TagButtons
                                                    btnId={(val) => `allergy-tag-${val}`}
                                                    col={allergyTags.map(([label,value]) => ({ text: label, value }))}
                                                    {...rest}
                                                    name={'overrideAllergyTags'}
                                                    buttonProps={ { className: 'ma2 btn-no-shadow' } }
                                                />
                                            </React.Fragment>
                                        )}
                                        {_.isBlank(recipeDraft.overriddenId) && (<React.Fragment>
                                            <div className="small-form-header">{t('Categorization')}</div>
                                            <TagButtons
                                                btnId={(val) => `main-or-side-${val}`}
                                                col={Recipe.mainOrSideTagCol(t)}
                                                {...rest}
                                                name={'mainOrSide'}
                                                single
                                                buttonProps={ { className: 'ma2 btn-no-shadow' } }
                                            />
                                            <div className="mt10 mb10">
                                                <TagButtons
                                                    btnId={(val) => `cat-tag-${val}`}
                                                    col={isMain ? Recipe.foodTypesForForm(t) : Recipe.sideCategoryCol(t)}
                                                    {...rest}
                                                    name={isMain ? 'foodType' : 'sideCategory'}
                                                    single
                                                    buttonProps={ { className: 'ma2 btn-no-shadow' } }
                                                />
                                            </div>
                                            {isMain && (<MultivalueCheckbox 
                                                checkedVal={1}
                                                uncheckedVal={0}
                                                {...rest}
                                                name={'standalone'}
                                                label={t('Standalone?')} 
                                                filled 
                                                className="no-wrap ml2"
                                                tooltip={t('standalone tip')}
                                            />)}
                                            {complexity !== undefined && (<MultivalueCheckbox 
                                                checkedVal={0}
                                                uncheckedVal={1}
                                                {...rest}
                                                name={'complexity'}
                                                label={t("is basic recipe")} 
                                                filled 
                                                className="no-wrap ml2"
                                                tooltip={t('is basic rec tip')}
                                            />)}
                                            {teamShared !== undefined && (<MultivalueCheckbox 
                                                checkedVal={true}
                                                uncheckedVal={false}
                                                id='team-shared-check'
                                                {...rest}
                                                name={'teamShared'}
                                                label={t("Save to team level")} 
                                                filled 
                                                className="no-wrap ml2"
                                                tooltip={t('team level tip')}
                                            />)}
                                            {saveToFavorites !== undefined && (<MultivalueCheckbox 
                                                checkedVal={true}
                                                uncheckedVal={false}
                                                id='save-to-faves-check'
                                                {...rest}
                                                name={'saveToFavorites'}
                                                label={t("Add to my favorites")} 
                                                filled 
                                                className="no-wrap ml2"
                                            />)}
                                            {isMealKit !== undefined && (<MultivalueCheckbox 
                                                checkedVal={true}
                                                uncheckedVal={false}
                                                id='is-meal-kit-check'
                                                {...rest}
                                                name={'isMealKit'}
                                                label={t("This is a delivery meal/kit")} 
                                                tooltip={t('meal kit tip')}
                                                filled 
                                                className="no-wrap ml2"
                                            />)}
                                        </React.Fragment>)}
                                        <div className="small-form-header">{t('Image')} <span className="parenthetical">({t('optional')})</span></div>
                                        <FileUpload 
                                            name="image"
                                            setFieldValue={rest.setFieldValue}
                                            defaultImageUrl={_.isBlank(rest.values.image) ? rest.values.defaultImage : rest.values.image}
                                        />
                                    </form>
                                )
                            }}       
                        />
                    </Card>
                </div>
            </ActivityContainer>
        );
    }
}

let FoodsListWrapper = ({ foods, destroyFood, addIngredient, t, history, basePath, readOnly, isTeam, canCreate }) => {
    const destroy = food => destroyFood(_.filter(foods,otherFood => otherFood.id !== food.id));
    let noFoodsTip = isTeam ? t("no team foods") : t("You haven't created any foods yet");
    if(canCreate) {
        noFoodsTip = `${noFoodsTip} ${t('create new foods tip')}`
    }

    const extraActionRender = readOnly ? null : ({ record }) => {

        return (
            <React.Fragment>
                <ActionBox icon="pencil" onClick={() => history.push(foodModalPathFor(basePath,record.id))} />
                <ConfirmActionButton onClick={() => destroy(record)} render={({ onClick, ready }) => {
                    return (
                        <ActionBox icon="times" onClick={onClick} className={ready ? 'red-text' : ''} />
                    )
                }} />
            </React.Fragment>
        )
    }
    
    return (
        <div className={classnames({ pa10: foods.length === 0})}>
            {foods.length === 0 && (<SlightEmphasisIconNote text={noFoodsTip} />)}
            <FoodsList 
                foods={foods}
                log={addIngredient.bind(null,noSeqHandlerCreator(history,basePath))}
                allowNewMeasure
                t={t}
                logIcon="plus"
                maxIndex={9999}
                extraActionRender={extraActionRender}
            />
        </div>
    )
}

FoodsListWrapper = requestStateHandler({ destroyFood: 'foods' })(FoodsListWrapper)

const FoodsTabSuccess = ({ user, trainer, destroyFood, readOnly, ...rest }) => {
    const { t } = useTranslation();
    const foods = _.signedInAsClient(trainer,user) ? trainer.activeFoods() : user.activeFoods();
    const history = useHistory();
    const newDestroyFood = (newList) => {
        const deleted = _.filter(foods,food => !_.some(newList,newFood => newFood.id === food.id));
        return destroyFood(deleted[0].id);
    }

    return (
        <FoodsListWrapper 
            foods={foods} t={t} 
            history={history} 
            destroyFood={newDestroyFood} 
            readOnly={readOnly} 
            isTeam={trainer && trainer.hasOtherTrainers()} 
            canCreate={(trainer || user).canCreateFoods()} 
            {...rest} 
        />
    )
}

let FoodsTab = ({ user, trainer, loadMyFoods, addIngredient, basePath, destroyFood }) => {
    const readOnly = !(trainer || user).canCreateFoods();

    return (
        <React.Fragment>
            <Card>
                <Loader
                    successComponent={FoodsTabSuccess}
                    type="page"
                    load={loadMyFoods}
                    preloaded={() => user.foodsLoaded}
                    alwaysLoad
                    basePath={basePath}
                    user={user}
                    trainer={trainer}
                    readOnly={readOnly}
                    addIngredient={addIngredient}
                    destroyFood={destroyFood}
                />
            </Card>
            <EditFoodModal basePath={basePath} />
        </React.Fragment>
    )
}

const mapStateToFoodTabProps = state => ({
    user: recipeEditorUserSelector(state),
    trainer: recipeEditorTrainerSelector(state)
})

const mapDispatchToFoodsTabProps = dispatch => ({
    loadMyFoods: () => dispatch(loadMyFoods()),
    addIngredient: (noSeqHandler,data) => dispatch(addIngredient(noSeqHandler,data)),
    destroyFood: foodId => dispatch(destroyFood(foodId))
})

FoodsTab = connect(mapStateToFoodTabProps,mapDispatchToFoodsTabProps)(FoodsTab)

const noSeqHandlerCreator = (history,basePath) => (foodId,servings) => history.push(addIngredientModalPathFor(basePath,foodId,servings));

const DatabaseTabWrapper = ({ log, basePath, trainer, user, ...rest }) => {
    const history = useHistory();
    const noSeqHandler = noSeqHandlerCreator(history,basePath);
    const props = { log: log.bind(null,noSeqHandler), showNewFoodPrompt: (trainer || user).canCreateFoods(), basePath, ...rest };
    let innerComp;
    if(window.isCordova || _.isPWA()) {
        innerComp = (<ScanModalWrapper basePath={basePath} render={({ launchHandler }) => <DatabaseTab scanLaunchHandler={launchHandler} {...props} />} />)
    } else {
        innerComp = (<DatabaseTab {...props} />);
    }

    //static positioning necessary because new food modal is in here
    return (
        <Card style={{ position: 'static'}}>{innerComp}</Card>
    )
}

const mapDBTDispatchToProps = (dispatch) => ({
    log: (noSeqHandler,data) => dispatch(addIngredient(noSeqHandler,data))
});

const ConnectedDatabaseTab = connect(mapStateToFoodTabProps,mapDBTDispatchToProps)(DatabaseTabWrapper);

const AddIngredientTabs = ({ activeTab, basePath }) => {
    const tabIndex = _.isBlank(activeTab) ? 1 : activeTab;
    const tabs = [ConnectedDatabaseTab,FoodsTab];
    const Tab = tabs[tabIndex-1] || (() => '');

    return (
        <React.Fragment>
            <Tab logIcon="plus" allowNewMeasure basePath={basePath} />
            <AddIngredientModal basePath={basePath} />
        </React.Fragment>
    )
}

const AddIngredientTabsWrapper = (props) => (
    <CenteredActivityContainer>
        <AddIngredientTabs {...props} />
    </CenteredActivityContainer>
)

const addTabLabels = () => ([
    { icon: ["far","list-alt"], text: 'Database' },
    { icon: ["far","heart"], text: resolvedFoodsLabel() }
])

const addTransitionMap = {
    rules: [
        [[ ...allMainMatches, editRecipeMatch ] ,transitions.slideOut],
        [[], transitions.slideOver]
    ]
};

export const AddIngredientPage = ({ activeTab, setActiveTab, scrollRef, setupTransitions }) => {
    const { t } = useTranslation();
    const tabLabels = addTabLabels().map(({ text, icon }) => (<React.Fragment><FontAwesomeIcon icon={icon} /> {t(text)}</React.Fragment>));

    return (
        <SimpleNavPageWithTabs
            scrollRef={scrollRef} 
            transitionMap={addTransitionMap} 
            setupTransitions={setupTransitions} 
            title={t('Add Ingredients')}
            tabLabels={tabLabels}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
        >
            <AddIngredientTabsWrapper basePath={addIngredientsPath} />
        </SimpleNavPageWithTabs>
    )
}

const AddButton = ({ t }) => (<React.Fragment><FontAwesomeIcon icon="plus" /><span>{t("Add")}</span></React.Fragment>)

let AddIngredientContent = ({ food, addIngredient, match: { params: { servings } }, basePath, close }) => {
    const { t } = useTranslation();
    
    if(food) {
        return (
            <FoodAmountSelectForm 
                food={food}
                confirmAction={addIngredient}
                confirmTitle={<AddButton t={t} />}
                close={close}
                defaultSeq={''}
                defaultServings={Number(servings)}
                t={t}
            />
        )
    } else {
        return <Redirect to={basePath} />
    }
 
}

let ScanModalWrapper = ({ addIngredient, basePath, render }) => {
    const { t } = useTranslation();

    return (
        <ScannedFoodModal 
            basePath={basePath} 
            confirmTitle={(<AddButton t={t} />)} 
            confirmAction={addIngredient} 
            render={render} />
    )
}

const mapDispatchToAddModalProps = dispatch => ({
    addIngredient: data => dispatch(addIngredient(null,data))
})

const mapStateToFoodModalProps = (state,props) => ({
    food: foodSelector(state,props),
    initialName: initialFoodNameSelector(state,props)
})

AddIngredientContent = connect(mapStateToFoodModalProps,mapDispatchToAddModalProps)(AddIngredientContent);
ScanModalWrapper = connect(null,mapDispatchToAddModalProps)(ScanModalWrapper);

const AddIngredientModal = ({ basePath }) => {
    return (
        <RouteModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={addIngredientModalMatchFor(basePath)} 
            exact 
            render={({ match, close }) => (
                <AddIngredientContent 
                    basePath={basePath}
                    match={match}
                    close={close}
                />
            )} 
        />
    )
}

const mapDispatchToFoodProps = (dispatch) => ({
    submit: (data) => dispatch(updateFood(data))
})

const ConnectedFoodForm = connect(null,mapDispatchToFoodProps)(FoodForm);

let EditFoodContent = ({ food, initialName, headerRef, footerRef, calcNonScrollDim, close, resetQuery }) => {
    const { t } = useTranslation();
 
    return (
        <React.Fragment>
            <ModalHeader defaults exitButton scrollable noShadow ref={headerRef}>
                <FontAwesomeIcon icon={['far','edit']} /> {food ? t('Edit Food') : t('New Food')}
            </ModalHeader>
            <ConnectedFoodForm 
                food={food} 
                initialName={initialName}
                calcNonScrollDim={calcNonScrollDim} 
                footerRef={footerRef} 
                close={close}
                defaultFooter
                actionTitle={t('Save')}
                resetQuery={resetQuery}
            />
        </React.Fragment>
    )

}

EditFoodContent = connect(mapStateToFoodModalProps)(EditFoodContent)

export const EditFoodModal = ({ basePath, submitLoad: resetQuery }) => {
    return (
        <RouteModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={foodModalMatchFor(basePath)} 
            exact 
            render={(modalProps) => (
                <EditFoodContent 
                    resetQuery={resetQuery}
                    {...modalProps}
                />
            )} 
        />
    )
}

const EditIngredientContent = ({ updateIngredient, ingredients, match: { params: { index } }, basePath, close }) => {
    const ingredient = ingredients[Number(index)];
    const { t } = useTranslation();
    const confirmAction = ({ seq, servings }) => {
        const foodWeight = ingredient.foodWeight.food.foodWeightFor(seq);
        updateIngredient(Number(index), { seq: foodWeight.seq, amount: servings, foodWeightId: foodWeight.id });
        return Promise.resolve({ status: 'SUCCESS' })
    }

    if(ingredient) {
        return (
            <FoodAmountSelectForm 
                food={ingredient.foodWeight.food}
                confirmAction={confirmAction}
                confirmTitle={t('Save')}
                close={close}
                defaultSeq={ingredient.foodWeight.seq}
                defaultServings={ingredient.amount}
                nutritionMultiplier={ingredient.effectiveNutritionMult()}
                t={t}
            />
        )
    } else {
        return <Redirect to={basePath} />
    }
 
}

const EditIngredientModal = ({ basePath, ingredients, updateIngredient }) => {
    return (
        <RouteModal 
            fullWidth 
            noOverflow 
            limitWidth 
            path={editIngredientModalMatchFor(basePath)} 
            exact 
            render={({ match, close }) => (
                <EditIngredientContent 
                    ingredients={ingredients}
                    basePath={basePath}
                    match={match}
                    close={close}
                    updateIngredient={updateIngredient}
                />
            )} 
        />
    )
}

const IngredientRow = ({ basePath, ingredient, destroyIngredient, index }) => {

    return (
        <div className="re-ingredient-container">
            <div className="re-ingredient-desc">
                <div className="display-flex">
                    <div className="re-ingr-piece abbr-text">{ingredient.humanReadableQty(1,false)} </div>
                    <div className="abbr-text">{ingredient.foodName()}</div>
                </div>
            </div>
            <div className="re-ingredient-btns">
                <LinkButton variant="flat" to={editIngredientModalPathFor(basePath,index)}><FontAwesomeIcon icon="pencil-alt" /></LinkButton>
                <SimpleConfirmActionButton component={Button} variant="flat" onClick={() => destroyIngredient(index)}>
                    <FontAwesomeIcon icon="times" />
                </SimpleConfirmActionButton>
            </div>
        </div>
    )
}

const deleteIngredientCreator = (recipeDraft,cacheRecipeChanges) => index => {
    const newIngredients = [ ...recipeDraft.ingredients ];
    const ingr = newIngredients[index];
    newIngredients.splice(index,1,{...ingr, _destroy: true});
    cacheRecipeChanges({ ...recipeDraft, ingredients: newIngredients });
}
const updateIngredientCreator = (recipeDraft,cacheRecipeChanges) => (index,changes) => {
    const newIngredients = [ ...recipeDraft.ingredients ];
    newIngredients.splice(index,1,{ ...recipeDraft.ingredients[index], ...changes});
    cacheRecipeChanges({ ...recipeDraft, ingredients: newIngredients });
}

class IngredientStep extends React.Component {

    constructor(props) {
        super(props);
        const { setupTransitions } = this.props;
        setupTransitions({ rules: [
            [editRecipeMatchFor(2), transitions.flowFormIn]
        ]})
    }

    render() {
        const { recipeDraft, recipe: { ingredients }, recipe, cacheRecipeChanges, basePath, t } = this.props;
        const destroyIngredient = deleteIngredientCreator(recipeDraft,cacheRecipeChanges);
        const updateIngredient = updateIngredientCreator(recipeDraft,cacheRecipeChanges);
        const filteredIngrs = _.filter(ingredients,i => !i._destroy);

        return (
            <ActivityContainer>
                <ResponsiveContainer render={({ belowBreakpoint }) => {

                    return (
                        <React.Fragment>
                            {recipeDraft.showMasterEditWarning && (<div className="col s12">
                                <div className="mb15" id="master-edit-warning">
                                    <SlightEmphasisIconNote
                                        variant="warning"
                                        text={t(recipeDraft.showMasterEditWarning)}
                                    />
                                </div>
                            </div>)}
                            <div className="col s12 m8 offset-m2 l6">
                                <div className="m-center-column">
                                    {!belowBreakpoint && (<h4 className="conversion-heading ml0">{t('Recipe')}</h4>)}
                                    <Card className="mt20 pa10">
                                        <FormikWrapper 
                                            initialValues={ _.pick(recipeDraft,['name','servings']) }
                                            submit={values => {
                                                cacheRecipeChanges(values);
                                                return Promise.resolve({ status: 'SUCCESS' });
                                            }}
                                            initialErrors={{}}
                                            autoSubmit
                                            render={({ handleSubmit, submitState, handleAutoSubmitChange, ...rest }) => {
                                                const inputProps = _.pick(rest,formikAutoSubmitTextProps);
                                                return (
                                                    <form onSubmit={handleSubmit}>
                                                        <div className="small-form-header">{t('Basic Info')}</div>
                                                        <div className="valign-wrapper">
                                                            <InputWithErrors 
                                                                label={t('Name')}
                                                                className="inline flex-grow mb0"
                                                                name="name"
                                                                id="nameField"
                                                                component={TextInput}
                                                                {...inputProps}
                                                            />
                                                            <InputWithErrors 
                                                                label={t('Makes')}
                                                                helperText={t('servings')}
                                                                className="inline mb0"
                                                                name="servings"
                                                                id="servingsField"
                                                                component={NumberInput}
                                                                inProps={
                                                                    { disabled: !_.isBlank(recipeDraft.overriddenId) }
                                                                }
                                                                limitPattern={/[0-9]+/}
                                                                {...inputProps}
                                                            />
                                                        </div>
                                                    </form>
                                                )
                                            }}       
                                        />
                                        <div className="small-form-header">{t('Ingredients')}</div>
                                        {filteredIngrs.length === 0 && (
                                            <div className="pa10">
                                            </div>
                                        )}
                                        <TransitionGroup>
                                            {ingredients.map((ingredient,index) => {
                                                if(ingredient._destroy) {
                                                    return null;
                                                }
                                                return (
                                                    <CSSTransition classNames="fade" key={ingredient.formKey()} timeout={300}>
                                                        <IngredientRow basePath={basePath} ingredient={ingredient} index={index} destroyIngredient={destroyIngredient} />
                                                    </CSSTransition>
                                                )
                                            })}
                                        </TransitionGroup>
                                        {belowBreakpoint && (
                                            <div className={classnames("pa10 text-center",{ mt15: (filteredIngrs.length > 0) })}>
                                                <LinkButton id="add-ingredients-btn" color="primary" rounded outlined={filteredIngrs.length > 0} noShadow to={addIngredientsPath}>
                                                    <FontAwesomeIcon icon={'plus'} size='1x'/><span>{t("Add Ingredients")}</span>
                                                </LinkButton>
                                            </div>
                                        )}
                                        <div className="small-form-header">{t('Nutrition')} <span className="parenthetical">({t('per serving', { serving: t('serving')})})</span></div>
                                        <FullSvgMacroChart macros={recipe.calcdMacroHash()} />
                                    </Card>
                                </div>
                            </div>
                            {!belowBreakpoint && (
                                <div className="col s12 m8 offset-m2 l6">
                                    <div className="m-center-column">
                                        <h4 className="conversion-heading ml0">{t('Add Ingredients')}</h4>
                                        <SectionTabs activeTab={0} render={({ activeTab }) => {
                                            return (
                                                <AddIngredientTabs activeTab={activeTab+1} basePath={basePath} />
                                            )
                                        }}>
                                            {addTabLabels().map(({icon, text},index) => (<SectionTab key={index}><FontAwesomeIcon icon={icon} /> {t(text)}</SectionTab>))}
                                        </SectionTabs>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    )
                }} />
                <EditIngredientModal basePath={basePath} ingredients={ingredients} updateIngredient={updateIngredient} />
            </ActivityContainer>
        );
    }
}

export const parseRecipeDraft = recipeDraft => {
    let { image, defaultImage, ...draft } = recipeDraft;
    draft = Recipe.parseForSubmit(draft);
    if(_.isBlank(image)) {
        return draft;
    }
    return { ...draft, image: _.dataURItoBlob(image) }
}

const RecipeEditorSuccess = ({ date, location, recipeDraft, allergyTags, recipe, history, basePath, t, cacheRecipeChanges, step, confirmAction }) => {
    const filteredIngrs = _.filter(recipe.ingredients,i => !i._destroy)

    return (
        <div>
            <RouteTransitionMap location={location} canUpdate noOverflow>
                <IngredientStep 
                    path={editRecipeMatchFor(1)} 
                    date={date}
                    recipeDraft={recipeDraft}
                    recipe={recipe}
                    basePath={basePath}
                    cacheRecipeChanges={cacheRecipeChanges}
                    history={history}
                    t={t}
                />
                <CategorizeStep 
                    allergyTags={allergyTags}
                    path={editRecipeMatchFor(2)} 
                    recipeDraft={recipeDraft} 
                    cacheRecipeChanges={cacheRecipeChanges}
                    t={t}
                />
            </RouteTransitionMap>  
            <BottomButtons>
                {step === 1 && (<Button color="primary" id="next-btn" rounded disabled={filteredIngrs.length <= 0} onClick={() => (filteredIngrs.length > 0 && history.push(editRecipePathFor(2,date)))}>
                    <FontAwesomeIcon icon={'arrow-right'} size='1x'/><span>{t("Next")}</span>
                </Button>)}
                {step === 2 && (
                    <DefaultAsyncActionButton
                        loaderType={"loaderOnly"}
                        rounded 
                        color="primary" 
                        id={`confirm-recipe-btn`}
                        action={() => confirmAction(parseRecipeDraft(recipeDraft))}
                        successCallback={() => (goWrapper(history,-2))}
                    >
                            <FontAwesomeIcon icon={'check'}></FontAwesomeIcon> {t("Confirm")}
                    </DefaultAsyncActionButton>
                )}
            </BottomButtons>
        </div>      
    )
}

const transitionMap = {
    rules: [
        [[ ...mealPlanSlideOutPaths, userMealMatch, viewMealPathMatch, viewRecipeReplacementMatch, viewTempMealMatch, viewTempRecipeReplacementMatch, swapRecipeMatch, swapTempRecipeMatch, swapMealMatch, recipeBrowserPath ] ,transitions.slideOut],
        [[addIngredientsPath], transitions.slideOver]
    ]
};

export const getRecipeDraftAction = (recipeDraft) => {
    const { duplicateSourceId, overriddenId, id } = recipeDraft;
    let action = 'createRecipe';
    if(!_.isBlank(id)) {
        action = 'updateRecipe';
    } else if(!_.isBlank(overriddenId)) {
        action = 'createRecipeOverride';
    } else  if(!_.isBlank(duplicateSourceId)) {
        action = 'copyRecipe';
    }
    return action;
}

const RecipeEditorPage = ({ createRecipe, updateRecipe, createRecipeOverride, recipeEditorData: { recipeDraft, recipe }, allergyTags, loadRecipeDraft, cacheRecipeChanges, scrollRef, setupTransitions, history, location, match: { params: { date, step } } }) => {
    const  { t } = useTranslation();
    date = (date && date !== 'ingredient' && date !== 'add_ingredient' && date !== 'food_modal') ? date : null;
    const basePath =  editRecipePathFor(step,date)
    const action = getRecipeDraftAction(recipeDraft);
    const { title, confirmAction } = { 
        createRecipe: { confirmAction: createRecipe, title: 'New Recipe' }, 
        createRecipeOverride: { confirmAction: createRecipeOverride, title: 'Edit Recipe'},
        updateRecipe: { confirmAction: updateRecipe, title: 'Edit Recipe' },
        copyRecipe: { confirmAction: createRecipe, title: 'Copy Recipe' }
    }[action];

    return (
        <SimpleNavPage 
            scrollRef={scrollRef} 
            transitionMap={transitionMap} 
            setupTransitions={setupTransitions} 
            title={t(title)}
            navChildren={<ExitMealPlanButton hideIfUnsavedChanges />}
        >
                <Loader
                    successComponent={RecipeEditorSuccess}
                    allergyTags={allergyTags}
                    type="page"
                    load={loadRecipeDraft.bind(null,recipe.ingredients)}
                    preloaded={() => false}
                    basePath={basePath}
                    cacheRecipeChanges={cacheRecipeChanges}
                    location={location}
                    history={history}
                    recipe={recipe}
                    recipeDraft={recipeDraft}
                    confirmAction={confirmAction}
                    step={Number(step)}
                    date={date}
                    t={t}
                />
            {!recipeDraft  && (<Redirect to={resolvedHomePath()} />)}
        </SimpleNavPage>
    )
}

const mapStateToProps = state => ({
    recipeEditorData: recipeEditorSelector(state),
    allergyTags: allergyTagsSelector(state)
})

const mapDispatchToProps = dispatch => ({
    cacheRecipeChanges: data => dispatch(cacheRecipeChanges(data)),
    loadRecipeDraft: ingredients => dispatch(loadRecipeDraft(ingredients)),
    createRecipe: data => dispatch(createRecipe(data)),
    updateRecipe: data => dispatch(updateRecipe(data)),
    createRecipeOverride: data => dispatch(createRecipeOverride(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(RecipeEditorPage);